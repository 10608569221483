import { RootState } from '../../store.reducer';
import { MemoizedSelector, createSelector } from '@ngrx/store';
import { GenericMap } from '../../../models/generic-map';
import { ItemCustomField } from 'src/app/models/Opportunity/item-custom-field';
import { selectAllFieldTypes } from '../../system-settings/field-types/selectors/field-types.selector';
import { FieldType } from 'src/app/models/call-notes/field-type';
import { selectItemCustomFields } from './item-custom-fields.selector';

export const selectCurrentOppItemCustomFieldId = (state: RootState): number =>
  state.opportunities.selectedCustomItemFieldId;

export const selectCurrentOppItemCustomField: MemoizedSelector<
  RootState,
  ItemCustomField
> = createSelector(
  selectItemCustomFields,
  selectAllFieldTypes,
  selectCurrentOppItemCustomFieldId,
  (
    itemCFs: GenericMap<ItemCustomField>,
    types: GenericMap<FieldType>,
    fieldId: number
  ): ItemCustomField => {
    return !!itemCFs[fieldId] && !!types[itemCFs[fieldId].typeId]
      ? {
          ...itemCFs[fieldId],
          typeName: types[itemCFs[fieldId].typeId].name
        }
      : null;
  }
);
