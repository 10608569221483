import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'tn-data-not-found',
  template: `
    <h3 mat-dialog-title>
      Info: {{ !!data && !!data.confirmHeader ? data.confirmHeader : 'Data not found' }}
    </h3>
    <div fxLayoutAlign="center center" [style.paddingBottom]="'24px'">
      {{ !!data && !!data.confirmMessage ? data.confirmMessage : '' }}
    </div>
    <div mat-dialog-actions fxLayoutAlign="center center" [style.paddingBottom]="'24px'">
      <button mat-raised-button color="primary" (click)="onClose()">Close</button>
    </div>
  `,
  styles: []
})
export class DataNotFoundComponent {
  constructor(
    public dialogRef: MatDialogRef<DataNotFoundComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { confirmHeader?: string; confirmMessage?: string }
  ) {}

  onClose(): void {
    this.dialogRef.close(false);
  }
}
