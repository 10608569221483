import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { Label } from 'src/app/models/label';

export enum ProjectLabelsIndividiualActionTypes {
  SelectProjectLabelIndividualId = '[ProjectLabelsIndividual] Select ProjectLabelIndvidual Id',
  ClearProjectLabelIndividualId = '[ProjectLabelsIndividual] Clear ProjectLabelIndividual Id',
  LoadProjectLabelsIndividiuals = '[ProjectLabelsIndividiual] Load ProjectLabelsIndividiuals',
  LoadProjectLabelsIndividiualsSuccess = '[ProjectLabelsIndividiual] Load ProjectLabelsIndividiuals Success',
  LoadProjectLabelsIndividiualsFailure = '[ProjectLabelsIndividiual] Load ProjectLabelsIndividiuals Failure',
  CreateProjectLabelsIndividual = '[ProjectLabelsIndividual] Create ProjectLabelsIndividual',
  CreateProjectLabelsIndividualSuccess = '[ProjectLabelsIndividual] Create ProjectLabelsIndividual Success',
  CreateProjectLabelsIndividualFailure = '[ProjectLabelsIndividual] Create ProjectLabelsIndividual Failure',
  UpdateProjectLabelsIndividual = '[ProjectLabelsIndividual] Update ProjectLabelsIndividual',
  UpdateProjectLabelsIndividualSuccess = '[ProjectLabelsIndividual] Update ProjectLabelsIndividual Success',
  UpdateProjectLabelsIndividualFailure = '[ProjectLabelsIndividual] Update ProjectLabelsIndividual Failure',
  DeleteProjectLabelsIndividual = '[ProjectLabelsIndividual] Delete ProjectLabelsIndividual',
  DeleteProjectLabelsIndividualSuccess = '[ProjectLabelsIndividual] Delete ProjectLabelsIndividual Success',
  DeleteProjectLabelsIndividualFailure = '[ProjectLabelsIndividual] Delete ProjectLabelsIndividual Failure'
}

export class SelectProjectLabelIndividualId implements Action {
  readonly type = ProjectLabelsIndividiualActionTypes.SelectProjectLabelIndividualId;
  constructor(public payload: number) {}
}

export class ClearProjectLabelIndividualId implements Action {
  readonly type = ProjectLabelsIndividiualActionTypes.ClearProjectLabelIndividualId;
}

export class LoadProjectLabelsIndividiuals implements Action {
  readonly type = ProjectLabelsIndividiualActionTypes.LoadProjectLabelsIndividiuals;
  constructor(public payload: number) {}
}

export class LoadProjectLabelsIndividiualsSuccess implements Action {
  readonly type =
    ProjectLabelsIndividiualActionTypes.LoadProjectLabelsIndividiualsSuccess;
  constructor(public payload: Label[]) {}
}

export class LoadProjectLabelsIndividiualsFailure implements Action {
  readonly type =
    ProjectLabelsIndividiualActionTypes.LoadProjectLabelsIndividiualsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class CreateProjectLabelsIndividual implements Action {
  readonly type = ProjectLabelsIndividiualActionTypes.CreateProjectLabelsIndividual;
  constructor(public payload: { label: Label; groupId: number }) {}
}

export class CreateProjectLabelsIndividualSuccess implements Action {
  readonly type =
    ProjectLabelsIndividiualActionTypes.CreateProjectLabelsIndividualSuccess;
  constructor(public payload: Label) {}
}

export class CreateProjectLabelsIndividualFailure implements Action {
  readonly type =
    ProjectLabelsIndividiualActionTypes.CreateProjectLabelsIndividualFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class UpdateProjectLabelsIndividual implements Action {
  readonly type = ProjectLabelsIndividiualActionTypes.UpdateProjectLabelsIndividual;
  constructor(public payload: { label: Label; groupId: number }) {}
}

export class UpdateProjectLabelsIndividualSuccess implements Action {
  readonly type =
    ProjectLabelsIndividiualActionTypes.UpdateProjectLabelsIndividualSuccess;
  constructor(public payload: Label) {}
}

export class UpdateProjectLabelsIndividualFailure implements Action {
  readonly type =
    ProjectLabelsIndividiualActionTypes.UpdateProjectLabelsIndividualFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class DeleteProjectLabelsIndividual implements Action {
  readonly type = ProjectLabelsIndividiualActionTypes.DeleteProjectLabelsIndividual;
  constructor(public payload: { groupId: number; id: number }) {}
}

export class DeleteProjectLabelsIndividualSuccess implements Action {
  readonly type =
    ProjectLabelsIndividiualActionTypes.DeleteProjectLabelsIndividualSuccess;
  constructor(public payload: { groupId: number; id: number }) {}
}

export class DeleteProjectLabelsIndividualFailure implements Action {
  readonly type =
    ProjectLabelsIndividiualActionTypes.DeleteProjectLabelsIndividualFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export type ProjectLabelsIndividiualActions =
  | SelectProjectLabelIndividualId
  | ClearProjectLabelIndividualId
  | LoadProjectLabelsIndividiuals
  | LoadProjectLabelsIndividiualsSuccess
  | LoadProjectLabelsIndividiualsFailure
  | CreateProjectLabelsIndividual
  | CreateProjectLabelsIndividualSuccess
  | CreateProjectLabelsIndividualFailure
  | UpdateProjectLabelsIndividual
  | UpdateProjectLabelsIndividualSuccess
  | UpdateProjectLabelsIndividualFailure
  | DeleteProjectLabelsIndividual
  | DeleteProjectLabelsIndividualSuccess
  | DeleteProjectLabelsIndividualFailure;
