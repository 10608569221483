import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';

import { CallNoteV2 } from '../../models/call-notes/call-note-V2';
import { AuthenticationService } from '../authentication.service';
import { FilteredList } from '../../models/filteredList';
import { GlobalFilter } from '../../models/global-filter';
import { ServicesModule } from 'src/app/services/services.module';

@Injectable({
  providedIn: ServicesModule
})
export class CallNoteV2Service {
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private http: HttpClient
  ) {
    // super('/callnotes', authService);
  }

  getCallNotesFiltered(
    filter: any,
    skip?: number,
    limit?: number,
    order?: string
  ): Promise<FilteredList> {
    return this.authService
      .makeJpiRequest(
        '/callnotes/search?skip=' +
          (skip ? skip : 0) +
          '&limit=' +
          (limit ? limit : 0) +
          (order ? '&order=' + order.replace(/\+/g, '%2B') : ''),
        null,
        filter,
        'post'
      )
      .then((callNotes) => {
        callNotes = JSON.parse(callNotes);
        return callNotes;
      });
  }

  getCompanyIdsByCallNoteNarrative(
    filter: any,
    skip?: number,
    limit?: number
  ): Promise<number[]> {
    return this.authService
      .makeJpiRequest(
        `/callnotes/search_company?skip=${skip}&limit=${limit}&order=callDate`,
        null,
        filter,
        'post'
      )
      .then((companyIds) => {
        return JSON.parse(companyIds);
      });
  }

  exportCallNotes(
    orderField: string,
    orderAsc: boolean,
    filter: GlobalFilter
  ): Promise<string> {
    let url: string = '/callnotes/export';
    if (orderField) {
      url += '?order=' + (orderAsc ? '%2B' : '%2D') + orderField;
    }

    return this.authService
      .makeJpiRequest(url, null, filter, 'post')
      .then((response) => response);
  }

  addCallNote(callNote: CallNoteV2): Promise<CallNoteV2> {
    this.convertFollowUpDates(callNote);
    (<any>callNote).createdDate = moment(new Date(Date.now())).format(
      'YYYY-MM-DDTHH:mm:ss'
    );
    (<any>callNote).callDate = moment(new Date(callNote.callDate)).format(
      'YYYY-MM-DDTHH:mm:ss'
    );
    return this.authService
      .makeJpiRequest('/callnotes/', null, callNote, 'post')
      .then((result) => {
        return JSON.parse(result);
      });
  }

  updateCallNote(callNote: CallNoteV2): Promise<CallNoteV2> {
    for (let i = 0; i < callNote.fields.length; i++) {
      if (!callNote.fields[i].field) {
        callNote.fields.splice(i, 1);
        i--;
      }
    }
    this.convertFollowUpDates(callNote);
    return this.authService
      .makeJpiRequest('/callnotes/' + callNote.id, null, callNote, 'put')
      .then((result) => {
        return JSON.parse(result);
      });
  }

  getNearest15MinuteTime(date: Date): Date {
    const minutes = date.getMinutes();
    if (minutes > 0 && minutes < 15) {
      date.setMinutes(15);
    } else if (minutes > 15 && minutes < 30) {
      date.setMinutes(30);
    } else if (minutes > 30 && minutes < 45) {
      date.setMinutes(45);
    } else if (minutes > 45 && minutes < 60) {
      date.setHours(date.getHours() + 1);
      date.setMinutes(0);
    }
    return date;
  }

  convertFollowUpDates(callNote: CallNoteV2): void {
    if (
      callNote.followUp &&
      callNote.followUp.task &&
      !callNote.followUp.dueDateStart &&
      !callNote.followUp.dueDateEnd
    ) {
      let newDate = new Date();
      newDate = this.getNearest15MinuteTime(newDate);
      newDate.setDate(newDate.getDate() + 1);
      callNote.followUp.dueDateStart = newDate;
      callNote.followUp.dueDateEnd = moment(newDate).add(30, 'minutes').toDate();
    }
    if (callNote.followUp.dueDateStart) {
      const newStartDate = new Date(callNote.followUp.dueDateStart);
      (<any>callNote.followUp).dueDateStart = moment(
        callNote.followUp.dueDateStart
      ).format('YYYY-MM-DDTHH:mm:ss');
      callNote.followUp.dueDateStartTS = moment(newStartDate).valueOf();
    }
    if (callNote.followUp.dueDateEnd) {
      const newEndDate = new Date(callNote.followUp.dueDateEnd);
      (<any>callNote.followUp).dueDateEnd = moment(callNote.followUp.dueDateEnd).format(
        'YYYY-MM-DDTHH:mm:ss'
      );
      callNote.followUp.dueDateEndTS = moment(newEndDate).valueOf();
    }
  }

  getById(id): Promise<CallNoteV2> {
    return this.authService
      .makeJpiRequest('/callnotes/' + id, null, null, 'get')
      .then((callNote) => {
        return JSON.parse(callNote);
      });
  }

  create(callNote: any): Promise<CallNoteV2> {
    return this.authService
      .makeJpiRequest('/callnotes', null, callNote, 'post')
      .then((callNote) => {
        return JSON.parse(callNote);
      });
  }

  update(callNote: CallNoteV2): Promise<CallNoteV2> {
    return this.authService
      .makeJpiRequest('/callnotes/' + callNote.id, null, callNote, 'put')
      .then((callNote) => {
        return JSON.parse(callNote);
      });
  }

  delete(callNote: CallNoteV2): Promise<CallNoteV2> {
    return this.authService
      .makeJpiRequest('/callnotes/' + callNote.id, null, callNote, 'delete')
      .then((callNote) => {
        return JSON.parse(callNote);
      });
  }

  deleteById(callNoteId: number): Promise<CallNoteV2> {
    return this.authService
      .makeJpiRequest('/callnotes/' + callNoteId, null, { id: callNoteId }, 'delete')
      .then((callNote) => {
        return JSON.parse(callNote);
      });
  }

  bulkDelete(callNoteIds: number[]): Promise<boolean> {
    return this.authService
      .makeJpiRequest('/callnotes', null, callNoteIds, 'delete')
      .then((callNote) => {
        return true;
      });
  }
}
