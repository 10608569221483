import { Component, Input, OnInit } from '@angular/core';
import { Comment } from 'src/app/models/call-notes/comment';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import { DeleteCallNoteComment } from 'src/app/store/call-notes/call-notes.actions';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'tn-project-call-note-comment',
  templateUrl: './project-call-note-comment.component.html',
  styleUrls: ['./project-call-note-comment.component.scss']
})
export class ProjectCallNoteCommentComponent implements OnInit {
  @Input() callNoteId: number;
  @Input() comment: Comment;
  showDelete = false;
  safeValue: SafeHtml;

  constructor(private store: Store<RootState>, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.safeValue = this.sanitizer.bypassSecurityTrustHtml(this.comment.value);
  }

  deleteHandler() {
    this.store.dispatch(
      new DeleteCallNoteComment({
        callNoteId: this.callNoteId,
        comment: this.comment
      })
    );
  }

  setDeleteVisibility(val: boolean) {
    this.showDelete = val;
  }
}
