/* eslint-disable @typescript-eslint/dot-notation */

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';

import { RootState } from 'src/app/store/store.reducer';
import { Observable, throwError } from 'rxjs';
import { mergeMap, first, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(private store: Store<RootState>) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (new RegExp(environment.jpiUrl).test(request.url)) {
      return this.store.select('auth', 'data', 'jpi').pipe(
        first(),
        mergeMap((jpiAuthObj) => {
          const headers = !!jpiAuthObj ? { Authorization: jpiAuthObj.token } : {};
          const contentType = request.headers.get('Content-Type') || 'application/json';
          // headers['provider'] = '';
          /*if (!!jpiAuthObj && !!jpiAuthObj.provider) {
            headers['provider'] =              jpiAuthObj.provider.toLowerCase() === 'okta' ? 'okta' : 'telenotes';
          } else {
            delete headers['provider'];
          }*/
          headers['Content-Type'] = contentType;
          if (!!request.url.match('files') && request.method.toLowerCase() === 'post') {
            delete headers['Content-Type'];
          }
          const authReq = !!jpiAuthObj
            ? request.clone({
                setHeaders: headers
              })
            : request.clone();
          return next.handle(authReq);
        })
      );
    } else if (new RegExp(environment.apiUrl).test(request.url)) {
      return this.store.select('auth', 'data', 'api').pipe(
        first(),
        mergeMap((apiAuthObj) => {
          const authReq = !!apiAuthObj
            ? request.clone({
                setHeaders: {
                  Authorization: apiAuthObj.ApiAuthToken
                }
              })
            : request;
          return next.handle(authReq);
        })
      );
    } else if (new RegExp(environment.elasticSyncUrl).test(request.url)) {
      return this.store.select('auth', 'data', 'jpi').pipe(
        first(),
        mergeMap((jpiAuthObj) => {
          const headers = !!jpiAuthObj ? { Authorization: jpiAuthObj.token } : {};
          const contentType = request.headers.get('Content-Type') || 'application/json';
          headers['Content-Type'] = contentType;
          const authReq = !!jpiAuthObj
            ? request.clone({
                setHeaders: headers
              })
            : request.clone();
          return next.handle(authReq).pipe(catchError((error: any) => throwError(error)));
        })
      );
    } else {
      return next.handle(request).pipe(catchError((error: any) => throwError(error)));
    }
  }
}
