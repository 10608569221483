import { DashboardStackCount } from 'src/app/models/dashboard/dashboard-stack-count';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { endOfMonth, subMonths } from 'date-fns';
import format from 'date-fns/format';
import startOfMonth from 'date-fns/startOfMonth';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { CallNote } from 'src/app/models/call-notes/call-note';
import { Company } from 'src/app/models/companies/company';
import { LabelGroupCountsResponse } from 'src/app/models/dashboard/call-notes/label-group-counts-response';
import { LabelGroupsCountsResponse } from 'src/app/models/dashboard/call-notes/label-groups-counts-response';
import { ChartDatum } from 'src/app/models/dashboard/chart-datum';
import { CompanyContacted } from 'src/app/models/dashboard/company-contacted';
import { DashboardApiRequest } from 'src/app/models/dashboard/dashboard-api-request';
import {
  DashboardApiNotesResponse,
  DashboardApiResponse
} from 'src/app/models/dashboard/dashboard-api-response';
import { DashboardCount } from 'src/app/models/dashboard/dashboard-count';
import { JpiResponse } from 'src/app/models/http/jpi-response.model';
import { CallNotesService } from 'src/app/services/call-notes/call-notes.service';
import { CompaniesService } from 'src/app/services/companies/companies.service';
import { CallNotesDashboardService } from 'src/app/services/dashboard/call-notes-dashboard.service';
import { convertToMap } from 'src/app/utils/convertToMap';
import { dbDateFormatWithoutT } from 'src/app/utils/dbDateFormat';
import { deepCopy } from 'src/app/utils/deepCopy';
import { LoadCallNotesSuccess } from '../call-notes/call-notes.actions';
import {
  DashboardActions,
  DashboardActionTypes,
  LoadCallFieldCountsFailure,
  LoadCallFieldCountsSuccess,
  LoadCallFieldNotesFailure,
  LoadCallFieldNotesSuccess,
  LoadCallLabelGroupCountsFailure,
  LoadCallLabelGroupCountsSuccess,
  LoadCallLabelGroupsCountsFailure,
  LoadCallLabelGroupsCountsSuccess,
  LoadCallTypeCountsFailure,
  LoadCallTypeCountsSuccess,
  LoadCallTypeNotesFailure,
  LoadCallTypeNotesSuccess,
  LoadCallTypeUserCountsFailure,
  LoadCallTypeUserCountsSuccess,
  LoadCallUserCountsFailure,
  LoadCallUserCountsSuccess,
  LoadCallUserNotesFailure,
  LoadCallUserNotesSuccess,
  LoadContactedCompanies,
  LoadContactedCompaniesFailure,
  LoadContactedCompaniesSuccess,
  LoadLast12MonthsFailure,
  LoadLast12MonthsSuccess,
  LoadNewCompanies,
  LoadNewCompaniesFailure,
  LoadNewCompaniesSuccess
} from './dashboard.actions';

@Injectable()
export class DashboardEffects {
  constructor(
    private actions$: Actions<DashboardActions>,
    private cnDashboardService: CallNotesDashboardService,
    private companiesService: CompaniesService,
    private cnService: CallNotesService
  ) {}

  loadCallTypeCounts$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActionTypes.LoadCallTypeCounts),
      mergeMap((action) =>
        this.cnDashboardService.getCallTypeCounts(action.payload).pipe(
          map((res: DashboardApiResponse<DashboardCount>) => {
            return new LoadCallTypeCountsSuccess(res.counts);
          }),
          catchError((error: HttpErrorResponse) =>
            of(new LoadCallTypeCountsFailure(error))
          )
        )
      )
    )
  );

  loadCallTypeUserCounts$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActionTypes.LoadCallTypeUserCounts),
      mergeMap((action) =>
        this.cnDashboardService.getCallTypeUserCounts(action.payload).pipe(
          map((res: DashboardApiResponse<DashboardStackCount>) => {
            return new LoadCallTypeUserCountsSuccess(res.counts);
          }),
          catchError((error: HttpErrorResponse) =>
            of(new LoadCallTypeUserCountsFailure(error))
          )
        )
      )
    )
  );

  loadCallTypeNotes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActionTypes.LoadCallTypeNotes),
      mergeMap((action) =>
        this.cnDashboardService.getCallTypeNotes(action.payload).pipe(
          switchMap((res: DashboardApiNotesResponse<CallNote>) => {
            return [
              new LoadCallTypeNotesSuccess(deepCopy(res.items)),
              new LoadCallNotesSuccess(convertToMap(res.items, 'id'))
            ];
          }),
          catchError((error: HttpErrorResponse) =>
            of(new LoadCallTypeNotesFailure(error))
          )
        )
      )
    )
  );

  loadCallFieldCounts$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActionTypes.LoadCallFieldCounts),
      mergeMap((action) =>
        this.cnDashboardService.getFieldCounts(action.payload).pipe(
          map((res: DashboardApiResponse<DashboardCount>) => {
            return new LoadCallFieldCountsSuccess(res.counts);
          }),
          catchError((error: HttpErrorResponse) =>
            of(new LoadCallFieldCountsFailure(error))
          )
        )
      )
    )
  );

  loadCallFieldNotes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActionTypes.LoadCallFieldNotes),
      mergeMap((action) =>
        this.cnDashboardService.getCallFieldNotes(action.payload).pipe(
          switchMap((res: DashboardApiNotesResponse<CallNote>) => {
            return [
              new LoadCallFieldNotesSuccess(res.items),
              new LoadCallNotesSuccess(convertToMap(res.items, 'id'))
            ];
          }),
          catchError((error: HttpErrorResponse) =>
            of(new LoadCallFieldNotesFailure(error))
          )
        )
      )
    )
  );

  loadCallUserCounts$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActionTypes.LoadCallUserCounts),
      mergeMap((action) =>
        this.cnDashboardService.getUserCounts(action.payload).pipe(
          map((res: DashboardApiResponse<DashboardCount>) => {
            return new LoadCallUserCountsSuccess(res.counts);
          }),
          catchError((error: HttpErrorResponse) =>
            of(new LoadCallUserCountsFailure(error))
          )
        )
      )
    )
  );

  loadCallUserNotes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActionTypes.LoadCallUserNotes),
      mergeMap((action) =>
        this.cnDashboardService.getCallUserNotes(action.payload).pipe(
          switchMap((res: DashboardApiNotesResponse<CallNote>) => {
            return [
              new LoadCallUserNotesSuccess(res.items),
              new LoadCallNotesSuccess(convertToMap(res.items, 'id'))
            ];
          }),
          catchError((error: HttpErrorResponse) =>
            of(new LoadCallUserNotesFailure(error))
          )
        )
      )
    )
  );

  loadCallLabelGroupCounts$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActionTypes.LoadCallLabelGroupCounts),
      mergeMap((action) =>
        this.cnDashboardService.getLabelGroupCounts(action.payload).pipe(
          map((res: LabelGroupCountsResponse) => {
            return new LoadCallLabelGroupCountsSuccess(res.counts);
          }),
          catchError((error: HttpErrorResponse) =>
            of(new LoadCallLabelGroupCountsFailure(error))
          )
        )
      )
    )
  );

  loadCallLabelGroupsCounts$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActionTypes.LoadCallLabelGroupsCounts),
      mergeMap((action) =>
        this.cnDashboardService.getLabelGroupsCounts(action.payload).pipe(
          map((res: LabelGroupsCountsResponse) => {
            return new LoadCallLabelGroupsCountsSuccess(res.groups);
          }),
          catchError((error: HttpErrorResponse) =>
            of(new LoadCallLabelGroupsCountsFailure(error))
          )
        )
      )
    )
  );

  loadLast12Months$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActionTypes.LoadLast12Months),
      mergeMap((action) => {
        return forkJoin([
          of(action.payload),
          forkJoin(
            Array.from(Array(13).keys())
              // .slice(1)
              .reduce(
                (
                  acc: Observable<DashboardApiResponse<DashboardCount>>[],
                  currIndex: number
                ) => {
                  const currDate = subMonths(startOfMonth(new Date()), currIndex);
                  acc.push(
                    this.cnDashboardService.getUserCounts({
                      repIds: action.payload.repIds,
                      territoryIds: action.payload.territoryIds,
                      fromDate: format(currDate, dbDateFormatWithoutT),
                      toDate: format(endOfMonth(currDate), dbDateFormatWithoutT)
                    })
                  );
                  return acc;
                },
                []
              )
              .reverse()
          )
        ]);
      }),
      map((resultArray) => {
        return { actionPayload: resultArray[0], res: resultArray[1] };
      }),
      map(({ actionPayload, res }) => {
        return new LoadLast12MonthsSuccess(
          res.map((resItem: DashboardApiResponse<DashboardCount>, i: number) => {
            const chartDatum: ChartDatum = {
              name:
                new Date(resItem.fromDate).getMonth() === 0 || i === 0
                  ? format(new Date(resItem.fromDate), 'LLL yy')
                  : format(new Date(resItem.fromDate), 'LLL'),
              value: resItem.counts.reduce((acc, curr) => {
                acc += curr.count;
                return acc;
              }, 0),
              extra: {
                beginDate: resItem.fromDate,
                endDate: resItem.toDate,
                repIds: actionPayload.repIds,
                territoryIds: actionPayload.territoryIds
              }
            };
            return chartDatum;
          })
        );
      }),
      catchError((error) => {
        return of(new LoadLast12MonthsFailure(error));
      })
    )
  );

  $loadNewCompanies = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActionTypes.LoadNewCompanies),
      mergeMap((action: LoadNewCompanies) => {
        return this.companiesService
          .getCompaniesFiltered(
            action.payload.filter,
            action.payload.params.limit,
            action.payload.params.order,
            action.payload.params.orderAsc,
            action.payload.params.skip
          )
          .pipe(
            map((res: JpiResponse<Company>) => new LoadNewCompaniesSuccess(res.items)),
            catchError((error) => of(new LoadNewCompaniesFailure(error)))
          );
      })
    )
  );

  $loadContactedCompanies = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActionTypes.LoadContactedCompanies),
      mergeMap((action: LoadContactedCompanies) => {
        return this.cnService
          .getCallNotes(action.payload.filter, {
            limit: action.payload.params.limit.toString(),
            skip: action.payload.params.skip.toString(),
            order: action.payload.params.order
          })
          .pipe(
            map((res: JpiResponse<CallNote>) => {
              const compsContacted: CompanyContacted[] = res.items.reduce(
                (acc: CompanyContacted[], curr: CallNote) => {
                  const existingCompContacted: CompanyContacted = acc.find(
                    (compContacted) => compContacted.id === curr.company.id
                  );
                  if (!!existingCompContacted) {
                    existingCompContacted.callNotes.push(curr);
                  } else {
                    acc.push({
                      name: curr.company.name,
                      address: curr.company.billingAddress,
                      id: curr.company.id,
                      dateLastContacted: curr.callDate as unknown as string,
                      callNotes: [curr]
                    });
                  }
                  return acc;
                },
                []
              );
              return new LoadContactedCompaniesSuccess(compsContacted);
            }),
            catchError((error) => of(new LoadContactedCompaniesFailure(error)))
          );
      })
    )
  );
}
