import { Component, Input, OnInit } from '@angular/core';

import { CompanyV2 } from '../../models/companies/company-V2';

@Component({
    selector: 'company-popup-addresses',
    templateUrl: './company-popup-addresses.component.html',
    styleUrls: ['./company-popup.component.scss']
})
export class CompanyPopupAddressesComponent implements OnInit {
    @Input() billingAddressExpanded: boolean;
    @Input() shippingAddressExpanded: boolean;
    @Input() company: CompanyV2;

    constructor() { }

    ngOnInit() {
    }

    expandBillingRow(): void {
        this.billingAddressExpanded = !this.billingAddressExpanded;
    }

    expandShippingRow(): void {
        this.shippingAddressExpanded = !this.shippingAddressExpanded;
    }
}
