import { Component, OnInit, OnDestroy } from '@angular/core';
import { RootState } from 'src/app/store/store.reducer';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription, Observable, combineLatest } from 'rxjs';
import { CallNoteField } from 'src/app/models/call-notes/call-note-field';
import { Store } from '@ngrx/store';
import { FieldType } from 'src/app/models/call-notes/field-type';
import { CloseDrawer } from 'src/app/store/layout/layout.actions';
import {
  ClearCurrentCallNoteField,
  DeleteCallNoteCustomField,
  CreateCallNoteCustomField,
  UpdateCallNoteCustomField,
  LoadCallNoteDropdownOptions,
  SaveCallNoteDropdownOption,
  DeleteCallNoteDropdownOption,
  UpdateCallNoteDropdownOptionsOrder
} from 'src/app/store/system-settings/call-notes/call-note-custom-fields.actions';
import { selectCurrentCallNoteFieldDropdownOptions } from 'src/app/store/system-settings/call-notes/selectors/call-note-dropdown-options.selector';
import { map, take } from 'rxjs/operators';
import { Field } from 'src/app/models/call-notes/field';
import { DropdownOption } from 'src/app/models/dropdown-option';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDeleteComponent } from 'src/app/view/shared/confirm-delete/confirm-delete.component';

@Component({
  selector: 'tn-call-note-field-form',
  templateUrl: './call-note-field-form.component.html',
  styleUrls: ['./call-note-field-form.component.css']
})
export class CallNoteFieldFormComponent implements OnInit, OnDestroy {
  callNoteFieldForm: FormGroup = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    type: new FormControl(null, [Validators.required]),
    callnoteOrder: new FormControl(null),
    id: new FormControl(null),
    deleted: new FormControl(null),
    deletedDate: new FormControl(null)
    // field: new FormControl(this.currentCallNoteField.field)
  });
  $currentCallNoteFieldSub: Subscription;
  currentCallNoteField: Field;
  callNoteFields: Field[];
  fieldTypes: FieldType[] = [];
  dropdownOptions: DropdownOption[];
  optionsFetched = false;
  newOptionForm: FormGroup = new FormGroup({
    dropdownOption: new FormControl(null),
    fieldId: new FormControl(null)
  });

  constructor(private store: Store<RootState>, private dialog: MatDialog) {}

  ngOnInit() {
    this.$currentCallNoteFieldSub = combineLatest([
      this.store.select('callNoteCustomFields', 'currentCallNoteField'),
      this.store.select('callNoteCustomFields', 'callNoteFields'),
      this.store.select('fieldTypes', 'fieldTypes').pipe(
        map((fieldTypes: FieldType[]) => {
          return fieldTypes.filter((fieldType: FieldType) => fieldType.callNoteField);
        })
      ),
      this.store.select(selectCurrentCallNoteFieldDropdownOptions)
    ]).subscribe(
      ([field, fields, fieldTypes, dropdownOptions]: [
        Field,
        Field[],
        FieldType[],
        DropdownOption[]
      ]) => {
        this.dropdownOptions = dropdownOptions;
        this.fieldTypes = fieldTypes;
        this.currentCallNoteField = field;
        this.callNoteFields = fields;
        if (!!field && field.id && !this.optionsFetched) {
          // causes infinite loop without this.optionsFetched
          this.newOptionForm.controls.fieldId.setValue(field.id);
          this.store.dispatch(new LoadCallNoteDropdownOptions(field.id));
          this.optionsFetched = true;
        }
        if (!!this.currentCallNoteField) {
          this.callNoteFieldForm.patchValue({
            name: this.currentCallNoteField.name,
            type: this.currentCallNoteField.type,
            callnoteOrder: this.currentCallNoteField.callnoteOrder,
            id: this.currentCallNoteField.id,
            deleted: this.currentCallNoteField.deleted,
            deletedDate: this.currentCallNoteField.deletedDate
            // field: new FormControl(this.currentCallNoteField.field)
          });
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.$currentCallNoteFieldSub.unsubscribe();
  }

  editHandler(option: DropdownOption) {
    option.isEditing = true;
  }

  saveOptionHandler(option: DropdownOption, newName: string) {
    this.store.dispatch(new SaveCallNoteDropdownOption(option));
    option.isEditing = false;
  }

  cancelEditHandler(option: DropdownOption) {
    option.isEditing = false;
  }

  deleteHandler(option: DropdownOption) {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {});

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((willDelete: boolean) => {
        if (willDelete) {
          this.store.dispatch(new DeleteCallNoteDropdownOption(option));
        }
      });
  }

  optionDropped(event) {
    moveItemInArray(this.dropdownOptions, event.previousIndex, event.currentIndex);
    this.dropdownOptions.forEach((field, i) => {
      field.dropdownOrder = i + 1;
    });
    this.store.dispatch(
      new UpdateCallNoteDropdownOptionsOrder({
        fieldId: this.currentCallNoteField.id,
        options: this.dropdownOptions
      })
    );
  }

  submitOptionHandler(event) {
    event.preventDefault();
    const newOption = this.newOptionForm.value;
    if (!!newOption.dropdownOption && !!newOption.dropdownOption.trim()) {
      this.store.dispatch(new SaveCallNoteDropdownOption(newOption));
      this.newOptionForm.controls.dropdownOption.reset();
    }
  }

  compareObjects(o1: any, o2: any) {
    if (!!o1 && !!o2) {
      return o1.name === o2.name && o1.id && o2.id;
    }
  }

  createButtonClicked() {
    this.callNoteFieldForm.patchValue({ callnoteOrder: this.callNoteFields.length });
    this.store.dispatch(new CreateCallNoteCustomField(this.callNoteFieldForm.value));
    this.store.dispatch(new CloseDrawer());
    this.store.dispatch(new ClearCurrentCallNoteField());
  }

  saveButtonClicked() {
    this.store.dispatch(new UpdateCallNoteCustomField(this.callNoteFieldForm.value));
    this.store.dispatch(new CloseDrawer());
    this.store.dispatch(new ClearCurrentCallNoteField());
  }

  cancelButtonClicked() {
    this.store.dispatch(new CloseDrawer());
    this.store.dispatch(new ClearCurrentCallNoteField());
  }

  deleteButtonClicked() {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {});

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((willDelete: boolean) => {
        if (willDelete) {
          this.store.dispatch(
            new DeleteCallNoteCustomField(this.currentCallNoteField.id)
          );
          this.store.dispatch(new CloseDrawer());
          this.store.dispatch(new ClearCurrentCallNoteField());
        }
      });
  }
}
