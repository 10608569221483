import { NgModule } from '@angular/core';
import { RootRoutingModule } from './root-routing.module';
import { SharedModule } from './shared/shared.module';
import { RootComponent } from './shared/root/root.component';
import { AppViewComponent } from './shared/app-view/app-view.component';
import { MaterialModule } from './material/material.module';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppV2Module } from '../app-v2/app-v2.module';

@NgModule({
  declarations: [RootComponent, AppViewComponent],
  exports: [RootComponent],
  imports: [
    CommonModule,
    RootRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    MaterialModule,
    AppV2Module
  ]
})
export class ViewModule {}
