import { MemoizedSelector, createSelector } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import { ExpenseType } from 'src/app/models/activities/expense-type';
import { selectAllExpenseTypes } from './all-expenseTypes-as-array.selector';

export const selectedExpenseTypeId = (state: RootState) =>
  state.expenseTypes.selectedExpenseTypeId;

export const selectSelectedExpenseType: MemoizedSelector<
  RootState,
  ExpenseType
> = createSelector(
  selectAllExpenseTypes,
  selectedExpenseTypeId,
  (
    expenseTypeIndividualMap: { [id: number]: ExpenseType },
    expenseTypeId: number
  ): ExpenseType => {
    let expenseType: ExpenseType;
    if (!!expenseTypeId) {
      const id = expenseTypeId.toString();
      expenseType = {
        ...expenseTypeIndividualMap[id]
      };
    }
    return expenseType;
  }
);
