import { Action } from '@ngrx/store';
import { IdNameItem } from 'src/app/models/id-name-item';

export enum CalendarActionTypes {
  ToggleShowAll = '[Calendar] Toggle Show All',
  SetSelectedUser = '[Calendar] Set Selected User',
  SetTab = '[Calendar] Set Tab',
  SetView = '[Calendar] Set View',
  SetUpcomingSort = '[Calendar] Set Upcoming Sort',
  SetOverdueSort = '[Calendar] Set Overdue Sort'
}

export class ToggleShowAll implements Action {
  readonly type = CalendarActionTypes.ToggleShowAll;
  constructor(public payload?: boolean) {}
}

export class SetSelectedUser implements Action {
  readonly type = CalendarActionTypes.SetSelectedUser;
  constructor(public payload: IdNameItem) {}
}

export class SetTab implements Action {
  readonly type = CalendarActionTypes.SetTab;
  constructor(public payload: 0 | 1) {}
}

export class SetView implements Action {
  readonly type = CalendarActionTypes.SetView;
  constructor(public payload: 'calendar' | 'list' | 'cards') {}
}

export class SetUpcomingSort implements Action {
  readonly type = CalendarActionTypes.SetUpcomingSort;
  constructor(public payload: 'asc' | 'desc') {}
}

export class SetOverdueSort implements Action {
  readonly type = CalendarActionTypes.SetOverdueSort;
  constructor(public payload: 'asc' | 'desc') {}
}

export type CalendarActions =
  | ToggleShowAll
  | SetSelectedUser
  | SetTab
  | SetView
  | SetUpcomingSort
  | SetOverdueSort;
