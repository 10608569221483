import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ProjectCustomField } from 'src/app/models/projects/project-custom-field';
import { GenericMap } from 'src/app/models/generic-map';

export const SetCurrentProjectCustomFieldId = createAction(
  '[ProjectCustomFields] Set Current Project Custom Field ID',
  props<{ id: number }>()
);

export const LoadProjectCustomFields = createAction(
  '[ProjectCustomFields] Load Project Custom Fields'
);

export const LoadProjectCustomFieldsSuccess = createAction(
  '[ProjectCustomFields] Load Project Custom Fields Success',
  props<{ data: GenericMap<ProjectCustomField> }>()
);

export const LoadProjectCustomFieldsFailure = createAction(
  '[ProjectCustomFields] Load Project Custom Fields Failure',
  props<{ error: HttpErrorResponse }>()
);

export const SaveProjectCustomField = createAction(
  '[ProjectCustomFields] Save Project Custom Field',
  props<{ data: ProjectCustomField }>()
);

export const SaveProjectCustomFieldSuccess = createAction(
  '[ProjectCustomFields] Save Project Custom Field Success',
  props<{ data: ProjectCustomField }>()
);

export const SaveProjectCustomFieldFailure = createAction(
  '[ProjectCustomFields] Save Project Custom Field Failure',
  props<{ error: HttpErrorResponse }>()
);

export const DeleteProjectCustomField = createAction(
  '[ProjectCustomFields] Delete Project Custom Field',
  props<{ data: ProjectCustomField }>()
);

export const DeleteProjectCustomFieldSuccess = createAction(
  '[ProjectCustomFields] Delete Project Custom Field Success',
  props<{ data: any }>()
);

export const DeleteProjectCustomFieldFailure = createAction(
  '[ProjectCustomFields] Delete Project Custom Field Failure',
  props<{ error: HttpErrorResponse }>()
);

export const UpdateProjectCustomFieldsListOrder = createAction(
  '[ProjectCustomFields] Update Project Custom Fields List Order',
  props<{ data: ProjectCustomField[] }>()
);

export const UpdateProjectCustomFieldsListOrderSuccess = createAction(
  '[ProjectCustomFields] Update Project Custom Fields List Order Success',
  props<{ data: ProjectCustomField[] }>()
);

export const UpdateProjectCustomFieldsListOrderFailure = createAction(
  '[ProjectCustomFields] Update Project Custom Fields List Order Failure',
  props<{ error: HttpErrorResponse }>()
);
