<form [formGroup]="opportunityLabelForm" class="example-form">
  <mat-form-field class="example-full-width">
    <mat-label>Name</mat-label>
    <input formControlName="name" matInput type="text" required />
  </mat-form-field>

  <mat-form-field class="example-full-width">
    <mat-label>Description</mat-label>
    <textarea matInput formControlName="description" tnAutosizeTextarea></textarea>
  </mat-form-field>

  <div fxLayout="column" fxLayoutGap="12px" style="margin-bottom: 24px">
    <mat-checkbox formControlName="required">Required</mat-checkbox>
    <mat-checkbox formControlName="allowMultiple">Allow Multiple Selections</mat-checkbox>
  </div>

  <div
    *ngIf="currentOpportunityLabel?.id"
    cdkDropList
    class="drag-drop-list"
    (cdkDropListDropped)="drop($event)"
  >
    <mat-divider></mat-divider>
    <mat-progress-bar
      style.visibility="{{ ($labelsPending | async) === false ? 'hidden' : 'visible' }}"
      [mode]="'indeterminate'"
    ></mat-progress-bar>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <h4>Labels</h4>
      <button
        mat-raised-button
        class="add-label-button"
        color="primary"
        [matMenuTriggerFor]="addLabelMenu"
        #addLabelMenuTrigger
        (menuOpened)="menuOpened()"
      >
        Add label <mat-icon>add</mat-icon>
      </button>
    </div>
    <div fxLayout="row" style="margin-left: 5px" fxLayoutAlign="start center">
      <div fxFlex="7"></div>
      <div [fxFlex]="currentOpportunityLabel.stage ? 47 : 84">Name</div>
      <div *ngIf="currentOpportunityLabel.stage" fxFlex="13" color="primary">Won</div>
      <div *ngIf="currentOpportunityLabel.stage" fxFlex="12">Lost</div>
      <div *ngIf="currentOpportunityLabel.stage" fxFlex="12">Closed</div>
      <div fxFlex="9"></div>
    </div>
    <mat-menu class="label-select-menu" #addLabelMenu="matMenu" xPosition="before">
      <form
        [formGroup]="opportunityLabelIndividualForm"
        class="menu-form"
        (click)="$event.stopPropagation()"
      >
        <div fxLayout="row" fxLayoutAlign="space-between">
          <mat-form-field>
            <mat-label>Label Name</mat-label>
            <input
              matInput
              cdkFocusInitial
              [value]="currentOpportunityLabelIndividual?.name"
              formControlName="name"
              type="string"
            />
          </mat-form-field>
          <div mat-dialog-actions>
            <button
              *ngIf="!opportunityLabelIndividualForm.controls.id.value"
              mat-icon-button
              color="primary"
              (click)="saveIndividual()"
              [disabled]="
                opportunityLabelIndividualForm.pristine ||
                opportunityLabelIndividualForm.invalid
              "
            >
              <mat-icon>check</mat-icon>
            </button>
          </div>
          <div mat-dialog-actions>
            <button
              *ngIf="opportunityLabelIndividualForm.controls.id.value"
              mat-icon-button
              color="primary"
              (click)="editIndividual()"
              [disabled]="
                opportunityLabelIndividualForm.pristine ||
                opportunityLabelIndividualForm.invalid
              "
            >
              <mat-icon>check</mat-icon>
            </button>
          </div>
        </div>
      </form>
    </mat-menu>
    <div
      *ngFor="let label of opportunityLabelsIndividual | sort: 'listOrder'"
      cdkDrag
      cdkDragBoundary=".drag-drop-list"
      style="
        background-color: whitesmoke;
        border-bottom: 1px #555;
        margin-top: 7px;
        padding: 5px 5px 0 5px;
        border-radius: 6px;
        height: 32px;
      "
    >
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="7" fxLayout="row" fxLayoutAlign="start center">
          <i class="material-icons">reorder</i>
        </div>
        <div
          [fxFlex]="currentOpportunityLabel.stage ? 45 : 84"
          [style.position]="'relative'"
        >
          {{ label.name }}
          <button
            mat-icon-button
            color="primary"
            [style.cursor]="'pointer'"
            [style.position]="'absolute'"
            [style.bottom]="'-10px'"
            [matMenuTriggerFor]="addLabelMenu"
            #addLabelMenuTrigger
            (menuOpened)="menuOpenedEdit(label)"
          >
            <mat-icon [style.fontSize]="'20px'">edit</mat-icon>
          </button>
        </div>
        <div fxFlex="13" *ngIf="currentOpportunityLabel.stage" fxLayoutAlign="center">
          <mat-checkbox
            color="primary"
            (change)="wonChange(label, $event)"
            [checked]="label.isWon"
          ></mat-checkbox>
        </div>
        <div fxFlex="13" *ngIf="currentOpportunityLabel.stage" fxLayoutAlign="center">
          <mat-checkbox
            color="primary"
            (change)="lostChange(label, $event)"
            [checked]="label.isLost"
          ></mat-checkbox>
        </div>
        <div fxFlex="13" *ngIf="currentOpportunityLabel.stage" fxLayoutAlign="center">
          <mat-checkbox
            color="primary"
            (change)="closedChange(label, $event)"
            [checked]="label.isClosed"
          ></mat-checkbox>
        </div>
        <div fxFlex="9" fxLayoutAlign="center" (click)="deleteIndividual(label)">
          <mat-icon color="warn" class="material-icons">delete</mat-icon>
        </div>
      </div>
    </div>
  </div>

  <div
    style="margin-top: 10px"
    fxLayout="row-reverse"
    fxLayoutAlign="space-between center"
  >
    <div fxLayout="row-reverse">
      <button
        *ngIf="!currentOpportunityLabel?.id"
        mat-raised-button
        color="primary"
        mat-button
        class="button-container"
        [disabled]="opportunityLabelForm.pristine || opportunityLabelForm.invalid"
        (click)="save()"
      >
        Save
      </button>
      <button
        *ngIf="currentOpportunityLabel?.id"
        mat-raised-button
        mat-button
        color="primary"
        class="button-container"
        [disabled]="opportunityLabelForm.pristine || opportunityLabelForm.invalid"
        (click)="update()"
      >
        Save
      </button>
      <button
        preventDefaultAction
        class="button-container"
        mat-raised-button
        mat-button
        (click)="cancel()"
      >
        Cancel
      </button>
    </div>
    <div>
      <button
        preventDefaultAction
        *ngIf="currentOpportunityLabel?.id"
        mat-raised-button
        color="warn"
        mat-button
        (click)="delete()"
      >
        Delete
      </button>
    </div>
  </div>
</form>
