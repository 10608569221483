import { GenericMap } from './../../../models/generic-map';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ToggleSalesModule } from 'src/app/store/sales/sales.actions';
import { CallNoteFormAudioComponent } from '../call-note-form-audio/call-note-form-audio.component';
import { OpenDrawer } from 'src/app/store/layout/layout.actions';
import { Feature } from 'src/app/models/features/feature';

@Component({
  selector: 'tn-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {
  $showNewSalesModuleButton: Observable<boolean>;
  $newCompProfileState: Observable<boolean>;
  $newSalesModuleState: Observable<boolean>;
  $showMakeDashboardDefaultBtn: Observable<boolean>;
  $makeDashboardDefaultState: Observable<boolean>;
  webRecording: boolean = false;

  constructor(private store: Store<RootState>) {}

  ngOnInit() {
    this.$showNewSalesModuleButton = this.store
      .select('router', 'state', 'url')
      .pipe(
        map(
          (url: string) =>
            (!!url.match('/sales/') || !!url.match('/salescommissions')) &&
            !url.match('/preferences')
        )
      );
    this.$newSalesModuleState = this.store.select('sales', 'useNewSalesModule');
    this.store
      .select('auth', 'data', 'api', 'Role', 'Features')
      .subscribe((features: GenericMap<Feature>) => {
        if (
          (features != null || undefined) &&
          Object.keys(features).length &&
          features[4]?.Permissions[163]?.Value === 'true'
        ) {
          this.webRecording = true;
        }
      });
  }

  toggleSalesModule() {
    this.store.dispatch(new ToggleSalesModule());
  }

  openCallNoteDrawer(): void {
    this.store.dispatch(
      new OpenDrawer({
        component: CallNoteFormAudioComponent,
        data: {
          company: null,
          redirect: false
        }
      })
    );
  }
}
