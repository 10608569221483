import { IdNameItem } from 'src/app/models/id-name-item';
import { CalendarActions, CalendarActionTypes } from './calendar.actions';

export interface CalendarState {
  showAll: boolean;
  selectedUser: IdNameItem;
  selectedTab: 0 | 1;
  selectedView: 'calendar' | 'list' | 'cards';
  upcomingSort: 'asc' | 'desc';
  overdueSort: 'asc' | 'desc';
}

export const initialState: CalendarState = {
  showAll: false,
  selectedUser: null,
  selectedTab: 0,
  selectedView: 'calendar',
  upcomingSort: 'asc',
  overdueSort: 'desc'
};

export function calendarReducer(
  state = initialState,
  action: CalendarActions
): CalendarState {
  switch (action.type) {
    case CalendarActionTypes.ToggleShowAll: {
      return {
        ...state,
        showAll:
          action.payload === true || action.payload === false
            ? action.payload
            : !state.showAll
      };
    }

    case CalendarActionTypes.SetSelectedUser: {
      return {
        ...state,
        showAll: false,
        selectedUser: action.payload
      };
    }

    case CalendarActionTypes.SetTab: {
      return {
        ...state,
        selectedTab: action.payload
      };
    }

    case CalendarActionTypes.SetView: {
      return {
        ...state,
        selectedView: action.payload
      };
    }

    case CalendarActionTypes.SetUpcomingSort: {
      return {
        ...state,
        upcomingSort: action.payload
      };
    }

    case CalendarActionTypes.SetOverdueSort: {
      return {
        ...state,
        overdueSort: action.payload
      };
    }

    default:
      return state;
  }
}
