import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import {
  LoadSystemSettingsFailure,
  LoadSystemSettingsSuccess,
  SystemSettingsActionTypes,
  SystemSettingsActions
} from './system-settings.actions';

@Injectable()
export class SystemSettingsEffects {
  loadSystemSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SystemSettingsActionTypes.LoadSystemSettings),
      concatMap(() =>
        /** An EMPTY observable only emits completion. Replace with your own observable API request */
        EMPTY.pipe(
          map(data => new LoadSystemSettingsSuccess({ data })),
          catchError(error => of(new LoadSystemSettingsFailure({ error })))
        )
      )
    )
  );

  constructor(private actions$: Actions<SystemSettingsActions>) {}
}
