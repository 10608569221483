import { Injectable } from '@angular/core';
import { ServicesModule } from '../services.module';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CallType } from 'src/app/models/admin/call-type/call-type';

@Injectable({
  providedIn: ServicesModule
})
export class CallTypesService {
  private jpiUrl = environment.jpiUrl;
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get(`${this.jpiUrl}/callnotetypes`);
  }

  getById(callTypeId: number) {
    return this.http.get(`${this.jpiUrl}/callnotetypes/${callTypeId}`);
  }

  create(callType: CallType) {
    return this.http.post(`${this.jpiUrl}/callnotetypes`, callType);
  }

  update(callType: CallType) {
    return this.http.put(`${this.jpiUrl}/callnotetypes/${callType.id}`, callType);
  }

  delete(callTypeId: number) {
    return this.http.delete(`${this.jpiUrl}/callnotetypes/${callTypeId}`);
  }
}
