import { FileMetaData } from 'src/app/models/file-meta-data';
import { selectUsers } from '../../users/selectors/multi-select-users.selector';
import { RootState } from '../../store.reducer';
import { MemoizedSelector, createSelector } from '@ngrx/store';
import { GenericMap } from 'src/app/models/generic-map';
import { User } from 'src/app/models/admin/users/user';

export const selectPendingFileUploads = (state: RootState) =>
  state.expenses.pendingFileUploads;

export const pendingFileUploadsAsArray: MemoizedSelector<
  RootState,
  FileMetaData[]
> = createSelector(
  selectPendingFileUploads,
  selectUsers,
  (filesMap: GenericMap<FileMetaData>, users: GenericMap<User>): FileMetaData[] => {
    return Object.values(filesMap).length
      ? Object.values(filesMap)
          .filter(file => !!file)
          .sort((a, b) =>
            a.uploadDate < b.uploadDate ? 1 : a.uploadDate === b.uploadDate ? -1 : 0
          )
          .map(file => {
            const uploadedBy = users[file.uploadedBy as number];
            return {
              ...file,
              uploadedByItem: {
                name: uploadedBy.firstName + ' ' + uploadedBy.lastName,
                id: uploadedBy.id
              }
            };
          })
      : [];
  }
);
