import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  map,
  withLatestFrom,
  flatMap,
  mergeMap,
  tap,
  concatMap,
  catchError,
  switchMap
} from 'rxjs/operators';
import { InitActionTypes } from './init.actions';
import {
  LoginRedirect,
  SetInflightUrl,
  DeleteAuthObjects,
  OktaLogin,
  LoadApiSuccess,
  LoadJpiSuccess,
  LoadAuthComplete,
  LoadAuthFailure
} from '../auth/auth.actions';
import { RootState } from '../store.reducer';
import { Action, Store } from '@ngrx/store';
import { of, EMPTY, forkJoin } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';
import { ClearUsers, LoadUsers } from '../users/users.actions';
import { queryToObject } from 'src/app/utils/queryToObject';
import { convertToMap } from 'src/app/utils/convertToMap';
import { Feature } from 'src/app/models/features/feature';
import { AuthData } from '../auth/auth.reducer';
import { SetSelectedUser } from '../calendar/calendar.actions';

@Injectable()
export class InitEffects {
  initApp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InitActionTypes.InitializeApp),
      withLatestFrom(
        this.store$.select('auth', 'data'),
        this.store$.select('auth', 'oktaState')
      ),
      map((actionAndAuth) => ({
        action: actionAndAuth[0],
        authData: actionAndAuth[1],
        storeOktaState: actionAndAuth[2]
      })),
      switchMap(({ authData, storeOktaState }) => {
        const urlQueryString = window.location.search;
        const urlQueryObj =
          queryToObject<{ state: string; code: string }>(urlQueryString);
        const urlQueryOktaState = urlQueryObj.state;
        const urlQueryOktaCode = urlQueryObj.code;
        if (storeOktaState === urlQueryOktaState && !authData.api && !authData.jpi) {
          return this.authService
            .oktaJpiLogin(urlQueryOktaCode, window.location.origin)
            .pipe(
              switchMap((res) =>
                forkJoin([of(res), this.authService.oktaApiLogin(res.token)])
              ),
              map((resultArray) => {
                return { jpiResult: resultArray[0], apiResult: resultArray[1] };
              }),
              switchMap(({ apiResult, jpiResult }) => {
                if (!apiResult.UseNewTelenotes) {
                  this.authService.deleteCookies();
                  this.store$.dispatch(new DeleteAuthObjects());
                  window.location.href =
                    'https://telenotes.net/AccountInfo.aspx?SNID=' + apiResult.Snid;
                }
                if (!!apiResult.Role && !!apiResult.Role.Features) {
                  apiResult.Role.Features = convertToMap(
                    (apiResult.Role.Features as []).map((feature: Feature) => {
                      return {
                        ...feature,
                        Permissions: convertToMap(feature.Permissions as [], 'Id')
                      };
                    }),
                    'Id'
                  );
                }

                const data: AuthData = {
                  api: apiResult,
                  jpi: jpiResult
                };
                // clean default route
                if (!!data.api.DefaultUrl && data.api.DefaultUrl.startsWith('/')) {
                  data.api.DefaultUrl = data.api.DefaultUrl.substr(
                    1,
                    data.api.DefaultUrl.length
                  );
                }
                this.authService.deleteCookies();
                this.authService.setCookies(data);
                return [
                  new LoadApiSuccess(apiResult),
                  new LoadJpiSuccess(jpiResult),
                  new ClearUsers(),
                  new LoadAuthComplete()
                ];
              }),
              catchError((error) => {
                return of(new LoadAuthFailure(error));
              })
            );
        }
        if (!authData.api && !authData.jpi) {
          this.authService.deleteCookies();
          return [new LoginRedirect()];
        } else {
          this.store$.dispatch(new LoadUsers());
          if (!authData.api.UseNewTelenotes) {
            this.authService.deleteCookies();
            this.store$.dispatch(new DeleteAuthObjects());
            this.router.navigate(['/auth/login']);
            return EMPTY;
          }
          if (!this.cookies.get('AuthToken')) {
            this.authService.setCookies(authData);
          }
          this.store$.dispatch(
            new SetSelectedUser({
              id: authData.jpi.user.id,
              name: authData.jpi.user.firstName + ' ' + authData.jpi.user.lastName
            })
          );
          return EMPTY;
        }
      }),
      map((data: { type: string; payload?: any }) => {
        if (data.type === new LoadApiSuccess(data.payload).type) {
          return new LoadApiSuccess(data.payload);
        }
        if (data.type === new LoadJpiSuccess(data.payload).type) {
          return new LoadJpiSuccess(data.payload);
        }
        if (data.type === new ClearUsers().type) {
          return new ClearUsers();
        }
        if (data.type === new LoadAuthComplete().type) {
          return new LoadAuthComplete();
        }
        let inflightUrl = window.location.pathname + window.location.search;
        const urlQueryString = window.location.search;
        const urlQueryObj =
          queryToObject<{ state: string; code: string }>(urlQueryString);
        if (/login/.test(inflightUrl) || /auth/.test(inflightUrl) || !!urlQueryObj.code) {
          inflightUrl = '/';
        }
        return new SetInflightUrl(inflightUrl);
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store$: Store<RootState>,
    private authService: AuthService,
    private cookies: CookieService,
    private router: Router
  ) {}
}
