import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FieldType } from 'src/app/models/call-notes/field-type';
import { Observable } from 'rxjs/internal/Observable';
import { Subscription } from 'rxjs/internal/Subscription';
import { CompanyField } from 'src/app/models/companies/company-field';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import {
  DeleteCompanyField,
  CreateCompanyField,
  ClearCurrentCompanyField,
  UpdateCompanyField
} from 'src/app/store/system-settings/company-fields/company-fields.actions';

import { CloseDrawer } from 'src/app/store/layout/layout.actions';
import { DeleteObject } from 'src/app/store/undo-delete/undo-delete.actions';
import { LoadFieldTypes } from 'src/app/store/system-settings/field-types/field-types.actions';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDeleteComponent } from 'src/app/view/shared/confirm-delete/confirm-delete.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'tn-company-field-form',
  templateUrl: './company-field-form.component.html',
  styleUrls: ['./company-field-form.component.css']
})
export class CompanyFieldFormComponent implements OnInit, OnDestroy {
  companyFieldForm: FormGroup;
  $fieldTypes: Observable<FieldType[]>;
  $currentCompanyFieldSub: Subscription;
  $companyFieldsSub: Subscription;
  currentCompanyField;
  companyFields: CompanyField[];
  fieldTypes: [];
  constructor(private store: Store<RootState>, private dialog: MatDialog) {}

  ngOnInit() {
    this.store.dispatch(new LoadFieldTypes());
    this.$fieldTypes = this.store.select('fieldTypes', 'fieldTypes');
    this.$currentCompanyFieldSub = this.store
      .select('companyFields', 'currentCompanyField')
      .subscribe((field) => (this.currentCompanyField = field));
    this.$companyFieldsSub = this.store
      .select('companyFields', 'companyFields')
      .subscribe((fields) => (this.companyFields = fields));
    this.companyFieldForm = new FormGroup({
      name: new FormControl(this.currentCompanyField.name, [Validators.required]),
      type: new FormControl(this.currentCompanyField.type, [Validators.required]),
      id: new FormControl(this.currentCompanyField.id),
      fieldOrder: new FormControl(null),
      visible: new FormControl(null),
      standardField: new FormControl(null),
      nickname: new FormControl(null),
      agencyId: new FormControl(null),
      deleted: new FormControl(null),
      listId: new FormControl(null),
      sectionId: new FormControl(null),
      tabId: new FormControl(null)
    });
    if (this.currentCompanyField.standardField && !this.currentCompanyField.name) {
      this.companyFieldForm.patchValue({
        name: this.currentCompanyField.standardField.name
      });
    }
  }

  ngOnDestroy(): void {
    this.$companyFieldsSub.unsubscribe();
    this.$currentCompanyFieldSub.unsubscribe();
  }

  compareObjects(o1: any, o2: any) {
    if (!!o1 && !!o2) {
      return o1.name === o2.name && o1.id && o2.id;
    }
  }

  createButtonClicked() {
    // this.companyFieldForm.patchValue({ fieldOrder: this.companyFields.length + 1 });
    this.store.dispatch(new CreateCompanyField(this.companyFieldForm.value));
    this.store.dispatch(new CloseDrawer());
    this.store.dispatch(new ClearCurrentCompanyField());
  }

  saveButtonClicked() {
    this.store.dispatch(new UpdateCompanyField(this.companyFieldForm.value));
    this.store.dispatch(new CloseDrawer());
    this.store.dispatch(new ClearCurrentCompanyField());
  }

  cancelButtonClicked() {
    this.store.dispatch(new CloseDrawer());
    this.store.dispatch(new ClearCurrentCompanyField());
  }

  deleteButtonClicked() {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {});

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((willDelete: boolean) => {
        if (willDelete) {
          this.store.dispatch(new DeleteCompanyField(this.currentCompanyField.id));
          this.store.dispatch(new CloseDrawer());
          this.store.dispatch(new ClearCurrentCompanyField());
        }
      });
  }
}
