import { Injectable } from '@angular/core';
import { ServicesModule } from '../services.module';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: ServicesModule
})
export class DmsService {
  private jpiUrl = environment.jpiUrl;

  constructor(private http: HttpClient) {}

  getMagentoDmsLinkedCompany(payload) {
    return this.http.get(`${this.jpiUrl}/dms/${payload.customerId}/${payload.date}`);
  }
}
