import { Component, HostListener, Input } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { ToolbarService } from '../../../shared/services/toolbar.service';
import { CallNoteV2 } from '../../../shared/models/call-notes/call-note-V2';
import { ContactV2 } from '../../../shared/models/contacts/contact-V2';

@Component({
    selector: 'tn-searchResults',
    templateUrl: './search-results.component.html',
    styleUrls: ['../companies.scss']
})
export class SearchResultsComponent {
    key: any;
    selectedIndex: number;
    selectedItems: number;
    companyId: number;
    showContactPopup: boolean;
    selectedContact: ContactV2;
    showCallNotePopup: boolean;
    selectedCallNote: CallNoteV2;
    @Input() searchResults: any[];

    constructor(private _router: Router, private _route: ActivatedRoute, private _toolbar: ToolbarService) {
        this.selectedIndex = 0;
        this.selectedItems = 0;
    }

    @HostListener('document:keyup', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        this.key = event.key;
        if (this.key == 'Enter') {
            switch (this.selectedItems) {
                case 0:
                    this._router.navigate(['/companies', this.searchResults[this.selectedItems].items[this.selectedIndex].id]);
                    break;
                case 1:
                    this._router.navigate(['/companies', this.searchResults[this.selectedItems].items[this.selectedIndex].account]);
                    break;
                case 2:
                    break;
            }
        } else {
            switch (this.selectedItems) {
                case 0: // companies are currently selected
                    if (this.key == 'ArrowUp') {
                        if (this.selectedIndex <= 0) {
                            // needs to loop around to call notes
                            if (this.searchResults[2] && this.searchResults[2].items && this.searchResults[2].items.length) {
                                this.selectedItems = 2;
                                this.selectedIndex = this.searchResults[2].items.length - 1;
                            } else if (this.searchResults[1] && this.searchResults[1].items && this.searchResults[1].items.length) { // needs to loop around to contacts
                                this.selectedItems = 1;
                                this.selectedIndex = this.searchResults[1].items.length - 1;
                            } else { // needs to loop to end of companies
                                this.selectedItems = 0;
                                this.selectedIndex = this.searchResults[0].items.length - 1;
                            }
                        } else {
                            this.selectedIndex--;
                        }
                    } else if (this.key == 'ArrowDown') {
                        if (this.selectedIndex >= (this.searchResults[0].items.length - 1)) {
                            this.selectedIndex = 0;
                            if (this.searchResults[1] && this.searchResults[1].items && this.searchResults[1].items.length) { // needs to loop around to contacts
                                this.selectedItems = 1;
                            } else if (this.searchResults[2] && this.searchResults[2].items && this.searchResults[2].items.length) { // needs to loop to call notes
                                this.selectedItems = 2;
                            } else { // needs to loop to begining of companies
                                this.selectedItems = 0;
                            }
                        } else {
                            this.selectedIndex++;
                        }
                    }
                    break;
                case 1: // contacts are currently selected
                    if (this.key == 'ArrowUp') {
                        if (this.selectedIndex <= 0) {
                            // needs to loop around to companies
                            if (this.searchResults[0] && this.searchResults[0].items && this.searchResults[0].items.length) {
                                this.selectedItems = 0;
                                this.selectedIndex = this.searchResults[0].items.length - 1;
                            } else if (this.searchResults[2] && this.searchResults[2].items && this.searchResults[2].items.length) { // needs to loop around to call notes
                                this.selectedItems = 2;
                                this.selectedIndex = this.searchResults[2].items.length - 1;
                            } else { // needs to loop to end of contacts
                                this.selectedItems = 1;
                                this.selectedIndex = this.searchResults[1].items.length - 1;
                            }
                        } else {
                            this.selectedIndex--;
                        }
                    } else if (this.key == 'ArrowDown') {
                        if (this.selectedIndex >= (this.searchResults[1].items.length - 1)) {
                            this.selectedIndex = 0;
                            if (this.searchResults[2] && this.searchResults[2].items && this.searchResults[2].items.length) { // needs to loop around to call notes
                                this.selectedItems = 2;
                            } else if (this.searchResults[0] && this.searchResults[0].items && this.searchResults[0].items.length) { // needs to loop to companies
                                this.selectedItems = 0;
                            } else { // needs to loop to begining of contacts
                                this.selectedItems = 1;
                            }
                        } else {
                            this.selectedIndex++;
                        }
                    }
                    break;
                case 2: // call notes are selected
                    if (this.key == 'ArrowUp') {
                        if (this.selectedIndex <= 0) {
                            // needs to loop around to contacts
                            if (this.searchResults[1] && this.searchResults[1].items && this.searchResults[1].items.length) {
                                this.selectedItems = 1;
                                this.selectedIndex = this.searchResults[1].items.length - 1;
                            } else if (this.searchResults[0] && this.searchResults[0].items && this.searchResults[0].items.length) { // needs to loop around to companies
                                this.selectedItems = 0;
                                this.selectedIndex = this.searchResults[0].items.length - 1;
                            } else { // needs to loop to end of call notes
                                this.selectedItems = 2;
                                this.selectedIndex = this.searchResults[2].items.length - 1;
                            }
                        } else {
                            this.selectedIndex--;
                        }
                    } else if (this.key == 'ArrowDown') {
                        if (this.selectedIndex >= (this.searchResults[2].items.length - 1)) {
                            this.selectedIndex = 0;
                            if (this.searchResults[0] && this.searchResults[0].items && this.searchResults[0].items.length) { // needs to loop around to companies
                                this.selectedItems = 0;
                            } else if (this.searchResults[1] && this.searchResults[1].items && this.searchResults[1].items.length) { // needs to loop to contacts
                                this.selectedItems = 1;
                            } else { // needs to loop to begining of call notes
                                this.selectedItems = 2;
                            }
                        } else {
                            this.selectedIndex++;
                        }
                    }
                    break;
            }
        }
    }

    calculateNarrative(data: CallNoteV2) {
        for (const field of data.fields) {
            if ((<any>field).isHit && field.value && field.value.length > 0) {
                return field.value;
            }
        }
        return '';
    }

    navigateToCompany(CompanyId: number): void {
        this._route.params.forEach((params: Params) => {
            if (isNaN(params["id"])) {
                this._router.navigate(['companies']);
            }
            this.companyId = params["id"];
        });
        if (!this.companyId) {
            this._router.navigate(['/companies', CompanyId]);
        } else if (CompanyId == this.companyId) {
            this._toolbar.announceNewSearchTerm("");
        } else {
            this._router.navigate(['/companies', CompanyId]);
        }
    }

    openContact(contact: any) {
        this.selectedContact = contact;
        this.showContactPopup = true;
    }

    openCallNote(callNote: any) {
        this.selectedCallNote = callNote;
        this.showCallNotePopup = true;
    }

    openCompanyList(): void {
        this._router.navigate(['/companies/list'], { queryParams: { companyNameAddress: this._toolbar.searchTerm } });
        this._toolbar.announceNewSearchTerm(null);
    }

    openContactList(): void {
        this._router.navigate(['/companies/list'], { queryParams: { contactName: this._toolbar.searchTerm } });
        this._toolbar.announceNewSearchTerm(null);
    }

    openCallNoteList(): void {
        this._router.navigate(['/activity/list'], { queryParams: { narrative: this._toolbar.searchTerm } });
        this._toolbar.announceNewSearchTerm(null);
    }
}

