import { Injectable } from '@angular/core';

import { AuthenticationService } from './authentication.service';

@Injectable()
export class ProgressBoardService {
	constructor(private authService: AuthenticationService) {
	}

	clearServiceData(): void {

	}

	getCompletedItems(): Promise<any[]> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/progressboard/complete', '', 'get').then(function (result: any) {
			return self.parseProgressBoardCsv(result);
		});
	}

	getInProgressItems(): Promise<any[]> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/progressboard/inprogress', '', 'get').then(function (result: any) {
			return self.parseProgressBoardCsv(result);
		});
	}

	parseProgressBoardCsv(csvString: string): any[] {
		const temp: any[] = csvString.split('\n');
		const items: any[] = [];

		let i = 1;
		for (i = 1; i < temp.length; i++) {
			const data: any[] = temp[i].split('\xa7');

			if (data.length > 1) {
				const item: any = {};

				item.Id = data[0];
				item.EnteredDate = new Date(data[1]);
				item.Description = data[2];
				item.EstDeliveryDate = new Date(data[3]);
				item.Complete = data[4];
				item.CompleteDate = new Date(data[5]);
				item.Platform = data[6];
				item.Module = data[7];

				items.push(item);
			}
		}

		return items;
	}
}
