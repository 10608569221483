import {
  ProjectLabelsIndividiualActions,
  ProjectLabelsIndividiualActionTypes
} from './project-labels-individiual.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { Label } from 'src/app/models/label';

export interface ProjectLabelsIndividualState {
  pending: boolean;
  error: HttpErrorResponse;
  data: { [id: number]: Label };
  selectedProjectLabelIndividualId: number;
}

export const initialState: ProjectLabelsIndividualState = {
  pending: false,
  data: {},
  error: undefined,
  selectedProjectLabelIndividualId: undefined
};

export function projectLabelsIndividualReducer(
  state = initialState,
  action: ProjectLabelsIndividiualActions
): ProjectLabelsIndividualState {
  switch (action.type) {
    case ProjectLabelsIndividiualActionTypes.SelectProjectLabelIndividualId:
      return {
        ...state,
        selectedProjectLabelIndividualId: action.payload
      };

    case ProjectLabelsIndividiualActionTypes.ClearProjectLabelIndividualId:
      return {
        ...state,
        selectedProjectLabelIndividualId: undefined
      };

    case ProjectLabelsIndividiualActionTypes.LoadProjectLabelsIndividiuals:
      return {
        ...state,
        pending: true
      };

    case ProjectLabelsIndividiualActionTypes.LoadProjectLabelsIndividiualsSuccess:
      return {
        ...state,
        pending: false,
        error: undefined,
        data: action.payload
      };

    case ProjectLabelsIndividiualActionTypes.LoadProjectLabelsIndividiualsFailure:
      return {
        ...state,
        pending: false,
        data: {},
        error: action.payload
      };

    case ProjectLabelsIndividiualActionTypes.CreateProjectLabelsIndividual:
      return {
        ...state,
        pending: true
      };

    case ProjectLabelsIndividiualActionTypes.CreateProjectLabelsIndividualSuccess:
      return {
        ...state,
        pending: false
      };

    case ProjectLabelsIndividiualActionTypes.CreateProjectLabelsIndividualFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case ProjectLabelsIndividiualActionTypes.UpdateProjectLabelsIndividual:
      return {
        ...state,
        pending: true
      };

    case ProjectLabelsIndividiualActionTypes.UpdateProjectLabelsIndividualSuccess:
      return {
        ...state,
        pending: false
      };

    case ProjectLabelsIndividiualActionTypes.UpdateProjectLabelsIndividualFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case ProjectLabelsIndividiualActionTypes.DeleteProjectLabelsIndividual:
      return {
        ...state,
        pending: true
      };

    case ProjectLabelsIndividiualActionTypes.DeleteProjectLabelsIndividualSuccess:
      return {
        ...state,
        pending: false,
        selectedProjectLabelIndividualId: undefined
      };

    case ProjectLabelsIndividiualActionTypes.DeleteProjectLabelsIndividualFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    default:
      return state;
  }
}
