<h2 mat-dialog-title>Select Default Landing Page</h2>
<mat-dialog-content>
  <div [style.maxWidth]="'400px'" *ngIf="!landingPageSelected">
    You can now set your default page! Right now it is set to dashboard, but feel free to
    change it to whatever you like below. You can always click on
    <strong>Default Page</strong> in the upper-right options menu to change it whenever
    you'd like.
  </div>
  <mat-selection-list #landingPage [multiple]="false" [(ngModel)]="selectedOptions">
    <mat-list-option *ngFor="let route of links" [value]="route.path">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <mat-icon>{{ route.icon }}</mat-icon>
        <span>{{ route.title }}</span>
      </div>
    </mat-list-option>
  </mat-selection-list>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-raised-button
    color="primary"
    [mat-dialog-close]="selectedOptions[0]"
    cdkFocusInitial
  >
    OK
  </button>
</mat-dialog-actions>
