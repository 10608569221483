import { Action } from '@ngrx/store';

export enum InitActionTypes {
  InitializeApp = '[Init] Initialize App'
}

export class InitializeApp implements Action {
  readonly type = InitActionTypes.InitializeApp;
}

export type InitActions = InitializeApp;
