import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Expense } from 'src/app/models/activities/expense';

@Injectable({
  providedIn: 'root'
})
export class ExpenseService {
  private jpiUrl = environment.jpiUrl;
  constructor(private http: HttpClient) {}

  // Expenses

  getAll(companyId?: number, dateRange?: { begin: string; end: string }) {
    const params = {
      companyId: null,
      startDate: null,
      endDate: null
    };
    if (!!companyId) {
      params.companyId = companyId;
    } else {
      delete params.companyId;
    }
    if (!!dateRange && dateRange.begin && !!dateRange.end) {
      params.startDate = dateRange.begin;
      params.endDate = dateRange.end;
    } else {
      delete params.startDate;
      delete params.endDate;
    }
    return this.http.get(`${this.jpiUrl}/expenses/v2/byDateRange`, { params });
  }

  getById(expenseId: number) {
    return this.http.get(`${this.jpiUrl}/expenses/v2/${expenseId}`);
  }

  create(expense: Expense) {
    return this.http.post(`${this.jpiUrl}/expenses/v2`, expense);
  }

  update(expense: Expense) {
    return this.http.put(`${this.jpiUrl}/expenses/v2/${expense.id}`, expense);
  }

  delete(expenseId: number) {
    return this.http.delete(`${this.jpiUrl}/expenses/v2/${expenseId}`);
  }
}
