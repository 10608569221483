import { Action } from '@ngrx/store';

export enum SystemSettingsActionTypes {
  LoadSystemSettings = '[SystemSettings] Load SystemSettings',
  LoadSystemSettingsSuccess = '[SystemSettings] Load SystemSettings Success',
  LoadSystemSettingsFailure = '[SystemSettings] Load SystemSettings Failure'
}

export class LoadSystemSettings implements Action {
  readonly type = SystemSettingsActionTypes.LoadSystemSettings;
}

export class LoadSystemSettingsSuccess implements Action {
  readonly type = SystemSettingsActionTypes.LoadSystemSettingsSuccess;
  constructor(public payload: { data: any }) {}
}

export class LoadSystemSettingsFailure implements Action {
  readonly type = SystemSettingsActionTypes.LoadSystemSettingsFailure;
  constructor(public payload: { error: any }) {}
}

export type SystemSettingsActions =
  | LoadSystemSettings
  | LoadSystemSettingsSuccess
  | LoadSystemSettingsFailure;
