import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CallNote } from 'src/app/models/call-notes/call-note';
import { CallNoteField } from 'src/app/models/call-notes/call-note-field';
import { Contact } from 'src/app/models/contacts/contact';
import { CallCountNotesRequest } from 'src/app/models/dashboard/call-notes/call-count-notes-request';
import { LoadCallNotes } from 'src/app/store/call-notes/call-notes.actions';
import {
  ClearCallNotes,
  LoadCallFieldNotes,
  LoadCallTypeNotes,
  LoadCallUserNotes
} from 'src/app/store/dashboard/dashboard.actions';
import { CloseLeftDrawer } from 'src/app/store/layout/layout.actions';
import { RootState } from 'src/app/store/store.reducer';

@Component({
  selector: 'tn-call-notes-slide-out',
  templateUrl: './call-notes-slide-out.component.html',
  styleUrls: ['./call-notes-slide-out.component.scss']
})
export class CallNotesSlideOutComponent implements OnInit {
  @Input() data: CallCountNotesRequest;
  $pending: Observable<boolean>;
  $notes: Observable<CallNote[]>;
  searchText = '';

  constructor(private store: Store<RootState>) {}

  ngOnInit() {
    if (!!this.data.labelIds && this.data.labelIds.length > 0) {
      let filters = [
        {
          operand1: 'labels.id',
          operator: 'IS_ANY_OF',
          operand2: this.data.labelIds
        },
        {
          operand1: 'createdDate',
          operator: 'IS_BETWEEN',
          operand2: [this.data.fromDate, this.data.toDate]
        }
      ];
      if (this.data.repIds.length > 0) {
        filters.push({
          operand1: 'assignedToId',
          operator: 'IS_ANY_OF',
          operand2: this.data.repIds
        });
      }
      this.store.dispatch(
        new LoadCallNotes({
          filter: {
            filters: filters
          },
          params: {
            limit: '10000',
            skip: '0',
            order: 'createdDate'
          }
        })
      );
    }
    if (!!this.data.callNoteIds && this.data.callNoteIds.length > 0) {
      this.store.dispatch(new LoadCallTypeNotes(this.data));
    }
    if (!!this.data.callTypeIds && this.data.callTypeIds.length > 0) {
      this.store.dispatch(new LoadCallTypeNotes(this.data));
    }
    if (!!this.data.fieldIds && this.data.fieldIds.length > 0) {
      this.store.dispatch(new LoadCallFieldNotes(this.data));
    }
    if (
      (!this.data.callTypeIds &&
        !this.data.fieldIds &&
        !this.data.callNoteIds &&
        !this.data.labelIds) ||
      (!!this.data.callTypeIds &&
        !!this.data.fieldIds &&
        !!this.data.callNoteIds &&
        this.data.callTypeIds.length === 0 &&
        this.data.fieldIds.length === 0)
    ) {
      this.store.dispatch(new LoadCallUserNotes(this.data));
    }
    this.$pending = this.store.select(
      'dashboard',
      'data',
      'callNotes',
      'notes',
      'pending'
    );
    this.$notes = this.store
      .select('dashboard', 'data', 'callNotes', 'notes', 'data')
      .pipe(
        map((callNotes: CallNote[]) => {
          return callNotes.map((callNote) => ({
            ...callNote,
            followUp: {
              ...callNote.followUp,
              assignedTo: !!callNote.followUp.assignedTo?.id
                ? {
                    id: callNote.followUp.assignedTo?.id,
                    mailbox: callNote.followUp.assignedTo?.mailbox,
                    name:
                      callNote.followUp.assignedTo?.firstName +
                      ' ' +
                      callNote.followUp.assignedTo?.lastName
                  }
                : null
            },
            fields: callNote.fields
              .sort(
                (a: CallNoteField, b: CallNoteField) =>
                  a.field.callnoteOrder - b.field.callnoteOrder
              )
              .map((field: CallNoteField) => {
                return {
                  name: field.field.name,
                  id: field.field.id,
                  value: field.value,
                  dropdownId: field.dropdownId,
                  type: field.field.type
                } as CallNoteField;
              }),
            companyName: callNote.company.name,
            contacts: callNote.contacts.map(
              (contact) =>
                ({
                  id: contact.id,
                  name: contact.firstName + ' ' + contact.lastName
                } as Contact)
            ),
            createdBy: {
              mailbox: callNote.createdBy.mailbox,
              id: callNote.createdBy.id,
              name: callNote.createdBy.firstName + ' ' + callNote.createdBy.lastName
            }
          }));
        })
      );
  }

  closeLeftDrawer() {
    this.store.dispatch(new CloseLeftDrawer());
    this.store.dispatch(new ClearCallNotes());
  }
}
