<form [formGroup]="itemNameForm" class="item-name-form">
  <mat-form-field class="example-full-width">
    <mat-label>Name</mat-label>
    <input
      [value]="currentItemName?.name"
      formControlName="name"
      matInput
      type="string"
    />
  </mat-form-field>
  <div fxLayout="row-reverse" fxLayoutAlign="space-between center">
    <div fxLayout="row-reverse">
      <button
        [disabled]="itemNameForm.pristine || itemNameForm.invalid"
        *ngIf="!currentItemName?.id"
        mat-raised-button
        color="primary"
        mat-button
        class="button-container"
        (click)="save()"
      >
        Save
      </button>
      <button
        [disabled]="itemNameForm.pristine || itemNameForm.invalid"
        *ngIf="currentItemName?.id"
        mat-raised-button
        mat-button
        color="primary"
        class="button-container"
        (click)="update()"
      >
        Save
      </button>
      <button
        preventDefaultAction
        class="button-container"
        mat-raised-button
        color="white"
        mat-button
        (click)="cancel()"
      >
        Cancel
      </button>
    </div>
    <button
      preventDefaultAction
      *ngIf="currentItemName?.id"
      mat-raised-button
      color="warn"
      mat-button
      (click)="delete()"
    >
      Delete
    </button>
  </div>
</form>
