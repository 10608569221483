import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
  concatMap
} from 'rxjs/operators';
import { of, Observable, concat, EMPTY, forkJoin } from 'rxjs';
import {
  LoadExpensesFailure,
  LoadExpensesSuccess,
  ExpensesActionTypes,
  ExpensesActions,
  CreateExpense,
  CreateExpenseSuccess,
  CreateExpenseFailure,
  DeleteExpense,
  DeleteExpenseSuccess,
  DeleteExpenseFailure,
  UpdateExpense,
  UpdateExpenseSuccess,
  UpdateExpenseFailure,
  LoadExpenses,
  LoadExpenseTypesSuccess,
  LoadExpenseTypesFailure,
  ClearExpenseId,
  UploadExpenseFileSuccess,
  UploadExpenseFileFailure,
  DeleteExpenseFileSuccess,
  DeleteExpenseFileFailure,
  SelectExpenseId,
  ClearExpenseFile,
  ClearAllExpenseFiles,
  UploadFileOnNewExpenseSuccess,
  UploadFileOnNewExpense,
  ClearExpenseFileFailure,
  ClearAllExpenseFilesSuccess,
  ClearExpenseFileSuccess,
  LoadExpensesTableColumnsSuccess,
  LoadExpensesTableColumnsFailure,
  LoadSelectedExpensesTableColumns,
  LoadSelectedExpensesTableColumnsSuccess
} from './expenses.actions';
import { Expense } from 'src/app/models/activities/expense';
import { HttpErrorResponse } from '@angular/common/http';
import { ExpenseService } from 'src/app/services/activities/expense.service';
import { Action, Store } from '@ngrx/store';
import {
  CloseDrawer,
  SetLoadingBarVisibility,
  OpenSnackbar,
  OpenErrorSnackbar
} from '../layout/layout.actions';
import { ExpenseTypesService } from 'src/app/services/system-settings/expens-types/expense-types.service';
import { ExpenseType } from '../../models/activities/expense-type';
import { convertToMap } from 'src/app/utils/convertToMap';
import { RootState } from '../store.reducer';
import { selectSelectedExpense } from './selectors/selected-expense.selector';
import { FilesService } from 'src/app/services/files/files.service';
import { FileMetaData } from 'src/app/models/file-meta-data';
import { GenericMap } from 'src/app/models/generic-map';
import { ExpenseTableColumnsData } from './expenseColumns.data';

@Injectable()
export class ExpensesEffects {
  constructor(
    private expenseTypesService: ExpenseTypesService,
    private expenseService: ExpenseService,
    private actions$: Actions<ExpensesActions>,
    private store$: Store<RootState>,
    private filesService: FilesService
  ) {}

  loadExpenses$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ExpensesActionTypes.LoadExpenses),
      withLatestFrom(this.store$.select('expenses', 'queryParams', 'daterange')),
      mergeMap(([action, dateRange]: [LoadExpenses, { begin: string; end: string }]) => {
        const companyId = !!action.payload ? action.payload.companyId : undefined;
        const queryDateRange: { begin: string; end: string } = !!action.payload
          ? {
              begin: action.payload.startDate,
              end: action.payload.endDate
            }
          : dateRange;
        return this.expenseService.getAll(companyId, queryDateRange).pipe(
          map((expenses: Expense[]) => {
            return new LoadExpensesSuccess(convertToMap(expenses, 'id'));
          }),
          catchError((error: HttpErrorResponse) => of(new LoadExpensesFailure(error)))
        );
      })
    )
  );

  createExpense$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ExpensesActionTypes.CreateExpense),
      map((action: CreateExpense) => action.payload),
      withLatestFrom(this.store$.select('expenses', 'pendingFileUploads')),
      mergeMap(([expense, filesMap]) => {
        expense.files = Object.values(filesMap);
        return this.expenseService.create(expense).pipe(
          map((newExpense: Expense) => new CreateExpenseSuccess(newExpense)),
          catchError((error: HttpErrorResponse) => of(new CreateExpenseFailure(error)))
        );
      })
    )
  );

  updateExpense$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ExpensesActionTypes.UpdateExpense),
      map((action: UpdateExpense) => action.payload),
      mergeMap((expense: Expense) =>
        this.expenseService.update(expense).pipe(
          map((updatedExpense: Expense) => new UpdateExpenseSuccess(updatedExpense)),
          catchError((error: HttpErrorResponse) => of(new UpdateExpenseFailure(error)))
        )
      )
    )
  );

  deleteExpense$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ExpensesActionTypes.DeleteExpense),
      map((action: DeleteExpense) => action.payload),
      mergeMap((expenseId: number) =>
        this.expenseService.delete(expenseId).pipe(
          map(() => new DeleteExpenseSuccess(expenseId)),
          catchError((error: HttpErrorResponse) => of(new DeleteExpenseFailure(error)))
        )
      )
    )
  );

  reloadExpenses$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ExpensesActionTypes.DeleteExpenseSuccess,
        ExpensesActionTypes.UpdateExpenseSuccess,
        ExpensesActionTypes.CreateExpenseSuccess
      ),
      map(() => new LoadExpenses())
    )
  );

  onExpenseSaveOrCancel$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ExpensesActionTypes.CreateExpenseSuccess,
        ExpensesActionTypes.UpdateExpenseSuccess,
        ExpensesActionTypes.CancelExpense
      ),
      switchMap(() => [new ClearExpenseId(), new CloseDrawer()])
    )
  );

  clearAllFilesOnCancel$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ExpensesActionTypes.CancelExpense),
      map(() => new ClearAllExpenseFiles())
    )
  );

  // expense types

  loadExpenseTypes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ExpensesActionTypes.LoadExpenseTypes),
      mergeMap(() =>
        this.expenseTypesService.getAll().pipe(
          map((expenseTypes: ExpenseType[]) => new LoadExpenseTypesSuccess(expenseTypes)),
          catchError((error: HttpErrorResponse) => of(new LoadExpenseTypesFailure(error)))
        )
      )
    )
  );

  uploadExpenseFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExpensesActionTypes.UploadExpenseFile),
      tap(() => this.store$.dispatch(new SetLoadingBarVisibility(true))),
      withLatestFrom(this.store$.select(selectSelectedExpense)),
      map((actionAndStore) => ({
        file: actionAndStore[0].payload,
        expense: actionAndStore[1]
      })),
      mergeMap(({ file, expense }) => {
        return this.filesService.uploadFile(file).pipe(
          switchMap((res: FileMetaData) => [
            new SetLoadingBarVisibility(false),
            new UploadExpenseFileSuccess({ fileMetaData: res, expense }),

            new OpenSnackbar({
              duration: 5000,
              message: 'Uploaded file successfully',
              opened: true
            })
          ]),
          catchError((error: HttpErrorResponse) =>
            of(new UploadExpenseFileFailure(error))
          )
        );
      })
    )
  );

  uploadExpenseFileSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExpensesActionTypes.UploadExpenseFileSuccess),
      map((action: UploadExpenseFileSuccess) => action.payload),
      concatMap(({ fileMetaData, expense }) => {
        return this.expenseService
          .update({
            ...expense,
            files: [...expense.files, { id: fileMetaData.id } as FileMetaData]
          })
          .pipe(
            map(() => new LoadExpenses()),
            catchError((error: HttpErrorResponse) => of(new UpdateExpenseFailure(error)))
          );
      })
    )
  );

  uploadExpenseFileFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExpensesActionTypes.UploadExpenseFileFailure),
      map((action) => action.payload),
      switchMap((error: HttpErrorResponse) => {
        return [
          new SetLoadingBarVisibility(false),
          new OpenErrorSnackbar({
            duration: 10000,
            message: error.status === 413 ? 'Unsupported tile type' : error.message,
            opened: true
          })
        ];
      })
    )
  );

  deleteExpenseFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExpensesActionTypes.DeleteExpenseFile),
      withLatestFrom(this.store$.select(selectSelectedExpense)),
      map((actionAndStore) => ({
        fileId: actionAndStore[0].payload,
        expense: actionAndStore[1]
      })),
      concatMap(({ fileId, expense }) => {
        return this.filesService.deleteFile(fileId).pipe(
          map((res: FileMetaData) => new DeleteExpenseFileSuccess({ fileId, expense })),
          catchError((error) => of(new DeleteExpenseFileFailure(error)))
        );
      })
    )
  );

  deleteExpenseFileSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExpensesActionTypes.DeleteExpenseFileSuccess),
      map((action: DeleteExpenseFileSuccess) => action.payload),
      concatMap(({ fileId, expense }) => {
        return this.expenseService
          .update({
            ...expense,
            files: expense.files.filter((file) => file.id !== fileId)
          })
          .pipe(
            map(() => new LoadExpenses()),
            catchError((error: HttpErrorResponse) => of(new UpdateExpenseFailure(error)))
          );
      })
    )
  );

  uploadNewExpenseFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExpensesActionTypes.UploadFileOnNewExpense),
      tap(() => this.store$.dispatch(new SetLoadingBarVisibility(true))),
      mergeMap((action: UploadFileOnNewExpense) => {
        return this.filesService.uploadFile(action.payload).pipe(
          switchMap((res: FileMetaData) => [
            new SetLoadingBarVisibility(false),
            new UploadFileOnNewExpenseSuccess(res),
            new OpenSnackbar({
              duration: 5000,
              message: 'Uploaded file successfully',
              opened: true
            })
          ]),
          catchError((error: HttpErrorResponse) =>
            of(new UploadExpenseFileFailure(error))
          )
        );
      })
    )
  );

  uploadNewExpenseFileFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExpensesActionTypes.UploadExpenseFileFailure),
      map((action) => action.payload),
      switchMap((error: HttpErrorResponse) => {
        return [
          new SetLoadingBarVisibility(false),
          new OpenErrorSnackbar({
            duration: 10000,
            message: error.status === 413 ? 'Unsupported tile type' : error.message,
            opened: true
          })
        ];
      })
    )
  );

  clearExpenseFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExpensesActionTypes.ClearExpenseFile),
      map((action) => action.payload),
      concatMap((fileId: number) => {
        return this.filesService.deleteFile(fileId).pipe(
          map(() => new ClearExpenseFileSuccess(fileId)),
          catchError((error: HttpErrorResponse) => of(new ClearExpenseFileFailure(error)))
        );
      })
    )
  );

  clearAllExpenseFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExpensesActionTypes.ClearAllExpenseFiles),
      withLatestFrom(this.store$.select('expenses', 'pendingFileUploads')),
      map(([_, filesMap]) => filesMap),
      map((files: GenericMap<FileMetaData>) =>
        Object.values(files)
          .filter((file) => !!file)
          .map((file) => file.id)
      ),
      concatMap((fileIds: number[]) => {
        return forkJoin(
          fileIds.map((fileId: number) =>
            this.filesService
              .deleteFile(fileId)
              .pipe(catchError((error) => of(new ClearExpenseFileFailure(error))))
          )
        ).pipe(map(() => new ClearAllExpenseFilesSuccess()));
      })
    )
  );

  //expense columns

  loadExpenseTableColumns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExpensesActionTypes.LoadExpensesTableColumns),
      concatMap(() => {
        return of(ExpenseTableColumnsData).pipe(
          map((res: string[]) => {
            return new LoadExpensesTableColumnsSuccess(res);
          }),
          catchError((error) => of(new LoadExpensesTableColumnsFailure(error)))
        );
      })
    )
  );

  loadSelectedExpenseTableColumns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExpensesActionTypes.LoadSelectedExpensesTableColumns),
      map((action: LoadSelectedExpensesTableColumns) => action.payload),
      mergeMap((columns) => {
        return of(columns).pipe(
          map((res: string[]) => {
            return new LoadSelectedExpensesTableColumnsSuccess(res);
          }),
          catchError((error) => of(new LoadExpensesTableColumnsFailure(error)))
        );
      })
    )
  );
}
