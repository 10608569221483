import { Injectable } from '@angular/core';

import { AuthenticationService } from '../../shared/services/authentication.service';
import { User } from '../models/admin/users/user';
import { Contact } from '../models/contacts/contact';

@Injectable()
export class AgentService {
	private agents: { [companyId: number]: Contact[] };

	constructor(private authService: AuthenticationService) {
		this.agents = {};
	}

	clearServiceData(): void {
		delete this.agents;
		this.agents = {};
	}
	// new service methods
	getAllUsers(): Promise<User[]> {
		return this.authService.makeRequest('/api-v1.2.1/admin/users', null, 'get').then(function (result: any) {
			return JSON.parse(result);
		});
	}

	getAllUsersForClient(clientId: number): Promise<User[]> {
		return this.authService.makeRequest('/api-v1.2.1/admin/users?agencyId=' + clientId, null, 'get').then(function (result) {
			return JSON.parse(result);
		});
	}

	// old service methods
	getAllAgents(): Promise<Contact[]> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.2/agent/getcsv', '', 'get')
			.then(function (result: any) {
				const agents: Contact[] = self.parseAgentCsv(result);
				return agents;
			});
	}

	addAgentForCompany(companyId: number, agent: Contact): Promise<boolean> {
		const request: any = {};
		request.CompanyId = companyId;
		request.Agent = agent;
		request.Agent.AgentMailBoxId = agent.MailBox;
		return this.authService.makeRequest('/api-v1.2.1/agent/addAssigned', request, 'post').then(function (result) {
			return true;
		});
	}

	updateAgentForCompany(companyId: number, agent: Contact): Promise<boolean> {
		const request: any = {};
		const tempAgent = JSON.parse(JSON.stringify(agent));
		if (tempAgent.CommissionSplit) {
			tempAgent.CommissionSplit = tempAgent.CommissionSplit / 100;
		}
		request.CompanyId = companyId;
		request.Agent = tempAgent;
		request.Agent.AgentMailBoxId = agent.MailBox;
		return this.authService.makeRequest('/api-v1.2.1/agent/updateAssigned', request, 'post').then(function (result) {
			return true;
		});
	}

	deleteAgentForCompany(companyId: number, agent: Contact): Promise<boolean> {
		const request: any = {};
		request.CompanyId = companyId;
		request.Agent = agent;
		request.Agent.AgentMailBoxId = agent.MailBox;
		return this.authService.makeRequest('/api-v1.2.1/agent/deleteAssigned', request, 'post').then(function (result) {
			return true;
		});
	}

	getAgentsForCompany(companyId: number): Promise<Contact[]> {
		const self = this;
		if (this.agents[companyId]) {
			return Promise.resolve(this.agents[companyId]);
		}

		// should only reach this point if the company was not found in the list the service already has
		return this.authService.makeRequest('/api-v1.2.1/agent/getByCompanyId?id=' + companyId, '', 'get')
			.then(function (result: any) {
				const agents: Contact[] = self.parseAgentCsv(result);
				self.agents[companyId] = agents;
				return agents;
			});
	}

	parseAgentCsv(csvString: string): Contact[] {
		const temp: any[] = csvString.split('\n');
		const agents: Contact[] = [];

		let i = 1;
		for (i = 1; i < temp.length; i++) {
			const data: any[] = temp[i].split('\xa7');

			if (data.length > 1) {
				const agent: Contact = <Contact>{};

				agent.ContactId = parseInt(data[0]);
				agent.MailBox = data[1];
				agent.FirstName = data[2];
				agent.LastName = data[3];
				agent.Address1 = data[4];
				agent.Address2 = data[5];
				agent.City = data[6];
				agent.State = data[7];
				agent.Zip = data[8];
				agent.PrimaryPhone = data[9];
				agent.Email = data[10];
				agent.IsActive = (data[11].toLowerCase() == 'true');
				agent.HouseAccount = (data[12].toLowerCase() == 'true');

				agent.FullName = agent.FirstName + ' ' + agent.LastName;
				agent.Name = agent.FirstName + ' ' + agent.LastName;
				agent.Id = agent.ContactId;

				agents.push(agent);
			}
		}

		return agents;
	}

	parseSplitCsv(csvString: string): any[] {
		const temp: any[] = csvString.split('\n');
		const splits: any[] = [];

		let i = 1;
		for (i = 1; i < temp.length; i++) {
			const data: any[] = temp[i].split('\xa7');

			if (data.length > 1) {
				const split: any = {};

				split.RepId = data[0];
				split.RepName = data[1];
				split.CustomerId = data[2];
				split.CustomerName = data[3];
				split.Address = data[4];
				split.City = data[5];
				split.State = data[6];
				split.Zip = data[7];
				split.ManufacturerId = data[8];
				split.ManufacturerName = data[9];
				split.Split = data[10];
				split.ManufacturerId2 = data[11];
				split.ManufacturerName2 = data[12];
				split.ManufacturerSplit = data[13];

				splits.push(split);
			}
		}

		return splits;
	}
}
