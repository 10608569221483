import { Injectable } from '@angular/core';
import { ServicesModule } from '../services.module';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { DateQuery } from 'src/app/models/elastic/date-query';
import { RepQuery } from 'src/app/models/elastic/rep-query';
import { CallNoteTypeQuery } from 'src/app/models/elastic/callNoteType-query';
import { CallNoteFieldQuery } from 'src/app/models/elastic/callNoteField-query';

@Injectable({
  providedIn: ServicesModule
})
export class ElasticService {
  private jpiUrl = environment.jpiUrl;

  constructor(private http: HttpClient) {}

  //Controller is 'elastic-search-controller', currently all requests are Post requests

  getNumberOfCallNotesByDate(dateQuery: DateQuery) {
    const query = `{
      "size": 0,
      "aggs": {
        "total_call_notes": {
          "date_range": {
            "field": "createdDate",
            "format": "yyyy-MM-dd HH:mm:ss",
            "ranges": [
              {
                "from": "${dateQuery.initialDate}",
                "to": "${dateQuery.initialDateEndOfDay}"
              }
            ]
          },
          "aggs": {
            "totals_by_call_note_field": {
              "nested": {
                "path": "fields"
              },
              "aggs": {
                "call_note_fields_values": {
                  "terms": {
                    "field": "fields.fieldId"
                  },
                  "aggs": {
                    "call_note_field_info": {
                      "top_hits": {
                        "_source": {
                          "includes": ["fields.fieldId", "fields.fieldName"]
                        },
                        "size": 1
                      }
                    }
                  }
                }
              }
            },
            "totals_by_rep": {
              "terms": {
                "size": 2000,
                "field": "assignedToMailbox"
              },
              "aggs": {
                "rep_info": {
                  "top_hits": {
                    "_source": {
                      "includes": ["assignedToMailbox", "assignedToFirstName", "assignedToLastName", "assignedToId"]
                    },
                    "size": 1
                  }
                }
              }
            },
            "totals_by_call_type": {
              "terms": {
                "size": 2000,
                "field": "typeId"
              },
              "aggs": {
                "type_info": {
                  "top_hits": {
                    "_source": {
                      "includes": ["typeId", "typeName"]
                    },
                    "size": 1
                  }
                }
              }
            }
          }
        },
        "total_call_notes_past_30_days": {
          "date_range": {
            "field": "createdDate",
            "format": "yyyy-MM-dd HH:mm:ss",
            "ranges": [
              {
                "from": "${dateQuery.monthLookBack}",
                "to": "${dateQuery.initialDate}"
              }
            ]
          },
          "aggs": {
            "totals_by_rep": {
              "terms": {
                "size": 2000,
                "field": "assignedToMailbox"
              },
              "aggs": {
                "rep_info": {
                  "top_hits": {
                    "_source": {
                      "includes": ["assignedToMailbox", "assignedToFirstName", "assignedToLastName", "assignedToId"]
                    },
                    "size": 1
                  }
                }
              }
            },
            "totals_by_call_type": {
              "terms": {
                "size": 2000,
                "field": "typeId"
              },
              "aggs": {
                "type_info": {
                  "top_hits": {
                    "_source": {
                      "includes": ["typeId", "typeName"]
                    },
                    "size": 1
                  }
                }
              }
            },
            "totals_by_call_note_field": {
              "nested": {
                "path": "fields"
              },
              "aggs": {
                "call_note_fields_values": {
                  "terms": {
                    "field": "fields.fieldId"
                  },
                  "aggs": {
                    "call_note_field_info": {
                      "top_hits": {
                        "_source": {
                          "includes": ["fields.fieldId", "fields.fieldName"]
                        },
                        "size": 1
                  }
                }
              }
              }
            }
            }
          }
        }
      }
    }`;
    return this.http.post(`${this.jpiUrl}/elastic/call_notes?limit=500`, query);
  }

  getCallNotesByRep(repQuery: RepQuery) {
    const query = `{
        "query": {
          "bool": {
            "filter": {
              "range": {
                "createdDate": {
                  "format": "yyyy-MM-dd HH:mm:ss",
                  "from": "${repQuery.initialDate}",
                  "to": "${repQuery.initialDateEndOfDay}"
                }
              }
            }
            , "must": [
              {
                "match": {
                  "assignedToId": "${repQuery.repId}"
                }
              }
            ]
          }
        },
        "size": 500
      }`;
    return this.http.post(`${this.jpiUrl}/elastic/call_notes?limit=500`, query);
  }

  getCallNotesByCallType(callNoteTypeQuery: CallNoteTypeQuery) {
    const query = `{
      "query": {
        "bool": {
          "filter": {
            "range": {
              "createdDate": {
                "format": "yyyy-MM-dd HH:mm:ss",
                "from": "${callNoteTypeQuery.initialDate}",
                "to": "${callNoteTypeQuery.initialDateEndOfDay}"
              }
            }
          }
          , "must": [
            {
              "match": {
                "typeId": "${callNoteTypeQuery.callNoteTypeId}"
              }
            }
          ]
        }
      },
      "size": 500 
    }`;
    return this.http.post(`${this.jpiUrl}/elastic/call_notes?limit=500`, query);
  }

  getCallNotesByCallNoteField(callNoteFieldQuery: CallNoteFieldQuery) {
    const query = `{
      "query": {
        "bool": {
          "filter": {
            "range": {
              "createdDate": {
                "format": "yyyy-MM-dd HH:mm:ss",
                "from": "${callNoteFieldQuery.initialDate}",
                "to": "${callNoteFieldQuery.initialDateEndOfDay}"
              }
            }
          }
          , "must": [
            {
              "nested": {
                "path": "fields",
                "query": {
                  "term": {
                    "fields.fieldId": "${callNoteFieldQuery.callNoteFieldId}"
                  }
                }
              }
            }
          ]
        }
      },
      "size": 500
    }`;
    return this.http.post(`${this.jpiUrl}/elastic/call_notes?limit=500`, query);
  }

  getCallNoteByTypesTotal(date: { start: string; end: string }, reps: number[]) {
    const query = `{
    "query": {
      "bool": {
        "must": [
          {
            "terms": {
              "assignedToId": [${reps}],
              "boost": 1
            }
          },
          {
            "range": {
              "createdDate": {
                "from": "${date.start}",
                "to": "${date.end}",
                "include_lower": true,
                "include_upper": true,
                "boost": 1
              }
            }
          }
        ],
        "adjust_pure_negative": true,
        "boost": 1
      }
    },
    "size": 0,
    "aggs": {
      "total_by_typeId": {
        "terms": {
          "field": "typeId",
          "size": 10000
        },
        "aggs": {
          "type_info": {
            "top_hits": {
              "_source": {
                "includes": [
                  "typeId",
                  "typeName",
                  "assignedToId"
                ]
              },
              "size": 1
            }
          }
        }
      }
    }
  }
}`;

    return this.http.post(`${this.jpiUrl}/elastic/call_notes?limit=10000`, query);
  }

  getCallNotesByCompanyLabel(date: { start: string; end: string }, reps: number[]) {
    const query = `{
      "query": {
        "bool": {
          "must": [
            {
              "terms": {
                "assignedToId": [${reps}],
                "boost": 1
              }
            },
            {
              "range": {
                "createdDate": {
                  "from": "${date.start}",
                  "to": "${date.end}",
                  "include_lower": true,
                  "include_upper": true,
                  "boost": 1
                }
              }
            }
          ],
          "adjust_pure_negative": true,
          "boost": 1
        }
      },
      "size": 0,
      "aggs": {
        "nested_labels": {
            "nested": {
                "path": "labels"
            },
            "aggs": {
                "label_ids": {
                    "terms": {
                        "field": "labels.id",
                        "size": 10000
                    },
                    "aggs": {
                        "label_info": {
                            "top_hits": {
                                "_source": {
                                    "includes": [
                                        "labels.id",
                                        "labels.name",
                                        "labels.groupId",
                                        "labels.description"
                                    ]
                                },
                                "size": 1
                            }
                        }
                    }
                }
            }
        }
    }
    }
  }`;

    return this.http.post(`${this.jpiUrl}/elastic/call_notes?limit=10000`, query);
  }

  getCallNotesByReps(date: { start: string; end: string }, reps: number[]) {
    const query = `{
      "query": {
        "bool": {
          "must": [
            {
              "range": {
                "createdDate": {
                  "from": "${date.start}",
                  "to": "${date.end}",
                  "include_lower": true,
                  "include_upper": true,
                  "boost": 1
                }
              }
            },
            {
              "terms": {
                "assignedToId": [${reps}]
              }
            }
          ]
        }
      },
      "size": 10000
    }`;
    return this.http.post(
      `${this.jpiUrl}/elastic/call_notes?limit=10000&order=createdDate&direction=asc`,
      query
    );
  }
}
