<div class="call-note-form">
  <form [formGroup]="callNoteFieldForm">
    <div fxLayout="column">
      <mat-form-field fxFlex>
        <mat-label>Name</mat-label>
        <input
          formControlName="name"
          matInput
          [value]="currentCallNoteField?.name"
          type="text"
        />
      </mat-form-field>

      <mat-form-field fxFlex>
        <mat-label>Type</mat-label>
        <mat-select
          formControlName="type"
          matInput
          [value]="currentCallNoteField?.type"
          [compareWith]="compareObjects"
        >
          <mat-option *ngFor="let type of fieldTypes" [value]="type">{{
            type.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxLayout="row-reverse" fxLayoutAlign="space-between center">
      <div fxLayout="row-reverse">
        <button
          *ngIf="!currentCallNoteField?.id"
          type="button"
          mat-raised-button
          color="primary"
          mat-button
          [disabled]="callNoteFieldForm.pristine || callNoteFieldForm.invalid"
          (click)="createButtonClicked()"
        >
          Create
        </button>
        <button
          *ngIf="currentCallNoteField?.id"
          type="button"
          mat-raised-button
          color="primary"
          mat-button
          [disabled]="callNoteFieldForm.pristine || callNoteFieldForm.invalid"
          (click)="saveButtonClicked()"
        >
          Save
        </button>
        <button
          preventDefaultAction
          class="button-container"
          mat-raised-button
          color="white"
          mat-button
          (click)="cancelButtonClicked()"
        >
          Cancel
        </button>
      </div>
      <button
        *ngIf="currentCallNoteField?.id"
        preventDefaultAction
        mat-raised-button
        color="accent"
        mat-button
        (click)="deleteButtonClicked()"
      >
        Delete
      </button>
    </div>
  </form>

  <div
    [style.marginTop]="'16px'"
    *ngIf="
      !!currentCallNoteField?.id && callNoteFieldForm.controls.type?.value?.id === 16
    "
  >
    <form [formGroup]="newOptionForm" (submit)="submitOptionHandler($event)">
      <mat-form-field fxFill>
        <input
          matInput
          placeholder="Add a dropdown option"
          autocomplete="off"
          formControlName="dropdownOption"
        />
        <button
          mat-button
          *ngIf="
            newOptionForm.controls.dropdownOption.value &&
            newOptionForm.controls.dropdownOption.value.trim()
          "
          matSuffix
          mat-icon-button
          color="primary"
          type="submit"
        >
          <mat-icon>done</mat-icon>
        </button>
      </mat-form-field>
    </form>
    <div cdkDropList class="drag-drop-list" (cdkDropListDropped)="optionDropped($event)">
      <div
        cdkDrag
        class="drag-drop-item"
        *ngFor="let option of dropdownOptions | sort: 'dropdownOrder'"
        cdkDragBoundary=".drag-drop-list"
        style="background-color: white;
        border-bottom: 1px #555;
        margin: 8px 0 16px;
        padding: 5px 5px 5px 10px;
        border-radius: 6px;
        border: 1px solid #ddd;
        cursor: grab;"
      >
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex="10"><i class="material-icons">reorder</i></div>
          <div fxFlex="66">
            <div *ngIf="!option.isEditing; else editingInput">
              {{ option.dropdownOption }}
            </div>
            <ng-template #editingInput>
              <input
                matInput
                placeholder="Option name"
                autofocus
                (keydown.enter)="saveOptionHandler(option)"
                [(ngModel)]="option.dropdownOption"
              />
            </ng-template>
          </div>
          <div fxFlex="12">
            <button
              mat-icon-button
              *ngIf="!option.isEditing; else editingSave"
              matTooltip="edit"
              color="primary"
              (click)="editHandler(option)"
            >
              <mat-icon>edit</mat-icon>
            </button>
            <ng-template #editingSave>
              <button
                mat-icon-button
                matTooltip="save"
                color="primary"
                (click)="saveOptionHandler(option)"
              >
                <mat-icon>done</mat-icon>
              </button>
            </ng-template>
          </div>
          <div fxFlex="12">
            <button
              mat-icon-button
              *ngIf="!option.isEditing; else editingCancel"
              matTooltip="delete"
              color="warn"
              (click)="deleteHandler(option)"
            >
              <mat-icon>delete</mat-icon>
            </button>
            <ng-template #editingCancel>
              <button
                mat-icon-button
                matTooltip="cancel"
                (click)="cancelEditHandler(option)"
              >
                <mat-icon>clear</mat-icon>
              </button>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
