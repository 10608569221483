export interface ColumnHeader {
  name: string;
  displayName: string;
  type: ColumnType;
  isCurrency?: boolean;
  isDate?: boolean;
  alignRight?: boolean;
}

export enum ColumnType {
  NUMERIC = 'numeric',
  STRING = 'string',
  LABEL = 'label',
  BOOLEAN = 'boolean'
}
