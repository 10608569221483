import { Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { DxSelectBoxComponent } from 'devextreme-angular';
import isEmpty from 'lodash.isempty';

import { CallNoteV2 } from '../../models/call-notes/call-note-V2';
import { CompanyV2 } from '../../models/companies/company-V2';
import { ContactV2 } from '../../models/contacts/contact-V2';
import { GlobalFilter } from '../../models/global-filter';
import { Address } from './../../models/address';
import { CallNoteType } from './../../models/call-notes/call-note-type';
import { CallNoteTypeService } from './../../services/call-notes/call-note-type.service';
import { CompanyJPIService } from './../../services/companies/company-JPI.service';
import { ContactService } from './../../services/contact.service';
import { ContactJPIService } from '../../services/contact-JPI.service';
import { Subscription } from 'rxjs';
import { CompanyBasic } from '../../models/companies/company-basic';
import { NoteField } from '../../models/call-notes/note-field';
import { FilteredList } from '../../models/filteredList';

@Component({
  selector: 'call-note-details',
  templateUrl: './call-note-details.component.html',
  styleUrls: ['./call-note-popup.component.scss', './call-note-details.component.scss']
})
export class CallNoteDetailsComponent implements OnInit {
  @ViewChild('companySelectBox')
  companySelectBox: DxSelectBoxComponent;
  @Input() callNoteExpanded: boolean = true;
  @Input() callNote: CallNoteV2;
  @Input() contactsInList: ContactV2[];
  @Input() contactsList: boolean;
  @Input() contactTagBoxDisplayed: boolean;
  @Input() clickEditCompany: boolean;
  @Input() companySelectBoxDisplayed: boolean;
  @Input() displayedCompany: object;
  @Input() contacts: ContactV2[];
  @Input() companies: any;
  @Input() contactsFeature: number;
  @Input() editCallNotesFeature: number;
  @Input() companyId: number;
  @Output() contactsChange: EventEmitter<ContactV2[]> = new EventEmitter<ContactV2[]>();
  window: any = window;
  windowHeight: number = window.innerHeight * 0.9;
  windowWidth: number = window.innerWidth * 0.9;
  callTypes: CallNoteType[];
  newContact: ContactV2;
  contactPopup: boolean;
  displayedContact: ContactV2;
  contactDetailsPopup: boolean;
  companyProfileCompanyId: number;
  companyProfilePopup: boolean;
  contactSubscription: Subscription;
  typingTimer: any;
  inputDelay = 300;

  constructor(
    private contactJPIService: ContactJPIService,
    private contactService: ContactService,
    private companyJPIService: CompanyJPIService,
    private callNoteTypeService: CallNoteTypeService
  ) {}

  ngOnInit() {
    this.getCallTypes();
    this.subscribeToContact();
    this.companies = this.selectBoxCompanySearch();
  }

  ngOndDestroy(): void {
    this.contactSubscription.unsubscribe();
  }

  getCallTypes(): void {
    this.callNoteTypeService.getAll().then((result) => {
      this.callTypes = result;
      this.callTypes.sort((a: CallNoteType, b: CallNoteType) =>
        a.name.localeCompare(b.name)
      );
    });
  }

  subscribeToContact(): void {
    this.contactSubscription = this.contactService.contactAnnounced.subscribe(
      (result) => {
        this.newContact = result;
        if (this.newContact != null) {
          if (!this.contactsInList) {
            this.contactsInList = [];
          }
          if (!this.callNote.contacts) {
            this.callNote.contacts = [];
          }
          if (!(<any>this.callNote).contactIds) {
            (<any>this.callNote).contactIds = [];
          }
          this.callNote.contacts.push(this.newContact);
          (<any>this.callNote).contactIds.push(this.newContact.id);
          this.contactsInList.push(this.newContact);
          this.contactsList = true;
          this.contactTagBoxDisplayed = false;
          this.offContactFocus();
          this.contactService.contactFromCallNote(null);
        }
      }
    );
  }

  offContactFocus(): void {
    if ((<any>this.callNote).contactIds && (<any>this.callNote).contactIds.length > 0) {
      this.contactsList = true;
      this.contactTagBoxDisplayed = false;
      this.contactsInList = [];
      (<any>this.callNote).contactIds.map((id: number) => {
        this.contacts.map((contact: any) => {
          if (contact.id == id) {
            this.contactsInList.push(contact);
          }
        });
      });
    }
  }

  getOptionsForDropdowns(field: NoteField): any {
    const dataSource = [];
    // if (field.field.type.legacyId == 6) {
    //     return this.lists.map((list: CustomList) => {
    //         if (list.Id == field.field.type.listId) {
    //             if (list.Options) {
    //                 list.Options.map((option: FieldOption) => {
    //                     return dataSource.push(option);
    //                 });
    //             }
    //         }
    //     });
    // }
  }

  changeContacts(): void {
    this.contactsList = false;
    this.contactTagBoxDisplayed = true;
  }

  expandCallNote(): void {
    this.callNoteExpanded = !this.callNoteExpanded;
  }

  companyChanged(e): void {
    this.setNewCallNoteCompany(e.value);
    if (e.value && Object.keys(e.value).length > 0) {
      this.contactJPIService.getContactsForCompany(this.callNote.company.id).then(
        (result) => {
          this.contacts = result;
          this.contacts.map(
            (contact: ContactV2) =>
              (contact.fullName = `${contact.firstName} ${contact.lastName}`)
          );
          this.contactsChange.emit(this.contacts);
        },
        (error) => {
          throw error;
        }
      );
      this.companyJPIService.getCompanyByIdJPI(e.value.id).then(
        (result) => {
          this.getDisplayedCompany(result);
          this.clickEditCompany = true;
          this.contactTagBoxDisplayed = true;
          this.companySelectBoxDisplayed = false;
        },
        (error) => {
          throw error;
        }
      );
    } else {
      this.contacts = [];
    }
  }

  setNewCallNoteCompany(newCompany: CompanyBasic): void {
    if (newCompany.id) {
      this.getDisplayedCompany(newCompany);
      this.callNote.company = {
        id: newCompany.id,
        agencyId: newCompany.agencyId,
        name: newCompany.name,
        deleted: newCompany.deleted,
        deletedDate: newCompany.deletedDate,
        territories: newCompany.territories,
        primaryUser: newCompany.primaryUser,
        billingAddress: newCompany.billingAddress,
        shippingAddress: newCompany.shippingAddress
      };
    }
  }

  getDisplayedCompany(company: any): any {
    this.cleanUpCompanyAddress(company);
    if (!isEmpty(company.shippingAddress)) {
      this.displayedCompany = {
        name: company.name,
        id: company.id,
        addressLine1: company.shippingAddress.addressLine1,
        addressLine2: company.shippingAddress.addressLine2,
        city: company.shippingAddress.city,
        state: company.shippingAddress.state,
        postCode: company.shippingAddress.postCode
      };
    } else if (!isEmpty(company.billingAddress)) {
      this.displayedCompany = {
        name: company.name,
        id: company.id,
        addressLine1: company.billingAddress.addressLine1,
        addressLine2: company.billingAddress.addressLine2,
        city: company.billingAddress.city,
        state: company.billingAddress.state,
        postCode: company.billingAddress.postCode
      };
    } else {
      this.displayedCompany = {
        name: company.name,
        id: company.id
      };
    }
  }

  cleanUpCompanyAddress(company: CompanyV2): void {
    for (const key in company.billingAddress) {
      if (company.billingAddress.hasOwnProperty(key)) {
        if (!!company.billingAddress[key]) {
          company.billingAddress[key] = company.billingAddress[key].trim();
        }
        if (!company.billingAddress[key] || company.billingAddress[key].trim() == '') {
          delete company.billingAddress[key];
        }
      }
    }
    for (const key in company.shippingAddress) {
      if (company.shippingAddress.hasOwnProperty(key)) {
        if (!!company.shippingAddress[key]) {
          company.shippingAddress[key] = company.shippingAddress[key].trim();
        }
        if (!company.billingAddress[key] || company.shippingAddress[key] == '') {
          delete company.shippingAddress[key];
        }
      }
    }
  }

  openCompanyProfile(company: CompanyV2) {
    // this.companyProfileCompanyId = company.id;
    // this.companyProfilePopup = true;
    window.open(`/companies/company/${company.id}`, '_blank');
  }

  changeCompany(): void {
    const filter: GlobalFilter = {
      filters: []
    };
    this.companyJPIService
      .getJPICompaniesFiltered(0, 25, 'name.keyword', true, filter)
      .then((results: FilteredList) => {
        this.companies = results.items;
        this.contacts = [];
        this.callNote.company.id = null;
        this.displayedCompany = {};
        this.companySelectBox.instance.reset();
        this.clickEditCompany = false;
        this.contactTagBoxDisplayed = false;
        this.companySelectBoxDisplayed = true;
      });
  }

  selectBoxCompanySearch(searchTerm?: string): any {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      const filter: GlobalFilter = { filters: [] };
      if (searchTerm) {
        filter.filters.push({
          operand1: 'name',
          operator: 'CONTAINS_ANY_OF',
          operand2: [searchTerm]
        });
        this.companyJPIService
          .getJPICompaniesFiltered(0, 25, 'name.keyword', true, filter)
          .then((results: FilteredList) => {
            results.items.map((result: any) => {
              result = this.cleanUpAddresses(result);
              if (isEmpty(result.billingAddress)) {
                delete result.billingAddress;
              }
              if (isEmpty(result.shippingAddress)) {
                delete result.shippingAddress;
              }
            });
            this.companies = results.items;
            this.companySelectBox.instance.open();
          });
      } else {
        this.companyJPIService
          .getJPICompaniesFiltered(0, 25, 'name.keyword', true, filter)
          .then((results: FilteredList) => {
            results.items.map((result: any) => {
              result = this.cleanUpAddresses(result);
              if (isEmpty(result.billingAddress)) {
                delete result.billingAddress;
              }
              if (isEmpty(result.shippingAddress)) {
                delete result.shippingAddress;
              }
            });
            this.companies = results.items;
          });
      }
    }, this.inputDelay);
  }

  companySearch(): void {
    if (this.companySelectBox.instance.option().text != '') {
      this.companySelectBox.instance.open();
    }
    setTimeout(() => {
      this.selectBoxCompanySearch(this.companySelectBox.instance.option().text);
      this.contactsList = false;
    }, 20);
  }

  checkEmptyObject(address: Address): boolean {
    return isEmpty(address);
  }

  callTypeChanged(e: any): void {
    if (e.selectedItem != null) {
      this.callNote.type = e.selectedItem;
    } else {
      this.callNote.type = Object.assign({});
    }
  }

  createNewContact(): void {
    this.contactPopup = true;
    this.displayedContact = Object.assign({});
  }

  openContactDetails(contact: ContactV2): void {
    this.contactDetailsPopup = true;
    this.displayedContact = contact;
  }

  openContact(): void {
    this.contactPopup = true;
    this.contactDetailsPopup = false;
  }

  hidingContactDetails(): void {
    this.contactDetailsPopup = false;
  }

  cleanUpAddresses(company: CompanyV2): CompanyV2 {
    for (const key in company.billingAddress) {
      if (company.billingAddress.hasOwnProperty(key) && company.billingAddress[key]) {
        company.billingAddress[key] = company.billingAddress[key].trim();
        if (company.billingAddress[key].trim() == '') {
          delete company.billingAddress[key];
        }
      }
    }
    for (const key in company.shippingAddress) {
      if (company.shippingAddress.hasOwnProperty(key) && company.shippingAddress[key]) {
        company.shippingAddress[key] = company.shippingAddress[key].trim();
        if (company.shippingAddress[key] == '') {
          delete company.shippingAddress[key];
        }
      }
    }
    return company;
  }

  openCompanyInNewTab(): void {
    this.companyProfilePopup = false;
    window.open(`/companies/company/${this.companyId}`, '_blank');
  }

  profileClosed(): void {
    this.companyProfilePopup = false;
  }
}
