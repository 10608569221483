import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ExpenseTypesEffects } from './expense-types.effects';
import { expenseTypesReducer } from './expense-types.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('expenseTypes', expenseTypesReducer),
    EffectsModule.forFeature([ExpenseTypesEffects])
  ]
})
export class ExpenseTypesStateModule {}
