import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { CompanyListV2 } from '../../models/companies/company-list-V2';
import { CompanyV2 } from '../../models/companies/company-V2';
import { MergeRequest } from '../../models/companies/merge-request';
import { AuthenticationService } from '../authentication.service';

@Injectable()
export class CompanyListService {

	constructor(private authService: AuthenticationService, private router: Router, private http: HttpClient) {
		// super('/companies', authService);
	}

	getAll(): Promise<CompanyListV2[]> {
		return this.authService.makeJpiRequest('/companylists', null, null, 'get').then(lists => {
			return JSON.parse(lists);
		});
	}

	getById(id: number): Promise<CompanyListV2> {
		return this.authService.makeJpiRequest('/companylists/' + id, null, null, 'get').then((list) => {
			return JSON.parse(list);
		});
	}

	create(list: CompanyListV2): Promise<CompanyListV2> {
		return this.authService.makeJpiRequest('/companylists', null, list, 'post').then((list) => {
			return JSON.parse(list);
		});
	}

	update(list: CompanyListV2): Promise<CompanyListV2> {
		return this.authService.makeJpiRequest('/companylists/' + list.id, null, list, 'put').then((list) => {
			return JSON.parse(list);
		});
	}

	delete(list: CompanyListV2): Promise<CompanyListV2> {
		return this.authService.makeJpiRequest('/companylists/' + list.id, null, list, 'delete').then((list) => {
			return JSON.parse(list);
		});
	}

	mergeCompanies(company: CompanyV2, mergedCompanyIds: number[]): Promise<any> {
		const body: MergeRequest = { company: company, companyIds: mergedCompanyIds };
		return this.authService.makeJpiRequest('/bulkoperations/merge', null, body, 'post').then((company) => {
			return JSON.parse(company);
		});
	}
}
