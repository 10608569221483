import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { Address } from 'src/app/models/address';
import { Company } from 'src/app/models/companies/company';
import { CompanySelectItem } from 'src/app/models/companies/company-select';
import { Contact } from 'src/app/models/contacts/contact';
import { GlobalFilter } from 'src/app/models/global-filter';
import {
  AddNewCompanyContact,
  ClearSearchCompanies,
  EditNewCompanyContact,
  LoadCompanyContacts,
  SearchCompanies,
  SelectSearchedCompany,
  SaveContact,
  DeleteContact
} from 'src/app/store/companies/companies.actions';
import { selectCurrentCompany } from 'src/app/store/companies/selectors/current-company.selector';
import { CloseDrawer, CloseLeftDrawer } from 'src/app/store/layout/layout.actions';
import { RootState } from 'src/app/store/store.reducer';
import { removeNullPropsImmutable } from 'src/app/utils/removeNullProps';

@Component({
  selector: 'tn-contact-slide-out-form',
  templateUrl: './contact-slide-out-form.component.html',
  styleUrls: ['./contact-slide-out-form.component.scss']
})
export class ContactSlideOutFormComponent implements OnInit, OnDestroy {
  @Input() data: {
    contact?: Contact;
    contactIndex?: number;
    hasCompanyInput?: boolean;
    company?: CompanySelectItem;
  } = {
    contact: null,
    contactIndex: null,
    hasCompanyInput: true,
    company: null
  };

  newContactForm = new FormGroup({
    id: new FormControl<number>(null),
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl(''),
    title: new FormControl(''),
    email: new FormControl(''),
    phone: new FormControl(''),
    ext: new FormControl(''),
    mobilePhone: new FormControl(''),
    fax: new FormControl(''),
    birthday: new FormControl<Date | string | number>(''),
    dept: new FormControl(''),
    account: new FormControl(null),
    notes: new FormControl(''),
    address: new FormGroup({
      addressLine1: new FormControl(''),
      addressLine2: new FormControl(''),
      city: new FormControl(''),
      state: new FormControl(''),
      postCode: new FormControl(''),
      country: new FormControl(''),
      county: new FormControl('')
    })
  });

  $selectedContactSub: Subscription;
  $selectedContact: Observable<Contact>;
  company: CompanySelectItem;
  $company: Observable<Company>;
  $companies: Observable<CompanySelectItem[]>;
  $companiesSearchPending: Observable<boolean>;
  $companiesSearchError: Observable<HttpErrorResponse>;
  selectedCompanyId: number;
  hasContact = false;

  constructor(private store: Store<RootState>) {}

  ngOnDestroy() {
    this.$selectedContactSub.unsubscribe();
  }

  ngOnInit() {
    this.newContactForm.markAllAsTouched();
    if (!!this.data.company && this.data.hasCompanyInput) {
      this.company = this.data.company;
      this.selectedCompanyId = this.data.company.id;
      this.newContactForm.controls.account.setValue(this.data.company.id);
      this.company = this.data.company;
      this.store.dispatch(new SelectSearchedCompany(this.data.company.id));
      this.store.dispatch(new LoadCompanyContacts(this.data.company.id));
    }
    this.$company = this.store.select(selectCurrentCompany);
    this.$selectedContact = this.store.select('contacts', 'contact');
    this.$selectedContactSub = this.$selectedContact
      .pipe(withLatestFrom(this.$company))
      .subscribe(([contact, company]: [Contact, Company]) => {
        if (!!contact && this.data.hasCompanyInput) {
          this.hasContact = true;
          contact.account = contact.account || company.id;
          this.selectedCompanyId = contact.account;
          this.newContactForm.patchValue({
            id: contact.id,
            firstName: contact.firstName,
            lastName: contact.lastName,
            birthday: contact.birthday,
            email: contact.email,
            mobilePhone: contact.mobilePhone ? contact.mobilePhone.trim() : null,
            phone: contact.phone ? contact.phone.trim() : null,
            ext: contact.ext ? contact.ext.trim() : null,
            fax: contact.fax ? contact.fax.trim() : null,
            title: contact.title,
            account: contact.account,
            address: contact.address,
            dept: contact.dept,
            notes: contact.notes
          });
          if (!!contact.birthday) {
            this.newContactForm.patchValue({
              birthday: new Date(contact.birthday)
            });
          }
        }
      });
    this.$companies = this.store.select('companies', 'search', 'data');
    this.$companiesSearchPending = this.store.select('companies', 'search', 'pending');
    this.$companiesSearchError = this.store.select('companies', 'search', 'error');

    if (this.data.contactIndex !== undefined && this.data.contactIndex !== null) {
      this.newContactForm.reset(this.data.contact);
    }
  }

  cancel() {
    this.newContactForm.reset();
    if (this.data.hasCompanyInput) {
      this.store.dispatch(new CloseDrawer());
    } else {
      this.store.dispatch(new CloseLeftDrawer());
    }
  }

  submitHandler() {
    this.newContactForm.value.birthday = this.newContactForm.value.birthday
      ? new Date(this.newContactForm.value.birthday + ' ').getTime()
      : null;
    if (this.newContactForm.value.firstName === null) {
      this.newContactForm.patchValue({ firstName: '' });
    }
    if (this.newContactForm.value.lastName === null) {
      this.newContactForm.patchValue({ lastName: '' });
    }
    if (!this.data.hasCompanyInput) {
      if (this.data.contactIndex !== undefined && this.data.contactIndex !== null) {
        this.store.dispatch(
          new EditNewCompanyContact({
            contact: this.newContactForm.value as Contact,
            index: this.data.contactIndex
          })
        );
      } else {
        this.store.dispatch(
          new AddNewCompanyContact(this.newContactForm.value as Contact)
        );
      }
      this.store.dispatch(new CloseLeftDrawer());
      this.newContactForm.reset();
    } else {
      this.store.dispatch(
        new SaveContact({
          contact: {
            ...(removeNullPropsImmutable(this.newContactForm.value) as Contact)
          },
          // this is in case the user switches the contact's company
          companyId: this.selectedCompanyId
        })
      );
      this.store.dispatch(new CloseDrawer());
    }
  }

  onCompanySearch(searchVal: string) {
    if (!!searchVal.length) {
      const reqFilter: GlobalFilter = {
        filters: [
          {
            operand1: 'searchField',
            operator: 'CONTAINS_ALL_OF',
            operand2: [searchVal]
          }
        ]
      };
      this.store.dispatch(new SearchCompanies(reqFilter));
    } else {
      this.store.dispatch(new ClearSearchCompanies());
    }
  }

  onCompanySelect(event: MatAutocompleteSelectedEvent) {
    this.newContactForm.markAsDirty();
    this.newContactForm.controls.account.setValue(event.option.value.id);
    this.store.dispatch(new LoadCompanyContacts(event.option.value.id));
    this.store.dispatch(new SelectSearchedCompany(event.option.value.id));
    this.selectedCompanyId = event.option.value.id;
  }
}
