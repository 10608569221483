import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Subscription, Observable, Subject } from 'rxjs';
import { ItemCustomField } from 'src/app/models/Opportunity/item-custom-field';
import { RootState } from 'src/app/store/store.reducer';
import { Store } from '@ngrx/store';
import {
  selectItemCustomFields,
  selectItemCustomFieldsAsArray
} from 'src/app/store/opportunities/selectors/item-custom-fields.selector';
import { ItemName } from 'src/app/models/Opportunity/itemName';
import { selectItemNamesAsArray } from 'src/app/store/system-settings/opportunity-item-names/selectors/all-itemNames-as-array.selector';
import { Item } from 'src/app/models/Opportunity/item';
import { CloseDrawer } from 'src/app/store/layout/layout.actions';
import { selectCurrentOpportunity } from 'src/app/store/opportunities/selectors/current-opportunity.selector';
import { Opportunity } from 'src/app/models/Opportunity/opportunity';
import { removeNullProps } from 'src/app/utils/removeNullProps';
import { SaveOpportunity } from 'src/app/store/opportunities/opportunities.actions';
import { withLatestFrom } from 'rxjs/operators';
import { GenericMap } from 'src/app/models/generic-map';

@Component({
  selector: 'tn-opp-item-form',
  templateUrl: './opp-item-form.component.html',
  styleUrls: ['./opp-item-form.component.scss']
})
export class OppItemFormComponent implements OnInit, OnDestroy {
  searchText = '';
  $focusEventSubject: Subject<void> = new Subject<void>();

  addItemForm: FormGroup;
  customFieldsForm: FormGroup;
  $currentOpportunity: Observable<Opportunity>;
  $opportunitySub: Subscription;
  opportunity: Opportunity;
  opportunityForm: FormGroup;
  total: number;
  weightedTotal: number;
  itemNames: ItemName[];
  $itemNames: Observable<ItemName[]>;
  opportunityItems: Item[];
  $customFields: Observable<ItemCustomField[]>;
  $customFieldsMap: Observable<GenericMap<ItemCustomField>>;
  $addItemFormEventsSubject: Subject<void> = new Subject<void>();
  $currentItemId: Observable<number>;
  currentItem: Item;

  constructor(private store: Store<RootState>) {}

  ngOnInit() {
    this.$customFieldsMap = this.store.select(selectItemCustomFields);
    this.$customFields = this.store.select(selectItemCustomFieldsAsArray);
    this.$currentItemId = this.store.select('opportunities', 'selectedItemId');
    this.$itemNames = this.store.select(selectItemNamesAsArray);
    this.$currentOpportunity = this.store.select(selectCurrentOpportunity);
    this.$opportunitySub = this.$currentOpportunity
      .pipe(
        withLatestFrom(
          this.$currentItemId,
          this.$itemNames,
          this.$customFields,
          this.$customFieldsMap
        )
      )
      .subscribe(
        ([opportunity, itemId, itemNames, fields, fieldsMap]: [
          Opportunity,
          number,
          ItemName[],
          ItemCustomField[],
          GenericMap<ItemCustomField>
        ]) => {
          this.opportunity = opportunity;
          this.itemNames = itemNames;
          this.addItemForm = new FormGroup({
            itemName: new FormControl(null),
            amount: new FormControl(null),
            quantity: new FormControl(null),
            probability: new FormControl(null, [Validators.max(100), Validators.min(1)]),
            itemDesc: new FormControl(null),
            nameId: new FormControl(null)
          });
          if (this.itemNames.length === 1) {
            this.addItemForm.controls.itemName.setValue(this.itemNames[0].name);
          }
          this.customFieldsForm = new FormGroup({});

          fields.reduce((controls, field) => {
            controls.addControl(field.id.toString(), new FormControl());
            return controls;
          }, this.customFieldsForm);
          if (!!this.opportunity && !!this.opportunity.items) {
            this.opportunityItems = this.opportunity.items;
            if (itemId) {
              this.currentItem = this.opportunity.items.find(
                (item: Item) => item.id === itemId
              );
              this.addItemForm.patchValue(this.currentItem);
              if (!!this.currentItem.customFields.length) {
                this.currentItem.customFields.map((cf) => {
                  let fieldVal: string | boolean = cf.fieldValue;
                  if (
                    !!fieldsMap[cf.opportunityItemFieldId] &&
                    fieldsMap[cf.opportunityItemFieldId].typeId === 2
                  ) {
                    fieldVal =
                      cf.fieldValue === '1'
                        ? true
                        : cf.fieldValue === '0'
                        ? false
                        : cf.fieldValue;
                  }
                  return this.customFieldsForm.controls[
                    cf.opportunityItemFieldId
                  ].setValue(fieldVal);
                });
              }
            }
          }
          this.opportunityForm = new FormGroup({
            companyId: new FormControl(null),
            ownedBy: new FormControl(null),
            name: new FormControl(null),
            id: new FormControl(null),
            items: new FormControl(null),
            estimatedCloseDate: new FormControl(null),
            probability: new FormControl(null, [Validators.max(100), Validators.min(1)]),
            assignedTo: new FormControl(null),
            createdBy: new FormControl(null),
            createdDate: new FormControl(null),
            files: new FormControl(null),
            labels: new FormControl(null),
            lastUpdated: new FormControl(null),
            notes: new FormControl(null)
          });
        }
      );
    this.store
      .select('auth', 'data', 'api', 'Role', 'Features', 20, 'Permissions')
      .subscribe((permissions) => {
        if (permissions[159]) {
          if (!this.addItemForm.value.probability) {
            this.addItemForm.patchValue({
              probability: 100
            });
          }
          this.addItemForm
            .get('probability')
            .setValidators([Validators.max(100), Validators.min(0), Validators.required]);
        }
      });
  }

  ngOnDestroy() {
    this.$opportunitySub.unsubscribe();
  }

  submitItem() {
    if (this.addItemForm.value.itemName) {
      this.addItemForm.patchValue({
        nameId: this.addItemForm.value.itemName.id
      });
    }
    this.opportunityForm.patchValue({
      companyId: this.opportunity.companyId,
      ownedBy: this.opportunity.ownedBy,
      name: this.opportunity.name,
      id: this.opportunity.id,
      probability: this.opportunity.probability,
      estimatedCloseDate: this.opportunity.estimatedCloseDate,
      assignedTo: this.opportunity.assignedTo,
      createdBy: this.opportunity.createdBy,
      createdDate: this.opportunity.createdDate,
      files: this.opportunity.files,
      labels: this.opportunity.labels,
      lastUpdated: this.opportunity.lastUpdated,
      notes: this.opportunity.notes,
      items: this.opportunity.items
    });
    const customFields = Object.keys(this.customFieldsForm.value)
      .map((key) => {
        const isCheckbox = typeof this.customFieldsForm.value[key] === 'boolean';
        const fieldValue = isCheckbox
          ? this.customFieldsForm.value[key]
            ? '1'
            : '0'
          : this.customFieldsForm.value[key];
        return {
          opportunityItemFieldId: key,
          fieldValue
        };
      })
      .filter((cf) => !!cf.fieldValue);
    removeNullProps(customFields);
    const newItem = {
      ...this.currentItem,
      ...this.addItemForm.value,
      customFields
    };
    const newItems = this.opportunity.items.map((obj) =>
      newItem.id === obj.id ? newItem : obj
    );

    if (!newItem.id) {
      newItems.push(newItem);
    }
    this.opportunityForm.patchValue({
      ...this.opportunity,
      items: newItems
    });
    this.store.dispatch(new CloseDrawer());
    this.addItemForm.reset();
    this.customFieldsForm.reset();
    removeNullProps(this.opportunityForm.value);
    this.store.dispatch(new SaveOpportunity(this.opportunityForm.value));
  }

  cancelItemHandler() {
    this.store.dispatch(new CloseDrawer());
    this.addItemForm.reset();
    this.customFieldsForm.reset();
    this.$addItemFormEventsSubject.next();
  }

  compareObjects(o1: any, o2: any) {
    if (!!o1 && !!o2) {
      return o1.name === o2.name && o1.id && o2.id;
    }
  }
}
