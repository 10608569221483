import { RootState } from '../../store.reducer';
import { MemoizedSelector, createSelector } from '@ngrx/store';
import { IdNameItem } from 'src/app/models/id-name-item';
import { GenericMap } from 'src/app/models/generic-map';
import { CallNoteType } from 'src/app/models/call-notes/call-note-type';

export const selectCallNoteTypes = (state: RootState) => state.callNoteTypes.data;

export const selectCallNoteTypesAsIdNames: MemoizedSelector<
  RootState,
  IdNameItem[]
> = createSelector(
  selectCallNoteTypes,
  (callNoteTypes: GenericMap<CallNoteType>): IdNameItem[] => {
    return Object.values(callNoteTypes).length
      ? Object.values(callNoteTypes)
          .map((type: CallNoteType) => {
            return {
              name: type.name,
              id: type.id
            };
          })
          .sort((a, b) => {
            const textA = a.name.toLowerCase();
            const textB = b.name.toLowerCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          })
      : [];
  }
);
