import { Subscription, Observable, EMPTY } from 'rxjs';
import { Component, OnInit, OnDestroy, Input, Inject } from '@angular/core';
import { RootState } from 'src/app/store/store.reducer';
import { Store } from '@ngrx/store';
import { OpenDrawer, CloseDrawer } from 'src/app/store/layout/layout.actions';
import { Contact } from 'src/app/models/contacts/contact';
import { LoadContact, LoadContacts } from 'src/app/store/contacts/contacts.actions';
import { CallNoteFormComponent } from 'src/app/view/shared/call-note-form/call-note-form.component';
import { DeleteContact } from 'src/app/store/companies/companies.actions';
import { ActivatedRoute } from '@angular/router';
import { selectCurrentCompany } from 'src/app/store/companies/selectors/current-company.selector';
import { Company } from 'src/app/models/companies/company';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContactSlideOutFormComponent } from 'src/app/view/shared/contact-slide-out-form/contact-slide-out-form.component';
import { formatAddressIntoOneLine } from 'src/app/utils/formatAddressIntoOneLine';

@Component({
  selector: 'tn-company-contacts',
  templateUrl: './company-contacts.component.html',
  styleUrls: ['./company-contacts.component.scss']
})
export class CompanyContactsComponent implements OnInit, OnDestroy {
  @Input() data;
  company: Partial<Company> = {
    contacts: []
  };
  $company: Observable<any>;
  $companySub: Subscription;
  contacts: Contact[];
  filteredContacts;
  $pending: Observable<boolean> = EMPTY;

  companyId;

  constructor(
    private route: ActivatedRoute,
    private store: Store<RootState>,
    public dialog: MatDialog
  ) {
    this.companyId = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit() {
    this.$company = this.store.select(selectCurrentCompany);
    this.$pending = this.store.select('companies', 'pending');
    this.$companySub = this.$company.subscribe((company) => {
      if (company) {
        this.company = company;
        this.contacts = company.contacts;
        this.contacts.map((contact) => {
          if (!!contact.phone) {
            contact.phone = contact.phone.trim();
            if (contact.phone.length === 10) {
              let format = '(xxx) xxx-xxxx';
              // eslint-disable-next-line @typescript-eslint/prefer-for-of
              for (let i = 0; i < contact.phone.length; i++) {
                format = format.replace('x', contact.phone[i]);
              }
              contact.formattedPhone = format;
            } else if (contact.phone.length === 11) {
              let format = 'x (xxx) xxx-xxxx';
              // eslint-disable-next-line @typescript-eslint/prefer-for-of
              for (let i = 0; i < contact.phone.length; i++) {
                format = format.replace('x', contact.phone[i]);
              }
              contact.formattedPhone = format;
            } else if (contact.phone.length === 7) {
              let format = 'xxx-xxxx';
              // eslint-disable-next-line @typescript-eslint/prefer-for-of
              for (let i = 0; i < contact.phone.length; i++) {
                format = format.replace('x', contact.phone[i]);
              }
              contact.formattedPhone = format;
            } else {
              contact.formattedPhone = contact.phone;
            }
          }
          if (!!contact.mobilePhone) {
            contact.mobilePhone = contact.mobilePhone.trim();
            if (contact.mobilePhone.length === 10) {
              let format = '(xxx) xxx-xxxx';
              // eslint-disable-next-line @typescript-eslint/prefer-for-of
              for (let i = 0; i < contact.mobilePhone.length; i++) {
                format = format.replace('x', contact.mobilePhone[i]);
              }
              contact.formattedMobilePhone = format;
            } else if (contact.mobilePhone.length === 11) {
              let format = 'x (xxx) xxx-xxxx';
              // eslint-disable-next-line @typescript-eslint/prefer-for-of
              for (let i = 0; i < contact.mobilePhone.length; i++) {
                format = format.replace('x', contact.mobilePhone[i]);
              }
              contact.formattedMobilePhone = format;
            } else if (contact.mobilePhone.length === 7) {
              let format = 'xxx-xxxx';
              // eslint-disable-next-line @typescript-eslint/prefer-for-of
              for (let i = 0; i < contact.mobilePhone.length; i++) {
                format = format.replace('x', contact.mobilePhone[i]);
              }
              contact.formattedMobilePhone = format;
            } else {
              contact.formattedMobilePhone = contact.mobilePhone;
            }
          }
          if (!!contact.fax) {
            contact.fax = contact.fax.trim();
            if (contact.fax.length === 10) {
              let format = '(xxx) xxx-xxxx';
              // eslint-disable-next-line @typescript-eslint/prefer-for-of
              for (let i = 0; i < contact.fax.length; i++) {
                format = format.replace('x', contact.fax[i]);
              }
              contact.formattedFax = format;
            } else if (contact.fax.length === 11) {
              let format = 'x (xxx) xxx-xxxx';
              // eslint-disable-next-line @typescript-eslint/prefer-for-of
              for (let i = 0; i < contact.fax.length; i++) {
                format = format.replace('x', contact.fax[i]);
              }
              contact.formattedFax = format;
            } else if (contact.fax.length === 7) {
              let format = 'xxx-xxxx';
              // eslint-disable-next-line @typescript-eslint/prefer-for-of
              for (let i = 0; i < contact.fax.length; i++) {
                format = format.replace('x', contact.fax[i]);
              }
              contact.formattedFax = format;
            } else {
              contact.formattedFax = contact.fax;
            }
          }
          contact.name = contact.firstName + ' ' + contact.lastName;
        });
        this.filteredContacts = company.contacts;
      }
    });
    if (!!this.data.contact) {
      this.applyFilter(this.data.contact.name);
    }
  }

  ngOnDestroy() {
    this.$companySub.unsubscribe();
  }

  applyFilter(query) {
    this.filteredContacts = query
      ? this.contacts.filter((c) =>
          c.name
            .toString()
            .toLocaleLowerCase()
            .includes(query.toString().toLocaleLowerCase())
        )
      : this.contacts;
  }

  rowClicked(contact) {
    this.store.dispatch(
      new LoadContact({ companyId: this.company.id, contactId: contact.id })
    );
    this.store.dispatch(
      new OpenDrawer({
        component: ContactSlideOutFormComponent,
        data: {
          company: {
            id: this.company.id,
            name: this.company.name,
            address: formatAddressIntoOneLine(this.company.shippingAddress)
          },
          hasCompanyInput: true,
          contact
        }
      })
    );
  }

  addCallNote(contact: Contact) {
    this.store.dispatch(
      new OpenDrawer({
        component: CallNoteFormComponent,
        data: {
          company: this.company,
          selectedContact: {
            id: contact.id,
            name: contact.firstName + ' ' + contact.lastName
          }
        }
      })
    );
  }

  openDialog(contact): void {
    const dialogRef = this.dialog.open(CompanyContactDialog, {
      width: '250px',
      data: contact
    });
  }
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'company-contact-dialog',
  templateUrl: 'company-contacts-dialog.component.html'
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CompanyContactDialog {
  constructor(
    public dialogRef: MatDialogRef<CompanyContactDialog>,
    private store: Store<RootState>,
    @Inject(MAT_DIALOG_DATA) public data: Contact
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  deleteContact() {
    this.store.dispatch(new DeleteContact(this.data));
    this.dialogRef.close();
  }
}
