import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { CallNoteV2 } from '../../../../shared/models/call-notes/call-note-V2';
import { CompanyV2 } from '../../../../shared/models/companies/company-V2';
import { ContactV2 } from '../../../../shared/models/contacts/contact-V2';
import { ToolbarService } from '../../../../shared/services/toolbar.service';
import { CustomFieldV2 } from './../../../../shared/models/admin/custom-fields/custom-field-V2';
import { CompanyPermissionsService } from './../../../../shared/services/permissions/company-permissions.service';

@Component({
	selector: 'company-profile-contacts',
	templateUrl: './company-profile-contacts.component.html',
	styleUrls: ['../../companies.scss']
})
export class CompanyProfileContactsComponent implements OnInit, OnDestroy {
	@Input() loadingContacts: boolean;
	@Input() companyJPI: CompanyV2;
	@Input() selectedCallNote: CallNoteV2;
	@Input() callNotePopup: boolean = false;
	@Input() standardContactFields: CustomFieldV2[];
	@Input() contactCustomFields: CustomFieldV2[];
	editContactsFeature: number;
	contactPopup$: boolean;
	selectedContact: ContactV2;
	editContactPopup: boolean;
	contactPressTimer: number;
	isHeld: boolean = false;
	companyId: number;
	customContactSubscription: Subscription;

	constructor(_companyPermissionsService: CompanyPermissionsService,
		private _toolbarService: ToolbarService) {
		_companyPermissionsService.getContactsPermission().then(result => this.editContactsFeature = result);
	}

	ngOnInit(): void {
		this.companyId = this.companyJPI.id;
		this.customContactSubscription = this._toolbarService.customContactAnnounced.subscribe((result) => { this.contactPopup$ = result; });
	}

	ngOnDestroy(): void {
		this.customContactSubscription.unsubscribe();
	}

	calculateFullName(contact): string {
		return contact.firstName + " " + contact.lastName;
	}

	phoneValue(contact): string {
		if (contact.phone) {
			let newPhone = contact.phone.replace(/[^0-9]/g, "");
			if (newPhone.length == 10) { newPhone = `(${newPhone[0]}${newPhone[1]}${newPhone[2]}) ${newPhone[3]}${newPhone[4]}${newPhone[5]}-${newPhone[6]}${newPhone[7]}${newPhone[8]}${newPhone[9]}`; }
			return newPhone;
		}
	}

	mobileValue(contact): string {
		if (contact.mobilePhone) {
			let newPhone = contact.mobilePhone.replace(/[^0-9]/g, "");
			if (newPhone.length == 10) { newPhone = `(${newPhone[0]}${newPhone[1]}${newPhone[2]}) ${newPhone[3]}${newPhone[4]}${newPhone[5]}-${newPhone[6]}${newPhone[7]}${newPhone[8]}${newPhone[9]}`; }
			return newPhone;
		}
	}

	contactMouseUp(): void {
		clearTimeout(this.contactPressTimer);
		setTimeout(() => {
			this.isHeld = false;
		}, 50);
	}

	contactMouseDown(): void {
		this.contactPressTimer = window.setTimeout(() => {
			this.isHeld = true;
		}, 300);
	}

	editContact(event): void {
		if (!(event.event.target.cellIndex == 0 || event.event.target.cellIndex == undefined) && (event.event.target.cellIndex != 3 && (event.data.Email != event.event.target.innerHTML)) && this.isHeld == false) {
			this.selectedContact = event.data;
			this.editContactPopup = true;
		} else if (event.event.target.cellIndex == 0 || event.event.target.cellIndex == undefined) {
			this.newCallNoteFromContact(event.data);
		}
	}

	newCallNoteFromContact(contact: ContactV2): void {
		this.selectedCallNote = Object.assign({ company: { id: this.companyJPI.id }, contacts: [contact], type: {}, followUp: { assignedTo: {} } });
		this.callNotePopup = true;
	}
}
