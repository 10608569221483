import { Component, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { User } from 'src/app/models/admin/users/user';
import { UsersMap } from 'src/app/models/admin/users/users-map';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SignInAsOther } from 'src/app/store/auth/auth.actions';
import { CloseDrawer } from 'src/app/store/layout/layout.actions';
import { RootState } from 'src/app/store/store.reducer';
import { selectUsers } from 'src/app/store/users/selectors/multi-select-users.selector';

@Component({
  selector: 'tn-sign-in-as-user',
  templateUrl: './sign-in-as-user.component.html',
  styleUrls: ['./sign-in-as-user.component.scss']
})
export class SignInAsUserComponent implements OnInit {
  $users: Observable<User[]>;
  searchText: '';
  selectedUser: User;

  constructor(private store: Store<RootState>, private authService: AuthService) {}

  ngOnInit(): void {
    this.$users = this.store.select(selectUsers).pipe(
      map((usersMap: UsersMap) => {
        return Object.values(usersMap);
      })
    );
  }

  closeDrawer() {
    this.store.dispatch(new CloseDrawer());
  }

  signInMenuOpen(user: User) {
    this.selectedUser = user;
  }

  signInAs() {
    this.store.dispatch(new SignInAsOther(this.selectedUser.id));
  }
}
