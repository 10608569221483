import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { layoutReducer } from './layout.reducer';
import { EffectsModule } from '@ngrx/effects';
import { LayoutEffects } from './layout.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('layout', layoutReducer),
    EffectsModule.forFeature([LayoutEffects])
  ]
})
export class LayoutStateModule {}
