import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { RootState } from '../store.reducer';
import { Observable, catchError, map, mergeMap, of, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import {
  DmsActionTypes,
  DmsActions,
  LoadMagentoDmsLinkedCompanyFailure,
  LoadMagentoDmsLinkedCompanySuccess
} from './dms.actions';
import { DmsService } from 'src/app/services/dms/dms.service';

@Injectable()
export class DmsEffects {
  loadMagentoDmsLinkedCompany$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DmsActionTypes.LoadMagentoDmsLinkedCompany),
      mergeMap((action) =>
        this.dmsService.getMagentoDmsLinkedCompany(action.payload).pipe(
          map((data: any) => new LoadMagentoDmsLinkedCompanySuccess(data)),
          catchError((error: HttpErrorResponse) =>
            of(new LoadMagentoDmsLinkedCompanyFailure(error))
          )
        )
      )
    )
  );
  constructor(
    private dmsService: DmsService,
    private actions$: Actions<DmsActions>,
    private store$: Store<RootState>
  ) {}
}
