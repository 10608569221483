import { Action } from '@ngrx/store';
import { Mileage } from '../../models/activities/mileage';
import { HttpErrorResponse } from '@angular/common/http';

export enum MileageActionTypes {
  SelectMileageId = '[Mileage] Select Mileage ID',
  ClearMileageId = '[Mileage] Clear Selected Mileage ID',
  LoadMileage = '[Mileage] Load Mileage',
  LoadMileageSuccess = '[Mileage] Load Mileage Success',
  LoadMileageFailure = '[Mileage] Load Mileage Failure',
  AddMileage = '[Mileage] Add Mileage',
  AddMileageSuccess = '[Mileage] Add Mileage Success',
  AddMileageFailure = '[Mileage] Add Mileage Failure',
  UpdateMileage = '[Mileage] Update Mileage',
  UpdateMileageSuccess = '[Mileage] Update Mileage Success',
  UpdateMileageFailure = '[Mileage] Update Mileage Failure',
  DeleteMileage = '[Mileage] Delete Mileage',
  DeleteMileageSuccess = '[Mileage] Delete Mileage Success',
  DeleteMileageFailure = '[Mileage] Delete Mileage Failure',
  SetDateRange = '[Expenses] Set Date Range'
}
export class SelectMileageId implements Action {
  readonly type = MileageActionTypes.SelectMileageId;
  constructor(public payload: number) {}
}

export class ClearMileageId implements Action {
  readonly type = MileageActionTypes.ClearMileageId;
}

export class LoadMileage implements Action {
  readonly type = MileageActionTypes.LoadMileage;
  constructor(
    public payload?: {
      startDate: string;
      endDate: string;
    }
  ) {}
}

export class LoadMileageSuccess implements Action {
  readonly type = MileageActionTypes.LoadMileageSuccess;
  constructor(public payload: Mileage[]) {}
}

export class LoadMileageFailure implements Action {
  readonly type = MileageActionTypes.LoadMileageFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class AddMileage implements Action {
  readonly type = MileageActionTypes.AddMileage;
  constructor(public payload: Mileage) {}
}

export class AddMileageSuccess implements Action {
  readonly type = MileageActionTypes.AddMileageSuccess;
  constructor(public payload: Mileage) {}
}

export class AddMileageFailure implements Action {
  readonly type = MileageActionTypes.AddMileageFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class UpdateMileage implements Action {
  readonly type = MileageActionTypes.UpdateMileage;
  constructor(public payload: Mileage) {}
}

export class UpdateMileageSuccess implements Action {
  readonly type = MileageActionTypes.UpdateMileageSuccess;
  constructor(public payload: Mileage) {}
}

export class UpdateMileageFailure implements Action {
  readonly type = MileageActionTypes.UpdateMileageFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class DeleteMileage implements Action {
  readonly type = MileageActionTypes.DeleteMileage;
  constructor(public payload: number) {}
}

export class DeleteMileageSuccess implements Action {
  readonly type = MileageActionTypes.DeleteMileageSuccess;
  constructor(public payload: number) {}
}

export class DeleteMileageFailure implements Action {
  readonly type = MileageActionTypes.DeleteMileageFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class SetDateRange implements Action {
  readonly type = MileageActionTypes.SetDateRange;
  constructor(public payload: { begin: string; end: string }) {}
}

export type MileageActions =
  | LoadMileage
  | LoadMileageSuccess
  | LoadMileageFailure
  | AddMileage
  | AddMileageSuccess
  | AddMileageFailure
  | UpdateMileage
  | UpdateMileageSuccess
  | UpdateMileageFailure
  | DeleteMileage
  | DeleteMileageSuccess
  | DeleteMileageFailure
  | SelectMileageId
  | ClearMileageId
  | SetDateRange;
