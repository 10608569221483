<contact-popup *ngIf="contactPopup" [(contactPopup)]="contactPopup" [(contact)]="displayedContact"
	[(contacts)]="contacts" [(companyId)]="callNote.company.id"></contact-popup>

<div class="white-padding" fxLayout="row">
	<div fxFlex="4"></div>
	<div class="client-input edit-company-dropdown input-padding row-click popup-expanded-row" fxLayout="row"
		(click)="expandCallNote()" fxFlex>
		<div fxFlex>Call Note</div>
		<mat-icon *ngIf="!callNoteExpanded" class="icon-large">arrow_drop_down</mat-icon>
		<mat-icon *ngIf="callNoteExpanded" class="icon-large">arrow_drop_up</mat-icon>
	</div>
	<div fxFlex="4"></div>
</div>
<div *ngIf="callNoteExpanded" fxLayout="column">
	<div class="client-input input-padding" fxLayout="row">
		<div fxFlex="4"></div>
		<div class="client-input" fxLayout="column" fxFlex="34">
			<span>Call Date</span>
			<dx-date-box *ngIf="editCallNotesFeature==1" [(value)]="callNote.callDate" type="date"></dx-date-box>
			<dx-date-box *ngIf="editCallNotesFeature==0" [(value)]="callNote.callDate" type="date" disabled="true">
			</dx-date-box>
		</div>
		<div fxFlex="8"></div>
		<div fxFlex class="client-input">
			<div fxLayout="row">
				<div fxFlex fxLayout="column">
					<span>Company</span>
					<a *ngIf="clickEditCompany==true" class="link" style="text-decoration: none"
						[ngClass]="{ 'text-muted': (editCallNotesFeature==0) }" style="font-weight: 700"
						(click)="openCompanyProfile(displayedCompany)">{{displayedCompany.name}}</a>
					<span *ngIf="clickEditCompany==true" [ngClass]="{ 'text-muted': (editCallNotesFeature==0) }"
						fxLayout="row wrap">
						<span
							*ngIf="displayedCompany.addressLine1 && (displayedCompany.city || displayedCompany.state || (displayedCompany.city && displayedCompany.state))">{{displayedCompany.addressLine1}},&nbsp;</span>
						<span
							*ngIf="displayedCompany.addressLine1 && !(displayedCompany.city || displayedCompany.state || (displayedCompany.city && displayedCompany.state))">{{displayedCompany.addressLine1}}</span>
						<span
							*ngIf="displayedCompany.addressLine2 && (displayedCompany.city || displayedCompany.state || (displayedCompany.city && displayedCompany.state))">{{displayedCompany.addressLine2}},&nbsp;</span>
						<span
							*ngIf="displayedCompany.addressLine2 && !(displayedCompany.city || displayedCompany.state || (displayedCompany.city && displayedCompany.state))">{{displayedCompany.addressLine2}}</span>
						<span
							*ngIf="displayedCompany.city && displayedCompany.state">{{displayedCompany.city}},&nbsp;</span>
						<span *ngIf="displayedCompany.city && !displayedCompany.state">{{displayedCompany.city}}</span>
						<span *ngIf="displayedCompany.state">{{displayedCompany.state}}</span>
						<span
							*ngIf="!displayedCompany.addressLine1 && !displayedCompany.addressLine2 && !displayedCompany.city && !displayedCompany.state && !displayedCompany.city && !displayedCompany.postCode"
							class="text-muted">No address provided</span>
					</span>
				</div>
				<div *ngIf="clickEditCompany==true && editCallNotesFeature==1" fxLayoutAlign="end center">
					<mat-icon style="cursor: pointer" (click)="changeCompany()">edit_mode</mat-icon>
				</div>
			</div>

			<dx-select-box #companySelectBox [dataSource]="companies" displayExpr="name" searchEnabled="true"
				placeholder="Search for a company" (onValueChanged)="companyChanged($event)"
				[visible]="companySelectBoxDisplayed" itemTemplate="itemTemplate" (onKeyUp)="companySearch()">
				<div *dxTemplate="let company of 'itemTemplate'">
					<div style="font-weight: 700">{{company.name}}</div>
					<div fxLayout="row">
						<span>{{company | companyAddress}}</span>
					</div>
				</div>
			</dx-select-box>
		</div>
		<div fxFlex="4"></div>
	</div>
	<div class="client-input input-padding" fxLayout="row">
		<div fxFlex="4"></div>
		<div *ngIf="contacts && clickEditCompany==true" fxFlex class="client-input">
			<div fxLayout="row" fxFlex>
				<div fxLayout="column" fxFlex>
					<div fxLayout="row" fxLayoutAlign="space-between center">
						<div>Contacts</div>
						<div *ngIf="contactsFeature==1" class="link" (click)="createNewContact()">New
							Contact</div>
					</div>
					<div *ngIf="contactsList==true" fxLayout="row wrap">
						<div *ngFor="let contact of contactsInList; let last = last" fxLayout="row">
							<div *ngIf="!last">
								<span *ngIf="!last" class="link" (click)="openContactDetails(contact)"
									style="font-weight: 700">{{contact.firstName}}
									{{contact.lastName}}</span>,&nbsp;
							</div>
							<div *ngIf="last">
								<span class="link" (click)="openContactDetails(contact)"
									style="font-weight: 700">{{contact.firstName}}
									{{contact.lastName}}</span>
							</div>
						</div>
					</div>
					<dx-tag-box #contactTagBox *ngIf="editCallNotesFeature==1 && contactsList==false"
						[dataSource]="contacts" displayExpr="fullName" valueExpr="id" [searchEnabled]="true"
						[(value)]="callNote.contactIds" [showSelectionControls]="true" applyValueMode="instantly"
						(onFocusOut)="offContactFocus($event)" [hideSelectedItems]="true"
						[visible]="contactTagBoxDisplayed" [multiline]="true"></dx-tag-box>
					<dx-tag-box *ngIf="editCallNotesFeature==0 && contactsList==false" [dataSource]="contacts"
						displayExpr="fullName" valueExpr="id" [(value)]="callNote.contactIds" [searchEnabled]="true"
						[showSelectionControls]="true" applyValueMode="instantly" [hideSelectedItems]="true"
						[multiline]="true" disabled="true" [visible]="contactTagBoxDisplayed"></dx-tag-box>
				</div>
				<div *ngIf="contactsList==true && editCallNotesFeature==1" fxLayoutAlign="end center">
					<mat-icon style="cursor: pointer" (click)="changeContacts()">edit_mode</mat-icon>
				</div>
			</div>
		</div>
		<div fxFlex="4"></div>
	</div>
	<div class="client-input input-padding" fxLayout="row">
		<div fxFlex="4"></div>
		<div fxFlex class="client-input" fxLayout="column">
			<span>Call Type</span>
			<dx-select-box *ngIf="editCallNotesFeature==1 && callNote.type" [dataSource]="callTypes" displayExpr="name" valueExpr="id"
				[(value)]="callNote.type.id" [searchEnabled]="true" (onSelectionChanged)="callTypeChanged($event)">
			</dx-select-box>
			<dx-select-box *ngIf="editCallNotesFeature==0 && callNote.type" [dataSource]="callTypes" displayExpr="name" valueExpr="id"
				[(value)]="callNote.type.id" disabled="true"></dx-select-box>
		</div>
		<div fxFlex="4"></div>
	</div>
	<div fxLayout="row wrap">
		<ng-template ngFor let-field [ngForOf]="callNote.fields">
			<div *ngIf="field.field && field.field.type && field.field.type.legacyId == 22"
				class="client-input input-padding" fxFlex="100" fxLayout="row">
				<div fxFlex="4"></div>
				<div fxFlex class="client-input" fxLayout="column">
					<span [innerText]="field.field.name"></span>
					<dx-text-area *ngIf="editCallNotesFeature==1" [(value)]="field.value" [autoResizeEnabled]="true">
					</dx-text-area>
					<dx-text-area *ngIf="editCallNotesFeature==0" [(value)]="field.value" [autoResizeEnabled]="true"
						disabled="true"></dx-text-area>
				</div>
				<div fxFlex="4"></div>
			</div>
			<div *ngIf="field.field && field.field.type && field.field.type.legacyId==14"
				class="client-input input-padding" fxFlex="50" fxLayout="row">
				<div fxFlex="8"></div>
				<div fxFlex="84" class="client-input" fxLayout="row" fxLayoutAlign="start center">
					<dx-check-box *ngIf="editCallNotesFeature==1" [(value)]="field.value"></dx-check-box>
					<dx-check-box *ngIf="editCallNotesFeature==0" [(value)]="field.value" disabled="true">
					</dx-check-box>
					<span [innerText]="field.field.name" style="padding-left: 5px;"></span>
				</div>
				<div fxFlex="8"></div>
			</div>
			<div *ngIf="field.field && field.field.type && field.field.type.legacyId==5"
				class="client-input input-padding" fxFlex="50" fxLayout="row">
				<div fxFlex="8"></div>
				<div fxFlex="84" class="client-input" fxLayout="column">
					<span [innerText]="field.field.name"></span>
					<dx-number-box *ngIf="editCallNotesFeature==1" [(value)]="field.value"></dx-number-box>
					<dx-number-box *ngIf="editCallNotesFeature==0" [(value)]="field.value" disabled="true">
					</dx-number-box>
				</div>
				<div fxFlex="8"></div>
			</div>
			<div *ngIf="field.field && field.field.type && field.field.type.legacyId==3"
				class="client-input input-padding" fxFlex="50" fxLayout="row">
				<div fxFlex="8"></div>
				<div fxFlex="84" class="client-input" fxLayout="column">
					<span [innerText]="field.field.name"></span>
					<dx-number-box *ngIf="editCallNotesFeature==1" format="$ #,##0.##" [(value)]="field.value">
					</dx-number-box>
					<dx-number-box *ngIf="editCallNotesFeature==0" format="$ #,##0.##" [(value)]="field.value"
						disabled="true"></dx-number-box>
				</div>
				<div fxFlex="8"></div>
			</div>
			<div *ngIf="field.field && field.field.type && field.field.type.legacyId==6"
				class="client-input input-padding" fxFlex="50" fxLayout="row">
				<div fxFlex="8"></div>
				<div fxFlex="84" class="client-input" fxLayout="column">
					<span [innerText]="field.field.name"></span>
					<dx-select-box *ngIf="editCallNotesFeature==1" [dataSource]="getOptionsForDropdowns(field)"
						[(value)]="field.value"></dx-select-box>
					<dx-select-box *ngIf="editCallNotesFeature==0" [(value)]="field.value" disabled="true">
					</dx-select-box>
				</div>
				<div fxFlex="8"></div>
			</div>
			<div *ngIf="field.field && field.field.type && field.field.type.legacyId==10"
				class="client-input input-padding" fxFlex="50" fxLayout="row">
				<div fxFlex="8"></div>
				<div fxFlex="84" class="client-input" fxLayout="column">
					<span [innerText]="field.field.name"></span>
					<dx-date-box *ngIf="editCallNotesFeature==1" type="date" [(value)]="field.value"></dx-date-box>
					<dx-date-box *ngIf="editCallNotesFeature==0" type="date" [(value)]="field.value" disabled="true">
					</dx-date-box>
				</div>
				<div fxFlex="8"></div>
			</div>
			<div *ngIf="field.field && field.field.type && field.field.type.legacyId==35"
				class="client-input input-padding" fxFlex="50" fxLayout="row">
				<div fxFlex="8"></div>
				<div fxFlex="84" class="client-input" fxLayout="column">
					<span [innerText]="field.field.name"></span>
					<dx-date-box *ngIf="editCallNotesFeature==1" type="date" [(value)]="field.value"></dx-date-box>
					<dx-date-box *ngIf="editCallNotesFeature==0" type="date" [(value)]="field.value" disabled="true">
					</dx-date-box>
				</div>
				<div fxFlex="8"></div>
			</div>
		</ng-template>
	</div>
</div>

<dx-popup [width]="325" [height]="250" [showTitle]="false" [dragEnabled]="false" [closeOnOutsideClick]="true"
	[visible]="contactDetailsPopup" shadingColor="rgba(0,0,0,0.5)" (onHiding)="hidingContactDetails()">
	<div *dxTemplate="let data of 'content'">
		<div>
			<h3>CONTACT DETAILS</h3>
		</div>
		<div fxLayout="row" style="padding-bottom: 10px">
			<span style="font-weight: 700" fxLayoutAlign="start center">Name:&nbsp;</span>
			<span fxLayoutAlign="start center">{{displayedContact.fullName}}</span>
		</div>
		<div fxLayout="row" style="padding-bottom: 10px">
			<span style="font-weight: 700" fxLayoutAlign="start center">Title:&nbsp;</span>
			<span fxLayoutAlign="start center">{{displayedContact.title}}</span>
		</div>
		<div fxLayout="row" style="padding-bottom: 10px">
			<span style="font-weight: 700" fxLayoutAlign="start center">Email:&nbsp;</span>
			<span fxLayoutAlign="start center">{{displayedContact.email}}</span>
		</div>
		<div fxLayout="row" style="padding-bottom: 10px">
			<span style="font-weight: 700" fxLayoutAlign="start center">Phone:&nbsp;</span>
			<span fxLayoutAlign="start center">{{displayedContact.phone}}</span>
		</div>
		<div fxLayout="row" fxLayoutAlign="end center">
			<span fxLayoutAlign="end center" class="button button-blue" (click)="openContact()">Edit Contact</span>
		</div>
	</div>
</dx-popup>


<!-- <dx-popup [width]="windowWidth" [height]="windowHeight" [showTitle]="false" [dragEnabled]="false"
	[closeOnOutsideClick]="true" [visible]="companyProfilePopup" shadingColor="rgba(0,0,0,0.5)"
	(onHiding)="profileClosed()">
	<div *dxTemplate="let data of 'content'">
		<div fxLayout="row" fxLayoutAlign="space-between center">
			<span class="link" (click)="openCompanyInNewTab()">Open in a new tab</span>
			<mat-icon style="cursor: pointer" (click)="companyProfilePopup=false">clear</mat-icon>
		</div>
		<dx-scroll-view [height]="'inherit'">
			<company-profile [isList]="true" [isPopup]="true" [companyId]="companyProfileCompanyId"></company-profile>
		</dx-scroll-view>
	</div>
</dx-popup> -->

<company-profile-popup *ngIf="companyProfilePopup" [(companyProfilePopup)]="companyProfilePopup" [companyId]="companyProfileCompanyId"></company-profile-popup>