import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { userSettingsReducer } from './user-settings.reducer';
import { EffectsModule } from '@ngrx/effects';
import { UserSettingsEffects } from './user-settings.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('userSettings', userSettingsReducer),
    EffectsModule.forFeature([UserSettingsEffects])
  ]
})
export class UserSettingsStateModule {}
