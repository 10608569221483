<div class="opportunity-form">
  <mat-accordion [multi]="true" class="opportunity-form-panels">
    <h2>Opportunity</h2>
    <mat-progress-bar
      *ngIf="$pending | async"
      class="form-divider-progress-bar"
      mode="indeterminate"
    ></mat-progress-bar>
    <div>
      <form [formGroup]="labelsForm" autocomplete="off">
        <mat-divider class="form-divider"></mat-divider>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <h4 class="form-panel-header">
              Labels<span style="color: #f44336" *ngIf="hasRequiredLabel">*</span>
            </h4>
          </mat-expansion-panel-header>
          <mat-form-field *ngFor="let group of allLabelGroupsArray" class="full-width">
            <mat-label>{{ group.name }} </mat-label>
            <mat-select
              [required]="group.required"
              [formControlName]="group.id"
              (selectionChange)="labelChange($event)"
              [multiple]="group.allowMultiple"
              style="margin: 5px"
            >
              <mat-option [value]="null"> No selection </mat-option>
              <mat-option *ngFor="let label of group.labels" [value]="label.id">
                {{ label.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-expansion-panel>
      </form>
    </div>
    <mat-divider class="form-divider"></mat-divider>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <h4 class="form-panel-header">
          Opportunity Details<span style="color: #f44336" *ngIf="hasRequiredField"
            >*</span
          >
        </h4>
      </mat-expansion-panel-header>
      <form [formGroup]="opportunityForm" autocomplete="off">
        <mat-form-field class="full-width">
          <input
            matInput
            [required]="true"
            placeholder="Opportunity Name"
            autocomplete="off"
            formControlName="name"
            [value]="opportunity?.name"
          />
        </mat-form-field>

        <tn-company-select
          [isRequired]="true"
          (companySearchEvent)="onCompanySearch($event)"
          [$companySearchResults]="$companies"
          [selectedCompany]="opportunity?.companyItem"
          [$error]="$companiesSearchError"
          (companySelected)="onCompanySelect($event)"
          [$pending]="$companiesSearchPending"
        >
        </tn-company-select>

        <tn-item-select
          *ngIf="opportunity?.id"
          [isRequired]="true"
          [placeholder]="'Opportunity Owner'"
          [$selectableItems]="$allUsers"
          [selectedItem]="opportunity?.ownedByItem"
          (itemSelected)="onOwnerSelected($event)"
        ></tn-item-select>

        <tn-item-select
          *ngIf="!opportunity?.id"
          [isRequired]="true"
          [placeholder]="'Opportunity Owner'"
          [$selectableItems]="$allUsers"
          [selectedItem]="currentUser"
          (itemSelected)="onOwnerSelected($event)"
        >
        </tn-item-select>

        <tn-multi-select
          [selectedItems]="opportunity?.assignedToItems || []"
          (selectedItemsChange)="onUserSelected($event)"
          [$allItems]="$allUsers"
          [placeholder]="'Assigned User'"
        >
        </tn-multi-select>

        <div fxLayout="column">
          <div fxFlex="100" fxLayout="row">
            <div fxFlex="calc(50% - 12px)" fxLayoutGap="12px">
              <mat-form-field>
                <input
                  matInput
                  [matDatepicker]="closeDatePicker"
                  autocomplete="off"
                  formControlName="estimatedCloseDate"
                  placeholder="Estimated Close Date"
                  [value]="opportunity?.estimatedCloseDate"
                  (click)="closeDatePicker.open()"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="closeDatePicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #closeDatePicker></mat-datepicker>
              </mat-form-field>
              <mat-form-field>
                <input
                  matInput
                  type="number"
                  placeholder="Probability"
                  autocomplete="off"
                  formControlName="probability"
                  [value]="opportunity?.probability"
                />
                <div
                  class="feedback"
                  *ngIf="
                    opportunityForm['controls']['probability'].errors?.min ||
                    opportunityForm['controls']['probability'].errors?.max
                  "
                >
                  Must be a number from 1 to 100
                </div>
              </mat-form-field>
            </div>
          </div>
        </div>
        <h4 *ngIf="opportunity?.items.length">Total: {{ total | currency }}</h4>
        <h4 *ngIf="opportunity?.probability && opportunity?.items.length">
          WeightedTotal:
          {{ weightedTotal | currency }}
        </h4>

        <mat-form-field class="full-width">
          <textarea
            matInput
            placeholder="Opportunity Description"
            autocomplete="off"
            formControlName="notes"
            [value]="opportunity?.notes"
          ></textarea>
        </mat-form-field>
      </form>
    </mat-expansion-panel>
    <div class="form-actions" fxLayout="row-reverse" fxLayoutAlign="space-between center">
      <div fxLayout="row-reverse" fxLayoutGap="12px">
        <button
          type="button"
          mat-raised-button
          color="primary"
          (click)="onSubmit()"
          [disabled]="opportunityForm.invalid"
        >
          Create
        </button>
        <button preventDefaultAction mat-raised-button color="white" (click)="cancel()">
          Cancel
        </button>
      </div>
    </div>
  </mat-accordion>
</div>
