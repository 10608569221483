import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ProjectBid } from 'src/app/models/admin/projects/project-bid';

export enum ProjectBidsActionTypes {
  SelectProjectBidId = '[ProjectBids] Select ProjectBid Id',
  ClearProjectBidId = '[ProjectBids] Clear Project Bid Id',
  LoadProjectBids = '[ProjectBids] Load ProjectBids',
  LoadProjectBidsSuccess = '[ProjectBids] Load ProjectBids Success',
  LoadProjectBidsFailure = '[ProjectBids] Load ProjectBids Failure',
  AddProjectBids = '[ProjectBids] Add ProjectBids',
  AddProjectBidsSuccess = '[ProjectBids] Add ProjectBids Success',
  AddProjectBidsFailure = '[ProjectBids] Add ProjectBids Failure',
  UpdateProjectBids = '[ProjectBids] Update ProjectBids',
  UpdateProjectBidsSuccess = '[ProjectBids] Update ProjectBids Success',
  UpdateProjectBidsFailure = '[ProjectBids] Update ProjectBids Failure',
  DeleteProjectBids = '[ProjectBids] Delete ProjectBids',
  DeleteProjectBidsSuccess = '[ProjectBids] Delete ProjectBids Success',
  DeleteProjectBidsFailure = '[ProjectBids] Delete ProjectBids Failure'
}

export class SelectProjectBidId implements Action {
  readonly type = ProjectBidsActionTypes.SelectProjectBidId;
  constructor(public payload: number) {}
}

export class ClearProjectBidId implements Action {
  readonly type = ProjectBidsActionTypes.ClearProjectBidId;
}

export class LoadProjectBids implements Action {
  readonly type = ProjectBidsActionTypes.LoadProjectBids;
}

export class LoadProjectBidsSuccess implements Action {
  readonly type = ProjectBidsActionTypes.LoadProjectBidsSuccess;
  constructor(public payload: ProjectBid[]) {}
}

export class LoadProjectBidsFailure implements Action {
  readonly type = ProjectBidsActionTypes.LoadProjectBidsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class AddProjectBids implements Action {
  readonly type = ProjectBidsActionTypes.AddProjectBids;
  constructor(public payload: ProjectBid) {}
}

export class AddProjectBidsSuccess implements Action {
  readonly type = ProjectBidsActionTypes.AddProjectBidsSuccess;
  constructor(public payload: ProjectBid) {}
}

export class AddProjectBidsFailure implements Action {
  readonly type = ProjectBidsActionTypes.AddProjectBidsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class UpdateProjectBids implements Action {
  readonly type = ProjectBidsActionTypes.UpdateProjectBids;
  constructor(public payload: ProjectBid) {}
}

export class UpdateProjectBidsSuccess implements Action {
  readonly type = ProjectBidsActionTypes.UpdateProjectBidsSuccess;
  constructor(public payload: ProjectBid) {}
}

export class UpdateProjectBidsFailure implements Action {
  readonly type = ProjectBidsActionTypes.UpdateProjectBidsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class DeleteProjectBids implements Action {
  readonly type = ProjectBidsActionTypes.DeleteProjectBids;
  constructor(public payload: number) {}
}

export class DeleteProjectBidsSuccess implements Action {
  readonly type = ProjectBidsActionTypes.DeleteProjectBidsSuccess;
  constructor(public payload: number) {}
}

export class DeleteProjectBidsFailure implements Action {
  readonly type = ProjectBidsActionTypes.DeleteProjectBidsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export type ProjectBidsActions =
  | SelectProjectBidId
  | LoadProjectBids
  | LoadProjectBidsSuccess
  | LoadProjectBidsFailure
  | AddProjectBids
  | AddProjectBidsSuccess
  | AddProjectBidsFailure
  | UpdateProjectBids
  | UpdateProjectBidsSuccess
  | UpdateProjectBidsFailure
  | DeleteProjectBids
  | DeleteProjectBidsSuccess
  | DeleteProjectBidsFailure
  | ClearProjectBidId;
