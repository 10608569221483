<div>Current Territory Assignments</div>
<div *ngIf="selectPrimaryUser" class="text-small">
  Select primary sales rep for the selected companies
</div>
<div *ngFor="let territory of currentTerritoryAssignments">
  <div class="popup-expanded-row" (click)="expandTerritory(territory)">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div style="font-weight: 500">{{ territory.name }}</div>
      <div>
        Assigned to {{ territory.companyCount }}/{{ this.selectedRows.length }} selected
        companies
      </div>
      <mat-icon *ngIf="!territory.expanded" class="icon-large">arrow_drop_down</mat-icon>
      <mat-icon *ngIf="territory.expanded" class="icon-large">arrow_drop_up</mat-icon>
    </div>
  </div>
  <div *ngIf="territory.expanded">
    <div *ngIf="territory.users && territory.users.length">
      <div
        *ngFor="let user of territory.users"
        fxLayout="row"
        style="padding: 3px 0 3px 20px"
      >
        <input
          *ngIf="selectPrimaryUser"
          type="radio"
          name="primaryUser"
          (click)="primaryUserChanged(user, territory)"
        />

        <div style="padding-right: 50px; padding-left: 15px;">
          {{ user.firstName }}&nbsp;{{ user.lastName }}
        </div>
        <div class="text-muted">{{ user.role?.name }}</div>
      </div>
    </div>
    <div *ngIf="!territory.users || territory.users.length == 0">
      <div style="padding-left: 20px">This territory has no users assigned to it.</div>
    </div>
  </div>
</div>
