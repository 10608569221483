import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'company-profile-popup',
  templateUrl: './company-profile-popup.component.html'
})
export class CompanyProfilePopupComponent {
  @Input() companyProfilePopup: boolean;
  @Input() $companyProfilePopup: Observable<boolean>;
  @Output() companyProfilePopupClosed: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Input() companyId: boolean;
  windowHeight = window.innerHeight * 0.8;
  windowWidth = window.innerWidth * 0.8;
  popupTabsFixed: boolean;

  openCompanyInNewTab(): void {
    this.companyProfileClosed();
    window.open(`/companies/company/${this.companyId}`, '_blank');
  }

  companyProfileClosed(): void {
    // this.companyProfilePopup = false;
    this.companyProfilePopupClosed.emit();
  }

  profileScrolled(event): void {
    this.popupTabsFixed = event.scrollOffset.top >= 60 ? true : false;
  }
}
