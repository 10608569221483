import { Injectable } from '@angular/core';

import { AuthenticationService } from '../authentication.service';
import { CustomList } from '../../models/admin/custom-fields/customList';
import { SectionTabV2 } from '../../models/admin/custom-fields/section-tab-V2';
import { CustomFieldV2 } from '../../models/admin/custom-fields/custom-field-V2';
import { FieldOption } from '../../models/admin/custom-fields/fieldOption';
import { FieldType } from '../../models/admin/custom-fields/fieldType';
import { SectionV2 } from '../../models/admin/custom-fields/section-V2';

@Injectable()
export class CustomFieldService {
	constructor(private authService: AuthenticationService) {
	}

	clearServiceData(): void {

	}

	getCompanyCustomFields(companyId: number): Promise<any[]> {
		return this.authService.makeRequest('/api-v1.2.1/customfields?companyId=' + companyId, '', 'get').then(function (result: any) {
			return JSON.parse(result);
		});
	}

	parseCustomFieldValuesCsv(csvString: string): any {
		const temp: any[] = csvString.split('\n');
		const values: any[] = [];

		let i = 1;
		for (i = 1; i < temp.length; i++) {
			const data: any[] = temp[i].split('\xa7');

			if (data.length > 1) {
				const value: any = {};

				value.CompanyId = data[0];
				value.CustomFieldId = data[1];
				value.Value = data[2];

				values.push(value);
			}
		}

		return values;
	}

	saveCustomFields(companyId: number, values: any): Promise<boolean> {
		const updates: any[] = [];

		const keys = Object.keys(values);
		for (let i = 0; i < keys.length; i++) {
			updates.push({ CompanyId: companyId, CustomFieldId: values[keys[i]].FieldId, Value: values[keys[i]].NewValue });
		}

		return this.authService.makeRequest('/api-v1.2.1/companyCustomFields/setFieldValues', updates, 'post').then(function (result) {
			return true;
		});
	}

	createCompanyCustomFieldHtml(field: any): string {
		switch (field.FieldType) {
			case 1: // int
				return this.buildIntHtml(field);
			case 4: // string
				return this.buildStringHtml(field);
			case 6: // drop down list
				return this.buildDropdownHtml(field);
			case 9: // carriage return
				return this.buildReturnHtml(field);
			case 10: // datetime
				return this.buildDateTimeHtml(field);
			case 14: // checkbox
				return this.buildCheckboxHtml(field);
			case 17: // checklist
				return this.buildCheckListHtml(field);
			case 29: // label
				return this.buildLabelHtml(field);
			case 32: // whole number
				return this.buildWholeNumberHtml(field);
			case 100: // table
				if (field.TableFields) {
					return this.buildTableHtml(field.TableFields);
				} else {
					return this.buildErrorHtml();
				}
			default:
				return this.buildErrorHtml();
		}
	}

	buildErrorHtml(): string {
		let htmlString = '<span class="custom-field-container int">';

		htmlString += '<span class="custom-field-label">Unknown custom field type.</span>';

		htmlString += '</span>';

		return htmlString;
	}

	buildIntHtml(field: any): string {
		let htmlString = '<span class="custom-field-container int">';

		htmlString += '<span class="custom-field-label">' + field.FieldName + '&nbsp;</span>';

		htmlString += '<input class="custom-field input int" type="number" id="' + field.FieldId + '">';

		htmlString += '</span>';

		return htmlString;
	}

	buildStringHtml(field: any): string {
		let htmlString = '<span class="custom-field-container string">';

		htmlString += '<span class="custom-field-label">' + field.FieldName + '&nbsp;</span>';

		htmlString += '<input class="custom-field input string" type="text" id="' + field.FieldId + '">';

		htmlString += '</span>';

		return htmlString;
	}

	buildDropdownHtml(field: any): string {
		let htmlString = '<span class="custom-field-container dropdown">';

		htmlString += '<span class="custom-field-label">' + field.FieldName + '&nbsp;</span>';

		htmlString += '<select class="custom-field input dropdown" id="' + field.FieldId + '">';

		let i = 0;
		for (i = 0; i < field.EnumValues.EnumItems.length; i++) {
			htmlString += '<option value="' + field.EnumValues.EnumItems[i].EnumValue + '">' + field.EnumValues.EnumItems[i].EnumDisplay + '</option>';
		}

		htmlString += '</select>';

		htmlString += '</span>';

		return htmlString;
	}

	buildReturnHtml(field: any): string {
		const htmlString = '<br />';

		return htmlString;
	}

	buildDateTimeHtml(field: any): string {
		let htmlString = '<span layout="row" class="custom-field-container datetime">';

		htmlString += '<span class="custom-field-label">' + field.FieldName + '&nbsp;</span>';

		htmlString += '<input type="date" class="custom-field input date" id="' + field.FieldId + '">';

		htmlString += '</span>';

		return htmlString;
	}

	buildCheckboxHtml(field: any): string {
		let htmlString = '<span layout="row" class="custom-field-container checkbox">';

		htmlString += '<label style="width: 100%; display: flex;">';

		htmlString += '<span class="custom-field-label">' + field.FieldName + '&nbsp;</span>';

		htmlString += '<input type="checkbox" class="custom-field input checkbox" id="' + field.FieldId + '">';

		htmlString += '</label>';

		htmlString += '</span>';

		return htmlString;
	}

	buildCheckListHtml(field: any): string {
		return '';
	}

	buildLabelHtml(field: any): string {
		let htmlString = '<span layout="row" class="custom-field-container label">';

		htmlString += '<span class="custom-field-label">' + field.FieldName + '&nbsp;</span>';

		htmlString += '</span>';

		return htmlString;
	}

	buildWholeNumberHtml(field: any): string {
		let htmlString = '<span class="custom-field-container whole">';

		htmlString += '<span class="custom-field-label">' + field.FieldName + '&nbsp;</span>';

		htmlString += '<input class="custom-field input whole" type="number" id="' + field.FieldId + '">';

		htmlString += '</span>';

		return htmlString;
	}

	buildTableHtml(fields: any): string {
		let htmlString = '<div class="custom-field-table">';

		// find table column length
		let maxColumnLength = 0;
		let i = 0;
		for (i = 0; i < fields.length; i++) {
			if (fields[i].length > maxColumnLength) {
				maxColumnLength = fields[i].length;
			}
		}

		for (i = 0; i < fields.length; i++) {
			htmlString += '<div class="custom-field-table-row">';

			let j = 0;
			for (j = 0; j < maxColumnLength; j++) {
				if (fields[i][j]) {
					htmlString += this.createCompanyCustomFieldHtml(fields[i][j]);
				} else {
					htmlString += '<span class="custom-field-container empty"></span>';
				}
			}

			htmlString += '</div>';
		}

		htmlString += '</div>';
		return htmlString;
	}

	getTypes(): Promise<FieldType[]> {
		return this.authService.makeRequest('/api-v1.2.1/customfields/getTypes', null, 'get').then(function (result) {
			return JSON.parse(result);
		});
	}

	getSections(): Promise<SectionV2[]> {
		return this.authService.makeRequest('/api-v1.2.1/customfields', null, 'get').then(function (result) {
			return JSON.parse(result);
		});
	}

	getLists(): Promise<CustomList[]> {
		return this.authService.makeRequest('/api-v1.2.1/customLists', null, 'get').then(function (result) {
			return JSON.parse(result);
		});
	}

	getContactFields(): Promise<CustomFieldV2[]> {
		return this.authService.makeRequest('/api-v1.2.1/contactCustomfields', null, 'get').then(function (result) {
			return JSON.parse(result);
		});
	}

	createNewSection(section: SectionV2): Promise<number> {
		return this.authService.makeRequest('/api-v1.2.1/customfields/createSection', section, 'post').then(function (result) {
			return result;
		});
	}

	updateSection(section: SectionV2): Promise<any[]> {
		return this.authService.makeRequest('/api-v1.2.1/customfields/updateSection', section, 'post').then(function (result) {
			return result;
		});
	}

	deleteSection(section: SectionV2): Promise<any> {
		return this.authService.makeRequest('/api-v1.2.1/customfields/deleteSection', section, 'post').then(function (result) {
			return result;
		});
	}

	createNewTab(tab: SectionTabV2): Promise<number> {
		return this.authService.makeRequest('/api-v1.2.1/customfields/createTab', tab, 'post').then(function (result) {
			return result;
		});
	}

	updateTab(tab: SectionTabV2): Promise<any[]> {
		return this.authService.makeRequest('/api-v1.2.1/customfields/updateTab', tab, 'post').then(function (result) {
			return result;
		});
	}

	deleteTab(tab: SectionTabV2): Promise<any> {
		return this.authService.makeRequest('/api-v1.2.1/customfields/deleteTab', tab, 'post').then(function (result) {
			return result;
		});
	}

	createNewField(field: CustomFieldV2): Promise<number> {
		return this.authService.makeRequest('/api-v1.2.1/customfields/createField', field, 'post').then(function (result) {
			return result;
		});
	}

	updateField(field: CustomFieldV2): Promise<any[]> {
		return this.authService.makeRequest('/api-v1.2.1/customfields/updateField', field, 'post').then(function (result) {
			return result;
		});
	}

	updateFieldValue(field: CustomFieldV2): Promise<any> {
		return this.authService.makeRequest('/api-v1.2.1/customfields/updateFieldValue', field, 'post').then(function (result) {
			return result;
		});
	}

	updateFieldValues(fields: CustomFieldV2[]): Promise<any> {
		return this.authService.makeRequest('/api-v1.2.1/customfields/updateFieldValues', fields, 'post').then(function (result) {
			return result;
		});
	}

	deleteField(field: CustomFieldV2): Promise<boolean> {
		return this.authService.makeRequest('/api-v1.2.1/customfields/deleteField', field, 'post').then(function (result) {
			return result;
		});
	}

	createList(list: CustomList): Promise<number> {
		return this.authService.makeRequest('/api-v1.2.1/customLists/create', list, 'post').then(function (result) {
			return result;
		});
	}

	updateList(list: CustomList): Promise<any> {
		return this.authService.makeRequest('/api-v1.2.1/customLists/update', list, 'post').then(function (result) {
			return result;
		});
	}

	deleteList(list: CustomList): Promise<any> {
		return this.authService.makeRequest('/api-v1.2.1/customLists/delete', list, 'post').then(function (result) {
			return result;
		});
	}

	createListOption(listOptions: FieldOption): Promise<number> {
		return this.authService.makeRequest('/api-v1.2.1/customLists/options/create', listOptions, 'post').then(function (result) {
			return result;
		});
	}

	updateListOption(listOptions: FieldOption): Promise<any> {
		return this.authService.makeRequest('/api-v1.2.1/customLists/options/update', listOptions, 'post').then(function (result) {
			return result;
		});
	}

	deleteListOption(listOptions: FieldOption): Promise<boolean> {
		return this.authService.makeRequest('/api-v1.2.1/customLists/options/delete', listOptions, 'post').then(function (result) {
			return result;
		});
	}

	createContactField(field: CustomFieldV2): Promise<number> {
		return this.authService.makeRequest('/api-v1.2.1/customfields/createContactField', field, 'post').then(function (result) {
			return result;
		});
	}

	updateContactField(field: CustomFieldV2): Promise<any> {
		return this.authService.makeRequest('/api-v1.2.1/customfields/updateContactField', field, 'post').then(function (result) {
			return result;
		});
	}

	deleteContactField(field: CustomFieldV2): Promise<any> {
		return this.authService.makeRequest('/api-v1.2.1/customfields/deleteContactField', field, 'post').then(function (result) {
			return result;
		});
	}
}
