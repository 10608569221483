import { ReportsActions, ReportsActionTypes } from './reports.actions';
import { HttpErrorResponse } from '@angular/common/http';

export interface ReportsState {
  pending: boolean;
  error: HttpErrorResponse;
  data: any;
}

export const initialState: ReportsState = {
  pending: false,
  error: undefined,
  data: undefined
};

export function reportsReducer(
  state = initialState,
  action: ReportsActions
): ReportsState {
  switch (action.type) {
    case ReportsActionTypes.LoadReports:
      return state;

    case ReportsActionTypes.LoadReportsSuccess:
      return state;

    case ReportsActionTypes.LoadReportsFailure:
      return state;

    default:
      return state;
  }
}
