import { Injectable } from '@angular/core';

import { CompanyPermissions } from '../../models/admin/permission-enums';
import { FeaturePermissionsService } from './feature-permissions.service';
import { AuthenticationService } from '../authentication.service';

@Injectable()
export class CompanyPermissionsService {

	constructor(private featurePermissionsService: FeaturePermissionsService, private authService: AuthenticationService) { }

	getViewCompaniesPermission(): Promise<number> {
		return this.featurePermissionsService.getPermissionValue(this.authService.modules.Companies, CompanyPermissions.ViewCompanies).then(result => {
			return result;
		});
	}

	getExportCompaniesPermission(): Promise<boolean> {
		return this.featurePermissionsService.getPermissionValue(this.authService.modules.Companies, CompanyPermissions.ExportCompanies);
	}

	getAddEditDeleteCompaniesPermission(): Promise<number> {
		return this.featurePermissionsService.getPermissionValue(this.authService.modules.Companies, CompanyPermissions.AddEditDeleteCompanies).then(result => {
			return result;
		});
	}

	getMergeCompaniesPermission(): Promise<boolean> {
		return this.featurePermissionsService.getPermissionValue(this.authService.modules.Companies, CompanyPermissions.MergeCompanies);
	}

	getAssignPermission(): Promise<boolean> {
		return this.featurePermissionsService.getPermissionValue(this.authService.modules.Companies, CompanyPermissions.Assign);
	}

	getLinkPermission(): Promise<boolean> {
		return this.featurePermissionsService.getPermissionValue(this.authService.modules.Companies, CompanyPermissions.Link);
	}

	getImportPermission(): Promise<boolean> {
		return this.featurePermissionsService.getPermissionValue(this.authService.modules.Companies, CompanyPermissions.Import);
	}

	getBulkDeleteCompaniesPermission(): Promise<boolean> {
		return this.featurePermissionsService.getPermissionValue(this.authService.modules.Companies, CompanyPermissions.BulkDeleteCompanies);
	}

	getBulkEditCompaniesPermission(): Promise<boolean> {
		return this.featurePermissionsService.getPermissionValue(this.authService.modules.Companies, CompanyPermissions.BulkEditCompanies);
	}

	getContactsPermission(): Promise<number> {
		return this.featurePermissionsService.getPermissionValue(this.authService.modules.Companies, CompanyPermissions.Contacts).then(result => {
			return result;
		});
	}

	getBulkDeleteContactsPermission(): Promise<boolean> {
		return this.featurePermissionsService.getPermissionValue(this.authService.modules.Companies, CompanyPermissions.BulkDeleteContacts);
	}

	getBulkEditContactsPermission(): Promise<boolean> {
		return this.featurePermissionsService.getPermissionValue(this.authService.modules.Companies, CompanyPermissions.BulkEditContacts);
	}

	getExportContactsPermission(): Promise<boolean> {
		return this.featurePermissionsService.getPermissionValue(this.authService.modules.Companies, CompanyPermissions.ExportContacts);
	}

	getMergeContactsPermission(): Promise<boolean> {
		return this.featurePermissionsService.getPermissionValue(this.authService.modules.Companies, CompanyPermissions.MergeContacts);
	}
}
