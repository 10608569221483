<div fxLayout="row" fxLayoutAlign="space-between center">
  <div fxLayoutAlign="start center">
    <mat-icon style="padding-right: 5px">delete</mat-icon>
    <div *ngIf="($objectsToDelete | async)?.length === 1; else deleteMulti">
      <div *ngFor="let object of $objectsToDelete | async">
        <span *ngIf="object.name">'{{ object.name }}' deleted.</span>
        <span *ngIf="!object.name">Item deleted.</span>
      </div>
    </div>
    <ng-template #deleteMulti>
      <div fxLayout="column" fxLayoutAlign="start start">
        <div>Deleted</div>
      </div>
    </ng-template>
  </div>
  <div fxLayoutAlign="end center">
    <button
      mat-button
      mat-raised-button
      (click)="undoButtonClicked()"
      style="margin-left: 20px"
    >
      Undo
    </button>
    <a (click)="clearButtonClicked()" style="padding-left: 8px; cursor: pointer"
      ><mat-icon>clear</mat-icon></a
    >
  </div>
</div>
