import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { mergeMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import {
  ItemNamesActionTypes,
  ItemNamesActions,
  LoadItemNamesSuccess,
  LoadItemNamesFailure,
  CreateItemName,
  CreateItemNameSuccess,
  CreateItemNameFailure,
  UpdateItemName,
  UpdateItemNameSuccess,
  UpdateItemNameFailure,
  DeleteItemName,
  DeleteItemNameSuccess,
  DeleteItemNameFailure,
  LoadItemNames
} from './opportunity-item-names.actions';

import { ItemNameService } from '../../../services/system-settings/opportunities/item-name.service';
import { Store, Action } from '@ngrx/store';
import { RootState } from '../../store.reducer';
import { ItemName } from 'src/app/models/Opportunity/itemName';
import { convertToMap } from 'src/app/utils/convertToMap';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ItemNamesEffects {
  constructor(
    private actions$: Actions<ItemNamesActions>,
    private itemNameService: ItemNameService,
    private store$: Store<RootState>
  ) {}

  loadItemNames$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ItemNamesActionTypes.LoadItemNames),
      mergeMap(() =>
        this.itemNameService.getAll().pipe(
          map((itemNames: ItemName[]) => {
            return new LoadItemNamesSuccess(convertToMap(itemNames, 'id'));
          }),
          catchError((error: HttpErrorResponse) => of(new LoadItemNamesFailure(error)))
        )
      )
    )
  );

  createItemName$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ItemNamesActionTypes.CreateItemName),
      map((action: CreateItemName) => action.payload),
      mergeMap((itemName: ItemName) => {
        return this.itemNameService.createName(itemName).pipe(
          map((newItemName: ItemName) => new CreateItemNameSuccess(newItemName)),
          catchError((error: HttpErrorResponse) => of(new CreateItemNameFailure(error)))
        );
      })
    )
  );

  updateItemName$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ItemNamesActionTypes.UpdateItemName),
      map((action: UpdateItemName) => action.payload),
      mergeMap((itemName: ItemName) => {
        return this.itemNameService.updateItemName(itemName).pipe(
          map((updatedItemName: ItemName) => new UpdateItemNameSuccess(updatedItemName)),
          catchError((error: HttpErrorResponse) => of(new UpdateItemNameFailure(error)))
        );
      })
    )
  );

  deleteItemName$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ItemNamesActionTypes.DeleteItemName),
      map((action: DeleteItemName) => action.payload),
      mergeMap((itemNameId: number) =>
        this.itemNameService.deleteItemName(itemNameId).pipe(
          map(() => new DeleteItemNameSuccess(itemNameId)),
          catchError((error: HttpErrorResponse) => of(new DeleteItemNameFailure(error)))
        )
      )
    )
  );

  reloadItemName$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ItemNamesActionTypes.DeleteItemNameSuccess,
        ItemNamesActionTypes.UpdateItemNameSuccess,
        ItemNamesActionTypes.CreateItemNameSuccess
      ),
      map(() => new LoadItemNames())
    )
  );
}
