import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import {
  LoadProjectFieldsFailure,
  LoadProjectFieldsSuccess,
  ProjectFieldsActionTypes,
  ProjectFieldsActions,
  CreateProjectFields,
  CreateProjectFieldsSuccess,
  CreateProjectFieldsFailure,
  UpdateProjectFields,
  UpdateProjectFieldsSuccess,
  UpdateProjectFieldsFailure,
  DeleteProjectFieldsSuccess,
  DeleteProjectFieldsFailure,
  DeleteProjectFields,
  LoadProjectFieldsSettingSuccess,
  LoadProjectFieldsSettingFailure,
  UpdateProjectFieldsSetting,
  UpdateProjectFieldsSettingSuccess,
  UpdateProjectFieldsSettingFailure
} from './project-fields.actions';
import { ProjectFieldsService } from 'src/app/services/system-settings/projects/project-fields.service';
import { Action, Store } from '@ngrx/store';
import { ProjectField } from 'src/app/models/admin/projects/project-field';
import { HttpErrorResponse } from '@angular/common/http';
import { ProjectSetting } from '../../../models/projects/project-settings';
import { RootState } from '../../store.reducer';
import { convertToMap } from 'src/app/utils/convertToMap';

@Injectable()
export class ProjectFieldsEffects {
  loadProjectFields$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectFieldsActionTypes.LoadProjectFields),
      mergeMap(() =>
        this.projectFieldsService.getAll().pipe(
          map((data: ProjectField[]) => {
            return new LoadProjectFieldsSuccess(convertToMap(data, 'id'));
          }),
          catchError((error: HttpErrorResponse) =>
            of(new LoadProjectFieldsFailure(error))
          )
        )
      )
    )
  );

  loadProjectSettings$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectFieldsActionTypes.LoadProjectFieldsSetting),
      withLatestFrom(this.store$.select('auth', 'data', 'jpi', 'user', 'agencyId')),
      map(actionAndAgencyId => ({
        action: actionAndAgencyId[0],
        agencyId: actionAndAgencyId[1]
      })),
      mergeMap(({ agencyId }) =>
        this.projectFieldsService.getSettings(agencyId).pipe(
          map((data: ProjectSetting) => new LoadProjectFieldsSettingSuccess(data)),
          catchError((error: HttpErrorResponse) =>
            of(new LoadProjectFieldsSettingFailure(error))
          )
        )
      )
    )
  );

  createProjectFields$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectFieldsActionTypes.CreateProjectFields),
      map((action: CreateProjectFields) => action.payload),
      mergeMap((projectField: ProjectField) =>
        this.projectFieldsService.create(projectField).pipe(
          map((newField: ProjectField) => new CreateProjectFieldsSuccess(newField)),
          catchError((error: HttpErrorResponse) =>
            of(new CreateProjectFieldsFailure(error))
          )
        )
      )
    )
  );

  updateProjectFields$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectFieldsActionTypes.UpdateProjectFields),
      map((action: UpdateProjectFields) => action.payload),
      mergeMap((projectField: ProjectField) =>
        this.projectFieldsService.update(projectField).pipe(
          map(
            (updatedProjectField: ProjectField) =>
              new UpdateProjectFieldsSuccess(updatedProjectField)
          ),
          catchError((error: HttpErrorResponse) =>
            of(new UpdateProjectFieldsFailure(error))
          )
        )
      )
    )
  );

  updateProjectFieldsSetting$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectFieldsActionTypes.UpdateProjectFieldsSetting),
      map((action: UpdateProjectFieldsSetting) => action.payload),
      mergeMap((projectSetting: ProjectSetting) => {
        return this.projectFieldsService.updateSettings(projectSetting).pipe(
          map(
            (updatedProjectSetting: ProjectSetting) =>
              new UpdateProjectFieldsSettingSuccess(updatedProjectSetting)
          ),
          catchError((error: HttpErrorResponse) =>
            of(new UpdateProjectFieldsSettingFailure(error))
          )
        );
      })
    )
  );

  deleteProjectFields$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectFieldsActionTypes.DeleteProjectFields),
      map((action: DeleteProjectFields) => action.payload),
      mergeMap((id: number) =>
        this.projectFieldsService.delete(id).pipe(
          map(() => new DeleteProjectFieldsSuccess(id)),
          catchError((error: HttpErrorResponse) =>
            of(new DeleteProjectFieldsFailure(error))
          )
        )
      )
    )
  );

  constructor(
    private projectFieldsService: ProjectFieldsService,
    private actions$: Actions<ProjectFieldsActions>,
    private store$: Store<RootState>
  ) {}
}
