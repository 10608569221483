import { MemoizedSelector, createSelector } from '@ngrx/store';
import { RootState } from '../../../store.reducer';
import { Label } from '../../../../models/label';

export const selectAllOpportunityLabelsIndividual = (state: RootState) =>
  state.opportunityLabelsIndividual.data;

export const selectOpportunityLabelsIndividualAsArray: MemoizedSelector<
  RootState,
  Label[]
> = createSelector(
  selectAllOpportunityLabelsIndividual,
  (opportunityLabelsIndividualMap: { [id: number]: Label }): Label[] => {
    return Object.values(opportunityLabelsIndividualMap)
      .filter((lbl) => !lbl.isDeleted)
      .sort((a, b) => {
        const textA = a.listOrder;
        const textB = b.listOrder;
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
  }
);
