import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import {
  LoadProjectLabelsIndividiualsFailure,
  LoadProjectLabelsIndividiualsSuccess,
  ProjectLabelsIndividiualActionTypes,
  ProjectLabelsIndividiualActions,
  LoadProjectLabelsIndividiuals,
  CreateProjectLabelsIndividual,
  CreateProjectLabelsIndividualSuccess,
  CreateProjectLabelsIndividualFailure,
  UpdateProjectLabelsIndividual,
  UpdateProjectLabelsIndividualSuccess,
  UpdateProjectLabelsIndividualFailure,
  DeleteProjectLabelsIndividual,
  DeleteProjectLabelsIndividualSuccess,
  DeleteProjectLabelsIndividualFailure
} from './project-labels-individiual.actions';
import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ProjectLabelsIndividualService } from '../../../services/system-settings/projects/project-labels-individual.service';
import { Label } from 'src/app/models/label';
import { convertToMap } from 'src/app/utils/convertToMap';
import { LoadProjectLabels } from '../project-labels/project-labels.actions';

@Injectable()
export class ProjectLabelsIndividiualEffects {
  LoadProjectLabelsIndividual$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectLabelsIndividiualActionTypes.LoadProjectLabelsIndividiuals),
      map((action: LoadProjectLabelsIndividiuals) => action.payload),
      mergeMap((groupId: number) =>
        this.projectLabelsIndividualService.getAll(groupId).pipe(
          map((projectLabelsIndividual: Label[]) => {
            return new LoadProjectLabelsIndividiualsSuccess(
              convertToMap(projectLabelsIndividual, 'id')
            );
          }),
          catchError((error: HttpErrorResponse) =>
            of(new LoadProjectLabelsIndividiualsFailure(error))
          )
        )
      )
    )
  );

  createProjectLabelsIndividual$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectLabelsIndividiualActionTypes.CreateProjectLabelsIndividual),
      map((action: CreateProjectLabelsIndividual) => action.payload),
      mergeMap((action: { label: Label; groupId: number }) => {
        return this.projectLabelsIndividualService
          .create(action.label, action.groupId)
          .pipe(
            switchMap((newProjectLabelIndvidual: Label) => [
              new LoadProjectLabels(),
              new CreateProjectLabelsIndividualSuccess(newProjectLabelIndvidual)
            ]),
            catchError((error: HttpErrorResponse) =>
              of(new CreateProjectLabelsIndividualFailure(error))
            )
          );
      })
    )
  );

  updateProjectLabelsIndividual$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectLabelsIndividiualActionTypes.UpdateProjectLabelsIndividual),
      map((action: UpdateProjectLabelsIndividual) => action.payload),
      mergeMap((action: { label: Label; groupId: number }) => {
        return this.projectLabelsIndividualService
          .update(action.label, action.groupId)
          .pipe(
            switchMap((updatedProjectLabelIndividual: Label) => [
              new LoadProjectLabels(),
              new UpdateProjectLabelsIndividualSuccess(updatedProjectLabelIndividual)
            ]),
            catchError((error: HttpErrorResponse) =>
              of(new UpdateProjectLabelsIndividualFailure(error))
            )
          );
      })
    )
  );

  deleteProjectLabelsIndividual$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectLabelsIndividiualActionTypes.DeleteProjectLabelsIndividual),
      map((action: DeleteProjectLabelsIndividual) => action.payload),
      mergeMap((action: { groupId: number; id: number }) =>
        this.projectLabelsIndividualService.delete(action.groupId, action.id).pipe(
          switchMap(() => [
            new LoadProjectLabels(),
            new DeleteProjectLabelsIndividualSuccess(action)
          ]),
          catchError((error: HttpErrorResponse) =>
            of(new DeleteProjectLabelsIndividualFailure(error))
          )
        )
      )
    )
  );

  constructor(
    private projectLabelsIndividualService: ProjectLabelsIndividualService,
    private actions$: Actions<ProjectLabelsIndividiualActions>
  ) {}
}
