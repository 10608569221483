<div class="address-container">
  <div [hidden]="editing">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px">
      <div fxLayout="column" fxLayoutGap="6px">
        <div>
          <span [ngClass]="{ 'error-text': addressForm.invalid && required }">
            <span class="address-label">{{ prefix }}:</span>
            {{ displayAddressText }}</span
          >
        </div>
        <div *ngIf="!!address?.county">County: {{ address.county }}</div>
      </div>
      <button
        mat-icon-button
        (click)="editHandler()"
        [color]="addressForm.valid || !required ? 'primary' : 'warn'"
        [disabled]="iSQuote"
      >
        <mat-icon>edit</mat-icon>
      </button>
    </div>
  </div>
  <div [hidden]="!editing">
    <form [formGroup]="addressForm" fxLayout="column" (submit)="saveHandler($event)">
      <mat-form-field>
        <input
          matInput
          formControlName="addressLine1"
          placeholder="Address Line 1"
          [value]="address?.addressLine1"
        />
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          formControlName="addressLine2"
          placeholder="Address Line 2"
          [value]="address?.addressLine2"
        />
      </mat-form-field>
      <div fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="12px">
          <mat-form-field fxFlex="36">
            <input
              matInput
              [required]="required"
              formControlName="city"
              placeholder="City"
              [value]="address?.city"
            />
          </mat-form-field>
          <mat-form-field fxFlex="36">
            <input
              matInput
              [required]="required"
              formControlName="state"
              placeholder="State / Province / Region"
              [value]="address?.state"
            />
          </mat-form-field>
          <mat-form-field fxFlex="20">
            <input
              matInput
              formControlName="postCode"
              placeholder="ZIP / Postal Code"
              [value]="address?.postCode"
            />
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="12px">
          <mat-form-field fxFlex>
            <input
              matInput
              formControlName="county"
              placeholder="County"
              [value]="address?.county"
            />
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="12px">
          <mat-form-field fxFlex *ngIf="showCountry">
            <input
              matInput
              formControlName="country"
              placeholder="Country"
              [value]="address?.country"
              (blur)="saveHandler()"
            />
          </mat-form-field>
          <div fxLayout="row" fxFlex="20">
            <button mat-icon-button (click)="cancelHandler()">
              <mat-icon>clear</mat-icon>
            </button>
            <button
              mat-icon-button
              color="primary"
              type="submit"
              [disabled]="addressForm.invalid && required"
            >
              <mat-icon>check</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
