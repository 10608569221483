import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Label } from 'src/app/models/label';

@Injectable({
  providedIn: 'root'
})
export class ProjectLabelsIndividualService {
  private jpiUrl = environment.jpiUrl;

  constructor(private http: HttpClient) {}

  getAll(groupId: number) {
    return this.http.get(`${this.jpiUrl}/projectlabelgroups/${groupId}/labels`);
  }

  getById(labelId: number, groupId: number) {
    return this.http.get(
      `${this.jpiUrl}/projectlabelgroups/${groupId}/labels/${labelId}`
    );
  }

  create(label: Label, groupId: number) {
    return this.http.post(`${this.jpiUrl}/projectlabelgroups/${groupId}/labels`, label);
  }

  update(label: Label, groupId: number) {
    return this.http.put(
      `${this.jpiUrl}/projectlabelgroups/${groupId}/labels/${label.id}`,
      label
    );
  }

  delete(groupId: number, labelId: number) {
    return this.http.delete(
      `${this.jpiUrl}/projectlabelgroups/${groupId}/labels/${labelId}`
    );
  }
}
