<div fxLayout="row" fxLayoutAlign="start start">
  <!-- Image Slide Out Preview -->
  <div *ngIf="currentExpense?.files?.length > 0">
    <div
      *ngFor="let file of currentExpense?.files"
      fxLayout="row"
      fxLayoutAlign="space-between start"
    >
      <div fxFlex="100">
        <mat-card style="margin: 5px">
          <mat-card-content style="text-align: center">
            <div *ngIf="file.fileType === 'application/pdf'; else image">
              <iframe
                [src]="file.downloadUrl | safe: 'resourceUrl'"
                height="600px"
                width="auto"
              ></iframe>
            </div>
            <ng-template #image>
              <img
                src="{{ file.downloadUrl }}"
                alt="expense file"
                max-width="300px"
                width="auto"
                height="600px"
              />
            </ng-template>
          </mat-card-content>
          <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
            <div style="font-size: 12px">
              Uploaded on {{ file.uploadedDate | date }}
              <br />
              {{ file.fileSize }}
            </div>
            <div>
              <button
                mat-icon-button
                (click)="onDelete(file.id)"
                style="margin-right: 5px"
              >
                <mat-icon color="warn">delete</mat-icon>
              </button>
              <button color="primary" mat-raised-button (click)="download(file)">
                <mat-icon>cloud_download</mat-icon>
                &nbsp;<span>Download</span>
              </button>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
  <!-- End Image Slide Out Preview -->
  <div class="expense-form" fxLayout="column" fxFlex>
    <form [formGroup]="expenseForm">
      <mat-form-field>
        <input
          matInput
          class="expense-full-width"
          [matDatepicker]="picker"
          formControlName="expenseDate"
          required
          placeholder="Choose a date"
          [value]="currentExpense?.expenseDate"
          (click)="picker.open()"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <tn-company-select
        (companySearchEvent)="onCompanySearch($event)"
        [$companySearchResults]="$companies"
        [selectedCompany]="currentExpense?.company"
        [$error]="$companiesSearchError"
        [isRequired]="true"
        (companySelected)="onCompanySelect($event)"
        [$pending]="$companiesSearchPending"
      ></tn-company-select>

      <tn-item-select
        *ngIf="!$hasExpensesAdmin | async"
        [placeholder]="'Assigned User'"
        [$selectableItems]="$allUsers"
        [selectedItem]="currentExpense?.assignedToUser"
        (itemSelected)="onAssignedUserSelected($event)"
        (isValid)="userValidation($event)"
      ></tn-item-select>

      <tn-multi-select
        [selectedItems]="currentExpense?.contacts"
        (selectedItemsChange)="setContacts($event)"
        [$allItems]="$companyContacts"
        [$clearSelectedContactsEvent]="$contactsEventsSubject"
        [placeholder]="'Contacts'"
      >
      </tn-multi-select>

      <div>
        <mat-form-field fxFlex>
          <mat-label>Type</mat-label>
          <mat-select
            [value]="currentExpense?.type"
            formControlName="type"
            matInput
            required
            [compareWith]="compareObjects"
          >
            <mat-option
              *ngFor="let expenseType of $expenseTypes | async"
              [value]="expenseType"
            >
              {{ expenseType.type }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field fxFlex>
          <mat-label>Purpose/details</mat-label>
          <input
            matInput
            required
            [value]="currentExpense?.purpose"
            autocomplete="off"
            formControlName="purpose"
          />
        </mat-form-field>
      </div>

      <div>
        <mat-form-field fxFlex>
          <mat-label>Location</mat-label>
          <input
            matInput
            [value]="currentExpense?.location"
            autocomplete="off"
            formControlName="location"
          />
        </mat-form-field>
      </div>

      <div>
        <mat-form-field fxFlex>
          <mat-label>Amount</mat-label>
          <span class="input-icon" matPrefix>
            <mat-icon>attach_money</mat-icon>&nbsp;
          </span>
          <input
            matInput
            autocomplete="off"
            required
            [value]="currentExpense?.amount"
            formControlName="amount"
            type="number"
          />
        </mat-form-field>
      </div>

      <div style="margin-bottom: 10px">
        <mat-checkbox
          [value]="currentExpense?.reimbursement"
          formControlName="reimbursement"
          >Reimbursement needed</mat-checkbox
        >
      </div>

      <div *ngIf="!!currentExpense.id; else elseFileBlock">
        <tn-expense-files></tn-expense-files>
      </div>
      <ng-template #elseFileBlock>
        <tn-pending-expense-files></tn-pending-expense-files>
      </ng-template>
      <br />

      <div fxLayout="row-reverse" fxLayoutAlign="space-between center" class="footer">
        <div fxLayout="row-reverse">
          <button
            *ngIf="!currentExpense?.id"
            type="button"
            mat-raised-button
            color="primary"
            mat-button
            [disabled]="expenseForm.pristine || expenseForm.invalid"
            (click)="createButtonClicked(expenseForm.value)"
          >
            Create
          </button>
          <button
            *ngIf="currentExpense?.id"
            type="button"
            mat-raised-button
            color="primary"
            mat-button
            [disabled]="expenseForm.pristine || expenseForm.invalid"
            (click)="saveButtonClicked(expenseForm.value)"
          >
            Save
          </button>
          &nbsp;&nbsp;
          <button
            preventDefaultAction
            mat-raised-button
            color="white"
            mat-button
            (click)="cancelButtonClicked()"
            cdkFocusInitial
          >
            Cancel
          </button>
        </div>
        <button
          *ngIf="currentExpense?.id"
          preventDefaultAction
          mat-raised-button
          color="warn"
          mat-button
          (click)="deleteButtonClicked(currentExpense)"
        >
          Delete
        </button>
      </div>
    </form>
  </div>
</div>
