import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { DxTagBoxComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';
import * as moment from 'moment';

import { CustomFieldV2 } from '../../models/admin/custom-fields/custom-field-V2';
import { CustomList } from '../../models/admin/custom-fields/customList';
import { User } from '../../models/admin/users/user';
import { CommentV3 } from '../../models/call-notes/comment-V3';
import { NoteField } from '../../models/call-notes/note-field';
import { CompanyBasic } from '../../models/companies/company-basic';
import { CompanyV2 } from '../../models/companies/company-V2';
import { ContactV2 } from '../../models/contacts/contact-V2';
import { File } from '../../models/files/file';
import { CustomFieldService } from '../../services/admin/custom-field.service';
import { AuthenticationService } from '../../services/authentication.service';
import { CallNoteFieldsService } from '../../services/call-notes/call-note-fields.service';
import { CallNoteV2Service } from '../../services/call-notes/call-note-V2.service';
import { CallNoteService } from '../../services/call-notes/call-note.service';
import { CompanyJPIService } from '../../services/companies/company-JPI.service';
import { CompanyService } from '../../services/companies/company.service';
import { ContactJPIService } from '../../services/contact-JPI.service';
import { NotificationService } from '../../services/notification.service';
import { CompanyPermissionsService } from '../../services/permissions/company-permissions.service';
import { ToolbarService } from '../../services/toolbar.service';
import { UserService } from '../../services/user.service';
import { UserV2 } from './../../models/admin/users/user-V2';
import { CallNoteV2 } from './../../models/call-notes/call-note-V2';
import { Field } from './../../models/call-notes/field';
import { ActivityPermissionsService } from './../../services/permissions/activity-permissions.service';

@Component({
  selector: 'call-note-popup',
  templateUrl: './call-note-popup.component.html',
  styleUrls: ['./call-note-popup.component.scss']
})
export class CallNotePopupComponent implements OnInit, OnDestroy {
  @ViewChild('shareCallNoteTagBox')
  shareCallNoteTagBox: DxTagBoxComponent;
  @Input() callNotePopup: boolean;
  @Input() companies: any;
  @Input() companyAgents: UserV2[];
  @Input() contact: number;
  @Input() contacts: ContactV2[];
  @Input() callNote: CallNoteV2;
  @Input() customCallNoteFields: any;
  @Input() companyId: number;
  @Input() callNotes: CallNoteV2[];
  @Input() callNoteExpanded: boolean = true;
  @Input() followUpExpanded: boolean;
  @Input() editCallNotesFeature: number;
  @Input() companyFiles: File[];
  @Input() followUps: DataSource;
  @Input() document: Document;
  @Input() fromToolbar: boolean;
  @Output() callNotePopupChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() callNotesChange: EventEmitter<CallNoteV2[]> = new EventEmitter<
    CallNoteV2[]
  >();
  @Output() companyFilesChange: EventEmitter<File[]> = new EventEmitter<File[]>();
  @Output() followUpsChange: EventEmitter<DataSource> = new EventEmitter<DataSource>();
  @Output() callNoteCreated: EventEmitter<any> = new EventEmitter<any>();
  @Output() callNoteChange: EventEmitter<CallNoteV2> = new EventEmitter<CallNoteV2>();
  window: any = window;
  assignTasksFeature: boolean;
  anotherNotePopup: boolean;
  anotherNoteCreating: boolean;
  customCallNoteFieldsCheck: CustomFieldV2[];
  callNoteCopy: CallNoteV2;
  cancelCallNotePopup: boolean;
  deleteCallNotePopup: boolean;
  lists: CustomList[];
  fileUploadPopup: boolean;
  file: File;
  shareNoteDialog: boolean;
  emailList: User[];
  displayedCompany: Object;
  clickEditCompany: boolean;
  companySelectBoxDisplayed: boolean;
  contactsList: boolean;
  contactsInList: ContactV2[];
  contactTagBoxDisplayed: boolean;
  isSaving: boolean;
  savingMessage: string;
  isDeleting: boolean;
  deletingMessage: string;
  contactsFeature: number;
  newComment: CommentV3;
  commentsExpanded: boolean;
  addNewComment: boolean;
  typingTimer: any;

  constructor(
    private customFieldService: CustomFieldService,
    private notificationService: NotificationService,
    private companyService: CompanyService,
    private authService: AuthenticationService,
    private callNoteService: CallNoteService,
    private callNoteV2Service: CallNoteV2Service,
    private callNoteFieldsService: CallNoteFieldsService,
    private userService: UserService,
    private toolbarService: ToolbarService,
    private contactJPIService: ContactJPIService,
    activityPermissionsService: ActivityPermissionsService,
    companyPermissionsService: CompanyPermissionsService,
    private companyJPIService: CompanyJPIService
  ) {
    Promise.all([
      activityPermissionsService.getCallNotesPermission(),
      activityPermissionsService.getAssignTasksPermission(),
      companyPermissionsService.getContactsPermission()
    ]).then((results) => {
      this.editCallNotesFeature = results[0];
      this.assignTasksFeature = results[1];
      this.contactsFeature = results[2];
    });
  }

  async ngOnInit() {
    this.contactsInList = [];
    document.body.style.overflow = 'hidden';
    this.contactsList = true;
    this.contactsInList = [];
    this.followUpExpanded = true;
    this.callNoteExpanded = true;
    await this.getCompanyAgents();
    if (!this.callNote) {
      this.callNote = Object.assign({ followUp: { assignedTo: {} }, type: {} });
    }
    if (!this.callNote.type) {
      this.callNote.type = Object.assign({});
    }
    if (this.callNote.id || (this.callNote.company && this.callNote.company.id)) {
      this.createCallNoteCopy();
      this.getCustomFields();
    } else {
      this.initCallNote();
    }
    this.getEmailList();
    this.customFieldService.getLists().then((result) => {
      this.lists = result;
    });
  }

  ngOnDestroy(): void {
    document.body.style.overflow = 'visible';
    this.callNotesChange.emit(this.callNotes);
    // this.callNote = JSON.parse(JSON.stringify(this.callNoteCheck));
  }

  shareCallNoteKeyDown(event) {
    if (event.event.key === 'Enter') {
      const email = this.shareCallNoteTagBox.instance.option().text;
      this.emailList.push(<any>{ Email: email, EmailDisplay: email });
      this.callNote.shareWithEmails.push(email);
    }
  }

  createCallNoteCopy(): void {
    this.callNote.createdDate = moment.utc(this.callNote.createdDate).local().toDate();
    this.callNote.callDate = moment.utc(this.callNote.createdDate).local().toDate();
    if (
      this.callNote.followUp &&
      this.callNote.followUp.dueDateStart &&
      (<any>this.callNote.followUp.dueDateStart).toString() == 'Invalid Date'
    ) {
      this.callNote.followUp.dueDateStart = null;
    }
    if (
      this.callNote.followUp &&
      this.callNote.followUp.dueDateEnd &&
      (<any>this.callNote.followUp.dueDateEnd).toString() == 'Invalid Date'
    ) {
      this.callNote.followUp.dueDateEnd = null;
    }

    this.sortCallNoteFields();

    if (this.callNote.contacts && this.callNote.contacts.length > 0) {
      (<any>this.callNote).contactIds = [];
      this.callNote.contacts.map((contact: ContactV2) => {
        (<any>this.callNote).contactIds.push(contact.id);
      });
    }
    this.callNoteCopy = JSON.parse(JSON.stringify(this.callNote));
    this.callNoteCopy.createdDate = this.callNote.createdDate;
    if (this.callNoteCopy.followUp) {
      this.callNoteCopy.followUp = { ...this.callNote.followUp };
    } else {
      this.callNote.followUp = { ...this.callNote.followUp };
      this.callNoteCopy.followUp = { ...this.callNoteCopy.followUp };
      this.callNote.followUp.assignedTo = { ...this.callNote.followUp.assignedTo };
      this.callNoteCopy.followUp.assignedTo = {
        ...this.callNoteCopy.followUp.assignedTo
      };
    }
    if (
      (this.callNote.followUp.assignedTo && isEmpty(this.callNote.followUp.assignedTo)) ||
      !this.callNote.followUp.assignedTo
    ) {
      const agentId = parseInt(
        this.authService.getCookie(this.authService.cookieNames.AgentId)
      );
      this.callNote.followUp.assignedTo = this.companyAgents.filter(
        (agent) => agent.id === agentId
      )[0];
    }

    this.getCompanyForCallNote();
    this.createCommentCopies();
  }

  // contactChanged(e: any): void {
  //     if (e.addedItems && e.addedItems.length > 0) {
  //         e.addedItems.map((contact: ContactV2) => {
  //             const tempContact = JSON.parse(JSON.stringify(contact));
  //             delete tempContact.fullName;
  //             this.callNote.contacts.push(tempContact);
  //         });
  //     }
  //     if (e.removedItems && e.removedItems.length > 0) {
  //         e.removedItems.map((contact: ContactV2) => {
  //             for (let i = 0; i < this.callNote.contacts.length; i++) {
  //                 if (this.callNote.contacts[i].id == contact.id) {
  //                     this.callNote.contacts.splice(i, 1);
  //                     break;
  //                 }
  //             }
  //         });
  //     }
  // }

  sortCallNoteFields(): void {
    if (this.callNote.fields && this.callNote.fields.length > 0) {
      const tempFieldsCallNoteOrder = [];
      const tempFieldsNoCallNoteOrder = [];
      this.callNote.fields.map((field) => {
        if (field.field && field.field.callnoteOrder) {
          tempFieldsCallNoteOrder.push(field);
        } else {
          tempFieldsNoCallNoteOrder.push(field);
        }
      });
      tempFieldsCallNoteOrder.sort(
        (a: NoteField, b: NoteField) => a.field.callnoteOrder - b.field.callnoteOrder
      );
      tempFieldsNoCallNoteOrder.map((field) => tempFieldsCallNoteOrder.push(field));
      this.callNote.fields = tempFieldsCallNoteOrder;
    } else {
      this.callNote.fields = [];
      this.callNoteFieldsService.getCallNoteFieldsSorted().then(
        (result) => {
          result.map((field: Field) => {
            this.callNote.fields.push({
              id: 0,
              field: field,
              value: null,
              deleted: false,
              deletedDate: null
            });
          });
        },
        (error) => {
          throw error;
        }
      );
    }
  }

  getCompanyForCallNote(): void {
    this.companyJPIService.getById(this.callNote.company.id).then(
      (company: CompanyV2) => {
        this.getDisplayedCompany(company);
        this.contactJPIService.getContactsForCompany(this.callNote.company.id).then(
          (contacts) => {
            this.contacts = contacts;
            this.contactsInList = [];
            if (this.contacts) {
              for (const contact of this.contacts) {
                contact.id = parseInt(contact.id.toString());
              }
              if (
                (<any>this.callNote).contactIds &&
                (<any>this.callNote).contactIds.length > 0
              ) {
                for (const contact of this.contacts) {
                  if ((<any>this.callNote).contactIds.includes(contact.id)) {
                    this.contactsInList.push(contact);
                  }
                }
                this.contactsList = true;
              } else {
                this.contactsList = false;
                this.contactTagBoxDisplayed = true;
              }
            }
          },
          (error) => {
            throw error;
          }
        );
        this.clickEditCompany = true;
        this.companySelectBoxDisplayed = false;
      },
      (error) => {
        throw error;
      }
    );
  }

  createCommentCopies(): void {
    if (this.callNote.comments) {
      for (const comment of this.callNote.comments) {
        for (const commentCheck of this.callNoteCopy.comments) {
          if (comment.id == commentCheck.id) {
            commentCheck.createdDate = comment.createdDate;
          }
        }
      }
    }
  }

  async getCompanyAgents() {
    if (this.companyAgents) {
      this.sortCompanyAgents();
    } else {
      this.companyAgents = await this.userService.getActive();
      this.sortCompanyAgents();
    }
  }

  sortCompanyAgents(): void {
    for (const agent of this.companyAgents) {
      agent.fullName = agent.firstName + ' ' + agent.lastName;
    }
    this.companyAgents.sort((a, b) => a.fullName.localeCompare(b.fullName));
  }

  getEmailList(): void {
    this.companyService.getEmailList().then((result) => {
      this.emailList = result;
      for (let i = 0; i < this.emailList.length; i++) {
        this.emailList[i].FullName =
          this.emailList[i].FirstName + ' ' + this.emailList[i].LastName;
        (<any>this.emailList[i]).EmailDisplay =
          this.emailList[i].FullName + '<' + this.emailList[i].Email + '>';
      }
      this.callNote.shareWithEmails = [];
    });
  }

  initCallNote(): void {
    this.companyId = this.toolbarService.companyId;
    this.createCallNoteFromScratch(null, this.callNote.contacts);
  }

  createCallNoteFromScratch(company: CompanyBasic, contacts: ContactV2[]): any {
    const newCallNote: any = {
      callDate: new Date(Date.now()),
      followUp: {
        task: null,
        dueDateStart: null,
        dueDateEnd: null,
        complete: false,
        completedDate: null
      },
      createdDate: null,
      company: {},
      type: {},
      fields: []
    };

    this.setAssignedRep(newCallNote);
    this.anotherNoteCallNoteCompany(newCallNote, company);

    if (this.callNote.contacts) {
      newCallNote.contacts = this.callNote.contacts;
    }
    if (
      this.callNote.followUp &&
      this.callNote.followUp.dueDateStart &&
      this.callNote.followUp.dueDateEnd &&
      !this.anotherNoteCreating
    ) {
      newCallNote.followUp.dueDateStart = this.callNote.followUp.dueDateStart;
      newCallNote.followUp.dueDateEnd = this.callNote.followUp.dueDateEnd;
    }
    this.anotherNoteCreating = false;

    if (!this.companyId) {
      this.allowForCompanySearching(newCallNote, contacts);
      this.combineCallNote(newCallNote);
    } else {
      this.getCompanyForNewCallNote(newCallNote, contacts);
    }
  }

  setAssignedRep(newCallNote: any): void {
    if (
      !this.callNote.followUp ||
      !this.callNote.followUp.assignedTo ||
      !this.callNote.followUp.assignedTo?.id
    ) {
      newCallNote.followUp.assignedTo = this.companyAgents.filter(
        (agent) =>
          agent.id ==
          parseInt(this.authService.getCookie(this.authService.cookieNames.AgentId))
      )[0];
    } else {
      newCallNote.followUp.assignedTo = this.companyAgents.filter(
        (agent) => agent.id == this.callNote.followUp.assignedTo?.id
      )[0];
    }
  }

  anotherNoteCallNoteCompany(newCallNote: any, company: CompanyBasic): void {
    if (company) {
      newCallNote.company = {
        id: company.id,
        agencyId: company.agencyId,
        name: company.name,
        deleted: null,
        deletedDate: null,
        territories: company.territories,
        primaryUser: {}
      };
      if (company.primaryUser) {
        newCallNote.company.primaryUser = {
          id: company.primaryUser.id,
          mailbox: company.primaryUser.mailbox,
          agencyId: company.primaryUser.agencyId,
          firstName: company.primaryUser.firstName,
          lastName: company.primaryUser.lastName,
          deleted: null
        };
      }
    }
    newCallNote.followUp.dueDateStart = null;
    newCallNote.followUp.dueDateStartTS = null;
    newCallNote.followUp.dueDateEnd = null;
    newCallNote.followUp.dueDateEndTS = null;
    newCallNote.followUp.complete = false;
  }

  allowForCompanySearching(newCallNote: any, contacts: any): void {
    if (isEmpty(newCallNote.company)) {
      this.companySelectBoxDisplayed = true;
    }
    if (contacts && contacts.length > 0) {
      this.callNote.contacts = contacts;
      this.contactsInList = [];
      this.callNote.contacts.map((contact: ContactV2) =>
        this.contactsInList.push(contact)
      );
      if (this.contactsInList.length < 1) {
        this.contactsList = true;
        this.contactTagBoxDisplayed = false;
      }
    } else {
      this.contactsList = false;
      this.contactTagBoxDisplayed = true;
    }
  }

  getCompanyForNewCallNote(newCallNote: any, contacts: any): void {
    this.companyJPIService.getById(this.companyId).then(
      (company: CompanyV2) => {
        newCallNote.company = {
          id: company.id,
          agencyId: company.agencyId,
          name: company.name,
          deleted: null,
          deletedDate: null,
          territories: company.territories,
          primaryUser: null
        };
        if (company.primaryUser) {
          newCallNote.company.primaryUser = {
            id: company.primaryUser.id,
            mailbox: company.primaryUser.mailbox,
            agencyId: company.primaryUser.agencyId,
            firstName: company.primaryUser.firstName,
            lastName: company.primaryUser.lastName,
            deleted: null
          };
        }
        this.getDisplayedCompany(company);
        this.clickEditCompany = true;
        this.companySelectBoxDisplayed = false;

        this.getCompanyContacts(contacts);
        this.combineCallNote(newCallNote);
      },
      (error) => {
        throw error;
      }
    );
  }

  getCompanyContacts(contacts: any): void {
    if (this.contacts) {
      this.contacts.map(
        (contact: ContactV2) =>
          (contact.fullName = `${contact.firstName} ${contact.lastName}`)
      );
    } else {
      this.contactJPIService.getContactsForCompany(this.companyId).then(
        (contacts: ContactV2[]) => {
          this.contacts = contacts;
        },
        (error) => {
          throw error;
        }
      );
    }
    if (contacts && contacts.length > 0) {
      this.callNote.contacts = contacts;
      this.contactsList = true;
      contacts.forEach((contact) => this.contactsInList.push(contact));
      this.contactTagBoxDisplayed = false;
    } else {
      this.contactsList = false;
      this.contactTagBoxDisplayed = true;
    }
  }

  combineCallNote(newCallNote: any): void {
    this.callNote = Object.assign({}, newCallNote);
    if (!this.callNote.followUp.dueDateStart && this.callNote.followUp.dueDateEnd) {
      this.callNote = JSON.parse(JSON.stringify(this.callNote));
      this.callNote.followUp.dueDateStart = null;
      this.callNote.followUp.dueDateStartTS = null;
      this.callNote.followUp.dueDateEnd = null;
      this.callNote.followUp.dueDateEndTS = null;
      this.callNote.followUp.complete = false;
    }
    this.callNoteFieldsService.getCallNoteFieldsSorted().then(
      (result) => {
        result.map((field: Field) => {
          this.callNote.fields.push({
            id: 0,
            field: field,
            value: null,
            deleted: false,
            deletedDate: null
          });
        });
        this.getCustomFields();
        this.callNoteCopy = JSON.parse(JSON.stringify(this.callNote));
      },
      (error) => {
        throw error;
      }
    );
    // if (this.callNote.contacts) {
    //     this.callNote.contacts.map((contact: ContactV2) => { this.contactsInList.push(contact); });
    //     this.contactsList = true;
    // }
  }

  getCustomFields(): void {
    this.callNoteService.getCallnoteFields().then((result) => {
      this.customCallNoteFields = result.filter((field) => !field.isDeleted);
      this.customCallNoteFieldsCheck = JSON.parse(
        JSON.stringify(this.customCallNoteFields)
      );
    });
  }

  getDisplayedCompany(company: CompanyV2): any {
    if (company.shippingAddress) {
      this.displayedCompany = {
        name: company.name,
        id: company.id,
        addressLine1: company.shippingAddress.addressLine1,
        addressLine2: company.shippingAddress.addressLine2,
        city: company.shippingAddress.city,
        state: company.shippingAddress.state,
        postCode: company.shippingAddress.postCode
      };
    } else if (company.billingAddress) {
      this.displayedCompany = {
        name: company.name,
        id: company.id,
        addressLine1: company.billingAddress.addressLine1,
        addressLine2: company.billingAddress.addressLine2,
        city: company.billingAddress.city,
        state: company.billingAddress.state,
        postCode: company.billingAddress.postCode
      };
    } else {
      this.displayedCompany = {
        name: company.name,
        id: company.id
      };
    }
  }

  resetCallNoteRows(): void {
    this.callNoteExpanded = true;
    this.followUpExpanded = true;
  }

  clearCustomNarratives(): void {
    for (let i = 0; i < this.customCallNoteFields.length; i++) {
      this.customCallNoteFields[i].narrative = '';
    }
  }

  saveCallNote(): void {
    if (!this.callNote.company.id) {
      this.notificationService.showError('Call note must have a company');
    } else if (!this.callNote.type.id) {
      this.notificationService.showError('Must provide a call type');
    } else if (
      this.callNote.followUp &&
      this.callNote.followUp.dueDateStart != null &&
      this.callNote.followUp.dueDateEnd != null &&
      this.callNote.followUp.dueDateStart > this.callNote.followUp.dueDateEnd
    ) {
      this.notificationService.showError(
        "Follow up start date can't be earlier than follow up end date"
      );
    } else {
      if (
        this.callNote.followUp.dueDateStart &&
        this.callNote.followUp.dueDateEnd &&
        !this.callNote.followUp.task
      ) {
        this.callNote.followUp.task = 'Follow up';
      }
      if (!this.callNote.callDate) {
        this.callNote.callDate = new Date();
      }
      this.savingMessage = 'Saving...';
      this.isSaving = true;
      this.callNote.contacts = [];
      if ((<any>this.callNote).contactIds && (<any>this.callNote).contactIds.length > 0) {
        (<any>this.callNote).contactIds.map((id: number) => {
          this.contacts.map((contact: ContactV2) => {
            if (contact.id == id) {
              this.callNote.contacts.push(contact);
            }
          });
        });
      }
      this.callNote.contacts.map((contact: ContactV2) => delete contact.fullName);
      delete (<any>this.callNote).contactIds;
      if (this.callNote.id) {
        this.updateCallNote(this.callNote);
      } else {
        this.createCallNote(this.callNote);
      }
    }
  }

  addComment(): void {
    this.newComment = Object.assign({});
    this.commentsExpanded = true;
    this.addNewComment = true;
  }

  updateCallNote(callNote: CallNoteV2): void {
    if (this.callNote.comments && this.callNote.comments.length > 0) {
      this.callNote.comments.map((comment: CommentV3) => {
        comment.createdDate = new Date(this.callNote.createdDate);
        delete (<any>comment).disabled;
      });
    }
    this.callNoteV2Service.updateCallNote(callNote).then(() => {
      if (this.callNotes && this.callNotes.length > 0) {
        for (let i = 0; i < this.callNotes.length; i++) {
          if (callNote.id == this.callNotes[i].id) {
            this.callNotes.splice(i, 1, callNote);
            break;
          }
        }
      }
      this.callNotesChange.emit(this.callNotes);
      this.companyFilesChange.emit(this.companyFiles);
      if (callNote.followUp.complete && !this.callNoteCopy.followUp.complete) {
        this.anotherNotePopup = true;
        this.anotherNoteCreating = true;
      } else {
        this.callNoteService.callNoteCreatedFromToolbar(callNote);
      }
      this.isSaving = false;
      if (this.fromToolbar) {
        this.callNoteService.callNoteCreatedFromToolbar('reload');
      } else {
        this.callNoteService.callNoteCreatedFromToolbar(callNote);
      }
      if (!this.anotherNotePopup) {
        this.callNotePopup = false;
        this.callNotePopupChange.emit(this.callNotePopup);
      }
    });
  }

  createCallNote(callNote: CallNoteV2): void {
    if (this.companyId) {
      callNote.company.id = this.companyId;
    }
    delete callNote.createdDate;
    this.callNoteV2Service.addCallNote(callNote).then((result) => {
      callNote = result;
      this.callNoteService.callNoteCreatedFromToolbar(callNote);
      this.callNotePopup = false;
      this.toolbarService.callNoteVisible(this.callNotePopup);
      this.callNoteCreated.emit(callNote);
      this.callNotePopupChange.emit(this.callNotePopup);
      this.companyFilesChange.emit(this.companyFiles);
      this.isSaving = false;
    });
  }

  cancelCallNote(callNote: CallNoteV2): void {
    const callNoteCompare = { ...callNote };
    const callNoteCopyCompare = { ...this.callNoteCopy };

    callNoteCopyCompare.shareWithEmails = callNoteCompare.shareWithEmails;
    callNoteCopyCompare.callDate = callNoteCompare.callDate;

    (<any>callNoteCompare.followUp).assignedTo = {
      id: callNoteCompare.followUp.assignedTo?.id
    };
    (<any>callNoteCopyCompare.followUp).assignedTo = {
      id: callNoteCopyCompare.followUp.assignedTo?.id
    };

    if (
      isEqual(callNoteCompare, callNoteCopyCompare) &&
      isEqual(this.customCallNoteFields, this.customCallNoteFieldsCheck)
    ) {
      this.callNotePopup = false;
      this.toolbarService.callNoteVisible(this.callNotePopup);
      this.callNotesChange.emit(this.callNotes);
      this.callNotePopupChange.emit(this.callNotePopup);
    } else {
      this.cancelCallNotePopup = true;
    }
  }

  callNoteCancelled(): void {
    this.callNote = JSON.parse(JSON.stringify(this.callNoteCopy));
    this.callNoteChange.emit(this.callNote);
    this.resetCallNotes();
    this.resetPopups();
  }

  resetCallNotes(): void {
    // this.callNote = Object.assign({ followUp: { assignedTo: {} }, type: {} });
    // this.callNoteCopy = Object.assign({});
    this.customCallNoteFields = Object.assign({});
    this.customCallNoteFieldsCheck = Object.assign({});
  }

  resetPopups(): void {
    this.cancelCallNotePopup = false;
    this.callNotePopup = false;
    this.toolbarService.callNoteVisible(this.callNotePopup);
    this.callNotePopupChange.emit(this.callNotePopup);
    this.resetCallNoteRows();
  }

  callNoteDeleted(callNote: CallNoteV2): void {
    callNote.createdDate = new Date(callNote.createdDate);
    if (this.callNote.comments) {
      this.callNote.comments.map((comment: CommentV3) => {
        comment.createdDate = new Date(this.callNote.createdDate);
        delete (<any>comment).disabled;
      });
    }
    delete (<any>callNote).contactIds;
    this.deletingMessage = 'Deleting...';
    this.isDeleting = true;
    this.callNoteV2Service.delete(callNote).then(
      () => {
        if (this.callNotes) {
          for (let i = 0; i < this.callNotes.length; i++) {
            if (callNote.id == this.callNotes[i].id) {
              this.callNotes.splice(i, 1);
              this.callNotesChange.emit(this.callNotes);
            }
          }
        }
        this.callNoteService.callNoteCreatedFromToolbar('reload');
        this.callNoteService.callNoteDeletedOnProfile(callNote.id);
        this.resetCallNotes();
        this.resetPopups();
        this.deleteCallNotePopup = false;
      },
      (error) => {
        throw error;
      }
    );
  }

  closeCallNote(): void {
    this.callNotePopup = false;
    this.callNotePopupChange.emit(this.callNotePopup);
  }

  uploadFile(): void {
    this.file = Object.assign({});
    this.fileUploadPopup = true;
  }

  notNewNote(): void {
    this.callNotePopup = false;
    this.anotherNotePopup = false;
    this.callNotePopupChange.emit(this.callNotePopup);
    this.followUpsChange.emit(this.followUps);
    this.callNoteService.updateCalendarFollowUps(true);
  }

  addAnotherNote(): void {
    this.anotherNotePopup = false;
    this.createCallNoteFromScratch(this.callNote.company, this.callNote.contacts);
    this.callNoteCopy = JSON.parse(JSON.stringify(this.callNote));
    this.getCustomFields();
    this.callNoteService.updateCalendarFollowUps(true);
  }
}
