<div class="container">
  <h3>Merge Companies</h3>

  <div class="heading-text">
    <p>Which company record would you like to keep?</p>
    <p></p>
  </div>

  <mat-radio-group [(ngModel)]="primaryCompanyId">
    <mat-card [ngClass]="{ 'card-background': primaryCompanyId === data.company1.id }">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h4>{{ data.company1.name }}</h4>
        <mat-radio-button color="primary" [value]="data.company1.id"></mat-radio-button>
      </div>
      <div class="address">
        <div *ngIf="data.company1.billingAddress.addressLine1">
          {{ data?.company1?.billingAddress?.addressLine1 }}
        </div>
        <div *ngIf="data.company1.billingAddress.addressLine2">
          {{ data?.company1?.billingAddress?.addressLine2 }}
        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
          <div
            fxFlex="60"
            *ngIf="
              data.company1.billingAddress.city ||
              data.company1.billingAddress.state ||
              data.company1.postCode
            "
          >
            {{ data?.company1?.billingAddress?.city }},
            {{ data?.company1?.billingAddress?.state }}
            {{ data?.company1?.billingAddress?.postCode }}
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" class="phoneNumber">
        <div *ngIf="data.company1.phone; else noInfo" fxFlex="60">
          {{ data.company1.phone }}
        </div>
        <ng-template #noInfo>
          <div fxFlex="60">&nbsp;</div>
        </ng-template>
        <div *ngIf="data?.company1.accountId" fxFlex="40">
          <b>Account ID: </b> {{ data?.company1.accountId }}
        </div>
      </div>
      <div class="other-data" fxLayout="row" fxLayoutAlign="start center">
        <p *ngIf="data?.company1.createdDate" fxFlex="60">
          <b>Created:</b> {{ data?.company1.createdDate | date }}
        </p>
        <p *ngIf="data?.company1.id" fxFlex="40">
          <b>Telenotes ID: </b> {{ data?.company1.id }}
        </p>
      </div>
    </mat-card>

    <mat-card [ngClass]="{ 'card-background': primaryCompanyId === data.company2.id }">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h4>{{ data.company2.name }}</h4>
        <mat-radio-button color="primary" [value]="data.company2.id"></mat-radio-button>
      </div>
      <div class="address">
        <div *ngIf="data.company2.billingAddress.addressLine1">
          {{ data?.company2?.billingAddress?.addressLine1 }}
        </div>
        <div *ngIf="data.company2.billingAddress.addressLine2">
          {{ data?.company2?.billingAddress?.addressLine2 }}
        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
          <div
            fxFlex="60"
            *ngIf="
              data.company2.billingAddress.city ||
              data.company2.billingAddress.state ||
              data.company2.postCode
            "
          >
            {{ data?.company2?.billingAddress?.city }},
            {{ data?.company2?.billingAddress?.state }}
            {{ data?.company2?.billingAddress?.postCode }}
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center" class="phoneNumber">
        <div *ngIf="data.company2.phone; else noInfo" fxFlex="60">
          {{ data.company2.phone }}
        </div>
        <div *ngIf="data?.company2.accountId" fxFlex="40">
          <b>Account ID: </b> {{ data?.company2.accountId }}
        </div>
      </div>
      <div class="other-data" fxLayout="row" fxLayoutAlign="start center">
        <p *ngIf="data?.company2.createdDate" fxFlex="60">
          <b>Created:</b> {{ data?.company2.createdDate | date }}
        </p>
        <p *ngIf="data?.company2.id" fxFlex="40">
          <b>Telenotes ID: </b> {{ data?.company2.id }}
        </p>
      </div>
    </mat-card>
  </mat-radio-group>

  <div class="info-box">
    <h5 style="font-size: medium">What happens when I merge two companies?</h5>
    <p>
      1. All contacts, call notes, follow-ups, all other activity, files, and linked
      records for both companies will be preserved in the new record.
    </p>
    <p>2. All company fields from the selected company will remain unchanged.</p>
    <p>
      3. All company fields from the deselected company will merge into the selected
      company, but only if the fields in the selected company are empty.
    </p>
    <p>
      4. If a company field is populated in both companies, only the value in the selected
      company will remain.
    </p>
    <p>5. You can edit the company after the merge to make any other changes.</p>
  </div>
  <div class="reminder">
    <p>
      <b>Keep in mind:</b> It can take up to 10 minutes for all of the activity from both
      companies to sync. You may need to refresh your page to view all changes.
    </p>
  </div>

  <div class="form-actions" fxLayout="row-reverse" fxLayoutAlign="space-between center">
    <div class="form-buttons" fxLayout="row-reverse" fxLayoutGap="12px">
      <button
        mat-raised-button
        color="primary"
        (click)="merge()"
        [disabled]="!primaryCompanyId"
      >
        Merge
      </button>
      <button mat-raised-button (click)="cancel()" style="margin-right: 10px">
        Cancel
      </button>
    </div>
    <p>This action can't be undone.</p>
  </div>
</div>
