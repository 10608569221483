import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { callNoteTypesReducer } from './call-note-types.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CallNoteTypesEffects } from './call-note-types.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature('callNoteTypes', callNoteTypesReducer),
    EffectsModule.forFeature([CallNoteTypesEffects])
  ]
})
export class CallNoteTypesStateModule {}
