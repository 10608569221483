import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { contactCustomFieldsReducer } from './contact-custom-fields.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ContactCustomFieldsEffects } from './contact-custom-fields.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('contactCustomFields', contactCustomFieldsReducer),
    EffectsModule.forFeature([ContactCustomFieldsEffects])
  ]
})
export class ContactCustomFieldsStateModule {}
