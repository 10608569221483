import { RootState } from '../../store.reducer';
import { MemoizedSelector, createSelector } from '@ngrx/store';
import { CallNote } from 'src/app/models/call-notes/call-note';
import { GenericMap } from 'src/app/models/generic-map';
import { GenericApi } from 'src/app/models/generic-api';
import { Company } from 'src/app/models/companies/company';
import { Contact } from 'src/app/models/contacts/contact';
import { CallNoteField } from 'src/app/models/call-notes/call-note-field';
import { selectCompanyParamId } from '../../companies/selectors/current-company.selector';

export const selectCallNotes = (state: RootState) => state.callNotes.data;

export const selectCompanyCallNotes = (state: RootState) => state.callNotes.byCompanyId;

export const selectCompanyCallNotesAsArray: MemoizedSelector<RootState, CallNote[]> =
  createSelector(
    selectCompanyCallNotes,
    selectCompanyParamId,
    (
      callNotesByCompanyId: GenericMap<GenericApi<GenericMap<CallNote>>>,
      companyId: number
    ): CallNote[] => {
      if (callNotesByCompanyId[companyId]) {
        return Object.values(callNotesByCompanyId[companyId].data)
          .filter((callNote) => !!callNote)
          .map((callNote: CallNote) => {
            return {
              ...callNote,
              followUp: {
                ...callNote.followUp,
                assignedTo: !!callNote.followUp.assignedTo?.id
                  ? {
                      id: callNote.followUp.assignedTo?.id,
                      mailbox: callNote.followUp.assignedTo?.mailbox,
                      name:
                        callNote.followUp.assignedTo?.firstName +
                        ' ' +
                        callNote.followUp.assignedTo?.lastName
                    }
                  : null
              },
              fields: callNote.fields
                .sort(
                  (a: CallNoteField, b: CallNoteField) =>
                    a.field.callnoteOrder - b.field.callnoteOrder
                )
                .map((field: CallNoteField) => {
                  return {
                    name: field.field.name,
                    id: field.field.id,
                    value: field.value,
                    dropdownId: field.dropdownId,
                    type: field.field.type
                  } as CallNoteField;
                }),
              companyName: callNote.company.name,
              contacts: callNote.contacts.map((contact: Contact) =>
                contact.lastName
                  ? ({
                      id: contact.id,
                      name: contact.firstName + ' ' + contact.lastName
                    } as Contact)
                  : ({
                      id: contact.id,
                      name: contact.firstName
                    } as Contact)
              ),
              createdBy: {
                mailbox: callNote.createdBy.mailbox,
                id: callNote.createdBy.id,
                name: callNote.createdBy.firstName + ' ' + callNote.createdBy.lastName
              }
            };
          })
          .sort((a, b) => {
            const dateA = new Date(a.createdDate).getTime();
            const dateB = new Date(b.createdDate).getTime();
            return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
          });
      } else {
        return [];
      }
    }
  );
