import { Injectable } from '@angular/core';
import { ServicesModule } from '../services.module';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Territory } from 'src/app/models/admin/territories/territory';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: ServicesModule
})
export class TerritoryManagementService {
  constructor(private http: HttpClient) {}

  getAll(agencyId?: number): Observable<Territory[]> {
    const paramsObj: any = {
      flatten: true
    };
    if (!!agencyId) {
      paramsObj.agencyId = agencyId.toString();
    }
    return this.http.get(environment.jpiUrl + '/territories', {
      params: new HttpParams({
        fromObject: paramsObj
      })
    }) as Observable<Territory[]>;
  }

  getById(id: string): Observable<Territory> {
    return this.http.get(environment.jpiUrl + '/territories/' + id) as Observable<
      Territory
    >;
  }

  createTerritory(territory: Territory): Observable<Territory> {
    return this.http.post(environment.jpiUrl + '/territories', territory) as Observable<
      Territory
    >;
  }

  updateTerritory(territory: Territory): Observable<Territory> {
    return this.http.put(
      environment.jpiUrl + '/territories/' + territory.id,
      territory
    ) as Observable<Territory>;
  }

  deleteTerritory(territoryId: number): Observable<null> {
    return this.http.delete(
      environment.jpiUrl + '/territories/' + territoryId
    ) as Observable<null>;
  }
}
