import { RootState } from '../../store.reducer';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { TerritoriesMap } from 'src/app/models/admin/territories/territories-map';
import * as arrayToTree from 'array-to-tree';
import { Territory } from 'src/app/models/admin/territories/territory';

export const selectTerritoriesMap = (state: RootState) =>
  state.territoryManagement.territories.data;

export const selectTerritoriesTree: MemoizedSelector<
  RootState,
  Territory[]
> = createSelector(
  selectTerritoriesMap,
  (territoriesMap: TerritoriesMap): Territory[] =>
    arrayToTree(Object.values(territoriesMap), {
      parentProperty: 'parentId',
      customID: 'id',
      childrenProperty: 'subTerritories'
    })
);
