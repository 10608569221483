import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ContactField } from 'src/app/models/contacts/contact-field';

@Injectable({
  providedIn: 'root'
})
export class ContactCustomFieldsService {
  private jpiUrl = environment.jpiUrl;
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get(`${this.jpiUrl}/contactfields`);
  }

  getById(contactFieldId: number) {
    return this.http.get(`${this.jpiUrl}/contactfields/${contactFieldId}`);
  }

  create(contactField: ContactField) {
    return this.http.post(`${this.jpiUrl}/contactfields`, contactField);
  }

  update(contactField: ContactField) {
    return this.http.put(`${this.jpiUrl}/contactfields/${contactField.id}`, contactField);
  }

  delete(contactFieldId: number) {
    return this.http.delete(`${this.jpiUrl}/contactfields/${contactFieldId}`);
  }
}
