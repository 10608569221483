import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

import { Client } from '../../models/admin/client';
import { Feature } from '../../models/admin/feature';
import { Industry } from '../../models/admin/industry';
import { Plan } from '../../models/admin/plan';
import { Role } from '../../models/admin/roles/role';
import { User } from '../../models/admin/users/user';
import { UserV2 } from '../../models/admin/users/user-V2';
import { AuthenticationService } from '../authentication.service';

@Injectable()
export class AdminService {
  public permissions: any = { Territories: 75, Roles: 67, Users: 71, Customization: 79 };
  private selectedClientId = new BehaviorSubject<number>(
    parseInt(this.authService.getCookie(this.authService.cookieNames.AgencyId))
  );
  selectedClientIdAnnounced = this.selectedClientId.asObservable();
  private clientIdAnnouncer = new Subject<boolean>();
  clientIdAnnounced = this.clientIdAnnouncer.asObservable();

  constructor(private authService: AuthenticationService) {}

  setClientIdChangedStatus(shouldInit: boolean): void {
    this.clientIdAnnouncer.next(shouldInit);
  }

  changeSelectedClientId(id: number): void {
    this.selectedClientId.next(id);
  }

  canViewTerritories(): Promise<boolean> {
    const self = this;
    return this.authService
      .getFeature(this.authService.modules.Admin)
      .then(function(result) {
        if (!result) {
          return false;
        }

        for (let i = 0; i < result.Permissions.length; i++) {
          if (result.Permissions[i].Id == self.permissions.Territories) {
            return parseInt(result.Permissions[i].Value) >= 1;
          }
        }
      });
  }

  canEditTerritories(): Promise<boolean> {
    const self = this;
    return this.authService
      .getFeature(this.authService.modules.Admin)
      .then(function(result) {
        if (!result) {
          return false;
        }

        for (let i = 0; i < result.Permissions.length; i++) {
          if (result.Permissions[i].Id == self.permissions.Territories) {
            return parseInt(result.Permissions[i].Value) >= 2;
          }
        }
      });
  }

  canViewRoles(): Promise<boolean> {
    const self = this;
    return this.authService
      .getFeature(this.authService.modules.Admin)
      .then(function(result) {
        if (!result) {
          return false;
        }

        for (let i = 0; i < result.Permissions.length; i++) {
          if (result.Permissions[i].Id == self.permissions.Roles) {
            return parseInt(result.Permissions[i].Value) >= 1;
          }
        }
      });
  }

  canEditRoles(): Promise<boolean> {
    const self = this;
    return this.authService
      .getFeature(this.authService.modules.Admin)
      .then(function(result) {
        if (!result) {
          return false;
        }

        for (let i = 0; i < result.Permissions.length; i++) {
          if (result.Permissions[i].Id == self.permissions.Roles) {
            return parseInt(result.Permissions[i].Value) >= 2;
          }
        }
      });
  }

  canViewUsers(): Promise<boolean> {
    const self = this;
    return this.authService
      .getFeature(this.authService.modules.Admin)
      .then(function(result) {
        if (!result) {
          return false;
        }

        for (let i = 0; i < result.Permissions.length; i++) {
          if (result.Permissions[i].Id == self.permissions.Users) {
            return parseInt(result.Permissions[i].Value) >= 1;
          }
        }
      });
  }

  canEditUsers(): Promise<boolean> {
    const self = this;
    return this.authService
      .getFeature(this.authService.modules.Admin)
      .then(function(result) {
        if (!result) {
          return false;
        }

        for (let i = 0; i < result.Permissions.length; i++) {
          if (result.Permissions[i].Id == self.permissions.Users) {
            return parseInt(result.Permissions[i].Value) >= 2;
          }
        }
      });
  }

  canViewCustomization(): Promise<boolean> {
    const self = this;
    return this.authService
      .getFeature(this.authService.modules.Admin)
      .then(function(result) {
        if (!result) {
          return false;
        }

        for (let i = 0; i < result.Permissions.length; i++) {
          if (result.Permissions[i].Id == self.permissions.Customization) {
            return parseInt(result.Permissions[i].Value) >= 1;
          }
        }
      });
  }

  canEditCustomization(): Promise<boolean> {
    const self = this;
    return this.authService
      .getFeature(this.authService.modules.Admin)
      .then(function(result) {
        if (!result) {
          return false;
        }

        for (let i = 0; i < result.Permissions.length; i++) {
          if (result.Permissions[i].Id == self.permissions.Customization) {
            return parseInt(result.Permissions[i].Value) >= 2;
          }
        }
      });
  }

  getAgencyFeatures(): Promise<Feature[]> {
    return this.authService
      .makeRequest('/api-v1.2.1/admin/agencyFeatures', null, 'get')
      .then(function(result) {
        const tempFeatures = !!result ? JSON.parse(result) : [];
        for (let f = 0; f < tempFeatures.length; f++) {
          for (let x = 0; x < tempFeatures[f].Permissions.length; x++) {
            if (tempFeatures[f].Permissions[x].PermissionType == 2) {
              tempFeatures[f].Permissions[x].Value = false;
            }
          }
        }
        return tempFeatures;
      });
  }

  getAgencyFeaturesForClient(agencyId: number): Promise<Feature[]> {
    return this.authService
      .makeRequest('/api-v1.2.1/admin/agencyFeatures?agencyId=' + agencyId, null, 'get')
      .then(function(result) {
        const tempFeatures = !!result ? JSON.parse(result) : [];
        for (let f = 0; f < tempFeatures.length; f++) {
          for (let x = 0; x < tempFeatures[f].Permissions.length; x++) {
            if (tempFeatures[f].Permissions[x].PermissionType == 2) {
              tempFeatures[f].Permissions[x].Value = false;
            }
          }
        }
        return tempFeatures;
      });
  }

  getRoles() {
    return this.authService
      .makeRequest('/api-v1.2.1/admin/roles', null, 'get')
      .then(function(result) {
        const tempRoles = !!result ? JSON.parse(result) : [];
        for (let f = 0; f < tempRoles.length; f++) {
          for (let i = 0; i < tempRoles[f].Features.length; i++) {
            for (let x = 0; x < tempRoles[f].Features[i].Permissions.length; x++) {
              if (
                tempRoles[f].Features[i].Permissions[x].PermissionType == 2 &&
                (tempRoles[f].Features[i].Permissions[x].Value == 1 ||
                  tempRoles[f].Features[i].Permissions[x].Value == 'true')
              ) {
                tempRoles[f].Features[i].Permissions[x].Value = true;
              } else if (
                tempRoles[f].Features[i].Permissions[x].PermissionType == 2 &&
                (tempRoles[f].Features[i].Permissions[x].Value == 0 ||
                  tempRoles[f].Features[i].Permissions[x].Value == 'false')
              ) {
                tempRoles[f].Features[i].Permissions[x].Value = false;
              }
            }
          }
        }
        return tempRoles;
      });
  }

  getClientRoles(clientId: number) {
    if (!!clientId) {
      return this.authService
        .makeRequest('/api-v1.2.1/admin/roles?agencyId=' + clientId, null, 'get')
        .then(function(result) {
          const tempRoles = !!result ? JSON.parse(result) : [];
          for (let f = 0; f < tempRoles.length; f++) {
            for (let i = 0; i < tempRoles[f].Features.length; i++) {
              for (let x = 0; x < tempRoles[f].Features[i].Permissions.length; x++) {
                if (
                  tempRoles[f].Features[i].Permissions[x].PermissionType == 2 &&
                  (tempRoles[f].Features[i].Permissions[x].Value == 1 ||
                    tempRoles[f].Features[i].Permissions[x].Value == 'true')
                ) {
                  tempRoles[f].Features[i].Permissions[x].Value = true;
                } else if (
                  tempRoles[f].Features[i].Permissions[x].PermissionType == 2 &&
                  (tempRoles[f].Features[i].Permissions[x].Value == 0 ||
                    tempRoles[f].Features[i].Permissions[x].Value == 'false')
                ) {
                  tempRoles[f].Features[i].Permissions[x].Value = false;
                }
              }
            }
          }
          return tempRoles;
        });
    } else {
      return null;
    }
  }

  createNewRole(role: Role): Promise<number> {
    return this.authService
      .makeRequest('/api-v1.2.1/admin/createRole', role, 'post')
      .then(function(result) {
        return result;
      });
  }

  updateRole(role: Role): Promise<any[]> {
    return this.authService
      .makeRequest('/api-v1.2.1/admin/updateRole', role, 'post')
      .then(function(result) {
        return result;
      });
  }

  deleteRole(role: Role): Promise<any> {
    return this.authService
      .makeRequest('/api-v1.2.1/admin/deleteRole', role, 'post')
      .then(function(result) {
        return result;
      });
  }

  getUsers(): Promise<User[]> {
    return this.authService
      .makeRequest('/api-v1.2.1/admin/users', null, 'get')
      .then(function(result) {
        return !!result ? JSON.parse(result) : undefined;
      });
  }

  getUsersForClient(agencyId: number): Promise<User[]> {
    if (!!agencyId) {
      return this.authService
        .makeRequest('/api-v1.2.1/admin/users?agencyId=' + agencyId, null, 'get')
        .then(function(result) {
          return !!result ? JSON.parse(result) : undefined;
        });
    } else {
      return null;
    }
  }

  getUsersV2(): Promise<UserV2[]> {
    return this.authService
      .makeJpiRequest('/users', null, null, 'get')
      .then(function(result) {
        return !!result ? JSON.parse(result) : undefined;
      });
  }

  getUserV2ById(id: number): Promise<UserV2> {
    return this.authService
      .makeJpiRequest('/users/' + id, null, null, 'get')
      .then(function(result) {
        return !!result ? JSON.parse(result) : undefined;
      });
  }

  getUsersForClientV2(agencyId: number): Promise<UserV2[]> {
    return this.authService
      .makeJpiRequest('/users?agencyId=' + agencyId, null, null, 'get')
      .then(function(result) {
        return !!result ? JSON.parse(result) : undefined;
      });
  }

  createNewUser(user: User): Promise<User> {
    return this.authService
      .makeRequest('/api-v1.2.1/admin/createUser', user, 'post')
      .then(function(result) {
        return !!result ? JSON.parse(result) : undefined;
      });
  }

  createNewUserV2(user: any): Promise<any> {
    return this.authService
      .makeJpiRequest('/users', null, user, 'post')
      .then(function(result) {
        return !!result ? JSON.parse(result) : undefined;
      });
  }

  updateUser(user: User): Promise<boolean> {
    return this.authService
      .makeRequest('/api-v1.2.1/admin/updateUser', user, 'post')
      .then(function(result) {
        return result;
      });
  }

  updateUserRate(user: User, oldRoleId: number): Promise<boolean> {
    return this.authService
      .makeRequest(
        '/api-v1.2.1/admin/updateUserRate?oldRoleId=' + oldRoleId,
        user,
        'post'
      )
      .then(function(result) {
        return result;
      });
  }

  updateUserV2(user: UserV2): Promise<boolean> {
    return this.authService
      .makeJpiRequest('/users/' + user.id, null, user, 'put')
      .then(function(result) {
        return result;
      });
  }

  deleteUser(user: User): Promise<boolean> {
    return this.authService
      .makeRequest('/api-v1.2.1/admin/deleteUser', user, 'post')
      .then(function(result) {
        return result;
      });
  }

  deleteUserV2(user: UserV2): Promise<boolean> {
    return this.authService
      .makeJpiRequest('/users/' + user.id, null, user, 'delete')
      .then(function(result) {
        return result;
      });
  }

  updateUserPassword(user: User) {
    return this.authService
      .makeRequest('/api-v1.2.1/admin/updateUserPassword', user, 'post')
      .then(function(result) {
        return result;
      });
  }

  getClients(): Promise<any[]> {
    return this.authService
      .makeRequest('/api-v1.2.1/client/get', null, 'get')
      .then(function(result: any) {
        return !!result ? JSON.parse(result) : undefined;
      });
  }

  getClient(clientId: number): Promise<any[]> {
    return this.authService
      .makeRequest('/api-v1.2.1/client/get?clientId=' + clientId, null, 'get')
      .then(function(result: any) {
        return !!result ? JSON.parse(result) : undefined;
      });
  }

  createNewClient(client: Client): Promise<number> {
    return this.authService
      .makeRequest('/api-v1.2.1/client/create', client, 'post')
      .then(function(result: number) {
        return result;
      });
  }

  updateClient(client: Client): Promise<any[]> {
    return this.authService
      .makeRequest('/api-v1.2.1/client/update', client, 'post')
      .then(function(result) {
        return result;
      });
  }

  createIndustry(industry: Industry): Promise<number> {
    return this.authService
      .makeRequest('/api-v1.2.1/admin/createIndustry', industry, 'post')
      .then(function(result: number) {
        return result;
      });
  }

  getIndustries(): Promise<any[]> {
    return this.authService
      .makeRequest('/api-v1.2.1/admin/getIndustries', null, 'get')
      .then(function(result: any) {
        return !!result ? JSON.parse(result) : undefined;
      });
  }

  getIndustryId(): Promise<any[]> {
    return this.authService
      .makeRequest('/api-v1.2.1/admin/getIndustry?id=', null, 'get')
      .then(function(result: any) {
        return !!result ? JSON.parse(result) : undefined;
      });
  }

  deleteIndustry(industry: Industry): Promise<any[]> {
    return this.authService
      .makeRequest('/api-v1.2.1/admin/deleteIndustry', industry, 'post')
      .then(function(result) {
        return result;
      });
  }

  getPlans(): Promise<any[]> {
    return this.authService
      .makeRequest('/api-v1.2.1/admin/plans', null, 'get')
      .then(function(result: any) {
        return !!result ? JSON.parse(result) : undefined;
      });
  }

  getPlanForAgency(agencyId: number): Promise<any> {
    return this.authService
      .makeRequest('/api-v1.2.1/admin/plan?agencyId=' + agencyId, null, 'get')
      .then(function(result: any) {
        return !!result ? JSON.parse(result) : undefined;
      });
  }

  getPlan(): Promise<any> {
    return this.authService
      .makeRequest('/api-v1.2.1/admin/plan', null, 'get')
      .then(function(result: any) {
        return !!result ? JSON.parse(result) : undefined;
      });
  }

  createNewPlan(plan: Plan): Promise<number> {
    return this.authService
      .makeRequest('/api-v1.2.1/admin/createPlan', plan, 'post')
      .then(function(result: number) {
        return result;
      });
  }

  getFeatures(): Promise<any[]> {
    return this.authService
      .makeRequest('/api-v1.2.1/admin/features', null, 'get')
      .then(function(result) {
        return !!result ? JSON.parse(result) : undefined;
      });
  }

  updatePlan(plan: Plan): Promise<any[]> {
    return this.authService
      .makeRequest('/api-v1.2.1/admin/updatePlan', plan, 'post')
      .then(function(result) {
        return result;
      });
  }

  deletePlan(plan: Plan): Promise<any[]> {
    return this.authService
      .makeRequest('/api-v1.2.1/admin/deletePlan', plan, 'post')
      .then(function(result) {
        return result;
      });
  }
}
