import { RootState } from '../../store.reducer';
import { MemoizedSelector, createSelector } from '@ngrx/store';
import { GenericMap } from '../../../models/generic-map';
import { selectAllFieldTypes } from '../../system-settings/field-types/selectors/field-types.selector';
import { FieldType } from 'src/app/models/call-notes/field-type';
import { OpportunityCustomField } from 'src/app/models/Opportunity/opportunity-custom-field';

export const selectOppCustomFields = (
  state: RootState
): GenericMap<OpportunityCustomField> => state.opportunities.customOppFields.data;

export const selectOppCustomFieldsAsArray: MemoizedSelector<
  RootState,
  OpportunityCustomField[]
> = createSelector(
  selectOppCustomFields,
  selectAllFieldTypes,
  (
    oppCFs: GenericMap<OpportunityCustomField>,
    types: GenericMap<FieldType>
  ): OpportunityCustomField[] => {
    return Object.values(types).length
      ? Object.values(oppCFs).map((cf: OpportunityCustomField) => ({
          ...cf,
          typeName: types[cf.typeInfo.id] ? types[cf.typeInfo.id].name : 'No type name'
        }))
      : [];
  }
);
