import { RootState } from '../../store.reducer';
import { MemoizedSelector, createSelector, Selector } from '@ngrx/store';
import { ColumnHeader, ColumnType } from 'src/app/models/column-header';
import { LabelGroup } from 'src/app/models/label-group';
import { selectAllOpportunityLabels } from '../../system-settings/opportunity-labels/selectors/all-opportunityLabels-as-array.selector';
import { selectItemCustomFieldsAsArray } from './item-custom-fields.selector';
import { ItemCustomField } from 'src/app/models/Opportunity/item-custom-field';
import { selectAllCompanyLabels } from '../../system-settings/company-labels/selectors/all-companyLabels-as-array.selector';
import { selectOppCustomFieldsAsArray } from './opp-custom-fields.selector';
import { OpportunityCustomField } from 'src/app/models/Opportunity/opportunity-custom-field';

// -- Opportunity Columns
export const selectAllColsByOpp = (state: RootState) => state.opportunities.allColsByOpp;
export const selectCacheColByOpp = (state: RootState) => state.opportunities.selColsByOpp;

export const selectAllOppColsWithLabels: MemoizedSelector<RootState, ColumnHeader[]> =
  addCompanyLabelColumns(addOppLabelColumns(addOppCustomFields(selectAllColsByOpp)));

export const selectExpOppColNames: MemoizedSelector<RootState, string[]> =
  createExpiredOppColumnsSelector(selectAllOppColsWithLabels, selectCacheColByOpp);

export const selectSelOppColumns: MemoizedSelector<RootState, ColumnHeader[]> =
  createCachedOppColumnsSelector(selectAllOppColsWithLabels, selectCacheColByOpp);

// -- Item Columns
export const selectAllColsByItem = (state: RootState) =>
  state.opportunities.allColsByItem;
export const selectCacheColByItem = (state: RootState) =>
  state.opportunities.selColsByItem;

export const selectAllItemColsWithLabels: MemoizedSelector<RootState, ColumnHeader[]> =
  addCompanyLabelColumns(addOppLabelColumns(addItemCustomFields(selectAllColsByItem)));

export const selectExpItemColNames: MemoizedSelector<RootState, string[]> =
  createExpiredOppColumnsSelector(selectAllItemColsWithLabels, selectCacheColByItem);

export const selectSelItemColumns: MemoizedSelector<RootState, ColumnHeader[]> =
  createCachedOppColumnsSelector(selectAllItemColsWithLabels, selectCacheColByItem);

function addItemCustomFields(
  columnsSelector: Selector<RootState, ColumnHeader[]>
): MemoizedSelector<RootState, ColumnHeader[]> {
  return createSelector(
    columnsSelector,
    selectItemCustomFieldsAsArray,
    (allDefColumns: ColumnHeader[], iCFs: ItemCustomField[]): ColumnHeader[] => {
      return [
        ...allDefColumns,
        ...iCFs
          .sort((a, b) => {
            const textA = a.name.toLowerCase();
            const textB = b.name.toLowerCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          })
          .map((icf: ItemCustomField) => {
            let columnType: ColumnType;
            let isCurrency = false;
            switch (icf.typeId) {
              case 1:
                columnType = ColumnType.STRING;
                break;
              case 2:
                columnType = ColumnType.BOOLEAN;
                break;
              case 4:
                columnType = ColumnType.NUMERIC;
                isCurrency = true;
                break;
              case 14:
                columnType = ColumnType.NUMERIC;
                break;
            }
            return {
              name: icf.name,
              displayName: icf.name,
              type: columnType,
              isCurrency
            };
          })
      ]
        .map((column) => {
          return {
            ...column,
            alignRight: column.type !== ColumnType.STRING
          };
        })
        .sort((a, b) => {
          const textA = a.alignRight;
          const textB = b.alignRight;
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
    }
  );
}

function addOppCustomFields(
  columnsSelector: Selector<RootState, ColumnHeader[]>
): MemoizedSelector<RootState, ColumnHeader[]> {
  return createSelector(
    columnsSelector,
    selectOppCustomFieldsAsArray,
    (allDefColumns: ColumnHeader[], oCFs: OpportunityCustomField[]): ColumnHeader[] => {
      return [
        ...allDefColumns,
        ...oCFs
          .sort((a, b) => {
            const textA = a.name.toLowerCase();
            const textB = b.name.toLowerCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          })
          .map((ocf: OpportunityCustomField) => {
            let columnType: ColumnType;
            let isCurrency = false;
            switch (ocf.typeInfo.id) {
              case 1:
                columnType = ColumnType.STRING;
                break;
              case 2:
                columnType = ColumnType.BOOLEAN;
                break;
              case 4:
                columnType = ColumnType.NUMERIC;
                isCurrency = true;
                break;
              case 14:
                columnType = ColumnType.NUMERIC;
                break;
            }
            return {
              name: ocf.name,
              displayName: ocf.name,
              type: columnType,
              isCurrency
            };
          })
      ]
        .map((column) => {
          return {
            ...column,
            alignRight: column.type !== ColumnType.STRING
          };
        })
        .sort((a, b) => {
          const textA = a.alignRight;
          const textB = b.alignRight;
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
    }
  );
}

function addOppLabelColumns(
  columnsSelector: Selector<RootState, ColumnHeader[]>
): MemoizedSelector<RootState, ColumnHeader[]> {
  return createSelector<RootState, unknown[], ColumnHeader[]>(
    columnsSelector,
    selectAllOpportunityLabels,

    (allDefColumns: ColumnHeader[], labels: LabelGroup[]): ColumnHeader[] => {
      return [
        ...allDefColumns,
        ...Object.values(labels)
          .filter((lg) => !lg.deleted)
          .sort((a, b) => {
            const textA = a.name.toLowerCase();
            const textB = b.name.toLowerCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          })
          .map((label) => ({
            name: label.name,
            displayName: label.name,
            type: ColumnType.LABEL
          }))
      ];
    }
  );
}

function addCompanyLabelColumns(
  columnsSelector: Selector<RootState, ColumnHeader[]>
): MemoizedSelector<RootState, ColumnHeader[]> {
  return createSelector<RootState, unknown[], ColumnHeader[]>(
    columnsSelector,
    selectAllCompanyLabels,
    (allDefColumns: ColumnHeader[], labels: LabelGroup[]): ColumnHeader[] => {
      return [
        ...allDefColumns,
        ...Object.values(labels)
          .sort((a, b) => {
            const textA = a.name.toLowerCase();
            const textB = b.name.toLowerCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          })
          .map((label) => ({
            name: label.name,
            displayName: 'Company - ' + label.name,
            type: ColumnType.LABEL
          }))
      ];
    }
  );
}

// These are columns that aren't in use anymore,
// not just removed from the selectedColumns list,
// but columns that are still in the selectedColumns list but aren't available
// anymore as a valid column, so if they remain inside the cached selectedColumn
// Object, they will cause an error with the table.
export function createExpiredOppColumnsSelector(
  allColumnsSelector: Selector<RootState, ColumnHeader[]>,
  selColumnsSelector: Selector<RootState, ColumnHeader[]>
): MemoizedSelector<RootState, string[]> {
  return createSelector(
    allColumnsSelector,
    selColumnsSelector,
    (allColumns: ColumnHeader[], cachedColumns: ColumnHeader[]): string[] => {
      const allColumnNames: string[] = allColumns.map((column) => column.name);
      return cachedColumns
        .filter((col) => !!col)
        .filter((column) => !allColumnNames.includes(column.name))
        .map((column) => column.name);
    }
  );
}

export function createCachedOppColumnsSelector(
  allColumnsSelector: Selector<RootState, ColumnHeader[]>,
  selColumnsSelector: Selector<RootState, ColumnHeader[]>
): MemoizedSelector<RootState, ColumnHeader[]> {
  return createSelector(
    allColumnsSelector,
    selColumnsSelector,
    (allColumns: ColumnHeader[], cachedColumns: ColumnHeader[]): ColumnHeader[] => {
      const allColumnNames: string[] = allColumns.map((column) => column.name);
      const selCols = cachedColumns
        .filter((col) => !!col)
        .filter((column) => allColumnNames.includes(column.name));
      return selCols.filter(
        (selCol, index) => selCols.map((col) => col.name).indexOf(selCol.name) === index
      );
    }
  );
}
