import { Component, Input, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';

import { UserV2 } from '../../models/admin/users/user-V2';
import { CallNoteV2 } from './../../models/call-notes/call-note-V2';
import { DxSelectBoxComponent } from 'devextreme-angular';

@Component({
  selector: 'call-note-follow-up',
  templateUrl: './call-note-follow-up.component.html',
  styleUrls: ['./call-note-popup.component.scss']
})
export class CallNoteFollowUpComponent implements OnInit {
  @ViewChild(DxSelectBoxComponent) selectBoxes: DxSelectBoxComponent;
  @Input() editCallNotesFeature: boolean;
  @Input() callNote: CallNoteV2;
  @Input() assignTasksFeature: boolean;
  @Input() companyAgents: UserV2[];
  followUpExpanded: boolean = true;
  datesDuration: number;

  constructor() {}

  ngOnInit() {}

  expandFollowUpRow(): void {
    this.followUpExpanded = !this.followUpExpanded;
  }

  incrementedDates(e: any, date: Date): void {
    if (date == null) {
      const dateNow = new Date(Date.now());
      let minutes = dateNow.getMinutes();

      if (minutes > 0 && minutes < 15) {
        minutes = 15;
      } else if (minutes > 15 && minutes < 30) {
        minutes = 30;
      } else if (minutes > 30 && minutes < 45) {
        minutes = 45;
      } else if (minutes > 45 && minutes < 60) {
        minutes = 0;
      }

      const newDate = new Date(
        dateNow.getFullYear(),
        dateNow.getMonth(),
        dateNow.getDate(),
        dateNow.getHours(),
        minutes
      );
      this.callNote.followUp.dueDateStart = newDate;
    }
    e.component._strategy._timeView._minuteBox.option('step', 15);
    e.component._strategy._timeView._minuteBox.option('min', -60);
  }

  startDateChanged(startDate: Date): void {
    if (startDate != null && this.callNote.followUp.dueDateEnd == null) {
      this.callNote.followUp.dueDateEnd = moment(
        moment(startDate).add(30, 'minutes')
      ).toDate();
      this.datesDuration = 30;
    } else if (startDate) {
      if (this.datesDuration != 0 && this.datesDuration != null) {
        this.callNote.followUp.dueDateEnd = moment(
          moment(startDate).add(this.datesDuration, 'minutes')
        ).toDate();
      }
    }
  }

  endDateChanged(startDate: Date, endDate: Date): void {
    if (startDate && endDate) {
      this.datesDuration = moment
        .duration(moment(endDate).diff(moment(startDate)))
        .asMinutes();
      if (this.datesDuration < 0) {
        this.datesDuration = 0;
      }
    }
  }

  isEndDateDisabled(startDate: Date): boolean {
    if (startDate && startDate != null) {
      return false;
    }
    return true;
  }

  assignedAgentChange(e): void {
    if (e.selectedItem) {
      this.callNote.followUp.assignedTo = {
        id: e.selectedItem.id,
        mailbox: e.selectedItem.mailbox,
        agencyId: e.selectedItem.agencyId,
        firstName: e.selectedItem.firstName,
        lastName: e.selectedItem.lastName,
        deleted: e.selectedItem.deleted
      };
    }
  }
}
