import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Opportunity } from 'src/app/models/Opportunity/opportunity';
import { Observable } from 'rxjs';
import { TableItem } from 'src/app/models/Opportunity/tableItem';
import { ItemCustomField } from 'src/app/models/Opportunity/item-custom-field';
import { ItemCustomFieldValue } from 'src/app/models/Opportunity/item-custom-field-value';
import { GlobalFilter } from 'src/app/models/global-filter';
import { JpiResponse } from 'src/app/models/http/jpi-response.model';
import { ElasticOpportunity } from 'src/app/models/Opportunity/elastic-opportunity';
import { ElasticOppItem } from 'src/app/models/Opportunity/elastic-opp-item';
import { OpportunityCustomField } from 'src/app/models/Opportunity/opportunity-custom-field';
import { OpportunityCustomFieldValue } from 'src/app/models/Opportunity/opportunity-custom-field-value';
import { FunnelResponse } from 'src/app/models/Opportunity/funnel-response';

@Injectable({
  providedIn: 'root'
})
export class OpportunitiesService {
  private jpiUrl = environment.jpiUrl;

  constructor(private http: HttpClient) {}

  getOpportunities(): Observable<Opportunity[]> {
    return this.http.get(`${this.jpiUrl}/opportunities/v2`) as Observable<Opportunity[]>;
  }

  getOpportunitiesElastic(
    filter: GlobalFilter,
    params: { limit: string; order?: string; skip?: string }
  ): Observable<JpiResponse<ElasticOpportunity>> {
    return this.http.post(`${this.jpiUrl}/opportunities/v2/search`, filter, {
      params
    }) as Observable<JpiResponse<ElasticOpportunity>>;
  }

  getOppItemsElastic(
    filter: GlobalFilter,
    params: { limit: string; order?: string; skip?: string }
  ): Observable<JpiResponse<ElasticOppItem>> {
    return this.http.post(`${this.jpiUrl}/opportunities/v2/searchByItem`, filter, {
      params
    }) as Observable<JpiResponse<ElasticOppItem>>;
  }

  getOpportunity(id: number): Observable<Opportunity> {
    return this.http.get(
      `${this.jpiUrl}/opportunities/v2/${id}`
    ) as Observable<Opportunity>;
  }

  createOpportunity(opportunity: Opportunity): Observable<Opportunity> {
    return this.http.post(
      `${this.jpiUrl}/opportunities/v2`,
      opportunity
    ) as Observable<Opportunity>;
  }

  updateOpportunity(opportunity: Opportunity): Observable<Opportunity> {
    return this.http.put(
      `${this.jpiUrl}/opportunities/v2/patch/${opportunity.id}`,
      opportunity
    ) as Observable<Opportunity>;
  }

  deleteOpportunity(id: number): Observable<Opportunity> {
    return this.http.delete(
      `${this.jpiUrl}/opportunities/v2/${id}`
    ) as Observable<Opportunity>;
  }

  getOppItemCustomFields(): Observable<ItemCustomField[]> {
    return this.http.get(`${this.jpiUrl}/opportunities/itemcustomfields`) as Observable<
      ItemCustomField[]
    >;
  }

  createOppItemCustomField(cf: ItemCustomField): Observable<ItemCustomField> {
    return this.http.post(
      `${this.jpiUrl}/opportunities/itemcustomfields`,
      cf
    ) as Observable<ItemCustomField>;
  }

  updateOppItemCustomField(cf: ItemCustomField): Observable<ItemCustomField> {
    return this.http.put(
      `${this.jpiUrl}/opportunities/itemcustomfields/${cf.id}`,
      cf
    ) as Observable<ItemCustomField>;
  }

  deleteOppItemCustomField(cfId: number): Observable<boolean> {
    return this.http.delete(
      `${this.jpiUrl}/opportunities/itemcustomfields/${cfId}`
    ) as Observable<boolean>;
  }

  createOppItemCustomFieldValue(
    cf: ItemCustomFieldValue
  ): Observable<ItemCustomFieldValue> {
    return this.http.post(
      `${this.jpiUrl}/opportunities/itemcustomfieldvalues`,
      cf
    ) as Observable<ItemCustomFieldValue>;
  }

  updateOppItemCustomFieldValue(
    cf: ItemCustomFieldValue
  ): Observable<ItemCustomFieldValue> {
    return this.http.put(
      `${this.jpiUrl}/opportunities/itemcustomfieldvalues/${cf.id}`,
      cf
    ) as Observable<ItemCustomFieldValue>;
  }

  deleteOppItemCustomFieldValue(cf: ItemCustomFieldValue): Observable<boolean> {
    return this.http.delete(
      `${this.jpiUrl}/opportunities/itemcustomfieldvalues/${cf.id}`
    ) as Observable<boolean>;
  }

  getOpportunityCustomFields(): Observable<OpportunityCustomField[]> {
    return this.http.get(`${this.jpiUrl}/opportunities/v2/customfields`) as Observable<
      OpportunityCustomField[]
    >;
  }

  createOpportunityCustomField(
    cf: OpportunityCustomField
  ): Observable<OpportunityCustomField> {
    return this.http.post(
      `${this.jpiUrl}/opportunities/v2/customfields`,
      cf
    ) as Observable<OpportunityCustomField>;
  }

  updateOpportunityCustomField(
    cf: OpportunityCustomField
  ): Observable<OpportunityCustomField> {
    return this.http.put(
      `${this.jpiUrl}/opportunities/v2/customfields/${cf.id}`,
      cf
    ) as Observable<OpportunityCustomField>;
  }

  deleteOpportunityCustomField(cfId: number): Observable<boolean> {
    return this.http.delete(
      `${this.jpiUrl}/opportunities/v2/customfields/${cfId}`
    ) as Observable<boolean>;
  }

  getOpportunityCustomFieldValues(
    opportunityId: number
  ): Observable<OpportunityCustomField[]> {
    return this.http.get(
      `${this.jpiUrl}/opportunities/v2/${opportunityId}/customfieldvalues`
    ) as Observable<OpportunityCustomField[]>;
  }

  createOpportunityCustomFieldValue(
    cf: OpportunityCustomFieldValue
  ): Observable<OpportunityCustomFieldValue> {
    return this.http.post(
      `${this.jpiUrl}/opportunities/v2/${cf.opportunityId}/customfieldvalues`,
      cf
    ) as Observable<OpportunityCustomFieldValue>;
  }

  updateOpportunityCustomFieldValue(
    cf: OpportunityCustomFieldValue
  ): Observable<OpportunityCustomFieldValue> {
    return this.http.put(
      `${this.jpiUrl}/opportunities/v2/${cf.opportunityId}/customfieldvalues/${cf.id}`,
      cf
    ) as Observable<OpportunityCustomFieldValue>;
  }

  deleteOpportunityCustomFieldValue(
    cf: OpportunityCustomFieldValue
  ): Observable<boolean> {
    return this.http.delete(
      `${this.jpiUrl}/opportunities/v2/${cf.opportunityId}/customfieldvalues/${cf.id}`
    ) as Observable<boolean>;
  }

  reorderOpportunityCustomFields(
    cfs: OpportunityCustomField[]
  ): Observable<OpportunityCustomField[]> {
    return this.http.put(
      `${this.jpiUrl}/opportunities/v2/customfields/updateListOrder`,
      cfs
    ) as Observable<OpportunityCustomField[]>;
  }

  getFunnel(isClosed: boolean, days: number) {
    return this.http.get(
      `${this.jpiUrl}/opportunities/v2/funnel?isClosed=${isClosed}&days=${days}`
    ) as Observable<FunnelResponse>;
  }
}
