import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import {
  AddProjectLabels,
  UpdateProjectLabels,
  DeleteProjectLabels,
  ClearProjectLabelId,
  LoadProjectLabels
} from 'src/app/store/system-settings/project-labels/project-labels.actions';
import { CloseDrawer, OpenDrawer } from 'src/app/store/layout/layout.actions';
import { DeleteObject } from 'src/app/store/undo-delete/undo-delete.actions';
import {
  LoadProjectLabelsIndividiuals,
  SelectProjectLabelIndividualId,
  DeleteProjectLabelsIndividual,
  CreateProjectLabelsIndividual,
  UpdateProjectLabelsIndividual
} from 'src/app/store/system-settings/project-labels-individual/project-labels-individiual.actions';
import { Label } from 'src/app/models/label';
import { selectSelectedProjectLabel } from 'src/app/store/system-settings/project-labels/selectors/selected-projectLabels.selector';
// eslint-disable-next-line max-len
import { selectProjectLabelsIndividualAsArray } from '../../../../../store/system-settings/project-labels/selectors/all-projectLabelsIndividual-as-array.selector';
import { LabelGroup } from 'src/app/models/label-group';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDeleteComponent } from 'src/app/view/shared/confirm-delete/confirm-delete.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'tn-add-project-labels',
  templateUrl: './add-project-labels.component.html',
  styleUrls: ['./add-project-labels.component.css']
})
export class AddProjectLabelsComponent implements OnInit, OnDestroy {
  currentProjectLabelIndividual: Label;
  $currentProjectLabelIndivdualSub: Subscription;
  projectLabelIndividual: Label[];
  $projectLabelIndividualSub: Subscription;
  projectLabelForm: FormGroup = new FormGroup({
    name: new FormControl(null, Validators.required),
    required: new FormControl(null),
    allowMultiple: new FormControl(null),
    description: new FormControl(null),
    id: new FormControl(null)
  });
  projectLabelIndividualForm: FormGroup;
  projectLabels;
  currentProjectLabel: LabelGroup;
  $projectLabelSub: Subscription;
  $currentProjectLabelSub: Subscription;
  @ViewChild(MatMenuTrigger, { static: true }) addLabelMenuTrigger: MatMenuTrigger;
  $addLabelFormEventsSubject: Subject<void> = new Subject<void>();
  $focusEventSubject: Subject<void> = new Subject<void>();

  constructor(private store: Store<RootState>, private dialog: MatDialog) {}

  ngOnInit() {
    this.$currentProjectLabelSub = this.store
      .select(selectSelectedProjectLabel)
      .subscribe((projectLabel) => {
        this.currentProjectLabel = projectLabel;
        if (!!projectLabel && !!projectLabel.id) {
          this.projectLabelForm.patchValue({
            ...projectLabel
          });
          this.projectLabelForm.markAsPristine();
          this.store.dispatch(
            new LoadProjectLabelsIndividiuals(this.currentProjectLabel.id)
          );
        }
      });
    this.$currentProjectLabelIndivdualSub = this.store
      .select(selectProjectLabelsIndividualAsArray)
      .subscribe(
        (projectLabelIndividual) => (this.projectLabelIndividual = projectLabelIndividual)
      );
    this.projectLabelIndividualForm = new FormGroup({
      name: new FormControl(null),
      id: new FormControl(null),
      groupId: new FormControl(null),
      description: new FormControl(null)
    });
  }

  ngOnDestroy() {
    this.$currentProjectLabelSub.unsubscribe();
    this.$currentProjectLabelIndivdualSub.unsubscribe();
  }

  save() {
    this.store.dispatch(new AddProjectLabels(this.projectLabelForm.value));
    this.store.dispatch(new LoadProjectLabels());
  }

  cancelHandler() {
    this.$addLabelFormEventsSubject.next();
    this.projectLabelIndividualForm.reset();
    this.addLabelMenuTrigger.closeMenu();
  }

  saveIndividual() {
    this.$addLabelFormEventsSubject.next();
    this.store.dispatch(
      new CreateProjectLabelsIndividual({
        label: this.projectLabelIndividualForm.value,
        groupId: this.currentProjectLabel.id
      })
    );
    this.store.dispatch(new LoadProjectLabels());
    this.projectLabelIndividualForm.reset();
  }

  editIndividual() {
    this.store.dispatch(
      new UpdateProjectLabelsIndividual({
        label: this.projectLabelIndividualForm.value,
        groupId: this.currentProjectLabel.id
      })
    );
    this.store.dispatch(new LoadProjectLabels());
  }

  menuOpened() {
    this.projectLabelIndividualForm.reset();
    this.$focusEventSubject.next();
  }

  menuOpenedEdit(companyLabelIndividual) {
    this.projectLabelIndividualForm.reset();
    this.$focusEventSubject.next();
    this.projectLabelIndividualForm.patchValue({
      name: companyLabelIndividual.name,
      id: companyLabelIndividual.id,
      groupId: companyLabelIndividual.groupId,
      description: companyLabelIndividual.description
    });
  }

  update() {
    this.store.dispatch(
      new UpdateProjectLabels({
        ...this.projectLabelForm.value,
        labels: this.projectLabelIndividual
      })
    );
    this.store.dispatch(new CloseDrawer());
    this.store.dispatch(new ClearProjectLabelId());
    this.store.dispatch(new LoadProjectLabels());
  }

  cancel(): void {
    this.store.dispatch(new CloseDrawer());
    this.store.dispatch(new ClearProjectLabelId());
  }

  delete(): void {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {});

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((willDelete: boolean) => {
        if (willDelete) {
          this.store.dispatch(new DeleteProjectLabels(this.currentProjectLabel.id));
          this.store.dispatch(new CloseDrawer());
          this.store.dispatch(new ClearProjectLabelId());
          this.store.dispatch(new LoadProjectLabels());
        }
      });
  }

  deleteIndividual(projectLabelIndividual) {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {});

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((willDelete: boolean) => {
        if (willDelete) {
          this.store.dispatch(
            new DeleteProjectLabelsIndividual({
              id: projectLabelIndividual.id,
              groupId: projectLabelIndividual.groupId
            })
          );
          this.store.dispatch(new LoadProjectLabels());
        }
      });
  }
}
