import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProductLine } from 'src/app/models/product-lines/product-line';
import { ServicesModule } from '../services.module';

@Injectable({
  providedIn: ServicesModule
})
export class ProductLinesService {
  private jpiUrl = environment.jpiUrl;

  constructor(private http: HttpClient) {}

  getProductLines(): Observable<ProductLine[]> {
    return this.http.get(`${this.jpiUrl}/productlines`) as Observable<ProductLine[]>;
  }
}
