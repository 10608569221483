<form
  [formGroup]="addItemForm"
  class="new-item-form"
  (click)="$event.stopPropagation()"
  (keydown.tab)="$event.stopPropagation()"
  (keydown.shift.tab)="$event.stopPropagation()"
>
  <div class="item-info" fxLayoutAlign="space-between center" fxLayoutGap="12px">
    <mat-form-field fxFlex="calc(30%)">
      <mat-select
        formControlName="itemName"
        placeholder="Item"
        required
        [compareWith]="compareObjects"
      >
        <mat-option *ngFor="let itemName of itemNames" [value]="itemName">
          {{ itemName.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="item-info" fxLayoutAlign="space-between center" fxLayoutGap="12px">
    <mat-form-field fxFlex="calc(30%)">
      <textarea
        matInput
        type="paragraph"
        placeholder="Description"
        tnAutosizeTextarea
        formControlName="itemDesc"
      ></textarea>
    </mat-form-field>
  </div>

  <div class="item-info" fxLayoutAlign="space-between center" fxLayoutGap="12px">
    <mat-form-field fxFlex="calc(30%)">
      <span class="input-icon" matPrefix> <mat-icon>attach_money</mat-icon>&nbsp; </span>
      <input
        matInput
        autocomplete="off"
        formControlName="amount"
        placeholder="Amount"
        required
        type="number"
      />
    </mat-form-field>

    <mat-form-field fxFlex="calc(30%)">
      <input
        matInput
        autocomplete="off"
        formControlName="quantity"
        required
        placeholder="Quantity"
        type="number"
      />
    </mat-form-field>
  </div>

  <div class="item-info" fxLayoutAlign="space-between center" fxLayoutGap="12px">
    <mat-form-field fxFlex="calc(30%)">
      <input
        matInput
        type="number"
        placeholder="Probability"
        autocomplete="off"
        formControlName="probability"
        [value]="currentItem?.probability"
      />
      <div
        class="feedback"
        *ngIf="
          addItemForm['controls']['probability'].errors?.min ||
          addItemForm['controls']['probability'].errors?.max
        "
      >
        Must be a number from 1 to 100
      </div>
    </mat-form-field>
  </div>
</form>
<mat-divider></mat-divider>
<form
  [formGroup]="customFieldsForm"
  (click)="$event.stopPropagation()"
  class="new-item-form"
  (keydown.tab)="$event.stopPropagation()"
  (keydown.shift.tab)="$event.stopPropagation()"
>
  <div class="item-custom-fields">
    <div *ngFor="let field of $customFields | async | sort: 'listorder'">
      <div [ngSwitch]="field.typeName">
        <div *ngSwitchCase="'Text'">
          <mat-form-field fxFlex="100">
            <input
              matInput
              autocomplete="off"
              [formControlName]="field.id"
              [placeholder]="field.name"
              type="text"
            />
          </mat-form-field>
        </div>
        <div *ngSwitchCase="'Checkbox'" class="custom-field-checkbox">
          <mat-checkbox [formControlName]="field.id">{{ field.name }}</mat-checkbox>
        </div>
        <div *ngSwitchCase="'Money'">
          <mat-form-field fxFlex="100">
            <span class="input-icon" matPrefix>
              <mat-icon>attach_money</mat-icon>&nbsp;
            </span>
            <input
              matInput
              autocomplete="off"
              [formControlName]="field.id"
              [placeholder]="field.name"
              type="number"
            />
          </mat-form-field>
        </div>
        <div *ngSwitchCase="'Number'">
          <mat-form-field fxFlex="100">
            <input
              matInput
              autocomplete="off"
              [formControlName]="field.id"
              [placeholder]="field.name"
              type="number"
            />
          </mat-form-field>
        </div>
        <div *ngSwitchDefault>
          <mat-form-field fxFlex="100">
            <input
              matInput
              autocomplete="off"
              [formControlName]="field.id"
              [placeholder]="field.name"
              type="text"
            />
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</form>
<div class="actions" fxLayout="row-reverse" fxLayoutAlign="space-between center">
  <div fxLayout="row-reverse" fxLayoutGap="12px">
    <button
      [disabled]="
        addItemForm.invalid ||
        customFieldsForm.invalid ||
        (addItemForm.pristine && customFieldsForm.pristine)
      "
      mat-raised-button
      color="primary"
      mat-button
      class="button-container"
      (click)="submitItem()"
    >
      Save
    </button>
    <button
      preventDefaultAction
      class="button-container"
      mat-raised-button
      color="white"
      mat-button
      (click)="cancelItemHandler()"
    >
      Cancel
    </button>
  </div>
</div>
