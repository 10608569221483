import { RootState } from '../../store.reducer';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { Contact } from 'src/app/models/contacts/contact';
import { IdNameItem } from 'src/app/models/id-name-item';
import { GenericMap } from 'src/app/models/generic-map';
import { Company } from 'src/app/models/companies/company';
import { selectAllCompanies } from './current-company.selector';

export const selectContacts = (state: RootState) => state.companies.contacts;
export const selectedSearchCompanyId = (state: RootState) =>
  state.companies.search.selectedSearchCompanyId;

export const selectCompanyContacts: MemoizedSelector<RootState, IdNameItem[]> =
  createSelector(
    selectContacts,
    selectedSearchCompanyId,
    (
      contacts: GenericMap<{ data: { [id: number]: Contact } }>,
      companyId: number
    ): IdNameItem[] => {
      return !!contacts[companyId]
        ? Object.values(contacts[companyId].data).map((contact: Contact) => {
            return contact.lastName
              ? { id: contact.id, name: contact.firstName + ' ' + contact.lastName }
              : { id: contact.id, name: contact.firstName };
          })
        : [];
    }
  );

export const selectContactsFromCompanyData: MemoizedSelector<RootState, IdNameItem[]> =
  createSelector(
    selectAllCompanies,
    selectedSearchCompanyId,
    (companies: GenericMap<Company>, companyId: number): IdNameItem[] => {
      return !!companies[companyId]
        ? Object.values(companies[companyId].contacts).map((contact: Contact) => {
            return contact.lastName
              ? { id: contact.id, name: contact.firstName + ' ' + contact.lastName }
              : { id: contact.id, name: contact.firstName };
          })
        : [];
    }
  );
