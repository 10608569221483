import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  OpportunityLabelsActionTypes,
  OpportunityLabelsActions,
  LoadOpportunityLabelsSuccess,
  LoadOpportunityLabelsFailure,
  AddOpportunityLabels,
  UpdateOpportunityLabels,
  UpdateOpportunityLabelsFailure,
  DeleteOpportunityLabels,
  DeleteOpportunityLabelsSuccess,
  DeleteOpportunityLabelsFailure,
  LoadOpportunityLabels,
  SelectOpportunityLabelId,
  AddOpportunityLabelsSuccess,
  AddOpportunityLabelsFailure,
  UpdateOpportunityLabelsSuccess,
  BulkUpdateOpportunityLabelGroups,
  BulkUpdateOpportunityLabelGroupsFailure,
  BulkUpdateOpportunityLabelGroupsSuccess,
  SetOppLabelGroupListOrder
} from './opportunity-labels.actions';
import { OpportunitiesLabelsService } from 'src/app/services/system-settings/opportunities/opportunities-labels.service';
import { mergeMap, map, catchError, switchMap, withLatestFrom } from 'rxjs/operators';
import { LabelGroup } from 'src/app/models/label-group';
import { HttpErrorResponse } from '@angular/common/http';
import { convertToMap } from 'src/app/utils/convertToMap';
import { of, Observable, forkJoin } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import { selectOpportunityLabelsAsArray } from '../selectors/all-opportunityLabels-as-array.selector';
import { isEqualObjects } from 'src/app/utils/isEqualObjects';
@Injectable()
export class OpportunityLabelsEffects {
  loadOpportunityLabels$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunityLabelsActionTypes.LoadOpportunityLabels),
      mergeMap(() =>
        this.oppLabelsService.getAll().pipe(
          map((data: LabelGroup[]) => {
            return new LoadOpportunityLabelsSuccess(convertToMap(data, 'id'));
          }),
          catchError((error: HttpErrorResponse) =>
            of(new LoadOpportunityLabelsFailure(error))
          )
        )
      )
    )
  );

  addOpportunityLabels$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunityLabelsActionTypes.AddOpportunityLabels),
      map((action: AddOpportunityLabels) => action.payload),
      mergeMap((opportunityLabel: LabelGroup) => {
        return this.oppLabelsService.addOpportunityLabel(opportunityLabel).pipe(
          switchMap((newOpportunityLabel: LabelGroup) => [
            new AddOpportunityLabelsSuccess(newOpportunityLabel),
            new SelectOpportunityLabelId(newOpportunityLabel.id),
            new LoadOpportunityLabels()
          ]),
          catchError((error: HttpErrorResponse) =>
            of(new AddOpportunityLabelsFailure(error))
          )
        );
      })
    )
  );

  addOpportunityLabelsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunityLabelsActionTypes.AddOpportunityLabelsSuccess),
      map(() => new LoadOpportunityLabels())
    )
  );

  updateOpportunityLabels$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunityLabelsActionTypes.UpdateOpportunityLabels),
      map((action: UpdateOpportunityLabels) => action.payload),
      mergeMap((opportunityLabel: LabelGroup) => {
        return this.oppLabelsService.updateOpportunityLabel(opportunityLabel).pipe(
          switchMap((updatedOpportunityLabel: LabelGroup) => [
            new UpdateOpportunityLabelsSuccess(updatedOpportunityLabel),
            new LoadOpportunityLabels()
          ]),
          catchError((error: HttpErrorResponse) =>
            of(new UpdateOpportunityLabelsFailure(error))
          )
        );
      })
    )
  );

  bulkUpdateOpportunityLabelGroups$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunityLabelsActionTypes.BulkUpdateOpportunityLabelGroups),
      map((action: BulkUpdateOpportunityLabelGroups) => action.payload),
      withLatestFrom(this.store$.select(selectOpportunityLabelsAsArray)),
      mergeMap(([editedLabelGroups, stateLabelGroups]: [LabelGroup[], LabelGroup[]]) => {
        const unchangedLabelGroups = [];
        const updatingLabelGroups = editedLabelGroups.reduce(
          (acc: LabelGroup[], uLG: LabelGroup) => {
            const sLG = stateLabelGroups.find((stateLG) => stateLG.id === uLG.id);
            if (!isEqualObjects(sLG, uLG)) {
              acc.push(uLG);
            } else {
              unchangedLabelGroups.push(uLG);
            }
            return acc;
          },
          []
        );
        return forkJoin([
          ...updatingLabelGroups.map((lg) =>
            this.oppLabelsService.updateOpportunityLabel(lg)
          ),
          ...unchangedLabelGroups.map((lg) => of(lg))
        ]).pipe(
          map(
            (res: LabelGroup[]) =>
              new BulkUpdateOpportunityLabelGroupsSuccess(convertToMap(res, 'id'))
          ),
          catchError((error: HttpErrorResponse) =>
            of(new BulkUpdateOpportunityLabelGroupsFailure(error))
          )
        );
      })
    )
  );

  deleteOpportunityLabels$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunityLabelsActionTypes.DeleteOpportunityLabels),
      map((action: DeleteOpportunityLabels) => action.payload),
      mergeMap((opportunityLabelId: number) =>
        this.oppLabelsService.deleteOpportunityLabel(opportunityLabelId).pipe(
          switchMap(() => [
            new DeleteOpportunityLabelsSuccess(opportunityLabelId),
            new LoadOpportunityLabels()
          ]),
          catchError((error: HttpErrorResponse) =>
            of(new DeleteOpportunityLabelsFailure(error))
          )
        )
      )
    )
  );

  deleteOpportunityLabelsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunityLabelsActionTypes.DeleteOpportunityLabelsSuccess),
      switchMap(() => [new LoadOpportunityLabels()])
    )
  );

  setOppLabelGroupListOrder$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunityLabelsActionTypes.SetOppLabelGroupListOrder),
      map((action: SetOppLabelGroupListOrder) => action.payload),
      mergeMap((labelGroups: LabelGroup[]) =>
        this.oppLabelsService.setLabelGroupListOrder(labelGroups).pipe(
          switchMap((reorderedGroups) => [
            new LoadOpportunityLabelsSuccess(reorderedGroups)
          ]),
          catchError((error: HttpErrorResponse) =>
            of(new LoadOpportunityLabelsFailure(error))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions<OpportunityLabelsActions>,
    private store$: Store<RootState>,
    private oppLabelsService: OpportunitiesLabelsService
  ) {}
}
