import { Action } from '@ngrx/store';
import { ItemName } from 'src/app/models/Opportunity/itemName';
import { HttpErrorResponse } from '@angular/common/http';

export enum ItemNamesActionTypes {
  SelectItemNameId = '[ItemNames] Select ItemName ID',
  ClearItemNameId = '[ItemNames] Clear Selected ItemName ID',

  LoadItemNames = '[ItemNames] Load ItemNames',
  LoadItemNamesSuccess = '[ItemNames] Load ItemsNames Success',
  LoadItemNamesFailure = '[ItemNames] Load ItemNames Failure',

  CreateItemName = '[ItemNames] Create itemName',
  CreateItemNameSuccess = '[ItemNames] Create itemName Success',
  CreateItemNameFailure = '[ItemNames] Create itemName Failure',

  UpdateItemName = '[ItemNames] Update ItemName',
  UpdateItemNameSuccess = '[ItemNames] Update ItemName Success',
  UpdateItemNameFailure = '[ItemNames] Update ItemName Failure',

  DeleteItemName = '[ItemNames] Delete ItemName',
  DeleteItemNameSuccess = '[ItemNames] Delete ItemName Success',
  DeleteItemNameFailure = '[ItemNames] Delete ItemName Failure',

  LoadItemNameTypes = '[ItemNames] Load ItemName Types',
  LoadItemNameTypesSuccess = '[ItemNames] Load ItemName Types Success',
  LoadItemNameTypesFailure = '[ItemNames] Load ItemName Types Failure'
}

export class SelectItemNameId implements Action {
  readonly type = ItemNamesActionTypes.SelectItemNameId;
  constructor(public payload: number) {}
}

export class ClearItemNameId implements Action {
  readonly type = ItemNamesActionTypes.ClearItemNameId;
}

export class LoadItemNames implements Action {
  readonly type = ItemNamesActionTypes.LoadItemNames;
}

export class LoadItemNamesSuccess implements Action {
  readonly type = ItemNamesActionTypes.LoadItemNamesSuccess;
  constructor(public payload: ItemName[]) {}
}

export class LoadItemNamesFailure implements Action {
  readonly type = ItemNamesActionTypes.LoadItemNamesFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class CreateItemName implements Action {
  readonly type = ItemNamesActionTypes.CreateItemName;
  constructor(public payload: ItemName) {}
}

export class CreateItemNameSuccess implements Action {
  readonly type = ItemNamesActionTypes.CreateItemNameSuccess;
  constructor(public payload: ItemName) {}
}

export class CreateItemNameFailure implements Action {
  readonly type = ItemNamesActionTypes.CreateItemNameFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class UpdateItemName implements Action {
  readonly type = ItemNamesActionTypes.UpdateItemName;
  constructor(public payload: ItemName) {}
}

export class UpdateItemNameSuccess implements Action {
  readonly type = ItemNamesActionTypes.UpdateItemNameSuccess;
  constructor(public payload: ItemName) {}
}

export class UpdateItemNameFailure implements Action {
  readonly type = ItemNamesActionTypes.UpdateItemNameFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class DeleteItemName implements Action {
  readonly type = ItemNamesActionTypes.DeleteItemName;
  constructor(public payload: number) {}
}

export class DeleteItemNameSuccess implements Action {
  readonly type = ItemNamesActionTypes.DeleteItemNameSuccess;
  constructor(public payload: number) {}
}

export class DeleteItemNameFailure implements Action {
  readonly type = ItemNamesActionTypes.DeleteItemNameFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export type ItemNamesActions =
  | SelectItemNameId
  | ClearItemNameId
  | LoadItemNames
  | LoadItemNamesSuccess
  | LoadItemNamesFailure
  | CreateItemName
  | CreateItemNameSuccess
  | CreateItemNameFailure
  | UpdateItemName
  | UpdateItemNameSuccess
  | UpdateItemNameFailure
  | DeleteItemName
  | DeleteItemNameSuccess
  | DeleteItemNameFailure;
