import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { LabelGroup } from 'src/app/models/label-group';
import { Subscription, Subject, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import { Label } from '../../../../../models/label';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { CloseDrawer, OpenDrawer } from 'src/app/store/layout/layout.actions';
import {
  ClearOpportunityLabelId,
  AddOpportunityLabels,
  UpdateOpportunityLabels,
  DeleteOpportunityLabels,
  LoadOpportunityLabels
} from 'src/app/store/system-settings/opportunity-labels/opportunity-labels/opportunity-labels.actions';
// eslint-disable-next-line
import { selectSelectedOpportunityLabel } from 'src/app/store/system-settings/opportunity-labels/selectors/selected-opportunityLabels.selector';
// eslint-disable-next-line
import { selectOpportunityLabelsIndividualAsArray } from 'src/app/store/system-settings/opportunity-labels/selectors/all-opportunityLabelsIndividual-as-array.selector';
import { DeleteObject } from 'src/app/store/undo-delete/undo-delete.actions';
import {
  DeleteOpportunityLabelsIndividual,
  LoadOpportunityLabelsIndividuals,
  CreateOpportunityLabelsIndividual,
  UpdateOpportunityLabelsIndividual,
  BulkUpdateOpportunityLabels,
  SetOppLabelListOrder
} from 'src/app/store/system-settings/opportunity-labels-individual/opportunity-labels-individual.actions';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDeleteComponent } from 'src/app/view/shared/confirm-delete/confirm-delete.component';
import { take } from 'rxjs/operators';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { deepCopy } from 'src/app/utils/deepCopy';
import { moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'tn-add-opportunity-label',
  templateUrl: './add-opportunity-label.component.html',
  styleUrls: ['./add-opportunity-label.component.scss']
})
export class AddOpportunityLabelComponent implements OnInit, OnDestroy {
  currentOpportunityLabelIndividual: Label;
  $currentOpportunityLabelIndividualSub: Subscription;
  opportunityLabelsIndividual: Label[];
  $opportunityLabelIndividualSub: Subscription;
  $labelsPending: Observable<boolean>;
  opportunityLabelForm: FormGroup = new FormGroup({
    name: new FormControl(null, Validators.required),
    required: new FormControl(null),
    allowMultiple: new FormControl(null),
    stage: new FormControl(null),
    primaryGroup: new FormControl(null),
    description: new FormControl(null),
    id: new FormControl(null),
    listOrder: new FormControl(null)
  });
  opportunityLabelIndividualForm: FormGroup = new FormGroup({
    name: new FormControl(null),
    id: new FormControl(null),
    groupId: new FormControl(null),
    description: new FormControl(null),
    isClosed: new FormControl(false),
    isWon: new FormControl(false),
    isLost: new FormControl(false),
    listOrder: new FormControl(null)
  });
  currentOpportunityLabel: LabelGroup;
  $currentOpportunityLabelSub: Subscription;
  $opportunityLabelSub: Subscription;
  @ViewChild(MatMenuTrigger, { static: true }) addLabelMenuTrigger: MatMenuTrigger;
  $addLabelFormEventsSubject: Subject<void> = new Subject<void>();
  $focusEventSubject: Subject<void> = new Subject<void>();
  $groupsCountSub: Subscription;
  labelGroupsCount: number;

  constructor(private store: Store<RootState>, private dialog: MatDialog) {}

  ngOnInit() {
    this.$groupsCountSub = this.store
      .select('opportunityLabels', 'data')
      .subscribe((lgs) => {
        this.labelGroupsCount = Object.keys(lgs).length;
      });
    this.$labelsPending = this.store.select('opportunityLabelsIndividual', 'pending');
    this.$currentOpportunityLabelSub = this.store
      .select(selectSelectedOpportunityLabel)
      .subscribe((opportunityLabel: LabelGroup) => {
        this.currentOpportunityLabel = opportunityLabel;
        if (!!opportunityLabel && !!opportunityLabel.id) {
          this.opportunityLabelForm.patchValue({
            ...opportunityLabel
          });
          this.opportunityLabelForm.markAsPristine();
          this.store.dispatch(
            new LoadOpportunityLabelsIndividuals(this.currentOpportunityLabel.id)
          );
        }
      });
    this.$currentOpportunityLabelIndividualSub = this.store
      .select(selectOpportunityLabelsIndividualAsArray)
      .subscribe((opportunityLabelIndividual) => {
        this.opportunityLabelsIndividual = opportunityLabelIndividual;
      });
  }

  ngOnDestroy() {
    this.$currentOpportunityLabelIndividualSub.unsubscribe();
    this.$currentOpportunityLabelSub.unsubscribe();
    this.$groupsCountSub.unsubscribe();
  }

  save() {
    this.store.dispatch(
      new AddOpportunityLabels({
        ...this.opportunityLabelForm.value,
        listOrder: this.labelGroupsCount + 1
      })
    );
    this.store.dispatch(new LoadOpportunityLabels());
  }

  cancelHandler() {
    this.$addLabelFormEventsSubject.next();
    this.opportunityLabelIndividualForm.reset();
    this.addLabelMenuTrigger.closeMenu();
  }

  saveIndividual() {
    this.$addLabelFormEventsSubject.next();
    const label = this.opportunityLabelIndividualForm.value;
    delete label.id;
    this.store.dispatch(
      new CreateOpportunityLabelsIndividual({
        label,
        groupId: this.currentOpportunityLabel.id
      })
    );
    this.store.dispatch(new LoadOpportunityLabels());
    this.opportunityLabelIndividualForm.reset();
  }

  editIndividual() {
    this.store.dispatch(
      new UpdateOpportunityLabelsIndividual({
        label: this.opportunityLabelIndividualForm.value,
        groupId: this.currentOpportunityLabel.id
      })
    );
    this.store.dispatch(
      new LoadOpportunityLabelsIndividuals(this.currentOpportunityLabel.id)
    );
  }

  menuOpened() {
    this.opportunityLabelIndividualForm.reset();
    this.opportunityLabelIndividualForm.patchValue({
      groupId: this.currentOpportunityLabel.id,
      listOrder: this.currentOpportunityLabel.labels.length + 1
    });
    this.$focusEventSubject.next();
  }

  menuOpenedEdit(companyLabelIndividual) {
    this.opportunityLabelIndividualForm.reset();
    this.$focusEventSubject.next();
    this.opportunityLabelIndividualForm.patchValue({
      name: companyLabelIndividual.name,
      id: companyLabelIndividual.id,
      groupId: companyLabelIndividual.groupId,
      description: companyLabelIndividual.description,
      isClosed: companyLabelIndividual.isClosed,
      isWon: companyLabelIndividual.isWon,
      isLost: companyLabelIndividual.isLost,
      listOrder: companyLabelIndividual.listOrder
    });
  }

  update() {
    this.store.dispatch(
      new UpdateOpportunityLabels({
        ...this.opportunityLabelForm.value,
        labels: this.opportunityLabelsIndividual
      })
    );
    this.store.dispatch(new CloseDrawer());
    this.store.dispatch(new ClearOpportunityLabelId());
    // this.store.dispatch(new LoadOpportunityLabels());
  }

  cancel(): void {
    this.store.dispatch(new CloseDrawer());
    this.store.dispatch(new ClearOpportunityLabelId());
  }

  delete(): void {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {});

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((willDelete: boolean) => {
        if (willDelete) {
          this.store.dispatch(
            new DeleteOpportunityLabels(this.currentOpportunityLabel.id)
          );
          this.store.dispatch(new CloseDrawer());
          this.store.dispatch(new ClearOpportunityLabelId());
          // this.store.dispatch(new LoadOpportunityLabels());
        }
      });
  }

  deleteIndividual(opportunityLabelIndividual) {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {});

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((willDelete: boolean) => {
        if (willDelete) {
          this.store.dispatch(
            new DeleteOpportunityLabelsIndividual({
              id: opportunityLabelIndividual.id,
              groupId: opportunityLabelIndividual.groupId
            })
          );
          this.store.dispatch(new LoadOpportunityLabels());
        }
      });
  }

  lostChange(label: Label, event: MatCheckboxChange) {
    const newLabels = deepCopy(this.opportunityLabelsIndividual).map(
      (oldLabel: Label) => {
        if (oldLabel.id === label.id) {
          return {
            ...oldLabel,
            isLost: event.checked,
            isWon: oldLabel.isWon ? !event.checked : false,
            isClosed: true
          };
        } else {
          if (oldLabel.isLost === true) {
            return { ...oldLabel, isLost: false };
          }
          return oldLabel;
        }
      }
    );
    this.store.dispatch(new BulkUpdateOpportunityLabels(newLabels));
  }

  wonChange(label: Label, event: MatCheckboxChange) {
    const newLabels = deepCopy(this.opportunityLabelsIndividual).map(
      (oldLabel: Label) => {
        if (oldLabel.id === label.id) {
          return {
            ...oldLabel,
            isWon: event.checked,
            isLost: oldLabel.isLost ? !event.checked : false,
            isClosed: true
          };
        } else {
          if (oldLabel.isWon === true) {
            return { ...oldLabel, isWon: false };
          }
          return oldLabel;
        }
      }
    );
    this.store.dispatch(new BulkUpdateOpportunityLabels(newLabels));
  }

  closedChange(label: Label, event: MatCheckboxChange) {
    this.store.dispatch(
      new UpdateOpportunityLabelsIndividual({
        label: {
          ...label,
          isLost: event.checked ? label.isLost : false,
          isWon: event.checked ? label.isWon : false,
          isClosed: event.checked
        },
        groupId: this.currentOpportunityLabel.id
      })
    );
  }

  drop(event) {
    moveItemInArray(
      this.opportunityLabelsIndividual,
      event.previousIndex,
      event.currentIndex
    );
    this.opportunityLabelsIndividual.map((lg, i) => {
      lg.listOrder = i + 1;
    });
    this.store.dispatch(
      new SetOppLabelListOrder({
        groupId: this.currentOpportunityLabel.id,
        labels: this.opportunityLabelsIndividual
      })
    );
  }
}
