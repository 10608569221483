import { TutorialsActions, TutorialsActionTypes } from './tutorials.actions';

export interface TutorialsState {
  pending: boolean;
}

export const initialState: TutorialsState = {
  pending: false
};

export function tutorialsReducer(
  state = initialState,
  action: TutorialsActions
): TutorialsState {
  switch (action.type) {
    case TutorialsActionTypes.LoadTutorials:
      return {
        ...state,
        pending: true
      };

    case TutorialsActionTypes.LoadTutorialsSuccess:
      return {
        ...state,
        pending: false
      };

    case TutorialsActionTypes.LoadTutorialsFailure:
      return {
        ...state,
        pending: false
      };

    default:
      return state;
  }
}
