import { CallNoteV2 } from './../shared/models/call-notes/call-note-V2';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { Feature } from '../shared/models/admin/feature';
import { ChildCompanyRequest } from '../shared/models/companies/child-company-request';
import { Company } from '../shared/models/companies/company';
import { ToolbarTab } from '../shared/models/toolbarTab';
import { AuthenticationService } from '../shared/services/authentication.service';
import { CompanyService } from '../shared/services/companies/company.service';
import { ToolbarService } from '../shared/services/toolbar.service';
import { File } from '../shared/models/files/file';
import { ExpandService } from '../shared/services/expand.service';
import { Router } from '@angular/router';

@Component({
  selector: 'tn-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss', '../shared/styles/buttons.shared.scss']
})
export class ToolbarComponent implements OnInit, OnDestroy {
  window: any = window;
  childCompanyPopup: boolean;
  newCompanyPopup: boolean;
  callNotePopup: boolean;
  contactPopup: boolean;
  fileUploadPopup: boolean;
  file: File;
  userName: string;
  tabs: ToolbarTab[];
  buttons: string[];
  showSearchBar: boolean = false;
  searchBarPlaceHolder: string = '';
  authServiceSubscription: Subscription;
  tabSubscription: Subscription;
  buttonSubscription: Subscription;
  searchBarSubscription: Subscription;
  featureSubscription: Subscription;
  searchTerm: string;
  inputDelay: number = 500;
  typingTimer: any;
  newButtonMenu: boolean;
  newCompanyProfileMenu: boolean;
  customCallNoteMenu: boolean;
  expanded: boolean = true;
  company: Company;
  childCompanyRequest: ChildCompanyRequest;
  allCompanies: Company[];
  expandSubscription: Subscription;
  navExpanded: boolean;
  companyId: number;
  callNotePermissions: Feature;
  callNotesFeature: number;
  companyPermissions: Feature;
  activityPermissions: Feature;
  editContactsFeature: number;
  callNotePopupSubscription: Subscription;
  callNotePopup$: boolean;
  followUpPopupSubscription: Subscription;
  followUpPopup$: boolean;
  companyPopupSubscription: Subscription;
  contactPopup$: boolean;
  contactPopupSubscription: Subscription;
  companyPopup$: boolean;
  callNote: CallNoteV2;
  showToolbarSubscription: Subscription;
  showToolbar: boolean;

  menuItems: any = [];

  constructor(
    private authService: AuthenticationService,
    private toolbarService: ToolbarService,
    private companyService: CompanyService,
    private router: Router,
    private expandService: ExpandService
  ) {
    this.showToolbarSubscription = this.toolbarService.showToolbarAnnounced.subscribe(
      showToolbar => {
        this.showToolbar = showToolbar;
      }
    );
    this.authServiceSubscription = this.authService.userAnnounced.subscribe(name => {
      this.userName = name;
      this.menuItems = [
        {
          id: '0',
          name: this.userName,
          items: [
            {
              id: '1',
              name: 'Settings',
              route: '/admin/settings'
            },
            {
              id: '3',
              name: 'Sign Out',
              route: '/signout'
            }
          ]
        },
        {
          id: '4',
          name: 'Help',
          items: [
            {
              id: '7',
              name: 'Tutorials',
              route: '/tutorials'
            },
            {
              id: '5',
              name: 'Feedback',
              email: 'feedback@telenotes.com'
            },
            {
              id: '6',
              name: 'Report Issues',
              email: 'support@telenotes.com'
            }
          ]
        }
      ];
    });
    this.buttonSubscription = this.toolbarService.buttonAnnouced.subscribe(buttons => {
      this.buttons = buttons;
    });
    this.tabSubscription = toolbarService.tabsChanged.subscribe(newTabs => {
      this.tabs = newTabs;
    });
    this.searchBarSubscription = this.toolbarService.searchBarAnnounced.subscribe(
      showSearchBar => {
        this.showSearchBar = showSearchBar;

        if (showSearchBar) {
          this.searchBarPlaceHolder = this.toolbarService.getSearchBarPlaceHolder();
        } else {
          this.searchBarPlaceHolder = '';
          this.searchTerm = '';
        }
      }
    );

    this.expandSubscription = this.expandService.expandedAnnounced.subscribe(
      expand => (this.navExpanded = expand)
    );
    this.featureSubscription = this.authService.moduleAnnounced.subscribe(features =>
      this.processFeatureSubscription(features)
    );
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event) {
    if (event.path[0].scrollingElement.scrollTop >= 0) {
      this.newCompanyProfileMenu = false;
      this.newButtonMenu = false;
      this.customCallNoteMenu = false;
    }
  }

  ngOnInit(): void {
    this.authService
      .getVisibleFeatures()
      .then(features => this.processFeatureSubscription(features));

    this.userName = this.authService.getUserName();
    this.callNotePopupSubscription = this.toolbarService.callNoteAnnounced.subscribe(
      result => {
        this.callNote = Object.assign({
          company: {},
          type: {},
          followUp: { assignedTo: {} }
        });
        this.callNotePopup$ = result;
      }
    );
    this.followUpPopupSubscription = this.toolbarService.followUpAnnounced.subscribe(
      result => {
        this.callNote = Object.assign({
          company: {},
          type: {},
          followUp: { assignedTo: {} }
        });
        this.followUpPopup$ = result;
      }
    );
    this.contactPopupSubscription = this.toolbarService.contactAnnounced.subscribe(
      result => {
        this.contactPopup$ = result;
      }
    );
    this.companyPopupSubscription = this.toolbarService.companyAnnounced.subscribe(
      result => {
        this.companyPopup$ = result;
      }
    );
  }

  ngOnDestroy(): void {
    this.authServiceSubscription.unsubscribe();
    this.tabSubscription.unsubscribe();
    this.searchBarSubscription.unsubscribe();
    this.buttonSubscription.unsubscribe();
    this.expandSubscription.unsubscribe();
    this.featureSubscription.unsubscribe();
    this.callNotePopupSubscription.unsubscribe();
    this.followUpPopupSubscription.unsubscribe();
    this.contactPopupSubscription.unsubscribe();
    this.companyPopupSubscription.unsubscribe();
  }

  menuClick(menuItem): void {
    if (menuItem.route) {
      this.router.navigate([menuItem.route]);
    } else if (menuItem.email) {
      location.href = `mailto:${menuItem.email}`;
    }
  }

  processFeatureSubscription(features: any) {
    for (let i = 0; i < features.length; i++) {
      if (features[i].Name == this.authService.modules.Admin) {
        this.menuItems[0] = {
          id: '0',
          name: this.userName,
          items: [
            {
              id: '1',
              name: 'Settings',
              route: '/admin/usersettings'
            },
            {
              // 	id: "8",
              // 	name: "User Settings",
              // route: '/admin/usersettings'
              // }, {
              id: '2',
              name: 'Admin',
              route: '/admin'
            },
            {
              id: '3',
              name: 'Sign Out',
              route: '/signout'
            }
          ]
        };
      }
      if (features[i].Name == this.authService.modules.Companies) {
        for (const key in features[i].Permissions) {
          if (features[i].Permissions[key].Id == 49) {
            this.editContactsFeature = JSON.parse(features[i].Permissions[key].Value);
          }
        }
      }
      if (features[i].Name == this.authService.modules.Activity) {
        for (const key in features[i].Permissions) {
          if (features[i].Permissions[key].Id == 56) {
            this.callNotesFeature = JSON.parse(features[i].Permissions[key].Value);
          }
        }
      }
    }
  }

  searchTermChanged(event: any): void {
    const self = this;
    const key = event.key;

    if (key != 'ArrowDown' && key != 'ArrowUp' && key != 'Enter') {
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(function() {
        self.toolbarService.announceNewSearchTerm(self.searchTerm);
      }, this.inputDelay);
    }
  }

  buttonClicked(button: string): void {
    if (button.toLowerCase() == 'new' && this.toolbarService.companyId) {
      this.expanded = !this.expanded;
      this.newCompanyProfileMenu = !this.newCompanyProfileMenu;
    } else if (button.toLowerCase() == 'new' && this.toolbarService.customCallNoteMenu) {
      this.customCallNoteMenu = true;
    } else if (button.toLowerCase() == 'new') {
      this.expanded = !this.expanded;
      this.newButtonMenu = true;
    }
    this.toolbarService.buttonClicked(button);
  }

  getAllCompanies() {
    const self = this;
    this.companyService.getCompanies().then(function(result) {
      self.allCompanies = result;
    });
  }

  newCompany() {
    this.newCompanyPopup = true;
    this.toolbarService.companyVisible(this.newCompanyPopup);
    this.closeMenusAfterClick();
  }

  newCallNote() {
    this.callNotePopup = true;
    this.companyId = this.toolbarService.companyId;
    this.toolbarService.callNoteVisible(this.callNotePopup);
    this.closeMenusAfterClick();
  }

  newFollowUp() {
    this.callNotePopup = true;
    this.toolbarService.followUpVisible(this.callNotePopup);
    this.closeMenusAfterClick();
  }

  newContact() {
    this.contactPopup = true;
    this.closeMenusAfterClick();
    this.toolbarService.contactVisible(this.contactPopup);
  }

  newFile() {
    this.file = Object.assign({});
    this.fileUploadPopup = true;
    this.toolbarService.fileUploadVisible(this.fileUploadPopup);
    this.closeMenusAfterClick();
  }

  newCustomCompany() {
    this.toolbarService.customCompanyVisible(true);
    this.closeMenusAfterClick();
  }

  newCustomCallNote() {
    this.companyId = this.toolbarService.companyId;
    this.toolbarService.customCallNoteVisible(true);
    this.closeMenusAfterClick();
  }

  newCustomFollowUp() {
    this.toolbarService.customFollowUpVisible(true);
    this.closeMenusAfterClick();
  }

  newCustomContact() {
    this.toolbarService.customContactVisible(true);
    this.closeMenusAfterClick();
  }

  newCustomFile() {
    this.file = Object.assign({});
    this.fileUploadPopup = true;
    this.toolbarService.customFileUploadVisible(this.fileUploadPopup);
    this.closeMenusAfterClick();
  }

  newChildCompany() {
    const self = this;
    this.companyId = this.toolbarService.companyId;
    this.getAllCompanies();
    this.companyService.getCompanyById(this.companyId).then(function(result1) {
      self.company = result1;
      self.childCompanyRequest = Object.assign({ ChildCompanyIds: [] });
      for (const comp of self.company.ChildCompanies) {
        self.childCompanyRequest.ChildCompanyIds.push(comp.CompanyId);
      }
      self.childCompanyPopup = true;
      self.closeMenusAfterClick();
    });
  }

  closeMenusAfterClick() {
    this.newButtonMenu = false;
    this.customCallNoteMenu = false;
    this.newCompanyProfileMenu = false;
  }
}
