import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  Observable,
  Subscription,
  Subject,
  BehaviorSubject,
  combineLatest,
  EMPTY
} from 'rxjs';
import { CallNote } from 'src/app/models/call-notes/call-note';
import { IdNameItem } from 'src/app/models/id-name-item';
import { HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import { selectMultiSelectUsers } from 'src/app/store/users/selectors/multi-select-users.selector';
import { map } from 'rxjs/operators';
import { selectSelectedCallNote } from 'src/app/store/call-notes/selectors/current-call-note.selector';
import {
  ClearSearchCompanies,
  LoadCompanyContacts,
  SearchCompanies,
  SelectSearchedCompany
} from 'src/app/store/companies/companies.actions';
import { selectIdNameCurrentUser } from 'src/app/store/users/selectors/id-name-current-user.selector';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { GlobalFilter } from 'src/app/models/global-filter';
import { CompanySelectItem } from 'src/app/models/companies/company-select';
import { selectCompanyContacts } from 'src/app/store/companies/selectors/company-contacts.selector';
import { LoadCallNoteTypes } from 'src/app/store/call-note-types/call-note-types.actions';
import { selectCallNoteTypesAsIdNames } from 'src/app/store/call-note-types/selectors/call-note-types-id-name-items.selector';
import {
  LoadCallNote,
  LoadCallNoteComments,
  SaveCallNote,
  SelectCallNoteId
} from 'src/app/store/call-notes/call-notes.actions';
import { Project } from 'src/app/models/projects/project';
import { selectCurrentProject } from 'src/app/store/projects/selectors/current-project.selector';
import {
  LoadCallNoteCustomFields,
  LoadCallNoteDropdownOptions
} from 'src/app/store/system-settings/call-notes/call-note-custom-fields.actions';
import { CallNoteField } from 'src/app/models/call-notes/call-note-field';
import {
  addDays,
  setHours,
  setMinutes,
  setSeconds,
  setMilliseconds,
  format,
  addMinutes
} from 'date-fns';
import { CloseDrawer, OpenDrawer } from 'src/app/store/layout/layout.actions';
import { dbDateFormat } from 'src/app/utils/dbDateFormat';
import { Company } from 'src/app/models/companies/company';
import { Field } from 'src/app/models/call-notes/field';
import { FollowUp } from 'src/app/models/call-notes/follow-up';
import { Opportunity } from 'src/app/models/Opportunity/opportunity';
import {
  selectCurrentOpportunity,
  selectOpportunityByCurrentCallNote
} from 'src/app/store/opportunities/selectors/current-opportunity.selector';
import { CompanyIdNameItem } from 'src/app/models/companies/company-id-name-item';
import { selectCurrentCompany } from 'src/app/store/companies/selectors/current-company.selector';
import { Contact } from 'src/app/models/contacts/contact';
import { MatDialog } from '@angular/material/dialog';
import { CompanyCallNoteDialog } from '../../pages/projects/project-call-note/company-call-note-dialogue';
import { DropdownOption } from 'src/app/models/dropdown-option';
import { GenericMap } from 'src/app/models/generic-map';
import { GenericApi } from 'src/app/models/generic-api';
import { LoadFieldTypes } from 'src/app/store/system-settings/field-types/field-types.actions';
import { convertToMap } from 'src/app/utils/convertToMap';
import { ProjectCallNoteCommentDialogComponent } from '../../pages/projects/project-call-note/project-call-note-comment-dialog/project-call-note-comment-dialog.component';
import { MatExpansionPanel } from '@angular/material/expansion';
import { OpportunitiesService } from 'src/app/services/opportunities/opportunities.service';
import { ProjectsService } from 'src/app/services/projects/projects.service';
import {
  selectCallNoteComments,
  selectCallNoteCommentsError,
  selectCallNoteCommentsPending
} from 'src/app/store/call-notes/selectors/call-note-comments.selector';
import { Comment } from 'src/app/models/call-notes/comment';
import moment from 'moment';
import { CallNoteFormAudioComponent } from '../call-note-form-audio/call-note-form-audio.component';
import {
  LoadOpportunitiesElastic,
  LoadOpportunity
} from 'src/app/store/opportunities/opportunities.actions';
import { selectActiveOpportunityItems } from 'src/app/store/opportunities/selectors/all-opportunities-as-array.selector';

@Component({
  selector: 'tn-call-note-form',
  templateUrl: './call-note-form.component.html',
  styleUrls: ['./call-note-form.component.scss']
})
export class CallNoteFormComponent implements OnInit, OnDestroy {
  @Input() data: {
    followUpOpen: boolean;
    company: CompanySelectItem;
    selectedContact?: IdNameItem;
    date?: Date;
    task?: boolean;
    callnoteId?: number;
  };
  callNoteForm: FormGroup = new FormGroup({
    callDate: new FormControl('', Validators.required),
    company: new FormControl(null, Validators.required),
    type: new FormControl(null, Validators.required),
    contacts: new FormControl(null),
    shareWithEmails: new FormControl(null),
    opportunity: new FormControl(null)
  });

  fieldsForm: FormGroup = new FormGroup({
    value: new FormControl(null)
  });

  followUpForm: FormGroup = new FormGroup({
    task: new FormControl(null),
    dueDateStart: new FormControl(null),
    dueDateEnd: new FormControl(null),
    complete: new FormControl(null),
    assignedTo: new FormControl(null)
  });

  commentsForm: FormGroup = new FormGroup({
    callNoteID: new FormControl(),
    value: new FormControl()
  });

  $comments: Observable<Comment[]>;
  $commentsPending: Observable<boolean>;
  $commentsError: Observable<HttpErrorResponse>;
  commentsFetched = false;
  contactsFetched = false;
  companyFetched = false;

  $currentSelectedCallNote: Observable<number> = EMPTY;
  $currentCallNote: Observable<CallNote> = EMPTY;
  $currentCallNoteSub: Subscription;
  currentCallNote: CallNote;
  $project: Observable<Project> = EMPTY;
  project: Project;
  $opportunity: Observable<Opportunity> = EMPTY;
  opportunity: Opportunity;
  $company: Observable<Company> = EMPTY;
  company: Company;
  $types: Observable<IdNameItem[]> = EMPTY;
  $allUsers: Observable<IdNameItem[]> = EMPTY;
  $currentUser: Observable<IdNameItem> = EMPTY;
  $contactsEventsSubject: Subject<void> = new Subject<void>();
  $companies: Observable<CompanySelectItem[]> = EMPTY;
  $companiesSearchPending: Observable<boolean> = EMPTY;
  $companiesSearchError: Observable<HttpErrorResponse> = EMPTY;
  $companyContacts: Observable<IdNameItem[]> = EMPTY;
  $companyContactsPending: Observable<boolean> = EMPTY;
  $companyContactsError: Observable<HttpErrorResponse> = EMPTY;
  selectedCompany: CompanyIdNameItem;
  callNoteFields: Field[] = [];
  selectedNoteFields: Field[] = [];
  unselectedNoteFields: Field[] = [];
  $selectableCallNoteFields: BehaviorSubject<IdNameItem[]> = new BehaviorSubject([]);
  $addFieldEventsSubject: Subject<void> = new Subject<void>();
  customCallNoteFieldLimit = 18;
  $callNotePending: Observable<boolean> = EMPTY;
  $focusEventSubject: Subject<void> = new Subject<void>();
  offsetDate = new Date();
  dropdownOptions: GenericMap<{ fetched: boolean; options: DropdownOption[] }> = {};
  $projName: Observable<string> = EMPTY;
  $oppName: Observable<string> = EMPTY;
  oppNameFetched = false;
  projNameFetched = false;
  webRecording: boolean = false;
  opportunitySelect: boolean = false;
  $opportunitiesSub: Subscription;
  $opportunities: Observable<Opportunity[]>;
  opportunities: IdNameItem[];
  selectedOpportunity: Opportunity;

  @ViewChild('commentsPanel', { static: true }) commentsPanel: MatExpansionPanel;

  constructor(
    private store: Store<RootState>,
    public dialog: MatDialog,
    public oppService: OpportunitiesService,
    public projService: ProjectsService
  ) {}

  ngOnInit() {
    if (this.data?.callnoteId) {
      this.store.dispatch(new LoadCallNote(this.data.callnoteId as number));
    }
    this.$callNotePending = this.store.select('callNotes', 'pendingIndividual');
    this.data = this.data || { followUpOpen: true, company: null };
    if (!!this.data.company) {
      this.callNoteForm.controls.company.setValue(this.data.company);
      this.store.dispatch(new SelectSearchedCompany(this.data.company.id));
      this.store.dispatch(new LoadCompanyContacts(this.data.company.id));
    } else {
      this.store.dispatch(new ClearSearchCompanies());
    }

    this.store.dispatch(new LoadCallNoteTypes());
    this.store.dispatch(new LoadFieldTypes());
    this.store.dispatch(new LoadCallNoteCustomFields());
    this.$types = this.store.select(selectCallNoteTypesAsIdNames);
    this.$currentUser = this.store.select(selectIdNameCurrentUser);
    this.$allUsers = this.store.select(selectMultiSelectUsers).pipe(
      map((users: IdNameItem[]) => {
        if (!!users?.length) {
          return users;
        } else {
          return [];
        }
      })
    );
    this.$project = this.store.select(selectCurrentProject);
    this.$opportunity = this.store.select(selectCurrentOpportunity);
    this.$company = this.store.select(selectCurrentCompany);
    this.$companies = this.store.select('companies', 'search', 'data');
    this.$companiesSearchPending = this.store.select('companies', 'search', 'pending');
    this.$companiesSearchError = this.store.select('companies', 'search', 'error');
    this.$companyContacts = this.store.select(selectCompanyContacts);
    this.$currentCallNote = this.store.select(selectSelectedCallNote);

    this.$comments = this.store.select(selectCallNoteComments);
    this.$commentsPending = this.store.select(selectCallNoteCommentsPending);
    this.$commentsError = this.store.select(selectCallNoteCommentsError);

    this.store
      .select('auth', 'data', 'api', 'Role', 'Features')
      .subscribe((features: any) => {
        if (features[4]?.Permissions[163]?.Value === 'true') {
          this.webRecording = true;
        }
        if (features[20]?.Permissions[138]?.Value === '2') {
          this.opportunitySelect = true;
        }
      });

    this.store.dispatch(
      new LoadOpportunitiesElastic({
        filters: [],
        params: { limit: '10000', skip: '0', order: 'createdDate' }
      })
    );
    this.$opportunities = this.store.select(selectActiveOpportunityItems);

    this.$opportunitiesSub = this.$opportunities.subscribe(
      (opportunities: Opportunity[]) => {
        if (opportunities?.length > 0) {
          this.opportunities = opportunities.map((opp: Opportunity) => {
            return { id: opp.id, name: opp.name };
          });
        }
      }
    );

    this.$currentCallNoteSub = combineLatest([
      this.$currentCallNote,
      this.store.select('callNoteCustomFields', 'callNoteFields'),
      this.$currentUser,
      this.$company,
      this.$opportunity,
      this.$project,
      this.store.select('callNoteCustomFields', 'dropdownOptions'),
      this.store.select(selectOpportunityByCurrentCallNote)
    ])
      .pipe(
        map(
          ([
            callNote,
            customFields,
            user,
            company,
            opportunity,
            project,
            dropdownOptions,
            selectedOpportunity
          ]: [
            CallNote,
            Field[],
            IdNameItem,
            Company,
            Opportunity,
            Project,
            GenericMap<GenericApi<GenericMap<DropdownOption>>>,
            Opportunity
          ]) => ({
            callNote,
            customFields: customFields.sort(
              (a: Field, b: Field) => a.callnoteOrder - b.callnoteOrder
            ),
            user,
            company,
            opportunity,
            project,
            dropdownOptions,
            selectedOpportunity
          })
        )
      )
      .subscribe(
        ({
          customFields,
          user,
          company,
          callNote,
          opportunity,
          project,
          dropdownOptions,
          selectedOpportunity
        }) => {
          this.selectedOpportunity = selectedOpportunity;
          this.opportunity = opportunity;
          this.project = project;
          const newDueDate = !!this.data.date
            ? setMilliseconds(
                setSeconds(setMinutes(setHours(this.data.date, 8), 0), 0),
                0
              )
            : setMilliseconds(
                setSeconds(setMinutes(setHours(addDays(new Date(), 1), 8), 0), 0),
                0
              );
          const initialCallNote: CallNote = {
            id: null,
            callDate: this.data.date ? this.data.date : new Date(),
            company:
              this.callNoteForm.controls.company.value ||
              (this.data.company as unknown as Company),
            type: this.callNoteForm.controls.type.value || null,
            contacts: [],
            projectId: null,
            opportunityId: opportunity ? this.opportunity.id : null,
            fields: [],
            shareWithEmails: [],
            hasFollowUpTask: false,
            followUp: {
              task: null,
              dueDateStart: newDueDate,
              dueDateEnd: setMinutes(newDueDate, 30),
              assignedTo: user,
              complete: null
            }
          };
          if (customFields.length) {
            this.callNoteFields = customFields;
            customFields.forEach((field: Field) => {
              if (field.type.id === 16 && !this.dropdownOptions[field.id]) {
                this.dropdownOptions[field.id] = {
                  fetched: false,
                  options: []
                };
                this.dropdownOptions[field.id].fetched = true;
                this.store.dispatch(new LoadCallNoteDropdownOptions(field.id));
              }
              if (
                Object.values(dropdownOptions).length &&
                !!dropdownOptions[field.id] &&
                !!dropdownOptions[field.id].data
              ) {
                this.dropdownOptions = {
                  ...this.dropdownOptions,
                  [field.id]: {
                    fetched: true,
                    options: Object.values(dropdownOptions[field.id].data).sort(
                      (a: DropdownOption, b: DropdownOption) => {
                        return a.dropdownOrder < b.dropdownOrder
                          ? -1
                          : a.dropdownOrder > b.dropdownOrder
                          ? 1
                          : 0;
                      }
                    )
                  }
                };
              }
              this.fieldsForm.addControl(field.id.toString(), new FormControl());
            });
            this.$selectableCallNoteFields.next(
              customFields.map((field) => ({
                id: field.id,
                name: field.name
              }))
            );
          }
          if (!!callNote && callNote.company) {
            if (!this.commentsFetched) {
              this.store.dispatch(new LoadCallNoteComments(callNote.id));
              this.commentsFetched = true;
            }
            if (callNote.opportunityId && !this.oppNameFetched) {
              this.$oppName = this.fetchCallNoteOpportunityName(callNote.opportunityId);
            }
            if (callNote.projectId && !this.projNameFetched) {
              this.$projName = this.fetchCallNoteProjectName(callNote.projectId);
            }
            this.currentCallNote = callNote;
            this.currentCallNote.hasFollowUpTask = callNote.hasFollowUpTask;
            if (!this.companyFetched) {
              this.store.dispatch(new SelectSearchedCompany(callNote.company.id));
              this.companyFetched = true;
            }
            if (!this.contactsFetched) {
              this.store.dispatch(new LoadCompanyContacts(callNote.company.id));
              this.contactsFetched = true;
            }
            this.commentsForm.controls.callNoteID.patchValue(callNote.id);
            if (customFields.length) {
              callNote.fields.forEach((callNoteField: CallNoteField) => {
                if (callNoteField.field.type.id === 16) {
                  if (
                    Object.values(dropdownOptions).length &&
                    !!dropdownOptions[callNoteField.field.id] &&
                    !!dropdownOptions[callNoteField.field.id].data
                  ) {
                    this.fieldsForm.controls[callNoteField.field.id].setValue(
                      // change this to dropdownId instead of value
                      dropdownOptions[callNoteField.field.id].data[
                        callNoteField.dropdownId
                      ]
                    );
                  }
                } else {
                  this.fieldsForm.controls[callNoteField.field.id].setValue(
                    callNoteField.value
                  );
                }
              });
              this.selectedNoteFields = this.callNoteFields.filter((field: Field) =>
                callNote.fields.map((cnField) => cnField.field.id).includes(field.id)
              );
              this.unselectedNoteFields = this.callNoteFields.filter(
                (field: Field) =>
                  !callNote.fields.map((cnField) => cnField.field.id).includes(field.id)
              );
              this.$selectableCallNoteFields.next(
                this.unselectedNoteFields.map((cnField) => ({
                  id: cnField.id,
                  name: cnField.name
                }))
              );
            }
          } else {
            this.currentCallNote = initialCallNote;
          }
          if (!!company && !!company.id) {
            this.currentCallNote.company = company;
            this.callNoteForm.patchValue({
              company: this.company
            });
            this.callNoteForm.controls.company.setValidators(null);
            this.callNoteForm.controls.company.updateValueAndValidity();
          }
          if (!!opportunity && !!opportunity.companyItem.id) {
            this.currentCallNote.company = opportunity.companyItem as Company;
            this.callNoteForm.patchValue({
              company: opportunity.companyItem
            });
            this.callNoteForm.patchValue({
              opportunity: opportunity
            });
            this.callNoteForm.controls.company.setValidators(null);
            this.callNoteForm.controls.company.updateValueAndValidity();
          }
          if (!!this.data.selectedContact) {
            this.currentCallNote.contacts = [this.data.selectedContact as Contact];
          }
          this.callNoteForm.patchValue({
            callDate: this.currentCallNote.callDate,
            company: this.currentCallNote.company,
            type: this.currentCallNote.type,
            contacts: this.currentCallNote.contacts,
            opportunity: this.opportunity
          });
          this.followUpForm.patchValue({
            task: this.currentCallNote.followUp.task,
            dueDateStart: this.currentCallNote.followUp.dueDateStart
              ? new Date(this.currentCallNote.followUp.dueDateStart)
              : null,
            dueDateEnd: this.currentCallNote.followUp.dueDateEnd
              ? new Date(this.currentCallNote.followUp.dueDateEnd)
              : null,
            assignedTo: this.currentCallNote.followUp.assignedTo,
            complete: this.currentCallNote.followUp.complete
          });
        }
      );

    this.callNoteForm.markAllAsTouched();
  }

  ngOnDestroy() {
    this.$currentCallNoteSub.unsubscribe();
    this.$opportunitiesSub.unsubscribe();
  }

  onEmailsUpdate(emails: string[]) {
    this.callNoteForm.controls.shareWithEmails.setValue(emails);
    this.callNoteForm.markAsDirty();
  }

  onShareMenuClick($event: MouseEvent) {
    $event.stopPropagation();
    this.$focusEventSubject.next();
  }

  userValidation(isValid: boolean) {
    if (!isValid) {
      this.fieldsForm.setErrors({ userError: 'Assigned rep must be selected' });
    }
  }

  typeValidation(isValid: boolean) {
    if (!isValid) {
      this.callNoteForm.setErrors({ typeError: 'Call type must be selected' });
    }
  }

  onTypeSelected(itemSelected: IdNameItem) {
    this.callNoteForm.markAsDirty();
    this.callNoteForm.controls.type.setValue(itemSelected);
  }

  onAssignedSelected(itemSelected: IdNameItem) {
    this.followUpForm.markAsDirty();
    this.followUpForm.controls.assignedTo.setValue(itemSelected);
  }

  setContacts(items: IdNameItem[]) {
    this.callNoteForm.markAsDirty();
    this.callNoteForm.controls.contacts.setValue(items);
  }

  onCompanySearch(searchVal: string) {
    this.callNoteForm.controls.company.setValue(undefined);
    if (!!searchVal.length) {
      const reqFilter: GlobalFilter = {
        filters: [
          {
            operand1: 'searchField',
            operator: 'CONTAINS_ALL_OF',
            operand2: [searchVal]
          }
        ]
      };
      this.store.dispatch(new SearchCompanies(reqFilter));
    } else {
      this.store.dispatch(new ClearSearchCompanies());
    }
  }

  onCompanySelect(event: MatAutocompleteSelectedEvent) {
    this.callNoteForm.markAsDirty();
    this.store.dispatch(new LoadCompanyContacts(event.option.value.id));
    this.store.dispatch(new SelectSearchedCompany(event.option.value.id));
    this.callNoteForm.controls.contacts.setValue([]);
    this.currentCallNote.contacts = [];
    this.callNoteForm.controls.company.setValue(event.option.value);
  }

  submitHandler() {
    const fieldsMap = convertToMap(this.callNoteFields, 'id');
    const startDate = this.followUpForm.value.dueDateStart
      ? format(this.followUpForm.value.dueDateStart, dbDateFormat)
      : null;
    const endDate = this.followUpForm.value.dueDateEnd
      ? format(this.followUpForm.value.dueDateEnd, dbDateFormat)
      : null;
    const callNote: CallNote = {
      id: this.currentCallNote.id,
      createdDate: this.currentCallNote.createdDate || format(new Date(), dbDateFormat),
      callDate: this.callNoteForm.value.callDate,
      company: this.callNoteForm.value.company,
      type: this.callNoteForm.value.type,
      projectId: this.currentCallNote.projectId,
      hasFollowUpTask: this.currentCallNote.hasFollowUpTask,
      opportunityId: this.callNoteForm.value.opportunity,
      contacts: this.callNoteForm.value.contacts,
      fields: Object.keys(this.fieldsForm.value)
        .filter((fieldId) => !!this.fieldsForm.value[fieldId])
        .map((fieldId: string) => {
          // If it's a dropdown
          if (fieldsMap[fieldId].type.id === 16) {
            return {
              dropdownId: this.fieldsForm.value[fieldId].id,
              field: {
                id: parseInt(fieldId, 10)
              },
              value: this.fieldsForm.value[fieldId].dropdownOption
            } as unknown as CallNoteField;
          } else {
            return {
              field: {
                id: fieldId
              },
              value:
                typeof this.fieldsForm.value[fieldId] === 'object'
                  ? this.fieldsForm.value[fieldId].id
                  : this.fieldsForm.value[fieldId]
            } as unknown as CallNoteField;
          }
        })
        .filter((val) => !!val),
      followUp: null,
      shareWithEmails: this.callNoteForm.controls.shareWithEmails.value
    };
    if (!!this.project && !!this.project.id) {
      callNote.projectId = this.project.id;
    }
    if (!!this.opportunity && !!this.opportunity.id) {
      callNote.opportunityId = this.opportunity.id;
    }
    if (!!this.followUpForm.value.task) {
      callNote.hasFollowUpTask = true;
      callNote.followUp = {
        task: this.followUpForm.value.task,
        complete: this.followUpForm.value.complete,
        completedDate: this.followUpForm.value.complete ? new Date() : null,
        dueDateStart: startDate,
        dueDateStartTS: startDate ? new Date(startDate).getTime() : null,
        dueDateEnd: endDate,
        dueDateEndTS: endDate ? new Date(endDate).getTime() : null
      };
      callNote.assignedTo = this.followUpForm.value.assignedTo.mailbox;
    } else {
      callNote.assignedTo = this.followUpForm.value.assignedTo.mailbox;
    }
    this.store.dispatch(new SaveCallNote(callNote));
  }

  cancelButtonClicked() {
    this.store.dispatch(new SelectCallNoteId(undefined));
    this.store.dispatch(new CloseDrawer());
  }

  deleteButtonClicked() {
    const dialogRef = this.dialog.open(CompanyCallNoteDialog, {
      width: '250px',
      data: this.currentCallNote
    });
  }

  getTime(time: string): { hour: number; minutes: number } {
    const isAm = !!time.match(/AM/);
    const hour = time.match(/[0-9]{1,}[:]/)[0];
    const cleanedHour = parseInt(hour.slice(0, hour.length - 1), 10);
    const hour12 = isAm
      ? cleanedHour === 12
        ? 0
        : cleanedHour
      : cleanedHour === 12
      ? cleanedHour
      : cleanedHour + 12;
    return {
      hour: hour12,
      minutes: parseInt(time.match(/[0-9]{1,}\ /)[0].trim(), 10)
    };
  }

  onFieldItemSelected(itemSelected: IdNameItem) {
    let selectedField: Field;
    this.callNoteFields.forEach((field) => {
      if (field.id === itemSelected.id) {
        selectedField = field;
      }
    });
    this.$addFieldEventsSubject.next();
    this.callNoteForm.markAsDirty();
    this.selectedNoteFields.push(selectedField);
    this.selectedNoteFields = this.selectedNoteFields.sort(
      (a: Field, b: Field) => a.callnoteOrder - b.callnoteOrder
    );
    this.unselectedNoteFields = this.callNoteFields
      .sort((a: Field, b: Field) => a.callnoteOrder - b.callnoteOrder)
      .filter(
        (field: Field) =>
          !this.selectedNoteFields.map((cnField) => cnField.id).includes(field.id)
      );
    this.$selectableCallNoteFields.next(
      this.unselectedNoteFields.map((cnField) => ({
        id: cnField.id,
        name: cnField.name
      }))
    );
  }

  onOpportunitySelected(opportunity: Opportunity) {
    this.callNoteForm.controls.opportunity.setValue(opportunity.id);
    this.callNoteForm.markAsDirty();
  }

  deleteFieldHandler(fieldId: number) {
    this.fieldsForm.controls[fieldId].setValue(null);
    this.fieldsForm.markAsDirty();
    this.selectedNoteFields = this.selectedNoteFields
      .filter((field) => field.id !== fieldId)
      .sort((a: Field, b: Field) => a.callnoteOrder - b.callnoteOrder);
    this.unselectedNoteFields = this.callNoteFields
      .sort((a: Field, b: Field) => a.callnoteOrder - b.callnoteOrder)
      .filter(
        (field: Field) =>
          !this.selectedNoteFields.map((cnField) => cnField.id).includes(field.id)
      );
    this.$selectableCallNoteFields.next(
      this.unselectedNoteFields.map((cnField) => ({
        id: cnField.id,
        name: cnField.name
      }))
    );
  }

  onStartDateChanged() {
    const start = new Date(this.followUpForm.value.dueDateStart).getTime();
    const end = new Date(this.followUpForm.value.dueDateEnd).getTime();
    if (start > end) {
      const newEndTime = addMinutes(this.followUpForm.value.dueDateStart, 30);
      this.followUpForm.patchValue({
        dueDateEnd: newEndTime
      });
    }
  }

  onEndDateChanged() {
    const start = new Date(this.followUpForm.value.dueDateStart).getTime();
    const end = new Date(this.followUpForm.value.dueDateEnd).getTime();
    if (start > end) {
      this.followUpForm.patchValue({
        dueDateStart: this.followUpForm.value.dueDateEnd
      });
    }
  }

  commentHandler(callNote) {
    const dialogRef = this.dialog.open(ProjectCallNoteCommentDialogComponent, {
      width: '500px',
      data: callNote
    });
  }

  fetchCallNoteProjectName(projId: number): Observable<string> {
    return this.projService.getProject(projId).pipe(map((proj) => proj.name));
  }

  fetchCallNoteOpportunityName(oppId: number): Observable<string> {
    return this.oppService.getOpportunity(oppId).pipe(map((opp) => opp.name));
  }

  openCallNoteDrawer(): void {
    this.store.dispatch(
      new OpenDrawer({
        component: CallNoteFormAudioComponent,
        data: {
          company: this.company ? this.company : this.data.company || null,
          redirect: false
        }
      })
    );
  }
}
