import { ExpensesActions, ExpensesActionTypes } from './expenses.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { Expense } from 'src/app/models/activities/expense';
import { ExpenseType } from 'src/app/models/activities/expense-type';
import { GenericMap } from 'src/app/models/generic-map';
import { FileMetaData } from 'src/app/models/file-meta-data';
import format from 'date-fns/format';
import subtract from 'date-fns/sub';
import { endOfDay, startOfDay } from 'date-fns';
import { ColumnHeader } from 'src/app/models/column-header';

export interface ExpensesState {
  pending: boolean;
  data: GenericMap<Expense>;
  selectedExpenseId: number;
  queryParams: {
    daterange: {
      begin: string;
      end: string;
    };
  };
  // TODO: Make this its own api sub-state item with error, data, and pending
  expenseTypes: ExpenseType[];
  error: HttpErrorResponse;
  pendingFileUploads: GenericMap<FileMetaData>;
  expenseColumns: string[];
  selectedExpenseColumns: string[];
}

export const initialState: ExpensesState = {
  pending: false,
  data: {},
  queryParams: {
    daterange: {
      begin: format(
        subtract(startOfDay(new Date()), {
          months: 3
        }),
        'MM/dd/yyyy'
      ),
      end: format(endOfDay(new Date()), 'MM/dd/yyyy')
    }
  },
  selectedExpenseId: null,
  expenseTypes: [],
  error: undefined,
  pendingFileUploads: {},
  expenseColumns: [],
  selectedExpenseColumns: [
    'Select',
    'Attachment',
    'Sales Rep',
    'Company',
    'Contacts',
    'Type',
    'Purpose/details',
    'Expense Date',
    'Amount'
  ]
};

export function expensesReducer(
  state = initialState,
  action: ExpensesActions
): ExpensesState {
  switch (action.type) {
    case ExpensesActionTypes.SelectExpenseId:
      return {
        ...state,
        selectedExpenseId: action.payload
      };

    case ExpensesActionTypes.ClearExpenseId:
      return {
        ...state,
        selectedExpenseId: null
      };

    case ExpensesActionTypes.CreateExpense:
      return { ...state, pending: true };

    case ExpensesActionTypes.CreateExpenseSuccess:
      return { ...state, pending: false, pendingFileUploads: {} };

    case ExpensesActionTypes.CreateExpenseFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case ExpensesActionTypes.UpdateExpense:
      return { ...state, pending: true };

    case ExpensesActionTypes.UpdateExpenseFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case ExpensesActionTypes.DeleteExpense:
      return { ...state, pending: true };

    case ExpensesActionTypes.DeleteExpenseFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case ExpensesActionTypes.LoadExpenses:
      return { ...state, pending: true };

    case ExpensesActionTypes.LoadExpensesSuccess:
      return {
        ...state,
        pending: false,
        data: action.payload
      };

    case ExpensesActionTypes.LoadExpensesFailure:
      return {
        ...state,
        pending: false,
        data: {},
        error: action.payload
      };

    case ExpensesActionTypes.LoadExpenseTypesSuccess:
      return {
        ...state,
        expenseTypes: action.payload
      };

    // case ExpensesActionTypes.UploadExpenseFileSuccess:
    //   return {
    //     ...state,
    //     pendingFileUploads: {
    //       ...state.pendingFileUploads,
    //       [action.payload.fileMetaData.id]: action.payload.fileMetaData
    //     }
    //   };

    case ExpensesActionTypes.UploadFileOnNewExpenseSuccess: {
      return {
        ...state,
        pendingFileUploads: {
          ...state.pendingFileUploads,
          [action.payload.id]: action.payload
        }
      };
    }

    case ExpensesActionTypes.ClearExpenseFileSuccess:
      return {
        ...state,
        pendingFileUploads: {
          ...state.pendingFileUploads,
          [action.payload]: undefined
        }
      };

    case ExpensesActionTypes.ClearAllExpenseFilesSuccess:
      return {
        ...state,
        pendingFileUploads: {}
      };

    case ExpensesActionTypes.SetDateRange:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          daterange: action.payload
        }
      };

    case ExpensesActionTypes.LoadExpensesTableColumnsFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case ExpensesActionTypes.LoadExpensesTableColumnsSuccess:
      return {
        ...state,
        pending: false,
        expenseColumns: action.payload
      };

    case ExpensesActionTypes.LoadSelectedExpensesTableColumnsFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case ExpensesActionTypes.LoadSelectedExpensesTableColumnsSuccess:
      return {
        ...state,
        pending: false,
        selectedExpenseColumns: action.payload
      };

    case ExpensesActionTypes.SelectExpenseTableColumns:
      return {
        ...state,
        selectedExpenseColumns: action.payload
      };

    default:
      return state;
  }
}
