import { DashboardStackCount } from 'src/app/models/dashboard/dashboard-stack-count';
import { DashboardActions, DashboardActionTypes } from './dashboard.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { GenericApi } from 'src/app/models/generic-api';
import { DashboardCount } from 'src/app/models/dashboard/dashboard-count';
import { LabelGroupCount } from 'src/app/models/dashboard/call-notes/label-group-count';
import { LabelGroupsCount } from 'src/app/models/dashboard/call-notes/label-groups-count';
import subtract from 'date-fns/sub';
import setDate from 'date-fns/set';
import startOfWeek from 'date-fns/startOfWeek';
import { dbDateFormatWithoutT } from 'src/app/utils/dbDateFormat';
import format from 'date-fns/format';
import { CallNote } from 'src/app/models/call-notes/call-note';
import { ChartDatum } from 'src/app/models/dashboard/chart-datum';
import { Company } from 'src/app/models/companies/company';
import { CompanyContacted } from 'src/app/models/dashboard/company-contacted';
import { endOfDay, startOfDay, startOfMonth } from 'date-fns';

export interface DashboardState {
  selectedUserId: number;
  selectedTerritoryId: number;
  pending: boolean;
  error: HttpErrorResponse;
  queryParams: {
    daterange: {
      begin: string;
      end: string;
    };
    userIds: number[];
    territoryIds: number[];
    callTypeIds: number[];
    callFieldIds: number[];
    labelGroupIds: number[];
  };
  data: {
    callNotes: {
      typeCounts: GenericApi<DashboardCount[]>;
      typeUserCounts: GenericApi<DashboardStackCount[]>;
      fieldCounts: GenericApi<DashboardCount[]>;
      userCounts: GenericApi<DashboardCount[]>;
      labelGroupCounts: GenericApi<LabelGroupCount[]>;
      labelGroupsCounts: GenericApi<LabelGroupsCount[]>;
      last12Months: GenericApi<ChartDatum[]>;
      notes: GenericApi<CallNote[]>;
    };
    companies: {
      newCompanies: GenericApi<Company[]>;
      companiesContacted: GenericApi<CompanyContacted[]>;
      companiesNotContacted: GenericApi<CompanyContacted[]>;
    };
  };
}

export const initialState: DashboardState = {
  selectedUserId: undefined,
  selectedTerritoryId: undefined,
  pending: false,
  error: undefined,
  queryParams: {
    daterange: {
      begin: format(
        startOfDay(
          setDate(new Date(), {
            date: startOfMonth(new Date()).getDate(),
            year: startOfMonth(new Date()).getFullYear(),
            month: startOfMonth(new Date()).getMonth()
          })
        ),
        dbDateFormatWithoutT
      ),
      end: format(
        setDate(new Date(), {
          date: endOfDay(new Date()).getDate(),
          year: endOfDay(new Date()).getFullYear(),
          month: endOfDay(new Date()).getMonth()
        }),
        dbDateFormatWithoutT
      )
    },
    userIds: [],
    territoryIds: [],
    callTypeIds: [],
    callFieldIds: [],
    labelGroupIds: []
  },
  data: {
    callNotes: {
      typeCounts: {
        pending: false,
        error: undefined,
        data: []
      },
      typeUserCounts: {
        pending: false,
        error: undefined,
        data: []
      },
      fieldCounts: {
        pending: false,
        error: undefined,
        data: []
      },
      userCounts: {
        pending: false,
        error: undefined,
        data: []
      },
      labelGroupCounts: {
        pending: false,
        error: undefined,
        data: []
      },
      labelGroupsCounts: {
        pending: false,
        error: undefined,
        data: []
      },
      notes: {
        pending: false,
        error: undefined,
        data: []
      },
      last12Months: {
        pending: false,
        error: undefined,
        data: []
      }
    },
    companies: {
      newCompanies: {
        pending: false,
        error: undefined,
        data: [],
        count: 0
      },
      companiesContacted: {
        pending: false,
        error: undefined,
        data: [],
        count: 0
      },
      companiesNotContacted: {
        pending: false,
        error: undefined,
        data: [],
        count: 0
      }
    }
  }
};

export function dashboardReducer(
  state = initialState,
  action: DashboardActions
): DashboardState {
  switch (action.type) {
    case DashboardActionTypes.LoadDashboard:
      return {
        ...state,
        pending: true
      };

    case DashboardActionTypes.LoadCallTypeCounts:
      return {
        ...state,
        data: {
          ...state.data,
          callNotes: {
            ...state.data.callNotes,
            typeCounts: {
              pending: true,
              error: undefined,
              data: []
            }
          }
        }
      };

    case DashboardActionTypes.LoadCallTypeUserCounts:
      return {
        ...state,
        data: {
          ...state.data,
          callNotes: {
            ...state.data.callNotes,
            typeUserCounts: {
              pending: true,
              error: undefined,
              data: []
            }
          }
        }
      };

    case DashboardActionTypes.LoadCallTypeUserCountsSuccess:
      return {
        ...state,
        data: {
          ...state.data,
          callNotes: {
            ...state.data.callNotes,
            typeUserCounts: {
              pending: false,
              error: undefined,
              data: action.payload
            }
          }
        }
      };
    case DashboardActionTypes.LoadCallTypeUserCountsFailure:
      return {
        ...state,
        data: {
          ...state.data,
          callNotes: {
            ...state.data.callNotes,
            typeUserCounts: {
              pending: false,
              error: action.payload,
              data: []
            }
          }
        }
      };

    case DashboardActionTypes.LoadCallTypeCountsSuccess:
      return {
        ...state,
        data: {
          ...state.data,
          callNotes: {
            ...state.data.callNotes,
            typeCounts: {
              pending: false,
              error: undefined,
              data: action.payload
            }
          }
        }
      };

    case DashboardActionTypes.LoadCallTypeCountsFailure:
      return {
        ...state,
        data: {
          ...state.data,
          callNotes: {
            ...state.data.callNotes,
            typeCounts: {
              pending: false,
              error: action.payload,
              data: []
            }
          }
        }
      };

    case DashboardActionTypes.LoadCallFieldCounts:
      return {
        ...state,
        data: {
          ...state.data,
          callNotes: {
            ...state.data.callNotes,
            fieldCounts: {
              pending: true,
              error: undefined,
              data: []
            }
          }
        }
      };

    case DashboardActionTypes.LoadCallFieldCountsSuccess:
      return {
        ...state,
        data: {
          ...state.data,
          callNotes: {
            ...state.data.callNotes,
            fieldCounts: {
              pending: false,
              error: undefined,
              data: action.payload
            }
          }
        }
      };

    case DashboardActionTypes.LoadCallFieldCountsFailure:
      return {
        ...state,
        data: {
          ...state.data,
          callNotes: {
            ...state.data.callNotes,
            fieldCounts: {
              pending: false,
              error: action.payload,
              data: []
            }
          }
        }
      };

    case DashboardActionTypes.LoadCallUserCounts:
      return {
        ...state,
        data: {
          ...state.data,
          callNotes: {
            ...state.data.callNotes,
            userCounts: {
              pending: true,
              error: undefined,
              data: []
            }
          }
        }
      };

    case DashboardActionTypes.LoadCallUserCountsSuccess:
      return {
        ...state,
        data: {
          ...state.data,
          callNotes: {
            ...state.data.callNotes,
            userCounts: {
              pending: false,
              error: undefined,
              data: action.payload
            }
          }
        }
      };

    case DashboardActionTypes.LoadCallUserCountsFailure:
      return {
        ...state,
        data: {
          ...state.data,
          callNotes: {
            ...state.data.callNotes,
            userCounts: {
              pending: false,
              error: action.payload,
              data: []
            }
          }
        }
      };

    case DashboardActionTypes.LoadCallLabelGroupCountsSuccess:
      return {
        ...state,
        data: {
          ...state.data,
          callNotes: {
            ...state.data.callNotes,
            labelGroupCounts: {
              pending: false,
              error: undefined,
              data: action.payload
            }
          }
        }
      };

    case DashboardActionTypes.LoadCallLabelGroupCountsFailure:
      return {
        ...state,
        data: {
          ...state.data,
          callNotes: {
            ...state.data.callNotes,
            labelGroupCounts: {
              pending: false,
              error: action.payload,
              data: []
            }
          }
        }
      };

    case DashboardActionTypes.LoadCallLabelGroupsCounts:
      return {
        ...state,
        data: {
          ...state.data,
          callNotes: {
            ...state.data.callNotes,
            labelGroupsCounts: {
              pending: true,
              error: undefined,
              data: []
            }
          }
        }
      };

    case DashboardActionTypes.LoadCallLabelGroupsCountsSuccess:
      return {
        ...state,
        data: {
          ...state.data,
          callNotes: {
            ...state.data.callNotes,
            labelGroupsCounts: {
              pending: false,
              error: undefined,
              data: action.payload
            }
          }
        }
      };

    case DashboardActionTypes.LoadCallLabelGroupsCountsFailure:
      return {
        ...state,
        data: {
          ...state.data,
          callNotes: {
            ...state.data.callNotes,
            labelGroupsCounts: {
              pending: false,
              error: action.payload,
              data: []
            }
          }
        }
      };

    case DashboardActionTypes.SetDateRange:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          daterange: action.payload
        }
      };

    case DashboardActionTypes.SetCallTypeIds:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          callTypeIds: action.payload
        }
      };

    case DashboardActionTypes.SetCallFieldIds:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          callFieldIds: action.payload
        }
      };

    case DashboardActionTypes.SetTerritoryIds:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          territoryIds: action.payload
        }
      };

    case DashboardActionTypes.SetUserIds:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          userIds: action.payload
        }
      };

    case DashboardActionTypes.SetLabelGroupIds:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          labelGroupIds: action.payload
        }
      };

    case DashboardActionTypes.ClearCallNotes:
      return {
        ...state,
        data: {
          ...state.data,
          callNotes: {
            ...state.data.callNotes,
            notes: {
              pending: false,
              error: undefined,
              data: []
            }
          }
        }
      };

    case DashboardActionTypes.LoadCallTypeNotes:
      return {
        ...state,
        data: {
          ...state.data,
          callNotes: {
            ...state.data.callNotes,
            notes: {
              pending: true,
              error: undefined,
              data: []
            }
          }
        }
      };

    case DashboardActionTypes.LoadCallTypeNotesSuccess:
      return {
        ...state,
        data: {
          ...state.data,
          callNotes: {
            ...state.data.callNotes,
            notes: {
              pending: false,
              error: undefined,
              data: action.payload
            }
          }
        }
      };

    case DashboardActionTypes.LoadCallTypeNotesFailure:
      return {
        ...state,
        data: {
          ...state.data,
          callNotes: {
            ...state.data.callNotes,
            notes: {
              pending: false,
              error: action.payload,
              data: []
            }
          }
        }
      };

    case DashboardActionTypes.LoadCallFieldNotes:
      return {
        ...state,
        data: {
          ...state.data,
          callNotes: {
            ...state.data.callNotes,
            notes: {
              pending: true,
              error: undefined,
              data: []
            }
          }
        }
      };

    case DashboardActionTypes.LoadCallFieldNotesSuccess:
      return {
        ...state,
        data: {
          ...state.data,
          callNotes: {
            ...state.data.callNotes,
            notes: {
              pending: false,
              error: undefined,
              data: action.payload
            }
          }
        }
      };

    case DashboardActionTypes.LoadCallFieldNotesFailure:
      return {
        ...state,
        data: {
          ...state.data,
          callNotes: {
            ...state.data.callNotes,
            notes: {
              pending: false,
              error: action.payload,
              data: []
            }
          }
        }
      };

    case DashboardActionTypes.LoadCallUserNotes:
      return {
        ...state,
        data: {
          ...state.data,
          callNotes: {
            ...state.data.callNotes,
            notes: {
              pending: true,
              error: undefined,
              data: []
            }
          }
        }
      };

    case DashboardActionTypes.LoadCallUserNotesSuccess:
      return {
        ...state,
        data: {
          ...state.data,
          callNotes: {
            ...state.data.callNotes,
            notes: {
              pending: false,
              error: undefined,
              data: action.payload
            }
          }
        }
      };

    case DashboardActionTypes.LoadCallUserNotesFailure:
      return {
        ...state,
        data: {
          ...state.data,
          callNotes: {
            ...state.data.callNotes,
            notes: {
              pending: false,
              error: action.payload,
              data: []
            }
          }
        }
      };

    case DashboardActionTypes.LoadLast12Months:
      return {
        ...state,
        data: {
          ...state.data,
          callNotes: {
            ...state.data.callNotes,
            last12Months: {
              pending: true,
              error: undefined,
              data: []
            }
          }
        }
      };

    case DashboardActionTypes.LoadLast12MonthsSuccess:
      return {
        ...state,
        data: {
          ...state.data,
          callNotes: {
            ...state.data.callNotes,
            last12Months: {
              pending: false,
              error: undefined,
              data: action.payload
            }
          }
        }
      };

    case DashboardActionTypes.LoadLast12MonthsFailure:
      return {
        ...state,
        data: {
          ...state.data,
          callNotes: {
            ...state.data.callNotes,
            last12Months: {
              pending: false,
              error: action.payload,
              data: []
            }
          }
        }
      };

    case DashboardActionTypes.LoadNewCompanies: {
      return {
        ...state,
        data: {
          ...state.data,
          companies: {
            ...state.data.companies,
            newCompanies: {
              pending: true,
              data: [],
              error: undefined,
              count: 0
            }
          }
        }
      };
    }

    case DashboardActionTypes.LoadNewCompaniesSuccess: {
      return {
        ...state,
        data: {
          ...state.data,
          companies: {
            ...state.data.companies,
            newCompanies: {
              pending: false,
              data: action.payload,
              error: undefined,
              count: action.payload.length
            }
          }
        }
      };
    }

    case DashboardActionTypes.LoadNewCompaniesFailure: {
      return {
        ...state,
        data: {
          ...state.data,
          companies: {
            ...state.data.companies,
            newCompanies: {
              pending: false,
              data: [],
              error: action.payload,
              count: 0
            }
          }
        }
      };
    }

    case DashboardActionTypes.LoadContactedCompanies: {
      return {
        ...state,
        data: {
          ...state.data,
          companies: {
            ...state.data.companies,
            companiesContacted: {
              pending: true,
              data: [],
              error: undefined,
              count: 0
            }
          }
        }
      };
    }

    case DashboardActionTypes.LoadContactedCompaniesSuccess: {
      return {
        ...state,
        data: {
          ...state.data,
          companies: {
            ...state.data.companies,
            companiesContacted: {
              pending: false,
              data: action.payload,
              error: undefined,
              count: action.payload.length
            }
          }
        }
      };
    }

    case DashboardActionTypes.LoadContactedCompaniesFailure: {
      return {
        ...state,
        data: {
          ...state.data,
          companies: {
            ...state.data.companies,
            companiesContacted: {
              pending: false,
              data: [],
              error: action.payload,
              count: 0
            }
          }
        }
      };
    }

    default:
      return state;
  }
}
