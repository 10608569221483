import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';

import { reducers, metaReducers } from './store.reducer';
import { environment } from '../../environments/environment';
import { InitStateModule } from './init/init.module';
import { ServicesModule } from '../services/services.module';
import { AuthStateModule } from './auth/auth-state.module';
import { CompaniesStateModule } from './companies/companies.module';
import { ContactsStateModule } from './contacts/contacts.module';
import { ExpensesStateModule } from './expenses/expenses-state.module';
import { DashboardStateModule } from './dashboard/dashboard.module';
// import { MapsStateModule } from './maps/maps.module';
import { MileageStateModule } from './mileage/mileage.module';
import { OpportunitiesStateModule } from './opportunities/opportunities.module';
import { ReportsStateModule } from './reports/reports.module';
import { SalesStateModule } from './sales/sales.module';
import { WhatsNewStateModule } from './whats-new/whats-new.module';
import { TutorialsStateModule } from './tutorials/tutorials.module';
import { HelpStateModule } from './help/help.module';
import { UserSettingsStateModule } from './user-settings/user-settings.module';
import { SystemSettingsStateModule } from './system-settings/system-settings.module';
import { TerritoryManagementStateModule } from './territory-management/territory-management.module';
import { ManageUsersStateModule } from './manage-users/manage-users.module';
import { ClientsStateModule } from './clients/clients.module';

import { BillingStateModule } from './billing/billing.module';
import { UsersStateModule } from './users/users.module';
import { CallNoteCustomFieldsStateModule } from './system-settings/call-notes/call-note-custom-fields-state.module';
import { UndoDeleteStateModule } from './undo-delete/undo-delete.module';
import { CompanyFieldsStateModule } from './system-settings/company-fields/company-fields-state.module';
import { ContactCustomFieldsStateModule } from './system-settings/contact-custom-fields/contact-custom-fields-state.module';
import { FieldTypesStateModule } from './system-settings/field-types/field-types-state.module';
import { ProjectFieldsStateModule } from './system-settings/project-fields/project-fields.module';
import { PlansStateModule } from './plans/plans.module';
import { CompanyLabelsStateModule } from './system-settings/company-labels/company-labels.module';
import { CallTypesStateModule } from './system-settings/call-types/call-types.module';
import { ProjectBidsStateModule } from './system-settings/project-bids/project-bids.module';
import { ProjectLabelsStateModule } from './system-settings/project-labels/project-labels.module';
import { CompanyLabelsIndividualStateModule } from './system-settings/company-labels-individual/company-labels-individual.module';
import { ProjectLabelsIndividualStateModule } from './system-settings/project-labels-individual/project-labels-individual.module';
import { ProductLinesStateModule } from './product-lines/product-lines.module';
import { CallNotesStateModule } from './call-notes/call-notes.module';
import { CallNoteTypesStateModule } from './call-note-types/call-note-types.module';
import { ProjectsStateModule } from './projects/projects.module';
import { ExpenseTypesStateModule } from './system-settings/expense-types/expense-types.module';
import { GlobalSearchStateModule } from './global-search/global-search.module';
import { LayoutStateModule } from './layout/layout.module';
import { ItemNamesStateModule } from './system-settings/opportunity-item-names/opportunity-item-names.module';
import { OpportunityLabelsStateModule } from './system-settings/opportunity-labels/opportunity-labels/opportunity-labels.module';
// eslint-disable-next-line
import { OpportunityLabelsIndividualStateModule } from './system-settings/opportunity-labels-individual/opportunity-labels-individual.module';
import { FileCategoriesModule } from './system-settings/file-categories/file-categories.module';
import { ProjectCustomFieldsStateModule } from './system-settings/project-custom-fields/project-custom-fields.module';
import { BidCustomFieldsStateModule } from './system-settings/bid-custom-fields/bid-custom-fields.module';
import { ProjCompCustomFieldsStateModule } from './system-settings/proj-comp-custom-fields/proj-comp-custom-fields.module';
import { ProjManufCustomFieldsStateModule } from './system-settings/proj-manuf-custom-fields/proj-manuf-custom-fields.module';
import { TodosStateModule } from './todos/todos.module';
import { CalendarStateModule } from './calendar/calendar.module';
import { DailyActivitiesStateModule } from './daily-activities/daily-activities.module';
import { PrivateNotesModule } from './private-notes/private-notes.module';
import { DmsStateModule } from './dms/dms.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false
      }
    }),
    !environment.production
      ? StoreDevtoolsModule.instrument({
          name: 'Telenotes Web Client - Dev'
        })
      : StoreDevtoolsModule.instrument({
          name: 'Telenotes Web Client - Prod'
        }),
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot([]),
    ServicesModule,
    InitStateModule,
    LayoutStateModule,
    AuthStateModule,
    CompaniesStateModule,
    DashboardStateModule,
    // MapsStateModule,
    MileageStateModule,
    OpportunitiesStateModule,
    ProjectsStateModule,
    ReportsStateModule,
    SalesStateModule,
    ExpensesStateModule,
    TutorialsStateModule,
    WhatsNewStateModule,
    HelpStateModule,
    UserSettingsStateModule,
    SystemSettingsStateModule,
    TerritoryManagementStateModule,
    ManageUsersStateModule,
    ClientsStateModule,
    PlansStateModule,
    BillingStateModule,
    UsersStateModule,
    CallNoteCustomFieldsStateModule,
    UndoDeleteStateModule,
    CompanyFieldsStateModule,
    ContactCustomFieldsStateModule,
    UndoDeleteStateModule,
    FieldTypesStateModule,
    ProjectFieldsStateModule,
    CompanyLabelsStateModule,
    CallTypesStateModule,
    ProjectBidsStateModule,
    ProjectLabelsStateModule,
    CompanyLabelsIndividualStateModule,
    ProjectLabelsIndividualStateModule,
    ProductLinesStateModule,
    CallNotesStateModule,
    CallNoteTypesStateModule,
    ExpenseTypesStateModule,
    ContactsStateModule,
    DailyActivitiesStateModule,
    GlobalSearchStateModule,
    ItemNamesStateModule,
    OpportunityLabelsStateModule,
    OpportunityLabelsIndividualStateModule,
    FileCategoriesModule,
    ProjectCustomFieldsStateModule,
    BidCustomFieldsStateModule,
    ProjCompCustomFieldsStateModule,
    ProjManufCustomFieldsStateModule,
    TodosStateModule,
    CalendarStateModule,
    PrivateNotesModule,
    DmsStateModule
  ]
})
export class RootStoreModule {}
