import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { CallNoteFieldQuery } from 'src/app/models/elastic/callNoteField-query';
import { CallNoteTypeQuery } from 'src/app/models/elastic/callNoteType-query';
import { DateQuery } from 'src/app/models/elastic/date-query';
import { RepQuery } from 'src/app/models/elastic/rep-query';

export enum DailyActivitiesActionTypes {
  LoadDailyActivity = '[Daily Activities] Load Daily Activity',
  LoadDailyActivitySuccess = '[Daily Activities] Load Daily Activity Success',
  LoadDailyActivityFailure = '[Daily Activities] Load Daily Activity Failure',
  LoadCallNotesByRep = '[Daily Activities] Load Call Notes By Rep',
  LoadCallNotesByRepSuccess = '[Daily Activities] Load Call Notes By Rep Success',
  LoadCallNotesByRepFailure = '[Daily Activities] Load Call Notes By Rep Failure',
  LoadCallNotesByCallType = '[Daily Activities] Load Call Notes By Call Type',
  LoadCallNotesByCallTypeSuccess = '[Daily Activities] Load Call Notes By Call Type Success',
  LoadCallNotesByCallTypeFailure = '[Daily Activities] Load Call Notes By Call Type Failure',
  LoadCallNotesByCallField = '[Daily Activities] Load Call Notes By Call Field',
  LoadCallNotesByCallFieldSuccess = '[Daily Activities] Load Call Notes By Call Field Success',
  LoadCallNotesByCallFieldFailure = '[Daily Activities] Load Call Notes By Call Field Failure'
}

export class LoadDailyActivity implements Action {
  readonly type = DailyActivitiesActionTypes.LoadDailyActivity;
  constructor(public payload: DateQuery) {}
}

export class LoadDailyActivitySuccess implements Action {
  readonly type = DailyActivitiesActionTypes.LoadDailyActivitySuccess;
  constructor(public payload: {}) {}
}

export class LoadDailyActivityFailure implements Action {
  readonly type = DailyActivitiesActionTypes.LoadDailyActivityFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadCallNotesByRep implements Action {
  readonly type = DailyActivitiesActionTypes.LoadCallNotesByRep;
  constructor(public payload: RepQuery) {}
}

export class LoadCallNotesByRepSuccess implements Action {
  readonly type = DailyActivitiesActionTypes.LoadCallNotesByRepSuccess;
  constructor(public payload: {}) {}
}

export class LoadCallNotesByRepFailure implements Action {
  readonly type = DailyActivitiesActionTypes.LoadCallNotesByRepFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadCallNotesByCallType implements Action {
  readonly type = DailyActivitiesActionTypes.LoadCallNotesByCallType;
  constructor(public payload: CallNoteTypeQuery) {}
}

export class LoadCallNotesByCallTypeSuccess implements Action {
  readonly type = DailyActivitiesActionTypes.LoadCallNotesByCallTypeSuccess;
  constructor(public payload: {}) {}
}

export class LoadCallNotesByCallTypeFailure implements Action {
  readonly type = DailyActivitiesActionTypes.LoadCallNotesByCallTypeFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadCallNotesByCallField implements Action {
  readonly type = DailyActivitiesActionTypes.LoadCallNotesByCallField;
  constructor(public payload: CallNoteFieldQuery) {}
}

export class LoadCallNotesByCallFieldSuccess implements Action {
  readonly type = DailyActivitiesActionTypes.LoadCallNotesByCallFieldSuccess;
  constructor(public payload: {}) {}
}

export class LoadCallNotesByCallFieldFailure implements Action {
  readonly type = DailyActivitiesActionTypes.LoadCallNotesByCallFieldFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export type DailyActivitiesActions =
  | LoadDailyActivity
  | LoadDailyActivitySuccess
  | LoadDailyActivityFailure
  | LoadCallNotesByRep
  | LoadCallNotesByRepSuccess
  | LoadCallNotesByRepFailure
  | LoadCallNotesByCallType
  | LoadCallNotesByCallTypeSuccess
  | LoadCallNotesByCallTypeFailure
  | LoadCallNotesByCallField
  | LoadCallNotesByCallFieldSuccess
  | LoadCallNotesByCallFieldFailure;
