import { Action } from '@ngrx/store';
import { Label } from 'src/app/models/label';
import { HttpErrorResponse } from '@angular/common/http';

export enum CompanyLabelsIndividualActionTypes {
  SelectCompanyLabelIndividualId = '[CompanyLabelsIndividual] Select CompanyLabelIndvidual Id',
  ClearCompanyLabelIndividualId = '[CompanyLabelsIndividual] Clear CompanyLabelIndividual Id',
  LoadCompanyLabelsIndividual = '[CompanyLabelsIndividual] Load CompanyLabelsIndividual',
  LoadCompanyLabelsIndividualSuccess = '[CompanyLabelsIndividual] Load CompanyLabelsIndividual Success',
  LoadCompanyLabelsIndividualFailure = '[CompanyLabelsIndividual] Load CompanyLabelsIndividual Failure',
  CreateCompanyLabelsIndividual = '[CompanyLabelsIndividual] Create CompanyLabelsIndividual',
  CreateCompanyLabelsIndividualSuccess = '[CompanyLabelsIndividual] Create CompanyLabelsIndividual Success',
  CreateCompanyLabelsIndividualFailure = '[CompanyLabelsIndividual] Create CompanyLabelsIndividual Failure',
  UpdateCompanyLabelsIndividual = '[CompanyLabelsIndividual] Update CompanyLabelsIndividual',
  UpdateCompanyLabelsIndividualSuccess = '[CompanyLabelsIndividual] Update CompanyLabelsIndividual Success',
  UpdateCompanyLabelsIndividualFailure = '[CompanyLabelsIndividual] Update CompanyLabelsIndividual Failure',
  DeleteCompanyLabelsIndividual = '[CompanyLabelsIndividual] Delete CompanyLabelsIndividual',
  DeleteCompanyLabelsIndividualSuccess = '[CompanyLabelsIndividual] Delete CompanyLabelsIndividual Success',
  DeleteCompanyLabelsIndividualFailure = '[CompanyLabelsIndividual] Delete CompanyLabelsIndividual Failure'
}

export class SelectCompanyLabelIndividualId implements Action {
  readonly type = CompanyLabelsIndividualActionTypes.SelectCompanyLabelIndividualId;
  constructor(public payload: number) {}
}

export class ClearCompanyLabelIndividualId implements Action {
  readonly type = CompanyLabelsIndividualActionTypes.ClearCompanyLabelIndividualId;
}

export class LoadCompanyLabelsIndividual implements Action {
  readonly type = CompanyLabelsIndividualActionTypes.LoadCompanyLabelsIndividual;
  constructor(public payload: number) {}
}

export class LoadCompanyLabelsIndividualSuccess implements Action {
  readonly type = CompanyLabelsIndividualActionTypes.LoadCompanyLabelsIndividualSuccess;
  constructor(public payload: Label[]) {}
}

export class LoadCompanyLabelsIndividualFailure implements Action {
  readonly type = CompanyLabelsIndividualActionTypes.LoadCompanyLabelsIndividualFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class CreateCompanyLabelsIndividual implements Action {
  readonly type = CompanyLabelsIndividualActionTypes.CreateCompanyLabelsIndividual;
  constructor(public payload: { label: Label; groupId: number }) {}
}

export class CreateCompanyLabelsIndividualSuccess implements Action {
  readonly type = CompanyLabelsIndividualActionTypes.CreateCompanyLabelsIndividualSuccess;
  constructor(public payload: Label) {}
}

export class CreateCompanyLabelsIndividualFailure implements Action {
  readonly type = CompanyLabelsIndividualActionTypes.CreateCompanyLabelsIndividualFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class UpdateCompanyLabelsIndividual implements Action {
  readonly type = CompanyLabelsIndividualActionTypes.UpdateCompanyLabelsIndividual;
  constructor(public payload: { label: Label; groupId: number }) {}
}

export class UpdateCompanyLabelsIndividualSuccess implements Action {
  readonly type = CompanyLabelsIndividualActionTypes.UpdateCompanyLabelsIndividualSuccess;
  constructor(public payload: Label) {}
}

export class UpdateCompanyLabelsIndividualFailure implements Action {
  readonly type = CompanyLabelsIndividualActionTypes.UpdateCompanyLabelsIndividualFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class DeleteCompanyLabelsIndividual implements Action {
  readonly type = CompanyLabelsIndividualActionTypes.DeleteCompanyLabelsIndividual;
  constructor(public payload: { groupId: number; id: number }) {}
}

export class DeleteCompanyLabelsIndividualSuccess implements Action {
  readonly type = CompanyLabelsIndividualActionTypes.DeleteCompanyLabelsIndividualSuccess;
  constructor(public payload: { groupId: number; id: number }) {}
}

export class DeleteCompanyLabelsIndividualFailure implements Action {
  readonly type = CompanyLabelsIndividualActionTypes.DeleteCompanyLabelsIndividualFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export type CompanyLabelsIndividualActions =
  | SelectCompanyLabelIndividualId
  | ClearCompanyLabelIndividualId
  | LoadCompanyLabelsIndividual
  | LoadCompanyLabelsIndividualSuccess
  | LoadCompanyLabelsIndividualFailure
  | CreateCompanyLabelsIndividual
  | CreateCompanyLabelsIndividualSuccess
  | CreateCompanyLabelsIndividualFailure
  | UpdateCompanyLabelsIndividual
  | UpdateCompanyLabelsIndividualSuccess
  | UpdateCompanyLabelsIndividualFailure
  | DeleteCompanyLabelsIndividual
  | DeleteCompanyLabelsIndividualSuccess
  | DeleteCompanyLabelsIndividualFailure;
