<h1 mat-dialog-title>Comment/Share</h1>

<form [formGroup]="commentsForm" fxLayout="row" fxLayoutGap="14px">
  <textarea
    style="margin: 20px"
    class="comment-textarea"
    matInput
    tnAutosizeTextarea
    placeholder="Add a comment"
    formControlName="value"
  ></textarea>
</form>
<form [formGroup]="shareForm">
  <tn-multi-select
    style="margin-bottom: 1rem"
    [selectedItems]="initialUsersArray"
    (selectedItemsChange)="setUsers($event)"
    [$allItems]="$allUsers"
    [placeholder]="'Share with (optional)'"
  >
  </tn-multi-select>
  <div *ngIf="!manualEnter; else enterEmail" (click)="manualEntry()" style="padding: 5px">
    <p class="open-additional-field">Not there? Click to manually enter email</p>
  </div>
  <ng-template #enterEmail>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <mat-form-field style="width: 100%">
        <input
          type="email"
          formControlName="manualShareWithEmails"
          matInput
          placeholder="Type email"
        />
      </mat-form-field>
      <div fxLayout="row">
        <button
          mat-icon-button
          class="material-icons"
          color="primary"
          style="margin-right: 5px"
          matTooltip="Add email"
          (click)="submitEmail()"
          [disabled]="manualShareWithEmails.invalid || manualShareWithEmails.pristine"
        >
          <mat-icon>done</mat-icon>
        </button>
        <button
          mat-icon-button
          class="material-icons"
          color="warn"
          (click)="manualEntry()"
          matTooltip="Cancel manual entry"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    <div
      *ngIf="
        (manualShareWithEmails.invalid && manualShareWithEmails.touched) ||
        manualShareWithEmails.dirty
      "
    >
      <small style="color: red" *ngIf="manualShareWithEmails.errors?.pattern"
        >Please provide a valid email address</small
      >
    </div>
  </ng-template>
</form>
<div *ngIf="manualEmails.length">
  <p style="text-decoration: underline">Additionally sharing with:</p>
  <ul>
    <li *ngFor="let email of manualEmails">
      {{ email }}
      <button
        mat-icon-button
        color="warn"
        matTooltip="Remove email"
        class="material-icons"
        (click)="removeEmail(email)"
      >
        <mat-icon>cancel</mat-icon>
      </button>
    </li>
  </ul>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="white" (click)="onNoClick()">Cancel</button>
  <button
    mat-raised-button
    color="primary"
    (click)="addComment()"
    [disabled]="commentsForm.invalid || commentsForm.pristine"
  >
    Save
  </button>
</div>
