<div fxLayout="column" fxLayoutAlign="center center">
    <div *ngIf="error">
        <h2><code ng-non-bindable>{{</code>{{error}}<code ng-non-bindable>}}</code></h2>
    </div>
    <div>
        <img src="assets/error.jpg" />
    </div>
    <div fxLayout="column" fxLayoutAlign="center center">
        <h1 [innerText]="message"></h1>
        <a href="mailto:support@telenotes.com">Wanna tell us what happened?</a>
    </div>
</div>
