<div>
  <div align="end" class="cursor"><mat-icon mat-dialog-close>close</mat-icon></div>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div class="call-note-title-info" fxLayout="row">
      <div fxLayout="column" fxLayoutGap="6px">
        <div [style.fontWeight]="500">
          {{ contact?.firstName }} {{ contact?.lastName }}
        </div>
        <div [style.color]="'#999999'">{{ contact?.title }}</div>
      </div>
    </div>
    <div class="call-note-actions" fxLayout="row">
      <a
        mat-icon-button
        color="primary"
        *ngIf="contact?.email"
        href="mailto:{{ contact?.email }}"
        matTooltip="Email contact"
        target="_blank"
        ><mat-icon [style.fontSize]="'20px'" class="material-icons"> email </mat-icon></a
      >
      <button
        mat-icon-button
        color="primary"
        (click)="addCallNote(contact)"
        matTooltip="Add call note"
      >
        <mat-icon>note_add</mat-icon>
      </button>
      <button
        mat-icon-button
        color="primary"
        (click)="rowClicked(contact)"
        matTooltip="Edit contact"
      >
        <mat-icon>edit</mat-icon>
      </button>
      <button
        mat-icon-button
        color="warn"
        (click)="deleteContact(contact)"
        matTooltip="Delete contact"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
  <mat-card-content class="card-content">
    <div>
      <mat-divider></mat-divider>

      <div fxLayout="row" style="padding-top: 10px">
        <div fxFlex="50" fxLayout="column">
          <div style="margin-bottom: 10px">
            <b>Phone: </b
            ><span [style.color]="'#999999'">{{ formatPhone(contact.phone) }}</span>
          </div>
        </div>
        <div fxFlex="50" fxLayout="column">
          <div>
            <b>Ext: </b><span [style.color]="'#999999'">{{ contact?.ext || '--' }}</span>
          </div>
        </div>
      </div>
      <div fxLayout="row">
        <div fxFlex="50" fxLayout="column">
          <div style="margin-bottom: 10px">
            <b>Mobile: </b
            ><span [style.color]="'#999999'">{{ formatPhone(contact.mobilePhone) }}</span>
          </div>
        </div>
        <div fxFlex="50" fxLayout="column">
          <div>
            <b>Fax: </b
            ><span [style.color]="'#999999'">{{ formatPhone(contact.fax) }}</span>
          </div>
        </div>
      </div>
      <div fxLayout="row">
        <div fxFlex="50" fxLayout="column">
          <div style="margin-bottom: 10px">
            <b>Title: </b
            ><span [style.color]="'#999999'">{{ contact?.title || '--' }}</span>
          </div>
        </div>
        <div fxFlex="50" fxLayout="column">
          <div>
            <b>Department: </b
            ><span [style.color]="'#999999'">{{ contact?.dept || '--' }}</span>
          </div>
        </div>
      </div>
      <div fxLayout="row">
        <div fxFlex="100" fxLayout="column">
          <div style="margin-bottom: 10px">
            <b>Email: </b
            ><span [style.color]="'#999999'">{{ contact?.email || '--' }}</span>
          </div>
        </div>
      </div>
      <div fxLayout="row">
        <div fxFlex="50" fxLayout="column" style="margin-bottom: 10px">
          <div *ngIf="contact.birthday">
            <b>Birthday: </b
            ><span [style.color]="'#999999'">{{
              contact?.birthday | date : 'longDate'
            }}</span>
          </div>
          <div *ngIf="!contact.birthday">
            <b>Birthday: </b><span [style.color]="'#999999'">--</span>
          </div>
        </div>
      </div>
      <div *ngIf="contact.address" style="margin-bottom: 10px">
        <b>Address: </b>
        <span [style.color]="'#999999'" *ngIf="contact.address.addressLine1">
          {{ contact?.address?.addressLine1 }},
        </span>
        <span [style.color]="'#999999'" *ngIf="contact.address.addressLine2">
          {{ contact?.address?.addressLine2 }},
        </span>
        <span [style.color]="'#999999'" *ngIf="contact.address.city"
          >{{ contact?.address?.city }},</span
        >
        <span [style.color]="'#999999'" *ngIf="contact.address.state">
          {{ contact?.address?.state }}
        </span>
        <span [style.color]="'#999999'" *ngIf="contact.address.postCode">{{
          contact?.address?.postCode
        }}</span>
      </div>
      <div *ngIf="contact.notes" style="margin-bottom: 10px">
        <b>Details: </b
        ><span class="details" [style.color]="'#999999'">{{ contact?.notes }}</span>
      </div>
      <div *ngIf="!contact.notes" style="margin-bottom: 10px">
        <b>Details: </b><span [style.color]="'#999999'">--</span>
      </div>
    </div>
  </mat-card-content>
</div>
