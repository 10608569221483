import { Action } from '@ngrx/store';

export enum WhatsNewActionTypes {
  LoadWhatsNew = '[WhatsNew] Load Whats New'
}

export class LoadWhatsNew implements Action {
  readonly type = WhatsNewActionTypes.LoadWhatsNew;
}

export type WhatsNewActions = LoadWhatsNew;
