import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { callNotesReducer } from './call-notes.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CallNotesEffects } from './call-notes.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature('callNotes', callNotesReducer),
    EffectsModule.forFeature([CallNotesEffects])
  ]
})
export class CallNotesStateModule {}
