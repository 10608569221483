import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import {
  CallTypesActionTypes,
  CallTypesActions,
  LoadCallTypesSuccess,
  LoadCallTypesFailure,
  CreateCallTypesSuccess,
  CreateCallTypes,
  UpdateCallTypes,
  UpdateCallTypesSuccess,
  UpdateCallTypesFailure,
  CreateCallTypesFailure,
  DeleteCallTypes,
  DeleteCallTypesSuccess,
  DeleteCallTypesFailure
} from './call-types.actions';
import { Action } from '@ngrx/store';
import { CallType } from 'src/app/models/admin/call-type/call-type';
import { HttpErrorResponse } from '@angular/common/http';
import { CallTypesService } from '../../../services/system-settings/call-types.service';

@Injectable()
export class CallTypesEffects {
  loadCallTypes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CallTypesActionTypes.LoadCallTypes),
      mergeMap(() =>
        this.callTypesService.getAll().pipe(
          map((callTypes: CallType[]) => new LoadCallTypesSuccess(callTypes)),
          catchError((error: HttpErrorResponse) => of(new LoadCallTypesFailure(error)))
        )
      )
    )
  );

  createCallTypes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CallTypesActionTypes.CreateCallTypes),
      map((action: CreateCallTypes) => action.payload),
      mergeMap((callType: CallType) =>
        this.callTypesService.create(callType).pipe(
          map((newCallType: CallType) => new CreateCallTypesSuccess(newCallType)),
          catchError((error: HttpErrorResponse) => of(new CreateCallTypesFailure(error)))
        )
      )
    )
  );

  updateCallTypes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CallTypesActionTypes.UpdateCallTypes),
      map((action: UpdateCallTypes) => action.payload),
      mergeMap((callType: CallType) => {
        return this.callTypesService.update(callType).pipe(
          map((updatedCallType: CallType) => new UpdateCallTypesSuccess(updatedCallType)),
          catchError((error: HttpErrorResponse) => of(new UpdateCallTypesFailure(error)))
        );
      })
    )
  );

  deleteCallTypes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CallTypesActionTypes.DeleteCallTypes),
      map((action: DeleteCallTypes) => action.payload),
      mergeMap((callTypeId: number) =>
        this.callTypesService.delete(callTypeId).pipe(
          map(() => new DeleteCallTypesSuccess(callTypeId)),
          catchError((error: HttpErrorResponse) => of(new DeleteCallTypesFailure(error)))
        )
      )
    )
  );

  constructor(
    private callTypesService: CallTypesService,
    private actions$: Actions<CallTypesActions>
  ) {}
}
