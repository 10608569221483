import { Component, OnInit, OnDestroy } from '@angular/core';
import { Territory } from 'src/app/models/admin/territories/territory';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { Company } from 'src/app/models/companies/company';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import { LoadTerritories } from 'src/app/store/territory-management/territory-management.actions';
import { TerritoriesMap } from 'src/app/models/admin/territories/territories-map';
import { ActivatedRoute } from '@angular/router';
import { CompanyAssignment } from 'src/app/models/sales/companyAssignment';
import { SetCompanyAssignment } from 'src/app/store/sales/sales.actions';
import { selectCurrentCompany } from 'src/app/store/companies/selectors/current-company.selector';
import { selectCurrentCompanyAssignments } from 'src/app/store/sales/selectors/current-company-assignments.selector';
import { CompanyPermissions } from 'src/app/models/companies/company-permissions';
import { selectCompanyPermissions } from 'src/app/store/companies/selectors/company-permissions.selector';

@Component({
  selector: 'tn-company-sales-reps',
  templateUrl: './company-sales-reps.component.html',
  styleUrls: ['./company-sales-reps.component.scss']
})
export class CompanySalesRepsComponent implements OnInit, OnDestroy {
  $permissions: Observable<CompanyPermissions>;
  $company: Observable<Company>;
  $companySub: Subscription;
  $companyAssignments: Observable<CompanyAssignment[]>;
  $pending: Observable<boolean>;
  company: Company;
  $territories: Observable<TerritoriesMap>;
  territories: Territory[];
  companyTerritories: Territory[] = [];
  filteredTerritories: Territory[] = [];

  companyId;

  constructor(private store: Store<RootState>, private route: ActivatedRoute) {
    this.companyId = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit() {
    this.store.dispatch(new LoadTerritories());
    this.$permissions = this.store.select(selectCompanyPermissions);
    this.$company = this.store.select(selectCurrentCompany);
    this.$territories = this.store.select('territoryManagement', 'territories', 'data');
    this.$companyAssignments = this.store.select(selectCurrentCompanyAssignments);
    this.$companySub = combineLatest([
      this.$company,
      this.$companyAssignments,
      this.$territories
    ]).subscribe(([comp, compAssignments, territories]) => {
      if (!!comp && !!compAssignments && !!territories) {
        this.company = comp;
        this.territories = Object.values(territories).sort((a, b) => {
          const textA = a.name.toLowerCase();
          const textB = b.name.toLowerCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        this.territories = Object.values(territories)
          .sort((a, b) => {
            const textA = a.name.toLowerCase();
            const textB = b.name.toLowerCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          })
          .filter((terr) => comp.territories.map((cTerr) => cTerr.id).includes(terr.id))
          .map((terr) => {
            return {
              ...terr,
              users: terr.users
                .sort((a, b) => {
                  const textA = a.name.toLowerCase();
                  const textB = b.name.toLowerCase();
                  return textA < textB ? -1 : textA > textB ? 1 : 0;
                })
                .map((tu) => {
                  const compAssignmentsArr = compAssignments
                    .filter((ca) => ca.associateSales === true)
                    .map((ca) => ca.AgentMailboxId);
                  return {
                    ...tu,
                    associateSales: compAssignmentsArr.includes(tu.mailbox)
                  };
                })
            };
          });
        this.filteredTerritories = this.territories;
      }
    });
  }

  ngOnDestroy() {
    this.$companySub.unsubscribe();
  }

  applyFilter(query: Event) {
    this.filteredTerritories = query
      ? this.territories.map((terr: Territory) => {
          return {
            ...terr,
            users: terr.users.filter((user) =>
              user.name
                .toString()
                .toLocaleLowerCase()
                .includes(query.toString().toLocaleLowerCase())
            )
          };
        })
      : this.territories;
  }

  onChange(user, $event) {
    const payload = {
      companyId: this.company.id,
      agentMailboxId: user.mailbox,
      associateSales: $event.checked
    };
    this.store.dispatch(new SetCompanyAssignment(payload));
  }
}
