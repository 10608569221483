<form [formGroup]="companyLabelForm" class="example-form">
  <mat-form-field class="example-full-width">
    <mat-label>Name</mat-label>
    <input formControlName="name" matInput type="string" required />
  </mat-form-field>

  <mat-form-field class="example-full-width">
    <mat-label>Description</mat-label>
    <textarea matInput formControlName="description" tnAutosizeTextarea></textarea>
  </mat-form-field>

  <div fxLayout="column" fxLayoutGap="12px" style="margin-bottom: 24px">
    <mat-checkbox formControlName="required">Required</mat-checkbox>
    <mat-checkbox formControlName="allowMultiple">Allow Multiple Selections</mat-checkbox>
  </div>

  <div *ngIf="currentCompanyLabel?.id">
    <div fxLayout="row" style="margin-left: 10px; margin-right: 5px">
      <div fxFlex="85"><i>Labels</i></div>
      <div fxFlex="10">
        <button
          mat-icon-button
          class="add-label-nav-button"
          color="primary"
          [matMenuTriggerFor]="addLabelMenu"
          #addLabelMenuTrigger
          (menuOpened)="menuOpened()"
        >
          <i class="material-icons" style="cursor: pointer"> add_circle </i>
        </button>

        <mat-menu class="label-select-menu" #addLabelMenu="matMenu" xPosition="before">
          <form
            [formGroup]="companyLabelIndividualForm"
            class="menu-form"
            (click)="$event.stopPropagation()"
          >
            <div fxLayout="row" fxLayoutAlign="space-between">
              <mat-form-field>
                <mat-label>Label Name</mat-label>
                <input
                  matInput
                  cdkFocusInitial
                  [value]="currentCompanyLabelIndividual?.name"
                  formControlName="name"
                  type="string"
                />
              </mat-form-field>
              <div mat-dialog-actions>
                <button
                  *ngIf="!companyLabelIndividualForm.controls.id.value"
                  mat-icon-button
                  color="primary"
                  (click)="saveIndividual()"
                  [disabled]="
                    companyLabelIndividualForm.pristine ||
                    companyLabelIndividualForm.invalid
                  "
                >
                  <mat-icon>check</mat-icon>
                </button>
              </div>
              <div mat-dialog-actions>
                <button
                  *ngIf="companyLabelIndividualForm.controls.id.value"
                  mat-icon-button
                  color="primary"
                  (click)="editIndividual()"
                  [disabled]="
                    companyLabelIndividualForm.pristine ||
                    companyLabelIndividualForm.invalid
                  "
                >
                  <mat-icon>check</mat-icon>
                </button>
              </div>
            </div>
          </form>
        </mat-menu>
      </div>
    </div>
    <div
      *ngFor="let companyLabelIndividual of companyLabelsIndividual"
      style="
        background-color: whitesmoke;
        border-bottom: 1px #555;
        margin-top: 7px;
        padding: 5px 5px 5px 10px;
        border-radius: 6px;
        cursor: pointer;
      "
    >
      <div fxLayout="row" fxLayoutAlign="center center">
        <div
          fxFlex="80"
          [matMenuTriggerFor]="addLabelMenu"
          #addLabelMenuTrigger
          (menuOpened)="menuOpenedEdit(companyLabelIndividual)"
        >
          {{ companyLabelIndividual.name }}
        </div>
        <div fxFlex="10" (click)="deleteLabel(companyLabelIndividual)">
          <i class="material-icons">delete</i>
        </div>
      </div>
    </div>
  </div>

  <div style="margin-top: 5px" fxLayout="row-reverse" fxLayoutAlign="space-between end">
    <div fxLayout="row-reverse">
      <button
        *ngIf="!currentCompanyLabel?.id"
        mat-raised-button
        color="primary"
        mat-button
        class="button-container"
        (click)="save()"
        [disabled]="companyLabelForm.pristine || companyLabelForm.invalid"
      >
        Save
      </button>
      <button
        *ngIf="currentCompanyLabel?.id"
        mat-raised-button
        mat-button
        color="primary"
        class="button-container"
        (click)="update()"
        [disabled]="companyLabelForm.pristine || companyLabelForm.invalid"
      >
        Save
      </button>
      <button
        preventDefaultAction
        class="button-container"
        mat-raised-button
        color="white"
        mat-button
        (click)="cancel()"
      >
        Cancel
      </button>
    </div>
    <div>
      <button
        preventDefaultAction
        *ngIf="currentCompanyLabel?.id"
        mat-raised-button
        color="warn"
        mat-button
        (click)="delete()"
      >
        Delete
      </button>
    </div>
  </div>
</form>
