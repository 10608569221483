import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PageableData, PrivateNote } from 'src/app/models/private-notes/private-note';
import { environment } from 'src/environments/environment';
import { ServicesModule } from '../services.module';
import { User } from 'src/app/models/admin/users/user';

@Injectable({
  providedIn: ServicesModule
})
export class PrivateNotesService {
  private jpiUrl = environment.jpiUrl;

  constructor(private http: HttpClient) {}

  getPrivateNotes(limit: number, offset: number): Observable<PageableData<PrivateNote>> {
    return this.http.get(
      `${this.jpiUrl}/private_note/${offset}/${limit}/createdDateMillis/desc`
    ) as Observable<PageableData<PrivateNote>>;
  }

  getPrivateNotesByCompanyId(companyId: number, limit: number, offset: number) {
    return this.http.get(
      `${this.jpiUrl}/private_note/company/${companyId}/${offset}/${limit}/createdDateMillis/desc`
    ) as Observable<PageableData<PrivateNote>>;
  }

  getPrivateNotesAssignableUsers(): Observable<User[]> {
    return this.http.get(`${this.jpiUrl}/private_note/assignable_users`) as Observable<
      User[]
    >;
  }

  savePrivateNote(privateNote: PrivateNote): Observable<PrivateNote> {
    return this.http.post(
      `${this.jpiUrl}/private_note`,
      privateNote
    ) as Observable<PrivateNote>;
  }

  updatePrivateNoteById(privateNote: PrivateNote): Observable<PrivateNote> {
    return this.http.put(
      `${this.jpiUrl}/private_note/${privateNote.id}`,
      privateNote
    ) as Observable<PrivateNote>;
  }

  deletePrivateNoteById(id: number): Observable<PrivateNote> {
    return this.http.delete(
      `${this.jpiUrl}/private_note/${id}`
    ) as Observable<PrivateNote>;
  }
}
