import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { GlobalFilter } from 'src/app/models/global-filter';
import { CompanySelectItem } from 'src/app/models/companies/company-select';
import { Contact } from 'src/app/models/contacts/contact';
import { Company } from 'src/app/models/companies/company';
import { GenericMap } from 'src/app/models/generic-map';
import { FileMetaData } from 'src/app/models/file-meta-data';
import { DMS } from 'src/app/models/companies/dms';
import { CompanyLink } from 'src/app/models/companies/company-link';
import { DmsLinkRequest } from 'src/app/models/companies/dms-link-request';
import { DMSExactMatch } from 'src/app/models/companies/dms-exact-match';

export enum CompaniesActionTypes {
  LoadCompany = '[Companies] Load Company',
  LoadCompanySuccess = '[Companies] Load Company Success',
  LoadCompanyFailure = '[Companies] Load Company Failure',

  LoadCompanies = '[Companies] Load Companies',
  LoadCompaniesSuccess = '[Companies] Load Companies Success',
  LoadCompaniesFailure = '[Companies] Load Companies Failure',

  CreateCompany = '[Companies] Create Company',
  CreateCompanySuccess = '[Companies] Create Company Success',
  CreateCompanyFailure = '[Companies] Create Company Failure',

  UpdateCompany = '[Companies] Update Company',
  UpdateCompanySuccess = '[Companies] Update Company Success',
  UpdateCompanyFailure = '[Companies] Update Company Failure',

  ClearCompanies = '[Companies] Clear Companies',

  ClearSearchCompanies = '[Companies] Clear Search Companies',

  SelectSearchedCompany = '[Companies] Select Searched Company',

  SearchCompanies = '[Companies] Search Companies',
  SearchCompaniesSuccess = '[Companies] Search Companies Success',
  SearchCompaniesFailure = '[Companies] Search Companies Failure',

  LoadCompanyContacts = '[Companies] Load Company Contacts',
  LoadCompanyContactsSuccess = '[Companies] Load Company Contacts Success',
  LoadCompanyContactsFailure = '[Companies] Load Company Contacts Failure',

  SaveContact = '[Companies] Save Contact',
  SaveContactSuccess = '[Companies] Save Contact Success',
  SaveContactFailure = '[Companies] Save Contact Failure',

  DeleteContact = '[Companies] Delete Contact',
  DeleteContactSuccess = '[Companies] Delete Contact Success',
  DeleteContactFailure = '[Companies] Delete Contact Failure',

  SearchContactsSuccess = '[Companies] Search Contacts Success',

  SelectCompanyAndContactId = '[Companies] Select Company And Contact ID',

  LoadCompanyFiles = '[Companies] Load Company Files',
  LoadCompanyFilesSuccess = '[Companies] Load Company Files Success',
  LoadCompanyFilesFailure = '[Companies] Load Company Files Failure',

  UploadCompanyFile = '[Companies] Upload Company File',
  UploadCompanyFileSuccess = '[Companies] Upload Company File Success',
  UploadCompanyFileFailure = '[Companies] Upload Company File Failure',

  UploadCompanyFileByCompany = '[Companies] Upload Company File By Company',
  UploadCompanyFileByCompanySuccess = '[Companies] Upload Company File By Company Success',
  UploadCompanyFileByCompanyFailure = '[Companies] Upload Company File By Company Failure',

  DeleteCompanyFile = '[Companies] Delete Company File',
  DeleteCompanyFileSuccess = '[Companies] Delete Company File Success',
  DeleteCompanyFileFailure = '[Companies] Delete Company File Failure',

  LoadDmsLinkedCompanies = '[Companies] Load DMS Linked Companies',
  LoadDmsLinkedCompaniesSuccess = '[Companies] Load DMS Linked Companies Success',
  LoadDmsLinkedCompaniesFailure = '[Companies] Load DMS Linked Companies Failure',

  LoadDmsExactMatches = '[Companies] Load DMS Exact Matches',
  LoadDmsExactMatchesSuccess = '[Companies] Load DMS Exact Matches Success',
  LoadDmsExactMatchesFailure = '[Companies] Load DMS Exact Matches Failure',

  LoadDmsUnlinkedCompanies = '[Companies] Load DMS Unlinked Companies',
  LoadDmsUnlinkedCompaniesSuccess = '[Companies] Load DMS Unlinked Companies Success',
  LoadDmsUnlinkedCompaniesFailure = '[Companies] Load DMS Unlinked Companies Failure',

  LoadDmsLinkedCompany = '[Companies] Load DMS Linked Company',
  LoadDmsLinkedCompanySuccess = '[Companies] Load DMS Linked Company Success',
  LoadDmsLinkedCompanyFailure = '[Companies] Load DMS Linked Company Failure',

  LinkDmsCompany = '[Companies] Link DMS Company',
  LinkDmsCompanySuccess = '[Companies] Link DMS Company Success',
  LinkDmsCompanyFailure = '[Companies] Link DMS Company Failure',

  UnlinkDmsCompany = '[Companies] Unlink DMS Company',
  UnlinkDmsCompanySuccess = '[Companies] Unlink DMS Company Success',
  UnlinkDmsCompanyFailure = '[Companies] Unlink DMS Company Failure',

  BulkLinkDmsCompanies = '[Companies] Bulk Link DMS Companies',
  BulkLinkDmsCompaniesSuccess = '[Companies] Bulk Link DMS Company Success',
  BulkLinkDmsCompaniesFailure = '[Companies] Bulk Link DMS Company Failure',

  DeleteCompany = '[Companies] Delete Company',
  DeleteCompanySuccess = '[Companies] Delete Company Success',
  DeleteCompanyFailure = '[Companies] Delete Company Failure',

  ToggleAssignedRepsPanel = '[Companies] Toggle Assigned Reps Panel',
  ToggleContactsPanel = '[Companies] Toggle Contacts Panel',
  ToggleCompanyDetailsPanel = '[Companies] Toggle Company Details Panel',

  SaveChildCompanies = '[Companies] Save Child Companies',
  SaveChildCompaniesSuccess = '[Companies] Save Child Companies Success',
  SaveChildCompaniesFailure = '[Companies] Save Child Companies Failure',

  AddNewCompanyContact = '[Companies] Add New Company Contact',
  RemoveNewCompanyContact = '[Companies] Remove New Company Contact',
  EditNewCompanyContact = '[Companies] Edit New Company Contact',
  ClearNewCompanyContacts = '[Companies] Clear New Company Contacts',
  CreateCompanyWithContacts = '[Companies] Create Company With Contacts',
  NavigateToNewCompany = '[Companies] Navigate To New Company'
}

export class LoadCompany implements Action {
  readonly type = CompaniesActionTypes.LoadCompany;
  constructor(public payload: number) {}
}

export class LoadCompanySuccess implements Action {
  readonly type = CompaniesActionTypes.LoadCompanySuccess;
  constructor(public payload: Company) {}
}

export class LoadCompanyFailure implements Action {
  readonly type = CompaniesActionTypes.LoadCompanyFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadCompanies implements Action {
  readonly type = CompaniesActionTypes.LoadCompanies;
  constructor(public payload?: GlobalFilter) {}
}

export class LoadCompaniesSuccess implements Action {
  readonly type = CompaniesActionTypes.LoadCompaniesSuccess;
  constructor(public payload: GenericMap<Company>) {}
}

export class LoadCompaniesFailure implements Action {
  readonly type = CompaniesActionTypes.LoadCompaniesFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class CreateCompany implements Action {
  readonly type = CompaniesActionTypes.CreateCompany;
  constructor(public payload: Company) {}
}

export class CreateCompanySuccess implements Action {
  readonly type = CompaniesActionTypes.CreateCompanySuccess;
  constructor(public payload: Company) {}
}

export class CreateCompanyFailure implements Action {
  readonly type = CompaniesActionTypes.CreateCompanyFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class UpdateCompany implements Action {
  readonly type = CompaniesActionTypes.UpdateCompany;
  constructor(public payload: Company) {}
}

export class UpdateCompanySuccess implements Action {
  readonly type = CompaniesActionTypes.UpdateCompanySuccess;
  constructor(public payload: Company) {}
}

export class UpdateCompanyFailure implements Action {
  readonly type = CompaniesActionTypes.UpdateCompanyFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class ClearCompanies implements Action {
  readonly type = CompaniesActionTypes.ClearCompanies;
}

export class ClearSearchCompanies implements Action {
  readonly type = CompaniesActionTypes.ClearSearchCompanies;
}

export class SelectSearchedCompany implements Action {
  readonly type = CompaniesActionTypes.SelectSearchedCompany;
  constructor(public payload: number) {}
}

export class SearchCompanies implements Action {
  readonly type = CompaniesActionTypes.SearchCompanies;
  constructor(public payload: GlobalFilter) {}
}

export class SearchCompaniesSuccess implements Action {
  readonly type = CompaniesActionTypes.SearchCompaniesSuccess;
  constructor(public payload: CompanySelectItem[]) {}
}

export class SearchCompaniesFailure implements Action {
  readonly type = CompaniesActionTypes.SearchCompaniesFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadCompanyContacts implements Action {
  readonly type = CompaniesActionTypes.LoadCompanyContacts;
  constructor(public payload: number) {}
}

export class LoadCompanyContactsSuccess implements Action {
  readonly type = CompaniesActionTypes.LoadCompanyContactsSuccess;
  constructor(
    public payload: { companyId: number; contacts: { [contactId: number]: Contact } }
  ) {}
}

export class LoadCompanyContactsFailure implements Action {
  readonly type = CompaniesActionTypes.LoadCompanyContactsFailure;
  constructor(public payload: { companyId: number; error: HttpErrorResponse }) {}
}

export class SaveContact implements Action {
  readonly type = CompaniesActionTypes.SaveContact;
  constructor(public payload: { contact: Contact; companyId?: number }) {}
}

export class SaveContactSuccess implements Action {
  readonly type = CompaniesActionTypes.SaveContactSuccess;
  constructor(public payload: Contact) {}
}

export class SaveContactFailure implements Action {
  readonly type = CompaniesActionTypes.SaveContactFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class DeleteContact implements Action {
  readonly type = CompaniesActionTypes.DeleteContact;
  constructor(public payload: Contact) {}
}

export class DeleteContactSuccess implements Action {
  readonly type = CompaniesActionTypes.DeleteContactSuccess;
  constructor(public payload: Contact) {}
}

export class DeleteContactFailure implements Action {
  readonly type = CompaniesActionTypes.DeleteContactFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class SearchContactsSuccess implements Action {
  readonly type = CompaniesActionTypes.SearchContactsSuccess;
  constructor(public payload: GenericMap<GenericMap<Contact>>) {}
}

export class SelectCompanyAndContactId implements Action {
  readonly type = CompaniesActionTypes.SelectCompanyAndContactId;
  constructor(public payload: { companyId: number; contactId: number }) {}
}

export class LoadCompanyFiles implements Action {
  readonly type = CompaniesActionTypes.LoadCompanyFiles;
  constructor(public payload: number) {}
}

export class LoadCompanyFilesSuccess implements Action {
  readonly type = CompaniesActionTypes.LoadCompanyFilesSuccess;
  constructor(public payload: { agencyId: number; files: FileMetaData[] }) {}
}

export class LoadCompanyFilesFailure implements Action {
  readonly type = CompaniesActionTypes.LoadCompanyFilesFailure;
  constructor(public payload: { agencyId: number; error: HttpErrorResponse }) {}
}

export class UploadCompanyFile implements Action {
  readonly type = CompaniesActionTypes.UploadCompanyFile;
  constructor(public payload: Blob) {}
}

export class UploadCompanyFileSuccess implements Action {
  readonly type = CompaniesActionTypes.UploadCompanyFileSuccess;
  constructor(public payload: FileMetaData) {}
}

export class UploadCompanyFileFailure implements Action {
  readonly type = CompaniesActionTypes.UploadCompanyFileFailure;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class UploadCompanyFileByCompany implements Action {
  readonly type = CompaniesActionTypes.UploadCompanyFileByCompany;
  constructor(public payload: Blob) {}
}

export class UploadCompanyFileByCompanySuccess implements Action {
  readonly type = CompaniesActionTypes.UploadCompanyFileByCompanySuccess;
  constructor(public payload: FileMetaData) {}
}

export class UploadCompanyFileByCompanyFailure implements Action {
  readonly type = CompaniesActionTypes.UploadCompanyFileByCompanyFailure;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class DeleteCompanyFile implements Action {
  readonly type = CompaniesActionTypes.DeleteCompanyFile;
  constructor(public payload: number) {}
}

export class DeleteCompanyFileSuccess implements Action {
  readonly type = CompaniesActionTypes.DeleteCompanyFileSuccess;
  constructor(public payload: FileMetaData) {}
}

export class DeleteCompanyFileFailure implements Action {
  readonly type = CompaniesActionTypes.DeleteCompanyFileFailure;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class LoadDmsLinkedCompanies implements Action {
  readonly type = CompaniesActionTypes.LoadDmsLinkedCompanies;
}

export class LoadDmsLinkedCompaniesSuccess implements Action {
  readonly type = CompaniesActionTypes.LoadDmsLinkedCompaniesSuccess;
  constructor(public payload: DMS[]) {}
}

export class LoadDmsLinkedCompaniesFailure implements Action {
  readonly type = CompaniesActionTypes.LoadDmsLinkedCompaniesFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadDmsExactMatches implements Action {
  readonly type = CompaniesActionTypes.LoadDmsExactMatches;
}

export class LoadDmsExactMatchesSuccess implements Action {
  readonly type = CompaniesActionTypes.LoadDmsExactMatchesSuccess;
  constructor(public payload: DMSExactMatch[]) {}
}

export class LoadDmsExactMatchesFailure implements Action {
  readonly type = CompaniesActionTypes.LoadDmsExactMatchesFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadDmsUnlinkedCompanies implements Action {
  readonly type = CompaniesActionTypes.LoadDmsUnlinkedCompanies;
}

export class LoadDmsUnlinkedCompaniesSuccess implements Action {
  readonly type = CompaniesActionTypes.LoadDmsUnlinkedCompaniesSuccess;
  constructor(public payload: DMS[]) {}
}

export class LoadDmsUnlinkedCompaniesFailure implements Action {
  readonly type = CompaniesActionTypes.LoadDmsUnlinkedCompaniesFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadDmsLinkedCompany implements Action {
  readonly type = CompaniesActionTypes.LoadDmsLinkedCompany;
  constructor(public payload: { dmsId: string; date: string }) {}
}

export class LoadDmsLinkedCompanySuccess implements Action {
  readonly type = CompaniesActionTypes.LoadDmsLinkedCompanySuccess;
  constructor(public payload: DMS) {}
}

export class LoadDmsLinkedCompanyFailure implements Action {
  readonly type = CompaniesActionTypes.LoadDmsLinkedCompanyFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class LinkDmsCompany implements Action {
  readonly type = CompaniesActionTypes.LinkDmsCompany;
  constructor(
    public payload: { dmsLinkRequest: DmsLinkRequest; companyUpdate: Company }
  ) {}
}

export class LinkDmsCompanySuccess implements Action {
  readonly type = CompaniesActionTypes.LinkDmsCompanySuccess;
  constructor(public payload: DmsLinkRequest) {}
}

export class LinkDmsCompanyFailure implements Action {
  readonly type = CompaniesActionTypes.LinkDmsCompanyFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class UnlinkDmsCompany implements Action {
  readonly type = CompaniesActionTypes.UnlinkDmsCompany;
  constructor(
    public payload: { dmsLinkRequest: DmsLinkRequest; companyUpdate: Company }
  ) {}
}

export class UnlinkDmsCompanySuccess implements Action {
  readonly type = CompaniesActionTypes.UnlinkDmsCompanySuccess;
  constructor(public payload: DmsLinkRequest) {}
}

export class UnlinkDmsCompanyFailure implements Action {
  readonly type = CompaniesActionTypes.UnlinkDmsCompanyFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class BulkLinkDmsCompanies implements Action {
  readonly type = CompaniesActionTypes.BulkLinkDmsCompanies;
  constructor(public payload: DmsLinkRequest[]) {}
}

export class BulkLinkDmsCompaniesSuccess implements Action {
  readonly type = CompaniesActionTypes.BulkLinkDmsCompaniesSuccess;
  constructor(public payload: DmsLinkRequest[]) {}
}

export class BulkLinkDmsCompaniesFailure implements Action {
  readonly type = CompaniesActionTypes.BulkLinkDmsCompaniesFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class DeleteCompany implements Action {
  readonly type = CompaniesActionTypes.DeleteCompany;
  constructor(public payload: number) {}
}

export class DeleteCompanySuccess implements Action {
  readonly type = CompaniesActionTypes.DeleteCompanySuccess;
  constructor(public payload: number) {}
}

export class DeleteCompanyFailure implements Action {
  readonly type = CompaniesActionTypes.DeleteCompanyFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class ToggleAssignedRepsPanel implements Action {
  readonly type = CompaniesActionTypes.ToggleAssignedRepsPanel;
}

export class ToggleContactsPanel implements Action {
  readonly type = CompaniesActionTypes.ToggleContactsPanel;
}

export class ToggleCompanyDetailsPanel implements Action {
  readonly type = CompaniesActionTypes.ToggleCompanyDetailsPanel;
}

export class SaveChildCompanies implements Action {
  readonly type = CompaniesActionTypes.SaveChildCompanies;
  constructor(public payload: { companyId: number; childCompanies: CompanyLink[] }) {}
}

export class SaveChildCompaniesSuccess implements Action {
  readonly type = CompaniesActionTypes.SaveChildCompaniesSuccess;
  constructor(public payload: { companyId: number; childCompanies: CompanyLink[] }) {}
}

export class SaveChildCompaniesFailure implements Action {
  readonly type = CompaniesActionTypes.SaveChildCompaniesFailure;
  constructor(public payload: { companyId: number; error: HttpErrorResponse }) {}
}

export class AddNewCompanyContact implements Action {
  readonly type = CompaniesActionTypes.AddNewCompanyContact;
  constructor(public payload: Contact) {}
}

export class RemoveNewCompanyContact implements Action {
  readonly type = CompaniesActionTypes.RemoveNewCompanyContact;
  constructor(public payload: number) {}
}

export class EditNewCompanyContact implements Action {
  readonly type = CompaniesActionTypes.EditNewCompanyContact;
  constructor(public payload: { contact: Contact; index: number }) {}
}

export class ClearNewCompanyContacts implements Action {
  readonly type = CompaniesActionTypes.ClearNewCompanyContacts;
}

export class CreateCompanyWithContacts implements Action {
  readonly type = CompaniesActionTypes.CreateCompanyWithContacts;
  constructor(public payload: { company: Company; redirect: boolean }) {}
}

export class NavigateToNewCompany implements Action {
  readonly type = CompaniesActionTypes.NavigateToNewCompany;
  constructor(public payload: number) {}
}

export type CompaniesActions =
  | LoadCompany
  | LoadCompanySuccess
  | LoadCompanyFailure
  | LoadCompanies
  | LoadCompaniesSuccess
  | LoadCompaniesFailure
  | CreateCompany
  | CreateCompanySuccess
  | CreateCompanyFailure
  | UpdateCompany
  | UpdateCompanySuccess
  | UpdateCompanyFailure
  | ClearCompanies
  | ClearSearchCompanies
  | SelectSearchedCompany
  | SearchCompanies
  | SearchCompaniesSuccess
  | SearchCompaniesFailure
  | LoadCompanyContacts
  | LoadCompanyContactsSuccess
  | LoadCompanyContactsFailure
  | SaveContact
  | SaveContactSuccess
  | SaveContactFailure
  | DeleteContact
  | DeleteContactSuccess
  | DeleteContactFailure
  | SearchContactsSuccess
  | SelectCompanyAndContactId
  | LoadCompanyFiles
  | LoadCompanyFilesSuccess
  | LoadCompanyFilesFailure
  | UploadCompanyFile
  | UploadCompanyFileSuccess
  | UploadCompanyFileFailure
  | UploadCompanyFileByCompany
  | UploadCompanyFileByCompanySuccess
  | UploadCompanyFileByCompanyFailure
  | DeleteCompanyFile
  | DeleteCompanyFileSuccess
  | DeleteCompanyFileFailure
  | LoadDmsLinkedCompanies
  | LoadDmsLinkedCompaniesSuccess
  | LoadDmsLinkedCompaniesFailure
  | LoadDmsExactMatches
  | LoadDmsExactMatchesSuccess
  | LoadDmsExactMatchesFailure
  | LoadDmsUnlinkedCompanies
  | LoadDmsUnlinkedCompaniesSuccess
  | LoadDmsUnlinkedCompaniesFailure
  | LoadDmsLinkedCompany
  | LoadDmsLinkedCompanySuccess
  | LoadDmsLinkedCompanyFailure
  | LinkDmsCompany
  | LinkDmsCompanySuccess
  | LinkDmsCompanyFailure
  | UnlinkDmsCompany
  | UnlinkDmsCompanySuccess
  | UnlinkDmsCompanyFailure
  | BulkLinkDmsCompanies
  | BulkLinkDmsCompaniesSuccess
  | BulkLinkDmsCompaniesFailure
  | DeleteCompany
  | DeleteCompanySuccess
  | DeleteCompanyFailure
  | ToggleAssignedRepsPanel
  | ToggleContactsPanel
  | ToggleCompanyDetailsPanel
  | SaveChildCompanies
  | SaveChildCompaniesSuccess
  | SaveChildCompaniesFailure
  | AddNewCompanyContact
  | RemoveNewCompanyContact
  | EditNewCompanyContact
  | ClearNewCompanyContacts
  | CreateCompanyWithContacts
  | NavigateToNewCompany;
