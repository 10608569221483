import { MemoizedSelector, createSelector } from '@ngrx/store';
import { RootState } from '../../../store.reducer';
import { ProjectBid } from 'src/app/models/admin/projects/project-bid';
import { selectAllProjectBids } from './all-projectBids-as-array.selector';
import { GenericMap } from 'src/app/models/generic-map';

export const selectedProjectBidId = (state: RootState) =>
  state.projectBids.selectedProjectBidId;

export const selectSelectedProjectBid: MemoizedSelector<
  RootState,
  ProjectBid
> = createSelector(
  selectAllProjectBids,
  selectedProjectBidId,
  (projectBidMap: GenericMap<ProjectBid>, projectBidId: number): ProjectBid => {
    let projectBid: ProjectBid;
    if (!!projectBidId && projectBidMap[projectBidId]) {
      const id = projectBidId.toString();
      projectBid = {
        ...projectBidMap[id]
      };
    }
    return projectBid;
  }
);
