import { PlansActions, PlansActionTypes } from './plans.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { Plans } from 'src/app/models/admin/plans/plans';
import { GenericMap } from 'src/app/models/generic-map';

export interface PlansState {
  pending: boolean;
  error: HttpErrorResponse;
  data: GenericMap<Plans>;
  selectedPlanId: number;
}

export const initialState: PlansState = {
  pending: false,
  data: {},
  error: undefined,
  selectedPlanId: undefined
};

export function plansReducer(state = initialState, action: PlansActions): PlansState {
  switch (action.type) {
    case PlansActionTypes.SelectPlanId:
      return {
        ...state,
        selectedPlanId: action.payload
      };

    case PlansActionTypes.ClearPlanId:
      return {
        ...state,
        selectedPlanId: undefined
      };

    case PlansActionTypes.LoadPlans:
      return {
        ...state,
        pending: true
      };

    case PlansActionTypes.LoadPlansSuccess:
      return {
        ...state,
        pending: false,
        data: action.payload
      };

    case PlansActionTypes.LoadPlansFailure:
      return {
        ...state,
        pending: false,
        data: {},
        error: action.payload
      };

    case PlansActionTypes.CreatePlans:
      return {
        ...state,
        pending: true
      };

    case PlansActionTypes.CreatePlansSuccess:
      return {
        ...state,
        pending: false
      };

    case PlansActionTypes.CreatePlansFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case PlansActionTypes.UpdatePlan:
      return { ...state, pending: true };

    case PlansActionTypes.UpdatePlanSuccess:
      return {
        ...state,
        pending: false
      };

    case PlansActionTypes.UpdatePlanFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case PlansActionTypes.DeletePlans:
      return {
        ...state,
        pending: true
      };

    case PlansActionTypes.DeletePlansSuccess:
      return {
        ...state,
        pending: false,
        selectedPlanId: undefined
      };

    case PlansActionTypes.DeletePlansFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    default:
      return state;
  }
}
