import { Injectable } from '@angular/core';
import { CompanyField } from 'src/app/models/companies/company-field';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyCustomFieldsService {
  private jpiUrl = environment.jpiUrl;
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get(`${this.jpiUrl}/companyfields`);
  }

  getById(companyFieldId: number) {
    return this.http.get(`${this.jpiUrl}/companyfields/${companyFieldId}`);
  }

  create(companyField: CompanyField) {
    return this.http.post(`${this.jpiUrl}/companyfields`, companyField);
  }

  update(companyField: CompanyField) {
    return this.http.put(`${this.jpiUrl}/companyfields/${companyField.id}`, companyField);
  }

  delete(companyFieldId: number) {
    return this.http.delete(`${this.jpiUrl}/companyfields/${companyFieldId}`);
  }
}
