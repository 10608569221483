import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import {
  LoadCallNoteTypesFailure,
  LoadCallNoteTypesSuccess,
  CallNoteTypesActionTypes,
  CallNoteTypesActions
} from './call-note-types.actions';
import { CallNoteTypesService } from 'src/app/services/call-note-types/call-note-types.service';
import { CallNoteType } from 'src/app/models/call-notes/call-note-type';
import { convertToMap } from 'src/app/utils/convertToMap';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class CallNoteTypesEffects {
  constructor(
    private actions$: Actions<CallNoteTypesActions>,
    private cntService: CallNoteTypesService
  ) {}

  loadCallNoteTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CallNoteTypesActionTypes.LoadCallNoteTypes),
      concatMap(() =>
        this.cntService.getAll().pipe(
          map(
            (res: CallNoteType[]) => new LoadCallNoteTypesSuccess(convertToMap(res, 'id'))
          ),
          catchError((error: HttpErrorResponse) =>
            of(new LoadCallNoteTypesFailure(error))
          )
        )
      )
    )
  );
}
