import { CompanyLabelGroupService } from './../../services/admin/customization/company-label-group.service';
import { Component, Input, OnInit } from '@angular/core';

import { CompanyLabel } from '../../models/admin/company-labels/company-label';
import { CompanyLabelGroup } from '../../models/admin/company-labels/company-label-group';
import { CompanyV2 } from '../../models/companies/company-V2';

@Component({
    selector: 'company-popup-labels',
    templateUrl: './company-popup-labels.component.html',
    styleUrls: ['./company-popup.component.scss']
})
export class CompanyPopupLabelsComponent implements OnInit {
    @Input() labelsExpanded: boolean = true;
    @Input() newGroupLabels: CompanyLabelGroup[];
    @Input() company: CompanyV2;

    constructor(private companyLabelGroupService: CompanyLabelGroupService) { }

    ngOnInit() {
        if (!this.newGroupLabels) {
            this.companyLabelGroupService.getCompanyLabelGroups().then(labelGroups => {
                this.newGroupLabels = labelGroups;
            });
        }
        if (!this.company) { this.company = { ...this.company }; }
    }

    expandLabelsRow(): void {
        this.labelsExpanded = !this.labelsExpanded;
    }

    checkSelectedLabel(labelGroup: CompanyLabelGroup): number {
        if (this.company.labels) {
            for (const label of this.company.labels) {
                if (label.groupId == labelGroup.id) {
                    return label.id;
                }
            }
        }
        return;
    }

    checkSelectedLabelMultiple(labelGroup: CompanyLabelGroup): CompanyLabel[] {
        const companyLabels: CompanyLabel[] = [];
        for (const label of this.company.labels) {
            if (label.groupId == labelGroup.id) {
                companyLabels.push(label);
            }
        }
        return companyLabels;
    }

    multipleLabelsValueChange(e: any, group: CompanyLabelGroup): void {
        const labelIds: number[] = [];
        if (!this.company.labels) {
            this.company.labels = [];
        } else {
            this.company.labels.map((label: CompanyLabel) => labelIds.push(label.id));
        }
        if (e.addedItems) {
            e.addedItems.map((label: CompanyLabel) => {
                if (!labelIds.includes(label.id)) {
                    this.company.labels.push(label);
                }
            });
        }
        if (e.removedItems && e.removedItems.length > 0) {
            for (let i = 0; i < this.company.labels.length; i++) {
                if (this.company.labels[i].id == e.removedItems[0].id) {
                    this.company.labels.splice(i, 1);
                }
            }
        }
    }

    selectLabel(e: any): void {
        if (e.selectedItem) {
            if (this.company.labels) {
                this.company.labels = this.company.labels.filter((label: CompanyLabel) => label.groupId != e.selectedItem.groupId);
            } else {
                this.company.labels = [];
            }
            this.company.labels.push(e.selectedItem);
        }
    }
}
