import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import {
  CreateExpenseTypes,
  UpdateExpenseTypes,
  DeleteExpenseTypes,
  ClearExpenseTypeId,
  LoadExpenseTypes
} from '../../../../../store/system-settings/expense-types/expense-types.actions';
import { CloseDrawer } from 'src/app/store/layout/layout.actions';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ExpenseType } from '../../../../../models/activities/expense-type';
import { selectSelectedExpenseType } from 'src/app/store/system-settings/expense-types/selectors/selected-expenseType.selector';
import { DeleteObject } from 'src/app/store/undo-delete/undo-delete.actions';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDeleteComponent } from 'src/app/view/shared/confirm-delete/confirm-delete.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'tn-add-expense-types',
  templateUrl: './add-expense-types.component.html',
  styleUrls: ['./add-expense-types.component.css']
})
export class AddExpenseTypesComponent implements OnInit, OnDestroy {
  expenseTypeForm: FormGroup;
  currentExpenseType: ExpenseType;
  $currentExpenseTypeSub: Subscription;

  constructor(private store: Store<RootState>, private dialog: MatDialog) {}

  ngOnInit() {
    this.expenseTypeForm = new FormGroup({
      agencyId: new FormControl(null),
      type: new FormControl(null, [Validators.required]),
      id: new FormControl(null)
    });
    this.$currentExpenseTypeSub = this.store
      .select(selectSelectedExpenseType)
      .subscribe((expenseType) => {
        if (!!expenseType) {
          this.currentExpenseType = expenseType;
          this.expenseTypeForm.setValue({
            agencyId: expenseType.agencyId,
            type: expenseType.type,
            id: expenseType.id
          });
        }
      });
  }

  ngOnDestroy() {
    this.$currentExpenseTypeSub.unsubscribe();
  }

  save() {
    this.store.dispatch(new CreateExpenseTypes(this.expenseTypeForm.value));
    this.store.dispatch(new CloseDrawer());
    this.store.dispatch(new ClearExpenseTypeId());
    this.store.dispatch(new LoadExpenseTypes());
  }

  update() {
    this.expenseTypeForm.value.id = this.currentExpenseType.id;
    this.store.dispatch(new UpdateExpenseTypes(this.expenseTypeForm.value));
    this.store.dispatch(new CloseDrawer());
    this.store.dispatch(new ClearExpenseTypeId());
    this.store.dispatch(new LoadExpenseTypes());
  }

  cancel(): void {
    this.store.dispatch(new CloseDrawer());
    this.store.dispatch(new ClearExpenseTypeId());
  }

  delete(): void {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {});

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((willDelete: boolean) => {
        if (willDelete) {
          this.store.dispatch(new DeleteExpenseTypes(this.currentExpenseType.id));
          this.store.dispatch(new ClearExpenseTypeId());
          this.store.dispatch(new CloseDrawer());
          this.store.dispatch(new LoadExpenseTypes());
        }
      });
  }
}
