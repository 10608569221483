<div class="navbar" fxLayout="column" [ngClass]="{ 'is-open': $sidenavExpanded | async }">
  <div
    class="navbar-logo container"
    fxLayoutAlign="center center"
    fxLayout="row"
    fxFlex="none"
  >
    <a [routerLink]="'/'">
      <img
        *ngIf="($sidenavExpanded | async) === false"
        src="assets/voze_mark_white.png"
        style="max-width: 25px"
      />
    </a>
    <a [routerLink]="'/'" *ngIf="!!($sidenavExpanded | async)">
      <img src="assets/Voze_White_Logo.png" class="logo-expanded" />
    </a>
  </div>

  <div *ngFor="let route of $links | async">
    <div class="links-container">
      <a
        *ngIf="($sidenavExpanded | async) === false"
        [routerLink]="['/' + route.path]"
        fxLayoutAlign="center center"
        routerLinkActive="active-link"
        fxLayout="row"
        class="menu-item-container"
      >
        <mat-icon
          color="primary"
          class="material-icons hover-link"
          fxLayoutAlign="start center"
          >{{ route.icon }}</mat-icon
        >
      </a>
      <a
        *ngIf="!!($sidenavExpanded | async)"
        [routerLink]="['/' + route.path]"
        routerLinkActive="active-link-expanded"
        class="menu-item-container"
        fxLayoutAlign="start center"
      >
        <mat-icon
          color="primary"
          class="material-icons hover-link"
          style="padding-left: 15px"
          >{{ route.icon }}</mat-icon
        >
        <span style="font-size: 0.5em; padding-left: 15px">{{ route.title }}</span>
      </a>
    </div>
  </div>
  <div fxFlex="auto"></div>
  <!-- navigation -->
  <button
    mat-button
    class="navigate-container"
    fxFlex="none"
    [ngClass]="{ 'button-open': $sidenavExpanded | async }"
    (click)="toggleOpenSidenav()"
  >
    <span class="menu-item-container" fxLayoutAlign="end center">
      <mat-icon *ngIf="($sidenavExpanded | async) === false; else chevLeft"
        >chevron_right</mat-icon
      >
      <ng-template #chevLeft>
        <mat-icon>chevron_left</mat-icon>
      </ng-template>
    </span>
  </button>
</div>
