import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CompanyMergeService } from 'src/app/services/companies/companyMerge.service';
import { CloseDrawer } from 'src/app/store/layout/layout.actions';
import { RootState } from 'src/app/store/store.reducer';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PhonePipe } from 'src/app/view/shared/pipes/phone.pipe';

@Component({
  selector: 'tn-company-merge',
  templateUrl: './company-merge.component.html',
  styleUrls: ['./company-merge.component.scss']
})
export class CompanyMergeComponent implements OnInit {
  @Input() data: any;
  primaryCompanyId: number;

  constructor(
    private store: Store<RootState>,
    private companyMergeService: CompanyMergeService,
    private _snackBar: MatSnackBar,
    private phonePipe: PhonePipe
  ) {}

  ngOnInit(): void {
    this.data.company1.phone = this.phonePipe.transform(this.data.company1.phone);
    this.data.company2.phone = this.phonePipe.transform(this.data.company2.phone);
  }

  merge() {
    const observer = {
      error: (err) => {
        this.openErrorSnackbar();
      },
      complete: () => {
        this.openSnackbar();
        setTimeout(() => {
          location.reload();
        }, 5000);
      }
    };
    let mergedObj;
    if (this.primaryCompanyId === this.data.company1.id) {
      mergedObj = {
        primaryCompanyId: this.data.company1.id,
        secondaryCompanyId: this.data.company2.id
      };
    } else {
      mergedObj = {
        primaryCompanyId: this.data.company2.id,
        secondaryCompanyId: this.data.company1.id
      };
    }
    this.companyMergeService.mergeCompaniesV2(mergedObj).subscribe(observer);
    this.store.dispatch(new CloseDrawer());
  }

  cancel() {
    this.store.dispatch(new CloseDrawer());
  }

  openSnackbar() {
    this._snackBar.open(
      'The company merging process has begun, it may take a few minutes to fully complete.',
      'Success',
      {
        duration: 5000
      }
    );
  }

  openErrorSnackbar() {
    this._snackBar.open('An error occurred, please try again.', 'Error', {
      duration: 5000
    });
  }
}
