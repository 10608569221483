import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptorService } from './http-interceptor.service';
import { CookieService } from 'ngx-cookie-service';
import { Interceptor401Service } from './401-interceptor.service';

@NgModule({
  declarations: [],
  providers: [
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor401Service,
      multi: true
    }
  ]
})
export class AuthModule {}
