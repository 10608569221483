import { CompaniesActions, CompaniesActionTypes } from './companies.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { Company } from 'src/app/models/companies/company';
import { CompanySelectItem } from 'src/app/models/companies/company-select';
import { Contact } from 'src/app/models/contacts/contact';
import { FileMetaData } from 'src/app/models/file-meta-data';
import { GenericMap } from 'src/app/models/generic-map';
import { DMS } from 'src/app/models/companies/dms';
import { DMSExactMatch } from 'src/app/models/companies/dms-exact-match';

export interface CompaniesState {
  pending: boolean;
  error: HttpErrorResponse;
  data: GenericMap<Company>;
  search: {
    pending: boolean;
    error: HttpErrorResponse;
    selectedSearchCompanyId: number;
    data: CompanySelectItem[];
  };
  contacts: {
    [companyId: string]: {
      pending: boolean;
      error: HttpErrorResponse;
      data: { [contactId: number]: Contact };
    };
  };
  files: {
    [companyId: string]: {
      pending: boolean;
      error: HttpErrorResponse;
      data: FileMetaData[];
    };
  };
  selectedContact: {
    contactId: number;
    companyId: number;
  };
  dmsLinked: {
    pending: boolean;
    error: HttpErrorResponse;
    data: GenericMap<DMS>;
  };
  dmsExactMatches: {
    pending: boolean;
    error: HttpErrorResponse;
    data: GenericMap<DMSExactMatch>;
  };
  dmsUnlinked: {
    pending: boolean;
    error: HttpErrorResponse;
    data: GenericMap<DMS>;
  };
  dmsLinkedCompany: {
    pending: boolean;
    error: HttpErrorResponse;
    linkedCompany: DMS;
  };
  expansionPanels: {
    assignedReps: boolean;
    contacts: boolean;
    companyDetails: boolean;
  };
  newCompanyContacts: Contact[];
}

export const initialState: CompaniesState = {
  pending: false,
  error: undefined,
  data: {},
  search: {
    pending: false,
    error: undefined,
    selectedSearchCompanyId: null,
    data: []
  },
  contacts: {},
  files: {},
  selectedContact: {
    contactId: null,
    companyId: null
  },
  dmsLinked: {
    pending: false,
    error: undefined,
    data: {}
  },
  dmsExactMatches: {
    pending: false,
    error: undefined,
    data: {}
  },
  dmsUnlinked: {
    pending: false,
    error: undefined,
    data: {}
  },
  dmsLinkedCompany: {
    pending: false,
    error: undefined,
    linkedCompany: undefined
  },
  expansionPanels: {
    assignedReps: true,
    contacts: true,
    companyDetails: true
  },
  newCompanyContacts: []
};

export function companiesReducer(
  state = initialState,
  action: CompaniesActions
): CompaniesState {
  switch (action.type) {
    case CompaniesActionTypes.LoadCompany:
      return {
        ...state,
        error: undefined,
        pending: true
      };

    case CompaniesActionTypes.LoadCompanySuccess: {
      return {
        ...state,
        pending: false,
        error: undefined,
        data: {
          ...state.data,
          [action.payload.id]: action.payload
        }
      };
    }

    case CompaniesActionTypes.LoadCompanyFailure:
      return { ...state, pending: false, error: action.payload };

    case CompaniesActionTypes.LoadCompanies:
      return {
        ...state,
        pending: true
      };

    case CompaniesActionTypes.LoadCompaniesSuccess:
      return {
        ...state,
        pending: false,
        data: {
          ...state.data,
          ...action.payload
        }
      };

    case CompaniesActionTypes.LoadCompaniesFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case CompaniesActionTypes.CreateCompany:
      return {
        ...state,
        pending: true
      };

    case CompaniesActionTypes.CreateCompanySuccess:
      return {
        ...state,
        pending: false
      };

    case CompaniesActionTypes.CreateCompanyFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case CompaniesActionTypes.ClearSearchCompanies:
      return {
        ...state,
        search: { ...initialState.search }
      };

    case CompaniesActionTypes.ClearCompanies:
      return {
        ...state,
        data: { ...initialState.data }
      };

    case CompaniesActionTypes.SelectSearchedCompany: {
      return {
        ...state,
        search: {
          ...state.search,
          selectedSearchCompanyId: action.payload
        }
      };
    }

    case CompaniesActionTypes.SearchCompanies:
      return {
        ...state,
        search: {
          ...state.search,
          pending: true
        }
      };

    case CompaniesActionTypes.SearchCompaniesSuccess:
      return {
        ...state,
        search: {
          ...state.search,
          pending: false,
          error: undefined,
          data: action.payload
        }
      };

    case CompaniesActionTypes.SearchCompaniesFailure:
      return {
        ...state,
        search: {
          ...state.search,
          pending: false,
          error: action.payload,
          data: []
        }
      };

    case CompaniesActionTypes.LoadCompanyContacts:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          [action.payload]: {
            pending: true,
            error: undefined,
            data: {}
          }
        }
      };

    case CompaniesActionTypes.LoadCompanyContactsSuccess:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          [action.payload.companyId]: {
            pending: false,
            error: undefined,
            data: action.payload.contacts
          }
        }
      };

    case CompaniesActionTypes.LoadCompanyContactsFailure:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          [action.payload.companyId]: {
            pending: false,
            error: action.payload.error,
            data: {}
          }
        }
      };

    case CompaniesActionTypes.LoadCompanyFiles:
      return {
        ...state,
        files: {
          ...state.files,
          [action.payload]: {
            pending: true,
            error: undefined,
            data: {}
          }
        }
      };

    case CompaniesActionTypes.LoadCompanyFilesSuccess:
      return {
        ...state,
        files: {
          ...state.files,
          [action.payload.agencyId]: {
            pending: false,
            error: undefined,
            data: action.payload.files
          }
        }
      };

    case CompaniesActionTypes.LoadCompanyFilesFailure:
      return {
        ...state,
        files: {
          ...state.files,
          [action.payload.agencyId]: {
            pending: false,
            error: action.payload.error,
            data: {}
          }
        }
      };

    case CompaniesActionTypes.SelectCompanyAndContactId:
      return {
        ...state,
        selectedContact: action.payload
      };

    case CompaniesActionTypes.LoadDmsLinkedCompanies:
      return { ...state, pending: true };

    case CompaniesActionTypes.LoadDmsLinkedCompaniesSuccess:
      return {
        ...state,
        dmsLinked: {
          ...state.dmsLinked,
          pending: false,
          data: action.payload
        }
      };

    case CompaniesActionTypes.LoadDmsLinkedCompaniesFailure:
      return {
        ...state,
        dmsLinked: {
          ...state.dmsLinked,
          pending: false,
          data: {},
          error: action.payload
        }
      };

    case CompaniesActionTypes.LoadDmsExactMatches:
      return { ...state, pending: true };

    case CompaniesActionTypes.LoadDmsExactMatchesSuccess:
      return {
        ...state,
        dmsExactMatches: {
          ...state.dmsExactMatches,
          pending: false,
          data: action.payload
        }
      };

    case CompaniesActionTypes.LoadDmsExactMatchesFailure:
      return {
        ...state,
        dmsExactMatches: {
          ...state.dmsExactMatches,
          pending: false,
          data: {},
          error: action.payload
        }
      };

    case CompaniesActionTypes.LoadDmsUnlinkedCompanies:
      return { ...state, pending: true };

    case CompaniesActionTypes.LoadDmsUnlinkedCompaniesSuccess:
      return {
        ...state,
        dmsUnlinked: {
          ...state.dmsUnlinked,
          pending: false,
          data: action.payload
        }
      };

    case CompaniesActionTypes.LoadDmsUnlinkedCompaniesFailure:
      return {
        ...state,
        dmsUnlinked: {
          ...state.dmsUnlinked,
          pending: false,
          data: {},
          error: action.payload
        }
      };

    case CompaniesActionTypes.LoadDmsLinkedCompany:
      return {
        ...state,
        dmsLinkedCompany: {
          pending: true,
          error: undefined,
          linkedCompany: undefined
        }
      };

    case CompaniesActionTypes.LoadDmsLinkedCompanySuccess:
      return {
        ...state,
        dmsLinkedCompany: {
          error: undefined,
          pending: false,
          linkedCompany: action.payload
        }
      };

    case CompaniesActionTypes.LoadDmsLinkedCompanyFailure:
      return {
        ...state,
        dmsLinkedCompany: {
          pending: false,
          error: action.payload,
          linkedCompany: undefined
        }
      };

    case CompaniesActionTypes.ToggleAssignedRepsPanel: {
      return {
        ...state,
        expansionPanels: {
          ...state.expansionPanels,
          assignedReps: !state.expansionPanels.assignedReps
        }
      };
    }

    case CompaniesActionTypes.ToggleContactsPanel: {
      return {
        ...state,
        expansionPanels: {
          ...state.expansionPanels,
          contacts: !state.expansionPanels.contacts
        }
      };
    }

    case CompaniesActionTypes.ToggleCompanyDetailsPanel: {
      return {
        ...state,
        expansionPanels: {
          ...state.expansionPanels,
          companyDetails: !state.expansionPanels.companyDetails
        }
      };
    }

    case CompaniesActionTypes.SaveChildCompaniesSuccess: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.companyId]: {
            ...state.data[action.payload.companyId],
            directChildCompanies: action.payload.childCompanies
          }
        }
      };
    }

    case CompaniesActionTypes.AddNewCompanyContact: {
      return {
        ...state,
        newCompanyContacts: [...state.newCompanyContacts, action.payload]
      };
    }

    case CompaniesActionTypes.RemoveNewCompanyContact: {
      return {
        ...state,
        newCompanyContacts: state.newCompanyContacts.filter(
          (_, i) => i !== action.payload
        )
      };
    }

    case CompaniesActionTypes.EditNewCompanyContact: {
      const newCompanyContacts = [...state.newCompanyContacts];
      newCompanyContacts[action.payload.index] = action.payload.contact;
      return {
        ...state,
        newCompanyContacts
      };
    }

    case CompaniesActionTypes.ClearNewCompanyContacts: {
      return {
        ...state,
        newCompanyContacts: []
      };
    }

    default:
      return state;
  }
}
