import { UsersActions, UsersActionTypes } from './users.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { UsersMap } from 'src/app/models/admin/users/users-map';

export interface UsersState {
  pending: boolean;
  error: HttpErrorResponse;
  data: UsersMap;
}

export const initialState: UsersState = {
  pending: false,
  error: undefined,
  data: {}
};

export function usersReducer(state = initialState, action: UsersActions): UsersState {
  switch (action.type) {
    case UsersActionTypes.LoadUsers:
      return {
        ...state,
        pending: true,
        error: undefined
      };

    case UsersActionTypes.LoadUsersSuccess: {
      return {
        ...state,
        pending: false,
        error: undefined,
        data: {
          ...state.data,
          ...action.payload
        }
      };
    }

    case UsersActionTypes.LoadUsersFailure:
      return {
        ...state,
        pending: false,
        error: action.payload,
        data: {}
      };

    case UsersActionTypes.ClearUsers:
      return {
        ...state,
        data: {}
      };

    default:
      return state;
  }
}
