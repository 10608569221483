<div fxLayout="column" fxLayoutAlign="space-around stretch" fxFlex="100">
    <div fxLayout="column wrap" fxLayoutAlign="space-around stretch" fxFlex="100" *ngIf="isLoaded">
        <div *ngFor="let row of productData">
                <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center">
                    <dx-chart fxFlex="50" id="chart" title="{{row.name}} Monthly Sales" [dataSource]="row.data">
                        <dxo-legend [visible]="false"></dxo-legend>
                        <dxi-series  type="bar" valueField="val">
                            
                        </dxi-series>
                        <dxi-series name="average" color="orange" type="spline" valueField="avg">
                            <dxo-label [visible]="false">/</dxo-label>
                        </dxi-series>
                        
                        <dxo-common-series-settings
                            argumentField="arg"
                            type="bar"
                            hoverMode="allArgumentPoints"
                            selectionMode="allArgumentPoints">
                            <dxo-label
                                [visible]="true"
                                [format]="{
                                    type: 'fixedPoint',
                                    precision: '0'
                                }">
                            </dxo-label>
                        </dxo-common-series-settings>
                        <dxo-tooltip [enabled]="true" [customizeTooltip]="setTooltip">
                            <dxo-format type="currency" [precision]="2"></dxo-format>
                        </dxo-tooltip>
                        <dxo-argument-axis [tickInterval]="10">
                            <dxo-label>
                                <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
                            </dxo-label>
                        </dxo-argument-axis>
                        <dxo-legend
                            verticalAlignment="bottom"
                            horizontalAlignment="center">
                        </dxo-legend>
                    </dx-chart>

                    <dx-chart [customizePoint]="customizePoint" fxFlex="50" id="chart" title="{{row.name}} Sales Increase" [dataSource]="row.lydata">
                        <dxo-legend [visible]="false"></dxo-legend>
                        <dxi-series type="bar"></dxi-series>
                        <dxi-series name="average" color="orange" type="spline" valueField="avg">
                            <dxo-label [visible]="false">/</dxo-label>
                        </dxi-series>
                         <dxo-common-series-settings
                            argumentField="arg"
                            type="bar"
                            hoverMode="allArgumentPoints"
                            selectionMode="allArgumentPoints">
                            <dxo-label
                                [visible]="true"
                                [format]="{
                                    type: 'fixedPoint',
                                    precision: '0'
                                }">
                            </dxo-label>
                        </dxo-common-series-settings>
                        <dxo-tooltip [enabled]="true" [customizeTooltip]="setTooltip">
                            <dxo-format type="currency" [precision]="2"></dxo-format>
                        </dxo-tooltip>
                        <dxo-argument-axis [tickInterval]="10">
                            <dxo-label>
                                <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
                            </dxo-label>
                        </dxo-argument-axis>
                        <dxo-legend
                            verticalAlignment="bottom"
                            horizontalAlignment="center">
                        </dxo-legend>
                    </dx-chart>
                
            </div>
        </div>
    </div>
</div>
