import { Action } from '@ngrx/store';
import { FieldType } from 'src/app/models/call-notes/field-type';
import { HttpErrorResponse } from '@angular/common/http';

export enum FieldTypesActionTypes {
  LoadFieldTypes = '[FieldTypes] Load FieldTypes',
  LoadFieldTypesSuccess = '[FieldTypes] Load FieldTypes Success',
  LoadFieldTypesFailure = '[FieldTypes] Load FieldTypes Failure'
}

export class LoadFieldTypes implements Action {
  readonly type = FieldTypesActionTypes.LoadFieldTypes;
}

export class LoadFieldTypesSuccess implements Action {
  readonly type = FieldTypesActionTypes.LoadFieldTypesSuccess;
  constructor(public payload: FieldType[]) {}
}

export class LoadFieldTypesFailure implements Action {
  readonly type = FieldTypesActionTypes.LoadFieldTypesFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export type FieldTypesActions =
  | LoadFieldTypes
  | LoadFieldTypesSuccess
  | LoadFieldTypesFailure;
