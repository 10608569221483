<div
  fxLayout="row wrap"
  class="share-with-emails-container"
  [matMenuTriggerFor]="shareMenu"
  *ngIf="
    !!callNoteForm.controls.shareWithEmails.value &&
    callNoteForm.controls.shareWithEmails.value.length
  "
>
  When you save this call note, it will be shared with:&nbsp;
  <span
    class="share-with-emails"
    *ngFor="let email of callNoteForm.controls.shareWithEmails.value; let last = last"
  >
    <span *ngIf="!last"> {{ email }},&nbsp; </span>
    <span *ngIf="last">
      {{ email }}
    </span>
  </span>
</div>
<div class="call-note-form" fxLayout="column" fxLayoutAlign="space-between center">
  <mat-accordion [multi]="true" class="call-note-form-panels">
    <mat-expansion-panel [expanded]="true" disabled>
      <mat-expansion-panel-header>
        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center">
          <div class="mat-card-title">
            <div fxLayoutAlign="space-between center">
              <span style="color: black">Call Note</span>
              <button
                mat-icon-button
                color="primary"
                *ngIf="!!currentCallNote?.id"
                matTooltip="share/comment"
                (click)="commentHandler(currentCallNote)"
              >
                <mat-icon>share</mat-icon>
              </button>
            </div>
          </div>
          <div>&nbsp;</div>
          <div
            fxLayout="row"
            fxLayoutAlign="space-around center"
            *ngIf="webRecording && !currentCallNote?.id"
          >
            <div>Use your voice to record</div>
            &nbsp;
            <button
              tabindex="false"
              (click)="openCallNoteDrawer()"
              mat-icon-button
              matTooltip="Record Call Note"
              style="background-color: red"
            >
              <mat-icon style="color: white">mic</mat-icon>
            </button>
          </div>

          <div
            class="call-note-created-info"
            *ngIf="!!currentCallNote?.id"
            fxLayout="column"
            fxLayoutGap="6px"
          >
            <div>Created By: {{ currentCallNote.createdBy.name }}</div>
            <div>On: {{ currentCallNote.createdDate | date : 'medium' }}</div>
            <div>ID: {{ currentCallNote.id }}</div>
          </div>
        </div>
      </mat-expansion-panel-header>
      <div fxLayout="column" [style.marginBottom]="'16px'">
        <a
          mat-button
          fxLayout="row"
          fxLayoutAlign="start center"
          *ngIf="!!currentCallNote?.opportunityId && ($oppName | async)"
          [routerLink]="['/opportunities/opportunity', currentCallNote.opportunityId]"
          target="_blank"
          color="accent"
          matTooltip="Go to linked opportunity"
          matTooltipPosition="left"
          [style.marginLeft]="'-20px'"
        >
          <mat-icon>trending_up</mat-icon> Opportunity: {{ $oppName | async }}
        </a>
        <a
          mat-button
          fxLayout="row"
          fxLayoutAlign="start center"
          *ngIf="!!currentCallNote?.projectId && ($projName | async)"
          [routerLink]="['/projects/project', currentCallNote.projectId]"
          target="_blank"
          color="accent"
          matTooltip="Go to linked project"
          matTooltipPosition="left"
          [style.marginLeft]="'-20px'"
        >
          <mat-icon>assignment</mat-icon> Project: {{ $projName | async }}
        </a>
      </div>
      <form [formGroup]="callNoteForm" (submit)="submitHandler()" autocomplete="off">
        <div fxLayout="column">
          <div fxLayout="row" fxLayoutGap="12px">
            <mat-form-field fxFlex="33%">
              <input
                matInput
                [matDatepicker]="callDatePicker"
                formControlName="callDate"
                required
                placeholder="Choose a date"
                (click)="callDatePicker.open()"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="callDatePicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #callDatePicker></mat-datepicker>
            </mat-form-field>
            <tn-company-select
              fxFlex="67%"
              (companySearchEvent)="onCompanySearch($event)"
              [$companySearchResults]="$companies"
              [selectedCompany]="currentCallNote?.company"
              [$error]="$companiesSearchError"
              [isRequired]="true"
              (companySelected)="onCompanySelect($event)"
              [$pending]="$companiesSearchPending"
            ></tn-company-select>
          </div>
          <tn-item-select
            [placeholder]="'Call Type'"
            [$selectableItems]="$types"
            [hasValidator]="true"
            [isRequired]="true"
            [selectedItem]="currentCallNote?.type"
            (itemSelected)="onTypeSelected($event)"
            (isValid)="typeValidation($event)"
          ></tn-item-select>
          <tn-multi-select
            class="contacts-selector"
            [selectedItems]="currentCallNote?.contacts || []"
            (selectedItemsChange)="setContacts($event)"
            [$allItems]="$companyContacts"
            [placeholder]="'Company Contacts'"
          ></tn-multi-select>
          <tn-item-select
            *ngIf="!!opportunities?.length && opportunitySelect"
            [placeholder]="'Opportunity'"
            [$selectableItems]="$opportunities"
            [selectedItem]="selectedOpportunity"
            (itemSelected)="onOpportunitySelected($event)"
            [hasValidator]="false"
            [isRequired]="false"
            [optionDetails]="true"
          ></tn-item-select>
        </div>
      </form>

      <div *ngIf="!!callNoteFields.length">
        <div
          *ngIf="callNoteFields.length >= customCallNoteFieldLimit; else elseFieldBlock"
        >
          <tn-item-select
            [placeholder]="'Select field'"
            [$selectableItems]="$selectableCallNoteFields"
            (itemSelected)="onFieldItemSelected($event)"
            [$clearInputEvent]="$addFieldEventsSubject"
            [overrideSort]="true"
            [hasValidator]="false"
          ></tn-item-select>

          <form [formGroup]="fieldsForm" fxLayoutGap="8px" fxLayout="column">
            <div *ngFor="let field of selectedNoteFields | sort : 'callnoteOrder'">
              <div [ngSwitch]="field.type?.id">
                <div *ngSwitchCase="2" fxLayoutAlign="space-between start">
                  <mat-checkbox [formControlName]="field.id">
                    {{ field.name }}
                  </mat-checkbox>
                  <button
                    mat-icon-button
                    color="warn"
                    (click)="deleteFieldHandler(field.id)"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </div>

                <div *ngSwitchCase="4" fxLayoutAlign="space-between start">
                  <mat-form-field fxFlex="100">
                    <input
                      matInput
                      autocomplete="off"
                      currencyMask
                      type="number"
                      [placeholder]="field.name"
                      [formControlName]="field.id"
                    />
                  </mat-form-field>
                  <button
                    mat-icon-button
                    color="warn"
                    (click)="deleteFieldHandler(field.id)"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </div>

                <div *ngSwitchCase="14 || 12" fxLayoutAlign="space-between start">
                  <mat-form-field fxFlex="100">
                    <input
                      matInput
                      autocomplete="off"
                      type="number"
                      [placeholder]="field.name"
                      [formControlName]="field.id"
                    />
                  </mat-form-field>
                  <button
                    mat-icon-button
                    color="warn"
                    (click)="deleteFieldHandler(field.id)"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </div>

                <div *ngSwitchCase="16">
                  <mat-form-field
                    fxFlex="100"
                    *ngIf="!!dropdownOptions && !!dropdownOptions[field.id]"
                  >
                    <mat-label>{{ field.name }}</mat-label>
                    <mat-select [formControlName]="field.id">
                      <mat-option [value]="null"> No selection </mat-option>
                      <mat-option
                        *ngFor="let option of dropdownOptions[field.id].options"
                        [value]="option"
                      >
                        {{ option.dropdownOption }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div *ngSwitchDefault fxLayoutAlign="space-between start">
                  <mat-form-field fxFlex="100">
                    <textarea
                      matInput
                      autocomplete="off"
                      tnAutosizeTextarea
                      [placeholder]="field.name"
                      [formControlName]="field.id"
                    ></textarea>
                  </mat-form-field>
                  <button
                    mat-icon-button
                    color="warn"
                    (click)="deleteFieldHandler(field.id)"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>

        <ng-template #elseFieldBlock>
          <br />
          <form [formGroup]="fieldsForm" fxLayoutGap="8px" fxLayout="column">
            <div *ngFor="let field of callNoteFields | sort : 'callnoteOrder'">
              <div [ngSwitch]="field.type?.id">
                <mat-checkbox [formControlName]="field.id" *ngSwitchCase="2">
                  {{ field.name }}
                </mat-checkbox>

                <mat-form-field fxFlex="100" *ngSwitchCase="4">
                  <input
                    matInput
                    currencyMask
                    autocomplete="off"
                    [placeholder]="field.name"
                    [formControlName]="field.id"
                  />
                </mat-form-field>

                <mat-form-field fxFlex="100" *ngSwitchCase="14 || 12">
                  <input
                    matInput
                    autocomplete="off"
                    type="number"
                    [placeholder]="field.name"
                    [formControlName]="field.id"
                  />
                </mat-form-field>

                <div *ngSwitchCase="16">
                  <mat-form-field
                    fxFlex="100"
                    *ngIf="!!dropdownOptions && !!dropdownOptions[field.id]"
                  >
                    <mat-label>{{ field.name }}</mat-label>
                    <mat-select [formControlName]="field.id">
                      <mat-option [value]="null"> No selection </mat-option>
                      <mat-option
                        *ngFor="let option of dropdownOptions[field.id].options"
                        [value]="option"
                      >
                        {{ option.dropdownOption }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <mat-form-field fxFlex="100" *ngSwitchDefault>
                  <textarea
                    matInput
                    autocomplete="off"
                    tnAutosizeTextarea
                    [placeholder]="field.name"
                    [formControlName]="field.id"
                  ></textarea>
                </mat-form-field>
              </div>
            </div>
          </form>
        </ng-template>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <div class="mat-card-title">Follow-up</div>
      </mat-expansion-panel-header>
      <form [formGroup]="followUpForm">
        <div fxLayout="column">
          <mat-form-field>
            <textarea
              matInput
              tnAutosizeTextarea
              autocomplete="off"
              placeholder="Description"
              formControlName="task"
            ></textarea>
          </mat-form-field>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
            <tn-item-select
              fxFlex="100%"
              [placeholder]="'Assigned Sales Rep'"
              [$selectableItems]="$allUsers"
              [selectedItem]="currentCallNote?.followUp?.assignedTo"
              (itemSelected)="onAssignedSelected($event)"
              (isValid)="userValidation($event)"
            ></tn-item-select>
            <mat-checkbox formControlName="complete">Complete</mat-checkbox>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="12px">
            <div fxLayout="column" fxLayoutGap="12px" fxFlex="calc(50% - 12px)">
              <mat-form-field>
                <input
                  matInput
                  [ngxMatDatetimePicker]="startTime"
                  placeholder="Start Date/Time"
                  formControlName="dueDateStart"
                  (dateChange)="onStartDateChanged()"
                  (focus)="startTime.open()"
                />
                <button
                  type="button"
                  mat-icon-button
                  matSuffix
                  (click)="startTime.open(); $event.stopPropagation()"
                >
                  <mat-icon>today</mat-icon>
                </button>
                <ngx-mat-datetime-picker
                  #startTime
                  enableMeridian="true"
                ></ngx-mat-datetime-picker>
              </mat-form-field>
            </div>
            <div fxLayout="column" fxLayoutGap="12px" fxFlex="calc(50% - 12px)">
              <mat-form-field>
                <input
                  matInput
                  [ngxMatDatetimePicker]="endTime"
                  placeholder="End Date/Time"
                  formControlName="dueDateEnd"
                  (dateChange)="onEndDateChanged()"
                  (focus)="endTime.open()"
                />
                <button
                  type="button"
                  mat-icon-button
                  matSuffix
                  (click)="endTime.open(); $event.stopPropagation()"
                >
                  <mat-icon>today</mat-icon>
                </button>
                <ngx-mat-datetime-picker
                  #endTime
                  enableMeridian="true"
                ></ngx-mat-datetime-picker>
              </mat-form-field>
            </div>
          </div>
        </div>
      </form>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="!!currentCallNote?.id" [expanded]="true">
      <mat-expansion-panel-header>
        <div class="mat-card-title">
          <span *ngIf="($commentsPending | async) === false; else loadingComments">
            <span *ngIf="($comments | async)?.length === 0">No </span>
            <span *ngIf="($comments | async)?.length > 0"
              >{{ ($comments | async).length }}
            </span>
            Comment<span
              *ngIf="($comments | async)?.length > 1 || ($comments | async)?.length === 0"
              >s</span
            >
          </span>
          <ng-template #loadingComments
            ><div fxLayout="row" fxLayoutAlign="space-between center">
              <span>Loading Comments...</span
              ><mat-progress-spinner
                [diameter]="20"
                [strokeWidth]="3"
                [mode]="'indeterminate'"
              ></mat-progress-spinner></div
          ></ng-template>
        </div>
      </mat-expansion-panel-header>
      <div fxLayout="column">
        <div fxLayout="column" fxLayoutGap="16px">
          <tn-project-call-note-comment
            *ngFor="let comment of $comments | async"
            [callNoteId]="currentCallNote.id"
            [comment]="comment"
          ></tn-project-call-note-comment>
        </div>
        <button
          mat-raised-button
          (click)="commentHandler(currentCallNote)"
          color="primary"
          fxFlex="100"
          [style.marginTop]="'16px'"
        >
          ADD COMMENT
        </button>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="form-actions" fxLayout="row-reverse" fxLayoutAlign="space-between center">
    <div fxLayout="row-reverse" fxLayoutGap="12px">
      <button
        type="button"
        mat-raised-button
        color="primary"
        mat-button
        (click)="submitHandler()"
        [disabled]="
          callNoteForm.invalid ||
          (callNoteForm.pristine && fieldsForm.pristine && followUpForm.pristine) ||
          ($callNotePending | async)
        "
      >
        Save
      </button>
      <button
        preventDefaultAction
        type="button"
        [disabled]="$callNotePending | async"
        mat-raised-button
        color="white"
        mat-button
        (click)="cancelButtonClicked()"
      >
        Cancel
      </button>
    </div>
    <button
      *ngIf="currentCallNote?.id"
      preventDefaultAction
      type="button"
      [disabled]="$callNotePending | async"
      mat-raised-button
      color="warn"
      class="text-white"
      mat-button
      (click)="deleteButtonClicked()"
    >
      Delete
    </button>
  </div>
</div>
