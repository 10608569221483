import { ColumnType, ColumnHeader } from 'src/app/models/column-header';

export const OpportunityItemsTableColumnsData: ColumnHeader[] = [
  {
    name: 'oppName',
    displayName: 'Opportunity Name',
    type: ColumnType.STRING,
    isCurrency: false,
    isDate: false
  },
  {
    name: 'oppId',
    displayName: 'Opportunity ID',
    type: ColumnType.NUMERIC,
    isCurrency: false,
    isDate: false
  },
  {
    name: 'companyName',
    displayName: 'Company',
    type: ColumnType.STRING,
    isCurrency: false,
    isDate: false
  },
  {
    name: 'companyId',
    displayName: 'Company ID',
    type: ColumnType.STRING,
    isCurrency: false,
    isDate: false
  },
  {
    name: 'itemName',
    displayName: 'Item',
    type: ColumnType.STRING,
    isCurrency: false,
    isDate: false
  },
  {
    name: 'ownedByName',
    displayName: 'Owner',
    type: ColumnType.STRING,
    isCurrency: false,
    isDate: false
  },
  {
    name: 'createdByName',
    displayName: 'Created By',
    type: ColumnType.STRING,
    isCurrency: false,
    isDate: false
  },
  {
    name: 'assignedToNames',
    displayName: 'Assigned To',
    type: ColumnType.STRING,
    isCurrency: false,
    isDate: false
  },
  {
    name: 'createdDate',
    displayName: 'Date Created',
    type: ColumnType.NUMERIC,
    isCurrency: false,
    isDate: true
  },
  {
    name: 'estimatedCloseDate',
    displayName: 'Estimated Close date',
    type: ColumnType.NUMERIC,
    isCurrency: false,
    isDate: true
  },
  {
    name: 'amount',
    displayName: 'Amount',
    type: ColumnType.NUMERIC,
    isCurrency: true,
    isDate: false
  },
  {
    name: 'quantity',
    displayName: 'Quantity',
    type: ColumnType.NUMERIC,
    isCurrency: false,
    isDate: false
  },
  {
    name: 'itemTotal',
    displayName: 'Item Total',
    type: ColumnType.NUMERIC,
    isCurrency: true,
    isDate: false
  },
  {
    name: 'itemProbability',
    displayName: 'Item Probability',
    type: ColumnType.NUMERIC,
    isCurrency: false,
    isDate: false
  },
  {
    name: 'itemWeightedTotal',
    displayName: 'Item Weighted Total',
    type: ColumnType.NUMERIC,
    isCurrency: true,
    isDate: false
  },
  {
    name: 'total',
    displayName: 'Opportunity Total',
    type: ColumnType.NUMERIC,
    isCurrency: true,
    isDate: false
  },
  {
    name: 'weightedTotal',
    displayName: 'Opportunity Weighted Total',
    type: ColumnType.NUMERIC,
    isCurrency: true,
    isDate: false
  },
  {
    name: 'age',
    displayName: 'Age',
    type: ColumnType.NUMERIC,
    isCurrency: false,
    isDate: false
  },
  {
    name: 'accountId',
    displayName: 'Account ID',
    type: ColumnType.STRING,
    isCurrency: false,
    isDate: false
  },
  {
    name: 'description',
    displayName: 'Description',
    type: ColumnType.STRING,
    isCurrency: false,
    isDate: false
  },
  {
    name: 'divisionName',
    displayName: 'Division',
    type: ColumnType.STRING,
    isCurrency: false,
    isDate: false
  },
  {
    name: 'businessUnit',
    displayName: 'Business Unit',
    type: ColumnType.STRING,
    isCurrency: false,
    isDate: false
  }
];
