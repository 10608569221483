import { Pipe, PipeTransform } from '@angular/core';
import { CompanyContacted } from 'src/app/models/dashboard/company-contacted';

@Pipe({
  name: 'contactedCompaniesFilter'
})
export class ContactedCompaniesFilterPipe implements PipeTransform {
  transform(
    contactedCompanies: CompanyContacted[],
    searchText: string
  ): CompanyContacted[] {
    searchText = searchText ? searchText.toLowerCase() : null;
    return !!contactedCompanies && contactedCompanies.length > 0
      ? contactedCompanies
          .filter((company) => {
            return !!searchText
              ? company.name.toLowerCase().match(searchText) ||
                  Object.values(company.address)
                    .join(', ')
                    .toLowerCase()
                    .match(searchText) ||
                  Object.values(company.address)
                    .join(', ')
                    .toLowerCase()
                    .match(searchText)
              : true;
          })
          .sort((cnA, cnB) => {
            const aDate = new Date(cnA.dateLastContacted).toISOString();
            const bDate = new Date(cnB.dateLastContacted).toISOString();

            return bDate.localeCompare(aDate);
          })
      : [];
  }
}
