import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { UsersMap } from 'src/app/models/admin/users/users-map';

export enum UsersActionTypes {
  LoadUsers = '[Users] Load Users',
  LoadUsersSuccess = '[Users] Load Users Success',
  LoadUsersFailure = '[Users] Load Users Failure',
  ClearUsers = '[Users] Clear Users'
}

export class LoadUsers implements Action {
  readonly type = UsersActionTypes.LoadUsers;
  constructor(public payload?: number) {}
}

export class LoadUsersSuccess implements Action {
  readonly type = UsersActionTypes.LoadUsersSuccess;
  constructor(public payload: UsersMap) {}
}

export class LoadUsersFailure implements Action {
  readonly type = UsersActionTypes.LoadUsersFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class ClearUsers implements Action {
  readonly type = UsersActionTypes.ClearUsers;
}

export type UsersActions = LoadUsers | LoadUsersSuccess | LoadUsersFailure | ClearUsers;
