import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, withLatestFrom, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as ProjectCompanyCustomFieldsActions from './project-company-custom-fields.actions';
import { ProjectCustomField } from 'src/app/models/projects/project-custom-field';
import { convertToMap } from 'src/app/utils/convertToMap';
import { selectAgencyId } from '../../companies/selectors/files-as-array.selector';
import { ProjectsService } from 'src/app/services/projects/projects.service';
import { RootState } from '../../store.reducer';
import { Store } from '@ngrx/store';

@Injectable()
export class ProjectCompanyCustomFieldsEffects {
  loadProjectCompanyCustomFields$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectCompanyCustomFieldsActions.LoadProjectCompanyCustomFields),
      concatMap(() =>
        this.projService.getProjectCompanyCustomFields().pipe(
          map((data: ProjectCustomField[]) =>
            ProjectCompanyCustomFieldsActions.LoadProjectCompanyCustomFieldsSuccess({
              data: convertToMap(data, 'id')
            })
          ),
          catchError(error =>
            of(
              ProjectCompanyCustomFieldsActions.LoadProjectCompanyCustomFieldsFailure({
                error
              })
            )
          )
        )
      )
    );
  });

  saveProjectCompanyCustomField$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectCompanyCustomFieldsActions.SaveProjectCompanyCustomField),
      map(action => action.data),
      withLatestFrom(this.store.select(selectAgencyId)),
      concatMap(([pcf, agencyId]: [ProjectCustomField, number]) => {
        if (!!pcf.id) {
          return this.projService.updateProjectCompanyCustomField(pcf).pipe(
            switchMap((data: ProjectCustomField) => [
              ProjectCompanyCustomFieldsActions.SaveProjectCompanyCustomFieldSuccess({
                data
              }),
              ProjectCompanyCustomFieldsActions.LoadProjectCompanyCustomFields()
            ]),
            catchError(error =>
              of(
                ProjectCompanyCustomFieldsActions.SaveProjectCompanyCustomFieldFailure({
                  error
                })
              )
            )
          );
        } else {
          return this.projService
            .createProjectCompanyCustomField({ ...pcf, agencyId })
            .pipe(
              switchMap(data => [
                ProjectCompanyCustomFieldsActions.SaveProjectCompanyCustomFieldSuccess({
                  data
                }),
                ProjectCompanyCustomFieldsActions.LoadProjectCompanyCustomFields()
              ]),
              catchError(error =>
                of(
                  ProjectCompanyCustomFieldsActions.LoadProjectCompanyCustomFieldsFailure(
                    { error }
                  )
                )
              )
            );
        }
      })
    );
  });

  deleteProjectCompanyCustomField$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectCompanyCustomFieldsActions.DeleteProjectCompanyCustomField),
      map(action => action.data),
      concatMap((pcf: ProjectCustomField) =>
        this.projService.deleteProjectCompanyCustomField(pcf).pipe(
          switchMap(data => [
            ProjectCompanyCustomFieldsActions.DeleteProjectCompanyCustomFieldSuccess({
              data
            }),
            ProjectCompanyCustomFieldsActions.LoadProjectCompanyCustomFields()
          ]),
          catchError(error =>
            of(
              ProjectCompanyCustomFieldsActions.DeleteProjectCompanyCustomFieldFailure({
                error
              })
            )
          )
        )
      )
    );
  });

  updateProjectCompanyCustomFieldsListOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectCompanyCustomFieldsActions.UpdateProjectCompanyCustomFieldsListOrder),
      map(action => action.data),
      concatMap((pCFs: ProjectCustomField[]) =>
        this.projService.updateProjectCompanyCustomFieldsListOrder(pCFs).pipe(
          map(data =>
            ProjectCompanyCustomFieldsActions.UpdateProjectCompanyCustomFieldsListOrderSuccess(
              {
                data
              }
            )
          ),
          catchError(error =>
            of(
              ProjectCompanyCustomFieldsActions.UpdateProjectCompanyCustomFieldsListOrderFailure(
                {
                  error
                }
              )
            )
          )
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private projService: ProjectsService,
    private store: Store<RootState>
  ) {}
}
