import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  OnDestroy,
  AfterViewInit,
  OnChanges
} from '@angular/core';

import { FormControl } from '@angular/forms';
import { tap, takeUntil, take } from 'rxjs/operators';
import { Observable, Subscription, EMPTY, Subject, ReplaySubject } from 'rxjs';
import { IdNameItem } from 'src/app/models/id-name-item';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'tn-checkbox-multi-select',
  templateUrl: './checkbox-multi-select.component.html',
  styleUrls: ['./checkbox-multi-select.component.scss']
})
export class CheckboxMultiSelectComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
  @Input() selectedItems: IdNameItem[] = [];
  @Output() selectedItemsChange = new EventEmitter<IdNameItem[]>();
  @Input() $allItems: Observable<IdNameItem[]> = EMPTY;
  @Input() placeholder: string;
  selectableItems: IdNameItem[] = [];
  $inputCtrlSub: Subscription;
  allItems: IdNameItem[] = [];

  constructor() {}

  /** control for the selected input for multi-selection */
  public itemInputCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword multi-selection */
  public itemMultiFilterCtrl = new FormControl('');

  /** list of items filtered by search keyword */
  public filteredItemsMulti: ReplaySubject<IdNameItem[]> = new ReplaySubject<
    IdNameItem[]
  >(1);

  /** local copy of filtered banks to help set the toggle all checkbox state */
  protected filteredItemsCache: IdNameItem[] = [];

  /** flags to set the toggle all checkbox state */
  isIndeterminate = false;
  isChecked = false;

  @ViewChild('multiSelect', { static: true }) multiSelect: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  ngOnInit(): void {
    // set intial selection
    this.itemInputCtrl.setValue(this.selectedItems);

    // load the initial item list
    this.$allItems
      .pipe(
        tap((items: IdNameItem[]) => {
          this.allItems = items;
        })
      )
      .subscribe();

    this.itemMultiFilterCtrl.setValue(null);

    this.filteredItemsMulti.next(this.allItems);

    // listen for search field value changes
    this.itemMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterItemsMulti();
        this.setToggleAllCheckboxState();
      });

    // listen for multi select field value changes
    this.itemInputCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.setToggleAllCheckboxState();
      this.selectedItemsChange.emit(this.itemInputCtrl.value);
    });
  }

  ngOnChanges() {
    if (JSON.stringify(this.itemInputCtrl.value) !== JSON.stringify(this.selectedItems)) {
      this.itemInputCtrl.setValue(this.selectedItems);
    }
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  toggleSelectAll(selectAllValue: boolean) {
    this.filteredItemsMulti.pipe(take(1), takeUntil(this._onDestroy)).subscribe((val) => {
      if (selectAllValue) {
        this.itemInputCtrl.patchValue(val);
      } else {
        this.itemInputCtrl.patchValue([]);
      }
    });
  }

  /**
   * Sets the initial value after the filteredItems are loaded initially
   */
  protected setInitialValue() {
    this.filteredItemsMulti.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
      // setting the compareWith property to a comparison function
      // triggers initializing the selection according to the initial value of
      // the form control (i.e. _initializeSelection())
      // this needs to be done after the filteredItems are loaded initially
      // and after the mat-option elements are available
      this.multiSelect.compareWith = (a: IdNameItem, b: IdNameItem) =>
        a && b && a.id === b.id;
    });
  }

  protected filterItemsMulti() {
    if (!this.allItems) {
      return;
    }
    // get the search keyword
    let search = this.itemMultiFilterCtrl.value;
    if (!search) {
      this.filteredItemsCache = this.allItems;
      this.filteredItemsMulti.next(this.filteredItemsCache);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the items
    this.filteredItemsCache = this.allItems.filter(
      (item) => item.name.toLowerCase().indexOf(search) > -1
    );
    this.filteredItemsCache.push(
      ...this.selectedItems.filter(
        (item) => !this.filteredItemsCache.find((el) => el.id === item.id)
      )
    );
    this.filteredItemsMulti.next(this.filteredItemsCache);
  }

  protected setToggleAllCheckboxState() {
    let filteredLength = 0;
    if (this.itemInputCtrl && this.itemInputCtrl.value) {
      this.filteredItemsCache.forEach((el) => {
        if (this.itemInputCtrl.value.indexOf(el) > -1) {
          filteredLength++;
        }
      });
      this.isIndeterminate =
        filteredLength > 0 && filteredLength < this.filteredItemsCache.length;
      this.isChecked =
        filteredLength > 0 && filteredLength === this.filteredItemsCache.length;
    }
  }
}
