<div fxLayout="column">
  <mat-tab-group [dynamicHeight]="true">
    <mat-tab label="Territory Companies">
      <div fxLayout="column" class="form-tab-container">
        <mat-form-field fxFlex="1 1 100">
          <input
            matInput
            [formControl]="companyFilterCtrl"
            autocomplete="off"
            placeholder="Filter companies"
          />
        </mat-form-field>

        <table mat-table [dataSource]="$filteredCompanies | async">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let company">{{ company.name }}</td>
          </ng-container>

          <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef>Address</th>
            <td mat-cell *matCellDef="let company">{{ company.address }}</td>
          </ng-container>

          <ng-container matColumnDef="remove">
            <th mat-header-cell *matHeaderCellDef>Remove</th>
            <td mat-cell *matCellDef="let company">
              <button mat-icon-button (click)="onRemoveCompany(company.id)">
                <mat-icon>remove_circle_outline</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="filteredColumns"></tr>
          <tr mat-row *matRowDef="let company; columns: filteredColumns"></tr>
        </table>
      </div>
    </mat-tab>
    <mat-tab label="Search All Companies">
      <div fxLayout="column" class="form-tab-container">
        <mat-form-field fxFlex="1 1 100">
          <mat-icon class="search-icon" matPrefix>search</mat-icon>
          <input
            matInput
            [formControl]="companySearchCtrl"
            autocomplete="off"
            placeholder="Search companies"
          />
          <span matSuffix class="loading-icon">
            <mat-spinner *ngIf="$pending | async" diameter="16"></mat-spinner>
            <mat-icon *ngIf="$error | async" color="warn">error</mat-icon>
          </span>
        </mat-form-field>

        <table mat-table [dataSource]="$selectableCompanies | async">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let company">{{ company.name }}</td>
          </ng-container>

          <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef>Address</th>
            <td mat-cell *matCellDef="let company">{{ company.address }}</td>
          </ng-container>

          <ng-container matColumnDef="toggleRelation">
            <th mat-header-cell *matHeaderCellDef>Add / Remove</th>
            <td mat-cell *matCellDef="let company">
              <mat-checkbox
                [checked]="company.selected"
                (change)="onCheckboxChanged(company.id, $event)"
              >
              </mat-checkbox>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="searchedColumns"></tr>
          <tr mat-row *matRowDef="let company; columns: searchedColumns"></tr>
        </table>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
