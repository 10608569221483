import { MemoizedSelector, createSelector } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import { ProjectField } from '../../../../models/admin/projects/project-field';

export const selectAllProjectFields = (state: RootState) => state.projectFields.data;

export const selectProjectFieldsAsArray: MemoizedSelector<
  RootState,
  ProjectField[]
> = createSelector(
  selectAllProjectFields,
  (projectFieldsMap: { [id: number]: ProjectField }): ProjectField[] => {
    return Object.values(projectFieldsMap);
  }
);
