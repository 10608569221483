<dx-popup
  [position]="{ my: 'right', at: 'right', of: window }"
  [width]="600"
  [height]="'100vh'"
  [showTitle]="false"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [(visible)]="fileUploadPopup"
  shadingColor="rgba(0,0,0,0.5)"
>
  <div *dxTemplate="let data of 'content'">
    <div fxLayout="column" style="height: calc(100% - 50px);">
      <dx-scroll-view [height]="'inherit'">
        <div class="popup-title">
          <span>File Upload</span>
        </div>
        <!-- <div fxLayout="column" style="padding-top: 10px;">
          <span>File Categories</span>
          <dx-select-box
            [dataSource]="fileCategories"
            displayExpr="Name"
            valueExpr="Id"
            [(value)]="file.CategoryId"
            placeholder="Choose file category (optional)"
          ></dx-select-box>
        </div> -->
        <form enctype="multipart/form-data" fxLayout="column">
          <input
            id="uploadImage"
            type="file"
            style="padding-top: 15px;"
            (change)="uploadFile($event)"
          />
          <div>
            <img *ngIf="url" style="padding-top: 15px" [src]="url" />
          </div>
        </form>
      </dx-scroll-view>
      <div *ngIf="notSupportedType" style="color: red; text-align: center">
        <p>
          *At this time the selected file type is not able to be uploaded, the following
          file types are accepted:
        </p>
        <ul fxLayout="row" fxLayoutAlign="space-evenly center">
          <div>
            <li>png</li>
            <li>jpeg</li>
            <li>jpg</li>
          </div>
          <div>
            <li>gif</li>
            <li>tiff</li>
            <li>svg</li>
          </div>
          <div>
            <li>pdf</li>
            <li>xlsx</li>
            <li>xls</li>
          </div>
          <div>
            <li>doc</li>
            <li>docx</li>
            <li>ppt</li>
          </div>
          <div>
            <li>pptx</li>
            <li>txt</li>
            <li>rtf</li>
            <li>csv</li>
          </div>
        </ul>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="end end">
      <div fxFlex fxLayoutAlign="end end">
        <span class="button button-white" (click)="cancelFileUpload()">Cancel</span>
        <span
          class="button button-blue"
          (click)="
            finishingUpload(
              fileUploaded,
              companyId,
              callNoteId,
              contactId,
              file.CategoryId
            )
          "
          >Save</span
        >
      </div>
    </div>
  </div>
</dx-popup>

<dx-popup
  [width]="350"
  [height]="150"
  [showTitle]="false"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [(visible)]="uploadingFilePopup"
  shadingColor="rgba(0,0,0,0.5)"
>
  <div *dxTemplate="let data of 'content'">
    <div fxFlex fxLayout="column" fxLayoutAlign="center center">
      <dx-load-indicator id="medium-indicator" height="40" width="40"></dx-load-indicator>
      <h3>Uploading File...</h3>
    </div>
  </div>
</dx-popup>
