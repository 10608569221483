import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TerritoryV2 } from '../../../shared/models/admin/territories/territory-V2';
import { UserV2 } from '../../../shared/models/admin/users/user-V2';

@Component({
  selector: 'current-territory-assignments',
  templateUrl: './current-territory-assignments.component.html',
  styleUrls: ['../companies.scss', '../../../shared/styles/popups.scss']
})
export class CurrentTerritoryAssignmentsComponent {
  @Input() currentTerritoryAssignments: TerritoryV2[];
  @Input() selectedRows: number;
  @Input() selectPrimaryUser: boolean;
  @Input() newPrimaryUser: UserV2;
  @Input() primaryUserTerritory: TerritoryV2;
  @Output() newPrimaryUserChange: EventEmitter<UserV2> = new EventEmitter<UserV2>();
  @Output() primaryUserTerritoryChange: EventEmitter<TerritoryV2> = new EventEmitter<
    TerritoryV2
  >();
  sortingUsers: boolean;

  constructor() {}

  expandTerritory(territory: TerritoryV2): void {
    for (let i = 0; i < this.currentTerritoryAssignments.length; i++) {
      if (this.currentTerritoryAssignments[i].id == territory.id) {
        (<any>this.currentTerritoryAssignments[i]).expanded = !(<any>(
          this.currentTerritoryAssignments[i]
        )).expanded;
        break;
      }
    }
  }

  primaryUserChanged(user: UserV2, territory: TerritoryV2): void {
    this.primaryUserTerritory = territory;
    this.newPrimaryUser = user;
    this.newPrimaryUserChange.emit(this.newPrimaryUser);
    this.primaryUserTerritoryChange.emit(this.primaryUserTerritory);
  }
}
