<contact-popup
  *ngIf="editContactPopup"
  [(contactPopup)]="editContactPopup"
  [(contact)]="selectedContact"
  [(contacts)]="companyJPI.contacts"
  [(contactDetails)]="standardContactFields"
  [(contactCustomFields)]="contactCustomFields"
  [(companyId)]="companyId"
></contact-popup>
<contact-popup
  *ngIf="contactPopup$"
  [(contactPopup)]="contactPopup$"
  [(contacts)]="companyJPI.contacts"
  [(contactDetails)]="standardContactFields"
  [(contactCustomFields)]="contactCustomFields"
  [(companyId)]="companyId"
>
</contact-popup>
<call-note-popup
  *ngIf="callNotePopup"
  [(callNotePopup)]="callNotePopup"
  [(callNote)]="selectedCallNote"
  [(contacts)]="companyJPI.contacts"
  [(companyId)]="companyJPI.id"
></call-note-popup>

<div class="panel-padding">
  <div
    class="loading-container"
    *ngIf="loadingContacts"
    fxFlex
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <dx-load-indicator id="medium-indicator" height="40" width="40"></dx-load-indicator>
    <h3>Loading...</h3>
  </div>
  <div *ngIf="!loadingContacts">
    <dx-data-grid
      *ngIf="companyJPI.contacts.length != 0"
      [dataSource]="companyJPI.contacts"
      [showColumnLines]="false"
      [showRowLines]="true"
      [showBorders]="true"
      [rowAlternationEnabled]="true"
      (onRowClick)="editContact($event)"
      (mouseup)="contactMouseUp($event)"
      (mousedown)="contactMouseDown($event)"
    >
      <dxo-search-panel
        [visible]="true"
        [width]="250"
        placeholder="Search..."
      ></dxo-search-panel>
      <dxi-column cellTemplate="addCallNoteTemplate" width="40px"></dxi-column>
      <dxi-column
        caption="Name"
        [calculateCellValue]="calculateFullName"
        [allowSearch]="true"
        [allowSorting]="true"
      ></dxi-column>
      <dxi-column dataField="title"></dxi-column>
      <dxi-column dataField="dept"></dxi-column>
      <dxi-column dataField="email" cellTemplate="emailTemplate"></dxi-column>
      <div *dxTemplate="let contact of 'addCallNoteTemplate'">
        <span *ngIf="editContactsFeature == 1">
          <mat-icon style="cursor: pointer">note_add</mat-icon>
        </span>
      </div>
      <div *dxTemplate="let email of 'emailTemplate'">
        <a class="link" [href]="'mailto:' + email.data.email">{{ email.data.email }}</a>
      </div>
      <dxi-column
        dataField="phone"
        [calculateCellValue]="phoneValue"
        caption="Phone"
      ></dxi-column>
      <dxi-column dataField="ext" caption="Ext"></dxi-column>
      <dxi-column
        dataField="fax"
        [calculateCellValue]="mobileValue"
        caption="Mobile"
      ></dxi-column>
      <dxi-column
        dataField="notes"
        caption="Details"
        [allowSearch]="false"
        [allowSorting]="false"
      ></dxi-column>
    </dx-data-grid>
    <div *ngIf="companyJPI.contacts.length == 0" style="padding-top: 10px">
      There are no contacts for&nbsp;{{ companyJPI.name }}.
    </div>
  </div>
</div>
