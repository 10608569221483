import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import {
  SearchCompanies,
  ClearSearchCompanies,
  LoadCompanyContacts,
  SelectSearchedCompany
} from 'src/app/store/companies/companies.actions';
import { Expense } from 'src/app/models/activities/expense';
import {
  UpdateExpense,
  DeleteExpense,
  CreateExpense,
  CancelExpense,
  LoadExpenseTypes,
  DeleteExpenseFile
} from 'src/app/store/expenses/expenses.actions';
import { CloseDrawer } from 'src/app/store/layout/layout.actions';
import { GlobalFilter } from 'src/app/models/global-filter';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { IdNameItem } from 'src/app/models/id-name-item';
import { selectMultiSelectUsers } from 'src/app/store/users/selectors/multi-select-users.selector';
import { map, take, withLatestFrom } from 'rxjs/operators';
import { selectCompanyContacts } from 'src/app/store/companies/selectors/company-contacts.selector';
import { CompanySelectItem } from 'src/app/models/companies/company-select';
import { ExpenseType } from '../../../../models/activities/expense-type';
import { selectSelectedExpense } from 'src/app/store/expenses/selectors/selected-expense.selector';
import { selectIdNameCurrentUser } from 'src/app/store/users/selectors/id-name-current-user.selector';
import { Company } from 'src/app/models/companies/company';
import { selectCurrentCompany } from 'src/app/store/companies/selectors/current-company.selector';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDeleteComponent } from 'src/app/view/shared/confirm-delete/confirm-delete.component';
import { selectHasExpensesAdmin } from 'src/app/store/expenses/selectors/has-expense-admin.selector';
import { FilesService } from 'src/app/services/files/files.service';

@Component({
  selector: 'tn-expense-form',
  templateUrl: './expense-form.component.html',
  styleUrls: ['./expense-form.component.css']
})
export class ExpenseFormComponent implements OnInit, OnDestroy {
  expenseForm: FormGroup = new FormGroup({
    agencyId: new FormControl(null),
    createdByUser: new FormControl(null),
    createdById: new FormControl(null),
    assignedToUser: new FormControl(null, Validators.required),
    assignedToId: new FormControl(null),
    expenseDate: new FormControl(new Date(), Validators.required),
    company: new FormControl(null, Validators.required),
    companyId: new FormControl(null),
    contacts: new FormControl(),
    type: new FormControl(null, Validators.required),
    purpose: new FormControl(null, Validators.required),
    amount: new FormControl(null, Validators.required),
    id: new FormControl(),
    location: new FormControl(),
    createdDate: new FormControl(),
    typeId: new FormControl(null),
    files: new FormControl([]),
    reimbursement: new FormControl(null)
  });
  $currentExpense: Observable<Expense>;
  $expenseTypes: Observable<ExpenseType[]>;
  $companies: Observable<CompanySelectItem[]>;
  $companiesSearchPending: Observable<boolean>;
  $companiesSearchError: Observable<HttpErrorResponse>;
  $companyContacts: Observable<IdNameItem[]>;
  $searching: Observable<boolean>;
  $currentExpenseSub: Subscription;
  $contactSub: Subscription;
  $allUsers: Observable<IdNameItem[]>;
  $drawerOpenedSub: Subscription;
  $currentUser: Observable<IdNameItem>;
  currentExpense: Expense;
  $contactsEventsSubject: Subject<void> = new Subject<void>();
  $company: Observable<Company>;
  $companySub: Subscription;
  company: Company;
  $hasExpensesAdmin: Observable<boolean>;

  constructor(
    private store: Store<RootState>,
    public dialog: MatDialog,
    private fs: FilesService
  ) {}

  ngOnInit() {
    this.$drawerOpenedSub = this.store
      .select('layout', 'drawerOpened')
      .subscribe((drawerOpened: boolean) => {
        if (!drawerOpened) {
          this.cancelButtonClicked();
        }
      });
    this.store.dispatch(new LoadExpenseTypes());
    this.store.dispatch(new ClearSearchCompanies());
    this.$hasExpensesAdmin = this.store.select(selectHasExpensesAdmin);
    this.$expenseTypes = this.store.select('expenses', 'expenseTypes');
    this.$currentUser = this.store.select(selectIdNameCurrentUser);
    this.$company = this.store.select(selectCurrentCompany);
    this.$companySub = this.$company.subscribe((company: Company) => {
      if (!!company) {
        this.company = company;
        this.expenseForm.controls.company.setValue(company);
        this.store.dispatch(new SelectSearchedCompany(company.id));
        this.store.dispatch(new LoadCompanyContacts(this.company.id));
      } else {
        this.store.dispatch(new ClearSearchCompanies());
      }
    });
    this.$companies = this.store.select('companies', 'search', 'data');
    this.$companiesSearchPending = this.store.select('companies', 'search', 'pending');
    this.$companiesSearchError = this.store.select('companies', 'search', 'error');
    // this.store.dispatch(new LoadUsers());
    this.$allUsers = this.store.select(selectMultiSelectUsers).pipe(
      map((users: IdNameItem[]) => {
        if (!!users.length) {
          return users;
        } else {
          return [];
        }
      })
    );
    this.$currentExpense = this.store.select(selectSelectedExpense);
    this.$companyContacts = this.store.select(selectCompanyContacts);
    this.$currentExpenseSub = this.$currentExpense
      .pipe(
        withLatestFrom(this.$currentUser),
        map((expenseAndUser) => ({
          expense: expenseAndUser[0],
          user: expenseAndUser[1]
        }))
      )
      .subscribe(({ expense, user }) => {
        const initialExpense: Expense = {
          assignedToUser: user,
          createdByUser: user,
          createdById: user.id,
          company: this.company,
          contacts: [],
          amount: undefined,
          expenseDate: new Date(),
          purpose: null,
          type: undefined,
          location: null,
          createdDate: new Date(),
          files: [],
          reimbursement: false
        };
        if (!!expense && !!expense.company) {
          this.currentExpense = expense;
          this.store.dispatch(new SelectSearchedCompany(expense.company.id));
          this.store.dispatch(new LoadCompanyContacts(expense.company.id));
        } else {
          this.currentExpense = initialExpense;
        }
        this.expenseForm.patchValue({
          createdByUser: this.currentExpense.createdByUser,
          createdById: this.currentExpense.createdById,
          assignedToUser: this.currentExpense.assignedToUser,
          company: this.currentExpense.company,
          type: this.currentExpense.type,
          contacts: this.currentExpense.contacts,
          amount: this.currentExpense.amount,
          purpose: this.currentExpense.purpose,
          expenseDate: this.currentExpense.expenseDate,
          id: this.currentExpense.id,
          location: this.currentExpense.location,
          files: this.currentExpense.files,
          reimbursement: this.currentExpense.reimbursement,
          createdDate: this.currentExpense.createdDate
        });
      });
    this.expenseForm.markAllAsTouched();
  }

  ngOnDestroy() {
    this.expenseForm.reset();
    this.$drawerOpenedSub.unsubscribe();
    this.$currentExpenseSub.unsubscribe();
  }

  saveButtonClicked(form) {
    form.companyId = this.expenseForm.value.company.id;
    form.typeId = this.expenseForm.value.type.id;
    form.assignedToId = this.expenseForm.value.assignedToUser.id;
    form.agencyId = this.expenseForm.value.type.agencyId;
    this.store.dispatch(new UpdateExpense(form));
  }

  createButtonClicked(form) {
    form.companyId = this.expenseForm.value.company.id;
    form.typeId = this.expenseForm.value.type.id;
    form.assignedToId = this.expenseForm.value.assignedToUser.id;
    form.agencyId = this.expenseForm.value.type.agencyId;
    form.createdDate = new Date();
    this.store.dispatch(new CreateExpense(form));
  }

  cancelButtonClicked(): void {
    this.store.dispatch(new CancelExpense());
  }

  deleteButtonClicked(expense: Expense): void {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {});

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((willDelete: boolean) => {
        if (willDelete) {
          this.store.dispatch(new CloseDrawer());
          this.store.dispatch(new DeleteExpense(expense.id));
        }
      });
  }

  compareObjects(o1: any, o2: any) {
    if (!!o1 && !!o2) {
      return o1.type === o2.type && o1.id && o2.id;
    }
  }

  onCompanySearch(searchVal: string) {
    this.expenseForm.controls.company.setValue(undefined);
    if (!!searchVal.length) {
      const reqFilter: GlobalFilter = {
        filters: [
          {
            operand1: 'searchField',
            operator: 'CONTAINS_ALL_OF',
            operand2: [searchVal]
          }
        ]
      };
      this.store.dispatch(new SearchCompanies(reqFilter));
    } else {
      this.store.dispatch(new ClearSearchCompanies());
    }
  }

  onCompanySelect(event: MatAutocompleteSelectedEvent) {
    this.expenseForm.markAsDirty();
    this.store.dispatch(new LoadCompanyContacts(event.option.value.id));
    this.store.dispatch(new SelectSearchedCompany(event.option.value.id));
    this.expenseForm.controls.contacts.setValue([]);
    this.currentExpense.contacts = [];
    this.expenseForm.controls.company.setValue(event.option.value);
  }

  onAssignedUserSelected(itemSelected: IdNameItem) {
    this.expenseForm.markAsDirty();
    this.expenseForm.controls.assignedToUser.setValue(itemSelected);
  }

  userValidation(isValid: boolean) {
    if (!isValid) {
      this.expenseForm.setErrors({ error: 'User must be selected' });
    }
  }

  setContacts(contacts: IdNameItem[]) {
    this.expenseForm.markAsDirty();
    this.expenseForm.controls.contacts.setValue(contacts);
  }

  onDelete(fileId: number) {
    this.store.dispatch(new DeleteExpenseFile(fileId));
  }

  download(file) {
    let date = new Date(this.currentExpense.expenseDate)
      .toLocaleString()
      .split(',')[0]
      .replaceAll('/', '-');
    let dotIndex = file.fileName.lastIndexOf('.');
    let ext = file.fileName.substring(dotIndex);
    file.fileName =
      this.currentExpense.id +
      '_' +
      file.id +
      '_' +
      this.currentExpense.assignedToUser.name +
      '_' +
      date +
      '_' +
      this.currentExpense.company.name +
      '_' +
      this.currentExpense.amount +
      ext;

    this.fs.downloadFile(file);
  }
}
