import { Action } from '@ngrx/store';
import { ColumnHeader } from 'src/app/models/column-header';
import { HttpErrorResponse } from '@angular/common/http';
import { GenericMap } from 'src/app/models/generic-map';
import { Opportunity } from 'src/app/models/Opportunity/opportunity';
import { FileMetaData } from 'src/app/models/file-meta-data';
import { ItemCustomField } from 'src/app/models/Opportunity/item-custom-field';
import { Filters, GlobalFilter } from 'src/app/models/global-filter';
import { ElasticOpportunity } from 'src/app/models/Opportunity/elastic-opportunity';
import { ElasticOppItem } from 'src/app/models/Opportunity/elastic-opp-item';
import { OpportunityCustomField } from 'src/app/models/Opportunity/opportunity-custom-field';
import { OpportunityCustomFieldValue } from 'src/app/models/Opportunity/opportunity-custom-field-value';

export enum OpportunitiesActionTypes {
  AddLabelColumns = '[Opportunities] Add Label Columns',
  LabelColumnsAdded = '[Opportunities] Label Columns Added',
  LabelColumnAdded = '[Opportunities] Label Column Added',
  NoLabelColsAdded = '[Opportunities] No Label Columns Added',

  SelectOppTableColumns = '[Opportunities] Select Opp Table Columns',
  SelectOppItemTableColumns = '[Opportunities] Select Opp Item Table Columns',

  SelectOpportunityId = '[Opportunities] Select Opportunity ID',
  ClearOpportunityId = '[Opportunities] Clear Selected Opportunity ID',
  SelectTableColumns = '[Opportunities] Select Table Columns',
  SelectItemTableColumns = '[Opportunities] Select Item Table Columns',
  RedirectToOpportunities = '[Opportunities] Redirect To Opportunities',
  RedirectToOpportunity = '[Opportunities] Redirect To Opportunity',

  LoadOpportunitiesTableColumns = '[Opportunities] Load Opportunities Table Columns',
  LoadOpportunitiesTableColumnsSuccess = '[Opportunities] Load Opportunities Table Columns Success',
  LoadOpportunitiesTableColumnsFailure = '[Opportunities] Load Opportunities Table Columns Failure',

  LoadOpportunityItemsTableColumns = '[Opportunities] Load Opportunity Items Table Columns',
  LoadOpportunityItemsTableColumnsSuccess = '[Opportunities] Load Opportunity Items Table Columns Success',
  LoadOpportunityItemsTableColumnsFailure = '[Opportunities] Load Opportunity Items Table Columns Failure',

  LoadOpportunities = '[Opportunities] Load Opportunities',
  LoadOpportunitiesSuccess = '[Opportunities] Load Opportunities Success',
  LoadOpportunitiesFailure = '[Opportunities] Load Opportunities Failure',

  LoadOpportunitiesElastic = '[Opportunities] Load Opportunities Elastic',
  LoadOpportunitiesElasticSuccess = '[Opportunities] Load Opportunities Elastic Success',
  LoadOpportunitiesElasticFailure = '[Opportunities] Load Opportunities ElasticFailure',

  LoadOppItemsElastic = '[Opportunities] Load Opp Items Elastic',
  LoadOppItemsElasticSuccess = '[Opportunities] Load Opp Items Elastic Success',
  LoadOppItemsElasticFailure = '[Opportunities] Load Opp Items ElasticFailure',

  LoadOpportunity = '[Opportunities] Load  Opportunity',
  LoadOpportunitySuccess = '[Opportunities] Load  Opportunity Success',
  LoadOpportunityFailure = '[Opportunities] Load  Opportunity Failure',

  SaveOpportunity = '[Opportunities] Save Opportunity',
  SaveOpportunitySuccess = '[Opportunities] Save Opportunity Success',
  SaveOpportunityFailure = '[Opportunities] Save Opportunity Failure',

  DeleteOpportunity = '[Opportunities] Delete Opportunity',
  DeleteOpportunitySuccess = '[Opportunities] Delete Opportunity Success',
  DeleteOpportunitySuccessNoRedirect = '[Opportunities] Delete Opportunity Success No Direct',
  DeleteOpportunityFailure = '[Opportunities] Delete Opportunity Failure',

  UploadOpportunityFile = '[Opportunities] Upload Opportunity File',
  UploadOpportunityFileSuccess = '[Opportunities] Upload Opportunity File Success',
  UploadOpportunityFileFailure = '[Opportunities] Upload Opportunity File Failure',

  DeleteOpportunityFile = '[Opportunities] Delete Opportunity File',
  DeleteOpportunityFileSuccess = '[Opportunities] Delete Opportunity File Success',
  DeleteOpportunityFileFailure = '[Opportunities] Delete Opportunity File Failure',

  LoadOppItemCustomFields = '[Opportunities] Load Opp Item Custom Fields',
  LoadOppItemCustomFieldsSuccess = '[Opportunities] Load Opp Item Custom Fields Success',
  LoadOppItemCustomFieldsFailure = '[Opportunities] Load Opp Item Custom Fields Failure',

  LoadOppItemCustomFieldValues = '[Opportunities] Load Opp Item Custom Field Values',
  LoadOppItemCustomFieldValuesSuccess = '[Opportunities] Load Opp Item Custom Field Values Success',
  LoadOppItemCustomFieldValuesFailure = '[Opportunities] Load Opp Item Custom Field Values Failure',

  CreateOppItemCustomField = '[Opportunities] Create Opp Item Custom Field',
  CreateOppItemCustomFieldSuccess = '[Opportunities] Create Opp Item Custom Field Success',
  CreateOppItemCustomFieldFailure = '[Opportunities] Create Opp Item Custom Field Failure',

  UpdateOppItemCustomField = '[Opportunities] Update Opp Item Custom Field',
  UpdateOppItemCustomFieldSuccess = '[Opportunities] Update Opp Item Custom Field Success',
  UpdateOppItemCustomFieldFailure = '[Opportunities] Update Opp Item Custom Field Failure',

  DeleteOppItemCustomField = '[Opportunities] Delete Opp Item Custom Field',
  DeleteOppItemCustomFieldSuccess = '[Opportunities] Delete Opp Item Custom Field Success',
  DeleteOppItemCustomFieldFailure = '[Opportunities] Delete Opp Item Custom Field Failure',

  LoadOpportunityCustomFields = '[Opportunities] Load Opportunity Custom Fields',
  LoadOpportunityCustomFieldsSuccess = '[Opportunities] Load Opportunity Custom Fields Success',
  LoadOpportunityCustomFieldsFailure = '[Opportunities] Load Opportunity Custom Fields Failure',

  LoadOpportunityCustomFieldValues = '[Opportunities] Load Opportunity Custom Field Values',
  LoadOpportunityCustomFieldValuesSuccess = '[Opportunities] Load Opportunity Custom Field Values Success',
  LoadOpportunityCustomFieldValuesFailure = '[Opportunities] Load Opportunity Custom Field Values Failure',

  CreateOpportunityCustomField = '[Opportunities] Create Opportunity Custom Field',
  CreateOpportunityCustomFieldSuccess = '[Opportunities] Create Opportunity Custom Field Success',
  CreateOpportunityCustomFieldFailure = '[Opportunities] Create Opportunity Custom Field Failure',

  UpdateOpportunityCustomField = '[Opportunities] Update Opportunity Custom Field',
  UpdateOpportunityCustomFieldSuccess = '[Opportunities] Update Opportunity Custom Field Success',
  UpdateOpportunityCustomFieldFailure = '[Opportunities] Update Opportunity Custom Field Failure',

  DeleteOpportunityCustomField = '[Opportunities] Delete Opportunity Custom Field',
  DeleteOpportunityCustomFieldSuccess = '[Opportunities] Delete Opportunity Custom Field Success',
  DeleteOpportunityCustomFieldFailure = '[Opportunities] Delete Opportunity Custom Field Failure',

  SaveOpportunityCustomFieldValue = '[Opportunities] Save Opportunity Custom FieldValue',
  SaveOpportunityCustomFieldValueSuccess = '[Opportunities] Save Opportunity Custom Field Value Success',
  SaveOpportunityCustomFieldValueFailure = '[Opportunities] Save Opportunity Custom Field Value Failure',

  DeleteOpportunityCustomFieldValue = '[Opportunities] Delete Opportunity Custom FieldValue',
  DeleteOpportunityCustomFieldValueSuccess = '[Opportunities] Delete Opportunity Custom Field Value Success',
  DeleteOpportunityCustomFieldValueFailure = '[Opportunities] Delete Opportunity Custom Field Value Failure',

  ReorderOpportunityCustomFields = '[Opportunities] Reorder Opportunity Custom Fields',
  ReorderOpportunityCustomFieldsSuccess = '[Opportunities] Reorder Opportunity Custom Fields Success',
  ReorderOpportunityCustomFieldsFailure = '[Opportunities] Reorder Opportunity Custom Fields Failure',

  SetCurrentOppItemCustomFieldId = '[Opportunities] Set Current Opp Item Custom Field',
  SetCurrentOpportunityCustomFieldId = '[Opportunities] Set Current Opportunity Custom Field',
  SetCurrentOppItemId = '[Opportunities] Set Current Opp Item ID',

  ShowClosedOpps = '[Opportunities] Show Closed Opps',
  SetOppsCount = '[Opportunities] Set Opps Count',
  SetItemsCount = '[Opportunities] Set Items Count',
  ShowOpenFollowUpCallNotes = '[Opportunities] ShowOpenFollowUpCallNotes'
}

export class AddLabelColumns implements Action {
  readonly type = OpportunitiesActionTypes.AddLabelColumns;
}

export class LabelColumnAdded implements Action {
  readonly type = OpportunitiesActionTypes.LabelColumnAdded;
  constructor(public payload: ColumnHeader) {}
}

export class LabelColumnsAdded implements Action {
  readonly type = OpportunitiesActionTypes.LabelColumnsAdded;
  constructor(public payload: ColumnHeader[]) {}
}

export class NoLabelColsAdded implements Action {
  readonly type = OpportunitiesActionTypes.NoLabelColsAdded;
}

export class SelectOppTableColumns implements Action {
  readonly type = OpportunitiesActionTypes.SelectOppTableColumns;
  constructor(public payload: ColumnHeader[]) {}
}

export class SelectOppItemTableColumns implements Action {
  readonly type = OpportunitiesActionTypes.SelectOppItemTableColumns;
  constructor(public payload: ColumnHeader[]) {}
}

export class SelectOpportunityId implements Action {
  readonly type = OpportunitiesActionTypes.SelectOpportunityId;
}

export class ClearOpportunityId implements Action {
  readonly type = OpportunitiesActionTypes.ClearOpportunityId;
}

export class SelectTableColumns implements Action {
  readonly type = OpportunitiesActionTypes.SelectTableColumns;
  constructor(public payload: ColumnHeader[]) {}
}

export class SelectItemTableColumns implements Action {
  readonly type = OpportunitiesActionTypes.SelectItemTableColumns;
  constructor(public payload: ColumnHeader[]) {}
}

export class RedirectToOpportunities implements Action {
  readonly type = OpportunitiesActionTypes.RedirectToOpportunities;
}
export class RedirectToOpportunity implements Action {
  readonly type = OpportunitiesActionTypes.RedirectToOpportunity;
  constructor(public payload: number) {}
}

export class LoadOpportunitiesTableColumns implements Action {
  readonly type = OpportunitiesActionTypes.LoadOpportunitiesTableColumns;
}

export class LoadOpportunitiesTableColumnsSuccess implements Action {
  readonly type = OpportunitiesActionTypes.LoadOpportunitiesTableColumnsSuccess;
  constructor(public payload: ColumnHeader[]) {}
}

export class LoadOpportunitiesTableColumnsFailure implements Action {
  readonly type = OpportunitiesActionTypes.LoadOpportunitiesTableColumnsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadOpportunityItemsTableColumns implements Action {
  readonly type = OpportunitiesActionTypes.LoadOpportunityItemsTableColumns;
}

export class LoadOpportunityItemsTableColumnsSuccess implements Action {
  readonly type = OpportunitiesActionTypes.LoadOpportunityItemsTableColumnsSuccess;
  constructor(public payload: ColumnHeader[]) {}
}

export class LoadOpportunityItemsTableColumnsFailure implements Action {
  readonly type = OpportunitiesActionTypes.LoadOpportunityItemsTableColumnsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadOpportunities implements Action {
  readonly type = OpportunitiesActionTypes.LoadOpportunities;
}

export class LoadOpportunitiesSuccess implements Action {
  readonly type = OpportunitiesActionTypes.LoadOpportunitiesSuccess;
  constructor(public payload: GenericMap<Opportunity>) {}
}

export class LoadOpportunitiesFailure implements Action {
  readonly type = OpportunitiesActionTypes.LoadOpportunitiesFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadOpportunitiesElastic implements Action {
  readonly type = OpportunitiesActionTypes.LoadOpportunitiesElastic;
  constructor(
    public payload: {
      filters: Filters[];
      should?: Filters[];
      andShould?: Filters[][];
      orLabels?: Filters[];
      orReps?: Filters[];
      params: {
        limit: string;
        order?: string;
        skip: string;
      };
    }
  ) {}
}

export class LoadOpportunitiesElasticSuccess implements Action {
  readonly type = OpportunitiesActionTypes.LoadOpportunitiesElasticSuccess;
  constructor(
    public payload: {
      fetchingMore?: boolean;
      count: number;
      items: ElasticOpportunity[];
    }
  ) {}
}

export class LoadOpportunitiesElasticFailure implements Action {
  readonly type = OpportunitiesActionTypes.LoadOpportunitiesElasticFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadOppItemsElastic implements Action {
  readonly type = OpportunitiesActionTypes.LoadOppItemsElastic;
  constructor(
    public payload: {
      filters: Filters[];
      should?: Filters[];
      andShould?: Filters[][];
      orLabels?: Filters[];
      orReps?: Filters[];
      params: {
        limit: string;
        order?: string;
        skip: string;
      };
    }
  ) {}
}

export class LoadOppItemsElasticSuccess implements Action {
  readonly type = OpportunitiesActionTypes.LoadOppItemsElasticSuccess;
  constructor(
    public payload: {
      fetchingMore?: boolean;
      count: number;
      items: ElasticOppItem[];
    }
  ) {}
}

export class LoadOppItemsElasticFailure implements Action {
  readonly type = OpportunitiesActionTypes.LoadOppItemsElasticFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadOpportunity implements Action {
  readonly type = OpportunitiesActionTypes.LoadOpportunity;
  constructor(public payload: number) {}
}

export class LoadOpportunitySuccess implements Action {
  readonly type = OpportunitiesActionTypes.LoadOpportunitySuccess;
  constructor(public payload: Opportunity) {}
}

export class LoadOpportunityFailure implements Action {
  readonly type = OpportunitiesActionTypes.LoadOpportunityFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class SaveOpportunity implements Action {
  readonly type = OpportunitiesActionTypes.SaveOpportunity;
  constructor(public payload: Opportunity) {}
}

export class SaveOpportunitySuccess implements Action {
  readonly type = OpportunitiesActionTypes.SaveOpportunitySuccess;
  constructor(public payload: Opportunity) {}
}

export class SaveOpportunityFailure implements Action {
  readonly type = OpportunitiesActionTypes.SaveOpportunityFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class DeleteOpportunity implements Action {
  readonly type = OpportunitiesActionTypes.DeleteOpportunity;
  constructor(public payload: { id: number; redirect: boolean }) {}
}

export class DeleteOpportunitySuccess implements Action {
  readonly type = OpportunitiesActionTypes.DeleteOpportunitySuccess;
}

export class DeleteOpportunitySuccessNoRedirect implements Action {
  readonly type = OpportunitiesActionTypes.DeleteOpportunitySuccessNoRedirect;
}

export class DeleteOpportunityFailure implements Action {
  readonly type = OpportunitiesActionTypes.DeleteOpportunityFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class UploadOpportunityFile implements Action {
  readonly type = OpportunitiesActionTypes.UploadOpportunityFile;
  constructor(public payload: Blob) {}
}

export class UploadOpportunityFileSuccess implements Action {
  readonly type = OpportunitiesActionTypes.UploadOpportunityFileSuccess;
  constructor(public payload: FileMetaData) {}
}

export class UploadOpportunityFileFailure implements Action {
  readonly type = OpportunitiesActionTypes.UploadOpportunityFileFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class DeleteOpportunityFile implements Action {
  readonly type = OpportunitiesActionTypes.DeleteOpportunityFile;
  constructor(public payload: number) {}
}

export class DeleteOpportunityFileSuccess implements Action {
  readonly type = OpportunitiesActionTypes.DeleteOpportunityFileSuccess;
  constructor(public payload: FileMetaData) {}
}

export class DeleteOpportunityFileFailure implements Action {
  readonly type = OpportunitiesActionTypes.DeleteOpportunityFileFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadOppItemCustomFields implements Action {
  readonly type = OpportunitiesActionTypes.LoadOppItemCustomFields;
}

export class LoadOppItemCustomFieldsSuccess implements Action {
  readonly type = OpportunitiesActionTypes.LoadOppItemCustomFieldsSuccess;
  constructor(public payload: GenericMap<ItemCustomField>) {}
}

export class LoadOppItemCustomFieldsFailure implements Action {
  readonly type = OpportunitiesActionTypes.LoadOppItemCustomFieldsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class CreateOppItemCustomField implements Action {
  readonly type = OpportunitiesActionTypes.CreateOppItemCustomField;
  constructor(public payload: ItemCustomField) {}
}

export class CreateOppItemCustomFieldSuccess implements Action {
  readonly type = OpportunitiesActionTypes.CreateOppItemCustomFieldSuccess;
  constructor(public payload: ItemCustomField) {}
}

export class CreateOppItemCustomFieldFailure implements Action {
  readonly type = OpportunitiesActionTypes.CreateOppItemCustomFieldFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class UpdateOppItemCustomField implements Action {
  readonly type = OpportunitiesActionTypes.UpdateOppItemCustomField;
  constructor(public payload: ItemCustomField) {}
}

export class UpdateOppItemCustomFieldSuccess implements Action {
  readonly type = OpportunitiesActionTypes.UpdateOppItemCustomFieldSuccess;
  constructor(public payload: ItemCustomField) {}
}

export class UpdateOppItemCustomFieldFailure implements Action {
  readonly type = OpportunitiesActionTypes.DeleteOppItemCustomFieldFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class DeleteOppItemCustomField implements Action {
  readonly type = OpportunitiesActionTypes.DeleteOppItemCustomField;
  constructor(public payload: number) {}
}

export class DeleteOppItemCustomFieldSuccess implements Action {
  readonly type = OpportunitiesActionTypes.DeleteOppItemCustomFieldSuccess;
  constructor(public payload: number) {}
}

export class DeleteOppItemCustomFieldFailure implements Action {
  readonly type = OpportunitiesActionTypes.DeleteOppItemCustomFieldFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadOpportunityCustomFields implements Action {
  readonly type = OpportunitiesActionTypes.LoadOpportunityCustomFields;
}

export class LoadOpportunityCustomFieldsSuccess implements Action {
  readonly type = OpportunitiesActionTypes.LoadOpportunityCustomFieldsSuccess;
  constructor(public payload: GenericMap<OpportunityCustomField>) {}
}

export class LoadOpportunityCustomFieldsFailure implements Action {
  readonly type = OpportunitiesActionTypes.LoadOpportunityCustomFieldsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class CreateOpportunityCustomField implements Action {
  readonly type = OpportunitiesActionTypes.CreateOpportunityCustomField;
  constructor(public payload: OpportunityCustomField) {}
}

export class CreateOpportunityCustomFieldSuccess implements Action {
  readonly type = OpportunitiesActionTypes.CreateOpportunityCustomFieldSuccess;
  constructor(public payload: OpportunityCustomField) {}
}

export class CreateOpportunityCustomFieldFailure implements Action {
  readonly type = OpportunitiesActionTypes.CreateOpportunityCustomFieldFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class UpdateOpportunityCustomField implements Action {
  readonly type = OpportunitiesActionTypes.UpdateOpportunityCustomField;
  constructor(public payload: OpportunityCustomField) {}
}

export class UpdateOpportunityCustomFieldSuccess implements Action {
  readonly type = OpportunitiesActionTypes.UpdateOpportunityCustomFieldSuccess;
  constructor(public payload: OpportunityCustomField) {}
}

export class UpdateOpportunityCustomFieldFailure implements Action {
  readonly type = OpportunitiesActionTypes.DeleteOpportunityCustomFieldFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class DeleteOpportunityCustomField implements Action {
  readonly type = OpportunitiesActionTypes.DeleteOpportunityCustomField;
  constructor(public payload: number) {}
}

export class DeleteOpportunityCustomFieldSuccess implements Action {
  readonly type = OpportunitiesActionTypes.DeleteOpportunityCustomFieldSuccess;
  constructor(public payload: number) {}
}

export class DeleteOpportunityCustomFieldFailure implements Action {
  readonly type = OpportunitiesActionTypes.DeleteOpportunityCustomFieldFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class ReorderOpportunityCustomFields implements Action {
  readonly type = OpportunitiesActionTypes.ReorderOpportunityCustomFields;
  constructor(public payload: OpportunityCustomField[]) {}
}

export class ReorderOpportunityCustomFieldsSuccess implements Action {
  readonly type = OpportunitiesActionTypes.ReorderOpportunityCustomFieldsSuccess;
  constructor(public payload: GenericMap<OpportunityCustomField>) {}
}

export class ReorderOpportunityCustomFieldsFailure implements Action {
  readonly type = OpportunitiesActionTypes.ReorderOpportunityCustomFieldsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class SetCurrentOppItemCustomFieldId implements Action {
  readonly type = OpportunitiesActionTypes.SetCurrentOppItemCustomFieldId;
  constructor(public payload: number) {}
}

export class SetCurrentOpportunityCustomFieldId implements Action {
  readonly type = OpportunitiesActionTypes.SetCurrentOpportunityCustomFieldId;
  constructor(public payload: number) {}
}

export class SetCurrentOppItemId implements Action {
  readonly type = OpportunitiesActionTypes.SetCurrentOppItemId;
  constructor(public payload: number) {}
}

export class SaveOpportunityCustomFieldValue implements Action {
  readonly type = OpportunitiesActionTypes.SaveOpportunityCustomFieldValue;
  constructor(public payload: OpportunityCustomFieldValue) {}
}

export class SaveOpportunityCustomFieldValueSuccess implements Action {
  readonly type = OpportunitiesActionTypes.SaveOpportunityCustomFieldValueSuccess;
  constructor(public payload: OpportunityCustomFieldValue) {}
}

export class SaveOpportunityCustomFieldValueFailure implements Action {
  readonly type = OpportunitiesActionTypes.SaveOpportunityCustomFieldValueFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class DeleteOpportunityCustomFieldValue implements Action {
  readonly type = OpportunitiesActionTypes.DeleteOpportunityCustomFieldValue;
  constructor(public payload: OpportunityCustomFieldValue) {}
}

export class DeleteOpportunityCustomFieldValueSuccess implements Action {
  readonly type = OpportunitiesActionTypes.DeleteOpportunityCustomFieldValueSuccess;
}

export class DeleteOpportunityCustomFieldValueFailure implements Action {
  readonly type = OpportunitiesActionTypes.DeleteOpportunityCustomFieldValueFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class ShowClosedOpps implements Action {
  readonly type = OpportunitiesActionTypes.ShowClosedOpps;
  constructor(public payload: boolean) {}
}

export class ShowOpenFollowUpCallNotes implements Action {
  readonly type = OpportunitiesActionTypes.ShowOpenFollowUpCallNotes;
  constructor(public payload: boolean) {}
}

export class SetOppsCount implements Action {
  readonly type = OpportunitiesActionTypes.SetOppsCount;
  constructor(public payload: number) {}
}

export class SetItemsCount implements Action {
  readonly type = OpportunitiesActionTypes.SetItemsCount;
  constructor(public payload: number) {}
}

export type OpportunitiesActions =
  | SelectTableColumns
  | SelectItemTableColumns
  | RedirectToOpportunities
  | RedirectToOpportunity
  | LoadOpportunitiesTableColumns
  | LoadOpportunitiesTableColumnsSuccess
  | LoadOpportunitiesTableColumnsFailure
  | LoadOpportunityItemsTableColumns
  | LoadOpportunityItemsTableColumnsSuccess
  | LoadOpportunityItemsTableColumnsFailure
  | LoadOpportunities
  | LoadOpportunitiesSuccess
  | LoadOpportunitiesFailure
  | LoadOpportunitiesElastic
  | LoadOpportunitiesElasticSuccess
  | LoadOpportunitiesElasticFailure
  | LoadOppItemsElastic
  | LoadOppItemsElasticSuccess
  | LoadOppItemsElasticFailure
  | LoadOpportunity
  | LoadOpportunitySuccess
  | LoadOpportunityFailure
  | SaveOpportunity
  | SaveOpportunitySuccess
  | SaveOpportunityFailure
  | DeleteOpportunity
  | DeleteOpportunitySuccess
  | DeleteOpportunitySuccessNoRedirect
  | DeleteOpportunityFailure
  | SelectOpportunityId
  | ClearOpportunityId
  | UploadOpportunityFile
  | UploadOpportunityFileSuccess
  | UploadOpportunityFileFailure
  | DeleteOpportunityFile
  | DeleteOpportunityFileSuccess
  | DeleteOpportunityFileFailure
  | LoadOppItemCustomFields
  | LoadOppItemCustomFieldsSuccess
  | LoadOppItemCustomFieldsFailure
  | CreateOppItemCustomField
  | CreateOppItemCustomFieldSuccess
  | CreateOppItemCustomFieldFailure
  | UpdateOppItemCustomField
  | UpdateOppItemCustomFieldSuccess
  | UpdateOppItemCustomFieldFailure
  | DeleteOppItemCustomField
  | DeleteOppItemCustomFieldSuccess
  | DeleteOppItemCustomFieldFailure
  | SetCurrentOppItemCustomFieldId
  | LoadOpportunityCustomFields
  | LoadOpportunityCustomFieldsSuccess
  | LoadOpportunityCustomFieldsFailure
  | CreateOpportunityCustomField
  | CreateOpportunityCustomFieldSuccess
  | CreateOpportunityCustomFieldFailure
  | UpdateOpportunityCustomField
  | UpdateOpportunityCustomFieldSuccess
  | UpdateOpportunityCustomFieldFailure
  | DeleteOpportunityCustomField
  | DeleteOpportunityCustomFieldSuccess
  | DeleteOpportunityCustomFieldFailure
  | SaveOpportunityCustomFieldValue
  | SaveOpportunityCustomFieldValueSuccess
  | SaveOpportunityCustomFieldValueFailure
  | DeleteOpportunityCustomFieldValue
  | DeleteOpportunityCustomFieldValueSuccess
  | DeleteOpportunityCustomFieldValueFailure
  | ReorderOpportunityCustomFieldsSuccess
  | ReorderOpportunityCustomFields
  | ReorderOpportunityCustomFieldsFailure
  | SetCurrentOpportunityCustomFieldId
  | SetCurrentOppItemId
  | AddLabelColumns
  | LabelColumnsAdded
  | LabelColumnAdded
  | NoLabelColsAdded
  | SelectOppTableColumns
  | SelectOppItemTableColumns
  | ShowClosedOpps
  | ShowOpenFollowUpCallNotes
  | SetOppsCount
  | SetItemsCount;
