<mat-card class="project-call-note-card mat-elevation-z4">
  <mat-card-header
    fxLayout="row"
    fxLayoutAlign="space-between center"
    *ngIf="!showCompany; else companyFocusedHeader"
  >
    <div
      class="call-note-title-info"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="16px"
    >
      <div class="call-note-date">{{ callNote.callDate | date: 'mediumDate' }}</div>
      <div class="call-note-label-group">
        <mat-chip-list>
          <mat-chip>{{ callNote.type.name }}</mat-chip>
        </mat-chip-list>
      </div>
      <div class="created-by-and-company-contact" fxLayout="column" fxLayoutGap="2px">
        <div>
          <span class="bolder">Created by: </span>
          <span class="name">{{
            !!callNote?.createdBy.name
              ? callNote?.createdBy.name
              : callNote?.createdBy.firstName + ' ' + callNote?.createdBy.lastName
          }}</span>
        </div>
        <div>
          <!-- <span class="bolder" *ngIf="showCompany">
            {{ callNote.company.name }}<span *ngIf="!!callNote.contacts.length">: </span>
          </span> -->
          <span class="bolder"
            ><span *ngIf="!!callNote.contacts.length">Contacts: </span>
            <span *ngIf="!callNote.contacts.length">Contacts: --</span>
          </span>
          <span *ngFor="let contact of callNote.contacts; let last = last">
            <span class="name">{{
              !!contact.name ? contact.name : contact.firstName + ' ' + contact.lastName
            }}</span
            ><span *ngIf="!last">, </span>
          </span>
        </div>
      </div>
    </div>
    <div class="call-note-actions" fxLayout="row">
      <a
        mat-icon-button
        *ngIf="!!callNote.opportunityId && ($oppId | async) === false"
        [routerLink]="['/opportunities/opportunity', callNote.opportunityId]"
        target="_blank"
        color="accent"
        matTooltip="Go to linked opportunity"
      >
        <mat-icon>trending_up</mat-icon>
      </a>
      <a
        mat-icon-button
        *ngIf="!!callNote.projectId && ($projId | async) === false"
        [routerLink]="['/projects/project', callNote.projectId]"
        target="_blank"
        color="accent"
        matTooltip="Go to linked project"
      >
        <mat-icon>assignment</mat-icon>
      </a>

      <button
        mat-icon-button
        (click)="commentHandler(callNote)"
        color="primary"
        matTooltip="share/comment"
        *ngIf="showCommentsButton"
      >
        <mat-icon>share</mat-icon>
      </button>
      <button mat-icon-button color="primary" (click)="editHandler()" matTooltip="edit">
        <mat-icon>edit</mat-icon>
      </button>
      <button
        mat-icon-button
        color="warn"
        (click)="deleteHandler(callNote)"
        matTooltip="delete"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </mat-card-header>
  <ng-template #companyFocusedHeader>
    <mat-card-header fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="row" fxLayoutAlign="start center">
          <h3 class="call-note-company-name">{{ callNote.company.name }}</h3>
          &nbsp;
          <a
            mat-icon-button
            class="company-link"
            matSuffix
            color="primary"
            target="_blank"
            [routerLink]="['/companies/company/', callNote.company.id]"
            ><mat-icon class="search-icon">call_made</mat-icon></a
          >
        </div>
        <div class="call-note-actions" fxLayout="row">
          <a
            mat-icon-button
            *ngIf="!!callNote.opportunityId && ($oppId | async) === false"
            [routerLink]="['/opportunities/opportunity', callNote.opportunityId]"
            target="_blank"
            color="accent"
            matTooltip="Go to linked opportunity"
          >
            <mat-icon>trending_up</mat-icon>
          </a>
          <a
            mat-icon-button
            *ngIf="!!callNote.projectId && ($projId | async) === false"
            [routerLink]="['/projects/project', callNote.projectId]"
            target="_blank"
            color="accent"
            matTooltip="Go to linked project"
          >
            <mat-icon>assignment</mat-icon>
          </a>

          <button
            mat-icon-button
            (click)="commentHandler(callNote)"
            color="primary"
            matTooltip="share/comment"
            *ngIf="showCommentsButton"
          >
            <mat-icon>comment</mat-icon>
          </button>
          <button
            mat-icon-button
            color="primary"
            (click)="editHandler()"
            matTooltip="edit"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            mat-icon-button
            color="warn"
            (click)="deleteHandler(callNote)"
            matTooltip="delete"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between start">
        <div
          class="call-note-title-info"
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="16px"
        >
          <div class="call-note-date">{{ callNote.callDate | date: 'mediumDate' }}</div>
          <div class="call-note-label-group">
            <mat-chip-list>
              <mat-chip>{{ callNote.type.name }}</mat-chip>
            </mat-chip-list>
          </div>
        </div>
        <div
          class="created-by-and-company-contact"
          fxLayout="column"
          fxLayoutAlign="end start"
          fxLayoutGap="2px"
        >
          <div>
            <span class="bolder">Created by: </span>
            <span class="name">{{
              !!callNote?.createdBy.name
                ? callNote?.createdBy.name
                : callNote?.createdBy.firstName + ' ' + callNote?.createdBy.lastName
            }}</span>
          </div>
          <div>
            <span class="bolder"
              ><span *ngIf="!!callNote.contacts.length">Contacts: </span>
              <span *ngIf="!callNote.contacts.length">Contacts: --</span>
            </span>
            <span *ngFor="let contact of callNote.contacts; let last = last">
              <span class="name">{{
                !!contact.name ? contact.name : contact.firstName + ' ' + contact.lastName
              }}</span
              ><span *ngIf="!last">, </span>
            </span>
          </div>
        </div>
      </div>
    </mat-card-header>
  </ng-template>
  <mat-card-content>
    <div *ngIf="callNote.fields.length">
      <mat-divider></mat-divider>
      <div class="call-note-narratives-group" fxLayout="column" fxLayoutGap="16px">
        <div class="call-note-narrative" *ngFor="let narrative of callNote.fields">
          <span class="call-note-narrative-title">{{ narrative.name }}</span>
          <span [ngSwitch]="narrative.type?.id" class="call-note-narrative-body">
            <span class="call-note-narrative-body" *ngSwitchCase="2">
              <mat-checkbox
                disabled
                [checked]="narrative.value === 'true'"
              ></mat-checkbox>
            </span>
            <span *ngSwitchCase="4" class="call-note-narrative-body">{{
              narrative.value | currency: 'USD'
            }}</span>
            <span *ngSwitchCase="16" class="call-note-narrative-body">{{
              dropdowns[narrative.dropdownId]?.dropdownOption
            }}</span>
            <span
              *ngSwitchDefault
              class="call-note-narrative-body"
              [innerHTML]="narrative.safeValue"
            ></span>
          </span>
        </div>
      </div>
    </div>
    <div *ngIf="!!callNote.followUp.task">
      <mat-divider></mat-divider>
      <div class="call-note-follow-up">
        <h3 class="follow-up-title">Follow-up</h3>
        <tn-project-follow-up
          [showCompany]="false"
          [callNote]="callNote"
        ></tn-project-follow-up>
      </div>
    </div>
    <div class="call-note-comments" *ngIf="callNote.comments?.length > 0">
      <mat-accordion class="comments-panel">
        <mat-expansion-panel #commentsPanel expanded="false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Comments ({{ callNote.comments?.length }})
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div fxLayout="column" fxLayoutGap="8px">
            <tn-project-call-note-comment
              *ngFor="let comment of callNote.comments"
              [callNoteId]="callNote.id"
              [comment]="comment"
            ></tn-project-call-note-comment>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </mat-card-content>
</mat-card>
