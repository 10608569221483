import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { undoDeleteReducer } from './undo-delete.reducer';
import { EffectsModule } from '@ngrx/effects';
import { UndoDeleteEffects } from './undo-delete.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('undoDelete', undoDeleteReducer),
    EffectsModule.forFeature([UndoDeleteEffects])
  ]
})
export class UndoDeleteStateModule {}
