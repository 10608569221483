<div fxLayout="row" fxLayoutAlign="space-between center">
  <div class="follow-up-container" fxLayout="column" fxLayoutGap="14px">
    <div
      class="follow-up-details"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="8px"
    >
      <mat-checkbox
        [checked]="callNote.followUp?.complete"
        (change)="handleCheckbox($event)"
      ></mat-checkbox>
      <div class="details-text">
        <span class="follow-up-company" *ngIf="showCompany">{{
          callNote.company.name
        }}</span>
        <div *ngIf="callNote.followUp" [innerHTML]="task"></div>
      </div>
    </div>
    <div class="follow-up-details" fxLayout="row" fxLayoutGap="8px">
      <div
        *ngIf="!!callNote.followUp.assignedTo; else noAssignedBlock"
        fxLayout="row"
        fxLayoutGap="8px"
      >
        <span>Assigned to:</span>
        <span class="bolder">{{
          !!callNote.followUp.assignedTo?.name
            ? callNote.followUp.assignedTo?.name
            : callNote.followUp.assignedTo?.firstName +
              ' ' +
              callNote.followUp.assignedTo?.lastName
        }}</span>
      </div>
      <ng-template #noAssignedBlock> No agent assigned. </ng-template>
      <div *ngIf="!!callNote.followUp.dueDateStart" fxLayout="row" fxLayoutGap="8px">
        <span>|</span>
        <span>Start:</span>
        <span class="bolder">{{
          callNote.followUp.dueDateStart | date : 'medium' : 'America/Denver'
        }}</span>
      </div>
      <div *ngIf="!!callNote.followUp.dueDateEnd" fxLayout="row" fxLayoutGap="8px">
        <span>|</span>
        <span>End:</span>
        <span class="bolder">{{
          callNote.followUp.dueDateEnd | date : 'medium' : 'America/Denver'
        }}</span>
      </div>
    </div>
  </div>
  <!-- <div fxLayout="row">
    <button mat-icon-button color="primary" (click)="handleEditClick()" matTooltip="edit">
      <mat-icon>edit</mat-icon>
    </button>
    <button
      mat-icon-button
      color="warn"
      (click)="handleDeleteClick(callNote)"
      matTooltip="delete"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </div> -->
</div>
