import { Injectable } from '@angular/core';

import { AuthenticationService } from '../../authentication.service';
import { GroupLabel, Label, LabelCompany } from '../../../models/label';

@Injectable()
export class LabelService {
	myGroupLabels: GroupLabel[];
	myLabels: Label[];

	constructor(private authService: AuthenticationService) { }

	clearServiceData(): void { }

	getGroupLabels(): Promise<GroupLabel[]> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/Labels/getGroupLabels', '', 'get').then(function (result: any) {
			self.myGroupLabels = JSON.parse(result);
			return self.myGroupLabels;
		});
	}

	getCompanyLabelData(companyId: number): Promise<LabelCompany[]> {
		return this.authService.makeRequest('/api-v1.2.1/company/getCompanyLabelData?companyId=' + companyId, null, 'get').then(function (result) {
			return JSON.parse(result);
		});
	}

	addGroupLabels(gl): Promise<number> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/Labels/addGroupLabel', gl, 'post').then(function (result: any) {
			return result;
		});
	}

	updateGroupLabels(gl): Promise<boolean> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/Labels/updateGroupLabel', gl, 'post').then(function (result: any) {
			return result[0];
		});
	}

	removeGroupLabels(gl): Promise<boolean> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/Labels/removeGroupLabel', gl, 'post').then(function (result: any) {
			return result[0];
		});
	}

	getLabels(): Promise<Label[]> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/Labels/getLabels', '', 'get').then(function (result: any) {
			self.myLabels = JSON.parse(result);
			return self.myLabels;
		});
	}

	addLabel(lbl): Promise<number> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/Labels/addLabel', lbl, 'post').then(function (result: number) {
			return result;
		});
	}

	updateLabel(lbl): Promise<number> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/Labels/updateLabel', lbl, 'post').then(function (result: any) {
			return result[0];
		});
	}

	removeLabel(lbl): Promise<number> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/Labels/removeLabel', lbl, 'post').then(function (result: any) {
			return result[0];
		});
	}

	setLabelData(label: LabelCompany): Promise<any> {
		return this.authService.makeRequest('/api-v1.2.1/company/setLabelData', label, 'post').then(function (result: any) {
			return result[0];
		});
	}
}
