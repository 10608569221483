import {
  FileCategoriesActions,
  FileCategoriesActionTypes
} from './file-categories.actions';
import { Category } from 'src/app/models/admin/files/category';
import { GenericMap } from 'src/app/models/generic-map';
import { HttpErrorResponse } from '@angular/common/http';

export const fileCategoriesFeatureKey = 'fileCategories';

export interface FileCategoriesState {
  pending: boolean;
  data: GenericMap<Category>;
  selectedCategoryId: number;
  categories: Category[];
  error: HttpErrorResponse;
}

export const initialState: FileCategoriesState = {
  pending: false,
  data: {},
  selectedCategoryId: null,
  categories: [],
  error: undefined
};

export function fileCategoriesReducer(
  state = initialState,
  action: FileCategoriesActions
): FileCategoriesState {
  switch (action.type) {
    case FileCategoriesActionTypes.SelectFileCategoriesId:
      return {
        ...state,
        selectedCategoryId: action.payload
      };

    case FileCategoriesActionTypes.ClearFileCategoriesId:
      return {
        ...state,
        selectedCategoryId: undefined
      };

    case FileCategoriesActionTypes.LoadFileCategories:
      return {
        ...state,
        pending: true
      };

    case FileCategoriesActionTypes.LoadFileCategoriesSuccess:
      return {
        ...state,
        error: undefined,
        pending: false,
        data: action.payload
      };

    case FileCategoriesActionTypes.LoadFileCategoriesFailure:
      return {
        ...state,
        pending: false,
        data: {},
        error: action.payload
      };

    case FileCategoriesActionTypes.CreateFileCategories:
      return {
        ...state,
        pending: true
      };

    case FileCategoriesActionTypes.CreateFileCategoriesSuccess:
      return {
        ...state,
        pending: false
      };

    case FileCategoriesActionTypes.CreateFileCategoriesFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case FileCategoriesActionTypes.UpdateFileCategories:
      return {
        ...state,
        pending: true
      };

    case FileCategoriesActionTypes.UpdateFileCategoriesSuccess:
      return {
        ...state,
        pending: false
      };

    case FileCategoriesActionTypes.UpdateFileCategoriesFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case FileCategoriesActionTypes.DeleteFileCategories:
      return {
        ...state,
        pending: true
      };

    case FileCategoriesActionTypes.DeleteFileCategoriesSuccess:
      return {
        ...state,
        pending: false,
        selectedCategoryId: undefined
      };

    case FileCategoriesActionTypes.DeleteFileCategoriesFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    default:
      return state;
  }
}
