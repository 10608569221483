import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

import { AuthenticationService } from '../../shared/services/authentication.service';
import { Territory, TerritoryAgent } from '../models/admin/territories/territory';
import {
  AverageCalls,
  CallsPerCompanyType,
  CurrentVsBudgeted,
  Goals,
  localBook,
  MonthlyPace,
  Pages,
  SalesAnalysis,
  SalesProfitandMargin,
  SAReps,
  ChartDataBasic,
  UnitGoals,
  SalesActivitySummary,
  CompanyVisitSummary,
  DaysSinceLastVisit,
  CompanyList,
  Opportunity,
  JpiRetreads,
  JpiSalesAnalysis,
  DSAReport,
  DBCounts,
  DBItems,
  CompanyCustomData,
  DMS,
  Analysis_Numbers_Combined,
  DMSSummary,
  JpiSalesByProductCategory,
  JpiCompanyOverview
} from '../models/dashboard';

@Injectable()
export class DashboardService {
  constructor(private _authService: AuthenticationService, private _router: Router) {}
  listItems: string[] = [];
  isExceed: boolean;

  clearServiceData(): void {}

  // can user access the page
  canActivate(route: ActivatedRouteSnapshot): boolean {
    // check to see if user is signed in
    const self = this;
    this.isExceed = false;
    return true;
  }
  setDashboardFeature(val) {
    this.isExceed = val;
    if (this.isExceed == false) {
      this._authService.getVisibleFeatures();
      this._router.navigate(['dashboard/classic']);
    }
  }
  getDashboardFeature(): Promise<boolean> {
    const self = this;

    return this._authService
      .getFeature(this._authService.modules.Excede)
      .then((dashboardPermissions) => {
        for (const key in dashboardPermissions.Permissions) {
          if (
            dashboardPermissions.Permissions[key].Id == 108 ||
            dashboardPermissions.Permissions[key].Id == 121 ||
            dashboardPermissions.Permissions[key].Id == 56
          ) {
            this.setDashboardFeature(true);
            return true;
          }
        }
      });
  }

  getRegionStruct(): Promise<Territory[]> {
    return this._authService
      .makeRequest('/api-v1.2.1/dashboard/getRegionStruct', '', 'get')
      .then((result: any) => {
        return JSON.parse(result);
      });
  }

  getReps(): Promise<TerritoryAgent[]> {
    return this._authService
      .makeRequest('/api-v1.2.1/dashboard/getReps', '', 'get')
      .then((result: any) => {
        return JSON.parse(result);
      });
  }

  getNarrativeAnalysisTimePeriod(
    Reps: SAReps,
    tp: string,
    start: string,
    end: string
  ): Promise<[ChartDataBasic]> {
    return this._authService
      .makeRequest(
        '/api-v1.2.1/dashboard/getNarrativeAnalysisTimePeriodV2?TimePeriod=' +
          tp +
          '&start=' +
          start +
          '&end=' +
          end,
        Reps,
        'post'
      )
      .then((result: any) => {
        return JSON.parse(result);
      });
  }
  getNarrativeAnalysis(Reps: SAReps, tp: number): Promise<SalesAnalysis[]> {
    return this._authService
      .makeRequest('/api-v1.2.1/dashboard/getNarrativeAnalysis?Year=' + tp, Reps, 'post')
      .then((result: any) => {
        return JSON.parse(result);
      });
  }

  getSalesAnalysis(Reps: SAReps, tp: number): Promise<SalesAnalysis[]> {
    return this._authService
      .makeRequest('/api-v1.2.1/dashboard/getSalesAnalysis?Year=' + tp, Reps, 'post')
      .then((result: any) => {
        return JSON.parse(result);
      });
  }
  getSalesAnalysisJPI(Reps: string[], year: number): Promise<JpiSalesAnalysis[]> {
    return this._authService
      .makeJpiRequest(
        '/dashboard/SalesAnalysisCombined/' + year.toString(),
        null,
        Reps,
        'post'
      )
      .then(function (result) {
        return JSON.parse(result).items;
      });
  }
  getSalesAnalysisJPIRY(
    Reps: string[],
    year: number,
    runningYear: boolean,
    brnid: string
  ): Promise<JpiSalesAnalysis[]> {
    var ry = 'false';
    if (runningYear) {
      ry = 'true';
    }
    var brnId = '';
    if (brnid != '') {
      brnId = '/' + brnid;
    }
    return this._authService
      .makeJpiRequest(
        '/dashboard/SalesAnalysisCombined/' + year.toString() + '/' + ry + brnId,
        null,
        Reps,
        'post'
      )
      .then(function (result) {
        return JSON.parse(result).items;
      });
  }

  getCompanyCountByLabel(territoryIds: number[]): Promise<DBCounts[]> {
    return this._authService
      .makeJpiRequest('/dashboard/companiesCount', null, territoryIds, 'post')
      .then(function (result) {
        return JSON.parse(result).items;
      });
  }

  getCompanyCustomDetails(
    territoryIds: number[],
    fieldName: string,
    labels: string
  ): Promise<CompanyCustomData[]> {
    return this._authService
      .makeJpiRequest(
        '/dashboard/companycustomdatadetails/' + fieldName + '/' + labels,
        null,
        territoryIds,
        'post'
      )
      .then(function (result) {
        return JSON.parse(result).items;
      });
  }

  getCompanyCustomDetailsStage(
    territoryIds: number[],
    stageName: string
  ): Promise<CompanyCustomData[]> {
    return this._authService
      .makeJpiRequest(
        '/dashboard/companycustomdatastage/' + stageName,
        null,
        territoryIds,
        'post'
      )
      .then(function (result) {
        return JSON.parse(result).items;
      });
  }

  getCompanyCustomSum(territoryIds: number[]): Promise<DBItems[]> {
    return this._authService
      .makeJpiRequest('/dashboard/companycustomdata', null, territoryIds, 'post')
      .then(function (result) {
        return JSON.parse(result).items;
      });
  }

  getOpportunitySum_byLabel(
    territoryIds: number[],
    labelIdList: number[]
  ): Promise<DBItems[]> {
    var labelIds = '';
    labelIdList.forEach((element) => {
      if (labelIds != '') {
        labelIds = labelIds + ',';
      }
      labelIds = labelIds + element.toString();
    });

    return this._authService
      .makeJpiRequest('/opportunities/v2/summary/' + labelIds, null, territoryIds, 'post')
      .then(function (result) {
        return JSON.parse(result);
      });
  }
  getOpportunitySum(territoryIds: number[]): Promise<DBItems[]> {
    return this._authService
      .makeJpiRequest('/opportunities/v2/summary', null, territoryIds, 'post')
      .then(function (result) {
        return JSON.parse(result);
      });
  }
  getMaddenCoDSAReportJPI(Reps: string[], year: number): Promise<DSAReport[]> {
    return this._authService
      .makeJpiRequest(
        '/dashboard/MaddenCoDSAReport/' + year.toString(),
        null,
        Reps,
        'post'
      )
      .then(function (result) {
        return JSON.parse(result).items;
      });
  }

  getSalesAnalysisProductsJPI(
    Reps: string[],
    year: number,
    groupid: number
  ): Promise<JpiSalesAnalysis[]> {
    return this._authService
      .makeJpiRequest(
        '/dashboard/ProductSales/' + year.toString() + '/' + groupid.toString(),
        null,
        Reps,
        'post'
      )
      .then(function (result) {
        return JSON.parse(result).items;
      });
  }

  getSalesAnalysisCompaniesJPI(
    Reps: string[],
    year: number,
    summaryType: number
  ): Promise<JpiSalesAnalysis[]> {
    return this._authService
      .makeJpiRequest(
        '/dashboard/CompanySales/' + year.toString() + '/' + summaryType.toString(),
        null,
        Reps,
        'post'
      )
      .then(function (result) {
        return JSON.parse(result).items;
      });
  }

  //"/SalesByProductCategory/{startDate}/{endDate}/{allData}/{pageId}/{brnId}")
  getSalesByProductCategoryJPI(
    Reps: string[],
    startDate: string,
    endDate: string,
    allData: boolean,
    pageId: number,
    brnId: string
  ): Promise<JpiSalesByProductCategory[]> {
    return this._authService
      .makeJpiRequest(
        '/dashboard/SalesByProductCategory/' +
          startDate.toString() +
          '/' +
          endDate +
          '/' +
          allData +
          '/' +
          pageId +
          '/-1', //+ brnId,
        null,
        Reps,
        'post'
      )
      .then(function (result) {
        return JSON.parse(result);
      });
  }

  getPartsAnalysisJPI(
    Reps: string[],
    tp: string,
    start: string,
    end: string,
    brnIds: string
  ): Promise<DMSSummary[]> {
    if (Reps.length <= 0) {
      start = start + '';
    }
    var reps = [-1];
    var brnId = '';
    if (brnIds != '') {
      brnId = '/' + brnIds;
    } else {
      brnId = '/';
    }

    return this._authService
      .makeJpiRequest(
        '/dashboard/DMSGetProductSourceSummary/' +
          start +
          '/' +
          end +
          '/I~,C~,D~,X~,K~,S~,N~,F~/' +
          tp +
          '/I~_D~,C~,K~,S~,N~,F~,X~' +
          brnId,
        null,
        Reps,
        'post'
      )
      .then(function (result) {
        return JSON.parse(result);
      });
  }

  //'/dashboard/DMSGetProductSourceSummary/' + '2021-9-1/2021-9-20' + '/I~,C~,D~,X~,K~,S~,N~F~/' + 'This Year' + '/I~_D~,C~,K~,S~,N~,F~,X~',
  getSalesAnalysisCompaniesJPI_RY(
    Reps: string[],
    year: number,
    summaryType: number,
    isRollingYear: boolean,
    branchId: string
    //): Promise<JpiSalesAnalysis[]> {
  ): Promise<Analysis_Numbers_Combined> {
    var isRoll = '/t/false';
    var branchid = '';
    if (isRollingYear == null) {
      isRollingYear = false;
    } else {
      if (isRollingYear == true) {
        isRoll = '/t/' + String(isRollingYear);
      }
    }
    if (branchId != '') {
      branchid = '/' + branchId;
    }
    return this._authService
      .makeJpiRequest(
        '/dashboard/CompanySales/' +
          year.toString() +
          '/' +
          summaryType.toString() +
          isRoll +
          branchid,
        null,
        Reps,
        'post'
      )
      .then(function (result) {
        return JSON.parse(result);
      });
  }
  getDMSProductByClassID(
    Reps: string[],
    tp: string,
    start: string,
    end: string,
    classid: string
  ): Promise<any> {
    return this._authService
      .makeJpiRequest(
        '/dashboard/DMSgetProductByClassID_Dashboard/' +
          start +
          '/' +
          end +
          '/' +
          tp +
          '/' +
          classid,
        null,
        Reps,
        'post'
      )
      .then(function (result) {
        return JSON.parse(result);
      });
  }
  getDMSPartClass() {
    return this._authService
      .makeJpiRequest('/dms/orders/integrator/getPartClass', null, null, 'get')
      .then(function (result) {
        return JSON.parse(result);
      });
  }

  getDMSPartSource() {
    return this._authService
      .makeJpiRequest('/dms/orders/integrator/getPartSource', null, null, 'get')
      .then(function (result) {
        return JSON.parse(result);
      });
  }

  getSalesAnalysisCompaniesJPI_SRCID(
    Reps: string[],
    year: number,
    summaryType: number,
    srcId: string,
    brnIds: string
  ): Promise<JpiSalesAnalysis[]> {
    var brnid = '';
    if (brnIds != null && brnIds != '') {
      brnid = '/' + brnIds.toString();
    }
    return this._authService
      .makeJpiRequest(
        '/dashboard/CompanySalesSource/' +
          year.toString() +
          '/' +
          summaryType.toString() +
          '/' +
          srcId.toString() +
          brnid,

        null,
        Reps,
        'post'
      )
      .then(function (result) {
        return JSON.parse(result).items;
      });
  }

  getCompaniesDetailsJPI_SRCID(
    Reps: string[],
    year: number,
    summaryType: number,
    srcId: string,
    customerId: string
  ): Promise<DMS[]> {
    return this._authService
      .makeJpiRequest(
        '/dashboard/CompanySalesSourceDetails/' +
          year.toString() +
          '/' +
          summaryType.toString() +
          '/' +
          srcId.toString() +
          '/' +
          customerId.toString(),
        null,
        Reps,
        'post'
      )
      .then(function (result) {
        return JSON.parse(result).items;
      });
  }

  getCompaniesDetailsJPI_ALL(
    Reps: string[],
    year: number,
    summaryType: number,
    customerId: string,
    brnId: string
  ): Promise<DMS[]> {
    var brnid = '';
    if (brnId != null) {
      brnid = brnId.toString();
    }
    return this._authService
      .makeJpiRequest(
        '/dashboard/CompanySalesAllDetails/' +
          year.toString() +
          '/' +
          summaryType.toString() +
          '/' +
          customerId.toString() +
          '/' +
          brnid,
        null,
        Reps,
        'post'
      )
      .then(function (result) {
        return JSON.parse(result).items;
      });
  }

  getSalesAnalysisCompaniesJPI_CLASSID(
    Reps: string[],
    year: number,
    summaryType: number,
    classId: string,
    brnIds: string
  ): Promise<JpiSalesAnalysis[]> {
    var brnid = '';
    if (brnIds != null) {
      brnid = brnIds.toString();
    }
    return this._authService
      .makeJpiRequest(
        '/dashboard/CompanySalesClass/' +
          year.toString() +
          '/' +
          summaryType.toString() +
          '/' +
          classId.toString() +
          '/' +
          brnid,
        null,
        Reps,
        'post'
      )
      .then(function (result) {
        return JSON.parse(result).items;
      });
  }
  getCompaniesDetailsJPI_CLASSID(
    Reps: string[],
    year: number,
    summaryType: number,
    classId: string,
    customerId: string
  ): Promise<DMS[]> {
    return this._authService
      .makeJpiRequest(
        '/dashboard/CompanySalesClassDetails/' +
          year.toString() +
          '/' +
          summaryType.toString() +
          '/' +
          classId.toString() +
          '/' +
          customerId,
        null,
        Reps,
        'post'
      )
      .then(function (result) {
        return JSON.parse(result).items;
      });
  }
  getSalesAnalysisCompaniesJPI_DBID(
    Reps: string[],
    year: number,
    summaryType: number,
    dashboardId: number
  ): Promise<JpiSalesAnalysis[]> {
    return this._authService
      .makeJpiRequest(
        '/dashboard/CompanySales/' +
          year.toString() +
          '/' +
          summaryType.toString() +
          '/' +
          dashboardId.toString(),
        null,
        Reps,
        'post'
      )
      .then(function (result) {
        return JSON.parse(result).items;
      });
  }
  getSalesAnalysisCompaniesJPI_DBID_RY(
    Reps: string[],
    year: number,
    summaryType: number,
    dashboardId: number,
    isRollingYear: boolean
  ): Promise<JpiSalesAnalysis[]> {
    return this._authService
      .makeJpiRequest(
        '/dashboard/CompanySales/' +
          year.toString() +
          '/' +
          summaryType.toString() +
          '/' +
          dashboardId.toString() +
          '/t/' +
          isRollingYear.valueOf(),
        null,
        Reps,
        'post'
      )
      .then(function (result) {
        return JSON.parse(result).items;
      });
  }
  getSalesAnalysisProducts(Reps: SAReps, tp: number): Promise<SalesAnalysis[]> {
    return this._authService
      .makeRequest(
        '/api-v1.2.1/dashboard/getSalesAnalysisProducts?Year=' + tp,
        Reps,
        'post'
      )
      .then((result: any) => {
        return JSON.parse(result);
      });
  }

  getCompanyYearlySales(companyId: number): Promise<SalesAnalysis[]> {
    return this._authService
      .makeRequest(
        '/api-v1.2.1/dashboard/GetCompanyYearlySales?companyId=' + companyId,
        '',
        'get'
      )
      .then((result: any) => {
        return JSON.parse(result);
      });
  }

  getSalesAnalysisRetreadJPI(Reps: string[], year: number): Promise<JpiRetreads> {
    return this._authService
      .makeJpiRequest('/dashboard/Retreads/' + year.toString(), null, Reps, 'post')
      .then(function (result) {
        return JSON.parse(result).items;
      });
  }
  getSalesAnalysisRetread(Reps: SAReps, tp: number): Promise<SalesAnalysis[]> {
    return this._authService
      .makeRequest(
        '/api-v1.2.1/dashboard/getSalesAnalysisRetread?Year=' + tp,
        Reps,
        'post'
      )
      .then((result: any) => {
        return JSON.parse(result);
      });
  }
  getSalesAnalysisRetreadStores(stores: number[], tp: number): Promise<SalesAnalysis[]> {
    return this._authService
      .makeRequest(
        '/api-v1.2.1/dashboard/getSalesAnalysisRetread_Stores?Year=' + tp,
        stores,
        'post'
      )
      .then((result: any) => {
        return JSON.parse(result);
      });
  }

  getmcLocalBook(
    Reps: SAReps,
    tp: string,
    start: string,
    end: string
  ): Promise<localBook[]> {
    return this._authService
      .makeRequest(
        '/api-v1.2.1/dashboard/getmcLocalBook?TimePeriod=' +
          tp +
          '&start=' +
          start +
          '&end=' +
          end,
        Reps,
        'post'
      )
      .then((result: any) => {
        return JSON.parse(result);
      });
  }

  getUnitGoalsJPI(Reps: string[], tp: string, start: string, end: string): Promise<any> {
    return this._authService
      .makeJpiRequest(
        '/dashboard/UnitGoals/' + start + '/' + end + '/' + tp,
        null,
        Reps,
        'post'
      )
      .then(function (result) {
        return JSON.parse(result);
      });
  }

  getUnitGoals(
    Reps: SAReps,
    tp: string,
    start: string,
    end: string
  ): Promise<UnitGoals[]> {
    return this._authService
      .makeRequest(
        '/api-v1.2.1/dashboard/getUnitGoals?TimePeriod=' +
          tp +
          '&start=' +
          start +
          '&end=' +
          end,
        Reps,
        'post'
      )
      .then((result: any) => {
        return JSON.parse(result);
      });
  }

  getSalesAnalysisByStore(Stores: number[], tp: number): Promise<SalesAnalysis[]> {
    return this._authService
      .makeRequest(
        '/api-v1.2.1/dashboard/getSalesAnalysisByStore?Year=' + tp,
        Stores,
        'post'
      )
      .then((result: any) => {
        return JSON.parse(result);
      });
  }

  getBudgeted(Reps: string[], tp: string): Promise<CurrentVsBudgeted[]> {
    return this._authService
      .makeRequest('/api-v1.2.1/dashboard/getBudgeted?TimePeriod=' + tp, Reps, 'post')
      .then((result: any) => {
        return JSON.parse(result);
      });
  }

  getProfitMargin(Reps: string[], tp: string): Promise<JpiSalesAnalysis[]> {
    return this._authService
      .makeRequest(
        '/api-v1.2.1/dashboard/getProfitMargin?TimePeriod=' +
          tp +
          '&start=2018-01-01&end=2018-08-01',
        Reps,
        'post'
      )
      .then((result: any) => {
        return JSON.parse(result);
      });
  }
  getProfitMarginJPI(
    Reps: string[],
    tp: string,
    year: number,
    brnid: string
  ): Promise<SalesProfitandMargin[]> {
    var brnId = '';
    if (brnid != '') {
      brnId = '/' + brnid;
    }

    return this._authService
      .makeJpiRequest(
        '/dashboard/SalesSummary/' + year.toString() + brnId,
        null,
        Reps,
        'post'
      )
      .then(function (result) {
        return JSON.parse(result);
      });
  }

  getCompanySales_Labels(
    Reps: string[],
    tp: string,
    year: number,
    labels: string,
    allData: string
  ): Promise<SalesProfitandMargin[]> {
    var lbls = '';
    if (labels != '') {
      lbls = '/' + labels;
    }

    return this._authService
      .makeJpiRequest(
        '/dashboard/SalesSummaryByCompanyType/' + year.toString() + '/' + allData + lbls,
        null,
        Reps,
        'post'
      )
      .then(function (result) {
        return JSON.parse(result);
      });
  }

  getCompanyGroupSales(
    Reps: string[],
    start: string,
    end: string,
    labels: string,
    allData: string,
    branches: string
  ): Promise<JpiCompanyOverview> {
    //dashboard/SalesSummary_MaddenCo/2021-01-01T00:00:00/2021-12-28T00:00:00/false/4902,4901,2637,2638/0
    if (branches == '') {
      branches = '0';
    }
    return this._authService
      .makeJpiRequest(
        '/dashboard/SalesSummary_MaddenCo/' +
          start.toString() +
          '/' +
          end.toString() +
          '/' +
          allData +
          '/' +
          labels +
          '/0/' +
          branches,
        null,
        Reps,
        'post'
      )
      .then(function (result) {
        return JSON.parse(result);
      });
  }

  getIsDMSUser(agencyId: String): Promise<Number> {
    return this._authService
      .makeJpiRequest('/clients/' + agencyId, null, null, 'get')
      .then(function (result) {
        var JSONObject = JSON.parse(result);
        return JSONObject['integrationid'];
      });
  }

  getCustomerLabelActivity(Reps: string[], groupId: number, year: number) {
    return this._authService
      .makeJpiRequest(
        '/dashboard/customerLabelActivity/' + groupId.toString() + '/' + year.toString(),
        null,
        Reps,
        'post'
      )
      .then(function (result) {
        return JSON.parse(result);
      });
  }
  getLabelGroups() {
    return this._authService
      .makeJpiRequest('/companies/groupLabels', null, null, 'get')
      .then(function (result) {
        return JSON.parse(result);
      });
  }
  getLabelsInGroups() {
    return this._authService
      .makeJpiRequest('/companylabelgroups', null, null, 'get')
      .then(function (result) {
        return JSON.parse(result);
      });
  }
  getDARSummary(agentIds: string[]) {
    return this._authService
      .makeJpiRequest('/dashboard/darSummary', null, agentIds, 'post')
      .then(function (result) {
        return JSON.parse(result);
      });
  }
  getDAR(start: string, end: string) {
    return this._authService
      .makeJpiRequest(
        '/dashboard/callnote/user/dar/' + start + '/' + end,
        null,
        null,
        'get'
      )
      .then(function (result) {
        return JSON.parse(result);
      });
  }
  getAverageCalls(
    Reps: string[],
    tp: string,
    start: string,
    end: string
  ): Promise<AverageCalls[]> {
    tp = 'This Month';
    return this._authService
      .makeRequest(
        '/api-v1.2.1/dashboard/getAverageCalls?TimePeriod=' +
          tp +
          '&start=' +
          start +
          '&end=' +
          end,
        Reps,
        'post'
      )
      .then((result: any) => {
        return JSON.parse(result);
      });
  }

  getMonthlyPace(
    Reps: string[],
    tp: string,
    start: string,
    end: string
  ): Promise<MonthlyPace[]> {
    return this._authService
      .makeRequest(
        '/api-v1.2.1/dashboard/getMonthlyPace?TimePeriod=' +
          tp +
          '&start=' +
          start +
          '&end=' +
          end,
        Reps,
        'post'
      )
      .then((result: any) => {
        return JSON.parse(result);
      });
  }

  getGoals(): Promise<Goals[]> {
    return this._authService
      .makeRequest('/api-v1.2.1/dashboard/getGoals', '', 'get')
      .then((result: any) => {
        return JSON.parse(result);
      });
  }

  setGoal(
    agentid: number,
    goaldesclinkid: number,
    value: number,
    amount: number,
    year: number,
    goalid: number
  ): Promise<boolean> {
    const uri =
      '/api-v1.2.1/dashboard/setGoal?agentid=' +
      agentid.toString() +
      '&goaldesclinkid=' +
      goaldesclinkid.toString() +
      '&value=' +
      value.toString() +
      '&amount=' +
      amount.toString() +
      '&year=' +
      year.toString() +
      '&goalid=' +
      goalid.toString();
    return this._authService.makeRequest(uri, '', 'post').then((result: any) => {
      return JSON.parse(result);
    });
  }

  getCallsPerCompanyType(
    reps: string[],
    tp: string,
    start: string,
    end: string
  ): Promise<CallsPerCompanyType[]> {
    const R = Object.assign({ Reps: reps });
    tp = 'This Month';
    return this._authService
      .makeRequest(
        '/api-v1.2.1/dashboard/getCallsPerCompanyType?TimePeriod=' +
          tp +
          '&start=' +
          start +
          '&end=' +
          end,
        reps,
        'post'
      )
      .then((result: any) => {
        return JSON.parse(result);
      });
  }

  getPages(featureIds: number[]): Promise<Pages[]> {
    return this._authService
      .makeRequest('/api-v1.2.1/dashboard/getPages', featureIds, 'post')
      .then((result: any) => {
        return JSON.parse(result);
      });
  }

  setPageAssociation(pageId: number, remove: boolean): Promise<AverageCalls[]> {
    return this._authService
      .makeRequest(
        '/api-v1.2.1/dashboard/setPageAssignment?pageId=' + pageId + '&remove=' + remove,
        '',
        'post'
      )
      .then((result: any) => {
        return JSON.parse(result);
      });
  }

  getSelectedReps(): Promise<number[]> {
    return this._authService
      .makeRequest('/api-v1.2.1/dashboard/getSelectedReps', '', 'Get')
      .then((result: any) => {
        return JSON.parse(result);
      });
  }

  setSelectedRep(agentId: number, remove: boolean): Promise<boolean> {
    return this._authService
      .makeRequest(
        '/api-v1.2.1/dashboard/setSelectedRep?agentId=' + agentId + '&remove=' + remove,
        '',
        'Post'
      )
      .then((result: any) => {
        return JSON.parse(result);
      });
  }
  getSalesActivitiesSummary(
    reps: string[],
    tp: string,
    start: string,
    end: string
  ): Promise<SalesActivitySummary[]> {
    const R = Object.assign({ Reps: reps });

    return this._authService
      .makeRequest(
        '/api-v1.2.1/dashboard/GetSalesActivitySummary?TimePeriod=' +
          tp +
          '&start=' +
          start +
          '&end=' +
          end,
        reps,
        'post'
      )
      .then((result: any) => {
        return JSON.parse(result);
      });
  }
  getCompanyVisitSummary(
    reps: string[],
    tp: string,
    start: string,
    end: string
  ): Promise<CompanyVisitSummary[]> {
    const R = Object.assign({ Reps: reps });
    return this._authService
      .makeRequest(
        '/api-v1.2.1/dashboard/GetCompanyVisitSummary?TimePeriod=' +
          tp +
          '&start=' +
          start +
          '&end=' +
          end,
        reps,
        'post'
      )
      .then((result: any) => {
        return JSON.parse(result);
      });
  }
  getDaysSinceLastVisit(
    reps: string[],
    tp: string,
    start: string,
    end: string
  ): Promise<DaysSinceLastVisit[]> {
    const R = Object.assign({ Reps: reps });
    return this._authService
      .makeRequest(
        '/api-v1.2.1/dashboard/DaysSinceLastVisit?TimePeriod=' +
          tp +
          '&start=' +
          start +
          '&end=' +
          end,
        reps,
        'post'
      )
      .then((result: any) => {
        return JSON.parse(result);
      });
  }
  getCompanyList(
    reps: string[],
    tp: string,
    start: string,
    end: string
  ): Promise<CompanyList[]> {
    const R = Object.assign({ Reps: reps });
    return this._authService
      .makeRequest(
        '/api-v1.2.1/dashboard/CompanyList?TimePeriod=' +
          tp +
          '&start=' +
          start +
          '&end=' +
          end,
        reps,
        'post'
      )
      .then((result: any) => {
        return JSON.parse(result);
      });
  }
  getSalesActivities(
    reps: string[],
    tp: string,
    start: string,
    end: string
  ): Promise<CompanyList[]> {
    const R = Object.assign({ Reps: reps });
    return this._authService
      .makeRequest(
        '/api-v1.2.1/dashboard/SalesActivities?TimePeriod=' +
          tp +
          '&start=' +
          start +
          '&end=' +
          end,
        reps,
        'post'
      )
      .then((result: any) => {
        return JSON.parse(result);
      });
  }
  getOpportunities(
    reps: string[],
    tp: string,
    start: string,
    end: string
  ): Promise<Opportunity[]> {
    const R = Object.assign({ Reps: reps });
    return this._authService
      .makeRequest(
        '/api-v1.2.1/dashboard/Opportunities?TimePeriod=' +
          tp +
          '&start=' +
          start +
          '&end=' +
          end,
        reps,
        'post'
      )
      .then((result: any) => {
        return JSON.parse(result);
      });
  }
}
