import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { User } from 'src/app/models/admin/users/user';
import { GenericMap } from 'src/app/models/generic-map';
import { PageableData, PrivateNote } from 'src/app/models/private-notes/private-note';

export enum PrivateNotesActionTypes {
  LoadPrivateNotes = '[PrivateNotes] Load Private Notes',
  LoadPrivateNotesSuccess = '[PrivateNotes] Load Private Notes Success',
  LoadPrivateNotesFailure = '[PrivateNotes] Load Private Notes Failure',
  LoadPrivateNotesByCompanyId = '[PrivateNotes] Load Private Notes By Company Id',
  LoadPrivateNotesByCompanyIdSuccess = '[PrivateNotes] Load Private Notes By Company Id Success',
  LoadPrivateNotesByCompanyIdFailure = '[PrivateNotes] Load Private Notes By Company Id Failure',
  LoadPrivateNotesAssignableUsers = '[PrivateNotes] Load Private Notes Assignable Users',
  LoadPrivateNotesAssignableUsersSuccess = '[PrivateNotes] Load Private Notes Assignable Users Success',
  LoadPrivateNotesAssignableUsersFailure = '[PrivateNotes] Load Private Notes Assignable Users Failure',
  SavePrivateNote = '[PrivateNotes] Save Private Note',
  SavePrivateNoteSuccess = '[PrivateNotes] Save Private Note Success',
  SavePrivateNoteFailure = '[PrivateNotes] Save Private Note Failure',
  UpdatePrivateNote = '[PrivateNotes] Update Private Note',
  UpdatePrivateNoteSuccess = '[PrivateNotes] Update Private Note Success',
  UpdatePrivateNoteFailure = '[PrivateNotes] Update Private Note Failure',
  DeletePrivateNote = '[PrivateNotes] Delete Private Note',
  DeletePrivateNoteSuccess = '[PrivateNotes] Delete Private Note Success',
  DeletePrivateNoteFailure = '[PrivateNotes] Delete Private Note Failure',
  DeletePrivateNoteBatch = '[PrivateNotes] Delete Private Note Batch',
  DeletePrivateNoteBatchSuccess = '[PrivateNotes] Delete Private Note Batch Success',
  DeletePrivateNoteBatchFailure = '[PrivateNotes] Delete Private Note Batch Failure'
}

export class LoadPrivateNotes implements Action {
  readonly type = PrivateNotesActionTypes.LoadPrivateNotes;
  constructor(public payload: { limit: number; offset: number }) {}
}

export class LoadPrivateNotesSuccess implements Action {
  readonly type = PrivateNotesActionTypes.LoadPrivateNotesSuccess;
  constructor(public payload: PageableData<PrivateNote>) {}
}

export class LoadPrivateNotesFailure implements Action {
  readonly type = PrivateNotesActionTypes.LoadPrivateNotesFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadPrivateNotesByCompanyId implements Action {
  readonly type = PrivateNotesActionTypes.LoadPrivateNotesByCompanyId;
  constructor(public payload: { companyId: number; limit: number; offset: number }) {}
}

export class LoadPrivateNotesByCompanyIdSuccess implements Action {
  readonly type = PrivateNotesActionTypes.LoadPrivateNotesByCompanyIdSuccess;
  constructor(public payload: PageableData<PrivateNote>) {}
}

export class LoadPrivateNotesByCompanyIdFailure implements Action {
  readonly type = PrivateNotesActionTypes.LoadPrivateNotesByCompanyIdFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadPrivateNotesAssignableUsers implements Action {
  readonly type = PrivateNotesActionTypes.LoadPrivateNotesAssignableUsers;
  constructor() {}
}

export class LoadPrivateNotesAssignableUsersSuccess implements Action {
  readonly type = PrivateNotesActionTypes.LoadPrivateNotesAssignableUsersSuccess;
  constructor(public payload: GenericMap<User>) {}
}

export class LoadPrivatenotesAssignableUsersFailure implements Action {
  readonly type = PrivateNotesActionTypes.LoadPrivateNotesAssignableUsersFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class SavePrivateNote implements Action {
  readonly type = PrivateNotesActionTypes.SavePrivateNote;
  constructor(public payload: PrivateNote) {}
}

export class SavePrivateNoteSuccess implements Action {
  readonly type = PrivateNotesActionTypes.SavePrivateNoteSuccess;
  constructor(public payload: PrivateNote) {}
}

export class SavePrivateNoteFailure implements Action {
  readonly type = PrivateNotesActionTypes.SavePrivateNoteFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class UpdatePrivateNote implements Action {
  readonly type = PrivateNotesActionTypes.UpdatePrivateNote;
  constructor(public payload: PrivateNote) {}
}

export class UpdatePrivateNoteSuccess implements Action {
  readonly type = PrivateNotesActionTypes.UpdatePrivateNoteSuccess;
  constructor(public payload: PrivateNote) {}
}

export class UpdatePrivateNoteFailure implements Action {
  readonly type = PrivateNotesActionTypes.UpdatePrivateNoteFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class DeletePrivateNote implements Action {
  readonly type = PrivateNotesActionTypes.DeletePrivateNote;
  constructor(public payload: number) {}
}

export class DeletePrivateNoteSuccess implements Action {
  readonly type = PrivateNotesActionTypes.DeletePrivateNoteSuccess;
  constructor(public payload: number) {}
}

export class DeletePrivateNoteFailure implements Action {
  readonly type = PrivateNotesActionTypes.DeletePrivateNoteFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class DeletePrivateNoteBatch implements Action {
  readonly type = PrivateNotesActionTypes.DeletePrivateNoteBatch;
  constructor(public payload: number[]) {}
}

export class DeletePrivateNoteBatchSuccess implements Action {
  readonly type = PrivateNotesActionTypes.DeletePrivateNoteBatchSuccess;
  constructor(public payload: number[]) {}
}

export class DeletePrivateNoteBatchFailure implements Action {
  readonly type = PrivateNotesActionTypes.DeletePrivateNoteBatchFailure;
  constructor(public payload: { id: number; error: HttpErrorResponse }[]) {}
}

export type PrivateNotesActions =
  | LoadPrivateNotes
  | LoadPrivateNotesSuccess
  | LoadPrivateNotesFailure
  | LoadPrivateNotesByCompanyId
  | LoadPrivateNotesByCompanyIdSuccess
  | LoadPrivateNotesByCompanyIdFailure
  | LoadPrivateNotesAssignableUsers
  | LoadPrivateNotesAssignableUsersSuccess
  | LoadPrivatenotesAssignableUsersFailure
  | SavePrivateNote
  | SavePrivateNoteSuccess
  | SavePrivateNoteFailure
  | UpdatePrivateNote
  | UpdatePrivateNoteSuccess
  | UpdatePrivateNoteFailure
  | DeletePrivateNote
  | DeletePrivateNoteSuccess
  | DeletePrivateNoteFailure
  | DeletePrivateNoteBatch
  | DeletePrivateNoteBatchSuccess
  | DeletePrivateNoteBatchFailure;
