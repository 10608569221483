import { Component, OnInit } from '@angular/core';
import { RootState } from 'src/app/store/store.reducer';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { selectUserFullName } from 'src/app/store/auth/selectors/full-name.selector';
import { ExpireAuth, Logout } from 'src/app/store/auth/auth.actions';
import { selectOptionsLinks } from './options-links.selector';
import { RouteDatum } from 'src/app/models/routing/route-datum.model';
import {
  OpenDrawer,
  OpenLeftDrawer,
  SetDefaultLandingPage
} from 'src/app/store/layout/layout.actions';
import { ContactSupportComponent } from '../contact-support/contact-support.component';
import { selectFeatures } from '../sidenav/sidenav-links.selector';
import { GenericMap } from 'src/app/models/generic-map';
import { Feature } from 'src/app/models/features/feature';
import { FeaturePermission } from 'src/app/models/features/feature-permission';
import { SignInAsUserComponent } from '../../pages/system-settings/sign-in-as-user/sign-in-as-user.component';
import { SelectLandingPageDialogueComponent } from '../select-landing-page-dialogue/select-landing-page-dialogue.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'tn-user-options',
  templateUrl: './user-options.component.html',
  styleUrls: ['./user-options.component.scss']
})
export class UserOptionsComponent implements OnInit {
  $links: Observable<RouteDatum[][]>;
  $username: Observable<string>;
  $email: Observable<string>;
  $canSignInAsUser: Observable<boolean>;
  $pending: Observable<boolean>;

  canExpireAuth = false;

  constructor(private store: Store<RootState>, public landingDialog: MatDialog) {}

  ngOnInit() {
    // if (!environment.production) {
    //   this.canExpireAuth = true;
    // }
    this.$pending = this.store.select('auth', 'pending');
    this.$links = this.store.select(selectOptionsLinks);
    this.$username = this.store.select(selectUserFullName);
    this.$email = this.store.select('auth', 'data', 'jpi', 'user', 'email');
    this.$canSignInAsUser = this.store.select(selectFeatures).pipe(
      map((featuresMap: GenericMap<Feature>) => {
        let adminPermissions: string[];
        if ((featuresMap != null || undefined) && Object.keys(featuresMap).length)
          adminPermissions = Object.values(featuresMap[5].Permissions)
            .filter((perm: FeaturePermission) => {
              return perm.Value === 'true' || perm.Value !== '0';
            })
            .map((perm: FeaturePermission) => perm.Name.toLowerCase());

        return adminPermissions?.includes('sign in as other users');
      })
    );
  }

  signOutHandler() {
    this.store.dispatch(new Logout());
  }

  openSupport() {
    this.store.dispatch(
      new OpenLeftDrawer({ component: ContactSupportComponent, data: {} })
    );
  }

  expireAuth() {
    this.store.dispatch(new ExpireAuth());
  }

  signInAsUser() {
    this.store.dispatch(new OpenDrawer({ component: SignInAsUserComponent, data: {} }));
  }

  selectLandingPage() {
    const dialogRef = this.landingDialog.open(SelectLandingPageDialogueComponent);

    dialogRef.afterClosed().subscribe((result) => {
      this.store.dispatch(new SetDefaultLandingPage(result));
    });
  }
}
