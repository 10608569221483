import { Injectable } from '@angular/core';

import { CompanyLabel } from '../../../models/admin/company-labels/company-label';
import { AuthenticationService } from '../../authentication.service';

@Injectable()
export class CompanyLabelService {
	constructor(private authService: AuthenticationService) { }

	getCompanyLabels(groupId: number): Promise<any> {
		return this.authService.makeJpiRequest('/companylabelgroups/' + groupId + '/labels', null, null, 'get')
			.then(response => JSON.parse(response));
	}

	getCompanyLabelById(groupId: number, labelId: number): Promise<any> {
		return this.authService.makeJpiRequest('/companylabelgroups/' + groupId + '/labels/' + labelId, null, null, 'get')
			.then(response => JSON.parse(response));
	}

	addCompanyLabel(groupId: number, label: CompanyLabel): Promise<any> {
		return this.authService.makeJpiRequest('/companylabelgroups/' + groupId + '/labels', null, label, 'post')
			.then(response => JSON.parse(response));
	}

	updateCompanyLabel(groupId: number, label: CompanyLabel): Promise<any> {
		return this.authService.makeJpiRequest('/companylabelgroups/' + groupId + '/labels/' + label.id, null, label, 'put')
			.then(response => JSON.parse(response));
	}

	deleteCompanyLabel(groupId: number, label: CompanyLabel): Promise<any> {
		return this.authService.makeJpiRequest('/companylabelgroups/' + groupId + '/labels/' + label.id, null, label, 'delete')
			.then(response => JSON.parse(response));
	}
}
