import { Pipe, PipeTransform } from '@angular/core';

import { Company } from '../../../models/companies/company';
import { CompanyBasic } from 'src/app/models/companies/company-basic';

// Input: Company or CompanyBasic Object

// Does:

// converts null address fields to empty strings
// trims leading and trailing spaces from address fields
// concatenates address fields into a single string
// defaults to BillingAddress, or uses ShippingAddress if BillingAddress is missing

// Output: company address as string.

@Pipe({ name: 'companyAddress' })
export class CompanyAddressPipe implements PipeTransform {
  transform(company: Company | CompanyBasic): string {
    let address: any;
    if (company.billingAddress) {
      address = company.billingAddress;
    } else if (company.shippingAddress) {
      address = company.shippingAddress;
    } else {
      return 'No Address Provided';
    }

    this.fixNullFields(address);
    this.trimAddressFields(address);
    return this.concatenateAddressFields(address);
  }

  fixNullFields(address): void {
    const addressFields: string[] = Object.keys(address);
    addressFields.forEach(addressField => {
      if (!address[addressField]) {
        address[addressField] = '';
      }
    });
  }

  trimAddressFields(address): void {
    const addressFields: string[] = Object.keys(address);
    addressFields.forEach(
      addressField => (address[addressField] = address[addressField].trim())
    );
  }

  concatenateAddressFields(address): any {
    const addressFields: string[] = Object.values(address);
    const addressFieldArray: string[] = [];
    addressFields.forEach(addressField => {
      if (addressField !== '') {
        addressField.charAt(addressField.length - 1) === '.'
          ? addressFieldArray.push(addressField + ' ')
          : addressFieldArray.push(addressField + ', ');
      }
    });

    return addressFieldArray.length === 0
      ? 'No Address Provided'
      : addressFieldArray.join('').slice(0, -2);
  }
}
