<div class="add-plan-form">
  <h3 *ngIf="!currentPlan?.id">New Plan</h3>
  <h3 *ngIf="currentPlan?.id">Edit {{ currentPlan.name }}</h3>

  <form [formGroup]="planForm" class="example-form">
    <mat-form-field appearance="fill" class="example-full-width">
      <mat-label>Plan Name</mat-label>
      <input [value]="currentPlan?.name" formControlName="name" matInput type="string" />
    </mat-form-field>

    <mat-form-field appearance="fill" class="example-full-width">
      <mat-label>Description</mat-label>
      <input
        [value]="currentPlan?.description"
        formControlName="description"
        matInput
        type="string"
      />
    </mat-form-field>
  </form>

  <div *ngIf="currentPlan?.id">
    <mat-divider></mat-divider>

    <h4>Features</h4>
    <div fxLayout="row">
      <div fxLayout="column" fxFlex="50">
        <mat-checkbox
          [(ngModel)]="dashboardFeature.isChecked"
          (change)="dashboardChange(dashboardFeature.isChecked)"
          name="dashboard"
          id="dashboard"
          >Dashboard</mat-checkbox
        >
        <mat-checkbox
          [(ngModel)]="mapsFeature.isChecked"
          (change)="mapsChange(mapsFeature.isChecked)"
          name="maps"
          id="maps"
          >Maps</mat-checkbox
        >
        <mat-checkbox
          [(ngModel)]="opportunitiesFeature.isChecked"
          (change)="opportunitiesChange(opportunitiesFeature.isChecked)"
          name="opportunities"
          id="opportunities"
          >Opportunities</mat-checkbox
        >
        <mat-checkbox
          [(ngModel)]="projectsFeature.isChecked"
          (change)="projectsChange(projectsFeature.isChecked)"
          name="projects"
          id="projects"
          >Projects</mat-checkbox
        >
        <mat-checkbox
          [(ngModel)]="salesFeature.isChecked"
          (change)="salesChange(salesFeature.isChecked)"
          name="sales"
          id="sales"
          >Sales</mat-checkbox
        >
      </div>
      <div fxLayout="column" fxFlex="50">
        <mat-checkbox
          [(ngModel)]="excedeFeature.isChecked"
          (change)="excedeChange(excedeFeature.isChecked)"
          name="excede"
          id="excede"
          >DMS</mat-checkbox
        >
        <mat-checkbox
          [(ngModel)]="maddenCoFeature.isChecked"
          (change)="maddenCoChange(maddenCoFeature.isChecked)"
          name="maddenCo"
          id="maddenCo"
          >Madden Co.</mat-checkbox
        >
        <mat-checkbox
          [(ngModel)]="rigDigFeature.isChecked"
          (change)="rigDigChange(rigDigFeature.isChecked)"
          name="rigDig"
          id="rigDig"
          >Rig Dig</mat-checkbox
        >
        <mat-checkbox
          [(ngModel)]="iSQuoteFeature.isChecked"
          (change)="iSQuoteChange(iSQuoteFeature.isChecked)"
          name="iSQuote"
          id="iSQuote"
          >ISQuote</mat-checkbox
        >
      </div>
    </div>

    <br />

    <mat-divider></mat-divider>
    <br />
  </div>
  <div fxLayout="row-reverse" fxLayoutAlign="space-between end">
    <div fxLayout="row-reverse">
      <button
        *ngIf="!currentPlan?.id"
        mat-raised-button
        color="primary"
        mat-button
        class="button-container"
        (click)="save()"
        [disabled]="planForm.pristine || planForm.invalid"
      >
        Save
      </button>
      <button
        *ngIf="currentPlan?.id"
        mat-raised-button
        mat-button
        color="primary"
        class="button-container"
        (click)="update()"
        [disabled]="planForm.pristine || planForm.invalid"
      >
        Save
      </button>
      <button
        preventDefaultAction
        class="button-container"
        mat-raised-button
        mat-button
        (click)="cancel()"
      >
        Cancel
      </button>
    </div>
    <div>
      <button
        preventDefaultAction
        *ngIf="currentPlan?.id"
        mat-raised-button
        color="warn"
        mat-button
        (click)="delete()"
      >
        Delete
      </button>
    </div>
  </div>
</div>
