import { RootState } from '../../store.reducer';
import { MemoizedSelector, createSelector } from '@ngrx/store';
import { Mileage } from 'src/app/models/activities/mileage';
import { selectAllMileage } from './all-mileage-as-array.selector';
import { GenericMap } from 'src/app/models/generic-map';

export const selectedMileageId = (state: RootState) => state.mileage.selectedMileageId;

export const selectSelectedMileage: MemoizedSelector<RootState, Mileage> = createSelector(
  selectAllMileage,
  selectedMileageId,
  (mileageMap: GenericMap<Mileage>, mileageId: number): Mileage => {
    let mileage: Mileage;
    if (!!mileageId) {
      const id = mileageId.toString();
      mileage = {
        ...mileageMap[id],
        assignedToUser: mileageMap[id].assignedToUser
      };
    }
    return mileage;
  }
);
