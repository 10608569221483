import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FollowUp } from 'src/app/models/call-notes/follow-up';

@Component({
  selector: 'tn-call-note-card-follow-up',
  templateUrl: './call-note-card-follow-up.component.html',
  styleUrls: ['./call-note-card-follow-up.component.scss']
})
export class CallNoteCardFollowUpComponent {
  @Input() followUp: FollowUp;
  @Input() isSameDay: boolean;
  @Output() emitChecked: EventEmitter<boolean> = new EventEmitter();
}
