import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, withLatestFrom } from 'rxjs/operators';
import {
  UndoDeleteActionTypes,
  UndoDeleteActions,
  DeleteObject,
  ExecuteDelete,
  ClearDeletedState,
  SetDeleteAction,
  AddObjectsToDeletion
} from './undo-delete.actions';
import { Store } from '@ngrx/store';
import { RootState } from '../store.reducer';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UndoDeleteComponent } from 'src/app/view/pages/undo-delete/undo-delete.component';

@Injectable()
export class UndoDeleteEffects {
  timeout: any;

  deleteObject$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UndoDeleteActionTypes.DeleteObject),
        withLatestFrom(this.store.select('undoDelete', 'currentDeleteAction')),
        map(([action, deleteAction]: [DeleteObject, any]) => {
          // on initial deletion
          if (deleteAction === undefined) {
            this.store.dispatch(new SetDeleteAction(action.payload.actionName));
            this.store.dispatch(new AddObjectsToDeletion(action.payload.objectsToDelete));
            this.openDeleteSnackbarComponent();
            // on additional deletions of the same type
          } else if (deleteAction === action.payload.actionName) {
            this.clearTimer();
            this.store.dispatch(new AddObjectsToDeletion(action.payload.objectsToDelete));
            this.openDeleteSnackbarComponent();
            // on additional deletions with different type
          } else {
            this.store.dispatch(new ExecuteDelete());
            this.store.dispatch(new AddObjectsToDeletion(action.payload.objectsToDelete));
            this.store.dispatch(new SetDeleteAction(action.payload.actionName));
            this.openDeleteSnackbarComponent();
          }
        })
      ),
    { dispatch: false }
  );

  executeDelete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UndoDeleteActionTypes.ExecuteDelete),
        withLatestFrom(
          this.store.select('undoDelete', 'currentDeleteAction'),
          this.store.select('undoDelete', 'itemsBeingDeleted')
        ),
        map(([action, deleteAction, items]: any) => {
          this.clearTimer();
          items.forEach((item) => {
            this.store.dispatch(new deleteAction(item.id));
          });
          this.store.dispatch(new ClearDeletedState());
        })
      ),
    { dispatch: false }
  );

  cancelDelete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UndoDeleteActionTypes.CancelDelete),
        map(() => {
          this.clearTimer();
          this.snackBar.dismiss();
          this.snackBar.open('Item Restored!', '', { duration: 1200 });
          this.store.dispatch(new ClearDeletedState());
        })
      ),
    { dispatch: false }
  );

  closeDeleteSnackbar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UndoDeleteActionTypes.CloseDeleteSnackbar),
        map(() => {
          this.snackBar.dismiss();
          this.store.dispatch(new ExecuteDelete());
        })
      ),
    { dispatch: false }
  );

  private callDeleteAction() {
    this.store.dispatch(new ExecuteDelete());
  }

  private clearTimer() {
    clearTimeout(this.timeout);
  }

  private openDeleteSnackbarComponent() {
    this.snackBar.openFromComponent(UndoDeleteComponent, { duration: 8000 });
    this.timeout = setTimeout(() => this.callDeleteAction(), 8000);
  }

  constructor(
    private snackBar: MatSnackBar,
    private store: Store<RootState>,
    private actions$: Actions<UndoDeleteActions>
  ) {}
}
