import { tap } from 'rxjs/operators';
import { RootState } from '../../store.reducer';
import { MemoizedSelector, createSelector } from '@ngrx/store';
import { UsersMap } from 'src/app/models/admin/users/users-map';
import { User } from 'src/app/models/admin/users/user';
import { IdNameItem } from 'src/app/models/id-name-item';
import { Territory } from 'src/app/models/admin/territories/territory';
import { selectFeatures } from 'src/app/view/shared/sidenav/sidenav-links.selector';
import { GenericMap } from 'src/app/models/generic-map';
import { Feature } from 'src/app/models/features/feature';
import { deepFlattenArray } from 'src/app/utils/deepFlattenArray';

export const selectUsers = (state: RootState) => state.users.data;
export const selectUserTerritories = (state: RootState) =>
  state.auth.data.jpi?.user.territories;

export const selectMultiSelectUsers: MemoizedSelector<RootState, IdNameItem[]> =
  createSelector(
    selectUsers,
    selectFeatures,
    (usersMap: UsersMap, featurePerms: GenericMap<Feature>): IdNameItem[] => {
      const users = Object.values(usersMap);
      if (users?.length) {
        const jpiReturn = JSON.parse(window.localStorage.getItem('jpiReturn'));
        const localTerr = jpiReturn?.user?.territories;

        const flatlocalTerritories = deepFlattenArray<Territory>(
          localTerr,
          'subTerritories'
        );

        const subTerrUserIds: number[] = flatlocalTerritories
          .map((terr) => terr.users)
          .flat()
          .map((user) => user.id);
        const filteredUsers =
          (featurePerms != null || undefined) &&
          Object.keys(featurePerms).length &&
          featurePerms[1]?.Permissions[31]?.Value === '4'
            ? users
            : users.filter((user) => subTerrUserIds.includes(user.id));
        return filteredUsers
          .map((user: User) => {
            return {
              id: user.id,
              agencyId: user.agencyId,
              name: `${user.firstName} ${user.lastName}`,
              firstName: user.firstName,
              lastName: user.lastName,
              mailbox: user.mailbox,
              email: user.email,
              phone: user.phone,
              address: user.address,
              createdDate: user.createdDate,
              deleted: user.deleted,
              deletedDate: user.deletedDate,
              useNewTelenotes: user.useNewTelenotes
            };
          })
          .sort((a, b) => {
            const textA = a.name.toLowerCase();
            const textB = b.name.toLowerCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });
      } else {
        return [];
      }
    }
  );
