import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FileCategoriesEffects } from './file-categories.effects';
import { fileCategoriesReducer } from './file-categories.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('categories', fileCategoriesReducer),
    EffectsModule.forFeature([FileCategoriesEffects])
  ]
})
export class FileCategoriesModule {}
