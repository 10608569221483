import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ProjectBid } from 'src/app/models/admin/projects/project-bid';
import { ServicesModule } from '../../services.module';

@Injectable({
  providedIn: ServicesModule
})
export class ProjectBidsService {
  private jpiUrl = environment.jpiUrl;
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get(`${this.jpiUrl}/bidfields`);
  }

  addProjectBid(projectBid: ProjectBid) {
    return this.http.post(`${this.jpiUrl}/bidfields`, projectBid);
  }

  updateProjectBid(projectBid: ProjectBid) {
    return this.http.put(`${this.jpiUrl}/bidfields/${projectBid.id}`, projectBid);
  }

  deleteProjectBid(projectBidId: number) {
    return this.http.delete(`${this.jpiUrl}/bidfields/${projectBidId}`);
  }
}
