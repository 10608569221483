import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ProjectFieldsEffects } from './project-fields.effects';
import { projectFieldsReducer } from './project-fields.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('projectFields', projectFieldsReducer),
    EffectsModule.forFeature([ProjectFieldsEffects])
  ]
})
export class ProjectFieldsStateModule {}
