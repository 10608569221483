import { RootState } from '../../store.reducer';
import { MemoizedSelector, createSelector } from '@ngrx/store';
import { GenericMap } from 'src/app/models/generic-map';
import { selectedCallNoteId } from './current-call-note.selector';
import { GenericApi } from 'src/app/models/generic-api';
import { Comment } from 'src/app/models/call-notes/comment';
import { HttpErrorResponse } from '@angular/common/http';

export const selectedCallNoteComments = (state: RootState) =>
  state.callNotes.commentsByCallNoteId;

export const selectCallNoteComments: MemoizedSelector<RootState, Comment[]> =
  createSelector(
    selectedCallNoteId,
    selectedCallNoteComments,
    (
      callNoteId: number,
      callNoteCommentsMap: GenericMap<GenericApi<Comment[]>>
    ): Comment[] => {
      return callNoteCommentsMap[callNoteId]?.data;
    }
  );

export const selectCallNoteCommentsPending: MemoizedSelector<RootState, boolean> =
  createSelector(
    selectedCallNoteId,
    selectedCallNoteComments,
    (
      callNoteId: number,
      callNoteCommentsMap: GenericMap<GenericApi<Comment[]>>
    ): boolean => {
      return callNoteCommentsMap[callNoteId]?.pending;
    }
  );

export const selectCallNoteCommentsError: MemoizedSelector<RootState, HttpErrorResponse> =
  createSelector(
    selectedCallNoteId,
    selectedCallNoteComments,
    (
      callNoteId: number,
      callNoteCommentsMap: GenericMap<GenericApi<Comment[]>>
    ): HttpErrorResponse => {
      return callNoteCommentsMap[callNoteId]?.error;
    }
  );
