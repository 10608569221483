import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { manageUsersReducer } from './manage-users.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ManageUsersEffects } from './manage-users.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('manageUsers', manageUsersReducer),
    EffectsModule.forFeature([ManageUsersEffects])
  ]
})
export class ManageUsersStateModule {}
