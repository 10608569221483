import { Injectable } from '@angular/core';

import { AuthenticationService } from '../../authentication.service';
import { ExpenseV2 } from '../../../models/expenseV2';
import { Observable } from 'rxjs';
import { ExpenseTypeV2 } from '../../../models/expense-typeV2';

@Injectable()
export class ExpenseService {
	constructor(private authService: AuthenticationService) { }

	getAll(): Observable<ExpenseV2[]> {
		return this.authService.makeNewJpiRequest("/expenses", null, null, "get").map((expense) => {
            const expenses: ExpenseV2[] = JSON.parse(expense);
            expenses.forEach(expense => {
                (<any>expense.assignedTo).fullName = expense.assignedTo.firstName + ' ' + expense.assignedTo.lastName;
            });
            return expenses;
        });
	}

	getById(id: number): Observable<ExpenseV2> {
		return this.authService.makeNewJpiRequest(`/expenses/${id}`, null, null, "get").map((expense) => {
			return this._parseExpense(expense);
		});
	}

	getAllByUserId(userId: number): Observable<ExpenseV2> {
		return this.authService.makeNewJpiRequest("/expenses", null, null, "get").map((expense) => {
			const expenses: ExpenseV2[] = JSON.parse(expense);
			let userExpenses = [];
            expenses.forEach(expense => {
				if (expense.assignedTo.id == userId) {
					(<any>expense.assignedTo).fullName = expense.assignedTo.firstName + ' ' + expense.assignedTo.lastName;
					userExpenses.push(expense);
				}
			});
            return userExpenses;
        });
	}

	create(expense: ExpenseV2): Observable<ExpenseV2> {
		return this.authService.makeNewJpiRequest("/expenses", null, expense, "post").map((expense) => {
			return this._parseExpense(expense);
		});
	}

	update(id: number, expense: ExpenseV2): Observable<ExpenseV2> {
		return this.authService.makeNewJpiRequest(`/expenses/${id}`, null, expense, "put").map((expense) => {
			return this._parseExpense(expense);
		});
	}

	delete(id: number): Observable<ExpenseV2> {
		return this.authService.makeNewJpiRequest(`/expenses/${id}`, null, { id: id }, "delete");
	}

	getTypes(): Observable<ExpenseTypeV2[]> {
		return this.authService.makeNewJpiRequest("/expensetypes", null, null, "get").map((expenseType) => {
			return JSON.parse(expenseType);
		});
	}

	private _parseExpense(expense: any) {
		const parsedExpense: any = JSON.parse(expense);
		parsedExpense.assignedTo.fullName = parsedExpense.assignedTo.firstName + ' ' + parsedExpense.assignedTo.lastName;
		return parsedExpense;
	}
}

