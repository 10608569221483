import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  concatMap,
  switchMap,
  tap,
  withLatestFrom,
  mergeMap,
  filter
} from 'rxjs/operators';
import { of, forkJoin, EMPTY } from 'rxjs';
import {
  LoadProjectsFailure,
  LoadProjectsSuccess,
  ProjectsActionTypes,
  ProjectsActions,
  SaveProjectSuccess,
  SaveProjectFailure,
  DeleteProjectSuccess,
  DeleteProjectSuccessNoRedirect,
  DeleteProjectFailure,
  RedirectToProjects,
  LoadProjects,
  LoadProjectBidsSuccess,
  SaveProjectBidSuccess,
  SaveProjectBidFailure,
  DeleteProjectBidSuccess,
  DeleteProjectBidFailure,
  LoadProjectBidsFailure,
  DeleteMultipleProjectBidsByCompanySuccess,
  LoadProjectBids,
  SaveProject,
  UploadProjectFileFailure,
  UploadProjectFileSuccess,
  DeleteProjectFileSuccess,
  DeleteProjectFileFailure,
  SaveProjectEstimateSuccess,
  SaveProjectEstimateFailure,
  DeleteProjectEstimateSuccess,
  AddLabelColumns,
  NoLabelColsAdded,
  LabelColumnsAdded,
  RedirectToProject,
  LoadProject,
  LoadProjectSuccess,
  LoadProjectFailure,
  SaveProjectCustomFieldValueSuccess,
  SaveProjectCustomFieldValueFailure,
  SaveProjectCustomFieldValue,
  DeleteProjectCustomFieldValue,
  DeleteProjectCustomFieldValueSuccess,
  DeleteProjectCustomFieldValueFailure,
  SaveProjectCompanyCustomFieldValue,
  DeleteProjectCompanyCustomFieldValue,
  SaveProjectCompanyCustomFieldValueFailure,
  SaveProjectCompanyCustomFieldValueSuccess,
  DeleteProjectCompanyCustomFieldValueSuccess,
  DeleteProjectCompanyCustomFieldValueFailure,
  SaveProjectManufCustomFieldValue,
  SaveProjectManufCustomFieldValueSuccess,
  SaveProjectManufCustomFieldValueFailure,
  DeleteProjectManufCustomFieldValue,
  DeleteProjectManufCustomFieldValueSuccess,
  DeleteProjectManufCustomFieldValueFailure,
  SaveBidCustomFieldValue,
  SaveBidCustomFieldValueSuccess,
  SaveBidCustomFieldValueFailure,
  DeleteBidCustomFieldValue,
  DeleteBidCustomFieldValueSuccess,
  DeleteBidCustomFieldValueFailure,
  SaveCompanyLinkedProjectSuccess,
  SaveCompanyLinkedProjectFailure,
  LoadProjectBidsSummarySuccess,
  LoadProjectBidsSummaryFailure
} from './projects.actions';
import { convertToMap } from 'src/app/utils/convertToMap';
import { ProjectsService } from 'src/app/services/projects/projects.service';
import { Router } from '@angular/router';
import { Project } from 'src/app/models/projects/project';
import { Store } from '@ngrx/store';
import { RootState } from '../store.reducer';
import { ProjectBid } from 'src/app/models/projects/project-bid';
import { selectCurrentProject } from './selectors/current-project.selector';
import { FilesService } from 'src/app/services/files/files.service';
import { FileMetaData } from 'src/app/models/file-meta-data';
import {
  OpenSnackbar,
  OpenErrorSnackbar,
  SetLoadingBarVisibility,
  CloseDrawer
} from '../layout/layout.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { ProjectLinkedCompany } from 'src/app/models/projects/project-linked-company';
import { GenericMap } from 'src/app/models/generic-map';
import { LabelGroup } from 'src/app/models/label-group';
import { ColumnType, ColumnHeader } from 'src/app/models/column-header';
import { LoadCompanies } from '../companies/companies.actions';
import { selectAllCompanies } from '../companies/selectors/current-company.selector';
import { ApiProjectCustomFieldValue } from 'src/app/models/projects/api-project-custom-field-value';
import { ApiProjectCompanyCustomFieldValue } from 'src/app/models/projects/api-project-company-custom-field-value';
import { ApiProjectManufCustomFieldValue } from 'src/app/models/projects/api-project-manuf-custom-field-value';
import { ApiBidCustomFieldValue } from 'src/app/models/projects/api-bid-custom-field-value';

@Injectable()
export class ProjectsEffects {
  constructor(
    private actions$: Actions<ProjectsActions>,
    private store$: Store<RootState>,
    private projService: ProjectsService,
    private filesService: FilesService,
    private router: Router
  ) {}

  addLabelColumns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActionTypes.AddLabelColumns),
      withLatestFrom(
        this.store$.select('projectLabels', 'data'),
        this.store$.select('projects', 'useDefaultColumns')
      ),
      map(
        ([action, labels, useDefaultCols]: [
          AddLabelColumns,
          GenericMap<LabelGroup>,
          boolean
        ]) => {
          if (!!labels && !!Object.values(labels).length && useDefaultCols) {
            const labelCols: ColumnHeader[] = Object.values(labels).map((label) => ({
              name: label.name,
              displayName: label.name,
              type: ColumnType.LABEL
            }));
            return new LabelColumnsAdded(labelCols);
          }
          return new NoLabelColsAdded();
        }
      )
    )
  );

  loadProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActionTypes.LoadProjects),
      concatMap(() =>
        this.projService.getProjects().pipe(
          switchMap((res: Project[]) => {
            const actionsArr: any[] = [new LoadProjectsSuccess(convertToMap(res, 'id'))];
            return actionsArr;
          }),
          catchError((error) => of(new LoadProjectsFailure(error)))
        )
      )
    )
  );

  loadProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActionTypes.LoadProject),
      concatMap((action: LoadProject) =>
        this.projService.getProject(action.payload).pipe(
          map((project: Project) => {
            return new LoadProjectSuccess(project);
          }),
          catchError((error: HttpErrorResponse) => of(new LoadProjectFailure(error)))
        )
      )
    )
  );

  projError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProjectsActionTypes.LoadProjectFailure,
        ProjectsActionTypes.LoadProjectsFailure,
        ProjectsActionTypes.SaveProjectFailure,
        ProjectsActionTypes.LoadProjectBidsFailure,
        ProjectsActionTypes.SaveProjectBidFailure,
        ProjectsActionTypes.DeleteProjectBidFailure,
        ProjectsActionTypes.DeleteProjectFailure,
        ProjectsActionTypes.DeleteProjectFileFailure,
        ProjectsActionTypes.SaveProjectEstimateFailure,
        ProjectsActionTypes.DeleteProjectEstimateFailure
      ),
      map((action: { type: string; payload: any }) => action.payload),
      map((error: HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse) {
          return new OpenErrorSnackbar({
            duration: 10000,
            message: `${error.status}: ${error.message}`,
            opened: true
          });
        } else if (!!(error as any).error) {
          const errProp: HttpErrorResponse = (error as any).error;
          return new OpenErrorSnackbar({
            duration: 10000,
            message: `${errProp.status}: ${errProp.message}`,
            opened: true
          });
        } else {
          return new OpenErrorSnackbar({
            duration: 10000,
            message: 'There has been an error',
            opened: true
          });
        }
      })
    )
  );

  saveProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActionTypes.SaveProject),
      withLatestFrom(this.store$),
      map((actionAndStore) => ({
        payload: actionAndStore[0].payload,
        store: actionAndStore[1]
      })),
      concatMap(({ payload, store }) => {
        const agencyId = store.auth.data.jpi.user.agencyId;
        const project = {
          ...payload,
          agencyId
        };
        if (!project.id) {
          return this.projService.createProject(project).pipe(
            switchMap((res: Project) => [
              new SaveProjectSuccess(res),
              new LoadProject(res.id),
              new RedirectToProject(res.id)
            ]),
            catchError((error) => of(new SaveProjectFailure(error)))
          );
        } else {
          return this.projService.updateProject(project).pipe(
            switchMap((res: Project) => [
              new SaveProjectSuccess(res),
              new LoadProject(res.id),
              new LoadCompanies({
                filters: [
                  {
                    operand1: 'id',
                    operator: 'IS_ANY_OF',
                    operand2: res.linkedCompanies.map((lc) => lc.companyId)
                  }
                ]
              })
            ]),
            catchError((error) => of(new SaveProjectFailure(error)))
          );
        }
      })
    )
  );

  deleteProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActionTypes.DeleteProject),
      concatMap(({ payload }) => {
        if (payload.redirect) {
          return this.projService.deleteProject(payload.id).pipe(
            map(() => new DeleteProjectSuccess()),
            catchError((error) => of(new DeleteProjectFailure(error)))
          );
        } else {
          return this.projService.deleteProject(payload.id).pipe(
            map(() => new DeleteProjectSuccessNoRedirect()),
            catchError((error) => of(new DeleteProjectFailure(error)))
          );
        }
      })
    )
  );

  deleteProjectSuccessNoRedirect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActionTypes.DeleteProjectSuccessNoRedirect),
      switchMap(() => [new LoadProjects()])
    )
  );

  deleteProjectSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActionTypes.DeleteProjectSuccess),
      switchMap(() => [new RedirectToProjects(), new LoadProjects()])
    )
  );

  redirectToProjects$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProjectsActionTypes.RedirectToProjects),
        tap(() => {
          this.router.navigate(['/projects']);
        })
      ),
    { dispatch: false }
  );

  redirectToProjectById$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProjectsActionTypes.RedirectToProject),
        map((action: RedirectToProject) => action.payload),
        mergeMap((id: number) => this.router.navigate([`/projects/project/${id}/bids`]))
      ),
    { dispatch: false }
  );

  loadProjectBids$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActionTypes.LoadProjectBids),
      concatMap(({ payload }) => {
        return this.projService.getProjectBids(payload.id).pipe(
          map((res: ProjectBid[]) => {
            return new LoadProjectBidsSuccess({
              project: payload,
              bidsMap: res.reduce((arrMap, obj) => {
                arrMap[obj.companyId] = {
                  ...arrMap[obj.companyId],
                  [obj.id]: obj
                };
                return arrMap;
              }, {})
            });
          }),
          catchError((error) =>
            of(new LoadProjectBidsFailure({ project: payload, error }))
          )
        );
      })
    )
  );

  loadProjectBidsSummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActionTypes.LoadProjectBidsSummary),
      concatMap(({ payload }) =>
        this.projService.getProjectBidsSummary(payload.id).pipe(
          map((summary: any[]) => {
            return new LoadProjectBidsSummarySuccess(summary);
          }),
          catchError((error: HttpErrorResponse) =>
            of(new LoadProjectBidsSummaryFailure(error))
          )
        )
      )
    )
  );

  loadProjectBidsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActionTypes.LoadProjectBidsSuccess),
      map(
        ({ payload }) =>
          new LoadCompanies({
            filters: [
              {
                operand1: 'id',
                operator: 'IS_ANY_OF',
                operand2: Object.keys(payload.bidsMap)
              }
            ]
          })
      )
    )
  );

  saveProjectBid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActionTypes.SaveProjectBid),
      concatMap(({ payload }) => {
        if (!payload.bid.id) {
          return this.projService.createProjectBid(payload.project.id, payload.bid).pipe(
            switchMap((res: ProjectBid) => [
              new SaveProjectBidSuccess(res),
              new LoadProjectBids(payload.project),
              new LoadProject(res.projectId)
            ]),
            catchError((error) =>
              of(new SaveProjectBidFailure({ bid: payload.bid, error }))
            )
          );
        } else {
          return this.projService.updateProjectBid(payload.project.id, payload.bid).pipe(
            switchMap((res: ProjectBid) => [
              new SaveProjectBidSuccess(res),
              new LoadProjectBids(payload.project),
              new LoadProject(payload.project.id)
            ]),
            catchError((error) =>
              of(new SaveProjectBidFailure({ bid: payload.bid, error }))
            )
          );
        }
      })
    )
  );

  deleteProjectBid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActionTypes.DeleteProjectBid),
      concatMap(({ payload }) =>
        this.projService.deleteProjectBid(payload.project.id, payload.bid.id).pipe(
          switchMap((res: ProjectBid) => [
            new DeleteProjectBidSuccess(res),
            new LoadProjectBids(payload.project),
            new LoadProject(payload.project.id)
          ]),
          catchError((error) =>
            of(new DeleteProjectBidFailure({ bid: payload.bid, error }))
          )
        )
      )
    )
  );

  deleteMultipleProjectBidsByCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActionTypes.DeleteMultipleProjectBidsByCompany),
      withLatestFrom(this.store$.select(selectAllCompanies)),
      map((actionAndStore) => ({
        payload: actionAndStore[0].payload,
        allCompanies: actionAndStore[1]
      })),
      concatMap(({ payload, allCompanies }) => {
        const companyContactIds = allCompanies[payload.companyId].contacts.map(
          (contact) => contact.id
        );
        const bidsToBeDeletedIds = payload.bids.map((bid) => bid.id);
        const newProj: Project = {
          ...payload.project,
          contactIds: payload.project.contactIds.filter(
            (id) => !companyContactIds.includes(id)
          ),
          bids: payload.project.bids.filter(
            (bid) => !bidsToBeDeletedIds.includes(bid.id)
          ),
          linkedCompanies: payload.project.linkedCompanies.filter(
            (plc: ProjectLinkedCompany) => {
              return plc.companyId !== payload.companyId;
            }
          )
        };
        if (payload.bids.length) {
          return forkJoin(
            payload.bids.map((bid: ProjectBid) =>
              this.projService
                .deleteProjectBid(bid.projectId, bid.id)
                .pipe(
                  catchError((error) => of(new DeleteProjectBidFailure({ bid, error })))
                )
            )
          ).pipe(
            switchMap(() => {
              return [
                new DeleteMultipleProjectBidsByCompanySuccess({
                  projectId: payload.project.id,
                  companyId: payload.companyId
                }),
                new SaveProject(newProj),
                new LoadProjectBids(newProj)
              ];
            })
          );
        } else {
          return of(new SaveProject(newProj));
        }
      })
    )
  );

  uploadProjectFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActionTypes.UploadProjectFile),
      tap(() => this.store$.dispatch(new SetLoadingBarVisibility(true))),
      withLatestFrom(this.store$.select(selectCurrentProject)),
      map((actionAndStore) => ({
        file: actionAndStore[0].payload,
        project: actionAndStore[1]
      })),
      switchMap(({ file, project }) => {
        return this.filesService.uploadFile(file).pipe(
          switchMap((res: FileMetaData) => [
            new SetLoadingBarVisibility(false),
            new UploadProjectFileSuccess(res),
            new OpenSnackbar({
              duration: 5000,
              message: 'Uploaded file successfully',
              opened: true
            }),
            new SaveProject({
              ...project,
              files: [...project.files, { id: res.id } as FileMetaData]
            })
          ]),
          catchError((error: HttpErrorResponse) =>
            of(new UploadProjectFileFailure(error))
          )
        );
      })
    )
  );

  uploadProjectFileFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActionTypes.UploadProjectFileFailure),
      map((action) => action.payload),
      switchMap((error: HttpErrorResponse) => {
        return [
          new SetLoadingBarVisibility(false),
          new OpenErrorSnackbar({
            duration: 10000,
            message: error.status === 413 ? 'Unsupported tile type' : error.message,
            opened: true
          })
        ];
      })
    )
  );

  deleteProjectFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActionTypes.DeleteProjectFile),
      withLatestFrom(this.store$.select(selectCurrentProject)),
      map((actionAndStore) => ({
        fileId: actionAndStore[0].payload,
        project: actionAndStore[1]
      })),
      concatMap(({ fileId, project }) => {
        return this.filesService.deleteFile(fileId).pipe(
          switchMap((res: FileMetaData) => [
            new DeleteProjectFileSuccess(res),
            new SaveProject({
              ...project,
              files: project.files.filter((file) => file.id !== fileId)
            })
          ]),
          catchError((error) => of(new DeleteProjectFileFailure(error)))
        );
      })
    )
  );

  saveProjectEstimate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActionTypes.SaveProjectEstimate),
      withLatestFrom(this.store$.select(selectCurrentProject)),
      concatMap(([action, project]) => {
        const projEst = action.payload;
        const newProj: Project = {
          ...project,
          linkedManufacturers: project.linkedManufacturers.map((item) => {
            return item.manufacturerId === projEst.manufacturerId &&
              item.projectId === projEst.projectId
              ? projEst
              : item;
          })
        };
        return this.projService.updateProject(newProj).pipe(
          switchMap(() => [
            new SaveProjectEstimateSuccess(),
            new LoadProject(newProj.id)
          ]),
          catchError((error) =>
            of(new SaveProjectEstimateFailure({ projectEstimate: projEst, error }))
          )
        );
      })
    )
  );

  deleteProjectEstimate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActionTypes.DeleteProjectEstimate),
      withLatestFrom(this.store$.select(selectCurrentProject)),
      concatMap(([action, project]) => {
        const projEst = action.payload;
        const bidsToBeDeletedIds = action.payload.bids.map((bid) => bid.id);
        const remainingBids = project.bids.filter(
          (bid) => !bidsToBeDeletedIds.includes(bid.id)
        );
        const newProj: Project = {
          ...project,
          bids: remainingBids,
          linkedManufacturers: project.linkedManufacturers.filter((item) => {
            return item.manufacturerId !== projEst.manufacturerId;
          })
        };
        if (action.payload.bids.length > 0) {
          return forkJoin(
            action.payload.bids.map((bid: ProjectBid) =>
              this.projService
                .deleteProjectBid(bid.projectId, bid.id)
                .pipe(
                  catchError((error) => of(new DeleteProjectBidFailure({ bid, error })))
                )
            )
          ).pipe(
            switchMap(() => {
              return [
                new DeleteProjectEstimateSuccess(),
                new SaveProject(newProj),
                new LoadProjectBids(newProj)
              ];
            })
          );
        } else {
          return of(new SaveProject(newProj));
        }
      })
    )
  );

  // -=-=-=-= Project Custom Fields =-=-=-=-

  saveProjectCustomFieldValue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActionTypes.SaveProjectCustomFieldValue),
      map((action: SaveProjectCustomFieldValue) => action.payload),
      concatMap((payload: ApiProjectCustomFieldValue) => {
        if (payload.id) {
          return this.projService.updateProjectCustomFieldValue(payload).pipe(
            switchMap((res) => [
              new SaveProjectCustomFieldValueSuccess(res),
              new LoadProject(payload.projectId)
            ]),
            catchError((error) => of(new SaveProjectCustomFieldValueFailure(error)))
          );
        } else {
          return this.projService.createProjectCustomFieldValue(payload).pipe(
            switchMap((res) => [
              new SaveProjectCustomFieldValueSuccess(res),
              new LoadProject(payload.projectId)
            ]),
            catchError((error) => of(new SaveProjectCustomFieldValueFailure(error)))
          );
        }
      })
    )
  );

  deleteProjectCustomFieldValue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActionTypes.DeleteProjectCustomFieldValue),
      map((action: DeleteProjectCustomFieldValue) => action.payload),
      concatMap((payload: ApiProjectCustomFieldValue) => {
        return this.projService.deleteProjectCustomFieldValue(payload).pipe(
          switchMap(() => [
            new DeleteProjectCustomFieldValueSuccess(),
            new LoadProject(payload.projectId)
          ]),
          catchError((error) => of(new DeleteProjectCustomFieldValueFailure(error)))
        );
      })
    )
  );

  // -=-=-=-= Project Company Custom Fields =-=-=-=-

  saveProjectCompanyCustomFieldValue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActionTypes.SaveProjectCompanyCustomFieldValue),
      map((action: SaveProjectCompanyCustomFieldValue) => action.payload),
      concatMap((payload: ApiProjectCompanyCustomFieldValue) => {
        if (payload.id) {
          return this.projService.updateProjectCompanyCustomFieldValue(payload).pipe(
            switchMap((res) => [
              new SaveProjectCompanyCustomFieldValueSuccess(res),
              new LoadProject(payload.projectId)
            ]),
            catchError((error) =>
              of(new SaveProjectCompanyCustomFieldValueFailure(error))
            )
          );
        } else {
          return this.projService.createProjectCompanyCustomFieldValue(payload).pipe(
            switchMap((res) => [
              new SaveProjectCompanyCustomFieldValueSuccess(res),
              new LoadProject(payload.projectId)
            ]),
            catchError((error) =>
              of(new SaveProjectCompanyCustomFieldValueFailure(error))
            )
          );
        }
      })
    )
  );

  deleteProjectCompanyCustomFieldValue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActionTypes.DeleteProjectCompanyCustomFieldValue),
      map((action: DeleteProjectCompanyCustomFieldValue) => action.payload),
      concatMap((payload: ApiProjectCompanyCustomFieldValue) => {
        return this.projService.deleteProjectCompanyCustomFieldValue(payload).pipe(
          switchMap(() => [
            new DeleteProjectCompanyCustomFieldValueSuccess(),
            new LoadProject(payload.projectId)
          ]),
          catchError((error) =>
            of(new DeleteProjectCompanyCustomFieldValueFailure(error))
          )
        );
      })
    )
  );

  // -=-=-=-= Project Manuf Custom Fields =-=-=-=-

  saveProjectManufCustomFieldValue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActionTypes.SaveProjectManufCustomFieldValue),
      map((action: SaveProjectManufCustomFieldValue) => action.payload),
      concatMap((payload: ApiProjectManufCustomFieldValue) => {
        if (payload.id) {
          return this.projService.updateProjectManufCustomFieldValue(payload).pipe(
            switchMap((res) => [
              new SaveProjectManufCustomFieldValueSuccess(res),
              new LoadProject(payload.projectId)
            ]),
            catchError((error) => of(new SaveProjectManufCustomFieldValueFailure(error)))
          );
        } else {
          return this.projService.createProjectManufCustomFieldValue(payload).pipe(
            switchMap((res) => [
              new SaveProjectManufCustomFieldValueSuccess(res),
              new LoadProject(payload.projectId)
            ]),
            catchError((error) => of(new SaveProjectManufCustomFieldValueFailure(error)))
          );
        }
      })
    )
  );

  deleteProjectManufCustomFieldValue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActionTypes.DeleteProjectManufCustomFieldValue),
      map((action: DeleteProjectManufCustomFieldValue) => action.payload),
      concatMap((payload: ApiProjectManufCustomFieldValue) => {
        return this.projService.deleteProjectManufCustomFieldValue(payload).pipe(
          switchMap(() => [
            new DeleteProjectManufCustomFieldValueSuccess(),
            new LoadProject(payload.projectId)
          ]),
          catchError((error) => of(new DeleteProjectManufCustomFieldValueFailure(error)))
        );
      })
    )
  );

  // -=-=-=-= Bid Custom Fields =-=-=-=-

  saveBidCustomFieldValue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActionTypes.SaveBidCustomFieldValue),
      map((action: SaveBidCustomFieldValue) => action.payload),
      concatMap((payload: ApiBidCustomFieldValue) => {
        if (payload.id) {
          return this.projService.updateBidCustomFieldValue(payload).pipe(
            switchMap((res) => [
              new SaveBidCustomFieldValueSuccess(res),
              new LoadProject(payload.projectId)
            ]),
            catchError((error) => of(new SaveBidCustomFieldValueFailure(error)))
          );
        } else {
          return this.projService.createBidCustomFieldValue(payload).pipe(
            switchMap((res) => [
              new SaveBidCustomFieldValueSuccess(res),
              new LoadProject(payload.projectId)
            ]),
            catchError((error) => of(new SaveBidCustomFieldValueFailure(error)))
          );
        }
      })
    )
  );

  deleteBidCustomFieldValue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActionTypes.DeleteBidCustomFieldValue),
      map((action: DeleteBidCustomFieldValue) => action.payload),
      concatMap((payload: ApiBidCustomFieldValue) => {
        return this.projService.deleteBidCustomFieldValue(payload).pipe(
          switchMap(() => [
            new DeleteBidCustomFieldValueSuccess(),
            new LoadProject(payload.projectId)
          ]),
          catchError((error) => of(new DeleteBidCustomFieldValueFailure(error)))
        );
      })
    )
  );

  saveCompanyLinkedProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectsActionTypes.SaveCompanyLinkedProject),
      withLatestFrom(this.store$),
      map((actionAndStore) => ({
        payload: actionAndStore[0].payload,
        store: actionAndStore[1]
      })),
      concatMap(({ payload, store }) => {
        const agencyId = store.auth.data.jpi.user.agencyId;
        const project = {
          ...payload.project,
          agencyId
        };
        const companyId = payload.companyID;
        const companyName = payload.companyName;
        return this.projService.createProject(project).pipe(
          concatMap((res: Project) => [
            new SaveCompanyLinkedProjectSuccess(res),
            new SaveProject({
              ...res,
              linkedCompanies: [
                {
                  companyId,
                  companyName,
                  projectId: res.id
                }
              ]
            }),
            new CloseDrawer()
          ]),
          catchError((error) => of(new SaveCompanyLinkedProjectFailure(error)))
        );
      })
    )
  );
}
