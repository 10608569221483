import { Action } from '@ngrx/store';

export enum TutorialsActionTypes {
  LoadTutorials = '[Tutorials] Load Tutorials',
  LoadTutorialsSuccess = '[Tutorials] Load Tutorials Success',
  LoadTutorialsFailure = '[Tutorials] Load Tutorials Failure'
}

export class LoadTutorials implements Action {
  readonly type = TutorialsActionTypes.LoadTutorials;
}

export class LoadTutorialsSuccess implements Action {
  readonly type = TutorialsActionTypes.LoadTutorialsSuccess;
  constructor(public payload: { data: any }) {}
}

export class LoadTutorialsFailure implements Action {
  readonly type = TutorialsActionTypes.LoadTutorialsFailure;
  constructor(public payload: { error: any }) {}
}

export type TutorialsActions =
  | LoadTutorials
  | LoadTutorialsSuccess
  | LoadTutorialsFailure;
