import {
  ProjectFieldsActions,
  ProjectFieldsActionTypes,
  SetCurrentProjectField
} from './project-fields.actions';
import { ProjectField } from 'src/app/models/admin/projects/project-field';
import { HttpErrorResponse } from '@angular/common/http';
import { ProjectSetting } from '../../../models/projects/project-settings';

export interface ProjectFieldsState {
  pending: boolean;
  error: HttpErrorResponse;
  data: { [id: number]: ProjectField };
  projectSetting: ProjectSetting;
  selectedProjectFieldId: number;
  currentProjectField: ProjectField;
}

export const initialState: ProjectFieldsState = {
  pending: false,
  error: undefined,
  data: {},
  projectSetting: undefined,
  selectedProjectFieldId: undefined,
  currentProjectField: undefined
};

export function projectFieldsReducer(
  state = initialState,
  action: ProjectFieldsActions
): ProjectFieldsState {
  switch (action.type) {
    case ProjectFieldsActionTypes.SetCurrentProjectField:
      return { ...state, currentProjectField: action.payload };

    case ProjectFieldsActionTypes.SelectProjectFieldId:
      return {
        ...state,
        selectedProjectFieldId: action.payload
      };

    case ProjectFieldsActionTypes.ClearProjectFieldId:
      return {
        ...state,
        selectedProjectFieldId: undefined
      };

    case ProjectFieldsActionTypes.LoadProjectFields:
      return {
        ...state,
        pending: true
      };

    case ProjectFieldsActionTypes.LoadProjectFieldsSuccess:
      return {
        ...state,
        pending: false,
        data: action.payload
      };

    case ProjectFieldsActionTypes.LoadProjectFieldsFailure:
      return {
        ...state,
        pending: false,
        data: {},
        error: action.payload
      };

    case ProjectFieldsActionTypes.LoadProjectFieldsSetting:
      return {
        ...state,
        pending: true
      };

    case ProjectFieldsActionTypes.LoadProjectFieldsSettingSuccess:
      return {
        ...state,
        pending: false,
        projectSetting: action.payload
      };

    case ProjectFieldsActionTypes.LoadProjectFieldsSettingFailure:
      return {
        ...state,
        pending: false,
        projectSetting: undefined,
        error: action.payload
      };

    case ProjectFieldsActionTypes.CreateProjectFields:
      return {
        ...state,
        pending: true
      };

    case ProjectFieldsActionTypes.CreateProjectFieldsSuccess:
      return {
        ...state,
        pending: false
      };

    case ProjectFieldsActionTypes.CreateProjectFieldsFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case ProjectFieldsActionTypes.UpdateProjectFieldsSuccess:
      return {
        ...state,
        pending: false
      };

    case ProjectFieldsActionTypes.UpdateProjectFieldsFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case ProjectFieldsActionTypes.UpdateProjectFieldsSettingSuccess:
      return {
        ...state,
        pending: false,
        projectSetting: action.payload
      };

    case ProjectFieldsActionTypes.UpdateProjectFieldsSettingFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case ProjectFieldsActionTypes.DeleteProjectFields:
      return {
        ...state,
        pending: true
      };

    case ProjectFieldsActionTypes.DeleteProjectFieldsSuccess:
      return {
        ...state,
        pending: false,
        selectedProjectFieldId: undefined
      };

    case ProjectFieldsActionTypes.DeleteProjectFieldsFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    default:
      return state;
  }
}
