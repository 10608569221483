import { HelpActions, HelpActionTypes } from './help.actions';

export interface HelpState {
  pending: boolean;
}

export const initialState: HelpState = {
  pending: false
};

export function helpReducer(state = initialState, action: HelpActions): HelpState {
  switch (action.type) {
    case HelpActionTypes.LoadHelp:
      return {
        ...state,
        pending: true
      };

    default:
      return state;
  }
}
