import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { systemSettingsReducer } from './system-settings.reducer';
import { EffectsModule } from '@ngrx/effects';
import { SystemSettingsEffects } from './system-settings.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('systemSettings', systemSettingsReducer),
    EffectsModule.forFeature([SystemSettingsEffects])
  ]
})
export class SystemSettingsStateModule {}
