<mat-card
  class="call-note-card mat-elevation-z4"
  (mouseenter)="loadContactInfo()"
  [ngClass]="{ removed: removed && inFollowUpList }"
>
  <mat-card-header fxLayout="row" fxLayoutAlign="space-between center">
    <div
      class="call-note-title-info"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="24px"
    >
      <h3 class="call-note-title">Call Note</h3>
      <div class="call-note-date">
        {{ callNote.callDate | date : (isSameYear ? 'MMM dd' : 'mediumDate') }}
      </div>
      <div class="call-note-label-group">
        <mat-chip-list>
          <mat-chip>{{ callNote.type.name }}</mat-chip>
        </mat-chip-list>
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="24px" fxLayoutAlign="end center">
      <div
        class="created-by-and-call-note-id"
        fxLayout="column"
        fxLayoutGap="2px"
        fxLayoutAlign="center end"
      >
        <div>
          <span class="info-label">Created by: </span>
          <span class="name">{{
            !!callNote?.createdBy.name
              ? callNote?.createdBy.name
              : callNote?.createdBy.firstName + ' ' + callNote?.createdBy.lastName
          }}</span>
        </div>
        <div>
          <span class="info-label">Call Note ID: </span>
          <span class="name">{{ callNote.id }}</span>
        </div>
      </div>
      <div class="call-note-actions" fxLayout="row">
        <a
          mat-icon-button
          *ngIf="!!callNote.opportunityId && ($oppId | async) === false"
          [routerLink]="['/opportunities/opportunity', callNote.opportunityId]"
          target="_blank"
          color="accent"
          matTooltip="Go to linked opportunity"
        >
          <mat-icon>trending_up</mat-icon>
        </a>
        <a
          mat-icon-button
          *ngIf="!!callNote.projectId && ($projId | async) === false"
          [routerLink]="['/projects/project', callNote.projectId]"
          target="_blank"
          color="accent"
          matTooltip="Go to linked project"
        >
          <mat-icon>assignment</mat-icon>
        </a>
        <button
          mat-icon-button
          (click)="commentHandler(callNote)"
          color="primary"
          matTooltip="share/comment"
          *ngIf="showCommentsButton"
        >
          <mat-icon>comment</mat-icon>
        </button>
        <button mat-icon-button color="primary" (click)="editHandler()" matTooltip="edit">
          <mat-icon>edit</mat-icon>
        </button>
        <button
          mat-icon-button
          color="warn"
          (click)="deleteHandler(callNote)"
          matTooltip="delete"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-header>

  <mat-card-content *ngIf="showCompany">
    <mat-divider></mat-divider>
    &nbsp;
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="column" fxLayoutAlign="start start">
        <div fxLayout="row" fxLayoutAlign="start center">
          <h3 class="call-note-company-name">{{ callNote.company.name }}</h3>
          <a
            mat-icon-button
            class="company-link"
            matSuffix
            color="primary"
            target="_blank"
            [routerLink]="['/companies/company/', callNote.company.id]"
            ><mat-icon class="search-icon">call_made</mat-icon></a
          >
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="24px">
          <div>{{ callNote.company | companyAddress }}</div>
          <div
            *ngIf="contactInfoLoaded"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="24px"
          >
            <div>
              <span class="info-label">Phone: </span>
              <span>{{ callNote.company.phone | phone }}</span>
            </div>
            <div>
              <span class="info-label">Alternate Phone: </span>
              <span>{{ callNote.company.alternatePhone | phone }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- <button
        *ngIf="!contactInfoLoaded"
        mat-button
        (click)="loadContactInfo()"
        color="primary"
      >
        <mat-icon>phone</mat-icon> Load Contact Info
      </button> -->
    </div>
  </mat-card-content>

  <mat-card-content *ngIf="callNote.contacts.length > 0">
    <mat-divider></mat-divider>
    &nbsp;
    <h3 class="call-note-company-name">Contacts</h3>

    <div
      fxLayout="column"
      fxLayoutGap="12px"
      *ngIf="contactInfoLoaded; else contactInfoNotLoadedForContacts"
    >
      <div *ngFor="let contact of callNote.contacts; let last = last">
        <div fxLayout="row" fxLayoutGap="18px" fxLayoutAlign="start center">
          <span *ngIf="!!contact?.email"
            ><a mat-icon-button color="primary" [href]="'mailto:' + contact.email">
              <mat-icon>email</mat-icon>
            </a></span
          >
          <span class="name cursor" (click)="openDialog(contact)">{{
            !!contact?.name ? contact.name : displayName(contact)
          }}</span>
          <span class="info-label bolder">{{ contact?.title }}</span>
          <div *ngIf="!!contact?.phone">
            <span class="info-label">Phone: </span>
            <span>{{ contact.phone | phone }}</span>
          </div>
          <div *ngIf="!!contact?.mobilePhone">
            <span class="info-label">Mobile: </span>
            <span>{{ contact.mobilePhone | phone }}</span>
          </div>
        </div>
      </div>
    </div>
    <ng-template #contactInfoNotLoadedForContacts>
      <div fxLayout="row" fxLayoutAlign="space-between end">
        <div>
          <span *ngFor="let contact of callNote.contacts; let last = last">
            <span class="name">{{
              !!contact?.name ? contact.name : displayName(contact)
            }}</span
            ><span *ngIf="!last">, </span>
          </span>
        </div>
        <!-- <button
          *ngIf="!showCompany && !contactInfoLoaded"
          mat-button
          (click)="loadContactInfo()"
          color="primary"
        >
          <mat-icon>phone</mat-icon> Load Contact Info
        </button> -->
      </div>
    </ng-template>
  </mat-card-content>

  <mat-card-content>
    <div *ngIf="callNote.fields.length">
      <mat-divider></mat-divider>
      <div class="call-note-narratives-group" fxLayout="column" fxLayoutGap="16px">
        <div class="call-note-narrative" *ngFor="let narrative of callNote.fields">
          <span class="call-note-narrative-title">{{ narrative.name }}</span>
          <span [ngSwitch]="narrative.type?.id" class="call-note-narrative-body">
            <span class="call-note-narrative-body" *ngSwitchCase="2">
              <mat-checkbox
                disabled
                [checked]="narrative.value === 'true'"
              ></mat-checkbox>
            </span>
            <span *ngSwitchCase="4" class="call-note-narrative-body">{{
              narrative.value | currency : 'USD'
            }}</span>
            <span *ngSwitchCase="16" class="call-note-narrative-body">{{
              dropdowns[narrative.dropdownId]?.dropdownOption
            }}</span>
            <span *ngSwitchDefault class="call-note-narrative-body" fxFlex
              ><img
                *ngIf="imageUrl"
                class="image-right"
                [src]="imageUrl"
                (click)="openFullImage(imageUrl)"
              />{{ narrative.value }}</span
            >
          </span>
        </div>
      </div>
    </div>
    <mat-card-content *ngIf="!!callNote.followUp.task">
      <mat-divider></mat-divider>

      <div *ngIf="!inFollowUpList; else isInList">
        <tn-call-note-card-follow-up
          (emitChecked)="handleCheckbox($event)"
          [followUp]="callNote.followUp"
          [isSameDay]="isSameDay"
        ></tn-call-note-card-follow-up>
      </div>
      <ng-template #isInList>
        <tn-call-note-card-follow-up
          *ngIf="callNote.followUp.complete === false; else isComplete"
          (emitChecked)="handleCheckbox($event)"
          [followUp]="callNote.followUp"
          [isSameDay]="isSameDay"
        ></tn-call-note-card-follow-up>
        <ng-template #isComplete>
          <div
            fxLayout="row"
            fxLayoutAlign="space-between center"
            [style.marginTop]="'3px'"
          >
            <div
              [style.color]="'#aaa'"
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="18px"
              [style.marginLeft]="'4px'"
            >
              <mat-icon>task_alt</mat-icon>
              <h3>Follow-up marked as complete!</h3>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
              <button mat-stroked-button color="primary" (click)="undoComplete()">
                UNDO
              </button>
              <button mat-icon-button color="warn" (click)="clearFollowUp()">
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </div>
        </ng-template>
      </ng-template>
    </mat-card-content>
    <mat-card-content *ngIf="callNote.hasAudioFile && webRecording">
      <mat-divider></mat-divider>
      <tn-audio-listen [callNote]="callNote"></tn-audio-listen>
    </mat-card-content>
    <mat-card-content class="call-note-comments" *ngIf="callNote.comments?.length > 0">
      <mat-divider></mat-divider>
      <mat-accordion class="comments-panel">
        <mat-expansion-panel #commentsPanel expanded="false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Comments ({{ callNote.comments?.length }})
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div fxLayout="column" fxLayoutGap="8px">
            <tn-project-call-note-comment
              *ngFor="let comment of callNote.comments"
              [callNoteId]="callNote.id"
              [comment]="comment"
            ></tn-project-call-note-comment>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-card-content>
  </mat-card-content>
</mat-card>
