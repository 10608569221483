import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'cancel-popup',
	templateUrl: './cancel-popup.component.html'
})
export class CancelPopupComponent {
	@Input() cancelPopup: boolean;
	@Output() cancelPopupChange = new EventEmitter<boolean>();
	@Output() cancelled = new EventEmitter();

	closeCancelPopup(): void {
		this.cancelPopup = false;
		this.cancelPopupChange.emit(this.cancelPopup);
	}

	cancelConfirmed(): void {
		this.cancelled.emit();
	}
}

