export function removeNullProps(obj: any): any {
  Object.keys(obj).forEach(key => obj[key] == null && delete obj[key]);
}

export function removeNullPropsImmutable<T>(obj: T): T {
  return !!obj
    ? Object.keys(obj).reduce((acc: any, currentKey: any) => {
        if (!!obj[currentKey]) {
          acc[currentKey] = obj[currentKey];
          return acc;
        } else {
          return acc;
        }
      }, {})
    : {};
}
