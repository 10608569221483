/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { DashboardDetailComponent } from '../pages/dashboard/dashboard-detail/dashboard-detail.component';
import { AddMileageComponent } from '../pages/mileage/add-mileage/add-mileage.component';
import { ExpenseFormComponent } from '../pages/expenses/expense-form/expense-form.component';
import { MultiSelectComponent } from './multi-select/multi-select.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TopbarComponent } from './topbar/topbar.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { SearchbarComponent } from './searchbar/searchbar.component';
import { NewMenuComponent } from './new-menu/new-menu.component';
import { UserOptionsComponent } from './user-options/user-options.component';
import { DrawerComponent } from './drawer/drawer.component';
import { DrawerDirective } from './drawer-host.directive';
import { RouterModule } from '@angular/router';
import { ArraySortPipe } from './pipes/array-sort.pipe';
import { MultiSelectCompaniesComponent } from './multi-select-companies/multi-select-companies.component';
import { CallNoteFieldFormComponent } from '../pages/system-settings/call-note-custom-fields/call-note-field-form/call-note-field-form.component';
import { ContactFieldFormComponent } from '../pages/system-settings/contact-custom-fields/contact-field-form/contact-field-form.component';
import { AddCallTypeComponent } from '../pages/system-settings/call-types/add-call-type/add-call-type.component';
import { AddCompanyLabelsComponent } from '../pages/system-settings/company-labels/add-company-labels/add-company-labels.component';
import { AddExpenseTypesComponent } from '../pages/system-settings/expense-types/add-expense-types/add-expense-types.component';
import { AddClientComponent } from '../pages/clients/add-client/add-client.component';
import { AddPlanComponent } from '../pages/plans/add-plan/add-plan.component';
import { UndoDeleteComponent } from '../pages/undo-delete/undo-delete.component';
import { CompanySelectComponent } from './company-select/company-select.component';
import { ItemSelectComponent } from './item-select/item-select.component';
import { CompanyFieldFormComponent } from '../pages/system-settings/company-custom-fields/company-field-form/company-field-form.component';
import { AddProjectFieldComponent } from '../pages/system-settings/project-settings/add-project-field/add-project-field.component';
import { AddProjectBidsComponent } from '../pages/system-settings/project-bids/add-project-bids/add-project-bids.component';
import { AddProjectLabelsComponent } from '../pages/system-settings/project-labels/add-project-labels/add-project-labels.component'; // eslint-disable-next-line max-len
import { FormAddressComponent } from './form-address/form-address.component';
import { CallNoteFormComponent } from './call-note-form/call-note-form.component';
import { ProjectCallNoteCommentComponent } from '../pages/projects/project-call-note-comment/project-call-note-comment.component';
import { CallNoteFilterPipe } from './pipes/call-note-filter.pipe';
import { CompanyBidFilterPipe } from './pipes/company-bid-filter.pipe';
import { ProjectFilesFilterPipe } from './pipes/project-files-filter.pipe';
import { AutosizeTextareaDirective } from './directives/autosize-textarea.directive';
import { DisplayContactsPipe } from './pipes/display-contacts.pipe';
import { CompanyAddressPipe } from './pipes/company-address.pipe';
import { GlobalSearchResultsComponent } from './global-search-results/global-search-results.component';
import { DrawerLeftComponent } from './drawer-left/drawer-left.component';
import { ContactSupportComponent } from './contact-support/contact-support.component';
import { FormatAddressPipe } from './pipes/format-address.pipe';
import { ClickOutsideModule } from 'ng-click-outside';
import { EmailMultiSelectComponent } from './email-multi-select/email-multi-select.component';
import { ExpenseFilesComponent } from '../pages/expenses/expense-files/expense-files.component';
import { ProjectFileComponent } from '../pages/projects/project-file/project-file.component';
import { PendingExpenseFilesComponent } from '../pages/expenses/pending-expense-files/pending-expense-files.component';
import { AddOpportunityLabelComponent } from '../pages/system-settings/opportunity-labels/add-opportunity-label/add-opportunity-label.component';
import { AddItemNameComponent } from '../pages/system-settings/opportunity-item-names/add-item-name/add-item-name.component';
import { ProjectCallNoteComponent } from '../pages/projects/project-call-note/project-call-note.component';
import {
  ProjectFollowUpComponent,
  CompanyFollowUpsDialog
} from '../pages/projects/project-follow-up/project-follow-up.component';
import { ItemFilterPipe } from './pipes/item-filter.pipe';
import { AddCategoryComponent } from '../pages/system-settings/file-categories/add-category/add-category.component';
import { ManufacturerBidFilterPipe } from './pipes/manufacturer-bid-filter.pipe';
import { ProjectsColumnSelectorComponent } from '../pages/projects/projects-page/projects-column-selector/projects-column-selector.component';
import { BidsColumnSelectorComponent } from '../pages/projects/projects-page/bids-column-selector/bids-column-selector.component';
import { CompaniesColumnSelectorComponent } from '../pages/projects/projects-page/companies-column-selector/companies-column-selector.component';
import { ManufsColumnSelectorComponent } from '../pages/projects/projects-page/manufs-column-selector/manufs-column-selector.component';
import { OpportunityFormComponent } from '../pages/companies/components/company-profile/company-opportunities/opportunity-form/opportunity-form.component';
import { AgePipe } from './pipes/age.pipe';
import { ProjectFormComponent } from '../pages/companies/components/company-profile/company-projects/project-form/project-form.component';
import { OppItemCustomFieldFormComponent } from '../pages/system-settings/opp-item-custom-field-form/opp-item-custom-field-form.component';
import { OppItemFormComponent } from '../pages/system-settings/opp-item-form/opp-item-form.component';
import {
  CompanyContactsComponent,
  CompanyContactDialog
} from '../pages/companies/components/company-profile/company-contacts/company-contacts.component';
import { CompanySalesRepsComponent } from '../pages/companies/components/company-profile/company-sales-reps/company-sales-reps.component';
import { CompanyLabelsComponent } from '../pages/companies/components/company-profile/company-labels/company-labels.component';
import {
  CompanyChildCompaniesComponent,
  CompanyChildCompaniesDialog
} from '../pages/companies/components/company-profile/company-child-companies/company-child-companies.component';

import { OppColumnSelectorComponent } from '../pages/opportunities/opps-page/opp-column-selector/opp-column-selector.component';
import { ItemColumnSelectorComponent } from '../pages/opportunities/opps-page/item-column-selector/item-column-selector.component';
// eslint-disable-next-line max-len
import { ProjectCustomFieldFormComponent } from '../pages/system-settings/project-custom-fields/project-custom-field-form/project-custom-field-form.component';
import { BidCustomFieldFormComponent } from '../pages/system-settings/bid-custom-fields/bid-custom-field-form/bid-custom-field-form.component';
import { ProjCompCustomFieldFormComponent } from '../pages/system-settings/project-company-custom-fields/proj-comp-custom-field-form/proj-comp-custom-field-form.component';
import { ProjManufCustomFieldFormComponent } from '../pages/system-settings/project-manuf-custom-fields/proj-manuf-custom-field-form/proj-manuf-custom-field-form.component';
import { CompanyCallNoteDialog } from '../pages/projects/project-call-note/company-call-note-dialogue';
import { ProjectCallNoteCommentDialogComponent } from '../pages/projects/project-call-note/project-call-note-comment-dialog/project-call-note-comment-dialog.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DialogUnlinkConfirmationComponent } from '../pages/companies/components/company-profile/dms/company-excede/company-excede-confirmation-dialog';
import { CompanySlideOutFormComponent } from './company-slide-out-form/company-slide-out-form.component';
import { ContactSlideOutFormComponent } from './contact-slide-out-form/contact-slide-out-form.component';
import { CallNotesSlideOutComponent } from '../pages/dashboard/call-notes-slide-out/call-notes-slide-out.component';
import { ConfirmDeleteComponent } from './confirm-delete/confirm-delete.component';
import { OppCustomFieldFormComponent } from '../pages/system-settings/opp-custom-field-form/opp-custom-field-form.component';
import { CallNoteCardComponent } from './call-note-card/call-note-card.component';
import { PhonePipe } from './pipes/phone.pipe';
import { CallNoteCardFollowUpComponent } from './call-note-card-follow-up/call-note-card-follow-up.component';
import { CompaniesFilterPipe } from './pipes/companies-filter.pipe';
import { ContactedCompaniesFilterPipe } from './pipes/contacted-companies-filter.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { UsersFilterPipe } from './pipes/users-filter.pipe';
import { SelectLandingPageDialogueComponent } from './select-landing-page-dialogue/select-landing-page-dialogue.component';
import { DailyActivityComponent } from './daily-activity/daily-activity.component';
import { CallNoteComponent } from './daily-activity/call-note/call-note.component';
import { RecordCallNoteComponent } from './record-call-note/record-call-note.component';
import { CallNoteFormAudioComponent } from './call-note-form-audio/call-note-form-audio.component';
import { AudioListenComponent } from './audio-listen/audio-listen.component';
import { ContactCardComponent } from './contact-card/contact-card.component';
import { TerritoriesSelectComponent } from './territories-select/territories-select.component';
import { CheckboxMultiSelectComponent } from './checkbox-multi-select/checkbox-multi-select.component';
import { ImageDialogComponent } from './image-dialog/image-dialog.component';
import { StackedBarChartComponent } from '../pages/dashboard/charts/base/stacked-bar-chart/stacked-bar-chart.component';
import { DataNotFoundComponent } from './data-not-found/data-not-found.component';
import { PrivateNoteFormComponent } from './private-note-form/private-note-form.component';
import { PrivateNoteCardComponent } from './private-note-card/private-note-card.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ClickOutsideModule,
    DragDropModule
  ],
  declarations: [
    TopbarComponent,
    SidenavComponent,
    SearchbarComponent,
    NewMenuComponent,
    UserOptionsComponent,
    DrawerComponent,
    DrawerDirective,
    DashboardDetailComponent,
    AddMileageComponent,
    ExpenseFormComponent,
    MultiSelectComponent,
    ArraySortPipe,
    CallNoteFieldFormComponent,
    ContactFieldFormComponent,
    UndoDeleteComponent,
    MultiSelectCompaniesComponent,
    CompanySelectComponent,
    CompanyFieldFormComponent,
    ItemSelectComponent,
    ItemSelectComponent,
    AddProjectFieldComponent,
    AddCompanyLabelsComponent,
    AddCallTypeComponent,
    AddExpenseTypesComponent,
    AddClientComponent,
    AddPlanComponent,
    AddProjectBidsComponent,
    AddProjectLabelsComponent,
    ProjectsColumnSelectorComponent,
    ManufsColumnSelectorComponent,
    CompaniesColumnSelectorComponent,
    BidsColumnSelectorComponent,
    FormAddressComponent,
    CallNoteFormComponent,
    ProjectCallNoteCommentComponent,
    CallNoteFilterPipe,
    CompanyBidFilterPipe,
    ManufacturerBidFilterPipe,
    ProjectFilesFilterPipe,
    DisplayContactsPipe,
    CompanyAddressPipe,
    AutosizeTextareaDirective,
    GlobalSearchResultsComponent,
    DrawerLeftComponent,
    ContactSupportComponent,
    FormatAddressPipe,
    EmailMultiSelectComponent,
    ExpenseFilesComponent,
    PendingExpenseFilesComponent,
    ProjectFileComponent,
    AddOpportunityLabelComponent,
    AddItemNameComponent,
    ProjectCallNoteComponent,
    ProjectFollowUpComponent,
    ItemFilterPipe,
    AddCategoryComponent,
    OpportunityFormComponent,
    AgePipe,
    ProjectFormComponent,
    OppItemCustomFieldFormComponent,
    OppItemFormComponent,
    OppColumnSelectorComponent,
    ItemColumnSelectorComponent,
    CompanyLabelsComponent,
    CompanyContactsComponent,
    CompanySalesRepsComponent,
    CompanyChildCompaniesComponent,
    CompanyLabelsComponent,
    CompanyChildCompaniesComponent,
    OppColumnSelectorComponent,
    ItemColumnSelectorComponent,
    CompanyContactDialog,
    CompanyChildCompaniesDialog,
    CompanyFollowUpsDialog,
    CompanyCallNoteDialog,
    DialogUnlinkConfirmationComponent,
    ProjManufCustomFieldFormComponent,
    ProjCompCustomFieldFormComponent,
    BidCustomFieldFormComponent,
    ProjectCustomFieldFormComponent,
    ProjectCallNoteCommentDialogComponent,
    ContactSlideOutFormComponent,
    CompanySlideOutFormComponent,
    CallNotesSlideOutComponent,
    ConfirmDeleteComponent,
    OppCustomFieldFormComponent,
    CallNoteCardComponent,
    PhonePipe,
    CallNoteCardFollowUpComponent,
    CompaniesFilterPipe,
    ContactedCompaniesFilterPipe,
    SafePipe,
    UsersFilterPipe,
    DailyActivityComponent,
    CallNoteComponent,
    SelectLandingPageDialogueComponent,
    DailyActivityComponent,
    RecordCallNoteComponent,
    CallNoteFormAudioComponent,
    AudioListenComponent,
    ContactCardComponent,
    TerritoriesSelectComponent,
    CheckboxMultiSelectComponent,
    ImageDialogComponent,
    StackedBarChartComponent,
    DataNotFoundComponent,
    PrivateNoteFormComponent,
    PrivateNoteCardComponent
  ],
  exports: [
    TopbarComponent,
    SidenavComponent,
    SearchbarComponent,
    NewMenuComponent,
    UserOptionsComponent,
    DrawerComponent,
    DrawerDirective,
    DashboardDetailComponent,
    AddMileageComponent,
    ExpenseFormComponent,
    MultiSelectComponent,
    ArraySortPipe,
    MultiSelectCompaniesComponent,
    CompanySelectComponent,
    ItemSelectComponent,
    FormAddressComponent,
    ItemSelectComponent,
    ReactiveFormsModule,
    FormsModule,
    ProjectCallNoteCommentComponent,
    CallNoteFilterPipe,
    UsersFilterPipe,
    CompanyBidFilterPipe,
    ManufacturerBidFilterPipe,
    ProjectFilesFilterPipe,
    DisplayContactsPipe,
    CompanyAddressPipe,
    AutosizeTextareaDirective,
    DrawerLeftComponent,
    ExpenseFilesComponent,
    PendingExpenseFilesComponent,
    ProjectFileComponent,
    ProjectCallNoteComponent,
    ProjectFollowUpComponent,
    ItemFilterPipe,
    FormatAddressPipe,
    AgePipe,
    CompanySlideOutFormComponent,
    CallNotesSlideOutComponent,
    CallNoteCardComponent,
    PhonePipe,
    CallNoteCardFollowUpComponent,
    CompaniesFilterPipe,
    ContactedCompaniesFilterPipe,
    RecordCallNoteComponent,
    CallNoteFormAudioComponent,
    TerritoriesSelectComponent,
    CheckboxMultiSelectComponent,
    StackedBarChartComponent,
    PrivateNoteCardComponent
  ],
  providers: [AgePipe, SafePipe, PhonePipe]
})
export class SharedModule {}
