import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { PrivateNote } from 'src/app/models/private-notes/private-note';
import { OpenDrawer } from 'src/app/store/layout/layout.actions';
import { RootState } from 'src/app/store/store.reducer';
import { PrivateNoteFormComponent } from '../private-note-form/private-note-form.component';
import { DeletePrivateNote } from 'src/app/store/private-notes/private-notes.actions';
import { User } from 'src/app/models/admin/users/user';

@Component({
  selector: 'tn-private-note-card',
  templateUrl: './private-note-card.component.html',
  styleUrls: ['./private-note-card.component.scss']
})
export class PrivateNoteCardComponent implements OnInit {
  // Inputs and Outputs
  @Input() privateNote: PrivateNote;
  @Input() currentUser: User;

  // Data
  owner: boolean = false;

  constructor(private store: Store<RootState>) {}

  ngOnInit(): void {
    this.owner = this.privateNote.createdBy.id === this.currentUser.id;
  }

  editHandler() {
    this.store.dispatch(
      new OpenDrawer({
        component: PrivateNoteFormComponent,
        data: { privateNote: this.privateNote }
      })
    );
  }

  deleteHandler(privateNoteId: number) {
    if (this.privateNote.createdBy.id !== this.currentUser.id) return;
    this.store.dispatch(new DeletePrivateNote(privateNoteId));
  }
}
