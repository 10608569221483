import {
  Component,
  Input,
  ViewChild,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  SecurityContext
} from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import { OpenDrawer } from 'src/app/store/layout/layout.actions';
import { CallNoteFormComponent } from 'src/app/view/shared/call-note-form/call-note-form.component';
import {
  SelectCallNoteId,
  SaveCallNoteComment
} from 'src/app/store/call-notes/call-notes.actions';
import { FormControl, FormGroup } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatDialog } from '@angular/material/dialog';
import { CompanyCallNoteDialog } from './company-call-note-dialogue';
import { ProjectCallNoteCommentDialogComponent } from './project-call-note-comment-dialog/project-call-note-comment-dialog.component';
import { Observable, Subscription } from 'rxjs';
import { GenericMap } from 'src/app/models/generic-map';
import { GenericApi } from 'src/app/models/generic-api';
import { DropdownOption } from 'src/app/models/dropdown-option';
import { CallNote } from 'src/app/models/call-notes/call-note';
import { DomSanitizer } from '@angular/platform-browser';
import { selectProjectParamId } from 'src/app/store/projects/selectors/current-project.selector';
import { selectOpportunityId } from 'src/app/store/opportunities/selectors/current-opportunity.selector';

@Component({
  selector: 'tn-project-call-note',
  templateUrl: './project-call-note.component.html',
  styleUrls: ['./project-call-note.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectCallNoteComponent implements OnInit, OnDestroy {
  @Input() showCompany = true;
  @Input() callNote: CallNote;

  $dropdownOptions: Observable<GenericMap<GenericApi<GenericMap<DropdownOption>>>>;
  $dropdownSub: Subscription;
  dropdowns: GenericMap<DropdownOption> = {};
  $projId: Observable<number>;
  $oppId: Observable<number>;

  commentsForm: FormGroup = new FormGroup({
    callNoteID: new FormControl(),
    value: new FormControl()
  });

  showCommentsButton = true;

  @ViewChild('commentsPanel', { static: true }) commentsPanel: MatExpansionPanel;
  constructor(
    private store: Store<RootState>,
    public dialog: MatDialog,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.$projId = this.store.select(selectProjectParamId);
    this.$oppId = this.store.select(selectOpportunityId);
    this.commentsForm.controls.callNoteID.patchValue(this.callNote.id);
    this.callNote.fields = this.callNote.fields.map((field) => {
      if (field.type?.id === 1) {
        return {
          ...field,
          safeValue: this.domSanitizer.bypassSecurityTrustHtml(field.value)
        };
      }
      return field;
    });

    if (this.callNote.comments && this.callNote.comments.length) {
      this.callNote.comments = this.callNote.comments.sort((a, b) =>
        a.createdDate > b.createdDate ? 1 : -1
      );
    }
    this.$dropdownOptions = this.store.select('callNoteCustomFields', 'dropdownOptions');
    this.$dropdownSub = this.$dropdownOptions.subscribe(
      (dropdowns: GenericMap<GenericApi<GenericMap<DropdownOption>>>) => {
        this.dropdowns = {
          ...this.dropdowns,
          ...Object.keys(dropdowns).reduce((resultObj, currId) => {
            resultObj = {
              ...resultObj,
              ...dropdowns[currId].data
            };
            return resultObj;
          }, {})
        };
      }
    );
  }

  ngOnDestroy() {
    this.$dropdownSub.unsubscribe();
  }

  editHandler() {
    this.store.dispatch(new SelectCallNoteId(this.callNote.id));
    this.store.dispatch(
      new OpenDrawer({
        component: CallNoteFormComponent,
        data: { callnoteId: this.callNote.id }
      })
    );
  }

  deleteHandler(callNote) {
    const dialogRef = this.dialog.open(CompanyCallNoteDialog, {
      width: '250px',
      data: callNote
    });
  }

  commentHandler(callNote) {
    const dialogRef = this.dialog.open(ProjectCallNoteCommentDialogComponent, {
      width: '500px',
      data: callNote
    });
  }

  onCommentSubmit() {
    if (!!this.commentsForm.controls.value.value) {
      this.commentsPanel.close();
      this.store.dispatch(new SaveCallNoteComment(this.commentsForm.value));
    }
  }
}
