import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DmsLinkRequest } from 'src/app/models/companies/dms-link-request';
import {
  LoadDmsLinkedCompany,
  UnlinkDmsCompany,
  UpdateCompany
} from 'src/app/store/companies/companies.actions';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import { Company } from 'src/app/models/companies/company';

@Component({
  selector: 'tn-unlink-confirmation-dialog',
  templateUrl: 'company-excede-confirmation-dialog.html'
})
export class DialogUnlinkConfirmationComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogUnlinkConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<RootState>
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  unlinkDms() {
    const unUnlinkRequest: DmsLinkRequest = {
      AgencyId: this.data.agencyId,
      DMSId: this.data.accountId,
      TelenotesId: this.data.id
    };
    const companyUpdate: Company = {
      ...this.data,
      dmsCustomerId: ''
    };
    this.store.dispatch(
      new UnlinkDmsCompany({ dmsLinkRequest: unUnlinkRequest, companyUpdate })
    );
    this.dialogRef.close();
  }
}
