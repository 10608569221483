import { selectedCallNoteId } from './../../call-notes/selectors/current-call-note.selector';
import { RootState } from '../../store.reducer';
import { MemoizedSelector, createSelector } from '@ngrx/store';
import { Opportunity } from '../../../models/Opportunity/opportunity';
import { GenericMap } from 'src/app/models/generic-map';
import { extractParamId } from 'src/app/utils/extractParamId';
import { selectUsers } from '../../users/selectors/multi-select-users.selector';
import { User } from 'src/app/models/admin/users/user';
import { FileMetaData } from 'src/app/models/file-meta-data';
import { selectIdNameCurrentUser } from 'src/app/store/users/selectors/id-name-current-user.selector';
import { IdNameItem } from '../../../models/id-name-item';
import { selectItemCustomFields } from './item-custom-fields.selector';
import { ItemCustomField } from 'src/app/models/Opportunity/item-custom-field';
import { Item } from 'src/app/models/Opportunity/item';
import { ItemCustomFieldValue } from 'src/app/models/Opportunity/item-custom-field-value';
import { selectCallNotes } from '../../call-notes/selectors/company-call-notes-as-array.selector';
import { CallNote } from 'src/app/models/call-notes/call-note';
import { selectAllElasticOpportunities } from './all-opportunities-as-array.selector';

export const selectOpportunities = (state: RootState) => state.opportunities.data;
export const selectOpportunityId = (state: RootState) =>
  !!state.router
    ? !!state.router.state.root.firstChild
      ? extractParamId(state.router.state.root, 'opportunities')
      : undefined
    : undefined;

export const selectOpportunityByCurrentCallNote: MemoizedSelector<
  RootState,
  Opportunity
> = createSelector(
  selectAllElasticOpportunities,
  selectCallNotes,
  selectedCallNoteId,
  // get the call notes from state and find the call note with the same id as the selected call note
  // and return the opportunity from the callnotes opportunityId
  (opportunities: Opportunity[], callNotes: GenericMap<CallNote>, callNoteId: number) => {
    if (callNotes[callNoteId]) {
      return opportunities.find(
        (opportunity: Opportunity) =>
          opportunity.id === callNotes[callNoteId].opportunityId
      );
    } else return {} as unknown as Opportunity;
  }
);

export const selectCurrentOpportunity: MemoizedSelector<RootState, Opportunity> =
  createSelector(
    selectOpportunities,
    selectOpportunityId,
    selectUsers,
    selectIdNameCurrentUser,
    selectItemCustomFields,
    (
      opportunityMap: GenericMap<Opportunity>,
      opportunityId: number,
      users: GenericMap<User>,
      currentUser: IdNameItem,
      icf: GenericMap<ItemCustomField>
    ): Opportunity => {
      let opportunity: Opportunity;
      if (
        !!opportunityId &&
        Object.keys(opportunityMap).length &&
        Object.keys(users).length &&
        !!currentUser &&
        !!opportunityMap[opportunityId]
      ) {
        const selectedOpportunity = opportunityMap[opportunityId];
        const createdByUser: User = users[selectedOpportunity.createdBy.toString()];
        const opportunityOwnerUser: User = users[selectedOpportunity.ownedBy.toString()];
        const id = opportunityId.toString();
        opportunity = {
          ...selectedOpportunity,
          items: selectedOpportunity.items.map((item: Item) => {
            return {
              ...item,
              customFields: item.customFields
                ? item.customFields
                    .filter((icfVal: ItemCustomFieldValue) => {
                      return !!icf[icfVal.opportunityItemFieldId];
                    })
                    .map((icfVal) => ({
                      ...icfVal,
                      listorder: icf[icfVal.opportunityItemFieldId].listorder,
                      showActions: false
                    }))
                : []
            };
          }),
          files: opportunityMap[id].files.map((file: FileMetaData) => {
            const uploadedBy = users[file.uploadedBy as number];
            return {
              ...file,
              uploadedByItem: !!uploadedBy
                ? {
                    name: uploadedBy.firstName + ' ' + uploadedBy.lastName,
                    id: uploadedBy.id
                  }
                : {
                    name: '--',
                    id: null
                  }
            };
          }),
          companyItem: {
            id: selectedOpportunity.companyId,
            name: selectedOpportunity.companyName
          },
          ownedByItem: {
            id: !!opportunityOwnerUser ? opportunityOwnerUser.id : null,
            name: !!opportunityOwnerUser
              ? opportunityOwnerUser.firstName + ' ' + opportunityOwnerUser.lastName
              : 'Inactive user'
          },
          createdByItem: {
            id: !!createdByUser ? createdByUser.id : null,
            name: !!createdByUser
              ? createdByUser.firstName + ' ' + createdByUser.lastName
              : 'Inactive user'
          },
          estimatedCloseDate: selectedOpportunity.estimatedCloseDate || undefined,
          assignedTo: selectedOpportunity.assignedTo,
          assignedToItems:
            !!selectedOpportunity.assignedTo && selectedOpportunity.assignedTo.length
              ? selectedOpportunity.assignedTo
                  .filter((userId: number) => {
                    return !!users[userId.toString()];
                  })
                  .map((userId: number) => {
                    const user: User = users[userId.toString()];
                    return {
                      id: user.id,
                      name: user.firstName + ' ' + user.lastName
                    };
                  })
              : []
        };
      }
      return opportunity as Opportunity;
    }
  );
