import { Injectable } from '@angular/core';

import { ActivityPermissions } from '../../models/admin/permission-enums';
import { FeaturePermissionsService } from './feature-permissions.service';
import { AuthenticationService } from '../authentication.service';

@Injectable()
export class ActivityPermissionsService {

    constructor(private featurePermissionsService: FeaturePermissionsService, private authService: AuthenticationService) { }

    getCallNotesPermission(): Promise<number> {
        return this.featurePermissionsService.getPermissionValue(this.authService.modules.Activity, ActivityPermissions.CallNotes).then(result => {
            return result;
        });
    }

    getExportCallNotesPermission(): Promise<boolean> {
        return this.featurePermissionsService.getPermissionValue(this.authService.modules.Activity, ActivityPermissions.ExportCallNotes);
    }

    getBulkDeleteCallNotesPermission(): Promise<boolean> {
        return this.featurePermissionsService.getPermissionValue(this.authService.modules.Activity, ActivityPermissions.BulkDeleteCallNotes);
    }

    getBulkEditCallNotesPermission(): Promise<boolean> {
        return this.featurePermissionsService.getPermissionValue(this.authService.modules.Activity, ActivityPermissions.BulkEditCallNotes);
    }

    getAssignTasksPermission(): Promise<boolean> {
        return this.featurePermissionsService.getPermissionValue(this.authService.modules.Activity, ActivityPermissions.AssignTasks);
    }

    getMileagePermission(): Promise<boolean> {
        return this.featurePermissionsService.getPermissionValue(this.authService.modules.Activity, ActivityPermissions.Mileage);
    }

    getExpensesPermission(): Promise<boolean> {
        return this.featurePermissionsService.getPermissionValue(this.authService.modules.Activity, ActivityPermissions.Expenses);
    }
}
