import { Action } from '@ngrx/store';
import { Plans } from 'src/app/models/admin/plans/plans';
import { HttpErrorResponse } from '@angular/common/http';

export enum PlansActionTypes {
  SelectPlanId = '[Plans] Select Plan ID',
  ClearPlanId = '[Plans] Clear Plan ID',
  LoadPlans = '[Plans] Load Plans',
  LoadPlansSuccess = '[Plans] Load Plans Success',
  LoadPlansFailure = '[Plans] Load Plans Failure',
  CreatePlans = '[Plans] Create Plans',
  CreatePlansSuccess = '[Plans] Create Plans Success',
  CreatePlansFailure = '[Plans] Create Plans Failure',
  UpdatePlan = '[Plans] Update Plans',
  UpdatePlanSuccess = '[Plans] Update Plans Success',
  UpdatePlanFailure = '[Plans] Update Plans Failure',
  DeletePlans = '[Plans] Delete Plans',
  DeletePlansSuccess = '[Plans] Delete Plans Success',
  DeletePlansFailure = '[Plans] Delete Plans Failure'
}

export class SelectPlanId implements Action {
  readonly type = PlansActionTypes.SelectPlanId;
  constructor(public payload: number) {}
}

export class ClearPlanId implements Action {
  readonly type = PlansActionTypes.ClearPlanId;
}

export class LoadPlans implements Action {
  readonly type = PlansActionTypes.LoadPlans;
}

export class LoadPlansSuccess implements Action {
  readonly type = PlansActionTypes.LoadPlansSuccess;
  constructor(public payload: Plans[]) {}
}

export class LoadPlansFailure implements Action {
  readonly type = PlansActionTypes.LoadPlansFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class CreatePlans implements Action {
  readonly type = PlansActionTypes.CreatePlans;
  constructor(public payload: Plans) {}
}

export class CreatePlansSuccess implements Action {
  readonly type = PlansActionTypes.CreatePlansSuccess;
  constructor(public payload: Plans) {}
}

export class CreatePlansFailure implements Action {
  readonly type = PlansActionTypes.CreatePlansFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class UpdatePlans implements Action {
  readonly type = PlansActionTypes.UpdatePlan;
  constructor(public payload: Plans) {}
}

export class UpdatePlansSuccess implements Action {
  readonly type = PlansActionTypes.UpdatePlanSuccess;
  constructor(public payload: Plans) {}
}

export class UpdatePlansFailure implements Action {
  readonly type = PlansActionTypes.UpdatePlanFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class DeletePlans implements Action {
  readonly type = PlansActionTypes.DeletePlans;
  constructor(public payload: number) {}
}

export class DeletePlansSuccess implements Action {
  readonly type = PlansActionTypes.DeletePlansSuccess;
  constructor(public payload: number) {}
}

export class DeletePlansFailure implements Action {
  readonly type = PlansActionTypes.DeletePlansFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export type PlansActions =
  | SelectPlanId
  | ClearPlanId
  | LoadPlans
  | LoadPlansSuccess
  | LoadPlansFailure
  | CreatePlans
  | CreatePlansSuccess
  | CreatePlansFailure
  | UpdatePlans
  | UpdatePlansSuccess
  | UpdatePlansFailure
  | DeletePlans
  | DeletePlansSuccess
  | DeletePlansFailure;
