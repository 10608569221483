import { Injectable } from '@angular/core';

import { AuthenticationService } from '../authentication.service';
import { ListFilter } from '../../models/filters/listFilter';

@Injectable()
export class CallNoteListService {
  constructor(private authService: AuthenticationService) {}

  createCallNoteList(list: ListFilter): Promise<ListFilter> {
    const updateList = JSON.parse(JSON.stringify(list));
    (<any>updateList).filters = JSON.stringify(updateList.filters);
    delete (<any>updateList).expandListOptions;
    return this.authService
      .makeJpiRequest('/callnotelists', null, updateList, 'post')
      .then(function(result) {
        const newList: ListFilter = JSON.parse(result);

        if (newList.filters) {
          newList.filters = JSON.parse(<any>newList.filters);
        } else {
          newList.filters = [];
        }

        return newList;
      });
  }

  getCallNoteList(id: number): Promise<ListFilter> {
    return this.authService
      .makeJpiRequest('/callnotelists/' + id, null, null, 'get')
      .then(function(result) {
        const list: ListFilter = JSON.parse(result);

        if (list.filters) {
          list.filters = JSON.parse(<any>list.filters);
        } else {
          list.filters = [];
        }

        return list;
      });
  }

  getCallNoteLists(): Promise<ListFilter[]> {
    return this.authService
      .makeJpiRequest('/callnotelists', null, null, 'get')
      .then(function(result) {
        const lists: ListFilter[] = JSON.parse(result);
        lists.forEach(list => {
          if (list.filters) {
            list.filters = JSON.parse(<any>list.filters);
          } else {
            list.filters = [];
          }
        });

        return lists;
      });
  }

  updateCallNoteList(list: ListFilter): Promise<ListFilter> {
    const updateList = JSON.parse(JSON.stringify(list));
    (<any>updateList).filters = JSON.stringify(updateList.filters);
    delete (<any>updateList).expandListOptions;
    return this.authService
      .makeJpiRequest('/callnotelists/' + list.id, null, updateList, 'put')
      .then(function(result) {
        result = JSON.parse(result);

        if (result.filters) {
          result.filters = JSON.parse(<any>result.filters);
        } else {
          result.filters = [];
        }

        return result;
      });
  }

  deleteCallNoteList(list: ListFilter): Promise<ListFilter> {
    const updateList = JSON.parse(JSON.stringify(list));
    (<any>updateList).filters = JSON.stringify(updateList.filters);
    delete (<any>updateList).expandListOptions;
    return this.authService
      .makeJpiRequest('/callnotelists/' + list.id, null, updateList, 'delete')
      .then(function(result) {
        result = JSON.parse(result);

        if (result.filters) {
          result.filters = JSON.parse(<any>result.filters);
        } else {
          result.filters = [];
        }

        return result;
      });
  }
}
