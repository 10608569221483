import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { productLinesReducer } from './product-lines.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ProductLinesEffects } from './product-lines.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('productLines', productLinesReducer),
    EffectsModule.forFeature([ProductLinesEffects])
  ]
})
export class ProductLinesStateModule {}
