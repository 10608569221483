import { FormControl } from '@angular/forms';

export function validateIdNameItem(input: FormControl) {
  return (typeof input.value === 'object' &&
    input.value.hasOwnProperty('id') &&
    input.value.hasOwnProperty('name')) ||
    input.value === '' ||
    input.value === null
    ? null
    : {
        validateIdNameItem: {
          valid: false,
          errorMsg: 'Value must be selected and not typed'
        }
      };
}

export function validateIdNameItemRequired(input: FormControl) {
  return !!input.value &&
    typeof input.value === 'object' &&
    input.value.hasOwnProperty('id') &&
    input.value.hasOwnProperty('name')
    ? null
    : {
        validateIdNameItem: {
          valid: false,
          errorMsg: 'Value must be selected and not typed'
        }
      };
}

export function validateFieldNotEmpty(input: FormControl) {
  return !!input.value && !!input.value.name
  ? null
  : {
    validateFieldNotEmpty: {
      valid: false,
      errorMsg: 'required field'
      }
    };
}
