import { ProjectLabelsActions, ProjectLabelsActionTypes } from './project-labels.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { GenericMap } from 'src/app/models/generic-map';
import { LabelGroup } from 'src/app/models/label-group';

export interface ProjectLabelsState {
  pending: boolean;
  error: HttpErrorResponse;
  data: GenericMap<LabelGroup>;
  selectedProjectLabelId: number;
}

export const initialState: ProjectLabelsState = {
  pending: false,
  error: undefined,
  data: {},
  selectedProjectLabelId: undefined
};

export function projectLabelsReducer(
  state = initialState,
  action: ProjectLabelsActions
): ProjectLabelsState {
  switch (action.type) {
    case ProjectLabelsActionTypes.SelectProjectLabelId:
      return {
        ...state,
        selectedProjectLabelId: action.payload
      };

    case ProjectLabelsActionTypes.ClearProjectLabelId:
      return {
        ...state,
        selectedProjectLabelId: undefined
      };

    case ProjectLabelsActionTypes.LoadProjectLabels:
      return {
        ...state,
        pending: true
      };

    case ProjectLabelsActionTypes.LoadProjectLabelsSuccess:
      return {
        ...state,
        pending: false,
        data: action.payload
      };

    case ProjectLabelsActionTypes.LoadProjectLabelsFailure:
      return {
        ...state,
        pending: false,
        data: {},
        error: action.payload
      };

    case ProjectLabelsActionTypes.AddProjectLabels:
      return {
        ...state,
        pending: true
      };

    case ProjectLabelsActionTypes.AddProjectLabelsSuccess:
      return {
        ...state,
        pending: false
      };

    case ProjectLabelsActionTypes.AddProjectLabelsFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case ProjectLabelsActionTypes.UpdateProjectLabels:
      return {
        ...state,
        pending: true
      };

    case ProjectLabelsActionTypes.UpdateProjectLabelsSuccess:
      return {
        ...state,
        pending: false
      };

    case ProjectLabelsActionTypes.UpdateProjectLabelsFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case ProjectLabelsActionTypes.DeleteProjectLabels:
      return {
        ...state,
        pending: true
      };

    case ProjectLabelsActionTypes.DeleteProjectLabelsSuccess:
      return {
        ...state,
        pending: false,
        selectedProjectLabelId: undefined
      };

    case ProjectLabelsActionTypes.DeleteProjectLabelsFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    default:
      return state;
  }
}
