import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  OnInit,
  OnDestroy
} from '@angular/core';
import { Address } from 'src/app/models/address';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable, EMPTY, Subscription } from 'rxjs';
import { formatAddressIntoOneLine } from 'src/app/utils/formatAddressIntoOneLine';

@Component({
  selector: 'tn-form-address',
  templateUrl: './form-address.component.html',
  styleUrls: ['./form-address.component.scss']
})
export class FormAddressComponent implements OnInit, OnChanges, OnDestroy {
  @Input() address: Address;
  @Input() iSQuote: boolean;
  @Input() prefix = 'Address';
  @Input() showCountry: boolean;
  @Input() editing = false;
  @Input() required = true;
  @Input() addressChangeFromOutside: Observable<Address> = EMPTY;
  @Output() addressChange = new EventEmitter<Address>();
  @Output() isValid = new EventEmitter<boolean>();
  addressChangeFromOutsideSub: Subscription;
  displayAddressText: string;
  addressForm = new FormGroup({
    addressLine1: new FormControl(''),
    addressLine2: new FormControl(),
    city: new FormControl('', this.required ? Validators.required : null),
    county: new FormControl(''),
    state: new FormControl('', this.required ? Validators.required : null),
    postCode: new FormControl(''),
    country: new FormControl('')
  });

  ngOnInit() {
    this.addressChangeFromOutsideSub = this.addressChangeFromOutside.subscribe(
      (address: Address) => {
        this.address = { ...address };
        this.addressForm.setValue({ ...address });
        this.renderText();
      }
    );
    if (!this.required) {
      this.addressForm.controls.city.setValidators(null);
      this.addressForm.controls.state.setValidators(null);
      this.addressForm.updateValueAndValidity();
    }
  }

  ngOnChanges() {
    this.renderText();
    if (!!this.address) {
      this.addressForm.setValue({
        ...this.address
      });
    }
  }

  ngOnDestroy(): void {
    this.addressChangeFromOutsideSub.unsubscribe();
  }

  checkAndEmitValidity() {
    if (this.required) {
      this.isValid.emit(this.addressForm.valid);
    }
  }

  editHandler() {
    this.editing = true;
  }

  saveHandler(event: Event) {
    event.preventDefault();
    this.editing = false;
    const sanitizedAddress = this.sanitizeAddressObject(this.addressForm.value);
    this.addressChange.emit(sanitizedAddress);
    this.addressForm.setValue(sanitizedAddress);
    this.address = this.addressForm.value as Address;
    this.renderText();
    this.checkAndEmitValidity();
  }

  cancelHandler() {
    // this.addressForm.setValue(this.address);
    this.editing = false;
    this.checkAndEmitValidity();
  }

  private renderText() {
    if (
      !!this.address &&
      !!Object.values(this.address).filter((addVal) => !!addVal).length
    ) {
      this.displayAddressText = formatAddressIntoOneLine(this.address);
    } else {
      this.displayAddressText = 'No address specified';
      this.addressChange.emit(null);
    }
  }

  private sanitizeAddressObject(address: Partial<Address>): Address {
    if (!!address) {
      return {
        addressLine1:
          !!address.addressLine1 && address.addressLine1.trim() === ''
            ? null
            : address.addressLine1,
        addressLine2:
          !!address.addressLine2 && address.addressLine2.trim() === ''
            ? null
            : address.addressLine2,
        city: !!address.city && address.city.trim() === '' ? null : address.city,
        county: !!address.county && address.county.trim() === '' ? null : address.county,
        state: !!address.state && address.state.trim() === '' ? null : address.state,
        postCode:
          !!address.postCode && address.postCode.trim() === '' ? null : address.postCode,
        country:
          !!address.country && address.country.trim() === '' ? null : address.country
      };
    }
  }
}
