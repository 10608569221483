import { HttpErrorResponse } from '@angular/common/http';
import { AuthActions, AuthActionTypes } from './auth.actions';
import { AuthenticationResponse } from 'src/app/models/auth/authentication-response';
import { User } from 'src/app/models/admin/users/user';
import { expiredAuth } from 'src/app/utils/expiredAuth';
import produce from 'immer';

export interface AuthData {
  api?: AuthenticationResponse;
  jpi?: {
    token?: string;
    user?: User;
    provider?: 'okta' | 'telenotes';
  };
}

export interface AuthState {
  pending: boolean;
  error: HttpErrorResponse;
  inflightUrl: string;
  oktaState: string;
  data: AuthData;
}

export const initialState: AuthState = {
  pending: false,
  error: undefined,
  inflightUrl: '/',
  oktaState: null,
  data: {
    api: undefined,
    jpi: undefined
  }
};

export function authReducer(state = initialState, action: AuthActions): AuthState {
  switch (action.type) {
    case AuthActionTypes.LoadAuth: {
      return produce(state, (draftState) => {
        draftState.pending = true;
      });
    }

    case AuthActionTypes.LoadApiSuccess:
      return produce(state, (draftState) => {
        draftState.data.api = action.payload;
      });

    case AuthActionTypes.LoadJpiSuccess:
      window.localStorage.setItem('jpiReturn', JSON.stringify(action.payload));
      return produce(state, (draftState) => {
        draftState.data.jpi = action.payload;
      });

    case AuthActionTypes.LoadAuthComplete:
      return produce(state, (draftState) => {
        draftState.error = undefined;
        draftState.pending = false;
      });

    case AuthActionTypes.LoadAuthFailure:
      return produce(state, (draftState) => {
        draftState.error = action.payload;
        draftState.pending = false;
      });

    case AuthActionTypes.Logout:
      return produce(state, (draftState) => {
        draftState.data.api = undefined;
        draftState.data.jpi = undefined;
      });

    case AuthActionTypes.Reauthenticate: {
      return produce(state, (draftState) => {
        draftState.data.api = undefined;
        draftState.data.jpi = undefined;
      });
    }

    case AuthActionTypes.SetInflightUrl: {
      return produce(state, (draftState) => {
        draftState.inflightUrl = action.payload;
      });
    }

    case AuthActionTypes.DeleteAuthObjects: {
      return produce(state, (draftState) => {
        draftState.data.api = undefined;
        draftState.data.jpi = undefined;
      });
    }

    case AuthActionTypes.SetOktaState: {
      return produce(state, (draftState) => {
        draftState.oktaState = action.payload;
      });
    }

    case AuthActionTypes.OktaLogin: {
      return produce(state, (draftState) => {
        draftState.pending = true;
      });
    }

    case AuthActionTypes.ExpireAuth: {
      return produce(state, (draftState) => {
        draftState.data = JSON.parse(expiredAuth);
      });
    }

    case AuthActionTypes.SignInAsOther: {
      return produce(state, (draftState) => {
        draftState.pending = true;
      });
    }

    case AuthActionTypes.SignInAsOtherSuccess: {
      return produce(state, (draftState) => {
        draftState.pending = false;
        draftState.data = action.payload;
      });
    }

    default:
      return state;
  }
}
