import { RootState } from '../../store.reducer';
import { MemoizedSelector, createSelector, Selector } from '@ngrx/store';
import { ColumnHeader, ColumnType } from 'src/app/models/column-header';
import { selectAllProjectLabels } from '../../system-settings/project-labels/selectors/all-projectLabels-as-array.selector';
import { LabelGroup } from 'src/app/models/label-group';
import { selectAllProjectCustomFields } from '../../system-settings/project-custom-fields/selectors/current-proj-custom-field.selector';
import { GenericMap } from 'src/app/models/generic-map';
import { ProjectCustomField } from 'src/app/models/projects/project-custom-field';
import { selectAllProjectCompanyCustomFields } from '../../system-settings/proj-comp-custom-fields/selectors/current-proj-comp-custom-field.selector';
import { selectAllProjectManufCustomFields } from '../../system-settings/proj-manuf-custom-fields/selectors/current-proj-manuf-custom-field.selector';
import { selectAllBidCustomFields } from '../../system-settings/bid-custom-fields/selectors/current-bid-custom-field.selector';

// -- Project Columns
export const selectAllColsByProj = (state: RootState) => state.projects.allColsByProj;
export const selectCacheColByProj = (state: RootState) => state.projects.selColsByProj;

export const selectAllProjColsWithLabels: MemoizedSelector<RootState, ColumnHeader[]> =
  addProjectLabelColumns(addProjectCustomFields(selectAllColsByProj));

export const selectExpProjColNames: MemoizedSelector<RootState, string[]> =
  createExpiredColumnsSelector(selectAllProjColsWithLabels, selectCacheColByProj);

export const selectSelProjColumns: MemoizedSelector<RootState, ColumnHeader[]> =
  createCachedColumnsSelector(selectAllProjColsWithLabels, selectCacheColByProj);

// -- Manufacturer Columns
export const selectAllColsByManuf = (state: RootState) => state.projects.allColsByManuf;
export const selectCacheColByManuf = (state: RootState) => state.projects.selColsByManuf;

export const selectAllManufColsWithLabels: MemoizedSelector<RootState, ColumnHeader[]> =
  addProjectLabelColumns(
    addProjectCustomFields(addProjectManufCustomFields(selectAllColsByManuf))
  );

export const selectExpManufColNames: MemoizedSelector<RootState, string[]> =
  createExpiredColumnsSelector(selectAllManufColsWithLabels, selectCacheColByManuf);

export const selectSelManufColumns: MemoizedSelector<RootState, ColumnHeader[]> =
  createCachedColumnsSelector(selectAllManufColsWithLabels, selectCacheColByManuf);

// -- Company Columns
export const selectAllColsByComp = (state: RootState) => state.projects.allColsByComp;
export const selectCacheColByComp = (state: RootState) => state.projects.selColsByComp;

export const selectAllCompColsWithLabels: MemoizedSelector<RootState, ColumnHeader[]> =
  addProjectLabelColumns(
    addProjectCustomFields(addProjectCompanyCustomFields(selectAllColsByComp))
  );

export const selectExpCompColNames: MemoizedSelector<RootState, string[]> =
  createExpiredColumnsSelector(selectAllCompColsWithLabels, selectCacheColByComp);

export const selectSelCompColumns: MemoizedSelector<RootState, ColumnHeader[]> =
  createCachedColumnsSelector(selectAllCompColsWithLabels, selectCacheColByComp);

// -- Bid Columns
export const selectAllColsByBid = (state: RootState) => state.projects.allColsByBid;
export const selectCacheColByBid = (state: RootState) => state.projects.selColsByBid;

export const selectAllBidColsWithLabels: MemoizedSelector<RootState, ColumnHeader[]> =
  addProjectLabelColumns(addProjectCustomFields(addBidCustomFields(selectAllColsByBid)));

export const selectExpBidColNames: MemoizedSelector<RootState, string[]> =
  createExpiredColumnsSelector(selectAllBidColsWithLabels, selectCacheColByBid);

export const selectSelBidColumns: MemoizedSelector<RootState, ColumnHeader[]> =
  createCachedColumnsSelector(selectAllBidColsWithLabels, selectCacheColByBid);

function addProjectLabelColumns(
  columnsSelector: Selector<RootState, ColumnHeader[]>
): MemoizedSelector<RootState, ColumnHeader[]> {
  return createSelector<RootState, unknown[], ColumnHeader[]>(
    columnsSelector,
    selectAllProjectLabels,
    (allDefColumns: ColumnHeader[], labels: LabelGroup[]): ColumnHeader[] => {
      return [
        ...allDefColumns,
        ...Object.values(labels)
          .sort((a, b) => {
            const textA = a.name.toLowerCase();
            const textB = b.name.toLowerCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          })
          .map((label) => ({
            name: label.name,
            displayName: label.name,
            type: ColumnType.LABEL
          }))
      ];
    }
  );
}

function addProjectCustomFields(
  columnsSelector: Selector<RootState, ColumnHeader[]>
): MemoizedSelector<RootState, ColumnHeader[]> {
  return createSelector(
    columnsSelector,
    selectAllProjectCustomFields,
    (
      allDefColumns: ColumnHeader[],
      pCFs: GenericMap<ProjectCustomField>
    ): ColumnHeader[] => {
      return [
        ...allDefColumns,
        ...Object.values(pCFs)
          .sort((a, b) => {
            const textA = a.listOrder;
            const textB = b.listOrder;
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          })
          .map((pcf: ProjectCustomField) => {
            let columnType: ColumnType;
            let isCurrency = false;
            let isDate = false;
            switch (pcf.type) {
              case 1:
                columnType = ColumnType.STRING;
                break;
              case 2:
                columnType = ColumnType.BOOLEAN;
                break;
              case 4:
                columnType = ColumnType.NUMERIC;
                isCurrency = true;
                break;
              case 6:
                columnType = ColumnType.NUMERIC;
                isDate = true;
                break;
              case 14:
                columnType = ColumnType.NUMERIC;
                break;
            }
            return {
              name: pcf.name,
              displayName: pcf.name,
              type: columnType,
              isCurrency,
              isDate
            };
          })
      ]
        .map((column) => {
          return {
            ...column,
            alignRight: column.type !== ColumnType.STRING
          };
        })
        .sort((a, b) => {
          const textA = a.alignRight;
          const textB = b.alignRight;
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
    }
  );
}

function addProjectCompanyCustomFields(
  columnsSelector: Selector<RootState, ColumnHeader[]>
): MemoizedSelector<RootState, ColumnHeader[]> {
  return createSelector(
    columnsSelector,
    selectAllProjectCompanyCustomFields,
    (
      allDefColumns: ColumnHeader[],
      pCFs: GenericMap<ProjectCustomField>
    ): ColumnHeader[] => {
      return [
        ...allDefColumns,
        ...Object.values(pCFs)
          .sort((a, b) => {
            const textA = a.listOrder;
            const textB = b.listOrder;
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          })
          .map((pcf: ProjectCustomField) => {
            let columnType: ColumnType;
            let isCurrency = false;
            switch (pcf.type) {
              case 1:
                columnType = ColumnType.STRING;
                break;
              case 2:
                columnType = ColumnType.BOOLEAN;
                break;
              case 4:
                columnType = ColumnType.NUMERIC;
                isCurrency = true;
                break;
              case 14:
                columnType = ColumnType.NUMERIC;
                break;
            }
            return {
              name: pcf.name,
              displayName: pcf.name,
              type: columnType,
              isCurrency
            };
          })
      ]
        .map((column) => {
          return {
            ...column,
            alignRight: column.type !== ColumnType.STRING
          };
        })
        .sort((a, b) => {
          const textA = a.alignRight;
          const textB = b.alignRight;
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
    }
  );
}

function addProjectManufCustomFields(
  columnsSelector: Selector<RootState, ColumnHeader[]>
): MemoizedSelector<RootState, ColumnHeader[]> {
  return createSelector(
    columnsSelector,
    selectAllProjectManufCustomFields,
    (
      allDefColumns: ColumnHeader[],
      pCFs: GenericMap<ProjectCustomField>
    ): ColumnHeader[] => {
      return [
        ...allDefColumns,
        ...Object.values(pCFs)
          .sort((a, b) => {
            const textA = a.listOrder;
            const textB = b.listOrder;
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          })
          .map((pcf: ProjectCustomField) => {
            let columnType: ColumnType;
            let isCurrency = false;
            switch (pcf.type) {
              case 1:
                columnType = ColumnType.STRING;
                break;
              case 2:
                columnType = ColumnType.BOOLEAN;
                break;
              case 4:
                columnType = ColumnType.NUMERIC;
                isCurrency = true;
                break;
              case 14:
                columnType = ColumnType.NUMERIC;
                break;
            }
            return {
              name: pcf.name,
              displayName: pcf.name,
              type: columnType,
              isCurrency
            };
          })
      ]
        .map((column) => {
          return {
            ...column,
            alignRight: column.type !== ColumnType.STRING
          };
        })
        .sort((a, b) => {
          const textA = a.alignRight;
          const textB = b.alignRight;
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
    }
  );
}

function addBidCustomFields(
  columnsSelector: Selector<RootState, ColumnHeader[]>
): MemoizedSelector<RootState, ColumnHeader[]> {
  return createSelector(
    columnsSelector,
    selectAllBidCustomFields,
    (
      allDefColumns: ColumnHeader[],
      pCFs: GenericMap<ProjectCustomField>
    ): ColumnHeader[] => {
      return [
        ...allDefColumns,
        ...Object.values(pCFs)
          .sort((a, b) => {
            const textA = a.listOrder;
            const textB = b.listOrder;
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          })
          .map((pcf: ProjectCustomField) => {
            let columnType: ColumnType;
            let isCurrency = false;
            switch (pcf.type) {
              case 1:
                columnType = ColumnType.STRING;
                break;
              case 2:
                columnType = ColumnType.BOOLEAN;
                break;
              case 4:
                columnType = ColumnType.NUMERIC;
                isCurrency = true;
                break;
              case 14:
                columnType = ColumnType.NUMERIC;
                break;
            }
            return {
              name: pcf.name,
              displayName: pcf.name,
              type: columnType,
              isCurrency
            };
          })
      ]
        .map((column) => {
          return {
            ...column,
            alignRight: column.type !== ColumnType.STRING
          };
        })
        .sort((a, b) => {
          const textA = a.alignRight;
          const textB = b.alignRight;
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
    }
  );
}

// These are columns that aren't in use anymore,
// not just removed from the selectedColumns list,
// but columns that are still in the selectedColumns list but aren't available
// anymore as a valid column, so if they remain inside the cached selectedColumn
// Object, they will cause an error with the table.
export function createExpiredColumnsSelector(
  allColumnsSelector: Selector<RootState, ColumnHeader[]>,
  selColumnsSelector: Selector<RootState, ColumnHeader[]>
): MemoizedSelector<RootState, string[]> {
  return createSelector(
    allColumnsSelector,
    selColumnsSelector,
    (allColumns: ColumnHeader[], cachedColumns: ColumnHeader[]): string[] => {
      const allColumnNames: string[] = allColumns.map((column) => column.name);
      return cachedColumns
        .filter((column) => !allColumnNames.includes(column.name))
        .map((column) => column.name);
    }
  );
}

export function createCachedColumnsSelector(
  allColumnsSelector: Selector<RootState, ColumnHeader[]>,
  selColumnsSelector: Selector<RootState, ColumnHeader[]>
): MemoizedSelector<RootState, ColumnHeader[]> {
  return createSelector(
    allColumnsSelector,
    selColumnsSelector,
    (allColumns: ColumnHeader[], cachedColumns: ColumnHeader[]): ColumnHeader[] => {
      const allColumnNames: string[] = allColumns.map((column) => column.name);
      const selCols = cachedColumns.filter((column) =>
        allColumnNames.includes(column.name)
      );
      return selCols.filter(
        (selCol, index) => selCols.map((col) => col.name).indexOf(selCol.name) === index
      );
    }
  );
}
