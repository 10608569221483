import { RootState } from '../../store.reducer';
import { MemoizedSelector, createSelector } from '@ngrx/store';
import { User } from 'src/app/models/admin/users/user';
import { IdNameItem } from 'src/app/models/id-name-item';

export const selectCurrentUser = (state: RootState) =>
  !!state.auth.data.jpi ? state.auth.data.jpi.user : null;

export const selectIdNameCurrentUser: MemoizedSelector<RootState, IdNameItem> =
  createSelector(selectCurrentUser, (user: User): IdNameItem => {
    return !!user
      ? ({
          name: user.firstName + ' ' + user.lastName,
          firstName: user.firstName,
          lastName: user.lastName,
          mailbox: user.mailbox,
          id: user.id,
          client: user.client
        } as IdNameItem)
      : null;
  });
