import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DxSelectBoxComponent } from 'devextreme-angular';

import { CustomFieldV2 } from '../../models/admin/custom-fields/custom-field-V2';
import { CompanyV2 } from '../../models/companies/company-V2';
import { GlobalFilter } from '../../models/global-filter';
import { CompanyJPIService } from '../../services/companies/company-JPI.service';
import { FilteredList } from '../../models/filteredList';

@Component({
  selector: 'company-popup-company-details',
  templateUrl: './company-popup-company-details.component.html',
  styleUrls: ['./company-popup.component.scss']
})
export class CompanyPopupCompanyDetailsComponent implements OnInit {
  @ViewChild('parentSelectBox') parentSelectBox: DxSelectBoxComponent;
  @Input() companyDetailsExpanded: boolean;
  @Input() company: CompanyV2;
  @Input() companies: CompanyV2[];
  @Input() companyDetails: CustomFieldV2[];
  @Input() companyDetailsCheck: CustomFieldV2[];
  @Input() companyCustomFields: CustomFieldV2[];
  typingTimer: any;
  inputDelay = 300;

  constructor(private _companyJPIService: CompanyJPIService) {}

  ngOnInit(): void {
    if (this.company.parentCompanyId) {
      const filter: GlobalFilter = { filters: [] };
      filter.filters.push({
        operand1: 'id',
        operator: 'IS_EQUAL_TO',
        operand2: this.company.parentCompanyId.toString()
      });
      this._companyJPIService
        .getJPICompaniesFiltered(0, 25, 'name.keyword', true, filter)
        .then((result: FilteredList) => (this.companies = <CompanyV2[]>result.items));
    } else {
      this.companies = this.selectBoxCompanySearch();
    }
  }

  selectBoxCompanySearch(searchTerm?: string): any {
    const filter: GlobalFilter = { filters: [] };
    if (searchTerm) {
      filter.filters.push({
        operand1: 'name',
        operator: 'CONTAINS_ANY_OF',
        operand2: [searchTerm]
      });
      this._companyJPIService
        .getJPICompaniesFiltered(0, 25, 'name.keyword', true, filter)
        .then((result: FilteredList) => (this.companies = <CompanyV2[]>result.items));
    } else {
      this._companyJPIService
        .getJPICompaniesFiltered(0, 25, 'name.keyword', true, filter)
        .then((result: FilteredList) => {
          this.companies = <CompanyV2[]>result.items;
        });
    }
  }

  expandCompanyDetails(): void {
    this.companyDetailsExpanded = !this.companyDetailsExpanded;
  }

  parentCompanySearch(): void {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      this.selectBoxCompanySearch(this.parentSelectBox.instance.option().text);
    }, this.inputDelay);
  }
}
