import { Action } from '@ngrx/store';
import { Client } from 'src/app/models/admin/client/client';
import { HttpErrorResponse } from '@angular/common/http';

export enum ClientsActionTypes {
  SyncToElastic = '[Clients] Sync To Elastic',
  SyncToElasticSuccess = '[Clients] Sync To Elastic Success',
  SyncToElasticFailure = '[Clients] Sync To Elastic Failure',
  SetCurrentClient = '[Clients] Set Current Client',
  ClearCurrentClient = '[Clients] Clear Current Client',
  LoadCurrentClient = '[Clients] Load Current Client',
  LoadCurrentClientSuccess = '[Clients] Load Current Client Success',
  LoadCurrentClientFailure = '[Clients] Load Current Client Failure',
  LoadClients = '[Clients] Load Clients',
  LoadClientsSuccess = '[Clients] Load Clients Success',
  LoadClientsFailure = '[Clients] Load Clients Failure',
  CreateClients = '[Clients] Create Clients',
  CreateClientsSuccess = '[Clients] Create Clients Success',
  CreateClientsFailure = '[Clients] Create Clients Failure',
  UpdateClients = '[Clients] Update Clients',
  UpdateClientsSuccess = '[Clients] Update Clients Success',
  UpdateClientsFailure = '[Clients] Update Clients Failure',
  DeleteClients = '[Clients] Delete Clients',
  DeleteClientsSuccess = '[Clients] Delete Clients Success',
  DeleteClientsFailure = '[Clients] Delete Clients Failure'
}

export class SetCurrentClient implements Action {
  readonly type = ClientsActionTypes.SetCurrentClient;
  constructor(public payload: Client) {}
}

export class ClearCurrentClient implements Action {
  readonly type = ClientsActionTypes.ClearCurrentClient;
}

export class SyncToElastic implements Action {
  readonly type = ClientsActionTypes.SyncToElastic;
  constructor(public payload: number) {}
}

export class SyncToElasticSuccess implements Action {
  readonly type = ClientsActionTypes.SyncToElasticSuccess;
  constructor(public payload: number) {}
}

export class SyncToElasticFailure implements Action {
  readonly type = ClientsActionTypes.SyncToElastic;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadClients implements Action {
  readonly type = ClientsActionTypes.LoadClients;
}

export class LoadClientsSuccess implements Action {
  readonly type = ClientsActionTypes.LoadClientsSuccess;
  constructor(public payload: Client[]) {}
}

export class LoadClientsFailure implements Action {
  readonly type = ClientsActionTypes.LoadClientsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadCurrentClient implements Action {
  readonly type = ClientsActionTypes.LoadCurrentClient;
  constructor(public payload: number) {}
}

export class LoadCurrentClientSuccess implements Action {
  readonly type = ClientsActionTypes.LoadCurrentClientSuccess;
  constructor(public payload: Client) {}
}

export class LoadCurrentClientFailure implements Action {
  readonly type = ClientsActionTypes.LoadCurrentClientFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class CreateClients implements Action {
  readonly type = ClientsActionTypes.CreateClients;
  constructor(public payload: Client) {}
}

export class CreateClientsSuccess implements Action {
  readonly type = ClientsActionTypes.CreateClientsSuccess;
  constructor(public payload: Client) {}
}

export class CreateClientsFailure implements Action {
  readonly type = ClientsActionTypes.CreateClientsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class UpdateClients implements Action {
  readonly type = ClientsActionTypes.UpdateClients;
  constructor(public payload: Client) {}
}

export class UpdateClientsSuccess implements Action {
  readonly type = ClientsActionTypes.UpdateClientsSuccess;
  constructor(public payload: Client) {}
}

export class UpdateClientsFailure implements Action {
  readonly type = ClientsActionTypes.UpdateClientsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class DeleteClients implements Action {
  readonly type = ClientsActionTypes.DeleteClients;
  constructor(public payload: number) {}
}

export class DeleteClientsSuccess implements Action {
  readonly type = ClientsActionTypes.DeleteClientsSuccess;
  constructor(public payload: number) {}
}

export class DeleteClientsFailure implements Action {
  readonly type = ClientsActionTypes.DeleteClientsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export type ClientsActions =
  | SyncToElastic
  | SyncToElasticSuccess
  | SyncToElasticFailure
  | SetCurrentClient
  | ClearCurrentClient
  | LoadClients
  | LoadClientsSuccess
  | LoadClientsFailure
  | LoadCurrentClient
  | LoadCurrentClientSuccess
  | LoadCurrentClientFailure
  | CreateClients
  | CreateClientsSuccess
  | CreateClientsFailure
  | UpdateClients
  | UpdateClientsSuccess
  | UpdateClientsFailure
  | DeleteClients
  | DeleteClientsSuccess
  | DeleteClientsFailure;
