<div class="container" [ngClass]="{ 'column-layout': isColumnLayout }">
  <div class="header" align="end"><mat-icon mat-dialog-close>close</mat-icon></div>
  <div class="content">
    <div class="image-container">
      <img
        #imageElement
        class="scaledImage"
        [src]="data.imageUrl"
        (load)="onImageLoad(imageElement)"
      />
    </div>
    <div class="narrative-container">
      {{ data.narrative }}
    </div>
  </div>
</div>
