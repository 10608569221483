<mat-sidenav-container autosize fxLayout="column">
  <mat-sidenav mode="side" opened="$sidenavOpened | async" class="mat-elevation-z5">
    <tn-sidenav></tn-sidenav>
  </mat-sidenav>
  <div class="mat-elevation-z5" style="z-index: 5; position: relative">
    <tn-topbar></tn-topbar>
    <mat-progress-bar
      *ngIf="$moduleLoading | async"
      class="module-load-indicator"
      mode="indeterminate"
    ></mat-progress-bar>
  </div>
  <mat-drawer-container class="drawer-container" [hasBackdrop]="false">
    <mat-drawer
      class="drawer-left"
      mode="over"
      [autoFocus]="false"
      [disableClose]="true"
      [opened]="$leftDrawerOpened | async"
    >
      <tn-drawer-left></tn-drawer-left>
    </mat-drawer>

    <mat-drawer-content>
      <mat-drawer-container class="drawer-container">
        <mat-drawer
          class="drawer"
          mode="over"
          [autoFocus]="true"
          position="end"
          (closedStart)="dispatchDrawerClosed()"
          [opened]="$drawerOpened | async"
        >
          <tn-drawer></tn-drawer>
        </mat-drawer>
        <mat-drawer-content class="app-content">
          <router-outlet></router-outlet>
        </mat-drawer-content>
      </mat-drawer-container>
    </mat-drawer-content>
  </mat-drawer-container>
</mat-sidenav-container>

<div class="hover-menu-button">
  <button
    *ngIf="!drawerBoolean"
    matTooltip="Daily Activity"
    (click)="openDailyActivityDrawer()"
    mat-mini-fab
    color="primary"
  >
    <mat-icon>chevron_left</mat-icon>
  </button>
</div>
