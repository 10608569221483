import { MemoizedSelector, createSelector } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import { LabelGroup } from 'src/app/models/label-group';

export const selectAllProjectLabels = (state: RootState) => state.projectLabels.data;

export const selectProjectLabelsAsArray: MemoizedSelector<
  RootState,
  LabelGroup[]
> = createSelector(
  selectAllProjectLabels,
  (projectLabelsMap: { [id: number]: LabelGroup }): LabelGroup[] => {
    const sortedGroups = Object.values(projectLabelsMap)
      .sort((a, b) => {
        const textA = a.name.toLowerCase();
        const textB = b.name.toLowerCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      })
      .map((labelGroup: LabelGroup) => {
        return {
          ...labelGroup,
          labels: labelGroup.labels.sort((a, b) => {
            const textA = a.name.toLowerCase();
            const textB = b.name.toLowerCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          })
        };
      });
    return sortedGroups;
  }
);
