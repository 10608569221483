import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'filtering-indicator',
  templateUrl: './filtering-indicator.component.html',
  styleUrls: ['../styles/buttons.shared.scss',
    '../styles/filtering-indicator.scss']
})
export class FilteringIndicatorComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }

  clearFilters(): void {
  }
}
