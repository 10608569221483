<div class="white-padding" fxLayout="row">
  <div fxFlex="4"></div>
  <div
    class="client-input edit-company-dropdown input-padding popup-expanded-row"
    fxLayout="row"
    (click)="expandCompanyDetails()"
    fxFlex
  >
    <div fxFlex>Company Details</div>
    <mat-icon *ngIf="!companyDetailsExpanded" class="icon-large"
      >arrow_drop_down</mat-icon
    >
    <mat-icon *ngIf="companyDetailsExpanded" class="icon-large">arrow_drop_up</mat-icon>
  </div>
  <div fxFlex="4"></div>
</div>
<div *ngIf="companyDetailsExpanded" fxLayout="column">
  <div class="client-input input-padding" fxLayout="row">
    <div fxFlex="4"></div>
    <div fxFlex class="client-input" fxLayout="column">
      <span>Phone</span>
      <dx-text-box
        [(value)]="company.phone"
        mask="(000) 000-0000"
        [maskRules]="rules"
      ></dx-text-box>
    </div>
    <div fxFlex="8"></div>
    <div fxFlex class="client-input" fxLayout="column">
      <span>Ext</span>
      <dx-text-box [(value)]="company.ext"></dx-text-box>
    </div>
    <div fxFlex="4"></div>
  </div>
  <div class="client-input input-padding" fxLayout="row">
    <div fxFlex="4"></div>
    <div fxFlex="42" class="client-input" fxLayout="column">
      <span>Website</span>
      <dx-text-box [(value)]="company.website">
        <!-- <dx-validator>
                    <dxi-validation-rule type="pattern" [pattern]="websiteRules" message="Must be a valid website"></dxi-validation-rule>
                </dx-validator> -->
      </dx-text-box>
    </div>
    <div fxFlex="8"></div>
    <div fxFlex class="client-input" fxLayout="column">
      <span>Fax</span>
      <dx-text-box
        [(value)]="company.fax"
        mask="(000) 000-0000"
        [maskRules]="rules"
      ></dx-text-box>
    </div>
    <div fxFlex="4"></div>
  </div>

  <div class="client-input input-padding" fxLayout="row">
    <div fxFlex="4"></div>
    <div fxFlex="92" class="client-input" fxLayout="column">
      <span>Notes</span>
      <dx-text-area [(value)]="company.notes"></dx-text-area>
    </div>
    <div fxFlex="4"></div>
  </div>

  <div class="" fxLayout="row wrap">
    <ng-template ngFor let-field [ngForOf]="companyDetails">
      <div
        *ngIf="field.FieldType == 1 && field.Name == 'Notes'"
        class="client-input input-padding"
        fxFlex="100"
        fxLayout="row"
      >
        <div fxFlex></div>
        <div fxFlex="86" class="client-input" fxLayout="column">
          <span [innerText]="field.Name"></span>
          <dx-text-area [(value)]="field.Value" [autoResizeEnabled]="true"></dx-text-area>
        </div>
        <div fxFlex></div>
      </div>
      <div
        *ngIf="field.FieldType == 11"
        class="client-input input-padding"
        fxFlex="50"
        fxLayout="row"
      >
        <div fxFlex></div>
        <div fxFlex="84" class="client-input" fxLayout="column">
          <span>{{ field.Name }}</span>
          <dx-text-box [(value)]="field.Value">
            <!-- <dx-validator>
                            <dxi-validation-rule type="pattern" [pattern]="websiteRules" message="Must be a valid website"></dxi-validation-rule>
                        </dx-validator> -->
          </dx-text-box>
        </div>
        <div fxFlex></div>
      </div>
      <div
        *ngIf="
          (field.FieldType == 1 || field.FieldType == 8 || field.FieldType == 10) &&
          field.Name != 'Notes'
        "
        class="client-input input-padding"
        fxFlex="50"
        fxLayout="row"
      >
        <div fxFlex></div>
        <div fxFlex="84" class="client-input" fxLayout="column">
          <span>{{ field.Name }}</span>
          <dx-text-box [(value)]="field.Value"></dx-text-box>
        </div>
        <div fxFlex></div>
      </div>
      <div
        *ngIf="field.FieldType == 2"
        class="client-input input-padding"
        fxFlex="50"
        fxLayout="row"
      >
        <div fxFlex="8"></div>
        <div fxFlex="84" class="client-input" fxLayout="column">
          <span [innerText]="field.Name"></span>
          <dx-check-box [(value)]="field.Value"></dx-check-box>
        </div>
        <div fxFlex="8"></div>
      </div>
      <div
        *ngIf="field.FieldType == 3"
        class="client-input input-padding"
        fxFlex="50"
        fxLayout="row"
      >
        <div fxFlex="8"></div>
        <div fxFlex="84" class="client-input" fxLayout="column">
          <span [innerText]="field.Name"></span>
          <dx-number-box [(value)]="field.Value"></dx-number-box>
        </div>
        <div fxFlex="8"></div>
      </div>
      <div
        *ngIf="field.FieldType == 4"
        class="client-input input-padding"
        fxFlex="50"
        fxLayout="row"
      >
        <div fxFlex="8"></div>
        <div fxFlex="84" class="client-input" fxLayout="column">
          <span [innerText]="field.Name"></span>
          <dx-number-box format="$ #,##0.##" [(value)]="field.Value"></dx-number-box>
        </div>
        <div fxFlex="8"></div>
      </div>
      <div
        *ngIf="field.FieldType == 5"
        class="client-input input-padding"
        fxFlex="50"
        fxLayout="row"
      >
        <div fxFlex="8"></div>
        <div fxFlex="84" class="client-input" fxLayout="column">
          <span [innerText]="field.Name"></span>
          <dx-select-box
            [dataSource]="field.Options"
            displayExpr="Name"
            valueExpr="Id"
            [(value)]="field.Value"
          ></dx-select-box>
        </div>
        <div fxFlex="8"></div>
      </div>
      <div
        *ngIf="field.FieldType == 6"
        class="client-input input-padding"
        fxFlex="50"
        fxLayout="row"
      >
        <div fxFlex="8"></div>
        <div fxFlex="84" class="client-input" fxLayout="column">
          <span [innerText]="field.Name"></span>
          <dx-date-box [(value)]="field.Value" type="date" [width]="232"></dx-date-box>
        </div>
        <div fxFlex="8"></div>
      </div>
      <div
        *ngIf="field.FieldType == 9"
        class="client-input input-padding"
        fxFlex="50"
        fxLayout="row"
      >
        <div fxFlex="8"></div>
        <div fxFlex="84" class="client-input" fxLayout="column">
          <span [innerText]="field.Name"></span>
          <dx-text-box
            [(value)]="field.Value"
            mask="(000) 000-0000"
            [maskRules]="rules"
          ></dx-text-box>
        </div>
        <div fxFlex="8"></div>
      </div>
      <div
        *ngIf="field.FieldType == 12"
        class="client-input input-padding"
        fxFlex="50"
        fxLayout="row"
      >
        <div fxFlex="8"></div>
        <div fxFlex="84" class="client-input" fxLayout="column">
          <span [innerText]="field.Name"></span>
          <dx-text-box [(value)]="field.Value"></dx-text-box>
        </div>
        <div fxFlex="8"></div>
      </div>
    </ng-template>
    <div class="client-input input-padding" fxFlex="50" fxLayout="row">
      <div fxFlex="8"></div>
      <div fxFlex="84" class="client-input" fxLayout="column">
        <span>Parent Company</span>
        <dx-select-box
          #parentSelectBox
          [dataSource]="companies"
          searchEnabled="true"
          displayExpr="name"
          valueExpr="id"
          [(value)]="company.parentCompanyId"
          (onKeyUp)="parentCompanySearch($event)"
          itemTemplate="itemTemplate"
        >
          <div *dxTemplate="let company of 'itemTemplate'">
            <div style="font-weight: 700">{{ company.name }}</div>
            <div fxLayout="row">
              <span *ngIf="company.billingAddress" fxLayout="row">
                <span
                  *ngIf="
                    company.billingAddress.addressLine1 != '' &&
                    company.billingAddress.addressLine2 != ''
                  "
                  >{{ company.billingAddress.addressLine1 }}&nbsp;{{
                    company.billingAddress.addressLine2
                  }},&nbsp;</span
                >
                <span
                  *ngIf="
                    company.billingAddress.addressLine1 != '' &&
                    company.billingAddress.addressLine2 == ''
                  "
                  >{{ company.billingAddress.addressLine1 }},&nbsp;</span
                >
                <span
                  *ngIf="
                    company.billingAddress.addressLine1 == '' &&
                    company.billingAddress.addressLine2 != ''
                  "
                  >{{ company.billingAddress.addressLine2 }},&nbsp;</span
                >
                <span *ngIf="company.billingAddress.city != ''"
                  >{{ company.billingAddress.city }},&nbsp;</span
                >
                <span *ngIf="company.billingAddress.state != ''"
                  >{{ company.billingAddress.state }}&nbsp;</span
                >
                <span
                  *ngIf="
                    company.billingAddress.addressLine1 == '' &&
                    !company.billingAddress.addressLine2 == ''
                  "
                >
                  <span class="error-text">Missing Address</span>,&nbsp;
                </span>
                <span *ngIf="company.billingAddress.city == ''"
                  ><span class="error-text">Missing&nbsp;City</span>,&nbsp;</span
                >
                <span *ngIf="company.billingAddress.state == ''"
                  ><span class="error-text">Missing&nbsp;State</span></span
                >
              </span>
              <span *ngIf="!company.billingAddress" fxLayout="row">
                <span> <span class="error-text">Missing Address</span>,&nbsp; </span>
                <span class="error-text">Missing&nbsp;City</span>,&nbsp;
                <span class="error-text">Missing&nbsp;State</span>
              </span>
            </div>
          </div>
        </dx-select-box>
      </div>
      <div fxFlex="8"></div>
    </div>
  </div>
</div>
<!-- <div class="white-padding" fxLayout="row">
    <div fxFlex="4"></div>
    <div class="client-input edit-company-dropdown input-padding popup-expanded-row" fxLayout="row" (click)="expandCustomFields()"
        fxFlex>
        <div fxFlex>Custom Fields</div>
        <mat-icon *ngIf="!customFieldsExpanded" class="icon-large">arrow_drop_down</mat-icon>
        <mat-icon *ngIf="customFieldsExpanded" class="icon-large">arrow_drop_up</mat-icon>
    </div>
    <div fxFlex="4"></div>
</div>
<div *ngIf="customFieldsExpanded">
    <div *ngFor="let section of customSections; let first = first;" fxLayout="column">
        <span *ngIf="first" fxLayoutAlign="start center" class="popup-width popup-h2" style="padding-top: 10px;">{{section.Name}}</span>
        <span *ngIf="!first" fxLayoutAlign="start center" class="popup-width popup-h2" style="padding-top: 10px;">{{section.Name}}</span>



        <div *ngIf="section.Tabs.length==0">
            <div fxLayout="row wrap">
                <ng-template ngFor let-field [ngForOf]="section.Fields">
                    <div *ngIf="field.FieldType == 1 && field.Name == 'Notes'" class="client-input input-padding" fxFlex="100" fxLayout="row">
                        <div fxFlex></div>
                        <div fxFlex="86" class="client-input" fxLayout="column">
                            <span [innerText]="field.Name"></span>
                            <dx-text-area [(value)]="field.Value" [autoResizeEnabled]="true"></dx-text-area>
                        </div>
                        <div fxFlex></div>
                    </div>
                    <div *ngIf="field.FieldType==11" class="client-input input-padding" fxFlex="50" fxLayout="row">
                        <div fxFlex></div>
                        <div fxFlex="84" class="client-input" fxLayout="column">
                            <span [innerText]="field.Name"></span>
                            <dx-text-box [(value)]="field.Value">
                                <dx-validator>
                                    <dxi-validation-rule type="pattern" [pattern]="websiteRules" message="Must be a valid website"></dxi-validation-rule>
                                </dx-validator>
                            </dx-text-box>
                        </div>
                        <div fxFlex></div>
                    </div>
                    <div *ngIf="(field.FieldType==1 || field.FieldType==8 || field.FieldType==10) && field.Name != 'Notes'" class="client-input input-padding"
                        fxFlex="50" fxLayout="row">
                        <div fxFlex></div>
                        <div fxFlex="84" class="client-input" fxLayout="column">
                            <span [innerText]="field.Name"></span>
                            <dx-text-box [(value)]="field.Value"></dx-text-box>
                        </div>
                        <div fxFlex></div>
                    </div>
                    <div *ngIf="field.FieldType==2" class="client-input input-padding" fxFlex="50" fxLayout="row">
                        <div fxFlex="8"></div>
                        <div fxFlex="84" class="client-input" fxLayout="column">
                            <span [innerText]="field.Name"></span>
                            <dx-check-box [(value)]="field.Value"></dx-check-box>
                        </div>
                        <div fxFlex="8"></div>
                    </div>
                    <div *ngIf="field.FieldType==3" class="client-input input-padding" fxFlex="50" fxLayout="row">
                        <div fxFlex="8"></div>
                        <div fxFlex="84" class="client-input" fxLayout="column">
                            <span [innerText]="field.Name"></span>
                            <dx-number-box [(value)]="field.Value"></dx-number-box>
                        </div>
                        <div fxFlex="8"></div>
                    </div>
                    <div *ngIf="field.FieldType==4" class="client-input input-padding" fxFlex="50" fxLayout="row">
                        <div fxFlex="8"></div>
                        <div fxFlex="84" class="client-input" fxLayout="column">
                            <span [innerText]="field.Name"></span>
                            <dx-number-box format="$ #,##0.##" [(value)]="field.Value"></dx-number-box>
                        </div>
                        <div fxFlex="8"></div>
                    </div>
                    <div *ngIf="field.FieldType==5" class="client-input input-padding" fxFlex="50" fxLayout="row">
                        <div fxFlex="8"></div>
                        <div fxFlex="84" class="client-input" fxLayout="column">
                            <span [innerText]="field.Name"></span>
                            TODO Figure out why this isn't saving....
                            <dx-select-box [dataSource]="field.Options" displayExpr="Name" valueExpr="Id" [(value)]="field.Value" (onValueChanged)="valueChanged($event, field)"></dx-select-box>
                        </div>
                        <div fxFlex="8"></div>
                    </div>
                    <div *ngIf="field.FieldType==6" class="client-input input-padding" fxFlex="50" fxLayout="row">
                        <div fxFlex="8"></div>
                        <div fxFlex="84" class="client-input" fxLayout="column">
                            <span [innerText]="field.Name"></span>
                            <dx-date-box [(value)]="field.Value" type="date" [width]="232"></dx-date-box>
                        </div>
                        <div fxFlex="8"></div>
                    </div>
                    <div *ngIf="field.FieldType==9" class="client-input input-padding" fxFlex="50" fxLayout="row">
                        <div fxFlex="8"></div>
                        <div fxFlex="84" class="client-input" fxLayout="column">
                            <span [innerText]="field.Name"></span>
                            <dx-text-box [(value)]="field.Value" mask="(000) 000-0000" [maskRules]="rules"></dx-text-box>
                        </div>
                        <div fxFlex="8"></div>
                    </div>
                    <div *ngIf="field.FieldType==12" class="client-input input-padding" fxFlex="50" fxLayout="row">
                        <div fxFlex="8"></div>
                        <div fxFlex="84" class="client-input" fxLayout="column">
                            <span [innerText]="field.Name"></span>
                            <dx-text-box [(value)]="field.Value"></dx-text-box>
                        </div>
                        <div fxFlex="8"></div>
                    </div>
                </ng-template>
            </div>
        </div>
        <div *ngIf="section.Tabs.length>0">
            <div *ngFor="let tab of section.Tabs" fxLayout="column">
                <span fxLayoutAlign="center center" class="popup-width popup-h3" style="border-bottom: solid 1px black;">{{tab.Name}}</span>
                <div fxLayout="row wrap">
                    <ng-template ngFor let-field [ngForOf]="tab.Fields">
                        <div *ngIf="field.FieldType == 1 && field.Name == 'Notes'" class="client-input input-padding" fxFlex="100" fxLayout="row">
                            <div fxFlex></div>
                            <div fxFlex="86" class="client-input" fxLayout="column">
                                <span [innerText]="field.Name"></span>
                                <dx-text-area [(value)]="field.Value" [autoResizeEnabled]="true"></dx-text-area>
                            </div>
                            <div fxFlex></div>
                        </div>
                        <div *ngIf="field.FieldType==11" class="client-input input-padding" fxFlex="50" fxLayout="row">
                            <div fxFlex></div>
                            <div fxFlex="84" class="client-input" fxLayout="column">
                                <span [innerText]="field.Name"></span>
                                <dx-text-box [(value)]="field.Value">
                                    <dx-validator>
                                        <dxi-validation-rule type="pattern" [pattern]="websiteRules" message="Must be a valid website"></dxi-validation-rule>
                                    </dx-validator>
                                </dx-text-box>
                            </div>
                            <div fxFlex></div>
                        </div>
                        <div *ngIf="(field.FieldType==1 || field.FieldType==8 || field.FieldType==10) && field.Name != 'Notes'" class="client-input input-padding"
                            fxFlex="50" fxLayout="row">
                            <div fxFlex></div>
                            <div fxFlex="84" class="client-input" fxLayout="column">
                                <span [innerText]="field.Name"></span>
                                <dx-text-box [(value)]="field.Value"></dx-text-box>
                            </div>
                            <div fxFlex></div>
                        </div>
                        <div *ngIf="field.FieldType==2" class="client-input input-padding" fxFlex="50" fxLayout="row">
                            <div fxFlex="8"></div>
                            <div fxFlex="84" class="client-input" fxLayout="column">
                                <span [innerText]="field.Name"></span>
                                <dx-check-box [(value)]="field.Value"></dx-check-box>
                            </div>
                            <div fxFlex="8"></div>
                        </div>
                        <div *ngIf="field.FieldType==3" class="client-input input-padding" fxFlex="50" fxLayout="row">
                            <div fxFlex="8"></div>
                            <div fxFlex="84" class="client-input" fxLayout="column">
                                <span [innerText]="field.Name"></span>
                                <dx-number-box [(value)]="field.Value"></dx-number-box>
                            </div>
                            <div fxFlex="8"></div>
                        </div>
                        <div *ngIf="field.FieldType==4" class="client-input input-padding" fxFlex="50" fxLayout="row">
                            <div fxFlex="8"></div>
                            <div fxFlex="84" class="client-input" fxLayout="column">
                                <span [innerText]="field.Name"></span>
                                <dx-number-box format="$ #,##0.##" [(value)]="field.Value"></dx-number-box>
                            </div>
                            <div fxFlex="8"></div>
                        </div>
                        <div *ngIf="field.FieldType==5" class="client-input input-padding" fxFlex="50" fxLayout="row">
                            <div fxFlex="8"></div>
                            <div fxFlex="84" class="client-input" fxLayout="column">
                                <span [innerText]="field.Name"></span>
                                TODO Figure out why this isn't saving....
                                <dx-select-box [dataSource]="field.Options" displayExpr="Name" valueExpr="Id" [(value)]="field.Value" (onValueChanged)="valueChanged($event, field)"></dx-select-box>
                            </div>
                            <div fxFlex="8"></div>
                        </div>
                        <div *ngIf="field.FieldType==6" class="client-input input-padding" fxFlex="50" fxLayout="row">
                            <div fxFlex="8"></div>
                            <div fxFlex="84" class="client-input" fxLayout="column">
                                <span [innerText]="field.Name"></span>
                                <dx-date-box [(value)]="field.Value" type="date" [width]="232"></dx-date-box>
                            </div>
                            <div fxFlex="8"></div>
                        </div>
                        <div *ngIf="field.FieldType==9" class="client-input input-padding" fxFlex="50" fxLayout="row">
                            <div fxFlex="8"></div>
                            <div fxFlex="84" class="client-input" fxLayout="column">
                                <span [innerText]="field.Name"></span>
                                <dx-text-box [(value)]="field.Value" mask="(000) 000-0000" [maskRules]="rules"></dx-text-box>
                            </div>
                            <div fxFlex="8"></div>
                        </div>
                        <div *ngIf="field.FieldType==12" class="client-input input-padding" fxFlex="50" fxLayout="row">
                            <div fxFlex="8"></div>
                            <div fxFlex="84" class="client-input" fxLayout="column">
                                <span [innerText]="field.Name"></span>
                                <dx-text-box [(value)]="field.Value"></dx-text-box>
                            </div>
                            <div fxFlex="8"></div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div> -->
