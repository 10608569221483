import { Action, createReducer, on } from '@ngrx/store';
import { GenericApi } from 'src/app/models/generic-api';
import { GenericMap } from 'src/app/models/generic-map';
import { ProjectCustomField } from 'src/app/models/projects/project-custom-field';
import {
  LoadBidCustomFields,
  LoadBidCustomFieldsSuccess,
  LoadBidCustomFieldsFailure,
  SetCurrentBidCustomFieldId
} from './bid-custom-fields.actions';

export const bidCustomFieldsFeatureKey = 'BidCustomFields';

export interface BidCustomFieldsState extends GenericApi<GenericMap<ProjectCustomField>> {
  selectedPcfId: number;
}

export const initialState: BidCustomFieldsState = {
  pending: false,
  error: undefined,
  selectedPcfId: null,
  data: {}
};

const reducer = createReducer(
  initialState,

  on(LoadBidCustomFields, state => {
    return {
      ...state,
      pending: true,
      error: undefined
    };
  }),
  on(LoadBidCustomFieldsSuccess, (state, action) => {
    return {
      ...state,
      pending: true,
      data: action.data
    };
  }),
  on(LoadBidCustomFieldsFailure, (state, action) => {
    return {
      ...state,
      pending: true,
      error: action.error
    };
  }),
  on(SetCurrentBidCustomFieldId, (state, action) => {
    return {
      ...state,
      selectedPcfId: action.id
    };
  })
);

export function bidCustomFieldsReducer(
  state: BidCustomFieldsState | undefined,
  action: Action
) {
  return reducer(state, action);
}
