import { HttpErrorResponse } from '@angular/common/http';
import { Todo } from 'src/app/models/todo/todo';
import { TodosActions, TodosActionTypes } from './todos.actions';
import { Statement } from '@angular/compiler';

export interface TodosState {
  pending: boolean;
  error: HttpErrorResponse;
  completeTodos: Todo[];
  incompleteTodos: Todo[];
  showTodos: boolean;
}

export const initialState: TodosState = {
  pending: false,
  error: undefined,
  completeTodos: [],
  incompleteTodos: [],
  showTodos: undefined
};

export function todosReducer(state = initialState, action: TodosActions): TodosState {
  switch (action.type) {
    case TodosActionTypes.SetLocalTodos:
      return {
        ...state,
        showTodos: action.payload
      };

    case TodosActionTypes.LoadCompleteTodos:
      return {
        ...state,
        pending: true
      };

    case TodosActionTypes.LoadCompleteTodosSuccess:
      return {
        ...state,
        pending: false,
        completeTodos: action.payload
      };

    case TodosActionTypes.LoadCompleteTodosFailure:
      return {
        ...state,
        pending: false,
        completeTodos: [],
        error: action.payload
      };

    case TodosActionTypes.LoadIncompleteTodos:
      return {
        ...state,
        pending: true
      };

    case TodosActionTypes.LoadIncompleteTodosSuccess:
      return {
        ...state,
        pending: false,
        incompleteTodos: action.payload
      };

    case TodosActionTypes.LoadIncompleteTodosFailure:
      return {
        ...state,
        pending: false,
        incompleteTodos: [],
        error: action.payload
      };

    case TodosActionTypes.CreateTodo:
      return {
        ...state,
        pending: true
      };

    case TodosActionTypes.CreateTodoSuccess:
      return {
        ...state,
        pending: false,
        incompleteTodos: [action.payload, ...state.incompleteTodos]
      };

    case TodosActionTypes.CreateTodoFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case TodosActionTypes.UpdateTodoSuccess:
      return {
        ...state,
        pending: false
      };

    case TodosActionTypes.UpdateTodoFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case TodosActionTypes.ReorderTodos:
      return {
        ...state,
        pending: true
      };

    case TodosActionTypes.ReorderTodosSuccess:
      return {
        ...state,
        pending: false,
        incompleteTodos: action.payload
      };

    case TodosActionTypes.ReorderTodosFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case TodosActionTypes.DeleteTodo:
      return {
        ...state,
        pending: true
      };

    case TodosActionTypes.DeleteTodoSuccess:
      return {
        ...state,
        pending: false
      };

    case TodosActionTypes.DeleteTodoFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    default:
      return state;
  }
}
