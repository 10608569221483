<file-upload-popup
  *ngIf="fileUploadPopup"
  [file]="file"
  [(fileUploadPopup)]="fileUploadPopup"
  [users]="users"
  [companyFiles]="companyFiles"
  [companyId]="companyJPI.id"
></file-upload-popup>
<file-upload-popup
  *ngIf="fileUploadPopup$"
  [file]="file"
  [(fileUploadPopup)]="fileUploadPopup$"
  [users]="users"
  [companyFiles]="companyFiles"
  [companyId]="companyJPI.id"
></file-upload-popup>

<div class="panel-padding">
  <dx-data-grid
    *ngIf="companyFiles && companyFiles.length > 0"
    [dataSource]="companyFiles"
    [showColumnLines]="false"
    [showRowLines]="false"
    [showBorders]="true"
    [rowAlternationEnabled]="false"
    style="max-height: 700px"
  >
    <dxo-scrolling mode="virtual"></dxo-scrolling>
    <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
    <dxi-column cellTemplate="nameTemplate" caption="Name"></dxi-column>
    <dxi-column dataField="FileType" caption="Type"></dxi-column>
    <dxi-column dataField="FileSize" caption="Size"></dxi-column>
    <!-- <dxi-column dataField="uploadedUser" caption="Uploaded By"></dxi-column> -->
    <dxi-column
      dataField="UploadedDate"
      caption="Uploaded Date"
      dataType="date"
    ></dxi-column>
    <!-- <dxi-column dataField="CategoryName" caption="Category" [groupIndex]="0"></dxi-column> -->
    <dxi-column cellTemplate="deleteTemplate"></dxi-column>
    <div *dxTemplate="let name of 'nameTemplate'">
      <a target="_blank" (click)="downloadFile(name.data)" class="link text-underlined">{{
        name.data.FileName
      }}</a>
    </div>
    <div *dxTemplate="let icon of 'deleteTemplate'">
      <mat-icon class="icon-spacing" (click)="deleteFile(icon.data)">delete</mat-icon>
    </div>
  </dx-data-grid>
  <div *ngIf="!companyFiles || companyFiles.length == 0" style="padding-top: 10px">
    There are no uploaded files for&nbsp;{{ companyJPI.name }}
  </div>
  <div style="padding: 5px;"></div>
  <span class="button button-white" (click)="uploadNewFile()">Upload File</span>
</div>

<dx-popup
  [width]="360"
  [height]="150"
  [showTitle]="false"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [(visible)]="deleteFilePopup"
  shadingColor="rgba(0,0,0,0.5)"
>
  <div *dxTemplate="let data of 'content'">
    <div fxLayout="column" fxFlex>
      <h3>Are you sure you want to delete this file?</h3>
      <div fxLayout="row" fxLayoutAlign="center center">
        <span class="button button-white" (click)="deleteFilePopup = false">No</span>
        <span class="button button-red" (click)="fileDeleted()">Yes</span>
      </div>
    </div>
  </div>
</dx-popup>

<dx-popup
  [width]="280"
  [height]="140"
  [showTitle]="false"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [(visible)]="loading"
  shadingColor="rgba(0,0,0,0.5)"
>
  <div *dxTemplate="let data of 'content'">
    <div fxLayout="column" fxLayoutAlign="center center">
      <dx-load-indicator id="medium-indicator" height="40" width="40"></dx-load-indicator>
      <h3>Deleting file...</h3>
    </div>
  </div>
</dx-popup>
