import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import {
  LoadProjectLabelsFailure,
  LoadProjectLabelsSuccess,
  ProjectLabelsActionTypes,
  ProjectLabelsActions,
  UpdateProjectLabels,
  UpdateProjectLabelsSuccess,
  UpdateProjectLabelsFailure,
  AddProjectLabelsSuccess,
  AddProjectLabelsFailure,
  AddProjectLabels,
  DeleteProjectLabels,
  DeleteProjectLabelsSuccess,
  DeleteProjectLabelsFailure,
  LoadProjectLabels,
  SelectProjectLabelId
} from './project-labels.actions';
import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ProjectLabelsService } from '../../../services/system-settings/projects/project-labels.service';
import { convertToMap } from 'src/app/utils/convertToMap';
import { LabelGroup } from 'src/app/models/label-group';
import { LabelColumnAdded, AddLabelColumns } from '../../projects/projects.actions';
import { ColumnType } from 'src/app/models/column-header';

@Injectable()
export class ProjectLabelsEffects {
  loadProjectLabels$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectLabelsActionTypes.LoadProjectLabels),
      mergeMap(() =>
        this.projectLabelsService.getAll().pipe(
          switchMap((data: LabelGroup[]) => {
            return [
              new LoadProjectLabelsSuccess(convertToMap(data, 'id')),
              new AddLabelColumns()
            ];
          }),
          catchError((error: HttpErrorResponse) =>
            of(new LoadProjectLabelsFailure(error))
          )
        )
      )
    )
  );

  addProjectLabels$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectLabelsActionTypes.AddProjectLabels),
      map((action: AddProjectLabels) => action.payload),
      mergeMap((projectLabel: LabelGroup) => {
        return this.projectLabelsService.addProjectLabel(projectLabel).pipe(
          switchMap((newProjectLabel: LabelGroup) => [
            new AddProjectLabelsSuccess(newProjectLabel),
            new SelectProjectLabelId(newProjectLabel.id),
            new LabelColumnAdded({
              name: newProjectLabel.name,
              displayName: newProjectLabel.name,
              type: ColumnType.NUMERIC
            }),
            new LoadProjectLabels()
          ]),
          catchError((error: HttpErrorResponse) => of(new AddProjectLabelsFailure(error)))
        );
      })
    )
  );

  updateProjectLabels$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectLabelsActionTypes.UpdateProjectLabels),
      map((action: UpdateProjectLabels) => action.payload),
      mergeMap((projectLabel: LabelGroup) => {
        return this.projectLabelsService.updateProjectLabel(projectLabel).pipe(
          switchMap((updatedProjectLabel: LabelGroup) => [
            new UpdateProjectLabelsSuccess(updatedProjectLabel),
            new LabelColumnAdded({
              name: updatedProjectLabel.name,
              displayName: updatedProjectLabel.name,
              type: ColumnType.NUMERIC
            }),
            new LoadProjectLabels()
          ]),
          catchError((error: HttpErrorResponse) =>
            of(new UpdateProjectLabelsFailure(error))
          )
        );
      })
    )
  );

  deleteProjectLabels$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectLabelsActionTypes.DeleteProjectLabels),
      map((action: DeleteProjectLabels) => action.payload),
      mergeMap((projectLabelId: number) =>
        this.projectLabelsService.deleteProjectLabel(projectLabelId).pipe(
          switchMap(() => [
            new DeleteProjectLabelsSuccess(projectLabelId),
            new LoadProjectLabels()
          ]),
          catchError((error: HttpErrorResponse) =>
            of(new DeleteProjectLabelsFailure(error))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions<ProjectLabelsActions>,
    private projectLabelsService: ProjectLabelsService
  ) {}
}
