<div fxLayout="column" class="container" fxLayoutAlign="center space-between">
  <div id="microphone"></div>
  <div id="controls" fxFlex fxLayout="column" fxLayoutAlign="center center">
    <div class="timer">{{ format(mm) }}:{{ format(ss) }}.{{ format(ms) }}</div>
    <p>{{ recordingStatusPrompt }}</p>
    <button class="record-btn red" mat-fab (click)="startRecording()" *ngIf="!recording">
      <mat-icon class="icon"> keyboard_voice </mat-icon>
    </button>
    <button class="record-btn green" mat-fab (click)="stopRecording()" *ngIf="recording">
      <mat-icon class="icon"> keyboard_voice </mat-icon>
    </button>
    <p>{{ recordingStatus }}</p>

    <div id="loudnoises"></div>
    <div
      fxFlex
      fxLayout="row"
      fxLayoutAlign="center space-around"
      [ngClass]="recordedAudio === true ? 'visible' : 'hidden'"
    >
      <button mat-icon-button (click)="replay()">
        <mat-icon class="player-control">replay_10</mat-icon>
      </button>
      <button mat-icon-button (click)="handlePlay()" *ngIf="audioPaused">
        <mat-icon class="player-control">play_arrow</mat-icon>
      </button>
      <button mat-icon-button (click)="handlePlay()" *ngIf="audioPlaying">
        <mat-icon class="player-control">pause</mat-icon>
      </button>
      <button mat-icon-button (click)="forward()">
        <mat-icon class="player-control">forward_10</mat-icon>
      </button>
      <button mat-icon-button matTooltip="Delete Recording" (click)="clearRecording()">
        <mat-icon class="player-control" color="warn">delete</mat-icon>
      </button>
    </div>
  </div>
</div>
