<div
  class="contact-form-container"
  fxLayout="column"
  fxLayoutAlign="space-between center"
>
  <div fxLayout="column" class="contact-form-column-container">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <h3 *ngIf="!hasContact">Add Contact</h3>
      <h3 *ngIf="hasContact">Edit Contact</h3>
      <button mat-icon-button (click)="cancel()"><mat-icon>close</mat-icon></button>
    </div>
    <form [formGroup]="newContactForm" fxLayout="column">
      <div fxLayout="row" fxLayoutGap="12px">
        <mat-form-field>
          <input
            matInput
            autocomplete="off"
            formControlName="firstName"
            placeholder="First Name"
            required="true"
          />
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            autocomplete="off"
            formControlName="lastName"
            placeholder="Last Name"
          />
        </mat-form-field>
      </div>

      <tn-company-select
        *ngIf="data.hasCompanyInput && !hasContact"
        (companySearchEvent)="onCompanySearch($event)"
        [$companySearchResults]="$companies"
        [selectedCompany]="company"
        [$error]="$companiesSearchError"
        [isRequired]="true"
        (companySelected)="onCompanySelect($event)"
        [$pending]="$companiesSearchPending"
      ></tn-company-select>

      <mat-form-field>
        <input matInput autocomplete="off" formControlName="title" placeholder="Title" />
      </mat-form-field>

      <mat-form-field>
        <input matInput autocomplete="off" formControlName="email" placeholder="Email" />
      </mat-form-field>

      <div fxLayout="row" fxLayoutGap="12px">
        <mat-form-field>
          <input
            matInput
            autocomplete="off"
            formControlName="phone"
            placeholder="Phone"
          />
        </mat-form-field>
        <mat-form-field>
          <input matInput autocomplete="off" formControlName="ext" placeholder="Ext" />
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutGap="12px">
        <mat-form-field>
          <input
            matInput
            autocomplete="off"
            formControlName="mobilePhone"
            placeholder="Mobile"
          />
        </mat-form-field>
        <mat-form-field>
          <input matInput autocomplete="off" formControlName="fax" placeholder="Fax" />
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutGap="12px">
        <mat-form-field>
          <mat-label>Birthday</mat-label>
          <input
            matInput
            autocomplete="off"
            formControlName="birthday"
            [matDatepicker]="picker"
          />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            autocomplete="off"
            formControlName="dept"
            placeholder="Department"
          />
        </mat-form-field>
      </div>
      <mat-form-field>
        <textarea
          class="text-area"
          matInput
          autocomplete="off"
          formControlName="notes"
          placeholder="Details"
        ></textarea>
      </mat-form-field>
      <br />
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>Address</mat-panel-title>
        </mat-expansion-panel-header>

        <form formGroupName="address" fxLayout="column">
          <mat-form-field>
            <input
              matInput
              autocomplete="off"
              formControlName="addressLine1"
              placeholder="Address Line 1"
            />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              autocomplete="off"
              formControlName="addressLine2"
              placeholder="Address Line 2"
            />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              autocomplete="off"
              formControlName="city"
              placeholder="City"
            />
          </mat-form-field>
          <div fxLayout="row" fxLayoutGap="12px">
            <mat-form-field fxFlex="65">
              <input
                matInput
                autocomplete="off"
                formControlName="state"
                placeholder="State / Province / Region"
              />
            </mat-form-field>
            <mat-form-field fxFlex="35">
              <input
                matInput
                autocomplete="off"
                formControlName="postCode"
                placeholder="ZIP / Postal Code"
              />
            </mat-form-field>
          </div>
          <mat-form-field>
            <input
              matInput
              autocomplete="off"
              formControlName="county"
              placeholder="County"
            />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              autocomplete="off"
              formControlName="country"
              placeholder="Country"
            />
          </mat-form-field>
        </form>
      </mat-expansion-panel>
    </form>
  </div>

  <div class="form-actions" fxLayout="row-reverse" fxLayoutAlign="space-between center">
    <div fxLayout="row-reverse" fxLayoutGap="12px">
      <button
        type="button"
        mat-raised-button
        color="primary"
        mat-button
        [disabled]="newContactForm.pristine || newContactForm.invalid"
        (click)="submitHandler()"
      >
        Save
      </button>
    </div>
  </div>
</div>
