import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
  OnDestroy,
  OnChanges
} from '@angular/core';

import { FormControl } from '@angular/forms';
import { startWith, map, mergeMap, tap } from 'rxjs/operators';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger
} from '@angular/material/autocomplete';
import { COMMA } from '@angular/cdk/keycodes';
import { Observable, Subscription, of, EMPTY } from 'rxjs';
import { IdNameItem } from 'src/app/models/id-name-item';

@Component({
  selector: 'tn-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss']
})
export class MultiSelectComponent implements OnInit, OnDestroy, OnChanges {
  @Input() selectedItems: IdNameItem[] = [];
  @Output() selectedItemsChange = new EventEmitter<IdNameItem[]>();
  @Input() $allItems: Observable<IdNameItem[]> = EMPTY;
  @Input() placeholder: string;
  @Input() $clearSelectedContactsEvent: Observable<void> = EMPTY;
  @Input() subtextProperty: string;
  @Input() hasNullOption = false;
  @Input() nullDisplayName = '-- None --';
  @Input() isRemovable: boolean = true;
  @Input() isDisabled: boolean = false;
  selectableItems: IdNameItem[] = [];
  $inputCtrlSub: Subscription;
  allItems: IdNameItem[] = [];
  $clearSelectedContactsSub: Subscription;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = false;
  separatorKeysCodes: number[] = [COMMA];
  itemInputCtrl = new FormControl();

  @ViewChild('itemInput', { static: true }) itemInput: ElementRef<HTMLInputElement>;
  @ViewChild('itemInput', { static: true, read: MatAutocompleteTrigger })
  autoComTrigger: MatAutocompleteTrigger;

  constructor() {}

  ngOnInit() {
    this.$inputCtrlSub = this.itemInputCtrl.valueChanges
      .pipe(
        startWith(null as string),
        mergeMap((itemInput: string | null) => {
          if (!!this.$allItems) {
            return this.$allItems.pipe(
              tap((items: IdNameItem[]) => {
                this.allItems = items;
                this.selectableItems = items.filter(
                  (item) =>
                    !this.selectedItems.map((selItem) => selItem?.id).includes(item.id)
                );
              }),
              map((items: IdNameItem[]) => {
                this.selectableItems = items
                  .filter((item) =>
                    this.selectableItems.map((selItem) => selItem.id).includes(item.id)
                  )
                  .filter((item) => {
                    return (
                      item.name
                        .toLowerCase()
                        .indexOf(
                          !!itemInput && typeof itemInput === 'string'
                            ? itemInput.toLowerCase()
                            : ''
                        ) >= 0
                    );
                  });
              })
            );
          } else {
            return EMPTY;
          }
        })
      )
      .subscribe();
    this.$clearSelectedContactsSub = this.$clearSelectedContactsEvent.subscribe(() => {
      this.selectedItems = [];
    });
    if (!this.isRemovable) {
      this.removable = false;
    }
    if (this.isDisabled) {
      this.itemInputCtrl.disable();
    }
  }

  ngOnDestroy() {
    this.$inputCtrlSub.unsubscribe();
    this.$clearSelectedContactsSub.unsubscribe();
  }

  ngOnChanges() {
    if (!!this.selectedItems && this.selectedItems.length) {
      this.selectableItems = this.allItems.filter(
        (item) => !this.selectedItems.map((selItem) => selItem.id).includes(item.id)
      );
    }
  }

  escapePressed(event: KeyboardEvent) {
    event.stopPropagation();
    this.autoComTrigger.closePanel();
  }

  removeItem(item: IdNameItem) {
    this.selectedItems = this.selectedItems.filter((selItem) => selItem.id !== item.id);
    this.selectableItems.push(item);
    this.selectedItemsChange.emit(this.selectedItems);
    this.itemInputCtrl.reset();
  }

  selected(itemSelection: MatAutocompleteSelectedEvent) {
    this.selectedItems.push(itemSelection.option.value);
    this.selectableItems = this.selectableItems.filter(
      (item) => item.id !== itemSelection.option.value.id
    );
    this.selectedItemsChange.emit(this.selectedItems);
    this.itemInput.nativeElement.value = '';
  }
}
