import { MapsActions, MapsActionTypes } from './maps.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { AgmRectangle } from '@agm/core';
import { Label } from 'src/app/models/label';
import { GenericMap } from 'src/app/models/generic-map';
import { Company } from 'src/app/models/companies/company';
import { GlobalFilter } from 'src/app/models/global-filter';

export interface MapsState {
  pending: boolean;
  error: HttpErrorResponse;
  bounds: AgmRectangle;
  companyMarkers: {
    data: GenericMap<Company>;
    error: HttpErrorResponse;
    pending: boolean;
  };
  sideDrawer: {
    opened: boolean;
    drawer: string;
  };
  hoveredMarker: Company;
  labels: GenericMap<Label>;
  filterIds: number[];
  showWithoutLabels: boolean;
  filters?: GlobalFilter;
}

export const initialState: MapsState = {
  pending: false,
  error: undefined,
  bounds: undefined,
  companyMarkers: {
    data: {},
    error: undefined,
    pending: false
  },
  sideDrawer: {
    opened: false,
    drawer: undefined
  },
  hoveredMarker: undefined,
  labels: {},
  filterIds: [],
  showWithoutLabels: false
};

export function mapsReducer(state = initialState, action: MapsActions): MapsState {
  switch (action.type) {
    case MapsActionTypes.SetBounds:
      return {
        ...state,
        bounds: action.payload
      };

    case MapsActionTypes.LoadCompanyMarkers:
      return {
        ...state,
        companyMarkers: {
          ...state.companyMarkers,
          pending: true
        }
      };

    case MapsActionTypes.LoadCompanyMarkersSuccess:
      return {
        ...state,
        companyMarkers: {
          pending: false,
          data: action.payload,
          error: undefined
        }
      };

    case MapsActionTypes.LoadCompanyMarkersFailure:
      return {
        ...state,
        companyMarkers: {
          pending: false,
          error: action.payload,
          data: {}
        }
      };

    case MapsActionTypes.OpenMapsSideDrawer:
      return {
        ...state,
        sideDrawer: {
          opened: true,
          drawer: action.payload
        }
      };

    case MapsActionTypes.CloseMapsSideDrawer:
      return {
        ...state,
        sideDrawer: {
          opened: false,
          drawer: undefined
        }
      };

    case MapsActionTypes.MarkerHovered:
      return {
        ...state,
        hoveredMarker: action.payload
      };

    case MapsActionTypes.AddLabelFilters:
      return {
        ...state,
        filterIds: [...state.filterIds].concat(action.payload)
      };

    case MapsActionTypes.RemoveLabelFilters: {
      return {
        ...state,
        filterIds: [
          ...state.filterIds.filter(filterId => !action.payload.includes(filterId))
        ]
      };
    }

    case MapsActionTypes.ToggleShowComapniesWithoutLabels: {
      return {
        ...state,
        showWithoutLabels: !state.showWithoutLabels
      };
    }

    default:
      return state;
  }
}
