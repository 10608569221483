import { Pipe, PipeTransform } from '@angular/core';
import { User } from 'src/app/models/admin/users/user';

@Pipe({
  name: 'usersFilter'
})
export class UsersFilterPipe implements PipeTransform {
  transform(users: User[], searchText: string): unknown {
    searchText = searchText ? searchText.toLowerCase() : null;
    return !!users && users.length > 0
      ? users.filter((user) => {
          return !!searchText
            ? user.firstName.toLowerCase().match(searchText) ||
                user.lastName.toLowerCase().match(searchText)
            : true;
        })
      : [];
  }
}
