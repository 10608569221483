import { MemoizedSelector, createSelector } from '@ngrx/store';
import { RootState } from '../../../store.reducer';
import { GenericMap } from 'src/app/models/generic-map';
import { ProjectCustomField } from 'src/app/models/projects/project-custom-field';

export const selectAllProjectManufCustomFields = (state: RootState) =>
  state.projectManufCustomFields.data;

export const selectedProjectManufCustomFieldId = (state: RootState) =>
  state.projectManufCustomFields.selectedPcfId;

export const selectCurrentProjectManufCustomField: MemoizedSelector<
  RootState,
  ProjectCustomField
> = createSelector(
  selectAllProjectManufCustomFields,
  selectedProjectManufCustomFieldId,
  (
    projectCFMap: GenericMap<ProjectCustomField>,
    projectCFId: number
  ): ProjectCustomField => {
    let projectCF: ProjectCustomField;
    if (!!projectCFId && projectCFMap[projectCFId]) {
      const id = projectCFId.toString();
      projectCF = {
        ...projectCFMap[id]
      };
    }
    return projectCF;
  }
);
