import { RootState } from '../../store.reducer';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { GenericMap } from 'src/app/models/generic-map';
import { extractParamId } from 'src/app/utils/extractParamId';
import { Company } from 'src/app/models/companies/company';
import { LoadCompanies } from '../companies.actions';

export const selectAllCompanies = (state: RootState): GenericMap<Company> =>
  state.companies.data;
export const selectCompanyParamId = (state: RootState) =>
  !!state.router
    ? !!state.router.state.root.firstChild
      ? extractParamId(state.router.state.root, 'companies')
      : undefined
    : undefined;

export const selectCurrentCompany: MemoizedSelector<RootState, Company> = createSelector(
  selectCompanyParamId,
  selectAllCompanies,
  (id: number, companies: GenericMap<Company>): Company => {
    let company: Company;
    if (!!id && Object.keys(companies).length && !!companies[id]) {
      company = {
        ...companies[id],
        links:
          !!companies[id] && companies[id].links
            ? companies[id].links.filter(link => link !== '')
            : []
      };
    }
    return company as Company;
  }
);

export const selectAllCompaniesAsArray: MemoizedSelector<
  RootState,
  Company[]
> = createSelector(selectAllCompanies, (companiesMap: GenericMap<Company>): Company[] => {
  return Object.values(companiesMap).map((company: Company) => ({
    accountId: company.accountId,
    agencyId: company.agencyId,
    billingAddress: company.billingAddress,
    directChildCompanies: company.directChildCompanies,
    contacts: company.contacts,
    createdDate: company.createdDate,
    deleted: company.deleted,
    deletedDate: company.deletedDate,
    updatedDate: company.updatedDate,
    fax: company.fax,
    ext: company.ext,
    geoLocation: company.geoLocation,
    id: company.id,
    labels: company.labels,
    mailbox: company.mailbox,
    name: company.name,
    notes: company.notes,
    parentCompanyId: company.parentCompanyId,
    phone: company.phone,
    primaryUser: company.primaryUser,
    shippingAddress: company.shippingAddress,
    territories: company.territories,
    links: company.links,
    dmsCustomerId: company.dmsCustomerId
  }));
});
