import { SendMFA } from './../../../../store/auth/auth.actions';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';

@Component({
  selector: 'tn-mfa-dialog',
  templateUrl: './mfa-dialog.component.html',
  styleUrls: ['./mfa-dialog.component.scss']
})
export class MfaDialogComponent {
  mfa = new FormControl('');

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<RootState>
  ) {}

  submitMFA() {
    this.store.dispatch(new SendMFA({ mfa: this.mfa.value, data: this.data }));
  }
}
