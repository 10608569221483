<file-upload-popup *ngIf="fileUploadPopup" [(fileUploadPopup)]="fileUploadPopup" [(file)]="file" [(contactId)]="contact.id"
    [(companyId)]="contact.CompanyId" [(companyFiles)]="companyFiles"></file-upload-popup>
<loading-popup *ngIf="isLoading" [isLoading]="isLoading" message="Saving..."></loading-popup>

<dx-popup [position]="{ my: 'right', at: 'right', of: window }" [width]="600" [height]="'100vh'" [showTitle]="false"
    [dragEnabled]="false" [closeOnOutsideClick]="false" [(visible)]="contactPopup" shadingColor="rgba(0,0,0,0.5)">
    <div *dxTemplate="let data of 'content'">
        <div fxLayout="column" style="height: calc(100% - 50px);">
            <dx-scroll-view [height]="'inherit'">
                <div fxLayout="row" fxFlex>
                    <div fxFlex="4"></div>
                    <div class="popup-title" fxLayoutAlign="start center" fxFlex>
                        <span *ngIf="!contact.id">New Contact</span>
                        <span *ngIf="contact.id">Edit Contact</span>
                    </div>
                </div>
                <div fxLayout="row">
                    <div fxFlex="4"></div>
                    <div fxFlex class="client-input input-padding" fxLayout="column">
                        <span fxLayoutAlign="start center">First Name</span>
                        <dx-text-box *ngIf="editContactsFeature==1" [(value)]="contact.firstName"></dx-text-box>
                        <dx-text-box *ngIf="editContactsFeature==0" [(value)]="contact.firstName" disabled="true"></dx-text-box>
                    </div>
                    <div fxFlex="8"></div>
                    <div fxFlex class="client-input input-padding" fxLayout="column">
                        <span fxLayoutAlign="start center">Last Name</span>
                        <dx-text-box *ngIf="editContactsFeature==1" [(value)]="contact.lastName"></dx-text-box>
                        <dx-text-box *ngIf="editContactsFeature==0" [(value)]="contact.lastName" disabled="true"></dx-text-box>
                    </div>
                    <div fxFlex="4"></div>
                </div>
                <div fxLayout="row">
                    <div fxFlex="4"></div>
                    <div fxFlex class="client-input input-padding" fxLayout="column">
                        <span fxLayoutAlign="start center">Email</span>
                        <dx-text-box *ngIf="editContactsFeature==1" [(value)]="contact.email"></dx-text-box>
                        <dx-text-box *ngIf="editContactsFeature==0" [(value)]="contact.email" disabled="true"></dx-text-box>
                    </div>
                    <div fxFlex="8"></div>
                    <div fxFlex class="client-input input-padding" fxLayout="column">
                        <span fxLayoutAlign="start center">Mobile</span>
                        <dx-text-box *ngIf="editContactsFeature==1" [(value)]="contact.mobilePhone" mask="(000) 000-0000"
                            [maskRules]="rules"></dx-text-box>
                        <dx-text-box *ngIf="editContactsFeature==0" [(value)]="contact.mobilePhone" mask="(000) 000-0000"
                            [maskRules]="rules" disabled="true"></dx-text-box>
                    </div>
                    <div fxFlex="4"></div>
                </div>
                <div fxLayout="row">
                    <div fxFlex="4"></div>
                    <div fxFlex class="client-input input-padding" fxLayout="column">
                        <span fxLayoutAlign="start center"> Phone</span>
                        <dx-text-box *ngIf="editContactsFeature==1" [(value)]="contact.phone" mask="(000) 000-0000"
                            [maskRules]="rules"></dx-text-box>
                        <dx-text-box *ngIf="editContactsFeature==0" [(value)]="contact.phone" mask="(000) 000-0000"
                            [maskRules]="rules" disabled="true"></dx-text-box>
                    </div>
                    <div fxFlex="8"></div>
                    <div fxFlex class="client-input input-padding" fxLayout="column">
                        <span fxLayoutAlign="start center"> Ext</span>
                        <dx-text-box *ngIf="editContactsFeature==1" [(value)]="contact.ext"></dx-text-box>
                        <dx-text-box *ngIf="editContactsFeature==0" [(value)]="contact.ext" disabled="true"></dx-text-box>
                    </div>
                    <div fxFlex="4"></div>
                </div>
                <div>
                    <div fxFlex="4"></div>
                    <div fxFlex class="client-input input-padding" fxLayout="column">
                        <span fxLayoutAlign="start center">Fax</span>
                        <dx-text-box *ngIf="editContactsFeature==1" [(value)]="contact.fax" mask="(000) 000-0000"
                            [maskRules]="rules"></dx-text-box>
                        <dx-text-box *ngIf="editContactsFeature==0" [(value)]="contact.fax" mask="(000) 000-0000"
                            [maskRules]="rules" disabled="true"></dx-text-box>
                    </div>
                    <div fxFlex="8"></div>
                    <div fxFlex class="client-input input-padding" fxLayout="column"></div>
                    <div fxFlex="4"></div>
                </div>
                <div fxLayout="row">
                    <div fxFlex="4"></div>
                    <div fxFlex class="client-input input-padding" fxLayout="column">
                        <span fxLayoutAlign="start center">Title</span>
                        <dx-text-box *ngIf="editContactsFeature==1" [(value)]="contact.title"></dx-text-box>
                        <dx-text-box *ngIf="editContactsFeature==0" [(value)]="contact.title" disabled="true"></dx-text-box>
                    </div>
                    <div fxFlex="8"></div>
                    <div fxFlex class="client-input input-padding" fxLayout="column">
                        <span fxLayoutAlign="start center">Company</span>
                        <dx-select-box #companySelectBox *ngIf="editContactsFeature==1" [dataSource]="contactCompanies"
                            displayExpr="name" [searchEnabled]="true" placeholder="Search for a company" (onKeyUp)="companySearch($event)"
                            (onValueChanged)="companyChanged($event)" [visible]="companySelectBoxVisible" itemTemplate="itemTemplate">
                            <div *dxTemplate="let company of 'itemTemplate'">
                                <div style="font-weight: 700">{{ company.name}}</div>
                                <div fxLayout="row">
                                    <span *ngIf="company.shippingAddress && !checkEmptyObject(company.shippingAddress)"
                                        fxLayout="row">
                                        <span *ngIf="company.shippingAddress.addressLine1 != '' && company.shippingAddress.addressLine2 != ''">{{company.shippingAddress.addressLine1}}&nbsp;{{company.shippingAddress.addressLine2}},&nbsp;</span>
                                        <span *ngIf="company.shippingAddress.addressLine1 != '' && company.shippingAddress.addressLine2 == ''">{{company.shippingAddress.addressLine1}},&nbsp;</span>
                                        <span *ngIf="company.shippingAddress.addressLine1 == '' && company.shippingAddress.addressLine2 != ''">{{company.shippingAddress.addressLine2}},&nbsp;</span>
                                        <span *ngIf="company.shippingAddress.city != ''">{{company.shippingAddress.city}},&nbsp;</span>
                                        <span *ngIf="company.shippingAddress.state != ''">{{company.shippingAddress.state}}&nbsp;</span>
                                        <span *ngIf="company.shippingAddress.addressLine1 == '' && !company.shippingAddress.addressLine2 == ''">
                                            <span class="text-muted">Missing&nbsp;Address</span>,&nbsp;
                                        </span>
                                        <span *ngIf="company.shippingAddress.city == ''"><span class="text-muted">Missing&nbsp;City</span>,&nbsp;</span>
                                        <span *ngIf="company.shippingAddress.state == ''"><span class="text-muted">Missing&nbsp;State</span></span>
                                    </span>
                                    <span *ngIf="company.billingAddress && !checkEmptyObject(company.billingAddress) && !company.shippingAddress"
                                        fxLayout="row">
                                        <span *ngIf="company.billingAddress.addressLine1 != '' && company.billingAddress.addressLine2 != ''">{{company.billingAddress.addressLine1}}&nbsp;{{company.billingAddress.addressLine2}},&nbsp;</span>
                                        <span *ngIf="company.billingAddress.addressLine1 != '' && company.billingAddress.addressLine2 == ''">{{company.billingAddress.addressLine1}},&nbsp;</span>
                                        <span *ngIf="company.billingAddress.addressLine1 == '' && company.billingAddress.addressLine2 != ''">{{company.billingAddress.addressLine2}},&nbsp;</span>
                                        <span *ngIf="company.billingAddress.city != ''">{{company.billingAddress.city}},&nbsp;</span>
                                        <span *ngIf="company.billingAddress.state != ''">{{company.billingAddress.state}}&nbsp;</span>
                                        <span *ngIf="company.billingAddress.addressLine1 == '' && !company.billingAddress.addressLine2 == ''">
                                            <span class="text-muted"> Missing&nbsp;Address</span>,&nbsp;
                                        </span>
                                        <span *ngIf="company.billingAddress.city == ''"><span class="text-muted">Missing&nbsp;City</span>,&nbsp;</span>
                                        <span *ngIf="company.billingAddress.state == ''"><span class="text-muted">Missing&nbsp;State</span></span>
                                    </span>
                                    <span *ngIf="(!company.billingAddress || checkEmptyObject(company.billingAddress)) && (!company.shippingAddress && checkEmptyObject(company.shippingAddress))"
                                        fxLayout="row">
                                        <span class="text-muted">No&nbsp;Address&nbsp;provided</span>
                                    </span>
                                </div>
                            </div>
                        </dx-select-box>
                        <div *ngIf="!companySelectBoxVisible">
                            <div fxLayout="row">
                                <div fxFlex fxLayout="column">
                                    <a [href]="'/companies/' + displayedCompany.id" target="_blank" class="link"
                                        [ngClass]="{ 'text-muted': (editCallNotesFeature==0) }" style="font-weight: 700; text-decoration: none">{{displayedCompany.name}}</a>
                                    <span [ngClass]="{ 'text-muted': (editCallNotesFeature==0) }" fxLayout="row wrap">
                                        <span *ngIf="displayedCompany.addressLine1 && (displayedCompany.city || displayedCompany.state || (displayedCompany.city && displayedCompany.state))">{{displayedCompany.addressLine1}},&nbsp;</span>
                                        <span *ngIf="displayedCompany.addressLine1 && !(displayedCompany.city || displayedCompany.state || (displayedCompany.city && displayedCompany.state))">{{displayedCompany.addressLine1}}</span>
                                        <span *ngIf="displayedCompany.addressLine2 && (displayedCompany.city || displayedCompany.state || (displayedCompany.city && displayedCompany.state))">{{displayedCompany.addressLine2}},&nbsp;</span>
                                        <span *ngIf="displayedCompany.addressLine2 && !(displayedCompany.city || displayedCompany.state || (displayedCompany.city && displayedCompany.state))">{{displayedCompany.addressLine2}}</span>
                                        <span *ngIf="displayedCompany.city && displayedCompany.state">{{displayedCompany.city}},&nbsp;</span>
                                        <span *ngIf="displayedCompany.city && !displayedCompany.state">{{displayedCompany.city}}</span>
                                        <span *ngIf="displayedCompany.state">{{ displayedCompany.state}}</span>
                                        <span *ngIf="!displayedCompany.addressLine1 && !displayedCompany.addressLine2 && !displayedCompany.city && !displayedCompany.state && !displayedCompany.city && !displayedCompany.postCode"
                                            class="text-muted">No address provided</span>
                                    </span>
                                </div>
                                <div *ngIf="editContactsFeature==1" fxLayoutAlign="end center">
                                    <mat-icon style="cursor: pointer" (click)="changeCompany()">edit_mode</mat-icon>
                                </div>
                            </div>
                        </div>
                        <dx-select-box *ngIf="editContactsFeature==0" [dataSource]="contactCompanies" [(value)]="companyId"
                            displayExpr="name" valueExpr="CompanyId" [searchEnabled]="true" placeholder="Search for a company"
                            disabled="true"></dx-select-box>
                    </div>
                    <div fxFlex="4"></div>
                </div>
                <div class="white-padding" fxLayout="row">
                    <div fxFlex="4"></div>
                    <div class="client-input edit-company-dropdown input-padding row-click popup-expanded-row" fxLayout="row"
                        (click)="expandAddressRow()" fxFlex>
                        <div fxFlex>Address</div>
                        <mat-icon *ngIf="!addressExpanded" class="icon-large">arrow_drop_down</mat-icon>
                        <mat-icon *ngIf="addressExpanded" class="icon-large">arrow_drop_up</mat-icon>
                    </div>
                    <div fxFlex="4"></div>
                </div>
                <div *ngIf="addressExpanded">
                    <div class="client-input input-padding" fxLayout="row">
                        <div fxFlex="4"></div>
                        <div class="client-input" fxLayout="column" fxFlex>
                            <div>Address Line 1</div>
                            <dx-text-box *ngIf="editContactsFeature==1" [(value)]="contact.address.addressLine1"></dx-text-box>
                            <dx-text-box *ngIf="editContactsFeature==0" [(value)]="contact.address.addressLine1"
                                disabled="true"></dx-text-box>
                        </div>
                        <div fxFlex="4"></div>
                    </div>
                    <div class="client-input input-padding" fxLayout="row">
                        <div fxFlex="4"></div>
                        <div class="client-input" fxLayout="column" fxFlex>
                            <div>Address Line 2</div>
                            <dx-text-box *ngIf="editContactsFeature==1" [(value)]="contact.address.addressLine2"></dx-text-box>
                            <dx-text-box *ngIf="editContactsFeature==0" [(value)]="contact.address.addressLine2"
                                disabled="true"></dx-text-box>
                        </div>
                        <div fxFlex="4"></div>
                    </div>
                    <div class="client-input input-padding" fxLayout="row">
                        <div fxFlex="4"></div>
                        <div fxFlex="43" class="client-input" fxLayout="column">
                            <span>City</span>
                            <dx-text-box *ngIf="editContactsFeature==1" [(value)]="contact.address.city"></dx-text-box>
                            <dx-text-box *ngIf="editContactsFeature==0" [(value)]="contact.address.city" disabled="true"></dx-text-box>
                        </div>
                        <div fxFlex="6"></div>
                        <div fxFlex="43" class="client-input" fxLayout="column">
                            <span>State</span>
                            <dx-text-box *ngIf="editContactsFeature==1" [(value)]="contact.address.state"></dx-text-box>
                            <dx-text-box *ngIf="editContactsFeature==0" [(value)]="contact.address.state" disabled="true"></dx-text-box>
                        </div>
                        <div fxFlex="4"></div>
                    </div>
                    <div class="client-input input-padding" fxLayout="row">
                        <div fxFlex="4"></div>
                        <div fxFlex="34" class="client-input" fxLayout="column">
                            <span>Zip Code</span>
                            <dx-text-box *ngIf="editContactsFeature==1" [(value)]="contact.address.postCode"></dx-text-box>
                            <dx-text-box *ngIf="editContactsFeature==0" [(value)]="contact.address.postCode" disabled="true"></dx-text-box>
                        </div>
                        <div fxFlex></div>
                        <!-- <div fxFlex="30" class="client-input" fxLayout="column">
                            <span>Country</span>
                            <dx-text-box *ngIf="editContactsFeature==1" placeholder="Select Country"></dx-text-box>
                            <dx-text-box *ngIf="editContactsFeature==0" placeholder="Select Country" disabled="true"></dx-text-box>
                        </div>
                        <div fxFlex="4"></div> -->
                    </div>
                    <!-- <div class="client-input input-padding" fxLayout="row">
                        <div fxFlex="4"></div>
                        <div fxFlex="50" class="client-input" fxLayout="column">
                            <span>County</span>
                            <dx-select-box placeholder="Select County"></dx-select-box>
                        </div>
                        <div fxFlex></div>
                    </div> -->
                </div>
                <div class="white-padding" fxLayout="row">
                    <div fxFlex="4"></div>
                    <div class="client-input edit-company-dropdown input-padding row-click popup-expanded-row" fxLayout="row"
                        (click)="expandContactDetails()" fxFlex>
                        <div fxFlex>Contact Details</div>
                        <mat-icon *ngIf="!contactDetailsExpanded" class="icon-large">arrow_drop_down</mat-icon>
                        <mat-icon *ngIf="contactDetailsExpanded" class="icon-large">arrow_drop_up</mat-icon>
                    </div>
                    <div fxFlex="4"></div>
                </div>
                <div *ngIf="contactDetailsExpanded">
                    <div fxLayout="row wrap" fxFlex>
                        <ng-template ngFor let-field [ngForOf]="contactDetails">
                            <div *ngIf="field.FieldType == 1 && field.Name == 'Notes'" class="client-input input-padding"
                                fxFlex="100" fxLayout="row">
                                <div fxFlex></div>
                                <div fxFlex="92" class="client-input" fxLayout="column">
                                    <span [innerText]="field.Name"></span>
                                    <dx-text-area *ngIf="editContactsFeature==1" [(value)]="field.Value"
                                        [autoResizeEnabled]="true"></dx-text-area>
                                    <dx-text-area *ngIf="editContactsFeature==0" [(value)]="field.Value"
                                        [autoResizeEnabled]="true" disabled="true"></dx-text-area>
                                </div>
                                <div fxFlex></div>
                            </div>
                            <div *ngIf="(field.FieldType==11)" class="client-input input-padding" fxFlex="50" fxLayout="row">
                                <div fxFlex></div>
                                <div fxFlex="84" class="client-input" fxLayout="column">
                                    <span [innerText]="field.Name"></span>
                                    <dx-text-box *ngIf="editContactsFeature==1" [(value)]="field.Value">
                                        <dx-validator>
                                            <dxi-validation-rule type="pattern" [pattern]="websiteRules" message="Must be a valid website"></dxi-validation-rule>
                                        </dx-validator>
                                    </dx-text-box>
                                    <dx-text-box *ngIf="editContactsFeature==0" disabled="true" [(value)]="field.Value"></dx-text-box>
                                </div>
                                <div fxFlex></div>
                            </div>
                            <div *ngIf="(field.FieldType==1 || field.FieldType==8 || field.FieldType==10) && field.Name != 'Notes'"
                                class="client-input input-padding" fxFlex="50" fxLayout="row">
                                <div fxFlex></div>
                                <div fxFlex="84" class="client-input" fxLayout="column">
                                    <span [innerText]="field.Name"></span>
                                    <dx-text-box *ngIf="editContactsFeature==1" [(value)]="field.Value"></dx-text-box>
                                    <dx-text-box *ngIf="editContactsFeature==0" [(value)]="field.Value" disabled="true"></dx-text-box>
                                </div>
                                <div fxFlex></div>
                            </div>
                            <div *ngIf="field.FieldType==2" class="client-input input-padding" fxFlex="50" fxLayout="row">
                                <div fxFlex="8"></div>
                                <div fxFlex="84" class="client-input" fxLayout="column">
                                    <span [innerText]="field.Name"></span>
                                    <dx-check-box *ngIf="editContactsFeature==1" [(value)]="field.Value"></dx-check-box>
                                    <dx-check-box *ngIf="editContactsFeature==0" [(value)]="field.Value" disabled="true"></dx-check-box>
                                </div>
                                <div fxFlex="8"></div>
                            </div>
                            <div *ngIf="field.FieldType==3" class="client-input input-padding" fxFlex="50" fxLayout="row">
                                <div fxFlex="8"></div>
                                <div fxFlex="84" class="client-input" fxLayout="column">
                                    <span [innerText]="field.Name"></span>
                                    <dx-number-box *ngIf="editContactsFeature==1" [(value)]="field.Value"></dx-number-box>
                                    <dx-number-box *ngIf="editContactsFeature==0" [(value)]="field.Value" disabled="true"></dx-number-box>
                                </div>
                                <div fxFlex="8"></div>
                            </div>
                            <div *ngIf="field.FieldType==4" class="client-input input-padding" fxFlex="50" fxLayout="row">
                                <div fxFlex="8"></div>
                                <div fxFlex="84" class="client-input" fxLayout="column">
                                    <span [innerText]="field.Name"></span>
                                    <dx-number-box *ngIf="editContactsFeature==1" format="$ #,##0.##" [(value)]="field.Value"></dx-number-box>
                                    <dx-number-box *ngIf="editContactsFeature==0" format="$ #,##0.##" [(value)]="field.Value"
                                        disabled="true"></dx-number-box>
                                </div>
                                <div fxFlex="8"></div>
                            </div>
                            <div *ngIf="field.FieldType==5" class="client-input input-padding" fxFlex="50" fxLayout="row">
                                <div fxFlex="8"></div>
                                <div fxFlex="84" class="client-input" fxLayout="column">
                                    <span [innerText]="field.Name"></span>
                                    <dx-select-box *ngIf="editContactsFeature==1" [(value)]="field.Value"></dx-select-box>
                                    <dx-select-box *ngIf="editContactsFeature==0" [(value)]="field.Value" disabled="true"></dx-select-box>
                                </div>
                                <div fxFlex="8"></div>
                            </div>
                            <div *ngIf="field.FieldType==6" class="client-input input-padding" fxFlex="50" fxLayout="row">
                                <div fxFlex="8"></div>
                                <div fxFlex="84" class="client-input" fxLayout="column">
                                    <span [innerText]="field.Name"></span>
                                    <dx-date-box *ngIf="editContactsFeature==1" [(value)]="field.Value" type="date"></dx-date-box>
                                    <dx-date-box *ngIf="editContactsFeature==0" [(value)]="field.Value" disabled="true"
                                        type="date"></dx-date-box>
                                </div>
                                <div fxFlex="8"></div>
                            </div>
                            <div *ngIf="field.FieldType==9" class="client-input input-padding" fxFlex="50" fxLayout="row">
                                <div fxFlex="8"></div>
                                <div fxFlex="84" class="client-input" fxLayout="column">
                                    <span [innerText]="field.Name"></span>
                                    <dx-text-box *ngIf="editContactsFeature==1" [(value)]="field.Value" mask="(000) 000-0000"
                                        [maskRules]="rules"></dx-text-box>
                                    <dx-text-box *ngIf="editContactsFeature==0" [(value)]="field.Value" mask="(000) 000-0000"
                                        [maskRules]="rules" disabled="true"></dx-text-box>
                                </div>
                                <div fxFlex="8"></div>
                            </div>
                            <div *ngIf="field.FieldType==12" class="client-input input-padding" fxFlex="50" fxLayout="row">
                                <div fxFlex="8"></div>
                                <div fxFlex="84" class="client-input" fxLayout="column">
                                    <span [innerText]="field.Name"></span>
                                    <dx-text-box *ngIf="editContactsFeature==1" [(value)]="field.Value"></dx-text-box>
                                    <dx-text-box *ngIf="editContactsFeature==0" [(value)]="field.Value" disabled="true"></dx-text-box>
                                </div>
                                <div fxFlex="8"></div>
                            </div>
                        </ng-template>
                    </div>
                </div>
                <div class="white-padding" fxLayout="row">
                    <div fxFlex="4"></div>
                    <div class="client-input edit-company-dropdown input-padding row-click popup-expanded-row" fxLayout="row"
                        (click)="expandCustomContactFields()" fxFlex>
                        <div fxFlex>Custom Fields</div>
                        <mat-icon *ngIf="!customContactFieldsExpanded" class="icon-large">arrow_drop_down</mat-icon>
                        <mat-icon *ngIf="customContactFieldsExpanded" class="icon-large">arrow_drop_up</mat-icon>
                    </div>
                    <div fxFlex="4"></div>
                </div>
                <div *ngIf="customContactFieldsExpanded">
                    <div fxLayout="row wrap" fxFlex>
                        <ng-template ngFor let-field [ngForOf]="contactCustomFields">
                            <div *ngIf="field.FieldType == 1 && field.Name == 'Notes'" class="client-input input-padding"
                                fxFlex="100" fxLayout="row">
                                <div fxFlex></div>
                                <div fxFlex="86" class="client-input" fxLayout="column">
                                    <span [innerText]="field.Name"></span>
                                    <dx-text-area *ngIf="editContactsFeature==1" [(value)]="field.Value"
                                        [autoResizeEnabled]="true"></dx-text-area>
                                    <dx-text-area *ngIf="editContactsFeature==0" [(value)]="field.Value"
                                        [autoResizeEnabled]="true" disabled="true"></dx-text-area>
                                </div>
                                <div fxFlex></div>
                            </div>
                            <div *ngIf="field.FieldType==11" class="client-input input-padding" fxFlex="50" fxLayout="row">
                                <div fxFlex></div>
                                <div fxFlex="84" class="client-input" fxLayout="column">
                                    <span [innerText]="field.Name"></span>
                                    <dx-text-box *ngIf="editContactsFeature==1" [(value)]="field.Value">
                                        <dx-validator>
                                            <dxi-validation-rule type="pattern" [pattern]="websiteRules" message="Must be a valid website"></dxi-validation-rule>
                                        </dx-validator>
                                        <dx-text-box *ngIf="editContactsFeature==0" [ disabled="true" [(value)]="field.Value"></dx-text-box>
                                    </dx-text-box>
                                </div>
                                <div fxFlex></div>
                            </div>
                            <div *ngIf="(field.FieldType==1 || field.FieldType==8 || field.FieldType==10) && field.Name != 'Notes'"
                                class="client-input input-padding" fxFlex="50" fxLayout="row">
                                <div fxFlex></div>
                                <div fxFlex="84" class="client-input" fxLayout="column">
                                    <span [innerText]="field.Name"></span>
                                    <dx-text-box *ngIf="editContactsFeature==1" [(value)]="field.Value"></dx-text-box>
                                    <dx-text-box *ngIf="editContactsFeature==0" [(value)]="field.Value" disabled="true"></dx-text-box>
                                </div>
                                <div fxFlex></div>
                            </div>
                            <div *ngIf="field.FieldType==2" class="client-input input-padding" fxFlex="50" fxLayout="row">
                                <div fxFlex="8"></div>
                                <div fxFlex="84" class="client-input" fxLayout="row" fxLayoutAlign="start center">
                                    <dx-check-box *ngIf="editContactsFeature==1" [(value)]="field.Value" fxLayoutAlign="start center"
                                        style="padding-right: 5px"></dx-check-box>
                                    <dx-check-box *ngIf="editContactsFeature==0" [(value)]="field.Value" fxLayoutAlign="start center"
                                        style="padding-right: 5px" disabled="true"></dx-check-box>
                                    <span [innerText]="field.Name" fxLayoutAlign="end center"></span>
                                </div>
                                <div fxFlex="8"></div>
                            </div>
                            <div *ngIf="field.FieldType==3" class="client-input input-padding" fxFlex="50" fxLayout="row">
                                <div fxFlex="8"></div>
                                <div fxFlex="84" class="client-input" fxLayout="column">
                                    <span [innerText]="field.Name"></span>
                                    <dx-number-box *ngIf="editContactsFeature==1" [(value)]="field.Value"></dx-number-box>
                                    <dx-number-box *ngIf="editContactsFeature==0" [(value)]="field.Value" disabled="true"></dx-number-box>
                                </div>
                                <div fxFlex="8"></div>
                            </div>
                            <div *ngIf="field.FieldType==4" class="client-input input-padding" fxFlex="50" fxLayout="row">
                                <div fxFlex="8"></div>
                                <div fxFlex="84" class="client-input" fxLayout="column">
                                    <span [innerText]="field.Name"></span>
                                    <dx-number-box *ngIf="editContactsFeature==1" format="$ #,##0.##" [(value)]="field.Value"></dx-number-box>
                                    <dx-number-box *ngIf="editContactsFeature==0" format="$ #,##0.##" [(value)]="field.Value"
                                        disabled="true"></dx-number-box>
                                </div>
                                <div fxFlex="8"></div>
                            </div>
                            <div *ngIf="field.FieldType==5" class="client-input input-padding" fxFlex="50" fxLayout="row">
                                <div fxFlex="8"></div>
                                <div fxFlex="84" class="client-input" fxLayout="column">
                                    <span [innerText]="field.Name"></span>
                                    <dx-select-box *ngIf="editContactsFeature==1" [(value)]="field.Value"></dx-select-box>
                                    <dx-select-box *ngIf="editContactsFeature==0" [(value)]="field.Value" disabled="true"></dx-select-box>
                                </div>
                                <div fxFlex="8"></div>
                            </div>
                            <div *ngIf="field.FieldType==6" class="client-input input-padding" fxFlex="50" fxLayout="row">
                                <div fxFlex="8"></div>
                                <div fxFlex="84" class="client-input" fxLayout="column">
                                    <span [innerText]="field.Name"></span>
                                    <dx-date-box *ngIf="editContactsFeature==1" [(value)]="field.Value" type="date"></dx-date-box>
                                    <dx-date-box *ngIf="editContactsFeature==0" [(value)]="field.Value" type="date"
                                        disabled="true"></dx-date-box>
                                </div>
                                <div fxFlex="8"></div>
                            </div>
                            <div *ngIf="field.FieldType==9" class="client-input input-padding" fxFlex="50" fxLayout="row">
                                <div fxFlex="8"></div>
                                <div fxFlex="84" class="client-input" fxLayout="column">
                                    <span [innerText]="field.Name"></span>
                                    <dx-text-box *ngIf="editContactsFeature==1" [(value)]="field.Value" mask="(000) 000-0000"
                                        [maskRules]="rules"></dx-text-box>
                                    <dx-text-box *ngIf="editContactsFeature==0" [(value)]="field.Value" mask="(000) 000-0000"
                                        [maskRules]="rules" disabled="true"></dx-text-box>
                                </div>
                                <div fxFlex="8"></div>
                            </div>
                            <div *ngIf="field.FieldType==12" class="client-input input-padding" fxFlex="50" fxLayout="row">
                                <div fxFlex="8"></div>
                                <div fxFlex="84" class="client-input" fxLayout="column">
                                    <span [innerText]="field.Name"></span>
                                    <dx-text-box *ngIf="editContactsFeature==1" [(value)]="field.Value"></dx-text-box>
                                    <dx-text-box *ngIf="editContactsFeature==0" [(value)]="field.Value" disabled="true"></dx-text-box>
                                </div>
                                <div fxFlex="8"></div>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </dx-scroll-view>
        </div>
        <div fxLayout="row" fxLayoutAlign="end end">
            <span *ngIf="contact.id && editContactsFeature==1" class="button button-red" (click)="deleteContactPopup=true">Delete</span>
            <span *ngIf="editContactsFeature==0" class="button button-white" (click)="closeContact()">Close</span>
            <div fxFlex fxLayoutAlign="end end">
                <span *ngIf="editContactsFeature==1" class="button button-white" (click)="cancelContact()">Cancel</span>
                <span *ngIf="editContactsFeature==1" class="button button-blue" (click)="contactUpdated(contact)">Save</span>
            </div>
        </div>
    </div>
</dx-popup>

<dx-popup [width]="410" [height]="150" [showTitle]="false" [dragEnabled]="false" [closeOnOutsideClick]="false"
    [(visible)]="deleteContactPopup" shadingColor="rgba(0,0,0,0.5)">
    <div *dxTemplate="let data of 'content'">
        <div fxLayout="column" fxFlex>
            <h3 fxLayoutAlign="center center">Are you sure you want to delete this contact?</h3>
            <div fxLayout="row" fxLayoutAlign="center center">
                <span class="button button-white" (click)="deleteContactPopup=false">No</span>
                <span class="button button-red" (click)="deleteContact(contact)">Yes</span>
            </div>
        </div>
    </div>
</dx-popup>

<dx-popup [width]="310" [height]="150" [showTitle]="false" [dragEnabled]="false" [closeOnOutsideClick]="false"
    [(visible)]="cancelConfirmationPopup" shadingColor="rgba(0,0,0,0.5)">
    <div *dxTemplate="let data of 'content'">
        <div fxLayout="column" fxFlex>
            <h3 fxLayoutAlign="center center">Are you sure you want to cancel?</h3>
            <div fxLayout="row" fxLayoutAlign="center center">
                <span class="button button-white" (click)="cancelConfirmationPopup=false">No</span>
                <span class="button button-red" (click)="contactCancelled()">Yes</span>
            </div>
        </div>
    </div>
</dx-popup>