import { ContactCardComponent } from './../contact-card/contact-card.component';
import {
  Component,
  Input,
  ViewChild,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Output,
  EventEmitter
} from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import { OpenDrawer, OpenErrorSnackbar } from 'src/app/store/layout/layout.actions';
import { CallNoteFormComponent } from 'src/app/view/shared/call-note-form/call-note-form.component';
import {
  SelectCallNoteId,
  SaveCallNoteComment,
  SaveCallNote
} from 'src/app/store/call-notes/call-notes.actions';
import { FormControl, FormGroup } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { GenericMap } from 'src/app/models/generic-map';
import { GenericApi } from 'src/app/models/generic-api';
import { DropdownOption } from 'src/app/models/dropdown-option';
import { CallNote } from 'src/app/models/call-notes/call-note';
import { DomSanitizer } from '@angular/platform-browser';
import { selectProjectParamId } from 'src/app/store/projects/selectors/current-project.selector';
import { selectOpportunityId } from 'src/app/store/opportunities/selectors/current-opportunity.selector';
import { CompanyCallNoteDialog } from '../../pages/projects/project-call-note/company-call-note-dialogue';
import { ProjectCallNoteCommentDialogComponent } from '../../pages/projects/project-call-note/project-call-note-comment-dialog/project-call-note-comment-dialog.component';
import { isSameDay, isSameYear } from 'date-fns';
import { CompaniesService } from 'src/app/services/companies/companies.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Company } from 'src/app/models/companies/company';
import { Contact } from 'src/app/models/contacts/contact';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CallNoteField } from 'src/app/models/call-notes/call-note-field';
import { CallNotesService } from 'src/app/services/call-notes/call-notes.service';
import { AudioListenComponent } from '../audio-listen/audio-listen.component';
import { File } from 'src/app/app-v2/shared/models/files/file';
import { ImageDialogComponent } from '../image-dialog/image-dialog.component';

@Component({
  selector: 'tn-call-note-card',
  templateUrl: './call-note-card.component.html',
  styleUrls: ['./call-note-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CallNoteCardComponent implements OnInit, OnDestroy {
  @Input() showCompany = true;
  @Input() callNote: CallNote;
  @Input() inFollowUpList = false;
  @Output() completeCheckbox: EventEmitter<{ id: number; checked: boolean }> =
    new EventEmitter();

  $dropdownOptions: Observable<GenericMap<GenericApi<GenericMap<DropdownOption>>>>;
  $dropdownSub: Subscription;
  dropdowns: GenericMap<DropdownOption> = {};
  $projId: Observable<number>;
  $oppId: Observable<number>;

  removed = false;

  commentsForm: FormGroup = new FormGroup({
    callNoteID: new FormControl(),
    value: new FormControl()
  });

  showCommentsButton = true;

  isSameYear = true;
  isSameDay = false;

  contactInfoLoaded = false;
  contactInfoPending = false;
  contactInfoError: HttpErrorResponse;
  showAudioFile: boolean = false;
  imageUrl: string;
  imageSub$: Subscription;
  // TODO: remove when web recording is implemented
  webRecording: boolean = false;

  @ViewChild('commentsPanel', { static: true }) commentsPanel: MatExpansionPanel;
  constructor(
    private store: Store<RootState>,
    public dialog: MatDialog,
    private compService: CompaniesService,
    private cdRef: ChangeDetectorRef,
    private cnService: CallNotesService
  ) {}

  ngOnInit() {
    // TODO: remove when web recording is implemented
    this.store
      .select('auth', 'data', 'api', 'Role', 'Features', 4, 'Permissions')
      .subscribe((permissions: number) => {
        if (
          (permissions != null || undefined) &&
          Object.keys(permissions) &&
          permissions[163]?.Value === 'true'
        ) {
          this.webRecording = true;
        }
      });

    if (
      isSameDay(
        new Date(this.callNote.followUp.dueDateStart),
        new Date(this.callNote.followUp.dueDateEnd)
      )
    ) {
      this.isSameDay = true;
    }
    this.isSameYear = isSameYear(new Date(), new Date(this.callNote.callDate));
    this.$projId = this.store.select(selectProjectParamId);
    this.$oppId = this.store.select(selectOpportunityId);
    this.commentsForm.controls.callNoteID.patchValue(this.callNote.id);

    if (this.callNote.comments && this.callNote.comments.length) {
      this.callNote.comments = this.callNote.comments.sort((a, b) =>
        a.createdDate > b.createdDate ? 1 : -1
      );
    }
    this.$dropdownOptions = this.store.select('callNoteCustomFields', 'dropdownOptions');
    this.$dropdownSub = this.$dropdownOptions.subscribe(
      (dropdowns: GenericMap<GenericApi<GenericMap<DropdownOption>>>) => {
        this.dropdowns = {
          ...this.dropdowns,
          ...Object.keys(dropdowns).reduce((resultObj, currId) => {
            resultObj = {
              ...resultObj,
              ...dropdowns[currId].data
            };
            return resultObj;
          }, {})
        };
      }
    );
    this.imageSub$ = this.cnService
      .getFileByIdForCallNote(this.callNote.id)
      .subscribe((file: any) => {
        this.imageUrl = file[0]?.downloadUrl;
      });
    this.loadContactInfo();
  }

  ngOnDestroy() {
    this.$dropdownSub.unsubscribe();
    this.imageSub$.unsubscribe();
  }

  editHandler() {
    this.store.dispatch(new SelectCallNoteId(this.callNote.id));
    this.store.dispatch(
      new OpenDrawer({
        component: CallNoteFormComponent,
        data: { callnoteId: this.callNote.id }
      })
    );
  }

  deleteHandler(callNote) {
    const dialogRef = this.dialog.open(CompanyCallNoteDialog, {
      width: '250px',
      data: callNote
    });
  }

  commentHandler(callNote) {
    const dialogRef = this.dialog.open(ProjectCallNoteCommentDialogComponent, {
      width: '500px',
      data: callNote
    });
  }

  onCommentSubmit() {
    if (!!this.commentsForm.controls.value.value) {
      this.commentsPanel.close();
      this.store.dispatch(new SaveCallNoteComment(this.commentsForm.value));
    }
  }

  loadContactInfo() {
    if (!this.contactInfoLoaded) {
      this.contactInfoPending = true;
      firstValueFrom(this.compService.getCompanyById(this.callNote.company.id))
        .then((company: Company) => {
          this.callNote.company.phone = company.phone;
          this.callNote.company.alternatePhone = company.alternatePhone;
          this.callNote.contacts = this.callNote.contacts.map((cnContact: Contact) => {
            return (
              company.contacts.find((contact) => contact.id === cnContact.id) ||
              company.contacts.find(
                (contact) => this.displayName(contact) === cnContact.name
              ) ||
              cnContact
            );
          });
        })
        .then(() => {
          this.contactInfoLoaded = true;
          this.contactInfoPending = false;
          this.cdRef.detectChanges();
        })
        .catch((error) => {
          this.contactInfoError = error;
          this.contactInfoPending = false;
          this.cdRef.detectChanges();
        });
    }
  }

  handleCheckbox(event: MatCheckboxChange) {
    firstValueFrom(
      this.cnService.update({
        ...this.callNote,
        fields: this.callNote.fields.map((field) => {
          return {
            field: {
              id: field.id
            },
            value: field.value
          } as unknown as CallNoteField;
        }),
        followUp: {
          ...this.callNote.followUp,
          complete: event.checked,
          completedDate: event.checked ? new Date() : null
        }
      })
    )
      .then((res) => (this.callNote.followUp.complete = res.followUp.complete))
      .catch((error: HttpErrorResponse) =>
        this.store.dispatch(
          new OpenErrorSnackbar({
            duration: 4000,
            message:
              'An error occurred while saving this follow-up: ' + error.status.toString(),
            opened: true
          })
        )
      );
  }

  undoComplete() {
    firstValueFrom(
      this.cnService.update({
        ...this.callNote,
        fields: this.callNote.fields.map((field) => {
          return {
            field: {
              id: field.id
            },
            value: field.value
          } as unknown as CallNoteField;
        }),
        followUp: {
          ...this.callNote.followUp,
          complete: false,
          completedDate: null
        }
      })
    )
      .then((res) => (this.callNote.followUp.complete = res.followUp.complete))
      .catch((error: HttpErrorResponse) =>
        this.store.dispatch(
          new OpenErrorSnackbar({
            duration: 4000,
            message:
              'An error occurred while saving this follow-up: ' + error.status.toString(),
            opened: true
          })
        )
      );
  }

  clearFollowUp() {
    // this.cmpRef.destroy();
    this.removed = true;
  }

  displayName(contact: Contact) {
    const first = contact.firstName ? contact.firstName : '';
    const last = contact.lastName ? contact.lastName : '';
    return first + ' ' + last;
  }

  openDialog(contact: Contact) {
    const dialogRef = this.dialog.open(ContactCardComponent, {
      width: 'fit-content',
      minWidth: '450px',
      data: contact
    });
  }

  openFullImage(imageUrl: string) {
    this.dialog.open(ImageDialogComponent, {
      data: {
        // isPortrait:
        imageUrl: imageUrl,
        narrative: this.callNote.fields[0].value
      }
    });
  }
}
