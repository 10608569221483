import {
  CompanyLabelsIndividualActions,
  CompanyLabelsIndividualActionTypes
} from './company-labels-individual.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { Label } from 'src/app/models/label';

export interface CompanyLabelsIndividualState {
  pending: boolean;
  error: HttpErrorResponse;
  data: { [id: number]: Label };
  selectedCompanyLabelIndividualId: number;
}

export const initialState: CompanyLabelsIndividualState = {
  pending: false,
  data: {},
  error: undefined,
  selectedCompanyLabelIndividualId: undefined
};

export function companyLabelsIndividualReducer(
  state = initialState,
  action: CompanyLabelsIndividualActions
): CompanyLabelsIndividualState {
  switch (action.type) {
    case CompanyLabelsIndividualActionTypes.SelectCompanyLabelIndividualId:
      return {
        ...state,
        selectedCompanyLabelIndividualId: action.payload
      };

    case CompanyLabelsIndividualActionTypes.ClearCompanyLabelIndividualId:
      return {
        ...state,
        selectedCompanyLabelIndividualId: undefined
      };

    case CompanyLabelsIndividualActionTypes.LoadCompanyLabelsIndividual:
      return {
        ...state,
        pending: true
      };

    case CompanyLabelsIndividualActionTypes.LoadCompanyLabelsIndividualSuccess:
      return {
        ...state,
        error: undefined,
        pending: false,
        data: action.payload
      };

    case CompanyLabelsIndividualActionTypes.LoadCompanyLabelsIndividualFailure:
      return {
        ...state,
        pending: false,
        data: {},
        error: action.payload
      };

    case CompanyLabelsIndividualActionTypes.CreateCompanyLabelsIndividual:
      return {
        ...state,
        pending: true
      };

    case CompanyLabelsIndividualActionTypes.CreateCompanyLabelsIndividualSuccess:
      return {
        ...state,
        pending: false
      };

    case CompanyLabelsIndividualActionTypes.CreateCompanyLabelsIndividualFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case CompanyLabelsIndividualActionTypes.UpdateCompanyLabelsIndividual:
      return {
        ...state,
        pending: true
      };

    case CompanyLabelsIndividualActionTypes.UpdateCompanyLabelsIndividualSuccess:
      return {
        ...state,
        pending: false
      };

    case CompanyLabelsIndividualActionTypes.UpdateCompanyLabelsIndividualFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case CompanyLabelsIndividualActionTypes.DeleteCompanyLabelsIndividual:
      return {
        ...state,
        pending: true
      };

    case CompanyLabelsIndividualActionTypes.DeleteCompanyLabelsIndividualSuccess:
      return {
        ...state,
        pending: false,
        selectedCompanyLabelIndividualId: undefined
      };

    case CompanyLabelsIndividualActionTypes.DeleteCompanyLabelsIndividualFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    default:
      return state;
  }
}
