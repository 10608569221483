import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'tn-confirm-delete',
  template: `
    <h3 mat-dialog-title>
      {{
        !!data && !!data.confirmHeader
          ? data.confirmHeader
          : 'Are you sure you want to delete this?'
      }}
    </h3>
    <div>{{ !!data && !!data.confirmMessage ? data.confirmMessage : '' }}</div>
    <div mat-dialog-actions fxLayoutAlign="end center" [style.paddingBottom]="'24px'">
      <button mat-raised-button color="white" (click)="onNoClick()">Cancel</button>
      <button mat-raised-button color="warn" (click)="onYesClick()" cdkFocusInitial>
        Delete
      </button>
    </div>
  `,
  styles: []
})
export class ConfirmDeleteComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDeleteComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { confirmHeader?: string; confirmMessage?: string }
  ) {}

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick() {
    this.dialogRef.close(true);
  }
}
