export function deepFlattenArray<T>(arr: T[], prop: string): T[] {
  let a: T[] = [];
  for (var i = 0; i < arr?.length; i++) {
    if (arr[i][prop]) {
      a = a.concat(deepFlattenArray(arr[i][prop], prop));
    }
    a.push(arr[i]);
  }
  return a;
}
