<div class="add-client-form">
  <h3 *ngIf="!currentClient?.id">New Client</h3>
  <h3 *ngIf="currentClient?.id">Edit {{ currentClient.name }}</h3>

  <form [formGroup]="clientForm" class="example-form">
    <mat-form-field appearance="fill" class="example-full-width">
      <mat-label>Client Name</mat-label>
      <input
        [value]="currentClient?.name"
        formControlName="name"
        matInput
        type="string"
      />
    </mat-form-field>

    <mat-form-field appearance="fill" class="example-full-width">
      <mat-label>Primary Contact</mat-label>
      <input
        [value]="currentClient?.primaryContact"
        formControlName="primaryContact"
        matInput
        type="string"
      />
    </mat-form-field>

    <mat-form-field appearance="fill" class="example-full-width">
      <mat-label>Primary Phone</mat-label>
      <input
        [value]="currentClient?.primaryPhone"
        formControlName="primaryPhone"
        matInput
        type="string"
      />
    </mat-form-field>

    <div formGroupName="address">
      <mat-form-field appearance="fill" class="example-full-width">
        <mat-label>Address</mat-label>
        <input
          [value]="currentClient?.address?.addressLine1"
          formControlName="addressLine1"
          matInput
          type="string"
        />
      </mat-form-field>

      <mat-form-field appearance="fill" class="example-full-width">
        <mat-label>Additional Address Information</mat-label>
        <input
          [value]="currentClient?.address?.addressLine2"
          formControlName="addressLine2"
          matInput
          type="string"
        />
      </mat-form-field>

      <mat-form-field appearance="fill" class="example-full-width">
        <mat-label>City</mat-label>
        <input
          [value]="currentClient?.address?.city"
          formControlName="city"
          matInput
          type="string"
        />
      </mat-form-field>

      <mat-form-field appearance="fill" class="example-full-width">
        <mat-label>State</mat-label>
        <input
          [value]="currentClient?.address?.state"
          formControlName="state"
          matInput
          type="string"
        />
      </mat-form-field>

      <mat-form-field appearance="fill" class="example-full-width">
        <mat-label>Postal Code</mat-label>
        <input
          [value]="currentClient?.address?.postCode"
          formControlName="postCode"
          matInput
          type="string"
        />
      </mat-form-field>
    </div>

    <mat-form-field appearance="fill" class="example-full-width">
      <mat-label>Plan</mat-label>
      <mat-select [value]="currentClient?.planId" formControlName="planId">
        <mat-option *ngFor="let plan of plans" [value]="plan.id">
          {{ plan.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="example-full-width">
      <mat-label>Start Date</mat-label>
      <input
        [value]="currentClient?.startDate"
        formControlName="startDate"
        matInput
        type="string"
      />
    </mat-form-field>

    <div fxLayout="row-reverse" fxLayoutAlign="space-between center">
      <div fxLayout="row-reverse">
        <button
          *ngIf="!currentClient?.id"
          mat-raised-button
          mat-button
          color="primary"
          class="button-container"
          (click)="save()"
          [disabled]="clientForm.pristine || clientForm.invalid"
        >
          Save
        </button>
        <button
          *ngIf="currentClient?.id"
          mat-raised-button
          mat-button
          color="primary"
          class="button-container"
          (click)="update()"
          [disabled]="clientForm.pristine || clientForm.invalid"
        >
          Save
        </button>
        <button
          preventDefaultAction
          class="button-container"
          mat-raised-button
          mat-button
          color="white"
          (click)="cancel()"
        >
          Cancel
        </button>
      </div>
      <div>
        <button
          *ngIf="currentClient?.id"
          preventDefaultAction
          class="button-container"
          mat-raised-button
          mat-button
          color="primary"
          (click)="syncToElastic()"
        >
          Sync to Elastic
        </button>
      </div>
    </div>
  </form>
</div>
