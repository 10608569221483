import { ClearCompanies } from './../../../store/companies/companies.actions';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import { GlobalSearchState } from 'src/app/store/global-search/global-search.reducer';
import { Subscription } from 'rxjs';
import { CompanyIdNameItem } from 'src/app/models/companies/company-id-name-item';
import { CallNoteIdNameItem } from 'src/app/models/call-notes/call-note-id-name-item';
import { ContactIdNameItem } from 'src/app/models/contacts/contact-id-name-item';
import { CloseLeftDrawer, OpenDrawer } from 'src/app/store/layout/layout.actions';
import { SelectCallNoteId } from 'src/app/store/call-notes/call-notes.actions';
import { CallNoteFormComponent } from '../call-note-form/call-note-form.component';
import { SelectCompanyAndContactId } from 'src/app/store/companies/companies.actions';
import { LoadContact } from 'src/app/store/contacts/contacts.actions';
import { ContactSlideOutFormComponent } from '../contact-slide-out-form/contact-slide-out-form.component';
import { Router } from '@angular/router';

@Component({
  selector: 'tn-global-search-results',
  templateUrl: './global-search-results.component.html',
  styleUrls: ['./global-search-results.component.scss']
})
export class GlobalSearchResultsComponent implements OnInit, OnDestroy {
  searchResultsSub: Subscription;
  searchTerm: string;
  companiesResults: CompanyIdNameItem[] = [];
  totalCompanies: number;
  contactsResults: ContactIdNameItem[] = [];
  totalContacts: number;
  callNotesResults: CallNoteIdNameItem[] = [];
  totalCallNotes: number;
  totalResults: number;

  constructor(private store: Store<RootState>, private router: Router) {}

  ngOnInit() {
    this.searchResultsSub = this.store.select('globalSearch').subscribe({
      next: (globalSearch: GlobalSearchState) => {
        this.searchTerm = globalSearch.searchTerm;
        this.totalCompanies = globalSearch.data.companies.totalMatches;
        this.companiesResults = Object.values(globalSearch.data.companies.data);
        this.totalContacts = globalSearch.data.contacts.totalMatches;
        this.contactsResults = Object.values(globalSearch.data.contacts.data);
        this.contactsResults = this.contactsResults.filter((el) => {
          return el.name;
        });
        this.totalCallNotes = globalSearch.data.callNotes.totalMatches;
        this.callNotesResults = Object.values(globalSearch.data.callNotes.data);
        this.totalResults =
          this.totalCompanies + this.totalContacts + this.totalCallNotes;
      },
      error: () => {}
    });
  }

  ngOnDestroy() {
    this.searchResultsSub.unsubscribe();
  }

  closeLeftDrawer() {
    this.store.dispatch(new CloseLeftDrawer());
  }

  handleCompanyClick(companyId: number) {
    this.store.dispatch(new ClearCompanies());
    this.router.navigate(['/companies/company', companyId]);
    this.closeLeftDrawer();
  }

  handleCallNoteClick(callNoteId: number) {
    this.store.dispatch(new SelectCallNoteId(callNoteId));
    this.store.dispatch(
      new OpenDrawer({
        component: CallNoteFormComponent,
        data: { callnoteId: callNoteId }
      })
    );
  }

  handleContactClick(contact: ContactIdNameItem) {
    this.store.dispatch(
      new SelectCompanyAndContactId({
        companyId: contact.company.id,
        contactId: contact.id
      })
    );
    this.store.dispatch(
      new LoadContact({ companyId: contact.company.id, contactId: contact.id })
    );
    this.store.dispatch(
      new OpenDrawer({
        component: ContactSlideOutFormComponent,
        data: {
          company: {
            id: contact.company.id,
            name: contact.company.name,
            address: ''
          },
          hasCompanyInput: true
        }
      })
    );
  }
}
