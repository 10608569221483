import { HttpErrorResponse } from '@angular/common/http';
import { CallNote } from 'src/app/models/call-notes/call-note';
import {
  DailyActivitiesActions,
  DailyActivitiesActionTypes
} from './daily-activities.actions';

export interface DailyActivitiesState {
  pending: boolean;
  error: HttpErrorResponse;
  data;
  repCallNotes;
  callTypeCallNotes;
  callFieldCallNotes;
}

export const initialState: DailyActivitiesState = {
  pending: false,
  error: undefined,
  data: {},
  repCallNotes: {},
  callTypeCallNotes: {},
  callFieldCallNotes: {}
};

export function dailyActivitiesReducer(
  state = initialState,
  action: DailyActivitiesActions
): DailyActivitiesState {
  switch (action.type) {
    case DailyActivitiesActionTypes.LoadDailyActivity:
      return {
        ...state,
        pending: true
      };

    case DailyActivitiesActionTypes.LoadDailyActivitySuccess:
      return {
        ...state,
        error: undefined,
        pending: false,
        data: action.payload
      };

    case DailyActivitiesActionTypes.LoadDailyActivityFailure:
      return {
        ...state,
        pending: true
      };

    case DailyActivitiesActionTypes.LoadCallNotesByRep:
      return {
        ...state,
        pending: true
      };

    case DailyActivitiesActionTypes.LoadCallNotesByRepSuccess:
      return {
        ...state,
        error: undefined,
        pending: false,
        repCallNotes: action.payload
      };

    case DailyActivitiesActionTypes.LoadCallNotesByRepFailure:
      return {
        ...state,
        pending: true
      };

    case DailyActivitiesActionTypes.LoadCallNotesByCallType:
      return {
        ...state,
        pending: true
      };

    case DailyActivitiesActionTypes.LoadCallNotesByCallTypeSuccess:
      return {
        ...state,
        error: undefined,
        pending: false,
        callTypeCallNotes: action.payload
      };

    case DailyActivitiesActionTypes.LoadCallNotesByCallTypeFailure:
      return {
        ...state,
        pending: true
      };

    case DailyActivitiesActionTypes.LoadCallNotesByCallField:
      return {
        ...state,
        pending: true
      };

    case DailyActivitiesActionTypes.LoadCallNotesByCallFieldSuccess:
      return {
        ...state,
        error: undefined,
        pending: false,
        callFieldCallNotes: action.payload
      };

    case DailyActivitiesActionTypes.LoadCallNotesByCallFieldFailure:
      return {
        ...state,
        pending: true
      };

    default:
      return state;
  }
}
