import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { RootState } from 'src/app/store/store.reducer';
import { Store } from '@ngrx/store';
import { Expense } from 'src/app/models/activities/expense';
import { Observable, Subscription } from 'rxjs';
import { selectSelectedExpense } from 'src/app/store/expenses/selectors/selected-expense.selector';
import {
  UploadExpenseFile,
  DeleteExpenseFile
} from 'src/app/store/expenses/expenses.actions';
import { FileMetaData } from 'src/app/models/file-meta-data';

@Component({
  selector: 'tn-expense-files',
  templateUrl: './expense-files.component.html',
  styleUrls: ['./expense-files.component.scss']
})
export class ExpenseFilesComponent implements OnInit, OnDestroy {
  $expenseSub: Subscription;
  expense: Expense;
  searchText = '';

  @ViewChild('fileUpload', { static: true }) fileUpload;
  constructor(private store: Store<RootState>) {}

  ngOnInit() {
    this.$expenseSub = this.store
      .select(selectSelectedExpense)
      .subscribe((expense: Expense) => {
        this.expense = expense;
      });
  }

  ngOnDestroy() {
    this.$expenseSub.unsubscribe();
  }

  onFilesAdded() {
    const files: { [key: string]: File } = this.fileUpload.nativeElement.files;
    for (let key in files) {
      if (!isNaN(parseInt(key, 10))) {
        this.store.dispatch(new UploadExpenseFile(files[key]));
      }
    }
  }

  addFiles() {
    this.fileUpload.nativeElement.click();
  }

  sortBy(prop: string) {
    return !!this.expense
      ? this.expense.files.sort((a, b) =>
          a[prop] < b[prop] ? 1 : a[prop] === b[prop] ? -1 : 0
        )
      : [];
  }

  onDelete(fileId: number) {
    this.store.dispatch(new DeleteExpenseFile(fileId));
  }
}
