import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Subject, of, Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { Feature } from '../models/admin/feature';
import { Role } from '../models/admin/roles/role';
import { Region } from '../models/region';
import { SalesSettings } from '../models/sales/SalesSettings';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import { Reauthenticate } from 'src/app/store/auth/auth.actions';
import { AuthModule } from 'src/app/services/auth/auth.module';
import { AuthenticationResponse } from 'src/app/models/auth/authentication-response';
import { FileMetaData } from 'src/app/models/file-meta-data';

@Injectable({
  providedIn: AuthModule
})
export class AuthenticationService implements CanActivate {
  public modules: any = {
    Companies: 'Companies',
    Activity: 'Activity',
    Sales: 'Sales',
    Maps: 'Maps',
    Admin: 'Admin',
    Apps: 'Apps',
    Excede: 'Excede'
  };
  salesSettings: SalesSettings;
  regions: Region;

  private apiUrl: string = environment.apiUrl;
  private jpiUrl: string = environment.jpiUrl;
  private moduleAnnouncer = new Subject<Feature[]>();
  private userAnnouncer = new Subject<string>();

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private fileHeaders = new HttpHeaders();
  private role: Role;
  private permissions: any;
  private apiAuth: AuthenticationResponse;
  private jpiToken: string;
  private inflightUrl: string;

  public cookieNames = {
    NewCustomFields: 'NCF',
    JpiToken: 'JpiToken',
    RoleId: 'RoleId',
    NewTelenotes: 'NewTelenotes',
    AuthToken: 'AuthToken',
    AgencyId: 'AgencyId',
    AgentId: 'AgentId',
    AgentMailbox: 'Mailbox',
    FullName: 'FullName',
    Snid: 'Snid',
    DefaultUrl: 'DefaultUrl',
    Email: 'Email'
  };
  public defaultValidHours: number = 12;

  moduleAnnounced = this.moduleAnnouncer.asObservable();
  userAnnounced = this.userAnnouncer.asObservable();

  signInError: number;

  constructor(
    private _router: Router,
    private _http: HttpClient,
    private store: Store<RootState>
  ) {
    this.store.select('auth').subscribe(auth => {
      this.apiAuth = (auth.data.api as unknown) as AuthenticationResponse;
      this.jpiToken = !!auth.data.jpi ? auth.data.jpi.token : null;
      this.inflightUrl = auth.inflightUrl;
    });
  }

  login(mailbox: string, password: string): Promise<boolean> {
    let body: any;
    let jpaBody: any;
    if (isNaN(<any>mailbox)) {
      body = { mailbox: mailbox, password: password };
      jpaBody = { email: mailbox, password: password };
    } else {
      body = { mailbox: parseInt(mailbox), password: password };
      jpaBody = { mailbox: parseInt(mailbox), password: password };
    }

    return Promise.all([
      this._http
        .post(this.apiUrl + '/api/security/AuthenticateWebUser', JSON.stringify(body), {
          headers: this.headers
        })
        .toPromise(),
      this.authenticateUser(jpaBody)
    ]).then(
      (result: any) => {
        const data = result[0] as AuthenticationResponse;
        // clean default route
        if (data.DefaultUrl.startsWith('/')) {
          data.DefaultUrl = data.DefaultUrl.substr(1, data.DefaultUrl.length);
        }
        this.addCookie(
          this.cookieNames.AuthToken,
          data.ApiAuthToken,
          this.defaultValidHours
        );
        this.addCookie(
          this.cookieNames.AgencyId,
          data.AgencyId.toString(),
          this.defaultValidHours
        );
        this.addCookie(
          this.cookieNames.AgentId,
          data.AgentId.toString(),
          this.defaultValidHours
        );
        this.addCookie(
          this.cookieNames.AgentMailbox,
          data.Mailbox.toString(),
          this.defaultValidHours
        );
        this.addCookie(this.cookieNames.FullName, data.FullName, this.defaultValidHours);
        this.addCookie(this.cookieNames.Snid, data.Snid, this.defaultValidHours);
        this.addCookie(
          this.cookieNames.DefaultUrl,
          data.DefaultUrl,
          this.defaultValidHours
        );
        this.addCookie(this.cookieNames.Email, data.Email, this.defaultValidHours);
        this.addCookie(
          this.cookieNames.NewTelenotes,
          data.UseNewTelenotes.toString(),
          this.defaultValidHours
        );
        this.addCookie(
          this.cookieNames.RoleId,
          data.RoleId.toString(),
          this.defaultValidHours
        );
        this.addCookie(
          this.cookieNames.NewCustomFields,
          result[1].user.client.useNewCustomFields,
          this.defaultValidHours
        );

        this.role = result[0].Role;
        this.getVisibleFeatures().then(features => this.moduleAnnouncer.next(features));

        this.userAnnouncer.next(this.apiAuth.FullName);

        return true;
      },
      e => {
        this.signInError = e.status;
        return false;
      }
    );
  }

  authenticateUser(jpaBody): Promise<any> {
    return this._http
      .post(this.jpiUrl + '/users/authenticate', JSON.stringify(jpaBody), {
        headers: this.headers
      })
      .toPromise()
      .then(result => {
        this.addCookie(
          this.cookieNames.JpiToken,
          (<any>result).token,
          this.defaultValidHours
        );
        this.regions = (<any>result).user.regions;
        return result;
      });
  }

  logout(): void {
    this.clearCookies();
    // this.makeRequest('/api-v1.2.1/security/classicLogout?snid=' + this.getCookie('Snid'), '', 'get');
    window.location.href = 'https://' + environment.classicUrl + '/logout.aspx';
  }

  isSignedIn(): boolean {
    const agentId = this.apiAuth.AgentId;
    const authToken = this.apiAuth.ApiAuthToken;

    if (authToken && agentId) {
      return true;
    } else {
      return false;
    }
  }

  getRegions(): Promise<Region> {
    if (this.regions) {
      return Promise.resolve(this.regions);
    } else {
      return this.makeJpiRequest('/territories', null, null, 'get').then(result => {
        this.regions = JSON.parse(result);
        return JSON.parse(result);
      });
    }
  }

  getAssignedRegions(): number[] {
    var index = 1;
    if (this.jpiToken.substring(2, 1) == '.') {
      index = 2;
    }
    const token = JSON.parse(JSON.parse(atob(this.jpiToken.split('.')[index])).User);
    return token.regions;
  }
  getAssignedRegionsAndChildRegions(): number[] {
    var index = 1;
    if (this.jpiToken.substring(2, 1) == '.') {
      index = 2;
    }
    const token = JSON.parse(JSON.parse(atob(this.jpiToken.split('.')[index])).User);
    return token.regions.concat(token.subRegions);
  }

  getRegionsForClient(agencyId: number): Promise<Region> {
    return this.makeJpiRequest(
      '/territories?agencyId=' + agencyId,
      null,
      null,
      'get'
    ).then(result => {
      return JSON.parse(result);
    });
  }

  getVisibleFeatures(): Promise<Feature[]> {
    if (!this.role) {
      if (this.isSignedIn() && this.apiAuth.RoleId != 0) {
        return this.makeRequest(
          '/api-v1.2.1/admin/role?roleId=' + this.apiAuth.RoleId,
          null,
          'get'
        ).then(result => {
          this.role = JSON.parse(result);
          return this.getVisibleFeatures();
        });
      } else {
        return Promise.resolve([]);
      }
    }

    const features: Feature[] = [];

    if (this.role.Features) {
      for (let i = 0; i < this.role.Features.length; i++) {
        switch (this.role.Features[i].Name) {
          case this.modules.Companies:
            features.push(this.role.Features[i]);
            break;
          case this.modules.Activity:
            features.push(this.role.Features[i]);
            break;
          case this.modules.Sales:
            for (let j = 0; j < this.role.Features[i].Permissions.length; j++) {
              if (this.role.Features[i].Permissions[j].Name == 'View Sales') {
                if (parseInt(this.role.Features[i].Permissions[j].Value) != 0) {
                  features.push(this.role.Features[i]);
                }
                break;
              }
            }
            break;
          case this.modules.Maps:
            for (let j = 0; j < this.role.Features[i].Permissions.length; j++) {
              if (this.role.Features[i].Permissions[j].Value != 'false') {
                features.push(this.role.Features[i]);
                break;
              }
            }
            break;
          case this.modules.Admin:
            for (let j = 0; j < this.role.Features[i].Permissions.length; j++) {
              if (parseInt(this.role.Features[i].Permissions[j].Value) != 0) {
                features.push(this.role.Features[i]);
                break;
              }
            }
            break;
          default:
            features.push(this.role.Features[i]);
        }
      }
    }
    return Promise.resolve(features);
  }

  getFeature(featureName: string): Promise<Feature> {
    if (!this.role) {
      if (this.isSignedIn() && this.apiAuth.RoleId != 0) {
        return this.makeRequest(
          '/api-v1.2.1/admin/role?roleId=' + this.apiAuth.RoleId,
          null,
          'get'
        ).then(result => {
          this.role = JSON.parse(result);
          this.getVisibleFeatures().then(result => {
            this.moduleAnnouncer.next(result);
          });
          return this.getFeature(featureName);
        });
      } else {
        return Promise.resolve(null);
      }
    }

    if (this.role.Features) {
      for (let i = 0; i < this.role.Features.length; i++) {
        if (this.role.Features[i].Name == featureName) {
          return Promise.resolve(this.role.Features[i]);
        }
      }
    }

    return Promise.resolve(null);
  }

  addCookie(key: string, value: string, validHours: number): void {
    const expires = new Date();
    expires.setHours(expires.getHours() + validHours);

    const cookieString =
      key + '=' + value + ';expires=' + expires.toUTCString() + ';path=/';
    document.cookie = cookieString;
  }

  getCookie(key: string): string {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const temp = cookies[i].split('=');
      if (temp && key == temp[0].trim()) {
        return temp[1];
      }
    }
    return '';
  }

  clearCookies(): void {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const temp = cookies[i].split('=');
      if (temp) {
        this.addCookie(temp[0], '', -1);
      }
    }
  }

  getDefaultRoute(): string {
    return '/';
  }

  getUserName(): string {
    return this.isSignedIn() ? this.apiAuth.FullName : '';
  }

  makeJpiRequest(url: string, params: any, body: any, method: string): Promise<any> {
    if (!this.isSignedIn()) {
      this._router.navigate(['/auth/login']);
    }

    switch (method.toLowerCase()) {
      case 'get':
        return this._http
          .get(this.jpiUrl + url, {
            headers: this.headers.append('Authorization', this.jpiToken),
            responseType: 'text',
            params: params
          })
          .toPromise();
      case 'post':
        return this._http
          .post(this.jpiUrl + url, body, {
            headers: this.headers.append('Authorization', this.jpiToken),
            responseType: 'text',
            params: params
          })
          .toPromise();
      case 'put':
        return this._http
          .put(this.jpiUrl + url, body, {
            headers: this.headers.append('Authorization', this.jpiToken),
            responseType: 'text',
            params: params
          })
          .toPromise();
      case 'delete':
        return this._http
          .delete(this.jpiUrl + url, <any>{
            headers: this.headers.append('Authorization', this.jpiToken),
            responseType: 'text',
            body: body,
            params: params
          })
          .toPromise();
    }
  }

  makeSyncRequest(agencyId: number) {
    if (!this.isSignedIn()) {
      this._router.navigate(['/auth/login']);
    }

    return this._http
      .post(
        environment.elasticSyncUrl +
          '/sync/' +
          agencyId +
          '?dev=' +
          environment.production,
        null,
        {
          headers: this.headers.append('Authorization', this.jpiToken),
          responseType: 'text'
        }
      )
      .toPromise();
  }

  makeJPIFileRequestForCompany(url: string): Observable<File[]> {
    const header = new HttpHeaders();
    return this._http.get(this.jpiUrl + url, {
      headers: header.append('Authorization', this.jpiToken)
    }) as Observable<File[]>;
  }

  makeJPIFileUpload(
    url: string,
    file: Blob,
    companyId: number,
    categoryId: number
  ): Observable<FileMetaData> {
    const form = new FormData();
    const header = new HttpHeaders();
    const fileObject = {
      companyId: companyId.toString(),
      categoryId: categoryId
    };
    form.set('file', file);
    form.set('fileobject', JSON.stringify(fileObject));
    return this._http.post(this.jpiUrl + url, form, {
      headers: header.append('Authorization', this.jpiToken)
    }) as Observable<FileMetaData>;
  }

  makeJPIFileRequest(url: string): Promise<any> {
    return this._http
      .get(this.jpiUrl + url, {
        headers: this.headers.append('Authorization', this.jpiToken),
        responseType: 'blob',
        params: null
      })
      .toPromise();
  }

  makeNewJpiRequest(url: string, params: any, body: any, method: string): any {
    if (!this.isSignedIn()) {
      this._router.navigate(['/auth/login']);
    }

    switch (method.toLowerCase()) {
      case 'get':
        return this._http.get(this.jpiUrl + url, {
          headers: this.headers.append('Authorization', this.jpiToken),
          responseType: 'text',
          params: params
        });
      case 'post':
        return this._http.post(this.jpiUrl + url, body, {
          headers: this.headers.append('Authorization', this.jpiToken),
          responseType: 'text',
          params: params
        });
      case 'put':
        return this._http.put(this.jpiUrl + url, body, {
          headers: this.headers.append('Authorization', this.jpiToken),
          responseType: 'text',
          params: params
        });
      case 'delete':
        return this._http.delete(this.jpiUrl + url, <any>{
          headers: this.headers.append('Authorization', this.jpiToken),
          responseType: 'text',
          body: body,
          params: params
        });
    }
  }

  makeRequest(url: string, body: any, method: string): Promise<any> {
    if (!this.isSignedIn()) {
      this.store.dispatch(new Reauthenticate());
    }
    if (method.toLowerCase() == 'post') {
      return this._http
        .post(this.apiUrl + url, body, {
          headers: this.headers.append('Authorization', this.apiAuth.ApiAuthToken),
          responseType: 'text'
        })
        .toPromise()
        .catch(error => {
          if (error.status === 401) {
            this.store.dispatch(new Reauthenticate());
          }
        });
    } else if (method.toLowerCase() == 'get') {
      return this._http
        .get(this.apiUrl + url, {
          headers: this.headers.append('Authorization', this.apiAuth.ApiAuthToken),
          responseType: 'text'
        })
        .toPromise()
        .catch(error => {
          if (error.status === 401) {
            this.store.dispatch(new Reauthenticate());
          }
        });
    }
  }

  makeFileRequest(url: string, body: any, method: string): Promise<any> {
    if (!this.isSignedIn()) {
      this._router.navigate(['/auth/login']);
    }
    if (method.toLowerCase() == 'post') {
      return this._http
        .post(this.apiUrl + url, body, {
          headers: this.fileHeaders.append('Authorization', this.apiAuth.ApiAuthToken),
          responseType: 'text'
        })
        .toPromise();
    } else if (method.toLowerCase() == 'get') {
      return this._http
        .get(this.apiUrl + url, {
          headers: this.fileHeaders.append('Authorization', this.apiAuth.ApiAuthToken),
          responseType: 'text'
        })
        .toPromise();
    }
  }

  makeRecoveryRequest(url: string, body: any): Promise<any> {
    return this._http
      .post(this.apiUrl + url, body, { headers: this.headers })
      .toPromise();
  }

  // can user access the page
  canActivate(): boolean {
    // check to see if user is signed in
    if (this.isSignedIn()) {
      if (this.apiAuth.UseNewTelenotes == false) {
        window.location.href =
          'https://telenotes.net/AccountInfo.aspx?SNID=' + this.apiAuth.Snid;
      }
      return true;
    }

    // user is not properly logged in, redirect to login
    this.logout();
    return false;
  }

  hasPermission(module: string, moduleAction: string): Promise<boolean> {
    if (this.permissions) {
      if (this.permissions[module]) {
        if (moduleAction) {
          return Promise.resolve(this.permissions[module].includes(moduleAction));
        } else {
          return Promise.resolve(true);
        }
      }
      return Promise.resolve(false);
    } else if (this.isSignedIn()) {
      return this.makeRequest(
        '/api/security/Permissions?agentMailBox=' + this.apiAuth.Mailbox,
        '',
        'post'
      )
        .then((result: any) => {
          this.permissions = JSON.parse(result);
          if (this.permissions[module]) {
            if (moduleAction) {
              return this.permissions[module].includes(moduleAction);
            } else {
              return true;
            }
          }
          return false;
        })
        .catch(() => {
          this._router.navigate(['/error', 500]);
        });
    }
  }

  updateSalesSettings(settings: SalesSettings): void {
    this.salesSettings = settings;
  }
}
