import { RootState } from '../../store.reducer';
import { MemoizedSelector, createSelector } from '@ngrx/store';
import { GenericMap } from 'src/app/models/generic-map';
import { CallNote } from 'src/app/models/call-notes/call-note';
import { CallNoteField } from 'src/app/models/call-notes/call-note-field';
import { Contact } from 'src/app/models/contacts/contact';
import { selectProjectParamId } from '../../projects/selectors/current-project.selector';

export const selectAllCallNotes = (state: RootState) => state.callNotes.data;

export const selectProjectCallNotesAsArray: MemoizedSelector<RootState, CallNote[]> =
  createSelector(
    selectAllCallNotes,
    selectProjectParamId,
    (callNotesMap: GenericMap<CallNote>, projectId: number): CallNote[] => {
      return Object.values(callNotesMap)
        .filter((cn: CallNote) => !!cn)
        .filter((cn: CallNote) => cn.projectId === projectId)
        .map((callNote: CallNote) => {
          return {
            ...callNote,
            followUp: {
              ...callNote.followUp,
              assignedTo: !!callNote.followUp.assignedTo?.id
                ? {
                    id: callNote.followUp.assignedTo?.id,
                    mailbox: callNote.followUp.assignedTo?.mailbox,
                    name:
                      callNote.followUp.assignedTo?.firstName +
                      ' ' +
                      callNote.followUp.assignedTo?.lastName
                  }
                : null
            },
            fields: callNote.fields
              .sort(
                (a: CallNoteField, b: CallNoteField) =>
                  a.field.callnoteOrder - b.field.callnoteOrder
              )
              .map((field: CallNoteField) => {
                return {
                  name: field.field.name,
                  id: field.field.id,
                  value: field.value,
                  dropdownId: field.dropdownId,
                  type: field.field.type
                } as CallNoteField;
              }),
            companyName: callNote.company.name,
            contacts: callNote.contacts.map((contact: Contact) =>
              contact.lastName
                ? ({
                    id: contact.id,
                    name: contact.firstName + ' ' + contact.lastName
                  } as Contact)
                : ({
                    id: contact.id,
                    name: contact.firstName
                  } as Contact)
            ),
            createdBy: {
              mailbox: callNote.createdBy.mailbox,
              id: callNote.createdBy.id,
              name: callNote.createdBy.firstName + ' ' + callNote.createdBy.lastName
            }
          };
        });
    }
  );
