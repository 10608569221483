import { MemoizedSelector, createSelector } from '@ngrx/store';
import { RootState } from '../../../store.reducer';
import { GenericMap } from 'src/app/models/generic-map';
import { ProjectCustomField } from 'src/app/models/projects/project-custom-field';

export const selectAllProjectCompanyCustomFields = (state: RootState) =>
  state.projectCompanyCustomFields.data;

export const selectedProjectCompanyCustomFieldId = (state: RootState) =>
  state.projectCompanyCustomFields.selectedPcfId;

export const selectCurrentProjectCompanyCustomField: MemoizedSelector<
  RootState,
  ProjectCustomField
> = createSelector(
  selectAllProjectCompanyCustomFields,
  selectedProjectCompanyCustomFieldId,
  (
    projectCFMap: GenericMap<ProjectCustomField>,
    projectCFId: number
  ): ProjectCustomField => {
    let projectCF: ProjectCustomField;
    if (!!projectCFId && projectCFMap[projectCFId]) {
      const id = projectCFId.toString();
      projectCF = {
        ...projectCFMap[id]
      };
    }
    return projectCF;
  }
);
