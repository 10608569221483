import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { AuthenticationService } from '../../shared/services/authentication.service';
import { Contact } from '../models/contacts/contact';
import { ContactV2 } from '../models/contacts/contact-V2';
import { CustomFieldV2 } from '../models/admin/custom-fields/custom-field-V2';

@Injectable()
export class ContactService {
	private contactAnnouncer = new Subject<ContactV2>();
	public contactAnnounced = this.contactAnnouncer.asObservable();

	constructor(private authService: AuthenticationService) {
	}

	contactFromCallNote(contact: ContactV2) {
		this.contactAnnouncer.next(null);
		this.contactAnnouncer.next(contact);
	}

	clearServiceData(): void {
	}

	getAllContacts(): Promise<Contact[]> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/contact/getcsv', null, 'get').then(function (result: any) {
			const contacts: Contact[] = self.parseCsv(result);
			return contacts.sort(function (contact1: Contact, contact2: Contact) { return contact1.FullName.localeCompare(contact2.FullName); });
		});
	}

	getContactsForCompany(companyId: number): Promise<Contact[]> {
		const self = this;

		return this.authService.makeRequest('/api-v1.2.1/contact/getByCompanyId?id=' + companyId, '', 'get')
			.then(function (result: any) {
				const contacts: Contact[] = self.parseCsv(result);
				return contacts.sort(function (contact1: Contact, contact2: Contact) { return contact1.FullName.localeCompare(contact2.FullName); });
			});
	}

	getContactCustomFields(): Promise<any[]> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/getContactCustomFieldsWithValues', '', 'get')
			.then(function (result: any) {
				return self.parseCustomContactFieldsCsv(result);
			});
	}

	getContactsWithCustomFields(companyId: number): Promise<Contact[]> {
		return this.authService.makeRequest('/api-v1.2.2/contact/getByCompanyId?id=' + companyId, '', 'get').then(function (result) {
			return JSON.parse(result);
		});
	}

	addNewContact(contact: Contact): Promise<number> {
		return this.authService.makeRequest('/api-v1.2.1/contact/add', contact, 'post').then(function (result) {
			return result;
		});
	}

	editContact(contact: Contact): Promise<boolean> {
		return this.authService.makeRequest('/api-v1.2.1/contact/edit', contact, 'post').then(function (result) {
			return true;
		});
	}

	deleteContact(contact: Contact): Promise<boolean> {
		return this.authService.makeRequest('/api-v1.2.1/contact/delete', contact, 'post').then(function (result) {
			return true;
		});
	}

	updateContactFieldValue(field: CustomFieldV2): Promise<boolean> {
		return this.authService.makeRequest('/api-v1.2.1/customfields/updateContactFieldValue', field, 'post').then(function (result) {
			return true;
		});
	}

	updateContactFieldValues(fields: CustomFieldV2[]): Promise<boolean> {
		return this.authService.makeRequest('/api-v1.2.1/customfields/updateContactFieldValues', fields, 'post').then(function (result) {
			return true;
		});
	}

	parseCsv(csvString: string): Contact[] {
		const temp: any[] = csvString.split('\n');
		const cons: Contact[] = [];

		const i = 1;
		for (let i = 1; i < temp.length; i++) {
			const data: any[] = temp[i].split('\xa7');

			if (data.length > 1) {
				const con: Contact = <Contact>{};

				con.CompanyId = data[0];
				con.ContactId = data[1];
				con.MailBox = data[2];
				con.Title = data[3];
				con.FirstName = data[4];
				con.LastName = data[5];
				con.Address1 = data[6];
				con.Address2 = data[7];
				con.City = data[8];
				con.State = data[9];
				con.Zip = data[10];
				con.HomePhone = data[11];
				con.PrimaryPhone = data[12];
				con.ext = data[13];
				con.MobilePhone = data[14];
				con.Email = data[15];
				con.BirthDay = data[16];

				con.FullName = con.FirstName + ' ' + con.LastName;

				cons.push(con);
			}
		}

		return cons;
	}

	parseCustomContactFieldsCsv(csvString: string): any[] {
		const temp: any[] = csvString.split('\n');
		const cons: any[] = [];

		for (let i = 1; i < temp.length; i++) {
			const data: any[] = temp[i].split('\xa7');

			if (data.length > 1) {
				const con: any = <any>{};

				con.ContactId = data[0];
				con.LabelId = data[1];
				con.FieldId = data[2];
				con.FieldType = data[3];
				con.ListId = data[4];
				con.IsFieldStandard = data[5];
				con.StandardFieldId = data[6];
				con.Value = data[7];

				cons.push(con);
			}
		}
		return cons;
	}
}
