import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

import { ToolbarTab } from '../models/toolbarTab';
import { ServicesModule } from 'src/app/services/services.module';

@Injectable({
  providedIn: ServicesModule
})
export class ToolbarService {
  private buttonAnnouncer = new Subject<string[]>();
  buttonAnnouced = this.buttonAnnouncer.asObservable();
  private buttonClickAnnouncer = new Subject<string>();
  buttonClickAnnouced = this.buttonClickAnnouncer.asObservable();
  private tabsAnnouncer = new Subject<ToolbarTab[]>();
  tabsChanged = this.tabsAnnouncer.asObservable();
  private searchBarAnnouncer = new Subject<boolean>();
  searchBarAnnounced = this.searchBarAnnouncer.asObservable();
  private buttons: string[];
  private tabs: ToolbarTab[];
  private isSearchBarVisible: boolean = false;
  private searchBarPlaceHolder: string = '';
  private searchTermAnnouncer = new Subject<string>();
  searchTermAnnounced = this.searchTermAnnouncer.asObservable();
  private companyAnnouncer = new Subject<boolean>();
  companyAnnounced = this.companyAnnouncer.asObservable();
  private callNoteAnnouncer = new Subject<boolean>();
  callNoteAnnounced = this.callNoteAnnouncer.asObservable();
  private followUpAnnouncer = new Subject<boolean>();
  followUpAnnounced = this.followUpAnnouncer.asObservable();
  private fileUploadAnnouncer = new Subject<boolean>();
  fileUploadAnnounced = this.fileUploadAnnouncer.asObservable();
  private contactAnnouncer = new Subject<boolean>();
  contactAnnounced = this.contactAnnouncer.asObservable();
  private customCompanyAnnouncer = new Subject<boolean>();
  customCompanyAnnounced = this.customCompanyAnnouncer.asObservable();
  private customCallNoteAnnouncer = new Subject<boolean>();
  customCallNoteAnnounced = this.customCallNoteAnnouncer.asObservable();
  private customFollowUpAnnouncer = new Subject<boolean>();
  customFollowUpAnnounced = this.customFollowUpAnnouncer.asObservable();
  private customFileUploadAnnouncer = new Subject<boolean>();
  customFileUploadAnnounced = this.customFileUploadAnnouncer.asObservable();
  private customContactAnnouncer = new Subject<boolean>();
  customContactAnnounced = this.customContactAnnouncer.asObservable();
  expanded: boolean = false;
  companyId: number;
  customCallNoteMenu: boolean;
  searchTerm: string;
  private showToolbarAnnouncer = new BehaviorSubject<boolean>(true);
  showToolbarAnnounced = this.showToolbarAnnouncer.asObservable();

  constructor() {
    this.tabs = [];
  }

  clearServiceData(): void {
    this.clearToolbar();
  }

  setToolbarVisibility(showToolbar: boolean) {
    this.showToolbarAnnouncer.next(showToolbar);
  }

  announceNewSearchTerm(term: string): void {
    this.searchTerm = term;
    this.searchTermAnnouncer.next(term);
  }

  addNewTab(name: string, url: string, active: boolean): void {
    const tab: ToolbarTab = {
      TabName: name,
      TabUrl: url,
      IsActive: active
    };
    this.addTab(tab);
  }

  addTab(tab: ToolbarTab): void {
    if (!this.tabs) {
      this.tabs = [];
    }
    this.tabs.push(tab);
    this.tabsAnnouncer.next(this.tabs);
  }

  addButton(button: string): void {
    if (!this.buttons) {
      this.buttons = [];
    }

    this.buttons.push(button);
    this.buttonAnnouncer.next(this.buttons);
  }

  buttonClicked(button: string): void {
    this.buttonClickAnnouncer.next(button);
  }

  showSearchBar(placeHolder: string): void {
    this.isSearchBarVisible = true;
    this.searchBarPlaceHolder = placeHolder;
    this.searchBarAnnouncer.next(true);
  }

  hideSearchBar(): void {
    this.isSearchBarVisible = false;
    this.searchBarPlaceHolder = '';
    this.searchBarAnnouncer.next(false);
  }

  getSearchBarPlaceHolder(): string {
    return this.searchBarPlaceHolder;
  }

  clearToolbar(): void {
    delete this.tabs;
    delete this.buttons;
    this.tabs = [];
    this.buttons = [];
    this.tabsAnnouncer.next(this.tabs);
    this.buttonAnnouncer.next(this.buttons);
    this.searchBarPlaceHolder = '';
    this.searchBarAnnouncer.next(false);
  }

  resetSearchBarPlaceHolder(): void {
    this.hideSearchBar();
    this.showSearchBar('Search Telenotes');
  }

  callNoteVisible(callNotePopup: boolean) {
    this.callNoteAnnouncer.next(callNotePopup);
  }

  followUpVisible(callNotePopup: boolean) {
    this.followUpAnnouncer.next(callNotePopup);
  }

  fileUploadVisible(fileUploadPopup: boolean) {
    this.fileUploadAnnouncer.next(fileUploadPopup);
  }

  contactVisible(contactUploadPopup: boolean) {
    this.contactAnnouncer.next(contactUploadPopup);
  }

  companyVisible(companyPopup: boolean) {
    this.companyAnnouncer.next(companyPopup);
  }

  customCompanyVisible(companyPopup: boolean) {
    this.customCompanyAnnouncer.next(companyPopup);
  }

  customCallNoteVisible(callNotePopup: boolean) {
    this.customCallNoteAnnouncer.next(callNotePopup);
  }

  customFollowUpVisible(callNotePopup: boolean) {
    this.customFollowUpAnnouncer.next(callNotePopup);
  }

  customFileUploadVisible(fileUploadPopup: boolean) {
    this.customFileUploadAnnouncer.next(fileUploadPopup);
  }

  customContactVisible(contactUploadPopup: boolean) {
    this.customContactAnnouncer.next(contactUploadPopup);
  }
}
