<div class="white-padding" fxLayout="row">
  <div fxFlex="4"></div>
  <div
    class="client-input edit-company-dropdown input-padding popup-expanded-row"
    fxLayout="row"
    (click)="expandBillingRow()"
    fxFlex
  >
    <div fxFlex>Billing Address</div>
    <mat-icon *ngIf="!billingAddressExpanded" class="icon-large"
      >arrow_drop_down</mat-icon
    >
    <mat-icon *ngIf="billingAddressExpanded" class="icon-large">arrow_drop_up</mat-icon>
  </div>
  <div fxFlex="4"></div>
</div>
<div *ngIf="billingAddressExpanded">
  <div class="client-input input-padding" fxLayout="row">
    <div fxFlex="4"></div>
    <div class="client-input" fxLayout="column" fxFlex>
      <span>Address Line 1</span>
      <dx-text-box [(value)]="company.billingAddress.addressLine1"></dx-text-box>
    </div>
    <div fxFlex="4"></div>
  </div>
  <div class="client-input input-padding" fxLayout="row">
    <div fxFlex="4"></div>
    <div class="client-input" fxLayout="column" fxFlex>
      <span>Address Line 2</span>
      <dx-text-box [(value)]="company.billingAddress.addressLine2"></dx-text-box>
    </div>
    <div fxFlex="4"></div>
  </div>
  <div class="client-input input-padding" fxLayout="row">
    <div fxFlex="4"></div>
    <div fxFlex="50" class="client-input" fxLayout="column">
      <span>City</span>
      <dx-text-box [(value)]="company.billingAddress.city"></dx-text-box>
    </div>
    <div fxFlex="6"></div>
    <div fxFlex="50" class="client-input" fxLayout="column">
      <span>State</span>
      <dx-text-box [(value)]="company.billingAddress.state"></dx-text-box>
    </div>
    <div fxFlex="4"></div>
  </div>
  <div class="client-input input-padding" fxLayout="row">
    <div fxFlex="4"></div>
    <div fxFlex="50" class="client-input" fxLayout="column">
      <span>County</span>
      <dx-text-box [(value)]="company.billingAddress.county"></dx-text-box>
    </div>
    <div fxFlex="6"></div>
    <div fxFlex="50" class="client-input" fxLayout="column">
      <span>Zip</span>
      <dx-text-box [(value)]="company.billingAddress.postCode"></dx-text-box>
    </div>
    <div fxFlex="4"></div>
    <!-- <div fxFlex="34" class="client-input" fxLayout="column">
            <span>Country</span>
            <dx-text-box></dx-text-box>
        </div>
        <div fxFlex="4"></div> -->
  </div>
  <!-- <div class="client-input input-padding" fxLayout="row">
        <div fxFlex="4"></div>
        <div fxFlex="46" class="client-input" fxLayout="column">
            <span>County</span>
            <dx-select-box placeholder="Select County"></dx-select-box>
        </div>
    </div> -->
</div>
<div class="white-padding" fxLayout="row">
  <div fxFlex="4"></div>
  <div
    class="client-input edit-company-dropdown input-padding popup-expanded-row"
    fxLayout="row"
    (click)="expandShippingRow()"
    fxFlex
  >
    <div fxFlex>Shipping Address</div>
    <mat-icon *ngIf="!shippingAddressExpanded" class="icon-large"
      >arrow_drop_down</mat-icon
    >
    <mat-icon *ngIf="shippingAddressExpanded" class="icon-large">arrow_drop_up</mat-icon>
  </div>
  <div fxFlex="4"></div>
</div>
<div *ngIf="shippingAddressExpanded">
  <div class="client-input input-padding" fxLayout="row">
    <div fxFlex="4"></div>
    <div fxFlex class="client-input" fxLayout="column">
      <span>Address Line 1</span>
      <dx-text-box [(value)]="company.shippingAddress.addressLine1"></dx-text-box>
    </div>
    <div fxFlex="4"></div>
  </div>
  <div class="client-input input-padding" fxLayout="row">
    <div fxFlex="4"></div>
    <div fxFlex class="client-input" fxLayout="column">
      <span>Address Line 2</span>
      <dx-text-box [(value)]="company.shippingAddress.addressLine2"></dx-text-box>
    </div>
    <div fxFlex="4"></div>
  </div>
  <div class="client-input input-padding" fxLayout="row">
    <div fxFlex="4"></div>
    <div fxFlex="50" class="client-input" fxLayout="column">
      <span>City</span>
      <dx-text-box [(value)]="company.shippingAddress.city"></dx-text-box>
    </div>
    <div fxFlex="6"></div>
    <div fxFlex="50" class="client-input" fxLayout="column">
      <span>State</span>
      <dx-text-box
        [(value)]="company.shippingAddress.state"
        [maxLength]="2"
      ></dx-text-box>
    </div>
    <div fxFlex="4"></div>
  </div>
  <div class="client-input input-padding" fxLayout="row">
    <div fxFlex="4"></div>
    <div fxFlex="50" class="client-input" fxLayout="column">
      <span>County</span>
      <dx-text-box [(value)]="company.shippingAddress.county"></dx-text-box>
    </div>
    <div fxFlex="6"></div>
    <div fxFlex="50" class="client-input" fxLayout="column">
      <span>Zip</span>
      <dx-text-box [(value)]="company.shippingAddress.postCode"></dx-text-box>
    </div>
    <div fxFlex="4"></div>
    <!-- <div fxFlex="34" class="client-input" fxLayout="column">
            <span>Country</span>
            <dx-text-box></dx-text-box>
        </div>
        <div fxFlex="4"></div> -->
  </div>
  <!-- <div class="client-input input-padding" fxLayout="row">
        <div fxFlex="4"></div>
        <div fxFlex="46" class="client-input" fxLayout="column">
            <span>County</span>
            <dx-select-box placeholder="Select County"></dx-select-box>
        </div>
    </div> -->
</div>
