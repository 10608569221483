import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Contact } from 'src/app/models/contacts/contact';
import { formatPhone } from 'src/app/utils/formatPhone';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import { LoadContact } from 'src/app/store/contacts/contacts.actions';
import { OpenDrawer } from 'src/app/store/layout/layout.actions';
import { ContactSlideOutFormComponent } from '../contact-slide-out-form/contact-slide-out-form.component';
import { CompanyContactDialog } from '../../pages/companies/components/company-profile/company-contacts/company-contacts.component';
import { CallNoteFormComponent } from '../call-note-form/call-note-form.component';

@Component({
  selector: 'tn-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss']
})
export class ContactCardComponent {
  constructor(
    public dialogRef: MatDialogRef<ContactCardComponent>,
    @Inject(MAT_DIALOG_DATA) public contact: Contact,
    private store: Store<RootState>,
    public dialog: MatDialog
  ) {}

  addCallNote(contact: Contact) {
    this.store.dispatch(
      new OpenDrawer({
        component: CallNoteFormComponent,
        data: {
          selectedContact: {
            id: contact.id,
            name: contact.firstName + ' ' + contact.lastName
          }
        }
      })
    );
    this.closeDialog();
  }

  rowClicked(contact: Contact) {
    this.store.dispatch(
      new LoadContact({ companyId: contact.account, contactId: contact.id })
    );
    this.store.dispatch(
      new OpenDrawer({
        component: ContactSlideOutFormComponent,
        data: {
          contact,
          hasCompanyInput: true
        }
      })
    );
    this.closeDialog();
  }

  deleteContact(contact: Contact) {
    const dialogRef = this.dialog.open(CompanyContactDialog, {
      width: '250px',
      data: contact
    });
  }

  formatPhone(phone: string) {
    return formatPhone(phone);
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
