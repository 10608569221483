import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Address } from 'src/app/models/address';
import { TerritoriesMap } from 'src/app/models/admin/territories/territories-map';
import { Territory } from 'src/app/models/admin/territories/territory';
import { Company } from 'src/app/models/companies/company';
import { CompanySelectItem } from 'src/app/models/companies/company-select';
import { Contact } from 'src/app/models/contacts/contact';
import { GenericMap } from 'src/app/models/generic-map';
import { GlobalFilter } from 'src/app/models/global-filter';
import { IdNameItem } from 'src/app/models/id-name-item';
import { Label } from 'src/app/models/label';
import { LabelGroup } from 'src/app/models/label-group';
import {
  ClearNewCompanyContacts,
  ClearSearchCompanies,
  CreateCompanyWithContacts,
  DeleteCompany,
  RemoveNewCompanyContact,
  SearchCompanies,
  SelectSearchedCompany,
  UpdateCompany
} from 'src/app/store/companies/companies.actions';
import {
  CloseDrawer,
  OpenErrorSnackbar,
  OpenLeftDrawer
} from 'src/app/store/layout/layout.actions';
import { selectAllCompanyLabels } from 'src/app/store/maps/selectors/label-filter-ids.selector';
import { RootState } from 'src/app/store/store.reducer';
import { LoadCompanyLabels } from 'src/app/store/system-settings/company-labels/company-labels.actions';
import { LoadTerritories } from 'src/app/store/territory-management/territory-management.actions';
import { DeleteObject } from 'src/app/store/undo-delete/undo-delete.actions';
import { selectMultiSelectUsers } from 'src/app/store/users/selectors/multi-select-users.selector';
import { LoadUsers } from 'src/app/store/users/users.actions';
import { convertToMap } from 'src/app/utils/convertToMap';
import { removeNullProps } from 'src/app/utils/removeNullProps';
import { ConfirmDeleteComponent } from '../confirm-delete/confirm-delete.component';
import { ContactSlideOutFormComponent } from '../contact-slide-out-form/contact-slide-out-form.component';

@Component({
  selector: 'tn-company-slide-out-form',
  templateUrl: './company-slide-out-form.component.html',
  styleUrls: ['./company-slide-out-form.component.scss']
})
export class CompanySlideOutFormComponent implements OnInit, OnDestroy {
  @Input() data: {
    company?: Company;
    redirect?: boolean;
  } = {
    company: null
  };

  companyNameCtrl = new FormControl('', Validators.required);

  shippingAddressForm = new FormGroup({
    addressLine1: new FormControl(''),
    addressLine2: new FormControl(''),
    city: new FormControl(''),
    state: new FormControl(''),
    postCode: new FormControl(''),
    country: new FormControl(''),
    county: new FormControl('')
  });

  billingAddressForm = new FormGroup({
    addressLine1: new FormControl(''),
    addressLine2: new FormControl(''),
    city: new FormControl(''),
    state: new FormControl(''),
    postCode: new FormControl(''),
    country: new FormControl(''),
    county: new FormControl('')
  });

  contactInfoForm = new FormGroup({
    phone: new FormControl(null),
    alternatePhone: new FormControl(null),
    fax: new FormControl(null),
    companyEmail: new FormControl(null),
    website: new FormControl(null),
    links: new FormControl([])
  });

  socialInfoForm = new FormGroup({
    linkedIn: new FormControl(null),
    twitter: new FormControl(null),
    facebook: new FormControl(null),
    instagram: new FormControl(null)
  });

  companyForm = new FormGroup({
    territories: new FormControl(null, Validators.required),
    primaryUser: new FormControl(null),
    secondaryRepId: new FormControl(null),
    parentCompanyId: new FormControl(null),
    contractExpiration: new FormControl(null),
    annualRevenue: new FormControl(null),
    employees: new FormControl(null),
    locations: new FormControl(null),
    leadSource: new FormControl(null),
    referralSource: new FormControl(null),
    accountId: new FormControl(null),
    notes: new FormControl(null)
  });

  labelsForm = new FormGroup({});

  companyLinks: string[] = [];

  territories: Territory[];
  $territoriesSub: Subscription;
  companyTerritories: Territory[] = [];

  $allUsers: Observable<IdNameItem[]>;
  $companies: Observable<CompanySelectItem[]>;
  $companiesSearchPending: Observable<boolean>;
  $companiesSearchError: Observable<HttpErrorResponse>;

  $companyLabels: Observable<GenericMap<LabelGroup>> = EMPTY;
  $companyLabelsSub: Subscription;
  $allCompanyLabelGroups: Observable<GenericMap<LabelGroup>> = EMPTY;
  hasRequiredLabel: boolean;
  allLabelGroups: GenericMap<LabelGroup> = {};
  allLabelGroupsArray: LabelGroup[] = [];
  hasLabelGroups: boolean;
  companyLabelsMap: GenericMap<Label> = {};
  allLabelsMap: GenericMap<Label> = {};
  $companyContacts: Observable<Contact[]> = EMPTY;

  @ViewChild('addLinkInput', { static: true, read: ElementRef })
  addLinkInput: ElementRef<HTMLInputElement>;

  constructor(
    private store: Store<RootState>,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnDestroy() {
    this.$territoriesSub.unsubscribe();
    this.$companyLabelsSub.unsubscribe();
  }

  ngOnInit() {
    this.companyNameCtrl.markAsTouched();
    this.companyForm.controls.territories.markAsTouched();
    // this.store.dispatch(new LoadUsers());
    this.store.dispatch(new LoadTerritories());
    this.store.dispatch(new LoadCompanyLabels());

    this.$companyContacts = this.store.select('companies', 'newCompanyContacts');
    this.$allUsers = this.store.select(selectMultiSelectUsers);
    this.$companyLabels = this.store.select(selectAllCompanyLabels);
    this.$companyLabelsSub = this.$companyLabels.subscribe({
      next: (groups: GenericMap<LabelGroup>) => {
        this.setUpLabelsForm(groups);
      }
    });

    this.$companies = this.store.select('companies', 'search', 'data');
    this.$companiesSearchPending = this.store.select('companies', 'search', 'pending');
    this.$companiesSearchError = this.store.select('companies', 'search', 'error');

    this.$territoriesSub = this.store
      .select('territoryManagement', 'territories', 'data')
      .subscribe((territories: TerritoriesMap) => {
        this.companyForm.removeControl('territories');
        this.companyForm.addControl(
          'territories',
          new FormControl(null, Validators.required)
        );
        this.companyForm.controls.territories.markAsTouched();
        this.territories = Object.values(territories).sort((a, b) => {
          const textA = a.name.toLowerCase();
          const textB = b.name.toLowerCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        if (
          !!this.territories.length &&
          !!this.data &&
          !!this.data.company &&
          !!this.data.company.territories
        ) {
          this.territories.map((territory) => {
            this.data.company.territories.map((companyTerritory) => {
              if (territory.id === companyTerritory.id) {
                this.companyTerritories.push(territory);
              }
            });
          });
          this.companyForm.get('territories').setValue(this.companyTerritories);
        }
      });

    if (!!this.data && !!this.data.company) {
      const company = this.data.company;
      this.companyNameCtrl.setValue(company.name);
      if (!!company.billingAddress) {
        this.billingAddressForm.setValue(company.billingAddress);
      }
      if (!!company.shippingAddress) {
        this.shippingAddressForm.setValue(company.shippingAddress);
      }

      this.companyForm.setValue({
        territories: company.territories,
        primaryUser: company.primaryUser,
        secondaryRepId: company.secondaryRepId,
        parentCompanyId: company.parentCompanyId,
        contractExpiration: company.contractExpiration,
        annualRevenue: company.annualRevenue,
        employees: company.employees,
        locations: company.locations,
        leadSource: company.leadSource,
        referralSource: company.referralSource,
        accountId: company.accountId,
        notes: company.notes
      });

      this.contactInfoForm.setValue({
        phone: company.phone,
        alternatePhone: company.alternatePhone,
        fax: company.fax,
        companyEmail: company.companyEmail,
        website: company.website,
        links: company.links
      });

      this.socialInfoForm.setValue({
        linkedIn: company.linkedIn,
        twitter: company.twitter,
        facebook: company.facebook,
        instagram: company.instagram
      });
    }
  }

  public compareUsers(user1: any, user2: any): boolean {
    return user1 && user2 ? user1.id === user2.id : false;
  }

  submitHandler() {
    const newCompany: Company = {
      ...(!!this.data && !!this.data.company ? this.data.company : {}),
      name: this.companyNameCtrl.value,

      phone: this.contactInfoForm.value.phone,
      alternatePhone: this.contactInfoForm.value.alternatePhone,
      fax: this.contactInfoForm.value.fax,
      companyEmail: this.contactInfoForm.value.companyEmail,
      website: this.contactInfoForm.value.website,
      links: this.contactInfoForm.value.links,

      linkedIn: this.socialInfoForm.value.linkedIn,
      twitter: this.socialInfoForm.value.twitter,
      facebook: this.socialInfoForm.value.facebook,
      instagram: this.socialInfoForm.value.instagram,

      territories: this.companyForm.value.territories,
      primaryUser: this.companyForm.value.primaryUser,
      secondaryRepId: parseInt(this.companyForm.value.secondaryRepId, 10),
      parentCompanyId: this.companyForm.value.parentCompanyId,
      contractExpiration: this.companyForm.value.contractExpiration,
      annualRevenue: this.companyForm.value.annualRevenue,
      employees: parseInt(this.companyForm.value.employees, 10),
      locations: parseInt(this.companyForm.value.locations, 10),
      leadSource: this.companyForm.value.leadSource,
      referralSource: this.companyForm.value.referralSource,
      accountId: this.companyForm.value.accountId,
      notes: this.companyForm.value.notes,

      billingAddress: this.billingAddressForm.value as Address,
      shippingAddress: this.shippingAddressForm.value as Address,

      labels: Object.values(this.labelsForm.value).length
        ? Object.values(this.labelsForm.value)
            .filter((label) => !!label)
            .flat()
            .map((labelId: number) => this.allLabelsMap[labelId])
        : []
    };

    if (!newCompany.id) {
      this.store.dispatch(
        new CreateCompanyWithContacts({
          company: newCompany,
          redirect: this.data.redirect
        })
      );
    } else {
      removeNullProps(newCompany);
      newCompany.updatedDate = new Date();
      this.store.dispatch(new UpdateCompany(newCompany));
      this.store.dispatch(new CloseDrawer());
      this.store.dispatch(new UpdateCompany(newCompany));
    }
  }

  addNewContact() {
    this.store.dispatch(
      new OpenLeftDrawer({
        component: ContactSlideOutFormComponent,
        data: { hasCompanyInput: false }
      })
    );
  }

  editNewContact(contact: Contact, contactIndex: number) {
    this.store.dispatch(
      new OpenLeftDrawer({
        component: ContactSlideOutFormComponent,
        data: {
          contact,
          contactIndex,
          hasCompanyInput: false
        }
      })
    );
  }

  removeNewContact(index: number) {
    this.store.dispatch(new RemoveNewCompanyContact(index));
  }

  cancelButtonClicked() {
    if (!!this.data?.company) {
      this.store.dispatch(new CloseDrawer());
      this.store.dispatch(new ClearNewCompanyContacts());
    } else {
      this.store.dispatch(new CloseDrawer());
      this.store.dispatch(new ClearNewCompanyContacts());
    }
  }

  deleteCompany() {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {});

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((willDelete: boolean) => {
        if (willDelete) {
          const routerSnapshotUrlArray = this.router.routerState.snapshot.url.split('/');
          this.store.dispatch(new CloseDrawer());
          this.store.dispatch(new DeleteCompany(this.data.company.id));
          if (
            routerSnapshotUrlArray[1] === 'companies' &&
            routerSnapshotUrlArray[2] === 'company'
          ) {
            this.router.navigate(['/companies-list/list']);
          }
        }
      });
  }

  sameAddressChange(changeEvent: MatCheckboxChange) {
    if (changeEvent.checked) {
      this.shippingAddressForm.setValue(this.billingAddressForm.value as Address);
    } else {
      this.shippingAddressForm.setValue({
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        postCode: '',
        country: '',
        county: ''
      });
    }
  }

  addLink(link: string) {
    if (!!link && this.companyLinks.indexOf(link) === -1) {
      this.companyLinks.push(link);
      this.contactInfoForm.markAsDirty();
      this.contactInfoForm.controls.links.setValue(this.companyLinks);
      this.addLinkInput.nativeElement.value = '';
    }
  }
  onRemoveLink(linkToRemove: string) {
    this.companyLinks = this.companyLinks.filter((link) => link !== linkToRemove);
    this.contactInfoForm.controls.links.setValue(this.companyLinks);
    this.contactInfoForm.markAsDirty();
  }

  // onAssignedUserSelected(itemSelected: IdNameItem) {
  //   if (itemSelected.id === this.companyForm.controls.secondaryRepId.value) {
  //     this.store.dispatch(
  //       new OpenErrorSnackbar({
  //         duration: 5000,
  //         message: 'User must be different from secondary owner',
  //         opened: true
  //       })
  //     );
  //   } else {
  //     this.companyForm.markAsDirty();
  //     this.companyForm.controls.primaryUser.setValue(itemSelected);
  //   }
  // }

  // onAssignedSecondaryUserSelected(itemSelected: IdNameItem) {
  //   if (itemSelected.id === this.companyForm.controls.primaryUser.value.id) {
  //     this.store.dispatch(
  //       new OpenErrorSnackbar({
  //         duration: 5000,
  //         message: 'User must be different from primary owner',
  //         opened: true
  //       })
  //     );
  //   } else {
  //     this.companyForm.markAsDirty();
  //     this.companyForm.controls.secondaryRepId.setValue(itemSelected.id);
  //   }
  // }

  onCompanySearch(searchVal: string) {
    this.companyForm.controls.parentCompanyId.setValue(undefined);
    if (!!searchVal.length) {
      const reqFilter: GlobalFilter = {
        filters: [
          {
            operand1: 'searchField',
            operator: 'CONTAINS_ALL_OF',
            operand2: [searchVal]
          }
        ]
      };
      this.store.dispatch(new SearchCompanies(reqFilter));
    } else {
      this.store.dispatch(new ClearSearchCompanies());
    }
  }

  onCompanySelect(event: MatAutocompleteSelectedEvent) {
    this.companyForm.markAsDirty();
    this.store.dispatch(new SelectSearchedCompany(event.option.value.id));
    this.companyForm.controls.parentCompanyId.setValue(event.option.value.id);
  }

  setUpLabelsForm(groups: GenericMap<LabelGroup>) {
    this.labelsForm = new FormGroup({});
    this.allLabelGroups = groups;
    this.allLabelGroupsArray = Object.values(groups)
      .sort((a, b) => {
        const textA = a.name.toLowerCase();
        const textB = b.name.toLowerCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      })
      .map((labelGroup: LabelGroup) => {
        return {
          ...labelGroup,
          labels: labelGroup.labels.sort((a, b) => {
            const textA = a.name.toLowerCase();
            const textB = b.name.toLowerCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          })
        };
      });
    this.hasLabelGroups = this.allLabelGroupsArray.length > 0;
    Object.values(groups).forEach((group: LabelGroup) => {
      this.allLabelsMap = {
        ...this.allLabelsMap,
        ...convertToMap(group.labels, 'id')
      };

      this.labelsForm.addControl(
        group.id.toString(),
        new FormControl(null, group.required ? Validators.required : null)
      );

      if (group.required) {
        this.hasRequiredLabel = true;
        this.labelsForm.controls[group.id.toString()].markAsTouched();
      }
    });
    if (!!this.data && !!this.data.company) {
      const company = this.data.company;
      company.labels.forEach((label: Label) => {
        if (this.allLabelGroups[label.groupId]) {
          if (this.allLabelGroups[label.groupId].allowMultiple) {
            this.labelsForm.controls[label.groupId].setValue([
              ...(this.labelsForm.controls[label.groupId].value || []),
              label.id
            ]);
          } else {
            this.labelsForm.controls[label.groupId].setValue(label.id);
          }
        }
      });
    }
  }

  labelChange(event: MatSelectChange) {
    if (!!event.value && typeof event.value !== 'number' && event.value.length) {
      if (
        event.value.includes(null) &&
        this.labelsForm.controls[event.source.ngControl.name]
      ) {
        this.labelsForm.controls[event.source.ngControl.name].setValue([]);
      }
    }
  }
}
