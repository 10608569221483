import { Subscription } from 'rxjs';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { CallNotesService } from 'src/app/services/call-notes/call-notes.service';
import {
  LoadCallNotes,
  SelectCallNoteId
} from 'src/app/store/call-notes/call-notes.actions';
import { OpenDrawer } from 'src/app/store/layout/layout.actions';
import { RootState } from 'src/app/store/store.reducer';
import { CompanyCallNoteDialog } from 'src/app/view/pages/projects/project-call-note/company-call-note-dialogue';
import { ProjectCallNoteCommentDialogComponent } from 'src/app/view/pages/projects/project-call-note/project-call-note-comment-dialog/project-call-note-comment-dialog.component';
import { CallNoteFormComponent } from '../../call-note-form/call-note-form.component';
import { ImageDialogComponent } from '../../image-dialog/image-dialog.component';

@Component({
  selector: 'tn-call-note',
  templateUrl: './call-note.component.html',
  styleUrls: ['./call-note.component.scss']
})
export class CallNoteComponent implements OnInit, OnDestroy {
  @Input() callNote;
  showCommentsButton: boolean = true;
  showAudioFile: boolean = false;
  // TODO: remove when web recording is implemented
  webRecording: boolean = false;
  imageSub$: Subscription;
  imageUrl: string;

  constructor(
    private store: Store<RootState>,
    public dialog: MatDialog,
    private cnService: CallNotesService
  ) {}

  ngOnInit() {
    // TODO: remove when web recording is implemented
    this.store
      .select('auth', 'data', 'api', 'Role', 'Features', 4, 'Permissions')
      .subscribe((permissions: number) => {
        if (
          (permissions != null || undefined) &&
          Object.keys(permissions) &&
          permissions[163]?.Value === 'true'
        ) {
          this.webRecording = true;
        }
      });
    this.imageSub$ = this.cnService
      .getFileByIdForCallNote(this.callNote.id)
      .subscribe((file: any) => {
        this.imageUrl = file[0]?.downloadUrl;
      });
  }
  ngOnDestroy() {
    this.imageSub$.unsubscribe();
  }

  deleteHandler(callNote) {
    const dialogRef = this.dialog.open(CompanyCallNoteDialog, {
      width: '250px',
      data: {
        ...callNote,
        company: {
          id: callNote.companyId
        }
      }
    });
  }

  editHandler() {
    const filter = {
      filters: [
        {
          operand1: 'id',
          operator: 'IS_ANY_OF',
          operand2: [this.callNote.id]
        }
      ]
    };
    this.store.dispatch(
      new LoadCallNotes({
        filter,
        params: {
          skip: '0',
          limit: '10000'
        }
      })
    );
    this.store.dispatch(new SelectCallNoteId(this.callNote.id));
    this.store.dispatch(
      new OpenDrawer({
        component: CallNoteFormComponent,
        data: { callnoteId: this.callNote.id }
      })
    );
  }

  commentHandler(callNote) {
    const dialogRef = this.dialog.open(ProjectCallNoteCommentDialogComponent, {
      width: '500px',
      data: {
        ...callNote,
        createdBy: {
          id: callNote.createdById
        }
      }
    });
  }

  openFullImage(imageUrl: string) {
    this.dialog.open(ImageDialogComponent, {
      data: {
        imageUrl: imageUrl,
        narrative: this.callNote.fields[0].value
      }
    });
  }
}
