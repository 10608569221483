import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { CompanyLabelGroup } from '../../../../shared/models/admin/company-labels/company-label-group';
import { CustomFieldV2 } from '../../../../shared/models/admin/custom-fields/custom-field-V2';
import { SectionV2 } from '../../../../shared/models/admin/custom-fields/section-V2';
import { RoleV2 } from '../../../../shared/models/admin/roles/role-V2';
import { TerritoryV2 } from '../../../../shared/models/admin/territories/territory-V2';
import { Assignment } from '../../../../shared/models/agent-details';
import { Company } from '../../../../shared/models/companies/company';
import { CompanyJPIService } from '../../../../shared/services/companies/company-JPI.service';
import { RoleService } from '../../../../shared/services/role.service';
import { TerritoryService } from '../../../../shared/services/territories/territory.service';
import { CompanyV2 } from './../../../../shared/models/companies/company-V2';
import { CustomFieldService } from './../../../../shared/services/admin/custom-field.service';
import { CompanyService } from './../../../../shared/services/companies/company.service';

@Component({
  selector: 'company-profile-details',
  templateUrl: './company-profile-details.component.html',
  styleUrls: ['../../companies.scss']
})
export class CompanyProfileDetailsComponent implements OnInit, OnDestroy {
  @Input() companyJPI: CompanyV2;
  @Input() company: Company;
  @Input() editCompanyPopup: boolean;
  @Input() labelGroups: CompanyLabelGroup[];
  @Input() customSection: SectionV2[];
  @Input() displayedLabels: CompanyLabelGroup[];
  @Input() isSalesAdmin: boolean;
  companyDetails: CustomFieldV2[];
  territoriesAndRepsGrid: any;
  assignments: Assignment[];
  territorySubscription: Subscription;
  roles: RoleV2[];
  parentCompany: CompanyV2;

  constructor(
    private companyService: CompanyService,
    private companyJPIService: CompanyJPIService,
    private territoryService: TerritoryService,
    private roleService: RoleService,
    private customFieldService: CustomFieldService
  ) {}

  ngOnInit() {
    this.getData();

    this.territorySubscription =
      this.territoryService.territoryChangedAnnounced.subscribe(
        (territoryChanged: boolean) => {
          if (territoryChanged == true) {
            this.loadTerritories();
            this.territoryService.territoryChangedInPopup(false);
          }
        }
      );
  }

  async getData(): Promise<void> {
    this.companyDetails = await this.customFieldService.getCompanyCustomFields(
      this.companyJPI.id
    );
    this.companyDetails = (<any>(
      this.companyDetails.filter((section) => section.Id === 0)[0]
    )).Fields;

    this.assignments = await this.companyService.getCompanyAssignments(
      this.companyJPI.id
    );
    this.roles = await this.roleService.getAll();
    await this.loadTerritories();
    if (this.companyJPI.parentCompanyId) {
      this.getParentCompany();
    }
  }

  async getParentCompany() {
    this.companyJPI.parentCompanyId
      ? (this.parentCompany = await this.companyJPIService.getById(
          this.companyJPI.parentCompanyId
        ))
      : (this.parentCompany = null);
  }

  ngOnDestroy(): void {
    this.territorySubscription.unsubscribe();
  }

  loadTerritories(): void {
    this.companyJPI.territories.sort((a: TerritoryV2, b: TerritoryV2) =>
      a.name.localeCompare(b.name)
    );
    this.territoriesAndRepsGrid = [];
    for (const territory of this.companyJPI.territories) {
      if (territory.users && territory.users.length > 0) {
        for (const user of territory.users) {
          this.territoriesAndRepsGrid.push({
            territory: territory.name,
            id: territory.id,
            name: `${user.firstName} ${user.lastName}`,
            role: this.getUserRole(user.roleId),
            sales: this.isSales(user.id),
            mailbox: user.mailbox
          });
        }
      } else {
        this.territoriesAndRepsGrid.push({
          territory: territory.name,
          id: territory.id,
          name: 'This territory has no sales reps'
        });
      }
    }
    this.territoriesAndRepsGrid.sort((a: any, b: any) => a.name.localeCompare(b.name));
  }

  getUserRole(roleId: number): string {
    const role = this.roles.filter((role) => role.id === roleId)[0];
    return role ? role.name : '';
  }

  isSales(id: number): boolean {
    const salesRep = this.assignments.filter((rep) => rep.AgentId == id);
    for (const rep of salesRep) {
      return rep.associateSales;
    }
  }

  openExternalWebsite(webURL: string): void {
    if (webURL && (webURL.startsWith('http://') || webURL.startsWith('https://'))) {
      window.open(webURL, '_blank');
    } else {
      window.open('http://' + webURL, '_blank');
    }
  }

  salesChanged(e: any, rowData: any): void {
    this.companyService
      .setCompanyAssignment(this.companyJPI.id, rowData.mailbox, e.value)
      .then(() => {});
  }

  openParentCompany(): void {
    window.open(`/companies/company/${this.companyJPI.parentCompanyId}`, '_blank');
  }
}
