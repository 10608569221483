import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { RouteDatum } from 'src/app/models/routing/route-datum.model';
import { RootState } from 'src/app/store/store.reducer';
import { ToggleOpenSidenav } from 'src/app/store/layout/layout.actions';
import { selectSidenavLinks } from './sidenav-links.selector';

@Component({
  selector: 'tn-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  $sidenavExpanded: Observable<boolean>;
  $links: Observable<RouteDatum[]>;

  constructor(private store: Store<RootState>) {}

  ngOnInit() {
    this.$links = this.store.select(selectSidenavLinks);
    this.$sidenavExpanded = this.store.select('layout', 'sidenavOpened');
  }

  toggleOpenSidenav() {
    this.store.dispatch(new ToggleOpenSidenav());
  }
}
