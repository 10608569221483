<div class="results-container">
  <div fxLayout="column" class="results-header mat-elevation-z5">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="row" fxLayoutGap="24px" fxLayoutAlign="start center">
        <h3>Call Notes</h3>
        <div>{{ ($notes | async | callNoteFilter: searchText).length }} results</div>
      </div>

      <button mat-icon-button (click)="closeLeftDrawer()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <mat-form-field>
      <span class="input-icon" matPrefix> <mat-icon>search</mat-icon>&nbsp; </span>
      <input
        matInput
        [(ngModel)]="searchText"
        autocomplete="off"
        placeholder="Filter call notes"
      />
    </mat-form-field>
  </div>
  <div
    *ngIf="$pending | async; else notPending"
    fxLayoutAlign="center center"
    [style.marginTop]="'24px'"
  >
    <mat-spinner></mat-spinner>
  </div>
  <ng-template #notPending>
    <cdk-virtual-scroll-viewport
      autosize
      class="cdk-viewport"
      minBufferPx="400"
      maxBufferPx="1000"
    >
      <tn-call-note-card
        *cdkVirtualFor="let note of $notes | async | callNoteFilter: searchText"
        [callNote]="note"
      ></tn-call-note-card>
    </cdk-virtual-scroll-viewport>
  </ng-template>
</div>
