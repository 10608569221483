import { ActionReducerMap, MetaReducer, ActionReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';

import { environment } from '../../environments/environment';
import { InitState, initReducer } from './init/init.reducer';
import { AuthState, authReducer } from './auth/auth.reducer';
import { companiesReducer, CompaniesState } from './companies/companies.reducer';
import { RouterReducerState, routerReducer } from '@ngrx/router-store';
import { SalesState, salesReducer } from './sales/sales.reducer';
import { ReportsState, reportsReducer } from './reports/reports.reducer';
import {
  OpportunitiesState,
  opportunitiesReducer
} from './opportunities/opportunities.reducer';
import { MileageState, mileageReducer } from './mileage/mileage.reducer';
import { MapsState, mapsReducer } from './maps/maps.reducer';
import { ExpensesState, expensesReducer } from './expenses/expenses.reducer';
import { DashboardState, dashboardReducer } from './dashboard/dashboard.reducer';
import { LayoutState, layoutReducer } from './layout/layout.reducer';
import { WhatsNewState, whatsNewReducer } from './whats-new/whats-new.reducer';
import { TutorialsState, tutorialsReducer } from './tutorials/tutorials.reducer';
import { HelpState, helpReducer } from './help/help.reducer';
import {
  userSettingsReducer,
  UserSettingsState
} from './user-settings/user-settings.reducer';
import { systemSettingsReducer } from './system-settings/system-settings.reducer';
import { SystemSettingsStateModule } from './system-settings/system-settings.module';
import {
  TerritoryManagementState,
  territoryManagementReducer
} from './territory-management/territory-management.reducer';
import {
  ManageUsersState,
  manageUsersReducer
} from './manage-users/manage-users.reducer';
import { ClientsState, clientsReducer } from './clients/clients.reducer';
import { billingReducer, BillingState } from './billing/billing.reducer';
import { UsersState, usersReducer } from './users/users.reducer';
import {
  contactCustomFieldsReducer,
  ContactCustomFieldsState
} from './system-settings/contact-custom-fields/contact-custom-fields.reducer';
import {
  CallNoteCustomFieldsState,
  callNoteCustomFieldsReducer
} from './system-settings/call-notes/call-note-custom-fields.reducer';
import { DeletedState, undoDeleteReducer } from './undo-delete/undo-delete.reducer';
import {
  CompanyFieldsState,
  companyFieldsReducer
} from './system-settings/company-fields/company-fields.reducer';
import {
  FieldTypesState,
  fieldTypesReducer
} from './system-settings/field-types/field-types.reducer';
import {
  projectBidsReducer,
  ProjectBidsState
} from './system-settings/project-bids/project-bids.reducer';
import { ProjectsState, projectsReducer } from './projects/projects.reducer';
import { PlansState, plansReducer } from './plans/plans.reducer';
import {
  CompanyLabelsState,
  companyLabelsReducer
} from './system-settings/company-labels/company-labels.reducer';
import {
  CallTypesState,
  callTypesReducer
} from './system-settings/call-types/call-types.reducer';
import {
  ProjectFieldsState,
  projectFieldsReducer
} from './system-settings/project-fields/project-fields.reducer';
import {
  ProjectLabelsState,
  projectLabelsReducer
} from './system-settings/project-labels/project-labels.reducer';
// eslint-disable-next-line max-len
import {
  ProjectLabelsIndividualState,
  projectLabelsIndividualReducer
} from './system-settings/project-labels-individual/project-labels-individiual.reducer';
import {
  CompanyLabelsIndividualState,
  companyLabelsIndividualReducer
} from './system-settings/company-labels-individual/company-labels-individual.reducer';
import {
  ProductLinesState,
  productLinesReducer
} from './product-lines/product-lines.reducer';
import { CallNotesState, callNotesReducer } from './call-notes/call-notes.reducer';
import {
  CallNoteTypesState,
  callNoteTypesReducer
} from './call-note-types/call-note-types.reducer';
import {
  ExpenseTypesState,
  expenseTypesReducer
} from './system-settings/expense-types/expense-types.reducer';
import {
  GlobalSearchState,
  globalSearchReducer
} from './global-search/global-search.reducer';
import {
  OpportunityLabelsState,
  opportunityLabelsReducer
} from './system-settings/opportunity-labels/opportunity-labels/opportunity-labels.reducer';
import {
  ItemNamesState,
  itemNamesReducer
} from './system-settings/opportunity-item-names/opportunity-item-names.reducer';
import {
  opportunityLabelsIndividualReducer,
  OpportunityLabelsIndividualState
} from './system-settings/opportunity-labels-individual/opportunity-labels-individual.reducer';
import {
  FileCategoriesState,
  fileCategoriesReducer
} from './system-settings/file-categories/file-categories.reducer';
import { ContactsState, contactsReducer } from './contacts/contacts.reducer';
import {
  projectCustomFieldsReducer,
  ProjectCustomFieldsState
} from './system-settings/project-custom-fields/project-custom-fields.reducer';
import {
  bidCustomFieldsReducer,
  BidCustomFieldsState
} from './system-settings/bid-custom-fields/bid-custom-fields.reducer';
import {
  projectCompanyCustomFieldsReducer,
  ProjectCompanyCustomFieldsState
} from './system-settings/proj-comp-custom-fields/project-company-custom-fields.reducer';
import {
  projectManufCustomFieldsReducer,
  ProjectManufCustomFieldsState
} from './system-settings/proj-manuf-custom-fields/project-manuf-custom-fields.reducer';
import { TodosState, todosReducer } from './todos/todos.reducer';
import { calendarReducer, CalendarState } from './calendar/calendar.reducer';
import {
  dailyActivitiesReducer,
  DailyActivitiesState
} from './daily-activities/daily-activities.reducer';
import {
  PrivateNotesState,
  privateNotesReducer
} from './private-notes/private-notes.reducer';
import { DmsState, dmsReducer } from './dms/dms.reducer';

export interface RootState {
  router?: RouterReducerState;
  init?: InitState;
  layout?: LayoutState;
  globalSearch?: GlobalSearchState;
  auth?: AuthState;
  users?: UsersState;
  dashboard?: DashboardState;
  companies?: CompaniesState;
  bidCustomFields?: BidCustomFieldsState;
  maps?: MapsState;
  opportunities?: OpportunitiesState;
  projectBids?: ProjectBidsState;
  sales?: SalesState;
  reports?: ReportsState;
  mileage?: MileageState;
  expenses?: ExpensesState;
  tutorials?: TutorialsState;
  whatsNew?: WhatsNewState;
  help?: HelpState;
  userSettings?: UserSettingsState;
  systemSettings?: SystemSettingsStateModule;
  territoryManagement?: TerritoryManagementState;
  manageUsers?: ManageUsersState;
  clients?: ClientsState;
  plans?: PlansState;
  billing?: BillingState;
  contactCustomFields?: ContactCustomFieldsState;
  callNoteCustomFields?: CallNoteCustomFieldsState;
  callTypes?: CallTypesState;
  undoDelete?: DeletedState;
  companyFields?: CompanyFieldsState;
  companyLabels?: CompanyLabelsState;
  companyLabelsIndividual?: CompanyLabelsIndividualState;
  fieldTypes?: FieldTypesState;
  projects?: ProjectsState;
  projectCustomFields?: ProjectCustomFieldsState;
  projectCompanyCustomFields?: ProjectCompanyCustomFieldsState;
  projectManufCustomFields?: ProjectManufCustomFieldsState;
  projectFields?: ProjectFieldsState;
  projectLabels?: ProjectLabelsState;
  projectLabelsIndividual?: ProjectLabelsIndividualState;
  productLines?: ProductLinesState;
  callNotes?: CallNotesState;
  callNoteTypes?: CallNoteTypesState;
  expenseTypes?: ExpenseTypesState;
  opportunityLabels?: OpportunityLabelsState;
  itemNames?: ItemNamesState;
  opportunityLabelsIndividual?: OpportunityLabelsIndividualState;
  fileCategories?: FileCategoriesState;
  contacts?: ContactsState;
  dailyActivities?: DailyActivitiesState;
  todos?: TodosState;
  calendar?: CalendarState;
  privateNotes?: PrivateNotesState;
  dms?: DmsState;
}

export const reducers: ActionReducerMap<RootState> = {
  init: initReducer,
  router: routerReducer,
  auth: authReducer,
  bidCustomFields: bidCustomFieldsReducer,
  billing: billingReducer,
  calendar: calendarReducer,
  callNoteCustomFields: callNoteCustomFieldsReducer,
  callNotes: callNotesReducer,
  callNoteTypes: callNoteTypesReducer,
  callTypes: callTypesReducer,
  clients: clientsReducer,
  companies: companiesReducer,
  companyFields: companyFieldsReducer,
  companyLabels: companyLabelsReducer,
  companyLabelsIndividual: companyLabelsIndividualReducer,
  contacts: contactsReducer,
  contactCustomFields: contactCustomFieldsReducer,
  dailyActivities: dailyActivitiesReducer,
  dashboard: dashboardReducer,
  expenses: expensesReducer,
  expenseTypes: expenseTypesReducer,
  fieldTypes: fieldTypesReducer,
  fileCategories: fileCategoriesReducer,
  globalSearch: globalSearchReducer,
  help: helpReducer,
  itemNames: itemNamesReducer,
  layout: layoutReducer,
  manageUsers: manageUsersReducer,
  maps: mapsReducer,
  mileage: mileageReducer,
  opportunities: opportunitiesReducer,
  opportunityLabels: opportunityLabelsReducer,
  opportunityLabelsIndividual: opportunityLabelsIndividualReducer,
  plans: plansReducer,
  productLines: productLinesReducer,
  projectBids: projectBidsReducer,
  projectCustomFields: projectCustomFieldsReducer,
  projectCompanyCustomFields: projectCompanyCustomFieldsReducer,
  projectManufCustomFields: projectManufCustomFieldsReducer,
  projectFields: projectFieldsReducer,
  projectLabels: projectLabelsReducer,
  projectLabelsIndividual: projectLabelsIndividualReducer,
  projects: projectsReducer,
  reports: reportsReducer,
  sales: salesReducer,
  systemSettings: systemSettingsReducer,
  territoryManagement: territoryManagementReducer,
  tutorials: tutorialsReducer,
  undoDelete: undoDeleteReducer,
  users: usersReducer,
  userSettings: userSettingsReducer,
  whatsNew: whatsNewReducer,
  todos: todosReducer,
  privateNotes: privateNotesReducer,
  dms: dmsReducer
};
export const localStorageKeys = [
  { layout: ['defaultLandingPage'] },
  { auth: ['data', 'inflightUrl', 'oktaState'] },
  {
    projects: [
      'useDefaultColumns',
      'selectedTable',
      'selColsByProj',
      'selColsByManuf',
      'selColsByComp',
      'selColsByBid'
    ]
  },
  {
    opportunities: [
      'useDefaultColumns',
      'selectedTable',
      'selColsByOpp',
      'selColsByItem',
      'customOppFields',
      'customItemFields'
    ]
  },
  { calendar: ['selectedView'] },
  {
    dashboard: [
      {
        queryParams: ['userIds', 'territoryIds', 'callTypeIds', 'callFieldIds']
      }
    ]
  },
  { expenses: ['expenseColumns', 'selectedExpenseColumns'] },
  { fieldTypes: ['data'] },
  { callNoteCustomFields: ['dropdownOptions'] },
  { projectLabels: ['data'] },
  { opportunityLabels: ['data'] },
  { projectCustomFields: ['data'] },
  { projectCompanyCustomFields: ['data'] },
  { companyLabels: ['data'] },
  { projectManufCustomFields: ['data'] },
  { bidCustomFields: ['data'] },
  { users: ['data'] },
  {
    companies: ['useNewCompanyProfile', 'expansionPanels']
  },
  {
    sales: ['useNewSalesModule']
  },
  {
    todos: ['showTodos']
  }
];
export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: localStorageKeys,
    rehydrate: true
  })(reducer);
}

const univMetaReducers: MetaReducer<RootState>[] = [localStorageSyncReducer];

export const metaReducers: MetaReducer<RootState>[] = univMetaReducers;
