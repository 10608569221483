import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Store } from '@ngrx/store';
import * as _moment from 'moment';
import moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { CloseDrawer } from 'src/app/store/layout/layout.actions';
import { RootState } from 'src/app/store/store.reducer';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ElasticService } from 'src/app/services/elastic/elastic.service';
import {
  LoadCallNotesByCallField,
  LoadCallNotesByCallType,
  LoadCallNotesByRep,
  LoadDailyActivity
} from 'src/app/store/daily-activities/daily-activities.actions';
import { Observable, Subscription } from 'rxjs';
import {
  selectMultiSelectUsers,
  selectUsers
} from 'src/app/store/users/selectors/multi-select-users.selector';
import { DateQuery } from 'src/app/models/elastic/date-query';
import { IdNameItem } from 'src/app/models/id-name-item';
import { Router } from '@angular/router';
import { ProjectCallNoteCommentDialogComponent } from '../../pages/projects/project-call-note/project-call-note-comment-dialog/project-call-note-comment-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DataNotFoundComponent } from '../data-not-found/data-not-found.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'tn-daily-activity',
  templateUrl: './daily-activity.component.html',
  styleUrls: ['./daily-activity.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class DailyActivityComponent implements OnInit, OnDestroy {
  @Input() data?: any;
  darData: {
    dar: boolean;
    callnoteId: number | null;
    comment: boolean | null;
    date: Date | null;
    userId: number | null;
    callNote?: any;
  };
  initialDate: string;
  today: Date;
  weekday: string;
  $usersVisibilitySub: Subscription;
  usersVisibility: IdNameItem[];
  $totalCallNotesSub: Subscription;
  totalCallNotes: number;

  $averageCallNotesSub: Subscription;
  averageCallNotes: number;

  seeAllSalesReps: boolean;
  seeAllCallTypes: boolean;
  seeAllCallNoteFields: boolean;
  seeType: boolean;
  seeField: boolean;
  seeRep: boolean;
  seeMore: boolean;

  $RepsSub: Subscription;
  $RepTotalsSub: Subscription;
  repTotals = [];
  topReps = [];
  allReps = [];
  allRepsMaster = [];
  allUsers = [];

  $topCallTypesSub: Subscription;
  topCallTypes = [];
  allCallTypes = [];

  repSearch = '';
  $averageCallNoteFieldSub: Subscription;
  selectedRep;
  repCallNotes;
  $repCallNotesSub: Subscription;
  selectedType;
  typeCallNotes;
  selectedField;
  fieldCallNotes;
  $averageCallTypesSub: Subscription;
  $topCallNoteFieldsSub: Subscription;
  topCallNoteFields = [];
  allCallNoteFields = [];

  @ViewChildren('tnCallNote', { read: ElementRef }) callNotes: QueryList<ElementRef>;

  constructor(
    private store: Store<RootState>,
    private elasticService: ElasticService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (this.data?.dar) {
      this.darData = this.data;
      this.clearQueryParams();
    }
    this.$usersVisibilitySub = this.store
      .select(selectMultiSelectUsers)
      .subscribe((users) => {
        this.allUsers = users;
      });
    if (this.data?.date) {
      this.initialDate = moment(this.data.date)
        .startOf('day')
        .format('YYYY-MM-DD HH:mm:ss');
    } else {
      this.initialDate = moment()
        .subtract(1, 'days')
        .startOf('day')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    this.today = new Date();
    const initialDateEndOfDay = moment(this.initialDate)
      .endOf('day')
      .format('YYYY-MM-DD HH:mm:ss');
    const monthLookBack = moment(this.initialDate)
      .subtract(30, 'days')
      .startOf('day')
      .format('YYYY-MM-DD HH:mm:ss');
    const query: DateQuery = {
      initialDate: this.initialDate,
      initialDateEndOfDay,
      monthLookBack
    };

    this.store.dispatch(new LoadDailyActivity(query));
    this.seeAllSalesReps = false;
    this.seeAllCallTypes = false;
    this.seeAllCallNoteFields = false;
    this.seeMore = false;
    this.getWeekDay();
    // get all these to a selector
    this.$totalCallNotesSub = this.store
      .select(
        'dailyActivities',
        'data',
        'aggregations',
        'total_call_notes',
        'buckets',
        '0',
        'doc_count'
      )
      .subscribe((total) => {
        this.totalCallNotes = total;
      });
    this.$averageCallNotesSub = this.store
      .select(
        'dailyActivities',
        'data',
        'aggregations',
        'total_call_notes_past_30_days',
        'buckets',
        '0',
        'doc_count'
      )
      .subscribe((average) => {
        this.averageCallNotes = Math.round(average / 30);
      });

    this.repCalc();
    this.callTypeCalc();
    this.callNoteFieldCalc();
  }

  ngOnDestroy() {
    this.$totalCallNotesSub.unsubscribe();
    this.$averageCallNotesSub.unsubscribe();
    this.$RepTotalsSub.unsubscribe();
    this.$RepsSub.unsubscribe();
    this.$topCallTypesSub.unsubscribe();
    this.$usersVisibilitySub.unsubscribe();
    if (this.$averageCallTypesSub) {
      this.$averageCallTypesSub.unsubscribe();
    }
    if (this.$averageCallNoteFieldSub) {
      this.$averageCallNoteFieldSub.unsubscribe();
    }
    this.$repCallNotesSub.unsubscribe();
  }

  repCalc() {
    this.$RepTotalsSub = this.store
      .select(
        'dailyActivities',
        'data',
        'aggregations',
        'total_call_notes_past_30_days',
        'buckets',
        '0',
        'totals_by_rep',
        'buckets'
      )
      .subscribe((totals) => {
        this.repTotals = totals;
      });
    this.$RepsSub = this.store
      .select(
        'dailyActivities',
        'data',
        'aggregations',
        'total_call_notes',
        'buckets',
        '0',
        'totals_by_rep',
        'buckets'
      )
      .subscribe((reps) => {
        if (reps?.length > 0) {
          this.allUsers.map((user) => {
            const rep = this.repTotals.find((object) => object.key == user.mailbox);
            const index = reps.findIndex((object) => object.key == user.mailbox);
            if (index === -1) {
              if (rep) {
                user.dailyAverage = Math.round(rep.doc_count / 30);
              } else {
                user.dailyAverage = 0;
              }
              user.fullName = user.firstName + ' ' + user.lastName;
              user.difference = user.dailyAverage;
              if (user.dailyAverage > 0) {
                user.direction = 'negative';
              } else {
                user.direction = 'neutral';
              }
              user.doc_count = 0;
              reps.push(user);
            }
          });
          reps.map((currentWeekRep) => {
            if (currentWeekRep.key) {
              currentWeekRep.fullName =
                currentWeekRep.rep_info.hits.hits[0]._source.assignedToFirstName +
                ' ' +
                currentWeekRep.rep_info.hits.hits[0]._source.assignedToLastName;
              const rep = this.repTotals.find(
                (object) => object.key == currentWeekRep.key
              );
              if (rep) {
                currentWeekRep.dailyAverage = Math.round(rep.doc_count / 30);
                if (currentWeekRep.doc_count > currentWeekRep.dailyAverage) {
                  currentWeekRep.direction = 'positive';
                  currentWeekRep.difference = Math.round(
                    currentWeekRep.doc_count - currentWeekRep.dailyAverage
                  );
                } else if (currentWeekRep.dailyAverage > currentWeekRep.doc_count) {
                  currentWeekRep.direction = 'negative';
                  currentWeekRep.difference = Math.round(
                    currentWeekRep.dailyAverage - currentWeekRep.doc_count
                  );
                } else {
                  currentWeekRep.direction = 'neutral';
                  currentWeekRep.difference = 0;
                }
              } else {
                currentWeekRep.direction = 'neutral';
                currentWeekRep.difference = 0;
              }
            }
          });
          // reps = reps.filter((el) => {
          //   return this.allUsers.some((u) => {
          //     return u.id === el.id;
          //   });
          // });
          this.topReps = reps.slice(0, 3);
          //rep_info is not always populated, so let's filter if object does not exist.  I believe this is due to no hits or callnotes.
          this.allReps = reps.filter((hasHits) => hasHits.rep_info != null);
          this.allRepsMaster = reps;

          //Found that _source.assignedToId is not always populated, so, I am getting the mailbox to associate with the Rep key.  Then we will compare on both mailbox as well as assignedTo.
          const repMb = this.allUsers.find((rep) => rep.id === this.darData.userId);

          if (this.darData?.dar && reps.length > 0) {
            var rep = this.getRep(this.darData.userId, repMb.mailbox, this.allReps);
            if (rep != null) {
              this.toggleSeeRep(rep, 'open', this.darData.userId);
            }
          }
        }
      });
  }

  getRep(userId, mbId, allReps) {
    const rep = this.allReps.find(
      (rep) =>
        rep.rep_info.hits.hits[0]._source.assignedToId === userId || rep.key === mbId
    );
    return rep;
  }
  callTypeCalc() {
    this.$topCallTypesSub = this.store
      .select(
        'dailyActivities',
        'data',
        'aggregations',
        'total_call_notes',
        'buckets',
        '0',
        'totals_by_call_type',
        'buckets'
      )
      .subscribe((callTypes) => {
        if (callTypes?.length > 0) {
          callTypes.map((currentCallType) => {
            currentCallType.direction = 'positive';
            currentCallType.difference = currentCallType.doc_count;
            this.$averageCallTypesSub = this.store
              .select(
                'dailyActivities',
                'data',
                'aggregations',
                'total_call_notes_past_30_days',
                'buckets',
                '0',
                'totals_by_call_type',
                'buckets'
              )
              .subscribe((totalCallTypes) => {
                totalCallTypes.map((total) => {
                  if (currentCallType.key === total.key) {
                    currentCallType.dailyAverage = Math.round(total.doc_count / 30);
                    if (currentCallType.doc_count > currentCallType.dailyAverage) {
                      currentCallType.direction = 'positive';
                      currentCallType.difference =
                        currentCallType.doc_count - currentCallType.dailyAverage;
                    } else if (currentCallType.dailyAverage > currentCallType.doc_count) {
                      currentCallType.direction = 'negative';
                      currentCallType.difference =
                        currentCallType.dailyAverage - currentCallType.doc_count;
                    } else {
                      currentCallType.direction = 'neutral';
                      currentCallType.difference = 0;
                    }
                  }
                });
              });
          });
          this.topCallTypes = callTypes.slice(0, 3);
          this.allCallTypes = callTypes;
        }
      });
  }

  callNoteFieldCalc() {
    this.$topCallNoteFieldsSub = this.store
      .select(
        'dailyActivities',
        'data',
        'aggregations',
        'total_call_notes',
        'buckets',
        '0',
        'totals_by_call_note_field',
        'call_note_fields_values',
        'buckets'
      )
      .subscribe((callNoteFields) => {
        if (callNoteFields?.length > 0) {
          callNoteFields.map((currentCallNoteField) => {
            currentCallNoteField.direction = 'positive';
            currentCallNoteField.difference = currentCallNoteField.doc_count;
            this.$averageCallNoteFieldSub = this.store
              .select(
                'dailyActivities',
                'data',
                'aggregations',
                'total_call_notes_past_30_days',
                'buckets',
                '0',
                'totals_by_call_note_field',
                'call_note_fields_values',
                'buckets'
              )
              .subscribe((totalCallFields) => {
                totalCallFields.map((total) => {
                  if (currentCallNoteField.key === total.key) {
                    currentCallNoteField.dailyAverage = Math.round(total.doc_count / 30);
                  }
                  if (
                    currentCallNoteField.doc_count > currentCallNoteField.dailyAverage
                  ) {
                    currentCallNoteField.direction = 'positive';
                    currentCallNoteField.difference =
                      currentCallNoteField.doc_count - currentCallNoteField.dailyAverage;
                  } else if (
                    currentCallNoteField.dailyAverage > currentCallNoteField.doc_count
                  ) {
                    currentCallNoteField.direction = 'negative';
                    currentCallNoteField.difference =
                      currentCallNoteField.dailyAverage - currentCallNoteField.doc_count;
                  } else {
                    currentCallNoteField.direction = 'neutral';
                    currentCallNoteField.difference = 0;
                  }
                });
              });
          });
          this.topCallNoteFields = callNoteFields.slice(0, 3);
          this.allCallNoteFields = callNoteFields;
        }
      });
  }

  searchReps() {
    if (this.repSearch.length > 0) {
      this.allReps = this.allRepsMaster.filter((rep) => {
        if (rep.key) {
          return (
            rep.rep_info.hits.hits[0]._source.createdByFirstName
              .toLowerCase()
              .includes(this.repSearch.toLowerCase()) ||
            rep.rep_info.hits.hits[0]._source.createdByLastName
              .toLowerCase()
              .includes(this.repSearch.toLowerCase()) ||
            rep.fullName.toLowerCase().includes(this.repSearch.toLowerCase())
          );
        } else {
          return (
            rep.firstName.toLowerCase().includes(this.repSearch.toLowerCase()) ||
            rep.lastName.toLowerCase().includes(this.repSearch.toLowerCase()) ||
            rep.fullName.toLowerCase().includes(this.repSearch.toLowerCase())
          );
        }
      });
    } else {
      this.allReps = this.allRepsMaster;
    }
  }

  clearRepSearch() {
    this.repSearch = '';
    this.searchReps();
  }

  close() {
    this.store.dispatch(new CloseDrawer());
  }

  toggleSeeAllSalesReps() {
    this.seeMore = !this.seeMore;
    this.seeAllSalesReps = !this.seeAllSalesReps;
  }

  toggleSeeAllCallTypes() {
    this.seeMore = !this.seeMore;
    this.seeAllCallTypes = !this.seeAllCallTypes;
  }

  toggleSeeAllCallNoteFields() {
    this.seeMore = !this.seeMore;
    this.seeAllCallNoteFields = !this.seeAllCallNoteFields;
  }

  toggleSeeRep(rep, action, repId) {
    if (action === 'open') {
      this.seeMore = true;
      this.seeRep = true;
      this.seeAllSalesReps = false;
      this.selectedRep = rep;
      const foundRep = this.allUsers.find((flt) => flt.mailbox === rep.key);
      if (foundRep != null) {
        repId = foundRep.id;
      }
      const query = {
        repId: repId,
        initialDate: this.initialDate,
        initialDateEndOfDay: moment(this.initialDate)
          .endOf('day')
          .format('YYYY-MM-DD HH:mm:ss')
      };
      this.store.dispatch(new LoadCallNotesByRep(query));
      this.repCallNotes = this.store.select(
        'dailyActivities',
        'repCallNotes',
        'hits',
        'hits'
      );
      this.$repCallNotesSub = this.repCallNotes.subscribe((data) => {
        if (this.darData?.dar && data?.length > 0) {
          const callNoteData = data.find(
            (callNote) => callNote._source.id === this.darData.callnoteId
          )?._source;
          if (callNoteData) {
            this.darData.callNote = callNoteData;
            setTimeout(() => {
              this.scrollToCallNote(
                data.findIndex(
                  (callNote) => callNote._source.id === this.darData.callnoteId
                )
              );
            });
          } else {
            this.callNoteNotFound();
          }
        }
      });
    } else {
      this.seeMore = false;
      this.seeRep = false;
      this.selectedRep = undefined;
    }
  }

  callNoteNotFound() {
    const dialogRef = this.dialog.open(DataNotFoundComponent, {
      width: '500px',
      data: {
        confirmHeader: 'Call Note Record Not Found',
        confirmMessage: `The call note you are looking for "Id: ${this.darData.callnoteId}"  is not available.`
      }
    });
    this.darData.dar = false;
  }

  scrollToCallNote(index: number): void {
    const tnCallNoteArray = this.callNotes.toArray();
    if (index >= 0 && index < tnCallNoteArray.length) {
      const element = tnCallNoteArray[index].nativeElement;
      element.scrollIntoView({ behavior: 'smooth' });
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            observer.disconnect();
            if (this.darData.comment) {
              const dialogRef = this.dialog.open(ProjectCallNoteCommentDialogComponent, {
                width: '500px',
                data: {
                  ...this.darData.callNote,
                  createdBy: {
                    id: this.darData.callNote.createdById
                  }
                }
              });
            }
          }
        },
        { threshold: 0.1 }
      );
      observer.observe(element);
      this.darData.dar = false;
    }
  }

  clearQueryParams(): void {
    this.router.navigate([], {
      queryParams: {}
    });
  }

  toggleSeeType(type, action) {
    if (action === 'open') {
      this.seeMore = true;
      this.seeType = true;
      this.seeAllCallTypes = false;
      this.selectedType = type;
      const query = {
        callNoteTypeId: this.selectedType.key,
        initialDate: this.initialDate,
        initialDateEndOfDay: moment(this.initialDate)
          .endOf('day')
          .format('YYYY-MM-DD HH:mm:ss')
      };
      this.store.dispatch(new LoadCallNotesByCallType(query));
      this.typeCallNotes = this.store.select(
        'dailyActivities',
        'callTypeCallNotes',
        'hits',
        'hits'
      );
    } else {
      this.seeMore = false;
      this.seeType = false;
      this.selectedType = undefined;
    }
  }

  toggleSeeField(field, action) {
    if (action === 'open') {
      this.seeMore = true;
      this.seeField = true;
      this.seeAllCallNoteFields = false;
      this.selectedField = field;
      const query = {
        callNoteFieldId: this.selectedField.key,
        initialDate: this.initialDate,
        initialDateEndOfDay: moment(this.initialDate)
          .endOf('day')
          .format('YYYY-MM-DD HH:mm:ss')
      };
      this.store.dispatch(new LoadCallNotesByCallField(query));
      this.fieldCallNotes = this.store.select(
        'dailyActivities',
        'callFieldCallNotes',
        'hits',
        'hits'
      );
    } else {
      this.seeMore = false;
      this.seeField = false;
      this.selectedField = undefined;
    }
  }

  getWeekDay() {
    const dayNumber = moment(this.initialDate).isoWeekday();
    switch (dayNumber) {
      case 0:
        this.weekday = 'Sunday';
        break;
      case 1:
        this.weekday = 'Monday';
        break;
      case 2:
        this.weekday = 'Tuesday';
        break;
      case 3:
        this.weekday = 'Wednesday';
        break;
      case 4:
        this.weekday = 'Thursday';
        break;
      case 5:
        this.weekday = 'Friday';
        break;
      case 6:
        this.weekday = 'Saturday';
        break;
    }
  }

  dateChange(event: MatDatepickerInputEvent<Date>) {
    this.clearRepSearch();
    const date = moment(event.value).toDate();
    this.initialDate = moment(date).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    this.getWeekDay();
    const initialDateEndOfDay = moment(date).endOf('day').format('YYYY-MM-DD HH:mm:ss');
    const monthLookBack = moment(date)
      .subtract(30, 'days')
      .startOf('day')
      .format('YYYY-MM-DD HH:mm:ss');
    const dateQuery: DateQuery = {
      initialDate: this.initialDate,
      initialDateEndOfDay,
      monthLookBack
    };

    this.store.dispatch(new LoadDailyActivity(dateQuery));
    if (this.selectedRep) {
      const repQuery = {
        repId: this.selectedRep.key,
        initialDate: this.initialDate,
        initialDateEndOfDay
      };
      this.store.dispatch(new LoadCallNotesByRep(repQuery));
    }
    if (this.selectedType) {
      const typeQuery = {
        callNoteTypeId: this.selectedType.key,
        initialDate: this.initialDate,
        initialDateEndOfDay
      };
      this.store.dispatch(new LoadCallNotesByCallType(typeQuery));
    }
    if (this.selectedField) {
      const fieldQuery = {
        callNoteFieldId: this.selectedField.key,
        initialDate: this.initialDate,
        initialDateEndOfDay
      };
      this.store.dispatch(new LoadCallNotesByCallField(fieldQuery));
    }
  }
}
