import { MemoizedSelector, createSelector } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import { selectAllCompanyLabels } from './all-companyLabels-as-array.selector';
import { LabelGroup } from 'src/app/models/label-group';

export const selectedCompanyLabelId = (state: RootState) =>
  state.companyLabels.selectedCompanyLabelId;

export const selectSelectedCompanyLabel: MemoizedSelector<
  RootState,
  LabelGroup
> = createSelector(
  selectAllCompanyLabels,
  selectedCompanyLabelId,
  (
    companyLabelIndividualMap: { [id: number]: LabelGroup },
    companyLabelId: number
  ): LabelGroup => {
    let companyLabel: LabelGroup;
    if (!!companyLabelId) {
      const id = companyLabelId.toString();
      companyLabel = {
        ...companyLabelIndividualMap[id]
      };
    }
    return companyLabel;
  }
);
