import { Action } from '@ngrx/store';
import { Todo } from 'src/app/models/todo/todo';
import { HttpErrorResponse } from '@angular/common/http';

export enum TodosActionTypes {
  SetLocalTodos = '[Todos] Set Local Storage For Todos',
  LoadCompleteTodos = '[Todos] Load Complete Todos',
  LoadCompleteTodosSuccess = '[Todos] Load Complete Todos Success',
  LoadCompleteTodosFailure = '[Todos] Load Complete Todos Failure',
  LoadIncompleteTodos = '[Todos] Load Incomplete Todos',
  LoadIncompleteTodosSuccess = '[Todos] Load Incomplete Todos Success',
  LoadIncompleteTodosFailure = '[Todos] Load Incomplete Todos Failure',
  CreateTodo = '[Todos] Create Todo',
  CreateTodoSuccess = '[Todos] Create Todo Success',
  CreateTodoFailure = '[Todos] Create Todo Failure',
  UpdateTodo = '[Todos] Update Todo',
  UpdateTodoSuccess = '[Todos] Update Todo Success',
  UpdateTodoFailure = '[Todos] Update Todo Failure',
  ReorderTodos = '[Todos] Reorder Todos',
  ReorderTodosSuccess = '[Todos] Reorder Todos Success',
  ReorderTodosFailure = '[Todos] Reorder Todos Failure',
  DeleteTodo = '[Todos] Delete Todo',
  DeleteTodoSuccess = '[Todos] Delete Todo Success',
  DeleteTodoFailure = '[Todos] Delete Todo Failure'
}

export class SetLocalTodos implements Action {
  readonly type = TodosActionTypes.SetLocalTodos;
  constructor(public payload: boolean) {}
}

export class LoadCompleteTodos implements Action {
  readonly type = TodosActionTypes.LoadCompleteTodos;
}

export class LoadCompleteTodosSuccess implements Action {
  readonly type = TodosActionTypes.LoadCompleteTodosSuccess;
  constructor(public payload: Todo[]) {}
}

export class LoadCompleteTodosFailure implements Action {
  readonly type = TodosActionTypes.LoadCompleteTodosFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadIncompleteTodos implements Action {
  readonly type = TodosActionTypes.LoadIncompleteTodos;
}

export class LoadIncompleteTodosSuccess implements Action {
  readonly type = TodosActionTypes.LoadIncompleteTodosSuccess;
  constructor(public payload: Todo[]) {}
}

export class LoadIncompleteTodosFailure implements Action {
  readonly type = TodosActionTypes.LoadIncompleteTodosFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class CreateTodo implements Action {
  readonly type = TodosActionTypes.CreateTodo;
  constructor(public payload: Todo) {}
}

export class CreateTodoSuccess implements Action {
  readonly type = TodosActionTypes.CreateTodoSuccess;
  constructor(public payload: Todo) {}
}

export class CreateTodoFailure implements Action {
  readonly type = TodosActionTypes.CreateTodoFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class UpdateTodo implements Action {
  readonly type = TodosActionTypes.UpdateTodo;
  constructor(public payload: Todo) {}
}

export class UpdateTodoSuccess implements Action {
  readonly type = TodosActionTypes.UpdateTodoSuccess;
  constructor(public payload: Todo) {}
}

export class UpdateTodoFailure implements Action {
  readonly type = TodosActionTypes.UpdateTodoFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class ReorderTodos implements Action {
  readonly type = TodosActionTypes.ReorderTodos;
  constructor(public payload: Todo[]) {}
}

export class ReorderTodosSuccess implements Action {
  readonly type = TodosActionTypes.ReorderTodosSuccess;
  constructor(public payload: Todo[]) {}
}

export class ReorderTodosFailure implements Action {
  readonly type = TodosActionTypes.ReorderTodosFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class DeleteTodo implements Action {
  readonly type = TodosActionTypes.DeleteTodo;
  constructor(public payload: Todo) {}
}

export class DeleteTodoSuccess implements Action {
  readonly type = TodosActionTypes.DeleteTodoSuccess;
  constructor(public payload: Todo) {}
}

export class DeleteTodoFailure implements Action {
  readonly type = TodosActionTypes.DeleteTodoFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export type TodosActions =
  | SetLocalTodos
  | LoadCompleteTodos
  | LoadCompleteTodosSuccess
  | LoadCompleteTodosFailure
  | LoadIncompleteTodos
  | LoadIncompleteTodosSuccess
  | LoadIncompleteTodosFailure
  | CreateTodo
  | CreateTodoSuccess
  | CreateTodoFailure
  | UpdateTodo
  | UpdateTodoSuccess
  | UpdateTodoFailure
  | ReorderTodos
  | ReorderTodosSuccess
  | ReorderTodosFailure
  | DeleteTodo
  | DeleteTodoSuccess
  | DeleteTodoFailure;
