import { RootState } from '../../store.reducer';
import { MemoizedSelector, createSelector } from '@ngrx/store';
import { Expense } from 'src/app/models/activities/expense';
import { GenericMap } from 'src/app/models/generic-map';
import { TableExpense } from 'src/app/models/activities/table-expense';
import { selectCompanyParamId } from '../../companies/selectors/current-company.selector';

export const selectAllExpenses = (state: RootState) => state.expenses.data;

export const selectExpensesAsArray: MemoizedSelector<RootState, TableExpense[]> =
  createSelector(
    selectAllExpenses,
    (expensesMap: GenericMap<Expense>): TableExpense[] => {
      return Object.values(expensesMap).map((expense: Expense) => ({
        id: expense.id,
        assignedUser: !!expense.assignedToUser
          ? expense.assignedToUser.firstName + ' ' + expense.assignedToUser.lastName
          : '--',
        company: !!expense.company ? expense.company.name : '--',
        contacts: expense.contacts
          .reduce((strArr, elem) => {
            let name: string;
            if (elem.firstName && elem.lastName) {
              name = elem.firstName + ' ' + elem.lastName;
            } else if (elem.firstName && !elem.lastName) {
              name = elem.firstName;
            } else if (!elem.firstName && elem.lastName) {
              name = elem.lastName;
            }
            strArr.push(name);
            return strArr;
          }, [])
          .join(', '),
        typeName: expense.type ? expense.type.type : '--',
        purpose: expense.purpose,
        location: expense.location,
        createdDate: !!expense.createdDate ? expense.createdDate.toString() : '--',
        expenseDate: !!expense.expenseDate ? expense.expenseDate.toString() : '--',
        amount: expense.amount,
        files: expense.files.length,
        reimbursement: !!expense.reimbursement ? 'Yes' : 'No'
      }));
    }
  );

export const selectExpensesByCompanyAsArray: MemoizedSelector<RootState, TableExpense[]> =
  createSelector(
    selectAllExpenses,
    selectCompanyParamId,
    (expensesMap: GenericMap<Expense>, companyId: number): TableExpense[] => {
      return Object.values(expensesMap)
        .filter((expense) => !!expense.company && expense.company.id === companyId)
        .map((expense: Expense) => ({
          id: expense.id,
          assignedUser: !!expense.assignedToUser
            ? expense.assignedToUser.firstName + ' ' + expense.assignedToUser.lastName
            : '--',
          company: !!expense.company ? expense.company.name : '--',
          contacts: expense.contacts
            .reduce((strArr, elem) => {
              let name: string;
              if (elem.firstName && elem.lastName) {
                name = elem.firstName + ' ' + elem.lastName;
              } else if (elem.firstName && !elem.lastName) {
                name = elem.firstName;
              } else if (!elem.firstName && elem.lastName) {
                name = elem.lastName;
              }
              strArr.push(name);
              return strArr;
            }, [])
            .join(', '),
          typeName: expense.type ? expense.type.type : '--',
          purpose: expense.purpose,
          location: expense.location,
          expenseDate: !!expense.expenseDate ? expense.expenseDate.toString() : '--',
          createdDate: !!expense.createdDate ? expense.createdDate.toString() : '--',
          amount: expense.amount,
          assignedUserId: expense.assignedToId
        }));
    }
  );
