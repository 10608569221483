import { ItemNamesActions, ItemNamesActionTypes } from './opportunity-item-names.actions';
import { GenericMap } from 'src/app/models/generic-map';
import { ItemName } from 'src/app/models/Opportunity/itemName';
import { HttpErrorResponse } from '@angular/common/http';

export const opportunityItemNamesFeatureKey = 'opportunityItemNames';

export interface ItemNamesState {
  pending: boolean;
  data: GenericMap<ItemName>;
  selectedItemNameId: number;
  itemNames: ItemName[];
  error: HttpErrorResponse;
}

export const initialState: ItemNamesState = {
  pending: false,
  data: {},
  selectedItemNameId: null,
  itemNames: [],
  error: undefined
};

export function itemNamesReducer(
  state = initialState,
  action: ItemNamesActions
): ItemNamesState {
  switch (action.type) {
    case ItemNamesActionTypes.SelectItemNameId:
      return {
        ...state,
        selectedItemNameId: action.payload
      };

    case ItemNamesActionTypes.ClearItemNameId:
      return {
        ...state,
        selectedItemNameId: undefined
      };

    case ItemNamesActionTypes.LoadItemNames:
      return {
        ...state,
        pending: true
      };

    case ItemNamesActionTypes.LoadItemNamesSuccess:
      return {
        ...state,
        error: undefined,
        pending: false,
        data: action.payload
      };

    case ItemNamesActionTypes.LoadItemNamesFailure:
      return {
        ...state,
        pending: false,
        data: {},
        error: action.payload
      };

    case ItemNamesActionTypes.CreateItemName:
      return {
        ...state,
        pending: true
      };

    case ItemNamesActionTypes.CreateItemNameSuccess:
      return {
        ...state,
        pending: false
      };

    case ItemNamesActionTypes.CreateItemNameFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case ItemNamesActionTypes.UpdateItemName:
      return {
        ...state,
        pending: true
      };

    case ItemNamesActionTypes.UpdateItemNameSuccess:
      return {
        ...state,
        pending: false
      };

    case ItemNamesActionTypes.UpdateItemNameFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case ItemNamesActionTypes.DeleteItemName:
      return {
        ...state,
        pending: true
      };

    case ItemNamesActionTypes.DeleteItemNameSuccess:
      return {
        ...state,
        pending: false,
        selectedItemNameId: undefined
      };

    case ItemNamesActionTypes.DeleteItemNameFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    default:
      return state;
  }
}
