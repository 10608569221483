import { Injectable } from '@angular/core';
import { State } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import { Feature } from 'src/app/models/features/feature';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  private jpiUrl = environment.jpiUrl;

  constructor(private http: HttpClient) {}

  getFeatures(): Observable<any> {
    return this.http.get(`${this.jpiUrl}/features`) as Observable<any>;
  }

  getRoles(agencyId: number): Observable<any> {
    return this.http.get(`${this.jpiUrl}/roles/${agencyId}`) as Observable<any>;
  }
}
