import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { EMPTY, of, Observable } from 'rxjs';
import { Field } from 'src/app/models/call-notes/field';
import {
  LoadCallNoteCustomFieldsFailure,
  LoadCallNoteCustomFieldsSuccess,
  CallNoteCustomFieldsActionTypes,
  CallNoteCustomFieldsActions,
  CreateCallNoteCustomFieldSuccess,
  CreateCallNoteCustomFieldFailure,
  CreateCallNoteCustomField,
  UpdateCallNoteCustomField,
  UpdateCallNoteCustomFieldSuccess,
  UpdateCallNoteCustomFieldFailure,
  DeleteCallNoteCustomField,
  DeleteCallNoteCustomFieldSuccess,
  DeleteCallNoteCustomFieldFailure,
  DeleteCallNoteDropdownOption,
  LoadCallNoteDropdownOptions,
  LoadCallNoteDropdownOptionsSuccess,
  LoadCallNoteDropdownOptionsFailure,
  SaveCallNoteDropdownOption,
  SaveCallNoteDropdownOptionSuccess,
  SaveCallNoteDropdownOptionFailure,
  DeleteCallNoteDropdownOptionSuccess,
  DeleteCallNoteDropdownOptionFailure,
  UpdateCallNoteDropdownOptionsOrder,
  UpdateCallNoteDropdownOptionsOrderSuccess,
  UpdateCallNoteDropdownOptionsOrderFailure
} from './call-note-custom-fields.actions';
import { CallNoteCustomFieldsService } from 'src/app/services/system-settings/call-note-custom-fields.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { convertToMap } from 'src/app/utils/convertToMap';
import { DropdownOption } from 'src/app/models/dropdown-option';

@Injectable()
export class CallNoteCustomFieldsEffects {
  loadCallNoteCustomFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CallNoteCustomFieldsActionTypes.LoadCallNoteCustomFields),
      mergeMap(() =>
        this.callNoteFieldsService.getAll().pipe(
          map((data: Field[]) => new LoadCallNoteCustomFieldsSuccess(data)),
          catchError((error: HttpErrorResponse) =>
            of(new LoadCallNoteCustomFieldsFailure(error))
          )
        )
      )
    )
  );

  createCallNoteCustomField$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CallNoteCustomFieldsActionTypes.CreateCallNoteCustomField),
      map((action: CreateCallNoteCustomField) => action.payload),
      mergeMap((field: Field) =>
        this.callNoteFieldsService.create(field).pipe(
          map((newField: Field) => new CreateCallNoteCustomFieldSuccess(newField)),
          catchError((error: HttpErrorResponse) =>
            of(new CreateCallNoteCustomFieldFailure(error))
          )
        )
      )
    )
  );

  updateCallNoteCustomField$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CallNoteCustomFieldsActionTypes.UpdateCallNoteCustomField),
      map((action: UpdateCallNoteCustomField) => action.payload),
      mergeMap((field: Field) =>
        this.callNoteFieldsService.update(field).pipe(
          map(
            (updatedField: Field) => new UpdateCallNoteCustomFieldSuccess(updatedField)
          ),
          catchError((error: HttpErrorResponse) =>
            of(new UpdateCallNoteCustomFieldFailure(error))
          )
        )
      )
    )
  );

  deleteCallNoteCustomField$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CallNoteCustomFieldsActionTypes.DeleteCallNoteCustomField),
      map((action: DeleteCallNoteCustomField) => action.payload),
      mergeMap((id: number) =>
        this.callNoteFieldsService.delete(id).pipe(
          map(() => new DeleteCallNoteCustomFieldSuccess(id)),
          catchError((error: HttpErrorResponse) =>
            of(new DeleteCallNoteCustomFieldFailure(error))
          )
        )
      )
    )
  );

  loadCallNoteDropdownOptions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CallNoteCustomFieldsActionTypes.LoadCallNoteDropdownOptions),
      map((action: LoadCallNoteDropdownOptions) => action.payload),
      mergeMap((id: number) =>
        this.callNoteFieldsService.getDropdownOptionsByFieldId(id).pipe(
          map(
            res =>
              new LoadCallNoteDropdownOptionsSuccess({
                fieldId: id,
                dropdownOptions: convertToMap(res, 'id')
              })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              new LoadCallNoteDropdownOptionsFailure({
                fieldId: id,
                error
              })
            )
          )
        )
      )
    )
  );

  saveCallNoteDropdownOptions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CallNoteCustomFieldsActionTypes.SaveCallNoteDropdownOption),
      map((action: SaveCallNoteDropdownOption) => action.payload),
      mergeMap((option: DropdownOption) => {
        if (option.id) {
          return this.callNoteFieldsService.updateDropdownOption(option).pipe(
            map(res => new SaveCallNoteDropdownOptionSuccess(res)),
            catchError((error: HttpErrorResponse) =>
              of(
                new SaveCallNoteDropdownOptionFailure({
                  option,
                  error
                })
              )
            )
          );
        } else {
          return this.callNoteFieldsService.createDropdownOption(option).pipe(
            map(res => new SaveCallNoteDropdownOptionSuccess(res)),
            catchError((error: HttpErrorResponse) =>
              of(
                new SaveCallNoteDropdownOptionFailure({
                  option,
                  error
                })
              )
            )
          );
        }
      })
    )
  );

  deleteCallNoteDropdownOptions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CallNoteCustomFieldsActionTypes.DeleteCallNoteDropdownOption),
      map((action: DeleteCallNoteDropdownOption) => action.payload),
      mergeMap((option: DropdownOption) => {
        return this.callNoteFieldsService.deleteDropdownOption(option).pipe(
          map(res => new DeleteCallNoteDropdownOptionSuccess(option)),
          catchError((error: HttpErrorResponse) =>
            of(
              new DeleteCallNoteDropdownOptionFailure({
                option,
                error
              })
            )
          )
        );
      })
    )
  );

  updateCallNoteDropdownOptionsOrder$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CallNoteCustomFieldsActionTypes.UpdateCallNoteDropdownOptionsOrder),
      map((action: UpdateCallNoteDropdownOptionsOrder) => action.payload),
      mergeMap(({ fieldId, options }) =>
        this.callNoteFieldsService.updateDropdownOptionsOrder(fieldId, options).pipe(
          map(
            data =>
              new UpdateCallNoteDropdownOptionsOrderSuccess({
                fieldId,
                optionsMap: convertToMap(data, 'id')
              })
          ),
          catchError(error =>
            of(
              new UpdateCallNoteDropdownOptionsOrderFailure({
                fieldId,
                error
              })
            )
          )
        )
      )
    )
  );

  constructor(
    private callNoteFieldsService: CallNoteCustomFieldsService,
    private actions$: Actions<CallNoteCustomFieldsActions>
  ) {}
}
