import { Injectable } from '@angular/core';
import { ServicesModule } from '../services.module';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Plans } from '../../models/admin/plans/plans';

@Injectable({
  providedIn: ServicesModule
})
export class PlansService {
  private jpiUrl = environment.jpiUrl;
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get(`${this.jpiUrl}/plans`);
  }

  getById(planId: number) {
    return this.http.get(`${this.jpiUrl}/plans/${planId}`);
  }

  create(plan: Plans) {
    return this.http.post(`${this.jpiUrl}/plans`, plan);
  }

  update(plan: Plans) {
    return this.http.put(`${this.jpiUrl}/plans/${plan.id}`, plan);
  }

  delete(planId: number) {
    return this.http.delete(`${this.jpiUrl}/plans/${planId}`);
  }
}
