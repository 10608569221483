import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServicesModule } from './services.module';
import { Contact } from '../models/contacts/contact';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: ServicesModule
})
export class ContactsService {
  jpiUrl: string = environment.jpiUrl;

  constructor(private http: HttpClient) {}

  getContactsForCompany(companyId: number): Observable<Contact[]> {
    return this.http.get(
      this.jpiUrl + '/companies/' + companyId + '/contacts'
    ) as Observable<Contact[]>;
  }

  getContact(companyId: number, contactId: number): Observable<Contact> {
    return this.http.get(
      `${this.jpiUrl}/companies/${companyId}/contacts/${contactId}`
    ) as Observable<Contact>;
  }

  createContact(contact: Contact, companyId: number) {
    return this.http.post(`${this.jpiUrl}/companies/${companyId}/contacts`, contact);
  }

  updateContact(contact: Contact) {
    return this.http.put(
      `${this.jpiUrl}/companies/${contact.account}/contacts/${contact.id}`,
      contact
    );
  }

  deleteContact(contact: Contact): Observable<Contact> {
    return this.http.delete(
      `${this.jpiUrl}/companies/${contact.account}/contacts/${contact.id}`
    ) as Observable<Contact>;
  }
}
