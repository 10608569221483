import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import {
  LoadUserSettingsFailure,
  LoadUserSettingsSuccess,
  UserSettingsActionTypes,
  UserSettingsActions
} from './user-settings.actions';

@Injectable()
export class UserSettingsEffects {
  loadUserSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserSettingsActionTypes.LoadUserSettings),
      concatMap(() =>
        /** An EMPTY observable only emits completion. Replace with your own observable API request */
        EMPTY.pipe(
          map(data => new LoadUserSettingsSuccess({ data })),
          catchError(error => of(new LoadUserSettingsFailure({ error })))
        )
      )
    )
  );

  constructor(private actions$: Actions<UserSettingsActions>) {}
}
