import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import {
  LoadPlansFailure,
  LoadPlansSuccess,
  PlansActionTypes,
  PlansActions,
  CreatePlans,
  CreatePlansSuccess,
  UpdatePlansSuccess,
  UpdatePlans,
  UpdatePlansFailure,
  DeletePlans,
  DeletePlansSuccess,
  DeletePlansFailure,
  CreatePlansFailure,
  SelectPlanId,
  LoadPlans
} from './plans.actions';
import { Action } from '@ngrx/store';
import { Plans } from '../../models/admin/plans/plans';
import { PlansService } from '../../services/plans/plans.service';
import { HttpErrorResponse } from '@angular/common/http';
import { convertToMap } from 'src/app/utils/convertToMap';

@Injectable()
export class PlansEffects {
  loadPlans$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(PlansActionTypes.LoadPlans),
      mergeMap(() =>
        this.plansService.getAll().pipe(
          map((plans: Plans[]) => {
            return new LoadPlansSuccess(convertToMap(plans, 'id'));
          }),
          catchError((error: HttpErrorResponse) => of(new LoadPlansFailure(error)))
        )
      )
    )
  );

  createPlans$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(PlansActionTypes.CreatePlans),
      map((action: CreatePlans) => action.payload),
      mergeMap((plan: Plans) => {
        return this.plansService.create(plan).pipe(
          switchMap((newPlan: Plans) => [
            new CreatePlansSuccess(newPlan),
            new LoadPlans()
          ]),
          catchError((error: HttpErrorResponse) => of(new CreatePlansFailure(error)))
        );
      })
    )
  );

  updatePlans$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(PlansActionTypes.UpdatePlan),
      map((action: UpdatePlans) => action.payload),
      mergeMap((plan: Plans) => {
        return this.plansService.update(plan).pipe(
          switchMap((updatedPlan: Plans) => [
            new UpdatePlansSuccess(updatedPlan),
            new LoadPlans()
          ]),
          catchError((error: HttpErrorResponse) => of(new UpdatePlansFailure(error)))
        );
      })
    )
  );

  deletePlans$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(PlansActionTypes.DeletePlans),
      map((action: DeletePlans) => action.payload),
      mergeMap((id: number) =>
        this.plansService.delete(id).pipe(
          switchMap(() => [new DeletePlansSuccess(id), new LoadPlans()]),
          catchError((error: HttpErrorResponse) => of(new DeletePlansFailure(error)))
        )
      )
    )
  );

  constructor(
    private plansService: PlansService,
    private actions$: Actions<PlansActions>
  ) {}
}
