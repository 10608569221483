import { Pipe, PipeTransform } from '@angular/core';
import { BidCompany } from 'src/app/models/projects/bid-company';

@Pipe({
  name: 'companyBidFilter'
})
export class CompanyBidFilterPipe implements PipeTransform {
  transform(bids: BidCompany[], searchText: string): BidCompany[] {
    searchText = searchText ? searchText.toLowerCase() : null;
    return bids.filter(bid => {
      return !!searchText
        ? bid.name.toLowerCase().match(searchText) ||
            bid.address.toLowerCase().match(searchText) ||
            (bid.labels
              ? bid.labels
                  .map(label => label.name)
                  .join(', ')
                  .toLowerCase()
                  .match(searchText)
              : false) ||
            bid.contacts
              .map(contact => contact.name)
              .join(', ')
              .toLowerCase()
              .match(searchText) ||
            bid.bids
              .map(individualBid => individualBid.manufacturer.name)
              .join(', ')
              .toLowerCase()
              .match(searchText) ||
            bid.bids
              .map(individualBid => individualBid.amount)
              .join(', ')
              .toLowerCase()
              .match(searchText) ||
            bid.bids
              .map(individualBid => {
                return individualBid.customFieldValues.map(cfv => cfv.fieldValue);
              })
              .flat()
              .join(', ')
              .toLowerCase()
              .match(searchText) ||
            bid.customFields
              .map(cf => cf.fieldValue)
              .join(', ')
              .toLowerCase()
              .match(searchText)
        : true;
    });
  }
}
