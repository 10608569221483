import { Component, Input, OnInit } from '@angular/core';

import { SalesService } from './../../../../shared/services/sales.service';

@Component({
  selector: 'company-profile-sales',
  templateUrl: './company-profile-sales.component.html',
  styleUrls: ['../../companies.scss']
})
export class CompanyProfileSalesComponent implements OnInit {
  @Input() canSeeSales: boolean;
  @Input() companyId: number;
  salesStartDate: Date;
  salesEndDate: Date;
  sales: any;
  manageCommissionsFeature: string;
  viewSalesCommissions: string;
  loadingSales: boolean;

  constructor(private _salesService: SalesService) { }

  ngOnInit() {
    this.loadingSales = true;
    this.salesStartDate = new Date();
    this.salesStartDate.setDate(1);
    this.salesStartDate.setMonth(0);
    this.salesEndDate = new Date(this.salesStartDate.getFullYear(), 11, 31);

    if (this.canSeeSales) {
      Promise.all([this._salesService.getSalesByDateRangeForCompany(this.salesStartDate, this.salesEndDate, this.companyId),
      this._salesService.getSalesByDateRangeForCompany(new Date(this.salesStartDate.getFullYear() - 1, 1, 1), new Date(this.salesEndDate.getFullYear() - 1, 11, 31), this.companyId),
      this._salesService.getPermissionValue(this._salesService.permissions.ManageCommissions),
      this._salesService.getPermissionValue(this._salesService.permissions.ViewAllRepCommissions),
      ]).then((result) => {
        this.sales = this.generateSalesReport(result[0], result[1]);
        this.manageCommissionsFeature = result[2];
        this.viewSalesCommissions = result[3];
        this.loadingSales = false;
      });
    }
  }
  generateSalesReport(salesYear1: any[], salesYear2: any[]): any {
    const now = new Date();
    const summary = this.createNewSalesSummaryObject();
    summary.Manufacturers = {};
    summary.Months = [
      { month: 'January', year1: 0, year2: 0 }, { month: 'February', year1: 0, year2: 0 }, { month: 'March', year1: 0, year2: 0 },
      { month: 'April', year1: 0, year2: 0 }, { month: 'May', year1: 0, year2: 0 }, { month: 'June', year1: 0, year2: 0 },
      { month: 'July', year1: 0, year2: 0 }, { month: 'August', year1: 0, year2: 0 }, { month: 'September', year1: 0, year2: 0 },
      { month: 'October', year1: 0, year2: 0 }, { month: 'November', year1: 0, year2: 0 }, { month: 'December', year1: 0, year2: 0 }
    ];
    summary.Years = [
      { value: 'year1', name: this.salesStartDate.getFullYear() }, { value: 'year2', name: this.salesStartDate.getFullYear() - 1 }
    ];
    for (let i = 0; i < salesYear1.length; i++) {
      if (!summary.Manufacturers[salesYear1[i].ManufacturerId]) {
        summary.Manufacturers[salesYear1[i].ManufacturerId] = this.createNewSalesSummaryObject();
        summary.Manufacturers[salesYear1[i].ManufacturerId].ManufacturerName = salesYear1[i].ManufacturerName;
      }

      if (now.getMonth() + 1 == salesYear1[i].Month) {
        summary.SalesThisMonth += salesYear1[i].Sales;
        summary.Manufacturers[salesYear1[i].ManufacturerId].SalesThisMonth += salesYear1[i].Sales;
      }

      summary.YearToDate += salesYear1[i].Sales;
      summary.Manufacturers[salesYear1[i].ManufacturerId].YearToDate += salesYear1[i].Sales;

      summary.Months[salesYear1[i].Month - 1].year1 += salesYear1[i].Sales;
    }

    for (let i = 0; i < salesYear2.length; i++) {
      if (!summary.Manufacturers[salesYear2[i].ManufacturerId]) {
        summary.Manufacturers[salesYear2[i].ManufacturerId] = this.createNewSalesSummaryObject();
        summary.Manufacturers[salesYear2[i].ManufacturerId].ManufacturerName = salesYear2[i].ManufacturerName;
      }

      if (now.getMonth() + 1 == salesYear2[i].Month) {
        summary.SalesThisMonthLastYear += salesYear2[i].Sales;
        summary.Manufacturers[salesYear2[i].ManufacturerId].SalesThisMonthLastYear += salesYear2[i].Sales;
      }
      if (now.getMonth() + 1 <= salesYear2[i].Month) {
        summary.LastYearToDate += salesYear2[i].Sales;
        summary.Manufacturers[salesYear2[i].ManufacturerId].LastYearToDate += salesYear2[i].Sales;
      }
      summary.LastYearTotal += salesYear2[i].Sales;
      summary.Manufacturers[salesYear2[i].ManufacturerId].LastYearTotal += salesYear2[i].Sales;

      summary.Months[salesYear2[i].Month - 1].year2 += salesYear2[i].Sales;
    }

    summary.YearToDateChange = this.calculatePercentageDifference(summary.YearToDate, summary.LastYearToDate);

    const keys = Object.keys(summary.Manufacturers);
    const temp: any[] = [];

    for (let i = 0; i < keys.length; i++) {
      summary.Manufacturers[keys[i]].YearToDateChange = this.calculatePercentageDifference(summary.Manufacturers[keys[i]].YearToDate, summary.Manufacturers[keys[i]].LastYearToDate);
      summary.Manufacturers[keys[i]].SalesThisMonth = this.normalizeFloat(summary.Manufacturers[keys[i]].SalesThisMonth);
      summary.Manufacturers[keys[i]].SalesThisMonthLastYear = this.normalizeFloat(summary.Manufacturers[keys[i]].SalesThisMonthLastYear);
      summary.Manufacturers[keys[i]].LastYearTotal = this.normalizeFloat(summary.Manufacturers[keys[i]].LastYearTotal);
      summary.Manufacturers[keys[i]].LastYearToDate = this.normalizeFloat(summary.Manufacturers[keys[i]].LastYearToDate);
      summary.Manufacturers[keys[i]].YearToDate = this.normalizeFloat(summary.Manufacturers[keys[i]].YearToDate);

      temp.push(summary.Manufacturers[keys[i]]);
    }

    summary.Manufacturers = temp;

    return summary;
  }

  createNewSalesSummaryObject(): any {
    return { SalesThisMonth: 0, SalesThisMonthLastYear: 0, LastYearTotal: 0, LastYearToDate: 0, YearToDate: 0, YearToDateChange: 0 };
  }

  normalizeFloat(num: number): number {
    return parseFloat(num.toFixed(2));
  }

  calculatePercentageDifference(num1: number, num2: number): number {
    if (num1 < num2) {
      return parseFloat((((num2 - num1) / num2) * 100).toFixed(2)) * -1;
    } else {
      return parseFloat((((num1 - num2) / num2) * 100).toFixed(2));
    }
  }

}
