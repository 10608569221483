import {
  OpportunityLabelsActions,
  OpportunityLabelsActionTypes
} from './opportunity-labels.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { GenericMap } from 'src/app/models/generic-map';
import { LabelGroup } from 'src/app/models/label-group';

export const opportunityLabelsFeatureKey = 'opportunityLabels';

export interface OpportunityLabelsState {
  pending: boolean;
  error: HttpErrorResponse;
  data: GenericMap<LabelGroup>;
  selectedOpportunityLabelId: number;
}

export const initialState: OpportunityLabelsState = {
  pending: false,
  error: undefined,
  data: {},
  selectedOpportunityLabelId: undefined
};

export function opportunityLabelsReducer(
  state = initialState,
  action: OpportunityLabelsActions
): OpportunityLabelsState {
  switch (action.type) {
    case OpportunityLabelsActionTypes.SelectOpportunityLabelId:
      return {
        ...state,
        selectedOpportunityLabelId: action.payload
      };

    case OpportunityLabelsActionTypes.ClearOpportunityLabelId:
      return {
        ...state,
        selectedOpportunityLabelId: undefined
      };

    case OpportunityLabelsActionTypes.LoadOpportunityLabels:
      return {
        ...state,
        pending: true
      };

    case OpportunityLabelsActionTypes.LoadOpportunityLabelsSuccess:
      return {
        ...state,
        pending: false,
        data: action.payload
      };

    case OpportunityLabelsActionTypes.LoadOpportunityLabelsFailure:
      return {
        ...state,
        pending: false,
        data: {},
        error: action.payload
      };

    case OpportunityLabelsActionTypes.AddOpportunityLabels:
      return {
        ...state,
        pending: true
      };

    case OpportunityLabelsActionTypes.AddOpportunityLabelsSuccess:
      return {
        ...state,
        pending: false
      };

    case OpportunityLabelsActionTypes.AddOpportunityLabelsFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case OpportunityLabelsActionTypes.UpdateOpportunityLabels:
      return {
        ...state,
        pending: true
      };

    case OpportunityLabelsActionTypes.UpdateOpportunityLabelsSuccess:
      return {
        ...state,
        pending: false
      };

    case OpportunityLabelsActionTypes.UpdateOpportunityLabelsFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case OpportunityLabelsActionTypes.DeleteOpportunityLabels:
      return {
        ...state,
        pending: true
      };

    case OpportunityLabelsActionTypes.DeleteOpportunityLabelsSuccess:
      return {
        ...state,
        pending: false,
        selectedOpportunityLabelId: undefined
      };

    case OpportunityLabelsActionTypes.DeleteOpportunityLabelsFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case OpportunityLabelsActionTypes.BulkUpdateOpportunityLabelGroups:
      return {
        ...state,
        pending: true
      };

    case OpportunityLabelsActionTypes.BulkUpdateOpportunityLabelGroupsSuccess:
      return {
        ...state,
        pending: false,
        data: action.payload
      };

    case OpportunityLabelsActionTypes.BulkUpdateOpportunityLabelGroupsFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case OpportunityLabelsActionTypes.SetOppLabelGroupListOrder:
      return {
        ...state,
        pending: true
      };

    case OpportunityLabelsActionTypes.SetOppLabelGroupListOrderSuccess:
      return {
        ...state,
        pending: false,
        data: action.payload
      };

    case OpportunityLabelsActionTypes.SetOppLabelGroupListOrderFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    default:
      return state;
  }
}
