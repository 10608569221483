import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  concatMap,
  mergeMap,
  tap,
  switchMap,
  withLatestFrom
} from 'rxjs/operators';
import { of } from 'rxjs';
import {
  LoadTerritoriesFailure,
  LoadTerritoriesSuccess,
  TerritoryManagementActionTypes,
  TerritoryManagementActions,
  LoadTerritoryCompaniesSuccess,
  LoadTerritoryCompaniesFailure,
  SaveCompanyAssociationSuccess,
  LoadTerritories,
  LoadTerritoryCompanies,
  SaveTerritorySuccess,
  SaveTerritoryFailure,
  RedirectToTerritories,
  DeleteTerritorySuccess,
  SaveCompanyAssociationFailure,
  DeleteTerritoryFailure
} from './territory-management.actions';
import { TerritoryManagementService } from 'src/app/services/territory-management/territory-management.service';
import { Territory } from 'src/app/models/admin/territories/territory';
import { convertToMap } from 'src/app/utils/convertToMap';
import { CompaniesService } from 'src/app/services/companies/companies.service';
import { ApiCompany } from 'src/app/models/companies/api-company';
import { Router } from '@angular/router';
import { GlobalFilter } from 'src/app/app-v2/shared/models/filters/globalFilter';
import { JpiResponse } from 'src/app/models/http/jpi-response.model';
import { Company } from 'src/app/models/companies/company';
import { formatAddressIntoOneLine } from 'src/app/utils/formatAddressIntoOneLine';
import { Store } from '@ngrx/store';
import { RootState } from '../store.reducer';

@Injectable()
export class TerritoryManagementEffects {
  constructor(
    private actions$: Actions<TerritoryManagementActions>,
    private tmService: TerritoryManagementService,
    private companiesService: CompaniesService,
    private router: Router,
    private store: Store<RootState>
  ) {}

  loadTerritories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TerritoryManagementActionTypes.LoadTerritories),
      concatMap(() =>
        this.tmService.getAll().pipe(
          map((res: Territory[]) => {
            return new LoadTerritoriesSuccess(
              convertToMap(
                res.map((terr) => ({
                  ...terr,
                  users: terr.users.map((user: any) => ({
                    id: user.id,
                    name: user.firstName + ' ' + user.lastName,
                    mailbox: user.mailbox
                  }))
                })),
                'id'
              )
            );
          }),
          catchError((error) => of(new LoadTerritoriesFailure(error)))
        )
      )
    )
  );

  saveTerritory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TerritoryManagementActionTypes.SaveTerritory),
      concatMap(({ payload }) => {
        if (!payload.id) {
          return this.tmService.createTerritory(payload).pipe(
            map((res: Territory) => new SaveTerritorySuccess(res)),
            catchError((error) => of(new SaveTerritoryFailure(error)))
          );
        } else {
          return this.tmService.updateTerritory(payload).pipe(
            map((res: Territory) => new SaveTerritorySuccess(res)),
            catchError((error) => of(new SaveTerritoryFailure(error)))
          );
        }
      })
    )
  );

  deleteTerritory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TerritoryManagementActionTypes.DeleteTerritory),
      concatMap(({ payload }) => {
        return this.tmService.deleteTerritory(payload).pipe(
          map(() => new DeleteTerritorySuccess()),
          catchError((error) => of(new DeleteTerritoryFailure(error)))
        );
      })
    )
  );

  saveTerritorySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TerritoryManagementActionTypes.SaveTerritorySuccess),
      map(() => new RedirectToTerritories())
    )
  );

  deleteTerritorySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TerritoryManagementActionTypes.DeleteTerritorySuccess),
      switchMap(() => [new RedirectToTerritories(), new LoadTerritories()])
    )
  );

  redirectToTerritories$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TerritoryManagementActionTypes.RedirectToTerritories),
        tap(() => {
          this.router.navigate(['/territory-management']);
        })
      ),
    { dispatch: false }
  );

  loadTerritoryCompanies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TerritoryManagementActionTypes.LoadTerritoryCompanies),
      mergeMap(({ payload }) => {
        const reqFilter: GlobalFilter = {
          filters: [
            {
              operand1: 'territories.id',
              operator: 'IS_ANY_OF',
              operand2: [payload]
            }
          ]
        };
        return this.companiesService
          .getCompaniesFiltered(reqFilter, 1000, 'name.keyword', false)
          .pipe(
            map((res: JpiResponse<Company>) => {
              return new LoadTerritoryCompaniesSuccess({
                companyNamesAndIds: convertToMap(
                  res.items.map((company: Company) => {
                    return {
                      id: company.id,
                      name: company.name,
                      address: formatAddressIntoOneLine(company.billingAddress)
                    };
                  }),
                  'id'
                ),
                territoryId: payload
              });
            }),
            catchError((error) => of(new LoadTerritoryCompaniesFailure(error)))
          );
      })
    )
  );

  saveCompanyAssociation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TerritoryManagementActionTypes.SaveCompanyAssociation),
      concatMap(({ payload }) => {
        if (payload.isAssociated) {
          return this.companiesService
            .setCompaniesByTerritoryId(payload.companyId, payload.territoryId)
            .pipe(
              map((_) => new SaveCompanyAssociationSuccess(payload.territoryId)),
              catchError((error) => of(new SaveCompanyAssociationFailure(error)))
            );
        } else {
          return this.companiesService
            .removeCompanyFromTerritory(payload.companyId, payload.territoryId)
            .pipe(
              map((_) => new SaveCompanyAssociationSuccess(payload.territoryId)),
              catchError((error) => of(new SaveCompanyAssociationFailure(error)))
            );
        }
      })
    )
  );

  saveCompanyAssociatedSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(TerritoryManagementActionTypes.SaveCompanyAssociationSuccess),
      map(({ payload }) => new LoadTerritoryCompanies(payload))
    )
  );
}
