import { MemoizedSelector, createSelector } from '@ngrx/store';
import { RootState } from '../../../store.reducer';
import { LabelGroup } from '../../../../models/label-group';
import { selectAllOpportunityLabels } from './all-opportunityLabels-as-array.selector';

export const selectedOpportunityLabelId = (state: RootState) =>
  state.opportunityLabels.selectedOpportunityLabelId;

export const selectSelectedOpportunityLabel: MemoizedSelector<
  RootState,
  LabelGroup
> = createSelector(
  selectAllOpportunityLabels,
  selectedOpportunityLabelId,
  (
    opportunityLabelMap: { [id: number]: LabelGroup },
    opportunityLabelId: number
  ): LabelGroup => {
    let opportunityLabel: LabelGroup;
    if (!!opportunityLabelId) {
      const id = opportunityLabelId.toString();
      opportunityLabel = {
        ...opportunityLabelMap[id]
      };
    }
    return opportunityLabel;
  }
);
