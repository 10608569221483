import { SalesActions, SalesActionTypes } from './sales.actions';
import { CompanyAssignment } from 'src/app/models/sales/companyAssignment';
import { GenericMap } from 'src/app/models/generic-map';
import { GenericApi } from 'src/app/models/generic-api';
import { SalesSummaryRow } from 'src/app/models/sales/sales-summary-row';

export interface SalesState extends GenericApi<SalesSummaryRow[]> {
  useNewSalesModule: boolean;
  companyAssignments: GenericApi<GenericMap<GenericMap<GenericApi<CompanyAssignment>>>>;
}

export const initialState: SalesState = {
  pending: false,
  error: undefined,
  useNewSalesModule: false,
  data: [],
  companyAssignments: {
    pending: false,
    error: undefined,
    data: {}
  }
};

export function salesReducer(state = initialState, action: SalesActions): SalesState {
  switch (action.type) {
    case SalesActionTypes.LoadSalesReports:
      return {
        ...state,
        pending: true,
        data: [],
        error: null
      };

    case SalesActionTypes.LoadSalesReportsSuccess:
      return {
        ...state,
        pending: false,
        data: action.payload,
        error: null
      };

    case SalesActionTypes.LoadSalesReportsFailure:
      return {
        ...state,
        pending: false,
        data: [],
        error: action.payload
      };

    case SalesActionTypes.LoadCompanyAssignments:
      return {
        ...state,
        companyAssignments: {
          ...state.companyAssignments,
          error: undefined,
          pending: true
        }
      };

    case SalesActionTypes.LoadCompanyAssignmentsSuccess:
      return {
        ...state,
        companyAssignments: {
          pending: false,
          error: undefined,
          data: action.payload
        }
      };

    case SalesActionTypes.LoadCompanyAssignmentsFailure:
      return {
        ...state,
        companyAssignments: {
          ...state.companyAssignments,
          pending: false,
          error: action.payload
        }
      };

    case SalesActionTypes.SetCompanyAssignment:
      return {
        ...state,
        companyAssignments: {
          ...state.companyAssignments,
          data: {
            ...state.companyAssignments.data,
            [action.payload.companyId]: {
              ...state.companyAssignments.data[action.payload.companyId],
              [action.payload.agentMailboxId]: {
                ...state.companyAssignments.data[action.payload.companyId][
                  action.payload.agentMailboxId
                ],
                pending: true,
                error: undefined
              }
            }
          }
        }
      };

    case SalesActionTypes.SetCompanyAssignmentSuccess:
      return {
        ...state,
        companyAssignments: {
          ...state.companyAssignments,
          data: {
            ...state.companyAssignments.data,
            [action.payload.companyId]: {
              ...state.companyAssignments.data[action.payload.companyId],
              [action.payload.agentMailboxId]: {
                pending: false,
                error: undefined,
                data: {
                  ...state.companyAssignments.data[action.payload.companyId][
                    action.payload.agentMailboxId
                  ].data,
                  associateSales: action.payload.associateSales
                }
              }
            }
          }
        }
      };

    case SalesActionTypes.SetCompanyAssignmentFailure:
      return {
        ...state,
        companyAssignments: {
          ...state.companyAssignments,
          data: {
            ...state.companyAssignments.data,
            [action.payload.companyId]: {
              ...state.companyAssignments.data[action.payload.companyId],
              [action.payload.agentMailboxId]: {
                ...state.companyAssignments.data[action.payload.companyId][
                  action.payload.agentMailboxId
                ],
                pending: false,
                error: action.payload.error
              }
            }
          }
        }
      };

    case SalesActionTypes.ToggleSalesModule: {
      return {
        ...state,
        useNewSalesModule: !state.useNewSalesModule
      };
    }

    default:
      return state;
  }
}
