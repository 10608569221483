import { InitActions, InitActionTypes } from './init.actions';

export interface InitState {
  appInitialized: boolean;
}

export const initialState: InitState = {
  appInitialized: false
};

export function initReducer(state = initialState, action: InitActions): InitState {
  switch (action.type) {
    case InitActionTypes.InitializeApp:
      return {
        ...state,
        appInitialized: true
      };

    default:
      return state;
  }
}
