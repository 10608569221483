import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'delete-popup',
	templateUrl: './delete-popup.component.html'
})
export class DeletePopupComponent {
	@Input() deletePopup: boolean;
	@Output() deletePopupChange = new EventEmitter<boolean>();
	@Output() deleted = new EventEmitter();

	closeDeletePopup(): void {
		this.deletePopup = false;
		this.deletePopupChange.emit(this.deletePopup);
	}

	deleteConfirmed(): void {
		this.deleted.emit();
	}
}

