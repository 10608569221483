import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { mergeMap, map, catchError } from 'rxjs/operators';
import { EMPTY, Observable, of } from 'rxjs';
import {
  ContactCustomFieldsActionTypes,
  ContactCustomFieldsActions,
  LoadContactCustomFieldsSuccess,
  LoadContactCustomFieldsFailure,
  CreateContactCustomField,
  CreateContactCustomFieldSuccess,
  CreateContactCustomFieldFailure,
  UpdateContactCustomField,
  UpdateContactCustomFieldSuccess,
  UpdateContactCustomFieldFailure,
  DeleteContactCustomField,
  DeleteContactCustomFieldSuccess,
  DeleteContactCustomFieldFailure
} from './contact-custom-fields.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { ContactField } from 'src/app/models/contacts/contact-field';
import { Action } from '@ngrx/store';
import { ContactCustomFieldsService } from 'src/app/services/system-settings/contact-custom-fields.service';

@Injectable()
export class ContactCustomFieldsEffects {
  loadContactCustomFields$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactCustomFieldsActionTypes.LoadContactCustomFields),
      mergeMap(() =>
        this.contactFieldsService.getAll().pipe(
          map((data: ContactField[]) => new LoadContactCustomFieldsSuccess(data)),
          catchError((error: HttpErrorResponse) =>
            of(new LoadContactCustomFieldsFailure(error))
          )
        )
      )
    )
  );

  createContactCustomField$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactCustomFieldsActionTypes.CreateContactCustomField),
      map((action: CreateContactCustomField) => action.payload),
      mergeMap((field: ContactField) =>
        this.contactFieldsService.create(field).pipe(
          map((newField: ContactField) => new CreateContactCustomFieldSuccess(newField)),
          catchError((error: HttpErrorResponse) =>
            of(new CreateContactCustomFieldFailure(error))
          )
        )
      )
    )
  );

  updateContactCustomField$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactCustomFieldsActionTypes.UpdateContactCustomField),
      map((action: UpdateContactCustomField) => action.payload),
      mergeMap((field: ContactField) =>
        this.contactFieldsService.update(field).pipe(
          map(
            (updatedField: ContactField) =>
              new UpdateContactCustomFieldSuccess(updatedField)
          ),
          catchError((error: HttpErrorResponse) =>
            of(new UpdateContactCustomFieldFailure(error))
          )
        )
      )
    )
  );

  DeleteContactCustomField$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactCustomFieldsActionTypes.DeleteContactCustomField),
      map((action: DeleteContactCustomField) => action.payload),
      mergeMap((id: number) =>
        this.contactFieldsService.delete(id).pipe(
          map(() => new DeleteContactCustomFieldSuccess(id)),
          catchError((error: HttpErrorResponse) =>
            of(new DeleteContactCustomFieldFailure(error))
          )
        )
      )
    )
  );

  constructor(
    private contactFieldsService: ContactCustomFieldsService,
    private actions$: Actions<ContactCustomFieldsActions>
  ) {}
}
