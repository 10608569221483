import { RootState } from '../../store.reducer';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { selectFeatures } from 'src/app/view/shared/sidenav/sidenav-links.selector';
import { GenericMap } from 'src/app/models/generic-map';
import { Feature } from 'src/app/models/features/feature';
import { CompanyPermissions } from 'src/app/models/companies/company-permissions';
import { selectCurrentUser } from '../../users/selectors/id-name-current-user.selector';
import { User } from 'src/app/models/admin/users/user';
import { Territory } from 'src/app/models/admin/territories/territory';
import { selectCurrentCompany } from './current-company.selector';
import { Company } from 'src/app/models/companies/company';

export const selectCompanyPermissions: MemoizedSelector<RootState, CompanyPermissions> =
  createSelector(
    selectCurrentUser,
    selectFeatures,
    selectCurrentCompany,
    (
      user: User,
      featuresMap: GenericMap<Feature>,
      company: Company
    ): CompanyPermissions => {
      const permissions: CompanyPermissions = {
        salesCommissions: false,
        customFields: false,
        expenses: false,
        expensesAdmin: false,
        opportunities: false,
        projects: false,
        salesTab: false,
        dms: false,
        privateNotes: false
      };

      // -=-=-=-= Sales Commissions =-=-=-=-
      if (
        (featuresMap != null || undefined) &&
        Object.keys(featuresMap).length &&
        !!featuresMap[2]
      ) {
        if (
          featuresMap[2].Permissions[11] &&
          featuresMap[2].Permissions[11].Value.toString() === '1' &&
          featuresMap[2].Permissions[1] &&
          featuresMap[2].Permissions[1].Value.toString() === '4'
        ) {
          permissions.salesCommissions = true;
        }
      }

      // -=-=-=-= Use New Custom Fields =-=-=-=-
      if (user?.client?.useNewCustomFields) {
        permissions.customFields = true;
      }

      // -=-=-=-= Expenses =-=-=-=-
      if (
        (featuresMap != null || undefined) &&
        Object.keys(featuresMap).length &&
        !!featuresMap[4]
      ) {
        if (
          featuresMap[4] &&
          featuresMap[4].Permissions[64] &&
          featuresMap[4].Permissions[64].Value.toString() === 'true'
        ) {
          permissions.expenses = true;
        }
      }

      // -=-=-=-= Expenses Admin =-=-=-=-
      if (
        (featuresMap != null || undefined) &&
        Object.keys(featuresMap).length &&
        !!featuresMap[4]
      ) {
        if (
          featuresMap[4] &&
          featuresMap[4].Permissions[137] &&
          featuresMap[4].Permissions[137].Value.toString() === 'true'
        ) {
          permissions.expenses = true;
        }
      }

      // -=-=-=-= Opportunities =-=-=-=-
      if (
        (featuresMap != null || undefined) &&
        Object.keys(featuresMap).length &&
        !!featuresMap[20]
      ) {
        if (
          featuresMap[20] &&
          featuresMap[20].Permissions[138] &&
          featuresMap[20].Permissions[138].Value.toString() === '2'
        ) {
          permissions.opportunities = true;
        }
      }

      // -=-=-=-= Projects =-=-=-=-
      if (
        (featuresMap != null || undefined) &&
        Object.keys(featuresMap).length &&
        !!featuresMap[13]
      ) {
        if (
          featuresMap[13] &&
          featuresMap[13].Permissions[125] &&
          featuresMap[13].Permissions[125].Value.toString() === 'true'
        ) {
          permissions.projects = true;
        }
      }

      // -=-=-=-= dms =-=-=-=-
      if (
        (featuresMap != null || undefined) &&
        Object.keys(featuresMap).length &&
        !!featuresMap[7]
      ) {
        if (
          featuresMap[7] &&
          featuresMap[7].Permissions[108] &&
          featuresMap[7].Permissions[108].Value.toString() === 'true'
        ) {
          permissions.dms = true;
        }
      }

      // -=-=-=-= Sales Tab =-=-=-=-
      if (
        (featuresMap != null || undefined) &&
        Object.keys(featuresMap).length &&
        !!featuresMap[2]
      ) {
        if (
          featuresMap[2] &&
          featuresMap[2].Permissions[1] &&
          featuresMap[2].Permissions[1].Value.toString() === '4'
        ) {
          permissions.salesTab = true;
        }
      }
      if (
        (featuresMap != null || undefined) &&
        Object.keys(featuresMap).length &&
        !!featuresMap[2]
      ) {
        if (
          featuresMap[2] &&
          featuresMap[2].Permissions[1] &&
          featuresMap[2].Permissions[1].Value.toString() === '2'
        ) {
          if (!!company) {
            const userTerritoryIds = user.territories.map((terr: Territory) => terr.id);
            const compTerritoryIds = company.territories.map(
              (terr: Territory) => terr.id
            );

            permissions.salesTab =
              userTerritoryIds.filter((uTerrId) => compTerritoryIds.includes(uTerrId))
                .length > 0;
          }
        }
      }

      // -=-=-=-= Private Notes =-=-=-=-
      if (
        (featuresMap != null || undefined) &&
        Object.keys(featuresMap).length &&
        !!featuresMap[29]
      ) {
        if (featuresMap[29]?.Permissions[168]?.Value.toString() === 'true') {
          permissions.privateNotes = true;
        }
      }

      return permissions;
    }
  );
