import { createSelector, MemoizedSelector } from '@ngrx/store';
import { Feature } from 'src/app/models/features/feature';
import { GenericMap } from 'src/app/models/generic-map';
import { selectFeatures } from 'src/app/view/shared/sidenav/sidenav-links.selector';
import { RootState } from '../../store.reducer';

export const selectHasMileageAdmin: MemoizedSelector<RootState, boolean> = createSelector(
  selectFeatures,
  (featuresMap: GenericMap<Feature>): boolean => {
    if (featuresMap[4]) {
      if (
        (featuresMap[4].Permissions[142] &&
          featuresMap[4].Permissions[142].Value.toString() === 'true') ||
        (featuresMap[4].Permissions[143] &&
          featuresMap[4].Permissions[143].Value.toString() === 'true')
      ) {
        return true;
      }
    }
    return false;
  }
);
