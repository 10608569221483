import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import {
  LoadCompanyFieldsFailure,
  LoadCompanyFieldsSuccess,
  CompanyFieldsActionTypes,
  CompanyFieldsActions,
  CreateCompanyField,
  CreateCompanyFieldSuccess,
  CreateCompanyFieldFailure,
  UpdateCompanyField,
  UpdateCompanyFieldSuccess,
  UpdateCompanyFieldFailure,
  DeleteCompanyField,
  DeleteCompanyFieldSuccess,
  DeleteCompanyFieldFailure
} from './company-fields.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { CompanyField } from 'src/app/models/companies/company-field';
import { CompanyCustomFieldsService } from 'src/app/services/system-settings/company-custom-fields.service';
import { Action } from '@ngrx/store';

@Injectable()
export class CompanyFieldsEffects {
  loadCompanyFieldss$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyFieldsActionTypes.LoadCompanyFields),
      mergeMap(() =>
        this.companyFieldsService.getAll().pipe(
          map((data: CompanyField[]) => new LoadCompanyFieldsSuccess(data)),
          catchError((error: HttpErrorResponse) =>
            of(new LoadCompanyFieldsFailure(error))
          )
        )
      )
    )
  );

  createCompanyField$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyFieldsActionTypes.CreateCompanyField),
      map((action: CreateCompanyField) => action.payload),
      mergeMap((field: CompanyField) =>
        this.companyFieldsService.create(field).pipe(
          map((newField: CompanyField) => new CreateCompanyFieldSuccess(newField)),
          catchError((error: HttpErrorResponse) =>
            of(new CreateCompanyFieldFailure(error))
          )
        )
      )
    )
  );

  updateCompanyField$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyFieldsActionTypes.UpdateCompanyField),
      map((action: UpdateCompanyField) => action.payload),
      mergeMap((field: CompanyField) =>
        this.companyFieldsService.update(field).pipe(
          map(
            (updatedField: CompanyField) => new UpdateCompanyFieldSuccess(updatedField)
          ),
          catchError((error: HttpErrorResponse) =>
            of(new UpdateCompanyFieldFailure(error))
          )
        )
      )
    )
  );

  DeleteCompanyField$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyFieldsActionTypes.DeleteCompanyField),
      map((action: DeleteCompanyField) => action.payload),
      mergeMap((id: number) =>
        this.companyFieldsService.delete(id).pipe(
          map(() => new DeleteCompanyFieldSuccess(id)),
          catchError((error: HttpErrorResponse) =>
            of(new DeleteCompanyFieldFailure(error))
          )
        )
      )
    )
  );

  constructor(
    private companyFieldsService: CompanyCustomFieldsService,
    private actions$: Actions<CompanyFieldsActions>
  ) {}
}
