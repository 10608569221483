import { Component, OnInit, AfterViewChecked } from '@angular/core';

import { Feature } from '../shared/models/admin/feature';
import { AuthenticationService } from '../shared/services/authentication.service';
import { ExpandService } from '../shared/services/expand.service';

@Component({
  selector: 'tn-nav',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, AfterViewChecked {
  navExpanded: boolean;
  menu: Feature[];
  agencyId: number;
  hasOpportunities: boolean;

  constructor(
    private _authService: AuthenticationService,
    private _expandService: ExpandService
  ) {
    _authService.moduleAnnounced.subscribe(modules => {
      this.menu = <Feature[]>JSON.parse(JSON.stringify(modules));
    });
  }

  ngOnInit(): void {
    this._authService.getVisibleFeatures().then(result => {
      this.menu = result;
    });
    this.navExpanded = true;
    this._expandService.expandMenu(this.navExpanded);
  }

  ngAfterViewChecked(): void {
    this.agencyId = parseInt(
      this._authService.getCookie(this._authService.cookieNames.AgencyId)
    );
    if (
      this.agencyId == 10 ||
      this.agencyId == 1023 ||
      this.agencyId == 993 ||
      this.agencyId == 398 ||
      this.agencyId == 992 ||
      this.agencyId == 1021 ||
      this.agencyId == 1032 ||
      this.agencyId == 845 ||
      this.agencyId == 1060 ||
      this.agencyId == 1068
    ) {
      this.hasOpportunities = true;
    }
  }

  expand() {
    this.navExpanded = !this.navExpanded;
    this._expandService.expandMenu(this.navExpanded);
  }
}
