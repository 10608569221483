import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
  concatMap,
  map,
  catchError,
  withLatestFrom,
  switchMap,
  delay,
  tap,
  mergeMap
} from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import {
  CallNotesActionTypes,
  CallNotesActions,
  LoadCallNotes,
  LoadCallNotesSuccess,
  LoadCallNotesFailure,
  SaveCallNoteSuccess,
  SaveCallNoteFailure,
  DeleteCallNoteSuccess,
  DeleteCallNoteFailure,
  SaveCallNoteComment,
  SaveCallNoteCommentSuccess,
  SaveCallNoteCommentFailure,
  DeleteCallNoteCommentSuccess,
  DeleteCallNoteCommentFailure,
  DeleteCallNoteComment,
  SelectCallNoteId,
  LoadCallNotesByCompanyId,
  LoadCallNotesByCompanyIdSuccess,
  LoadCallNotesByCompanyIdFailure,
  LoadCallNotesByCompanyIdWithFollowUps,
  LoadCallNotesByCompanyIdWithFollowUpsSuccess,
  LoadCallNotesByCompanyIdWithFollowUpsFailure,
  LoadCallNoteSuccess,
  LoadCallNote,
  LoadCallNoteFailure,
  LoadCallNoteComments,
  LoadCallNoteCommentsSuccess,
  LoadCallNoteCommentsFailure,
  SaveCallNoteAudioSuccess,
  SaveCallNoteAudioFailure,
  LoadCallNoteAudioById,
  LoadCallNoteAudioByIdSuccess,
  LoadCallNoteAudioByIdFailure
} from './call-notes.actions';
import { CallNotesService } from 'src/app/services/call-notes/call-notes.service';
import { JpiResponse } from 'src/app/models/http/jpi-response.model';
import { CallNote, CallNoteAudio } from 'src/app/models/call-notes/call-note';
import { convertToMap } from 'src/app/utils/convertToMap';
import { Action, Store } from '@ngrx/store';
import { RootState } from '../store.reducer';
import {
  CloseDrawer,
  OpenSnackbar,
  SetLoadingBarVisibility,
  OpenErrorSnackbar
} from '../layout/layout.actions';
import { selectIdNameCurrentUser } from '../users/selectors/id-name-current-user.selector';
import { IdNameItemUser } from 'src/app/models/auth/id-name-item-user';
import { format } from 'date-fns';
import { dbDateFormat } from 'src/app/utils/dbDateFormat';
import { Comment } from 'src/app/models/call-notes/comment';
import { HttpErrorResponse } from '@angular/common/http';
import { LoadCallTypeNotesSuccess } from '../dashboard/dashboard.actions';
import { deepCopy } from 'src/app/utils/deepCopy';

@Injectable()
export class CallNotesEffects {
  reloadDelay = 500;
  constructor(
    private actions$: Actions<CallNotesActions>,
    private cnService: CallNotesService,
    private store$: Store<RootState>
  ) {}

  loadCallNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CallNotesActionTypes.LoadCallNotes),
      map((action: LoadCallNotes) => action.payload),
      concatMap(({ filter, params }) =>
        this.cnService.getCallNotes(filter, params).pipe(
          switchMap((res: JpiResponse<CallNote>) => {
            return [
              new LoadCallNotesSuccess(convertToMap(res.items, 'id')),
              new LoadCallTypeNotesSuccess(deepCopy(res.items))
            ];
          }),
          catchError((error) => of(new LoadCallNotesFailure(error)))
        )
      )
    )
  );

  loadCallNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CallNotesActionTypes.LoadCallNote),
      map((action: LoadCallNote) => action.payload),
      concatMap((callNoteId) =>
        this.cnService.getCallNote(callNoteId).pipe(
          map((res: CallNote) => new LoadCallNoteSuccess(res)),
          catchError((error) => of(new LoadCallNoteFailure(error)))
        )
      )
    )
  );

  loadCallNoteComments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CallNotesActionTypes.LoadCallNoteComments),
      map((action: LoadCallNoteComments) => action.payload),
      concatMap((callNoteId) =>
        this.cnService.getCommentsForCallNote(callNoteId).pipe(
          map((comments: Comment[]) => {
            return new LoadCallNoteCommentsSuccess({ callNoteId, comments });
          }),
          catchError((error) =>
            of(new LoadCallNoteCommentsFailure({ callNoteId, error }))
          )
        )
      )
    )
  );

  loadCallNoteAudioById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CallNotesActionTypes.LoadCallNoteAudioById),
      map((action: LoadCallNoteAudioById) => action.payload),
      concatMap((callNoteId) =>
        this.cnService.getAudioForCallNote(callNoteId).pipe(
          map((audio: any) => {
            return new LoadCallNoteAudioByIdSuccess({ callNoteId, audio });
          }),
          catchError((error) =>
            of(new LoadCallNoteAudioByIdFailure({ callNoteId, error }))
          )
        )
      )
    )
  );

  saveCallNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CallNotesActionTypes.SaveCallNote),
      tap(() => this.store$.dispatch(new SetLoadingBarVisibility(true))),
      concatMap(({ payload }) => {
        if (!payload.id) {
          return this.cnService.create(payload).pipe(
            mergeMap((res: CallNote) => of(new SaveCallNoteSuccess(res))),
            catchError((error) => of(new SaveCallNoteFailure(error)))
          );
        } else {
          return this.cnService.update(payload).pipe(
            mergeMap((res: CallNote) => of(new SaveCallNoteSuccess(res))),
            catchError((error) => of(new SaveCallNoteFailure(error)))
          );
        }
      })
    )
  );

  SaveCallNoteAudio$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CallNotesActionTypes.SaveCallNoteAudio),
      tap(() => this.store$.dispatch(new SetLoadingBarVisibility(true))),
      concatMap(({ payload }) => {
        return this.cnService.createAudio(payload).pipe(
          mergeMap((res: CallNoteAudio) => of(new SaveCallNoteAudioSuccess(res))),
          catchError((error) => of(new SaveCallNoteAudioFailure(error)))
        );
      })
    )
  );

  deleteCallNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CallNotesActionTypes.DeleteCallNote),
      tap(() => this.store$.dispatch(new SetLoadingBarVisibility(true))),
      concatMap(({ payload }) => {
        return this.cnService.delete(payload.id).pipe(
          mergeMap((res: CallNote) =>
            of(
              new DeleteCallNoteSuccess({
                callNoteId: res.id,
                companyId: payload.company.id
              })
            )
          ),
          catchError((error) => of(new DeleteCallNoteFailure(error)))
        );
      })
    )
  );

  reloadCallNoteAfterCommentSave$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CallNotesActionTypes.SaveCallNoteCommentSuccess),
      map((action: SaveCallNoteCommentSuccess) => {
        return new LoadCallNoteComments(action.payload.callNoteID);
      })
    )
  );

  reloadCallNoteAfterCommentDelete$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CallNotesActionTypes.DeleteCallNoteCommentSuccess),
      map((action: DeleteCallNoteCommentSuccess) => {
        return new LoadCallNoteComments(action.payload.callNoteId);
      })
    )
  );

  saveCallNoteComment$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CallNotesActionTypes.SaveCallNoteComment),
      tap(() => this.store$.dispatch(new SetLoadingBarVisibility(true))),
      withLatestFrom(this.store$.select(selectIdNameCurrentUser)),
      map((actionAndUser: [SaveCallNoteComment, IdNameItemUser]) => ({
        comment: actionAndUser[0].payload,
        user: actionAndUser[1]
      })),
      concatMap(({ comment, user }) => {
        if (!!comment.id) {
          return this.cnService
            .updateCommentForCallNote(comment.callNoteID, comment)
            .pipe(
              switchMap((res: Comment) => of(new SaveCallNoteCommentSuccess(res))),
              catchError((error) => of(new SaveCallNoteCommentFailure(error)))
            );
        } else {
          return this.cnService
            .addCommentForCallNote(comment.callNoteID, {
              value: comment.value,
              emails: comment.emails,
              createdBy: user,
              createdDate: format(new Date(), dbDateFormat)
            })
            .pipe(
              switchMap((res: Comment) => of(new SaveCallNoteCommentSuccess(res))),
              catchError((error) => of(new SaveCallNoteCommentFailure(error)))
            );
        }
      })
    )
  );

  deleteCallNoteComment$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CallNotesActionTypes.DeleteCallNoteComment),
      tap(() => this.store$.dispatch(new SetLoadingBarVisibility(true))),
      map((action: DeleteCallNoteComment) => action.payload),
      concatMap(({ comment, callNoteId }) => {
        return this.cnService.deleteCommentForCallNote(callNoteId, comment.id).pipe(
          switchMap((res: Comment) =>
            of(new DeleteCallNoteCommentSuccess({ comment, callNoteId }))
          ),
          catchError((error) =>
            of(new DeleteCallNoteCommentFailure({ error, callNoteId }))
          )
        );
      })
    )
  );

  deleteCallNoteCommentSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CallNotesActionTypes.DeleteCallNoteCommentSuccess),
      map(
        () =>
          new OpenSnackbar({
            duration: 5000,
            message: 'Comment deleted successfully',
            opened: true
          })
      )
    )
  );

  setLoadingBarVisibility$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CallNotesActionTypes.SaveCallNoteSuccess,
        CallNotesActionTypes.SaveCallNoteFailure,
        CallNotesActionTypes.DeleteCallNoteSuccess,
        CallNotesActionTypes.DeleteCallNoteFailure,
        CallNotesActionTypes.SaveCallNoteCommentSuccess,
        CallNotesActionTypes.SaveCallNoteCommentFailure,
        CallNotesActionTypes.DeleteCallNoteCommentSuccess,
        CallNotesActionTypes.DeleteCallNoteCommentFailure,
        CallNotesActionTypes.SaveCallNoteAudioSuccess,
        CallNotesActionTypes.SaveCallNoteAudioFailure
      ),
      map(() => new SetLoadingBarVisibility(false))
    )
  );

  errorSnackbar$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CallNotesActionTypes.SaveCallNoteFailure,
        CallNotesActionTypes.SaveCallNoteAudioFailure,
        CallNotesActionTypes.DeleteCallNoteFailure,
        CallNotesActionTypes.SaveCallNoteCommentFailure,
        CallNotesActionTypes.DeleteCallNoteCommentFailure
      ),
      map((action) => action.payload),
      map(
        (error: HttpErrorResponse) =>
          new OpenErrorSnackbar({
            duration: 5000,
            message: error.message,
            opened: true
          })
      )
    )
  );

  saveCallNoteSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CallNotesActionTypes.SaveCallNoteSuccess),
      switchMap(() => [
        new CloseDrawer(),
        new OpenSnackbar({
          duration: 5000,
          message: 'Call note saved successfully',
          opened: true
        })
      ])
    )
  );

  SaveCallNoteAudioSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CallNotesActionTypes.SaveCallNoteAudioSuccess),
      switchMap((res) => [
        new CloseDrawer(),
        new OpenSnackbar({
          duration: 5000,
          message: 'Call note saved successfully',
          opened: true
        })
      ])
    )
  );

  deleteCallNoteSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CallNotesActionTypes.DeleteCallNoteSuccess),
      switchMap(() => [
        new OpenSnackbar({
          duration: 5000,
          message: 'Call note deleted successfully',
          opened: true
        }),
        new CloseDrawer(),
        new SelectCallNoteId(null)
      ])
    )
  );

  saveCommentSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CallNotesActionTypes.SaveCallNoteCommentSuccess),
      map(
        () =>
          new OpenSnackbar({
            duration: 5000,
            message: 'Comment saved successfully',
            opened: true
          })
      )
    )
  );

  loadCallNotesByCompanyId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CallNotesActionTypes.LoadCallNotesByCompanyId),
      map((action: LoadCallNotesByCompanyId) => action.payload),
      concatMap(({ companyId, filter, params }) =>
        this.cnService.getCallNotes(filter, params).pipe(
          switchMap((res: JpiResponse<CallNote>) => {
            return [
              new LoadCallNotesByCompanyIdSuccess({
                companyId,
                data: convertToMap(res.items, 'id'),
                count: res.count
              }),
              new LoadCallNotesSuccess(convertToMap(res.items, 'id'))
            ];
          }),
          catchError((error) =>
            of(new LoadCallNotesByCompanyIdFailure({ companyId, error }))
          )
        )
      )
    )
  );

  loadCallNotesByCompanyIdWithFollowUps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CallNotesActionTypes.LoadCallNotesByCompanyIdWithFollowUps),
      map((action: LoadCallNotesByCompanyIdWithFollowUps) => action.payload),
      concatMap(({ companyId, filter, params }) =>
        this.cnService.getCallNotes(filter, params).pipe(
          switchMap((res: JpiResponse<CallNote>) => {
            return [
              new LoadCallNotesByCompanyIdWithFollowUpsSuccess({
                companyId,
                data: convertToMap(res.items, 'id'),
                count: res.count
              }),
              new LoadCallNotesSuccess(convertToMap(res.items, 'id'))
            ];
          }),
          catchError((error) =>
            of(new LoadCallNotesByCompanyIdWithFollowUpsFailure({ companyId, error }))
          )
        )
      )
    )
  );

  compCallNotesError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CallNotesActionTypes.LoadCallNotesByCompanyIdFailure),
      map((action: { type: string; payload: any }) => action.payload),
      map((error: HttpErrorResponse) => {
        return new OpenErrorSnackbar({
          duration: 10000,
          message: `${error.status}: ${error.message}`,
          opened: true
        });
      })
    )
  );
}
