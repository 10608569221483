import { RootState } from '../../store.reducer';
import { MemoizedSelector, createSelector } from '@ngrx/store';
import { UsersMap } from 'src/app/models/admin/users/users-map';
import { User } from 'src/app/models/admin/users/user';
import { IdNameItem } from 'src/app/models/id-name-item';
import { EmailUser } from 'src/app/models/admin/users/email-user';
import { selectUsers } from './multi-select-users.selector';

export const selectMultiEmailUsers: MemoizedSelector<
  RootState,
  EmailUser[]
> = createSelector(
  selectUsers,
  (users: UsersMap): EmailUser[] => {
    return Object.values(users)
      .map((user: User) => ({
        id: user.id,
        name: `${user.firstName} ${user.lastName}`,
        email: user.email
      }))
      .sort((a, b) => {
        const textA = a.name.toLowerCase();
        const textB = b.name.toLowerCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
  }
);
