import { ClientsActions, ClientsActionTypes } from './clients.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { Client } from 'src/app/models/admin/client/client';

export interface ClientsState {
  pending: boolean;
  error: HttpErrorResponse;
  activeClients: Client[];
  inactiveClients: Client[];
  currentClient: Client;
  clients?: Client[];
}

export const initialState: ClientsState = {
  pending: false,
  inactiveClients: [],
  activeClients: [],
  error: undefined,
  currentClient: undefined
};

export function clientsReducer(
  state = initialState,
  action: ClientsActions
): ClientsState {
  switch (action.type) {
    case ClientsActionTypes.SetCurrentClient:
      return {
        ...state,
        currentClient: action.payload
      };

    case ClientsActionTypes.ClearCurrentClient:
      return {
        ...state,
        currentClient: undefined
      };

    case ClientsActionTypes.LoadClients:
      return {
        ...state,
        pending: true
      };

    case ClientsActionTypes.LoadClientsSuccess:
      return {
        ...state,
        pending: false,
        activeClients: action.payload.filter((client) => client.active === true),
        inactiveClients: action.payload.filter((client) => client.active === false)
      };

    case ClientsActionTypes.LoadClientsFailure:
      return {
        ...state,
        pending: false,
        activeClients: [],
        inactiveClients: [],
        error: action.payload
      };

    case ClientsActionTypes.LoadCurrentClient:
      return {
        ...state,
        pending: true
      };

    case ClientsActionTypes.LoadCurrentClientSuccess:
      return {
        ...state,
        pending: false,
        currentClient: action.payload
      };

    case ClientsActionTypes.LoadCurrentClientFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case ClientsActionTypes.CreateClients:
      return {
        ...state,
        pending: true
      };

    case ClientsActionTypes.CreateClientsSuccess:
      return {
        ...state,
        pending: false,
        activeClients: [...state.activeClients, action.payload]
      };

    case ClientsActionTypes.CreateClientsFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case ClientsActionTypes.UpdateClientsSuccess:
      const updatedActiveClients = state.activeClients.map((client) =>
        action.payload.id === client.id ? action.payload : client
      );
      const updatedInactiveClients = state.inactiveClients.map((client) =>
        action.payload.id === client.id ? action.payload : client
      );
      return {
        ...state,
        pending: false,
        activeClients: updatedActiveClients,
        inactiveClients: updatedInactiveClients
      };

    case ClientsActionTypes.UpdateClientsFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case ClientsActionTypes.DeleteClients:
      return {
        ...state,
        pending: true
      };

    case ClientsActionTypes.DeleteClientsSuccess:
      return {
        ...state,
        pending: false,
        inactiveClients: state.inactiveClients.filter(
          (client) => client.id !== action.payload
        ),
        activeClients: state.activeClients.filter(
          (client) => client.id !== action.payload
        ),
        currentClient: undefined
      };

    case ClientsActionTypes.DeleteClientsFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    default:
      return state;
  }
}
