import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'tn-image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.scss']
})
export class ImageDialogComponent {
  isColumnLayout = false;

  constructor(
    public dialogRef: MatDialogRef<ImageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { imageUrl: string; narrative: string },
    public dialog: MatDialog
  ) {}

  onImageLoad(image: HTMLImageElement) {
    const aspectRatio = image.width / image.height;
    const tolerance = 0.1; // Adjust this value to define the allowed difference from a perfect square.

    if (image.width > image.height && Math.abs(aspectRatio - 1) > tolerance) {
      this.isColumnLayout = true;
    } else {
      this.isColumnLayout = false;
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
