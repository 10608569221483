import { Action } from '@ngrx/store';

export enum UndoDeleteActionTypes {
  DeleteObject = '[UndoDelete] Delete Object',
  ExecuteDelete = '[UndoDelete] Execute Delete',
  AddObjectsToDeletion = '[UndoDelete] Add Objects to Deletion',
  SetDeleteAction = '[UndoDelete] Set Delete Action',
  CancelDelete = '[UndoDelete] Cancel Delete',
  CloseDeleteSnackbar = '[UndoDelete] Close Delete Popup',
  ClearDeletedState = '[UndoDelete] Clear Deletion State'
}

export class DeleteObject implements Action {
  readonly type = UndoDeleteActionTypes.DeleteObject;
  constructor(public payload: { actionName: any; objectsToDelete: any[] }) {}
}

export class ExecuteDelete implements Action {
  readonly type = UndoDeleteActionTypes.ExecuteDelete;
}

export class AddObjectsToDeletion implements Action {
  readonly type = UndoDeleteActionTypes.AddObjectsToDeletion;
  constructor(public payload: any[]) {}
}

export class SetDeleteAction implements Action {
  readonly type = UndoDeleteActionTypes.SetDeleteAction;
  constructor(public payload: any) {}
}

export class CancelDelete implements Action {
  readonly type = UndoDeleteActionTypes.CancelDelete;
}

export class CloseDeleteSnackbar implements Action {
  readonly type = UndoDeleteActionTypes.CloseDeleteSnackbar;
}

export class ClearDeletedState implements Action {
  readonly type = UndoDeleteActionTypes.ClearDeletedState;
}

export type UndoDeleteActions =
  | DeleteObject
  | ExecuteDelete
  | AddObjectsToDeletion
  | SetDeleteAction
  | CancelDelete
  | CloseDeleteSnackbar
  | ClearDeletedState;
