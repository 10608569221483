import { OpportunitiesActions, OpportunitiesActionTypes } from './opportunities.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { GenericMap } from 'src/app/models/generic-map';
import { Opportunity } from 'src/app/models/Opportunity/opportunity';
import { ColumnHeader } from 'src/app/models/column-header';
import { OpportunitiesTableColumnsData } from './columns.data';
import { OpportunityItemsTableColumnsData } from './itemColumns.data';
import { GenericApi } from 'src/app/models/generic-api';
import { ItemCustomField } from 'src/app/models/Opportunity/item-custom-field';
import { OppTableSelect } from 'src/app/models/Opportunity/opp-table-select.enum';
import { ElasticOpportunity } from 'src/app/models/Opportunity/elastic-opportunity';
import { ElasticOppItem } from 'src/app/models/Opportunity/elastic-opp-item';
import { OpportunityCustomField } from 'src/app/models/Opportunity/opportunity-custom-field';
export interface OpportunitiesState {
  pending: boolean;
  pendingSingle: boolean;
  error: HttpErrorResponse;
  useDefaultColumns: boolean;
  selectedTable: OppTableSelect;
  selColsByOpp: ColumnHeader[];
  allColsByOpp: ColumnHeader[];
  selColsByItem: ColumnHeader[];
  allColsByItem: ColumnHeader[];
  elasticData: ElasticOpportunity[];
  elasticItemsData: ElasticOppItem[];
  data: GenericMap<Opportunity>;
  count: number;
  itemsCount: number;
  selectedOpportunityId: number;
  customOppFields: GenericApi<GenericMap<OpportunityCustomField>>;
  customItemFields: GenericApi<GenericMap<ItemCustomField>>;
  selectedCustomItemFieldId: number;
  selectedCustomOppFieldId: number;
  selectedItemId: number;
  showClosed: boolean;
  showOpenFollowUpCallNotes: boolean;
}
export const initialState: OpportunitiesState = {
  pending: false,
  pendingSingle: false,
  error: undefined,
  useDefaultColumns: true,
  selectedTable: OppTableSelect.ByOpp,
  selColsByOpp: [
    OpportunitiesTableColumnsData.find((column) => column.name === 'name'),
    OpportunitiesTableColumnsData.find((column) => column.name === 'companyName'),
    OpportunitiesTableColumnsData.find((column) => column.name === 'ownedByName'),
    OpportunitiesTableColumnsData.find((column) => column.name === 'closeDateEstimate'),
    OpportunitiesTableColumnsData.find((column) => column.name === 'total'),
    OpportunitiesTableColumnsData.find((column) => column.name === 'weightedTotal')
  ],
  allColsByOpp: OpportunitiesTableColumnsData,
  selColsByItem: [
    OpportunityItemsTableColumnsData.find((column) => column.name === 'oppName'),
    OpportunityItemsTableColumnsData.find((column) => column.name === 'companyName'),
    OpportunityItemsTableColumnsData.find((column) => column.name === 'itemName'),
    OpportunityItemsTableColumnsData.find((column) => column.name === 'amount'),
    OpportunityItemsTableColumnsData.find((column) => column.name === 'quantity'),
    OpportunityItemsTableColumnsData.find((column) => column.name === 'itemTotal'),
    OpportunityItemsTableColumnsData.find((column) => column.name === 'description')
  ],
  allColsByItem: OpportunityItemsTableColumnsData,
  elasticData: [],
  elasticItemsData: [],
  data: {},
  count: 0,
  itemsCount: 0,
  selectedOpportunityId: undefined,
  customOppFields: {
    pending: false,
    error: undefined,
    data: {}
  },
  customItemFields: {
    pending: false,
    error: undefined,
    data: {}
  },
  selectedCustomItemFieldId: undefined,
  selectedCustomOppFieldId: undefined,
  selectedItemId: undefined,
  showClosed: false,
  showOpenFollowUpCallNotes: false
};
export function opportunitiesReducer(
  state = initialState,
  action: OpportunitiesActions
): OpportunitiesState {
  switch (action.type) {
    case OpportunitiesActionTypes.LabelColumnsAdded: {
      return {
        ...state,
        useDefaultColumns: false,
        selColsByOpp: [...state.selColsByOpp, ...action.payload],
        selColsByItem: [...state.selColsByItem, ...action.payload]
      };
    }

    case OpportunitiesActionTypes.LabelColumnAdded: {
      return {
        ...state,
        useDefaultColumns: false,
        selColsByOpp: [...state.selColsByOpp, action.payload],
        selColsByItem: [...state.selColsByItem, action.payload]
      };
    }

    case OpportunitiesActionTypes.SelectOppTableColumns:
      return {
        ...state,
        useDefaultColumns: false,
        selColsByOpp: action.payload
      };

    case OpportunitiesActionTypes.SelectOppItemTableColumns:
      return {
        ...state,
        useDefaultColumns: false,
        selColsByItem: action.payload
      };
    case OpportunitiesActionTypes.SelectOpportunityId:
      return {
        ...state
      };
    case OpportunitiesActionTypes.ClearOpportunityId:
      return {
        ...state,
        selectedOpportunityId: undefined
      };
    case OpportunitiesActionTypes.LoadOpportunitiesTableColumnsFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };
    case OpportunitiesActionTypes.LoadOpportunityItemsTableColumnsFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };
    case OpportunitiesActionTypes.LoadOpportunities:
      return {
        ...state,
        pending: true,
        data: {}
      };
    case OpportunitiesActionTypes.LoadOpportunitiesSuccess:
      return {
        ...state,
        pending: false,
        error: undefined,
        data: action.payload
      };
    case OpportunitiesActionTypes.LoadOpportunitiesFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };
    case OpportunitiesActionTypes.LoadOpportunitiesElastic:
      return {
        ...state,
        pending: true
      };
    case OpportunitiesActionTypes.LoadOpportunitiesElasticSuccess:
      return {
        ...state,
        pending: false,
        error: undefined,
        elasticData: action.payload.fetchingMore
          ? state.elasticData.concat(action.payload.items)
          : action.payload.items
      };
    case OpportunitiesActionTypes.LoadOpportunitiesElasticFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case OpportunitiesActionTypes.LoadOppItemsElastic:
      return {
        ...state,
        pending: true
      };
    case OpportunitiesActionTypes.LoadOppItemsElasticSuccess:
      return {
        ...state,
        pending: false,
        error: undefined,
        elasticItemsData: action.payload.fetchingMore
          ? state.elasticItemsData.concat(action.payload.items)
          : action.payload.items
      };
    case OpportunitiesActionTypes.LoadOppItemsElasticFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case OpportunitiesActionTypes.LoadOpportunity:
      return {
        ...state,
        pendingSingle: true
      };
    case OpportunitiesActionTypes.LoadOpportunitySuccess:
      return {
        ...state,
        pendingSingle: false,
        error: undefined,
        data: {
          ...state.data,
          [action.payload.id]: action.payload
        }
      };
    case OpportunitiesActionTypes.LoadOpportunityFailure:
      return {
        ...state,
        pendingSingle: false,
        error: action.payload
      };
    case OpportunitiesActionTypes.LoadOppItemCustomFields:
      return {
        ...state,
        customItemFields: {
          ...state.customItemFields,
          pending: true,
          error: undefined
        }
      };
    case OpportunitiesActionTypes.LoadOppItemCustomFieldsSuccess:
      return {
        ...state,
        customItemFields: {
          pending: false,
          error: undefined,
          data: action.payload
        }
      };
    case OpportunitiesActionTypes.LoadOppItemCustomFieldsFailure:
      return {
        ...state,
        customItemFields: {
          ...state.customItemFields,
          pending: false,
          error: action.payload
        }
      };

    case OpportunitiesActionTypes.LoadOpportunityCustomFields:
      return {
        ...state,
        customOppFields: {
          ...state.customOppFields,
          pending: true,
          error: undefined
        }
      };
    case OpportunitiesActionTypes.LoadOpportunityCustomFieldsSuccess:
      return {
        ...state,
        customOppFields: {
          pending: false,
          error: undefined,
          data: action.payload
        }
      };
    case OpportunitiesActionTypes.LoadOpportunityCustomFieldsFailure:
      return {
        ...state,
        customOppFields: {
          ...state.customOppFields,
          pending: false,
          error: action.payload
        }
      };
    case OpportunitiesActionTypes.UpdateOpportunityCustomFieldSuccess:
      return {
        ...state,
        customOppFields: {
          pending: false,
          error: undefined,
          data: {
            ...state.customOppFields.data,
            [action.payload.id]: action.payload
          }
        }
      };
    case OpportunitiesActionTypes.ReorderOpportunityCustomFields:
      return {
        ...state,
        customOppFields: {
          ...state.customOppFields,
          pending: true,
          error: undefined
        }
      };
    case OpportunitiesActionTypes.ReorderOpportunityCustomFieldsSuccess:
      return {
        ...state,
        customOppFields: {
          pending: false,
          error: undefined,
          data: action.payload
        }
      };
    case OpportunitiesActionTypes.ReorderOpportunityCustomFieldsFailure:
      return {
        ...state,
        customOppFields: {
          ...state.customOppFields,
          pending: false,
          error: action.payload
        }
      };
    case OpportunitiesActionTypes.SetCurrentOppItemCustomFieldId:
      return {
        ...state,
        selectedCustomItemFieldId: action.payload
      };
    case OpportunitiesActionTypes.SetCurrentOpportunityCustomFieldId:
      return {
        ...state,
        selectedCustomOppFieldId: action.payload
      };

    case OpportunitiesActionTypes.SetCurrentOppItemId:
      return {
        ...state,
        selectedItemId: action.payload
      };
    case OpportunitiesActionTypes.ShowClosedOpps:
      return {
        ...state,
        showClosed: action.payload
      };
    case OpportunitiesActionTypes.ShowOpenFollowUpCallNotes:
      return {
        ...state,
        showOpenFollowUpCallNotes: action.payload
      };
    case OpportunitiesActionTypes.SetOppsCount:
      return {
        ...state,
        count: action.payload
      };
    case OpportunitiesActionTypes.SetItemsCount:
      return {
        ...state,
        itemsCount: action.payload
      };
    default:
      return state;
  }
}
