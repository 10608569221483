import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppViewComponent } from './shared/app-view/app-view.component';
import { AuthGuardService } from '../services/auth/auth-guard.service';
import { AuthenticationService } from '../app-v2/shared/services/authentication.service';
import { InquiryComponent } from './pages/inquiry/inquiry.component';

export const mainRoutes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then((m) => m.AuthViewModule),
    data: { title: 'Login | Voze' }
  },
  {
    path: 'request',
    component: InquiryComponent,
    data: { title: 'Request Information | Voze' }
  },
  { path: 'public/login', redirectTo: 'auth', pathMatch: 'prefix' },
  { path: 'login', redirectTo: 'auth' },
  {
    path: '',
    canActivate: [AuthGuardService, AuthenticationService],
    component: AppViewComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('src/app/view/pages/dashboard/dashboard.module').then(
            (m) => m.DashboardViewModule
          ),
        data: {
          title: 'Dashboard | Voze'
        }
      },
      {
        path: 'companies',
        loadChildren: () =>
          import('src/app/view/pages/companies/companies.module').then(
            (m) => m.CompaniesViewModule
          ),
        data: {
          title: 'Companies | Voze'
        }
      },
      {
        path: 'calendar',
        loadChildren: () =>
          import('src/app/view/pages/calendar/calendar.module').then(
            (m) => m.CalendarViewModule
          ),
        data: {
          title: 'Calendar | Voze'
        }
      },
      {
        path: 'activity',
        loadChildren: () =>
          import('src/app/view/pages/activity/activity.module').then(
            (m) => m.ActivityViewModule
          ),
        data: {
          title: 'Call Notes | Voze'
        }
      },
      {
        path: 'private-notes',
        loadChildren: () =>
          import('src/app/view/pages/private-notes/private-notes.module').then(
            (m) => m.PrivateNotesViewModule
          ),
        data: {
          title: 'Private Notes | Voze'
        }
      },
      // {
      //   path: 'maps',
      //   loadChildren: () =>
      //     import('src/app/view/pages/maps/maps.module').then(m => m.MapsViewModule),
      //   data: {
      //     title: 'Maps | Voze'
      //   }
      // },
      {
        path: 'opportunities',
        loadChildren: () =>
          import('src/app/view/pages/opportunities/opportunities.module').then(
            (m) => m.OpportunitiesViewModule
          ),
        data: {
          title: 'Opportunities | Voze'
        }
      },
      {
        path: 'projects',
        loadChildren: () =>
          import('src/app/view/pages/projects/projects.module').then(
            (m) => m.ProjectsViewModule
          ),
        data: {
          title: 'Projects | Voze'
        }
      },
      {
        path: 'sales',
        loadChildren: () =>
          import('src/app/view/pages/sales/sales.module').then((m) => m.SalesViewModule),
        data: {
          title: 'Sales | Voze'
        }
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('src/app/view/pages/reports/reports.module').then(
            (m) => m.ReportsViewModule
          ),
        data: {
          title: 'Reports | Voze'
        }
      },
      {
        path: 'mileage',
        loadChildren: () =>
          import('src/app/view/pages/mileage/mileage.module').then(
            (m) => m.MileageViewModule
          ),
        data: {
          title: 'Mileage | Voze'
        }
      },
      {
        path: 'expenses',
        loadChildren: () =>
          import('src/app/view/pages/expenses/expenses.module').then(
            (m) => m.ExpensesViewModule
          ),
        data: {
          title: 'Expenses | Voze'
        }
      },
      // {
      //   path: 'tutorials',
      //   loadChildren: () =>
      //     import('src/app/view/pages/tutorials/tutorials.module').then(
      //       (m) => m.TutorialsViewModule
      //     ),
      //   data: {
      //     title: 'Tutorials | Voze'
      //   }
      // },
      {
        path: 'whats-new',
        loadChildren: () =>
          import('src/app/view/pages/whats-new/whats-new.module').then(
            (m) => m.WhatsNewViewModule
          ),
        data: {
          title: 'Whats New | Voze'
        }
      },
      {
        path: 'help',
        loadChildren: () =>
          import('src/app/view/pages/help/help.module').then((m) => m.HelpViewModule),
        data: {
          title: 'Help | Voze'
        }
      },
      {
        path: 'user-settings',
        loadChildren: () =>
          import('src/app/view/pages/user-settings/user-settings.module').then(
            (m) => m.UserSettingsViewModule
          ),
        data: {
          title: 'User Settings | Voze'
        }
      },
      {
        path: 'system-settings',
        loadChildren: () =>
          import('src/app/view/pages/system-settings/system-settings.module').then(
            (m) => m.SystemSettingsViewModule
          ),
        data: {
          title: 'System Settings | Voze'
        }
      },
      {
        path: 'territory-management',
        loadChildren: () =>
          import(
            'src/app/view/pages/territory-management/territory-management.module'
          ).then((m) => m.TerritoryManagementViewModule),
        data: {
          title: 'Territories | Voze'
        }
      },
      {
        path: 'manage-users',
        loadChildren: () =>
          import('src/app/view/pages/manage-users/manage-users.module').then(
            (m) => m.ManageUsersViewModule
          ),
        data: {
          title: 'Manage Users | Voze'
        }
      },
      {
        path: 'clients',
        loadChildren: () =>
          import('src/app/view/pages/clients/clients.module').then(
            (m) => m.ClientsViewModule
          ),
        data: {
          title: 'Clients | Voze'
        }
      },
      {
        path: 'plans',
        loadChildren: () =>
          import('src/app/view/pages/plans/plans.module').then((m) => m.PlansModule),
        data: {
          title: 'Plans | Voze'
        }
      },
      {
        path: 'billing',
        loadChildren: () =>
          import('src/app/view/pages/billing/billing.module').then(
            (m) => m.BillingViewModule
          ),
        data: {
          title: 'Billing | Voze'
        }
      },
      {
        path: '',
        loadChildren: () =>
          import('src/app/app-v2/app-v2.module').then((m) => m.AppV2Module)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(mainRoutes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class RootRoutingModule {}
