import { Action, createReducer, on } from '@ngrx/store';
import { GenericApi } from 'src/app/models/generic-api';
import { GenericMap } from 'src/app/models/generic-map';
import { ProjectCustomField } from 'src/app/models/projects/project-custom-field';
import {
  LoadProjectCompanyCustomFields,
  LoadProjectCompanyCustomFieldsSuccess,
  LoadProjectCompanyCustomFieldsFailure,
  SetCurrentProjectCompanyCustomFieldId
} from './project-company-custom-fields.actions';

export const projectCompanyCustomFieldsFeatureKey = 'projectCompanyCustomFields';

export interface ProjectCompanyCustomFieldsState
  extends GenericApi<GenericMap<ProjectCustomField>> {
  selectedPcfId: number;
}

export const initialState: ProjectCompanyCustomFieldsState = {
  pending: false,
  error: undefined,
  selectedPcfId: null,
  data: {}
};

const reducer = createReducer(
  initialState,

  on(LoadProjectCompanyCustomFields, state => {
    return {
      ...state,
      pending: true,
      error: undefined
    };
  }),
  on(LoadProjectCompanyCustomFieldsSuccess, (state, action) => {
    return {
      ...state,
      pending: true,
      data: action.data
    };
  }),
  on(LoadProjectCompanyCustomFieldsFailure, (state, action) => {
    return {
      ...state,
      pending: true,
      error: action.error
    };
  }),
  on(SetCurrentProjectCompanyCustomFieldId, (state, action) => {
    return {
      ...state,
      selectedPcfId: action.id
    };
  })
);

export function projectCompanyCustomFieldsReducer(
  state: ProjectCompanyCustomFieldsState | undefined,
  action: Action
) {
  return reducer(state, action);
}
