import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import {
  LoadReportsFailure,
  LoadReportsSuccess,
  ReportsActionTypes,
  ReportsActions
} from './reports.actions';

@Injectable()
export class ReportsEffects {
  loadReports$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReportsActionTypes.LoadReports),
      concatMap(() =>
        /** An EMPTY observable only emits completion. Replace with your own observable API request */
        EMPTY.pipe(
          map(data => new LoadReportsSuccess({ data })),
          catchError(error => of(new LoadReportsFailure({ error })))
        )
      )
    )
  );

  constructor(private actions$: Actions<ReportsActions>) {}
}
