import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ProjectCustomField } from 'src/app/models/projects/project-custom-field';
import { GenericMap } from 'src/app/models/generic-map';

export const SetCurrentProjectCompanyCustomFieldId = createAction(
  '[ProjectCompanyCustomFields] Set Current Project Company Custom Field ID',
  props<{ id: number }>()
);

export const LoadProjectCompanyCustomFields = createAction(
  '[ProjectCompanyCustomFields] Load Project Company Custom Fields'
);

export const LoadProjectCompanyCustomFieldsSuccess = createAction(
  '[ProjectCompanyCustomFields] Load Project Company Custom Fields Success',
  props<{ data: GenericMap<ProjectCustomField> }>()
);

export const LoadProjectCompanyCustomFieldsFailure = createAction(
  '[ProjectCompanyCustomFields] Load Project Company Custom Fields Failure',
  props<{ error: HttpErrorResponse }>()
);

export const SaveProjectCompanyCustomField = createAction(
  '[ProjectCompanyCustomFields] Save Project Company Custom Field',
  props<{ data: ProjectCustomField }>()
);

export const SaveProjectCompanyCustomFieldSuccess = createAction(
  '[ProjectCompanyCustomFields] Save Project Company Custom Field Success',
  props<{ data: ProjectCustomField }>()
);

export const SaveProjectCompanyCustomFieldFailure = createAction(
  '[ProjectCompanyCustomFields] Save Project Company Custom Field Failure',
  props<{ error: HttpErrorResponse }>()
);

export const DeleteProjectCompanyCustomField = createAction(
  '[ProjectCompanyCustomFields] Delete Project Company Custom Field',
  props<{ data: ProjectCustomField }>()
);

export const DeleteProjectCompanyCustomFieldSuccess = createAction(
  '[ProjectCompanyCustomFields] Delete Project Company Custom Field Success',
  props<{ data: any }>()
);

export const DeleteProjectCompanyCustomFieldFailure = createAction(
  '[ProjectCompanyCustomFields] Delete Project Company Custom Field Failure',
  props<{ error: HttpErrorResponse }>()
);

export const UpdateProjectCompanyCustomFieldsListOrder = createAction(
  '[ProjectCompanyCustomFields] Update Project Company Custom Fields List Order',
  props<{ data: ProjectCustomField[] }>()
);

export const UpdateProjectCompanyCustomFieldsListOrderSuccess = createAction(
  '[ProjectCompanyCustomFields] Update Project Company Custom Fields List Order Success',
  props<{ data: ProjectCustomField[] }>()
);

export const UpdateProjectCompanyCustomFieldsListOrderFailure = createAction(
  '[ProjectCompanyCustomFields] Update Project Company Custom Fields List Order Failure',
  props<{ error: HttpErrorResponse }>()
);
