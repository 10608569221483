import { UserSettingsActions, UserSettingsActionTypes } from './user-settings.actions';

export interface UserSettingsState {
  pending: boolean;
}

export const initialState: UserSettingsState = {
  pending: false
};

export function userSettingsReducer(
  state = initialState,
  action: UserSettingsActions
): UserSettingsState {
  switch (action.type) {
    case UserSettingsActionTypes.LoadUserSettings:
      return { ...state, pending: true };

    case UserSettingsActionTypes.LoadUserSettingsSuccess:
      return { ...state, pending: false };

    case UserSettingsActionTypes.LoadUserSettingsFailure:
      return { ...state, pending: false };

    default:
      return state;
  }
}
