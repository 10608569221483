<company-popup *ngIf="companyPopup$" [(companyPopup)]="companyPopup$"></company-popup>
<call-note-popup
  *ngIf="callNotePopup$"
  [(callNotePopup)]="callNotePopup$"
  [(callNote)]="selectedCallNote"
  [(contacts)]="companyJPI.contacts"
  [(companyId)]="companyId"
  [(callNotes)]="callNotes"
>
</call-note-popup>
<call-note-popup
  *ngIf="followUpPopup$"
  [(callNotePopup)]="followUpPopup$"
  [(callNote)]="selectedCallNote"
  [callNoteExpanded]="false"
  [(companyId)]="companyId"
  [(callNotes)]="callNotes"
></call-note-popup>
<tn-searchResults
  *ngIf="showSearchResults"
  [searchResults]="searchResults"
></tn-searchResults>

<div *ngIf="!showSearchResults">
  <div
    class="loading-container"
    *ngIf="loadingCompany"
    fxFlex
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <dx-load-indicator id="medium-indicator" height="40" width="40"></dx-load-indicator>
    <h3>Loading...</h3>
  </div>
  <div *ngIf="!loadingCompany" fxFlex fxLayout="column">
    <div fxLayout="row" class="profile-container">
      <h3 class="company-name" fxLayoutAlign="start center">{{ companyJPI.name }}</h3>
      <div fxLayout="row wrap" fxLayoutAlign="start center">
        <div *ngFor="let group of displayedLabels">
          <div fxLayoutAlign="center center">
            <div fxLayout="column" fxLayoutAlign="start center">
              <div style="padding-top: 10px" fxLayout="column">
                <span
                  *ngIf="group.labels && group.labels.length > 0"
                  class="profile-container"
                >
                  <span class="text-muted text-small" fxLayoutAlign="center center">{{
                    group.name
                  }}</span>
                  <div fxLayout="row">
                    <div *ngFor="let label of group.labels">
                      <span class="company-chip">{{ label.name }}</span>
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex></div>
      <div fxLayoutAlign="end center" style="height: 45px" *ngIf="webRecording">
        <button
          tabindex="false"
          (click)="openCallNoteDrawer()"
          mat-icon-button
          matTooltip="Record Call Note"
          style="background-color: red"
        >
          <mat-icon style="color: white">mic</mat-icon>
        </button>
      </div>
      <div
        *ngIf="isList"
        id="profileButtonContainer"
        class="button button-blue"
        style="height: 35px"
        fxLayoutAlign="end center"
        (click)="newCompanyListMenu = true"
      >
        New
        <mat-icon>arrow_drop_down</mat-icon>
      </div>
    </div>
    <div class="profile-container" fxLayout="column">
      <div
        id="tabs-container"
        [ngClass]="{
          'fixed-tabs': fixed,
          'tabs-top-expanded': navExpanded && fixed,
          'tabs-top': !navExpanded && fixed,
          'fixed-tabs-popup': popupTabsFixed
        }"
        fxFlex
        class="tab-border"
      >
        <dx-tabs
          *ngIf="profileTabs.length < 8"
          [dataSource]="profileTabs"
          (onItemClick)="selectTab($event)"
          [width]="'50vw'"
          [height]="40"
        ></dx-tabs>
        <dx-tabs
          *ngIf="profileTabs.length > 7 && profileTabs.length < 11"
          [dataSource]="profileTabs"
          (onItemClick)="selectTab($event)"
          [width]="'75vw'"
          [height]="40"
        ></dx-tabs>
        <dx-tabs
          *ngIf="profileTabs.length > 10"
          [dataSource]="profileTabs"
          (onItemClick)="selectTab($event)"
          [height]="40"
        ></dx-tabs>
      </div>
      <div style="padding-top: 20px">
        <mat-accordion [multi]="true">
          <mat-expansion-panel
            class="v2-expansion-panel"
            *ngFor="let tab of profileTabs"
            [id]="tab.text"
            [(expanded)]="tab.isOpen"
          >
            <mat-expansion-panel-header
              [expandedHeight]="'40px'"
              [collapsedHeight]="'30px'"
              class="accordion-header"
            >
              <div fxLayout="row" fxFlex>
                <div fxLayoutAlign="start center" fxFlex>
                  <mat-panel-title class="accordion-title">
                    <span>{{ tab.text }}</span>
                  </mat-panel-title>
                </div>
                <div fxLayoutAlign="start center">
                  <mat-panel-description>
                    <span
                      *ngIf="
                        tab.text == 'Details' && tab.isOpen == true && canEditCompany
                      "
                      class="button-small button-blue"
                      style="font-size: 1em"
                      (click)="companyEdit(tab)"
                      >Edit</span
                    >
                    <span *ngFor="let section of customSection">
                      <span
                        *ngIf="
                          tab.text == section.Name && tab.isOpen == true && canEditCompany
                        "
                        class="button-small button-blue"
                        style="font-size: 1em"
                        (click)="editSection(customSection, tab)"
                        fxLayout="center center"
                        >Edit</span
                      >
                    </span>
                  </mat-panel-description>
                </div>
              </div>
            </mat-expansion-panel-header>

            <ng-template
              *ngIf="tab.text == 'Details'"
              id="Details"
              matExpansionPanelContent
              class="expanded"
            >
              <company-profile-details
                [companyJPI]="companyJPI"
                [company]="company"
                [(editCompanyPopup)]="editCompanyPopup"
                [isSalesAdmin]="isSalesAdmin"
                [(labelGroups)]="labelGroups"
                [(customSection)]="customSection"
                [(displayedLabels)]="displayedLabels"
              ></company-profile-details>
            </ng-template>

            <ng-template
              id="Activity"
              *ngIf="tab.text == 'Activity'"
              matExpansionPanelContent
              dynamicHeight="true"
            >
              <company-profile-activity
                [loadingCallNotes]="loadingCallNotes"
                [(companyJPI)]="companyJPI"
                [(callNotes)]="callNotes"
                [(selectedCallNote)]="selectedCallNote"
                [(callNotePopup)]="callNotePopup"
              ></company-profile-activity>
            </ng-template>

            <ng-template
              *ngIf="tab.text == 'Contacts'"
              matExpansionPanelContent
              dynamicHeight="true"
            >
              <company-profile-contacts
                [loadingContacts]="loadingContacts"
                [(companyJPI)]="companyJPI"
              >
              </company-profile-contacts>
            </ng-template>

            <ng-template *ngIf="tab.text == 'Sales Charts'" matExpansionPanelContent>
              <tn-procede-yearly-sales
                [(companyId)]="companyId"
              ></tn-procede-yearly-sales>
            </ng-template>

            <ng-template
              *ngIf="tab.text == 'Sales' && canSeeSales"
              matExpansionPanelContent
            >
              <company-profile-sales
                [(companyId)]="companyJPI.id"
                [(canSeeSales)]="canSeeSales"
              >
              </company-profile-sales>
            </ng-template>

            <ng-template *ngIf="tab.text == 'Files'" matExpansionPanelContent>
              <company-profile-files [companyJPI]="companyJPI"></company-profile-files>
            </ng-template>

            <ng-template *ngIf="tab.text == 'Excede'" matExpansionPanelContent>
              <div fxLayout="column" *ngIf="!loadingProcedeData">
                <span class="header">Summary</span>
                <div fxLayout="row">
                  <div fxFlex="10" fxLayout="column">
                    <span class="procede-header">DMS Customer ID</span>
                    <span
                      *ngIf="procedeData.CustomerId"
                      [innerText]="procedeData.CustomerId"
                    ></span>
                  </div>
                </div>
                <div fxLayout="row">
                  <div fxFlex="10" fxLayout="column">
                    <span class="procede-header">Amount Open</span>
                    <span
                      *ngIf="procedeData.AmountOpen"
                      [innerText]="
                        procedeData.AmountOpen | currency : 'USD' : 'symbol' : '1.2-2'
                      "
                    ></span>
                  </div>
                  <div fxFlex="10" fxLayout="column">
                    <span class="procede-header">Aged 0 Days Old</span>
                    <span
                      *ngIf="procedeData.AmountAgeDays0"
                      [innerText]="
                        procedeData.AmountAgeDays0 | currency : 'USD' : 'symbol' : '1.2-2'
                      "
                    ></span>
                  </div>
                  <div fxFlex="10" fxLayout="column">
                    <span class="procede-header">Aged 1-30 Days Old</span>
                    <span
                      *ngIf="procedeData.AmountAgeDays30"
                      [innerText]="
                        procedeData.AmountAgeDays30
                          | currency : 'USD' : 'symbol' : '1.2-2'
                      "
                    ></span>
                  </div>
                  <div fxFlex="10" fxLayout="column">
                    <span class="procede-header">Aged 31-60 Days Old</span>
                    <span
                      *ngIf="procedeData.AmountAgeDays60"
                      [innerText]="
                        procedeData.AmountAgeDays60
                          | currency : 'USD' : 'symbol' : '1.2-2'
                      "
                    ></span>
                  </div>
                  <div fxFlex="10" fxLayout="column">
                    <span class="procede-header">Aged 61-90 Days Old</span>
                    <span
                      *ngIf="procedeData.AmountAgeDays90"
                      [innerText]="
                        procedeData.AmountAgeDays90
                          | currency : 'USD' : 'symbol' : '1.2-2'
                      "
                    ></span>
                  </div>
                  <div fxFlex="10" fxLayout="column">
                    <span class="procede-header">Aged 90+ Days Old</span>
                    <span
                      *ngIf="procedeData.AmountAgeDaysOver90"
                      [innerText]="
                        procedeData.AmountAgeDaysOver90
                          | currency : 'USD' : 'symbol' : '1.2-2'
                      "
                    ></span>
                  </div>
                </div>
                <div fxLayout="row" class="vertical-padding">
                  <div fxFlex="15" fxLayout="column" style="margin: 5px">
                    <span class="procede-header">Service</span>
                    <div fxLayout="row">
                      <strong>Lifetime</strong>
                      <span
                        *ngIf="procedeData.ServiceLifeTime"
                        fxFlex
                        fxLayoutAlign="end center"
                        [innerText]="
                          procedeData.ServiceLifeTime
                            | currency : 'USD' : 'symbol' : '1.2-2'
                        "
                      ></span>
                    </div>
                    <div fxLayout="row">
                      <strong>Month to Date</strong>
                      <span
                        *ngIf="procedeData.ServiceMonthToDate"
                        fxFlex
                        fxLayoutAlign="end center"
                        [innerText]="
                          procedeData.ServiceMonthToDate
                            | currency : 'USD' : 'symbol' : '1.2-2'
                        "
                      ></span>
                    </div>
                    <div fxLayout="row">
                      <strong>Year to Date</strong>
                      <span
                        *ngIf="procedeData.ServiceYearToDate"
                        fxFlex
                        fxLayoutAlign="end center"
                        [innerText]="
                          procedeData.ServiceYearToDate
                            | currency : 'USD' : 'symbol' : '1.2-2'
                        "
                      ></span>
                    </div>
                    <div fxLayout="row">
                      <strong>Previous Year</strong>
                      <span
                        *ngIf="procedeData.ServicePreviousYear"
                        fxFlex
                        fxLayoutAlign="end center"
                        [innerText]="
                          procedeData.ServicePreviousYear
                            | currency : 'USD' : 'symbol' : '1.2-2'
                        "
                      ></span>
                    </div>
                  </div>
                  <div fxFlex="15" fxLayout="column" style="margin: 5px">
                    <span class="procede-header">Lease</span>
                    <div fxLayout="row">
                      <strong>Lifetime</strong>
                      <span
                        *ngIf="procedeData.LeaseLifeTime"
                        fxFlex
                        fxLayoutAlign="end center"
                        [innerText]="
                          procedeData.LeaseLifeTime
                            | currency : 'USD' : 'symbol' : '1.2-2'
                        "
                      ></span>
                    </div>
                    <div fxLayout="row">
                      <strong>Month to Date</strong>
                      <span
                        *ngIf="procedeData.LeaseMonthToDate"
                        fxFlex
                        fxLayoutAlign="end center"
                        [innerText]="
                          procedeData.LeaseMonthToDate
                            | currency : 'USD' : 'symbol' : '1.2-2'
                        "
                      ></span>
                    </div>
                    <div fxLayout="row">
                      <strong>Year to Date</strong>
                      <span
                        *ngIf="procedeData.LeaseYearToDate"
                        fxFlex
                        fxLayoutAlign="end center"
                        [innerText]="
                          procedeData.LeaseYearToDate
                            | currency : 'USD' : 'symbol' : '1.2-2'
                        "
                      ></span>
                    </div>
                    <div fxLayout="row">
                      <strong>Previous Year</strong>
                      <span
                        *ngIf="procedeData.LeasePreviousYear"
                        fxFlex
                        fxLayoutAlign="end center"
                        [innerText]="
                          procedeData.LeasePreviousYear
                            | currency : 'USD' : 'symbol' : '1.2-2'
                        "
                      ></span>
                    </div>
                  </div>
                  <div fxFlex="15" fxLayout="column" style="margin: 5px">
                    <span class="procede-header">Parts</span>
                    <div fxLayout="row">
                      <strong>Lifetime</strong>
                      <span
                        *ngIf="procedeData.PartsLifeTime"
                        fxFlex
                        fxLayoutAlign="end center"
                        [innerText]="
                          procedeData.PartsLifeTime
                            | currency : 'USD' : 'symbol' : '1.2-2'
                        "
                      ></span>
                    </div>
                    <div fxLayout="row">
                      <strong>Month to Date</strong>
                      <span
                        *ngIf="procedeData.PartsMonthToDate"
                        fxFlex
                        fxLayoutAlign="end center"
                        [innerText]="
                          procedeData.PartsMonthToDate
                            | currency : 'USD' : 'symbol' : '1.2-2'
                        "
                      ></span>
                    </div>
                    <div fxLayout="row">
                      <strong>Year to Date</strong>
                      <span
                        *ngIf="procedeData.PartsYearToDate"
                        fxFlex
                        fxLayoutAlign="end center"
                        [innerText]="
                          procedeData.PartsYearToDate
                            | currency : 'USD' : 'symbol' : '1.2-2'
                        "
                      ></span>
                    </div>
                    <div fxLayout="row">
                      <strong>Previous Year</strong>
                      <span
                        *ngIf="procedeData.PartsPreviousYear"
                        fxFlex
                        fxLayoutAlign="end center"
                        [innerText]="
                          procedeData.PartsPreviousYear
                            | currency : 'USD' : 'symbol' : '1.2-2'
                        "
                      ></span>
                    </div>
                  </div>
                  <div fxFlex="15" fxLayout="column" style="margin: 5px">
                    <span class="procede-header">Vehicle</span>
                    <div fxLayout="row">
                      <strong>Lifetime</strong>
                      <span
                        *ngIf="procedeData.VehicleLifeTime"
                        fxFlex
                        fxLayoutAlign="end center"
                        [innerText]="
                          procedeData.VehicleLifeTime
                            | currency : 'USD' : 'symbol' : '1.2-2'
                        "
                      ></span>
                    </div>
                    <div fxLayout="row">
                      <strong>Month to Date</strong>
                      <span
                        *ngIf="procedeData.VehicleMonthToDate"
                        fxFlex
                        fxLayoutAlign="end center"
                        [innerText]="
                          procedeData.VehicleMonthToDate
                            | currency : 'USD' : 'symbol' : '1.2-2'
                        "
                      ></span>
                    </div>
                    <div fxLayout="row">
                      <strong>Year to Date</strong>
                      <span
                        *ngIf="procedeData.VehicleYearToDate"
                        fxFlex
                        fxLayoutAlign="end center"
                        [innerText]="
                          procedeData.VehicleYearToDate
                            | currency : 'USD' : 'symbol' : '1.2-2'
                        "
                      ></span>
                    </div>
                    <div fxLayout="row">
                      <strong>Previous Year</strong>
                      <span
                        *ngIf="procedeData.VehiclePreviousYear"
                        fxFlex
                        fxLayoutAlign="end center"
                        [innerText]="
                          procedeData.VehiclePreviousYear
                            | currency : 'USD' : 'symbol' : '1.2-2'
                        "
                      ></span>
                    </div>
                  </div>
                  <div fxFlex="15" fxLayout="column" style="margin: 5px">
                    <span class="procede-header">Fuel</span>
                    <div fxLayout="row">
                      <strong>Lifetime</strong>
                      <span
                        *ngIf="procedeData.FuelLifeTime"
                        fxFlex
                        fxLayoutAlign="end center"
                        [innerText]="
                          procedeData.FuelLifeTime | currency : 'USD' : 'symbol' : '1.2-2'
                        "
                      ></span>
                    </div>
                    <div fxLayout="row">
                      <strong>Month to Date</strong>
                      <span
                        *ngIf="procedeData.FuelMonthToDate"
                        fxFlex
                        fxLayoutAlign="end center"
                        [innerText]="
                          procedeData.FuelMonthToDate
                            | currency : 'USD' : 'symbol' : '1.2-2'
                        "
                      ></span>
                    </div>
                    <div fxLayout="row">
                      <strong>Year to Date</strong>
                      <span
                        *ngIf="procedeData.FuelYearToDate"
                        fxFlex
                        fxLayoutAlign="end center"
                        [innerText]="
                          procedeData.FuelYearToDate
                            | currency : 'USD' : 'symbol' : '1.2-2'
                        "
                      ></span>
                    </div>
                    <div fxLayout="row">
                      <strong>Previous Year</strong>
                      <span
                        *ngIf="procedeData.FuelPreviousYear"
                        fxFlex
                        fxLayoutAlign="end center"
                        [innerText]="
                          procedeData.FuelPreviousYear
                            | currency : 'USD' : 'symbol' : '1.2-2'
                        "
                      ></span>
                    </div>
                  </div>
                </div>
                <span class="header">Orders</span>
                <mat-tab-group class="company-tabs" dynamicHeight="true">
                  <mat-tab label="Repairs">
                    <dx-data-grid [dataSource]="procedeData.repairOrders">
                      <dxi-column dataField="OrderId" caption="Order ID"></dxi-column>
                      <dxi-column
                        dataField="ServiceWriter"
                        caption="Service Writer"
                      ></dxi-column>
                      <dxi-column dataField="UnitId" caption="Unit ID"></dxi-column>
                      <dxi-column dataField="FleetId" caption="Fleet ID"></dxi-column>
                      <dxi-column dataField="OrderStatusReadable" caption="Order Status">
                      </dxi-column>
                      <dxi-column
                        dataField="CreatedDate"
                        dataType="date"
                        caption="Created Date"
                      >
                      </dxi-column>
                    </dx-data-grid>
                  </mat-tab>
                  <mat-tab label="Lease/Rentals">
                    <dx-data-grid [dataSource]="procedeData.leaseOrders">
                      <dxi-column dataField="OrderId" caption="Order ID"></dxi-column>
                      <dxi-column
                        dataField="ContractType"
                        caption="Contract Type"
                      ></dxi-column>
                      <dxi-column dataField="OrderStatusReadable" caption="Order Status">
                      </dxi-column>
                      <dxi-column
                        dataField="CreatedDate"
                        dataType="date"
                        caption="Created Date"
                      >
                      </dxi-column>
                    </dx-data-grid>
                  </mat-tab>
                  <mat-tab label="Parts">
                    <dx-data-grid
                      [dataSource]="procedeData.partsOrders"
                      [masterDetail]="{ enabled: true, template: 'detail' }"
                    >
                      <dxi-column dataField="OrderId" caption="Order ID"></dxi-column>
                      <dxi-column dataField="OrderStatusReadable" caption="Order Status">
                      </dxi-column>
                      <dxi-column
                        dataField="CreatedDate"
                        dataType="date"
                        caption="Created Date"
                      >
                      </dxi-column>

                      <div *dxTemplate="let parts of 'detail'">
                        <dx-data-grid [dataSource]="parts.data.Parts">
                          <dxi-column dataField="PartId" caption="Part ID"></dxi-column>
                          <dxi-column
                            dataField="PartDescription"
                            caption="Part Description"
                          >
                          </dxi-column>
                          <dxi-column dataField="QuantityShip" caption="Quantity Ship">
                          </dxi-column>
                          <dxi-column
                            dataField="QuantityBackOrder"
                            caption="Quantity Backorder"
                          ></dxi-column>
                          <dxi-column
                            dataField="CreatedDate"
                            caption="Created Date"
                            dataType="date"
                          ></dxi-column>
                        </dx-data-grid>
                      </div>
                    </dx-data-grid>
                  </mat-tab>
                </mat-tab-group>
              </div>
            </ng-template>

            <div *ngFor="let section of customSection">
              <ng-template *ngIf="tab.text == section.Name" matExpansionPanelContent>
                <div *ngIf="section.Fields.length > 0" class="custom-section-padding">
                  <div fxLayout="row wrap">
                    <div
                      *ngFor="let field of section.Fields"
                      fxLayout="column"
                      style="padding-bottom: 7px"
                    >
                      <div *ngIf="field.Value && field.FieldType == 11" fxFlex="16">
                        <span
                          class="text-muted text-small"
                          [innerText]="field.Name"
                        ></span>
                        <span class="link" (click)="openExternalWebsite(field.Value)">{{
                          field.Value
                        }}</span>
                      </div>
                      <div
                        *ngIf="
                          field.Value &&
                          (field.FieldType == 1 ||
                            field.FieldType == 2 ||
                            field.FieldType == 8 ||
                            field.FieldType == 10 ||
                            field.FieldType == 12)
                        "
                        fxFlex="16"
                      >
                        <span
                          class="text-muted text-small"
                          [innerText]="field.Name"
                        ></span>
                        <span>{{ field.Value }}</span>
                      </div>
                      <div *ngIf="field.Value && field.FieldType == 3" fxFlex="16">
                        <span
                          class="text-muted text-small"
                          [innerText]="field.Name"
                        ></span>
                        <span *ngIf="field.Value">{{ field.Value | number }}</span>
                      </div>
                      <div *ngIf="field.Value && field.FieldType == 4" fxFlex="16">
                        <span
                          class="text-muted text-small"
                          [innerText]="field.Name"
                        ></span>
                        <span *ngIf="field.Value">{{ field.Value | currency }}</span>
                      </div>
                      <div *ngIf="field.Value && field.FieldType == 5" fxFlex="16">
                        <span
                          class="text-muted text-small"
                          [innerText]="field.Name"
                        ></span>
                        <span>{{ field.Value }}</span>
                      </div>
                      <div *ngIf="field.Value && field.FieldType == 6" fxFlex="16">
                        <span
                          class="text-muted text-small"
                          [innerText]="field.Name"
                        ></span>
                        <span *ngIf="field.Value">{{ field.Value | date }}</span>
                      </div>
                      <div *ngIf="field.Value && field.FieldType == 9" fxFlex="16">
                        <span
                          class="text-muted text-small"
                          [innerText]="field.Name"
                        ></span>
                        <span>{{ field.Value }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <mat-tab-group
                  [selectedIndex]="0"
                  (focusChange)="selectedTab($event)"
                  dynamicHeight="true"
                >
                  <div *ngFor="let Tab of section.Tabs">
                    <mat-tab label="{{ Tab.Name }}" class="tab-header">
                      <div class="custom-section-padding">
                        <div *ngIf="section.Fields.length == 0">
                          <!-- TODO make this row wrap work -->
                          <div fxLayout="row wrap">
                            <div
                              *ngFor="let field of Tab.Fields"
                              style="padding-bottom: 7px"
                              fxFlex="16"
                            >
                              <!-- <span class="text-muted text-small" [innerText]="field.Name"></span>
                                                            <span *ngIf="field.Value && field.FieldType==11" class="link" target="_blank" [routerLink]="field.Value">{{field.Value}}</span>
                                                            <span *ngIf="field.Value && (field.FieldType==1 || field.FieldType==8 || field.FieldType==10 || field.FieldType==12)">{{field.Value}}</span>
                                                            <span *ngIf="field.Value && field.FieldType==2 && (field.Value=='true' || field.Value == true)">Yes</span>
                                                            <span *ngIf="(!field.Value || field.Value=='false') && field.FieldType==2">No</span>
                                                            <span *ngIf="field.Value && field.FieldType==3">{{field.Value | number}}</span>
                                                            <span *ngIf="field.Value && field.FieldType==4">{{field.Value | currency}}</span>
                                                            <span *ngIf="field.Value && field.FieldType==5">{{field.Value}}</span>
                                                            <span *ngIf="field.Value && field.FieldType==6">{{field.Value | date}}</span>
                                                            <span *ngIf="field.Value && field.FieldType==9">{{field.Value}}</span> -->
                              <div
                                *ngIf="field.Value && field.FieldType == 11"
                                fxLayout="column"
                              >
                                <span
                                  class="text-muted text-small"
                                  [innerText]="field.Name"
                                ></span>
                                <span
                                  class="link"
                                  (click)="openExternalWebsite(field.Value)"
                                  >{{ field.Value }}</span
                                >
                              </div>
                              <div
                                *ngIf="
                                  field.Value &&
                                  (field.FieldType == 1 ||
                                    field.FieldType == 2 ||
                                    field.FieldType == 8 ||
                                    field.FieldType == 10 ||
                                    field.FieldType == 12)
                                "
                                fxLayout="column"
                              >
                                <span
                                  class="text-muted text-small"
                                  [innerText]="field.Name"
                                ></span>
                                <span>{{ field.Value }}</span>
                              </div>
                              <div
                                *ngIf="field.Value && field.FieldType == 3"
                                fxLayout="column"
                              >
                                <span
                                  class="text-muted text-small"
                                  [innerText]="field.Name"
                                ></span>
                                <span *ngIf="field.Value">{{
                                  field.Value | number
                                }}</span>
                              </div>
                              <div
                                *ngIf="field.Value && field.FieldType == 4"
                                fxLayout="column"
                              >
                                <span
                                  class="text-muted text-small"
                                  [innerText]="field.Name"
                                ></span>
                                <span *ngIf="field.Value">{{
                                  field.Value | currency
                                }}</span>
                              </div>
                              <div
                                *ngIf="field.Value && field.FieldType == 5"
                                fxLayout="column"
                              >
                                <span
                                  class="text-muted text-small"
                                  [innerText]="field.Name"
                                ></span>
                                <span>{{ field.Value }}</span>
                              </div>
                              <div
                                *ngIf="field.Value && field.FieldType == 6"
                                fxLayout="column"
                              >
                                <span
                                  class="text-muted text-small"
                                  [innerText]="field.Name"
                                ></span>
                                <span *ngIf="field.Value">{{ field.Value | date }}</span>
                              </div>
                              <div
                                *ngIf="field.Value && field.FieldType == 9"
                                fxLayout="column"
                              >
                                <span
                                  class="text-muted text-small"
                                  [innerText]="field.Name"
                                ></span>
                                <span>{{ field.Value }}</span>
                              </div>
                              <div *ngIf="!field.Value" fxFlex="0"></div>
                            </div>
                          </div>
                          <div style="padding-top: 10px"></div>
                        </div>
                      </div>
                    </mat-tab>
                  </div>
                </mat-tab-group>
              </ng-template>
            </div>
            <ng-template
              id="Additional Fields"
              *ngIf="tab.text == 'Custom Fields'"
              matExpansionPanelContent
            >
              <div class="panel-padding">
                <div
                  class="loading-container"
                  *ngIf="loadingContacts"
                  fxFlex
                  fxLayout="column"
                  fxLayoutAlign="center center"
                >
                  <dx-load-indicator
                    id="medium-indicator"
                    height="40"
                    width="40"
                  ></dx-load-indicator>
                  <h3>Loading...</h3>
                </div>
                <iframe [src]="safeUrl"></iframe>
              </div>
            </ng-template>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
</div>

<dx-popup
  (onScroll)="onWindowScroll($event)"
  [width]="150"
  [height]="'auto'"
  [showTitle]="false"
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [(visible)]="newCompanyListMenu"
  [shading]="false"
  [position]="{
    my: 'top',
    at: 'left bottom',
    of: '#profileButtonContainer',
    offset: '15 0'
  }"
>
  <div *dxTemplate="let data of 'content'">
    <div fxLayout="column" fxFlex>
      <span *ngIf="callNotesFeature == 1" class="list-options" (click)="newCallNote()"
        >Call Note</span
      >
      <span *ngIf="callNotesFeature == 1" class="list-options" (click)="newFollowUp()"
        >Follow-up</span
      >
      <span *ngIf="editContactsFeature == 1" class="list-options" (click)="newContact()"
        >Contact</span
      >
    </div>
  </div>
</dx-popup>
