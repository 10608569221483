import { MergeRequest } from './../../models/companies/merge-request';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { CompanyV2 } from '../../models/companies/company-V2';
import { AuthenticationService } from '../authentication.service';
import { GlobalFilter } from './../../models/global-filter';
import { FilteredList } from '../../models/filteredList';
import { RootState } from 'src/app/store/store.reducer';
import { Store } from '@ngrx/store';
import { ServicesModule } from 'src/app/services/services.module';

@Injectable({
  providedIn: ServicesModule
})
export class CompanyJPIService {
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private http: HttpClient,
    private store: Store<RootState>
  ) {
    // super('/companies', authService);
  }

  getJPICompanies(
    limit?: number,
    offset?: number,
    field?: string,
    query?: string,
    orderBy?: string
  ): Promise<any> {
    const params: any = {};

    const apiURL = '/companies';
    if (limit) {
      params['limit'] = limit;
    }
    if (offset) {
      params['offset'] = offset;
    }
    if (field) {
      params['fields'] = field;
    }
    if (query) {
      params['query'] = query;
    }
    if (orderBy) {
      params['orderBy'] = orderBy;
    }

    return this.authService
      .makeJpiRequest(apiURL, params, null, 'get')
      .then(response => JSON.parse(response));
  }

  getById(id: number): Promise<CompanyV2> {
    return this.authService
      .makeJpiRequest('/companies/' + id, null, null, 'get')
      .then(company => {
        return JSON.parse(company);
      });
  }

  create(company: any): Promise<CompanyV2> {
    return this.authService
      .makeJpiRequest('/companies', null, company, 'post')
      .then(company => {
        return JSON.parse(company);
      });
  }

  update(company: CompanyV2): Promise<CompanyV2> {
    return this.authService
      .makeJpiRequest('/companies/' + company.id, null, company, 'put')
      .then(company => {
        return JSON.parse(company);
      });
  }

  delete(company: CompanyV2): Promise<CompanyV2> {
    return this.authService
      .makeJpiRequest('/companies/' + company.id, null, company, 'delete')
      .then(company => {
        return JSON.parse(company);
      });
  }

  getCompanyByIdJPI(companyId: number): Promise<CompanyV2> {
    const authService: AuthenticationService = new AuthenticationService(
      this.router,
      this.http,
      this.store
    );

    return authService
      .makeJpiRequest('/companies/' + companyId, null, null, 'get')
      .then(result => JSON.parse(result));
  }

  getJPICompaniesFiltered(
    skip: number,
    limit: number,
    orderField: string,
    orderAsc: boolean,
    filter: GlobalFilter
  ): Promise<FilteredList> {
    const authService: AuthenticationService = new AuthenticationService(
      this.router,
      this.http,
      this.store
    );
    if (limit == undefined) {
      limit = 25;
    }
    if (skip == undefined) {
      skip = 0;
    }
    const params = { skip: skip, limit: limit };
    let url: string = '/companies/search';
    if (orderField) {
      url += '?order=' + (orderAsc ? '%2B' : '%2D') + orderField;
    }

    return authService
      .makeJpiRequest(url, params, filter, 'post')
      .then(response => JSON.parse(response));
  }

  getCompanyListJPI() {
    const authService: AuthenticationService = new AuthenticationService(
      this.router,
      this.http,
      this.store
    );
    return authService
      .makeJpiRequest(`/companylists/`, null, null, 'get')
      .then(response => response);
  }

  exportCompanies(
    orderField: string,
    orderAsc: boolean,
    filter: GlobalFilter
  ): Promise<string> {
    let url: string = '/companies/export';
    if (orderField) {
      url += '?order=' + (orderAsc ? '%2B' : '%2D') + orderField;
    }

    return this.authService
      .makeJpiRequest(url, null, filter, 'post')
      .then(response => response);
  }
}
