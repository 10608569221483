import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from '../authentication.service';
import { Field } from './../../models/call-notes/field';

@Injectable()
export class CallNoteFieldsService {
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private http: HttpClient
  ) {
    // super('/callnotefields', authService);
  }

  getAll(): Promise<Field[]> {
    return this.authService
      .makeJpiRequest('/callnotefields', null, null, 'get')
      .then(fields => {
        return JSON.parse(fields);
      });
  }

  getById(): Promise<Field[]> {
    return this.authService
      .makeJpiRequest('/callnotefields', null, null, 'get')
      .then(field => {
        return JSON.parse(field);
      });
  }

  create(callNoteField: any): Promise<Field> {
    return this.authService
      .makeJpiRequest('/callnotefields', null, callNoteField, 'post')
      .then(field => {
        return JSON.parse(field);
      });
  }

  update(callNoteField: Field): Promise<Field> {
    return this.authService
      .makeJpiRequest('/callnotefields/' + callNoteField.id, null, callNoteField, 'put')
      .then(field => {
        return JSON.parse(field);
      });
  }

  delete(callNoteField: Field): Promise<Field> {
    return this.authService
      .makeJpiRequest(
        '/callnotefields/' + callNoteField.id,
        null,
        callNoteField,
        'delete'
      )
      .then(field => {
        return JSON.parse(field);
      });
  }

  getCallNoteFieldsSorted(): Promise<any> {
    return this.authService
      .makeJpiRequest('/callnotefields/', null, null, 'get')
      .then(callNoteFields => {
        callNoteFields = JSON.parse(callNoteFields);
        return callNoteFields.sort(
          (a: Field, b: Field) => a.callnoteOrder - b.callnoteOrder
        );
      });
  }

  updateCallNoteFields(callNoteField): Promise<any> {
    return this.authService
      .makeJpiRequest('/callnotefields/' + callNoteField.id, null, callNoteField, 'put')
      .then(callNoteFields => {
        callNoteFields = JSON.parse(callNoteFields);
        return callNoteFields.sort(
          (a: Field, b: Field) => a.callnoteOrder - b.callnoteOrder
        );
      });
  }

  getCallNoteFieldsForCallNote(callNoteId: number): Promise<any> {
    return this.authService
      .makeJpiRequest('/callnotes/' + callNoteId + '/fields', null, null, 'get')
      .then(result => {
        return JSON.parse(result);
      });
  }

  getCallNoteFieldByIdForCallNote(callNoteId: number, field: Field): Promise<any> {
    return this.authService
      .makeJpiRequest('/callnotes/' + callNoteId + '/' + field.id, null, null, 'get')
      .then(result => {
        return JSON.parse(result);
      });
  }

  addCallNoteFieldForCallNote(callNoteId: number, field: Field): Promise<any> {
    return this.authService
      .makeJpiRequest('/callnotes/' + callNoteId + '/', null, field, 'post')
      .then(result => {
        return JSON.parse(result);
      });
  }

  updateCallNoteFieldForCallNote(callNoteId: number, field: Field): Promise<any> {
    return this.authService
      .makeJpiRequest('/callnotes/' + callNoteId + '/' + field.id, null, field, 'put')
      .then(result => {
        return JSON.parse(result);
      });
  }

  deleteCallNoteFieldForCallNote(callNoteId: number, field: Field): Promise<any> {
    return this.authService
      .makeJpiRequest('/callnotes/' + callNoteId + '/' + field.id, null, field, 'delete')
      .then(result => {
        return JSON.parse(result);
      });
  }

  getAllDropdownOptions(): Promise<any[]> {
    return this.authService
      .makeJpiRequest(`/callnotefields/dropdownfields`, null, null, 'get')
      .then(result => {
        return JSON.parse(result);
      }) as Promise<any[]>;
  }
}
