import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CloseDrawer } from 'src/app/store/layout/layout.actions';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import { selectIdNameCurrentUser } from 'src/app/store/users/selectors/id-name-current-user.selector';
import { Subscription } from 'rxjs';
import { CreateTodo, UpdateTodo, DeleteTodo } from 'src/app/store/todos/todos.actions';
import { Todo } from 'src/app/models/todo/todo';

@Component({
  selector: 'tn-todo-form',
  templateUrl: './todo-form.component.html',
  styleUrls: ['./todo-form.component.scss']
})
export class TodoFormComponent implements OnInit {
  @Input() data;
  todoForm: FormGroup;
  $currentUser: Subscription;
  currentTodo: Todo;
  $pendingActivity: Subscription;
  pendingActivity: boolean;

  constructor(private store: Store<RootState>) {}

  ngOnInit(): void {
    this.$currentUser = this.store.select(selectIdNameCurrentUser).subscribe((user) => {
      this.todoForm = new FormGroup({
        id: new FormControl(null),
        description: new FormControl(null, Validators.required),
        complete: new FormControl(false),
        agentId: new FormControl(user.id),
        completeDate: new FormControl(null)
      });
    });
    if (this.data) {
      this.currentTodo = this.data.todo;
      this.todoForm.patchValue({
        id: this.currentTodo.id,
        description: this.currentTodo.description,
        complete: this.currentTodo.complete,
        agentId: this.currentTodo.agentId,
        completeDate: this.currentTodo.completeDate
      });
    }
    this.$pendingActivity = this.store.select('todos', 'pending').subscribe((pending) => {
      this.pendingActivity = pending;
    });
  }

  saveTodo() {
    if (this.currentTodo) {
      this.store.dispatch(new UpdateTodo(this.todoForm.value));
      this.store.dispatch(new CloseDrawer());
    } else {
      this.store.dispatch(new CreateTodo(this.todoForm.value));
    }
  }

  onKeydown(event) {
    event.preventDefault();
  }

  cancel() {
    this.store.dispatch(new CloseDrawer());
    if (this.currentTodo) {
      this.store.dispatch(new CloseDrawer());
    } else {
    }
  }

  delete() {
    this.store.dispatch(new DeleteTodo(this.currentTodo));
    this.store.dispatch(new CloseDrawer());
  }
}
