<call-note-popup *ngIf="showCallNotePopup" [(callNotePopup)]="showCallNotePopup" [(callNote)]="selectedCallNote" [callNoteExpanded]="true"></call-note-popup>
<contact-popup *ngIf="showContactPopup" [(contactPopup)]="showContactPopup" [(contact)]="selectedContact" [(companyId)]="selectedContact.account"></contact-popup>
    
<div class="loading-container-centered" *ngIf="!searchResults" fxLayoutAlign="center center">
    <dx-load-indicator id="medium-indicator" height="40" width="40"></dx-load-indicator>
    <h3>Loading...</h3>
</div>
<div fxLayout="row">
    <div fxLayout="column" *ngIf="searchResults && searchResults[0]" fxFlex="50">
        <div class="result-header" fxLayout="row">
            <div fxFlex>
                <strong>Companies</strong><small>&nbsp;(<span *ngIf="searchResults[0].countRelation != 'EQUAL_TO'">More
                        than&nbsp;</span><span [innerText]="searchResults[0].count"></span>&nbsp;companies matching your
                    search</small>)
            </div>
            <div class="result-icon" (click)="openCompanyList()">
                <small class="link">See all</small>
            </div>
        </div>
        <div fxLayout="row" *ngFor="let result of searchResults[0].items; let i = index" class="result-line"
            [ngClass]="{'selected': selectedIndex == i && selectedItems == 0}">
            <a (click)="navigateToCompany(result.id)" fxLayout="row">
                <span style="font-weight: 500">{{result.name}}</span>&nbsp;
                <span>{{result | companyAddress}}</span>
            </a>
        </div>
    </div>
    <div fxLayout="column" *ngIf="searchResults && searchResults[1]" fxFlex="50">
        <div class="result-header" fxLayout="row">
            <div fxFlex>
                <strong>Contacts</strong><small>&nbsp;(<span *ngIf="searchResults[1].countRelation != 'EQUAL_TO'">More
                        than&nbsp;</span><span [innerText]="searchResults[1].count"></span>&nbsp;companies with contacts
                    matching your search</small>)
            </div>
            <div class="result-icon" (click)="openContactList()">
                <small class="link">See all</small>
            </div>
        </div>
        <div fxLayout="row" *ngFor="let result of searchResults[1].items; let i = index" class="result-line"
            [ngClass]="{'selected': selectedIndex == i && selectedItems == 1}">
            <a (click)="openContact(result)" fxLayout="row">
                <span
                    style="font-weight: 500">{{result.firstName}}&nbsp;{{result.lastName}}</span>&nbsp;({{result.companyName}})
            </a>
        </div>
    </div>
</div>
<div fxLayout="column" *ngIf="searchResults && searchResults[2]" style="width: 100%;">
    <div class="result-header" fxLayout="row">
        <div fxFlex>
            <strong>Call Notes</strong><small>&nbsp;(<span *ngIf="searchResults[2].countRelation != 'EQUAL_TO'">More
                    than&nbsp;</span><span [innerText]="searchResults[2].count"></span>&nbsp;call notes matching your search</small>)
        </div>
        <div class="result-icon" (click)="openCallNoteList()">
            <small class="link">See all</small>
        </div>
    </div>
    <div fxLayout="row" *ngFor="let result of searchResults[2].items; let i = index" class="result-line"
        [ngClass]="{'selected': selectedIndex == i && selectedItems == 2}">
        <a (click)="openCallNote(result)" fxFlex>
            <div fxFlex="40">
                <span style="font-weight: 500">{{result.callDate.toLocaleDateString()}}&nbsp;{{result.createdBy.firstName}}&nbsp;{{result.createdBy.lastName}}&nbsp;({{result.company.name}})</span>
            </div>
            <div>
                {{calculateNarrative(result)}}
            </div>
        </a>
    </div>
</div>