<div class="white-padding" fxLayout="row">
  <div fxFlex="4"></div>
  <div
    class="client-input edit-company-dropdown input-padding row-click popup-expanded-row"
    fxLayout="row"
    (click)="expandFollowUpRow()"
    fxFlex
  >
    <div fxFlex>Follow-up</div>
    <mat-icon *ngIf="!followUpExpanded" class="icon-large">arrow_drop_down</mat-icon>
    <mat-icon *ngIf="followUpExpanded" class="icon-large">arrow_drop_up</mat-icon>
  </div>
  <div fxFlex="4"></div>
</div>
<div *ngIf="followUpExpanded">
  <div class="client-input input-padding" fxLayout="row">
    <div fxFlex="4"></div>
    <div fxLayout="column" fxFlex>
      <span>Description</span>
      <dx-text-area
        *ngIf="editCallNotesFeature == 1"
        [(value)]="callNote.followUp.task"
        [autoResizeEnabled]="true"
      ></dx-text-area>
      <dx-text-area
        *ngIf="editCallNotesFeature == 0"
        [(value)]="callNote.followUp.task"
        [autoResizeEnabled]="true"
        disabled="true"
      ></dx-text-area>
    </div>
    <div fxFlex="4"></div>
  </div>
  <div class="client-input input-padding" fxLayout="row">
    <div fxFlex="4"></div>
    <div fxLayout="column">
      <div>Start Date</div>
      <dx-date-box
        #startDateBox
        *ngIf="editCallNotesFeature == 1"
        [(value)]="callNote.followUp.dueDateStart"
        type="datetime"
        [showAnalogClock]="false"
        (onOptionChanged)="
          startDateChanged(callNote.followUp.dueDateStart, callNote.followUp.dueDateEnd)
        "
        [showClearButton]="true"
        (onOpened)="incrementedDates($event, callNote.followUp.dueDateStart)"
      ></dx-date-box>
      <dx-date-box
        *ngIf="editCallNotesFeature == 0"
        [(value)]="callNote.followUp.dueDateStart"
        type="datetime"
        disabled="true"
      ></dx-date-box>
    </div>
    <div fxFlex="2"></div>
    <div fxLayout="column">
      <div>End Date</div>
      <dx-date-box
        #endDateBox
        *ngIf="editCallNotesFeature == 1"
        [(value)]="callNote.followUp.dueDateEnd"
        [showAnalogClock]="false"
        type="datetime"
        (onOptionChanged)="
          endDateChanged(callNote.followUp.dueDateStart, callNote.followUp.dueDateEnd)
        "
        [showClearButton]="true"
        (onOpened)="incrementedDates($event, callNote.followUp.dueDateEnd)"
        [disabled]="isEndDateDisabled(callNote.followUp.dueDateStart)"
      ></dx-date-box>
      <dx-date-box
        *ngIf="editCallNotesFeature == 0"
        [(value)]="callNote.followUp.dueDateEnd"
        type="datetime"
        disabled="true"
      ></dx-date-box>
    </div>
    <div fxFlex="4"></div>
  </div>
  <div fxLayout="row">
    <div fxFlex="4"></div>
    <div fxFlex class="client-input input-padding" fxLayout="column">
      <span fxLayoutAlign="start center">Assigned Sales Rep</span>
      <dx-select-box
        *ngIf="
          editCallNotesFeature == 1 && assignTasksFeature && callNote.followUp.assignedTo
        "
        [dataSource]="companyAgents"
        displayExpr="fullName"
        valueExpr="id"
        [value]="callNote.followUp.assignedTo.id"
        [searchEnabled]="true"
        (onSelectionChanged)="assignedAgentChange($event)"
      ></dx-select-box>
      <dx-select-box
        *ngIf="
          editCallNotesFeature == 0 ||
          (!assignTasksFeature && callNote.followUp.assignedTo)
        "
        [dataSource]="companyAgents"
        displayExpr="fullName"
        valueExpr="id"
        [value]="callNote.followUp.assignedTo.id"
        disabled="true"
      ></dx-select-box>
    </div>
    <div fxFlex="8"></div>
    <div class="client-input input-padding" fxLayout="row" fxLayoutAlign="start center">
      <dx-check-box
        *ngIf="editCallNotesFeature == 1"
        [(value)]="callNote.followUp.complete"
        text="Complete"
      ></dx-check-box>
      <dx-check-box
        *ngIf="editCallNotesFeature == 0"
        [(value)]="callNote.followUp.complete"
        disabled="true"
        text="Complete"
      ></dx-check-box>
    </div>
    <div fxFlex="4"></div>
  </div>
</div>
