import { IdNameItem } from 'src/app/models/id-name-item';
import { RootState } from '../../store.reducer';
import { createSelector } from '@ngrx/store';
import { GenericMap } from 'src/app/models/generic-map';
import { User } from 'src/app/models/admin/users/user';

export const selectPrivateNotes = (state: RootState) => state.privateNotes.data;
export const selectAssignableUsers = (state: RootState) =>
  state.privateNotes.assignableUsers;

export const selectAssignableUsersAsIdNameItems: (state: RootState) => IdNameItem[] =
  createSelector(
    selectAssignableUsers,
    (assignableUsers: GenericMap<User>): IdNameItem[] => {
      return Object.values(assignableUsers).map((user: User) => {
        return user.lastName
          ? { id: user.id, name: user.firstName + ' ' + user.lastName }
          : { id: user.id, name: user.firstName };
      });
    }
  );
