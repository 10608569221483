import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';

import * as ProjectCustomFieldsActions from './project-custom-fields.actions';
import { ProjectsService } from 'src/app/services/projects/projects.service';
import { convertToMap } from 'src/app/utils/convertToMap';
import { ProjectCustomField } from 'src/app/models/projects/project-custom-field';
import { Store } from '@ngrx/store';
import { RootState } from '../../store.reducer';
import { selectAgencyId } from '../../companies/selectors/files-as-array.selector';

@Injectable()
export class ProjectCustomFieldsEffects {
  loadProjectCustomFields$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectCustomFieldsActions.LoadProjectCustomFields),
      concatMap(() =>
        this.projService.getProjectCustomFields().pipe(
          map(data =>
            ProjectCustomFieldsActions.LoadProjectCustomFieldsSuccess({
              data: convertToMap(data, 'id')
            })
          ),
          catchError(error =>
            of(ProjectCustomFieldsActions.LoadProjectCustomFieldsFailure({ error }))
          )
        )
      )
    );
  });

  saveProjectCustomField$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectCustomFieldsActions.SaveProjectCustomField),
      map(action => action.data),
      withLatestFrom(this.store.select(selectAgencyId)),
      concatMap(([pcf, agencyId]: [ProjectCustomField, number]) => {
        if (!!pcf.id) {
          return this.projService.updateProjectCustomField(pcf).pipe(
            switchMap(data => [
              ProjectCustomFieldsActions.SaveProjectCustomFieldSuccess({ data }),
              ProjectCustomFieldsActions.LoadProjectCustomFields()
            ]),
            catchError(error =>
              of(ProjectCustomFieldsActions.SaveProjectCustomFieldFailure({ error }))
            )
          );
        } else {
          return this.projService.createProjectCustomField({ ...pcf, agencyId }).pipe(
            switchMap(data => [
              ProjectCustomFieldsActions.SaveProjectCustomFieldSuccess({ data }),
              ProjectCustomFieldsActions.LoadProjectCustomFields()
            ]),
            catchError(error =>
              of(ProjectCustomFieldsActions.LoadProjectCustomFieldsFailure({ error }))
            )
          );
        }
      })
    );
  });

  deleteProjectCustomField$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectCustomFieldsActions.DeleteProjectCustomField),
      map(action => action.data),
      concatMap((pcf: ProjectCustomField) =>
        this.projService.deleteProjectCustomField(pcf).pipe(
          switchMap(data => [
            ProjectCustomFieldsActions.DeleteProjectCustomFieldSuccess({ data }),
            ProjectCustomFieldsActions.LoadProjectCustomFields()
          ]),
          catchError(error =>
            of(ProjectCustomFieldsActions.DeleteProjectCustomFieldFailure({ error }))
          )
        )
      )
    );
  });

  updateProjectCustomFieldsListOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectCustomFieldsActions.UpdateProjectCustomFieldsListOrder),
      map(action => action.data),
      concatMap((pCFs: ProjectCustomField[]) =>
        this.projService.updateProjectCustomFieldsListOrder(pCFs).pipe(
          map(data =>
            ProjectCustomFieldsActions.UpdateProjectCustomFieldsListOrderSuccess({
              data
            })
          ),
          catchError(error =>
            of(
              ProjectCustomFieldsActions.UpdateProjectCustomFieldsListOrderFailure({
                error
              })
            )
          )
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private projService: ProjectsService,
    private store: Store<RootState>
  ) {}
}
