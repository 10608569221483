import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule, MatChipList } from '@angular/material/chips';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  DxAccordionModule,
  DxAutocompleteModule,
  DxBarGaugeModule,
  DxButtonModule,
  DxChartModule,
  DxCheckBoxModule,
  DxCircularGaugeModule,
  DxDataGridModule,
  DxDateBoxModule,
  DxDropDownBoxModule,
  DxFileUploaderModule,
  DxFilterBuilderModule,
  DxFormModule,
  DxLinearGaugeModule,
  DxListModule,
  DxLoadIndicatorModule,
  DxLoadPanelModule,
  DxLookupModule,
  DxMapModule,
  DxMenuModule,
  DxNumberBoxModule,
  DxPopoverModule,
  DxPopupModule,
  DxRadioGroupModule,
  DxSchedulerModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxSwitchModule,
  DxTabPanelModule,
  DxTabsModule,
  DxTagBoxModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxTileViewModule,
  DxToastModule,
  DxTooltipModule,
  DxTreeListModule,
  DxTreeViewModule,
  DxValidatorModule,
  DxPivotGridModule,
  DxFunnelModule,
  DxPieChartModule
} from 'devextreme-angular';
import { MatTreeModule } from '@angular/material/tree';
import { CompanyListComponent } from '../modules/companies/company-list/company-list.component';
import { CurrentTerritoryAssignmentsComponent } from '../modules/companies/company-list/current-territory-assignments.component';
import { CompanyProfileActivityComponent } from '../modules/companies/company-profile/company-profile-activity/company-profile-activity.component';
import { CompanyProfileContactsComponent } from '../modules/companies/company-profile/company-profile-contacts/company-profile-contacts.component';
import { CompanyProfileCustomFieldsComponent } from '../modules/companies/company-profile/company-profile-custom-fields/company-profile-custom-fields.component';
import { CompanyProfileFilesComponent } from '../modules/companies/company-profile/company-profile-files/company-profile-files.component';
import { CompanyProfilePageComponent } from '../modules/companies/company-profile/company-profile-page.component';
import { CompanyProfilePopupComponent } from '../modules/companies/company-profile/company-profile-popup.component';
import { CompanyProfileSalesComponent } from '../modules/companies/company-profile/company-profile-sales/company-profile-sales.component';
import { CompanyProfileComponent } from '../modules/companies/company-profile/company-profile.component';
import { SearchResultsComponent } from '../modules/companies/search-results/search-results.component';
import { ProcedeYearlySalesComponent } from '../modules/dashboard/company/procede-yearly-sales.component';
import { CompanyProfileDetailsComponent } from './../modules/companies/company-profile/company-profile-details/company-profile-details.component';
import { FilteringIndicatorComponent } from './filtering/filtering-indicator.component';
import { CompanyAddressPipe } from './pipes/company-address.pipe';
import { CallNoteCommentsComponent } from './popups/call-note/call-note-comments.component';
import { CallNoteDetailsComponent } from './popups/call-note/call-note-details.component';
import { CallNoteFollowUpComponent } from './popups/call-note/call-note-follow-up.component';
import { CallNotePopupComponent } from './popups/call-note/call-note-popup.component';
import { CancelPopupComponent } from './popups/cancel/cancel-popup.component';
import { ColorSwatchComponent } from './popups/color-swatch/color-swatch/color-swatch.component';
import { ChildCompanyPopupComponent } from './popups/company/child-company-popup.component';
import { CompanyPopupAddressesComponent } from './popups/company/company-popup-addresses.component';
import { CompanyPopupCompanyDetailsComponent } from './popups/company/company-popup-company-details.component';
import { CompanyPopupLabelsComponent } from './popups/company/company-popup-labels.component';
import { CompanyPopupTerritoriesAndRepsComponent } from './popups/company/company-popup-territories-and-reps.component';
import { CompanyPopupComponent } from './popups/company/company-popup.component';
import { ContactPopupComponent } from './popups/contact/contact-popup.component';
import { DeletePopupComponent } from './popups/delete/delete-popup.component';
import { FileUploadComponent } from './popups/file-upload/file-upload-popup.component';
import { LoadingPopupComponent } from './popups/loading-indicator/loading-popup.component';
import { AdminService } from './services/admin/admin.service';
import { CustomFieldService } from './services/admin/custom-field.service';
import { CompanyLabelGroupService } from './services/admin/customization/company-label-group.service';
import { CompanyLabelService } from './services/admin/customization/company-label.service';
import { ExpenseTypesService } from './services/admin/customization/expense-types.service';
import { ExpenseService } from './services/admin/customization/expense.service';
import { FieldTypeService } from './services/admin/customization/field-type.service';
import { LabelService } from './services/admin/customization/label.service';
import { MileageService } from './services/admin/mileage.service';
import { AgentService } from './services/agent.service';
import { BulkOperationsService } from './services/bulk-operations.service';
import { CallNoteCommentService } from './services/call-notes/call-note-comments.service';
import { CallNoteFieldsService } from './services/call-notes/call-note-fields.service';
import { CallNoteListService } from './services/call-notes/call-note-list.service';
import { CallNoteTypeService } from './services/call-notes/call-note-type.service';
import { CallNoteService } from './services/call-notes/call-note.service';
import { CallTypeService } from './services/call-type.service';
import { CompanyListService } from './services/companies/company-list.service';
import { CompanyService } from './services/companies/company.service';
import { ContactJPIService } from './services/contact-JPI.service';
import { ContactService } from './services/contact.service';
import { DashboardService } from './services/dashboard.service';
import { ExpandService } from './services/expand.service';
import { FileService } from './services/file.service';
import { ListService } from './services/lists.service';
import { MapService } from './services/map.service';
import { NotificationService } from './services/notification.service';
import { ActivityPermissionsService } from './services/permissions/activity-permissions.service';
import { AdminPermissionsService } from './services/permissions/admin-permissions.service';
import { AppsPermissionsService } from './services/permissions/apps-permissions.service';
import { CompanyPermissionsService } from './services/permissions/company-permissions.service';
import { FeaturePermissionsService } from './services/permissions/feature-permissions.service';
import { MapsPermissionsService } from './services/permissions/maps-permissions.service';
import { SalesPermissionsService } from './services/permissions/sales-permissions.service';
import { ProgressBoardService } from './services/progress-board.service';
import { RoleService } from './services/role.service';
import { RouteService } from './services/route.service';
import { SalesJPIService } from './services/sales-JPI.sevice';
import { SalesService } from './services/sales.service';
import { RegionService } from './services/territories/region.service';
import { TerritoryTypeService } from './services/territories/territory-type.service';
import { TerritoryService } from './services/territories/territory.service';
import { TutorialService } from './services/tutorial.service';
import { UserService } from './services/user.service';
import { DailyActivityReportService } from './services/daily-activity-report.service';
import { MatButtonModule } from '@angular/material/button';
import { CompanyMergeService } from 'src/app/services/companies/companyMerge.service';
import { StubComponent } from './stub.component';
import { TerritoriesSelectComponent } from './territories-select/territories-select.component';

@NgModule({
  declarations: [
    CallNotePopupComponent,
    CancelPopupComponent,
    DeletePopupComponent,
    ProcedeYearlySalesComponent,
    LoadingPopupComponent,
    SearchResultsComponent,
    CompanyPopupComponent,
    CompanyProfileComponent,
    CompanyProfileDetailsComponent,
    CompanyProfileContactsComponent,
    CompanyProfileSalesComponent,
    CompanyProfileFilesComponent,
    CompanyProfileActivityComponent,
    CompanyProfileCustomFieldsComponent,
    CompanyProfilePageComponent,
    CompanyProfilePopupComponent,
    CompanyListComponent,
    FilteringIndicatorComponent,
    CurrentTerritoryAssignmentsComponent,
    ContactPopupComponent,
    ChildCompanyPopupComponent,
    FileUploadComponent,
    ColorSwatchComponent,
    CallNoteFollowUpComponent,
    CallNoteCommentsComponent,
    CallNoteDetailsComponent,
    CompanyPopupLabelsComponent,
    CompanyPopupAddressesComponent,
    CompanyPopupCompanyDetailsComponent,
    CompanyPopupTerritoriesAndRepsComponent,
    CompanyAddressPipe,
    StubComponent,
    TerritoriesSelectComponent
  ],
  imports: [
    MatTreeModule,
    MatAutocompleteModule,
    DxDropDownBoxModule,
    DxSchedulerModule,
    DxTileViewModule,
    DxTooltipModule,
    DxPopoverModule,
    DxLoadIndicatorModule,
    DxLookupModule,
    DxTabsModule,
    DxTabPanelModule,
    DxListModule,
    DxScrollViewModule,
    DxDateBoxModule,
    DxFileUploaderModule,
    DxLoadPanelModule,
    DxAutocompleteModule,
    DxNumberBoxModule,
    DxTextBoxModule,
    DxCheckBoxModule,
    DxRadioGroupModule,
    DxTagBoxModule,
    DxSwitchModule,
    DxTextAreaModule,
    DxValidatorModule,
    DxToastModule,
    DxAccordionModule,
    DxMapModule,
    DxSelectBoxModule,
    DxFilterBuilderModule,
    DxPopupModule,
    DxTreeListModule,
    DxDataGridModule,
    DxButtonModule,
    DxChartModule,
    DxFormModule,
    DxMenuModule,
    FormsModule,
    MatGridListModule,
    HttpClientModule,
    FlexLayoutModule,
    MatTabsModule,
    MatTooltipModule,
    MatIconModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatInputModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatExpansionModule,
    DxTreeViewModule,
    DxCircularGaugeModule,
    DxBarGaugeModule,
    MatRadioModule,
    DxLinearGaugeModule,
    ScrollingModule,
    CommonModule,
    DxPivotGridModule,
    DxFunnelModule,
    DxPieChartModule,
    MatChipsModule,
    MatButtonModule
  ],
  exports: [
    CallNotePopupComponent,
    CancelPopupComponent,
    DeletePopupComponent,
    CallNoteCommentsComponent,
    CallNoteDetailsComponent,
    CompanyPopupTerritoriesAndRepsComponent,
    CompanyPopupAddressesComponent,
    CompanyPopupCompanyDetailsComponent,
    ProcedeYearlySalesComponent,
    LoadingPopupComponent,
    SearchResultsComponent,
    DxDropDownBoxModule,
    DxSchedulerModule,
    CompanyPopupComponent,
    CompanyProfileComponent,
    CompanyProfilePopupComponent,
    CompanyProfileDetailsComponent,
    CompanyProfileContactsComponent,
    CompanyProfileSalesComponent,
    CompanyProfileFilesComponent,
    CompanyProfileActivityComponent,
    CompanyProfileCustomFieldsComponent,
    FilteringIndicatorComponent,
    CurrentTerritoryAssignmentsComponent,
    ContactPopupComponent,
    ChildCompanyPopupComponent,
    FileUploadComponent,
    DxTileViewModule,
    DxTooltipModule,
    DxPopoverModule,
    DxLoadIndicatorModule,
    DxLookupModule,
    DxTabsModule,
    DxTabPanelModule,
    DxListModule,
    DxScrollViewModule,
    DxDateBoxModule,
    DxFileUploaderModule,
    DxLoadPanelModule,
    DxAutocompleteModule,
    DxNumberBoxModule,
    DxTextBoxModule,
    DxCheckBoxModule,
    DxRadioGroupModule,
    DxTagBoxModule,
    DxSwitchModule,
    DxTextAreaModule,
    DxValidatorModule,
    DxToastModule,
    DxAccordionModule,
    DxMapModule,
    DxSelectBoxModule,
    DxFilterBuilderModule,
    DxPopupModule,
    DxTreeListModule,
    DxDataGridModule,
    DxButtonModule,
    DxChartModule,
    DxFormModule,
    DxMenuModule,
    FormsModule,
    MatGridListModule,
    HttpClientModule,
    FlexLayoutModule,
    MatTabsModule,
    MatTooltipModule,
    MatIconModule,
    MatSelectModule,
    MatMenuModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatInputModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatExpansionModule,
    DxTreeViewModule,
    DxCircularGaugeModule,
    DxBarGaugeModule,
    MatRadioModule,
    DxLinearGaugeModule,
    DxBarGaugeModule,
    ColorSwatchComponent,
    ScrollingModule,
    CommonModule,
    CompanyAddressPipe,
    DxPivotGridModule,
    DxFunnelModule,
    DxPieChartModule,
    MatChipList,
    TerritoriesSelectComponent,
    MatTreeModule
  ],
  providers: [
    CompanyService,
    CompanyMergeService,
    CompanyListService,
    CompanyLabelService,
    CompanyLabelGroupService,
    NotificationService,
    CustomFieldService,
    ContactService,
    CallNoteService,
    CallNoteCommentService,
    CallNoteFieldsService,
    AgentService,
    ExpenseTypesService,
    CallTypeService,
    CallNoteTypeService,
    AdminService,
    DashboardService,
    ExpandService,
    ExpenseService,
    ProgressBoardService,
    FileService,
    LabelService,
    RegionService,
    ListService,
    MapService,
    MileageService,
    SalesService,
    SalesJPIService,
    TerritoryService,
    TerritoryTypeService,
    FieldTypeService,
    ContactJPIService,
    TutorialService,
    UserService,
    RoleService,
    RouteService,
    CallNoteListService,
    ActivityPermissionsService,
    AdminPermissionsService,
    AppsPermissionsService,
    CompanyPermissionsService,
    FeaturePermissionsService,
    MapsPermissionsService,
    SalesPermissionsService,
    BulkOperationsService,
    DailyActivityReportService
  ]
})
export class SharedModule {}
