<dx-popup [width]="310" [height]="150" [showTitle]="false" [dragEnabled]="false" [closeOnOutsideClick]="false"
 [(visible)]="cancelPopup" shadingColor="rgba(0,0,0,0.5)">
	<div *dxTemplate="let data of 'content'">
		<div fxLayout="column" fxFlex>
			<h3>Are you sure you want to cancel?</h3>
			<div fxLayout="row" fxLayoutAlign="center center">
				<span class="button button-white" (click)="closeCancelPopup()">No</span>
				<span class="button button-red" (click)="cancelConfirmed()">Yes</span>
			</div>
		</div>
	</div>
</dx-popup>