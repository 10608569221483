import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Mileage } from 'src/app/models/activities/mileage';

@Injectable({
  providedIn: 'root'
})
export class MileageService {
  private jpiUrl = environment.jpiUrl;
  constructor(private http: HttpClient) {}

  getAll(dateRange?: { begin: string; end: string }) {
    const params = {
      startDate: null,
      endDate: null
    };
    if (!!dateRange && dateRange.begin && !!dateRange.end) {
      params.startDate = dateRange.begin;
      params.endDate = dateRange.end;
    } else {
      delete params.startDate;
      delete params.endDate;
    }
    return this.http.get(`${this.jpiUrl}/mileage/v2/byDateRange`, { params });
  }

  getById(mileageId: number) {
    return this.http.get(`${this.jpiUrl}/mileage/v2/${mileageId}`);
  }

  addMileage(mileage: Mileage) {
    return this.http.post(`${this.jpiUrl}/mileage/v2`, mileage);
  }

  updateMileage(mileage: Mileage) {
    return this.http.put(`${this.jpiUrl}/mileage/v2/${mileage.id}`, mileage);
  }

  deleteMileage(mileageId: number) {
    return this.http.delete(`${this.jpiUrl}/mileage/v2/${mileageId}`);
  }
}
