import { Injectable } from '@angular/core';

import { AuthenticationService } from '../../shared/services/authentication.service';
import { Lists } from '../models/lists';

@Injectable()
export class ListService {
	myLists: Lists[];

	constructor(private authService: AuthenticationService) { }

	clearServiceData(): void { }

	getListItems(): Promise<Lists[]> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/list/GetAll', '', 'get').then(function (result: any) {
			self.myLists = JSON.parse(result);
			return self.myLists;
		});
	}

	updateItem(list: Lists): Promise<boolean> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/list/update', list, 'post').then(function (result: any) {
			return true;
		});
	}

	addItem(): Promise<boolean> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/list/add', '', 'post').then(function (result: any) {
			return true;
		});
	}
	archiveItem(ItemID: number): Promise<boolean> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/list/archiveItem?picklistID=' + ItemID, '', 'post').then(function (result: any) {
			return true;
		});
	}

	enableItem(ItemID: number): Promise<boolean> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/list/enable?picklistID=' + ItemID, '', 'post').then(function (result: any) {
			return true;
		});
	}
}
