import { Injectable } from '@angular/core';
import notify from 'devextreme/ui/notify';

@Injectable()
export class NotificationService {
    constructor() {
    }

    showError(notificationMessage: string): void {
        notify({ message: notificationMessage, position: { at: 'top', offset: '0 30' } }, 'error', 5000);
    }

    showSuccess(notificationMessage: string): void {
        notify({ message: notificationMessage, position: { at: 'top', offset: '0 30' } }, 'success', 5000);
    }
}
