import { MemoizedSelector, createSelector } from '@ngrx/store';
import { RootState } from '../../store.reducer';
import { selectFeatures } from 'src/app/view/shared/sidenav/sidenav-links.selector';
import { GenericMap } from 'src/app/models/generic-map';
import { Feature } from 'src/app/models/features/feature';
import { FeaturePermission } from 'src/app/app-v2/shared/models/admin/feature';
import { CompanyAssignment } from 'src/app/models/sales/companyAssignment';
import { selectCompanyParamId } from '../../companies/selectors/current-company.selector';
import { GenericApi } from 'src/app/models/generic-api';

export const selectCompanyAssignments = (state: RootState) =>
  state.sales.companyAssignments.data;

export const selectCurrentCompanyAssignments: MemoizedSelector<
  RootState,
  CompanyAssignment[]
> = createSelector(
  selectCompanyAssignments,
  selectCompanyParamId,
  (
    caData: GenericMap<GenericMap<GenericApi<CompanyAssignment>>>,
    compId: number
  ): CompanyAssignment[] => {
    return !!compId && Object.values(caData).length && !!caData[compId]
      ? Object.values(caData[compId]).map((ca: GenericApi<CompanyAssignment>) => ca.data)
      : [];
  }
);
