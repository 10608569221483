import { ContactsActions, ContactsActionTypes } from './contacts.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { Contact } from 'src/app/models/contacts/contact';

export interface ContactsState {
  pending: boolean;
  error: HttpErrorResponse;
  data: { [id: number]: Contact };
  contact: Contact;
}

export const initialState: ContactsState = {
  pending: false,
  error: undefined,
  data: {},
  contact: undefined
};

export function contactsReducer(
  state = initialState,
  action: ContactsActions
): ContactsState {
  switch (action.type) {
    case ContactsActionTypes.LoadContacts:
      return {
        ...state,
        pending: true
      };

    case ContactsActionTypes.LoadContactsSuccess:
      return {
        ...state,
        error: undefined,
        pending: false,
        data: action.payload
      };

    case ContactsActionTypes.LoadContactsFailure:
      return {
        ...state,
        pending: false,
        data: {},
        error: action.payload
      };

    case ContactsActionTypes.LoadContact:
      return {
        ...state,
        pending: true
      };

    case ContactsActionTypes.LoadContactSuccess:
      return {
        ...state,
        error: undefined,
        pending: false,
        contact: action.payload
      };

    case ContactsActionTypes.LoadContactFailure:
      return {
        ...state,
        pending: false,
        contact: undefined,
        error: action.payload
      };

    case ContactsActionTypes.SaveContact:
      return {
        ...state,
        pending: true
      };

    case ContactsActionTypes.SaveContactSuccess:
      return {
        ...state,
        pending: false
      };

    case ContactsActionTypes.SaveContactFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case ContactsActionTypes.ClearCurrentContact:
      return {
        ...state,
        contact: undefined
      };

    default:
      return state;
  }
}
