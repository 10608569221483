import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  Input
} from '@angular/core';
import { Observable, Subscription, EMPTY, Subject, combineLatest } from 'rxjs';
import { CompanySelectItem } from 'src/app/models/companies/company-select';
import { HttpErrorResponse } from '@angular/common/http';
import { IdNameItem } from 'src/app/models/id-name-item';
import { Opportunity } from 'src/app/models/Opportunity/opportunity';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { GenericMap } from 'src/app/models/generic-map';
import { LabelGroup } from 'src/app/models/label-group';
import { Label } from 'src/app/models/label';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatSelectChange } from '@angular/material/select';
import {
  DeleteOpportunity,
  SaveOpportunity,
  LoadOpportunity
} from 'src/app/store/opportunities/opportunities.actions';
import { GlobalFilter } from 'src/app/models/global-filter';
import {
  SearchCompanies,
  ClearSearchCompanies,
  SelectSearchedCompany,
  LoadCompanyContacts
} from 'src/app/store/companies/companies.actions';
import { removeNullProps } from 'src/app/utils/removeNullProps';
import { selectIdNameCurrentUser } from 'src/app/store/users/selectors/id-name-current-user.selector';
import { LoadOpportunityLabels } from 'src/app/store/system-settings/opportunity-labels/opportunity-labels/opportunity-labels.actions';
import { LoadUsers } from 'src/app/store/users/users.actions';
import {
  selectOpportunityId,
  selectCurrentOpportunity
} from 'src/app/store/opportunities/selectors/current-opportunity.selector';
// eslint-disable-next-line max-len
import { selectAllOpportunityLabels } from 'src/app/store/system-settings/opportunity-labels/selectors/all-opportunityLabels-as-array.selector';
import { selectMultiSelectUsers } from 'src/app/store/users/selectors/multi-select-users.selector';
import { map, take } from 'rxjs/operators';
import { convertToMap } from 'src/app/utils/convertToMap';
import { CloseDrawer } from 'src/app/store/layout/layout.actions';
import { Company } from 'src/app/models/companies/company';
import { selectCurrentCompany } from 'src/app/store/companies/selectors/current-company.selector';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDeleteComponent } from 'src/app/view/shared/confirm-delete/confirm-delete.component';

@Component({
  selector: 'tn-opportunity-form',
  templateUrl: './opportunity-form.component.html',
  styleUrls: ['./opportunity-form.component.scss']
})
export class OpportunityFormComponent implements OnInit, OnDestroy {
  @Input() data: { company: Company };
  $companies: Observable<CompanySelectItem[]>;
  $companiesSearchPending: Observable<boolean>;
  $companiesSearchError: Observable<HttpErrorResponse>;

  $allUsers: Observable<IdNameItem[]>;

  $opportunity: Observable<Opportunity>;
  $opportunitySub: Subscription;
  opportunity: Opportunity;
  currentOpportunity: Opportunity;
  opportunityForm = new FormGroup({
    id: new FormControl(null),
    name: new FormControl(null, Validators.required),
    assignedTo: new FormControl(null),
    assignedToItems: new FormControl([]),
    ownedBy: new FormControl(null, Validators.required),
    companyId: new FormControl(null, Validators.required),
    estimatedCloseDate: new FormControl(null),
    total: new FormControl(null),
    notes: new FormControl(null),
    items: new FormControl(null),
    probability: new FormControl(null, [Validators.max(100), Validators.min(1)])
  });
  total: number;
  weightedTotal: number;
  $paramId: Observable<number>;
  $pending: Observable<boolean> = EMPTY;
  opportunityFetched = false;

  labelsForm = new FormGroup({});
  $allOpportunityLabelGroups: Observable<GenericMap<LabelGroup>> = EMPTY;
  allLabelGroups: GenericMap<LabelGroup> = {};
  allLabelGroupsArray: LabelGroup[] = [];
  hasLabelGroups: boolean;
  opportunityLabelsMap: GenericMap<Label> = {};
  allLabelsMap: GenericMap<Label> = {};

  editingName = false;
  hasRequiredLabel: boolean;
  hasRequiredField: boolean;
  $usersEventsSubject: Subject<void> = new Subject<void>();

  $currentUser: Subscription;
  currentUser: IdNameItem;

  $company: Observable<Company>;
  $companySub: Subscription;
  company: Company;

  @ViewChild('addLinkInput', { static: true }) addLinkInput: ElementRef<HTMLInputElement>;

  constructor(private store: Store<RootState>, private dialog: MatDialog) {}

  ngOnInit() {
    this.$currentUser = this.store.select(selectIdNameCurrentUser).subscribe((user) => {
      this.currentUser = user;
    });
    this.store.dispatch(new LoadOpportunityLabels());
    this.store.dispatch(new LoadUsers());
    this.$pending = this.store.select('opportunities', 'pending');
    if (!this.opportunityForm.value.ownedBy) {
      this.opportunityForm.patchValue({
        ownedBy: this.currentUser.id
      });
    }
    this.$paramId = this.store.select(selectOpportunityId);
    this.$allOpportunityLabelGroups = this.store.select(selectAllOpportunityLabels);
    this.$opportunity = this.store.select(selectCurrentOpportunity);
    this.$allUsers = this.store.select(selectMultiSelectUsers).pipe(
      map((users) => {
        if (!!users.length) {
          return users;
        } else {
          return [];
        }
      })
    );
    this.$company = this.store.select(selectCurrentCompany);
    this.$companySub = this.$company.subscribe((company: Company) => {
      if (!!company) {
        this.company = company;
        this.opportunityForm.controls.companyId.setValue(company.id);
        this.store.dispatch(new SelectSearchedCompany(company.id));
        this.store.dispatch(new LoadCompanyContacts(this.company.id));
      } else {
        this.store.dispatch(new ClearSearchCompanies());
      }
    });

    this.$opportunitySub = combineLatest([
      this.$opportunity,
      this.$allOpportunityLabelGroups,
      this.$paramId
    ])
      .pipe(map((res) => ({ opp: res[0], groups: res[1], paramId: res[2] })))
      .subscribe(({ opp, groups, paramId }) => {
        if (paramId && !opp && !this.opportunityFetched) {
          this.store.dispatch(new LoadOpportunity(paramId));
          this.opportunityFetched = true;
        }
        this.allLabelGroups = groups;
        this.allLabelGroupsArray = Object.values(groups)
          .sort((a, b) => {
            const textA = a.name.toLowerCase();
            const textB = b.name.toLowerCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          })
          .map((labelGroup: LabelGroup) => {
            return {
              ...labelGroup,
              labels: labelGroup.labels.sort((a, b) => {
                const textA = a.name.toLowerCase();
                const textB = b.name.toLowerCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              })
            };
          });
        this.hasLabelGroups = this.allLabelGroupsArray.length > 0;
        Object.values(groups).forEach((group: LabelGroup) => {
          this.allLabelsMap = {
            ...this.allLabelsMap,
            ...convertToMap(group.labels, 'id')
          };
          if (!this.hasRequiredLabel && group.required) {
            this.hasRequiredLabel = true;
          }
          this.labelsForm.addControl(
            group.id.toString(),
            new FormControl(null, group.required ? Validators.required : null)
          );
        });
        if (!!opp) {
          this.opportunity = opp;
          let itemTotal = this.opportunity.items.map(
            (item) => item.amount * item.quantity
          );
          if (!!opp.items.length) {
            this.total = itemTotal.reduce((prev, curr) => {
              return prev + curr;
            });
          }
          this.weightedTotal = (this.opportunity.probability / 100) * this.total;
          this.opportunityForm.patchValue({
            name: opp.name,
            id: opp.id,
            ownedBy: opp.ownedBy,
            companyId: opp.companyId,
            estimatedCloseDate: new Date(opp.estimatedCloseDate),
            notes: opp.notes,
            items: opp.items,
            assignedTo: opp.assignedTo,
            assignedToItems: opp.assignedToItems
          });
          if (!!opp.id && !!opp.labels) {
            opp.labels.forEach((label: Label) => {
              if (this.allLabelGroups[label.groupId]) {
                if (this.allLabelGroups[label.groupId].allowMultiple) {
                  this.labelsForm.controls[label.groupId].setValue([
                    ...(this.labelsForm.controls[label.groupId].value || []),
                    label.id
                  ]);
                } else {
                  this.labelsForm.controls[label.groupId].setValue(label.id);
                }
              }
            });
          }
          this.opportunityForm.markAsPristine();
        }
        if (!!this.data.company) {
          if (!this.opportunity) {
            this.opportunity = {
              ownedBy: this.currentUser.id,
              company: this.data.company,
              items: [],
              name: null,
              notes: null,
              customFieldValues: []
            };
          }
          if (!!this.opportunity) {
            this.opportunity.companyItem = {
              id: this.data.company.id,
              name: this.data.company.name
            };
          }
        }
      });
    this.$companies = this.store.select('companies', 'search', 'data');
    this.$companiesSearchPending = this.store.select('companies', 'search', 'pending');
    this.$companiesSearchError = this.store.select('companies', 'search', 'error');
    if (!!this.data.company) {
      this.company = this.data.company;
      this.opportunityForm.patchValue({
        companyId: this.company.id
      });
    }
    this.opportunityForm.markAllAsTouched();
  }

  ngOnDestroy() {
    this.$opportunitySub.unsubscribe();
    this.opportunityForm.reset();
    this.$currentUser.unsubscribe();
  }

  labelChange(event: MatSelectChange) {
    if (!!event.value && typeof event.value !== 'number' && event.value.length) {
      if (
        event.value.includes(null) &&
        this.labelsForm.controls[event.source.ngControl.name]
      ) {
        this.labelsForm.controls[event.source.ngControl.name].setValue([]);
      }
    }
    this.opportunityForm.markAsDirty();
  }

  onDelete(id: number) {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {});

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((willDelete: boolean) => {
        if (willDelete) {
          this.store.dispatch(new DeleteOpportunity({ id, redirect: false }));
        }
      });
  }

  onCompanySelect(event: MatAutocompleteSelectedEvent) {
    this.opportunityForm.markAsDirty();
    this.opportunityForm.controls.companyId.setValue(event.option.value.id);
  }

  onCompanySearch(searchVal: string) {
    this.opportunityForm.controls.companyId.setValue(undefined);

    if (!!searchVal.length) {
      const reqFilter: GlobalFilter = {
        filters: [
          {
            operand1: 'searchField',
            operator: 'CONTAINS_ALL_OF',
            operand2: [searchVal]
          }
        ]
      };
      this.store.dispatch(new SearchCompanies(reqFilter));
    } else {
      this.store.dispatch(new ClearSearchCompanies());
    }
  }

  get assignedTo() {
    return this.opportunityForm.get('assignedTo') as FormArray;
  }

  onUserSelected(items: IdNameItem[]) {
    this.opportunityForm.markAsDirty();
    this.opportunityForm.controls.assignedToItems.setValue(items);
  }

  onOwnerSelected(itemSelected: IdNameItem) {
    this.opportunityForm.markAsDirty();
    this.opportunityForm.controls.ownedBy.setValue(itemSelected.id);
  }

  onSubmit() {
    this.opportunityForm.value.estimatedCloseDate = new Date(
      this.opportunityForm.value.estimatedCloseDate + ' '
    ).getTime();
    const opportunity: Opportunity = {
      ...this.opportunity,
      ...this.opportunityForm.value,
      labels: Object.values(this.labelsForm.value).length
        ? Object.values(this.labelsForm.value)
            .filter((label) => !!label)
            .flat()
            .map((labelId: number) => this.allLabelsMap[labelId])
        : [],
      assignedTo: this.opportunityForm.value.assignedToItems.map(
        (item: IdNameItem) => item.id
      )
    };
    removeNullProps(opportunity);
    this.store.dispatch(new SaveOpportunity(opportunity));
  }

  onEdit() {
    this.opportunityForm.patchValue({
      id: this.opportunity.id,
      estimatedCloseDate: this.opportunityForm.value.estimatedCloseDate
    });
    this.opportunityForm.value.estimatedCloseDate = new Date(
      this.opportunityForm.value.estimatedCloseDate + ' '
    ).getTime();

    const opportunity: Opportunity = {
      ...this.opportunity,
      ...this.opportunityForm.value,
      labels: Object.values(this.labelsForm.value).length
        ? Object.values(this.labelsForm.value)
            .filter((label) => !!label)
            .flat()
            .map((labelId: number) => this.allLabelsMap[labelId])
        : [],
      assignedTo: this.opportunityForm.controls.assignedToItems.value.map(
        (item: IdNameItem) => item.id
      )
    };
    removeNullProps(opportunity);
    this.store.dispatch(new SaveOpportunity(opportunity));
  }

  cancel() {
    this.store.dispatch(new CloseDrawer());
  }
}
