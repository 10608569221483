import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  Input
} from '@angular/core';
import { Observable, EMPTY, Subscription, combineLatest } from 'rxjs';
import { Project } from 'src/app/models/projects/project';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IdNameItem } from 'src/app/models/id-name-item';
import { GenericMap } from 'src/app/models/generic-map';
import { LabelGroup } from 'src/app/models/label-group';
import { Label } from 'src/app/models/label';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import { convertToMap } from 'src/app/utils/convertToMap';
import {
  LoadProject,
  RedirectToProjects,
  DeleteProject,
  SaveProject,
  SaveCompanyLinkedProject
} from 'src/app/store/projects/projects.actions';
import { LoadProjectLabels } from 'src/app/store/system-settings/project-labels/project-labels.actions';
import { LoadUsers } from 'src/app/store/users/users.actions';
import {
  selectProjectParamId,
  selectCurrentProject
} from 'src/app/store/projects/selectors/current-project.selector';
import { selectAllProjectLabels } from 'src/app/store/system-settings/project-labels/selectors/all-projectLabels-as-array.selector';
import { selectMultiSelectUsers } from 'src/app/store/users/selectors/multi-select-users.selector';
import { map } from 'rxjs/operators';
import { ProjectEstimate } from 'src/app/models/projects/project-estimate';
import { MatSelectChange } from '@angular/material/select';
import { CloseDrawer } from 'src/app/store/layout/layout.actions';
import { removeNullProps } from 'src/app/utils/removeNullProps';
import { Address } from 'src/app/models/address';
import { Company } from 'src/app/models/companies/company';

@Component({
  selector: 'tn-project-form',
  templateUrl: './project-form.component.html',
  styleUrls: ['./project-form.component.scss']
})
export class ProjectFormComponent implements OnInit, OnDestroy {
  @Input() data: { company: Company };
  $project: Observable<Project> = EMPTY;
  $projectSub: Subscription;
  project: Partial<Project> = {
    name: null,
    address: null,
    bidDate: null,
    bidDateEstimate: null,
    closeDate: null,
    closeDateEstimate: null,
    assignedToItems: [],
    projectOwnerItem: null,
    description: '',
    id: null,
    created: null,
    createdBy: null,
    links: []
  };
  labelsForm = new FormGroup({});
  projectForm = new FormGroup({
    name: new FormControl('', Validators.required),
    address: new FormControl(null, Validators.required),
    bidDate: new FormControl(),
    bidDateEstimate: new FormControl(),
    closeDate: new FormControl(),
    closeDateEstimate: new FormControl(),
    assignedToItems: new FormControl([]),
    projectOwnerItem: new FormControl(),
    description: new FormControl(''),
    links: new FormControl([])
  });
  $allUsers: Observable<IdNameItem[]> = EMPTY;
  $pending: Observable<boolean> = EMPTY;
  editingName = false;
  $paramId: Observable<number>;
  $allProjectLabelGroups: Observable<GenericMap<LabelGroup>> = EMPTY;
  hasRequiredLabel: boolean;
  hasRequiredField: boolean;
  allLabelGroups: GenericMap<LabelGroup> = {};
  allLabelGroupsArray: LabelGroup[] = [];
  hasLabelGroups: boolean;
  projectLabelsMap: GenericMap<Label> = {};
  allLabelsMap: GenericMap<Label> = {};
  projectsFetched = false;
  projectActual: number;
  projectEstimate: number;

  @ViewChild('addLinkInput', { static: true }) addLinkInput: ElementRef<HTMLInputElement>;

  constructor(private store: Store<RootState>) {}

  ngOnInit() {
    this.store.dispatch(new LoadProjectLabels());
    // this.store.dispatch(new LoadUsers());
    this.$pending = this.store.select('projects', 'pending');
    this.$paramId = this.store.select(selectProjectParamId);
    this.$allProjectLabelGroups = this.store.select(selectAllProjectLabels);
    this.$project = this.store.select(selectCurrentProject);
    this.$allUsers = this.store.select(selectMultiSelectUsers);
    this.addressValidation(false);
    this.$projectSub = combineLatest([
      this.$project,
      this.$allProjectLabelGroups,
      this.$paramId
    ])
      .pipe(map((res) => ({ proj: res[0], groups: res[1], paramId: res[2] })))
      .subscribe(({ proj, groups, paramId }) => {
        if (paramId && !proj && !this.projectsFetched) {
          this.store.dispatch(new LoadProject(paramId));
          this.projectsFetched = true;
        }
        this.allLabelGroups = groups;
        this.allLabelGroupsArray = Object.values(groups)
          .sort((a, b) => {
            const textA = a.name.toLowerCase();
            const textB = b.name.toLowerCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          })
          .map((labelGroup: LabelGroup) => {
            return {
              ...labelGroup,
              labels: labelGroup.labels.sort((a, b) => {
                const textA = a.name.toLowerCase();
                const textB = b.name.toLowerCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              })
            };
          });
        this.hasLabelGroups = this.allLabelGroupsArray.length > 0;
        Object.values(groups).forEach((group: LabelGroup) => {
          this.allLabelsMap = {
            ...this.allLabelsMap,
            ...convertToMap(group.labels, 'id')
          };
          if (!this.hasRequiredLabel && group.required) {
            this.hasRequiredLabel = true;
          }
          this.labelsForm.addControl(
            group.id.toString(),
            new FormControl(null, group.required ? Validators.required : null)
          );
        });
        if (!!proj) {
          this.project = proj;
          this.projectEstimate = !!proj.linkedManufacturers
            ? proj.linkedManufacturers.reduce((acc, obj: ProjectEstimate) => {
                return acc + obj.estimate;
              }, 0)
            : 0;
          this.projectActual = !!proj.bids
            ? proj.bids.reduce((acc, obj) => {
                return acc + (obj.bidAwarded.toString() === '2' ? obj.amount : 0);
              }, 0)
            : 0;
          this.projectForm.patchValue({
            name: proj.name,
            address: proj.address,
            bidDate: proj.bidDate,
            bidDateEstimate: proj.bidDateEstimate,
            closeDate: proj.closeDate,
            closeDateEstimate: proj.closeDateEstimate,
            assignedToItems: proj.assignedToItems,
            projectOwnerItem: proj.projectOwnerItem,
            description: proj.description,
            links: proj.links
          });
          if (proj.address) {
            this.addressValidation(true);
          }
          if (!!proj.id && !!proj.labels) {
            proj.labels.forEach((label: Label) => {
              if (this.allLabelGroups[label.groupId]) {
                if (this.allLabelGroups[label.groupId].allowMultiple) {
                  this.labelsForm.controls[label.groupId].setValue([
                    ...(this.labelsForm.controls[label.groupId].value || []),
                    label.id
                  ]);
                } else {
                  this.labelsForm.controls[label.groupId].setValue(label.id);
                }
              }
            });
          }
          this.projectForm.markAsPristine();
          this.labelsForm.markAsPristine();
        }
      });
    this.projectForm.markAllAsTouched();
  }

  labelChange(event: MatSelectChange) {
    if (!!event.value && typeof event.value !== 'number' && event.value.length) {
      if (
        event.value.includes(null) &&
        this.labelsForm.controls[event.source.ngControl.name]
      ) {
        this.labelsForm.controls[event.source.ngControl.name].setValue([]);
      }
    }
  }

  ngOnDestroy() {
    this.$projectSub.unsubscribe();
    this.projectForm.reset();
  }
  onCancel() {
    this.store.dispatch(new CloseDrawer());
  }
  onDelete() {
    this.store.dispatch(new DeleteProject({ id: this.project.id, redirect: false }));
  }

  onSubmit() {
    const project: Project = {
      ...this.project,
      ...this.projectForm.value,
      labels: Object.values(this.labelsForm.value).length
        ? Object.values(this.labelsForm.value)
            .filter((label) => !!label)
            .flat()
            .map((labelId: number) => this.allLabelsMap[labelId])
        : [],
      assignedTo: this.projectForm.controls.assignedToItems.value.map(
        (item: IdNameItem) => item.id
      ),
      projectOwner: this.projectForm.controls.projectOwnerItem.value.id
    } as any; // hack until we can fix the whole app
    removeNullProps(project);
    if (!!this.data.company) {
      this.store.dispatch(
        new SaveCompanyLinkedProject({
          project,
          companyID: this.data.company.id,
          companyName: this.data.company.name
        })
      );
    } else {
      this.store.dispatch(new SaveProject(project));
    }
  }
  onAddressChange(address: Address) {
    this.projectForm.controls.address.setValue(address);
    this.projectForm.markAsDirty();
    this.project.address = address;
  }

  userValidation(isValid: boolean) {
    if (!isValid) {
      this.projectForm.setErrors({ userError: 'User must be selected' });
    }
  }
  addressValidation(isValid: boolean) {
    if (!isValid) {
      this.projectForm.setErrors({ addressError: 'Address must have a city and state' });
    }
  }
  onProjectOwnerSelected(itemSelected: IdNameItem) {
    this.projectForm.markAsDirty();
    this.projectForm.controls.projectOwnerItem.setValue(itemSelected);
  }

  setAssignedToItems(items: IdNameItem[]) {
    this.projectForm.markAsDirty();
    this.projectForm.controls.assignedToItems.setValue(items);
  }
  addLink(link: string) {
    if (!!link && this.project.links.indexOf(link) === -1) {
      this.project.links.push(link);
      this.projectForm.markAsDirty();
      this.projectForm.controls.links.setValue(this.project.links);
      this.addLinkInput.nativeElement.value = '';
    }
  }
  onRemoveLink(linkToRemove: string) {
    this.project.links = this.project.links.filter((link) => link !== linkToRemove);
    this.projectForm.controls.links.setValue(this.project.links);
  }
}
