import { GlobalSearchActions, GlobalSearchActionTypes } from './global-search.actions';
import { GenericApi } from 'src/app/models/generic-api';
import { GenericMap } from 'src/app/models/generic-map';
import { CallNoteIdNameItem } from 'src/app/models/call-notes/call-note-id-name-item';
import { ContactIdNameItem } from 'src/app/models/contacts/contact-id-name-item';
import { CompanyIdNameItem } from 'src/app/models/companies/company-id-name-item';

export interface GlobalSearchStateData {
  companies: {
    totalMatches: number;
    data: GenericMap<CompanyIdNameItem>;
  };
  contacts: {
    totalMatches: number;
    data: GenericMap<ContactIdNameItem>;
  };
  callNotes: {
    totalMatches: number;
    data: GenericMap<CallNoteIdNameItem>;
  };
}

export interface GlobalSearchState extends GenericApi<any> {
  searchTerm: string;
  data: GlobalSearchStateData;
}

export const initialState: GlobalSearchState = {
  searchTerm: null,
  pending: false,
  error: undefined,
  data: {
    companies: {
      totalMatches: undefined,
      data: {}
    },
    contacts: {
      totalMatches: undefined,
      data: {}
    },
    callNotes: {
      totalMatches: undefined,
      data: {}
    }
  }
};

export function globalSearchReducer(
  state = initialState,
  action: GlobalSearchActions
): GlobalSearchState {
  switch (action.type) {
    case GlobalSearchActionTypes.LoadGlobalSearch:
      return {
        ...state,
        searchTerm: action.payload,
        pending: true
      };

    case GlobalSearchActionTypes.LoadGlobalSearchSuccess:
      return {
        ...state,
        pending: false,
        data: action.payload
      };

    case GlobalSearchActionTypes.LoadGlobalSearchFailure:
      return { ...state, pending: false, error: action.payload };

    case GlobalSearchActionTypes.ClearSearchTerm:
      return { ...state, searchTerm: null };

    default:
      return state;
  }
}
