<div class="call-note-follow-up" fxLayout="column">
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="20px"
    [style.marginBottom]="'10px'"
  >
    <h3 class="follow-up-title">Follow-up</h3>

    <div class="follow-up-date">
      <span>{{ followUp.dueDateStart | date : 'MMM d' }}</span>
      <span> at </span>
      <span>{{ followUp.dueDateStart | date : 'h:mm aa' }}</span>
      <span> - </span>
      <span *ngIf="isSameDay; else notSameDay"
        ><span>{{ followUp.dueDateEnd | date : 'h:mm aa' }}</span></span
      >
      <ng-template #notSameDay>
        <span>{{ followUp.dueDateEnd | date : 'MMM d' }}</span>
        <span> at </span>
        <span>{{ followUp.dueDateEnd | date : 'h:mm aa' }}</span>
      </ng-template>
    </div>

    <mat-chip-list *ngIf="!!followUp.assignedTo" class="follow-up-assigned-to">
      <mat-chip>{{
        !!followUp.assignedTo?.name
          ? followUp.assignedTo?.name
          : followUp.assignedTo?.firstName + ' ' + followUp.assignedTo?.lastName
      }}</mat-chip>
    </mat-chip-list>
  </div>

  <div
    class="task-container"
    fxLayout="row"
    fxLayoutAlign="start start"
    fxLayoutGap="16px"
  >
    <mat-checkbox
      [checked]="followUp?.complete"
      (change)="emitChecked.emit($event)"
    ></mat-checkbox>
    <div class="follow-up-task" [innerHTML]="followUp.task"></div>
  </div>
</div>
