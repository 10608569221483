export const ExpenseTableColumnsData = [
  'Reimbursement',
  'Sales Rep',
  'Company',
  'Contacts',
  'Type',
  'Purpose/details',
  'Location',
  'Created Date',
  'Expense Date',
  'Amount'
];
