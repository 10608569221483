import { Action, createReducer, on } from '@ngrx/store';
import { GenericApi } from 'src/app/models/generic-api';
import { GenericMap } from 'src/app/models/generic-map';
import {
  LoadProjectCustomFields,
  LoadProjectCustomFieldsSuccess,
  LoadProjectCustomFieldsFailure,
  SetCurrentProjectCustomFieldId
} from './project-custom-fields.actions';
import { ProjectCustomField } from 'src/app/models/projects/project-custom-field';

export const projectCustomFieldsFeatureKey = 'projectCustomFields';

export interface ProjectCustomFieldsState
  extends GenericApi<GenericMap<ProjectCustomField>> {
  selectedPcfId: number;
}

export const initialState: ProjectCustomFieldsState = {
  pending: false,
  error: undefined,
  selectedPcfId: null,
  data: {}
};

const reducer = createReducer(
  initialState,

  on(LoadProjectCustomFields, state => {
    return {
      ...state,
      pending: true,
      error: undefined
    };
  }),
  on(LoadProjectCustomFieldsSuccess, (state, action) => {
    return {
      ...state,
      pending: true,
      data: action.data
    };
  }),
  on(LoadProjectCustomFieldsFailure, (state, action) => {
    return {
      ...state,
      pending: true,
      error: action.error
    };
  }),
  on(SetCurrentProjectCustomFieldId, (state, action) => {
    return {
      ...state,
      selectedPcfId: action.id
    };
  })
);

export function projectCustomFieldsReducer(
  state: ProjectCustomFieldsState | undefined,
  action: Action
) {
  return reducer(state, action);
}
