import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { GenericMap } from 'src/app/models/generic-map';
import { CallNoteType } from 'src/app/models/call-notes/call-note-type';

export enum CallNoteTypesActionTypes {
  LoadCallNoteTypes = '[CallNoteTypes] Load CallNoteTypes',
  LoadCallNoteTypesSuccess = '[CallNoteTypes] Load CallNoteTypes Success',
  LoadCallNoteTypesFailure = '[CallNoteTypes] Load CallNoteTypes Failure'
}

export class LoadCallNoteTypes implements Action {
  readonly type = CallNoteTypesActionTypes.LoadCallNoteTypes;
}

export class LoadCallNoteTypesSuccess implements Action {
  readonly type = CallNoteTypesActionTypes.LoadCallNoteTypesSuccess;
  constructor(public payload: GenericMap<CallNoteType>) {}
}

export class LoadCallNoteTypesFailure implements Action {
  readonly type = CallNoteTypesActionTypes.LoadCallNoteTypesFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export type CallNoteTypesActions =
  | LoadCallNoteTypes
  | LoadCallNoteTypesSuccess
  | LoadCallNoteTypesFailure;
