import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import { FileMetaData } from 'src/app/models/file-meta-data';
import { Observable, Subscription } from 'rxjs';
import {
  ClearExpenseFile,
  UploadFileOnNewExpense
} from 'src/app/store/expenses/expenses.actions';
import { pendingFileUploadsAsArray } from 'src/app/store/expenses/selectors/pending-file-uploads.selector';

@Component({
  selector: 'tn-pending-expense-files',
  templateUrl: './pending-expense-files.component.html',
  styleUrls: ['./pending-expense-files.component.scss']
})
export class PendingExpenseFilesComponent implements OnInit {
  pendingFiles: FileMetaData[];
  $pendingFiles: Observable<FileMetaData[]>;
  $pendingFilesSub: Subscription;
  @ViewChild('fileUpload', { static: true }) fileUpload;
  constructor(private store: Store<RootState>) {}

  ngOnInit() {
    this.$pendingFiles = this.store.select(pendingFileUploadsAsArray);
  }

  onFilesAdded() {
    const files: { [key: string]: File } = this.fileUpload.nativeElement.files;
    for (let key in files) {
      if (!isNaN(parseInt(key, 10))) {
        this.store.dispatch(new UploadFileOnNewExpense(files[key]));
      }
    }
  }

  addFiles() {
    this.fileUpload.nativeElement.click();
  }

  onDelete(fileId: number) {
    this.store.dispatch(new ClearExpenseFile(fileId));
  }
}
