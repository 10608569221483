import { Component, OnInit, Input } from '@angular/core';
import { RootState } from 'src/app/store/store.reducer';
import { Store } from '@ngrx/store';
import { Observable, EMPTY, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { SelectBidTableColumns } from 'src/app/store/projects/projects.actions';
import { ColumnHeader } from 'src/app/models/column-header';
import {
  selectAllBidColsWithLabels,
  selectSelBidColumns
} from 'src/app/store/projects/selectors/selected-projects-table-columns.selector';

@Component({
  selector: 'tn-bids-column-selector',
  templateUrl: './bids-column-selector.component.html',
  styleUrls: ['./bids-column-selector.component.scss']
})
export class BidsColumnSelectorComponent implements OnInit {
  @Input() data: any;
  $allColumns: Observable<ColumnHeader[]> = EMPTY;
  allColumns: ColumnHeader[] = [];
  $selectedColumns: Observable<ColumnHeader[]> = EMPTY;
  selectedColumnsNames: string[] = [];
  $columns: Observable<{ name: string; selected: boolean }[]> = EMPTY;
  displayedColumns = ['name', 'selected'];

  constructor(private store: Store<RootState>) {}

  ngOnInit() {
    this.$allColumns = this.store.select(selectAllBidColsWithLabels);
    this.$selectedColumns = this.store.select(selectSelBidColumns);
    this.$columns = combineLatest([this.$allColumns, this.$selectedColumns]).pipe(
      map(resultArray => {
        return { allColumns: resultArray[0], selectedColumns: resultArray[1] };
      }),
      map(({ allColumns, selectedColumns }) => {
        this.selectedColumnsNames = selectedColumns.map(col => col.name);
        this.allColumns = allColumns;
        return allColumns.map((col: ColumnHeader) => {
          return {
            ...col,
            name: col.name,
            selected:
              selectedColumns
                .map((selColumn: ColumnHeader) => selColumn.name)
                .indexOf(col.name) >= 0
          };
        });
      })
    );
  }

  onCheckboxChanged(selColumn: ColumnHeader, event: MatCheckboxChange) {
    if (event.checked) {
      this.selectedColumnsNames.push(selColumn.name);
      const newColumnArr = this.allColumns.filter(
        col => this.selectedColumnsNames.indexOf(col.name) > -1
      );
      this.store.dispatch(new SelectBidTableColumns(newColumnArr));
    } else {
      const newColumnArr = this.allColumns.filter(
        col =>
          this.selectedColumnsNames
            .filter(colName => colName !== selColumn.name)
            .indexOf(col.name) > -1
      );
      this.store.dispatch(new SelectBidTableColumns(newColumnArr));
    }
  }
}
