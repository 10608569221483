import { RootState } from '../../store.reducer';
import { MemoizedSelector, createSelector } from '@ngrx/store';
import { Opportunity } from 'src/app/models/Opportunity/opportunity';
import { GenericMap } from 'src/app/models/generic-map';
import * as moment from 'moment';
import { Company } from 'src/app/models/companies/company';
import { User } from 'src/app/models/admin/users/user';
import { LabelGroup } from 'src/app/models/label-group';
import { selectOpportunityLabelsAsArray } from '../../system-settings/opportunity-labels/selectors/all-opportunityLabels-as-array.selector';
import { Item } from 'src/app/models/Opportunity/item';
import { formatDate } from '@angular/common';
import { Label } from 'src/app/models/label';
import {
  selectAllCompanies,
  selectCompanyParamId
} from '../../companies/selectors/current-company.selector';
import { selectedSearchCompanyId } from '../../companies/selectors/company-contacts.selector';

export const selectAllOpportunities = (state: RootState): GenericMap<Opportunity> =>
  state.opportunities.data;
export const selectAllElasticOpportunities = (
  state: RootState
): GenericMap<Opportunity> => state.opportunities.elasticData;
export const selectAllUsers = (state: RootState): GenericMap<User> => state.users.data;

export const selectActiveOpportunityItems: MemoizedSelector<RootState, Opportunity[]> =
  createSelector(
    selectAllElasticOpportunities,
    selectedSearchCompanyId,
    (oppItems: GenericMap<Opportunity>, companyId: number): Opportunity[] => {
      return Object.values(oppItems).filter((opportunity: Opportunity) => {
        return (
          !opportunity.labels?.some((label: Label) => label.isClosed === true) &&
          opportunity.companyId === companyId
        );
      });
    }
  );

function createBaseOpportunityTableRow(
  opportunity: Opportunity,
  users: GenericMap<User>
): Opportunity {
  return {
    id: opportunity.id,
    name: opportunity.name,
    ownedBy: opportunity.ownedBy,
    company: opportunity.company,
    customFieldValues: []
  };
}

export function addOpportunityLabels(
  opportunity: Opportunity,
  labelGroups: LabelGroup[],
  opportunityLabels: Label[]
): Opportunity {
  const opportunityLabelsMapByGroupId: GenericMap<Label[]> = opportunityLabels.reduce(
    (map: GenericMap<Label[]>, label: Label) => {
      map[label.groupId] = [...(map[label.groupId] ? map[label.groupId] : []), label];
      return map;
    },
    {}
  );
  return {
    ...opportunity,
    ...labelGroups.reduce((resultObj, labelGroup) => {
      resultObj[labelGroup.name] =
        !!opportunityLabelsMapByGroupId[labelGroup.id] &&
        !!opportunityLabelsMapByGroupId[labelGroup.id].length
          ? opportunityLabelsMapByGroupId[labelGroup.id].map((label: Label) => label.name)
          : '--';
      return resultObj;
    }, {})
  } as Opportunity;
}

export const selectOpportunitiesAsArray: MemoizedSelector<RootState, Opportunity[]> =
  createSelector(
    selectAllOpportunities,
    selectAllCompanies,
    selectAllUsers,
    selectOpportunityLabelsAsArray,
    (
      opportunityMap: GenericMap<Opportunity>,
      companiesMap: GenericMap<Company>,
      usersMap: GenericMap<User>,
      labels: GenericMap<LabelGroup>
    ): Opportunity[] => {
      if (
        Object.values(opportunityMap).length &&
        Object.values(companiesMap).length &&
        Object.values(usersMap).length
      ) {
        return Object.values(opportunityMap)
          .sort((a, b) => {
            return a.created < b.created ? -1 : a.created > b.created ? 1 : 0;
          })
          .map((opportunity: Opportunity) => {
            return {
              ...addOpportunityLabels(
                createBaseOpportunityTableRow(opportunity, usersMap),
                Object.values(labels),
                opportunity.labels
              ),
              lastUpdated: opportunity.lastUpdated,
              createdDate: new Date(opportunity.createdDate),
              dateCreated: !!opportunity.createdDate
                ? formatDate(new Date(opportunity.createdDate), 'mediumDate', 'en-US')
                : '--',
              companyName: opportunity.companyName,
              companyId: opportunity.companyId,
              createdBy: opportunity.createdBy,
              createdByName: !!usersMap[opportunity.createdBy]
                ? usersMap[opportunity.createdBy].firstName +
                  ' ' +
                  usersMap[opportunity.createdBy].lastName
                : '--',
              estimatedCloseDate: new Date(opportunity.estimatedCloseDate),
              closeDateEstimate: !!opportunity.estimatedCloseDate
                ? formatDate(
                    new Date(opportunity.estimatedCloseDate),
                    'mediumDate',
                    'en-US'
                  )
                : '--',
              assignedTo: opportunity.assignedTo,
              probability: opportunity.probability,
              assignedToNames: opportunity.assignedTo
                .map((atUserId: number) => {
                  return !!usersMap[atUserId]
                    ? usersMap[atUserId].firstName + ' ' + usersMap[atUserId].lastName
                    : '--';
                })
                .join(', '),
              ownedByName: !!usersMap[opportunity.ownedBy]
                ? usersMap[opportunity.ownedBy].firstName +
                  ' ' +
                  usersMap[opportunity.ownedBy].lastName
                : '--',
              notes: opportunity.notes,
              itemNames: !!opportunity.items.length
                ? opportunity.items
                    .map((item: Item) => (!!item.itemName ? item.itemName.name : '--'))
                    .join(', ')
                : '--',
              total: !!opportunity.items.length
                ? opportunity.items
                    .map((item) => item.amount * item.quantity)
                    .reduce((prev, curr) => {
                      return prev + curr;
                    })
                : 0,
              age: opportunity.createdDate,
              weightedTotal: !!opportunity.items.length
                ? (opportunity.probability / 100) *
                  opportunity.items
                    .map((item) =>
                      item.probability !== 0 ? item.amount * item.quantity : 0
                    )
                    .reduce((prev, curr) => {
                      return prev + curr;
                    })
                : 0
            };
          });
      } else {
        return [];
      }
    }
  );

export const selectOpportunitiesByCompanyAsArray: MemoizedSelector<
  RootState,
  Opportunity[]
> = createSelector(
  (state: RootState): GenericMap<Opportunity> => state.opportunities.elasticData,
  selectAllCompanies,
  selectAllUsers,
  selectOpportunityLabelsAsArray,
  selectCompanyParamId,
  (
    opportunityMap: GenericMap<Opportunity>,
    companiesMap: GenericMap<Company>,
    usersMap: GenericMap<User>,
    labels: GenericMap<LabelGroup>,
    companyId: number
  ): Opportunity[] => {
    if (
      Object.values(opportunityMap).length &&
      Object.values(companiesMap).length &&
      Object.values(usersMap).length
    ) {
      return Object.values(opportunityMap)
        .filter((opportunity) => opportunity.companyId === companyId)
        .map((opportunity: Opportunity) => {
          return {
            ...addOpportunityLabels(
              createBaseOpportunityTableRow(opportunity, usersMap),
              Object.values(labels),
              opportunity.labels
            ),
            lastUpdated: opportunity.lastUpdated,
            createdDate: new Date(opportunity.createdDate),
            dateCreated: !!opportunity.createdDate
              ? formatDate(new Date(opportunity.createdDate), 'mediumDate', 'en-US')
              : '--',
            items: opportunity.items,
            labels: opportunity.labels,
            companyName: opportunity.companyName,
            companyId: opportunity.companyId,
            createdBy: opportunity.createdBy,
            createdByName: !!usersMap[opportunity.createdBy]
              ? usersMap[opportunity.createdBy].firstName +
                ' ' +
                usersMap[opportunity.createdBy].lastName
              : '--',
            estimatedCloseDate: new Date(opportunity.estimatedCloseDate),
            closeDateEstimate: !!opportunity.estimatedCloseDate
              ? formatDate(
                  new Date(opportunity.estimatedCloseDate),
                  'mediumDate',
                  'en-US'
                )
              : '--',
            assignedTo: opportunity.assignedTo,
            probability: opportunity.probability,
            assignedToNames: !!opportunity.assignedTo
              ? opportunity.assignedTo
                  .map((atUserId: number) => {
                    return !!usersMap[atUserId]
                      ? usersMap[atUserId].firstName + ' ' + usersMap[atUserId].lastName
                      : '--';
                  })
                  .join(', ')
              : '--',
            ownedByName: !!usersMap[opportunity.ownedBy]
              ? usersMap[opportunity.ownedBy].firstName +
                ' ' +
                usersMap[opportunity.ownedBy].lastName
              : '--',
            notes: opportunity.notes,
            itemNames: !!opportunity.items.length
              ? opportunity.items.map((item: Item) => item.itemName.name).join(', ')
              : '--',
            total: !!opportunity.items.length
              ? opportunity.items
                  .map((item) =>
                    item.probability !== 0 ? item.amount * item.quantity : 0
                  )
                  .reduce((prev, curr) => {
                    return prev + curr;
                  })
              : 0,
            age: moment(opportunity.createdDate).fromNow()
          };
        });
    } else {
      return [];
    }
  }
);
