import { DashboardStackCount } from 'src/app/models/dashboard/dashboard-stack-count';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CallTypeCountsRequest } from 'src/app/models/dashboard/call-notes/call-type-counts-request';
import { FieldCountsRequest } from 'src/app/models/dashboard/call-notes/field-counts-request';
import {
  DashboardApiNotesResponse,
  DashboardApiResponse
} from 'src/app/models/dashboard/dashboard-api-response';
import { environment } from 'src/environments/environment';
import { ServicesModule } from '../services.module';
import { LabelGroupCountsRequest } from 'src/app/models/dashboard/call-notes/label-group-counts-request';
import { LabelGroupCountsResponse } from 'src/app/models/dashboard/call-notes/label-group-counts-response';
import { DashboardApiRequest } from 'src/app/models/dashboard/dashboard-api-request';
import { LabelGroupsCountsRequest } from 'src/app/models/dashboard/call-notes/label-groups-counts-request';
import { LabelGroupsCountsResponse } from 'src/app/models/dashboard/call-notes/label-groups-counts-response';
import { CallNote } from 'src/app/models/call-notes/call-note';
import { DashboardCount } from 'src/app/models/dashboard/dashboard-count';

@Injectable({
  providedIn: ServicesModule
})
export class CallNotesDashboardService {
  private jpiUrl = environment.jpiUrl;

  constructor(private http: HttpClient) {}

  getCallTypeCounts(
    reqBody: CallTypeCountsRequest
  ): Observable<DashboardApiResponse<DashboardCount>> {
    return this.http.post(
      `${this.jpiUrl}/dashboard/callnote/calltype/counts`,
      reqBody
    ) as Observable<DashboardApiResponse<DashboardCount>>;
  }

  getCallTypeUserCounts(
    reqBody: CallTypeCountsRequest
  ): Observable<DashboardApiResponse<DashboardStackCount>> {
    return this.http.post(
      `${this.jpiUrl}/dashboard/callnote/calltype/rep/counts`,
      reqBody
    ) as Observable<DashboardApiResponse<DashboardStackCount>>;
  }
  getCallTypeNotes(
    reqBody: CallTypeCountsRequest
  ): Observable<DashboardApiNotesResponse<CallNote>> {
    return this.http.post(
      `${this.jpiUrl}/dashboard/callnote/calltype/notes`,
      reqBody
    ) as Observable<DashboardApiNotesResponse<CallNote>>;
  }

  getFieldCounts(
    reqBody: FieldCountsRequest
  ): Observable<DashboardApiResponse<DashboardCount>> {
    return this.http.post(
      `${this.jpiUrl}/dashboard/callnote/field/counts`,
      reqBody
    ) as Observable<DashboardApiResponse<DashboardCount>>;
  }

  getCallFieldNotes(
    reqBody: CallTypeCountsRequest
  ): Observable<DashboardApiNotesResponse<CallNote>> {
    return this.http.post(
      `${this.jpiUrl}/dashboard/callnote/field/notes`,
      reqBody
    ) as Observable<DashboardApiNotesResponse<CallNote>>;
  }

  getUserCounts(
    reqBody: DashboardApiRequest
  ): Observable<DashboardApiResponse<DashboardCount>> {
    return this.http.post(
      `${this.jpiUrl}/dashboard/callnote/user/counts`,
      reqBody
    ) as Observable<DashboardApiResponse<DashboardCount>>;
  }

  getCallUserNotes(
    reqBody: CallTypeCountsRequest
  ): Observable<DashboardApiNotesResponse<CallNote>> {
    return this.http.post(
      `${this.jpiUrl}/dashboard/callnote/user/notes`,
      reqBody
    ) as Observable<DashboardApiNotesResponse<CallNote>>;
  }

  getLabelGroupCounts(
    reqBody: LabelGroupCountsRequest
  ): Observable<LabelGroupCountsResponse> {
    return this.http.post(
      `${this.jpiUrl}/dashboard/callnote/grouplabel/counts`,
      reqBody
    ) as Observable<LabelGroupCountsResponse>;
  }

  getLabelGroupsCounts(
    reqBody: LabelGroupsCountsRequest
  ): Observable<LabelGroupsCountsResponse> {
    return this.http.post(
      `${this.jpiUrl}/dashboard/callnote/groupsandlabels/counts`,
      reqBody
    ) as Observable<LabelGroupsCountsResponse>;
  }
}
