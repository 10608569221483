<mat-card class="private-note-card mat-elevation-z4">
  <!-- Private Note Header-->
  <mat-card-header fxLayout="row" fxLayoutAlign="space-between center">
    <div
      class="private-note-title-info"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="24px"
    >
      <h3 class="private-note-title">Private Note</h3>
      <div>
        {{ privateNote?.createdDate | date : 'MMM dd' }}
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="24px" fxLayoutAlign="end center">
      <div
        class="created-by-and-private-note-id"
        fxLayout="column"
        fxLayoutGap="2px"
        fxLayoutAlign="center start"
      >
        <div>
          <span class="info-label">Created by: </span>
          <span class="name">{{
            !!privateNote?.createdBy.name
              ? privateNote?.createdBy.name
              : privateNote?.createdBy.firstName + ' ' + privateNote?.createdBy.lastName
          }}</span>
        </div>
        <div>
          <span class="info-label">Note ID: </span>
          <span class="name">{{ privateNote.id }}</span>
        </div>
      </div>
      <div class="private-note-actions" fxLayout="row">
        <button
          mat-icon-button
          color="primary"
          (click)="editHandler()"
          matTooltip="edit"
          [disabled]="!owner"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          mat-icon-button
          color="warn"
          (click)="deleteHandler(privateNote.id)"
          matTooltip="delete"
          [disabled]="!owner"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-header>
  <!-- Private Note Narrative Text -->
  <mat-card-content>
    <mat-divider></mat-divider>
    <div class="private-note-narrative" fxLayout="column" fxLayoutGap="16px">
      <div class="private-note-narrative-body">
        {{ privateNote?.note }}
      </div>
    </div>
  </mat-card-content>
  <!-- Private Note Assigned Users -->
  <mat-card-content *ngIf="!!privateNote?.assignedTo?.length">
    <mat-divider></mat-divider>
    <mat-accordion class="assigned-users-panel">
      <mat-expansion-panel #assignedUsersPanel expanded="false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="assigned-users-panel-title">
              <span class="assigned-users-panel-title-text">Assigned Users: </span>
              <span class="assigned-users-panel-title-count">{{
                privateNote?.assignedTo?.length
              }}</span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <!-- mat expansion description use mat-chip for each user in privateNote.assignedTo[] array if assigedTo.length > 0 -->
        <mat-panel-description>
          <div class="assigned-users-panel-description">
            <mat-chip-list class="mat-chip-list">
              <mat-chip [removable]="false" *ngFor="let user of privateNote?.assignedTo">
                {{ user.firstName }} {{ user?.lastName }}
              </mat-chip>
            </mat-chip-list>
          </div>
        </mat-panel-description>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-card-content>
</mat-card>
