import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import {
  CreateCompanyLabels,
  UpdateCompanyLabels,
  DeleteCompanyLabels,
  ClearCompanyLabelId,
  LoadCompanyLabels
} from 'src/app/store/system-settings/company-labels/company-labels.actions';
import { CloseDrawer } from 'src/app/store/layout/layout.actions';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Label } from 'src/app/models/label';
import {
  LoadCompanyLabelsIndividual,
  DeleteCompanyLabelsIndividual,
  CreateCompanyLabelsIndividual,
  UpdateCompanyLabelsIndividual
} from 'src/app/store/system-settings/company-labels-individual/company-labels-individual.actions';
import { selectSelectedCompanyLabel } from 'src/app/store/system-settings/company-labels/selectors/selected-companyLabels.selector';
// eslint-disable-next-line max-len
import { selectCompanyLabelsIndividualAsArray } from 'src/app/store/system-settings/company-labels/selectors/all-companyLabelsIndividual-as-array.selector';
import { LabelGroup } from 'src/app/models/label-group';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDeleteComponent } from 'src/app/view/shared/confirm-delete/confirm-delete.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'tn-add-company-labels',
  templateUrl: './add-company-labels.component.html',
  styleUrls: ['./add-company-labels.component.css']
})
export class AddCompanyLabelsComponent implements OnInit, OnDestroy {
  currentCompanyLabelIndividual: Label;
  $currentCompanyLabelIndividualSub: Subscription;
  companyLabelsIndividual: Label[];
  $companyLabelsIndividualSub: Subscription;
  companyLabelForm: FormGroup = new FormGroup({
    name: new FormControl(null, Validators.required),
    required: new FormControl(null),
    allowMultiple: new FormControl(null),
    description: new FormControl(null),
    id: new FormControl(null)
  });
  companyLabelIndividualForm: FormGroup;
  companyLabels;
  allCompanyLabels;
  currentCompanyLabel: LabelGroup;
  $currentCompanyLabelSub: Subscription;
  @ViewChild(MatMenuTrigger, { static: true }) addLabelMenuTrigger: MatMenuTrigger;
  $addLabelFormEventsSubject: Subject<void> = new Subject<void>();
  $focusEventSubject: Subject<void> = new Subject<void>();

  constructor(private store: Store<RootState>, private dialog: MatDialog) {}

  ngOnInit() {
    this.$currentCompanyLabelSub = this.store
      .select(selectSelectedCompanyLabel)
      .subscribe((companyLabel) => {
        this.currentCompanyLabel = companyLabel;
        if (!!companyLabel && !!companyLabel.id) {
          this.companyLabelForm.patchValue({
            ...companyLabel
          });
          this.companyLabelForm.markAsPristine();
          this.store.dispatch(new LoadCompanyLabelsIndividual(companyLabel.id));
        }
      });
    this.$currentCompanyLabelIndividualSub = this.store
      .select(selectCompanyLabelsIndividualAsArray)
      .subscribe((companyLabelIndividual) => {
        this.companyLabelsIndividual = companyLabelIndividual;
      });
    this.companyLabelIndividualForm = new FormGroup({
      name: new FormControl(null),
      id: new FormControl(null),
      groupId: new FormControl(null),
      description: new FormControl(null)
    });
  }

  ngOnDestroy() {
    this.$currentCompanyLabelSub.unsubscribe();
    this.$currentCompanyLabelIndividualSub.unsubscribe();
  }

  save() {
    this.store.dispatch(new CreateCompanyLabels(this.companyLabelForm.value));
    this.store.dispatch(new LoadCompanyLabels());
  }

  cancelHandler() {
    this.$addLabelFormEventsSubject.next();
    this.companyLabelIndividualForm.reset();
    this.addLabelMenuTrigger.closeMenu();
  }

  saveIndividual() {
    this.$addLabelFormEventsSubject.next();
    this.store.dispatch(
      new CreateCompanyLabelsIndividual({
        label: this.companyLabelIndividualForm.value,
        groupId: this.currentCompanyLabel.id
      })
    );
    this.store.dispatch(new LoadCompanyLabels());
    this.companyLabelIndividualForm.reset();
  }

  editIndividual() {
    this.store.dispatch(
      new UpdateCompanyLabelsIndividual({
        label: this.companyLabelIndividualForm.value,
        groupId: this.currentCompanyLabel.id
      })
    );
    this.store.dispatch(new LoadCompanyLabels());
  }

  menuOpened() {
    this.companyLabelIndividualForm.reset();
    this.$focusEventSubject.next();
  }

  menuOpenedEdit(companyLabelIndividual) {
    this.companyLabelIndividualForm.reset();
    this.$focusEventSubject.next();
    this.companyLabelIndividualForm.patchValue({
      name: companyLabelIndividual.name,
      id: companyLabelIndividual.id,
      groupId: companyLabelIndividual.groupId,
      description: companyLabelIndividual.description
    });
  }

  update() {
    this.store.dispatch(
      new UpdateCompanyLabels({
        ...this.companyLabelForm.value,
        labels: this.companyLabelsIndividual
      })
    );
    this.store.dispatch(new CloseDrawer());
    this.store.dispatch(new ClearCompanyLabelId());
    this.store.dispatch(new LoadCompanyLabels());
  }

  cancel(): void {
    this.store.dispatch(new CloseDrawer());
    this.store.dispatch(new ClearCompanyLabelId());
  }

  delete(): void {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {});

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((willDelete: boolean) => {
        if (willDelete) {
          this.store.dispatch(new DeleteCompanyLabels(this.currentCompanyLabel.id));
          this.store.dispatch(new CloseDrawer());
          this.store.dispatch(new ClearCompanyLabelId());
          this.store.dispatch(new LoadCompanyLabels());
        }
      });
  }

  deleteLabel(companyLabelIndividual) {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {});

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((willDelete: boolean) => {
        if (willDelete) {
          this.store.dispatch(
            new DeleteCompanyLabelsIndividual({
              id: companyLabelIndividual.id,
              groupId: companyLabelIndividual.groupId
            })
          );
          this.store.dispatch(new LoadCompanyLabels());
        }
      });
  }
}
