import { Injectable } from '@angular/core';
import { ServicesModule } from '../../services.module';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ExpenseType } from '../../../models/activities/expense-type';

@Injectable({
  providedIn: ServicesModule
})
export class ExpenseTypesService {
  private jpiUrl = environment.jpiUrl;
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get(`${this.jpiUrl}/expensetypes/V2`);
  }

  getById(expenseTypeId: number) {
    return this.http.get(`${this.jpiUrl}/expensetypes/V2/${expenseTypeId}`);
  }

  create(expenseType: ExpenseType) {
    return this.http.post(`${this.jpiUrl}/expensetypes/V2`, expenseType);
  }

  update(expenseType: ExpenseType) {
    return this.http.put(`${this.jpiUrl}/expensetypes/V2/${expenseType.id}`, expenseType);
  }

  delete(expenseTypeId: number) {
    return this.http.delete(`${this.jpiUrl}/expensetypes/V2/${expenseTypeId}`);
  }
}
