import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DxListComponent } from 'devextreme-angular';
import { Subscription } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { CompanyLabel } from '../../../shared/models/admin/company-labels/company-label';
import { CompanyLabelGroup } from '../../../shared/models/admin/company-labels/company-label-group';
import { CustomFieldV2 } from '../../../shared/models/admin/custom-fields/custom-field-V2';
import { CustomList } from '../../../shared/models/admin/custom-fields/customList';
import { SectionV2 } from '../../../shared/models/admin/custom-fields/section-V2';
import { CallNoteV2 } from '../../../shared/models/call-notes/call-note-V2';
import { Company } from '../../../shared/models/companies/company';
import { CompanyV2 } from '../../../shared/models/companies/company-V2';
import { Contact } from '../../../shared/models/contacts/contact';
import { ContactV2 } from '../../../shared/models/contacts/contact-V2';
import { ToolbarTab } from '../../../shared/models/toolbarTab';
import { CustomFieldService } from '../../../shared/services/admin/custom-field.service';
import { CompanyLabelGroupService } from '../../../shared/services/admin/customization/company-label-group.service';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { CallNoteService } from '../../../shared/services/call-notes/call-note.service';
import { CompanyJPIService } from '../../../shared/services/companies/company-JPI.service';
import { CompanySearchService } from '../../../shared/services/companies/company-search.service';
import { CompanyService } from '../../../shared/services/companies/company.service';
import { ContactService } from '../../../shared/services/contact.service';
import { ExpandService } from '../../../shared/services/expand.service';
import { SalesService } from '../../../shared/services/sales.service';
import { ToolbarService } from '../../../shared/services/toolbar.service';
import { ActivityPermissionsService } from './../../../shared/services/permissions/activity-permissions.service';
import { CompanyPermissionsService } from './../../../shared/services/permissions/company-permissions.service';
import { RootState } from 'src/app/store/store.reducer';
import { Store } from '@ngrx/store';
import { OpenDrawer } from 'src/app/store/layout/layout.actions';
import { CallNoteFormComponent } from 'src/app/view/shared/call-note-form/call-note-form.component';
import {
  LoadCompaniesSuccess,
  SearchCompaniesSuccess
} from 'src/app/store/companies/companies.actions';
import { CallNoteFormAudioComponent } from 'src/app/view/shared/call-note-form-audio/call-note-form-audio.component';

@Component({
  selector: 'company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['../companies.scss']
})
export class CompanyProfileComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild(DxListComponent) dxList: DxListComponent;
  @Input() companyId: number;
  @Input() isList: boolean;
  @Input() isPopup: boolean;
  @Input() popupTabsFixed: boolean;
  newCompanyListMenu: boolean;
  customFieldsExpanded: boolean;
  window: any = window;
  isProcedeLinked: boolean;
  procedeData: any;
  loadingProcedeData: boolean;
  callNotePopup: boolean;
  editCompanyPopup: boolean;
  fileUploadPopup: boolean;
  profileTabs: any[] = [];
  searchResults: any[];
  showSearchResults = false;
  contacts: Contact[];
  company: Company;
  companyDetails: CustomFieldV2[];
  customSection: SectionV2[];
  newCustomSection: SectionV2[];
  loadingCompany: boolean;
  loadingCallNotes: boolean;
  loadingContacts: boolean;
  loadingSales: boolean;
  sections: SectionV2[];
  labelGroups: CompanyLabelGroup[];
  displayedLabels: CompanyLabelGroup[];
  newGroupLabels: CompanyLabelGroup[];
  companyCustomSections: SectionV2[];
  customFields: CustomFieldV2[] = [];
  selectedIndex: number;
  fixed: boolean;
  newCompopup: boolean;
  callNotePopup$: boolean;
  fileUploadPopup$: boolean;
  companyPopup$: boolean;
  contactPopup$: boolean;
  callNotes: CallNoteV2[];
  lists: CustomList[];
  canEditCompany: boolean;
  canSeeSales: boolean;
  snid: string;
  safeUrl: SafeResourceUrl;
  followUpPopup$: boolean;
  selectedCallNote: CallNoteV2;
  companyJPI: CompanyV2;
  navExpanded: boolean = true;
  isSalesAdmin: boolean;
  searchTermSubscription: Subscription;
  customCompanySubscription: Subscription;
  customCallNoteSubscription: Subscription;
  customFollowUpSubscription: Subscription;
  companyProfileCompanyPopupSubscription: Subscription;
  editContactsFeature: number;
  callNotesFeature: number;
  document: Document;
  webRecording: boolean = false;

  constructor(
    private _toolbarService: ToolbarService,
    private _route: ActivatedRoute,
    private _companyService: CompanyService,
    private _contactService: ContactService,
    private _callNoteService: CallNoteService,
    private _router: Router,
    private _customFieldService: CustomFieldService,
    private _salesService: SalesService,
    private _authService: AuthenticationService,
    private _sanitizer: DomSanitizer,
    _expandService: ExpandService,
    private _companyJPIService: CompanyJPIService,
    _activityPermissionsService: ActivityPermissionsService,
    _companyPermissionsService: CompanyPermissionsService,
    private _companyLabelGroupService: CompanyLabelGroupService,
    private store: Store<RootState>,
    private _companySearchService: CompanySearchService
  ) {
    _expandService.expandedAnnounced.subscribe((expand) => {
      this.navExpanded = expand;
    });
    this._salesService.hasAdmin().then((result) => {
      this.isSalesAdmin = result;
    });
    _activityPermissionsService
      .getCallNotesPermission()
      .then((result) => (this.editContactsFeature = result));
    _companyPermissionsService
      .getContactsPermission()
      .then((result) => (this.callNotesFeature = result));
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event): void {
    this.fixed = event.path[0].scrollingElement.scrollTop >= 60;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (Object.keys(changes).length === 1) {
      if (Object.keys(changes)[0] !== 'popupTabsFixed') {
        this.loadingCompany = true;
        this.initCompany();
      }
    } else {
      this.loadingCompany = true;
      this.initCompany();
    }
  }

  ngOnInit(): void {
    this.loadingCompany = true;
    this.loadingCallNotes = true;
    this.loadingContacts = true;
    this.loadingSales = true;

    if (!this.isList) {
      const listTab: ToolbarTab = {
        TabName: 'List',
        TabUrl: '/companies-list/list',
        IsActive: false
      };
      const profileTab: ToolbarTab = {
        TabName: 'Profile',
        TabUrl: '/companies/company/' + this.companyId,
        IsActive: true
      };

      this._toolbarService.addTab(listTab);
      this._toolbarService.addTab(profileTab);
    }

    this._companyService.canMapExcedeData().then((result: boolean) => {
      if (result) {
        const procedeTab: ToolbarTab = {
          TabName: 'DMS',
          TabUrl: '/companies-list/dms',
          IsActive: false
        };
        this._toolbarService.addTab(procedeTab);
      }
    });

    this._toolbarService.showSearchBar('Search Voze');
    if (!this.isList) {
      this._toolbarService.addButton('New');
    }

    this.searchTermSubscription = this._toolbarService.searchTermAnnounced.subscribe(
      (searchTerm) => {
        delete this.searchResults;
        if (searchTerm) {
          this.showSearchResults = true;
          this._companySearchService.toolbarSearch(searchTerm).then((searchResults) => {
            this.searchResults = searchResults;
          });
        } else {
          this.showSearchResults = false;
        }
      }
    );

    this.customCompanySubscription =
      this._toolbarService.customCompanyAnnounced.subscribe((result) => {
        this.companyPopup$ = result;
      });
    this.customCallNoteSubscription =
      this._toolbarService.customCallNoteAnnounced.subscribe((result) => {
        this.selectedCallNote = Object.assign({
          followUp: Object.assign({}),
          type: Object.assign({})
        });
        this.callNotePopup$ = result;
      });
    this.customFollowUpSubscription =
      this._toolbarService.customFollowUpAnnounced.subscribe((result) => {
        this.selectedCallNote = Object.assign({
          followUp: Object.assign({}),
          type: Object.assign({})
        });
        this.followUpPopup$ = result;
      });
    this.companyProfileCompanyPopupSubscription =
      this._companyService.companyProfileCompanyPopupAnnounced.subscribe((result) => {
        this.editCompanyPopup = result;
      });

    this._companyLabelGroupService.getCompanyLabelGroups().then((labelGroups) => {
      this.labelGroups = labelGroups;
      if (!this.displayedLabels) {
        this.displayedLabels = JSON.parse(JSON.stringify(labelGroups));
      }
      this.newGroupLabels = JSON.parse(JSON.stringify(labelGroups));
      this._customFieldService.getSections().then((result) => {
        this.sections = result;
        this.newCustomSection = this.sections.filter((section) => section.Id !== 0);
        this.initRouter();
      });
    });
    this.store
      .select('auth', 'data', 'api', 'Role', 'Features', 4, 'Permissions')
      .subscribe((permissions: number) => {
        if (
          (permissions != null || undefined) &&
          Object.keys(permissions).length &&
          permissions[163]?.Value === 'true'
        ) {
          this.webRecording = true;
        }
      });
  }

  initRouter(): void {
    this._route.params.forEach((params: Params) => {
      if (!this.isList) {
        if (isNaN(params['id'])) {
          this._router.navigate(['companies']);
        }
        this.companyId = params['id'];
        this.initCompany();
      }
    });
  }

  ngOnDestroy(): void {
    if (!this.isList) {
      this._toolbarService.clearToolbar();
      this._callNoteService.clearCallNote();
      this.newCompanyListMenu = false;
    }
    this._toolbarService.companyId = null;
    this.searchTermSubscription.unsubscribe();
    this.customCompanySubscription.unsubscribe();
    this.customCallNoteSubscription.unsubscribe();
    this.customFollowUpSubscription.unsubscribe();
    this.companyProfileCompanyPopupSubscription.unsubscribe();
    window.removeEventListener('scroll', () => {});
    delete this.snid;
    delete this.safeUrl;
  }

  initCompany(): void {
    this.loadingCompany = true;
    this._toolbarService.companyId = this.companyId;
    this.snid = this._authService.getCookie(this._authService.cookieNames.Snid);
    const url =
      'https://' +
      environment.classicUrl +
      '/TNAccountInfoUDFields.aspx?ACTID=' +
      this.companyId +
      '&SNID=' +
      this.snid;
    this.safeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(url);
    // this._sanitizer.
    this.contacts = [];
    this._toolbarService.resetSearchBarPlaceHolder();
    this.showSearchResults = false;

    const filter = {
      filters: [
        { operand1: 'company.id', operator: 'IS_EQUAL_TO', operand2: this.companyId }
      ]
    };

    Promise.all([
      this._companyJPIService.getCompanyByIdJPI(this.companyId), // 0
      this._companyService.getCompanyById(this.companyId), // 1
      this._customFieldService.getCompanyCustomFields(this.companyId), // 2
      this._contactService.getContactsWithCustomFields(this.companyId) // 3
      // this._customFieldService.getLists(), // 4
    ]).then((result: any) => {
      this.companyJPI = result[0];

      this.store.dispatch(
        new LoadCompaniesSuccess({
          [this.companyJPI.id]: this.companyJPI as any
        })
      );

      this.company = result[1];
      this.sections = result[2];
      this._companyService.canEditCompany(this.companyJPI).then((result) => {
        this.canEditCompany = result;
      });
      if (!this.companyDetails) {
        this.companyDetails = result[2].filter((section) => section.Id == 0)[0].Fields;
      }
      if (this.companyJPI.website) {
        this.companyJPI.website = this.companyJPI.website.trim();
      }
      if (this.companyJPI.deleted) {
        this._router.navigate(['companies']);
      }
      if (this.displayedLabels) {
        for (const labelGroup of this.displayedLabels) {
          labelGroup.labels = [];
          labelGroup.labels = this.companyJPI.labels.filter(
            (label: CompanyLabel) => label.groupId == labelGroup.id
          );
        }
      } else {
        this._companyLabelGroupService.getCompanyLabelGroups().then(
          (result) => {
            this.displayedLabels = result;
            for (const labelGroup of this.displayedLabels) {
              labelGroup.labels = [];
              labelGroup.labels = this.companyJPI.labels.filter(
                (label: CompanyLabel) => label.groupId == labelGroup.id
              );
            }
          },
          (error) => {
            throw error;
          }
        );
      }
      this.companyJPI.contacts = this.companyJPI.contacts.filter(
        (contact: ContactV2) => !contact.deleted
      );
      this.companyJPI.contacts.sort((a: ContactV2, b: ContactV2) =>
        a.firstName.localeCompare(b.firstName)
      );
      if (!this.companyJPI.billingAddress) {
        this.companyJPI.billingAddress = Object.assign({});
      }
      if (!this.companyJPI.shippingAddress) {
        this.companyJPI.shippingAddress = Object.assign({});
      }
      // for (const section of this.sections) {
      //     for (const label of this.companyJPI.labels) {
      //         if (section.Labels.includes(label.id)) {
      //             section.DisplaySection = true;
      //             break;
      //         }
      //     }
      // }
      this.customSection = result[2].filter((section) => section.Id != 0);
      // this.lists = result[4];
      this.checkSalesPermissions().then(() => {
        if (this.company.ProcedeCustomerId) {
          this._authService.getVisibleFeatures().then((result: any) => {
            var dashboardFeature = result.filter((item) => item.Id === 12);
            // if (dashboardFeature.length > 0) {
            //   this.profileTabs.push(
            //     { text: 'Sales Charts', isOpen: true },
            //     { text: 'DMS Sales Data', isOpen: true }
            //   );
            // }
          });

          this._companyService.canViewExcedeData().then((result) => {
            if (result) {
              this.loadingProcedeData = true;
              this.profileTabs.push(
                { text: 'Sales Charts', isOpen: true },
                { text: 'Excede', isOpen: true }
              );
              this.isProcedeLinked = true;
              this._companyService
                .getProcedeCustomer(this.company.ProcedeCustomerId)
                .then((result: any) => {
                  this.procedeData = JSON.parse(result);

                  this.procedeData.repairOrders = [];
                  this.procedeData.leaseOrders = [];
                  this.procedeData.partsOrders = [];
                  for (let i = 0; i < this.procedeData.Orders.length; i++) {
                    this.procedeData.Orders[i].CreatedDate = new Date(
                      this.procedeData.Orders[i].CreatedDate
                    );
                    if (this.procedeData.Orders[i].OrderType == 1) {
                      this.procedeData.repairOrders.push(this.procedeData.Orders[i]);
                      this.procedeData.partsOrders.push(this.procedeData.Orders[i]);

                      for (let j = 0; j < this.procedeData.Orders[i].Parts.length; j++) {
                        this.procedeData.Orders[i].Parts[j].CreatedDate = new Date(
                          this.procedeData.Orders[i].Parts[j].CreatedDate
                        );
                      }
                    } else if (this.procedeData.Orders[i].OrderType == 2) {
                      this.procedeData.leaseOrders.push(this.procedeData.Orders[i]);
                    } else if (this.procedeData.Orders[i].OrderType == 3) {
                      this.procedeData.partsOrders.push(this.procedeData.Orders[i]);
                      for (let j = 0; j < this.procedeData.Orders[i].Parts.length; j++) {
                        this.procedeData.Orders[i].Parts[j].CreatedDate = new Date(
                          this.procedeData.Orders[i].Parts[j].CreatedDate
                        );
                      }
                    }
                  }
                  delete this.procedeData.Orders;
                  this.loadingProcedeData = false;
                });
            }
          });
        }
      });
      // for (let i = 0; i < this.customSection.length; i++) {
      // 	for (let j = 0; j < this.customSection[i].Tabs.length; j++) {
      // 		for (let k = 0; k < this.customSection[i].Tabs[j].Fields.length; k++) {
      // 			this.customFields.push(this.customSection[i].Tabs[j].Fields[k]);
      // 			if (this.customSection[i].Tabs[j].Fields[k].ListId) {
      // 				for (const list of this.lists) {
      // 					if (list.Id == this.customSection[i].Tabs[j].Fields[k].ListId) {
      // 						this.customSection[i].Tabs[j].Fields[k].Options = list.Options;
      // 					}
      // 				}
      // 			}
      // 		}
      // 	}
      // }

      this.loadingCallNotes = false;
      this.contacts = result[3];
      for (let i = 0; i < this.contacts.length; i++) {
        this.contacts[i].FullName =
          this.contacts[i].FirstName + ' ' + this.contacts[i].LastName;
        this.contacts[i].ContactId = parseInt(this.contacts[i].ContactId.toString());
      }
      this.loadingContacts = false;
    });
  }

  checkSalesPermissions(): Promise<any> {
    return this._salesService.canSeeSales(this.companyJPI).then((result) => {
      this.canSeeSales = result;
      this.createProfileTabs();
    });
  }

  createProfileTabs(): void {
    if (this.canSeeSales) {
      this.profileTabs = [
        { text: 'Details', isOpen: true },
        { text: 'Activity', isOpen: true },
        { text: 'Contacts', isOpen: true },
        { text: 'Sales', isOpen: true },
        { text: 'Files', isOpen: true }
      ];
    } else {
      this.profileTabs = [
        { text: 'Details', isOpen: true },
        { text: 'Activity', isOpen: true },
        { text: 'Contacts', isOpen: true },
        { text: 'Files', isOpen: true }
      ];
    }
    // TODO Add this back in when we are all moved over
    // for (let i = 0; i < this.customSection.length; i++) {
    //     if (this.customSection[i].DisplaySection) {
    //         this.profileTabs.push({ text: this.customSection[i].Name, isOpen: true });
    //     }
    // }
    if (
      this._authService.getCookie(this._authService.cookieNames.NewCustomFields) == 'true'
    ) {
      this.profileTabs.push({ text: 'Custom Fields', isOpen: true });
    }
    this.loadingCompany = false;
  }

  openExternalWebsite(webURL: string): void {
    if (webURL && (webURL.startsWith('http://') || webURL.startsWith('https://'))) {
      window.open(webURL, '_blank');
    } else {
      window.open('http://' + webURL, '_blank');
    }
  }

  selectTab(event: any): void {
    for (let i = 0; i < this.profileTabs.length; i++) {
      if (this.profileTabs[i].text == event.itemData.text) {
        this.profileTabs[i].isOpen = true;
        break;
      }
    }
    if (event.itemData.text == 'Details') {
      document.getElementById(event.itemData.text).scrollIntoView();
      window.scroll(0, window.scrollY - 300);
    } else if (event.itemData.text == 'Activity') {
      document.getElementById(event.itemData.text).scrollIntoView();
      window.scroll(0, window.scrollY - 150);
    } else if (event.itemData.text == 'Additional Fields') {
      document.getElementById(event.itemData.text).scrollIntoView();
      window.scroll(0, window.scrollY - 150);
    } else if (
      event.itemData.text == this.profileTabs[this.profileTabs.length - 1].text
    ) {
      document.getElementById(event.itemData.text).scrollIntoView();
      window.scroll(0, window.scrollY + 1000);
    } else {
      document.getElementById(event.itemData.text).scrollIntoView();
      window.scroll(0, window.scrollY - 104);
    }
  }

  selectedTab(event): void {
    this.selectedIndex = event.index;
  }

  companyEdit(tab): void {
    this.companyCustomSections = this.customSection;
    this._companyService.companyProfileCompanyPopupChange(true);
    setTimeout(() => {
      tab.isOpen = true;
    }, 10);
  }

  editSection(sections: SectionV2[], tab): void {
    this.editCompanyPopup = true;
    this.customFieldsExpanded = true;
    this.companyCustomSections = sections;
    setTimeout(() => {
      tab.isOpen = true;
    }, 10);
  }

  newCallNote() {
    // this.callNotePopup$ = true;
    // this.selectedCallNote = Object.assign({ followUp: Object.assign({}), type: Object.assign({}) });
    // this.newCompanyListMenu = false;
    this.store.dispatch(
      new OpenDrawer({
        component: CallNoteFormComponent,
        data: {
          company: {
            id: this.company.Id,
            name: this.company.CompanyName,
            address: this.company.Address
          }
        }
      })
    );
  }

  newFollowUp() {
    // this.followUpPopup$ = true;
    // this.selectedCallNote = Object.assign({ followUp: Object.assign({}), type: Object.assign({}) });
    // this.newCompanyListMenu = false;
    this.store.dispatch(
      new OpenDrawer({
        component: CallNoteFormComponent,
        data: {
          followUpOpen: true,
          company: {
            id: this.company.Id,
            name: this.company.CompanyName,
            address: this.company.Address
          }
        }
      })
    );
  }

  newContact() {
    this._toolbarService.customContactVisible(true);
    this.newCompanyListMenu = false;
  }

  openCallNoteDrawer(): void {
    const company = {
      name: this.company.CompanyName,
      id: this.company.CompanyId
    };

    this.store.dispatch(
      new OpenDrawer({
        component: CallNoteFormAudioComponent,
        data: {
          company: company,
          redirect: true
        }
      })
    );
  }
}
