import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ReportsEffects } from './reports.effects';
import { reportsReducer } from './reports.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('reports', reportsReducer),
    EffectsModule.forFeature([ReportsEffects])
  ]
})
export class ReportsStateModule {}
