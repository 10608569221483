<mat-form-field
  class="company-select-form-field"
  [style.width]="width"
  [style.padding]="padding"
>
  <mat-icon class="search-icon" matPrefix>search</mat-icon>
  <input
    type="text"
    [placeholder]="placeholder"
    matInput
    autocomplete="off"
    [autofocus]="autofocus"
    [required]="isRequired"
    #companySearchInput
    [formControl]="companySearchCtrl"
    [matAutocomplete]="companyAuto"
  />
  <a
    *ngIf="showCallMadeIcon"
    class="edit-icon"
    matSuffix
    target="_blank"
    [routerLink]="['/companies/company/', companySearchCtrl.value?.id]"
    ><mat-icon class="search-icon">call_made</mat-icon></a
  >
  <mat-hint align="start">
    <span
      *ngIf="
        !(
          companySearchCtrl.errors?.validateIdNameItem?.errorMsg &&
          !!($error | async) &&
          !companySearchCtrl.pristine
        )
      "
      align="start"
      >{{
        companySearchCtrl?.value?.address || companySearchCtrl?.value?.billingAddress
          | formatAddress
      }}
    </span>
    <strong
      *ngIf="
        companySearchCtrl.errors?.validateIdNameItem?.errorMsg &&
        !!($error | async) &&
        !companySearchCtrl.pristine
      "
      class="hint-error"
    >
      {{ companySearchCtrl.errors?.validateIdNameItem?.errorMsg }}</strong
    >
    <strong *ngIf="$error | async" class="hint-error">There has been an error</strong>
  </mat-hint>
  <mat-hint *ngIf="$error | async" align="end"
    ><span class="hint-error">error: {{ ($error | async)?.status }}</span></mat-hint
  >
  <span class="loading-icon" matSuffix>
    <mat-spinner *ngIf="$pending | async" diameter="16"></mat-spinner>
    <mat-icon *ngIf="$error | async" color="warn">error</mat-icon>
  </span>
  <mat-autocomplete
    #companyAuto="matAutocomplete"
    [displayWith]="displayFn"
    (optionSelected)="companySelected.emit($event)"
  >
    <mat-option *ngFor="let option of $companySearchResults | async" [value]="option">
      <div class="company-option-name">{{ option.name }}</div>
      <div class="company-option-address">{{ option.address | formatAddress }}</div>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
