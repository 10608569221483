import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  LoadUsersFailure,
  LoadUsersSuccess,
  UsersActionTypes,
  UsersActions
} from './users.actions';
import { UsersService } from 'src/app/services/users/users.service';
import { convertToMap } from 'src/app/utils/convertToMap';
import { User } from 'src/app/models/admin/users/user';
import { Store } from '@ngrx/store';
import { RootState } from '../store.reducer';
import { selectFeatures } from 'src/app/view/shared/sidenav/sidenav-links.selector';
import { Feature } from 'src/app/models/features/feature';
import { GenericMap } from 'src/app/models/generic-map';
import { Territory } from 'src/app/models/admin/territories/territory';
import { deepFlattenArray } from 'src/app/utils/deepFlattenArray';

@Injectable()
export class UsersEffects {
  constructor(
    private actions$: Actions<UsersActions>,
    private usersService: UsersService,
    private store: Store<RootState>
  ) {}

  loadUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActionTypes.LoadUsers),
      concatMap(({ payload }) =>
        this.usersService.getAll(payload).pipe(
          map((users: User[]) => {
            return new LoadUsersSuccess(convertToMap(users, 'id'));
          }),
          catchError((error) => of(new LoadUsersFailure(error)))
        )
      )
    )
  );
}
