import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import { Subscription } from 'rxjs';
import { CallType } from 'src/app/models/admin/call-type/call-type';
import {
  CreateCallTypes,
  ClearCurrentCallType,
  UpdateCallTypes,
  DeleteCallTypes
} from '../../../../../store/system-settings/call-types/call-types.actions';
import { CloseDrawer } from 'src/app/store/layout/layout.actions';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDeleteComponent } from 'src/app/view/shared/confirm-delete/confirm-delete.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'tn-add-call-type',
  templateUrl: './add-call-type.component.html',
  styleUrls: ['./add-call-type.component.css']
})
export class AddCallTypeComponent implements OnInit, OnDestroy {
  callTypeForm: FormGroup;
  callTypes: CallType[];
  currentCallType: CallType;
  $callTypeSub: Subscription;
  $currentCallTypeSub: Subscription;

  constructor(private store: Store<RootState>, private dialog: MatDialog) {}

  ngOnInit() {
    this.callTypeForm = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      id: new FormControl(null)
    });
    this.$currentCallTypeSub = this.store
      .select('callTypes', 'currentCallType')
      .subscribe((callType) => {
        this.currentCallType = callType;
        if (!!callType) {
          this.callTypeForm.patchValue({
            name: callType.name,
            id: callType.id
          });
        }
      });
    this.$callTypeSub = this.store
      .select('callTypes', 'callTypes')
      .subscribe((callType) => (this.callTypes = callType));
  }

  ngOnDestroy() {
    this.$callTypeSub.unsubscribe();
    this.$currentCallTypeSub.unsubscribe();
  }

  save() {
    this.store.dispatch(new CreateCallTypes(this.callTypeForm.value));
    this.store.dispatch(new ClearCurrentCallType());
    this.store.dispatch(new CloseDrawer());
  }

  update() {
    this.store.dispatch(new UpdateCallTypes(this.callTypeForm.value));
    this.store.dispatch(new ClearCurrentCallType());
    this.store.dispatch(new CloseDrawer());
  }

  cancel(): void {
    this.store.dispatch(new ClearCurrentCallType());
    this.store.dispatch(new CloseDrawer());
  }

  delete(): void {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {});

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((willDelete: boolean) => {
        if (willDelete) {
          this.store.dispatch(new DeleteCallTypes(this.currentCallType.id));
          this.store.dispatch(new ClearCurrentCallType());
          this.store.dispatch(new CloseDrawer());
        }
      });
  }
}
