import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Todo } from 'src/app/models/todo/todo';

@Injectable({
  providedIn: 'root'
})
export class TodoService {
  private jpiUrl = environment.jpiUrl;
  constructor(private http: HttpClient) {}

  getAllTodos() {
    return this.http.get(`${this.jpiUrl}/todos`);
  }

  createTodo(todo: Todo) {
    return this.http.post(`${this.jpiUrl}/todos`, todo);
  }

  updateTodo(todo: Todo) {
    return this.http.put(`${this.jpiUrl}/todos`, todo);
  }

  getTodo(todoId: number) {
    return this.http.get(`${this.jpiUrl}/todos/${todoId}`);
  }

  deleteTodo(todoId: number) {
    return this.http.delete(`${this.jpiUrl}/todos/${todoId}`);
  }

  getAllCompleteTodos() {
    return this.http.get(`${this.jpiUrl}/todos/completed`);
  }

  getAllIncompleteTodos() {
    return this.http.get(`${this.jpiUrl}/todos/incomplete`);
  }

  reOrderTodos(todoArray: Todo[]) {
    return this.http.post(`${this.jpiUrl}/todos/order`, todoArray);
  }
}
