import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

import { AuthenticationService } from '../../services/authentication.service';
import { CompanyJPIService } from '../../services/companies/company-JPI.service';
import { ChildCompanyRequest } from '../../models/companies/child-company-request';
import { CompanyV2 } from '../../models/companies/company-V2';
import { PagedResultRequest } from '../../models/paged-result-request';

@Component({
	selector: 'child-company-popup',
	templateUrl: './child-company-popup.component.html',
	styleUrls: ['../../../shared/styles/buttons.shared.scss',
		'../contact/contact-popup.component.scss']
})
export class ChildCompanyPopupComponent implements OnInit, OnDestroy {
	@Input() childCompanyPopup: boolean;
	@Input() companyId: number;
	@Input() childCompanyRequest: ChildCompanyRequest;
	@Input() companyJPI: CompanyV2;
	@Output() childCompanyPopupChanges: EventEmitter<boolean> = new EventEmitter<boolean>();
	window: any = window;
	companies: any = {};
	searchTerm: string;

	constructor(private _authService: AuthenticationService,
		private _companyService: CompanyJPIService) {
	}

	ngOnInit(): void {
		if (!this.childCompanyRequest) { this.childCompanyRequest = Object.assign({ ChildCompanyIds: [] }); }
		this._companyService.getById(this.companyId).then(company => this.companyJPI = company);

		this.companies = new DataSource({
			// store: new CustomStore({
			load: (options) => {
				// const request: PagedResultRequest = <PagedResultRequest>{
				//     Skip: options.skip,
				//     Limit: options.take
				// };

				// if (options.sort) {
				//     request.SortField = options.sort[0].selector;
				//     request.DescSort = options.sort[0].desc;
				// }

				// if (options.filter) {
				//     request.Filter = this.convertCustomFieldsToId(options.filter);
				// }
				if (options.searchValue) {
					return this._companyService.getJPICompanies(10, 0, "id,name", `name{}${options.searchValue}`, "-name").then((result) => {
						return result;
					});
				} else {
					return this._companyService.getJPICompanies(10, 0, "id.name", null, "-name").then((result) => {
						return result;
					});
				}

				// return this._callnoteService.getCallNotes(request).then(function (result) {
				//     this.callNoteCount = result.TotalItems;
				//     result.Items.forEach(function (note: CallNote) {
				//         if (note.Narratives && note.Narratives.length > 0) {
				//             for (let i = 0; i < note.Narratives.length; i++) {
				//                 if (note.Narratives[i].NarrativeTypeId && self.callNoteFieldIdLookup[note.Narratives[i].NarrativeTypeId]) {
				//                     (<any>note)[self.callNoteFieldIdLookup[note.Narratives[i].NarrativeTypeId]] = note.Narratives[i].Narrative;
				//                 }
				//             }
				//         }
				//     });
				//     return {
				//         data: result.Items,
				//         totalCount: result.TotalItems
				//     };
				// }, function () {
				//     return {
				//         data: [],
				//         totalCount: 0
				//     };
				// });
			}
			// })
		});
	}

	searchCompanies(e) {
		this.searchTerm = e.data;
	}

	ngOnDestroy(): void {
		this.emitVariables();
	}

	emitVariables(): void {
		this.childCompanyPopupChanges.emit(this.childCompanyPopup);
	}

}
