import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { fieldTypesReducer } from './field-types.reducer';
import { EffectsModule } from '@ngrx/effects';
import { FieldTypesEffects } from './field-types.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('fieldTypes', fieldTypesReducer),
    EffectsModule.forFeature([FieldTypesEffects])
  ]
})
export class FieldTypesStateModule {}
