import { ColumnHeader, ColumnType } from 'src/app/models/column-header';

export const ByManufacturerColumnsData: ColumnHeader[] = [
  {
    name: 'projectName',
    displayName: 'Project Name',
    type: ColumnType.STRING,
    isCurrency: false,
    isDate: false
  },
  {
    name: 'owner',
    displayName: 'Owner',
    type: ColumnType.STRING,
    isCurrency: false,
    isDate: false
  },
  {
    name: 'assignedTo',
    displayName: 'Assigned To',
    type: ColumnType.STRING,
    isCurrency: false,
    isDate: false
  },
  {
    name: 'address',
    displayName: 'Address',
    type: ColumnType.STRING,
    isCurrency: false,
    isDate: false
  },
  {
    name: 'city',
    displayName: 'City',
    type: ColumnType.STRING,
    isCurrency: false,
    isDate: false
  },
  {
    name: 'state',
    displayName: 'State',
    type: ColumnType.STRING,
    isCurrency: false,
    isDate: false
  },

  {
    name: 'manufacturer',
    displayName: 'Manufacturer',
    type: ColumnType.STRING,
    isCurrency: false,
    isDate: false
  },
  {
    name: 'manufCompanies',
    displayName: 'Companies',
    type: ColumnType.STRING,
    isCurrency: false,
    isDate: false
  },
  {
    name: 'manufacturerStatus',
    displayName: 'Manufacturer Status',
    type: ColumnType.STRING,
    isCurrency: false,
    isDate: false
  },
  {
    name: 'manufacturerEstimate',
    displayName: 'Manufacturer Estimate',
    type: ColumnType.NUMERIC,
    isCurrency: true,
    isDate: false
  },
  {
    name: 'manufacturerActual',
    displayName: 'Manufacturer Actual',
    type: ColumnType.NUMERIC,
    isCurrency: true,
    isDate: false
  },

  {
    name: 'bidDateEstimate',
    displayName: 'Estimated Bid Date',
    type: ColumnType.NUMERIC,
    isCurrency: false,
    isDate: true
  },
  {
    name: 'bidDateActual',
    displayName: 'Actual Bid Date',
    type: ColumnType.NUMERIC,
    isCurrency: false,
    isDate: true
  },
  {
    name: 'closeDateEstimate',
    displayName: 'Estimated Close Date',
    type: ColumnType.NUMERIC,
    isCurrency: false,
    isDate: true
  },
  {
    name: 'closeDateActual',
    displayName: 'Actual Close Date',
    type: ColumnType.NUMERIC,
    isCurrency: false,
    isDate: true
  },
  {
    name: 'totalBids',
    displayName: 'Total Bids',
    type: ColumnType.NUMERIC,
    isCurrency: false,
    isDate: false
  },
  {
    name: 'amountEstimate',
    displayName: 'Project Estimate',
    type: ColumnType.NUMERIC,
    isCurrency: true,
    isDate: false
  },
  {
    name: 'amountActual',
    displayName: 'Project Actual',
    type: ColumnType.NUMERIC,
    isCurrency: true,
    isDate: false
  }
];
