import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import {
  CompanyLabelsIndividualActionTypes,
  CompanyLabelsIndividualActions,
  LoadCompanyLabelsIndividualSuccess,
  LoadCompanyLabelsIndividualFailure,
  CreateCompanyLabelsIndividual,
  CreateCompanyLabelsIndividualSuccess,
  CreateCompanyLabelsIndividualFailure,
  UpdateCompanyLabelsIndividual,
  UpdateCompanyLabelsIndividualSuccess,
  UpdateCompanyLabelsIndividualFailure,
  DeleteCompanyLabelsIndividual,
  DeleteCompanyLabelsIndividualSuccess,
  DeleteCompanyLabelsIndividualFailure
} from './company-labels-individual.actions';
import { Action } from '@ngrx/store';
import { CompanyLabelsIndividualService } from '../../../services/system-settings/company-labels/company-labels-individual.service';
import { Label } from 'src/app/models/label';
import { HttpErrorResponse } from '@angular/common/http';
import { LoadCompanyLabelsIndividual } from 'src/app/store/system-settings/company-labels-individual/company-labels-individual.actions';
import { convertToMap } from 'src/app/utils/convertToMap';
import { LoadCompanyLabels } from '../company-labels/company-labels.actions';

@Injectable()
export class CompanyLabelsIndividualEffects {
  LoadCompanyLabelsIndividual$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyLabelsIndividualActionTypes.LoadCompanyLabelsIndividual),
      map((action: LoadCompanyLabelsIndividual) => action.payload),
      mergeMap((groupId: number) =>
        this.companyLabelsIndividualService.getAll(groupId).pipe(
          map((companyLabelsIndividual: Label[]) => {
            return new LoadCompanyLabelsIndividualSuccess(
              convertToMap(companyLabelsIndividual, 'id')
            );
          }),
          catchError((error: HttpErrorResponse) =>
            of(new LoadCompanyLabelsIndividualFailure(error))
          )
        )
      )
    )
  );

  createCompanyLabelsIndividual$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyLabelsIndividualActionTypes.CreateCompanyLabelsIndividual),
      map((action: CreateCompanyLabelsIndividual) => action.payload),
      mergeMap((action: { label: Label; groupId: number }) => {
        return this.companyLabelsIndividualService
          .create(action.label, action.groupId)
          .pipe(
            switchMap((newCompanyLabelIndvidual: Label) => [
              new LoadCompanyLabels(),
              new CreateCompanyLabelsIndividualSuccess(newCompanyLabelIndvidual)
            ]),
            catchError((error: HttpErrorResponse) =>
              of(new CreateCompanyLabelsIndividualFailure(error))
            )
          );
      })
    )
  );

  updateCompanyLabelsIndividual$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyLabelsIndividualActionTypes.UpdateCompanyLabelsIndividual),
      map((action: UpdateCompanyLabelsIndividual) => action.payload),
      mergeMap((action: { label: Label; groupId: number }) => {
        return this.companyLabelsIndividualService
          .update(action.label, action.groupId)
          .pipe(
            switchMap((updatedCompanyLabelIndividual: Label) => [
              new LoadCompanyLabels(),
              new UpdateCompanyLabelsIndividualSuccess(updatedCompanyLabelIndividual)
            ]),
            catchError((error: HttpErrorResponse) =>
              of(new UpdateCompanyLabelsIndividualFailure(error))
            )
          );
      })
    )
  );

  deleteCompanyLabelsIndividual$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyLabelsIndividualActionTypes.DeleteCompanyLabelsIndividual),
      map((action: DeleteCompanyLabelsIndividual) => action.payload),
      mergeMap((action: { groupId: number; id: number }) =>
        this.companyLabelsIndividualService.delete(action.groupId, action.id).pipe(
          switchMap(() => [
            new DeleteCompanyLabelsIndividualSuccess(action),
            new LoadCompanyLabels()
          ]),
          catchError((error: HttpErrorResponse) =>
            of(new DeleteCompanyLabelsIndividualFailure(error))
          )
        )
      )
    )
  );

  constructor(
    private companyLabelsIndividualService: CompanyLabelsIndividualService,
    private actions$: Actions<CompanyLabelsIndividualActions>
  ) {}
}
