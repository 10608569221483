import { Component, Input } from '@angular/core';

@Component({
    selector: 'loading-popup',
    templateUrl: './loading-popup.component.html',
    styleUrls: ['../../../shared/styles/buttons.shared.scss',
        '../contact/contact-popup.component.scss']
})
export class LoadingPopupComponent {
    @Input() isLoading: boolean;
    @Input() message: string;
    window: any = window;

    constructor() {
        if (!this.message) { this.message = "Loading..."; }
    }
}
