<div fxLayoutAlign="space-between center" style="margin-bottom: 1.25rem">
  <input type="file" #fileUpload (change)="onFilesAdded()" style="display: none" />
  <button
    mat-raised-button
    class="add-proj-item-nav-button"
    color="primary"
    (click)="addFiles()"
  >
    <mat-icon>add_circle</mat-icon>&nbsp;<span>Add Receipt</span>
  </button>
</div>
<div fxLayout="column" fxLayoutGap="24px">
  <tn-project-file
    *ngFor="let file of $pendingFiles | async"
    [file]="file"
    (deleteEvent)="onDelete($event)"
  >
  </tn-project-file>
</div>
