import { Action } from '@ngrx/store';
import { DrawerItem } from 'src/app/view/shared/drawer/drawer-item';
import { SnackbarState } from './layout.reducer';
import { DialogItem } from './layout.reducer';

export enum LayoutActionTypes {
  OpenSidenav = '[Layout] Open Sidenav',
  CloseSidenav = '[Layout] Close Sidenav',
  ToggleOpenSidenav = '[Layout] Toggle Open Sidenav',
  ExpandSidenav = '[Layout] Expand Sidenav',
  CollapseSidenav = '[Layout] Collapse Sidenav',
  ToggleExpandSidenav = '[Layout] Toggle Expand Sidenav',
  OpenDrawer = '[Layout] Open Drawer',
  CloseDrawer = '[Layout] Close Drawer',
  OpenLeftDrawer = '[Layout] Open Left Drawer',
  CloseLeftDrawer = '[Layout] Close Left Drawer',
  SetLoadingBarVisibility = '[Layout] Set Loading Bar Visibility',
  OpenSnackbar = '[Layout] Open Snackbar',
  CloseSnackbar = '[Layout] Close Snackbar',
  OpenErrorSnackbar = '[Layout] Open Error Snackbar',
  CloseErrorSnackbar = '[Layout] Close Error Snackbar',
  SetDefaultLandingPage = '[Layout] Set Default Landing Page'
}

export class SetDefaultLandingPage implements Action {
  readonly type = LayoutActionTypes.SetDefaultLandingPage;
  constructor(public payload: string) {}
}

export class OpenSidenav implements Action {
  readonly type = LayoutActionTypes.OpenSidenav;
}

export class CloseSidenav implements Action {
  readonly type = LayoutActionTypes.CloseSidenav;
}

export class ToggleOpenSidenav implements Action {
  readonly type = LayoutActionTypes.ToggleOpenSidenav;
}

export class ExpandSidenav implements Action {
  readonly type = LayoutActionTypes.ExpandSidenav;
}

export class CollapseSidenav implements Action {
  readonly type = LayoutActionTypes.CollapseSidenav;
}

export class ToggleExpandSidenav implements Action {
  readonly type = LayoutActionTypes.ToggleExpandSidenav;
}

export class OpenDrawer implements Action {
  readonly type = LayoutActionTypes.OpenDrawer;
  constructor(public payload: DrawerItem) {}
}

export class CloseDrawer implements Action {
  readonly type = LayoutActionTypes.CloseDrawer;
}

export class OpenLeftDrawer implements Action {
  readonly type = LayoutActionTypes.OpenLeftDrawer;
  constructor(public payload: DrawerItem) {}
}
export class CloseLeftDrawer implements Action {
  readonly type = LayoutActionTypes.CloseLeftDrawer;
}

export class SetLoadingBarVisibility implements Action {
  readonly type = LayoutActionTypes.SetLoadingBarVisibility;
  constructor(public payload: boolean) {}
}

export class OpenSnackbar implements Action {
  readonly type = LayoutActionTypes.OpenSnackbar;
  constructor(public payload: SnackbarState) {}
}

export class CloseSnackbar implements Action {
  readonly type = LayoutActionTypes.CloseSnackbar;
}

export class OpenErrorSnackbar implements Action {
  readonly type = LayoutActionTypes.OpenErrorSnackbar;
  constructor(public payload: SnackbarState) {}
}

export class CloseErrorSnackbar implements Action {
  readonly type = LayoutActionTypes.CloseErrorSnackbar;
}

export type LayoutActions =
  | OpenSidenav
  | CloseSidenav
  | ToggleOpenSidenav
  | ExpandSidenav
  | CollapseSidenav
  | ToggleExpandSidenav
  | OpenDrawer
  | CloseDrawer
  | OpenLeftDrawer
  | CloseLeftDrawer
  | SetLoadingBarVisibility
  | OpenSnackbar
  | CloseSnackbar
  | OpenErrorSnackbar
  | CloseErrorSnackbar
  | SetDefaultLandingPage;
