import { MemoizedSelector, createSelector } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import { ItemName } from 'src/app/models/Opportunity/itemName';
import { selectAllItemNames } from './all-itemNames-as-array.selector';

export const selectedItemNameId = (state: RootState) =>
  state.itemNames.selectedItemNameId;

export const selectSelectedItemName: MemoizedSelector<
  RootState,
  ItemName
> = createSelector(
  selectAllItemNames,
  selectedItemNameId,
  (itemNameIndividualMap: { [id: number]: ItemName }, itemNameId: number): ItemName => {
    let itemName: ItemName;
    if (!!itemNameId) {
      const id = itemNameId.toString();
      itemName = {
        ...itemNameIndividualMap[id]
      };
    }
    return itemName;
  }
);
