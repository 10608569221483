import { Injectable } from '@angular/core';

import { AuthenticationService } from './authentication.service';
import { CallTypeAssignment, CallTypeV2 } from '../models/call-type';

@Injectable()
export class CallTypeService {
	myCallTypes: CallTypeV2[];
	myCallTypeAssignments: CallTypeAssignment[];

	constructor(private authService: AuthenticationService) { }

	clearServiceData(): void { }

	getCallTypes(): Promise<CallTypeV2[]> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/calltypes/getCallTypes', '', 'get').then(function (result: any) {
			self.myCallTypes = JSON.parse(result);
			return self.myCallTypes;
		});
	}
	getCallTypesAll(moduleType): Promise<CallTypeV2[]> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/calltypes/getCallTypesAll?moduleType=' + moduleType, '', 'get').then(function (result: any) {
			self.myCallTypes = JSON.parse(result);
			return self.myCallTypes;
		});
	}
	addCallType(ct): Promise<number> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/calltypes/addCallType', ct, 'post').then(function (result: any) {
			return result;
		});
	}

	updateCallType(ct): Promise<number> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/calltypes/updateCallType', ct, 'post').then(function (result: any) {
			return result[0];
		});
	}

	remCallType(ct): Promise<boolean> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/calltypes/remCallType', ct, 'post').then(function (result: any) {
			return result[0];
		});
	}

	getRepCallTypesAssignment(): Promise<CallTypeAssignment[]> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/calltypes/getRepCallTypesAssignment', '', 'get').then(function (result: any) {
			self.myCallTypeAssignments = JSON.parse(result);
			return self.myCallTypeAssignments;
		});
	}

	addRepCallTypeAssignment(cta): Promise<number> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/calltypes/addRepCallTypeAssignment', cta, 'post').then(function (result: any) {
			return result[0];
		});
	}

	remRepCallTypeAssignment(cta): Promise<boolean> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/calltypes/remRepCallTypeAssignment', cta, 'post').then(function (result: any) {
			return result[0];
		});
	}
}
