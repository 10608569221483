import { Injectable } from '@angular/core';

import { TerritoryType } from '../../models/admin/territories/territory-type';
import { AuthenticationService } from '../authentication.service';

@Injectable()
export class TerritoryTypeService {
	constructor(private authService: AuthenticationService) {
		// super('/territorytypes', authService);
	}

	getAll(): Promise<TerritoryType[]> {
		return this.authService.makeJpiRequest('/territorytypes', null, null, 'get').then((type) => {
			return JSON.parse(type);
		});
	}

	getById(): Promise<TerritoryType> {
		return this.authService.makeJpiRequest('/territorytypes', null, null, 'get').then((type) => {
			return JSON.parse(type);
		});
	}

	create(callNoteType: any): Promise<TerritoryType> {
		return this.authService.makeJpiRequest('/territorytypes', null, callNoteType, 'post').then((type) => {
			return JSON.parse(type);
		});
	}

	update(callNoteType: TerritoryType): Promise<TerritoryType> {
		return this.authService.makeJpiRequest('/territorytypes/' + callNoteType.id, null, callNoteType, 'put').then((type) => {
			return JSON.parse(type);
		});
	}

	delete(callNoteType: TerritoryType): Promise<TerritoryType> {
		return this.authService.makeJpiRequest('/territorytypes/' + callNoteType.id, null, callNoteType, 'delete').then((type) => {
			return JSON.parse(type);
		});
	}

}
