import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import { CloseDrawer } from 'src/app/store/layout/layout.actions';
import { Subscription } from 'rxjs';
import { ItemName } from 'src/app/models/Opportunity/itemName';
import { selectSelectedItemName } from 'src/app/store/system-settings/opportunity-item-names/selectors/selected-itemName.selector';
import {
  CreateItemName,
  ClearItemNameId,
  UpdateItemName,
  DeleteItemName
} from '../../../../../store/system-settings/opportunity-item-names/opportunity-item-names.actions';
import { DeleteObject } from 'src/app/store/undo-delete/undo-delete.actions';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDeleteComponent } from 'src/app/view/shared/confirm-delete/confirm-delete.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'tn-add-item-name',
  templateUrl: './add-item-name.component.html',
  styleUrls: ['./add-item-name.component.scss']
})
export class AddItemNameComponent implements OnInit, OnDestroy {
  itemNameForm: FormGroup;
  itemNames: ItemName[];
  currentItemName: ItemName;
  $currentItemNameSub: Subscription;

  constructor(private store: Store<RootState>, private dialog: MatDialog) {}

  ngOnInit() {
    this.itemNameForm = new FormGroup({
      name: new FormControl(null, Validators.required),
      id: new FormControl(null)
    });
    this.$currentItemNameSub = this.store
      .select(selectSelectedItemName)
      .subscribe((itemName) => {
        if (!!itemName) {
          this.itemNameForm.setValue({
            name: itemName.name,
            id: itemName.id
          });
          this.currentItemName = itemName;
        }
      });
  }

  ngOnDestroy() {
    this.$currentItemNameSub.unsubscribe();
  }

  save() {
    this.store.dispatch(new CreateItemName(this.itemNameForm.value));
    this.store.dispatch(new CloseDrawer());
    this.store.dispatch(new ClearItemNameId());
  }

  update() {
    this.itemNameForm.value.id = this.currentItemName.id;
    this.store.dispatch(new UpdateItemName(this.itemNameForm.value));
    this.store.dispatch(new CloseDrawer());
    this.store.dispatch(new ClearItemNameId());
  }

  cancel(): void {
    this.store.dispatch(new CloseDrawer());
    this.store.dispatch(new ClearItemNameId());
  }

  delete(): void {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {});

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((willDelete: boolean) => {
        if (willDelete) {
          this.store.dispatch(new DeleteItemName(this.currentItemName.id));
          this.store.dispatch(new ClearItemNameId());
          this.store.dispatch(new CloseDrawer());
        }
      });
  }
}
