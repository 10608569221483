<mat-form-field fxFlex="1 1 100">
  <mat-chip-list #chipList [disabled]="isDisabled">
    <mat-chip
      *ngFor="let item of selectedItems"
      [selectable]="selectable"
      [removable]="removable"
      (removed)="removeItem(item)"
    >
      {{ item?.name }}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    <input
      matInput
      [placeholder]="placeholder"
      #itemInput
      type="text"
      [formControl]="itemInputCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      (keydown.escape)="escapePressed($event)"
    />
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngIf="hasNullOption" [value]="{ name: '', id: 0 }">
      {{ nullDisplayName }}
    </mat-option>
    <mat-option *ngFor="let item of selectableItems | sort : 'name'" [value]="item">
      <div class="option-item-name">{{ item.name }}</div>
      <div *ngIf="!!subtextProperty" class="option-item-property">
        {{ item[subtextProperty] }}
      </div>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
