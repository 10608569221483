import { PrivateNote } from 'src/app/models/private-notes/private-note';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CloseDrawer } from 'src/app/store/layout/layout.actions';
import { RootState } from 'src/app/store/store.reducer';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { CompanySelectItem } from 'src/app/models/companies/company-select';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalFilter } from 'src/app/models/global-filter';
import {
  ClearSearchCompanies,
  SearchCompanies,
  SelectSearchedCompany
} from 'src/app/store/companies/companies.actions';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { selectCurrentCompany } from 'src/app/store/companies/selectors/current-company.selector';
import { Company } from 'src/app/models/companies/company';
import {
  DeletePrivateNote,
  LoadPrivateNotesAssignableUsers,
  SavePrivateNote,
  UpdatePrivateNote
} from 'src/app/store/private-notes/private-notes.actions';
import { IdNameItem, IdNameItemMap } from 'src/app/models/id-name-item';
import { User } from 'src/app/models/admin/users/user';
import { GenericMap } from 'src/app/models/generic-map';
import { selectAssignableUsersAsIdNameItems } from 'src/app/store/private-notes/selectors/assignable-users.selector';
import { selectIdNameCurrentUser } from 'src/app/store/users/selectors/id-name-current-user.selector';

@Component({
  selector: 'tn-private-note-form',
  templateUrl: './private-note-form.component.html',
  styleUrls: ['./private-note-form.component.scss']
})
export class PrivateNoteFormComponent implements OnInit, OnDestroy {
  // Inputs
  @Input() data?: {
    company?: CompanySelectItem;
    privateNote?: PrivateNote;
  };
  // Form Controls
  privateNoteForm: FormGroup = new FormGroup({
    company: new FormControl('', Validators.required),
    note: new FormControl('', Validators.required),
    callDate: new FormControl(new Date(), Validators.required)
  });

  // Data
  currentPrivateNote: PrivateNote = {
    id: null,
    note: null,
    company: null,
    createdBy: null,
    mailbox: null,
    assignedTo: [],
    createdDate: null,
    createdDateMillis: null,
    deletedDate: null,
    deleted: null
  };
  assignableUsers: IdNameItemMap;
  currentUser: IdNameItem;
  isOwner: boolean = true;

  // Observables
  $companies: Observable<CompanySelectItem[]> = EMPTY;
  $companiesSearchPending: Observable<boolean> = EMPTY;
  $companiesSearchError: Observable<HttpErrorResponse> = EMPTY;
  $currentCompany: Observable<Company> = EMPTY;
  $currentUser: Observable<IdNameItem> = EMPTY;
  $assignableUsers: Observable<GenericMap<User>> = EMPTY;

  // Subscriptions
  currentCompanySub: Subscription;
  currentUserSub: Subscription;
  assignableUsersSub: Subscription;

  constructor(private store: Store<RootState>) {}

  ngOnInit(): void {
    // Dispatches
    this.store.dispatch(new LoadPrivateNotesAssignableUsers());

    // Data
    this.currentPrivateNote.createdDate = new Date();
    if (!!this.data?.privateNote) {
      this.currentPrivateNote = { ...this.data.privateNote };
      this.currentPrivateNote.assignedTo = [
        ...this.convertToNameIdItem(this.data.privateNote.assignedTo)
      ];
      this.currentPrivateNote.company = { ...this.data?.privateNote.company };
      this.store.dispatch(new SelectSearchedCompany(this.data.privateNote.company.id));
      this.privateNoteForm.controls.company.setValue(this.currentPrivateNote.company);
    }

    // Form Controls
    this.privateNoteForm.controls.note.setValue(this.currentPrivateNote.note);
    this.privateNoteForm.controls.callDate.setValue(this.currentPrivateNote.createdDate);

    // Selectors
    this.$companies = this.store.select('companies', 'search', 'data');
    this.$companiesSearchPending = this.store.select('companies', 'search', 'pending');
    this.$companiesSearchError = this.store.select('companies', 'search', 'error');
    this.$currentCompany = this.store.select(selectCurrentCompany);
    this.$currentUser = this.store.select(selectIdNameCurrentUser);
    this.$assignableUsers = this.store.select(selectAssignableUsersAsIdNameItems);

    // Subscriptions
    this.currentCompanySub = this.$currentCompany.subscribe((company) => {
      if (!this.currentPrivateNote?.id && !!company?.id) {
        this.store.dispatch(new SelectSearchedCompany(company.id));
        this.privateNoteForm.controls.company.setValue(company);
        this.currentPrivateNote.company = company;
      }
    });

    this.currentUserSub = this.$currentUser.subscribe((currentUser) => {
      this.currentUser = currentUser;
    });

    // Intial State
    if (
      this.currentPrivateNote.id &&
      this.currentPrivateNote?.createdBy?.id !== this.currentUser?.id
    ) {
      this.privateNoteForm.controls.note.disable();
      this.privateNoteForm.controls.company.disable();
      this.isOwner = this.currentPrivateNote?.createdBy?.id === this.currentUser?.id;
    }
    if (this.currentPrivateNote.id) {
      this.privateNoteForm.markAsDirty();
    }
    this.privateNoteForm.controls.callDate.disable();
  }

  ngOnDestroy(): void {
    this.currentCompanySub.unsubscribe();
    this.currentUserSub.unsubscribe();
  }

  convertToNameIdItem(assignedTo: User[]): IdNameItem[] {
    return assignedTo.map((user) => {
      return {
        id: user.id,
        name: user.firstName + ' ' + user.lastName
      };
    });
  }

  onCompanySearch(searchValue: string) {
    this.privateNoteForm.controls.company.setValue(undefined);
    if (!!searchValue.length) {
      const reqFilter: GlobalFilter = {
        filters: [
          {
            operand1: 'searchField',
            operator: 'CONTAINS_ALL_OF',
            operand2: [searchValue]
          }
        ]
      };
      this.store.dispatch(new SearchCompanies(reqFilter));
    } else {
      this.store.dispatch(new ClearSearchCompanies());
    }
  }

  onCompanySelect(event: MatAutocompleteSelectedEvent) {
    this.privateNoteForm.markAsDirty();
    this.store.dispatch(new SelectSearchedCompany(event.option.value.id));
    this.privateNoteForm.controls.company.setValue(event.option.value);
  }

  onAssignableUserSelect(event: IdNameItem[]) {
    this.currentPrivateNote.assignedTo = event.map((user) => {
      return {
        id: user.id,
        firstName: user.name.split(' ')[0],
        lastName: user.name.split(' ')[1],
        name: user.name
      };
    });
  }

  savePrivateNote() {
    const noteValue = this.privateNoteForm.controls.note.value;
    const callDateValue = this.privateNoteForm.controls.callDate.value;
    const companyId = this.privateNoteForm.controls.company.value.id;
    const companyName = this.privateNoteForm.controls.company.value.name;

    this.currentPrivateNote.note = noteValue;
    this.currentPrivateNote.createdDate = callDateValue;
    this.currentPrivateNote.company = { id: companyId, name: companyName };

    if (this.data?.privateNote?.id) {
      this.store.dispatch(new UpdatePrivateNote(this.currentPrivateNote));
    } else {
      this.store.dispatch(new SavePrivateNote(this.currentPrivateNote));
    }
    this.store.dispatch(new CloseDrawer());
  }

  cancelButtonClicked() {
    this.store.dispatch(new CloseDrawer());
  }

  deletePrivateNote() {
    if (this.data.privateNote.createdBy.id === this.currentUser.id) {
      this.store.dispatch(new DeletePrivateNote(this.data.privateNote.id));
    }
    this.store.dispatch(new CloseDrawer());
  }
}
