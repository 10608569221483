import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ProjectCustomField } from 'src/app/models/projects/project-custom-field';
import { GenericMap } from 'src/app/models/generic-map';

export const SetCurrentProjectManufCustomFieldId = createAction(
  '[ProjectManufCustomFields] Set Current Project Manuf Custom Field ID',
  props<{ id: number }>()
);

export const LoadProjectManufCustomFields = createAction(
  '[ProjectManufCustomFields] Load Project Manuf Custom Fields'
);

export const LoadProjectManufCustomFieldsSuccess = createAction(
  '[ProjectManufCustomFields] Load Project Manuf Custom Fields Success',
  props<{ data: GenericMap<ProjectCustomField> }>()
);

export const LoadProjectManufCustomFieldsFailure = createAction(
  '[ProjectManufCustomFields] Load Project Manuf Custom Fields Failure',
  props<{ error: HttpErrorResponse }>()
);

export const SaveProjectManufCustomField = createAction(
  '[ProjectManufCustomFields] Save Project Manuf Custom Field',
  props<{ data: ProjectCustomField }>()
);

export const SaveProjectManufCustomFieldSuccess = createAction(
  '[ProjectManufCustomFields] Save Project Manuf Custom Field Success',
  props<{ data: ProjectCustomField }>()
);

export const SaveProjectManufCustomFieldFailure = createAction(
  '[ProjectManufCustomFields] Save Project Manuf Custom Field Failure',
  props<{ error: HttpErrorResponse }>()
);

export const DeleteProjectManufCustomField = createAction(
  '[ProjectManufCustomFields] Delete Project Manuf Custom Field',
  props<{ data: ProjectCustomField }>()
);

export const DeleteProjectManufCustomFieldSuccess = createAction(
  '[ProjectManufCustomFields] Delete Project Manuf Custom Field Success',
  props<{ data: any }>()
);

export const DeleteProjectManufCustomFieldFailure = createAction(
  '[ProjectManufCustomFields] Delete Project Manuf Custom Field Failure',
  props<{ error: HttpErrorResponse }>()
);

export const UpdateProjectManufCustomFieldsListOrder = createAction(
  '[ProjectManufCustomFields] Update Project Manuf Custom Fields List Order',
  props<{ data: ProjectCustomField[] }>()
);

export const UpdateProjectManufCustomFieldsListOrderSuccess = createAction(
  '[ProjectManufCustomFields] Update Project Manuf Custom Fields List Order Success',
  props<{ data: ProjectCustomField[] }>()
);

export const UpdateProjectManufCustomFieldsListOrderFailure = createAction(
  '[ProjectManufCustomFields] Update Project Manuf Custom Fields List Order Failure',
  props<{ error: HttpErrorResponse }>()
);
