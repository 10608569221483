<div class="project-form">
  <mat-accordion [multi]="true" class="form-panels">
    <h2>Project</h2>
    <mat-progress-bar
      *ngIf="$pending | async"
      class="form-divider-progress-bar"
      mode="indeterminate"
    ></mat-progress-bar>

      <form [formGroup]="labelsForm" autocomplete="off">
        <mat-divider class="form-divider"></mat-divider>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <h4 class="form-panel-header">Labels<span style="color: #f44336;" *ngIf="hasRequiredLabel">*</span></h4>
          </mat-expansion-panel-header>
          <mat-form-field
            *ngFor="let group of allLabelGroupsArray"
            class="full-width"
          >
            <mat-label>{{ group.name }} </mat-label>
            <mat-select
              [required]="group.required"
              [formControlName]="group.id"
              (selectionChange)="labelChange($event)"
              [multiple]="group.allowMultiple"
              style="margin: 5px;"
            >
              <mat-option [value]="null">
                No selection
              </mat-option>
              <mat-option *ngFor="let label of group.labels" [value]="label.id">
                {{ label.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-expansion-panel>
      </form>

      
      <form [formGroup]="projectForm" autocomplete="off">
        <mat-divider class="form-divider"></mat-divider>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <h4 class="form-panel-header">
              Project Details<span style="color: #f44336;" *ngIf="hasRequiredField">*</span>
            </h4>
          </mat-expansion-panel-header>
          <mat-form-field class="full-width">
            <input
              matInput
              [required]="true"
              placeholder="Project Name"
              autocomplete="off"
              formControlName="name"
              [value]="project?.name"
            />
          </mat-form-field>
          <tn-form-address
            [editing]="!project?.address"
            [address]="project?.address"
            (addressChange)="onAddressChange($event)"
            (isValid)="addressValidation($event)"
          ></tn-form-address>
          <tn-item-select
            [placeholder]="'Project owner'"
            [$selectableItems]="$allUsers"
            [selectedItem]="project?.projectOwnerItem"
            (itemSelected)="onProjectOwnerSelected($event)"
            (isValid)="userValidation($event)"
          ></tn-item-select>
  
          <tn-multi-select
            [selectedItems]="project?.assignedToItems || []"
            (selectedItemsChange)="setAssignedToItems($event)"
            [$allItems]="$allUsers"
            [placeholder]="'Assigned to'"
          >
          </tn-multi-select>
          <mat-form-field class="full-width">
            <textarea
              matInput
              tnAutosizeTextarea
              autocomplete="off"
              placeholder="Project description"
              formControlName="description"
            ></textarea>
          </mat-form-field>
  
          <div fxLayout="column">
            <div fxFlex="100" fxLayout="row">
              <div fxFlex="calc(50% - 12px)" fxLayoutGap="12px">
                <mat-form-field>
                  <input
                    matInput
                    [matDatepicker]="bidDateEstimatePicker"
                    autocomplete="off"
                    formControlName="bidDateEstimate"
                    placeholder="Bid date estimate"
                    [value]="project?.bidDateEstimate"
                    (click)="bidDateEstimatePicker.open()"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="bidDateEstimatePicker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #bidDateEstimatePicker></mat-datepicker>
                </mat-form-field>
                <mat-form-field>
                  <input
                    matInput
                    [matDatepicker]="bidDateActualPicker"
                    autocomplete="off"
                    formControlName="bidDate"
                    placeholder="Actual bid date"
                    [value]="project?.bidDate"
                    (click)="bidDateActualPicker.open()"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="bidDateActualPicker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #bidDateActualPicker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div fxLayout="column">
            <div fxFlex="100" fxLayout="row" fxLayoutGap="12px">
              <mat-form-field>
                <input
                  matInput
                  [matDatepicker]="closeDateEstimatePicker"
                  autocomplete="off"
                  formControlName="closeDateEstimate"
                  placeholder="Close date estimate"
                  [value]="project?.closeDateEstimate"
                  (click)="closeDateEstimatePicker.open()"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="closeDateEstimatePicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #closeDateEstimatePicker></mat-datepicker>
              </mat-form-field>
              <mat-form-field>
                <input
                  matInput
                  [matDatepicker]="closeDateActualPicker"
                  autocomplete="off"
                  formControlName="closeDate"
                  placeholder="Actual close date"
                  [value]="project?.closeDate"
                  (click)="closeDateActualPicker.open()"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="closeDateActualPicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #closeDateActualPicker></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <div class="project-links" fxLayout="column" fxLayoutGap="8px">
            <div
              class="link-container"
              fxLayout="row"
              fxLayoutAlign="start center"
              *ngFor="let link of project.links"
            >
              <a [href]="link" target="_blank">{{ link }}</a>
              <button mat-icon-button color="accent" (click)="onRemoveLink(link)">
                <mat-icon>clear</mat-icon>
              </button>
            </div>
            <mat-form-field>
              <input
                matInput
                type="text"
                #addLinkInput
                placeholder="Add link"
                (keydown.enter)="addLink(addLinkInput.value); $event.preventDefault()"
                autocomplete="off"
              />
              <button
                mat-icon-button
                color="primary"
                matSuffix
                (click)="addLink(addLinkInput.value)"
              >
                <mat-icon>add_circle</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </mat-expansion-panel>

      </form>



        
      <div
        class="form-actions"
        fxLayout="row-reverse"
        fxLayoutAlign="space-between center"
      >
        <div fxLayout="row-reverse" fxLayoutGap="12px">
          <button
            type="button"
            mat-raised-button
            color="primary"
            (click)="onSubmit()"
            [disabled]="projectForm.invalid"
          >
            Create
          </button>
          <button
            preventDefaultAction
            mat-raised-button
            color="white"
            (click)="onCancel()"
          >
            Cancel
          </button>
        </div>
      </div>

  </mat-accordion>
</div>
