import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Lead } from 'src/app/models/leads/lead';
import { LeadService } from 'src/app/services/leads/lead.service';
import { OpenErrorSnackbar, OpenSnackbar } from 'src/app/store/layout/layout.actions';

@Component({
  selector: 'tn-inquiry',
  templateUrl: './inquiry.component.html',
  styleUrls: ['./inquiry.component.scss']
})
export class InquiryComponent {
  deviceType: string;
  browserType: string;
  osName: string;

  inquiryForm = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    phoneNumber: new FormControl('', Validators.required),
    emailAddress: new FormControl('', Validators.email && Validators.required),
    companyName: new FormControl('', Validators.required),
    sourceInfo: new FormControl('')
  });

  constructor(private leadService: LeadService, public dialog: MatDialog) {
    this.getType();
  }

  getType() {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      this.deviceType = 'Tablet';
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      this.deviceType = 'Mobile';
    }
    this.deviceType = 'Desktop';

    if (ua.match(/chrome|chromium|crios/i)) {
      this.browserType = 'Chrome';
    } else if (ua.match(/firefox|fxios/i)) {
      this.browserType = 'Firefox';
    } else if (ua.match(/safari/i)) {
      this.browserType = 'Safari';
    } else if (ua.match(/opr\//i)) {
      this.browserType = 'Opera';
    } else if (ua.match(/edg/i)) {
      this.browserType = 'Edge';
    } else {
      this.browserType = 'No browser detection';
    }

    if (ua.indexOf('Win') != -1) {
      this.osName = 'Windows';
    } else if (ua.indexOf('Mac') != -1) {
      this.osName = 'MacOS';
    } else if (ua.indexOf('X11') != -1) {
      this.osName = 'UNIX';
    } else if (ua.indexOf('Linux') != -1) {
      this.osName = 'Linux';
    } else {
      this.osName = 'No os detection';
    }
  }

  onSubmit() {
    const observer = {
      error: (err) => {
        this.inquiryForm.reset();
        // new OpenErrorSnackbar({
        //   duration: 5000,
        //   message: "Something went wrong, try again",
        //   opened: true
        // })
        this.dialog.open(ConfirmationDialogComponent, {
          data: {
            header: 'Something went wrong...',
            message: 'Please close this message and try again.'
          }
        });
      },
      complete: () => {
        this.inquiryForm.reset();
        // new OpenSnackbar({
        //   duration: 5000,
        //   message: 'Thank you for submitting your information, we will be following up shortly',
        //   opened: true
        // })
        this.dialog.open(ConfirmationDialogComponent, {
          data: {
            header: 'Success!',
            message:
              'Thank you for submitting your information, it has been sent to Voze and we will be following up shortly.'
          }
        });
      }
    };
    this.inquiryForm.patchValue({
      sourceInfo: this.deviceType + ' - ' + this.browserType + ' - ' + this.osName
    });
    this.leadService.createLead(this.inquiryForm.value as Lead).subscribe(observer);
  }
}

@Component({
  selector: 'tn-confirmation-dialog',
  templateUrl: 'confirmation-dialog.component.html',
  styleUrls: ['./inquiry.component.scss']
})
export class ConfirmationDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}
