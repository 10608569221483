import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import {
  LoadGlobalSearch,
  ClearSearchTerm
} from 'src/app/store/global-search/global-search.actions';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { GlobalSearchState } from 'src/app/store/global-search/global-search.reducer';
import { CloseLeftDrawer } from 'src/app/store/layout/layout.actions';

@Component({
  selector: 'tn-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss']
})
export class SearchbarComponent implements OnInit, OnDestroy {
  searchBarPlaceHolder = 'Search companies, contacts, and call notes';
  searchTerm: string;
  searchLoading: boolean;
  searchStateSub: Subscription;
  typingTimer: NodeJS.Timer;
  inputDelay = 750;
  searchInput = new FormControl('');
  searchInputSub: Subscription;

  constructor(private store: Store<RootState>) {}

  ngOnInit() {
    this.searchStateSub = this.store
      .select('globalSearch')
      .subscribe((searchState: GlobalSearchState) => {
        this.searchLoading = searchState.pending;
        if (!searchState.searchTerm) {
          this.searchInput.setValue(null);
        }
      });
    this.searchInputSub = this.searchInput.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(this.inputDelay),
        tap((searchTerm: string) => {
          if (searchTerm === '') {
            this.store.dispatch(new CloseLeftDrawer());
            this.store.dispatch(new ClearSearchTerm());
          }
        }),
        filter((searchTerm: string) => !!searchTerm && /^(?!\s*$).+/.test(searchTerm))
      )
      .subscribe((searchTerm: string) => {
        this.store.dispatch(new LoadGlobalSearch(searchTerm));
      });
  }

  ngOnDestroy() {
    this.searchInputSub.unsubscribe();
    this.searchStateSub.unsubscribe();
  }
}
