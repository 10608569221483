import { Injectable } from "@angular/core";
import { AuthenticationService } from "./authentication.service";
import { Observable } from "rxjs";
import { ClassicDarSettings } from "../models/admin/classicDarSettings";

@Injectable()
export class DailyActivityReportService {
    constructor(private authService: AuthenticationService) { }

    getById(id: number): Observable<ClassicDarSettings> {
        return this.authService.makeNewJpiRequest(`/classicdar/${id}`, null, null, "get").map((darSettings) => {
            return JSON.parse(darSettings);
        });
    }

    update(id: number, settings: ClassicDarSettings): Observable<ClassicDarSettings> {
        return this.authService.makeNewJpiRequest(`/classicdar/${id}`, null, settings, "put").map((darSettings) => {
            return JSON.parse(darSettings);
        });
    }
}
