import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'color-swatch',
  templateUrl: './color-swatch.component.html',
  styleUrls: ['./color-swatch.component.scss']
})
export class ColorSwatchComponent implements OnInit {
  @Input() colorPickerPopup: boolean;
  @Input() container: string;
  @Output() colorChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() colorPickerPopupChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  dotColors: string[] = ["#d50000", "#304ffe", "#00bfa5", "#ffd600", "#d50000", "#2962ff", "#00c853", "#ffab00", "#aa00ff", "#0091ea", "#64dd17", "#ff6d00", "#6200ea", "#00b8d4", "#aeea00", "#dd2c00"];
  window: any = window;

  constructor() { }

  ngOnInit() {
  }

  changeColor(color: string): void {
    this.colorChange.emit(color);
    this.colorPickerPopup = false;
    this.colorPickerPopupChange.emit(this.colorPickerPopup);
  }
}
