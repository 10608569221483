import { Action } from '@ngrx/store';

export enum HelpActionTypes {
  LoadHelp = '[Help] Load Help'
}

export class LoadHelp implements Action {
  readonly type = HelpActionTypes.LoadHelp;
}

export type HelpActions = LoadHelp;
