import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { combineLatest, map, Observable, Subscription, take, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import {
  CloseDrawer,
  CloseLeftDrawer,
  SetLoadingBarVisibility,
  CloseSnackbar,
  CloseErrorSnackbar,
  SetDefaultLandingPage,
  OpenDrawer
} from 'src/app/store/layout/layout.actions';
import {
  Router,
  RouteConfigLoadStart,
  RouteConfigLoadEnd,
  RouterEvent,
  NavigationEnd
} from '@angular/router';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { SnackbarState } from 'src/app/store/layout/layout.reducer';
import { MatDialog } from '@angular/material/dialog';
import { SelectLandingPageDialogueComponent } from '../select-landing-page-dialogue/select-landing-page-dialogue.component';
import { DailyActivityComponent } from '../daily-activity/daily-activity.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'tn-app-view',
  templateUrl: './app-view.component.html',
  styleUrls: ['./app-view.component.scss']
})
export class AppViewComponent implements OnInit {
  $sidenavOpened: Observable<boolean>;
  $drawerOpened: Observable<boolean>;
  $leftDrawerOpened: Observable<boolean>;
  $moduleLoading: Observable<boolean>;
  $snackbarOpened: Observable<SnackbarState>;
  $snackbarErrorOpened: Observable<SnackbarState>;
  snackbarRef: MatSnackBarRef<SimpleSnackBar>;
  snackRefSub: Subscription;
  asyncLoadCount = 0;
  $drawerOpenedSub: Subscription;
  drawerBoolean: boolean;
  $showDarButton: Observable<boolean>;

  constructor(
    private store: Store<RootState>,
    private router: Router,
    private snackBar: MatSnackBar,
    public landingDialog: MatDialog
  ) {}

  ngOnInit() {
    this.store
      .select('layout', 'defaultLandingPage')
      .pipe(take(1))
      .subscribe((landingPage: string) => {
        if (!landingPage) {
          this.openDialog();
        }
      });

    this.$sidenavOpened = this.store.select('layout', 'sidenavOpened');
    this.$drawerOpened = this.store.select('layout', 'drawerOpened');
    this.$showDarButton = this.store
      .select('auth', 'data', 'jpi', 'user', 'agencyId')
      .pipe(
        map((id) => {
          return !environment.production || id === 10;
        })
      );
    this.$drawerOpenedSub = this.$drawerOpened.subscribe((state) => {
      this.drawerBoolean = state;
    });
    this.$leftDrawerOpened = this.store.select('layout', 'leftDrawerOpened');
    this.$moduleLoading = this.store.select('layout', 'loadingBarVisible');
    this.$snackbarOpened = this.store.select('layout', 'snackbar');
    this.$snackbarOpened.subscribe((snackbar: SnackbarState) => {
      if (snackbar.opened) {
        if (!!this.snackRefSub) {
          this.snackRefSub.unsubscribe();
        }
        this.snackbarRef = this.snackBar.open(snackbar.message, 'DISMISS', {
          duration: snackbar.duration,
          panelClass: 'normal-snackbar'
        });
        this.snackRefSub = this.snackbarRef.afterDismissed().subscribe(() => {
          this.store.dispatch(new CloseSnackbar());
        });
      }
    });

    this.$snackbarErrorOpened = this.store.select('layout', 'errorSnackbar');
    this.$snackbarErrorOpened.subscribe((snackbar: SnackbarState) => {
      if (snackbar.opened) {
        if (!!this.snackRefSub) {
          this.snackRefSub.unsubscribe();
        }
        this.snackbarRef = this.snackBar.open(snackbar.message, 'DISMISS', {
          duration: snackbar.duration,
          panelClass: 'error-snackbar'
        });
        this.snackRefSub = this.snackbarRef.afterDismissed().subscribe(() => {
          this.store.dispatch(new CloseErrorSnackbar());
        });
      }
    });

    this.router.events.subscribe((event: RouterEvent): void => {
      if (event instanceof RouteConfigLoadStart) {
        this.asyncLoadCount++;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.asyncLoadCount--;
      }

      if (!!this.asyncLoadCount) {
        this.store.dispatch(new SetLoadingBarVisibility(true));
      } else if (!this.asyncLoadCount && event instanceof NavigationEnd) {
        this.store.dispatch(new SetLoadingBarVisibility(false));
      }
    });
  }

  openDailyActivityDrawer(): void {
    this.store.dispatch(new OpenDrawer({ component: DailyActivityComponent }));
  }

  dispatchDrawerClosed() {
    this.store.dispatch(new CloseDrawer());
  }
  dispatchLeftDrawerClosed() {
    this.store.dispatch(new CloseLeftDrawer());
  }

  openDialog() {
    const dialogRef = this.landingDialog.open(SelectLandingPageDialogueComponent);

    dialogRef.afterClosed().subscribe((result) => {
      this.store.dispatch(new SetDefaultLandingPage(result));
    });
  }
}
