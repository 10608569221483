import { MemoizedSelector, createSelector } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import { Category } from 'src/app/models/admin/files/category';
import { selectAllCategories } from './all-categories-as-array.selector';

export const selectedCategoryId = (state: RootState) =>
  state.fileCategories.selectedCategoryId;

export const selectSelectedCategory: MemoizedSelector<
  RootState,
  Category
> = createSelector(
  selectAllCategories,
  selectedCategoryId,
  (categoryIndividualMap: { [id: number]: Category }, categoryId: number): Category => {
    let category: Category;
    if (!!categoryId) {
      const id = categoryId.toString();
      category = {
        ...categoryIndividualMap[id]
      };
    }
    return category;
  }
);
