<div class="rep-form">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h3 [style.marginTop]="'0'" [style.marginBottom]="'10px'">
      Assigned Reps and Territories
    </h3>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="column">
      <mat-form-field>
        <span class="input-icon" matPrefix> <mat-icon>search</mat-icon>&nbsp; </span>
        <input
          matInput
          (keyup)="applyFilter($event.target.value)"
          autocomplete="off"
          placeholder="Search sales reps"
          #filterStr
        />
      </mat-form-field>
    </div>
    <div fxLayoutAlign="end center" fxLayoutGap="6px">
      <span class="company-info"><b>Sales & Commissions</b></span
      ><mat-icon
        [style.fontSize]="'16px'"
        [style.height]="'16px'"
        [style.width]="'16px'"
        [style.color]="'#555555'"
        matTooltip="When checked, the user will be associated with future sales for this company, and if applicable, commissions."
      >
        info
      </mat-icon>
    </div>
  </div>
  <div fxLayoutGap="12px" fxLayout="column">
    <div *ngFor="let territory of filteredTerritories">
      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start end">
        <h4 [style.marginTop]="'0'" [style.marginBottom]="'0'">
          {{ territory.name }}
        </h4>
      </div>
      <div
        *ngFor="let user of territory.users"
        fxLayout="row"
        fxLayoutAlign="space-between start"
        [style.marginLeft]="'8px'"
      >
        <div>{{ user.name }}</div>
        <div *ngIf="($permissions | async)?.salesCommissions">
          <mat-checkbox
            [checked]="user.associateSales"
            (change)="onChange(user, $event)"
          ></mat-checkbox>
        </div>
      </div>
      <div
        [style.marginLeft]="'8px'"
        [style.color]="'#999'"
        *ngIf="territory.users?.length === 0 && !filterStr.value"
      >
        There are no reps in this territory
      </div>
    </div>
  </div>
  <div>
    <mat-progress-bar *ngIf="$pending | async" mode="indeterminate"></mat-progress-bar>
  </div>
</div>
