import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { concatMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { WhatsNewActionTypes, WhatsNewActions } from './whats-new.actions';

@Injectable()
export class WhatsNewEffects {
  // loadWhatsNews$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(WhatsNewActionTypes.LoadWhatsNew),
  //     /** An EMPTY observable only emits completion. Replace with your own observable API request */
  //     concatMap(() => EMPTY)
  //   )
  // );

  constructor(private actions$: Actions<WhatsNewActions>) {}
}
