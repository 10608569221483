import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import {
  LoadTutorialsFailure,
  LoadTutorialsSuccess,
  TutorialsActionTypes,
  TutorialsActions
} from './tutorials.actions';

@Injectable()
export class TutorialsEffects {
  loadTutorials$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TutorialsActionTypes.LoadTutorials),
      concatMap(() =>
        /** An EMPTY observable only emits completion. Replace with your own observable API request */
        EMPTY.pipe(
          map(data => new LoadTutorialsSuccess({ data })),
          catchError(error => of(new LoadTutorialsFailure({ error })))
        )
      )
    )
  );

  constructor(private actions$: Actions<TutorialsActions>) {}
}
