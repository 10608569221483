import { DomSanitizer } from '@angular/platform-browser';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { CallNotesService } from 'src/app/services/call-notes/call-notes.service';

@Component({
  selector: 'tn-audio-listen',
  templateUrl: './audio-listen.component.html',
  styleUrls: ['./audio-listen.component.scss']
})
export class AudioListenComponent implements OnInit, OnDestroy {
  @Input() callNote: any;
  currentFile;
  audioSub$: Subscription;

  constructor(
    private callNoteService: CallNotesService,
    private sanitzer: DomSanitizer
  ) {}

  ngOnInit() {
    this.audioSub$ = this.callNoteService
      .getAudioForCallNote(this.callNote.id)
      .subscribe((audio: any) => {
        this.currentFile = this.sanitzer.bypassSecurityTrustUrl(
          window.URL.createObjectURL(audio)
        );
      });
  }

  ngOnDestroy() {
    this.audioSub$.unsubscribe();
  }
}
