<mat-form-field [style.width]="width" [style.padding]="padding">
  <input
    type="text"
    [placeholder]="placeholder"
    matInput
    autocomplete="off"
    #itemSearchInput
    [formControl]="itemInputCtrl"
    [required]="isRequired"
    [matAutocomplete]="auto"
  />
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayFn"
    (optionSelected)="onItemSelected($event)"
  >
    <mat-option
      *ngFor="let option of $filteredItems | async"
      [value]="option"
      fxLayout="row"
    >
      <div>
        {{ option.name }}
        <div *ngIf="optionDetails" class="small-grey-text">
          {{ formatOptionDetails(option) }}
        </div>
      </div>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
