import { MileageActions, MileageActionTypes } from './mileage.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { Mileage } from '../../models/activities/mileage';
import { GenericMap } from 'src/app/models/generic-map';
import format from 'date-fns/format';
import subtract from 'date-fns/sub';
import { endOfDay, startOfDay } from 'date-fns';

export interface MileageState {
  pending: boolean;
  error: HttpErrorResponse;
  data: GenericMap<Mileage>;
  queryParams: {
    daterange: {
      begin: string;
      end: string;
    };
  };
  selectedMileageId: number;
}

export const initialState: MileageState = {
  pending: false,
  error: undefined,
  data: {},
  queryParams: {
    daterange: {
      begin: format(
        subtract(startOfDay(new Date()), {
          months: 3
        }),
        'MM/dd/yyyy'
      ),
      end: format(endOfDay(new Date()), 'MM/dd/yyyy')
    }
  },
  selectedMileageId: undefined
};

export function mileageReducer(
  state = initialState,
  action: MileageActions
): MileageState {
  switch (action.type) {
    case MileageActionTypes.SelectMileageId:
      return {
        ...state,
        selectedMileageId: action.payload
      };

    case MileageActionTypes.ClearMileageId:
      return {
        ...state,
        selectedMileageId: undefined
      };

    case MileageActionTypes.LoadMileage:
      return {
        ...state,
        pending: true
      };

    case MileageActionTypes.LoadMileageSuccess:
      return {
        ...state,
        error: undefined,
        pending: false,
        data: action.payload
      };

    case MileageActionTypes.LoadMileageFailure:
      return {
        ...state,
        pending: false,
        data: {},
        error: action.payload
      };

    case MileageActionTypes.AddMileage:
      return {
        ...state,
        pending: true
      };

    case MileageActionTypes.AddMileageSuccess:
      return {
        ...state,
        pending: false
      };

    case MileageActionTypes.AddMileageFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case MileageActionTypes.UpdateMileage:
      return {
        ...state,
        pending: true
      };

    case MileageActionTypes.UpdateMileageSuccess:
      return {
        ...state,
        pending: false
      };

    case MileageActionTypes.UpdateMileageFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case MileageActionTypes.DeleteMileage:
      return {
        ...state,
        pending: true
      };

    case MileageActionTypes.DeleteMileageSuccess:
      return {
        ...state,
        pending: false,
        selectedMileageId: undefined
      };

    case MileageActionTypes.DeleteMileageFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case MileageActionTypes.SetDateRange:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          daterange: action.payload
        }
      };

    default:
      return state;
  }
}
