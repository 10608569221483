import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {
  transform(phone: string) {
    let newPhone: string;
    if (!!phone && phone.length) {
      phone = phone.trim();
      if (phone.length === 10) {
        let format = '(xxx) xxx-xxxx';
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < phone.length; i++) {
          format = format.replace('x', phone[i]);
        }
        newPhone = format;
      } else if (phone.length === 11) {
        let format = 'x (xxx) xxx-xxxx';
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < phone.length; i++) {
          format = format.replace('x', phone[i]);
        }
        newPhone = format;
      } else if (phone.length === 7) {
        let format = 'xxx-xxxx';
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < phone.length; i++) {
          format = format.replace('x', phone[i]);
        }
        newPhone = format;
      } else {
        newPhone = phone;
      }
      return newPhone;
    } else {
      return '--';
    }
  }
}
