import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import {
  LoadBillingFailure,
  LoadBillingSuccess,
  BillingActionTypes,
  BillingActions
} from './billing.actions';

@Injectable()
export class BillingEffects {
  loadBilling$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingActionTypes.LoadBilling),
      concatMap(() =>
        /** An EMPTY observable only emits completion. Replace with your own observable API request */
        EMPTY.pipe(
          map(data => new LoadBillingSuccess({ data })),
          catchError(error => of(new LoadBillingFailure({ error })))
        )
      )
    )
  );

  constructor(private actions$: Actions<BillingActions>) {}
}
