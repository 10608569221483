<div class="add-expense-type-form">
  <form [formGroup]="expenseTypeForm" class="expense-form">
    <mat-form-field class="example-full-width">
      <mat-label>Name</mat-label>
      <input
        [value]="currentExpenseType?.type"
        formControlName="type"
        matInput
        type="string"
      />
    </mat-form-field>

    <div fxLayout="row-reverse" fxLayoutAlign="space-between center">
      <div fxLayout="row-reverse" fxLayoutGap="12px">
        <button
          *ngIf="!currentExpenseType?.id"
          mat-raised-button
          mat-button
          color="primary"
          class="button-container"
          (click)="save()"
          [disabled]="expenseTypeForm.pristine || expenseTypeForm.invalid"
        >
          Save
        </button>
        <button
          *ngIf="currentExpenseType?.id"
          mat-raised-button
          mat-button
          color="primary"
          class="button-container"
          (click)="update()"
          [disabled]="expenseTypeForm.pristine || expenseTypeForm.invalid"
        >
          Save
        </button>
        <button
          preventDefaultAction
          class="button-container"
          mat-raised-button
          mat-button
          color="white"
          (click)="cancel()"
        >
          Cancel
        </button>
      </div>
      <div>
        <button
          preventDefaultAction
          *ngIf="currentExpenseType?.id"
          mat-raised-button
          mat-button
          color="warn"
          (click)="delete()"
        >
          Delete
        </button>
      </div>
    </div>
  </form>
</div>
