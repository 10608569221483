import { MemoizedSelector, createSelector } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import { ItemName } from 'src/app/models/Opportunity/itemName';

export const selectAllItemNames = (state: RootState) => state.itemNames.data;

export const selectItemNamesAsArray: MemoizedSelector<
  RootState,
  ItemName[]
> = createSelector(
  selectAllItemNames,
  (itemNamesMap: { [id: number]: ItemName }): ItemName[] => {
    return Object.values(itemNamesMap);
  }
);
