import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { map, mergeMap, catchError, switchMap, withLatestFrom } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import {
  OpportunityLabelsIndividualActionTypes,
  OpportunityLabelsIndividualActions,
  LoadOpportunityLabelsIndividuals,
  LoadOpportunityLabelsIndividualsFailure,
  LoadOpportunityLabelsIndividualsSuccess,
  CreateOpportunityLabelsIndividual,
  CreateOpportunityLabelsIndividualSuccess,
  CreateOpportunityLabelsIndividualFailure,
  UpdateOpportunityLabelsIndividual,
  UpdateOpportunityLabelsIndividualSuccess,
  UpdateOpportunityLabelsIndividualFailure,
  DeleteOpportunityLabelsIndividual,
  DeleteOpportunityLabelsIndividualSuccess,
  DeleteOpportunityLabelsIndividualFailure,
  SetOppLabelListOrder,
  BulkUpdateOpportunityLabels,
  BulkUpdateOpportunityLabelsSuccess,
  BulkUpdateOpportunityLabelsFailure
} from './opportunity-labels-individual.actions';
import { OpportunityLabelsIndividualService } from 'src/app/services/system-settings/opportunities/opportunity-labels-individual.service';
import { Action, Store } from '@ngrx/store';
import { Label } from 'src/app/models/label';
import { convertToMap } from 'src/app/utils/convertToMap';
import { HttpErrorResponse } from '@angular/common/http';
import { LoadOpportunityLabels } from '../opportunity-labels/opportunity-labels/opportunity-labels.actions';
import { selectOpportunityLabelsIndividualAsArray } from '../opportunity-labels/selectors/all-opportunityLabelsIndividual-as-array.selector';
import { isEqualObjects } from 'src/app/utils/isEqualObjects';
import { RootState } from '../../store.reducer';

@Injectable()
export class OpportunityLabelsIndividualEffects {
  LoadOpportunityLabelsIndividual$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunityLabelsIndividualActionTypes.LoadOpportunityLabelsIndividuals),
      map((action: LoadOpportunityLabelsIndividuals) => action.payload),
      mergeMap((groupId: number) =>
        this.opportunityLabelsIndividualService.getAll(groupId).pipe(
          map((opportunityLabelsIndividual: Label[]) => {
            return new LoadOpportunityLabelsIndividualsSuccess(
              convertToMap(opportunityLabelsIndividual, 'id')
            );
          }),
          catchError((error: HttpErrorResponse) =>
            of(new LoadOpportunityLabelsIndividualsFailure(error))
          )
        )
      )
    )
  );

  createOpportunityLabelsIndividual$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunityLabelsIndividualActionTypes.CreateOpportunityLabelsIndividual),
      map((action: CreateOpportunityLabelsIndividual) => action.payload),
      mergeMap((action: { label: Label; groupId: number }) => {
        return this.opportunityLabelsIndividualService
          .create(action.label, action.groupId)
          .pipe(
            switchMap((newOpportunityLabelIndividual: Label) => [
              new LoadOpportunityLabels(),
              new CreateOpportunityLabelsIndividualSuccess(newOpportunityLabelIndividual)
            ]),
            catchError((error: HttpErrorResponse) =>
              of(new CreateOpportunityLabelsIndividualFailure(error))
            )
          );
      })
    )
  );

  updateOpportunityLabelsIndividual$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunityLabelsIndividualActionTypes.UpdateOpportunityLabelsIndividual),
      map((action: UpdateOpportunityLabelsIndividual) => action.payload),
      mergeMap((action: { label: Label; groupId: number }) => {
        return this.opportunityLabelsIndividualService
          .update(action.label, action.groupId)
          .pipe(
            switchMap((updatedOpportunityLabelIndividual: Label) => [
              new LoadOpportunityLabels(),
              new UpdateOpportunityLabelsIndividualSuccess(
                updatedOpportunityLabelIndividual
              )
            ]),
            catchError((error: HttpErrorResponse) =>
              of(new UpdateOpportunityLabelsIndividualFailure(error))
            )
          );
      })
    )
  );

  deleteOpportunityLabelsIndividual$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunityLabelsIndividualActionTypes.DeleteOpportunityLabelsIndividual),
      map((action: DeleteOpportunityLabelsIndividual) => action.payload),
      mergeMap((action: { groupId: number; id: number }) =>
        this.opportunityLabelsIndividualService.delete(action.groupId, action.id).pipe(
          switchMap(() => [
            new LoadOpportunityLabels(),
            new DeleteOpportunityLabelsIndividualSuccess(action)
          ]),
          catchError((error: HttpErrorResponse) =>
            of(new DeleteOpportunityLabelsIndividualFailure(error))
          )
        )
      )
    )
  );

  bulkUpdateOpportunityLabels$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunityLabelsIndividualActionTypes.BulkUpdateOpportunityLabels),
      map((action: BulkUpdateOpportunityLabels) => action.payload),
      withLatestFrom(this.store$.select(selectOpportunityLabelsIndividualAsArray)),
      mergeMap(([editedLabels, stateLabels]: [Label[], Label[]]) => {
        const unchangedLabels = [];
        const updatingLabels = editedLabels.reduce((acc: Label[], uLG: Label) => {
          const sLG = stateLabels.find((stateLG) => stateLG.id === uLG.id);
          if (!isEqualObjects(sLG, uLG)) {
            acc.push(uLG);
          } else {
            unchangedLabels.push(uLG);
          }
          return acc;
        }, []);
        return forkJoin([
          ...updatingLabels.map((lg: Label) =>
            this.opportunityLabelsIndividualService.update(lg, lg.groupId)
          ),
          ...unchangedLabels.map((lg: Label) => of(lg))
        ]).pipe(
          map(
            (res: Label[]) =>
              new BulkUpdateOpportunityLabelsSuccess(convertToMap(res, 'id'))
          ),
          catchError((error: HttpErrorResponse) =>
            of(new BulkUpdateOpportunityLabelsFailure(error))
          )
        );
      })
    )
  );

  setOppLabelListOrder$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunityLabelsIndividualActionTypes.SetOppLabelListOrder),
      map((action: SetOppLabelListOrder) => action.payload),
      mergeMap((payload: { groupId: number; labels: Label[] }) =>
        this.opportunityLabelsIndividualService
          .setLabelListOrder(payload.groupId, payload.labels)
          .pipe(
            switchMap((reorderedGroups) => [
              new LoadOpportunityLabels(),
              new LoadOpportunityLabelsIndividualsSuccess(
                convertToMap(reorderedGroups, 'id')
              )
            ]),
            catchError((error: HttpErrorResponse) =>
              of(new LoadOpportunityLabelsIndividualsFailure(error))
            )
          )
      )
    )
  );

  constructor(
    private opportunityLabelsIndividualService: OpportunityLabelsIndividualService,
    private store$: Store<RootState>,
    private actions$: Actions<OpportunityLabelsIndividualActions>
  ) {}
}
