import { L } from '@angular/cdk/keycodes';
import { MemoizedSelector, createSelector } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import { LabelGroup } from '../../../../models/label-group';

export const selectAllOpportunityLabels = (state: RootState) =>
  state.opportunityLabels.data;

export const selectOpportunityLabelsAsArray: MemoizedSelector<RootState, LabelGroup[]> =
  createSelector(
    selectAllOpportunityLabels,
    (opportunityLabelsMap: { [id: number]: LabelGroup }): LabelGroup[] => {
      const sortedGroups = Object.values(opportunityLabelsMap)
        .filter((lg) => !lg.deleted)
        .sort((a, b) => {
          const textA = a.listOrder;
          const textB = b.listOrder;
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        })
        .map((labelGroup: LabelGroup) => {
          return {
            ...labelGroup,
            labels: labelGroup.labels.sort((a, b) => {
              const textA = a.listOrder;
              const textB = b.listOrder;
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            })
          };
        });
      return sortedGroups;
    }
  );
