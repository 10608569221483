import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppV2RoutingModule } from './app-v2-routing.module';
import { AppV2Component } from './app-v2.component';
import { ErrorComponent } from './error/error.component';
// import { ForgotPasswordComponent } from './login/forgotPassword.component';
import { LoginComponent } from './login/login.component';
// import { ResetPasswordComponent } from './login/resetPassword.component';
import { NavigationComponent } from './navigation/navigation.component';
import { SharedModule } from './shared/shared.module';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { StoreModule } from '@ngrx/store';
import { authReducer } from '../store/auth/auth.reducer';
import { callNotesReducer } from '../store/call-notes/call-notes.reducer';
import { layoutReducer } from '../store/layout/layout.reducer';
import { ToolbarModule } from './toolbar/toolbar.module';

@NgModule({
  declarations: [AppV2Component, NavigationComponent, LoginComponent, ErrorComponent],
  imports: [
    AppV2RoutingModule,
    SharedModule,
    HttpClientModule,
    ToolbarModule,
    StoreModule.forFeature('auth', authReducer),
    StoreModule.forFeature('callNotes', callNotesReducer),
    StoreModule.forFeature('layout', layoutReducer)
  ],
  exports: [ToolbarComponent, AppV2Component]
})
export class AppV2Module {
  constructor() {}
}
