<form [formGroup]="customFieldForm" class="custom-field-form">
  <div>
    <mat-form-field fxFlex>
      <mat-label>Name</mat-label>
      <input
        formControlName="name"
        matInput
        [value]="currentCustomField?.name"
        type="text"
      />
    </mat-form-field>
  </div>
  <div>
    <mat-form-field fxFlex>
      <mat-label>Type</mat-label>
      <mat-select
        formControlName="fieldType"
        matInput
        [value]="currentCustomField?.fieldType"
        [compareWith]="compareObjects"
      >
        <mat-option *ngFor="let type of fieldTypes" [value]="type">{{
          type.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div fxLayout="row-reverse" fxLayoutAlign="space-between center">
    <div fxLayout="row-reverse">
      <button
        type="button"
        mat-raised-button
        color="primary"
        mat-button
        [disabled]="customFieldForm.pristine || customFieldForm.invalid"
        (click)="saveButtonClicked()"
      >
        Save
      </button>
      <button
        preventDefaultAction
        class="button-container"
        mat-raised-button
        color="white"
        mat-button
        (click)="cancelButtonClicked()"
      >
        Cancel
      </button>
    </div>
    <button
      *ngIf="currentCustomField?.id"
      preventDefaultAction
      mat-raised-button
      color="accent"
      mat-button
      (click)="deleteButtonClicked()"
    >
      Delete
    </button>
  </div>
</form>
