import { BulkOperationsService } from './../../../shared/services/bulk-operations.service';
import { CompanyLabelGroup } from './../../../shared/models/admin/company-labels/company-label-group';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent, DxListComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import isEqual from 'lodash.isequal';
import uniqBy from 'lodash.uniqby';
import camelCase from 'lodash.camelcase';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { saveAs } from 'file-saver';
import { RootState } from 'src/app/store/store.reducer';
import { Store } from '@ngrx/store';
import { CustomFieldV2 } from '../../../shared/models/admin/custom-fields/custom-field-V2';
import { SectionV2 } from '../../../shared/models/admin/custom-fields/section-V2';
import { Feature } from '../../../shared/models/admin/feature';
import {
  Territory,
  TerritoryWReps
} from '../../../shared/models/admin/territories/territory';
import { TerritoryV2 } from '../../../shared/models/admin/territories/territory-V2';
import { UserV2 } from '../../../shared/models/admin/users/user-V2';
import { BulkEditRequest } from '../../../shared/models/companies/bulk-edit-request';
import { CompanyList } from '../../../shared/models/companies/company-list';
import { CompanyV2 } from '../../../shared/models/companies/company-V2';
import { ItemFilter } from '../../../shared/models/filters/itemFilter';
import { ListFilter } from '../../../shared/models/filters/listFilter';
import { GroupLabel, Label, LabelCompany } from '../../../shared/models/label';
import { ToolbarTab } from '../../../shared/models/toolbarTab';
import { CustomFieldService } from '../../../shared/services/admin/custom-field.service';
import { CompanyLabelGroupService } from '../../../shared/services/admin/customization/company-label-group.service';
import { LabelService } from '../../../shared/services/admin/customization/label.service';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { CompanyJPIService } from '../../../shared/services/companies/company-JPI.service';
import { CompanyListColumnsService } from '../../../shared/services/companies/company-list-columns.service';
import { CompanySearchService } from '../../../shared/services/companies/company-search.service';
import { CompanyService } from '../../../shared/services/companies/company.service';
import { ContactService } from '../../../shared/services/contact.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { RegionService } from '../../../shared/services/territories/region.service';
import { ToolbarService } from '../../../shared/services/toolbar.service';
import { CompanyListV2 } from './../../../shared/models/companies/company-list-V2';
import { CompanyListService } from './../../../shared/services/companies/company-list.service';
import { UserService } from '../../../shared/services/user.service';
import { TerritoryRequest } from '../../../shared/models/territory-request';
import { TerritoryService } from '../../../shared/services/territories/territory.service';
import { CompanyLabel } from '../../../shared/models/admin/company-labels/company-label';
import { LabelRequest } from '../../../shared/models/label-request';
import { MergeRequest } from '../../../shared/models/companies/merge-request';
import { FilteredList } from '../../../shared/models/filteredList';
import { ActivatedRoute } from '@angular/router';
import { Company } from 'src/app/app-v2/shared/models/companies/company';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OpenDrawer } from 'src/app/store/layout/layout.actions';
import { CompanyMergeComponent } from './company-merge/company-merge.component';
import { CallNoteV2Service } from 'src/app/app-v2/shared/services/call-notes/call-note-V2.service';
import { CallNote } from 'src/app/models/call-notes/call-note';

@Component({
  templateUrl: './company-list.component.html',
  styleUrls: ['../companies.scss', '../../../shared/styles/popups.scss']
})
export class CompanyListComponent implements OnInit, OnDestroy {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  @ViewChild(DxListComponent) dxList: DxListComponent;
  defaultCompanyColumns =
    '["name","billingAddress.addressLine1","billingAddress.city","billingAddress.state","billingAddress.postCode","phone","createdDate", "accountId"]';
  companyColumnChooser = { text: 'Company Details', items: [] };
  customCompanyColumnChooser = { text: 'Custom Company Fields', items: [] };
  contactColumnChooser = { text: 'Contact Details', items: [] };
  customContactColumnChooser = { text: 'Custom Contact Fields', items: [] };
  columnChooserPopup: boolean;
  editColumnPopup: boolean;
  isMerging: boolean;
  onlyContactColumnsVisible: boolean;
  canMerge: boolean;
  containsContactColumn: boolean;
  listDelete: CompanyList;
  deleteListPopup: boolean;
  saveAsListPopup: boolean;
  existingDuplicatePopup: boolean;
  newList: CompanyListV2;
  duplicateListPopup: boolean;
  scratchListPopup: boolean;
  newCompany: CompanyV2;
  newCompanyCheck: CompanyV2;
  newCompanyPopup: boolean;
  detailedCompanyPopup: boolean;
  renameListPopup: boolean;
  confirmationPopup: boolean;
  companyDetailsExpanded: boolean;
  bulkEditCompanyDetailsPopup: boolean;
  window: any = window;
  shippingAddress1Expanded: boolean;
  billingAddress1Expanded: boolean = false;
  labelsExpanded: boolean = false;
  billingAddress2Expanded: boolean;
  expandAdvancedMenuPopup: boolean;
  advancedExpanded: boolean;
  expandCustomerMenuPopup: boolean;
  expandSaveMenuPopup: boolean;
  expandExportMenuPopup: boolean;
  bulkEditPopup: boolean;
  bulkEditTerritoryPopup: boolean;
  bulkEditPrimarySalesPopup: boolean;
  editCustomFieldPopup: boolean;
  bulkDeletePopup: boolean;
  mergePopup: boolean;
  stepNumber: number = 1;
  numberOfSteps: number;
  isBusy: boolean = false;
  isLoading: boolean;
  searchTermSubscription: Subscription;
  buttonClickSubscription: Subscription;
  showSearchResults: boolean = false;
  searchResults: any[];
  companyDataStore: any;
  filteredCompanyList: CompanyV2[];
  totalCompanies: number = 0;
  selectedItemsCount: number = 0;
  filterButton: any;
  clearFilterButton: any;
  popupVisible: boolean;
  applyFilterButtonOptions: any;
  cancelButtonOptions: any;
  customersMenuExpanded: boolean = false;
  saveExpanded: boolean = false;
  companies: any[];
  companiesAndContacts: any[] = [];
  allCompanies;
  isFavorite: boolean = false;
  territories: Territory[];
  v2Territories: TerritoryV2[];
  territoryActions: any = [
    { Name: 'Move territories', Id: 1 },
    {
      Name: 'Add to territory',
      Id: 2
    },
    { Name: 'Remove from territory', Id: 3 }
  ];
  actionSelected: number;
  deleteConfirmation: string;
  COLUMN_LIST: any[]; // All Comlumns List
  column_options: DataSource; // List for Edit columns pop-up
  selected_columns: any[]; // selected Columns in Edit pop-up
  filteredColumnOptions: any[]; // columns displayed on grid
  selectedSortOption: any;
  sortingColumns: any[] = [
    {
      id: 1,
      value: 'Company (A-Z)',
      field: 'name',
      elasticField: 'name.keyword',
      asc: true
    },
    {
      id: 2,
      value: 'Company (Z-A)',
      field: 'name',
      elasticField: 'name.keyword',
      asc: false
    },
    {
      id: 3,
      value: 'Company City (A-Z)',
      elasticField: 'billingCity.keyword',
      field: 'billingAddress.city',
      asc: true
    },
    {
      id: 4,
      value: 'Company City (Z-A)',
      elasticField: 'billingCity.keyword',
      field: 'billingAddress.city',
      asc: false
    },
    {
      id: 5,
      value: 'Company State / Province / Region (A-Z)',
      elasticField: 'billingState.keyword',
      field: 'billingAddress.state',
      asc: true
    },
    {
      id: 6,
      value: 'Company State / Province / Region (Z-A)',
      elasticField: 'billingState.keyword',
      field: 'billingAddress.state',
      asc: false
    },
    {
      id: 7,
      value: 'Company ZIP / Postal Code (0-9)',
      elasticField: 'billingPostCode.keyword',
      field: 'billingAddress.postCode',
      asc: true
    },
    {
      id: 8,
      value: 'Company ZIP / Postal Code (9-0)',
      elasticField: 'billingPostCode.keyword',
      field: 'billingAddress.postCode',
      asc: false
    },
    {
      id: 9,
      value: 'Created Date (Oldest first)',
      field: 'createdDate',
      elasticField: 'createdDate',
      asc: true
    },
    {
      id: 10,
      value: 'Created Date (Newest first)',
      field: 'createdDate',
      elasticField: 'createdDate',
      asc: false
    },
    {
      id: 11,
      value: 'Account Id (Asc)',
      field: 'accountId',
      elasticField: 'accountId',
      asc: true
    },
    {
      id: 12,
      value: 'Account Id (Desc)',
      field: 'accountId',
      elasticField: 'accountId',
      asc: false
    },
    {
      id: 13,
      value: 'County (A-Z)',
      elasticField: 'billingCounty.keyword',
      field: 'billingAddress.county',
      asc: true
    },
    {
      id: 14,
      value: 'County (Z-A)',
      elasticField: 'billingCounty.keyword',
      field: 'billingAddress.county',
      asc: false
    }
  ];
  sortingContactColumns: any[] = [
    { id: 1, value: 'Company (A-Z)', field: 'name', asc: true },
    { id: 2, value: 'Company (Z-A)', field: 'name', asc: false },
    { id: 3, value: 'Company City (A-Z)', field: 'billingAddress.city', asc: true },
    { id: 4, value: 'Company City (Z-A)', field: 'billingAddress.city', asc: false },
    {
      id: 5,
      value: 'Company State / Province / Region (A-Z)',
      field: 'billingAddress.state',
      asc: true
    },
    {
      id: 6,
      value: 'Company State / Province / Region (Z-A)',
      field: 'billingAddress.state',
      asc: false
    },
    { id: 7, value: 'Company Zip (0-9)', field: 'billingAddress.postCode', asc: true },
    { id: 8, value: 'Company Zip (9-0)', field: 'billingAddress.postCode', asc: false },
    { id: 9, value: 'Created Date (Oldest first)', field: 'createdDate', asc: true },
    { id: 10, value: 'Created Date (Newest first)', field: 'createdDate', asc: false },
    { id: 11, value: 'Account ID', field: 'accountId', asc: true }
  ];
  contactColumns: string[] = [
    'Contact Id',
    'Title',
    'First Name',
    'Last Name',
    'Full Name',
    'Contact Address',
    'Contact Address Line 2',
    'Contact City',
    'Contact State / Province / Region',
    'Contact Postal Code',
    'Contact Home Phone',
    'Contact Primary Phone',
    'Contact Mobile Phone',
    'Contact ext',
    'Email',
    'Birthday',
    'Active Contact',
    'Primary Contact',
    'Commission Split',
    'House Account',
    'Custom Fields'
  ];
  listOptions: any[] = [];
  company1: any;
  company1Check: any;
  company1CompanyDetails: CustomFieldV2[] = [];
  company1CustomSections: SectionV2[] = [];
  company1CustomFields: CustomFieldV2[] = [];
  company2: any;
  company2Check: any;
  company2CompanyDetails: CustomFieldV2[] = [];
  company2CustomSections: SectionV2[] = [];
  company2CustomFields: CustomFieldV2[] = [];
  allContacts: any[];
  numberOfRowsSelected: number = 0;
  company1FieldsSelected: number;
  company2FieldsSelected: number;
  selectedRows: any = [];
  valueChange: boolean = false;
  editTerritoryRepCheckbox: boolean = false;
  editPrimaryRepCheckbox: boolean = false;
  editCompanyDetailsCheckbox: boolean = false;
  editCompanySteps: any[] = [];
  mergedCompany: any;
  companyDetails: CustomFieldV2[];
  company: CompanyV2;
  customSections: SectionV2[];
  customSectionsOriginal: SectionV2[];
  customSection: SectionV2;
  customFields: CustomFieldV2[];
  newCustomFields: CustomFieldV2[];
  mergedCompanyDetails: CustomFieldV2[];
  mergedCustomFields: CustomFieldV2[];
  mergedLabels: GroupLabel[];
  companyDetailsCheck: CustomFieldV2[];
  customFieldsCheck: CustomFieldV2[];
  numberOfContacts: number;
  companiesDeleted: number[];
  regionAgents: TerritoryWReps[];
  bulkEditCompany: Company;
  editPopupIndex: number = 0;
  selectedList: CompanyListV2 = <any>{ filters: [] };
  selectedListCopy: CompanyListV2;
  teamLists: CompanyListV2[];
  favTeamLists: ListFilter[];
  favOtherLists: ListFilter[];
  defaultList: ListFilter[];
  myLists: CompanyListV2[];
  lists: CompanyListV2[];
  allCompaniesFilter: CompanyListV2;
  allContactsFilter: CompanyListV2;
  isContactsList: boolean = false;
  filterValue: any[] = null;
  filters: any;
  visibleColumns: any;
  detailedView: boolean = false;
  territoryTagBoxValues: any[] = [];
  territoryTagBoxNames: any[] = [];
  company1GroupLabels: GroupLabel[];
  company2GroupLabels: GroupLabel[];
  company1CompanyLabel: LabelCompany[];
  company2CompanyLabel: LabelCompany[];
  bulkEditRequest: BulkEditRequest;
  groupLabels: CompanyLabelGroup[];
  labels: CompanyLabel[];
  billingAddressExpanded: boolean = false;
  shippingAddressExpanded: boolean = false;
  websiteRegex = new RegExp(/([\w\.]+\.(?:com|cc|net|ru)[^,\s]*)/);
  standardFieldsRegexTest: boolean = true;
  customFieldsRegexTest: boolean = true;
  dataDisplayed: DataSource;
  companyCustomFields: any[];
  contactWithCustomFields: any[];
  contactFields: CustomFieldV2[];
  numberOfCompanies: number;
  companiesDisplayed: boolean;
  contactsDisplayed: boolean;
  companyTerritories: any[];
  indexesSelected: number[] = [];
  selectedRowKeys: any[];
  companyDictionary: Array<Object> = [];
  totalCompaniesSelected: number = 0;
  rowCompanies: CompanyV2[];
  permissions: Feature;
  bulkEditFeature: boolean;
  bulkDeleteFeature: boolean;
  mergeFeature: boolean;
  exportFeature: boolean;
  canBulkEdit: boolean;
  canBulkDelete: boolean;
  companySubscription: Subscription;
  companyPopup$: boolean;
  contactPopupSubscription: Subscription;
  contactPopup$: boolean;
  callNotePopupSubscription: Subscription;
  followUpPopupSubscription: Subscription;
  callNotePopup$: boolean;
  followUpPopup$: boolean;
  listCompanyId: number;
  listCompaniesDisplayed: any[];
  selectedItems: any[];
  selectedFilterField: any;
  territoriesChanged: string[] = [];
  primarySalesAgentChanged: string[];
  companyDetailsChanged: Object[] = [];
  customSectionsChanged: any[] = [];
  changedPrimaryAgentMailbox: number;
  searchBarPlaceholder: string;
  searchfieldPlaceholder: string;
  expandActionMenuPopup: boolean;
  lastId: string;
  appliedFilters: ItemFilter[] = [];
  TotalRowCount: string;
  selectedColumnCount: number = 0;
  pageSize: number = 25;
  showFilterMenu: boolean = false;
  filterMenuPage: number;
  filterOptions: any[] = [
    { id: 1, value: 'Created Date', field: 'createdDate', type: 'date', filterValue: [] },
    { id: 2, value: 'Company Name', field: 'name', type: 'string', filterValue: [] },
    {
      id: 3,
      value: 'Address',
      field: 'billingAddress.addressLine1',
      elasticField: 'billingAddressLine1',
      type: 'string',
      filterValue: []
    },
    {
      id: 4,
      value: 'City',
      field: 'billingAddress.city',
      elasticField: 'billingCity',
      type: 'string',
      filterValue: []
    },
    {
      id: 5,
      value: 'State / Province / Region',
      field: 'billingAddress.state',
      elasticField: 'billingState',
      type: 'string',
      filterValue: []
    },
    {
      id: 6,
      value: 'ZIP / Postal Code',
      field: 'billingAddress.postCode',
      elasticField: 'billingPostCode',
      type: 'string',
      filterValue: []
    },
    {
      id: 7,
      value: 'Alternate Address',
      field: 'shippingAddress.addressLine1',
      elasticField: 'shippingAddressLine1',
      type: 'string',
      filterValue: []
    },
    {
      id: 8,
      value: 'Alternate City',
      field: 'shippingAddress.city',
      elasticField: 'shippingCity',
      type: 'string',
      filterValue: []
    },
    {
      id: 9,
      value: 'Alternate State / Province / Region',
      field: 'shippingAddress.state',
      elasticField: 'shippingState',
      type: 'string',
      filterValue: []
    },
    {
      id: 10,
      value: 'Alternate ZIP / Postal Code',
      field: 'shippingAddress.postCode',
      elasticField: 'shippingPostCode',
      type: 'string',
      filterValue: []
    },
    { id: 11, value: 'Website', field: 'website', type: 'string', filterValue: [] },
    { id: 12, value: 'Phone', field: 'phone', type: 'string', filterValue: [] },
    { id: 13, value: 'Fax', field: 'fax', type: 'string', filterValue: [] },
    { id: 14, value: 'Telenotes ID', field: 'id', type: 'number', filterValue: [] },
    {
      id: 15,
      value: 'Contact Full Name',
      field: 'contacts.searchField',
      type: 'string',
      filterValue: []
    },
    {
      id: 16,
      value: 'Contact First Name',
      field: 'contacts.firstName',
      type: 'string',
      filterValue: []
    },
    {
      id: 17,
      value: 'Contact Last Name',
      field: 'contacts.lastName',
      type: 'string',
      filterValue: []
    },
    {
      id: 18,
      value: 'Contact Title',
      field: 'contacts.title',
      type: 'string',
      filterValue: []
    },
    {
      id: 19,
      value: 'Contact Email',
      field: 'contacts.email',
      type: 'string',
      filterValue: []
    },
    {
      id: 20,
      value: 'Contact Phone Number',
      field: 'contacts.phone',
      type: 'string',
      filterValue: []
    },
    {
      id: 21,
      value: 'Contact Mobile Phone Number',
      field: 'contacts.mobilePhone',
      type: 'string',
      filterValue: []
    },
    {
      id: 22,
      value: 'Contact Fax Number',
      field: 'contacts.fax',
      type: 'string',
      filterValue: []
    },
    {
      id: 23,
      value: 'Contact Address',
      field: 'contacts.address.addressLine1',
      type: 'string',
      filterValue: []
    },
    {
      id: 24,
      value: 'Contact City',
      field: 'contacts.address.city',
      type: 'string',
      filterValue: []
    },
    {
      id: 25,
      value: 'Contact State / Province / Region',
      field: 'contacts.address.state',
      type: 'string',
      filterValue: []
    },
    {
      id: 26,
      value: 'Contact ZIP / Postal Code',
      field: 'contacts.address.postCode',
      type: 'string',
      filterValue: []
    },
    {
      id: 27,
      value: 'Company Territories',
      field: 'territories.id',
      type: 'list',
      filterValue: []
    },
    {
      id: 28,
      value: 'Account Id',
      field: 'accountId',
      type: 'string',
      filterValue: []
    },
    {
      id: 29,
      value: 'County',
      field: 'billingAddress.county',
      elasticField: 'billingCounty',
      type: 'string',
      filterValue: []
    },
    {
      id: 30,
      value: 'Alternate County',
      field: 'shippingAddress.county',
      elasticField: 'shippingCounty',
      type: 'string',
      filterValue: []
    },
    {
      id: 31,
      value: 'Company Notes Narrative',
      field: 'fields.value',
      type: 'string',
      filterValue: []
    },
    {
      id: 32,
      value: 'Contact Details',
      field: 'contacts.notes',
      type: 'string',
      filterValue: []
    }
  ];
  stringFilterOptions: any[] = [
    { id: 1, value: 'Contains any of' },
    { id: 2, value: 'Contains all of' },
    { id: 3, value: 'Contains none of' },
    { id: 4, value: 'Is empty' },
    { id: 5, value: 'Is not empty' }
  ];
  dateFilterOptions: any[] = [
    { id: 1, value: 'Is equal to' },
    { id: 2, value: 'Is before' },
    { id: 3, value: 'Is after' },
    { id: 4, value: 'Is between' }
  ];
  listFilterOptions: any[] = [
    { id: 1, value: 'Is any of' },
    { id: 2, value: 'Is none of' }
  ];
  numberFilterOptions: any[] = [
    { id: 1, value: 'Greater than' },
    { id: 2, value: 'Is equal to' },
    { id: 3, value: 'Less than' }
  ];
  dataLoaded: boolean = true;
  mergeData: any;
  selectAllCompany1 = { value: '', selected: false };
  selectAllCompany2 = { value: '', selected: false };
  company1SelectedField: number;
  company2SelectedField: number;
  showProfile: boolean = false;
  profileId: number;
  editOptions = [
    { text: 'Edit Territory and Sales rep assignments', value: 1 },
    { text: 'Edit primary sales rep', value: 2 },
    { text: 'Edit company fields', value: 3 },
    { text: 'Edit labels', value: 4 }
  ];
  editOption: any;
  editTerritoryOptions = [
    { text: 'Add territories', value: 1 },
    { text: 'Remove territories', value: 2 },
    { text: 'Move territories', value: 3 }
  ];
  editTerritoryOption: any;
  editLabelOptions = [
    { text: 'Add labels', value: 1 },
    { text: 'Remove labels', value: 2 },
    { text: 'Move labels', value: 3 }
  ];
  editLabelOption: any;
  BulkEditPage = 1;
  currentTerritoryAssignments: TerritoryV2[] = [];
  newPrimaryUser: UserV2;
  primaryUserTerritory: TerritoryV2;
  editFieldsCompany: CompanyV2;
  contactCustomFields: any[];
  addTerritoriesToCompanies: boolean = false;
  labelGroups: CompanyLabelGroup[];
  removeLabelGroups: CompanyLabelGroup[];
  selectedCompanyTerritories: TerritoryV2[];
  selectedCompanyLabels: CompanyLabel[];
  bulkEditSelectedTerritories: TerritoryV2[] = [];
  moveFromTerritories: TerritoryV2[] = [];
  moveToTerritories: TerritoryV2[] = [];
  bulkEditSelectedLabels: CompanyLabel[] = [];
  moveFromLabels: CompanyLabel[] = [];
  moveToLabels: CompanyLabel[] = [];
  bulkEditLabelsAdded: CompanyLabel[];
  moveLabelOption: CompanyLabel;
  moveLabelOptionAdd: CompanyLabelGroup;
  moveLabelOptionRemove: CompanyLabelGroup;
  selectedCompanyIds: number[] = [];
  companySalesReps: UserV2[];
  count: number = 0;
  countRelation: string;
  newCount: boolean = true;
  companyNameParam: string;
  contactNameParam: string;

  constructor(
    private companyService: CompanyService,
    private companyJPIService: CompanyJPIService,
    private userService: UserService,
    private bulkOperationsService: BulkOperationsService,
    private toolbarService: ToolbarService,
    private authService: AuthenticationService,
    private companyListService: CompanyListService,
    private customFieldService: CustomFieldService,
    private regionService: RegionService,
    private notificationService: NotificationService,
    private labelService: LabelService,
    private territoryService: TerritoryService,
    private companySearchService: CompanySearchService,
    private companyLabelGroupService: CompanyLabelGroupService,
    private companyListColumnsService: CompanyListColumnsService,
    private contactService: ContactService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private store: Store<RootState>,
    private callNoteV2Service: CallNoteV2Service
  ) {
    if (localStorage.getItem('companyColumns') === null) {
      this.allCompaniesFilter = <any>{
        id: 0,
        agencyId: 0,
        name: 'All Companies',
        createdDate: new Date(),
        filters: [],
        columns: this.defaultCompanyColumns,
        expanded: false,
        teamList: true,
        deleted: false,
        deletedDate: null,
        createdBy: null,
        companyList: true
      };
    } else {
      let chosenCompanyColumns = localStorage.getItem('companyColumns');
      this.allCompaniesFilter = <any>{
        id: 0,
        agencyId: 0,
        name: 'All Companies',
        createdDate: new Date(),
        filters: [],
        columns: chosenCompanyColumns,
        expanded: false,
        teamList: true,
        deleted: false,
        deletedDate: null,
        createdBy: null,
        companyList: true
      };
    }

    this.allContactsFilter = <any>{
      id: 0,
      agencyId: 0,
      name: 'All Contacts',
      createdDate: new Date(),
      filters: [],
      columns:
        '["name","contactFirstName","contactLastName","contactTitle","contactEmail","contactMobilePhone"]',
      teamList: true,
      expanded: false,
      deleted: false,
      deletedDate: null,
      createdBy: null,
      companyList: true
    };

    this.companySubscription = this.toolbarService.companyAnnounced.subscribe(
      (result) => {
        this.companyPopup$ = result;
      }
    );

    this.companyListService.getAll().then((lists) => {
      this.lists = lists;
      this.teamLists = [this.allCompaniesFilter, this.allContactsFilter].concat(
        lists.filter((list) => list.teamList)
      );
      this.myLists = this.lists.filter((list) => !list.teamList);
    });

    let ds;

    Promise.all([
      this.companyLabelGroupService.getCompanyLabelGroups(), // 0
      contactService.getContactCustomFields(), // 1
      territoryService.getAllFlattened()
    ]).then((response) => {
      this.labelGroups = response[0];
      this.contactCustomFields = response[1];

      this.filterOptions[26].listData = response[2].sort(function (
        territory1: TerritoryV2,
        territory2: TerritoryV2
      ) {
        return territory1.name.localeCompare(territory2.name);
      });

      this.companyListColumnsService.getColumns('editList').subscribe(
        (data) => {
          ds = data;
          for (let i = 0, x = 15; i < this.labelGroups.length; i++, x++) {
            ds.push({
              key: 'Company Fields',
              dataField: `labels.${this.labelGroups[i].id}`,
              caption: this.labelGroups[i].name,
              visible: true,
              enabled: false,
              dataType: 'string',
              id: this.labelGroups[i].id,
              allowSorting: false,
              cellTemplate: 'labelTemplate'
            });
            this.filterOptions.push({
              id: x,
              value: this.labelGroups[i].name,
              field: `labels.id`,
              type: 'list',
              filterValue: [],
              listData: this.labelGroups[i].labels,
              customField: true
            });
          }
        },
        () => {
          this.notificationService.showError(
            `There was an error retrieving the column data`
          );
        },
        () => {
          this.column_options = new DataSource({
            store: ds,
            group: 'key',
            searchOperation: 'contains',
            searchExpr: 'caption'
          });
          let columnsToRemove = [
            'createdBy',
            'modifiedBy',
            'leadsource',
            'referralsource',
            'linkedin',
            'facebook',
            'instagram',
            'twitter',
            'email',
            'emailDomain',
            'contractExpiration',
            'numberofemployees',
            'numberofLocations',
            'annualrevenue',
            'middle',
            'birthday',
            'contactFacebook',
            'contactTwitter',
            'contactLinkedIn',
            'department',
            'lastModified'
          ];
          this.column_options.store()._array = this.column_options
            .store()
            ._array.filter((column) => {
              return !columnsToRemove.includes(column.dataField);
            });
          this.selectedSortOption = this.sortingColumns[0];
          this.activatedRoute.queryParams.subscribe((params) => {
            this.paramsChanged(params);
          });
        }
      );

      this.activatedRoute.queryParams.subscribe((params) => {
        this.paramsChanged(params);
      });
      this.loadDataSource();
    });
  }

  loadDataSource() {
    this.dataDisplayed = new DataSource({
      store: new CustomStore({
        key: 'id',
        load: (loadOptions: any) => {
          this.isContactsList = this.checkIfContactsList();
          const filter = {
            filters: []
          };
          this.activatedRoute.queryParams.subscribe((params) => {
            if (params.globalSearch && params.companyNameAddress) {
              filter.filters = [
                {
                  operand1: 'searchField',
                  operand2: [params[Object.keys(params)[0]]],
                  operator: 'CONTAINS_ALL_OF'
                },
                ...this.appliedFilters
              ].flat(Infinity);
            } else if (params.globalSearch && params.contactName) {
              filter.filters = [
                {
                  operand1: 'contacts.searchField',
                  operand2: [params[Object.keys(params)[0]]],
                  operator: 'CONTAINS_ALL_OF'
                },
                ...this.appliedFilters
              ].flat(Infinity);
            } else filter.filters = this.appliedFilters;
          });
          if (!this.selectedSortOption) {
            this.selectedSortOption = this.sortingColumns[0];
          }
          if (filter.filters.some((filter) => filter.operand1 === 'fields.value')) {
            const cnFilter = {
              filters: filter.filters.filter(
                (filter) => filter.operand1 === 'fields.value'
              )
            };
            filter.filters = filter.filters.filter(
              (filter) => filter.operand1 !== 'fields.value'
            );
            return this.getCallNoteCompaniesIds(
              cnFilter,
              filter,
              loadOptions.skip,
              loadOptions.take
            );
          } else {
            return this.getCompanies(filter, loadOptions.skip, loadOptions.take).then(
              (res) => {
                return res;
              }
            );
          }
        },

        onLoaded: () => {
          this.dataLoaded = true;
          this.getSelectedRows();
        }
      })
    });
  }

  async getCompanies(filter, skip, take) {
    const res = await this.companyJPIService
      .getJPICompaniesFiltered(
        skip,
        take,
        this.selectedSortOption.elasticField,
        this.selectedSortOption.asc,
        filter
      )
      .then((companies: FilteredList) => {
        const contacts = [];
        companies.items = companies.items.sort(
          (company1: CompanyV2, company2: CompanyV2) => {
            switch (this.selectedSortOption.field) {
              case 'name':
                if (this.selectedSortOption.asc) {
                  return company1.name.localeCompare(company2.name);
                } else {
                  return company2.name.localeCompare(company1.name);
                }
                break;
              case 'createdDate':
                let date1: Date = moment(company1.createdDate).toDate();
                let date2: Date = moment(company2.createdDate).toDate();
                if (this.selectedSortOption.asc) {
                  return date1.getTime() - date2.getTime();
                } else {
                  return date2.getTime() - date1.getTime();
                }
                break;
              default:
                let fieldPieces: string[] = this.selectedSortOption.field.split('.');
                let field1 = !company1[fieldPieces[0]]
                  ? ''
                  : !company1[fieldPieces[0]][fieldPieces[1]]
                  ? ''
                  : company1[fieldPieces[0]][fieldPieces[1]];
                let field2 = !company2[fieldPieces[0]]
                  ? ''
                  : !company2[fieldPieces[0]][fieldPieces[1]]
                  ? ''
                  : company2[fieldPieces[0]][fieldPieces[1]];
                if (this.selectedSortOption.asc) {
                  return field1.localeCompare(field2);
                } else {
                  return field2.localeCompare(field1);
                }
                break;
            }
          }
        );
        if (this.isContactsList) {
          companies.items.forEach((company: CompanyV2) => {
            if (company.contacts.length) {
              company.contacts.forEach((contct: any) => {
                contct.contactId = contct.id;
                contct.contactIdAlt = contct.contactId;
                contct.contactAccount = contct.account;
                contct.contactAddress = contct.address;
                contct.contactDeleted = contct.deleted;
                contct.contactEmail = contct.email;
                contct.contactFax = contct.fax;
                contct.contactFirstName = contct.firstName;
                contct.contactLastName = contct.lastName;
                contct.contactMobilePhone = contct.mobilePhone;
                contct.contactPhone = contct.phone;
                contct.contactTitle = contct.title;
                contct.contactNotes = contct.notes;
                contct.contactCreatedDate = contct.createdDate;
                delete contct['id'];
                delete contct['account'];
                delete contct['address'];
                delete contct['deleted'];
                delete contct['email'];
                delete contct['fax'];
                delete contct['firstName'];
                delete contct['lastName'];
                delete contct['mobilePhone'];
                delete contct['phone'];
                delete contct['title'];
                const newRow = Object.assign(contct, company);
                delete newRow['contacts'];
                contacts.push(newRow);
              });
            } else {
              contacts.push(company);
            }
          });
        }
        this.count = companies.count;
        this.countRelation = companies.countRelation;

        return { data: this.isContactsList ? contacts : companies.items };
      });
    return res;
  }

  async getCallNoteCompaniesIds(cnFilter: any, filter: any, skip: number, take: number) {
    const res = await this.callNoteV2Service
      .getCompanyIdsByCallNoteNarrative(cnFilter, 0, 10000)
      .then((uniqueCompanyIds: number[]) => {
        // const companyIds = notes.items.map((note: CallNote) => note.company?.id);
        // const uniqueCompanyIds = Array.from(new Set(companyIds));
        // console.log(uniqueCompanyIds);

        filter.filters.push({
          operand1: 'id',
          operand2: uniqueCompanyIds,
          operator: 'IS_ANY_OF'
        });
        return this.getCompanies(filter, skip, take);
      });
    return res;
  }

  displayCompanyLabels(label): string {
    let labels = '';

    if (!label.data.labels) {
      label.data.labels = [];
    }

    label.data.labels.forEach((companyLabel) => {
      if (label.column.id === companyLabel.groupId) {
        labels += `${companyLabel.name}, `;
      }
    });
    return labels.slice(0, labels.length - 2);
  }

  ngOnInit(): void {
    this.toolbarService.setToolbarVisibility(true);
    this.bulkEditCompany = Object.assign({});
    this.bulkEditRequest = Object.assign({});
    this.mergedCompany = Object.assign({});
    this.company = Object.assign({});
    this.company1CustomFields = [];
    this.company2CustomFields = [];
    this.customFields = [];
    this.mergedCompanyDetails = [];
    this.mergedCustomFields = [];
    this.companyDetailsCheck = [];
    this.customFieldsCheck = [];
    this.companiesDeleted = [];
    this.companyDetailsChanged = [];
    this.primarySalesAgentChanged = [];
    this.rowCompanies = [];
    this.companiesDisplayed = true;
    this.searchBarPlaceholder = 'search companies and contacts';
    this.searchfieldPlaceholder = 'search fields';

    this.contactPopupSubscription = this.toolbarService.contactAnnounced.subscribe(
      (result) => {
        this.contactPopup$ = result;
        if (this.contactPopup$ == false) {
        }
      }
    );

    this.isBusy = true;

    this.companyService.canMapExcedeData().then((result: boolean) => {
      const listTab: ToolbarTab = {
        TabName: 'List',
        TabUrl: '/companies-list/list',
        IsActive: true
      };

      this.toolbarService.addTab(listTab);

      if (result) {
        const procedeTab: ToolbarTab = {
          TabName: 'DMS',
          TabUrl: '/companies-list/dms',
          IsActive: false
        };
        this.toolbarService.addTab(procedeTab);
      }

      this.toolbarService.showSearchBar('Search Voze');
      this.toolbarService.addButton('New');
    });

    this.searchTermSubscription = this.toolbarService.searchTermAnnounced.subscribe(
      (searchTerm) => {
        delete this.searchResults;
        if (searchTerm) {
          this.showSearchResults = true;
          this.companySearchService.toolbarSearch(searchTerm).then((searchResults) => {
            this.searchResults = searchResults;
          });
        } else {
          this.showSearchResults = false;
        }
      }
    );

    this.buttonClickSubscription = this.toolbarService.buttonClickAnnouced.subscribe(
      (button) => {
        this.buttonClicked(button);
      }
    );

    this.callNotePopupSubscription = this.toolbarService.callNoteAnnounced.subscribe(
      (result) => {
        this.callNotePopup$ = result;
      }
    );

    this.followUpPopupSubscription = this.toolbarService.followUpAnnounced.subscribe(
      (result) => {
        this.followUpPopup$ = result;
      }
    );

    this.authService.getFeature(this.authService.modules.Companies).then((result) => {
      this.permissions = result;
      for (const key in this.permissions.Permissions) {
        if (this.permissions.Permissions[key].Id == 43) {
          this.mergeFeature = JSON.parse(this.permissions.Permissions[key].Value);
        } else if (this.permissions.Permissions[key].Id == 48) {
          this.bulkEditFeature = JSON.parse(this.permissions.Permissions[key].Value);
        } else if (this.permissions.Permissions[key].Id == 47) {
          this.bulkDeleteFeature = JSON.parse(this.permissions.Permissions[key].Value);
        } else if (this.permissions.Permissions[key].Id == 36) {
          this.exportFeature = JSON.parse(this.permissions.Permissions[key].Value);
        }
      }
    });

    this.isBusy = false;
  }

  paramsChanged(params: any): void {
    this.contactNameParam = params['contactName'];
    this.companyNameParam = params['companyNameAddress'];

    if (this.companyNameParam) {
      (<any>this.allCompaniesFilter).filters = [
        {
          id: 2,
          value: 'Company Name/Address',
          field: 'searchField',
          type: 'string',
          filterValue: [this.companyNameParam],
          existingField: false,
          selectedFilterOption: { id: 2, value: 'Contains all of' },
          intermediateText: 'contains all of',
          optionId: 1
        }
      ];
    }

    if (this.contactNameParam) {
      (<any>this.allContactsFilter).filters = [
        {
          id: 15,
          value: 'Contact Full Name',
          field: 'contacts.searchField',
          type: 'string',
          filterValue: [this.contactNameParam],
          existingField: false,
          selectedFilterOption: { id: 2, value: 'Contains all of' },
          intermediateText: 'contains all of',
          optionId: 1
        }
      ];
    }

    if (this.contactNameParam) {
      this.selectList(this.allContactsFilter, false);
    } else {
      this.selectList(this.allCompaniesFilter, false);
    }
  }

  checkIfContactsList(): boolean {
    if (this.dataGrid && this.dataGrid.instance) {
      const visibleColumns = this.dataGrid.instance.getVisibleColumns();
      for (let i = 0; i < visibleColumns.length; i++) {
        if ((<any>visibleColumns[i]).key === 'Contact Fields') {
          return true;
        }
      }
    }
    return;
  }

  selectList(list: any, reload: boolean) {
    setTimeout(() => {
      const columns_list = [];
      this.removeAllColumns();
      if (this.dataGrid) {
        this.dataGrid.instance.clearSelection();
      }

      this.column_options.store()._array.forEach((column) => {
        columns_list.push(column);
      });

      if (list.name == 'All Contacts') {
        this.isContactsList = true;
        this.contactsDisplayed = true;
        this.companiesDisplayed = false;
        this.numberOfContacts = 2000;
      } else {
        this.isContactsList = false;
        this.contactsDisplayed = false;
        this.companiesDisplayed = true;
        this.numberOfCompanies = 10542;
      }

      this.selectedList = JSON.parse(JSON.stringify(list));
      this.selectedListCopy = { ...this.selectedList };
      const columns: string[] = JSON.parse(list.columns);
      this.filteredColumnOptions = [];
      columns.forEach((col) => {
        const addColumn = columns_list.filter(function (column) {
          if (column.dataField == col) {
            column.visible = true;
            column.enabled = true;
          }
          return column.dataField == col;
        });

        if (addColumn.length > 0) {
          addColumn[0].visible = true;
          this.filteredColumnOptions.push(addColumn[0]);
        }
      });
      if (typeof this.selectedList.filters === 'string') {
        this.selectedList.filters = JSON.parse(this.selectedList.filters);
      }
      this.selectedList.filters
        ? (this.appliedFilters = <any>this.selectedList.filters)
        : (this.appliedFilters = []);
      this.convertDisplayFilterToAppliedFilter(
        JSON.parse(JSON.stringify(this.selectedList.filters))
      );

      this.expandCustomerMenuPopup = false;

      if (reload) {
        this.sortChanged();
      }
    }, 1100);
  }

  getReps(comp): any[] {
    this.companySalesReps = [];
    comp.territories.forEach((territory) => this.getUsersFromCompany(territory));
    return this.companySalesReps;
  }

  getUsersFromCompany(territory: TerritoryV2) {
    if (territory.users && territory.users.length) {
      this.companySalesReps = this.companySalesReps.concat(territory.users);
    }
    if (territory.subTerritories && territory.subTerritories.length) {
      territory.subTerritories.forEach((subTerritory) =>
        this.getUsersFromCompany(subTerritory)
      );
    }
  }

  searchCompaniesWithoutTerritories() {
    this.newCount = true;
    this.selectedFilterField.intermediateText =
      this.selectedFilterField.selectedFilterOption.value.toLowerCase();
    this.selectedFilterField.optionId = this.selectedFilterField.selectedFilterOption.id;

    if (typeof this.selectedList.filters === 'string') {
      this.selectedList.filters = JSON.parse(this.selectedList.filters);
    }

    if (this.selectedFilterField.existingField) {
      this.selectedList.filters = (<any>this.selectedList).filters.filter(
        (filter) => filter.value != this.selectedFilterField.value
      );
    }

    this.appliedFilters = [];
    (<any>this.selectedList).filters.push(this.selectedFilterField);
    this.convertDisplayFilterToAppliedFilter((<any>this.selectedList).filters);
    this.sortChanged();
    this.filterMenuPage = 1;
    this.showFilterMenu = false;
  }

  selectFilterField(data: any): void {
    this.selectedFilterField = data;
    let fieldExists = false;

    JSON.parse(JSON.stringify(this.selectedList.filters)).forEach((filter) => {
      if (filter.value == data.value) {
        fieldExists = true;
      }
    });

    this.selectedFilterField.existingField = fieldExists;

    switch (data.type) {
      case 'date':
        if (!this.selectedFilterField.existingField) {
          this.selectedFilterField.filterValue = [];
          this.selectedFilterField.selectedFilterOption = this.dateFilterOptions[0];
        } else {
          for (let i = 0; i < this.dateFilterOptions.length; i++) {
            if (
              this.dateFilterOptions[i].id ==
              this.selectedFilterField.selectedFilterOption.id
            ) {
              this.selectedFilterField.selectedFilterOption = this.dateFilterOptions[i];
              break;
            }
          }
        }
        break;
      case 'list':
        this.listFilterOptions = [
          { id: 1, value: 'Is any of' },
          { id: 2, value: 'Is none of' }
        ];
        if (data.id === 27) {
          this.listFilterOptions.push({ id: 3, value: 'Is empty' });
        }
        if (!this.selectedFilterField.existingField) {
          this.selectedFilterField.filterValue = [];
          this.selectedFilterField.selectedFilterOption = this.listFilterOptions[0];
        } else {
          for (let i = 0; i < this.listFilterOptions.length; i++) {
            if (
              this.listFilterOptions[i].id ==
              this.selectedFilterField.selectedFilterOption.id
            ) {
              this.selectedFilterField.selectedFilterOption = this.listFilterOptions[i];
              break;
            }
          }
        }
        break;
      case 'string':
        if (this.selectedFilterField.id === 31) {
          this.stringFilterOptions = [
            { id: 0, value: 'Contains exact phrase' },
            { id: 1, value: 'Contains all of' },
            { id: 2, value: 'Contains any of' },
            { id: 3, value: 'Contains none of' },
            { id: 4, value: 'Is empty' },
            { id: 5, value: 'Is not empty' }
          ];
        } else if (this.selectedFilterField.id === 28) {
          this.stringFilterOptions = [
            { id: 6, value: 'Contains' },
            { id: 1, value: 'Contains all of' },
            { id: 2, value: 'Contains any of' },
            { id: 3, value: 'Contains none of' },
            { id: 4, value: 'Is empty' },
            { id: 5, value: 'Is not empty' }
          ];
        } else {
          this.stringFilterOptions = [
            { id: 1, value: 'Contains all of' },
            { id: 2, value: 'Contains any of' },
            { id: 3, value: 'Contains none of' },
            { id: 4, value: 'Is empty' },
            { id: 5, value: 'Is not empty' }
          ];
        }
        if (!this.selectedFilterField.existingField) {
          this.selectedFilterField.filterValue = [];
          this.selectedFilterField.selectedFilterOption = this.stringFilterOptions[0];
        } else {
          for (let i = 0; i < this.stringFilterOptions.length; i++) {
            if (
              this.stringFilterOptions[i].id ==
              this.selectedFilterField.selectedFilterOption.id
            ) {
              this.selectedFilterField.selectedFilterOption = this.stringFilterOptions[i];
              break;
            }
          }
        }
        break;
      case 'number':
        if (!this.selectedFilterField.existingField) {
          this.selectedFilterField.filterValue = [];
          this.selectedFilterField.selectedFilterOption = this.numberFilterOptions[0];
        } else {
          for (let i = 0; i < this.numberFilterOptions.length; i++) {
            if (
              this.numberFilterOptions[i].id ==
              this.selectedFilterField.selectedFilterOption.id
            ) {
              this.selectedFilterField.selectedFilterOption = this.numberFilterOptions[i];
              break;
            }
          }
        }
        break;
    }

    this.filterMenuPage = 2;
    this.showFilterMenu = true;
  }

  boolValueChanged(event: any): void {
    this.selectedFilterField.filterValue = [event.value];
  }

  stringFilterOptionChanged(event: any): void {
    switch (event.value.id) {
      case 4:
        this.selectedFilterField.filterValue = ['is empty'];
        break;
      case 5:
        this.selectedFilterField.filterValue = ['is not empty'];
        break;
    }
  }

  applyFilter(): void {
    this.newCount = true;
    switch (this.selectedFilterField.type) {
      case 'date':
        this.selectedFilterField.intermediateText =
          this.selectedFilterField.selectedFilterOption.value.toLowerCase();
        this.selectedFilterField.optionId =
          this.selectedFilterField.selectedFilterOption.id;
        break;
      case 'bool':
        this.selectedFilterField.intermediateText = 'is';
        break;
      case 'list':
        this.selectedFilterField.intermediateText =
          this.selectedFilterField.selectedFilterOption.value.toLowerCase();
        this.selectedFilterField.optionId =
          this.selectedFilterField.selectedFilterOption.id;
        break;
      case 'string':
        switch (this.selectedFilterField.selectedFilterOption.id) {
          case 4:
          case 5:
            this.selectedFilterField.intermediateText = '';
            break;
          default:
            this.selectedFilterField.intermediateText =
              this.selectedFilterField.selectedFilterOption.value.toLowerCase();
            break;
        }
        this.selectedFilterField.optionId =
          this.selectedFilterField.selectedFilterOption.id;
        break;
      case 'number':
        this.selectedFilterField.optionId =
          this.selectedFilterField.selectedFilterOption.id;
        this.selectedFilterField.intermediateText =
          this.selectedFilterField.selectedFilterOption.value.toLowerCase();
        break;
    }

    if (typeof this.selectedList.filters === 'string') {
      this.selectedList.filters = JSON.parse(this.selectedList.filters);
    }

    if (this.selectedFilterField.existingField) {
      this.selectedList.filters = (<any>this.selectedList).filters.filter(
        (filter) => filter.value != this.selectedFilterField.value
      );
    }

    this.appliedFilters = [];
    (<any>this.selectedList).filters.push(this.selectedFilterField);
    this.convertDisplayFilterToAppliedFilter((<any>this.selectedList).filters);
    this.sortChanged();
    this.filterMenuPage = 1;
    this.showFilterMenu = false;
  }

  canManipulateList(list: CompanyListV2): boolean {
    const currentUserId = parseInt(
      this.authService.getCookie(this.authService.cookieNames.AgentId)
    );
    return currentUserId === list.createdBy.id;
  }

  convertDisplayFilterToAppliedFilter(filters: any[]): void {
    if (!this.appliedFilters) {
      this.appliedFilters = [];
    }
    if (typeof filters === 'string') {
      filters = JSON.parse(filters);
      if (typeof filters === 'string') {
        filters = JSON.parse(filters);
        if (typeof filters === 'string') {
          filters = JSON.parse(filters);
        }
      }
    }

    if (typeof this.appliedFilters === 'string') {
      this.appliedFilters = JSON.parse(this.appliedFilters);
      if (typeof this.appliedFilters === 'string') {
        this.appliedFilters = JSON.parse(this.appliedFilters);
      }
    }
    filters.forEach((filter) => {
      switch (filter.type) {
        case 'date':
          this.appliedFilters = this.appliedFilters.concat(
            this.getDateItemFilter(filter)
          );
          break;
        case 'bool':
          this.appliedFilters = this.appliedFilters.concat(
            this.getBoolItemFilter(filter)
          );
          break;
        case 'list':
          this.appliedFilters = this.appliedFilters.concat(
            this.getListItemFilter(filter)
          );
          break;
        case 'string':
          this.appliedFilters = this.appliedFilters.concat(
            this.getStringItemFilter(filter)
          );
          break;
        case 'number':
          this.appliedFilters = this.appliedFilters.concat(
            this.getNumberItemFilter(filter)
          );
          break;
      }
    });
    this.appliedFilters = this.appliedFilters.filter(
      (filter) => !filter.hasOwnProperty('id')
    );
    this.loadDataSource();
  }

  cleanUpAppliedFilters(): any {
    return this.appliedFilters.map((filter) => {
      if (!filter.hasOwnProperty('id')) {
        return filter;
      }
      return;
    });
  }

  cancelRenameList(): void {
    this.selectedList.name = this.selectedListCopy.name;
    this.renameListPopup = false;
  }

  sortChanged(): void {
    let ds = null;
    if (!this.detailedView) {
      ds = this.dataGrid.instance.getDataSource();
    } else {
      ds = this.dxList.instance.getDataSource();
    }
    ds.reload();
  }

  getDateItemFilter(filter: any): ItemFilter[] {
    const itemFilter: ItemFilter[] = [];

    switch (filter.optionId) {
      case 1: // is equal to
        const dayAfter = moment(filter.filterValue[0]);
        dayAfter.add(1, 'days');

        itemFilter.push({
          operand1: filter.elasticField ? filter.elasticField : filter.field,
          operator: 'IS_BETWEEN',
          operand2: [
            new Date(filter.filterValue[0]).toLocaleDateString(),
            dayAfter.toDate().toLocaleDateString()
          ]
        });
        break;
      case 2: // is before
        itemFilter.push({
          operand1: filter.elasticField ? filter.elasticField : filter.field,
          operator: 'IS_BEFORE',
          operand2: new Date(filter.filterValue[0]).toLocaleDateString()
        });
        break;
      case 3: // is after
        itemFilter.push({
          operand1: filter.elasticField ? filter.elasticField : filter.field,
          operator: 'IS_AFTER',
          operand2: new Date(filter.filterValue[0]).toLocaleDateString()
        });
        break;
      case 4: // is between
        itemFilter.push({
          operand1: filter.elasticField ? filter.elasticField : filter.field,
          operator: 'IS_BETWEEN',
          operand2: [
            new Date(filter.filterValue[0]).toLocaleDateString(),
            !filter.filterValue[1]
              ? new Date().toLocaleDateString()
              : new Date(filter.filterValue[1]).toLocaleDateString()
          ]
        });
        break;
    }
    return itemFilter;
  }

  getBoolItemFilter(filter: any): ItemFilter[] {
    const itemFilter: ItemFilter[] = [];

    itemFilter.push({
      operand1: filter.elasticField ? filter.elasticField : filter.field,
      operator: 'IS_EQUAL_TO',
      operand2: filter.filterValue[0]
    });

    return itemFilter;
  }

  getListItemFilter(filter: any): ItemFilter[] {
    const itemFilter: ItemFilter[] = [];

    // convert list of names to list of ids
    let ids: number[] = [];
    filter.filterValue.forEach((name) => {
      for (let i = 0; i < filter.listData.length; i++) {
        if (filter.listData[i].name == name) {
          ids.push(filter.listData[i].id);
          break;
        }
      }
    });
    switch (filter.optionId) {
      case 1: //  is any of
        itemFilter.push({
          operand1: filter.elasticField ? filter.elasticField : filter.field,
          operator: 'IS_ANY_OF',
          operand2: ids
        });
        break;
      case 2: //  is none of
        itemFilter.push({
          operand1: filter.elasticField ? filter.elasticField : filter.field,
          operator: 'IS_NONE_OF',
          operand2: ids
        });
      case 3: //  is none of
        itemFilter.push({
          operand1: filter.elasticField ? filter.elasticField : filter.field,
          operator: 'IS_NULL'
        });
        break;
    }

    return itemFilter;
  }

  getStringItemFilter(filter: any): ItemFilter[] {
    const itemFilter: ItemFilter[] = [];

    switch (filter.optionId) {
      case 0: //  contains exact phrase
        itemFilter.push({
          operand1: filter.elasticField ? filter.elasticField : filter.field,
          operator: 'CONTAINS_EXACT_PHRASE',
          operand2: filter.filterValue
        });
      case 1: //  contains any of
        itemFilter.push({
          operand1: filter.elasticField ? filter.elasticField : filter.field,
          operator: 'CONTAINS_ANY_OF',
          operand2: filter.filterValue
        });
        break;
      case 2: //  contains all of
        itemFilter.push({
          operand1: filter.elasticField ? filter.elasticField : filter.field,
          operator: 'CONTAINS_ALL_OF',
          operand2: filter.filterValue
        });
        break;
      case 3: //  contains none of
        itemFilter.push({
          operand1: filter.elasticField ? filter.elasticField : filter.field,
          operator: 'CONTAINS_NONE_OF',
          operand2: filter.filterValue
        });
        break;
      case 4: //  is empty
        itemFilter.push({
          operand1: filter.elasticField ? filter.elasticField : filter.field,
          operator: 'IS_EMPTY',
          operand2: ''
        });
        break;
      case 5: //  is not empty
        itemFilter.push({
          operand1: filter.elasticField ? filter.elasticField : filter.field,
          operator: 'IS_NOT_EMPTY',
          operand2: ''
        });
        break;
      case 6: //  contains *wildcard* field.keyword
        itemFilter.push({
          operand1: filter.elasticField ? filter.elasticField : filter.field,
          operator: 'WILDCARD_KEYWORD',
          operand2: filter.filterValue
        });
        break;
    }

    return itemFilter;
  }

  getNumberItemFilter(filter: any): ItemFilter[] {
    const itemFilter: ItemFilter[] = [];

    switch (filter.optionId) {
      case 1: // is greater than
        itemFilter.push({
          operand1: filter.elasticField ? filter.elasticField : filter.field,
          operator: 'IS_GREATER_THAN',
          operand2: filter.filterValue[0]
        });
        break;
      case 2: // is equal to
        if (filter.filterValue.constructor === Array && filter.filterValue.length > 1) {
          itemFilter.push({
            operand1: filter.elasticField ? filter.elasticField : filter.field,
            operator: 'IS_ANY_OF',
            operand2: filter.filterValue
          });
        } else {
          itemFilter.push({
            operand1: filter.elasticField ? filter.elasticField : filter.field,
            operator: 'IS_EQUAL_TO',
            operand2: filter.filterValue[0]
          });
        }
        break;
      case 3: // is less than
        itemFilter.push({
          operand1: filter.elasticField ? filter.elasticField : filter.field,
          operator: 'IS_LESS_THAN',
          operand2: filter.filterValue[0]
        });
        break;
    }
    return itemFilter;
  }

  removeFilter(filter: any): void {
    if (typeof this.selectedList.filters === 'string') {
      this.selectedList.filters = JSON.parse(this.selectedList.filters);
    }
    this.selectedList.filters = (<any>this.selectedList).filters.filter(
      (displayFilter) => filter.value != displayFilter.value
    );

    this.appliedFilters = this.appliedFilters.filter((appliedFilter) => {
      let field = filter.elasticField ? filter.elasticField : filter.field;
      return field != appliedFilter.operand1;
    });

    this.sortChanged();
  }

  openFilterMenu(): void {
    this.filterMenuPage = 1;
    this.showFilterMenu = true;
    if (typeof this.selectedList.filters === 'string') {
      this.selectedList.filters = JSON.parse(this.selectedList.filters);
      if (typeof this.selectedList.filters === 'string') {
        this.selectedList.filters = JSON.parse(this.selectedList.filters);
        if (typeof this.selectedList.filters === 'string') {
          this.selectedList.filters = JSON.parse(this.selectedList.filters);
        }
      }
    }
    this.selectedList.filters = this.selectedList.filters;
  }

  setVisibleColumns(list: CompanyListV2) {
    this.filteredColumnOptions = JSON.parse(JSON.stringify(this.COLUMN_LIST));
    if (list.columns) {
      this.visibleColumns = JSON.parse(list.columns);

      for (let i = 0; i < this.filteredColumnOptions.length; i++) {
        for (let j = 0; j < this.visibleColumns.length; j++) {
          if (this.filteredColumnOptions[i].dataField == this.visibleColumns[j]) {
            this.filteredColumnOptions[i].visible = true;
            break;
          } else {
            this.filteredColumnOptions[i].visible = false;
          }
        }
      }
    }
  }

  addCustomFieldsToColumns() {
    const visibleCompanyDetails = this.companyDetails.filter(function (
      field: CustomFieldV2
    ) {
      return field.IsStandard && field.IsVisible;
    });

    const visibleCustomContactDetails = this.contactFields.filter(function (
      field: CustomFieldV2
    ) {
      return !field.IsStandard && field.IsVisible;
    });
    for (const field of visibleCompanyDetails) {
      if (field.FieldType == 2) {
        this.COLUMN_LIST.push({
          dataField: field.Name,
          caption: field.Name,
          visible: false,
          dataType: 'boolean'
        });
      } else if (field.FieldType == 6) {
        this.COLUMN_LIST.push({
          dataField: field.Name,
          caption: field.Name,
          visible: false,
          dataType: 'date'
        });
      } else {
        this.COLUMN_LIST.push({
          dataField: field.Name,
          caption: field.Name,
          visible: false,
          dataType: 'string'
        });
      }
      this.companyColumnChooser.items.push({
        Name: field.Name,
        dataField: field.Name,
        Visible: false
      });
    }

    for (const field of this.customFields) {
      if (field.FieldType == 2) {
        this.COLUMN_LIST.push({
          dataField: field.Name,
          caption: field.Name,
          visible: false,
          dataType: 'boolean'
        });
      } else if (field.FieldType == 6) {
        this.COLUMN_LIST.push({
          dataField: field.Name,
          caption: field.Name,
          visible: false,
          dataType: 'date'
        });
      } else {
        this.COLUMN_LIST.push({
          dataField: field.Name,
          caption: field.Name,
          visible: false,
          dataType: 'string'
        });
      }
      this.customCompanyColumnChooser.items.push({
        Name: field.Name,
        dataField: field.Name,
        Visible: false
      });
    }

    for (const field of visibleCustomContactDetails) {
      if (field.FieldType == 2) {
        this.COLUMN_LIST.push({
          dataField: field.Name,
          caption: field.Name,
          visible: false,
          dataType: 'boolean'
        });
      } else if (field.FieldType == 6) {
        this.COLUMN_LIST.push({
          dataField: field.Name,
          caption: field.Name,
          visible: false,
          dataType: 'date'
        });
      } else {
        this.COLUMN_LIST.push({
          dataField: field.Name,
          caption: field.Name,
          visible: false,
          dataType: 'string'
        });
      }
      this.contactColumns.push(field.Name);
      this.customContactColumnChooser.items.push({
        Name: field.Name,
        dataField: field.Name,
        Visible: false
      });
    }

    if (this.dataGrid) {
      this.dataGrid.instance.refresh(); // refreshes data grid
    }
  }

  getVisibleColumns() {
    this.visibleColumns = [];
    const visibleData = this.dataGrid.instance.getVisibleColumns();
    for (let i = 1; i < visibleData.length; i++) {
      this.visibleColumns.push(visibleData[i].dataField);
    }
  }

  getFilters() {
    this.filters = this.selectedList.filters;
  }

  ngOnDestroy(): void {
    this.toolbarService.clearToolbar();
    this.searchTermSubscription.unsubscribe();
    this.buttonClickSubscription.unsubscribe();
    this.companySubscription.unsubscribe();
    this.contactPopupSubscription.unsubscribe();
  }

  onInitialized(data: any) {
    this.dataGrid = data.component;
  }

  onToolbarPreparing(e: any) {
    e.toolbarOptions.items.push(
      {
        location: 'before',
        widget: 'dxButton',
        options: {
          text: 'Filter Builder',
          type: 'default',
          icon: 'filter',
          onInitialized: (args: any) => {
            this.filterButton = args.component;
          },
          onClick: () => {
            this.popupVisible = true;
          }
        }
      },
      {
        location: 'before',
        widget: 'dxButton',
        options: {
          text: 'Clear Filter',
          onInitialized: (args: any) => {
            this.clearFilterButton = args.component;
          },
          disabled: true,
          onClick: (args: any) => {
            e.component.clearFilter();
            args.component.option('disabled', true);
            this.filterButton.option('type', 'default');
            this.filterValue = null;
          }
        }
      }
    );
  }

  onPopupShowing() {
    this.filterValue = this.companyDataStore.filter();
  }

  expandBillingRow(row) {
    this.billingAddress1Expanded = !this.billingAddress1Expanded;
  }

  expandShippingRow() {
    this.shippingAddress1Expanded = !this.shippingAddress1Expanded;
  }

  expandCompanyDetails() {
    this.companyDetailsExpanded = !this.companyDetailsExpanded;
  }

  expandLabelsRow() {
    this.labelsExpanded = !this.labelsExpanded;
  }

  onSelectionChanged(data: any) {
    this.selectedItemsCount = data.selectedRowKeys.length;
  }

  renameList(list: CompanyListV2) {
    this.selectedList = Object.assign({}, list);
    this.renameListPopup = true;
  }

  detailedViewClicked() {
    if (this.dataGrid) {
      this.listCompaniesDisplayed = this.dataGrid.instance.getDataSource().items();
    }
    this.selectedItems = [this.listCompaniesDisplayed[0]];
    this.listCompanyId = this.listCompaniesDisplayed[0].id;
    this.detailedView = !this.detailedView;
  }

  detailListClicked(company) {
    this.listCompanyId = company.id;
  }

  openDeleteListPopup(list: CompanyList) {
    this.deleteListPopup = true;
    this.listDelete = list;
  }

  confirmDeleteSingleList(list: CompanyListV2) {
    this.companyListService.delete(list).then((deletedList) => {
      this.notificationService.showSuccess('List deleted');
      this.lists = this.lists
        .map((mapList) => (deletedList.id === mapList.id ? deletedList : mapList))
        .filter((filterList) => !filterList.deleted);
      this.updateCurrentLists();
      if (list.id === this.selectedList.id) {
        this.selectList(this.teamLists[0], true);
      }
      this.deleteListPopup = false;
    });
  }

  duplicateList(list: CompanyListV2) {
    this.newList = { ...list };
    this.duplicateListPopup = true;
  }

  duplicateExistingList() {
    this.existingDuplicatePopup = true;
  }

  addListToTeam(list: CompanyListV2) {
    (<any>list).expanded = false;
    list.teamList = true;
    this.companyListService.update(list).then((updatedList) => {
      this.lists = this.lists.map((sortedList) =>
        updatedList.id === sortedList.id ? updatedList : sortedList
      );
      this.updateCurrentLists();
    });
  }

  removeListFromTeam(list: CompanyListV2) {
    (<any>list).expanded = false;
    list.teamList = false;
    this.companyListService.update(list).then((updatedList) => {
      this.lists = this.lists.map((sortedList) =>
        updatedList.id === sortedList.id ? updatedList : sortedList
      );
      this.updateCurrentLists();
    });
  }

  updateCurrentLists() {
    this.teamLists = [this.allCompaniesFilter, this.allContactsFilter].concat(
      this.lists.filter((list) => list.teamList)
    );
    this.myLists = this.lists.filter((list) => !list.teamList);
  }

  saveAs(list: CompanyListV2) {
    this.newList = JSON.parse(JSON.stringify(list));
    this.getFilters();
    this.newList.filters = JSON.stringify(this.filters);
    this.newList.id = null;
    // this.listChanged(this.newList);
    this.saveAsListPopup = true;
  }

  discardChanges(list: CompanyListV2) {
    this.filterValue = JSON.parse(list.filters);
    this.convertDisplayFilterToAppliedFilter(this.filterValue);
    this.expandSaveMenuPopup = false;
  }

  allCompaniesList() {
    for (let i = 0; i < this.lists.length; i++) {
      if (this.lists[i].name == 'All Companies') {
        this.selectedList = Object.assign({}, this.lists[i]);
        this.filterValue = [];
        this.setVisibleColumns(this.lists[i]);
        break;
      }
    }
    this.expandCustomerMenuPopup = false;
  }

  allContactsList() {
    for (let i = 0; i < this.lists.length; i++) {
      if (this.lists[i].name == 'All Contacts') {
        this.selectedList = Object.assign({}, this.lists[i]);
        this.filterValue = [];
        this.setVisibleColumns(this.lists[i]);
        break;
      }
    }
    this.expandCustomerMenuPopup = false;
  }

  filterChanged(event) {}

  listChanged(list: CompanyListV2) {
    this.dataGrid.instance.clearSelection();
    if (list.id) {
      this.selectedList = list;
    }

    this.expandCustomerMenuPopup = false;
  }

  buttonClicked(button: string): void {
    if (button.toLowerCase() == 'new') {
      this.editCompany();
    }
  }

  editCompany(): void {
    this.newCompanyPopup = true;

    this.newCompany = Object.assign({});
    this.newCompanyCheck = Object.assign({});
  }

  expandCustomerMenu() {
    if (this.expandCustomerMenuPopup) {
      this.expandCustomerMenuPopup = false;
    } else {
      this.expandCustomerMenuPopup = true;
    }
  }

  expandSaveMenu() {
    if (this.expandSaveMenuPopup) {
      this.expandSaveMenuPopup = false;
    } else {
      this.expandSaveMenuPopup = true;
    }
  }

  expandAdvancedMenu() {
    if (this.advancedExpanded) {
      this.expandAdvancedMenuPopup = false;
    } else {
      this.expandAdvancedMenuPopup = true;
    }
    this.advancedExpanded = !this.advancedExpanded;
  }

  expandActionMenu() {
    if (this.expandActionMenuPopup) {
      this.expandActionMenuPopup = false;
    } else {
      this.expandActionMenuPopup = true;
    }
  }

  expandExportMenu() {
    if (this.expandExportMenuPopup) {
      this.expandExportMenuPopup = false;
    } else {
      this.expandExportMenuPopup = true;
    }
  }

  rowClicked(event) {
    const comps = this.dataGrid.instance.getSelectedRowKeys();
    this.numberOfRowsSelected = comps.length;
    this.totalCompaniesSelected = comps.length;
    if (this.totalCompaniesSelected == 2) {
      this.canMerge = true;
    } else {
      this.canMerge = false;
    }
    if (this.totalCompaniesSelected == 1 && event.isSelected) {
    } else {
      this.selectedRowKeys = this.dataGrid.instance.getSelectedRowKeys();
      this.indexesSelected = [];
      for (let i = 0; i < this.selectedRowKeys.length; i++) {
        this.indexesSelected.push(
          this.dataGrid.instance.getRowIndexByKey(this.selectedRowKeys[i])
        );
      }
      this.dataGrid.instance.selectRowsByIndexes(this.indexesSelected);
    }
  }

  showCompanyProfile(event) {
    // this.profileId = event.data.id;
    window.open(`/companies/company/${event.data.id}`, '_blank');
    // this.showProfile = true;
  }

  editList() {
    this.getSelectedRows();
    this.bulkEditPopup = true;
  }

  deleteList() {
    this.createCompaniesDeleted();
    this.bulkDeletePopup = true;
  }

  cancelMerge() {
    this.mergedCompanyDetails = [];
    this.mergedCustomFields = [];
    this.mergePopup = false;
    this.companyDetailsExpanded = false;
    this.billingAddress1Expanded = false;
    this.shippingAddress1Expanded = false;
  }

  async mergeList() {
    const company1 = Object.assign({}, this.rowCompanies[0]);
    const company2 = Object.assign({}, this.rowCompanies[1]);
    this.store.dispatch(
      new OpenDrawer({ component: CompanyMergeComponent, data: { company1, company2 } })
    );
  }

  populateMergeData() {
    this.mergeData = [];
    const data = [
      {
        field: 'Billing Address',
        fieldName: 'Address Line 1',
        comp1: {
          value:
            this.company1.billingAddress &&
            this.company1.billingAddress.addressLine1 &&
            this.company1.billingAddress.addressLine1 !== null
              ? this.company1.billingAddress.addressLine1.trim()
              : '',
          selected: false
        },
        comp2: {
          value:
            this.company2.billingAddress &&
            this.company2.billingAddress.addressLine1 &&
            this.company2.billingAddress.addressLine1 !== null
              ? this.company2.billingAddress.addressLine1.trim()
              : '',
          selected: false
        }
      },
      {
        field: 'Billing Address',
        fieldName: 'Address Line 2',
        comp1: {
          value:
            this.company1.billingAddress &&
            this.company1.billingAddress.addressLine2 &&
            this.company1.billingAddress.addressLine2 !== null
              ? this.company1.billingAddress.addressLine2.trim()
              : '',
          selected: false
        },
        comp2: {
          value:
            this.company2.billingAddress &&
            this.company2.billingAddress.addressLine2 &&
            this.company2.billingAddress.addressLine2 !== null
              ? this.company2.billingAddress.addressLine2.trim()
              : '',
          selected: false
        }
      },
      {
        field: 'Billing Address',
        fieldName: 'City',
        comp1: {
          value:
            this.company1.billingAddress &&
            this.company1.billingAddress.city &&
            this.company1.billingAddress.city !== null
              ? this.company1.billingAddress.city.trim()
              : '',
          selected: false
        },
        comp2: {
          value:
            this.company2.billingAddress &&
            this.company2.billingAddress.city &&
            this.company2.billingAddress.city !== null
              ? this.company2.billingAddress.city.trim()
              : '',
          selected: false
        }
      },
      {
        field: 'Billing Address',
        fieldName: 'State / Province / Region',
        comp1: {
          value:
            this.company1.billingAddress &&
            this.company1.billingAddress.state &&
            this.company1.billingAddress.state !== null
              ? this.company1.billingAddress.state.trim()
              : '',
          selected: false
        },
        comp2: {
          value:
            this.company2.billingAddress &&
            this.company2.billingAddress.state &&
            this.company2.billingAddress.state !== null
              ? this.company2.billingAddress.state.trim()
              : '',
          selected: false
        }
      },
      {
        field: 'Billing Address',
        fieldName: 'County',
        comp1: {
          value:
            this.company1.billingAddress &&
            this.company1.billingAddress.county &&
            this.company1.billingAddress.county !== null
              ? this.company1.billingAddress.county.trim()
              : '',
          selected: false
        },
        comp2: {
          value:
            this.company2.billingAddress &&
            this.company2.billingAddress.county &&
            this.company2.billingAddress.county !== null
              ? this.company2.billingAddress.county.trim()
              : '',
          selected: false
        }
      },
      {
        field: 'Billing Address',
        fieldName: 'ZIP / Postal Code',
        comp1: {
          value:
            this.company1.billingAddress &&
            this.company1.billingAddress.postCode &&
            this.company1.billingAddress.postCode !== null
              ? this.company1.billingAddress.postCode.trim()
              : '',
          selected: false
        },
        comp2: {
          value:
            this.company2.billingAddress &&
            this.company2.billingAddress.postCode &&
            this.company2.billingAddress.postCode !== null
              ? this.company2.billingAddress.postCode.trim()
              : '',
          selected: false
        }
      },
      {
        field: 'Billing Address',
        fieldName: 'Country',
        comp1: {
          value:
            this.company1.billingAddress &&
            this.company1.billingAddress.country &&
            this.company1.billingAddress.country !== null
              ? this.company1.billingAddress.country.trim()
              : '',
          selected: false
        },
        comp2: {
          value:
            this.company2.billingAddress &&
            this.company2.billingAddress.country &&
            this.company2.billingAddress.country !== null
              ? this.company2.billingAddress.country.trim()
              : '',
          selected: false
        }
      },
      {
        field: 'Shipping Address',
        fieldName: 'Address Line 1',
        comp1: {
          value:
            this.company1.shippingAddress &&
            this.company1.shippingAddress.addressLine1 &&
            this.company1.shippingAddress.addressLine1 !== null
              ? this.company1.shippingAddress.addressLine1.trim()
              : '',
          selected: false
        },
        comp2: {
          value:
            this.company2.shippingAddress &&
            this.company2.shippingAddress.addressLine1 &&
            this.company2.shippingAddress.addressLine1 !== null
              ? this.company2.shippingAddress.addressLine1.trim()
              : '',
          selected: false
        }
      },
      {
        field: 'Shipping Address',
        fieldName: 'Address Line 2',
        comp1: {
          value:
            this.company1.shippingAddress &&
            this.company1.shippingAddress.addressLine2 &&
            this.company1.shippingAddress.addressLine2 !== null
              ? this.company1.shippingAddress.addressLine2.trim()
              : '',
          selected: false
        },
        comp2: {
          value:
            this.company2.shippingAddress &&
            this.company2.shippingAddress.addressLine2 &&
            this.company2.shippingAddress.addressLine2 !== null
              ? this.company2.shippingAddress.addressLine2.trim()
              : '',
          selected: false
        }
      },
      {
        field: 'Shipping Address',
        fieldName: 'City',
        comp1: {
          value:
            this.company1.shippingAddress &&
            this.company1.shippingAddress.city &&
            this.company1.shippingAddress.city !== null
              ? this.company1.shippingAddress.city.trim()
              : '',
          selected: false
        },
        comp2: {
          value:
            this.company2.shippingAddress &&
            this.company2.shippingAddress.city &&
            this.company2.shippingAddress.city !== null
              ? this.company2.shippingAddress.city.trim()
              : '',
          selected: false
        }
      },
      {
        field: 'Shipping Address',
        fieldName: 'State / Province / Region',
        comp1: {
          value:
            this.company1.shippingAddress &&
            this.company1.shippingAddress.state &&
            this.company1.shippingAddress.state !== null
              ? this.company1.shippingAddress.state.trim()
              : '',
          selected: false
        },
        comp2: {
          value:
            this.company2.shippingAddress &&
            this.company2.shippingAddress.state &&
            this.company2.shippingAddress.state !== null
              ? this.company2.shippingAddress.state.trim()
              : '',
          selected: false
        }
      },
      {
        field: 'Shipping Address',
        fieldName: 'County',
        comp1: {
          value:
            this.company1.shippingAddress &&
            this.company1.shippingAddress.county &&
            this.company1.shippingAddress.county !== null
              ? this.company1.shippingAddress.county.trim()
              : '',
          selected: false
        },
        comp2: {
          value:
            this.company2.shippingAddress &&
            this.company2.shippingAddress.county &&
            this.company2.shippingAddress.county !== null
              ? this.company2.shippingAddress.county.trim()
              : '',
          selected: false
        }
      },
      {
        field: 'Shipping Address',
        fieldName: 'ZIP / Postal Code',
        comp1: {
          value:
            this.company1.shippingAddress &&
            this.company1.shippingAddress.postCode &&
            this.company1.shippingAddress.postCode !== null
              ? this.company1.shippingAddress.postCode.trim()
              : '',
          selected: false
        },
        comp2: {
          value:
            this.company2.shippingAddress &&
            this.company2.shippingAddress.postCode &&
            this.company2.shippingAddress.postCode !== null
              ? this.company2.shippingAddress.postCode.trim()
              : '',
          selected: false
        }
      },
      {
        field: 'Shipping Address',
        fieldName: 'Country',
        comp1: {
          value:
            this.company1.shippingAddress &&
            this.company1.shippingAddress.country &&
            this.company1.shippingAddress.country !== null
              ? this.company1.shippingAddress.country.trim()
              : '',
          selected: false
        },
        comp2: {
          value:
            this.company2.shippingAddress &&
            this.company2.shippingAddress.country &&
            this.company2.shippingAddress.country !== null
              ? this.company2.shippingAddress.country.trim()
              : '',
          selected: false
        }
      }
    ];

    this.mergeData = data;
    this.company1GroupLabels.forEach((label) => {
      if (!this.company1.labels) {
        this.company1.labels = [];
      }
      if (!this.company2.labels) {
        this.company2.labels = [];
      }
      const lbl1 = this.company1.labels.filter((list) => list.groupId == label.groupId);
      const lbl2 = this.company2.labels.filter((list) => list.groupId == label.groupId);

      const labelData = { field: 'Labels', fieldName: label.name, comp1: [], comp2: [] };

      if (lbl1.length) {
        lbl1.forEach((lbl) => {
          labelData.comp1.push({
            value: lbl != undefined ? lbl.name.trim() : '',
            selected: true,
            labelId: lbl.id
          });
        });
      }
      if (lbl2.length) {
        lbl2.forEach((lbl) => {
          labelData.comp2.push({
            value: lbl != undefined ? lbl.name.trim() : '',
            selected: false,
            labelId: lbl.id
          });
        });
      }

      this.mergeData.unshift(labelData);
    });
    for (let i = 0; i < this.companyDetails.length; i++) {
      data.unshift({
        field: 'Company Details',
        fieldName: this.companyDetails[i].Name,
        comp1: {
          value:
            this.company1CompanyDetails[i].Value &&
            this.company1CompanyDetails[i].Value !== null
              ? this.company1CompanyDetails[i].Value.trim()
              : '',
          selected: false
        },
        comp2: {
          value:
            this.company2CompanyDetails[i].Value &&
            this.company2CompanyDetails[i].Value !== null
              ? this.company2CompanyDetails[i].Value.trim()
              : '',
          selected: false
        }
      });
    }
  }

  isAnArray(data): boolean {
    return Array.isArray(data);
  }

  isNotArray(data): boolean {
    return !Array.isArray(data);
  }

  checkSelection(event) {
    this.getSelectedRows();
    this.companyService.canEditCompany(event.currentSelectedRowKeys).then((result) => {
      if (!result) {
        if (event.currentSelectedRowKeys && event.currentSelectedRowKeys.length) {
          this.notificationService.showError(
            `You don't have permission to make changes to ${event.currentSelectedRowKeys[0].CompanyName}`
          );
          event.currentSelectedRowKeys.isSelected = false;
        }
      } else {
        this.dataLoaded = false;
        this.getSelectedRows();
      }
    });
  }

  exportData() {
    this.dataGrid.export.fileName = 'CompanyList';
    const filter = {
      filters: this.appliedFilters,
      visibleColumns: JSON.parse(JSON.stringify(this.filteredColumnOptions))
    };

    let i: number = 0;
    filter.visibleColumns.forEach((filter) => {
      if (filter.key == 'Contact Fields') {
        filter.dataField = filter.dataField.replace('contact', 'contacts.');
        let temp: string[] = filter.dataField.split('.');
        temp[1] = temp[1].charAt(0).toLowerCase() + temp[1].slice(1);
        filter.dataField = temp[0] + '.' + temp[1];
      } else {
        filter.dataField = filter.elasticField ? filter.elasticField : filter.dataField;
      }

      filter.index = i;
      i++;
    });
    filter.visibleColumns.sort(function (column1, column2) {
      return column1.index - column2.index;
    });

    this.companyJPIService
      .exportCompanies(
        this.selectedSortOption.elasticField,
        this.selectedSortOption.asc,
        filter
      )
      .then((response) => {
        const filename = 'csvExport.csv';
        const blob = new Blob([response], { type: 'text/csv' });
        saveAs(blob, filename);
      });

    this.notificationService.showSuccess('Your download will start in a moment...');

    this.expandExportMenuPopup = false;
  }

  getSelectedRows() {
    const rows = [];
    if (this.dataGrid) {
      this.dataGrid.instance.getVisibleRows().forEach((row) => {
        if (row.isSelected) {
          rows.push(row.data);
        }
      });
    }
    this.selectedRows = rows;
    this.selectedCompanyIds = [];
    this.selectedRows.forEach((company) => {
      this.selectedCompanyIds.push(company.id);
    });
    this.selectedCompanyIds = JSON.parse(JSON.stringify(this.selectedCompanyIds));
    this.numberOfRowsSelected = rows.length;

    for (let i = 0; i < this.numberOfRowsSelected; i++) {
      if (!this.rowCompanies.includes(this.selectedRows[i])) {
        this.rowCompanies.push(this.selectedRows[i]);
      }
    }

    for (let i = 0; i < this.rowCompanies.length; i++) {
      if (!Object.values(this.selectedRows).includes(this.rowCompanies[i])) {
        this.rowCompanies.splice(i, 1);
        i--;
      }
    }
    this.totalCompaniesSelected = this.rowCompanies.length;
    if (
      this.totalCompaniesSelected == 2 &&
      this.selectedRows[0].id != this.selectedRows[1].id &&
      this.companyService.canEditSelectedCompanies(this.selectedRows)
    ) {
      this.canMerge = true;
    } else {
      this.canMerge = false;
    }
    if (
      this.totalCompaniesSelected != 0 &&
      this.companyService.canEditSelectedCompanies(this.selectedRows) &&
      this.bulkEditFeature &&
      this.bulkDeleteFeature
    ) {
      this.canBulkEdit = true;
      this.canBulkDelete = true;
    } else if (
      this.totalCompaniesSelected != 0 &&
      this.companyService.canEditSelectedCompanies(this.selectedRows) &&
      this.bulkEditFeature
    ) {
      this.canBulkEdit = true;
      this.canBulkDelete = false;
    } else if (
      this.totalCompaniesSelected != 0 &&
      this.companyService.canEditSelectedCompanies(this.selectedRows) &&
      this.bulkDeleteFeature
    ) {
      this.canBulkEdit = false;
      this.canBulkDelete = true;
    } else {
      this.canBulkEdit = false;
      this.canBulkDelete = false;
    }
    this.indexesSelected = [];
    for (let i = 0; i < this.selectedRows.length; i++) {
      this.indexesSelected.push(
        this.dataGrid.instance.getRowIndexByKey(this.selectedRows[i])
      );
    }
  }

  mergeValueChanged(event) {}

  company1Change(event) {
    this.company1 = Object.assign({}, event.selectedItem);
    this.populateMergedCompany();
  }

  company2Change(event) {
    if (event.selectedItem != null) {
      this.company2 = Object.assign({}, event.selectedItem);
      this.populateMergedCompany();
    }
  }

  getLabels() {
    this.labelService.getGroupLabels().then((result) => {
      this.labelService.getLabels().then((result1: Label[]) => {
        this.company1GroupLabels = result;
        for (let i = 0; i < this.company1GroupLabels.length; i++) {
          this.company1GroupLabels[i].labels = [];
          for (let j = 0; j < result1.length; j++) {
            if (this.company1GroupLabels[i].groupId == result1[j].groupId) {
              this.company1GroupLabels[i].labels.push(result1[j]);
            }
          }
        }
        this.company2GroupLabels = JSON.parse(JSON.stringify(this.company1GroupLabels));
        this.labelService.getCompanyLabelData(this.company1.id.value).then((result1) => {
          this.company1CompanyLabel = result1;
          this.labelService
            .getCompanyLabelData(this.company2.id.value)
            .then((result2) => {
              this.company2CompanyLabel = result2;
              this.matchLabels();
            });
        });
      });
    });
  }

  matchLabels() {
    for (let i = 0; i < this.company1GroupLabels.length; i++) {
      for (let j = 0; j < this.company1CompanyLabel.length; j++) {
        if (this.company1CompanyLabel[j].groupId == this.company1GroupLabels[i].groupId) {
          this.company1GroupLabels[i].labelId = this.company1CompanyLabel[j].labelId;
        }
      }
    }
    for (let i = 0; i < this.company2GroupLabels.length; i++) {
      for (let j = 0; j < this.company2CompanyLabel.length; j++) {
        if (this.company2CompanyLabel[j].groupId == this.company2GroupLabels[i].groupId) {
          this.company2GroupLabels[i].labelId = this.company2CompanyLabel[j].labelId;
        }
      }
    }
    this.populateMergeData();
    this.populateMergedCompany();
  }

  populateMergedCompany() {
    for (const key in this.company1) {
      if (this.company1.hasOwnProperty(key)) {
        if (this.company1[key]) {
          this.mergedCompany[key] = this.company1[key];
        } else if (this.company2[key]) {
          this.mergedCompany[key] = this.company2[key];
        }
      }
    }
    this.selectAllCompany1.selected = false;
    this.selectAllCompany2.selected = false;

    if (!this.company1.territories) {
      this.company1.territories = [];
    }
    if (!this.company2.territories) {
      this.company2.territories = [];
    }

    this.company1.territories.forEach((territory) => {
      territory.selected = false;
    });
    this.company2.territories.forEach((territory) => {
      territory.selected = false;
    });

    this.setMergeCheckboxes();
    this.mergePopup = true;
  }

  territoryChanged(): void {
    const element = document.getElementsByName('primaryUser');
    setTimeout(() => {
      for (let i = 0; i < element.length; i++) {
        if ((<any>element[i]).disabled === true) {
          (<any>element[i]).checked = false;
        }
      }
    }, 10);
  }

  setMergeCheckboxes() {
    this.company1SelectedField = 0;
    this.company2SelectedField = 0;
    this.mergeData.forEach((row) => {
      if (!row.comp2.value && row.comp1.value) {
        row.comp1.selected = true;
      } else if (!row.comp1.value && row.comp2.value) {
        row.comp2.selected = true;
      } else if (row.comp1.value && row.comp2.value) {
        row.comp1.selected = true;
      }
    });

    if (
      this.company1.id &&
      this.company1.id.value != '' &&
      this.company2.id &&
      this.company2.id.value == ''
    ) {
      this.company1.id.selected = true;
    } else if (
      this.company2.id &&
      this.company2.id.value != '' &&
      (this.company1.id == '' || !this.company1.id.value)
    ) {
      this.company2.id.selected = true;
    } else if (
      this.company1.id &&
      this.company1.id.value &&
      this.company2.id &&
      this.company2.id.value
    ) {
      this.company1.id.selected = true;
    }

    if (
      this.company1.name &&
      this.company1.name.value != '' &&
      (this.company2.name == '' || !this.company2.name.value)
    ) {
      this.company1.name.selected = true;
    } else if (
      this.company2.name &&
      this.company2.name.value != '' &&
      (this.company1.name == '' || !this.company1.name.value)
    ) {
      this.company2.name.selected = true;
    } else if (
      this.company1.name &&
      this.company1.name.value &&
      this.company2.name &&
      this.company2.name.value
    ) {
      this.company1.name.selected = true;
    }

    if (
      this.company1.website &&
      this.company1.website.value &&
      (!this.company2.website || (this.company2.website && !this.company2.website.value))
    ) {
      this.company1.website.selected = true;
    } else if (
      this.company2.website &&
      this.company2.website.value &&
      (!this.company1.website || (this.company1.website && !this.company1.website.value))
    ) {
      this.company2.website.selected = true;
    } else if (
      this.company1.website &&
      this.company1.website.value &&
      this.company2.website &&
      this.company2.website.value
    ) {
      this.company1.website.selected = true;
    }

    if (
      this.company1.accountId &&
      this.company1.accountId.value &&
      (!this.company2.accountId ||
        (this.company2.accountId && !this.company2.accountId.value))
    ) {
      this.company1.accountId.selected = true;
    } else if (
      this.company2.accountId &&
      this.company2.accountId.value &&
      (!this.company1.accountId ||
        (this.company1.accountId && !this.company1.accountId.value))
    ) {
      this.company2.accountId.selected = true;
    } else if (
      this.company1.accountId &&
      this.company1.accountId.value &&
      this.company2.accountId &&
      this.company2.accountId.value
    ) {
      this.company1.accountId.selected = true;
    } else if (
      !this.company1.accountId &&
      !this.company1.accountId.value &&
      !this.company2.accountId &&
      !this.company2.accountId.value
    ) {
      this.company1.accountId.selected = false;
    }
    if (
      this.company1.phone &&
      this.company1.phone.value &&
      (!this.company2.phone || (this.company2.phone && !this.company2.phone.value))
    ) {
      this.company1.phone.selected = true;
    } else if (
      this.company2.phone &&
      this.company2.phone.value &&
      (!this.company1.phone || (this.company1.phone && !this.company1.phone.value))
    ) {
      this.company2.phone.selected = true;
    } else if (
      this.company1.phone &&
      this.company1.phone.value &&
      this.company2.phone &&
      this.company2.phone.value
    ) {
      this.company1.phone.selected = true;
    }

    if (
      this.company1.fax &&
      this.company1.fax.value &&
      (!this.company2.fax || (this.company2.fax && !this.company2.fax.value))
    ) {
      this.company1.fax.selected = true;
    } else if (
      this.company2.fax &&
      this.company2.fax.value &&
      (!this.company1.fax || (this.company1.fax && !this.company1.fax.value))
    ) {
      this.company2.fax.selected = true;
    } else if (
      this.company1.fax &&
      this.company1.fax.value &&
      this.company2.fax &&
      this.company2.fax.value
    ) {
      this.company1.fax.selected = true;
    }

    if (
      this.company1.notes &&
      this.company1.notes.value &&
      (!this.company2.notes || (this.company2.notes && !this.company2.notes.value))
    ) {
      this.company1.notes.selected = true;
    } else if (
      this.company2.notes &&
      this.company2.notes.value &&
      (!this.company1.notes || (this.company1.notes && !this.company1.notes.value))
    ) {
      this.company2.notes.selected = true;
    } else if (
      this.company1.notes &&
      this.company1.notes.value &&
      this.company2.notes &&
      this.company2.notes.value
    ) {
      this.company1.notes.selected = true;
    }

    this.countSelectedRowsMerge();
  }

  selectAllboxesForMerge(company) {
    switch (company) {
      case 1:
        if (this.selectAllCompany1.selected) {
          this.selectAllCompany2.selected = false;
          this.company2.id.selected = false;
          this.company2.name.selected = false;
          this.company2.website.selected = false;
          this.company2.accountId.selected = false;
          this.company2.phone.selected = false;
          this.company2.fax.selected = false;
          this.company2.notes.selected = false;

          this.company1.id.selected = true;
          this.company1.name.selected = true;
          this.company1.website.selected = true;
          this.company1.accountId.selected = true;
          this.company1.phone.selected = true;
          this.company1.fax.selected = true;
          this.company1.notes.selected = true;

          this.mergeData.forEach((row) => {
            if (row.comp1.value !== '') {
              row.comp1.selected = true;
              row.comp2.selected = false;
            }
          });
        } else {
          this.company1.id.selected = false;
          this.company1.name.selected = false;
          this.company1.website.selected = false;
          this.company1.website.selected = false;
          this.company1.phone.selected = false;
          this.company1.fax.selected = false;
          this.company1.notes.selected = false;

          this.mergeData.forEach((row) => {
            if (row.comp2.value != '') {
              row.comp1.selected = false;
            }
          });
        }
        break;
      case 2:
        if (this.selectAllCompany2.selected) {
          this.selectAllCompany1.selected = false;
          this.company1.id.selected = false;
          this.company1.name.selected = false;
          this.company1.website.selected = false;
          this.company1.accountId.selected = false;
          this.company1.phone.selected = false;
          this.company1.fax.selected = false;
          this.company1.notes.selected = false;

          this.company2.id.selected = true;
          this.company2.name.selected = true;
          this.company2.website.selected = true;
          this.company2.accountId.selected = true;
          this.company2.phone.selected = true;
          this.company2.fax.selected = true;
          this.company2.notes.selected = true;

          this.mergeData.forEach((row) => {
            if (row.comp2.value !== '') {
              row.comp2.selected = true;
              row.comp1.selected = false;
            }
          });
        } else {
          this.company2.id.selected = false;
          this.company2.name.selected = false;
          this.company2.website.selected = false;
          this.company2.website.selected = false;
          this.company2.phone.selected = false;
          this.company2.fax.selected = false;
          this.company2.notes.selected = false;

          this.mergeData.forEach((row) => {
            if (row.comp1.value !== '') {
              row.comp2.selected = false;
            }
          });
        }
        break;
    }
    this.countSelectedRowsMerge();
  }

  selectBoxMerge(field, fieldName, company, event) {
    const newFieldName = { ...fieldName };
    switch (company) {
      case 1:
        if (field.selected) {
          this.company1[fieldName].selected = true;
          this.mergedCompany[fieldName] = this.company1[fieldName].value;
          if (this.company2[fieldName] && this.company2[fieldName].selected) {
            this.company2[fieldName].selected = false;
          }
        } else if (
          !this.company2[fieldName] ||
          (field.selected === false && this.company2[fieldName].selected === false)
        ) {
          this.mergedCompany[fieldName] = null;
        }

        if (field.data && event.event) {
          field.data.comp1.selected = true;
          field.data.comp2.selected = false;
        }
        break;
      case 2:
        if (field.selected) {
          this.company2[fieldName].selected = true;
          this.mergedCompany[fieldName] = this.company2[fieldName].value;
          if (this.company1[fieldName] && this.company1[fieldName].selected) {
            this.company1[fieldName].selected = false;
          }
        } else if (
          !this.company1[fieldName] ||
          (field.selected === false && this.company1[fieldName].selected === false)
        ) {
          this.mergedCompany[fieldName] = null;
        }
        if (field.data && event.event) {
          field.data.comp2.selected = true;
          field.data.comp1.selected = false;
        }
        break;
    }
    if (field.data && (fieldName.selected === true || fieldName.selected === false)) {
      this.mergeData.forEach((row) => {
        if (field.data.fieldName === row.fieldName) {
          if (company === 1) {
            if (field.selected == true || field.selected == false) {
              row.comp1.selected = field.selected;
            } else if (newFieldName.selected == true || newFieldName.selected == false) {
              row.comp1.selected = newFieldName.selected;
            }
          } else if (company === 2) {
            if (field.selected == true || field.selected == false) {
              row.comp2.selected = field.selected;
            } else if (newFieldName.selected == true || newFieldName.selected == false) {
              row.comp2.selected = newFieldName.selected;
            }
          }
        }
      });
    }

    this.mergeData.forEach((row) => {
      if (
        field.data &&
        field.data.field !== 'Labels' &&
        field.data.field !== 'Company Details'
      ) {
        if (field.data.field === row.field && field.data.fieldName === row.fieldName) {
          field.value.selected = event.value;
          if (row.comp1.selected === true) {
            this.mergedCompany[camelCase(field.data.field)][camelCase(row.fieldName)] =
              row.comp1.value;
          } else if (row.comp2.selected === true) {
            this.mergedCompany[camelCase(field.data.field)][camelCase(row.fieldName)] =
              row.comp2.value;
          } else {
            this.mergedCompany[camelCase(field.data.field)][camelCase(row.fieldName)] =
              null;
          }
        }
      }
    });
    this.countSelectedRowsMerge();
  }

  countSelectedRowsMerge() {
    this.company1SelectedField = 0;
    this.company2SelectedField = 0;

    if (this.company1.id && this.company1.id.value != '' && this.company1.id.selected) {
      this.company1SelectedField++;
    }
    if (
      this.company1.name &&
      this.company1.name.value != '' &&
      this.company1.name.selected
    ) {
      this.company1SelectedField++;
    }
    if (
      this.company1.website &&
      this.company1.website.value != '' &&
      this.company1.website.selected
    ) {
      this.company1SelectedField++;
    }
    if (
      this.company1.accountId &&
      this.company1.accountId.value != '' &&
      this.company1.accountId.selected
    ) {
      this.company1SelectedField++;
    }
    if (
      this.company1.phone &&
      this.company1.phone.value != '' &&
      this.company1.phone.selected
    ) {
      this.company1SelectedField++;
    }
    if (
      this.company1.fax &&
      this.company1.fax.value != '' &&
      this.company1.fax.selected
    ) {
      this.company1SelectedField++;
    }
    if (
      this.company1.notes &&
      this.company1.notes.value != '' &&
      this.company1.notes.selected
    ) {
      this.company1SelectedField++;
    }

    if (this.company2.id && this.company2.id.value != '' && this.company2.id.selected) {
      this.company2SelectedField++;
    }
    if (
      this.company2.name &&
      this.company2.name.value != '' &&
      this.company2.name.selected
    ) {
      this.company2SelectedField++;
    }
    if (
      this.company2.website &&
      this.company2.website.value != '' &&
      this.company2.website.selected
    ) {
      this.company2SelectedField++;
    }
    if (
      this.company2.accountId &&
      this.company2.accountId.value != '' &&
      this.company2.accountId.selected
    ) {
      this.company2SelectedField++;
    }
    if (
      this.company2.phone &&
      this.company2.phone.value != '' &&
      this.company2.phone.selected
    ) {
      this.company2SelectedField++;
    }
    if (
      this.company2.fax &&
      this.company2.fax.value != '' &&
      this.company2.fax.selected
    ) {
      this.company2SelectedField++;
    }
    if (
      this.company2.notes &&
      this.company2.notes.value != '' &&
      this.company2.notes.selected
    ) {
      this.company2SelectedField++;
    }

    this.mergeData.forEach((row) => {
      if (row.comp1.value !== '' && row.comp1.selected) {
        this.company1SelectedField++;
      }

      if (row.comp2.value !== '' && row.comp2.selected) {
        this.company2SelectedField++;
      }
    });
    this.isLoading = false;
  }

  getCustomFieldsByCompany() {
    const self = this;
    Promise.all([
      this.customFieldService.getCompanyCustomFields(
        !!this.company1.id && !!this.company1.id.value
          ? this.company1.id.value
          : this.company1.id
      ),
      this.customFieldService.getCompanyCustomFields(
        !!this.company2.id && !!this.company2.id.value
          ? this.company2.id.value
          : this.company2.id
      )
    ]).then(function (result) {
      self.companyDetailsCheck = [];
      self.customFieldsCheck = [];
      self.company1CustomFields = [];
      self.company2CustomFields = [];
      self.company1CompanyDetails = result[0].filter(function (section) {
        return section.Id == 0;
      })[0].Fields;
      self.company2CompanyDetails = result[1].filter(function (section) {
        return section.Id == 0;
      })[0].Fields;
      self.company1CustomSections = result[0].filter(function (section) {
        return section.Id != 0;
      });
      self.company2CustomSections = result[1].filter(function (section) {
        return section.Id != 0;
      });
      for (let i = 0; i < self.company1CustomSections.length; i++) {
        for (let j = 0; j < self.company1CustomSections[i].Tabs.length; j++) {
          for (let k = 0; k < self.company1CustomSections[i].Tabs[j].Fields.length; k++) {
            self.company1CustomFields.push(
              self.company1CustomSections[i].Tabs[j].Fields[k]
            );
          }
        }
      }
      for (let i = 0; i < self.company2CustomSections.length; i++) {
        for (let j = 0; j < self.company2CustomSections[i].Tabs.length; j++) {
          for (let k = 0; k < self.company2CustomSections[i].Tabs[j].Fields.length; k++) {
            self.company2CustomFields.push(
              self.company2CustomSections[i].Tabs[j].Fields[k]
            );
          }
        }
      }
      self.companyDetailsCheck = [];
      for (let i = 0; i < self.company1CompanyDetails.length; i++) {
        self.companyDetailsCheck.push(self.company1CompanyDetails[i]);
      }
      self.customFieldsCheck = [];
      for (let i = 0; i < self.company1CustomFields.length; i++) {
        self.customFieldsCheck.push(self.company1CustomFields[i]);
      }

      self.populateCustomFields();
    });
  }

  populateCustomFields() {
    this.mergedCompanyDetails = [];
    this.mergedCustomFields = [];
    for (let i = 0; i < this.companyDetailsCheck.length; i++) {
      if (this.company1CompanyDetails[i].Value) {
        this.mergedCompanyDetails.push(
          JSON.parse(JSON.stringify(this.company1CompanyDetails[i]))
        );
      } else if (this.company2CompanyDetails[i].Value) {
        this.mergedCompanyDetails.push(
          JSON.parse(JSON.stringify(this.company2CompanyDetails[i]))
        );
      } else {
        this.mergedCompanyDetails.push(
          JSON.parse(JSON.stringify(this.companyDetailsCheck[i]))
        );
      }
    }
    for (let i = 0; i < this.customFieldsCheck.length; i++) {
      if (this.company1CustomFields[i].Value) {
        this.mergedCustomFields.push(
          JSON.parse(JSON.stringify(this.company1CustomFields[i]))
        );
      } else if (this.company2CustomFields[i].Value) {
        this.mergedCustomFields.push(
          JSON.parse(JSON.stringify(this.company2CustomFields[i]))
        );
      } else {
        this.mergedCustomFields.push(
          JSON.parse(JSON.stringify(this.customFieldsCheck[i]))
        );
      }
    }

    this.populateLabels();
  }

  populateLabels() {
    this.mergedLabels = [];
    for (let i = 0; i < this.company1GroupLabels.length; i++) {
      if (this.company1GroupLabels[i].labelId) {
        this.mergedLabels.push(JSON.parse(JSON.stringify(this.company1GroupLabels[i])));
      } else if (this.company2GroupLabels[i].labelId) {
        this.mergedLabels.push(JSON.parse(JSON.stringify(this.company2GroupLabels[i])));
      } else {
        this.mergedLabels.push(JSON.parse(JSON.stringify(this.company1GroupLabels[i])));
      }
    }
    this.mergePopup = true;
  }

  mergedLabelsSame(mergedLabel, companyLabel): boolean {
    if (isEqual(mergedLabel, companyLabel)) {
      return true;
    } else {
      return false;
    }
  }

  mergeCompanies() {
    for (const key in this.mergedCompany) {
      if (this.mergedCompany.hasOwnProperty(key)) {
        if (
          this.mergedCompany[key] &&
          (this.mergedCompany[key].value === '' || this.mergedCompany[key].value)
        ) {
          this.mergedCompany[key] = this.mergedCompany[key].value;
        }
      }
    }
    this.mergedCompany.labels = [];
    for (let i = 0; i < this.mergeData.length; i++) {
      if (this.mergeData[i].field === 'Labels') {
        this.mergeData[i].comp1.forEach((label) => {
          if (label.selected) {
            this.addLabelToMergedCompany(label.labelId);
          }
        });
        this.mergeData[i].comp2.forEach((label) => {
          if (label.selected) {
            this.addLabelToMergedCompany(label.labelId);
          }
        });
      } else if (this.mergeData[i].field === 'Company Details') {
        this.companyDetails = this.companyDetails.map((companyDetail) => {
          if (this.mergeData[i].fieldName === companyDetail.Name) {
            this.mergeData[i].comp1.selected
              ? (companyDetail.Value = this.mergeData[i].comp1.value)
              : (companyDetail.Value = this.mergeData[i].comp2.value);
          }
          companyDetail.CompanyId = this.mergedCompany.id;
          return companyDetail;
        });
      } else {
        switch (this.mergeData[i].fieldName) {
          case 'ZIP / Postal Code':
            {
              if (this.mergeData[i].comp1.selected === true) {
                this.mergedCompany[camelCase(this.mergeData[i].field)].postCode =
                  this.mergeData[i].comp1.value;
              } else if (this.mergeData[i].comp2.selected === true) {
                this.mergedCompany[camelCase(this.mergeData[i].field)].postCode =
                  this.mergeData[i].comp2.value;
              }
            }
            break;
          case 'State / Province / Region':
            {
              if (this.mergeData[i].comp1.selected === true) {
                this.mergedCompany[camelCase(this.mergeData[i].field)].state =
                  this.mergeData[i].comp1.value;
              } else if (this.mergeData[i].comp2.selected === true) {
                this.mergedCompany[camelCase(this.mergeData[i].field)].state =
                  this.mergeData[i].comp2.value;
              }
            }
            break;
          default: {
            if (this.mergeData[i].comp1.selected === true) {
              this.mergedCompany[camelCase(this.mergeData[i].field)][
                camelCase(this.mergeData[i].fieldName)
              ] = this.mergeData[i].comp1.value;
            } else if (this.mergeData[i].comp2.selected === true) {
              this.mergedCompany[camelCase(this.mergeData[i].field)][
                camelCase(this.mergeData[i].fieldName)
              ] = this.mergeData[i].comp2.value;
            }
          }
        }
      }
    }
    this.mergedCompany.contacts = [];
    this.mergedCompany.geoLocation = {};
    const companyV2Keys = [
      'id',
      'agencyId',
      'accountId',
      'name',
      'website',
      'billingAddress',
      'shippingAddress',
      'phone',
      'fax',
      'notes',
      'createdDate',
      'deleted',
      'deletedDate',
      'contacts',
      'territories',
      'primaryUser',
      'labels',
      'directChildCompanies',
      'geoLocation'
    ];
    for (const key in this.mergedCompany) {
      if (this.mergedCompany.hasOwnProperty(key)) {
        if (!companyV2Keys.includes(key)) {
          delete this.mergedCompany[key];
        }
      }
    }
    this.mergedCompany.territories = [];
    this.mergedCompany.primaryUser = null;

    this.company1.territories.forEach((territory) => {
      if (territory.selected === true) {
        this.mergedCompany.territories.push(territory);
      }
    });
    this.company2.territories.forEach((territory) => {
      if (territory.selected === true) {
        this.mergedCompany.territories.push(territory);
      }
    });

    const element = document.getElementsByName('primaryUser');
    let userId;
    for (let i = 0; i < element.length; i++) {
      if ((<any>element[i]).checked) {
        userId = (<any>element[i]).value;
      }
    }

    if (userId) {
      this.userService.getById(userId).then((user) => {
        this.mergedCompany.primaryUser = user;
        this.finalMergeSteps();
      });
    } else {
      this.finalMergeSteps();
    }
  }

  finalMergeSteps(): void {
    if (this.mergedCompany.accountId && this.mergedCompany.accountId.value) {
      this.mergedCompany.accountId = this.mergedCompany.accountId.value;
    } else {
      this.mergedCompany.accountId = null;
    }
    if (this.mergedCompany.fax && this.mergedCompany.fax.value) {
      this.mergedCompany.fax = this.mergedCompany.fax.value;
    } else {
      this.mergedCompany.fax = null;
    }
    if (this.mergedCompany.website && this.mergedCompany.website.value) {
      this.mergedCompany.website = this.mergedCompany.accountId.value;
    } else {
      this.mergedCompany.website = null;
    }
    if (this.mergedCompany.phone && this.mergedCompany.phone.value) {
      this.mergedCompany.phone = this.mergedCompany.accountId.value;
    } else {
      this.mergedCompany.phone = null;
    }
    if (this.mergedCompany.notes && this.mergedCompany.notes.value) {
      this.mergedCompany.notes = this.mergedCompany.accountId.value;
    } else {
      this.mergedCompany.notes = null;
    }
    this.customFieldService.updateFieldValues(this.companyDetails);
    const mergedCompanyIds = [this.company1.id.value, this.company2.id.value].filter(
      (toBeDeletedCompId: number) => toBeDeletedCompId !== this.mergedCompany.id
    );
    this.companyListService
      .mergeCompanies(this.mergedCompany, mergedCompanyIds)
      .then((response) => {
        this.isMerging = false;
        this.selectList(this.selectedList, true);
        this.mergePopup = false;
        this.clearMergedCompanies();
      });
  }

  clearMergedCompanies() {
    const companyStub = {
      id: { value: null, selected: false },
      name: { value: null, selected: false },
      website: { value: null, selected: false },
      accountId: { value: null, selected: false },
      phone: { value: null, selected: false },
      fax: { value: null, selected: false },
      notes: { value: null, selected: false }
    };
    this.company1 = { ...companyStub };
    this.company2 = { ...companyStub };
    for (let i = 0; i > this.company1CompanyDetails.length; i++) {
      (<any>this.company1CompanyDetails[i]).selected = false;
    }
    for (let i = 0; i > this.company2CompanyDetails.length; i++) {
      (<any>this.company2CompanyDetails[i]).selected = false;
    }
  }

  addLabelToMergedCompany(labelId: number): void {
    const labelIds = [];
    this.mergedCompany.labels.forEach((label) => labelIds.push(label.id));
    for (let i = 0; i < this.labelGroups.length; i++) {
      this.labelGroups[i].labels.forEach((label) => {
        if (label.id === labelId && !labelIds.includes(label.id)) {
          this.mergedCompany.labels.push(label);
        }
      });
    }
  }

  labelChanged(key: number, value: number[]) {
    for (let i = 0; i < this.mergedLabels.length; i++) {
      if (this.mergedLabels[i].groupId == key) {
        this.mergedLabels[i].labelId = value;
      }
    }
  }

  standardFieldChanged(key, value) {
    for (let i = 0; i < this.mergedCompanyDetails.length; i++) {
      if (this.mergedCompanyDetails[i].StandardFieldId == key) {
        this.mergedCompanyDetails[i].Value = value;
      }
    }
  }

  customFieldChanged(key, value) {
    for (let i = 0; i < this.mergedCustomFields.length; i++) {
      if (this.mergedCustomFields[i].Id == key) {
        this.mergedCustomFields[i].Value = value;
      }
    }
  }

  editTerritoryBack(): void {
    this.BulkEditPage = 2;
    this.bulkEditSelectedTerritories = [];
  }

  bulkEditRouter(input) {
    switch (input) {
      case 1:
        this.populateTerritories();
        this.BulkEditPage = 2;
        break;
      case 2:
        this.populateTerritories();
        this.BulkEditPage = 3;
        break;
      case 3:
        this.editFieldsCompany = Object.assign({
          shippingAddress: {},
          billingAddress: {}
        });
        this.getDefaultLabelsForCompany();
        this.BulkEditPage = 4;
        break;
      case 4:
        this.labels = [];
        this.labelGroups.forEach((group) => {
          group.labels.forEach((label) => this.labels.push(label));
          (<any>group).addedLabels = [];
        });
        const selectedLabelIds: number[] = [];
        this.selectedRows.forEach((company) => {
          if (!company.labels) {
            company.labels = [];
          }

          company.labels.forEach((label) => selectedLabelIds.push(label.id));
        });
        this.removeLabelGroups = JSON.parse(JSON.stringify(this.labelGroups));
        this.removeLabelGroups.forEach((group) => {
          group.labels = group.labels.filter((label) =>
            selectedLabelIds.includes(label.id)
          );
        });
        this.BulkEditPage = 8;
        break;
      default:
        break;
    }
  }

  bulkEditTerritoryRouter(input) {
    this.selectedCompanyTerritories = [];
    this.selectedRows.forEach((company) => {
      company.territories.forEach((territory) => {
        this.selectedCompanyTerritories.push(territory);
      });
    });
    this.selectedCompanyTerritories = uniqBy(this.selectedCompanyTerritories, 'id');
    this.selectedCompanyTerritories.sort((a, b) => a.name.localeCompare(b.name));
    switch (input) {
      case 1:
        this.BulkEditPage = 5;
        break;
      case 2:
        this.BulkEditPage = 6;
        break;
      case 3:
        this.BulkEditPage = 7;
        break;
    }
  }

  bulkEditLabelsRouter(input) {
    switch (input) {
      case 1:
        this.BulkEditPage = 9;
        break;
      case 2:
        this.BulkEditPage = 10;
        break;
      case 3:
        this.BulkEditPage = 11;
        break;
    }
  }

  labelsOptionRouter() {
    this.BulkEditPage = 12;
    this.moveLabelOptionAdd = JSON.parse(JSON.stringify(this.moveLabelOption));
    this.moveLabelOptionRemove = JSON.parse(JSON.stringify(this.moveLabelOption));

    const selectedLabelIds: number[] = [];
    this.selectedRows.forEach((company) => {
      company.labels.forEach((label) => selectedLabelIds.push(label.id));
    });
    (<any>this.moveLabelOptionAdd).addedLabels = [];
    (<any>this.moveLabelOptionRemove).removedLabels = [];
    this.moveLabelOptionRemove.labels = this.moveLabelOptionRemove.labels.filter(
      (label) => selectedLabelIds.includes(label.id)
    );
  }

  saveEdit(): void {
    switch (this.editOption) {
      case 1:
        this.saveEditedTerritories();
        break;
      case 2:
        this.updatePrimaryUser();
        break;
      case 3:
        this.saveBulkEdit();
        break;
      case 4:
        this.saveEditedLabels();
        break;
      default:
        break;
    }

    this.editOption = null;
    this.editTerritoryOption = null;
    this.editLabelOption = null;
    this.BulkEditPage = 1;
    this.bulkEditPopup = false;
    this.newPrimaryUser = undefined;
    this.moveLabelOption = null;
    this.moveLabelOptionRemove = null;
    this.bulkEditSelectedTerritories = [];
    this.moveLabelOptionAdd = null;
  }

  saveEditedTerritories(): void {
    switch (this.editTerritoryOption) {
      case 1:
        return this.addingTerritoriesToCompanies(this.bulkEditSelectedTerritories, null);
        break;
      case 2:
        this.removingTerritoriesFromCompanies(this.bulkEditSelectedTerritories, null);
        break;
      case 3:
        this.movingTerritoriesInCompanies();
        break;
      default:
        break;
    }
  }

  addingTerritoriesToCompanies(
    territoriesArray: TerritoryV2[],
    companyIds: number[]
  ): void {
    const territoryRequest: TerritoryRequest = Object.assign({
      companyIds: [],
      territories: []
    });

    companyIds
      ? (territoryRequest.companyIds = companyIds)
      : this.selectedRows.forEach((company: CompanyV2) =>
          territoryRequest.companyIds.push(company.id)
        );
    this.selectedRows.forEach((company: CompanyV2) =>
      territoryRequest.companyIds.push(company.id)
    );
    territoriesArray.forEach((territory) => territoryRequest.territories.push(territory));
    this.bulkOperationsService.addTerritories(territoryRequest).then((result) => {
      this.selectList(this.selectedList, true);
    });
  }

  removingTerritoriesFromCompanies(
    territoriesArray: TerritoryV2[],
    companyIds: number[]
  ): void {
    const territoryRequest: TerritoryRequest = Object.assign({
      companyIds: [],
      territories: []
    });

    companyIds
      ? (territoryRequest.companyIds = companyIds)
      : this.selectedRows.forEach((company: CompanyV2) =>
          territoryRequest.companyIds.push(company.id)
        );
    this.selectedRows.forEach((company: CompanyV2) =>
      territoryRequest.companyIds.push(company.id)
    );
    territoriesArray.forEach((territory) => territoryRequest.territories.push(territory));
    this.bulkOperationsService.removeTerritories(territoryRequest).then((result) => {
      this.selectList(this.selectedList, true);
    });
  }

  async movingTerritoriesInCompanies() {
    const companyIds = JSON.parse(JSON.stringify(this.selectedCompanyIds));
    await this.removingTerritoriesFromCompanies(this.moveFromTerritories, companyIds);
    await this.addingTerritoriesToCompanies(this.moveToTerritories, companyIds);
  }

  saveEditedLabels(): void {
    switch (this.editLabelOption) {
      case 1:
        this.addingLabelsToCompanies(this.bulkEditSelectedLabels, null);
        break;
      case 2:
        this.removingLabelsFromCompanies(this.bulkEditSelectedLabels, null);
        break;
      case 3:
        this.movingLabelsInCompanies();
        break;
      default:
        break;
    }
  }

  async addingLabelsToCompanies(labelsArray: CompanyLabel[], companyIds: number[]) {
    const labelRequest: LabelRequest = Object.assign({ companyIds: [], labels: [] });
    companyIds
      ? (labelRequest.companyIds = companyIds)
      : this.selectedRows.forEach((company: CompanyV2) =>
          labelRequest.companyIds.push(company.id)
        );

    if (labelsArray.length) {
      labelRequest.labels = labelsArray;
    } else {
      if (this.labelGroups) {
        this.labelGroups.forEach((group) => {
          if ((<any>group).addedLabels) {
            if ((<any>group).addedLabels instanceof Array) {
              (<any>group).addedLabels.forEach((label) =>
                labelRequest.labels.push(JSON.parse(JSON.stringify(label)))
              );
            } else {
              labelRequest.labels.push(
                JSON.parse(JSON.stringify((<any>group).addedLabels))
              );
            }
          }
        });
      }
    }
    if (labelRequest.labels.length) {
      await this.bulkOperationsService.addLabels(labelRequest).then((result) => {
        this.selectList(this.selectedList, true);
      });
    }
    if (this.labelGroups) {
      this.labelGroups.forEach((group) => delete (<any>group).addedLabels);
    } else {
      if (this.removeLabelGroups) {
        delete (<any>this.moveLabelOptionAdd).addedLabels;
      }
    }
  }

  async removingLabelsFromCompanies(labelsArray: CompanyLabel[], companyIds: number[]) {
    const labelRequest: LabelRequest = Object.assign({ companyIds: [], labels: [] });
    companyIds
      ? (labelRequest.companyIds = companyIds)
      : this.selectedRows.forEach((company: CompanyV2) =>
          labelRequest.companyIds.push(company.id)
        );

    if (labelsArray.length) {
      labelRequest.labels = labelsArray;
    } else {
      if (this.removeLabelGroups) {
        this.removeLabelGroups.forEach((group) => {
          if ((<any>group).removedLabels) {
            if ((<any>group).removedLabels instanceof Array) {
              (<any>group).removedLabels.forEach((label) =>
                labelRequest.labels.push(JSON.parse(JSON.stringify(label)))
              );
            } else {
              labelRequest.labels.push(
                JSON.parse(JSON.stringify((<any>group).removedLabels))
              );
            }
          }
        });
      }
    }
    if (labelRequest.labels.length) {
      await this.bulkOperationsService.removeLabels(labelRequest).then((result) => {
        this.selectList(this.selectedList, true);
      });
    }
    if (this.removeLabelGroups) {
      this.removeLabelGroups.forEach((group) => delete (<any>group).removedLabels);
    } else {
      if (this.removeLabelGroups) {
        delete (<any>this.moveLabelOptionRemove).removedLabels;
      }
    }
  }

  async movingLabelsInCompanies() {
    const removeLabels = (<any>this.moveLabelOptionRemove).removedLabels;
    const addLabels = (<any>this.moveLabelOptionAdd).addedLabels;
    const companyIds = JSON.parse(JSON.stringify(this.selectedCompanyIds));
    await this.removingLabelsFromCompanies(removeLabels, companyIds);
    await this.addingLabelsToCompanies(addLabels, companyIds);
  }

  updatePrimaryUser(): void {
    this.selectedRows.forEach((company: CompanyV2) => {
      const companyTerritoryIds = [];
      company.territories.forEach((territory) => {
        companyTerritoryIds.push(territory.id);
      });
      if (this.addTerritoriesToCompanies) {
        if (!companyTerritoryIds.includes(this.primaryUserTerritory.id)) {
          company.territories.push(this.primaryUserTerritory);
          company.primaryUser = this.newPrimaryUser;
          this.companyJPIService.update(company).then((result) => {
            this.selectList(this.selectedList, true);
          });
        } else {
          company.primaryUser = this.newPrimaryUser;
          this.companyJPIService.update(company).then((result) => {
            this.selectList(this.selectedList, true);
          });
        }
      } else {
        if (companyTerritoryIds.includes(this.primaryUserTerritory.id)) {
          company.primaryUser = this.newPrimaryUser;
          this.companyJPIService.update(company).then((result) => {
            this.selectList(this.selectedList, true);
          });
        }
      }
    });
    this.bulkEditPopup = false;
  }

  saveBulkEdit(): void {
    const companyIds = [];
    this.selectedRows.forEach((row) => companyIds.push(row.id));
    this.editFieldsCompany.id = 1;
    const bulkEditRequest: MergeRequest = { company: this.editFieldsCompany, companyIds };
    this.bulkOperationsService.bulkEdit(bulkEditRequest).then((result) => {
      this.selectList(this.selectedList, true);
    });
  }

  primaryUserChanged(user: UserV2, territory: TerritoryV2): void {
    this.primaryUserTerritory = territory;
    this.newPrimaryUser = user;
  }

  editCompanyStep1() {
    this.editCompanySteps = [];
    if (this.editTerritoryRepCheckbox == true) {
      this.editCompanySteps.push('Territory');
    }
    if (this.editPrimaryRepCheckbox == true) {
      this.editCompanySteps.push('Primary');
    }
    if (this.editCompanyDetailsCheckbox == true) {
      this.editCompanySteps.push('Company Details');
    }

    this.editCompanySteps.push('Confirmation');
    this.numberOfSteps = 1 + this.editCompanySteps.length;
    this.checkNextPopup();
  }

  primaryRepsSetup() {
    this.regionService.getRegionsByAgent().then((result) => {
      this.regionAgents = result;
    });
  }

  storeCompanyDetails() {
    for (const property in this.bulkEditCompany) {
      if (this.bulkEditCompany.hasOwnProperty(property)) {
        this.companyDetailsChanged.push({
          Name: property,
          Value: this.bulkEditCompany[property]
        });
      }
    }
    for (let i = 0; i < this.companyDetails.length; i++) {
      if (this.companyDetails[i].Value) {
        this.companyDetailsChanged.push({
          Name: `${this.companyDetails[i].Name}`,
          Value: `${this.companyDetails[i].Value}`
        });
      }
    }
  }

  storeCustomFieldsChanged(section: SectionV2) {
    for (let i = 0; i < section.Tabs.length; i++) {
      for (let j = 0; j < section.Tabs[i].Fields.length; j++) {
        if (section.Tabs[i].Fields[j].Value) {
          this.customSectionsChanged.push({
            Section: `${section.Name}`,
            FieldName: `${section.Tabs[i].Fields[j].Name}`,
            Value: `${section.Tabs[i].Fields[j].Value}`
          });
        }
      }
    }
  }

  saveTerritoriesChanged() {
    this.territoryTagBoxNames = [];
    for (let i = 0; i < this.territoryTagBoxValues.length; i++) {
      for (let j = 0; j < this.regionAgents.length; j++) {
        if (this.territoryTagBoxValues[i] == this.regionAgents[j].regionId) {
          this.territoryTagBoxNames.push(this.regionAgents[j].regionName);
        }
      }
    }
    for (let i = 0; i < this.territoryTagBoxNames.length; i++) {
      this.territoriesChanged.push(this.territoryTagBoxNames[i]);
    }
  }

  storePrimaryAgentChanged(agent: any) {
    this.primarySalesAgentChanged.push(agent.firstname + ' ' + agent.lastname);
    this.changedPrimaryAgentMailbox = agent.mailbox;
  }

  clearCustomFields(section: SectionV2) {
    for (let i = 0; i < this.customSectionsChanged.length; i++) {
      if (this.customSectionsChanged[i].Section == section.Name) {
        this.customSectionsChanged.splice(i, 1);
        i--;
      }
    }
  }

  stepBackwards() {
    this.editPopupIndex -= 2;
    if (this.editPopupIndex == -1) {
      this.bulkEditPopup = true;
      this.bulkEditTerritoryPopup = false;
      this.bulkEditPrimarySalesPopup = false;
      this.bulkEditCompanyDetailsPopup = false;
      this.editCustomFieldPopup = false;
      this.confirmationPopup = false;
      this.customSectionsChanged = [];
      this.editCompanySteps = [];
      this.editPopupIndex += 1;
      this.stepNumber = 1;
    } else {
      this.stepNumber -= 2;
      this.checkNextPopup();
    }
  }

  checkNextPopup() {
    if (this.editCompanySteps[0] != 'Confirmation') {
      switch (this.editCompanySteps[this.editPopupIndex]) {
        case 'Territory':
          this.bulkEditPopup = false;
          this.populateTerritories();
          this.territoriesChanged = [];
          this.bulkEditTerritoryPopup = true;
          this.bulkEditPrimarySalesPopup = false;
          this.bulkEditCompanyDetailsPopup = false;
          this.editCustomFieldPopup = false;
          this.confirmationPopup = false;
          break;
        case 'Primary':
          this.primaryRepsSetup();
          if (!this.primarySalesAgentChanged) {
            this.primarySalesAgentChanged = [];
          }
          this.bulkEditPopup = false;
          this.bulkEditTerritoryPopup = false;
          this.bulkEditPrimarySalesPopup = true;
          this.bulkEditCompanyDetailsPopup = false;
          this.editCustomFieldPopup = false;
          this.confirmationPopup = false;
          break;
        case 'Company Details':
          this.bulkEditPopup = false;
          this.bulkEditTerritoryPopup = false;
          this.bulkEditPrimarySalesPopup = false;
          this.bulkEditCompany = Object.assign({});
          this.bulkEditCompanyDetailsPopup = true;
          this.editCustomFieldPopup = false;
          this.confirmationPopup = false;
          break;
        case 'Confirmation':
          this.splitCompanyDetails(this.companyDetailsChanged);
          this.bulkEditPopup = false;
          this.bulkEditTerritoryPopup = false;
          this.bulkEditPrimarySalesPopup = false;
          this.bulkEditCompanyDetailsPopup = false;
          this.editCustomFieldPopup = false;
          this.confirmationPopup = true;
          break;
        default:
          this.editCustomFieldPopup = false;
          this.bulkEditPopup = false;
          this.bulkEditTerritoryPopup = false;
          this.bulkEditPrimarySalesPopup = false;
          this.bulkEditCompanyDetailsPopup = false;
          this.checkCustomFieldsStep();
          this.clearCustomFields(this.customSection);
          this.editCustomFieldPopup = true;
          this.confirmationPopup = false;
      }
      this.stepNumber++;
      this.editPopupIndex++;
    } else {
      this.notificationService.showError('Please select one edit action');
      this.editCompanySteps = [];
    }
  }

  populateTerritories() {
    this.territoryService.getAllFlattened().then((territories) => {
      this.v2Territories = territories;

      for (let i = 0; i < this.selectedRows.length; i++) {
        if (!this.selectedRows[i].territories) {
          this.selectedRows[i].territories = [];
        }
        this.selectedRows[i].territories.forEach((territory) => {
          for (let j = 0; j < this.v2Territories.length; j++) {
            if (territory.id == this.v2Territories[j].id) {
              this.v2Territories[j].users.sort((user1, user2) => {
                return user1.firstName.localeCompare(user2.firstName);
              });

              this.currentTerritoryAssignments.push(this.v2Territories[j]);
              break;
            }
          }
        });
      }
      this.currentTerritoryAssignments = uniqBy(this.currentTerritoryAssignments, 'id');
      this.companiesInTerritoryCount();
    });
  }

  getDefaultLabelsForCompany(): void {
    this.editFieldsCompany.labels = [];
    this.companyLabelGroupService.getCompanyLabelGroups().then((labelGroup) => {
      this.groupLabels = labelGroup;
      this.groupLabels.forEach((groupLabel) => {
        if (groupLabel.defaultLabelId > 0) {
          this.editFieldsCompany.labels.push(
            groupLabel.labels.filter((label) => label.id === groupLabel.defaultLabelId)[0]
          );
        }
      });
    });
  }

  companiesInTerritoryCount(): void {
    const companyTerritoryIds = [];

    this.selectedRows.forEach((company) => {
      company.territories.forEach((territory) => {
        companyTerritoryIds.push(territory.id);
      });
    });

    for (let i = 0; i < this.currentTerritoryAssignments.length; i++) {
      (<any>this.currentTerritoryAssignments[i]).companyCount =
        companyTerritoryIds.filter(
          (id) => id == this.currentTerritoryAssignments[i].id
        ).length;
      (<any>this.currentTerritoryAssignments[i]).expanded = true;
    }
  }

  expandTerritory(territory: TerritoryV2): void {
    for (let i = 0; i < this.currentTerritoryAssignments.length; i++) {
      if (this.currentTerritoryAssignments[i].id == territory.id) {
        (<any>this.currentTerritoryAssignments[i]).expanded = !(<any>(
          this.currentTerritoryAssignments[i]
        )).expanded;
      }
    }
  }

  updateTagBoxValues() {
    this.territoryTagBoxValues = [];
    for (let i = 0; i < this.territories.length; i++) {
      let include = false;
      for (let j = 0; j < this.selectedRows.length; j++) {
        if (this.selectedRows[j].Territories.includes(this.territories[i].RegionID)) {
          include = true;
        } else {
          include = false;
          break;
        }
      }
      if (include == true) {
        this.territoryTagBoxValues.push(this.territories[i].RegionID);
      }
    }
  }

  splitCompanyDetails(details: any) {
    for (let i = 0; i < details.length; i++) {
      if (details[i].Name == 'WebSite') {
        details[i].Name = 'Website';
      }
      details[i].Name = details[i].Name.match(/[A-Z][a-z]+|[0-9]+/g).join(' ');
    }
  }

  clearBulkEdit() {
    this.editOption = null;
    this.newPrimaryUser = undefined;
    this.bulkEditPopup = false;
    this.BulkEditPage = 1;
  }

  checkCustomFieldsStep() {
    for (const section of this.customSections) {
      if (this.editCompanySteps[this.editPopupIndex] == section.Name) {
        this.customSection = section;
      }
    }
  }

  createCompaniesDeleted() {
    this.dataGrid.instance.getVisibleRows().forEach((row) => {
      if (row.isSelected) {
        this.companiesDeleted.push(row.data.id);
      }
    });
  }

  bulkDelete(companies: number[]) {
    const self = this;
    if (this.deleteConfirmation === 'DELETE') {
      this.companyService.bulkDelete(companies).then(function (result) {
        self.deleteFromDataDisplayed();
        self.dataGrid.instance.clearSelection();
        self.selectedRows = [];
        self.companiesDeleted = [];
        self.notificationService.showSuccess('Companies have been deleted');
        self.bulkDeletePopup = false;
      });
    }
  }

  deleteFromDataDisplayed() {
    this.dataGrid.instance.refresh();
  }

  setPrimaryMailbox(mailbox) {
    for (const region of this.regionAgents) {
      for (const agent of region.Reps) {
        if (agent.mailbox == mailbox || agent.AgentMailboxId == mailbox) {
          agent.primary = true;
          this.storePrimaryAgentChanged(agent);
        } else {
          agent.primary = false;
        }
      }
    }
  }

  setPrimary(event, agent): void {
    if (event.value == true) {
      this.setPrimaryMailbox(agent.mailbox);
    }
  }

  saveList(list: CompanyListV2, isDuplicate: boolean) {
    if (!isDuplicate) {
      this.getFilters();
      this.convertFiltersToDates();
      list.filters = JSON.stringify(this.filters);
      this.getVisibleColumns();
      list.columns = JSON.stringify(this.visibleColumns);
    } else {
      list.id = null;
    }
    if (list.id) {
      this.companyListService.update(list).then((updatedList) => {
        this.lists = this.lists.map((list) =>
          list.id === updatedList.id ? updatedList : list
        );
        this.notificationService.showSuccess('Company List updated');
        this.selectedList = updatedList;
        this.selectedList.filters = JSON.parse(this.selectedList.filters);
        this.selectList(this.selectedList, true);
        this.updateCurrentLists();
      });
    } else {
      list.teamList = false;
      this.companyListService.create(list).then((newList) => {
        this.notificationService.showSuccess('Company List created');
        this.selectedList = newList;
        this.selectedList.filters = JSON.parse(this.selectedList.filters);
        this.lists.push(newList);
        this.selectList(this.selectedList, true);
        this.updateCurrentLists();
      });
    }
    this.renameListPopup = false;
    this.duplicateListPopup = false;
    this.scratchListPopup = false;
    this.saveAsListPopup = false;
    this.expandCustomerMenuPopup = false;
    this.customersMenuExpanded = false;
  }

  convertFiltersToDates() {
    for (let i = 0; i < this.filters.length; i++) {
      if (Array.isArray(this.filters[i])) {
        if (
          Array.isArray(this.filters[i][0]) &&
          this.filters[i][0][2] instanceof Date &&
          this.filters[i][2][2] instanceof Date
        ) {
          let newDate;
          newDate = new Date(this.filters[i][0][2]);
          newDate =
            newDate.getFullYear() +
            '/' +
            (newDate.getMonth() + 1) +
            '/' +
            newDate.getDate();
          this.filters[i][0][2] = newDate;
          newDate = new Date(this.filters[i][2][2]);
          newDate =
            newDate.getFullYear() +
            '/' +
            (newDate.getMonth() + 1) +
            '/' +
            newDate.getDate();
          this.filters[i][2][2] = newDate;
        } else if (
          Array.isArray(this.filters[i][2]) &&
          this.filters[i][2][2] instanceof Date
        ) {
          let newDate;
          newDate = new Date(this.filters[i][2][2]);
          newDate =
            newDate.getFullYear() +
            '/' +
            (newDate.getMonth() + 1) +
            '/' +
            newDate.getDate();
          this.filters[i][2][2] = newDate;
        } else if (this.filters[i][2] instanceof Date) {
          let newDate;
          newDate = new Date(this.filters[i][2]);
          newDate =
            newDate.getFullYear() +
            '/' +
            (newDate.getMonth() + 1) +
            '/' +
            newDate.getDate();
          this.filters[i][2] = newDate;
        }
      }
    }
  }

  openOptions(list: CompanyListV2) {
    (<any>list).expanded = !(<any>list).expanded;
  }

  saveEditedCompanies(companies: Company[]) {
    this.bulkEditRequest.CompanyIds = [];
    this.bulkEditRequest.Fields = [];
    for (let i = 0; i < companies.length; i++) {}
    this.bulkEditRequest.Company = this.bulkEditCompany as Company;
    for (const section of this.customSections) {
      for (const tab of section.Tabs) {
        for (const field of tab.Fields) {
          if (field.Value) {
            this.bulkEditRequest.Fields.push(field);
          }
        }
      }
    }
    for (const field of this.companyDetails) {
      if (field.Value) {
        this.bulkEditRequest.Fields.push(field);
      }
    }
    for (const key in companies) {
      if (!companies.hasOwnProperty(key)) {
        continue;
      }
      this.bulkEditRequest.CompanyIds.push(companies[key].CompanyId);
      if (this.changedPrimaryAgentMailbox) {
        this.companyService
          .setPrimaryRep(companies[key].CompanyId, this.changedPrimaryAgentMailbox)
          .then(function (result1) {});
      }
    }
    this.companyService.bulkEdit(this.bulkEditRequest).then(function (result) {});
    this.clearBulkEdit();
  }

  openEditColumnChooser() {
    this.selected_columns = this.filteredColumnOptions.filter(function (column) {
      return column.visible;
    });
    this.selectedColumnCount = this.filteredColumnOptions.filter(function (column) {
      return column.visible;
    }).length;
    let i = 0;
    this.selected_columns.forEach((column) => {
      column.index = i;
      i++;
    });
    this.editColumnPopup = true;
  }

  moveFields(event) {
    const from = event.itemIndex;
    const to = event.toIndex;
    this.selected_columns[from].index = to;
    if (to > from) {
      for (let i = from + 1; i <= to; i++) {
        this.selected_columns[i].index = this.selected_columns[i].index - 1;
      }
    } else {
      for (let i = 0; i < from; i++) {
        this.selected_columns[i].index = this.selected_columns[i].index + 1;
      }
    }
    this.selected_columns = this.selected_columns.sort(function (field1, field2) {
      return field1.index - field2.index;
    });
  }

  changeRowPosition(field, change) {
    if (change) {
      this.column_options.store()._array.forEach((ds) => {
        if (ds.caption == field && ds.enabled == true) {
          this.selected_columns.push(ds);
        }
      });
    } else {
      this.selected_columns = this.selected_columns.filter(function (col) {
        return col.caption != field;
      });
    }
    this.selectedColumnCount = this.column_options
      .store()
      ._array.filter(function (column) {
        return column.enabled;
      }).length;
  }

  removeAllColumns() {
    this.column_options.store()._array.forEach((column) => {
      column.enabled = false;
    });

    this.selected_columns = [];
  }

  applyColumns() {
    this.filteredColumnOptions = [];
    this.selected_columns.forEach((column) => {
      this.filteredColumnOptions.push(column);
    });
    this.dataGrid.instance.refresh();
    this.editColumnPopup = false;
    this.expandActionMenuPopup = false;

    let selectedColumns = '[';
    this.filteredColumnOptions.forEach(
      (column) => (selectedColumns += '"' + column.dataField + '",')
    );
    selectedColumns = selectedColumns.substring(0, selectedColumns.length - 1) + ']';
    this.selectedList.columns = selectedColumns;
    localStorage.setItem('companyColumns', this.selectedList.columns);
  }

  closeEditColumns() {
    this.removeAllColumns();
    this.column_options.store()._array.forEach((column) => {
      this.filteredColumnOptions.filter(function (col) {
        if (col.dataField == column.dataField) {
          column.enabled = true;
        }
      });
    });
    this.editColumnPopup = false;
    this.expandActionMenuPopup = false;
  }

  sortSelected(column, value) {
    column.column = value;
    alert('Woo');
  }

  cancelSortOptions() {
    this.columnChooserPopup = false;
  }
}
