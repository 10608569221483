<mat-form-field color="accent" fxFlex>
  <mat-select
    [formControl]="itemInputCtrl"
    [placeholder]="placeholder"
    [multiple]="true"
    #multiSelect
  >
    <mat-option>
      <ngx-mat-select-search
        [showToggleAllCheckbox]="true"
        [toggleAllCheckboxTooltipMessage]="'Select All / Unselect All'"
        [toggleAllCheckboxTooltipPosition]="'above'"
        [toggleAllCheckboxIndeterminate]="isIndeterminate"
        [toggleAllCheckboxChecked]="isChecked"
        (toggleAll)="toggleSelectAll($event)"
        [formControl]="itemMultiFilterCtrl"
        [placeholderLabel]="'Search Users'"
        [noEntriesFoundLabel]="itemMultiFilterCtrl.value + ' user not found'"
      ></ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let item of filteredItemsMulti | async" [value]="item">
      {{ item.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
