<company-popup *ngIf="companyPopup$" [(companyPopup)]="companyPopup$"></company-popup>
<contact-popup *ngIf="contactPopup$" [(contactPopup)]="contactPopup$"></contact-popup>
<call-note-popup
  *ngIf="callNotePopup$"
  [(callNote)]="callNote"
  [(callNotePopup)]="callNotePopup$"
  [callNoteExpanded]="true"
  [fromToolbar]="true"
></call-note-popup>
<call-note-popup
  *ngIf="followUpPopup$"
  [(callNote)]="callNote"
  [(callNotePopup)]="followUpPopup$"
  [callNoteExpanded]="false"
  [fromToolbar]="true"
></call-note-popup>

<div
  [ngClass]="{
    'toolbar-container': !navExpanded,
    'toolbar-container-expanded': navExpanded
  }"
  fxLayout="row"
  *ngIf="showToolbar"
>
  <div class="toolbar-dynamic-options" fxLayout="row" fxFlex>
    <div
      *ngFor="let tab of tabs"
      class="tab-link"
      fxLayout="row"
      fxLayoutAlign="center center"
      [ngClass]="{ active: tab.IsActive }"
    >
      <a
        *ngIf="tab.TabName != 'Profile'"
        [routerLink]="[tab.TabUrl]"
        [innerText]="tab.TabName"
      ></a>
      <a *ngIf="tab.TabName == 'Profile'" [innerText]="tab.TabName"></a>
    </div>
    <!-- <div *ngIf="showSearchBar" class="search-bar" fxLayout="row" fxLayoutAlign="start center" fxFlex="25">
			<mat-icon>search</mat-icon>
			<input [placeholder]="searchBarPlaceHolder" (keyup)="searchTermChanged($event)" [(ngModel)]="searchTerm" />
		</div>
		<div *ngFor="let button of buttons" id="buttonContainer" fxLayout="row" fxLayoutAlign="center center">
			<span *ngIf="button != 'New'" class="button button-blue" (click)="buttonClicked(button)">{{button}}</span>
			<span *ngIf="button == 'New' && !newButtonMenu" class="button button-blue" (click)="buttonClicked(button)"
				fxLayoutAlign="start center">{{button}}
				<mat-icon>arrow_drop_down</mat-icon>
			</span>
			<span *ngIf="button == 'New' && newButtonMenu" class="button button-blue" (click)="buttonClicked(button)"
				fxLayoutAlign="end center">{{button}}
				<mat-icon>arrow_drop_up</mat-icon>
			</span>
		</div>
	</div> -->
    <!-- <div class="tool-user-options" fxLayout="row" fxLayoutAlign="center center">
		<dx-menu #menu [dataSource]="menuItems" displayExpr="name" orientation="horizontal"
			[hideSubmenuOnMouseLeave]="false" itemTemplate="menuTemplate" (onItemClick)="menuClick($event.itemData)">
			<div *dxTemplate="let menu of 'menuTemplate'" [ngSwitch]="menu.id">
				<div *ngSwitchCase="0" fxLayoutAlign="start center" class="detail-pane-back icon-large">
					<span [innerText]="userName"></span>
					<mat-icon>keyboard_arrow_down</mat-icon>
				</div>
				<div *ngSwitchCase="1" fxLayoutAlign="start center" class="detail-pane-background">{{menu.name}}</div>
				<div *ngSwitchCase="8" fxLayoutAlign="start center" class="detail-pane-background">{{menu.name}}</div>
				<div *ngSwitchCase="2" fxLayoutAlign="start center" class="detail-pane-background">{{menu.name}}</div>
				<div *ngSwitchCase="3" fxLayoutAlign="start center" class="detail-pane-background">{{menu.name}}</div>
				<div *ngSwitchCase="4" fxLayoutAlign="start center" class="detail-pane-back icon-large">{{menu.name}}
					<mat-icon>keyboard_arrow_down</mat-icon>
				</div>
				<div *ngSwitchCase="5" fxLayoutAlign="start center" class="detail-pane-background">
					<a href="mailto:feedback@telenotes.com">{{menu.name}}</a>
				</div>
				<div *ngSwitchCase="6" fxLayoutAlign="start center" class="detail-pane-background">
					<a href="mailto:support@telenotes.com">{{menu.name}}</a>
				</div>
				<div *ngSwitchCase="7" fxLayoutAlign="start center" class="detail-pane-background">
					<a [routerLink]="['/tutorials']">{{menu.name}}</a>
				</div>
			</div>
		</dx-menu>
	</div> -->
  </div>

  <!-- <dx-popup (onScroll)="onWindowScroll($event)" [width]="150" [height]="'auto'" [showTitle]="false" [dragEnabled]="false" -->
  <!-- [closeOnOutsideClick]="true" [(visible)]="newCompanyProfileMenu" [shading]="false" -->
  <!-- [position]="{ my: 'top', at: 'left bottom', of: '#buttonContainer', offset: '15 -13' }"> -->
  <!-- <div *dxTemplate="let data of 'content'"> -->
  <!-- <div fxLayout="column" fxFlex> -->
  <!-- <span class="list-options" (click)="newCustomCompany()">Company</span> -->
  <!-- <span *ngIf="callNotesFeature==1" class="list-options" (click)="newCustomCallNote()">Call Note</span> -->
  <!-- <span *ngIf="callNotesFeature==1" class="list-options" (click)="newCustomFollowUp()">Follow-up</span> -->
  <!-- <span *ngIf="editContactsFeature==1" class="list-options" (click)="newCustomContact()">Contact</span> -->
  <!-- <span class="list-options" (click)="newCustomFile()">Files/photo</span> -->
  <!-- <span class="list-options" (click)="newChildCompany()">Child Company</span> -->
  <!-- </div> -->
  <!-- </div> -->
  <!-- </dx-popup> -->

  <!-- <dx-popup (onScroll)="onWindowScroll($event)" [width]="150" [height]="'auto'" [showTitle]="false" [dragEnabled]="false"
	[closeOnOutsideClick]="true" [(visible)]="customCallNoteMenu" [shading]="false"
	[position]="{ my: 'top', at: 'left bottom', of: '#buttonContainer', offset: '15 -13' }">
	<div *dxTemplate="let data of 'content'">
		<div fxLayout="column" fxFlex>
			<span class="list-options" (click)="newCompany()">Company</span>
			<span *ngIf="callNotesFeature==1" class="list-options" (click)="newCustomCallNote()">Call Note</span>
			<span *ngIf="callNotesFeature==1" class="list-options" (click)="newCustomFollowUp()">Follow-up</span>
			<span *ngIf="editContactsFeature==1" class="list-options" (click)="newContact()">Contact</span>
		</div>
	</div>
</dx-popup> -->

  <!-- <dx-popup [width]="150" [height]="'auto'" [showTitle]="false" [dragEnabled]="false" [closeOnOutsideClick]="true"
	[(visible)]="newButtonMenu" [shading]="false"
	[position]="{ my: 'top', at: 'left bottom', of: '#buttonContainer', offset: '15 -13' }">
	<div *dxTemplate="let data of 'content'">
		<div fxLayout="column" fxFlex>
			<span class="list-options" (click)="newCompany()" fxLayoutAlign="start center">Company</span>
			<span *ngIf="callNotesFeature==1" class="list-options" (click)="newCallNote()"
				fxLayoutAlign="start center">Call
				Note
			</span>
			<span *ngIf="callNotesFeature==1" class="list-options" (click)="newFollowUp()"
				fxLayoutAlign="start center">Follow-up</span>
			<span *ngIf="editContactsFeature==1" class="list-options" (click)="newContact()">Contact</span>
		</div>
	</div>
</dx-popup> -->

  <!-- <dx-popup [position]="{ my: 'right', at: 'right', of: window }" [width]="600" [height]="'100vh'" [showTitle]="false" [dragEnabled]="false"
    [closeOnOutsideClick]="false" [(visible)]="childCompanyPopup" shadingColor="rgba(0,0,0,0.5)">
    <div *dxTemplate="let data of 'content'">
        <div fxLayout="column" style="height: calc(100% - 50px);">
            <dx-scroll-view [height]="'inherit'">
                <div class="popup-title">
                    <span>Add child company under {{company.CompanyName }}</span>
                </div>
                <div fxLayout="column" style="padding-top: 10px">
                    <span>Search for existing company</span>
                    <dx-tag-box [dataSource]="allCompanies" searchEnabled="true" displayExpr="CompanyName" valueExpr="CompanyId" [(value)]="childCompanyRequest.ChildCompanyIds"
                        [searchEnabled]="true" [showSelectionControls]="true" applyValueMode="instantly" [hideSelectedItems]="true"
                        [multiline]="true"></dx-tag-box>
                </div>
                <div fxLayout="column" fxFlex="35">
                    <span>Or</span>
                    <span class="button button-white" (click)="createChildCompany()">Create new company</span>
                </div>
            </dx-scroll-view>
        </div>
        <div fxLayout="row" fxLayoutAlign="end end">
            <div fxFlex fxLayoutAlign="end end">
                <span class="button button-white" (click)="childCompanyPopup=false">Cancel</span>
                <span class="button button-blue" (click)="saveChildCompany()">Save</span>
            </div>
        </div>
    </div>
</dx-popup> -->

  <!-- <child-company-popup *ngIf="childCompanyPopup" [(childCompanyPopup)]="childCompanyPopup" [(companyId)]="companyId"
	[(childCompanyRequest)]="childCompanyRequest"></child-company-popup> -->
</div>
