<div>
  <button mat-button class="user-options-btn" [matMenuTriggerFor]="userMenu">
    <span *ngIf="$pending | async; else authLoaded"
      ><mat-spinner [strokeWidth]="3" [diameter]="30"></mat-spinner
    ></span>
    <ng-template #authLoaded>
      {{ $username | async }}
      <mat-icon> keyboard_arrow_down </mat-icon>
    </ng-template>
  </button>
  <mat-menu #userMenu="matMenu" class="user-options-menu" xPosition="before">
    <div class="email-container" fxLayoutAlign="center center">{{ $email | async }}</div>
    <button mat-menu-item fxLayoutAlign="start center" (click)="selectLandingPage()">
      <mat-icon color="primary">home</mat-icon>
      <span>Default Page</span>
    </button>
    <button
      *ngIf="$canSignInAsUser | async"
      mat-menu-item
      fxLayoutAlign="start center"
      (click)="signInAsUser()"
    >
      <mat-icon color="primary">switch_account</mat-icon>
      <span>Sign In As User</span>
    </button>
    <mat-divider></mat-divider>
    <div (click)="openSupport()" mat-menu-item fxLayoutAlign="start center">
      <mat-icon color="primary">help</mat-icon>
      <span>Contact Support</span>
    </div>
    <div *ngFor="let category of $links | async">
      <mat-divider></mat-divider>
      <a
        *ngFor="let route of category"
        mat-menu-item
        [routerLink]="['/' + route.path]"
        routerLinkActive="user-options-active-link"
        fxLayoutAlign="start center"
      >
        <mat-icon color="primary">{{ route.icon }}</mat-icon>
        <span>{{ route.title }}</span>
      </a>
    </div>
    <mat-divider></mat-divider>
    <button mat-menu-item fxLayoutAlign="start center" (click)="signOutHandler()">
      <mat-icon color="primary">cancel</mat-icon>
      <span>Sign Out</span>
    </button>
    <mat-divider *ngIf="canExpireAuth"></mat-divider>
    <button
      *ngIf="canExpireAuth"
      mat-menu-item
      fxLayoutAlign="start center"
      (click)="expireAuth()"
    >
      <mat-icon>update</mat-icon>
      <span>Expire Auth</span>
    </button>
  </mat-menu>
</div>
