import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { helpReducer } from './help.reducer';
import { EffectsModule } from '@ngrx/effects';
import { HelpEffects } from './help.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('help', helpReducer),
    EffectsModule.forFeature([HelpEffects])
  ]
})
export class HelpStateModule {}
