<div class="global-search-results" (clickOutside)="closeLeftDrawer()">
  <div fxLayout="column" fxLayoutGap="12px">
    <div class="results-title" fxLayout="row" fxLayoutAlign="space-between center">
      <h3>{{ totalResults }} Total Results</h3>
      <button mat-icon-button (click)="closeLeftDrawer()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="space-between center"
      [ngClass]="{ 'no-results': totalCompanies === 0 }"
    >
      <h3 class="results-title">Companies</h3>
      <div class="see-all-link">
        <a
          *ngIf="totalCompanies > 0; else elseNoCompanies"
          [routerLink]="['/companies-list/list']"
          [queryParams]="{ companyNameAddress: searchTerm, globalSearch: true }"
          (click)="closeLeftDrawer()"
        >
          See all {{ totalCompanies }} matches</a
        >
        <ng-template #elseNoCompanies>
          <span>No results</span>
        </ng-template>
      </div>
    </div>
    <ul class="results-list">
      <li class="results-item" *ngFor="let company of companiesResults">
        <a
          class="company-link"
          (click)="handleCompanyClick(company.id)"
          href="javascript:void(0)"
        >
          <div class="results-name">
            {{ company.name }}
          </div>
          <div class="results-subtext">
            {{ company.address }}
          </div>
        </a>
      </li>
    </ul>
  </div>

  <div fxLayout="column">
    <div
      fxLayout="row"
      fxLayoutAlign="space-between center"
      [ngClass]="{ 'no-results': totalContacts === 0 }"
    >
      <h3 class="results-title">Contacts</h3>
      <div class="see-all-link">
        <a
          *ngIf="totalContacts > 0; else elseNoContacts"
          [routerLink]="['/companies-list/list']"
          [queryParams]="{ contactName: searchTerm, globalSearch: true }"
          (click)="closeLeftDrawer()"
        >
          See all {{ totalContacts }} matches</a
        >
        <ng-template #elseNoContacts>
          <span>No results</span>
        </ng-template>
      </div>
    </div>
    <ul class="results-list">
      <li class="results-item" *ngFor="let contact of contactsResults">
        <a (click)="handleContactClick(contact)" href="javascript:void(0)">
          <div class="results-name">
            {{ contact.name }}
          </div>
          <div class="results-subtext">
            {{ contact.company.name }}
          </div>
        </a>
      </li>
    </ul>
  </div>

  <div fxLayout="column">
    <div
      fxLayout="row"
      fxLayoutAlign="space-between center"
      [ngClass]="{ 'no-results': totalCallNotes === 0 }"
    >
      <h3 class="results-title">Call Notes</h3>
      <div class="see-all-link">
        <a
          *ngIf="totalCallNotes > 0; else elseNoCallNotes"
          [routerLink]="['/activity-list/list']"
          [queryParams]="{ narrative: searchTerm }"
          (click)="closeLeftDrawer()"
        >
          See all {{ totalCallNotes }} matches</a
        >
        <ng-template #elseNoCallNotes>
          <span>No results</span>
        </ng-template>
      </div>
    </div>
    <ul class="results-list">
      <li class="results-item" *ngFor="let callNote of callNotesResults">
        <a (click)="handleCallNoteClick(callNote.id)" href="javascript:void(0)">
          <div fxLayout="row" fxLayoutAlign="start center">
            <div class="results-name">
              {{ callNote.date | date }} - {{ callNote.createdBy }} ({{
                callNote.company
              }})
            </div>
            <div fxLayout="row" (click)="$event.stopPropagation()">
              <a
                mat-icon-button
                color="accent"
                *ngIf="callNote.projectId"
                [routerLink]="['/projects/project', callNote.projectId]"
                target="_blank"
              >
                <mat-icon>assignment</mat-icon>
              </a>
              <a
                mat-icon-button
                color="accent"
                *ngIf="callNote.opportunityId"
                [routerLink]="['/opportunities/opportunity', callNote.opportunityId]"
                target="_blank"
              >
                <mat-icon>trending_up</mat-icon>
              </a>
            </div>
          </div>
          <div class="results-subtext">
            {{ callNote.text }}
          </div>
        </a>
      </li>
    </ul>
  </div>
</div>
