import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CalendarEffects } from './calendar.effects';
import { calendarReducer } from './calendar.reducer';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature('calendar', calendarReducer),
    EffectsModule.forFeature([CalendarEffects])
  ]
})
export class CalendarStateModule {}
