import { Pipe, PipeTransform } from '@angular/core';
import { Item } from '../../../models/Opportunity/item';

@Pipe({
  name: 'itemFilter'
})
export class ItemFilterPipe implements PipeTransform {
  transform(items: Item[], searchText: string): Item[] {
    searchText = searchText ? searchText.toLowerCase() : null;
    return items.filter(item => {
      return !!searchText ? item.itemDesc.toLowerCase().match(searchText) : true;
    });
  }
}
