<form [formGroup]="contactFieldForm" class="contact-field-form">
  <div>
    <mat-form-field fxFlex>
      <mat-label>Name</mat-label>
      <input formControlName="name" matInput type="text" />
    </mat-form-field>
  </div>
  <div>
    <mat-form-field fxFlex>
      <mat-label>Type</mat-label>
      <mat-select formControlName="type" matInput [compareWith]="compareObjects">
        <mat-option *ngFor="let type of $fieldTypes | async" [value]="type">{{
          type.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div fxLayout="row-reverse" fxLayoutAlign="space-between center">
    <div fxLayout="row-reverse">
      <button
        *ngIf="!currentContactField?.id"
        type="button"
        mat-raised-button
        color="primary"
        mat-button
        (click)="createButtonClicked()"
        [disabled]="contactFieldForm.pristine || contactFieldForm.invalid"
      >
        Create
      </button>
      <button
        *ngIf="currentContactField?.id"
        type="button"
        mat-raised-button
        color="primary"
        mat-button
        [disabled]="contactFieldForm.pristine || contactFieldForm.invalid"
        (click)="saveButtonClicked()"
      >
        Save
      </button>
      <button
        preventDefaultAction
        mat-raised-button
        color="white"
        mat-button
        class="button-container"
        (click)="cancelButtonClicked()"
      >
        Cancel
      </button>
    </div>
    <button
      *ngIf="currentContactField?.id"
      preventDefaultAction
      mat-raised-button
      color="accent"
      mat-button
      (click)="deleteButtonClicked()"
    >
      Delete
    </button>
  </div>
</form>
