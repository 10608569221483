import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { AuthenticationService } from '../../shared/services/authentication.service';
import { Company } from '../models/companies/company';
import { RouteLeg } from './../models/route-leg';
import { CompanyService } from './companies/company.service';

@Injectable()
export class MapService {
	public permissions: any = { Routes: 29, Leads: 30 };
	mapCenter: any = { lat: 39.83333333, lng: -98.58333333 };
	mapZoomLevel: any;
	mappableCompanies: Company[];
	allRoutes: any[];
	searchPageRoute: any;
	selectedRoute: any;
	private redrawMapAnnouncer = new Subject<boolean>();
	public redrawMapAnnounced = this.redrawMapAnnouncer.asObservable();
	private labelsChangedAnnouncer = new Subject<boolean>();
	public labelsChangedAnnounced = this.labelsChangedAnnouncer.asObservable();
	private leadsChangedAnnouncer = new Subject<boolean>();
	public leadsChangedAnnounced = this.leadsChangedAnnouncer.asObservable();
	private showAllCompaniesChangedAnnouncer = new Subject<boolean>();
	public showAllCompaniesChangedAnnounced = this.showAllCompaniesChangedAnnouncer.asObservable();
	mapLoading: boolean;
	showAllCompanies: boolean = true;

	constructor(private authService: AuthenticationService,
		private companyService: CompanyService) {
	}

	updateRedrawMap(boolean: boolean) {
		this.redrawMapAnnouncer.next(null);
		this.redrawMapAnnouncer.next(boolean);
	}

	updateLabelsChanged(boolean: boolean) {
		this.labelsChangedAnnouncer.next(null);
		this.labelsChangedAnnouncer.next(boolean);
	}

	updateLeadsChanged(boolean: boolean) {
		this.leadsChangedAnnouncer.next(null);
		this.leadsChangedAnnouncer.next(boolean);
	}

	updateShowAllCompanies(boolean: boolean) {
		this.showAllCompaniesChangedAnnouncer.next(null);
		this.showAllCompaniesChangedAnnouncer.next(boolean);
	}

	getMappableCompanies(): Promise<Company[]> {
		if (this.mappableCompanies && this.mappableCompanies.length > 0) {
			return Promise.resolve(this.mappableCompanies);
		} else {
			this.mappableCompanies = [];

			return this.companyService.getCompanies().then((result) => {
				for (let i = 0; i < result.length; i++) {
					if (!isNaN(result[i].Latitude) && !isNaN(result[i].Longitude)) { this.mappableCompanies.push(result[i]); }
				}

				this.mappableCompanies.sort((a, b) => a.CompanyName.localeCompare(b.CompanyName));
				return this.mappableCompanies;
			});
		}
	}

	hasLeads(): Promise<boolean> {
		return this.authService.getFeature(this.authService.modules.Maps).then((result) => {
			for (let i = 0; i < result.Permissions.length; i++) {
				if (result.Permissions[i].Id == this.permissions.Leads) {
					return result.Permissions[i].Value == 'true';
				}
			}
			return false;
		});
	}

	hasRoutes(): Promise<boolean> {
		return this.authService.getFeature(this.authService.modules.Maps).then((result) => {
			for (let i = 0; i < result.Permissions.length; i++) {
				if (result.Permissions[i].Id == this.permissions.Routes) {
					return result.Permissions[i].Value == 'true';
				}
			}
			return false;
		});
	}

	getMapCenter(): any {
		return this.mapCenter;
	}

	setMapCenter(center: any): void {
		this.mapCenter = JSON.parse(JSON.stringify(center));
	}

	getMapZoomLevel(): any {
		return this.mapZoomLevel;
	}

	setMapZoomLevel(zoom: any): void {
		this.mapZoomLevel = zoom;
	}

	getSelectedRoute(): any {
		return this.selectedRoute;
	}

	setSelectedRoute(route: any): void {
		this.selectedRoute = route;
	}

	getSearchPageRoute(): any {
		return this.searchPageRoute;
	}

	clearSearchPageRoute(): void {
		delete this.searchPageRoute;
	}

	addCompanyToRoute(company: any): void {
		if (!this.selectedRoute) {
			if (!this.searchPageRoute) {
				this.selectedRoute = this.searchPageRoute = {
					id: 0,
					name: 'A new route',
					length: 0,
					legs: [],
					agencyId: null,
					avoidTolls: false,
					avoidHighways: false,
					type: 'telenotes'
				};
			} else {
				this.selectedRoute = this.searchPageRoute;
			}
		}

		this.selectedRoute.legs.push({
			position: this.selectedRoute.legs.length + 1,
			company: {
				id: company.id,
				name: company.name,
				territories: company.territories,
				primaryUser: company.primaryUser,
				geoLocation: company.geoLocation
			}
		});
		this.selectedRoute.hasChanged = true;
		company.isRouted = true;
	}

	removeCompanyFromRoute(company: any): void {
		if (!this.selectedRoute) {
			return;
		}

		for (let i = 0; i < this.selectedRoute.Destinations.length; i++) {
			const comp = this.selectedRoute.Destinations[i];
			if (comp.id == company.id) {
				comp.isRouted = false;
				this.selectedRoute.Destinations.splice(i, 1);
				break;
			}
		}

		this.selectedRoute.hasChanged = true;
	}

	clearRoute(): void {
		this.selectedRoute = null;
	}

	getRoutes(): Promise<any> {
		if (this.allRoutes && this.allRoutes.length > 0) {
			return Promise.resolve(this.allRoutes);
		} else {
			return this.authService.makeJpiRequest('/routes', null, null, 'get').then((result: any) => {
				const routes = JSON.parse(result);
				routes.map(route => {
					route.legs.sort((a: RouteLeg, b: RouteLeg) => a.position - b.position);
				});
				return routes;
			});
		}
	}

	addRoute(route: any, newRouteName: string): Promise<any> {

		const routeRequest: any = {
			RouteId: 0,
			RouteName: newRouteName,
			RouteLength: route.RouteLength,
			Destinations: []
		};

		for (let i = 0; i < route.Destinations.length; i++) {
			routeRequest.Destinations.push(route.Destinations[i].CompanyId);
		}

		return this.authService.makeRequest('/api-v1.2.1/maps/saveRoute', routeRequest, 'post').then((result) => {
			routeRequest.Destinations = [];
			for (let i = 0; i < route.Destinations.length; i++) {
				routeRequest.Destinations.push(route.Destinations[i]);
			}

			routeRequest.RouteId = result;
			routeRequest.type = 'telenotes';

			this.allRoutes.push(routeRequest);
			this.selectedRoute = routeRequest;

			return routeRequest;
		});
	}

	updateRoute(route: any): Promise<boolean> {
		const routeRequest: any = {
			RouteId: route.RouteId,
			RouteName: route.RouteName,
			RouteLength: route.RouteLength,
			Destinations: []
		};

		for (let i = 0; i < route.Destinations.length; i++) {
			routeRequest.Destinations.push(route.Destinations[i].CompanyId);
		}

		return this.authService.makeRequest('/api-v1.2.1/maps/updateRoute', routeRequest, 'post').then(() => {
			route.hasChanged = false;
			return true;
		});
	}

	deleteRoute(route: any): Promise<boolean> {

		const routeRequest = {
			RouteId: route.RouteId,
			RouteName: route.RouteName,
			RouteLength: route.RouteLength,
			Destinations: []
		};
		return this.authService.makeRequest('/api-v1.2.1/maps/deleteRoute', routeRequest, 'post').then(() => {
			for (let i = 0; i < route.Destinations.length; i++) {
				route.Destinations[i].isRouted = false;
			}
			for (let i = 0; i < this.allRoutes.length; i++) {
				if (this.allRoutes[i].RouteId == routeRequest.RouteId) {
					this.allRoutes.splice(i, 1);
					break;
				}
			}
			return true;
		});
	}
}
