import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { territoryManagementReducer } from './territory-management.reducer';
import { EffectsModule } from '@ngrx/effects';
import { TerritoryManagementEffects } from './territory-management.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('territoryManagement', territoryManagementReducer),
    EffectsModule.forFeature([TerritoryManagementEffects])
  ]
})
export class TerritoryManagementStateModule {}
