import { MemoizedSelector, createSelector } from '@ngrx/store';
import { ProjectField } from 'src/app/models/admin/projects/project-field';
import { selectAllProjectFields } from './all-projectFields-as-array.selector';
import { RootState } from 'src/app/store/store.reducer';
import { GenericMap } from 'src/app/models/generic-map';

export const selectedProjectFieldId = (state: RootState) =>
  state.projectFields.selectedProjectFieldId;

export const selectSelectedProjectField: MemoizedSelector<
  RootState,
  ProjectField
> = createSelector(
  selectAllProjectFields,
  selectedProjectFieldId,
  (projectFieldMap: GenericMap<ProjectField>, projectFieldId: number): ProjectField => {
    let projectField: ProjectField;
    if (!!projectFieldId && projectFieldMap[projectFieldId]) {
      const id = projectFieldId.toString();
      projectField = {
        ...projectFieldMap[id]
      };
    }
    return projectField;
  }
);
