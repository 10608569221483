import {
  Component,
  OnInit,
  ViewChild,
  ComponentFactoryResolver,
  Type
} from '@angular/core';
import { Store } from '@ngrx/store';
import { DrawerItem } from './../drawer/drawer-item';
import { DrawerDirective } from '../drawer-host.directive';
import { RootState } from 'src/app/store/store.reducer';

@Component({
  selector: 'tn-drawer-left',
  templateUrl: './drawer-left.component.html',
  styleUrls: ['./drawer-left.component.scss']
})
export class DrawerLeftComponent implements OnInit {
  @ViewChild(DrawerDirective, { static: true }) drawerHost: DrawerDirective;
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private store: Store<RootState>
  ) {}

  ngOnInit() {
    this.store.select('layout', 'leftDrawerContents').subscribe((item: DrawerItem) => {
      if (!!item.component) {
        this.loadComponent(item.component, item.data);
      }
    });
  }

  loadComponent(component: Type<any>, data: any) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      component
    );

    const viewContainerRef = this.drawerHost.viewContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent(componentFactory);
    (componentRef.instance as DrawerItem).data = data;
  }
}
