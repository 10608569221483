export enum CompanyPermissions {
  ViewCompanies = 31,
  ExportCompanies = 36,
  AddEditDeleteCompanies = 37,
  MergeCompanies = 43,
  Assign = 44,
  Link = 45,
  Import = 46,
  BulkDeleteCompanies = 47,
  BulkEditCompanies = 48,
  Contacts = 49,
  BulkDeleteContacts = 52,
  BulkEditContacts = 53,
  ExportContacts = 54,
  MergeContacts = 55
}

export enum ActivityPermissions {
  CallNotes = 56,
  ExportCallNotes = 59,
  BulkDeleteCallNotes = 60,
  BulkEditCallNotes = 61,
  AssignTasks = 62,
  Mileage = 63,
  Expenses = 64
}

export enum SalesPermissions {
  ViewSales = 1,
  SalesHistory = 7,
  SalesAdmin = 11,
  Import = 15,
  EditDeleteInvoices = 16,
  ManualSalesDataEntry = 17,
  ManageCommissions = 18,
  ManageCustomFields = 19,
  ManageManufacturers = 20,
  CloseMonths = 21,
  EditTeamReports = 22,
  ViewCommissions = 23,
  ViewAllSalesRepCommissions = 65,
  ViewAgencyCommissions = 66
}

export enum MapsPermissions {
  Routes = 29,
  Leads = 30
}

export enum AdminPermissions {
  Roles = 67,
  Users = 71,
  Territories = 75,
  Customization = 79,
  EditDarLists = 123
}

export enum AppsPermissions {
  CompanyLabels = 112,
  CallTypes = 111,
  CallNoteCheckboxFields = 117,
  AllowUploadingBusinessCards = 119
}
