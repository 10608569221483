import { RouteData } from '../models/routing/route-data.model';

export const userOptionsRouteData: {
  [permissionsLevel: string]: RouteData;
} = {
  help: {
    tutorials: {
      icon: 'play_circle_filled',
      path: 'tutorials',
      title: 'Tutorials'
    }
    // whatsNew: {
    //   icon: 'info',
    //   path: 'whats-new',
    //   title: 'What\'s New'
    // }
  },
  user: {
    userSettings: {
      icon: 'account_circle',
      path: 'admin/usersettings/nylassync',
      title: 'User Settings'
    }
  },
  admin: {
    systemSettings: {
      icon: 'settings',
      path: 'system-settings/callnotes',
      title: 'System Settings'
    },
    territoryManagement: {
      icon: 'map',
      path: 'territory-management',
      title: 'Territory Management'
    },
    manageUsers: {
      icon: 'people',
      path: 'admin/roles',
      title: 'Manage Users'
    }
  },
  owner: {
    clients: {
      icon: 'work',
      path: 'clients',
      title: 'Clients'
    },
    plans: {
      icon: 'list_alt',
      path: 'plans',
      title: 'Plans'
    }
    // billing: {
    //   icon: 'payment',
    //   path: 'billing',
    //   title: 'Billing'
    // }
  }
};
