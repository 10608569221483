<div
  class="company-form-container"
  fxLayout="column"
  fxLayoutAlign="space-between center"
>
  <div fxLayout="column">
    <div fxLayout="row">
      <h3 [style.marginTop]="'4px'">
        {{
          !!data && !!data.company && !!data.company.id ? 'Edit Company' : 'New Company'
        }}
      </h3>
    </div>

    <!--  COLUMNS -->
    <div fxLayout="row" class="columns-container">
      <!-- Column one -->
      <div class="form-column" fxLayout="column">
        <mat-form-field>
          <input
            matInput
            autocomplete="off"
            required
            placeholder="Company Name"
            [formControl]="companyNameCtrl"
          />
        </mat-form-field>
        <form [formGroup]="billingAddressForm" fxLayout="column">
          <mat-form-field>
            <input
              matInput
              autocomplete="off"
              formControlName="addressLine1"
              placeholder="Address Line 1"
            />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              autocomplete="off"
              formControlName="addressLine2"
              placeholder="Address Line 2"
            />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              autocomplete="off"
              formControlName="city"
              placeholder="City"
            />
          </mat-form-field>
          <div fxLayout="row" fxLayoutGap="12px">
            <mat-form-field fxFlex="65">
              <input
                matInput
                autocomplete="off"
                formControlName="state"
                placeholder="State / Province / Region"
              />
            </mat-form-field>
            <mat-form-field fxFlex="35">
              <input
                matInput
                autocomplete="off"
                formControlName="postCode"
                placeholder="ZIP / Postal Code"
              />
            </mat-form-field>
          </div>
          <mat-form-field>
            <input
              matInput
              autocomplete="off"
              formControlName="county"
              placeholder="County"
            />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              autocomplete="off"
              formControlName="country"
              placeholder="Country"
            />
          </mat-form-field>
        </form>

        <br />
        <mat-checkbox (change)="sameAddressChange($event)">
          Alternate Address same as above</mat-checkbox
        >
        <form [formGroup]="shippingAddressForm" fxLayout="column">
          <mat-form-field>
            <input
              matInput
              autocomplete="off"
              formControlName="addressLine1"
              placeholder="Alternate Address Line 1"
            />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              autocomplete="off"
              formControlName="addressLine2"
              placeholder="Alternate Address Line 2"
            />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              autocomplete="off"
              formControlName="city"
              placeholder="Alternate City"
            />
          </mat-form-field>
          <div fxLayout="row" fxLayoutGap="12px">
            <mat-form-field fxFlex="65">
              <input
                matInput
                autocomplete="off"
                formControlName="state"
                placeholder="State / Province / Region"
              />
            </mat-form-field>
            <mat-form-field fxFlex="35">
              <input
                matInput
                autocomplete="off"
                formControlName="postCode"
                placeholder="ZIP / Postal Code"
              />
            </mat-form-field>
          </div>
          <mat-form-field>
            <input
              matInput
              autocomplete="off"
              formControlName="county"
              placeholder="Alternate County"
            />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              autocomplete="off"
              formControlName="country"
              placeholder="Alternate Country"
            />
          </mat-form-field>
        </form>
      </div>

      <!-- Column two -->
      <div class="form-column" fxLayout="column">
        <form [formGroup]="contactInfoForm" fxLayout="column">
          <mat-form-field>
            <input
              matInput
              autocomplete="off"
              formControlName="phone"
              placeholder="Phone"
            />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              autocomplete="off"
              formControlName="alternatePhone"
              placeholder="Alternate Phone"
            />
          </mat-form-field>
          <mat-form-field>
            <input matInput autocomplete="off" formControlName="fax" placeholder="Fax" />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              autocomplete="off"
              formControlName="companyEmail"
              placeholder="Company Email"
            />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              autocomplete="off"
              formControlName="website"
              placeholder="Website"
            />
          </mat-form-field>
        </form>
        <br />
        <form [formGroup]="socialInfoForm" fxLayout="column">
          <mat-form-field>
            <input
              matInput
              autocomplete="off"
              formControlName="linkedIn"
              placeholder="LinkedIn"
            />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              autocomplete="off"
              formControlName="twitter"
              placeholder="Twitter"
            />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              autocomplete="off"
              formControlName="facebook"
              placeholder="Facebook"
            />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              autocomplete="off"
              formControlName="instagram"
              placeholder="Instagram"
            />
          </mat-form-field>
        </form>
        <h4 class="company-form-label">Company Links</h4>
        <div class="company-links" fxLayout="column" fxLayoutGap="8px">
          <div
            class="link-container"
            fxLayout="row"
            fxLayoutAlign="start center"
            *ngFor="let link of companyLinks"
          >
            <a [href]="'//' + link" target="_blank" class="company-link">{{ link }}</a>
            <button mat-icon-button color="accent" (click)="onRemoveLink(link)">
              <mat-icon>clear</mat-icon>
            </button>
          </div>
          <mat-form-field>
            <input
              matInput
              autocomplete="off"
              type="text"
              #addLinkInput
              placeholder="Add link"
              (keydown.enter)="addLink(addLinkInput.value); $event.preventDefault()"
              autocomplete="off"
            />
            <button
              mat-icon-button
              color="primary"
              matSuffix
              (click)="addLink(addLinkInput.value)"
            >
              <mat-icon>add_circle</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>

      <!-- Column three -->
      <div class="form-column" fxLayout="column">
        <form [formGroup]="companyForm" fxLayout="column">
          <mat-form-field class="full-width">
            <mat-label>Territories</mat-label>
            <mat-select class="selector-margin" formControlName="territories" multiple>
              <mat-option *ngFor="let territory of territories" [value]="territory">
                {{ territory.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div fxLayout="row" fxLayoutGap="12px">
            <mat-form-field fxFlex="50">
              <mat-label>Primary Owner</mat-label>
              <mat-select formControlName="primaryUser" [compareWith]="compareUsers">
                <mat-option *ngFor="let user of $allUsers | async" [value]="user">
                  {{ user.firstName }} {{ user.lastName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field fxFlex="50">
              <mat-label>Secondary Owner</mat-label>
              <mat-select formControlName="secondaryRepId">
                <mat-option *ngFor="let user of $allUsers | async" [value]="user.id">
                  {{ user.firstName }} {{ user.lastName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <!-- <tn-item-select
              fxFlex="50"
              [placeholder]="'Primary Owner'"
              [$selectableItems]="$allUsers"
              [selectedItem]="companyForm?.controls?.primaryUser.id"
              (itemSelected)="onAssignedUserSelected($event)"
              [isRequired]="false"
              [hasValidator]="false"
            ></tn-item-select>
            <tn-item-select
              fxFlex="50"
              [placeholder]="'Secondary Owner'"
              [$selectableItems]="$allUsers"
              [selectedItem]="companyForm?.controls?.secondaryRepId"
              (itemSelected)="onAssignedSecondaryUserSelected($event)"
              [isRequired]="false"
              [hasValidator]="false"
            ></tn-item-select> -->
          </div>
          <tn-company-select
            (companySearchEvent)="onCompanySearch($event)"
            [$companySearchResults]="$companies"
            [placeholder]="'Parent company'"
            [$error]="$companiesSearchError"
            [isRequired]="false"
            (companySelected)="onCompanySelect($event)"
            [$pending]="$companiesSearchPending"
          ></tn-company-select>

          <div fxLayout="row" fxLayoutGap="12px">
            <mat-form-field fxFlex="50">
              <mat-label>Contract Expiration</mat-label>
              <input
                formControlName="contractExpiration"
                matInput
                autocomplete="off"
                [matDatepicker]="picker"
              />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field fxFlex="50">
              <input
                matInput
                autocomplete="off"
                formControlName="annualRevenue"
                placeholder="Annual Revenue"
                type="number"
              />
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayoutGap="12px">
            <mat-form-field fxFlex="50">
              <input
                matInput
                autocomplete="off"
                formControlName="employees"
                type="number "
                placeholder="Number of Employees"
                type="number"
              />
            </mat-form-field>
            <mat-form-field fxFlex="50">
              <input
                matInput
                autocomplete="off"
                formControlName="locations"
                placeholder="Number of Locations"
                type="number"
              />
            </mat-form-field>
          </div>

          <mat-form-field>
            <input
              matInput
              autocomplete="off"
              formControlName="leadSource"
              placeholder="Lead Source"
            />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              autocomplete="off"
              formControlName="referralSource"
              placeholder="Referral Source"
            />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              autocomplete="off"
              formControlName="accountId"
              placeholder="Account ID"
            />
          </mat-form-field>
          <mat-form-field>
            <textarea
              matInput
              autocomplete="off"
              tnAutosizeTextarea
              formControlName="notes"
              placeholder="Notes"
            ></textarea>
          </mat-form-field>
        </form>
        <form [formGroup]="labelsForm" fxLayout="column">
          <mat-form-field *ngFor="let group of allLabelGroupsArray">
            <mat-label>{{ group.name }} </mat-label>
            <mat-select
              [required]="group.required"
              [formControlName]="group.id"
              (selectionChange)="labelChange($event)"
              [multiple]="group.allowMultiple"
            >
              <mat-option [value]="null"> No selection </mat-option>
              <mat-option *ngFor="let label of group.labels" [value]="label.id">
                {{ label.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
    </div>
  </div>

  <div class="form-actions" fxLayout="row-reverse" fxLayoutAlign="space-between center">
    <div fxLayout="row-reverse" fxLayoutGap="12px">
      <button
        type="button"
        mat-raised-button
        color="primary"
        mat-button
        [disabled]="
          companyNameCtrl.invalid ||
          shippingAddressForm.invalid ||
          billingAddressForm.invalid ||
          contactInfoForm.invalid ||
          socialInfoForm.invalid ||
          companyForm.invalid ||
          labelsForm.invalid ||
          (companyNameCtrl.pristine &&
            shippingAddressForm.pristine &&
            billingAddressForm.pristine &&
            contactInfoForm.pristine &&
            socialInfoForm.pristine &&
            companyForm.pristine &&
            labelsForm.pristine)
        "
        (click)="submitHandler()"
      >
        Save
      </button>
      <button
        preventDefaultAction
        type="button"
        mat-raised-button
        color="white"
        mat-button
        (click)="cancelButtonClicked()"
      >
        Cancel
      </button>
    </div>
    <div
      *ngIf="!!data && !!data.company; else showAddContacts"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      fxLayoutGap="12px"
    >
      <button
        preventDefaultAction
        mat-raised-button
        color="warn"
        (click)="deleteCompany()"
      >
        Delete Company
      </button>
    </div>
    <ng-template #showAddContacts>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="12px">
        <button mat-raised-button color="primary" (click)="addNewContact()">
          Add Contact <mat-icon>person_add</mat-icon>
        </button>
        <mat-chip-list>
          <mat-chip
            *ngFor="let contact of $companyContacts | async; let i = index"
            [removable]="true"
            (click)="editNewContact(contact, i)"
            (removed)="removeNewContact(i)"
            >{{ contact.firstName }} {{ contact.lastName }}
            <mat-icon matChipRemove>cancel</mat-icon></mat-chip
          >
        </mat-chip-list>
      </div>
    </ng-template>
  </div>
</div>
