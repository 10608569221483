import {
  OpportunityLabelsIndividualActions,
  OpportunityLabelsIndividualActionTypes
} from './opportunity-labels-individual.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { Label } from 'src/app/models/label';
import { selectedOpportunityLabelId } from '../opportunity-labels/selectors/selected-opportunityLabels.selector';

export const opportunityLabelsIndividualFeatureKey = 'opportunityLabelsIndividual';

export interface OpportunityLabelsIndividualState {
  pending: boolean;
  error: HttpErrorResponse;
  data: { [id: number]: Label };
  selectedOpportunityLabelIndividualId: number;
}

export const initialState: OpportunityLabelsIndividualState = {
  pending: false,
  data: {},
  error: undefined,
  selectedOpportunityLabelIndividualId: undefined
};

export function opportunityLabelsIndividualReducer(
  state = initialState,
  action: OpportunityLabelsIndividualActions
): OpportunityLabelsIndividualState {
  switch (action.type) {
    case OpportunityLabelsIndividualActionTypes.SelectOpportunityLabelIndividualId:
      return {
        ...state,
        selectedOpportunityLabelIndividualId: action.payload
      };

    case OpportunityLabelsIndividualActionTypes.ClearOpportunityLabelIndividualId:
      return {
        ...state,
        selectedOpportunityLabelIndividualId: undefined
      };

    case OpportunityLabelsIndividualActionTypes.LoadOpportunityLabelsIndividuals:
      return {
        ...state,
        pending: true
      };

    case OpportunityLabelsIndividualActionTypes.LoadOpportunityLabelsIndividualsSuccess:
      return {
        ...state,
        pending: false,
        error: undefined,
        data: action.payload
      };

    case OpportunityLabelsIndividualActionTypes.LoadOpportunityLabelsIndividualsFailure:
      return {
        ...state,
        pending: false,
        data: {},
        error: action.payload
      };

    case OpportunityLabelsIndividualActionTypes.CreateOpportunityLabelsIndividual:
      return {
        ...state,
        pending: true
      };

    case OpportunityLabelsIndividualActionTypes.CreateOpportunityLabelsIndividualSuccess:
      return {
        ...state,
        pending: false,
        data: {
          ...state.data,
          [action.payload.id]: action.payload
        }
      };

    case OpportunityLabelsIndividualActionTypes.CreateOpportunityLabelsIndividualFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case OpportunityLabelsIndividualActionTypes.UpdateOpportunityLabelsIndividual:
      return {
        ...state,
        pending: true
      };

    case OpportunityLabelsIndividualActionTypes.UpdateOpportunityLabelsIndividualSuccess:
      return {
        ...state,
        pending: false,
        data: {
          ...state.data,
          [action.payload.id]: action.payload
        }
      };

    case OpportunityLabelsIndividualActionTypes.UpdateOpportunityLabelsIndividualFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case OpportunityLabelsIndividualActionTypes.DeleteOpportunityLabelsIndividual:
      return {
        ...state,
        pending: true
      };

    case OpportunityLabelsIndividualActionTypes.DeleteOpportunityLabelsIndividualSuccess:
      return {
        ...state,
        pending: false,
        selectedOpportunityLabelIndividualId: undefined
      };

    case OpportunityLabelsIndividualActionTypes.DeleteOpportunityLabelsIndividualFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case OpportunityLabelsIndividualActionTypes.BulkUpdateOpportunityLabels:
      return {
        ...state,
        pending: true
      };

    case OpportunityLabelsIndividualActionTypes.BulkUpdateOpportunityLabelsSuccess:
      return {
        ...state,
        pending: false,
        data: action.payload
      };

    case OpportunityLabelsIndividualActionTypes.BulkUpdateOpportunityLabelsFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case OpportunityLabelsIndividualActionTypes.SetOppLabelListOrder:
      return {
        ...state,
        pending: true
      };

    case OpportunityLabelsIndividualActionTypes.SetOppLabelListOrderSuccess:
      return {
        ...state,
        pending: false,
        data: action.payload
      };

    case OpportunityLabelsIndividualActionTypes.SetOppLabelListOrderFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    default:
      return state;
  }
}
