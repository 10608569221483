import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { LabelGroup } from 'src/app/models/label-group';
import { Label } from 'src/app/models/label';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OpportunitiesLabelsService {
  private jpiUrl = environment.jpiUrl;
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get(`${this.jpiUrl}/opportunitylabelgroups`);
  }

  getOpportunityLabelGroup(id: number) {
    return this.http.get(`${this.jpiUrl}/opportunitylabelgroups/${id}`);
  }

  addOpportunityLabel(opportunityLabel: LabelGroup) {
    return this.http.post(`${this.jpiUrl}/opportunitylabelgroups`, opportunityLabel);
  }

  updateOpportunityLabel(opportunityLabel: LabelGroup) {
    return this.http.put(
      `${this.jpiUrl}/opportunitylabelgroups/${opportunityLabel.id}`,
      opportunityLabel
    );
  }

  deleteOpportunityLabel(opportunityLabelId: number) {
    return this.http.delete(
      `${this.jpiUrl}/opportunitylabelgroups/${opportunityLabelId}`
    );
  }

  setLabelGroupListOrder(labelGroups: LabelGroup[]): Observable<LabelGroup[]> {
    return this.http.post(
      `${this.jpiUrl}/opportunitylabelgroups/setListOrder`,
      labelGroups
    ) as Observable<LabelGroup[]>;
  }
}
