import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, withLatestFrom, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as ProjectManufCustomFieldsActions from './project-manuf-custom-fields.actions';
import { ProjectCustomField } from 'src/app/models/projects/project-custom-field';
import { convertToMap } from 'src/app/utils/convertToMap';
import { selectAgencyId } from '../../companies/selectors/files-as-array.selector';
import { ProjectsService } from 'src/app/services/projects/projects.service';
import { RootState } from '../../store.reducer';
import { Store } from '@ngrx/store';

@Injectable()
export class ProjectManufCustomFieldsEffects {
  loadProjectManufCustomFields$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectManufCustomFieldsActions.LoadProjectManufCustomFields),
      concatMap(() =>
        this.projService.getProjectManufCustomFields().pipe(
          map((data: ProjectCustomField[]) =>
            ProjectManufCustomFieldsActions.LoadProjectManufCustomFieldsSuccess({
              data: convertToMap(data, 'id')
            })
          ),
          catchError(error =>
            of(
              ProjectManufCustomFieldsActions.LoadProjectManufCustomFieldsFailure({
                error
              })
            )
          )
        )
      )
    );
  });

  saveProjectManufCustomField$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectManufCustomFieldsActions.SaveProjectManufCustomField),
      map(action => action.data),
      withLatestFrom(this.store.select(selectAgencyId)),
      concatMap(([pcf, agencyId]: [ProjectCustomField, number]) => {
        if (!!pcf.id) {
          return this.projService.updateProjectManufCustomField(pcf).pipe(
            switchMap((data: ProjectCustomField) => [
              ProjectManufCustomFieldsActions.SaveProjectManufCustomFieldSuccess({
                data
              }),
              ProjectManufCustomFieldsActions.LoadProjectManufCustomFields()
            ]),
            catchError(error =>
              of(
                ProjectManufCustomFieldsActions.SaveProjectManufCustomFieldFailure({
                  error
                })
              )
            )
          );
        } else {
          return this.projService
            .createProjectManufCustomField({ ...pcf, agencyId })
            .pipe(
              switchMap(data => [
                ProjectManufCustomFieldsActions.SaveProjectManufCustomFieldSuccess({
                  data
                }),
                ProjectManufCustomFieldsActions.LoadProjectManufCustomFields()
              ]),
              catchError(error =>
                of(
                  ProjectManufCustomFieldsActions.LoadProjectManufCustomFieldsFailure({
                    error
                  })
                )
              )
            );
        }
      })
    );
  });

  deleteProjectManufCustomField$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectManufCustomFieldsActions.DeleteProjectManufCustomField),
      map(action => action.data),
      concatMap((pcf: ProjectCustomField) =>
        this.projService.deleteProjectManufCustomField(pcf).pipe(
          switchMap(data => [
            ProjectManufCustomFieldsActions.DeleteProjectManufCustomFieldSuccess({
              data
            }),
            ProjectManufCustomFieldsActions.LoadProjectManufCustomFields()
          ]),
          catchError(error =>
            of(
              ProjectManufCustomFieldsActions.DeleteProjectManufCustomFieldFailure({
                error
              })
            )
          )
        )
      )
    );
  });

  updateProjectManufCustomFieldsListOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectManufCustomFieldsActions.UpdateProjectManufCustomFieldsListOrder),
      map(action => action.data),
      concatMap((pCFs: ProjectCustomField[]) =>
        this.projService.updateProjectManufCustomFieldsListOrder(pCFs).pipe(
          map(data =>
            ProjectManufCustomFieldsActions.UpdateProjectManufCustomFieldsListOrderSuccess(
              {
                data
              }
            )
          ),
          catchError(error =>
            of(
              ProjectManufCustomFieldsActions.UpdateProjectManufCustomFieldsListOrderFailure(
                {
                  error
                }
              )
            )
          )
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private projService: ProjectsService,
    private store: Store<RootState>
  ) {}
}
