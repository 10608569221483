import { Action } from '@ngrx/store';

export enum ReportsActionTypes {
  LoadReports = '[Reports] Load Reports',
  LoadReportsSuccess = '[Reports] Load Reports Success',
  LoadReportsFailure = '[Reports] Load Reports Failure'
}

export class LoadReports implements Action {
  readonly type = ReportsActionTypes.LoadReports;
}

export class LoadReportsSuccess implements Action {
  readonly type = ReportsActionTypes.LoadReportsSuccess;
  constructor(public payload: { data: any }) {}
}

export class LoadReportsFailure implements Action {
  readonly type = ReportsActionTypes.LoadReportsFailure;
  constructor(public payload: { error: any }) {}
}

export type ReportsActions = LoadReports | LoadReportsSuccess | LoadReportsFailure;
