import { MemoizedSelector, createSelector } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import { ExpenseType } from 'src/app/models/activities/expense-type';

export const selectAllExpenseTypes = (state: RootState) => state.expenseTypes.data;

export const selectExpenseTypesAsArray: MemoizedSelector<
  RootState,
  ExpenseType[]
> = createSelector(
  selectAllExpenseTypes,
  (expenseTypesMap: { [id: number]: ExpenseType }): ExpenseType[] => {
    return Object.values(expenseTypesMap);
  }
);
