import { createAction, props } from '@ngrx/store';
import { GenericMap } from 'src/app/models/generic-map';
import { ProjectCustomField } from 'src/app/models/projects/project-custom-field';
import { HttpErrorResponse } from '@angular/common/http';

export const SetCurrentBidCustomFieldId = createAction(
  '[BidCustomFields] Set Current Bid Custom Field ID',
  props<{ id: number }>()
);

export const LoadBidCustomFields = createAction(
  '[BidCustomFields] Load Bid Custom Fields'
);

export const LoadBidCustomFieldsSuccess = createAction(
  '[BidCustomFields] Load Bid Custom Fields Success',
  props<{ data: GenericMap<ProjectCustomField> }>()
);

export const LoadBidCustomFieldsFailure = createAction(
  '[BidCustomFields] Load Bid Custom Fields Failure',
  props<{ error: HttpErrorResponse }>()
);

export const SaveBidCustomField = createAction(
  '[BidCustomFields] Save Bid Custom Field',
  props<{ data: ProjectCustomField }>()
);

export const SaveBidCustomFieldSuccess = createAction(
  '[BidCustomFields] Save Bid Custom Field Success',
  props<{ data: ProjectCustomField }>()
);

export const SaveBidCustomFieldFailure = createAction(
  '[BidCustomFields] Save Bid Custom Field Failure',
  props<{ error: HttpErrorResponse }>()
);

export const DeleteBidCustomField = createAction(
  '[BidCustomFields] Delete Bid Custom Field',
  props<{ data: ProjectCustomField }>()
);

export const DeleteBidCustomFieldSuccess = createAction(
  '[BidCustomFields] Delete Bid Custom Field Success',
  props<{ data: any }>()
);

export const DeleteBidCustomFieldFailure = createAction(
  '[BidCustomFields] Delete Bid Custom Field Failure',
  props<{ error: HttpErrorResponse }>()
);

export const UpdateBidCustomFieldsListOrder = createAction(
  '[BidCustomFields] Update Project Company Custom Fields List Order',
  props<{ data: ProjectCustomField[] }>()
);

export const UpdateBidCustomFieldsListOrderSuccess = createAction(
  '[BidCustomFields] Update Project Company Custom Fields List Order Success',
  props<{ data: ProjectCustomField[] }>()
);

export const UpdateBidCustomFieldsListOrderFailure = createAction(
  '[BidCustomFields] Update Project Company Custom Fields List Order Failure',
  props<{ error: HttpErrorResponse }>()
);
