import { MemoizedSelector, createSelector } from '@ngrx/store';
import { RootState } from '../../store.reducer';
import { GenericMap } from 'src/app/models/generic-map';
import { LabelGroup } from 'src/app/models/label-group';

export const selectAllCompanyLabels = (state: RootState) => state.companyLabels.data;

export const allCompanyLabelIdsAsArray: MemoizedSelector<
  RootState,
  string[]
> = createSelector(
  selectAllCompanyLabels,
  (companyLabelsMap: GenericMap<LabelGroup>): string[] => {
    return Object.values(companyLabelsMap)
      .map(labelGroup => labelGroup.labels.map(label => label.id))
      .flat();
  }
);
