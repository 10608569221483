import { Component, OnInit } from '@angular/core';

@Component({
    templateUrl: './company-profile-page.component.html',
    styleUrls: []
})
export class CompanyProfilePageComponent implements OnInit {

    ngOnInit() {

    }
}
