import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy,
  ViewChild,
  ElementRef
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { validateIdNameItemRequired } from '../validators/id-name-item.validator';
import { IdNameItem } from 'src/app/models/id-name-item';
import { Observable, EMPTY, Subscription } from 'rxjs';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger
} from '@angular/material/autocomplete';
import { startWith, mergeMap, map, tap } from 'rxjs/operators';

@Component({
  selector: 'tn-item-select',
  templateUrl: './item-select.component.html',
  styleUrls: ['./item-select.component.scss']
})
export class ItemSelectComponent implements OnInit, OnChanges, OnDestroy {
  @Input() $selectableItems: Observable<IdNameItem[]> = EMPTY;
  @Input() selectedItem: IdNameItem;
  @Input() placeholder: string;
  @Output() itemSelected = new EventEmitter<MatAutocompleteSelectedEvent>();
  @Output() isValid = new EventEmitter<boolean>();
  @Output() textCleared = new EventEmitter();
  @Input() $clearInputEvent: Observable<void> = EMPTY;
  @Input() hasValidator = true;
  @Input() isRequired = false;
  @Input() halfWidth = false;
  @Input() overrideSort = false;
  @Input() $focusEvent: Observable<void> = EMPTY;
  @Input() optionDetails: boolean = false;
  $filteredItems: Observable<IdNameItem[]>;
  itemInputCtrl = new FormControl(undefined);
  $clearInputSub: Subscription;
  $focusSub: Subscription;
  width: string;
  padding: string;

  @ViewChild('itemSearchInput', { static: true }) itemSearchInput: MatAutocompleteTrigger;
  @ViewChild('auto', { static: true }) auto: MatAutocompleteTrigger;
  ngOnInit() {
    if (this.hasValidator) {
      this.itemInputCtrl.setValidators(validateIdNameItemRequired);
    }
    if (this.isRequired) {
      this.itemInputCtrl.setValidators(Validators.required);
      this.itemInputCtrl.markAsTouched();
    }
    if (this.halfWidth) {
      this.width = '46%';
      this.padding = '0 4px';
    } else {
      this.width = '100%';
      this.padding = '0';
    }
    this.$filteredItems = this.itemInputCtrl.valueChanges.pipe(
      startWith(!!this.selectedItem ? this.selectedItem.name : ''),
      tap((val) => {
        if (val === '') {
          this.textCleared.emit();
        }
      }),
      mergeMap((itemFilterVal: string = '') => {
        return this.$selectableItems.pipe(
          map((items: IdNameItem[]) => {
            const returnItems = items.filter((item) => {
              if (typeof itemFilterVal === 'string') {
                this.checkAndEmitValidity();
                return item.name.toLowerCase().indexOf(itemFilterVal.toLowerCase()) >= 0;
              } else {
                return true;
              }
            });
            if (!this.overrideSort) {
              return returnItems.sort((a, b) => {
                const textA = a.name.toLowerCase();
                const textB = b.name.toLowerCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              });
            } else {
              return returnItems;
            }
          })
        );
      })
    );
    this.$clearInputSub = this.$clearInputEvent.subscribe(() => {
      this.itemInputCtrl.setValue('');
      this.selectedItem = null;
    });
    if (!this.itemInputCtrl.valid && this.selectedItem) {
      this.itemInputCtrl.setValue(this.selectedItem);
    }
    this.$focusSub = this.$focusEvent.subscribe(() => {
      // this.itemSearchInput.nativeElement.focus();
      // this.itemSearchInput.openPanel();
      // console.log({ itemSearchInput: this.itemSearchInput });
    });
  }

  ngOnChanges(): void {
    this.itemInputCtrl.setValue(this.selectedItem);
  }

  ngOnDestroy() {
    this.$clearInputSub.unsubscribe();
    this.$focusSub.unsubscribe();
  }

  checkAndEmitValidity() {
    this.isValid.emit(this.itemInputCtrl.valid);
  }

  displayFn(item?: IdNameItem): string | undefined {
    return item ? item.name : undefined;
  }

  onItemSelected(event: MatAutocompleteSelectedEvent) {
    this.itemSelected.emit(event.option.value);
  }

  formatOptionDetails(option: any) {
    const stage = option.labels[0]?.name ? option.labels[0]?.name : '---';
    const total = option.total?.toFixed(2) ? option.total?.toFixed(2) : '---';
    const date = new Date(option.estimatedCloseDate)?.toLocaleDateString()
      ? new Date(option.estimatedCloseDate)?.toLocaleDateString()
      : '---';

    return `Stage: ${stage} | Total: $${total} | Est. Close Date: ${date}`;
  }
}
