import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import {
  LoadMileageFailure,
  LoadMileageSuccess,
  MileageActionTypes,
  MileageActions,
  AddMileageSuccess,
  AddMileageFailure,
  UpdateMileageSuccess,
  UpdateMileageFailure,
  DeleteMileageSuccess,
  DeleteMileageFailure,
  AddMileage,
  UpdateMileage,
  DeleteMileage,
  LoadMileage
} from './mileage.actions';
import { MileageService } from '../../services/activities/mileage/mileage.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Mileage } from 'src/app/models/activities/mileage';
import { Action, Store } from '@ngrx/store';
import { convertToMap } from 'src/app/utils/convertToMap';
import { RootState } from '../store.reducer';

@Injectable()
export class MileageEffects {
  loadMileage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MileageActionTypes.LoadMileage),
      withLatestFrom(this.store$.select('mileage', 'queryParams', 'daterange')),
      mergeMap(([action, dateRange]: [LoadMileage, { begin: string; end: string }]) => {
        const queryDateRange: { begin: string; end: string } = !!action.payload
          ? {
              begin: action.payload.startDate,
              end: action.payload.endDate
            }
          : dateRange;
        return this.mileageService.getAll(queryDateRange).pipe(
          map((mileage: Mileage[]) => {
            return new LoadMileageSuccess(convertToMap(mileage, 'id'));
          }),
          catchError((error: HttpErrorResponse) => of(new LoadMileageFailure(error)))
        );
      })
    )
  );

  addMileage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MileageActionTypes.AddMileage),
      map((action: AddMileage) => action.payload),
      mergeMap((mileage: Mileage) => {
        return this.mileageService.addMileage(mileage).pipe(
          map((newMileage: Mileage) => new AddMileageSuccess(newMileage)),
          catchError((error: HttpErrorResponse) => of(new AddMileageFailure(error)))
        );
      })
    )
  );

  updateMileage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MileageActionTypes.UpdateMileage),
      map((action: UpdateMileage) => action.payload),
      mergeMap((mileage: Mileage) => {
        return this.mileageService.updateMileage(mileage).pipe(
          map((updatedMileage: Mileage) => new UpdateMileageSuccess(updatedMileage)),
          catchError((error: HttpErrorResponse) => of(new UpdateMileageFailure(error)))
        );
      })
    )
  );

  deleteMileage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MileageActionTypes.DeleteMileage),
      map((action: DeleteMileage) => action.payload),
      mergeMap((mileageId: number) =>
        this.mileageService.deleteMileage(mileageId).pipe(
          map(() => new DeleteMileageSuccess(mileageId)),
          catchError((error: HttpErrorResponse) => of(new DeleteMileageFailure(error)))
        )
      )
    )
  );

  reloadMileage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        MileageActionTypes.DeleteMileageSuccess,
        MileageActionTypes.UpdateMileageSuccess,
        MileageActionTypes.AddMileageSuccess
      ),
      map(() => new LoadMileage())
    )
  );

  constructor(
    private mileageService: MileageService,
    private actions$: Actions<MileageActions>,
    private store$: Store<RootState>
  ) {}
}
