import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { GlobalSearchStateData } from './global-search.reducer';

export enum GlobalSearchActionTypes {
  LoadGlobalSearch = '[GlobalSearch] Load Global Search',
  LoadGlobalSearchSuccess = '[GlobalSearch] Load Global Search Success',
  LoadGlobalSearchFailure = '[GlobalSearch] Load Global Search Failure',
  ClearSearchTerm = '[GlobalSearch] Clear Search Term'
}

export class LoadGlobalSearch implements Action {
  readonly type = GlobalSearchActionTypes.LoadGlobalSearch;
  constructor(public payload: string) {}
}

export class LoadGlobalSearchSuccess implements Action {
  readonly type = GlobalSearchActionTypes.LoadGlobalSearchSuccess;
  constructor(public payload: GlobalSearchStateData) {}
}

export class LoadGlobalSearchFailure implements Action {
  readonly type = GlobalSearchActionTypes.LoadGlobalSearchFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class ClearSearchTerm implements Action {
  readonly type = GlobalSearchActionTypes.ClearSearchTerm;
}

export type GlobalSearchActions =
  | LoadGlobalSearch
  | LoadGlobalSearchSuccess
  | LoadGlobalSearchFailure
  | ClearSearchTerm;
