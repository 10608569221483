import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import { CloseLeftDrawer } from '../../../store/layout/layout.actions';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { selectUserFullName } from 'src/app/store/auth/selectors/full-name.selector';
import { User } from 'src/app/models/admin/users/user';

@Component({
  selector: 'tn-contact-support',
  templateUrl: './contact-support.component.html',
  styleUrls: ['./contact-support.component.css']
})
export class ContactSupportComponent implements OnInit {
  supportURLSub: Subscription;
  supportURL: URL;
  $username: Observable<string>;
  $email: Observable<string>;
  $user: Observable<User>;

  constructor(private store: Store<RootState>) {}

  ngOnInit() {
    this.$username = this.store.select(selectUserFullName);
    this.$email = this.store.select('auth', 'data', 'jpi', 'user', 'email');
    this.$user = this.store.select('auth', 'data', 'jpi', 'user');
    this.supportURLSub = combineLatest([
      this.$username,
      this.$email,
      this.$user
    ]).subscribe(([username, email, user]) => {
      this.supportURL = new URL(
        `mailto:support@voze.com?subject=Support from ${username} at ${user.client.name}, ${email}`
      );
    });
  }

  cancel() {
    this.store.dispatch(new CloseLeftDrawer());
  }
}
