import { Action } from '@ngrx/store';
import { Category } from 'src/app/models/admin/files/category';
import { HttpErrorResponse } from '@angular/common/http';

export enum FileCategoriesActionTypes {
  SelectFileCategoriesId = '[FileCategories] Select FileCategories ID',
  ClearFileCategoriesId = '[FileCategories] Clear Selected FileCategories ID',

  LoadFileCategories = '[FileCategories] Load FileCategories',
  LoadFileCategoriesSuccess = '[FileCategories] Load ItemsNames Success',
  LoadFileCategoriesFailure = '[FileCategories] Load FileCategories Failure',

  CreateFileCategories = '[FileCategories] Create FileCategories',
  CreateFileCategoriesSuccess = '[FileCategories] Create FileCategories Success',
  CreateFileCategoriesFailure = '[FileCategories] Create FileCategories Failure',

  UpdateFileCategories = '[FileCategories] Update FileCategories',
  UpdateFileCategoriesSuccess = '[FileCategories] Update FileCategories Success',
  UpdateFileCategoriesFailure = '[FileCategories] Update FileCategories Failure',

  DeleteFileCategories = '[FileCategories] Delete FileCategories',
  DeleteFileCategoriesSuccess = '[FileCategories] Delete FileCategories Success',
  DeleteFileCategoriesFailure = '[FileCategories] Delete FileCategories Failure',

  LoadFileCategoriesTypes = '[FileCategories] Load FileCategories Types',
  LoadFileCategoriesTypesSuccess = '[FileCategories] Load FileCategories Types Success',
  LoadFileCategoriesTypesFailure = '[FileCategories] Load FileCategories Types Failure'
}

export class SelectFileCategoriesId implements Action {
  readonly type = FileCategoriesActionTypes.SelectFileCategoriesId;
  constructor(public payload: number) {}
}

export class ClearFileCategoriesId implements Action {
  readonly type = FileCategoriesActionTypes.ClearFileCategoriesId;
}

export class LoadFileCategories implements Action {
  readonly type = FileCategoriesActionTypes.LoadFileCategories;
}

export class LoadFileCategoriesSuccess implements Action {
  readonly type = FileCategoriesActionTypes.LoadFileCategoriesSuccess;
  constructor(public payload: Category[]) {}
}

export class LoadFileCategoriesFailure implements Action {
  readonly type = FileCategoriesActionTypes.LoadFileCategoriesFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class CreateFileCategories implements Action {
  readonly type = FileCategoriesActionTypes.CreateFileCategories;
  constructor(public payload: Category) {}
}

export class CreateFileCategoriesSuccess implements Action {
  readonly type = FileCategoriesActionTypes.CreateFileCategoriesSuccess;
  constructor(public payload: Category) {}
}

export class CreateFileCategoriesFailure implements Action {
  readonly type = FileCategoriesActionTypes.CreateFileCategoriesFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class UpdateFileCategories implements Action {
  readonly type = FileCategoriesActionTypes.UpdateFileCategories;
  constructor(public payload: Category) {}
}

export class UpdateFileCategoriesSuccess implements Action {
  readonly type = FileCategoriesActionTypes.UpdateFileCategoriesSuccess;
  constructor(public payload: Category) {}
}

export class UpdateFileCategoriesFailure implements Action {
  readonly type = FileCategoriesActionTypes.UpdateFileCategoriesFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class DeleteFileCategories implements Action {
  readonly type = FileCategoriesActionTypes.DeleteFileCategories;
  constructor(public payload: number) {}
}

export class DeleteFileCategoriesSuccess implements Action {
  readonly type = FileCategoriesActionTypes.DeleteFileCategoriesSuccess;
  constructor(public payload: number) {}
}

export class DeleteFileCategoriesFailure implements Action {
  readonly type = FileCategoriesActionTypes.DeleteFileCategoriesFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export type FileCategoriesActions =
  | SelectFileCategoriesId
  | ClearFileCategoriesId
  | LoadFileCategories
  | LoadFileCategoriesSuccess
  | LoadFileCategoriesFailure
  | CreateFileCategories
  | CreateFileCategoriesSuccess
  | CreateFileCategoriesFailure
  | UpdateFileCategories
  | UpdateFileCategoriesSuccess
  | UpdateFileCategoriesFailure
  | DeleteFileCategories
  | DeleteFileCategoriesSuccess
  | DeleteFileCategoriesFailure;
