import { Action } from '@ngrx/store';

export enum ManageUsersActionTypes {
  LoadManageUsers = '[ManageUsers] Load ManageUsers',
  LoadManageUsersSuccess = '[ManageUsers] Load ManageUsers Success',
  LoadManageUsersFailure = '[ManageUsers] Load ManageUsers Failure'
}

export class LoadManageUsers implements Action {
  readonly type = ManageUsersActionTypes.LoadManageUsers;
}

export class LoadManageUsersSuccess implements Action {
  readonly type = ManageUsersActionTypes.LoadManageUsersSuccess;
  constructor(public payload: { data: any }) {}
}

export class LoadManageUsersFailure implements Action {
  readonly type = ManageUsersActionTypes.LoadManageUsersFailure;
  constructor(public payload: { error: any }) {}
}

export type ManageUsersActions =
  | LoadManageUsers
  | LoadManageUsersSuccess
  | LoadManageUsersFailure;
