import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import { Subscription, Observable } from 'rxjs';
import { FieldType } from 'src/app/models/call-notes/field-type';
import { ProjectCustomField } from 'src/app/models/projects/project-custom-field';
import { map, withLatestFrom } from 'rxjs/operators';
// eslint-disable-next-line max-len
import { selectCurrentProjectCustomField } from 'src/app/store/system-settings/project-custom-fields/selectors/current-proj-custom-field.selector';
import {
  SetCurrentProjectCustomFieldId,
  SaveProjectCustomField,
  DeleteProjectCustomField
} from 'src/app/store/system-settings/project-custom-fields/project-custom-fields.actions';
import { CloseDrawer } from 'src/app/store/layout/layout.actions';

@Component({
  selector: 'tn-project-custom-field-form',
  templateUrl: './project-custom-field-form.component.html',
  styleUrls: ['./project-custom-field-form.component.scss']
})
export class ProjectCustomFieldFormComponent implements OnInit, OnDestroy {
  customFieldForm: FormGroup = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    fieldType: new FormControl(null, [Validators.required]),
    listOrder: new FormControl(null),
    id: new FormControl(null),
    agencyId: new FormControl(null)
  });
  $currentCustomFieldSub: Subscription;
  $customFieldsSub: Subscription;
  currentCustomField: ProjectCustomField;
  customFields: ProjectCustomField[];
  fieldTypes: FieldType[] = [];
  $fieldTypes: Observable<FieldType[]>;
  $fieldTypesSub: Subscription;
  constructor(private store: Store<RootState>) {}

  ngOnInit() {
    this.$fieldTypes = this.store.select('fieldTypes', 'fieldTypes').pipe(
      map((fieldTypes: FieldType[]) => {
        return fieldTypes.filter((fieldType: FieldType) => fieldType.projectField);
      })
    );
    this.$fieldTypesSub = this.$fieldTypes.subscribe((fieldTypes) => {
      this.fieldTypes = fieldTypes;
    });
    this.$customFieldsSub = this.store
      .select('projectCustomFields', 'data')
      .subscribe((fields) => (this.customFields = Object.values(fields)));
    this.$currentCustomFieldSub = this.store
      .select(selectCurrentProjectCustomField)
      .pipe(withLatestFrom(this.store.select('projectCustomFields', 'data')))
      .subscribe(([field, fields]) => {
        if (!!field && field.id) {
          this.currentCustomField = field;
        } else {
          this.currentCustomField = {
            name: null,
            listOrder: Object.keys(fields).length
          } as ProjectCustomField;
        }
        this.customFieldForm.patchValue(this.currentCustomField);
      });
  }

  ngOnDestroy(): void {
    this.$customFieldsSub.unsubscribe();
    this.$currentCustomFieldSub.unsubscribe();
    this.$fieldTypesSub.unsubscribe();
  }

  compareObjects(o1: any, o2: any) {
    if (!!o1 && !!o2) {
      return o1.name === o2.name && o1.id && o2.id;
    }
  }

  saveButtonClicked() {
    this.store.dispatch(
      SaveProjectCustomField({
        data: {
          ...this.customFieldForm.value,
          type: this.customFieldForm.controls.fieldType.value.id
        }
      })
    );
    this.store.dispatch(new CloseDrawer());
    this.store.dispatch(SetCurrentProjectCustomFieldId(null));
  }

  cancelButtonClicked() {
    this.store.dispatch(new CloseDrawer());
    this.store.dispatch(SetCurrentProjectCustomFieldId(null));
  }

  deleteButtonClicked() {
    this.store.dispatch(DeleteProjectCustomField({ data: this.currentCustomField }));
    this.store.dispatch(new CloseDrawer());
    this.store.dispatch(SetCurrentProjectCustomFieldId(null));
  }
}
