import { MemoizedSelector, createSelector } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import { Label } from 'src/app/models/label';

export const selectAllCompanyLabelsIndividual = (state: RootState) =>
  state.companyLabelsIndividual.data;

export const selectCompanyLabelsIndividualAsArray: MemoizedSelector<
  RootState,
  Label[]
> = createSelector(
  selectAllCompanyLabelsIndividual,
  (companyLabelsIndividualMap: { [id: number]: Label }): Label[] => {
    return Object.values(companyLabelsIndividualMap);
  }
);
