import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import { Subscription, Observable } from 'rxjs';
import { FieldType } from 'src/app/models/call-notes/field-type';
import { ProjectCustomField } from 'src/app/models/projects/project-custom-field';
import { map, take, withLatestFrom } from 'rxjs/operators';
import { CloseDrawer } from 'src/app/store/layout/layout.actions';
// eslint-disable-next-line max-len
import { selectCurrentBidCustomField } from 'src/app/store/system-settings/bid-custom-fields/selectors/current-bid-custom-field.selector';
import {
  SaveBidCustomField,
  SetCurrentBidCustomFieldId,
  DeleteBidCustomField
} from 'src/app/store/system-settings/bid-custom-fields/bid-custom-fields.actions';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDeleteComponent } from 'src/app/view/shared/confirm-delete/confirm-delete.component';

@Component({
  selector: 'tn-bid-custom-field-form',
  templateUrl: './bid-custom-field-form.component.html',
  styleUrls: ['./bid-custom-field-form.component.scss']
})
export class BidCustomFieldFormComponent implements OnInit, OnDestroy {
  customFieldForm: FormGroup = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    fieldType: new FormControl(null, [Validators.required]),
    listOrder: new FormControl(null),
    id: new FormControl(null),
    agencyId: new FormControl(null)
  });
  $currentCustomFieldSub: Subscription;
  $customFieldsSub: Subscription;
  currentCustomField: ProjectCustomField;
  customFields: ProjectCustomField[];
  fieldTypes: FieldType[] = [];
  $fieldTypes: Observable<FieldType[]>;
  $fieldTypesSub: Subscription;
  constructor(private store: Store<RootState>, private dialog: MatDialog) {}

  ngOnInit() {
    this.$fieldTypes = this.store.select('fieldTypes', 'fieldTypes').pipe(
      map((fieldTypes: FieldType[]) => {
        return fieldTypes.filter((fieldType: FieldType) => fieldType.projectBidField);
      })
    );
    this.$fieldTypesSub = this.$fieldTypes.subscribe((fieldTypes) => {
      this.fieldTypes = fieldTypes;
    });
    this.$customFieldsSub = this.store
      .select('bidCustomFields', 'data')
      .subscribe((fields) => (this.customFields = Object.values(fields)));
    this.$currentCustomFieldSub = this.store
      .select(selectCurrentBidCustomField)
      .pipe(withLatestFrom(this.store.select('bidCustomFields', 'data')))
      .subscribe(([field, fields]) => {
        if (!!field && field.id) {
          this.currentCustomField = field;
        } else {
          this.currentCustomField = {
            name: null,
            listOrder: Object.keys(fields).length
          } as ProjectCustomField;
        }
        this.customFieldForm.patchValue(this.currentCustomField);
      });
  }

  ngOnDestroy(): void {
    this.$customFieldsSub.unsubscribe();
    this.$currentCustomFieldSub.unsubscribe();
    this.$fieldTypesSub.unsubscribe();
  }

  compareObjects(o1: any, o2: any) {
    if (!!o1 && !!o2) {
      return o1.name === o2.name && o1.id && o2.id;
    }
  }

  saveButtonClicked() {
    this.store.dispatch(
      SaveBidCustomField({
        data: {
          ...this.customFieldForm.value,
          type: this.customFieldForm.controls.fieldType.value.id
        }
      })
    );
    this.store.dispatch(new CloseDrawer());
    this.store.dispatch(SetCurrentBidCustomFieldId(null));
  }

  cancelButtonClicked() {
    this.store.dispatch(new CloseDrawer());
    this.store.dispatch(SetCurrentBidCustomFieldId(null));
  }

  deleteButtonClicked() {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {});

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((willDelete: boolean) => {
        if (willDelete) {
          this.store.dispatch(DeleteBidCustomField({ data: this.currentCustomField }));
          this.store.dispatch(new CloseDrawer());
          this.store.dispatch(SetCurrentBidCustomFieldId(null));
        }
      });
  }
}
