import { RootState } from '../../../store/store.reducer';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { GenericMap } from 'src/app/models/generic-map';
import { Feature } from 'src/app/models/features/feature';
import { RouteDatum } from 'src/app/models/routing/route-datum.model';
import { routeData } from '../../route-data';
import { FeaturePermission } from 'src/app/models/features/feature-permission';

export const selectFeatures = (state: RootState) =>
  state.auth.data.api ? state.auth.data.api.Role.Features : undefined;

export const selectUseNewSales = (state: RootState) => state.sales.useNewSalesModule;

export const selectSidenavLinks: MemoizedSelector<RootState, RouteDatum[]> =
  createSelector(
    selectFeatures,
    selectUseNewSales,
    (features: GenericMap<Feature>, useNewSales: boolean): RouteDatum[] => {
      let availFeatureNames: string[] = [];
      if ((features != null || undefined) && Object.keys(features).length) {
        availFeatureNames = Object.values(features).map((feature: Feature) =>
          feature.Name.toLowerCase()
        );
      }

      if (!availFeatureNames.includes('dashboard')) {
        delete routeData.dashboard;
      }
      if (
        !availFeatureNames.includes('private notes') ||
        features[29]?.Permissions[168]?.Value.toString() === 'false'
      ) {
        delete routeData.privateNotes;
      }

      if (
        !availFeatureNames.includes('sales') ||
        (features[2].Permissions &&
          features[2].Permissions[1] &&
          features[2].Permissions[1].Value.toString() === '0')
      ) {
        delete routeData.sales;
      } else {
        if (useNewSales) {
          // if has sales permissions AND using new Sales Module
          routeData.sales.path = '/sales';
        }
      }
      if (
        !availFeatureNames.includes('projects') ||
        (features[13].Permissions &&
          features[13].Permissions[125] &&
          features[13].Permissions[125].Value.toString() !== 'true')
      ) {
        delete routeData.projects;
      }
      if (
        !availFeatureNames.includes('maps') ||
        !Object.values(features[3]?.Permissions || {}).filter(
          (fp: FeaturePermission) => fp.Value === 'true'
        ).length
      ) {
        delete routeData.maps;
      }
      if (
        !availFeatureNames.includes('companies') ||
        !Object.values(features[1].Permissions || {}).filter(
          (fp: FeaturePermission) =>
            fp.Value === 'true' || fp.Value !== '0' || fp.Name === 'Contacts'
        ).length
      ) {
        delete routeData.companies;
      }
      if (availFeatureNames.includes('opportunities')) {
        const opportunityPermissionValues: string[] = [];
        Object.values(features[20].Permissions || {}).forEach((fp: FeaturePermission) => {
          opportunityPermissionValues.push(fp.Value);
        });
        if (opportunityPermissionValues.includes('2')) {
          delete routeData.classicOpportunities;
        } else if (opportunityPermissionValues.includes('1')) {
          delete routeData.opportunities;
        } else {
          delete routeData.classicOpportunities;
          delete routeData.opportunities;
        }
      } else {
        delete routeData.classicOpportunities;
        delete routeData.opportunities;
      }
      if (availFeatureNames.includes('activity')) {
        const activityPermissions = Object.values(features[4].Permissions || {})
          .filter(
            (fp: FeaturePermission) =>
              fp.Value === 'true' ||
              parseInt(fp.Value, 10) > 0 ||
              fp.Name === 'Sales History'
          )
          .map((perm: FeaturePermission) => perm.Name.toLowerCase());
        if (!activityPermissions.includes('mileage')) {
          delete routeData.mileage;
        }
        if (!activityPermissions.includes('expenses')) {
          delete routeData.expenses;
        }
      }
      return Object.values(routeData);
    }
  );
