import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { opportunityLabelsReducer } from './opportunity-labels.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { OpportunityLabelsEffects } from './opportunity-labels.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('opportunityLabels', opportunityLabelsReducer),
    EffectsModule.forFeature([OpportunityLabelsEffects])
  ]
})
export class OpportunityLabelsStateModule {}
