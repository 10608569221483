import { Action } from '@ngrx/store';
import { Field } from 'src/app/models/call-notes/field';
import { HttpErrorResponse } from '@angular/common/http';
import { DropdownOption } from 'src/app/models/dropdown-option';
import { GenericMap } from 'src/app/models/generic-map';

export enum CallNoteCustomFieldsActionTypes {
  SetCurrentCallNoteField = '[CallNoteCustomFields] Set Current Call Note Custom Field',
  ClearCurrentCallNoteField = '[CallNoteCustomFields] Clear Current Call Note Custom Field',
  LoadCallNoteCustomFields = '[CallNoteCustomFields] Load CallNoteCustomFields',
  LoadCallNoteCustomFieldsSuccess = '[CallNoteCustomFields] Load CallNoteCustomFields Success',
  LoadCallNoteCustomFieldsFailure = '[CallNoteCustomFields] Load CallNoteCustomFields Failure',
  CreateCallNoteCustomField = '[CallNoteCustomFields] Create CallNoteCustomFields',
  CreateCallNoteCustomFieldSuccess = '[CallNoteCustomFields] Create CallNoteCustomFields Success',
  CreateCallNoteCustomFieldFailure = '[CallNoteCustomFields] Create CallNoteCustomFields Failure',
  UpdateCallNoteCustomField = '[CallNoteCustomFields] Update Load CallNoteCustomFields',
  UpdateCallNoteCustomFieldSuccess = '[CallNoteCustomFields] Update CallNoteCustomFields Success',
  UpdateCallNoteCustomFieldFailure = '[CallNoteCustomFields] Update CallNoteCustomFields Failure',
  DeleteCallNoteCustomField = '[CallNoteCustomFields] Delete CallNoteCustomFields',
  DeleteCallNoteCustomFieldSuccess = '[CallNoteCustomFields] Delete CallNoteCustomFields Success',
  DeleteCallNoteCustomFieldFailure = '[CallNoteCustomFields] Delete CallNoteCustomFields Failure',

  LoadCallNoteDropdownOptions = '[CallNoteCustomFields] Load CallNoteDropdownOptions',
  LoadCallNoteDropdownOptionsSuccess = '[CallNoteCustomFields] Load CallNoteDropdownOptions Success',
  LoadCallNoteDropdownOptionsFailure = '[CallNoteCustomFields] Load CallNoteDropdownOptions Failure',
  SaveCallNoteDropdownOption = '[CallNoteCustomFields] Save Load CallNoteDropdownOptions',
  SaveCallNoteDropdownOptionSuccess = '[CallNoteCustomFields] Save CallNoteDropdownOptions Success',
  SaveCallNoteDropdownOptionFailure = '[CallNoteCustomFields] Save CallNoteDropdownOptions Failure',
  DeleteCallNoteDropdownOption = '[CallNoteCustomFields] Delete CallNoteDropdownOptions',
  DeleteCallNoteDropdownOptionSuccess = '[CallNoteCustomFields] Delete CallNoteDropdownOptions Success',
  DeleteCallNoteDropdownOptionFailure = '[CallNoteCustomFields] Delete CallNoteDropdownOptions Failure',
  UpdateCallNoteDropdownOptionsOrder = '[CallNoteCustomFields] Update Load CallNoteDropdownOptionsOrder',
  UpdateCallNoteDropdownOptionsOrderSuccess = '[CallNoteCustomFields] Update CallNoteDropdownOptionsOrder Success',
  UpdateCallNoteDropdownOptionsOrderFailure = '[CallNoteCustomFields] Update CallNoteDropdownOptionsOrder Failure'
}

export class SetCurrentCallNoteField implements Action {
  readonly type = CallNoteCustomFieldsActionTypes.SetCurrentCallNoteField;
  constructor(public payload: Field) {}
}

export class ClearCurrentCallNoteField implements Action {
  readonly type = CallNoteCustomFieldsActionTypes.ClearCurrentCallNoteField;
}

export class LoadCallNoteCustomFields implements Action {
  readonly type = CallNoteCustomFieldsActionTypes.LoadCallNoteCustomFields;
}

export class LoadCallNoteCustomFieldsSuccess implements Action {
  readonly type = CallNoteCustomFieldsActionTypes.LoadCallNoteCustomFieldsSuccess;
  constructor(public payload: Field[]) {}
}

export class LoadCallNoteCustomFieldsFailure implements Action {
  readonly type = CallNoteCustomFieldsActionTypes.LoadCallNoteCustomFieldsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class CreateCallNoteCustomField implements Action {
  readonly type = CallNoteCustomFieldsActionTypes.CreateCallNoteCustomField;
  constructor(public payload: Field) {}
}

export class CreateCallNoteCustomFieldSuccess implements Action {
  readonly type = CallNoteCustomFieldsActionTypes.CreateCallNoteCustomFieldSuccess;
  constructor(public payload: Field) {}
}

export class CreateCallNoteCustomFieldFailure implements Action {
  readonly type = CallNoteCustomFieldsActionTypes.CreateCallNoteCustomFieldFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class UpdateCallNoteCustomField implements Action {
  readonly type = CallNoteCustomFieldsActionTypes.UpdateCallNoteCustomField;
  constructor(public payload: Field) {}
}

export class UpdateCallNoteCustomFieldSuccess implements Action {
  readonly type = CallNoteCustomFieldsActionTypes.UpdateCallNoteCustomFieldSuccess;
  constructor(public payload: Field) {}
}

export class UpdateCallNoteCustomFieldFailure implements Action {
  readonly type = CallNoteCustomFieldsActionTypes.UpdateCallNoteCustomFieldFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class DeleteCallNoteCustomField implements Action {
  readonly type = CallNoteCustomFieldsActionTypes.DeleteCallNoteCustomField;
  constructor(public payload: number) {}
}

export class DeleteCallNoteCustomFieldSuccess implements Action {
  readonly type = CallNoteCustomFieldsActionTypes.DeleteCallNoteCustomFieldSuccess;
  constructor(public payload: number) {}
}

export class DeleteCallNoteCustomFieldFailure implements Action {
  readonly type = CallNoteCustomFieldsActionTypes.DeleteCallNoteCustomFieldFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadCallNoteDropdownOptions implements Action {
  readonly type = CallNoteCustomFieldsActionTypes.LoadCallNoteDropdownOptions;
  constructor(public payload: number) {}
}
export class LoadCallNoteDropdownOptionsSuccess implements Action {
  readonly type = CallNoteCustomFieldsActionTypes.LoadCallNoteDropdownOptionsSuccess;
  constructor(
    public payload: { fieldId: number; dropdownOptions: GenericMap<DropdownOption> }
  ) {}
}
export class LoadCallNoteDropdownOptionsFailure implements Action {
  readonly type = CallNoteCustomFieldsActionTypes.LoadCallNoteDropdownOptionsFailure;
  constructor(public payload: { fieldId: number; error: HttpErrorResponse }) {}
}
export class SaveCallNoteDropdownOption implements Action {
  readonly type = CallNoteCustomFieldsActionTypes.SaveCallNoteDropdownOption;
  constructor(public payload: DropdownOption) {}
}
export class SaveCallNoteDropdownOptionSuccess implements Action {
  readonly type = CallNoteCustomFieldsActionTypes.SaveCallNoteDropdownOptionSuccess;
  constructor(public payload: DropdownOption) {}
}
export class SaveCallNoteDropdownOptionFailure implements Action {
  readonly type = CallNoteCustomFieldsActionTypes.SaveCallNoteDropdownOptionFailure;
  constructor(public payload: { error: HttpErrorResponse; option: DropdownOption }) {}
}
export class DeleteCallNoteDropdownOption implements Action {
  readonly type = CallNoteCustomFieldsActionTypes.DeleteCallNoteDropdownOption;
  constructor(public payload: DropdownOption) {}
}
export class DeleteCallNoteDropdownOptionSuccess implements Action {
  readonly type = CallNoteCustomFieldsActionTypes.DeleteCallNoteDropdownOptionSuccess;
  constructor(public payload: DropdownOption) {}
}
export class DeleteCallNoteDropdownOptionFailure implements Action {
  readonly type = CallNoteCustomFieldsActionTypes.DeleteCallNoteDropdownOptionFailure;
  constructor(public payload: { error: HttpErrorResponse; option: DropdownOption }) {}
}

export class UpdateCallNoteDropdownOptionsOrder implements Action {
  readonly type = CallNoteCustomFieldsActionTypes.UpdateCallNoteDropdownOptionsOrder;
  constructor(public payload: { fieldId: number; options: DropdownOption[] }) {}
}
export class UpdateCallNoteDropdownOptionsOrderSuccess implements Action {
  readonly type =
    CallNoteCustomFieldsActionTypes.UpdateCallNoteDropdownOptionsOrderSuccess;
  constructor(
    public payload: { fieldId: number; optionsMap: GenericMap<DropdownOption> }
  ) {}
}
export class UpdateCallNoteDropdownOptionsOrderFailure implements Action {
  readonly type =
    CallNoteCustomFieldsActionTypes.UpdateCallNoteDropdownOptionsOrderFailure;
  constructor(public payload: { fieldId: number; error: HttpErrorResponse }) {}
}

export type CallNoteCustomFieldsActions =
  | SetCurrentCallNoteField
  | ClearCurrentCallNoteField
  | LoadCallNoteCustomFields
  | LoadCallNoteCustomFieldsSuccess
  | LoadCallNoteCustomFieldsFailure
  | CreateCallNoteCustomField
  | CreateCallNoteCustomFieldSuccess
  | CreateCallNoteCustomFieldFailure
  | UpdateCallNoteCustomField
  | UpdateCallNoteCustomFieldSuccess
  | UpdateCallNoteCustomFieldFailure
  | DeleteCallNoteCustomField
  | DeleteCallNoteCustomFieldSuccess
  | DeleteCallNoteCustomFieldFailure
  | LoadCallNoteDropdownOptions
  | LoadCallNoteDropdownOptionsSuccess
  | LoadCallNoteDropdownOptionsFailure
  | SaveCallNoteDropdownOption
  | SaveCallNoteDropdownOptionSuccess
  | SaveCallNoteDropdownOptionFailure
  | DeleteCallNoteDropdownOption
  | DeleteCallNoteDropdownOptionSuccess
  | DeleteCallNoteDropdownOptionFailure
  | UpdateCallNoteDropdownOptionsOrder
  | UpdateCallNoteDropdownOptionsOrderSuccess
  | UpdateCallNoteDropdownOptionsOrderFailure;
