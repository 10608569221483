import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  Inject,
  SecurityContext
} from '@angular/core';
import { FollowUp } from 'src/app/models/call-notes/follow-up';
import { RootState } from 'src/app/store/store.reducer';
import { Store } from '@ngrx/store';
import {
  SelectCallNoteId,
  SaveCallNote
} from 'src/app/store/call-notes/call-notes.actions';
import { OpenDrawer } from 'src/app/store/layout/layout.actions';
import { CallNoteFormComponent } from 'src/app/view/shared/call-note-form/call-note-form.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CallNote } from 'src/app/models/call-notes/call-note';
import { CallNoteField } from 'src/app/models/call-notes/call-note-field';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'tn-project-follow-up',
  templateUrl: './project-follow-up.component.html',
  styleUrls: ['./project-follow-up.component.scss']
})
export class ProjectFollowUpComponent implements OnInit {
  @Input() showCompany = true;
  @Input() callNote: CallNote;

  task: SafeHtml;

  constructor(
    private store: Store<RootState>,
    public dialog: MatDialog,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    if (!!this.callNote.followUp.task) {
      this.task = this.domSanitizer.bypassSecurityTrustHtml(this.callNote.followUp.task);
    }
  }

  handleEditClick() {
    this.store.dispatch(new SelectCallNoteId(this.callNote.id));
    this.store.dispatch(
      new OpenDrawer({
        component: CallNoteFormComponent,
        data: { followUpOpen: true, callnoteId: this.callNote.id }
      })
    );
  }

  handleCheckbox(event: MatCheckboxChange) {
    this.store.dispatch(
      new SaveCallNote({
        ...this.callNote,
        fields: this.callNote.fields.map((field) => {
          return {
            field: {
              id: field.id
            },
            value: field.value
          } as unknown as CallNoteField;
        }),
        followUp: {
          ...this.callNote.followUp,
          complete: event.checked,
          completedDate: event.checked ? new Date() : null
        }
      })
    );
  }

  handleDeleteClick(callNote) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const dialogRef = this.dialog.open(CompanyFollowUpsDialog, {
      width: '250px',
      data: callNote
    });
  }
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'company-follow-ups-dialog',
  templateUrl: 'company-follow-ups-dialog.component.html'
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CompanyFollowUpsDialog {
  constructor(
    public dialogRef: MatDialogRef<CompanyFollowUpsDialog>,
    private store: Store<RootState>,
    @Inject(MAT_DIALOG_DATA) public data: CallNote
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  deleteFollowUp() {
    this.store.dispatch(
      new SaveCallNote({
        ...this.data,
        fields: this.data.fields.map((field) => {
          return {
            field: {
              id: field.id
            },
            dropdownId: field.dropdownId,
            value: field.value
          } as unknown as CallNoteField;
        }),
        followUp: null
      })
    );
    this.dialogRef.close();
  }
}
