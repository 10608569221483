import { RouteLeg } from './../models/route-leg';
import { Route } from './../models/route';
import { Injectable } from '@angular/core';

import { AuthenticationService } from '../../shared/services/authentication.service';

@Injectable()
export class RouteService {
	constructor(private authService: AuthenticationService) { }

	getRoutes(): Promise<Route[]> {
		return this.authService.makeJpiRequest('/routes', null, null, 'get').then((result: any) => {
			const routes = JSON.parse(result);
			routes.map((route: Route) => {
				this.sortRouteLegs(route);
				this.setRouteLetter(route);
			});
			return routes;
		});
	}

	getRouteById(route: Route): Promise<Route> {
		return this.authService.makeJpiRequest('/routes/' + route.id, null, null, 'get').then((result: any) => {
			const routeResults = this.sortRouteLegs(JSON.parse(result));
			this.setRouteLetter(routeResults);
			return routeResults;
		});
	}

	addRoute(route: Route, setPositions: boolean): Promise<Route> {
		if (setPositions) {
			for (let i = 0, x = 1; i < route.legs.length; i++ , x++) { route.legs[i].position = x; }
		}
		return this.authService.makeJpiRequest('/routes', null, route, 'post').then((result: any) => {
			const routeResults = this.sortRouteLegs(JSON.parse(result));
			this.setRouteLetter(routeResults);
			return routeResults;
		});
	}

	updateRoute(route: Route, setPositions: boolean): Promise<any> {
		if (setPositions) {
			for (let i = 0, x = 1; i < route.legs.length; i++ , x++) { route.legs[i].position = x; }
		}
		return this.authService.makeJpiRequest('/routes/' + route.id, null, route, 'put').then((result: any) => {
			const routeResults = this.sortRouteLegs(JSON.parse(result));
			this.setRouteLetter(routeResults);
			return routeResults;
		});
	}

	deleteRoute(route: Route): Promise<Route> {
		return this.authService.makeJpiRequest('/routes/' + route.id, null, route, 'delete').then((result: any) => {
			const routeResults = this.sortRouteLegs(JSON.parse(result));
			return routeResults;
		});
	}

	sortRoutesByLegs(routes: Route[]): Route[] {
		const sortedRoutes = routes.sort((a: Route, b: Route) => a.name.localeCompare(b.name));
		sortedRoutes.map((route: Route) => {
			route.legs.sort((a: RouteLeg, b: RouteLeg) => a.position - b.position);
		});
		return sortedRoutes;
	}

	sortRouteLegs(route: Route): Route {
		route.legs.sort((a: RouteLeg, b: RouteLeg) => a.position - b.position);
		return route;
	}

	setRouteLetter(route: Route): Route {
		for (let i = 0, x = 1; i < route.legs.length; i++ , x++) { (<any>route.legs[i]).routeLetter = String.fromCharCode(64 + x); }
		return route;
	}
}
