import { Injectable } from '@angular/core';

import { SalesPermissions } from '../../models/admin/permission-enums';
import { FeaturePermissionsService } from './feature-permissions.service';
import { AuthenticationService } from '../authentication.service';

@Injectable()
export class SalesPermissionsService {

	constructor(private featurePermissionsService: FeaturePermissionsService, private authService: AuthenticationService) { }

	getViewSalesPermission(): Promise<number> {
		return this.featurePermissionsService.getPermissionValue(this.authService.modules.Sales, SalesPermissions.ViewSales).then(result => {
			return result;
		});
	}

	getSalesHistoryPermission(): Promise<number> {
		return this.featurePermissionsService.getPermissionValue(this.authService.modules.Sales, SalesPermissions.SalesHistory).then(result => {
			return result;
		});
	}

	getSalesAdminPermission(): Promise<number> {
		return this.featurePermissionsService.getPermissionValue(this.authService.modules.Sales, SalesPermissions.SalesAdmin).then(result => {
			return result;
		});
	}

	getImportPermission(): Promise<boolean> {
		return this.featurePermissionsService.getPermissionValue(this.authService.modules.Sales, SalesPermissions.Import);
	}

	getEditDeleteInvoicesPermission(): Promise<boolean> {
		return this.featurePermissionsService.getPermissionValue(this.authService.modules.Sales, SalesPermissions.EditDeleteInvoices);
	}

	getManualSalesDataEntryPermission(): Promise<boolean> {
		return this.featurePermissionsService.getPermissionValue(this.authService.modules.Sales, SalesPermissions.ManualSalesDataEntry);
	}

	getManageCommissionsPermission(): Promise<boolean> {
		return this.featurePermissionsService.getPermissionValue(this.authService.modules.Sales, SalesPermissions.ManageCommissions);
	}

	getManageCustomFieldsPermission(): Promise<boolean> {
		return this.featurePermissionsService.getPermissionValue(this.authService.modules.Sales, SalesPermissions.ManageCustomFields);
	}

	getManageManufacturersPermission(): Promise<boolean> {
		return this.featurePermissionsService.getPermissionValue(this.authService.modules.Sales, SalesPermissions.ManageManufacturers);
	}

	getCloseMonthsPermission(): Promise<boolean> {
		return this.featurePermissionsService.getPermissionValue(this.authService.modules.Sales, SalesPermissions.CloseMonths);
	}

	getEditTeamReportsPermission(): Promise<boolean> {
		return this.featurePermissionsService.getPermissionValue(this.authService.modules.Sales, SalesPermissions.EditTeamReports);
	}

	getViewCommissionsPermission(): Promise<number> {
		return this.featurePermissionsService.getPermissionValue(this.authService.modules.Sales, SalesPermissions.ViewCommissions).then(result => {
			return result;
		});
	}

	getViewAllSalesRepCommissionsPermission(): Promise<boolean> {
		return this.featurePermissionsService.getPermissionValue(this.authService.modules.Sales, SalesPermissions.ViewAllSalesRepCommissions);
	}

	getViewAgencyCommissionsPermission(): Promise<boolean> {
		return this.featurePermissionsService.getPermissionValue(this.authService.modules.Sales, SalesPermissions.ViewAgencyCommissions);
	}
}
