import { Injectable } from '@angular/core';

import { AuthenticationService } from '../authentication.service';

@Injectable()
export class FeaturePermissionsService {

	constructor(private authService: AuthenticationService) { }

	getPermissionValue(permissionModule: any, permission: any): any {
		return this.authService.getFeature(permissionModule).then((feature) => {
			for (const key in feature.Permissions) {
				if (feature.Permissions[key].Id == permission) {
					return JSON.parse(feature.Permissions[key].Value);
				}
			}
		});
	}
}
