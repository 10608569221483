<h1 mat-dialog-title>Are you sure?</h1>
<div mat-dialog-content>
  Are you sure you want to delete <b>{{ this.data.name }}</b>
</div>
<div mat-dialog-actions fxLayoutAlign="end center" [style.paddingBottom]="'24px'">
  <button mat-raised-button color="white" (click)="onNoClick()">Cancel</button>
  <button mat-raised-button color="warn" (click)="deleteContact()" cdkFocusInitial>
    Delete
  </button>
</div>
