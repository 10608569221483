import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { of, Observable } from 'rxjs';
import { ElasticService } from 'src/app/services/elastic/elastic.service';
import {
  DailyActivitiesActions,
  DailyActivitiesActionTypes,
  LoadCallNotesByCallField,
  LoadCallNotesByCallFieldFailure,
  LoadCallNotesByCallFieldSuccess,
  LoadCallNotesByCallType,
  LoadCallNotesByCallTypeFailure,
  LoadCallNotesByCallTypeSuccess,
  LoadCallNotesByRep,
  LoadCallNotesByRepFailure,
  LoadCallNotesByRepSuccess,
  LoadDailyActivity,
  LoadDailyActivityFailure,
  LoadDailyActivitySuccess
} from './daily-activities.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { DateQuery } from 'src/app/models/elastic/date-query';
import { RepQuery } from 'src/app/models/elastic/rep-query';
import { CallNoteTypeQuery } from 'src/app/models/elastic/callNoteType-query';
import { CallNoteFieldQuery } from 'src/app/models/elastic/callNoteField-query';

@Injectable()
export class DailyActivitiesEffects {
  constructor(
    private elasticService: ElasticService,
    private actions$: Actions<DailyActivitiesActions>
  ) {}

  loadDailyActivity$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DailyActivitiesActionTypes.LoadDailyActivity),
      map((action: LoadDailyActivity) => action.payload),
      mergeMap((dateQuery: DateQuery) =>
        this.elasticService.getNumberOfCallNotesByDate(dateQuery).pipe(
          map((data) => new LoadDailyActivitySuccess(data)),
          catchError((error: HttpErrorResponse) =>
            of(new LoadDailyActivityFailure(error))
          )
        )
      )
    )
  );

  loadCallNotesByRep$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DailyActivitiesActionTypes.LoadCallNotesByRep),
      map((action: LoadCallNotesByRep) => action.payload),
      mergeMap((repQuery: RepQuery) =>
        this.elasticService.getCallNotesByRep(repQuery).pipe(
          map((data) => new LoadCallNotesByRepSuccess(data)),
          catchError((error: HttpErrorResponse) =>
            of(new LoadCallNotesByRepFailure(error))
          )
        )
      )
    )
  );

  loadCallNotesByCallType$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DailyActivitiesActionTypes.LoadCallNotesByCallType),
      map((action: LoadCallNotesByCallType) => action.payload),
      mergeMap((callNoteTypeQuery: CallNoteTypeQuery) =>
        this.elasticService.getCallNotesByCallType(callNoteTypeQuery).pipe(
          map((data) => new LoadCallNotesByCallTypeSuccess(data)),
          catchError((error: HttpErrorResponse) =>
            of(new LoadCallNotesByCallTypeFailure(error))
          )
        )
      )
    )
  );

  loadCallNotesByCallField$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DailyActivitiesActionTypes.LoadCallNotesByCallField),
      map((action: LoadCallNotesByCallField) => action.payload),
      mergeMap((callNoteFieldQuery: CallNoteFieldQuery) =>
        this.elasticService.getCallNotesByCallNoteField(callNoteFieldQuery).pipe(
          map((data) => new LoadCallNotesByCallFieldSuccess(data)),
          catchError((error: HttpErrorResponse) =>
            of(new LoadCallNotesByCallFieldFailure(error))
          )
        )
      )
    )
  );
}
