import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { companiesReducer } from './companies.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CompaniesEffects } from './companies.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('companies', companiesReducer),
    EffectsModule.forFeature([CompaniesEffects])
  ]
})
export class CompaniesStateModule {}
