import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { clientsReducer } from './clients.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ClientsEffects } from './clients.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('clients', clientsReducer),
    EffectsModule.forFeature([ClientsEffects])
  ]
})
export class ClientsStateModule {}
