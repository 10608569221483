import { Component, Input, OnInit, DoCheck } from '@angular/core';
import moment from 'moment';
import { AuthenticationService } from 'src/app/app-v2/shared/services/authentication.service';
import { UserService } from 'src/app/app-v2/shared/services/user.service';
import { UserBasic } from 'src/app/models/admin/users/user-basic';
import { Output, EventEmitter } from '@angular/core';
import WaveSurfer from 'wavesurfer.js';
import MicrophonePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.microphone.min.js';

@Component({
  selector: 'tn-record-call-note',
  templateUrl: './record-call-note.component.html',
  styleUrls: ['./record-call-note.component.scss']
})
export class RecordCallNoteComponent implements OnInit, DoCheck {
  @Input() companyId = null as number;
  @Output() audioOutput = new EventEmitter<object>();
  @Output() clearAudio = new EventEmitter<void>();
  audioChunks: any[] = [];
  audioURL: string = null;
  blob: Blob;
  mediaRecorder;
  recording: boolean = false;
  currentUserId: any;
  currentUser: UserBasic;
  wavesurfer: any;
  microphone: any;
  audioPlayer: any;
  track: any;
  audioPaused: boolean = true;
  audioPlaying: boolean = false;
  recordingStatusPrompt: string = 'Click to Record';
  recordingStatus: string;
  recordingTouched: boolean = false;
  recordedAudio: boolean = false;
  mm: number = 0;
  ss: number = 0;
  ms: number = 0;
  timerId: number = 0;

  constructor(
    private _userService: UserService,
    private _authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.currentUserId = this._authenticationService.getCookie('AgentId');
    this._userService.getById(this.currentUserId).then((user) => {
      this.getUserBasic(user.id).then((userBasic) => {
        this.currentUser = userBasic;
      });
    });
    this.wavesurfer = WaveSurfer.create({
      container: '#microphone',
      waveColor: '#4aba05',
      barWidth: 2,
      barHeight: 2,
      barGap: 3,
      interact: false,
      cursorWidth: 0,
      plugins: [
        MicrophonePlugin.create({
          bufferSize: 8192,
          numberOfInputChannels: 1,
          numberOfOutputChannels: 1,
          constraints: {
            video: false,
            audio: true
          }
        })
      ]
    });
    this.microphone = this.wavesurfer.microphone;

    this.microphone.on('deviceReady', function () {
      console.info('Device ready!');
    });
    this.microphone.on('deviceError', function (code) {
      console.warn('Device error: ' + code);
    });
    this.microphone.start();

    this.audioPlayer = WaveSurfer.create({
      container: '#loudnoises',
      barWidth: 3,
      height: 80,
      responsive: true,
      progressColor: '#2D5BFF',
      waveColor: '#EFEFEF'
    });
  }

  ngDoCheck() {
    if (this.recording && this.recordingTouched) {
      this.recordingStatus = 'Recording';
      this.recordingStatusPrompt = 'Click to Pause';
    } else if (!this.recording && this.recordingTouched) {
      this.recordingStatus = 'Paused';
      this.recordingStatusPrompt = 'Click to Resume';
    } else {
      this.recordingStatus = 'Ready';
      this.recordingStatusPrompt = 'Click to Record';
    }
  }
  getUserBasic(id: number): Promise<UserBasic> {
    return this._userService.getById(id).then((userData) => {
      const userBasicData = {
        agencyId: userData.agencyId,
        deleted: userData.deleted,
        firstName: userData.firstName,
        id: userData.id,
        lastName: userData.lastName,
        fullName: userData.firstName + ' ' + userData.lastName,
        mailbox: userData.mailbox
      };
      return userBasicData;
    });
  }

  handlePlay() {
    if (this.audioPaused) {
      this.audioPlayer.play();
      this.audioPaused = false;
      this.audioPlaying = true;
    } else {
      this.audioPlayer.pause();
      this.audioPaused = true;
      this.audioPlaying = false;
    }
  }
  forward() {
    this.audioPlayer.skipForward(10);
  }
  replay() {
    this.audioPlayer.skipBackward(10);
  }

  startRecording() {
    this.recording = true;
    this.recordingTouched = true;
    this.audioPlayer.stop();
    this.audioPaused = true;
    this.audioPlaying = false;
    this.timerHandler();
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then((stream) => {
        this.mediaRecorder = new MediaRecorder(stream);
        this.mediaRecorder.start();

        this.mediaRecorder.ondataavailable = (event) => {
          this.audioChunks.push(event.data);
        };

        this.mediaRecorder.onstop = () => {
          const blob = new Blob(this.audioChunks, { type: 'audio/m4a' });
          this.blob = blob;
          const playbackBlob = new Blob(this.audioChunks, { type: 'audio/x-wav' });

          this.audioURL = window.URL.createObjectURL(blob);
          const playbackURL = window.URL.createObjectURL(playbackBlob);

          this.audioPlayer.load(playbackURL);
          this.timerHandler();
          this.saveCallNoteAudio();
        };
      })
      .catch((err) => {
        console.error(err.code, err.message);
      });
  }
  stopRecording() {
    this.recording = false;
    this.mediaRecorder.stop();
  }

  clearRecording() {
    this.recordingTouched = false;
    this.recordedAudio = false;
    this.audioPlayer.destroy();
    this.audioPlayer = WaveSurfer.create({
      container: '#loudnoises',
      barWidth: 3,
      height: 80,
      responsive: true,
      progressColor: '#2D5BFF',
      waveColor: '#EFEFEF'
    });
    this.blob = null;
    this.audioChunks = [];
    this.audioURL = null;
    this.clearAudio.emit();
    this.mm = 0;
    this.ss = 0;
    this.ms = 0;
  }

  saveCallNoteAudio() {
    const filename = `b${this.currentUser.mailbox}d${moment().format(
      'YY'
    )}${moment().format('MM')}${moment().format('DD')}t${moment().format('HHmmss')}.${
      this.companyId
    }${'.m4a'}`;
    if (!this.audioURL) return;
    const formData = new FormData();
    formData.append('file', this.blob, 'AudioUpload');
    const payload = { formData: formData, filename: filename };
    this.audioOutput.emit(payload);
    this.recordedAudio = true;
  }

  timerHandler() {
    if (this.recording) {
      // @ts-ignore
      this.timerId = setInterval(() => {
        this.ms++;

        if (this.ms >= 100) {
          this.ss++;
          this.ms = 0;
        }
        if (this.ss >= 60) {
          this.mm++;
          this.ss = 0;
        }
      }, 10);
    } else {
      clearInterval(this.timerId);
    }
  }
  format(num: number) {
    return (num + '').length === 1 ? '0' + num : num + '';
  }
}
