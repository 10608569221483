import { RootState } from '../../store.reducer';
import { MemoizedSelector, createSelector } from '@ngrx/store';
import { selectAllExpenses } from './all-expenses-as-array.selector';
import { GenericMap } from 'src/app/models/generic-map';
import { Expense } from 'src/app/models/activities/expense';
import { Contact } from 'src/app/models/contacts/contact';
import { formatAddressIntoOneLine } from 'src/app/utils/formatAddressIntoOneLine';
import { selectUsers } from '../../users/selectors/multi-select-users.selector';
import { User } from 'src/app/models/admin/users/user';
import { FileMetaData } from 'src/app/models/file-meta-data';

export const selectedExpenseId = (state: RootState) => state.expenses.selectedExpenseId;

export const selectSelectedExpense: MemoizedSelector<RootState, Expense> = createSelector(
  selectAllExpenses,
  selectedExpenseId,
  selectUsers,
  (
    expensesMap: GenericMap<Expense>,
    expenseId: number,
    users: GenericMap<User>
  ): Expense => {
    let expense: Expense;
    if (!!expenseId && expensesMap[expenseId]) {
      const id = expenseId.toString();
      expense = {
        ...expensesMap[id],
        company: !!expensesMap[id].company
          ? {
              id: expensesMap[id].company.id,
              name: expensesMap[id].company.name,
              address: formatAddressIntoOneLine(expensesMap[id].company.billingAddress)
            }
          : null,
        contacts: expensesMap[expenseId].contacts.map((contact: Contact) => ({
          id: contact.id,
          name: contact.firstName + ' ' + contact.lastName
        })),
        files: expensesMap[id].files.map((file: FileMetaData) => {
          const uploadedBy = users[file.uploadedBy as number];
          return {
            ...file,
            uploadedByItem: {
              name: uploadedBy.firstName + ' ' + uploadedBy.lastName,
              id: uploadedBy.id
            }
          };
        })
      };
      if (!!expensesMap[id].assignedToUser) {
        expense.assignedToUser = {
          id: expensesMap[id].assignedToId,
          name:
            expensesMap[id].assignedToUser.firstName +
            ' ' +
            expensesMap[id].assignedToUser.lastName,
          mailbox: expensesMap[id].assignedToUser.mailbox
        };
      } else {
        expense.assignedToUser = null;
      }
    }
    return expense;
  }
);
