import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CallTypesEffects } from './call-types.effects';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { callTypesReducer } from './call-types.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('callTypes', callTypesReducer),
    EffectsModule.forFeature([CallTypesEffects])
  ]
})
export class CallTypesStateModule {}
