import { ProductLinesActions, ProductLinesActionTypes } from './product-lines.actions';
import { GenericApi } from 'src/app/models/generic-api';
import { GenericMap } from 'src/app/models/generic-map';
import { ProductLine } from 'src/app/models/product-lines/product-line';

export interface ProductLinesState extends GenericApi<GenericMap<ProductLine>> {}

export const initialState: ProductLinesState = {
  pending: false,
  error: undefined,
  data: {}
};

export function productLinesReducer(
  state = initialState,
  action: ProductLinesActions
): ProductLinesState {
  switch (action.type) {
    case ProductLinesActionTypes.LoadProductLines:
      return {
        ...state,
        pending: true,
        error: undefined
      };

    case ProductLinesActionTypes.LoadProductLinesSuccess:
      return {
        ...state,
        pending: false,
        error: undefined,
        data: action.payload
      };

    case ProductLinesActionTypes.LoadProductLinesFailure:
      return { ...state, pending: false, error: action.payload };

    default:
      return state;
  }
}
