<div class="todo-form" fxLayout="column">
  <form [formGroup]="todoForm">
    <mat-form-field style="width: 100%">
      <mat-label>To-do</mat-label>
      <textarea
        tnAutosizeTextarea
        autocomplete="off"
        matInput
        formControlName="description"
        (keydown.enter)="onKeydown($event)"
        (keyup.enter)="saveTodo()"
      ></textarea>
    </mat-form-field>

    <div fxLayout="row-reverse" fxLayoutAlign="space-between center">
      <div fxLayout="row-reverse">
        <button
          type="button"
          mat-raised-button
          color="primary"
          mat-button
          [disabled]="todoForm.pristine || todoForm.invalid || pendingActivity"
          (click)="saveTodo()"
        >
          <span>Save</span>
        </button>
        &nbsp;&nbsp;
        <button
          preventDefaultAction
          mat-raised-button
          color="white"
          mat-button
          (click)="cancel()"
        >
          Cancel
        </button>
      </div>
      <button
        *ngIf="this.currentTodo"
        preventDefaultAction
        mat-raised-button
        color="warn"
        mat-button
        (click)="delete()"
      >
        Delete
      </button>
    </div>
  </form>
</div>
