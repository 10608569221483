import { Injectable } from '@angular/core';
import { ServicesModule } from '../../services.module';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LabelGroup } from 'src/app/models/label-group';

@Injectable({
  providedIn: ServicesModule
})
export class CompanyLabelsService {
  private jpiUrl = environment.jpiUrl;
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get(`${this.jpiUrl}/companylabelgroups/`);
  }

  getById(companyLabelId: number) {
    return this.http.get(`${this.jpiUrl}/companylabelgroups/${companyLabelId}`);
  }

  create(companyLabel: LabelGroup) {
    return this.http.post(`${this.jpiUrl}/companylabelgroups`, companyLabel);
  }

  update(companyLabel: LabelGroup) {
    return this.http.put(
      `${this.jpiUrl}/companylabelgroups/${companyLabel.id}`,
      companyLabel
    );
  }

  delete(companyLabelId: number) {
    return this.http.delete(`${this.jpiUrl}/companylabelgroups/${companyLabelId}`);
  }
}
