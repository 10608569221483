<div class="wrap">
  <div fxLayoutAlign="end end">
    <button mat-icon-button (click)="cancel()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center">
    <h2>Contact Support</h2>
  </div>
  <div class="support-message">
    Need assistance? Our dedicated support team is here to help you! If you have any
    questions, concerns, or need help with our products/services, feel free to reach out.
  </div>
  <div class="contact-info">
    <div>
      <span>Call: (866) 835-3668</span>
    </div>

    <div>
      Email:
      <a [href]="supportURL">
        <span>support@voze.com</span>
      </a>
    </div>
  </div>
  <div class="thank-you">
    We value your satisfaction and are eager to provide the support you need. Thank you
    for choosing us , and we look forward to resolving any inquiries you may have.
  </div>
</div>
