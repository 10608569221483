import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromProjectCompanyCustomFields from './project-company-custom-fields.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ProjectCompanyCustomFieldsEffects } from './project-company-custom-fields.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromProjectCompanyCustomFields.projectCompanyCustomFieldsFeatureKey,
      fromProjectCompanyCustomFields.projectCompanyCustomFieldsReducer
    ),
    EffectsModule.forFeature([ProjectCompanyCustomFieldsEffects])
  ]
})
export class ProjCompCustomFieldsStateModule {}
