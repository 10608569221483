<form [formGroup]="projectLabelForm" class="example-form">
  <mat-form-field class="example-full-width">
    <mat-label>Name</mat-label>
    <input formControlName="name" matInput type="text" required />
  </mat-form-field>

  <mat-form-field class="example-full-width">
    <mat-label>Description</mat-label>
    <textarea matInput formControlName="description" tnAutosizeTextarea></textarea>
  </mat-form-field>

  <div fxLayout="column" fxLayoutGap="12px" style="margin-bottom: 24px">
    <mat-checkbox formControlName="required">Required</mat-checkbox>
    <mat-checkbox formControlName="allowMultiple">Allow Multiple Selections</mat-checkbox>
  </div>

  <div *ngIf="currentProjectLabel?.id">
    <div fxLayout="row" style="margin-left: 10px; margin-right: 5px">
      <div fxFlex="85"><i>Labels</i></div>
      <div fxFlex="10">
        <button
          mat-icon-button
          class="add-label-nav-button"
          color="primary"
          [matMenuTriggerFor]="addLabelMenu"
          #addLabelMenuTrigger
          (menuOpened)="menuOpened()"
        >
          <i class="material-icons" style="cursor: pointer"> add_circle </i>
        </button>

        <mat-menu class="label-select-menu" #addLabelMenu="matMenu" xPosition="before">
          <form
            [formGroup]="projectLabelIndividualForm"
            class="menu-form"
            (click)="$event.stopPropagation()"
          >
            <div fxLayout="row" fxLayoutAlign="space-between">
              <mat-form-field>
                <mat-label>Label Name</mat-label>
                <input
                  matInput
                  cdkFocusInitial
                  [value]="currentProjectLabelIndividual?.name"
                  formControlName="name"
                  type="string"
                />
              </mat-form-field>
              <div mat-dialog-actions>
                <button
                  *ngIf="!projectLabelIndividualForm.controls.id.value"
                  mat-icon-button
                  color="primary"
                  (click)="saveIndividual()"
                  [disabled]="
                    projectLabelIndividualForm.pristine ||
                    projectLabelIndividualForm.invalid
                  "
                >
                  <mat-icon>check</mat-icon>
                </button>
              </div>
              <div mat-dialog-actions>
                <button
                  *ngIf="projectLabelIndividualForm.controls.id.value"
                  mat-icon-button
                  color="primary"
                  (click)="editIndividual()"
                  [disabled]="
                    projectLabelIndividualForm.pristine ||
                    projectLabelIndividualForm.invalid
                  "
                >
                  <mat-icon>check</mat-icon>
                </button>
              </div>
            </div>
          </form>
        </mat-menu>
      </div>
    </div>
    <div
      *ngFor="let projectLabelIndividual of projectLabelIndividual"
      style="
        background-color: whitesmoke;
        border-bottom: 1px #555;
        margin-top: 7px;
        padding: 5px 5px 5px 10px;
        border-radius: 6px;
        cursor: pointer;
      "
    >
      <div fxLayout="row" fxLayoutAlign="center center">
        <div
          fxFlex="80"
          [matMenuTriggerFor]="addLabelMenu"
          #addLabelMenuTrigger
          (menuOpened)="menuOpenedEdit(projectLabelIndividual)"
        >
          {{ projectLabelIndividual.name }}
        </div>
        <div fxFlex="10" (click)="deleteIndividual(projectLabelIndividual)">
          <i class="material-icons">delete</i>
        </div>
      </div>
    </div>
  </div>

  <div
    style="margin-top: 10px"
    fxLayout="row-reverse"
    fxLayoutAlign="space-between center"
  >
    <div fxLayout="row-reverse">
      <button
        *ngIf="!currentProjectLabel?.id"
        mat-raised-button
        color="primary"
        mat-button
        class="button-container"
        [disabled]="projectLabelForm.pristine || projectLabelForm.invalid"
        (click)="save()"
      >
        Save
      </button>
      <button
        *ngIf="currentProjectLabel?.id"
        mat-raised-button
        mat-button
        color="primary"
        class="button-container"
        [disabled]="projectLabelForm.pristine || projectLabelForm.invalid"
        (click)="update()"
      >
        Save
      </button>
      <button
        preventDefaultAction
        class="button-container"
        mat-raised-button
        mat-button
        (click)="cancel()"
      >
        Cancel
      </button>
    </div>
    <div>
      <button
        preventDefaultAction
        *ngIf="currentProjectLabel?.id"
        mat-raised-button
        color="warn"
        mat-button
        (click)="delete()"
      >
        Delete
      </button>
    </div>
  </div>
</form>
