<mat-form-field fxFlex="1 1 100">
  <mat-chip-list #chipList>
    <mat-chip
      *ngFor="let email of emails"
      [selectable]="true"
      [removable]="true"
      (removed)="removeEmail(email)"
    >
      {{ email }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    <input
      matInput
      placeholder="Select emails"
      #emailInput
      type="text"
      autocomplete="off"
      (keydown.escape)="escapePressed($event)"
      [formControl]="emailInputCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)"
    />
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let user of selectableUsers | sort: 'name'" [value]="user.email">
      <div class="option-name">{{ user.name }}</div>
      <div class="option-email">
        {{ user.email }}
      </div>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
