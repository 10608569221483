import { MapService } from './../../services/map.service';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DxSelectBoxComponent } from 'devextreme-angular';
import isEqual from 'lodash.isequal';

import { CompanyLabel } from '../../models/admin/company-labels/company-label';
import { CompanyLabelGroup } from '../../models/admin/company-labels/company-label-group';
import { CustomFieldV2 } from '../../models/admin/custom-fields/custom-field-V2';
import { SectionTabV2 } from '../../models/admin/custom-fields/section-tab-V2';
import { SectionV2 } from '../../models/admin/custom-fields/section-V2';
import { TerritoryV2 } from '../../models/admin/territories/territory-V2';
import { Company } from '../../models/companies/company';
import { CompanyV2 } from '../../models/companies/company-V2';
import { CustomFieldService } from '../../services/admin/custom-field.service';
import { CompanyLabelGroupService } from '../../services/admin/customization/company-label-group.service';
import { CompanyJPIService } from '../../services/companies/company-JPI.service';
import { CompanyService } from '../../services/companies/company.service';
import { NotificationService } from '../../services/notification.service';
import { TerritoryService } from '../../services/territories/territory.service';
import { ToolbarService } from '../../services/toolbar.service';
import { UserService } from '../../services/user.service';
import { FieldTypeEnum } from './../../models/admin/custom-fields/field-type';
import { CompanyPopupTerritoriesAndRepsComponent } from './company-popup-territories-and-reps.component';

@Component({
  selector: 'company-popup',
  templateUrl: './company-popup.component.html',
  styleUrls: ['../../styles/buttons.shared.scss', './company-popup.component.scss']
})
export class CompanyPopupComponent implements OnInit, OnDestroy {
  @ViewChild(DxSelectBoxComponent)
  territoriesTagBox: DxSelectBoxComponent;
  @ViewChild(CompanyPopupTerritoriesAndRepsComponent)
  territoriesComponent: CompanyPopupTerritoriesAndRepsComponent;
  @Input() companyPopup: boolean;
  @Input() customSections: SectionV2[];
  // @Input() customFieldsExpanded: boolean = true;
  @Input() company: CompanyV2;
  @Input() newGroupLabels: CompanyLabelGroup[];
  @Input() companyDetails: CustomFieldV2[];
  @Input() companyId: number;
  @Input() companiesAndContacts: any[];
  @Input() companies: any;
  @Input() newCompanies: Company[];
  @Input() parentCompanyId: number;
  @Input() displayedLabels: CompanyLabelGroup[];
  @Input() territories: TerritoryV2[];
  @Input() companyProfilePopup: boolean;
  @Input() companyListEditPopup: boolean;
  @Output() companyPopupChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() customSectionsChange: EventEmitter<SectionV2[]> = new EventEmitter<
    SectionV2[]
  >();
  @Output() companyChange: EventEmitter<CompanyV2> = new EventEmitter<CompanyV2>();
  @Output() newGroupLabelsChange: EventEmitter<CompanyLabelGroup[]> = new EventEmitter<
    CompanyLabelGroup[]
  >();
  @Output() companyDetailsChange: EventEmitter<CustomFieldV2[]> = new EventEmitter<
    CustomFieldV2[]
  >();
  @Output() companiesAndContactsChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() companiesChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() newCompaniesChange: EventEmitter<Company[]> = new EventEmitter<Company[]>();
  @Output() parentCompanyIdChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() displayedLabelsChange: EventEmitter<CompanyLabelGroup[]> = new EventEmitter<
    CompanyLabelGroup[]
  >();
  window: any = window;
  labelsExpanded: boolean = true;
  billingAddressExpanded: boolean = true;
  shippingAddressExpanded: boolean = true;
  companyDetailsExpanded: boolean = true;
  newGroupLabelsCheck: CompanyLabelGroup[];
  customSectionsCheck: SectionV2[];
  companyDetailsCheck: CustomFieldV2[];
  companyCustomFields: CustomFieldV2[];
  sections: SectionV2[];
  customFields: CustomFieldV2[];
  labelGroups: CompanyLabelGroup[];
  companyCheck: CompanyV2;
  cancelPopup: boolean;
  websiteRegex = new RegExp(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/
  );
  standardFieldsRegexTest: boolean = true;
  customFieldsRegexTest: boolean = true;
  contactCustomFieldsRegexTest: boolean = true;
  contactStandardFieldsRegexTest: boolean = true;
  deleteCompanyPopup: boolean;
  isLoading: boolean;
  primaryUserId: number;

  constructor(
    private customFieldService: CustomFieldService,
    private companyLabelGroupService: CompanyLabelGroupService,
    private notificationService: NotificationService,
    private companyJPIService: CompanyJPIService,
    private territoryService: TerritoryService,
    private companyService: CompanyService,
    private route: ActivatedRoute,
    private mapService: MapService,
    private router: Router,
    private userService: UserService,
    private toolbarService: ToolbarService
  ) {}

  ngOnInit(): void {
    if (!this.company || !this.company.id) {
      this.initNewCompany();
    } else {
      if (!this.company.billingAddress) {
        this.company.billingAddress = Object.assign({});
      }
      if (!this.company.shippingAddress) {
        this.company.shippingAddress = Object.assign({});
      }
      this.companyCheck = JSON.parse(JSON.stringify(this.company));
      if (this.newGroupLabels) {
        for (const group of this.newGroupLabels) {
          group.labelsSelected = [];
          group.labels.sort((a, b) => a.name.localeCompare(b.name));
          if (this.company.labels) {
            for (const label of this.company.labels) {
              if (group.allowMultiple && label.groupId == group.id) {
                group.labelsSelected.push(label.id);
              }
            }
          }
        }
        this.newGroupLabelsCheck = JSON.parse(JSON.stringify(this.newGroupLabels));
      }
      if (!this.company.labels) {
        this.company.labels = [];
      }
      const companyLabelGroups: number[] = [];
      this.company.labels.map((label: CompanyLabel) =>
        companyLabelGroups.push(label.groupId)
      );
      this.newGroupLabels.map((group: CompanyLabelGroup) => {
        if (group.defaultLabelId && !companyLabelGroups.includes(group.id)) {
          group.labels.map((label: CompanyLabel) => {
            if (label.id == group.defaultLabelId) {
              this.company.labels.push(label);
            }
          });
        }
      });
      if (this.company.primaryUser) {
        this.primaryUserId = this.company.primaryUser.id;
      }
      if (this.customSections) {
        this.customSections = JSON.parse(JSON.stringify(this.customSections));
      }
      if (this.companyDetails) {
        this.companyDetailsCheck = JSON.parse(JSON.stringify(this.companyDetails));
      }
      this.companyCheck.createdDate = this.company.createdDate;
      this.customFieldService.getSections().then(result => {
        this.sections = result.filter(section => section.Id !== 0 && !section.IsDeleted);
        this.getCustomFields();
      });
    }
    if (!this.territories) {
      this.territoryService.getAllFlattened().then(territories => {
        this.territories = territories;
      });
    }
  }

  ngOnDestroy(): void {
    this.companyService.newCompanyAdded(null);
  }

  initNewCompany(): void {
    if (this.parentCompanyId) {
      // this.newCompany = Object.assign({ ParentCompanyId: this.parentCompanyId });
    } else {
      this.company = Object.assign({
        billingAddress: Object.assign({}),
        shippingAddress: Object.assign({})
      });
    }
    if (!this.company.labels) {
      this.company.labels = [];
    }

    this.companyLabelGroupService.getCompanyLabelGroups().then(labelGroups => {
      this.labelGroups = labelGroups;
      this.customFieldService.getSections().then(result => {
        this.companyDetails = result.filter(section => section.Id === 0)[0].Fields;
        this.companyDetailsCheck = JSON.parse(JSON.stringify(this.companyDetails));
        this.sections = result;
        this.customSections = result.filter(
          section => section.Id !== 0 && !section.IsDeleted
        );
        this.filterTabsAndFields();
        this.newGroupLabels = JSON.parse(JSON.stringify(this.labelGroups));
        const companyLabelGroups: number[] = [];
        if (this.company.labels) {
          this.company.labels.map((label: CompanyLabel) =>
            companyLabelGroups.push(label.groupId)
          );
        }
        this.newGroupLabels.map((group: CompanyLabelGroup) => {
          if (group.defaultLabelId && !companyLabelGroups.includes(group.id)) {
            group.labels.map((label: CompanyLabel) => {
              if (label.id == group.defaultLabelId) {
                this.company.labels.push(label);
              }
            });
          }
        });
        this.companyCheck = JSON.parse(JSON.stringify(this.company));
        this.newGroupLabelsCheck = JSON.parse(JSON.stringify(this.newGroupLabels));
        this.getCustomFields();
      });
    });
  }

  getCustomFields(): void {
    this.customFields = [];
    if (this.customSections) {
      for (const customSection of this.customSections) {
        for (const customTab of customSection.Tabs) {
          for (const customField of customTab.Fields) {
            if (customField.FieldType == FieldTypeEnum.Dropdown && customField.Value) {
              customField.Value = parseInt(customField.Value.toString());
            } else {
              this.customFields.push(customField);
            }
          }
        }
      }
      this.customSections = JSON.parse(JSON.stringify(this.customSections));
      this.customSectionsCheck = JSON.parse(JSON.stringify(this.customSections));
    }
  }

  filterTabsAndFields(): void {
    for (const section of this.customSections) {
      if (section.Fields && section.Fields.length != 0) {
        section.Fields = section.Fields.filter(
          (field: CustomFieldV2) => !field.IsDeleted
        );
      } else {
        section.Tabs = section.Tabs.filter((tab: SectionTabV2) => !tab.IsDeleted);
        for (const tab of section.Tabs) {
          // for (const field of tab.Fields) {
          tab.Fields = tab.Fields.filter((field: CustomFieldV2) => !field.IsDeleted);
          // }
        }
      }
    }
    this.customSectionsCheck = JSON.parse(JSON.stringify(this.customSections));
  }

  pushSelectedTerritoriesIntoCompany(): void {
    if (this.territoriesComponent.selectedTerritoryIds) {
      this.company.territories = [];
      this.territoriesComponent.selectedTerritoryIds.map((territoryId: number) => {
        if (this.territories) {
          this.territories.map((territory: TerritoryV2) => {
            if (territory.id == territoryId) {
              this.company.territories.push(territory);
            }
          });
        }
      });
    }
  }

  cancelCompany(): void {
    this.pushSelectedTerritoriesIntoCompany();
    const tempCompany: CompanyV2 = JSON.parse(JSON.stringify(this.company));
    const tempCompanyCheck: CompanyV2 = JSON.parse(JSON.stringify(this.companyCheck));
    if (tempCompany.labels) {
      tempCompany.labels.sort((a: CompanyLabel, b: CompanyLabel) => a.id - b.id);
    }
    if (tempCompanyCheck.labels) {
      tempCompanyCheck.labels.sort((a: CompanyLabel, b: CompanyLabel) => a.id - b.id);
    }
    if (tempCompany.territories) {
      tempCompany.territories.sort((a: TerritoryV2, b: TerritoryV2) => a.id - b.id);
    }
    if (tempCompanyCheck.territories) {
      tempCompanyCheck.territories.sort((a: TerritoryV2, b: TerritoryV2) => a.id - b.id);
    }

    if (tempCompany.territories) {
      tempCompany.territories.map((territory: TerritoryV2) => {
        delete territory.users;
        delete territory.subTerritories;
      });
    }
    if (tempCompanyCheck.territories) {
      tempCompanyCheck.territories.map((territory: TerritoryV2) => {
        delete territory.users;
        delete territory.subTerritories;
      });
    }
    if (
      isEqual(tempCompany, tempCompanyCheck) &&
      isEqual(this.newGroupLabels, this.newGroupLabelsCheck) &&
      isEqual(this.customSections, this.customSectionsCheck) &&
      isEqual(this.companyDetails, this.companyDetailsCheck)
    ) {
      this.closingPopup();
    } else {
      this.cancelPopup = true;
    }
  }

  companyCancelled(): void {
    this.company = JSON.parse(JSON.stringify(this.companyCheck));
    this.companyCheck = JSON.parse(JSON.stringify(this.companyCheck));
    if (this.newGroupLabels) {
      this.newGroupLabels = JSON.parse(JSON.stringify(this.newGroupLabelsCheck));
    }
    this.newGroupLabelsCheck = JSON.parse(JSON.stringify(this.newGroupLabelsCheck));
    if (this.customSections) {
      this.customSections = JSON.parse(JSON.stringify(this.customSectionsCheck));
    }
    this.customSectionsCheck = JSON.parse(JSON.stringify(this.customSectionsCheck));
    if (this.companyDetails) {
      this.companyDetails = JSON.parse(JSON.stringify(this.companyDetailsCheck));
    }
    this.companyDetailsCheck = JSON.parse(JSON.stringify(this.companyDetailsCheck));
    this.closingPopup();
  }

  selectLabel(e: any): void {
    if (e.selectedItem) {
      if (this.company.labels) {
        this.company.labels = this.company.labels.filter(
          (label: CompanyLabel) => label.groupId != e.selectedItem.groupId
        );
      } else {
        this.company.labels = [];
      }
      this.company.labels.push(e.selectedItem);
    }
  }

  closingPopup(): void {
    this.isLoading = false;
    this.cancelPopup = false;
    this.companyPopup = false;
    this.parentCompanyId = null;
    this.emitVariables();
  }

  emitVariables(): void {
    this.companyService.companyProfileCompanyPopupChange(false);
    this.companyPopupChange.emit(this.companyPopup);
    this.customSectionsChange.emit(this.customSections);
    this.companyChange.emit(this.company);
    this.newGroupLabelsChange.emit(this.newGroupLabels);
    this.companyDetailsChange.emit(this.companyDetails);
    this.companiesAndContactsChange.emit(this.companiesAndContacts);
    this.companiesChange.emit(this.companies);
    this.parentCompanyIdChange.emit(this.company.parentCompanyId);
    this.displayedLabelsChange.emit(this.displayedLabels);
  }

  checkIfStandardWebsitesValid(): boolean {
    if (this.companyDetails) {
      for (let i = 0; i < this.companyDetails.length; i++) {
        if (this.companyDetails[i].FieldType == FieldTypeEnum.Website) {
          if (this.companyDetails[i].Value && this.companyDetails[i].Value.trim() != '') {
            if (!this.websiteRegex.test(this.companyDetails[i].Value)) {
              this.standardFieldsRegexTest = false;
              this.notificationService.showError(
                `Must have a valid website for ${this.companyDetails[i].Name} Field`
              );
              return false;
            }
          } else {
            this.standardFieldsRegexTest = true;
            continue;
          }
        }
      }
    }
    return true;
  }

  checkIfCustomWebsitesValid(): boolean {
    for (let i = 0; i < this.customFields.length; i++) {
      if (this.customFields[i].FieldType == FieldTypeEnum.Website) {
        if (this.customFields[i].Value && this.customFields[i].Value.trim() != '') {
          if (!this.websiteRegex.test(this.customFields[i].Value)) {
            this.customFieldsRegexTest = false;
            this.notificationService.showError(
              `Must have a valid website for ${this.customFields[i].Name} Field`
            );
            return false;
          }
        } else {
          this.customFieldsRegexTest = true;
          continue;
        }
      }
    }
    return true;
  }

  validateCompanyWebsite(company: CompanyV2, newCompany: boolean): void {
    if (this.company.website && this.company.website.trim() != '') {
      if (
        this.websiteRegex.test(this.company.website) &&
        this.customFieldsRegexTest == true &&
        this.standardFieldsRegexTest == true
      ) {
        if (newCompany) {
          this.addCompany(company);
        } else {
          this.updateCompany(company);
        }
      } else {
        this.notificationService.showError('Please provide valid websites');
      }
    } else {
      if (newCompany) {
        this.addCompany(company);
      } else {
        this.updateCompany(company);
      }
    }
  }

  updatingCompany(company: CompanyV2): void {
    if (!this.checkIfStandardWebsitesValid()) {
      return;
    }
    if (this.customFields) {
      if (!this.checkIfCustomWebsitesValid()) {
        return;
      }
      this.pushSelectedTerritoriesIntoCompany();
      if (!company.territories.length) {
        this.notificationService.showError(
          `Company must be assigned to at least one territory`
        );
        return;
      }
      if (this.territoriesComponent.primaryUserId != null) {
        this.userService.getById(this.territoriesComponent.primaryUserId).then(
          result => {
            company.primaryUser = result;
            this.validateCompanyWebsite(company, false);
          },
          error => {
            throw error;
          }
        );
      } else {
        company.primaryUser = null;
        this.validateCompanyWebsite(company, false);
      }
    }
  }

  updateCompany(company: CompanyV2): void {
    this.isLoading = true;
    if (company.website) {
      company.website.trim();
    }
    this.companyJPIService.update(company).then(
      result => {
        this.company = result;
        this.updateDisplayedLabels();
        if (this.customFields) {
          this.customFieldService.updateFieldValues(this.customFields).then(() => {});
        }
        if (this.companyDetails) {
          for (let i = 0; i < this.companyDetails.length; i++) {
            this.companyDetails[i].CompanyId = company.id;
          }
          this.customFieldService.updateFieldValues(this.companyDetails).then(() => {});
        }
        if (this.toolbarService.companyId) {
          this.territoryService.territoryChangedInPopup(true);
        }
        this.closingPopup();
      },
      error => {
        throw error;
      }
    );
  }

  saveNewCompany(company: CompanyV2): void {
    if (!company.name) {
      this.notificationService.showError('Must provide a name for the company');
      return;
    }
    if (!this.providedRequiredLabels(company)) {
      this.notificationService.showError(this.showLabelsError());
      return;
    }
    if (!this.checkIfStandardWebsitesValid()) {
      return;
    }
    if (this.customFields) {
      if (!this.checkIfCustomWebsitesValid()) {
        return;
      }
    }
    this.pushSelectedTerritoriesIntoCompany();
    if (!company.territories.length) {
      this.notificationService.showError(
        `Company must be assigned to at least one territory`
      );
      return;
    }
    if (this.territoriesComponent.primaryUserId != null) {
      this.userService.getById(this.territoriesComponent.primaryUserId).then(
        result => {
          company.primaryUser = result;
          this.validateCompanyWebsite(company, true);
        },
        error => {
          throw error;
        }
      );
    } else {
      company.primaryUser = null;
      this.validateCompanyWebsite(company, true);
    }
  }

  providedRequiredLabels(company: CompanyV2): boolean {
    let requiredLabelsSelected = true;
    this.labelGroups.forEach(group => {
      if (group.required) {
        requiredLabelsSelected = this.checkLabelsInCompany(group, company);
      }
    });
    return requiredLabelsSelected;
  }

  checkLabelsInCompany(group: CompanyLabelGroup, company: CompanyV2): boolean {
    let labelSelected = false;
    const companyLabelIds = [];
    company.labels.forEach(label => companyLabelIds.push(label.id));
    group.labels.forEach(label => {
      if (companyLabelIds.includes(label.id)) {
        labelSelected = true;
      }
    });
    return labelSelected;
  }

  showLabelsError(): string {
    const groupLabelsNotSelected = [];
    const companyLabelIds = [];
    this.company.labels.forEach(label => companyLabelIds.push(label.id));
    this.labelGroups.forEach(group => {
      if (group.required) {
        let groupLabelSelected = false;
        group.labels.forEach(label => {
          if (companyLabelIds.includes(label.id)) {
            groupLabelSelected = true;
          }
        });
        if (groupLabelSelected === false) {
          groupLabelsNotSelected.push(group.name);
        }
      }
    });
    return this.createLabelErrorMessage(groupLabelsNotSelected);
  }

  createLabelErrorMessage(groupLabelsNotSelected: any): string {
    let errorMessage: string;
    if (groupLabelsNotSelected.length === 1) {
      errorMessage = `Must have a label selected for ${groupLabelsNotSelected[0]} label group`;
    } else if (groupLabelsNotSelected.length === 2) {
      errorMessage = `Must have a label selected for both ${groupLabelsNotSelected[0]} and ${groupLabelsNotSelected[1]} label groups`;
    } else if (groupLabelsNotSelected.length > 2) {
      groupLabelsNotSelected = groupLabelsNotSelected.map((labelName, index) => {
        if (index !== groupLabelsNotSelected.length - 1) {
          labelName += ', ';
        }
        return labelName;
      });
      groupLabelsNotSelected.splice(groupLabelsNotSelected.length - 1, 0, 'and ');
      errorMessage = `Must have a label selected for ${groupLabelsNotSelected.join(
        ''
      )} label groups`;
    } else {
      errorMessage = `Must have a label selected for all required label groups`;
    }
    return errorMessage;
  }

  addCompany(company: CompanyV2): void {
    this.isLoading = true;
    this.companyJPIService.create(company).then(
      result => {
        this.company = result;
        this.companyId = result.id;
        this.updateDisplayedLabels();
        if (this.customFields) {
          for (let i = 0; i < this.customFields.length; i++) {
            this.customFields[i].CompanyId = result.id;
          }
          this.customFieldService.updateFieldValues(this.customFields).then(() => {});
        }
        if (this.companyDetails) {
          for (let i = 0; i < this.companyDetails.length; i++) {
            this.companyDetails[i].CompanyId = result.id;
          }
          this.customFieldService.updateFieldValues(this.companyDetails).then(() => {});
        }
        this.updateCompaniesList();
        this.closingPopup();
        this.companyService.newCompanyAdded(true);
      },
      error => {
        throw error;
      }
    );
  }

  updateCompaniesList(): void {
    const tempCompany = JSON.parse(JSON.stringify(this.company));
    for (const field of this.customFields) {
      tempCompany[field.Name] = field.Value;
    }
    for (const field of this.companyDetails) {
      tempCompany[field.Name] = field.Value;
    }
    if (this.companies) {
      this.companies.unshift(tempCompany);
    }
    if (this.companiesAndContacts) {
      this.companiesAndContacts.unshift(tempCompany);
    }
  }

  updateDisplayedLabels(): void {
    if (this.displayedLabels) {
      for (const labelGroup of this.displayedLabels) {
        labelGroup.labels = [];
        labelGroup.labels = this.company.labels.filter(
          (label: CompanyLabel) => label.groupId == labelGroup.id
        );
      }
    }
  }

  deleteCompany(): void {
    this.deleteCompanyPopup = true;
  }

  companyDeleted(): void {
    this.companyJPIService.delete(this.company).then(
      () => {
        this.route.params.forEach((params: Params) => {
          if (params['id'] && parseInt(params['id']) == this.company.id) {
            this.router.navigate(['companies']);
          }
        });
        this.deleteCompanyPopup = false;
        this.companyService.companyProfilePopupChange(false);
        this.companyPopup = false;
        this.mapService.updateRedrawMap(true);
      },
      error => {
        throw error;
      }
    );
  }
}
