import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import { Subscription, debounceTime, fromEvent } from 'rxjs';
import { ChartDatum } from 'src/app/models/dashboard/chart-datum';
import * as D3 from 'd3';
import { D3Service } from 'src/app/services/d3.service';

@Component({
  selector: 'tn-stacked-bar-chart',
  templateUrl: './stacked-bar-chart.component.html',
  styleUrls: ['./stacked-bar-chart.component.scss']
})
export class StackedBarChartComponent implements AfterViewInit, OnDestroy {
  @Input() data!: ChartDatum[];
  @Input() colors: string[] = [
    '#2f4f4f',
    '#556b2f',
    '#a0522d',
    '#191970',
    '#006400',
    '#8b0000',
    '#808000',
    '#5f9ea0',
    '#3cb371',
    '#b8860b',
    '#4682b4',
    '#000080',
    '#d2691e',
    '#9acd32',
    '#cd5c5c',
    '#32cd32',
    '#8fbc8f',
    '#800080',
    '#b03060',
    '#48d1cc',
    '#9932cc',
    '#ff0000',
    '#ff8c00',
    '#ffd700',
    '#0000cd',
    '#dc143c',
    '#00bfff',
    '#9370db',
    '#0000ff',
    '#a020f0',
    '#ff6347',
    '#da70d6',
    '#ff00ff',
    '#1e90ff',
    '#f0e68c',
    '#ffff54',
    '#dda0dd',
    '#90ee90',
    '#87ceeb',
    '#ff1493',
    '#ffa07a',
    '#f5deb3',
    '#7fffd4',
    '#ff69b4',
    '#ffc0cb',
    '#808080',
    '#dcdcdc'
  ];
  @Output() barSegmentClick = new EventEmitter<any>();
  barHeight = 32;

  private immutableData!: any[];
  private ctr: any;
  private xScale: any;
  private yScale: any;
  private padding: number = 0.2;
  private d3!: typeof D3;

  private transitionDuration: number = 500;
  private margin: { top: number; bottom: number; left: number; right: number } = {
    top: 30,
    bottom: 0,
    left: 60,
    right: 15
  };
  svg: any;
  legendSvg: any;
  colorScheme: any;
  stackData: any[];
  resizeSub: Subscription;

  @ViewChild('chart', { static: true }) chart!: ElementRef;

  constructor(private d3s: D3Service) {
    this.d3 = this.d3s.getD3();
  }

  get height() {
    return this.data.length * this.barHeight + this.margin.top + this.margin.bottom;
  }
  private innerWidth(): number {
    return this.chart.nativeElement.clientWidth - this.margin.left - this.margin.right;
  }

  ngAfterViewInit(): void {
    this.immutableData = !!this.data ? [...this.data] : [];

    this.renderChart();

    this.resizeSub = fromEvent(window, 'resize')
      .pipe(debounceTime(500))
      .subscribe((event) => {
        this.resizeChart();
      });
  }

  ngOnDestroy(): void {
    this.resizeSub.unsubscribe();
  }

  convertObject(obj) {
    const { name, series } = obj;
    const seriesObj = series.reduce((acc, curr, index) => {
      acc[curr.name.toLowerCase()] = curr.value;
      return acc;
    }, {});

    const newObj = {
      name,
      ...seriesObj,
      ...obj
    };

    return newObj;
  }

  addNameProperty(arr) {
    let callTypes: string[] = [];
    arr.forEach((obj) => {
      for (let key in obj) {
        if (
          key !== 'series' &&
          key !== 'name' &&
          key !== 'total' &&
          key !== 'id' &&
          key !== 'value' &&
          !callTypes.includes(key)
        ) {
          callTypes.push(key);
        }
      }
      arr.callTypes = callTypes;
    });
    return arr;
  }

  prepareImmutableData() {
    let processedData = this.immutableData.map((d) => this.convertObject(d));
    processedData = this.addNameProperty(processedData);
    processedData.sort((a, b) => a.value - b.value);
    return processedData;
  }

  generateStackData(immutableData) {
    const stackGenerator = this.d3.stack().keys(immutableData.callTypes);

    const stackData = stackGenerator(immutableData).map((callType) => {
      callType.forEach((user) => {
        if (isNaN(user[1]) || isNaN(user[0])) {
          user[1] = 0;
          user[0] = 0;
        }
        // @ts-ignore
        user.key = callType.key;
      });
      return callType;
    });

    return stackData;
  }

  calculateLegendItemsMaxWidth() {
    const rectWidth = 18;
    const textXOffset = 24;
    const spacing = 6;

    const legendTextWidths = this.stackData.map((d) => {
      const textElement = document.createElement('span');
      textElement.innerText = d.key;
      textElement.style.font = '14px';
      document.body.appendChild(textElement);
      const textWidth = textElement.offsetWidth;
      document.body.removeChild(textElement);
      return textWidth;
    });

    const longestTextWidth = Math.max(...legendTextWidths);
    const legendItemsMaxWidth = rectWidth + textXOffset + longestTextWidth + spacing;

    return legendItemsMaxWidth;
  }

  updateMarginLeft() {
    // Calculate the longest text label's width
    const calculateLongestLabelWidth = () => {
      const textWidths = this.immutableData.map((d) => {
        const textElement = document.createElement('span');
        textElement.innerText = d.name;
        textElement.style.font = '14px Arial';
        document.body.appendChild(textElement);
        const textWidth = textElement.offsetWidth;
        document.body.removeChild(textElement);
        return textWidth;
      });

      return Math.max(...textWidths);
    };

    // Set the left margin to the longest text label's width
    const longestLabelWidth = calculateLongestLabelWidth();
    this.margin.left = longestLabelWidth;
  }

  createTooltip() {
    return this.d3
      .select('#chart')
      .append('div')
      .style('opacity', 0)
      .attr('class', 'tooltip')
      .style('background-color', 'white')
      .style('border', 'solid')
      .style('border-width', '2px')
      .style('border-radius', '5px')
      .style('padding', '5px')
      .style('position', 'absolute');
  }

  mouseover(e, d, Tooltip) {
    const type = d.data.name;
    const data = d.data.series.find((obj) => obj.name.toLowerCase() === d.key);
    const total = d.data.value;
    Tooltip.html(
      `<div>
         <span style="font-weight: bold">${type}</span><br>
         ${data.name}: <span style="font-weight: bold">${data.value} / ${total}</span>
       </div>`
    )
      .style('position', 'fixed')
      .style('left', e.clientX + 5 + 'px')
      .style('top', e.clientY - Tooltip.node().getBoundingClientRect().height - 5 + 'px');
    Tooltip.style('opacity', 1);
    D3.select(e.target).style('opacity', 0.8);
  }

  mousemove(e, d, Tooltip) {
    const type = d.data.name;
    const data = d.data.series.find((obj) => obj.name.toLowerCase() === d.key);
    const total = d.data.value;
    Tooltip.html(
      `<div>
         <span style="font-weight: bold">${type}</span><br>
         ${data.name}: <span style="font-weight: bold">${data.value} / ${total}</span>
       </div>`
    )
      .style('position', 'fixed')
      .style('left', e.clientX + 5 + 'px')
      .style('top', e.clientY - Tooltip.node().getBoundingClientRect().height - 5 + 'px');
  }

  mouseleave(e, d, Tooltip) {
    Tooltip.style('opacity', 0);
    D3.select(e.target).style('opacity', 1);
  }

  mouseoverType(e, d, Tooltip) {
    Tooltip.html(
      `<div>
         <span style="font-weight: bold">${d.name}</span><br>
         Total: <span style="font-weight: bold">${d.value}</span>
       </div>`
    )
      .style('position', 'fixed')
      .style('left', e.clientX + 5 + 'px')
      .style('top', e.clientY - Tooltip.node().getBoundingClientRect().height - 5 + 'px');
    Tooltip.style('opacity', 1);
    D3.select(e.target).style('opacity', 0.8);
  }

  mousemoveType(e, d, Tooltip) {
    Tooltip.html(
      `<div>
         <span style="font-weight: bold">${d.name}</span><br>
         Total: <span style="font-weight: bold">${d.value}</span>
       </div>`
    )
      .style('position', 'fixed')
      .style('left', e.clientX + 5 + 'px')
      .style('top', e.clientY - Tooltip.node().getBoundingClientRect().height - 5 + 'px');
  }

  highlightSegments(key: string) {
    this.d3.selectAll('.users rect').style('opacity', 0.3);
    this.d3.selectAll(`[name="${key}"] rect`).style('opacity', 1);
  }

  removeHighlight() {
    this.d3.selectAll('.users rect').style('opacity', 1);
  }

  resizeChart() {
    document.querySelector('#chart-ctr-container').innerHTML = '';
    document.querySelector('#legend-container').innerHTML = '';
    this.renderChart();
  }

  renderChart() {
    setTimeout(() => {
      this.updateMarginLeft();
      this.immutableData = this.prepareImmutableData();
      this.stackData = this.generateStackData(this.immutableData);
      const legendMaxWidth = this.calculateLegendItemsMaxWidth();
      const Tooltip = this.createTooltip();

      // Draw Image
      const containerWidth = this.chart.nativeElement.clientWidth;
      const chartAreaWidth = containerWidth - legendMaxWidth;

      this.svg = this.d3
        .select('#chart-ctr-container')
        .append('svg')
        .attr('width', chartAreaWidth)
        .attr('height', this.height + this.margin.top + this.margin.bottom);

      this.ctr = this.svg
        .append('g')
        .attr('transform', `translate(10, ${this.margin.top})`);

      this.legendSvg = this.d3
        .select('#legend-container')
        .append('svg')
        .attr('width', legendMaxWidth)
        .attr('height', this.height + this.margin.top + this.margin.bottom);

      // Scales
      this.xScale = this.d3
        .scaleLinear()
        // @ts-ignore
        .domain([
          0,
          this.d3.max(this.stackData, (callType) => {
            return this.d3.max(callType, (user) => user[1]);
          })
        ])
        .range([this.margin.left, this.innerWidth() - 10]);

      this.yScale = this.d3
        .scaleBand()
        .domain(this.immutableData.map((callType) => callType.name))
        .range([this.height - this.margin.top, 0])
        .padding(this.padding);

      const colorScale = this.d3
        .scaleOrdinal()
        .domain(this.stackData.map((d) => d.key))
        .range(this.colors)
        .unknown('#ccc');

      // Draw Bars
      const callTypes = this.ctr
        .append('g')
        .classed('users', true)
        .selectAll('g')
        .data(this.stackData)
        .join('g')
        .attr('fill', (d) => colorScale(d.key))
        .attr('name', (d) => d.key);

      callTypes
        .selectAll('rect')
        .data((d) => d.filter((e) => e[1] !== 0)) // Filter out data with a count of 0
        .join('rect')
        .attr('x', (d) => this.xScale(d[0]))
        .attr('y', (d) => this.yScale(d.data.name))
        .attr('height', this.yScale.bandwidth())
        .style('cursor', 'pointer')
        .on('click', (e, d) => {
          const segmentInfo = {
            agentIds: [d.data.series.find((obj) => obj.name.toLowerCase() === d.key).id],
            callTypeIds: [d.data.id]
          };
          this.barSegmentClick.emit(segmentInfo);
        })
        .on('mouseover', (e, d) => {
          this.mouseover(e, d, Tooltip);
        })
        .on('mousemove', (e, d) => {
          this.mousemove(e, d, Tooltip);
        })
        .on('mouseleave', (e, d) => {
          this.mouseleave(e, d, Tooltip);
        })
        .transition()
        .ease(this.d3.easePolyInOut)
        .duration(this.transitionDuration)
        .attr('width', (d) => {
          if (d[1] === 0) return 0;
          return this.xScale(d[1]) - this.xScale(d[0]);
        });

      // Draw Axes
      const xAxis = this.d3.axisTop(this.xScale).ticks(5, '~s').tickSizeOuter(0);
      const yAxis = this.d3.axisLeft(this.yScale).tickSizeOuter(0);

      const yAxisGroup = this.ctr
        .append('g')
        .classed('y-axis-labels', true)
        .attr('transform', `translate(${this.margin.left}, 0)`)
        .style('cursor', 'pointer')
        .style('font-size', 14)
        .style('font-family', 'Arial')
        .call(yAxis);

      yAxisGroup
        .selectAll('.tick')
        .on('click', (e, d) => {
          const barInfo = {
            agentIds: [],
            callTypeIds: [
              this.immutableData.find((datum) => datum.name == e.srcElement.__data__).id
            ]
          };
          this.barSegmentClick.emit(barInfo);
        })
        .on('mouseover', (e, d) => {
          const data = this.immutableData.find(
            (datum) => datum.name == e.srcElement.__data__
          );
          this.mouseoverType(e, data, Tooltip);
        })
        .on('mousemove', (e, d) => {
          const data = this.immutableData.find(
            (datum) => datum.name == e.srcElement.__data__
          );
          this.mousemoveType(e, data, Tooltip);
        })
        .on('mouseleave', (e, d) => {
          this.mouseleave(e, d, Tooltip);
        });

      this.ctr
        .append('g')
        .classed('x-axis-labels', true)
        .attr('transform', `translate(0, ${0})`)
        .style('font-size', 14)
        .style('font-family', 'Arial')
        .style('cursor', 'none')
        .transition()
        .ease(this.d3.easePolyInOut)
        .duration(this.transitionDuration)
        .call(xAxis);

      // Create legend container
      const lineHeight = 24;
      const legendItemCount = this.stackData.length;
      const marginBottom = 20; // Define the margin-bottom value
      const legendHeight = legendItemCount * lineHeight + marginBottom;

      const legendContainer = this.legendSvg
        .attr('height', legendHeight)
        .append('g')
        .attr('class', 'legend-container')
        .attr('transform', 'translate(20, 20)');

      const legendItem = legendContainer
        .selectAll('.legend-item')
        .data(this.stackData)
        .join('g')
        .attr('class', 'legend-item')
        .attr('transform', (d, i) => `translate(0, ${i * lineHeight})`)
        .style('font-size', '14px')
        .style('cursor', 'pointer')
        .on('mouseover', (e, d) => {
          this.highlightSegments(d.key);
        })
        .on('mouseout', (e, d) => {
          this.removeHighlight();
        });

      legendItem
        .append('rect')
        .attr('width', 18)
        .attr('height', 18)
        .style('fill', (d) => colorScale(d.key))
        .style('stroke', (d) => colorScale(d.key));

      legendItem
        .append('text')
        .attr('x', 24)
        .attr('y', 9)
        .attr('dy', '.35em')
        .text((d) => d.key);
    }, 100);
  }
}

// NOTE leaving this in for future use case
//Segment text Labels
// callTypes
//   .selectAll('text')
//   .data((d) => {
//     return d;
//   })
//   .join('text')
//   .attr('x', (d) => this.xScale(d[0] + 1))
//   .style('opacity', 0)
//   .transition()
//   .style('opacity', 1)
//   .duration(this.transitionDuration)
//   // @ts-ignore
//   .attr('y', (d) => this.yScale(d.data.name) + this.yScale.bandwidth() / 2)
//   .style('cursor', 'pointer')
//   .attr('fill', 'white')
//   .attr('dominant-baseline', 'middle')
//   .style('font-weight', 600)
//   .attr('class', 'text-label')
//   .style('font-family', 'Arial')
//   .style('font-size', this.fontSize)
//   .text((d) => {
//     // @ts-ignore
//     return d.data[d.key] > 1 ? d.data[d.key] : '';
//   });
