import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Observable, EMPTY, Subscription, combineLatest, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import { GenericMap } from 'src/app/models/generic-map';
import { LabelGroup } from 'src/app/models/label-group';
import { Label } from 'src/app/models/label';
import { LoadCompanyLabels } from 'src/app/store/system-settings/company-labels/company-labels.actions';
import { selectCompanyLabelsAsArray } from 'src/app/store/system-settings/company-labels/selectors/all-companyLabels-as-array.selector';
import { Company } from 'src/app/models/companies/company';
import { convertToMap } from 'src/app/utils/convertToMap';
import { ActivatedRoute } from '@angular/router';
import { UpdateCompany } from 'src/app/store/companies/companies.actions';
import { selectCurrentCompany } from 'src/app/store/companies/selectors/current-company.selector';

@Component({
  selector: 'tn-company-labels',
  templateUrl: './company-labels.component.html',
  styleUrls: ['./company-labels.component.scss']
})
export class CompanyLabelsComponent implements OnInit, OnDestroy {
  company: Company;
  $company: Observable<Company>;
  $companySub: Subscription;

  $allCompanyLabelGroups: Observable<GenericMap<LabelGroup>> = EMPTY;
  $companyLabelsSub: Subscription;
  allLabelGroups: GenericMap<LabelGroup> = {};
  allLabelGroupsArray: LabelGroup[] = [];
  hasLabelGroups: boolean;
  allLabelsMap: GenericMap<Label> = {};
  labelCount: number;
  labels;
  filteredLabels;
  groups: GenericMap<LabelGroup> = {};
  groupsArray: LabelGroup[] = [];
  filteredGroupsArray: LabelGroup[] = [];
  hasRequiredLabel: boolean;
  hasRequiredField: boolean;

  removable = true;

  $pending: Observable<boolean> = EMPTY;

  constructor(private store: Store<RootState>) {}

  ngOnInit() {
    this.store.dispatch(new LoadCompanyLabels());
    this.$pending = this.store.select('companies', 'pending');
    this.$allCompanyLabelGroups = this.store.select(selectCompanyLabelsAsArray);
    this.$company = this.store.select(selectCurrentCompany);
    this.$companySub = combineLatest([
      this.$company,
      this.$allCompanyLabelGroups
    ]).subscribe(([comp, groups]) => {
      this.company = comp;
      this.labels = comp.labels;
      this.filteredLabels = comp.labels;
      this.allLabelGroups = groups;
      this.allLabelGroupsArray = Object.values(groups)
        .sort((a, b) => {
          const textA = a.name.toLowerCase();
          const textB = b.name.toLowerCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        })
        .map((labelGroup: LabelGroup) => {
          return {
            ...labelGroup,
            labels: labelGroup.labels.sort((a, b) => {
              const textA = a.name.toLowerCase();
              const textB = b.name.toLowerCase();
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            })
          };
        });
      this.hasLabelGroups = this.allLabelGroupsArray.length > 0;
      Object.values(groups).forEach((group: LabelGroup) => {
        this.allLabelsMap = {
          ...this.allLabelsMap,
          ...convertToMap(group.labels, 'id')
        };
      });
      if (comp.labels) {
        comp.labels.map((label) => {
          this.allLabelGroupsArray.map((group) => {
            if (label.groupId === group.id) {
              label.groupName = group.name;
            }
          });
        });
        this.labelCount = comp.labels.length;
      } else {
        this.labelCount = 0;
      }
      this.makeGroups();
    });
  }

  ngOnDestroy() {
    this.$companySub.unsubscribe();
  }

  makeGroups() {
    this.groups = this.filteredLabels.reduce((groups, item) => {
      const group = groups[item.groupName] || [];
      group.push(item);
      group.labels = groups[item.groupName] = group;
      group.name = item.groupName;
      return groups;
    }, {});
    this.makeGroupsArray();
  }

  makeGroupsArray() {
    this.groupsArray = Object.values(this.groups).map((labelGroup: LabelGroup) => {
      return {
        ...labelGroup
      };
    });
    this.filteredGroupsArray = this.groupsArray;
  }

  applyFilter(query: Event) {
    this.filteredGroupsArray = query
      ? this.groupsArray.filter((l) =>
          l.name
            .toString()
            .toLocaleLowerCase()
            .includes(query.toString().toLocaleLowerCase())
        )
      : this.groupsArray;
  }

  deleteLabel(label) {
    const removeIndex = this.filteredLabels
      .map((filteredLabel) => filteredLabel.id)
      .indexOf(label.id);
    this.filteredLabels.splice(removeIndex, 1);
    this.company.labels = this.filteredLabels;
    this.makeGroups();
    this.store.dispatch(new UpdateCompany(this.company));
  }
}
