import { Injectable } from '@angular/core';

import { RoleV2 } from '../models/admin/roles/role-V2';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class RoleService {

    constructor(private authService: AuthenticationService) {
    }

    getAll(): Promise<RoleV2[]> {
        return this.authService.makeJpiRequest('/roles', null, null, 'get').then((roles) => {
            return JSON.parse(roles);
        });
    }

    getById(id: number): Promise<RoleV2> {
        return this.authService.makeJpiRequest('/roles/' + id, null, null, 'get').then((role) => {
            return JSON.parse(role);
        });
    }

    create(role: RoleV2): Promise<RoleV2> {
        return this.authService.makeJpiRequest('/roles', null, role, 'post').then((role) => {
            return JSON.parse(role);
        });
    }

    update(role: RoleV2): Promise<RoleV2> {
        return this.authService.makeJpiRequest('/roles/' + role.id, null, role, 'put').then((role) => {
            return JSON.parse(role);
        });
    }

    delete(role: RoleV2): Promise<RoleV2> {
        return this.authService.makeJpiRequest('/roles/' + role.id, null, role, 'delete').then((role) => {
            return JSON.parse(role);
        });
    }
}
