import { ProjectBidsActions, ProjectBidsActionTypes } from './project-bids.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { ProjectBid } from '../../../models/admin/projects/project-bid';

export interface ProjectBidsState {
  pending: boolean;
  error: HttpErrorResponse;
  data: { [id: number]: ProjectBid };
  selectedProjectBidId: number;
}

export const initialState: ProjectBidsState = {
  pending: false,
  error: undefined,
  data: {},
  selectedProjectBidId: undefined
};

export function projectBidsReducer(
  state = initialState,
  action: ProjectBidsActions
): ProjectBidsState {
  switch (action.type) {
    case ProjectBidsActionTypes.SelectProjectBidId:
      return {
        ...state,
        selectedProjectBidId: action.payload
      };

    case ProjectBidsActionTypes.ClearProjectBidId:
      return {
        ...state,
        selectedProjectBidId: undefined
      };

    case ProjectBidsActionTypes.LoadProjectBids:
      return {
        ...state,
        pending: true
      };

    case ProjectBidsActionTypes.LoadProjectBidsSuccess:
      return {
        ...state,
        pending: false,
        data: action.payload
      };

    case ProjectBidsActionTypes.LoadProjectBidsFailure:
      return {
        ...state,
        pending: false,
        data: {},
        error: action.payload
      };

    case ProjectBidsActionTypes.AddProjectBids:
      return {
        ...state,
        pending: true
      };

    case ProjectBidsActionTypes.AddProjectBidsSuccess:
      return {
        ...state,
        pending: false
      };

    case ProjectBidsActionTypes.AddProjectBidsFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case ProjectBidsActionTypes.UpdateProjectBidsSuccess:
      return {
        ...state,
        pending: false
      };

    case ProjectBidsActionTypes.UpdateProjectBidsFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case ProjectBidsActionTypes.DeleteProjectBids:
      return {
        ...state,
        pending: true
      };

    case ProjectBidsActionTypes.DeleteProjectBidsSuccess:
      return {
        ...state,
        pending: false,
        selectedProjectBidId: undefined
      };

    case ProjectBidsActionTypes.DeleteProjectBidsFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    default:
      return state;
  }
}
