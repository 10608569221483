import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { RootState } from 'src/app/store/store.reducer';
import { Store } from '@ngrx/store';
import { Observable, EMPTY, combineLatest, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ColumnHeader } from 'src/app/models/column-header';
import {
  selectSelOppColumns,
  selectAllOppColsWithLabels
} from 'src/app/store/opportunities/selectors/selected-opportunities-table-columns.selector';
import { SelectOppTableColumns } from 'src/app/store/opportunities/opportunities.actions';
import { selectCurrentUser } from 'src/app/store/users/selectors/id-name-current-user.selector';
import { User } from 'src/app/models/admin/users/user';

@Component({
  selector: 'tn-opp-column-selector',
  templateUrl: './opp-column-selector.component.html',
  styleUrls: ['./opp-column-selector.component.scss']
})
export class OppColumnSelectorComponent implements OnInit, OnDestroy {
  @Input() data: any;
  $allColumns: Observable<ColumnHeader[]> = EMPTY;
  allColumns: ColumnHeader[] = [];
  $selectedColumns: Observable<ColumnHeader[]> = EMPTY;
  selectedColumnsNames: string[] = [];
  $columns: Observable<{ name: string; selected: boolean }[]> = EMPTY;
  displayedColumns = ['name', 'selected'];
  $currentUser: Observable<User> = EMPTY;
  currentUser: User;
  currentUserSub: Subscription;

  constructor(private store: Store<RootState>) {}

  ngOnInit() {
    this.$currentUser = this.store.select(selectCurrentUser);
    this.currentUserSub = this.$currentUser.subscribe((user) => {
      this.currentUser = user;
    });
    this.$allColumns = this.store.select(selectAllOppColsWithLabels);
    this.$selectedColumns = this.store.select(selectSelOppColumns);
    this.$columns = combineLatest([this.$allColumns, this.$selectedColumns]).pipe(
      map((resultArray) => {
        return { allColumns: resultArray[0], selectedColumns: resultArray[1] };
      }),
      map(({ allColumns, selectedColumns }) => {
        this.selectedColumnsNames = selectedColumns.map((col) => col.name);
        if (this.currentUser?.agencyId !== 16) {
          allColumns = allColumns.filter(
            (col) => col.name !== 'businessUnit' && col.name !== 'divisionName'
          );
        }
        this.allColumns = allColumns;
        return allColumns.map((col: ColumnHeader) => {
          return {
            ...col,
            name: col.name,
            selected:
              selectedColumns
                .map((selColumn: ColumnHeader) => selColumn.name)
                .indexOf(col.name) >= 0
          };
        });
      })
    );
  }

  ngOnDestroy() {
    this.currentUserSub.unsubscribe();
  }

  onCheckboxChanged(selColumn: ColumnHeader, event: MatCheckboxChange) {
    if (event.checked) {
      this.selectedColumnsNames.push(selColumn.name);
      const newColumnArr = this.allColumns.filter(
        (col) => this.selectedColumnsNames.indexOf(col.name) > -1
      );
      this.store.dispatch(new SelectOppTableColumns(newColumnArr));
    } else {
      const newColumnArr = this.allColumns.filter(
        (col) =>
          this.selectedColumnsNames
            .filter((colName) => colName !== selColumn.name)
            .indexOf(col.name) > -1
      );
      this.store.dispatch(new SelectOppTableColumns(newColumnArr));
    }
  }
}
