import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import {
  LoadClientsFailure,
  LoadClientsSuccess,
  ClientsActionTypes,
  ClientsActions,
  CreateClients,
  CreateClientsSuccess,
  CreateClientsFailure,
  UpdateClients,
  UpdateClientsSuccess,
  UpdateClientsFailure,
  DeleteClients,
  DeleteClientsSuccess,
  DeleteClientsFailure,
  SyncToElastic,
  SyncToElasticSuccess,
  SyncToElasticFailure,
  LoadCurrentClient,
  LoadCurrentClientFailure,
  LoadCurrentClientSuccess
} from './clients.actions';
import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { Client } from 'src/app/models/admin/client/client';
import { ClientsService } from '../../services/clients/clients.service';

@Injectable()
export class ClientsEffects {
  loadClients$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientsActionTypes.LoadClients),
      mergeMap(() =>
        this.clientsService.getAll().pipe(
          map((data: Client[]) => new LoadClientsSuccess(data)),
          catchError((error: HttpErrorResponse) => of(new LoadClientsFailure(error)))
        )
      )
    )
  );

  loadCurrentClient$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientsActionTypes.LoadCurrentClient),
      map((action: LoadCurrentClient) => action.payload),
      mergeMap((clientId: number) =>
        this.clientsService.getById(clientId).pipe(
          map((client: Client) => new LoadCurrentClientSuccess(client)),
          catchError((error: HttpErrorResponse) =>
            of(new LoadCurrentClientFailure(error))
          )
        )
      )
    )
  );

  createClients$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientsActionTypes.CreateClients),
      map((action: CreateClients) => action.payload),
      mergeMap((client: Client) =>
        this.clientsService.create(client).pipe(
          map((newClient: Client) => new CreateClientsSuccess(newClient)),
          catchError((error: HttpErrorResponse) => of(new CreateClientsFailure(error)))
        )
      )
    )
  );

  updateClients$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientsActionTypes.UpdateClients),
      map((action: UpdateClients) => action.payload),
      mergeMap((client: Client) =>
        this.clientsService.update(client).pipe(
          map((updatedClient: Client) => new UpdateClientsSuccess(updatedClient)),
          catchError((error: HttpErrorResponse) => of(new UpdateClientsFailure(error)))
        )
      )
    )
  );

  deleteClients$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientsActionTypes.DeleteClients),
      map((action: DeleteClients) => action.payload),
      mergeMap((clientId: number) =>
        this.clientsService.delete(clientId).pipe(
          map(() => new DeleteClientsSuccess(clientId)),
          catchError((error: HttpErrorResponse) => of(new DeleteClientsFailure(error)))
        )
      )
    )
  );

  syncToElastic$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ClientsActionTypes.SyncToElastic),
      map((action: SyncToElastic) => action.payload),
      mergeMap((clientId: number) =>
        this.clientsService.sync(clientId).pipe(
          map(() => new SyncToElasticSuccess(clientId)),
          catchError((error: HttpErrorResponse) => of(new SyncToElasticFailure(error)))
        )
      )
    )
  );

  constructor(
    private clientsService: ClientsService,
    private actions$: Actions<ClientsActions>
  ) {}
}
