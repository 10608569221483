import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { CallNote } from 'src/app/models/call-notes/call-note';
import { Company } from 'src/app/models/companies/company';
import { CallCountNotesRequest } from 'src/app/models/dashboard/call-notes/call-count-notes-request';
import { CallTypeCountsRequest } from 'src/app/models/dashboard/call-notes/call-type-counts-request';
import { FieldCountsRequest } from 'src/app/models/dashboard/call-notes/field-counts-request';
import { LabelGroupCount } from 'src/app/models/dashboard/call-notes/label-group-count';
import { LabelGroupCountsRequest } from 'src/app/models/dashboard/call-notes/label-group-counts-request';
import { LabelGroupsCount } from 'src/app/models/dashboard/call-notes/label-groups-count';
import { LabelGroupsCountsRequest } from 'src/app/models/dashboard/call-notes/label-groups-counts-request';
import { ChartDatum } from 'src/app/models/dashboard/chart-datum';
import { CompanyContacted } from 'src/app/models/dashboard/company-contacted';
import { DashboardApiRequest } from 'src/app/models/dashboard/dashboard-api-request';
import { DashboardCount } from 'src/app/models/dashboard/dashboard-count';
import { DashboardStackCount } from 'src/app/models/dashboard/dashboard-stack-count';
import { GlobalFilter } from 'src/app/models/global-filter';

export enum DashboardActionTypes {
  LoadDashboard = '[Dashboard] Load Dashboard',

  LoadCallTypes = '[Dashboard] Load Call Types',
  LoadCallTypesSuccess = '[Dashboard] Load Call Types Success',
  LoadCallTypesFailure = '[Dashboard] Load Call Types Failure',

  ClearCallNotes = '[Dashboard] Clear Call Notes',
  LoadCallTypeNotes = '[Dashboard] Load Call Type Notes',
  LoadCallTypeNotesSuccess = '[Dashboard] Load Call Type Notes Success',
  LoadCallTypeNotesFailure = '[Dashboard] Load Call Type Notes Failure',

  LoadCallFieldNotes = '[Dashboard] Load Call Field Notes',
  LoadCallFieldNotesSuccess = '[Dashboard] Load Call Field Notes Success',
  LoadCallFieldNotesFailure = '[Dashboard] Load Call Field Notes Failure',

  LoadCallUserNotes = '[Dashboard] Load Call User Notes',
  LoadCallUserNotesSuccess = '[Dashboard] Load Call User Notes Success',
  LoadCallUserNotesFailure = '[Dashboard] Load Call User Notes Failure',

  LoadCallTypeCounts = '[Dashboard] Load Call Type Counts',
  LoadCallTypeCountsSuccess = '[Dashboard] Load Call Type Counts Success',
  LoadCallTypeCountsFailure = '[Dashboard] Load Call Type Counts Failure',

  LoadCallTypeUserCounts = '[Dashboard] Load Call Type User Counts',
  LoadCallTypeUserCountsSuccess = '[Dashboard] Load Call Type User Counts Success',
  LoadCallTypeUserCountsFailure = '[Dashboard] Load Call Type User Counts Failure',

  LoadCallFieldCounts = '[Dashboard] Load Call Field Counts',
  LoadCallFieldCountsSuccess = '[Dashboard] Load Call Field Counts Success',
  LoadCallFieldCountsFailure = '[Dashboard] Load Call Field Counts Failure',

  LoadCallUserCounts = '[Dashboard] Load Call User Counts',
  LoadCallUserCountsSuccess = '[Dashboard] Load Call User Counts Success',
  LoadCallUserCountsFailure = '[Dashboard] Load Call User Counts Failure',

  LoadCallLabelGroupCounts = '[Dashboard] Load Call Label Group Counts',
  LoadCallLabelGroupCountsSuccess = '[Dashboard] Load Call Label Group Counts Success',
  LoadCallLabelGroupCountsFailure = '[Dashboard] Load Call Label Group Counts Failure',

  LoadCallLabelGroupsCounts = '[Dashboard] Load Call Label Groups Counts',
  LoadCallLabelGroupsCountsSuccess = '[Dashboard] Load Call Label Groups Counts Success',
  LoadCallLabelGroupsCountsFailure = '[Dashboard] Load Call Label Groups Counts Failure',

  LoadLast12Months = '[Dashboard] Load Last 12 Months',
  LoadLast12MonthsSuccess = '[Dashboard] Load Last 12 Months Success',
  LoadLast12MonthsFailure = '[Dashboard] Load Last 12 Months Failure',

  LoadNewCompanies = '[Dashboard] Load New Companies',
  LoadNewCompaniesSuccess = '[Dashboard] Load New Companies Success',
  LoadNewCompaniesFailure = '[Dashboard] Load New Companies Failure',

  LoadContactedCompanies = '[Dashboard] Load Contacted Companies',
  LoadContactedCompaniesSuccess = '[Dashboard] Load Contacted Companies Success',
  LoadContactedCompaniesFailure = '[Dashboard] Load Contacted Companies Failure',

  SetDateRange = '[Dashboard] Set Date Range',
  SetUserIds = '[Dashboard] Set User Ids',
  SetTerritoryIds = '[Dashboard] Set Territory Ids',
  SetCallTypeIds = '[Dashboard] Set Call Type Ids',
  SetCallFieldIds = '[Dashboard] Set Call Fields Ids',
  SetLabelGroupIds = '[Dashboard] Set Label Group Ids'
}

export class LoadDashboard implements Action {
  readonly type = DashboardActionTypes.LoadDashboard;
}

export class ClearCallNotes implements Action {
  readonly type = DashboardActionTypes.ClearCallNotes;
}

export class LoadCallTypeCounts implements Action {
  readonly type = DashboardActionTypes.LoadCallTypeCounts;
  constructor(public payload: CallTypeCountsRequest) {}
}
export class LoadCallTypeCountsSuccess implements Action {
  readonly type = DashboardActionTypes.LoadCallTypeCountsSuccess;
  constructor(public payload: DashboardCount[]) {}
}
export class LoadCallTypeCountsFailure implements Action {
  readonly type = DashboardActionTypes.LoadCallTypeCountsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadCallTypeUserCounts implements Action {
  readonly type = DashboardActionTypes.LoadCallTypeUserCounts;
  constructor(public payload: CallTypeCountsRequest) {}
}
export class LoadCallTypeUserCountsSuccess implements Action {
  readonly type = DashboardActionTypes.LoadCallTypeUserCountsSuccess;
  constructor(public payload: DashboardStackCount[]) {}
}
export class LoadCallTypeUserCountsFailure implements Action {
  readonly type = DashboardActionTypes.LoadCallTypeUserCountsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadCallTypeNotes implements Action {
  readonly type = DashboardActionTypes.LoadCallTypeNotes;
  constructor(public payload: CallCountNotesRequest) {}
}
export class LoadCallTypeNotesSuccess implements Action {
  readonly type = DashboardActionTypes.LoadCallTypeNotesSuccess;
  constructor(public payload: CallNote[]) {}
}
export class LoadCallTypeNotesFailure implements Action {
  readonly type = DashboardActionTypes.LoadCallTypeNotesFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadCallFieldNotes implements Action {
  readonly type = DashboardActionTypes.LoadCallFieldNotes;
  constructor(public payload: CallCountNotesRequest) {}
}
export class LoadCallFieldNotesSuccess implements Action {
  readonly type = DashboardActionTypes.LoadCallFieldNotesSuccess;
  constructor(public payload: CallNote[]) {}
}
export class LoadCallFieldNotesFailure implements Action {
  readonly type = DashboardActionTypes.LoadCallFieldNotesFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadCallUserNotes implements Action {
  readonly type = DashboardActionTypes.LoadCallUserNotes;
  constructor(public payload: CallCountNotesRequest) {}
}
export class LoadCallUserNotesSuccess implements Action {
  readonly type = DashboardActionTypes.LoadCallUserNotesSuccess;
  constructor(public payload: CallNote[]) {}
}
export class LoadCallUserNotesFailure implements Action {
  readonly type = DashboardActionTypes.LoadCallUserNotesFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadCallFieldCounts implements Action {
  readonly type = DashboardActionTypes.LoadCallFieldCounts;
  constructor(public payload: FieldCountsRequest) {}
}
export class LoadCallFieldCountsSuccess implements Action {
  readonly type = DashboardActionTypes.LoadCallFieldCountsSuccess;
  constructor(public payload: DashboardCount[]) {}
}
export class LoadCallFieldCountsFailure implements Action {
  readonly type = DashboardActionTypes.LoadCallFieldCountsFailure;
  constructor(public payload: HttpErrorResponse) {}
}
export class LoadCallUserCounts implements Action {
  readonly type = DashboardActionTypes.LoadCallUserCounts;
  constructor(public payload: DashboardApiRequest) {}
}
export class LoadCallUserCountsSuccess implements Action {
  readonly type = DashboardActionTypes.LoadCallUserCountsSuccess;
  constructor(public payload: DashboardCount[]) {}
}
export class LoadCallUserCountsFailure implements Action {
  readonly type = DashboardActionTypes.LoadCallUserCountsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadCallLabelGroupCounts implements Action {
  readonly type = DashboardActionTypes.LoadCallLabelGroupCounts;
  constructor(public payload: LabelGroupCountsRequest) {}
}
export class LoadCallLabelGroupCountsSuccess implements Action {
  readonly type = DashboardActionTypes.LoadCallLabelGroupCountsSuccess;
  constructor(public payload: LabelGroupCount[]) {}
}
export class LoadCallLabelGroupCountsFailure implements Action {
  readonly type = DashboardActionTypes.LoadCallLabelGroupCountsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadCallLabelGroupsCounts implements Action {
  readonly type = DashboardActionTypes.LoadCallLabelGroupsCounts;
  constructor(public payload: LabelGroupsCountsRequest) {}
}
export class LoadCallLabelGroupsCountsSuccess implements Action {
  readonly type = DashboardActionTypes.LoadCallLabelGroupsCountsSuccess;
  constructor(public payload: LabelGroupsCount[]) {}
}
export class LoadCallLabelGroupsCountsFailure implements Action {
  readonly type = DashboardActionTypes.LoadCallLabelGroupsCountsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadLast12Months implements Action {
  readonly type = DashboardActionTypes.LoadLast12Months;
  constructor(public payload: DashboardApiRequest) {}
}
export class LoadLast12MonthsSuccess implements Action {
  readonly type = DashboardActionTypes.LoadLast12MonthsSuccess;
  constructor(public payload: ChartDatum[]) {}
}
export class LoadLast12MonthsFailure implements Action {
  readonly type = DashboardActionTypes.LoadLast12MonthsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadNewCompanies implements Action {
  readonly type = DashboardActionTypes.LoadNewCompanies;
  constructor(
    public payload: {
      filter: GlobalFilter;
      params: {
        skip: number;
        limit: number;
        order: string;
        orderAsc: boolean;
      };
    }
  ) {}
}
export class LoadNewCompaniesSuccess implements Action {
  readonly type = DashboardActionTypes.LoadNewCompaniesSuccess;
  constructor(public payload: Company[]) {}
}
export class LoadNewCompaniesFailure implements Action {
  readonly type = DashboardActionTypes.LoadNewCompaniesFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadContactedCompanies implements Action {
  readonly type = DashboardActionTypes.LoadContactedCompanies;
  constructor(
    public payload: {
      filter: GlobalFilter;
      params: {
        skip: number;
        limit: number;
        order: string;
      };
    }
  ) {}
}
export class LoadContactedCompaniesSuccess implements Action {
  readonly type = DashboardActionTypes.LoadContactedCompaniesSuccess;
  constructor(public payload: CompanyContacted[]) {}
}
export class LoadContactedCompaniesFailure implements Action {
  readonly type = DashboardActionTypes.LoadContactedCompaniesFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class SetDateRange implements Action {
  readonly type = DashboardActionTypes.SetDateRange;
  constructor(public payload: { begin: string; end: string }) {}
}
export class SetUserIds implements Action {
  readonly type = DashboardActionTypes.SetUserIds;
  constructor(public payload: number[]) {}
}
export class SetTerritoryIds implements Action {
  readonly type = DashboardActionTypes.SetTerritoryIds;
  constructor(public payload: number[]) {}
}
export class SetCallTypeIds implements Action {
  readonly type = DashboardActionTypes.SetCallTypeIds;
  constructor(public payload: number[]) {}
}
export class SetCallFieldIds implements Action {
  readonly type = DashboardActionTypes.SetCallFieldIds;
  constructor(public payload: number[]) {}
}
export class SetLabelGroupIds implements Action {
  readonly type = DashboardActionTypes.SetLabelGroupIds;
  constructor(public payload: number[]) {}
}

export type DashboardActions =
  | LoadDashboard
  | ClearCallNotes
  | LoadCallTypeCounts
  | LoadCallTypeCountsSuccess
  | LoadCallTypeCountsFailure
  | LoadCallTypeNotes
  | LoadCallTypeNotesSuccess
  | LoadCallTypeNotesFailure
  | LoadCallTypeUserCounts
  | LoadCallTypeUserCountsSuccess
  | LoadCallTypeUserCountsFailure
  | LoadCallFieldNotes
  | LoadCallFieldNotesSuccess
  | LoadCallFieldNotesFailure
  | LoadCallUserNotes
  | LoadCallUserNotesSuccess
  | LoadCallUserNotesFailure
  | LoadCallFieldCounts
  | LoadCallFieldCountsSuccess
  | LoadCallFieldCountsFailure
  | LoadCallUserCounts
  | LoadCallUserCountsSuccess
  | LoadCallUserCountsFailure
  | LoadCallLabelGroupCounts
  | LoadCallLabelGroupCountsSuccess
  | LoadCallLabelGroupCountsFailure
  | LoadCallLabelGroupsCounts
  | LoadCallLabelGroupsCountsSuccess
  | LoadCallLabelGroupsCountsFailure
  | LoadLast12Months
  | LoadLast12MonthsSuccess
  | LoadLast12MonthsFailure
  | LoadNewCompanies
  | LoadNewCompaniesSuccess
  | LoadNewCompaniesFailure
  | LoadContactedCompanies
  | LoadContactedCompaniesSuccess
  | LoadContactedCompaniesFailure
  | SetDateRange
  | SetUserIds
  | SetTerritoryIds
  | SetCallTypeIds
  | SetCallFieldIds
  | SetLabelGroupIds;
