import { Pipe, PipeTransform } from '@angular/core';
import { ProjectBidEstimate } from 'src/app/models/projects/project-bid-estimate';

@Pipe({
  name: 'manufacturerBidFilter'
})
export class ManufacturerBidFilterPipe implements PipeTransform {
  transform(estimates: ProjectBidEstimate[], searchText: string): ProjectBidEstimate[] {
    searchText = searchText ? searchText.toLowerCase() : null;
    return !!estimates
      ? estimates.filter(est => {
          return !!searchText
            ? est.manufacturer.name.toLowerCase().match(searchText) ||
                est.estimate.toString().match(searchText) ||
                (est.projectBids
                  ? est.projectBids
                      .map(bid => bid.company.name + ';' + bid.amount + ';')
                      .join(', ')
                      .toLowerCase()
                      .match(searchText)
                  : false) ||
                est.projectBids
                  .map(individualBid => {
                    return individualBid.customFieldValues.map(cfv => cfv.fieldValue);
                  })
                  .flat()
                  .join(', ')
                  .toLowerCase()
                  .match(searchText) ||
                est.customFields
                  .map(cf => cf.fieldValue)
                  .join(', ')
                  .toLowerCase()
                  .match(searchText)
            : true;
        })
      : [];
  }
}
