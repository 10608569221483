<div *ngIf="canAssignTerritories">
    <div fxLayout="row" fxFlex>
        <div fxFlex="4"></div>
        <div class="client-input input-padding" fxLayout="column" fxFlex>
            <div>Territories</div>
            <dx-tag-box [dataSource]="territories" displayExpr="name" valueExpr="id" [(value)]="selectedTerritoryIds"
                [showSelectionControls]="true" applyValueMode="instantly" [hideSelectedItems]="true" [multiline]="true"
                searchEnabled="true" (onSelectionChanged)="territorySelectionChanged($event)"></dx-tag-box>
        </div>
        <div fxFlex="4"></div>
    </div>
</div>
<div *ngIf="canAssignTerritories && selectedTerritories.length>0">
    <div fxLayout="row" fxFlex>
        <div fxFlex="4"></div>
        <div class="client-input input-padding" fxLayout="column" fxFlex>
            <div>Primary Sales Rep</div>
            <dx-select-box [dataSource]="primaryUsers" displayExpr="fullName" valueExpr="id" [(value)]="primaryUserId"
                searchEnabled="true"></dx-select-box>
        </div>
        <div fxFlex="4"></div>
    </div>
</div>