import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, withLatestFrom, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as BidCustomFieldsActions from './bid-custom-fields.actions';
import { selectAgencyId } from '../../companies/selectors/files-as-array.selector';
import { ProjectCustomField } from 'src/app/models/projects/project-custom-field';
import { convertToMap } from 'src/app/utils/convertToMap';
import { ProjectsService } from 'src/app/services/projects/projects.service';
import { RootState } from '../../store.reducer';
import { Store } from '@ngrx/store';

@Injectable()
export class BidCustomFieldsEffects {
  loadBidCustomFields$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BidCustomFieldsActions.LoadBidCustomFields),
      concatMap(() =>
        this.projService.getBidCustomFields().pipe(
          map((data: ProjectCustomField[]) =>
            BidCustomFieldsActions.LoadBidCustomFieldsSuccess({
              data: convertToMap(data, 'id')
            })
          ),
          catchError(error =>
            of(BidCustomFieldsActions.LoadBidCustomFieldsFailure({ error }))
          )
        )
      )
    );
  });

  saveBidCustomField$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BidCustomFieldsActions.SaveBidCustomField),
      map(action => action.data),
      withLatestFrom(this.store.select(selectAgencyId)),
      concatMap(([pcf, agencyId]: [ProjectCustomField, number]) => {
        if (!!pcf.id) {
          return this.projService.updateBidCustomField(pcf).pipe(
            switchMap((data: ProjectCustomField) => [
              BidCustomFieldsActions.SaveBidCustomFieldSuccess({ data }),
              BidCustomFieldsActions.LoadBidCustomFields()
            ]),
            catchError(error =>
              of(BidCustomFieldsActions.SaveBidCustomFieldFailure({ error }))
            )
          );
        } else {
          return this.projService.createBidCustomField({ ...pcf, agencyId }).pipe(
            switchMap(data => [
              BidCustomFieldsActions.SaveBidCustomFieldSuccess({ data }),
              BidCustomFieldsActions.LoadBidCustomFields()
            ]),
            catchError(error =>
              of(BidCustomFieldsActions.LoadBidCustomFieldsFailure({ error }))
            )
          );
        }
      })
    );
  });

  deleteBidCustomField$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BidCustomFieldsActions.DeleteBidCustomField),
      map(action => action.data),
      concatMap((pcf: ProjectCustomField) =>
        this.projService.deleteBidCustomField(pcf).pipe(
          switchMap(data => [
            BidCustomFieldsActions.DeleteBidCustomFieldSuccess({ data }),
            BidCustomFieldsActions.LoadBidCustomFields()
          ]),
          catchError(error =>
            of(BidCustomFieldsActions.DeleteBidCustomFieldFailure({ error }))
          )
        )
      )
    );
  });

  updateBidCustomFieldsListOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BidCustomFieldsActions.UpdateBidCustomFieldsListOrder),
      map(action => action.data),
      concatMap((pCFs: ProjectCustomField[]) =>
        this.projService.updateBidCustomFieldsListOrder(pCFs).pipe(
          map(data =>
            BidCustomFieldsActions.UpdateBidCustomFieldsListOrderSuccess({
              data
            })
          ),
          catchError(error =>
            of(
              BidCustomFieldsActions.UpdateBidCustomFieldsListOrderFailure({
                error
              })
            )
          )
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private projService: ProjectsService,
    private store: Store<RootState>
  ) {}
}
