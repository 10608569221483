<form [formGroup]="fileCategoryForm" class="file-category-form">
  <mat-form-field class="example-full-width">
    <mat-label>Name</mat-label>
    <input
      [value]="currentCategory?.name"
      formControlName="name"
      matInput
      type="string"
    />
  </mat-form-field>
  <div fxLayout="row-reverse" fxLayoutAlign="space-between center">
    <div fxLayout="row-reverse">
      <button
        [disabled]="fileCategoryForm.pristine || fileCategoryForm.invalid"
        *ngIf="!currentCategory?.id"
        mat-raised-button
        color="primary"
        mat-button
        class="button-container"
        (click)="save()"
      >
        Save
      </button>
      <button
        [disabled]="fileCategoryForm.pristine || fileCategoryForm.invalid"
        *ngIf="currentCategory?.id"
        mat-raised-button
        mat-button
        color="primary"
        class="button-container"
        (click)="update()"
      >
        Save
      </button>
      <button
        preventDefaultAction
        class="button-container"
        mat-raised-button
        color="white"
        mat-button
        (click)="cancel()"
      >
        Cancel
      </button>
    </div>
    <button
      preventDefaultAction
      *ngIf="currentCategory?.id"
      mat-raised-button
      color="warn"
      mat-button
      (click)="delete()"
    >
      Delete
    </button>
  </div>
</form>
