import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { CallNote } from 'src/app/models/call-notes/call-note';
import { RootState } from 'src/app/store/store.reducer';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable, Subject, Subscription } from 'rxjs';
import { IdNameItem } from 'src/app/models/id-name-item';
import { SaveCallNoteComment } from 'src/app/store/call-notes/call-notes.actions';
import { selectAllMultiSelectUsers } from 'src/app/store/users/selectors/all-multi-select-users.selector';

@Component({
  selector: 'tn-project-call-note-comment-dialog',
  templateUrl: './project-call-note-comment-dialog.component.html',
  styleUrls: ['./project-call-note-comment-dialog.component.scss']
})
export class ProjectCallNoteCommentDialogComponent implements OnInit {
  commentsForm: FormGroup = new FormGroup({
    value: new FormControl(null, Validators.required)
  });
  shareForm: FormGroup = new FormGroup({
    shareWithEmails: new FormControl([]),
    manualShareWithEmails: new FormControl(
      '',
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
    )
  });
  $allUsers: Observable<IdNameItem[]>;
  $usersEventsSubject: Subject<void> = new Subject<void>();
  usersSub: Subscription;
  initialUsersArray: any[] = [];
  manualEnter = false;
  manualEmails = [];

  constructor(
    public dialogRef: MatDialogRef<ProjectCallNoteCommentDialogComponent>,
    private store: Store<RootState>,
    @Inject(MAT_DIALOG_DATA) public data: CallNote
  ) {}

  ngOnInit() {
    this.$allUsers = this.store.select(selectAllMultiSelectUsers);
    this.usersSub = this.$allUsers.subscribe((users) => {
      const usersArray: any[] = [];
      users.map((user: any) => {
        if (user.id === this.data.createdBy.id) {
          return usersArray.push(user);
        }
      });
      this.initialUsersArray = Array.from(new Set(usersArray.map((u) => u.email))).map(
        (email) => {
          return usersArray.find((u) => u.email === email);
        }
      );
      this.setUsers(this.initialUsersArray);
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  addComment() {
    if (this.manualEmails.length) {
      const allEmails = this.manualEmails.concat(this.shareForm.value.shareWithEmails);
      this.store.dispatch(
        new SaveCallNoteComment({
          callNoteID: this.data.id,
          value: this.commentsForm.value.value,
          emails: allEmails,
          deleted: false
        })
      );
    } else {
      this.store.dispatch(
        new SaveCallNoteComment({
          callNoteID: this.data.id,
          value: this.commentsForm.value.value,
          emails: this.shareForm.value.shareWithEmails,
          deleted: false
        })
      );
    }
    this.dialogRef.close();
  }

  manualEntry() {
    this.manualEnter = !this.manualEnter;
  }

  submitEmail() {
    this.manualEmails.push(this.shareForm.controls.manualShareWithEmails.value);
    // eslint-disable-next-line @typescript-eslint/dot-notation
    this.shareForm.controls['manualShareWithEmails'].reset();
  }

  removeEmail(email) {
    this.manualEmails.splice(this.manualEmails.indexOf(email), 1);
  }

  get manualShareWithEmails() {
    return this.shareForm.get('manualShareWithEmails');
  }

  setUsers(users: any[]) {
    const emailArray: string[] = [];
    users.map((user) => {
      if (user) {
        emailArray.push(user.email);
      }
    });
    this.shareForm.controls.shareWithEmails.setValue(emailArray);
  }
}
