import { Injectable } from '@angular/core';

import { AdminPermissions } from '../../models/admin/permission-enums';
import { FeaturePermissionsService } from './feature-permissions.service';
import { AuthenticationService } from '../authentication.service';

@Injectable()
export class AdminPermissionsService {

	constructor(private featurePermissionsService: FeaturePermissionsService, private authService: AuthenticationService) { }


	getRolesPermission(): Promise<number> {
		return this.featurePermissionsService.getPermissionValue(this.authService.modules.Admin, AdminPermissions.Roles).then(result => {
			return result;
		});
	}

	getUsersPermission(): Promise<number> {
		return this.featurePermissionsService.getPermissionValue(this.authService.modules.Admin, AdminPermissions.Users).then(result => {
			return result;
		});
	}

	getTerritoriesPermission(): Promise<number> {
		return this.featurePermissionsService.getPermissionValue(this.authService.modules.Admin, AdminPermissions.Territories).then(result => {
			return result;
		});
	}

	getCustomizationPermission(): Promise<number> {
		return this.featurePermissionsService.getPermissionValue(this.authService.modules.Admin, AdminPermissions.Customization).then(result => {
			return result;
		});
	}

	getDarPermission(): Promise<number> {
		return this.featurePermissionsService.getPermissionValue(this.authService.modules.Admin, AdminPermissions.EditDarLists).then(result => {
			return result;
		});
	}
}
