import { CallNoteFormAudioComponent } from './../../view/shared/call-note-form-audio/call-note-form-audio.component';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  concatMap,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import {
  LoadCompaniesFailure,
  LoadCompaniesSuccess,
  CompaniesActionTypes,
  CompaniesActions,
  SearchCompanies,
  SearchCompaniesSuccess,
  SearchCompaniesFailure,
  LoadCompanyContacts,
  LoadCompanyContactsSuccess,
  LoadCompanyContactsFailure,
  LoadCompanies,
  CreateCompany,
  CreateCompanySuccess,
  CreateCompanyFailure,
  SaveContactSuccess,
  SaveContactFailure,
  SaveContact,
  DeleteContact,
  DeleteContactSuccess,
  DeleteContactFailure,
  UploadCompanyFileSuccess,
  UploadCompanyFileFailure,
  DeleteCompanyFileSuccess,
  DeleteCompanyFileFailure,
  LoadCompany,
  LoadCompanySuccess,
  LoadCompanyFailure,
  UpdateCompany,
  UpdateCompanySuccess,
  UpdateCompanyFailure,
  LoadCompanyFiles,
  LoadCompanyFilesSuccess,
  LoadCompanyFilesFailure,
  DeleteCompany,
  DeleteCompanySuccess,
  DeleteCompanyFailure,
  SaveChildCompanies,
  SaveChildCompaniesSuccess,
  SaveChildCompaniesFailure,
  LoadDmsLinkedCompaniesSuccess,
  LoadDmsLinkedCompaniesFailure,
  LoadDmsUnlinkedCompaniesSuccess,
  LoadDmsUnlinkedCompaniesFailure,
  LoadDmsLinkedCompanySuccess,
  LoadDmsLinkedCompanyFailure,
  LoadDmsLinkedCompany,
  LoadDmsLinkedCompanies,
  LinkDmsCompany,
  LinkDmsCompanySuccess,
  LinkDmsCompanyFailure,
  UnlinkDmsCompany,
  UnlinkDmsCompanySuccess,
  UnlinkDmsCompanyFailure,
  BulkLinkDmsCompanies,
  BulkLinkDmsCompaniesSuccess,
  BulkLinkDmsCompaniesFailure,
  LoadDmsExactMatchesSuccess,
  LoadDmsExactMatchesFailure,
  LoadDmsUnlinkedCompanies,
  CreateCompanyWithContacts,
  NavigateToNewCompany,
  ClearNewCompanyContacts
} from './companies.actions';
import { CompaniesService } from 'src/app/services/companies/companies.service';
import { Company } from 'src/app/models/companies/company';
import { JpiResponse } from 'src/app/models/http/jpi-response.model';
import { HttpErrorResponse } from '@angular/common/http';
import { GlobalFilter } from 'src/app/models/global-filter';
import { ContactsService } from 'src/app/services/contacts.service';
import { convertToMap } from 'src/app/utils/convertToMap';
import { Contact } from 'src/app/models/contacts/contact';
import { Action, Store } from '@ngrx/store';
import {
  SetLoadingBarVisibility,
  OpenSnackbar,
  OpenErrorSnackbar,
  CloseDrawer,
  OpenDrawer
} from '../layout/layout.actions';
import { FileMetaData } from 'src/app/models/file-meta-data';
import { FilesService } from 'src/app/services/files/files.service';
import { RootState } from '../store.reducer';
import {
  selectCurrentCompany,
  selectCompanyParamId
} from './selectors/current-company.selector';
import { Router } from '@angular/router';
import { DMS } from 'src/app/models/companies/dms';
import { DmsLinkRequest } from 'src/app/models/companies/dms-link-request';
import { DMSExactMatch } from 'src/app/models/companies/dms-exact-match';

@Injectable()
export class CompaniesEffects {
  loadCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompaniesActionTypes.LoadCompany),
      map((action: LoadCompany) => action.payload),
      concatMap((id: number) => {
        return this.companiesService.getCompanyById(id).pipe(
          map((company: Company) => new LoadCompanySuccess(company)),
          catchError((error: HttpErrorResponse) => of(new LoadCompanyFailure(error)))
        );
      })
    )
  );

  loadCompanies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompaniesActionTypes.LoadCompanies),
      map((action: LoadCompanies) => action.payload),
      concatMap((filter: GlobalFilter) => {
        if (!!filter) {
          return this.companiesService
            .getCompaniesFiltered(filter, 30, 'name.keyword', true)
            .pipe(
              switchMap((res: JpiResponse<Company>) => {
                return [
                  new LoadCompaniesSuccess(convertToMap(res.items, 'id')),
                  ...res.items.map((company: Company) => {
                    return new LoadCompanyContactsSuccess({
                      companyId: company.id,
                      contacts: convertToMap(
                        company.contacts.map((contact: Contact) => {
                          return {
                            ...contact,
                            company: {
                              id: company.id,
                              name: company.name,
                              address: company.billingAddress
                            }
                          };
                        }),
                        'id'
                      )
                    });
                  })
                ];
              }),
              catchError((error) => of(new LoadCompaniesFailure(error)))
            );
        } else {
          return this.companiesService.getCompanies().pipe(
            map((res: JpiResponse<Company>) => {
              return new LoadCompaniesSuccess(convertToMap(res.items, 'id'));
            }),
            catchError((error) => of(new LoadCompaniesFailure(error)))
          );
        }
      })
    )
  );

  searchCompanies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompaniesActionTypes.SearchCompanies),
      map((action: SearchCompanies) => action.payload),
      mergeMap((filter: GlobalFilter) => {
        return this.companiesService
          .getCompaniesFiltered(filter, 30, 'name.keyword', true)
          .pipe(
            map(
              (res: JpiResponse<Company>) =>
                new SearchCompaniesSuccess(
                  res.items.map((company) => ({
                    id: company.id,
                    name: company.name,
                    labels: company.labels,
                    address: company.billingAddress
                  }))
                )
            ),
            catchError((error: HttpErrorResponse) =>
              of(new SearchCompaniesFailure(error))
            )
          );
      })
    )
  );

  loadCompanyContacts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompaniesActionTypes.LoadCompanyContacts),
      mergeMap((action: LoadCompanyContacts) => {
        return this.contactsService.getContactsForCompany(action.payload).pipe(
          map((contacts: Contact[]) => {
            return new LoadCompanyContactsSuccess({
              companyId: action.payload,
              contacts: convertToMap(contacts, 'id')
            });
          }),
          catchError((error: HttpErrorResponse) =>
            of(
              new LoadCompanyContactsFailure({
                companyId: action.payload,
                error
              })
            )
          )
        );
      })
    )
  );

  loadCompanyFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompaniesActionTypes.LoadCompanyFiles),
      mergeMap((action: LoadCompanyFiles) => {
        return this.filesService.getFiles(action.payload).pipe(
          map((files: FileMetaData[]) => {
            return new LoadCompanyFilesSuccess({
              agencyId: action.payload,
              files: convertToMap(files, 'id')
            });
          }),
          catchError((error: HttpErrorResponse) =>
            of(
              new LoadCompanyFilesFailure({
                agencyId: action.payload,
                error
              })
            )
          )
        );
      })
    )
  );

  createCompanyWithContacts$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CompaniesActionTypes.CreateCompanyWithContacts),
      map((action: CreateCompanyWithContacts) => action.payload),
      withLatestFrom(this.store$.select('companies', 'newCompanyContacts')),
      mergeMap(
        ([payload, contacts]: [{ company: Company; redirect: boolean }, Contact[]]) =>
          this.companiesService.createCompany(payload.company).pipe(
            switchMap((newCompany: Company) => {
              const actions = [
                ...contacts.map(
                  (contact) => new SaveContact({ contact, companyId: newCompany.id })
                ),
                new CreateCompanySuccess(newCompany),
                new ClearNewCompanyContacts(),
                new CloseDrawer()
              ];
              if (payload.redirect === true) {
                actions.push(
                  // @ts-ignore
                  new OpenDrawer({
                    component: CallNoteFormAudioComponent,
                    data: { redirect: true, company: newCompany.id }
                  })
                );
              } else {
                // @ts-ignore
                actions.push(new NavigateToNewCompany(newCompany.id));
              }
              return actions;
            }),
            catchError((error: HttpErrorResponse) => of(new CreateCompanyFailure(error)))
          )
      )
    )
  );

  navigateToNewCompany$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CompaniesActionTypes.NavigateToNewCompany),
        map((action: NavigateToNewCompany) => action.payload),
        tap((compId: number) => {
          const url = this.router.serializeUrl(
            this.router.createUrlTree(['/companies/company/', compId])
          );
          window.open(url, '_blank');
        })
      ),
    { dispatch: false }
  );

  createCompany$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CompaniesActionTypes.CreateCompany),
      map((action: CreateCompany) => action.payload),
      mergeMap((company: Company) =>
        this.companiesService.createCompany(company).pipe(
          map((newCompany: Company) => new CreateCompanySuccess(newCompany)),
          catchError((error: HttpErrorResponse) => of(new CreateCompanyFailure(error)))
        )
      )
    )
  );

  updateCompany$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CompaniesActionTypes.UpdateCompany),
      map((action: UpdateCompany) => action.payload),
      mergeMap((company: Company) =>
        this.companiesService.updateCompany(company).pipe(
          switchMap((updatedCompany: Company) => [
            new UpdateCompanySuccess(updatedCompany),
            new LoadCompany(updatedCompany.id)
          ]),
          catchError((error: HttpErrorResponse) => of(new UpdateCompanyFailure(error)))
        )
      )
    )
  );

  deleteCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompaniesActionTypes.DeleteCompany),
      map((action: DeleteCompany) => action.payload),
      mergeMap((companyId: number) =>
        this.companiesService.deleteCompany(companyId).pipe(
          map(() => new DeleteCompanySuccess(companyId)),
          catchError((error) => of(new DeleteCompanyFailure(error)))
        )
      )
    )
  );

  deleteContact$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CompaniesActionTypes.DeleteContact),
      concatMap((action: DeleteContact) => {
        return this.contactsService.deleteContact(action.payload).pipe(
          switchMap((contact: Contact) => [
            new DeleteContactSuccess(contact),
            new LoadCompany(contact.account)
          ]),
          catchError((error: HttpErrorResponse) => of(new DeleteContactFailure(error)))
        );
      })
    )
  );

  saveContact$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CompaniesActionTypes.SaveContact),
      map((action: SaveContact) => ({
        contact: action.payload.contact,
        companyId: action.payload.companyId
      })),
      concatMap(({ contact, companyId }) => {
        if (!!contact.id) {
          if (contact.account === companyId) {
            return this.contactsService.updateContact(contact).pipe(
              switchMap((resContact: Contact) => [
                new SaveContactSuccess(resContact),
                new LoadCompany(companyId)
              ]),
              catchError((error: HttpErrorResponse) => of(new SaveContactFailure(error)))
            );
          } else {
            contact.account = companyId;
            return this.contactsService.createContact(contact, companyId).pipe(
              switchMap((resContact: Contact) => [
                new SaveContactSuccess(resContact),
                new LoadCompany(companyId)
              ]),
              catchError((error: HttpErrorResponse) => of(new SaveContactFailure(error)))
            );
          }
        } else {
          return this.contactsService.createContact(contact, companyId).pipe(
            switchMap((resContact: Contact) => [
              new SaveContactSuccess(resContact),
              new LoadCompany(companyId)
            ]),
            catchError((error: HttpErrorResponse) => of(new SaveContactFailure(error)))
          );
        }
      })
    )
  );

  uploadCompanyFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompaniesActionTypes.UploadCompanyFile),
      tap(() => this.store$.dispatch(new SetLoadingBarVisibility(true))),
      withLatestFrom(this.store$.select(selectCurrentCompany)),
      map((actionAndStore) => ({
        file: actionAndStore[0].payload,
        company: actionAndStore[1]
      })),
      switchMap(({ file, company }) => {
        return this.filesService.uploadFile(file).pipe(
          switchMap((res: FileMetaData) => [
            new SetLoadingBarVisibility(false),
            new UploadCompanyFileSuccess(res),
            new OpenSnackbar({
              duration: 5000,
              message: 'Uploaded file successfully',
              opened: true
            })
          ]),
          catchError((error: HttpErrorResponse) =>
            of(new UploadCompanyFileFailure(error))
          )
        );
      })
    )
  );

  uploadCompanyFileByCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompaniesActionTypes.UploadCompanyFileByCompany),
      tap(() => this.store$.dispatch(new SetLoadingBarVisibility(true))),
      withLatestFrom(this.store$.select(selectCurrentCompany)),
      map((actionAndStore) => ({
        file: actionAndStore[0].payload,
        company: actionAndStore[1]
      })),
      switchMap(({ file, company }) => {
        return this.filesService.uploadFileByCompany(file, company.id).pipe(
          switchMap((res: FileMetaData) => {
            return [
              new SetLoadingBarVisibility(false),
              new UploadCompanyFileSuccess(res),
              new OpenSnackbar({
                duration: 5000,
                message: 'Uploaded file successfully',
                opened: true
              })
            ];
          }),
          catchError((error: HttpErrorResponse) =>
            of(new UploadCompanyFileFailure(error))
          )
        );
      })
    )
  );

  uploadCompanyFileFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompaniesActionTypes.UploadCompanyFileFailure),
      map((action) => action.payload),
      switchMap((error: HttpErrorResponse) => {
        return [
          new SetLoadingBarVisibility(false),
          new OpenErrorSnackbar({
            duration: 10000,
            message: error.status === 413 ? 'Unsupported file type' : error.message,
            opened: true
          })
        ];
      })
    )
  );

  deleteCompanyFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompaniesActionTypes.DeleteCompanyFile),
      withLatestFrom(this.store$.select(selectCurrentCompany)),
      map((actionAndStore) => ({
        fileId: actionAndStore[0].payload,
        company: actionAndStore[1]
      })),
      concatMap(({ fileId, company }) => {
        return this.filesService.deleteFile(fileId).pipe(
          switchMap((res: FileMetaData) => [new DeleteCompanyFileSuccess(res)]),
          catchError((error) => of(new DeleteCompanyFileFailure(error)))
        );
      })
    )
  );

  loadDmsLinkedCompany$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CompaniesActionTypes.LoadDmsLinkedCompany),
      map((action: LoadDmsLinkedCompany) => action.payload),
      mergeMap((action: { dmsId: string; date: string }) =>
        this.companiesService.getDmsLinkedCompany(action.dmsId, action.date).pipe(
          map((data: DMS) => new LoadDmsLinkedCompanySuccess(data)),
          catchError((error: HttpErrorResponse) =>
            of(new LoadDmsLinkedCompanyFailure(error))
          )
        )
      )
    )
  );

  loadDmsLinkedCompanies$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CompaniesActionTypes.LoadDmsLinkedCompanies),
      mergeMap(() => {
        return this.companiesService.getDmsLinked().pipe(
          map((DMSLinked: DMS[]) => {
            return new LoadDmsLinkedCompaniesSuccess(DMSLinked);
          }),
          catchError((error: HttpErrorResponse) =>
            of(new LoadDmsLinkedCompaniesFailure(error))
          )
        );
      })
    )
  );

  loadDmsExactMatches$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CompaniesActionTypes.LoadDmsExactMatches),
      mergeMap(() => {
        return this.companiesService.getDmsExactMatches().pipe(
          map((DMSLinked: DMSExactMatch[]) => {
            return new LoadDmsExactMatchesSuccess(DMSLinked);
          }),
          catchError((error: HttpErrorResponse) =>
            of(new LoadDmsExactMatchesFailure(error))
          )
        );
      })
    )
  );

  loadDmsUnlinkedCompanies$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CompaniesActionTypes.LoadDmsUnlinkedCompanies),
      mergeMap(() => {
        return this.companiesService.getDmsUnlinked().pipe(
          map((DMSUnlinked: DMS[]) => {
            return new LoadDmsUnlinkedCompaniesSuccess(DMSUnlinked);
          }),
          catchError((error: HttpErrorResponse) =>
            of(new LoadDmsUnlinkedCompaniesFailure(error))
          )
        );
      })
    )
  );

  linkDmsCompany$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CompaniesActionTypes.LinkDmsCompany),
      map((action: LinkDmsCompany) => action.payload),
      mergeMap((action: { dmsLinkRequest: DmsLinkRequest; companyUpdate: Company }) =>
        this.companiesService.linkDmsCompany(action.dmsLinkRequest).pipe(
          switchMap((newRequest: DmsLinkRequest) => [
            new LinkDmsCompanySuccess(newRequest),
            new UpdateCompany(action.companyUpdate),
            new LoadDmsUnlinkedCompanies(),
            new LoadDmsLinkedCompanies(),
            new OpenSnackbar({
              duration: 5000,
              message: 'Companies successfully linked',
              opened: true
            })
          ]),
          catchError((error: HttpErrorResponse) => of(new LinkDmsCompanyFailure(error)))
        )
      )
    )
  );

  unlinkDmsCompany$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CompaniesActionTypes.UnlinkDmsCompany),
      map((action: UnlinkDmsCompany) => action.payload),
      mergeMap((action: { dmsLinkRequest: DmsLinkRequest; companyUpdate: Company }) =>
        this.companiesService.unlinkDmsCompany(action.dmsLinkRequest).pipe(
          switchMap((newRequest: DmsLinkRequest) => [
            new UnlinkDmsCompanySuccess(newRequest),
            new UpdateCompany(action.companyUpdate),
            new LoadDmsUnlinkedCompanies(),
            new LoadDmsLinkedCompanies(),
            new OpenSnackbar({
              duration: 5000,
              message: 'Companies successfully unlinked',
              opened: true
            })
          ]),
          catchError((error: HttpErrorResponse) => of(new UnlinkDmsCompanyFailure(error)))
        )
      )
    )
  );

  bulkLinkDmsCompanies$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CompaniesActionTypes.BulkLinkDmsCompanies),
      map((action: BulkLinkDmsCompanies) => action.payload),
      mergeMap((dmsLinkRequests: DmsLinkRequest[]) =>
        this.companiesService.bulkLinkDmsCompany(dmsLinkRequests).pipe(
          switchMap((newRequests: DmsLinkRequest[]) => [
            new BulkLinkDmsCompaniesSuccess(newRequests),
            new LoadDmsUnlinkedCompanies(),
            new LoadDmsLinkedCompanies(),
            new OpenSnackbar({
              duration: 5000,
              message: 'Companies successfully linked',
              opened: true
            })
          ]),
          catchError((error: HttpErrorResponse) =>
            of(new BulkLinkDmsCompaniesFailure(error))
          )
        )
      )
    )
  );

  saveChildCompanies$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CompaniesActionTypes.SaveChildCompanies),
      map((action: SaveChildCompanies) => ({ ...action.payload })),
      mergeMap(({ companyId, childCompanies }) =>
        this.companiesService.saveChildCompanies(companyId, childCompanies).pipe(
          map((excedes: DMS[]) => {
            return new SaveChildCompaniesSuccess(convertToMap(excedes, 'id'));
          }),
          catchError((error: HttpErrorResponse) =>
            of(
              new SaveChildCompaniesFailure({
                companyId,
                error
              })
            )
          )
        )
      )
    )
  );

  constructor(
    private companiesService: CompaniesService,
    private actions$: Actions<CompaniesActions>,
    private contactsService: ContactsService,
    private filesService: FilesService,
    private store$: Store<RootState>,
    private router: Router
  ) {}
}
