import { UserService } from './../../services/user.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { UserV2 } from '../../models/admin/users/user-V2';
import { CallNote } from '../../models/call-notes/call-note';
import { Contact } from '../../models/contacts/contact';
import { File } from '../../models/files/file';
import { FileService } from '../../services/file.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'file-upload-popup',
  templateUrl: './file-upload-popup.component.html',
  styleUrls: ['../../../shared/styles/popups.scss']
})
export class FileUploadComponent implements OnInit {
  @Input() fileUploadPopup: boolean;
  @Input() file: File;
  @Input() fileCategories: any[];
  @Input() companyFiles: File[];
  @Input() companyId: number;
  @Input() contactId: number;
  @Input() callNoteId: number;
  @Input() expenseId: number;
  @Input() users: UserV2[];
  @Output() fileUploadPopupChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() companyFilesChange: EventEmitter<File[]> = new EventEmitter<File[]>();
  fileUploaded: any;
  uploadingFilePopup: boolean;
  window: any = window;
  selectedCallNote: CallNote;
  selectedContact: Contact;
  url: any;
  notSupportedType: boolean;

  constructor(
    private _notificationService: NotificationService,
    private _fileService: FileService,
    private _userService: UserService
  ) {}

  ngOnInit() {
    if (!this.file) {
      this.file = Object.assign({ CategoryId: 0 });
      this.file = Object.assign({ companyId: this.companyId });
    } else {
      this.file.CategoryId = 0;
    }
    this.fileUploadPopup = true;
    if (!this.fileCategories) {
      this._fileService.getFileCategories().then(result => {
        this.fileCategories = result;
        this.fileCategories.unshift({ Id: 0, IsDeleted: false, Name: 'Miscellaneous' });
      });
    }
    if (!this.users) {
      this._userService.getActive().then(users => {
        this.users = users;
      });
    }
    if (!this.selectedCallNote) {
      this.selectedCallNote = Object.assign({});
    }
    if (!this.selectedContact) {
      this.selectedContact = Object.assign({});
    }
  }

  uploadFile(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        this.url = (<FileReader>event.target).result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
    this.fileUploaded = event.target.files[0];
    switch (event.target.files[0].type) {
      case 'image/png':
        this.notSupportedType = false;
        break;
      case 'image/jpeg':
        this.notSupportedType = false;
        break;
      case 'image/jpg':
        this.notSupportedType = false;
        break;
      case 'image/gif':
        this.notSupportedType = false;
        break;
      case 'image/tiff':
        this.notSupportedType = false;
        break;
      case 'image/svg+xml':
        this.notSupportedType = false;
        break;
      case 'application/pdf':
        this.notSupportedType = false;
        break;
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        this.notSupportedType = false;
        break;
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        this.notSupportedType = false;
        break;
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        this.notSupportedType = false;
        break;
      case 'application/msword':
        this.notSupportedType = false;
        break;
      case 'application/vnd.ms-excel':
        this.notSupportedType = false;
        break;
      case 'application/vnd.ms-powerpoint':
        this.notSupportedType = false;
        break;
      case 'text/csv':
        this.notSupportedType = false;
        break;
      case 'text/plain':
        this.notSupportedType = false;
        break;
      case 'text/rtf':
        this.notSupportedType = false;
        break;
      default:
        this.notSupportedType = true;
    }
  }

  finishingUpload(
    file: any,
    companyId: number,
    callNoteId: number,
    contactId: number,
    categoryId: number
  ): void {
    const filesForCompany: string[] = [];
    this.companyFiles.forEach((companyFile: File) =>
      filesForCompany.push(companyFile.FileName)
    );
    if (!this.file.CategoryId) {
      this.file.CategoryId = 0;
    }
    if (filesForCompany.includes(file.name)) {
      let duplicate: boolean = false;
      this.companyFiles.forEach((companyFile: File) => {
        if (
          companyFile.CategoryId == this.file.CategoryId &&
          companyFile.FileName == file.name
        ) {
          duplicate = true;
        }
      });
      if (duplicate) {
        this._notificationService.showError(
          'Cannot have files with the same name within the same category'
        );
        return;
      }
    }
    if (this.fileUploaded == null) {
      this._notificationService.showError('Must select a file to upload');
    } else {
      this.uploadingFilePopup = true;
      if (!callNoteId) {
        callNoteId = 0;
      }
      if (!contactId) {
        contactId = 0;
      }
      if (!categoryId) {
        categoryId = 0;
      }
      this._fileService
        .uploadFile(file, this.companyId, this.file.CategoryId)
        .subscribe(result => {
          file = result;
          if (result) {
            if (this.users && this.users.length > 0) {
              for (let i = 0; i < this.users.length; i++) {
                if (this.users[i].id == file.UploadedBy) {
                  file.UploadedUser =
                    this.users[i].firstName + ' ' + this.users[i].lastName;
                  break;
                }
              }
            }
            if (this.fileCategories && this.fileCategories.length > 0) {
              for (let i = 0; i < this.fileCategories.length; i++) {
                if (file.CategoryId == this.fileCategories[i].Id) {
                  file.CategoryName = this.fileCategories[i].Name;
                  break;
                } else {
                  file.CategoryName = 'Miscellaneous';
                }
              }
            }
            file.UploadedDate = new Date(Date.now());
            file.FileName = file.fileName;
            file.FileType = file.fileType;
            file.FileSize = file.fileSize;
            this.companyFiles.push(file);
            callNoteId = 0;
            contactId = 0;
            categoryId = 0;
            this.uploadingFilePopup = false;
            this.fileUploadPopup = false;
            this._notificationService.showSuccess('File uploaded successfully');
            this.emitVariables();
          } else {
            this.uploadingFilePopup = false;
            this.fileUploadPopup = false;
            this._notificationService.showError('An error occurred, please try again');
            this.emitVariables();
          }
        });
    }
  }

  cancelFileUpload() {
    this.fileUploadPopup = false;
    this.emitVariables();
  }

  emitVariables() {
    this.fileUploadPopupChange.emit(this.fileUploadPopup);
    this.companyFilesChange.emit(this.companyFiles);
  }
}
