import { Injectable } from '@angular/core';
import { ServicesModule } from '../services.module';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: ServicesModule
})
export class CompanyMergeService {
  private jpiUrl = environment.jpiUrl;

  constructor(private http: HttpClient) {}

  mergeCompaniesV2(params) {
    return this.http.get(`${this.jpiUrl}/companies/merge`, { params });
  }
}
