import { Action } from '@ngrx/store';
import { CallType } from '../../../models/admin/call-type/call-type';
import { HttpErrorResponse } from '@angular/common/http';

export enum CallTypesActionTypes {
  SetCurrentCallType = '[CallTypes] Set Current CallType',
  ClearCurrentCallType = '[CallTypes] Clear Current CallType',
  LoadCallTypes = '[CallTypes] Load CallTypes',
  LoadCallTypesSuccess = '[CallTypes] Load CallTypes Success',
  LoadCallTypesFailure = '[CallTypes] Load CallTypes Failure',
  CreateCallTypes = '[CallTypes] Create CallTypes',
  CreateCallTypesSuccess = '[CallTypes] Create CallTypes Success',
  CreateCallTypesFailure = '[CallTypes] Create CallTypes Failure',
  UpdateCallTypes = '[CallTypes] Update CallTypes',
  UpdateCallTypesSuccess = '[CallTypes] Update CallTypes Success',
  UpdateCallTypesFailure = '[CallTypes] Update CallTypes Failure',
  DeleteCallTypes = '[CallTypes] Delete CallTypes',
  DeleteCallTypesSuccess = '[CallTypes] Delete CallTypes Success',
  DeleteCallTypesFailure = '[CallTypes] Delete CallTypes Failure'
}

export class SetCurrentCallType implements Action {
  readonly type = CallTypesActionTypes.SetCurrentCallType;
  constructor(public payload: CallType) {}
}

export class ClearCurrentCallType implements Action {
  readonly type = CallTypesActionTypes.ClearCurrentCallType;
}

export class LoadCallTypes implements Action {
  readonly type = CallTypesActionTypes.LoadCallTypes;
}

export class LoadCallTypesSuccess implements Action {
  readonly type = CallTypesActionTypes.LoadCallTypesSuccess;
  constructor(public payload: CallType[]) {}
}

export class LoadCallTypesFailure implements Action {
  readonly type = CallTypesActionTypes.LoadCallTypesFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class CreateCallTypes implements Action {
  readonly type = CallTypesActionTypes.CreateCallTypes;
  constructor(public payload: CallType) {}
}

export class CreateCallTypesSuccess implements Action {
  readonly type = CallTypesActionTypes.CreateCallTypesSuccess;
  constructor(public payload: CallType) {}
}

export class CreateCallTypesFailure implements Action {
  readonly type = CallTypesActionTypes.CreateCallTypesFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class UpdateCallTypes implements Action {
  readonly type = CallTypesActionTypes.UpdateCallTypes;
  constructor(public payload: CallType) {}
}

export class UpdateCallTypesSuccess implements Action {
  readonly type = CallTypesActionTypes.UpdateCallTypesSuccess;
  constructor(public payload: CallType) {}
}

export class UpdateCallTypesFailure implements Action {
  readonly type = CallTypesActionTypes.UpdateCallTypesFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class DeleteCallTypes implements Action {
  readonly type = CallTypesActionTypes.DeleteCallTypes;
  constructor(public payload: number) {}
}

export class DeleteCallTypesSuccess implements Action {
  readonly type = CallTypesActionTypes.DeleteCallTypesSuccess;
  constructor(public payload: number) {}
}

export class DeleteCallTypesFailure implements Action {
  readonly type = CallTypesActionTypes.DeleteCallTypesFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export type CallTypesActions =
  | SetCurrentCallType
  | ClearCurrentCallType
  | LoadCallTypes
  | LoadCallTypesSuccess
  | LoadCallTypesFailure
  | CreateCallTypes
  | CreateCallTypesSuccess
  | CreateCallTypesFailure
  | UpdateCallTypes
  | UpdateCallTypesSuccess
  | UpdateCallTypesFailure
  | DeleteCallTypes
  | DeleteCallTypesSuccess
  | DeleteCallTypesFailure;
