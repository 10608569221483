import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { AuthenticationService } from '../authentication.service';
import { TerritoryV2 } from './../../models/admin/territories/territory-V2';
import { Territory } from 'src/app/models/admin/territories/territory';

@Injectable()
export class TerritoryService {
  allTerritories: TerritoryV2[];
  private territoryChangedAnnouncer = new Subject<boolean>();
  public territoryChangedAnnounced = this.territoryChangedAnnouncer.asObservable();

  constructor(private authService: AuthenticationService) {
    // super('/territories', authService);
  }

  territoryChangedInPopup(bool: boolean) {
    this.territoryChangedAnnouncer.next(null);
    this.territoryChangedAnnouncer.next(bool);
  }

  getAll(): Promise<TerritoryV2[]> {
    return this.authService
      .makeJpiRequest('/territories', null, null, 'get')
      .then((territories) => {
        let allTerritories = JSON.parse(territories);
        allTerritories = allTerritories.filter((terr) => !terr.deleted);
        return JSON.parse(territories);
      });
  }

  getStores(): Promise<Territory[]> {
    return this.authService
      .makeJpiRequest('/territories/stores', null, null, 'get')
      .then((territories) => {
        let allTerritories = JSON.parse(territories);
        allTerritories = allTerritories.filter((terr) => !terr.deleted);
        return allTerritories;
      });
  }

  getById(id): Promise<TerritoryV2> {
    return this.authService
      .makeJpiRequest('/territories/' + id, null, null, 'get')
      .then((territory) => {
        return JSON.parse(territory);
      });
  }

  create(territory: any): Promise<TerritoryV2> {
    return this.authService
      .makeJpiRequest('/territories', null, territory, 'post')
      .then((response) => {
        return JSON.parse(response);
      });
  }

  update(territory: TerritoryV2): Promise<TerritoryV2> {
    return this.authService
      .makeJpiRequest('/territories/' + territory.id, null, territory, 'put')
      .then((response) => {
        return JSON.parse(response);
      });
  }

  delete(territory: TerritoryV2): Promise<TerritoryV2> {
    return this.authService
      .makeJpiRequest('/territories/' + territory.id, null, territory, 'delete')
      .then((response) => {
        return JSON.parse(response);
      });
  }

  getAllFlattened(): Promise<any> {
    return this.authService
      .makeJpiRequest('/territories', null, null, 'get')
      .then((territories) => {
        this.allTerritories = [];
        territories = JSON.parse(territories);
        for (const territory of territories) {
          this.loopThroughTerritories(territory);
        }
        this.allTerritories.sort((a: TerritoryV2, b: TerritoryV2) =>
          a.name.localeCompare(b.name)
        );
        this.allTerritories = this.allTerritories.filter(
          (territory) => !territory.deleted
        );
        return this.allTerritories;
      });
  }

  getAllForClientFlattened(agencyId: number): Promise<any> {
    return this.authService
      .makeJpiRequest(`/territories?agencyId=${agencyId}`, null, null, 'get')
      .then((territories) => {
        this.allTerritories = [];
        territories = JSON.parse(territories);
        for (const territory of territories) {
          this.loopThroughTerritories(territory);
        }
        this.allTerritories.sort((a: TerritoryV2, b: TerritoryV2) =>
          a.name.localeCompare(b.name)
        );
        return this.allTerritories;
      });
  }

  loopThroughTerritories(territory: TerritoryV2): any {
    if (territory.subTerritories && territory.subTerritories.length > 0) {
      this.allTerritories.push(territory);
      for (const sub of territory.subTerritories) {
        this.loopThroughTerritories(sub);
      }
    } else {
      this.allTerritories.push(territory);
    }
  }
}
