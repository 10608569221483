import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CompanySelectItem } from 'src/app/models/companies/company-select';
import { Observable, combineLatest, EMPTY } from 'rxjs';
import {
  map,
  startWith,
  mergeMap,
  debounceTime,
  distinctUntilChanged,
  tap
} from 'rxjs/operators';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { HttpErrorResponse } from '@angular/common/http';
import { formatAddressIntoOneLine } from 'src/app/utils/formatAddressIntoOneLine';
import { Address } from 'src/app/models/address';

export interface CompanyMultiSelectItem extends CompanySelectItem {
  selected: boolean;
}

@Component({
  selector: 'tn-multi-select-companies',
  templateUrl: './multi-select-companies.component.html',
  styleUrls: ['./multi-select-companies.component.scss']
})
export class MultiSelectCompaniesComponent implements OnInit {
  @Input() $selectedCompanies: Observable<CompanySelectItem[]> = EMPTY;
  @Input() $companySearchResults: Observable<CompanySelectItem[]> = EMPTY;
  @Output() companySearchEvent = new EventEmitter<string>();
  @Output() companyCheckboxEvent = new EventEmitter<{
    companyId: number;
    isAssociated: boolean;
  }>();
  @Input() $pending: Observable<boolean>;
  @Input() $error: Observable<HttpErrorResponse>;
  companyFilterCtrl = new FormControl('');
  companySearchCtrl = new FormControl('');
  $filteredCompanies: Observable<CompanySelectItem[]>;
  $selectableCompanies: Observable<CompanyMultiSelectItem[]>;

  filteredColumns: string[] = ['name', 'address', 'remove'];
  searchedColumns: string[] = ['name', 'address', 'toggleRelation'];

  ngOnInit() {
    this.$filteredCompanies = this.companyFilterCtrl.valueChanges.pipe(
      startWith(''),
      mergeMap((companyFilterVal: string) => {
        return this.$selectedCompanies.pipe(
          map((companies: CompanySelectItem[]) => {
            return companies.filter(
              item =>
                item.name.toLowerCase().indexOf(companyFilterVal.toLowerCase()) >= 0 ||
                (item.address as string)
                  .toLowerCase()
                  .indexOf(companyFilterVal.toLowerCase()) >= 0
            );
          })
        );
      })
    );

    this.$selectableCompanies = this.companySearchCtrl.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap((companySearchVal: string) => this.companySearchEvent.emit(companySearchVal)),
      mergeMap(() => {
        return combineLatest([this.$companySearchResults, this.$selectedCompanies]).pipe(
          map(resultArray => {
            return { searchedComps: resultArray[0], selectedComps: resultArray[1] };
          }),
          map(({ searchedComps, selectedComps }) => {
            return searchedComps.map((searchedComp: CompanySelectItem) => {
              return {
                ...searchedComp,
                address: formatAddressIntoOneLine(searchedComp.address as Address),
                selected: selectedComps.map(comp => comp.id).indexOf(searchedComp.id) >= 0
              };
            });
          })
        );
      })
    );
  }

  onCheckboxChanged(companyId: number, value: MatCheckboxChange) {
    this.companyCheckboxEvent.emit({ companyId, isAssociated: value.checked });
  }

  onRemoveCompany(companyId: number) {
    this.companyCheckboxEvent.emit({ companyId, isAssociated: false });
  }
}
