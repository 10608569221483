import { Action } from '@ngrx/store';
import { LabelGroup } from 'src/app/models/label-group';
import { HttpErrorResponse } from '@angular/common/http';
import { Label } from 'src/app/models/label';

export enum OpportunityLabelsActionTypes {
  SelectOpportunityLabelId = '[OpportunityLabels] Select Opportunity Label Id',
  ClearOpportunityLabelId = '[OpportunityLabels] Clear Opportunity Label Id',
  LoadOpportunityLabels = '[OpportunityLabels] Load OpportunityLabels',
  LoadOpportunityLabelsSuccess = '[OpportunityLabels] Load Opportunity Labels Success',
  LoadOpportunityLabelsFailure = '[OpportunityLabels] Load Opportunity Labels Failure',
  AddOpportunityLabels = '[OpportunityLabels] Add Opportunity Labels',
  AddOpportunityLabelsSuccess = '[OpportunityLabels] Add Opportunity Labels Success',
  AddOpportunityLabelsFailure = '[OpportunityLabels] Add Opportunity Labels Failure',
  UpdateOpportunityLabels = '[OpportunityLabels] Update Opportunity Labels',
  UpdateOpportunityLabelsSuccess = '[OpportunityLabels] Update Opportunity Labels Success',
  UpdateOpportunityLabelsFailure = '[OpportunityLabels] Update Opportunity Labels Failure',
  DeleteOpportunityLabels = '[OpportunityLabels] Delete Opportunity Labels',
  DeleteOpportunityLabelsSuccess = '[OpportunityLabels] Delete Opportunity Labels Success',
  DeleteOpportunityLabelsFailure = '[OpportunityLabels] Delete Opportunity Labels Failure',
  BulkUpdateOpportunityLabelGroups = '[OpportunityLabels] Bulk Update Opportunity Label Groups',
  BulkUpdateOpportunityLabelGroupsSuccess = '[OpportunityLabels] Bulk Update Opportunity Label Groups Success',
  BulkUpdateOpportunityLabelGroupsFailure = '[OpportunityLabels] Bulk Update Opportunity Label Groups Failure',
  SetOppLabelGroupListOrder = '[OpportunityLabels] Set Opp Label Group List Order',
  SetOppLabelGroupListOrderSuccess = '[OpportunityLabels] Set Opp Label Group List Order Success',
  SetOppLabelGroupListOrderFailure = '[OpportunityLabels] Set Opp Label Group List Order Failure'
}

export class SelectOpportunityLabelId implements Action {
  readonly type = OpportunityLabelsActionTypes.SelectOpportunityLabelId;
  constructor(public payload: number) {}
}

export class ClearOpportunityLabelId implements Action {
  readonly type = OpportunityLabelsActionTypes.ClearOpportunityLabelId;
}

export class LoadOpportunityLabels implements Action {
  readonly type = OpportunityLabelsActionTypes.LoadOpportunityLabels;
}

export class LoadOpportunityLabelsSuccess implements Action {
  readonly type = OpportunityLabelsActionTypes.LoadOpportunityLabelsSuccess;
  constructor(public payload: LabelGroup[]) {}
}

export class LoadOpportunityLabelsFailure implements Action {
  readonly type = OpportunityLabelsActionTypes.LoadOpportunityLabelsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class AddOpportunityLabels implements Action {
  readonly type = OpportunityLabelsActionTypes.AddOpportunityLabels;
  constructor(public payload: LabelGroup) {}
}

export class AddOpportunityLabelsSuccess implements Action {
  readonly type = OpportunityLabelsActionTypes.AddOpportunityLabelsSuccess;
  constructor(public payload: LabelGroup) {}
}

export class AddOpportunityLabelsFailure implements Action {
  readonly type = OpportunityLabelsActionTypes.AddOpportunityLabelsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class UpdateOpportunityLabels implements Action {
  readonly type = OpportunityLabelsActionTypes.UpdateOpportunityLabels;
  constructor(public payload: LabelGroup) {}
}

export class UpdateOpportunityLabelsSuccess implements Action {
  readonly type = OpportunityLabelsActionTypes.UpdateOpportunityLabelsSuccess;
  constructor(public payload: LabelGroup) {}
}

export class UpdateOpportunityLabelsFailure implements Action {
  readonly type = OpportunityLabelsActionTypes.UpdateOpportunityLabelsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class DeleteOpportunityLabels implements Action {
  readonly type = OpportunityLabelsActionTypes.DeleteOpportunityLabels;
  constructor(public payload: number) {}
}

export class DeleteOpportunityLabelsSuccess implements Action {
  readonly type = OpportunityLabelsActionTypes.DeleteOpportunityLabelsSuccess;
  constructor(public payload: number) {}
}

export class DeleteOpportunityLabelsFailure implements Action {
  readonly type = OpportunityLabelsActionTypes.DeleteOpportunityLabelsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class BulkUpdateOpportunityLabelGroups implements Action {
  readonly type = OpportunityLabelsActionTypes.BulkUpdateOpportunityLabelGroups;
  constructor(public payload: LabelGroup[]) {}
}

export class BulkUpdateOpportunityLabelGroupsSuccess implements Action {
  readonly type = OpportunityLabelsActionTypes.BulkUpdateOpportunityLabelGroupsSuccess;
  constructor(public payload: LabelGroup[]) {}
}

export class BulkUpdateOpportunityLabelGroupsFailure implements Action {
  readonly type = OpportunityLabelsActionTypes.BulkUpdateOpportunityLabelGroupsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class SetOppLabelGroupListOrder implements Action {
  readonly type = OpportunityLabelsActionTypes.SetOppLabelGroupListOrder;
  constructor(public payload: LabelGroup[]) {}
}

export class SetOppLabelGroupListOrderSuccess implements Action {
  readonly type = OpportunityLabelsActionTypes.SetOppLabelGroupListOrderSuccess;
  constructor(public payload: LabelGroup[]) {}
}

export class SetOppLabelGroupListOrderFailure implements Action {
  readonly type = OpportunityLabelsActionTypes.SetOppLabelGroupListOrderFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export type OpportunityLabelsActions =
  | SelectOpportunityLabelId
  | ClearOpportunityLabelId
  | LoadOpportunityLabels
  | LoadOpportunityLabelsSuccess
  | LoadOpportunityLabelsFailure
  | AddOpportunityLabels
  | AddOpportunityLabelsSuccess
  | AddOpportunityLabelsFailure
  | UpdateOpportunityLabels
  | UpdateOpportunityLabelsSuccess
  | UpdateOpportunityLabelsFailure
  | DeleteOpportunityLabels
  | DeleteOpportunityLabelsSuccess
  | DeleteOpportunityLabelsFailure
  | BulkUpdateOpportunityLabelGroups
  | BulkUpdateOpportunityLabelGroupsSuccess
  | BulkUpdateOpportunityLabelGroupsFailure
  | SetOppLabelGroupListOrder
  | SetOppLabelGroupListOrderSuccess
  | SetOppLabelGroupListOrderFailure;
