import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { whatsNewReducer } from './whats-new.reducer';
import { EffectsModule } from '@ngrx/effects';
import { WhatsNewEffects } from './whats-new.effects';

@NgModule({
  imports: [
    StoreModule.forFeature('whatsNew', whatsNewReducer),
    EffectsModule.forFeature([WhatsNewEffects])
  ]
})
export class WhatsNewStateModule {}
