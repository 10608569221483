import { MemoizedSelector, createSelector } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import { FieldType } from 'src/app/models/call-notes/field-type';

export const selectAllFieldTypes = (state: RootState) => state.fieldTypes.data;

export const selectFieldTypesAsArray: MemoizedSelector<
  RootState,
  FieldType[]
> = createSelector(
  selectAllFieldTypes,
  (fieldTypesMap: { [id: number]: FieldType }): FieldType[] => {
    return Object.values(fieldTypesMap);
  }
);
