import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import {
  LoadCompanyLabelsFailure,
  LoadCompanyLabelsSuccess,
  CompanyLabelsActionTypes,
  CompanyLabelsActions,
  CreateCompanyLabels,
  CreateCompanyLabelsSuccess,
  CreateCompanyLabelsFailure,
  UpdateCompanyLabels,
  UpdateCompanyLabelsSuccess,
  UpdateCompanyLabelsFailure,
  DeleteCompanyLabels,
  DeleteCompanyLabelsSuccess,
  DeleteCompanyLabelsFailure,
  SelectCompanyLabelId
} from './company-labels.actions';
import { CompanyLabelsService } from 'src/app/services/system-settings/company-labels/company-labels.service';
import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { convertToMap } from 'src/app/utils/convertToMap';
import { LoadCompanyLabels } from 'src/app/store/system-settings/company-labels/company-labels.actions';
import { LabelGroup } from 'src/app/models/label-group';

@Injectable()
export class CompanyLabelsEffects {
  loadCompanyLabels$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyLabelsActionTypes.LoadCompanyLabels),
      mergeMap(() =>
        this.companyLabelsService.getAll().pipe(
          map((companyLabels: LabelGroup[]) => {
            return new LoadCompanyLabelsSuccess(convertToMap(companyLabels, 'id'));
          }),
          catchError((error: HttpErrorResponse) =>
            of(new LoadCompanyLabelsFailure(error))
          )
        )
      )
    )
  );

  createCompanyLabels$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyLabelsActionTypes.CreateCompanyLabels),
      map((action: CreateCompanyLabels) => action.payload),
      mergeMap((companyLabel: LabelGroup) => {
        return this.companyLabelsService.create(companyLabel).pipe(
          switchMap((newCompanyLabel: LabelGroup) => [
            new CreateCompanyLabelsSuccess(newCompanyLabel),
            new SelectCompanyLabelId(newCompanyLabel.id),
            new LoadCompanyLabels()
          ]),
          catchError((error: HttpErrorResponse) =>
            of(new CreateCompanyLabelsFailure(error))
          )
        );
      })
    )
  );

  updateCompanyLabels$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyLabelsActionTypes.UpdateCompanyLabels),
      map((action: UpdateCompanyLabels) => action.payload),
      mergeMap((companyLabel: LabelGroup) => {
        return this.companyLabelsService.update(companyLabel).pipe(
          switchMap((updatedCompanyLabel: LabelGroup) => [
            new UpdateCompanyLabelsSuccess(updatedCompanyLabel),
            new LoadCompanyLabels()
          ]),
          catchError((error: HttpErrorResponse) =>
            of(new UpdateCompanyLabelsFailure(error))
          )
        );
      })
    )
  );

  deleteCompanyLabels$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyLabelsActionTypes.DeleteCompanyLabels),
      map((action: DeleteCompanyLabels) => action.payload),
      mergeMap((id: number) =>
        this.companyLabelsService.delete(id).pipe(
          switchMap(() => [new DeleteCompanyLabelsSuccess(id), new LoadCompanyLabels()]),
          catchError((error: HttpErrorResponse) =>
            of(new DeleteCompanyLabelsFailure(error))
          )
        )
      )
    )
  );

  constructor(
    private companyLabelsService: CompanyLabelsService,
    private actions$: Actions<CompanyLabelsActions>
  ) {}
}
