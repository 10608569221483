import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import { OpenDrawer } from 'src/app/store/layout/layout.actions';
import { CallNoteFormComponent } from 'src/app/view/shared/call-note-form/call-note-form.component';
import { CompanySelectItem } from 'src/app/models/companies/company-select';
import { selectCompanyInProfile } from 'src/app/store/companies/selectors/company-in-profile.selector';
import { selectCurrentOpportunity } from 'src/app/store/opportunities/selectors/current-opportunity.selector';
import { CompanySlideOutFormComponent } from '../company-slide-out-form/company-slide-out-form.component';
import { ContactSlideOutFormComponent } from '../contact-slide-out-form/contact-slide-out-form.component';
import { combineLatest } from 'rxjs';
import { Opportunity } from 'src/app/models/Opportunity/opportunity';
import { TodoFormComponent } from '../../pages/calendar/todo-form/todo-form.component';
import { PrivateNoteFormComponent } from '../private-note-form/private-note-form.component';
import { selectFeatures } from '../sidenav/sidenav-links.selector';

@Component({
  selector: 'tn-new-menu',
  templateUrl: './new-menu.component.html',
  styleUrls: ['./new-menu.component.scss']
})
export class NewMenuComponent implements OnInit {
  company: CompanySelectItem;
  privateNotes: boolean = false;
  constructor(private store: Store<RootState>) {}

  ngOnInit() {
    this.store.select(selectFeatures).subscribe((features) => {
      if (
        (features != null || undefined) &&
        Object.keys(features).length &&
        features[29]?.Permissions[168]?.Value.toString() === 'true'
      ) {
        this.privateNotes = true;
      }
    });

    combineLatest([
      this.store.select(selectCompanyInProfile),
      this.store.select(selectCurrentOpportunity)
    ]).subscribe(([company, opportunity]: [CompanySelectItem, Opportunity]) => {
      if (!!company) {
        this.company = company;
      }
      if (!!opportunity && opportunity.company) {
        this.company = {
          id: opportunity.company.id,
          name: opportunity.company.name,
          address:
            opportunity.company.shippingAddress || opportunity.company.billingAddress
        };
      }
    });
  }

  openCompanyDrawer(): void {
    this.store.dispatch(
      new OpenDrawer({
        component: CompanySlideOutFormComponent,
        data: { redirect: false }
      })
    );
  }

  openCallNoteDrawer(): void {
    this.store.dispatch(
      new OpenDrawer({
        component: CallNoteFormComponent,
        data: {
          company: this.company
        }
      })
    );
  }

  openContactDrawer(): void {
    this.store.dispatch(
      new OpenDrawer({
        component: ContactSlideOutFormComponent,
        data: {
          company: this.company,
          hasCompanyInput: true
        }
      })
    );
  }

  openTodoDrawer(): void {
    this.store.dispatch(
      new OpenDrawer({
        component: TodoFormComponent
      })
    );
  }

  openPrivateNoteDrawer(): void {
    this.store.dispatch(
      new OpenDrawer({
        component: PrivateNoteFormComponent,
        data: {
          company: this.company
        }
      })
    );
  }
}
