import { Injectable } from '@angular/core';

import { CompanyLabel } from '../../../models/admin/company-labels/company-label';
import { AuthenticationService } from '../../authentication.service';
import { CompanyLabelGroup } from './../../../models/admin/company-labels/company-label-group';

@Injectable()
export class CompanyLabelGroupService {

	constructor(private authService: AuthenticationService) { }

	getCompanyLabelGroups(): Promise<any> {
		return this.authService.makeJpiRequest('/companylabelgroups/', null, null, 'get')
			.then(labelGroups => {
				labelGroups = JSON.parse(labelGroups);
				labelGroups.forEach(labelGroup => {
					if (labelGroup.labels && labelGroup.labels.length > 0) { labelGroup.labels.sort((a: CompanyLabel, b: CompanyLabel) => a.name.localeCompare(b.name)); }
				});
				return labelGroups;
			});
	}

	getCompanyLabelGroupById(groupId: number): Promise<any> {
		return this.authService.makeJpiRequest('/companylabelgroups/' + groupId, null, null, 'get')
			.then(labelGroup => {
				labelGroup = JSON.parse(labelGroup);
				if (labelGroup.labels && labelGroup.labels.length > 0) { labelGroup.labels.sort((a: CompanyLabel, b: CompanyLabel) => a.name.localeCompare(b.name)); }
				return labelGroup;
			});
	}

	addCompanyLabelGroup(group: CompanyLabelGroup): Promise<any> {
		return this.authService.makeJpiRequest('/companylabelgroups/', null, group, 'post')
			.then(labelGroup => {
				labelGroup = JSON.parse(labelGroup);
				if (labelGroup.labels && labelGroup.labels.length > 0) { labelGroup.labels.sort((a: CompanyLabel, b: CompanyLabel) => a.name.localeCompare(b.name)); }
				return labelGroup;
			});
	}

	updateCompanyLabelGroup(group: CompanyLabelGroup): Promise<any> {
		return this.authService.makeJpiRequest('/companylabelgroups/' + group.id, null, group, 'put')
			.then(labelGroup => {
				labelGroup = JSON.parse(labelGroup);
				if (labelGroup.labels && labelGroup.labels.length > 0) { labelGroup.labels.sort((a: CompanyLabel, b: CompanyLabel) => a.name.localeCompare(b.name)); }
				return labelGroup;
			});
	}

	deleteCompanyLabelGroup(group: CompanyLabelGroup): Promise<any> {
		return this.authService.makeJpiRequest('/companylabelgroups/' + group.id, null, group, 'delete')
			.then(labelGroup => {
				labelGroup = JSON.parse(labelGroup);
				return labelGroup.labels.sort((a: CompanyLabel, b: CompanyLabel) => a.name.localeCompare(b.name));
			});
	}
}
