<div
  id="navbar"
  [ngClass]="{
    'navbar-container-expanded': navExpanded,
    'navbar-container': !navExpanded
  }"
  fxLayout="column"
>
  <div class="navbar-logo container" fxLayoutAlign="center center" fxLayout="row">
    <a [routerLink]="['/companies']">
      <img
        *ngIf="!navExpanded"
        src="assets/voze_favicon_32x32.png"
        style="max-width: 30px"
      />
    </a>
    <a [routerLink]="['/companies']">
      <img
        *ngIf="navExpanded"
        src="assets/Telenotes_White_Logo.png"
        style="width: 107px"
      />
    </a>
  </div>
  <ng-container *ngFor="let item of menu">
    <div *ngIf="!navExpanded && item.IsModule">
      <a
        [routerLink]="[item.ModuleUrl]"
        routerLinkActive="active-link"
        fxLayout="row"
        fxLayoutAlign="center center"
        class="menu-item-container"
        [matTooltip]="item.Name"
        matTooltipPosition="right"
      >
        <mat-icon fxLayoutAlign="start center">{{ item.ModuleIcon }}</mat-icon>
      </a>
    </div>
    <div *ngIf="navExpanded && item.IsModule" fxLayout="row">
      <a
        [routerLink]="[item.ModuleUrl]"
        routerLinkActive="active-link-expanded"
        fxLayoutAlign="start center"
        class="menu-item-container"
        fxFlex
      >
        <mat-icon style="padding-left: 15px">{{ item.ModuleIcon }}</mat-icon>
        <span
          style="font-size: 0.5em; padding-left: 10px"
          [innerText]="'&nbsp;' + item.Name"
        ></span>
      </a>
    </div>
  </ng-container>
  <div *ngIf="!navExpanded && hasOpportunities">
    <a
      [routerLink]="['/opportunities']"
      routerLinkActive="active-link"
      fxLayout="row"
      fxLayoutAlign="center center"
      class="menu-item-container"
      [matTooltip]="'Opportunities'"
      matTooltipPosition="right"
    >
      <mat-icon fxLayoutAlign="start center">trending_up</mat-icon>
    </a>
  </div>
  <div *ngIf="navExpanded && hasOpportunities" fxLayout="row">
    <a
      [routerLink]="['/opportunities']"
      routerLinkActive="active-link-expanded"
      fxLayoutAlign="start center"
      class="menu-item-container"
      fxFlex
    >
      <mat-icon style="padding-left: 15px">trending_up</mat-icon>
      <span
        style="font-size: 0.5em; padding-left: 10px"
        [innerText]="'&nbsp;Opportunities'"
      ></span>
    </a>
  </div>
  <div *ngIf="!navExpanded">
    <a
      [routerLink]="['/reports']"
      routerLinkActive="active-link"
      fxLayout="row"
      fxLayoutAlign="center center"
      class="menu-item-container"
      [matTooltip]="'Reports'"
      matTooltipPosition="right"
    >
      <mat-icon fxLayoutAlign="start center">equalizer</mat-icon>
    </a>
  </div>
  <div *ngIf="navExpanded" fxLayout="row">
    <a
      [routerLink]="['/reports']"
      routerLinkActive="active-link-expanded"
      fxLayoutAlign="start center"
      class="menu-item-container"
      fxFlex
    >
      <mat-icon style="padding-left: 15px">equalizer</mat-icon>
      <span
        style="font-size: 0.5em; padding-left: 10px"
        [innerText]="'&nbsp;Reports'"
      ></span>
    </a>
  </div>
  <div *ngIf="!navExpanded">
    <a
      [routerLink]="['/callnotes']"
      routerLinkActive="active-link"
      fxLayout="row"
      fxLayoutAlign="center center"
      class="menu-item-container"
      [matTooltip]="'Call Notes'"
      matTooltipPosition="right"
    >
      <mat-icon fxLayoutAlign="start center">search</mat-icon>
    </a>
  </div>
  <div *ngIf="navExpanded" fxLayout="row">
    <a
      [routerLink]="['/callnotes']"
      routerLinkActive="active-link-expanded"
      fxLayoutAlign="start center"
      class="menu-item-container"
      fxFlex
    >
      <mat-icon style="padding-left: 15px">search</mat-icon>
      <span
        style="font-size: 0.5em; padding-left: 10px"
        [innerText]="'&nbsp;Call Notes'"
      ></span>
    </a>
  </div>
  <div *ngIf="!navExpanded && agencyId == 10">
    <a
      [routerLink]="['/lunch']"
      routerLinkActive="active-link"
      fxLayout="row"
      fxLayoutAlign="center center"
      class="menu-item-container"
      [matTooltip]="'Lunch'"
      matTooltipPosition="right"
    >
      <mat-icon fxLayoutAlign="start center">fastfood</mat-icon>
    </a>
  </div>
  <div *ngIf="navExpanded && agencyId == 10" fxLayout="row">
    <a
      [routerLink]="['/lunch']"
      routerLinkActive="active-link-expanded"
      fxLayoutAlign="start center"
      class="menu-item-container"
      fxFlex
    >
      <mat-icon style="padding-left: 15px">fastfood</mat-icon>
      <span
        style="font-size: 0.5em; padding-left: 10px"
        [innerText]="'&nbsp;Lunch'"
      ></span>
    </a>
  </div>
  <div fxLayout="row">
    <span fxFlex></span>
    <div fxLayoutAlign="end center" (click)="expand()" class="expand-icon">
      <mat-icon
        *ngIf="navExpanded"
        class="icon-medium icon-close"
        fxLayoutAlign="end center"
        >arrow_back_ios</mat-icon
      >
      <mat-icon *ngIf="navExpanded" class="icon-medium" fxLayoutAlign="end center"
        >arrow_back_ios</mat-icon
      >
      <mat-icon
        *ngIf="!navExpanded"
        class="icon-medium icon-close"
        fxLayoutAlign="end center"
        >arrow_forward_ios</mat-icon
      >
      <mat-icon *ngIf="!navExpanded" class="icon-medium" fxLayoutAlign="end center"
        >arrow_forward_ios</mat-icon
      >
    </div>
  </div>
</div>
