import { Injectable } from '@angular/core';

import { CallNoteType } from '../../models/call-notes/call-note-type';
import { AuthenticationService } from '../authentication.service';

@Injectable()
export class CallNoteTypeService {
	constructor(private authService: AuthenticationService) {
		// super('/callnotetypes', authService);
	}

	getAll(): Promise<CallNoteType[]> {
		return this.authService.makeJpiRequest('/callnotetypes', null, null, 'get').then((types) => {
			return JSON.parse(types);
		});
	}

	getById(id): Promise<CallNoteType[]> {
		return this.authService.makeJpiRequest('/callnotetypes/' + id, null, null, 'get').then((type) => {
			return JSON.parse(type);
		});
	}

	create(callNoteType: any): Promise<CallNoteType> {
		return this.authService.makeJpiRequest('/callnotetypes', null, callNoteType, 'post').then((type) => {
			return JSON.parse(type);
		});
	}

	update(callNoteType: CallNoteType): Promise<CallNoteType> {
		return this.authService.makeJpiRequest('/callnotetypes/' + callNoteType.id, null, callNoteType, 'put').then((type) => {
			return JSON.parse(type);
		});
	}

	delete(callNoteType: CallNoteType): Promise<CallNoteType> {
		return this.authService.makeJpiRequest('/callnotetypes/' + callNoteType.id, null, callNoteType, 'delete').then((type) => {
			return JSON.parse(type);
		});
	}

	// getRepCallTypesAssignment(): Promise<CallTypeAssignment[]> {
	//     return this._authService.makeJpiRequest('/api-v1.2.1/calltypes/getRepCallTypesAssignment', '', 'get').then((result: any) => {
	//         return self.myCallTypeAssignments;
	//     });
	// }

	// addRepCallTypeAssignment(cta): Promise<number> {
	//     return this._authService.makeJpiRequest('/api-v1.2.1/calltypes/addRepCallTypeAssignment', cta, 'post').then((result: any) => {
	//         return result[0];
	//     });
	// }

	// remRepCallTypeAssignment(cta): Promise<boolean> {
	//     return this._authService.makeJpiRequest('/api-v1.2.1/calltypes/remRepCallTypeAssignment', cta, 'post').then((result: any) => {
	//         return result[0];
	//     });
	// }
}
