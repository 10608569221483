import { Action } from '@ngrx/store';
import { AgmRectangle } from '@agm/core';
import { HttpErrorResponse } from '@angular/common/http';
import { GlobalFilter } from 'src/app/models/global-filter';
import { Label } from 'src/app/models/label';
export enum MapsActionTypes {
  SetBounds = '[Maps] Set Bounds',
  LoadCompanyMarkers = '[Maps] Load Company Markers',
  LoadCompanyMarkersSuccess = '[Maps] Load Company Markers Success',
  LoadCompanyMarkersFailure = '[Maps] Load Company Markers Failure',
  OpenMapsSideDrawer = '[Maps] Open Side Drawer',
  CloseMapsSideDrawer = '[Maps] Close Side Drawer',
  MarkerHovered = '[Maps] Marker Hovered',
  AddLabelFilters = '[Maps] Add Label Filter',
  RemoveLabelFilters = '[Maps] Remove Label Filter',
  ToggleShowComapniesWithoutLabels = '[Maps] Toggle Show Companies Without Labels'
}

export class SetBounds implements Action {
  readonly type = MapsActionTypes.SetBounds;
  constructor(public payload: AgmRectangle) {}
}

export class LoadCompanyMarkers implements Action {
  readonly type = MapsActionTypes.LoadCompanyMarkers;
  constructor(public payload?: GlobalFilter) {}
}

export class LoadCompanyMarkersSuccess implements Action {
  readonly type = MapsActionTypes.LoadCompanyMarkersSuccess;
  constructor(public payload: any) {}
}

export class LoadCompanyMarkersFailure implements Action {
  readonly type = MapsActionTypes.LoadCompanyMarkersFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class OpenMapsSideDrawer implements Action {
  readonly type = MapsActionTypes.OpenMapsSideDrawer;
  constructor(public payload: string) {}
}

export class CloseMapsSideDrawer implements Action {
  readonly type = MapsActionTypes.CloseMapsSideDrawer;
}

export class MarkerHovered implements Action {
  readonly type = MapsActionTypes.MarkerHovered;
  constructor(public payload: any) {}
}

export class AddLabelFilters implements Action {
  readonly type = MapsActionTypes.AddLabelFilters;
  constructor(public payload: number[]) {}
}

export class RemoveLabelFilters implements Action {
  readonly type = MapsActionTypes.RemoveLabelFilters;
  constructor(public payload: number[]) {}
}

export class ToggleShowComapniesWithoutLabels implements Action {
  readonly type = MapsActionTypes.ToggleShowComapniesWithoutLabels;
}

export type MapsActions =
  | SetBounds
  | LoadCompanyMarkers
  | LoadCompanyMarkersSuccess
  | LoadCompanyMarkersFailure
  | OpenMapsSideDrawer
  | CloseMapsSideDrawer
  | MarkerHovered
  | AddLabelFilters
  | RemoveLabelFilters
  | ToggleShowComapniesWithoutLabels;
