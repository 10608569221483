import { RootState } from '../../store.reducer';
import { CallNote } from 'src/app/models/call-notes/call-note';
import { MemoizedSelector, createSelector } from '@ngrx/store';
import { selectAllCallNotes } from './project-call-notes-as-array.selector';
import { GenericMap } from 'src/app/models/generic-map';
import { Contact } from 'src/app/models/contacts/contact';
import { Company } from 'src/app/models/companies/company';
import { CallNoteType } from 'src/app/models/call-notes/call-note-type';
import { selectIdNameCurrentUser } from '../../users/selectors/id-name-current-user.selector';
import { IdNameItemUser } from 'src/app/models/auth/id-name-item-user';
import { formatAddressIntoOneLine } from 'src/app/utils/formatAddressIntoOneLine';

export const selectedCallNoteId = (state: RootState): number =>
  state.callNotes.selectedCallNoteId;

export const selectSelectedCallNote: MemoizedSelector<RootState, CallNote> =
  createSelector<RootState, unknown[], CallNote>(
    selectAllCallNotes,
    selectIdNameCurrentUser,
    selectedCallNoteId,
    (
      callNotesMap: GenericMap<CallNote>,
      user: IdNameItemUser,
      callNoteId: number
    ): CallNote => {
      let callNote: CallNote;
      if (!!callNoteId && callNotesMap[callNoteId]) {
        callNote = {
          ...callNotesMap[callNoteId],
          shareWithEmails: callNotesMap[callNoteId].shareWithEmails || [],
          createdBy: {
            id: callNotesMap[callNoteId].createdBy.id,
            mailbox: callNotesMap[callNoteId].createdBy.mailbox,
            name:
              callNotesMap[callNoteId].createdBy.firstName +
              ' ' +
              callNotesMap[callNoteId].createdBy.lastName
          },
          company: callNotesMap[callNoteId].company,
          type: {
            id: callNotesMap[callNoteId].type.id,
            name: callNotesMap[callNoteId].type.name
          } as CallNoteType,
          contacts: callNotesMap[callNoteId].contacts.map((contact: Contact) => {
            return contact.lastName
              ? { id: contact.id, name: contact.firstName + ' ' + contact.lastName }
              : { id: contact.id, name: contact.firstName };
          }) as Contact[],
          followUp: {
            ...callNotesMap[callNoteId].followUp,
            assignedTo: {
              id: callNotesMap[callNoteId].followUp.assignedTo?.id,
              mailbox: callNotesMap[callNoteId].followUp.assignedTo?.mailbox,
              name:
                callNotesMap[callNoteId].followUp.assignedTo?.firstName +
                ' ' +
                callNotesMap[callNoteId].followUp.assignedTo?.lastName
            }
          }
        };
        if (!callNote.followUp.assignedTo?.id) {
          callNote.followUp.assignedTo = user;
        }
      }
      return callNote;
    }
  );
