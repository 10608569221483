import { Injectable } from '@angular/core';
import { AuthModule } from './auth.module';
import { Store, select } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import {
  CanActivate,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { LoginRedirect, SetInflightUrl } from 'src/app/store/auth/auth.actions';

@Injectable({
  providedIn: AuthModule
})
export class AuthGuardService implements CanActivate {
  constructor(public store: Store<RootState>) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.store.pipe(
      select('auth', 'data'),
      map(({ api, jpi }) => {
        if (!api || !jpi) {
          let inflightUrl = state.url;
          if (/login/.test(inflightUrl) || /auth/.test(inflightUrl)) {
            inflightUrl = '/';
          }
          this.store.dispatch(new SetInflightUrl(inflightUrl));
          this.store.dispatch(new LoginRedirect());
          return false;
        }
        return true;
      }),
      take(1)
    );
  }
}
