<button
  mat-raised-button
  color="primary"
  class="mat-elevation-z0"
  [matMenuTriggerFor]="newMenu"
>
  New
  <mat-icon> keyboard_arrow_down </mat-icon>
</button>
<mat-menu #newMenu="matMenu" xPosition="before">
  <button mat-menu-item (click)="openCompanyDrawer()">Company</button>
  <button mat-menu-item (click)="openCallNoteDrawer()">Call Note</button>
  <button mat-menu-item (click)="openContactDrawer()">Contact</button>
  <button mat-menu-item (click)="openTodoDrawer()">To-do</button>
  <button mat-menu-item (click)="openPrivateNoteDrawer()" *ngIf="privateNotes">
    Private Note
  </button>
</mat-menu>
