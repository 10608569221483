import { Injectable } from '@angular/core';

import { AuthenticationService } from '../authentication.service';
import { Mileage } from '../../models/mileage';

@Injectable()
export class MileageService {
	constructor(private authService: AuthenticationService) { }

	clearServiceData(): void {
	}

	getMailbox(): number {
		return Number(this.authService.getCookie("Mailbox"));
	}
	getRepName(): string {
		return this.authService.getCookie("FullName");
	}
	getAllMileage(): Promise<Mileage[]> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/mileage/GetMileage', '', 'get').then(function (result: any) {
			return JSON.parse(result);
		});
	}
	remMileage(mileageId: number, agentMailboxId: number): Promise<number> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/mileage/delMileage?mileageId=' + mileageId + '&agentMailboxId=' + agentMailboxId, '', 'post').then(function (result: any) {
			return JSON.parse(result);
		});
	}
	addMileage(mileage: Mileage): Promise<number> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/mileage/addMileage', mileage, 'post').then(function (result: any) {
			return JSON.parse(result);
		});
	}

	editMileage(mileage: Mileage): Promise<number> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/mileage/editMileage', mileage, 'post').then(function (result: any) {
			return JSON.parse(result);
		});
	}
}

