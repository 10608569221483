import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ServicesModule } from '../../services.module';
import { ProjectField } from 'src/app/models/admin/projects/project-field';
import { ProjectSetting } from '../../../models/projects/project-settings';

@Injectable({
  providedIn: ServicesModule
})
export class ProjectFieldsService {
  private jpiUrl = environment.jpiUrl;
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get(`${this.jpiUrl}/projectfields`);
  }

  create(projectField: ProjectField) {
    return this.http.post(`${this.jpiUrl}/projectfields`, projectField);
  }

  update(projectField: ProjectField) {
    return this.http.put(`${this.jpiUrl}/projectfields/${projectField.id}`, projectField);
  }

  delete(projectFieldId: number) {
    return this.http.delete(`${this.jpiUrl}/projectfields/${projectFieldId}`);
  }

  // boolean visibility project settings

  getSettings(agencyId) {
    return this.http.get(`${this.jpiUrl}/projectsettings/${agencyId}`);
  }

  createSettings(projectSetting) {
    return this.http.post(`${this.jpiUrl}/projectsettings`, projectSetting);
  }

  updateSettings(projectSetting: ProjectSetting) {
    return this.http.put(
      `${this.jpiUrl}/projectsettings/${projectSetting.id}`,
      projectSetting
    );
  }
}
