import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  OnDestroy,
  OnChanges
} from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import { Observable, Subscription } from 'rxjs';
import { map, mergeMap, startWith, tap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { FormControl } from '@angular/forms';
import { EmailUser } from 'src/app/models/admin/users/email-user';
import { selectMultiEmailUsers } from 'src/app/store/users/selectors/multi-email-users.selector';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger
} from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'tn-email-multi-select',
  templateUrl: './email-multi-select.component.html',
  styleUrls: ['./email-multi-select.component.scss']
})
export class EmailMultiSelectComponent implements OnInit, OnDestroy, OnChanges {
  @Input() emails: string[] = [];
  @Output() emailsUpdated: EventEmitter<string[]> = new EventEmitter();
  @Input() $focusEvent: Observable<void> = EMPTY;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  $users: Observable<EmailUser[]> = EMPTY;
  users: EmailUser[];
  $filteredUsers: Observable<EmailUser[]> = EMPTY;
  selectableUsers: EmailUser[] = [];
  emailInputCtrl = new FormControl();
  $emailCtrlSub: Subscription;
  $focusSub: Subscription;

  @ViewChild('emailInput', { static: true }) emailInput: ElementRef<HTMLInputElement>;
  @ViewChild('emailInput', { static: true, read: MatAutocompleteTrigger })
  autoComTrigger: MatAutocompleteTrigger;

  constructor(private store: Store<RootState>) {}

  ngOnInit() {
    this.$users = this.store.select(selectMultiEmailUsers);
    this.$focusSub = this.$focusEvent.subscribe(() => {
      this.emailInput.nativeElement.focus();
    });
    this.$emailCtrlSub = this.emailInputCtrl.valueChanges
      .pipe(
        startWith(null as string),
        mergeMap((emailInput: string | null) => {
          if (!!this.$users) {
            return this.$users.pipe(
              tap((emailUsers: EmailUser[]) => {
                this.users = emailUsers;
                this.selectableUsers = emailUsers.filter(
                  user => !this.emails.includes(user.email)
                );
              }),
              map((emailUsers: EmailUser[]) => {
                this.selectableUsers = emailUsers
                  .filter(user =>
                    this.selectableUsers.map(selUser => selUser.id).includes(user.id)
                  )
                  .filter(
                    emailUser =>
                      emailUser.name
                        .toLowerCase()
                        .indexOf(
                          !!emailInput && typeof emailInput === 'string'
                            ? emailInput.toLowerCase()
                            : ''
                        ) >= 0 ||
                      emailUser.email
                        .toLowerCase()
                        .indexOf(
                          !!emailInput && typeof emailInput === 'string'
                            ? emailInput.toLowerCase()
                            : ''
                        ) >= 0
                  );
              })
            );
          } else {
            return EMPTY;
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.$emailCtrlSub.unsubscribe();
    this.$focusSub.unsubscribe();
  }

  ngOnChanges() {
    if (!!this.emails && this.emails.length) {
      this.selectableUsers = this.users.filter(user => !this.emails.includes(user.email));
    }
  }

  selected(event: MatAutocompleteSelectedEvent) {
    const value = event.option.value;
    if ((value || '').trim() && /^\S+@\S+\.\S+$/.test(value)) {
      this.emails.push(value.trim());
      this.emailsUpdated.emit(this.emails);
    }
    this.emailInput.nativeElement.value = '';
    this.emailInputCtrl.setValue(null);
  }

  add(event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim() && /^\S+@\S+\.\S+$/.test(value)) {
      this.emails.push(value.trim());
      this.emailsUpdated.emit(this.emails);
    }

    if (input) {
      input.value = '';
    }

    this.emailInputCtrl.setValue(null);
    this.emailInput.nativeElement.value = '';
    this.autoComTrigger.closePanel();
  }

  escapePressed(event: KeyboardEvent) {
    event.stopPropagation();
    this.autoComTrigger.closePanel();
  }

  removeEmail(email: string) {
    const index = this.emails.indexOf(email);

    if (index >= 0) {
      this.emails.splice(index, 1);
      this.emailsUpdated.emit(this.emails);
      this.emailInputCtrl.reset();
    }
  }
}
