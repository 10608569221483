import { ProjectsActions, ProjectsActionTypes } from './projects.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { ColumnHeader } from 'src/app/models/column-header';
import { GenericMap } from 'src/app/models/generic-map';
import { Project } from 'src/app/models/projects/project';
import { ProjectBid } from 'src/app/models/projects/project-bid';
import { GenericApi } from 'src/app/models/generic-api';
import { ByProjectsColumnsData } from './table-columns/by-project-columns.data';
import { ByManufacturerColumnsData } from './table-columns/by-manufacturer-columns.data';
import { ByCompanyColumnsData } from './table-columns/by-company-columns.data';
import { ByBidColumnsData } from './table-columns/by-bids-columns.data';
import { ProjectTableSelect } from 'src/app/models/projects/project-table-select.enum';

export interface ProjectsState {
  pending: boolean;
  error: HttpErrorResponse;
  useDefaultColumns: boolean;
  selectedTable: ProjectTableSelect;
  selColsByProj: ColumnHeader[];
  allColsByProj: ColumnHeader[];
  selColsByManuf: ColumnHeader[];
  allColsByManuf: ColumnHeader[];
  selColsByComp: ColumnHeader[];
  allColsByComp: ColumnHeader[];
  selColsByBid: ColumnHeader[];
  allColsByBid: ColumnHeader[];
  data: GenericMap<Project>;
  // Generic ID is Project ID
  bids: GenericMap<GenericApi<GenericMap<GenericApi<GenericMap<ProjectBid>>>>>;
  summary: any;
}

export const initialState: ProjectsState = {
  pending: false,
  error: undefined,
  useDefaultColumns: true,
  selectedTable: ProjectTableSelect.ByProject,
  selColsByProj: [
    ByProjectsColumnsData[0],
    ByProjectsColumnsData[1],
    ByProjectsColumnsData[5],
    ByProjectsColumnsData[7],
    ByProjectsColumnsData[9],
    ByProjectsColumnsData[10],
    ByProjectsColumnsData[11]
  ],
  selColsByManuf: [
    ByManufacturerColumnsData[0],
    ByManufacturerColumnsData[1],
    ByManufacturerColumnsData[5],
    ByManufacturerColumnsData[6],
    ByManufacturerColumnsData[7],
    ByManufacturerColumnsData[8],
    ByManufacturerColumnsData[13]
  ],
  selColsByComp: [
    ByCompanyColumnsData[0],
    ByCompanyColumnsData[1],
    ByCompanyColumnsData[5],
    ByCompanyColumnsData[6],
    ByCompanyColumnsData[7],
    ByCompanyColumnsData[12]
  ],
  selColsByBid: [
    ByBidColumnsData[0],
    ByBidColumnsData[1],
    ByBidColumnsData[5],
    ByBidColumnsData[6],
    ByBidColumnsData[7],
    ByBidColumnsData[8]
  ],
  allColsByProj: ByProjectsColumnsData,
  allColsByManuf: ByManufacturerColumnsData,
  allColsByComp: ByCompanyColumnsData,
  allColsByBid: ByBidColumnsData,
  data: {},
  bids: {},
  summary: {}
};

export function projectsReducer(
  state = initialState,
  action: ProjectsActions
): ProjectsState {
  switch (action.type) {
    case ProjectsActionTypes.LabelColumnsAdded: {
      return {
        ...state,
        useDefaultColumns: false,
        selColsByProj: [...state.selColsByProj, ...action.payload],
        selColsByManuf: [...state.selColsByManuf, ...action.payload],
        selColsByComp: [...state.selColsByComp, ...action.payload],
        selColsByBid: [...state.selColsByBid, ...action.payload]
      };
    }

    case ProjectsActionTypes.LabelColumnAdded: {
      return {
        ...state,
        useDefaultColumns: false,
        selColsByProj: [...state.selColsByProj, action.payload],
        selColsByManuf: [...state.selColsByManuf, action.payload],
        selColsByComp: [...state.selColsByComp, action.payload],
        selColsByBid: [...state.selColsByBid, action.payload]
      };
    }

    case ProjectsActionTypes.SelectProjTableColumns:
      return {
        ...state,
        useDefaultColumns: false,
        selColsByProj: action.payload
      };

    case ProjectsActionTypes.SelectManufTableColumns:
      return {
        ...state,
        useDefaultColumns: false,
        selColsByManuf: action.payload
      };

    case ProjectsActionTypes.SelectCompTableColumns:
      return {
        ...state,
        useDefaultColumns: false,
        selColsByComp: action.payload
      };

    case ProjectsActionTypes.SelectBidTableColumns:
      return {
        ...state,
        useDefaultColumns: false,
        selColsByBid: action.payload
      };

    case ProjectsActionTypes.LoadProjects:
      return {
        ...state,
        pending: true,
        data: {}
      };

    case ProjectsActionTypes.LoadProjectsSuccess:
      return {
        ...state,
        pending: false,
        error: undefined,
        data: action.payload
      };

    case ProjectsActionTypes.LoadProject:
      return {
        ...state,
        pending: true
      };

    case ProjectsActionTypes.LoadProjectSuccess:
      return {
        ...state,
        pending: false,
        error: undefined,
        data: {
          ...state.data,
          [action.payload.id]: action.payload
        }
      };

    case ProjectsActionTypes.LoadProjectsFailure:
      return { ...state, pending: false, error: action.payload };

    case ProjectsActionTypes.LoadProjectBids:
      return {
        ...state,
        bids: {
          ...state.bids,
          [action.payload.id]: {
            ...state.bids[action.payload.id],
            pending: true,
            error: undefined
          }
        }
      };

    case ProjectsActionTypes.LoadProjectBidsSuccess: {
      const companyIds: string[] = Object.keys(action.payload.bidsMap);
      const newLoadedSuccessState = {};
      if (!!companyIds && companyIds.length) {
        companyIds.forEach((companyId) => {
          newLoadedSuccessState[companyId] = {
            pending: false,
            error: undefined,
            data: action.payload.bidsMap[companyId]
          };
        });
      }
      return {
        ...state,
        bids: {
          ...state.bids,
          [action.payload.project.id]: {
            pending: false,
            error: undefined,
            data: newLoadedSuccessState
          }
        }
      };
    }

    case ProjectsActionTypes.LoadProjectBidsSummarySuccess: {
      return {
        ...state,
        pending: false,
        error: undefined,
        summary: action.payload
      };
    }

    case ProjectsActionTypes.LoadProjectBidsFailure:
      return {
        ...state,
        bids: {
          ...state.bids,
          [action.payload.project.id]: {
            ...state.bids[action.payload.project.id],
            pending: false,
            error: action.payload.error
          }
        }
      };

    case ProjectsActionTypes.SaveProjectBid: {
      let projectId = action.payload.project.id;
      return {
        ...state,
        bids: {
          ...state.bids,
          [projectId]: {
            ...state.bids[projectId],
            pending: true,
            error: undefined
          }
        }
      };
    }

    case ProjectsActionTypes.SaveProjectBidFailure:
      let saveFailBid = action.payload.bid;
      return {
        ...state,
        bids: {
          ...state.bids,
          [saveFailBid.projectId]: {
            pending: false,
            error: undefined,
            data: {
              ...state.bids[saveFailBid.projectId].data,
              [saveFailBid.companyId]: {
                ...state.bids[saveFailBid.projectId].data[saveFailBid.companyId],
                pending: false,
                error: action.payload.error
              }
            }
          }
        }
      };

    case ProjectsActionTypes.DeleteProjectBid: {
      let projectId = action.payload.project.id;
      return {
        ...state,
        bids: {
          ...state.bids,
          [projectId]: {
            ...state.bids[projectId],
            pending: true,
            error: undefined
          }
        }
      };
    }

    // case ProjectsActionTypes.DeleteProjectBidSuccess:
    //   let deleteSuccessBid = action.payload;
    //   return {
    //     ...state,
    //     bids: {
    //       ...state.bids,
    //       [deleteSuccessBid.projectId]: {
    //         pending: false,
    //         error: undefined,
    //         data: {
    //           ...state.bids[deleteSuccessBid.projectId].data,
    //           [deleteSuccessBid.companyId]: {
    //             pending: false,
    //             error: undefined,
    //             data: {
    //               ...state.bids[deleteSuccessBid.projectId].data[
    //                 deleteSuccessBid.companyId
    //               ].data,
    //               [deleteSuccessBid.id]: undefined
    //             }
    //           }
    //         }
    //       }
    //     }
    //   };

    case ProjectsActionTypes.DeleteProjectBidFailure:
      let deleteFailBid = action.payload.bid;
      return {
        ...state,
        bids: {
          ...state.bids,
          [deleteFailBid.projectId]: {
            pending: false,
            error: undefined,
            data: {
              ...state.bids[deleteFailBid.projectId].data,
              [deleteFailBid.companyId]: {
                ...state.bids[deleteFailBid.projectId].data[deleteFailBid.companyId],
                pending: false,
                error: action.payload.error
              }
            }
          }
        }
      };

    // case ProjectsActionTypes.DeleteMultipleProjectBidsByCompanySuccess:
    //   let companyId = action.payload;
    //   return {
    //     ...state,
    //     bids: {
    //       ...state.bids,
    //       [deleteSuccessBid.projectId]: {
    //         pending: false,
    //         error: undefined,
    //         data: {
    //           ...state.bids[deleteSuccessBid.projectId].data,
    //           [deleteSuccessBid.companyId]: undefined
    //         }
    //       }
    //     }
    //   };

    case ProjectsActionTypes.SelectProjectTable: {
      return {
        ...state,
        selectedTable: action.payload
      };
    }

    default:
      return state;
  }
}
