import { Action, createReducer, on } from '@ngrx/store';
import { GenericApi } from 'src/app/models/generic-api';
import { GenericMap } from 'src/app/models/generic-map';
import { ProjectCustomField } from 'src/app/models/projects/project-custom-field';
import {
  LoadProjectManufCustomFields,
  LoadProjectManufCustomFieldsSuccess,
  LoadProjectManufCustomFieldsFailure,
  SetCurrentProjectManufCustomFieldId
} from './project-manuf-custom-fields.actions';

export const projectManufCustomFieldsFeatureKey = 'projectManufCustomFields';

export interface ProjectManufCustomFieldsState
  extends GenericApi<GenericMap<ProjectCustomField>> {
  selectedPcfId: number;
}

export const initialState: ProjectManufCustomFieldsState = {
  pending: false,
  error: undefined,
  selectedPcfId: null,
  data: {}
};

const reducer = createReducer(
  initialState,

  on(LoadProjectManufCustomFields, state => {
    return {
      ...state,
      pending: true,
      error: undefined
    };
  }),
  on(LoadProjectManufCustomFieldsSuccess, (state, action) => {
    return {
      ...state,
      pending: true,
      data: action.data
    };
  }),
  on(LoadProjectManufCustomFieldsFailure, (state, action) => {
    return {
      ...state,
      pending: true,
      error: action.error
    };
  }),
  on(SetCurrentProjectManufCustomFieldId, (state, action) => {
    return {
      ...state,
      selectedPcfId: action.id
    };
  })
);

export function projectManufCustomFieldsReducer(
  state: ProjectManufCustomFieldsState | undefined,
  action: Action
) {
  return reducer(state, action);
}
