import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Observable, Subscription, EMPTY, Subject } from 'rxjs';
import { CompanyLink } from 'src/app/models/companies/company-link';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import { MatMenuTrigger } from '@angular/material/menu';
import { CompanySelectItem } from 'src/app/models/companies/company-select';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GlobalFilter } from 'src/app/models/global-filter';
import {
  SearchCompanies,
  ClearSearchCompanies,
  SaveChildCompanies
} from 'src/app/store/companies/companies.actions';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { removeNullPropsImmutable } from 'src/app/utils/removeNullProps';
import { ActivatedRoute } from '@angular/router';
import { selectCurrentCompany } from 'src/app/store/companies/selectors/current-company.selector';
import { map } from 'rxjs/operators';
import { Company } from 'src/app/models/companies/company';
import { CompanyIdNameItem } from 'src/app/models/companies/company-id-name-item';
import { Address } from 'src/app/models/address';

@Component({
  selector: 'tn-company-child-companies',
  templateUrl: './company-child-companies.component.html',
  styleUrls: ['./company-child-companies.component.scss']
})
export class CompanyChildCompaniesComponent implements OnInit, OnDestroy {
  company: Partial<Company> = {
    directChildCompanies: []
  };
  $company: Observable<any>;
  $companySub: Subscription;
  filteredChildCompanies: CompanyLink[] = [];
  childCompanies: CompanyLink[] = [];
  $pending: Observable<boolean> = EMPTY;

  companyId;

  @ViewChild('addCompanyMenuTrigger')
  addCompanyMenuTrigger: MatMenuTrigger;
  $companies: Observable<CompanySelectItem[]>;
  $companiesSearchPending: Observable<boolean>;
  $companiesSearchError: Observable<HttpErrorResponse>;
  addCompanyForm = new FormGroup({
    id: new FormControl(null, Validators.required),
    name: new FormControl(null, Validators.required)
  });
  $addCompanyFormEventsSubject: Subject<void> = new Subject<void>();
  $focusEventSubject: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private store: Store<RootState>,
    public dialog: MatDialog
  ) {
    this.companyId = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit() {
    this.$companies = this.store.select('companies', 'search', 'data').pipe(
      map((companies: CompanyIdNameItem[]) => {
        return companies.filter((comp) => {
          return (
            comp.id !== this.company.id &&
            comp.id !==
              (!!this.company.parentCompany
                ? this.company.parentCompany.id
                : undefined) &&
            !this.company.directChildCompanies.map((item) => item.id).includes(comp.id)
          );
        });
      })
    );
    this.$companiesSearchPending = this.store.select('companies', 'search', 'pending');
    this.$companiesSearchError = this.store.select('companies', 'search', 'error');
    this.$company = this.store.select(selectCurrentCompany);
    this.$pending = this.store.select('companies', 'pending');
    this.$companySub = this.$company.subscribe((company: Company) => {
      this.companyId = company.id;
      this.company = company;
      if (!!company && !!company.directChildCompanies) {
        this.childCompanies = company.directChildCompanies;
        this.filteredChildCompanies = company.directChildCompanies;
      }
    });
  }

  ngOnDestroy() {
    this.$companySub.unsubscribe();
  }

  applyFilter(query: Event) {
    this.filteredChildCompanies = query
      ? this.childCompanies.filter((c) =>
          c.name
            .toString()
            .toLocaleLowerCase()
            .includes(query.toString().toLocaleLowerCase())
        )
      : this.childCompanies;
  }

  onCompanySearch(searchVal: string) {
    this.addCompanyForm.controls.id.setValue(undefined);
    this.addCompanyForm.controls.name.setValue(undefined);
    if (!!searchVal.length) {
      const reqFilter: GlobalFilter = {
        filters: [
          {
            operand1: 'searchField',
            operator: 'CONTAINS_ALL_OF',
            operand2: [searchVal]
          }
        ]
      };
      this.store.dispatch(new SearchCompanies(reqFilter));
    } else {
      this.store.dispatch(new ClearSearchCompanies());
    }
  }

  onCompanySelect(event: MatAutocompleteSelectedEvent) {
    this.addCompanyForm.markAsDirty();
    this.addCompanyForm.controls.id.setValue(event.option.value.id);
    this.addCompanyForm.controls.name.setValue(event.option.value.name);
    this.store.dispatch(new ClearSearchCompanies());
  }

  onSubmit(event: Event) {
    event.preventDefault();
    this.childCompanies.push(this.addCompanyForm.value as any);
    this.store.dispatch(
      new SaveChildCompanies({
        companyId: this.companyId,
        childCompanies: this.childCompanies
      })
    );
  }

  menuOpened() {
    this.$focusEventSubject.next();
  }

  hasShippingAddress(address: Address) {
    return !!Object.keys(removeNullPropsImmutable(address)).length;
  }

  deleteChild(company) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const dialogRef = this.dialog.open(CompanyChildCompaniesDialog, {
      width: '250px',
      data: company
    });
  }
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'company-child-companies-dialog',
  templateUrl: 'company-child-companies-dialog.component.html'
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CompanyChildCompaniesDialog implements OnInit, OnDestroy {
  company: Partial<Company> = {
    directChildCompanies: []
  };
  $company: Observable<any>;
  $companySub: Subscription;
  filteredChildCompanies: CompanyLink[] = [];
  childCompanies: CompanyLink[] = [];
  $companies: Observable<CompanySelectItem[]>;

  constructor(
    public dialogRef: MatDialogRef<CompanyChildCompaniesDialog>,
    private store: Store<RootState>,
    @Inject(MAT_DIALOG_DATA) public data: Company
  ) {}

  ngOnInit() {
    this.$company = this.store.select(selectCurrentCompany);
    this.$companySub = this.$company.subscribe((company: Company) => {
      this.company = company;
      if (!!company && !!company.directChildCompanies) {
        this.childCompanies = company.directChildCompanies;
        this.filteredChildCompanies = company.directChildCompanies;
      }
    });
  }

  ngOnDestroy() {
    this.$companySub.unsubscribe();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  deleteChildCompany() {
    const removeIndex = this.childCompanies
      .map((child) => child.id)
      .indexOf(this.data.id);
    this.childCompanies.splice(removeIndex, 1);
    this.store.dispatch(
      new SaveChildCompanies({
        companyId: this.company.id,
        childCompanies: this.childCompanies
      })
    );
    this.dialogRef.close();
  }
}
