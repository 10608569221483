import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ContactsEffects } from './contacts.effects';
import { contactsReducer } from './contacts.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('contacts', contactsReducer),
    EffectsModule.forFeature([ContactsEffects])
  ]
})
export class ContactsStateModule {}
