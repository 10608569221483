import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  LoadFieldTypesFailure,
  LoadFieldTypesSuccess,
  FieldTypesActionTypes,
  FieldTypesActions
} from './field-types.actions';
import { FieldTypesService } from 'src/app/services/system-settings/field-types/field-types.service';
import { FieldType } from 'src/app/models/call-notes/field-type';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class FieldTypesEffects {
  loadFieldTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FieldTypesActionTypes.LoadFieldTypes),
      concatMap(() =>
        this.fieldTypesService.getAll().pipe(
          map((fieldTypes: FieldType[]) => new LoadFieldTypesSuccess(fieldTypes)),
          catchError((error: HttpErrorResponse) => of(new LoadFieldTypesFailure(error)))
        )
      )
    )
  );

  constructor(
    private fieldTypesService: FieldTypesService,
    private actions$: Actions<FieldTypesActions>
  ) {}
}
