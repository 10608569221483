import {
  ContactCustomFieldsActions,
  ContactCustomFieldsActionTypes
} from './contact-custom-fields.actions';
import { ContactField } from 'src/app/models/contacts/contact-field';
import { HttpErrorResponse } from '@angular/common/http';

export interface ContactCustomFieldsState {
  pending: boolean;
  standardContactFields: ContactField[];
  customContactFields: ContactField[];
  currentContactField: ContactField;
  contactFields?: ContactField[];
  error: HttpErrorResponse;
}

export const initialState: ContactCustomFieldsState = {
  pending: false,
  standardContactFields: [],
  customContactFields: [],
  currentContactField: undefined,
  error: undefined
};

export function contactCustomFieldsReducer(
  state = initialState,
  action: ContactCustomFieldsActions
): ContactCustomFieldsState {
  switch (action.type) {
    case ContactCustomFieldsActionTypes.SetCurrentContactField:
      return { ...state, currentContactField: action.payload };

    case ContactCustomFieldsActionTypes.ClearCurrentContactField:
      return { ...state, currentContactField: undefined };

    case ContactCustomFieldsActionTypes.LoadContactCustomFields:
      return { ...state, pending: true };

    case ContactCustomFieldsActionTypes.LoadContactCustomFieldsSuccess:
      return {
        ...state,
        pending: false,
        standardContactFields: action.payload.filter(field => field.standardField),
        customContactFields: action.payload.filter(field => field.standardField === null)
      };

    case ContactCustomFieldsActionTypes.LoadContactCustomFieldsFailure:
      return {
        ...state,
        pending: false,
        standardContactFields: [],
        customContactFields: [],
        error: action.payload
      };

    case ContactCustomFieldsActionTypes.CreateContactCustomField:
      return { ...state, pending: true };

    case ContactCustomFieldsActionTypes.CreateContactCustomFieldSuccess:
      return {
        ...state,
        pending: false,
        customContactFields: [...state.customContactFields, action.payload]
      };

    case ContactCustomFieldsActionTypes.CreateContactCustomFieldFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case ContactCustomFieldsActionTypes.UpdateContactCustomField:
      return { ...state };

    case ContactCustomFieldsActionTypes.UpdateContactCustomFieldSuccess:
      const updatedCustomContactFields = state.customContactFields.map(field =>
        action.payload.id === field.id ? action.payload : field
      );
      return {
        ...state,
        pending: false,
        customContactFields: updatedCustomContactFields
      };

    case ContactCustomFieldsActionTypes.UpdateContactCustomFieldFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case ContactCustomFieldsActionTypes.DeleteContactCustomField:
      return { ...state, pending: true };

    case ContactCustomFieldsActionTypes.DeleteContactCustomFieldSuccess:
      return {
        ...state,
        pending: false,
        customContactFields: state.customContactFields.filter(
          contactField => contactField.id !== action.payload
        ),
        currentContactField: undefined
      };

    case ContactCustomFieldsActionTypes.DeleteContactCustomFieldFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    default:
      return state;
  }
}
