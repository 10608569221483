import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrivateNotesEffects } from './private-notes.effects';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { privateNotesReducer } from './private-notes.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('privateNotes', privateNotesReducer),
    EffectsModule.forFeature([PrivateNotesEffects])
  ]
})
export class PrivateNotesModule {}
