<div class="page-content">
  <div class="form-v9-content">
    <form class="form-detail" [formGroup]="inquiryForm" (ngSubmit)="onSubmit()">
      <div class="heading">
        <a href="https://telenotes.com">
          <img src="assets/voze_logo.png" alt="Voze" />
        </a>
      </div>
      <!-- <h2>Request Information</h2> -->
      <br />
      <br />
      <div class="form-row-total">
        <div class="form-row">
          <input
            formControlName="firstName"
            type="text"
            name="firstName"
            id="firstName"
            class="input-text"
            placeholder="First name*"
          />
        </div>
        <div class="form-row">
          <input
            formControlName="lastName"
            type="text"
            name="lastName"
            id="lastName"
            class="input-text"
            placeholder="Last name*"
          />
        </div>
      </div>
      <div class="form-row-total">
        <div class="form-row">
          <input
            formControlName="phoneNumber"
            type="text"
            name="phoneNumber"
            id="phoneNumber"
            class="input-text"
            placeholder="Phone number*"
          />
        </div>
        <div class="form-row">
          <input
            formControlName="emailAddress"
            type="text"
            name="email"
            id="email"
            class="input-text"
            placeholder="Work email*"
            pattern="[^@]+@[^@]+.[a-zA-Z]{2,6}"
          />
        </div>
      </div>
      <div class="form-row-total">
        <div class="form-row">
          <input
            formControlName="companyName"
            type="text"
            name="companyName"
            id="companyName"
            class="input-text"
            placeholder="Company name*"
          />
        </div>
      </div>
      <div class="form-row-last">
        <input
          [disabled]="!inquiryForm.valid"
          type="submit"
          name="request"
          class="register"
          value="Submit"
        />
      </div>
    </form>
    <div class="requiredMessage" style="text-align: center">
      <p>*In order to better serve you, all fields are required for submission</p>
    </div>
  </div>
</div>
