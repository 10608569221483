<mat-card class="call-note-card mat-elevation-z4">
  <mat-card-header fxLayout="column" fxLayoutAlign="center space-between">
    <div fxLayout="row" class="mb-10" fxLayoutAlign="space-between center">
      <div class="call-note-title-info" fxLayout="row" fxLayoutAlign="start center">
        <div class="call-note-label-group">
          <mat-chip-list>
            <mat-chip disableRipple="true">
              {{ callNote.typeName }}
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>
      <div
        class="created-by-and-call-note-id"
        fxLayout="row"
        fxLayoutGap="2px"
        fxLayoutAlign="end center"
      >
        <button
          mat-icon-button
          (click)="commentHandler(callNote)"
          color="primary"
          matTooltip="share/comment"
          *ngIf="showCommentsButton"
        >
          <mat-icon>comment</mat-icon>
        </button>
        <button mat-icon-button color="primary" (click)="editHandler()" matTooltip="edit">
          <mat-icon>edit</mat-icon>
        </button>
        <button
          mat-icon-button
          color="warn"
          (click)="deleteHandler(callNote)"
          matTooltip="delete"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
    <div
      class="created-by-and-call-note-id"
      fxLayout="row"
      fxLayoutGap="2px"
      fxLayoutAlign="space-between center"
    >
      <div>
        <span class="info-label">Created by: </span>
        <span class="name">{{
          callNote?.createdByFirstName + ' ' + callNote?.createdByLastName
        }}</span>
      </div>
      <div>
        <span class="info-label">Call Note ID: </span>
        <span class="name">{{ callNote.id }}</span>
      </div>
    </div>
  </mat-card-header>
  <mat-card-content>
    <mat-divider></mat-divider>
    &nbsp;
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="column" fxLayoutAlign="start start">
        <div fxLayout="row" fxLayoutAlign="start center">
          <h3 class="call-note-company-name">{{ callNote.companyName }}</h3>
          <a
            mat-icon-button
            class="company-link"
            matSuffix
            color="primary"
            target="_blank"
            [routerLink]="['/companies/company/', callNote.companyId]"
            ><mat-icon class="search-icon">call_made</mat-icon></a
          >
        </div>
      </div>
    </div>
  </mat-card-content>
  <mat-card-content *ngIf="callNote.contacts.length > 0">
    <mat-divider></mat-divider>
    &nbsp;
    <h3 class="call-note-company-name">Contacts</h3>
    <div fxLayout="row" fxLayoutAlign="space-between end">
      <div>
        <span *ngFor="let contact of callNote.contacts; let last = last">
          <span class="name">{{
            !!contact.name ? contact.name : contact.firstName + ' ' + contact.lastName
          }}</span
          ><span *ngIf="!last">, </span>
        </span>
      </div>
      <!-- <button
              *ngIf="!showCompany && !contactInfoLoaded"
              mat-button
              (click)="loadContactInfo()"
              color="primary"
            >
              <mat-icon>phone</mat-icon> Load Contact Info
            </button> -->
    </div>
  </mat-card-content>
  <mat-card-content>
    <div *ngIf="callNote.fields.length">
      <mat-divider></mat-divider>
      <div class="call-note-narratives-group" fxLayout="column" fxLayoutGap="8px">
        <div class="call-note-narrative" *ngFor="let narrative of callNote.fields">
          <span class="call-note-narrative-title">{{ narrative.fieldName }}:</span>
          <span [ngSwitch]="narrative.type?.id" class="call-note-narrative-body">
            <span class="call-note-narrative-body" *ngSwitchCase="2">
              <mat-checkbox
                disabled
                [checked]="narrative.value === 'true'"
              ></mat-checkbox>
            </span>
            <span *ngSwitchCase="4" class="call-note-narrative-body">{{
              narrative.value | currency : 'USD'
            }}</span>
            <span *ngSwitchCase="16" class="call-note-narrative-body">{{
              dropdowns[narrative.dropdownId]?.dropdownOption
            }}</span>
            <span *ngSwitchDefault class="call-note-narrative-body"
              ><img
                *ngIf="imageUrl"
                class="image-right"
                [src]="imageUrl"
                (click)="openFullImage(imageUrl)"
              />{{ narrative.value }}</span
            >
          </span>
        </div>
      </div>
    </div>
  </mat-card-content>
  <mat-card-content *ngIf="!!callNote.hasFollowUpTask">
    <mat-divider></mat-divider>
    <div class="call-note-narratives-group">
      <div class="call-note-narrative">
        <span class="call-note-narrative-title">Follow Up Task:</span>
        <span class="call-note-narrative-body">{{ callNote.task }}</span>
      </div>
    </div>
  </mat-card-content>
  <mat-card-content *ngIf="callNote.hasAudioFile && webRecording">
    <mat-divider></mat-divider>
    <tn-audio-listen [callNote]="callNote"></tn-audio-listen>
  </mat-card-content>
  <mat-card-content class="call-note-comments" *ngIf="callNote.comments?.length > 0">
    <mat-divider></mat-divider>
    <mat-accordion class="comments-panel">
      <mat-expansion-panel #commentsPanel expanded="false">
        <mat-expansion-panel-header>
          <mat-panel-title> Comments ({{ callNote.comments?.length }}) </mat-panel-title>
        </mat-expansion-panel-header>

        <div fxLayout="column" fxLayoutGap="8px">
          <tn-project-call-note-comment
            *ngFor="let comment of callNote.comments"
            [callNoteId]="callNote.id"
            [comment]="comment"
          ></tn-project-call-note-comment>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-card-content>
</mat-card>
