import { switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, Observable, tap } from 'rxjs';
import { RootState } from '../store/store.reducer';
import { selectSidenavLinks } from '../view/shared/sidenav/sidenav-links.selector';
import { SetDefaultLandingPage } from '../store/layout/layout.actions';

@Injectable({
  providedIn: 'root'
})
export class DefaultLandingPageGuard implements CanActivate {
  constructor(private store: Store<RootState>, private router: Router) {}

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.store.select('layout', 'defaultLandingPage').pipe(
      switchMap((landingPage) =>
        this.store.select(selectSidenavLinks).pipe(
          tap((links) => {
            if (links.some((link) => link.path === landingPage)) {
              this.router.navigate(['/' + (landingPage || 'dashboard')]);
            } else {
              // removing the default landing page from local storage if it is not a valid route
              this.store.dispatch(new SetDefaultLandingPage(null));
              this.router.navigate(['/dashboard']);
            }
          }),
          map(() => false)
        )
      )
    );
  }
}
