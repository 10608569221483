<div class="sign-in-as-user-panel">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
    <button mat-icon-button (click)="closeDrawer()"><mat-icon>close</mat-icon></button>
    <h3 [style.margin]="'0 0 6px'">Sign In As Different User</h3>
  </div>
  <mat-form-field [style.width]="'100%'">
    <mat-icon class="search-icon" matPrefix>search</mat-icon>
    <input
      matInput
      placeholder="Search users"
      [(ngModel)]="searchText"
      autocomplete="off"
      autofocus
    />
  </mat-form-field>
  <div class="scroll-container">
    <div
      class="user-item"
      fxLayout="row"
      fxLayoutAlign="center center"
      *ngFor="let user of $users | async | usersFilter: searchText | sort: 'firstName'"
    >
      <button
        mat-button
        [matMenuTriggerFor]="signInMenu"
        #signInMenuTrigger
        (menuOpened)="signInMenuOpen(user)"
      >
        {{ user.firstName }} {{ user.lastName }}
      </button>
    </div>
  </div>
  <mat-menu #signInMenu="matMenu" xPosition="before">
    <div [style.padding]="'8px 16px'" fxLayout="column" fxLayoutGap="8px">
      <div>Sign in as {{ selectedUser?.firstName }} {{ selectedUser?.lastName }}?</div>
      <div fxLayout="row-reverse">
        <button mat-icon-button (click)="signInAs()" color="primary">
          <mat-icon>check</mat-icon>
        </button>
        <button mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </mat-menu>
</div>
