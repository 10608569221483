<div class="white-padding" fxLayout="row">
    <div fxFlex="4"></div>
    <div class="client-input edit-company-dropdown input-padding popup-expanded-row" fxLayout="row" (click)="expandLabelsRow()"
        fxFlex>
        <div fxFlex>Labels</div>
        <mat-icon *ngIf="!labelsExpanded" class="icon-large">arrow_drop_down</mat-icon>
        <mat-icon *ngIf="labelsExpanded" class="icon-large">arrow_drop_up</mat-icon>
    </div>
    <div fxFlex="4"></div>
</div>
<div *ngIf="labelsExpanded">
    <div fxLayout="row wrap" fxFlex>
        <ng-template ngFor let-group [ngForOf]="newGroupLabels">
            <div class="client-input input-padding" fxFlex="50" fxLayout="row">
                <div fxFlex></div>
                <div *ngIf="group.labels" class="client-input" fxLayout="column" fxFlex="84">
                    <span>{{ group.name }}</span>
                    <dx-tag-box *ngIf="(group.labels.length==0 || group.labels.length>1) && (group.allowMultiple==true)"
                        [dataSource]="group.labels" displayExpr="name" valueExpr="id" [(value)]="group.labelsSelected"
                        [showSelectionControls]="true" applyValueMode="instantly" [hideSelectedItems]="true"
                        [multiline]="true" searchEnabled="true" (onSelectionChanged)="multipleLabelsValueChange($event, group)"></dx-tag-box>
                    <dx-select-box *ngIf="((group.labels.length==1) || (group.allowMultiple==false))" [dataSource]="group.labels"
                        displayExpr="name" valueExpr="id" [value]="checkSelectedLabel(group)" (onSelectionChanged)="selectLabel($event)"
                        searchEnabled="true"></dx-select-box>
                </div>
                <div fxFlex></div>
            </div>
        </ng-template>
    </div>
</div>