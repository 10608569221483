<company-popup
  *ngIf="editCompanyPopup"
  [(companyPopup)]="editCompanyPopup"
  [(company)]="companyJPI"
  [(newGroupLabels)]="labelGroups"
  [(companyDetails)]="companyDetails"
  [customSections]="customSection"
  [(companyId)]="companyJPI.id"
  [(displayedLabels)]="displayedLabels"
  (parentCompanyIdChange)="getParentCompany()"
>
</company-popup>

<div class="panel-padding">
  <div fxLayout="row wrap" class="list-header" style="border-bottom: 1px solid #919396">
    <div
      *ngIf="
        companyJPI.billingAddress.addressLine1 ||
        companyJPI.billingAddress.addressLine2 ||
        companyJPI.billingAddress.city ||
        companyJPI.billingAddress.state ||
        companyJPI.billingAddress.county ||
        companyJPI.billingAddress.postCode
      "
      class="field-padding"
      fxLayout="column"
      fxFlex="16"
    >
      <span class="text-muted text-small">Billing Address</span>
      <div
        fxLayout="row"
        *ngIf="companyJPI.billingAddress.addressLine1"
        [innerText]="companyJPI.billingAddress.addressLine1"
      ></div>
      <div
        fxLayout="row"
        *ngIf="companyJPI.billingAddress.addressLine2"
        [innerText]="companyJPI.billingAddress.addressLine2"
      ></div>
      <div fxLayout="row">
        <span
          *ngIf="companyJPI.billingAddress.city"
          [innerText]="companyJPI.billingAddress.city"
        ></span>
        <span *ngIf="companyJPI.billingAddress.city && companyJPI.billingAddress.state"
          >,&nbsp;</span
        >
        <span
          *ngIf="companyJPI.billingAddress.state"
          [innerText]="companyJPI.billingAddress.state"
        ></span>
        <span
          *ngIf="companyJPI.billingAddress.postCode"
          [innerText]="'&nbsp;' + companyJPI.billingAddress.postCode"
        ></span>
      </div>
      <div fxLayout="row">
        <span *ngIf="companyJPI.billingAddress.county"
          >County: {{ companyJPI.billingAddress.county }}</span
        >
      </div>
    </div>
    <div
      *ngIf="
        companyJPI.shippingAddress.addressLine1 ||
        companyJPI.shippingAddress.addressLine2 ||
        companyJPI.shippingAddress.city ||
        companyJPI.shippingAddress.state ||
        companyJPI.shippingAddress.county ||
        companyJPI.shippingAddress.postCode
      "
      class="field-padding"
      fxLayout="column"
      fxFlex="16"
    >
      <span class="text-muted text-small">Shipping Address</span>
      <div
        fxLayout="row"
        *ngIf="companyJPI.shippingAddress.addressLine1"
        [innerText]="companyJPI.shippingAddress.addressLine1"
      ></div>
      <div
        fxLayout="row"
        *ngIf="companyJPI.shippingAddress.addressLine2"
        [innerText]="companyJPI.shippingAddress.addressLine2"
      ></div>
      <div fxLayout="row">
        <span
          *ngIf="companyJPI.shippingAddress.city"
          [innerText]="companyJPI.shippingAddress.city"
        ></span>
        <span *ngIf="companyJPI.shippingAddress.city && companyJPI.shippingAddress.state"
          >,&nbsp;</span
        >
        <span
          *ngIf="companyJPI.shippingAddress.state"
          [innerText]="companyJPI.shippingAddress.state"
        ></span>
        <span
          *ngIf="companyJPI.shippingAddress.postCode"
          [innerText]="'&nbsp;' + companyJPI.shippingAddress.postCode"
        ></span>
      </div>
      <div fxLayout="row">
        <span *ngIf="companyJPI.shippingAddress.county"
          >County: {{ companyJPI.shippingAddress.county }}</span
        >
      </div>
    </div>
    <div *ngIf="companyJPI.phone" class="field-padding" fxLayout="column" fxFlex="16">
      <span class="text-muted text-small">Phone</span>
      <span *ngIf="companyJPI.phone.length == 10"
        >({{ companyJPI.phone[0] }}{{ companyJPI.phone[1]
        }}{{ companyJPI.phone[2] }})&nbsp;{{ companyJPI.phone[3] }}{{ companyJPI.phone[4]
        }}{{ companyJPI.phone[5] }}-{{ companyJPI.phone[6] }}{{ companyJPI.phone[7]
        }}{{ companyJPI.phone[8] }}{{ companyJPI.phone[9] }}</span
      >
      <span *ngIf="companyJPI.phone.length != 10">{{ companyJPI.phone }}</span>
    </div>
    <div *ngIf="companyJPI.ext" class="field-padding" fxLayout="column" fxFlex="16">
      <span class="text-muted text-small">Ext.</span>
      <span>{{ companyJPI.ext }}</span>
    </div>
    <div *ngIf="companyJPI.fax" class="field-padding" fxLayout="column" fxFlex="16">
      <span class="text-muted text-small">Fax</span>
      <span *ngIf="companyJPI.fax.length == 10"
        >({{ companyJPI.fax[0] }}{{ companyJPI.fax[1] }}{{ companyJPI.fax[2] }})&nbsp;{{
          companyJPI.fax[3]
        }}{{ companyJPI.fax[4] }}{{ companyJPI.fax[5] }}-{{ companyJPI.fax[6]
        }}{{ companyJPI.fax[7] }}{{ companyJPI.fax[8] }}{{ companyJPI.fax[9] }}</span
      >
      <span *ngIf="companyJPI.fax.length != 10">{{ companyJPI.fax }}</span>
    </div>
    <div *ngIf="companyJPI.website" class="field-padding" fxLayout="column" fxFlex="16">
      <span class="text-muted text-small">Website</span>
      <span
        class="link"
        (click)="openExternalWebsite(companyJPI.website)"
        [innerText]="companyJPI.website"
      ></span>
    </div>
    <ng-template ngFor let-field [ngForOf]="companyDetails">
      <div *ngIf="field.Value" class="field-padding" fxLayout="column" fxFlex="16">
        <span class="text-muted text-small" [innerText]="field.Name"></span>
        <span *ngIf="field.FieldType == 4" fxFlex>${{ field.Value }}</span>
        <span *ngIf="field.FieldType == 6">{{ field.Value | date: 'mediumDate' }}</span>
        <span
          *ngIf="field.FieldType == 11"
          class="link"
          (click)="openExternalWebsite(field.Value)"
          >{{ field.Value }}</span
        >
        <span
          *ngIf="field.FieldType != 4 && field.FieldType != 11 && field.FieldType != 6"
          fxFlex
          >{{ field.Value }}</span
        >
      </div>
    </ng-template>
  </div>
  <div *ngIf="companyJPI.notes">
    <div fxLayout="column" class="list-header" style="border-bottom: 1px solid #919396">
      <span class="text-muted text-small">Notes</span>
      <span [innerText]="companyJPI.notes"></span>
    </div>
  </div>
  <div fxLayout="row wrap" class="list-header" style="border-bottom: 1px solid #919396">
    <div *ngIf="companyJPI.id" class="field-padding" fxLayout="column" fxFlex="14">
      <span class="text-muted text-small">Telenotes ID</span>
      <span [innerText]="companyJPI.id"></span>
    </div>
    <div *ngIf="company.AccountId" class="field-padding" fxLayout="column" fxFlex="14">
      <span class="text-muted text-small">Account ID</span>
      <span [innerText]="company.AccountId"></span>
    </div>
    <div
      *ngIf="companyJPI.createdDate"
      class="field-padding"
      fxLayout="column"
      fxFlex="14"
    >
      <span class="text-muted text-small">Date Created</span>
      <span
        *ngIf="
          companyJPI.createdDate && companyJPI.createdDate.toString() != 'Invalid Date'
        "
        >{{ companyJPI.createdDate | date }}</span
      >
    </div>
    <div *ngIf="parentCompany" class="field-padding" fxLayout="column" fxFlex="14">
      <span class="text-muted text-small">Parent Company</span>
      <div class="link" (click)="openParentCompany()">{{ parentCompany.name }}</div>

      <div *ngIf="parentCompany.billingAddress" fxLayout="column">
        <div
          fxLayout="row"
          *ngIf="parentCompany.billingAddress.addressLine1"
          [innerText]="parentCompany.billingAddress.addressLine1"
        ></div>
        <div
          fxLayout="row"
          *ngIf="parentCompany.billingAddress.addressLine2"
          [innerText]="parentCompany.billingAddress.addressLine2"
        ></div>
        <div fxLayout="row">
          <span
            *ngIf="parentCompany.billingAddress.city"
            [innerText]="parentCompany.billingAddress.city"
          ></span>
          <span
            *ngIf="
              parentCompany.billingAddress.city && parentCompany.billingAddress.state
            "
            >,&nbsp;</span
          >
          <span
            *ngIf="parentCompany.billingAddress.state"
            [innerText]="parentCompany.billingAddress.state"
          ></span>
          <span
            *ngIf="parentCompany.billingAddress.postCode"
            [innerText]="'&nbsp;' + parentCompany.billingAddress.postCode"
          ></span>
        </div>
      </div>
      <div
        *ngIf="!parentCompany.billingAddress && parentCompany.shippingAddress"
        fxLayout="column"
      >
        <div
          fxLayout="row"
          *ngIf="parentCompany.shippingAddress.addressLine1"
          [innerText]="parentCompany.shippingAddress.addressLine1"
        ></div>
        <div
          fxLayout="row"
          *ngIf="parentCompany.shippingAddress.addressLine2"
          [innerText]="parentCompany.shippingAddress.addressLine2"
        ></div>
        <div fxLayout="row">
          <span
            *ngIf="parentCompany.shippingAddress.city"
            [innerText]="parentCompany.shippingAddress.city"
          ></span>
          <span
            *ngIf="
              parentCompany.shippingAddress.city && parentCompany.shippingAddress.state
            "
            >,&nbsp;</span
          >
          <span
            *ngIf="parentCompany.shippingAddress.state"
            [innerText]="parentCompany.shippingAddress.state"
          ></span>
          <span
            *ngIf="parentCompany.shippingAddress.postCode"
            [innerText]="'&nbsp;' + parentCompany.shippingAddress.postCode"
          ></span>
        </div>
      </div>

      <!-- <div *ngIf="companyJPI.billingAddress" fxLayout="row">
				<span *ngIf="companyJPI.billingAddress.city" [innerText]="companyJPI.billingAddress.city"></span>
				<span *ngIf="companyJPI.billingAddress.city && companyJPI.billingAddress.state">,&nbsp;</span>
				<span *ngIf="companyJPI.billingAddress.state" [innerText]="companyJPI.billingAddress.state"></span>
				<span *ngIf="companyJPI.billingAddress.postCode"
					[innerText]="'&nbsp;' + companyJPI.billingAddress.postCode"></span>
			</div>
			<div *ngIf="!companyJPI.billingAddress && companyJPI.shippingAddress" fxLayout="row">
				<span *ngIf="companyJPI.shippingAddress.city" [innerText]="companyJPI.shippingAddress.city"></span>
				<span *ngIf="companyJPI.shippingAddress.city && companyJPI.shippingAddress.state">,&nbsp;</span>
				<span *ngIf="companyJPI.shippingAddress.state" [innerText]="companyJPI.shippingAddress.state"></span>
				<span *ngIf="companyJPI.shippingAddress.postCode"
					[innerText]="'&nbsp;' + companyJPI.shippingAddress.postCode"></span>
			</div> -->
    </div>
    <div
      *ngIf="company.ChildCompanies"
      class="field-padding"
      fxLayout="column"
      fxFlex="14"
    >
      <span class="text-muted text-small">Child Companies</span>
      <span>{{ company.ChildCompanies.length | number }}</span>
    </div>
    <div
      *ngIf="companyJPI.primaryUser"
      class="field-padding"
      fxLayout="column"
      fxFlex="14"
    >
      <span class="text-muted text-small">Primary Sales Rep</span>
      <span *ngIf="companyJPI.primaryUser.firstName && companyJPI.primaryUser.lastName"
        >{{ companyJPI.primaryUser.firstName }}&nbsp;{{
          companyJPI.primaryUser.lastName
        }}</span
      >
      <span
        *ngIf="companyJPI.primaryUser.firstName && !companyJPI.primaryUser.lastName"
        >{{ companyJPI.primaryUser.firstName }}</span
      >
      <span
        *ngIf="!companyJPI.primaryUser.firstName && companyJPI.primaryUser.lastName"
        >{{ companyJPI.primaryUser.lastName }}</span
      >
    </div>
  </div>
  <div class="text-muted text-small" style="padding-top: 10px">
    Territories and Sales Reps
  </div>
  <div style="padding-top: 10px">
    <dx-data-grid
      *ngIf="territoriesAndRepsGrid && territoriesAndRepsGrid.length > 0"
      [dataSource]="territoriesAndRepsGrid"
      [showColumnLines]="false"
      [showRowLines]="true"
      [showBorders]="true"
      [rowAlternationEnabled]="false"
      [hoverStateEnabled]="true"
      style="max-height: 700px"
    >
      <dxo-scrolling mode="virtual"></dxo-scrolling>
      <dxo-grouping [autoExpandAll]="false" expandMode="rowClick"></dxo-grouping>
      <dxi-column
        dataField="territory"
        [groupIndex]="0"
        groupCellTemplate="groupTemplate"
      ></dxi-column>
      <div *dxTemplate="let group of 'groupTemplate'">
        <span style="font-weight: 500">{{ group.data.key }}</span>
      </div>
      <dxi-column dataField="name" caption="Name"></dxi-column>
      <dxi-column dataField="role" caption="Role"></dxi-column>
      <dxi-column
        *ngIf="isSalesAdmin"
        dataField="sales"
        caption="Sales & Commissions"
        cellTemplate="salesTemplate"
      ></dxi-column>
      <div *dxTemplate="let sales of 'salesTemplate'">
        <dx-check-box
          *ngIf="sales.data.name != 'This territory has no sales reps'"
          [(value)]="sales.data.sales"
          (onOptionChanged)="salesChanged($event, sales.data)"
        ></dx-check-box>
        <div *ngIf="sales.data.name == 'This territory has no sales reps'"></div>
      </div>
    </dx-data-grid>
    <div *ngIf="territoriesAndRepsGrid && territoriesAndRepsGrid.length == 0">
      There are no territories selected for {{ companyJPI.name }}.
    </div>
  </div>
</div>
