import { Action } from '@ngrx/store';
import { Contact } from 'src/app/models/contacts/contact';
import { HttpErrorResponse } from '@angular/common/http';

export enum ContactsActionTypes {
  LoadContacts = '[Contacts] Load Contacts',
  LoadContactsSuccess = '[Contacts] Load Contacts Success',
  LoadContactsFailure = '[Contacts] Load Contacts Failure',
  LoadContact = '[Contacts] Load Contact',
  LoadContactSuccess = '[Contacts] Load Contact Success',
  LoadContactFailure = '[Contacts] Load Contact Failure',
  SaveContact = '[Contacts] Save Contact',
  SaveContactSuccess = '[Contacts] Save Contact Success',
  SaveContactFailure = '[Contacts] Save Contact Failure',
  ClearCurrentContact = '[Contacts] Clear Current Contact'
}

export class LoadContacts implements Action {
  readonly type = ContactsActionTypes.LoadContacts;
  constructor(public payload: number) {}
}

export class LoadContactsSuccess implements Action {
  readonly type = ContactsActionTypes.LoadContactsSuccess;
  constructor(public payload: Contact[]) {}
}

export class LoadContactsFailure implements Action {
  readonly type = ContactsActionTypes.LoadContactsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadContact implements Action {
  readonly type = ContactsActionTypes.LoadContact;
  constructor(public payload: { companyId: number; contactId: number }) {}
}

export class LoadContactSuccess implements Action {
  readonly type = ContactsActionTypes.LoadContactSuccess;
  constructor(public payload: Contact) {}
}

export class LoadContactFailure implements Action {
  readonly type = ContactsActionTypes.LoadContactFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class SaveContact implements Action {
  readonly type = ContactsActionTypes.SaveContact;
  constructor(public payload: { contact: Contact; companyId: number }) {}
}

export class SaveContactSuccess implements Action {
  readonly type = ContactsActionTypes.SaveContactSuccess;
  constructor(public payload: Contact) {}
}

export class SaveContactFailure implements Action {
  readonly type = ContactsActionTypes.SaveContactFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class ClearCurrentContact implements Action {
  readonly type = ContactsActionTypes.ClearCurrentContact;
}

export type ContactsActions =
  | LoadContacts
  | LoadContactsSuccess
  | LoadContactsFailure
  | LoadContact
  | LoadContactSuccess
  | LoadContactFailure
  | SaveContact
  | SaveContactSuccess
  | SaveContactFailure
  | ClearCurrentContact;
