import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  OnChanges,
  ViewChild,
  ElementRef
} from '@angular/core';
import { Observable, Subscription, EMPTY } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, tap, map, filter } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import {
  validateIdNameItem,
  validateIdNameItemRequired,
  validateFieldNotEmpty
} from '../validators/id-name-item.validator';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { CompanyIdNameItem } from 'src/app/models/companies/company-id-name-item';
import { IdNameItem } from 'src/app/models/id-name-item';

@Component({
  selector: 'tn-company-select',
  templateUrl: './company-select.component.html',
  styleUrls: ['./company-select.component.scss']
})
export class CompanySelectComponent implements OnInit, OnChanges, OnDestroy {
  @Input() $companySearchResults: Observable<CompanyIdNameItem[]>;
  @Input() placeholder = 'Company';
  @Input() selectedCompany: CompanyIdNameItem;
  @Input() $pending: Observable<boolean>;
  @Input() autofocus: boolean;
  @Input() isRequired = false;
  @Input() $error: Observable<HttpErrorResponse>;
  @Input() $clearInputEvent: Observable<void> = EMPTY;
  @Input() $focusEvent: Observable<void> = EMPTY;
  @Input() showCompanyLink = true;
  @Input() halfWidth = false;
  @Input() isValid: boolean;
  @Input() isDisabled: boolean = false;
  @Output() companySearchEvent = new EventEmitter<string>();
  @Output() companySelected = new EventEmitter<MatAutocompleteSelectedEvent>();
  companySearchCtrl = new FormControl<IdNameItem | string>({ id: 0, name: '' }, [
    validateIdNameItem
  ]);
  companySearchInputSub: Subscription;
  $clearInputSub: Subscription;
  $focusSub: Subscription;
  width: string;
  padding: string;

  @ViewChild('companySearchInput', { static: true })
  companySearchInput: ElementRef<HTMLInputElement>;
  ngOnInit() {
    if (this.isRequired) {
      this.companySearchCtrl.setValidators([
        validateIdNameItemRequired,
        validateFieldNotEmpty,
        Validators.required
      ]);
      this.companySearchCtrl.markAsTouched();
    }
    if (this.halfWidth) {
      this.width = '46%';
      this.padding = '0 4px';
    } else {
      this.width = '100%';
      this.padding = '0';
    }
    this.companySearchInputSub = this.companySearchCtrl.valueChanges
      .pipe(
        filter(
          (companySearchVal: string | CompanyIdNameItem) =>
            typeof companySearchVal === 'string'
        ),
        debounceTime(500),
        distinctUntilChanged(),
        tap((companySearchVal: string) => {
          this.companySearchEvent.emit(companySearchVal);
        })
      )
      .subscribe();
    this.$clearInputSub = this.$clearInputEvent.subscribe(() => {
      this.companySearchCtrl.setValue('');
    });
    this.$focusSub = this.$focusEvent.subscribe(() => {
      this.companySearchInput.nativeElement.focus();
    });

    if (this.isDisabled) {
      this.companySearchCtrl.disable();
    }
  }

  ngOnChanges() {
    if (!!this.selectedCompany) {
      this.companySearchCtrl.setValue(this.selectedCompany);
    }
  }

  ngOnDestroy() {
    this.companySearchInputSub.unsubscribe();
    this.$clearInputSub.unsubscribe();
    this.$focusSub.unsubscribe();
  }

  displayFn(item?: CompanyIdNameItem): string | undefined {
    return item ? item.name : undefined;
  }

  showCallMadeIcon(): boolean {
    if (typeof this.companySearchCtrl.value !== 'string') {
      return this.companySearchCtrl.value.id && this.showCompanyLink;
    } else {
      return false;
    }
  }
}
