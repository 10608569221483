import { MemoizedSelector, createSelector } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import { ProjectBid } from 'src/app/models/admin/projects/project-bid';

export const selectAllProjectBids = (state: RootState) => state.projectBids.data;

export const selectProjectBidsAsArray: MemoizedSelector<
  RootState,
  ProjectBid[]
> = createSelector(
  selectAllProjectBids,
  (projectBidsMap: { [id: number]: ProjectBid }): ProjectBid[] => {
    return Object.values(projectBidsMap);
  }
);
