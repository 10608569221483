import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Observable, EMPTY } from 'rxjs';
import { Plans } from 'src/app/models/admin/plans/plans';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import {
  CreatePlans,
  UpdatePlans,
  DeletePlans,
  ClearPlanId,
  LoadPlans
} from 'src/app/store/plans/plans.actions';
import { CloseDrawer } from 'src/app/store/layout/layout.actions';
import {
  FormGroup,
  FormControl,
  FormArray,
  FormControlName,
  Validators
} from '@angular/forms';
// eslint-disable-next-line max-len
import { DeleteObject } from 'src/app/store/undo-delete/undo-delete.actions';
import { Feature } from 'src/app/models/features/feature';
import { selectSelectedPlan } from 'src/app/store/plans/selectors/selected-plan.selector';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDeleteComponent } from 'src/app/view/shared/confirm-delete/confirm-delete.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'tn-add-plan',
  templateUrl: './add-plan.component.html',
  styleUrls: ['./add-plan.component.css']
})
export class AddPlanComponent implements OnInit, OnDestroy {
  currentPlan: Plans;
  planForm: FormGroup;
  featureForm: FormGroup;
  features: FormArray;
  plans: Plans[];
  $planSub: Subscription;
  $currentPlanSub: Subscription;
  $currentPlan: Observable<Plans> = EMPTY;
  dashboardFeature: Feature;
  excedeFeature: Feature;
  salesFeature: Feature;
  mapsFeature: Feature;
  maddenCoFeature: Feature;
  projectsFeature: Feature;
  opportunitiesFeature: Feature;
  rigDigFeature: Feature;
  iSQuoteFeature: Feature;

  constructor(private store: Store<RootState>, private dialog: MatDialog) {}

  ngOnInit() {
    this.$currentPlan = this.store.select(selectSelectedPlan);
    this.$currentPlanSub = this.$currentPlan.subscribe((plan) => {
      this.currentPlan = plan;
    });
    this.planForm = new FormGroup({
      id: new FormControl(null),
      name: new FormControl(null, Validators.required),
      description: new FormControl(null),
      features: new FormArray([])
    });
    if (this.currentPlan) {
      this.planForm.patchValue({
        name: this.currentPlan.name,
        description: this.currentPlan.description,
        id: this.currentPlan.id
      });
      this.dashboardFeature = {
        featureId: 6,
        planId: this.currentPlan.id,
        isChecked: this.currentPlan.dashboard
      };
      this.excedeFeature = {
        featureId: 7,
        planId: this.currentPlan.id,
        isChecked: this.currentPlan.excede
      };
      this.salesFeature = {
        featureId: 2,
        planId: this.currentPlan.id,
        isChecked: this.currentPlan.sales
      };
      this.mapsFeature = {
        featureId: 3,
        planId: this.currentPlan.id,
        isChecked: this.currentPlan.maps
      };
      this.maddenCoFeature = {
        featureId: 12,
        planId: this.currentPlan.id,
        isChecked: this.currentPlan.maddenCo
      };
      this.projectsFeature = {
        featureId: 13,
        planId: this.currentPlan.id,
        isChecked: this.currentPlan.projects
      };
      this.opportunitiesFeature = {
        featureId: 20,
        planId: this.currentPlan.id,
        isChecked: this.currentPlan.opportunities
      };
      this.opportunitiesFeature = {
        featureId: 20,
        planId: this.currentPlan.id,
        isChecked: this.currentPlan.opportunities
      };
      this.rigDigFeature = {
        featureId: 22,
        planId: this.currentPlan.id,
        isChecked: this.currentPlan.rigDig
      };
      this.iSQuoteFeature = {
        featureId: 25,
        planId: this.currentPlan.id,
        isChecked: this.currentPlan.iSQuote
      };
      if (this.currentPlan.dashboard) {
        this.features = this.planForm.get('features') as FormArray;
        this.features.insert(
          0,
          new FormControl({ featureId: this.dashboardFeature.featureId })
        );
      }
      if (this.currentPlan.excede) {
        this.features = this.planForm.get('features') as FormArray;
        this.features.insert(
          1,
          new FormControl({ featureId: this.excedeFeature.featureId })
        );
      }
      if (this.currentPlan.maps) {
        this.features = this.planForm.get('features') as FormArray;
        this.features.insert(
          2,
          new FormControl({ featureId: this.mapsFeature.featureId })
        );
      }
      if (this.currentPlan.maddenCo) {
        this.features = this.planForm.get('features') as FormArray;
        this.features.insert(
          3,
          new FormControl({ featureId: this.maddenCoFeature.featureId })
        );
      }
      if (this.currentPlan.opportunities) {
        this.features = this.planForm.get('features') as FormArray;
        this.features.insert(
          4,
          new FormControl({ featureId: this.opportunitiesFeature.featureId })
        );
      }
      if (this.currentPlan.projects) {
        this.features = this.planForm.get('features') as FormArray;
        this.features.insert(
          5,
          new FormControl({ featureId: this.projectsFeature.featureId })
        );
      }
      if (this.currentPlan.sales) {
        this.features = this.planForm.get('features') as FormArray;
        this.features.insert(
          6,
          new FormControl({ featureId: this.salesFeature.featureId })
        );
      }
      if (this.currentPlan.rigDig) {
        this.features = this.planForm.get('features') as FormArray;
        this.features.insert(
          7,
          new FormControl({ featureId: this.rigDigFeature.featureId })
        );
      }
      if (this.currentPlan.iSQuote) {
        this.features = this.planForm.get('features') as FormArray;
        this.features.insert(
          8,
          new FormControl({ featureId: this.iSQuoteFeature.featureId })
        );
      }
    }
  }

  ngOnDestroy() {
    this.$currentPlanSub.unsubscribe();
  }

  save() {
    this.store.dispatch(new CreatePlans(this.planForm.value));
    this.store.dispatch(new ClearPlanId());
    this.store.dispatch(new CloseDrawer());
  }

  update() {
    this.store.dispatch(new UpdatePlans(this.planForm.value));
    this.store.dispatch(new ClearPlanId());
    this.store.dispatch(new CloseDrawer());
  }

  dashboardChange(event: any) {
    this.planForm.markAsDirty();
    this.features = this.planForm.get('features') as FormArray;
    if (event) {
      this.features.insert(
        0,
        new FormControl({ featureId: this.dashboardFeature.featureId })
      );
    } else {
      this.features.removeAt(
        this.features.value.findIndex((feature) => feature.featureId === 6)
      );
    }
  }

  excedeChange(event: any) {
    this.planForm.markAsDirty();
    this.features = this.planForm.get('features') as FormArray;
    if (event) {
      this.features.insert(
        1,
        new FormControl({ featureId: this.excedeFeature.featureId })
      );
    } else {
      this.features.removeAt(
        this.features.value.findIndex((feature) => feature.featureId === 7)
      );
    }
  }

  mapsChange(event: any) {
    this.planForm.markAsDirty();
    this.features = this.planForm.get('features') as FormArray;
    if (event) {
      this.features.insert(2, new FormControl({ featureId: this.mapsFeature.featureId }));
    } else {
      this.features.removeAt(
        this.features.value.findIndex((feature) => feature.featureId === 3)
      );
    }
  }

  maddenCoChange(event: any) {
    this.planForm.markAsDirty();
    this.features = this.planForm.get('features') as FormArray;
    if (event) {
      this.features.insert(
        3,
        new FormControl({ featureId: this.maddenCoFeature.featureId })
      );
    } else {
      this.features.removeAt(
        this.features.value.findIndex((feature) => feature.featureId === 12)
      );
    }
  }

  opportunitiesChange(event: any) {
    this.planForm.markAsDirty();
    this.features = this.planForm.get('features') as FormArray;
    if (event) {
      this.features.insert(
        4,
        new FormControl({ featureId: this.opportunitiesFeature.featureId })
      );
    } else {
      this.features.removeAt(
        this.features.value.findIndex((feature) => feature.featureId === 20)
      );
    }
  }

  projectsChange(event: any) {
    this.planForm.markAsDirty();
    this.features = this.planForm.get('features') as FormArray;
    if (event) {
      this.features.insert(
        5,
        new FormControl({ featureId: this.projectsFeature.featureId })
      );
    } else {
      this.features.removeAt(
        this.features.value.findIndex((feature) => feature.featureId === 13)
      );
    }
  }

  salesChange(event: any) {
    this.planForm.markAsDirty();
    this.features = this.planForm.get('features') as FormArray;
    if (event) {
      this.features.insert(
        6,
        new FormControl({ featureId: this.salesFeature.featureId })
      );
    } else {
      this.features.removeAt(
        this.features.value.findIndex((feature) => feature.featureId === 2)
      );
    }
  }

  rigDigChange(event: any) {
    this.planForm.markAsDirty();
    this.features = this.planForm.get('features') as FormArray;
    if (event) {
      this.features.insert(
        7,
        new FormControl({ featureId: this.rigDigFeature.featureId })
      );
    } else {
      this.features.removeAt(
        this.features.value.findIndex((feature) => feature.featureId === 22)
      );
    }
  }

  iSQuoteChange(event: any) {
    this.planForm.markAsDirty();
    this.features = this.planForm.get('features') as FormArray;
    if (event) {
      this.features.insert(
        8,
        new FormControl({ featureId: this.iSQuoteFeature.featureId })
      );
    } else {
      this.features.removeAt(
        this.features.value.findIndex((feature) => feature.featureId === 25)
      );
    }
  }

  cancel(): void {
    this.store.dispatch(new ClearPlanId());
    this.store.dispatch(new CloseDrawer());
  }

  delete(): void {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {});

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((willDelete: boolean) => {
        if (willDelete) {
          this.store.dispatch(new CloseDrawer());
          this.store.dispatch(new ClearPlanId());
          this.store.dispatch(new LoadPlans());
          this.store.dispatch(new DeletePlans(this.currentPlan.id));
        }
      });
  }
}
