import { ActivatedRouteSnapshot } from '@angular/router';

export function extractParamId(route: ActivatedRouteSnapshot, path?: string): number {
  let id: string;
  if (!!route) {
    if (!!route.firstChild) {
      if (!!route.routeConfig && route.routeConfig.path === path) {
        id = !!route.firstChild.params.id ? route.firstChild.params.id : null;
        return parseInt(id, 10);
      }
    } else {
      id = !!route.params.id ? route.params.id : null;
    }
    return id ? parseInt(id, 10) : extractParamId(route.firstChild, path);
  } else {
    return null;
  }
}
