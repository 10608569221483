import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';

import { RootState } from 'src/app/store/store.reducer';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Reauthenticate } from 'src/app/store/auth/auth.actions';
import { environment } from 'src/environments/environment';

@Injectable()
export class Interceptor401Service implements HttpInterceptor {
  jpi = environment.jpiUrl;
  api = environment.apiUrl;

  constructor(private store: Store<RootState>) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (
          error instanceof HttpErrorResponse &&
          error.status === 401 &&
          // We have to check against these specific URLs because a user might
          // input the wrong credentials, get a 401 error, and the inflight URL
          // would become the login URL
          error.url !== this.api + '/api/security/AuthenticateWebUser' &&
          error.url !== this.jpi + '/users/authenticate'
        ) {
          this.store.dispatch(new Reauthenticate());
        }
        return throwError(error);
      })
    );
  }
}
