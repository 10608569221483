import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CompanyLabelsIndividualEffects } from './company-labels-individual.effects';
import { companyLabelsIndividualReducer } from './company-labels-individual.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('companyLabelsIndividual', companyLabelsIndividualReducer),
    EffectsModule.forFeature([CompanyLabelsIndividualEffects])
  ]
})
export class CompanyLabelsIndividualStateModule {}
