import { Injectable } from '@angular/core';
import { ServicesModule } from '../services.module';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Client } from 'src/app/models/admin/client/client';

@Injectable({
  providedIn: ServicesModule
})
export class ClientsService {
  private jpiUrl = environment.jpiUrl;
  private elasticSyncUrl = environment.elasticSyncUrl;
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get(`${this.jpiUrl}/clients`);
  }

  getById(clientId: number) {
    return this.http.get(`${this.jpiUrl}/clients/${clientId}`);
  }

  create(client: Client) {
    return this.http.post(`${this.jpiUrl}/clients`, client);
  }

  update(client: Client) {
    return this.http.put(`${this.jpiUrl}/clients/${client.id}`, client);
  }

  delete(clientId: number) {
    return this.http.delete(`${this.jpiUrl}/clients/${clientId}`);
  }

  sync(clientId: number) {
    return this.http.post(`${this.elasticSyncUrl}/sync/${clientId}`, null);
  }
}
