import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Project } from 'src/app/models/projects/project';
import { ProjectBid } from 'src/app/models/projects/project-bid';
import { ProjectCustomField } from 'src/app/models/projects/project-custom-field';
import { ApiProjectCustomFieldValue } from 'src/app/models/projects/api-project-custom-field-value';
import { ApiProjectCompanyCustomFieldValue } from 'src/app/models/projects/api-project-company-custom-field-value';
import { ApiProjectManufCustomFieldValue } from 'src/app/models/projects/api-project-manuf-custom-field-value';
import { ApiBidCustomFieldValue } from 'src/app/models/projects/api-bid-custom-field-value';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {
  private jpiUrl = environment.jpiUrl;

  constructor(private http: HttpClient) {}

  getProjects(): Observable<Project[]> {
    return this.http.get(`${this.jpiUrl}/projects`) as Observable<Project[]>;
  }

  getProject(id: number): Observable<Project> {
    return this.http.get(`${this.jpiUrl}/projects/${id}`) as Observable<Project>;
  }

  createProject(project: Project): Observable<Project> {
    return this.http.post(`${this.jpiUrl}/projects`, project) as Observable<Project>;
  }

  updateProject(project: Project): Observable<Project> {
    return this.http.put(
      `${this.jpiUrl}/projects/${project.id}`,
      project
    ) as Observable<Project>;
  }

  deleteProject(id: number): Observable<Project> {
    return this.http.delete(`${this.jpiUrl}/projects/${id}`) as Observable<Project>;
  }

  getProjectBids(id: number): Observable<ProjectBid[]> {
    return this.http.get(`${this.jpiUrl}/projects/${id}/bids`) as Observable<
      ProjectBid[]
    >;
  }

  createProjectBid(projectId: number, bid: ProjectBid): Observable<ProjectBid> {
    return this.http.post(
      `${this.jpiUrl}/projects/${projectId}/bids`,
      bid
    ) as Observable<ProjectBid>;
  }

  updateProjectBid(projectId: number, bid: ProjectBid): Observable<ProjectBid> {
    return this.http.put(
      `${this.jpiUrl}/projects/${projectId}/bids/${bid.id}`,
      bid
    ) as Observable<ProjectBid>;
  }

  deleteProjectBid(projectId: number, bidId: number): Observable<ProjectBid> {
    return this.http.delete(
      `${this.jpiUrl}/projects/${projectId}/bids/${bidId}`
    ) as Observable<ProjectBid>;
  }

  getProjectBidsSummary(id: number): Observable<any[]> {
    return this.http.get(`${this.jpiUrl}/projects/${id}/bids/summary`) as Observable<any>;
  }

  // -=-=-= Project Custom Fields =-=-=-
  getProjectCustomFields(): Observable<ProjectCustomField[]> {
    return this.http.get(`${this.jpiUrl}/projects/customfields`) as Observable<
      ProjectCustomField[]
    >;
  }

  createProjectCustomField(pcf: ProjectCustomField): Observable<ProjectCustomField> {
    return this.http.post(
      `${this.jpiUrl}/projects/customfields`,
      pcf
    ) as Observable<ProjectCustomField>;
  }

  updateProjectCustomField(pcf: ProjectCustomField): Observable<ProjectCustomField> {
    return this.http.put(
      `${this.jpiUrl}/projects/customfields/${pcf.id}`,
      pcf
    ) as Observable<ProjectCustomField>;
  }

  deleteProjectCustomField(pcf: ProjectCustomField): Observable<any> {
    return this.http.delete(
      `${this.jpiUrl}/projects/customfields/${pcf.id}`
    ) as Observable<any>;
  }

  updateProjectCustomFieldsListOrder(
    pCFs: ProjectCustomField[]
  ): Observable<ProjectCustomField[]> {
    return this.http.put(
      `${this.jpiUrl}/projects/customfields/updateListOrder`,
      pCFs
    ) as Observable<ProjectCustomField[]>;
  }

  createProjectCustomFieldValue(
    pCFv: ApiProjectCustomFieldValue
  ): Observable<ApiProjectCustomFieldValue> {
    return this.http.post(
      `${this.jpiUrl}/projects/${pCFv.projectId}/customfieldvalue`,
      pCFv
    ) as Observable<ApiProjectCustomFieldValue>;
  }

  updateProjectCustomFieldValue(
    pCFv: ApiProjectCustomFieldValue
  ): Observable<ApiProjectCustomFieldValue> {
    return this.http.put(
      `${this.jpiUrl}/projects/${pCFv.projectId}/customfieldvalue/${pCFv.id}`,
      pCFv
    ) as Observable<ApiProjectCustomFieldValue>;
  }

  deleteProjectCustomFieldValue(pCFv: ApiProjectCustomFieldValue): Observable<any> {
    return this.http.delete(
      `${this.jpiUrl}/projects/${pCFv.projectId}/customfieldvalue/${pCFv.id}`
    ) as Observable<any>;
  }

  // -=-=-= Bid Custom Fields =-=-=-
  getBidCustomFields(): Observable<ProjectCustomField[]> {
    return this.http.get(`${this.jpiUrl}/projects/bidcustomfields`) as Observable<
      ProjectCustomField[]
    >;
  }

  createBidCustomField(bcf: ProjectCustomField): Observable<ProjectCustomField> {
    return this.http.post(
      `${this.jpiUrl}/projects/bidcustomfields`,
      bcf
    ) as Observable<ProjectCustomField>;
  }

  updateBidCustomField(bcf: ProjectCustomField): Observable<ProjectCustomField> {
    return this.http.put(
      `${this.jpiUrl}/projects/bidcustomfields/${bcf.id}`,
      bcf
    ) as Observable<ProjectCustomField>;
  }

  deleteBidCustomField(bcf: ProjectCustomField): Observable<any> {
    return this.http.delete(
      `${this.jpiUrl}/projects/bidcustomfields/${bcf.id}`
    ) as Observable<any>;
  }

  updateBidCustomFieldsListOrder(
    pCFs: ProjectCustomField[]
  ): Observable<ProjectCustomField[]> {
    return this.http.put(
      `${this.jpiUrl}/projects/bidcustomfields/updateListOrder`,
      pCFs
    ) as Observable<ProjectCustomField[]>;
  }

  createBidCustomFieldValue(
    pCFv: ApiBidCustomFieldValue
  ): Observable<ApiBidCustomFieldValue> {
    return this.http.post(
      `${this.jpiUrl}/projects/${pCFv.projectId}/custombidvalues`,
      pCFv
    ) as Observable<ApiBidCustomFieldValue>;
  }

  updateBidCustomFieldValue(
    pCFv: ApiBidCustomFieldValue
  ): Observable<ApiBidCustomFieldValue> {
    return this.http.put(
      `${this.jpiUrl}/projects/${pCFv.projectId}/custombidvalues/${pCFv.id}`,
      pCFv
    ) as Observable<ApiBidCustomFieldValue>;
  }

  deleteBidCustomFieldValue(pCFv: ApiBidCustomFieldValue): Observable<any> {
    return this.http.delete(
      `${this.jpiUrl}/projects/${pCFv.projectId}/custombidvalues/${pCFv.id}`
    ) as Observable<any>;
  }

  // -=-=-= Project Company Custom Fields =-=-=-
  getProjectCompanyCustomFields(): Observable<ProjectCustomField[]> {
    return this.http.get(`${this.jpiUrl}/projects/company/customfields`) as Observable<
      ProjectCustomField[]
    >;
  }

  createProjectCompanyCustomField(
    pcf: ProjectCustomField
  ): Observable<ProjectCustomField> {
    return this.http.post(
      `${this.jpiUrl}/projects/company/customfields`,
      pcf
    ) as Observable<ProjectCustomField>;
  }

  updateProjectCompanyCustomField(
    pcf: ProjectCustomField
  ): Observable<ProjectCustomField> {
    return this.http.put(
      `${this.jpiUrl}/projects/company/customfields/${pcf.id}`,
      pcf
    ) as Observable<ProjectCustomField>;
  }

  deleteProjectCompanyCustomField(pcf: ProjectCustomField): Observable<any> {
    return this.http.delete(
      `${this.jpiUrl}/projects/company/customfields/${pcf.id}`
    ) as Observable<any>;
  }

  updateProjectCompanyCustomFieldsListOrder(
    pCFs: ProjectCustomField[]
  ): Observable<ProjectCustomField[]> {
    return this.http.put(
      `${this.jpiUrl}/projects/company/customfields/updateListOrder`,
      pCFs
    ) as Observable<ProjectCustomField[]>;
  }

  createProjectCompanyCustomFieldValue(
    pCFv: ApiProjectCompanyCustomFieldValue
  ): Observable<ApiProjectCompanyCustomFieldValue> {
    return this.http.post(
      `${this.jpiUrl}/projects/${pCFv.projectId}/company/customfieldvalues`,
      pCFv
    ) as Observable<ApiProjectCompanyCustomFieldValue>;
  }

  updateProjectCompanyCustomFieldValue(
    pCFv: ApiProjectCompanyCustomFieldValue
  ): Observable<ApiProjectCompanyCustomFieldValue> {
    return this.http.put(
      `${this.jpiUrl}/projects/${pCFv.projectId}/company/customfieldvalues/${pCFv.id}`,
      pCFv
    ) as Observable<ApiProjectCompanyCustomFieldValue>;
  }

  deleteProjectCompanyCustomFieldValue(
    pCFv: ApiProjectCompanyCustomFieldValue
  ): Observable<any> {
    return this.http.delete(
      `${this.jpiUrl}/projects/${pCFv.projectId}/company/customfieldvalues/${pCFv.id}`
    ) as Observable<any>;
  }

  // -=-=-= Project Manufacturer Custom Fields =-=-=-
  getProjectManufCustomFields(): Observable<ProjectCustomField[]> {
    return this.http.get(
      `${this.jpiUrl}/projects/manufacturer/customfields`
    ) as Observable<ProjectCustomField[]>;
  }

  createProjectManufCustomField(pcf: ProjectCustomField): Observable<ProjectCustomField> {
    return this.http.post(
      `${this.jpiUrl}/projects/manufacturer/customfields`,
      pcf
    ) as Observable<ProjectCustomField>;
  }

  updateProjectManufCustomField(pcf: ProjectCustomField): Observable<ProjectCustomField> {
    return this.http.put(
      `${this.jpiUrl}/projects/manufacturer/customfields/${pcf.id}`,
      pcf
    ) as Observable<ProjectCustomField>;
  }

  deleteProjectManufCustomField(pcf: any): Observable<any> {
    return this.http.delete(
      `${this.jpiUrl}/projects/manufacturer/customfields/${pcf.id}`
    ) as Observable<any>;
  }

  updateProjectManufCustomFieldsListOrder(
    pCFs: ProjectCustomField[]
  ): Observable<ProjectCustomField[]> {
    return this.http.put(
      `${this.jpiUrl}/projects/manufacturer/customfields/updateListOrder`,
      pCFs
    ) as Observable<ProjectCustomField[]>;
  }

  createProjectManufCustomFieldValue(
    pCFv: ApiProjectManufCustomFieldValue
  ): Observable<ApiProjectManufCustomFieldValue> {
    return this.http.post(
      `${this.jpiUrl}/projects/${pCFv.projectId}/manufacturer/customfieldvalues`,
      pCFv
    ) as Observable<ApiProjectManufCustomFieldValue>;
  }

  updateProjectManufCustomFieldValue(
    pCFv: ApiProjectManufCustomFieldValue
  ): Observable<ApiProjectManufCustomFieldValue> {
    return this.http.put(
      `${this.jpiUrl}/projects/${pCFv.projectId}/manufacturer/customfieldvalues/${pCFv.id}`,
      pCFv
    ) as Observable<ApiProjectManufCustomFieldValue>;
  }

  deleteProjectManufCustomFieldValue(
    pCFv: ApiProjectManufCustomFieldValue
  ): Observable<any> {
    return this.http.delete(
      `${this.jpiUrl}/projects/${pCFv.projectId}/manufacturer/customfieldvalues/${pCFv.id}`
    ) as Observable<any>;
  }
}
