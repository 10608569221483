import {
  SystemSettingsActions,
  SystemSettingsActionTypes
} from './system-settings.actions';

export interface State {
  pending: boolean;
}

export const initialState: State = {
  pending: false
};

export function systemSettingsReducer(
  state = initialState,
  action: SystemSettingsActions
): State {
  switch (action.type) {
    case SystemSettingsActionTypes.LoadSystemSettings:
      return state;

    case SystemSettingsActionTypes.LoadSystemSettingsSuccess:
      return state;

    case SystemSettingsActionTypes.LoadSystemSettingsFailure:
      return state;

    default:
      return state;
  }
}
