import { RootState } from '../../store.reducer';
import { MemoizedSelector, createSelector } from '@ngrx/store';
import { GenericMap } from 'src/app/models/generic-map';
import { Plans } from 'src/app/models/admin/plans/plans';

export const selectAllPlans = (state: RootState) => state.plans.data;

export const selectPlansAsArray: MemoizedSelector<RootState, Plans[]> = createSelector(
  selectAllPlans,
  (plansMap: GenericMap<Plans>): Plans[] => {
    return Object.values(plansMap).map((plans: Plans) => ({
      id: plans.id,
      name: plans.name,
      description: plans.description,
      features: plans.features
    }));
  }
);
