<mat-form-field fxFlex="1 1 100">
  <input
    type="text"
    placeholder="{{ getSelectedItems() }} "
    matInput
    [matAutocomplete]="auto"
  />
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" class="h-fit">
    <mat-option disabled>Select one or more territories</mat-option>
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
        <button mat-icon-button disabled></button>
        <mat-checkbox
          *ngIf="node.users.length"
          class="checklist-leaf-node"
          [checked]="checklistSelection.isSelected(node)"
          (change)="leafItemSelectionToggle(node)"
          >{{ node.label }}
          <span *ngIf="node.storeno">({{ node.storeno }})</span></mat-checkbox
        >
        <mat-checkbox
          *ngIf="!node.users.length"
          disabled
          class="checklist-leaf-node"
          [checked]="checklistSelection.isSelected(node)"
          (change)="leafItemSelectionToggle(node)"
          >{{ node.label }} <span *ngIf="node.storeno">({{ node.storeno }})</span>
          <span class="text-italic">(unassigned territory)</span></mat-checkbox
        >
      </mat-tree-node>

      <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
        <button
          mat-icon-button
          matTreeNodeToggle
          [attr.aria-label]="'Toggle ' + node.label"
        >
          <mat-icon class="mat-icon-rtl-mirror">
            {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
          </mat-icon>
        </button>
        <mat-checkbox
          [checked]="descendantsAllSelected(node)"
          [indeterminate]="descendantsPartiallySelected(node)"
          (change)="itemSelectionToggle(node)"
          >{{ node.label
          }}<span *ngIf="node.storeno"> ({{ node.storeno }})</span></mat-checkbox
        >
      </mat-tree-node>
    </mat-tree>
  </mat-autocomplete>
</mat-form-field>
