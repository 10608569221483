<div class="mileage-form" fxLayout="column">
  <form [formGroup]="mileageForm">
    <tn-item-select
      *ngIf="$hasMileageAdmin | async"
      [placeholder]="'Assigned User'"
      [$selectableItems]="$allUsers"
      [selectedItem]="currentMileage?.assignedToUser"
      required
      (itemSelected)="onUserSelected($event)"
      (isValid)="userValidation($event)"
    ></tn-item-select>

    <mat-form-field style="width: 100%">
      <mat-label>Mileage Date</mat-label>
      <input matInput [matDatepicker]="mileagePicker" formControlName="mileageDate" />
      <mat-datepicker-toggle matSuffix [for]="mileagePicker"></mat-datepicker-toggle>
      <mat-datepicker #mileagePicker></mat-datepicker>
    </mat-form-field>

    <div fxLayout="row" fxLayoutAlign="space-between center">
      <mat-form-field class="mileage-odometer">
        <input
          [value]="currentMileage?.odometerStart"
          formControlName="odometerStart"
          matInput
          required
          placeholder="Odometer Start"
          type="number"
        />
      </mat-form-field>
      <div>&mdash;</div>
      <mat-form-field class="mileage-odometer">
        <input
          [value]="currentMileage?.odometerEnd"
          formControlName="odometerEnd"
          matInput
          required
          placeholder="Odometer End"
          type="number"
        />
      </mat-form-field>
    </div>

    <div fxLayout="row-reverse" fxLayoutAlign="space-between center">
      <div fxLayout="row-reverse">
        <button
          type="button"
          mat-raised-button
          color="primary"
          mat-button
          [disabled]="mileageForm.pristine || mileageForm.invalid || hasOdometerError"
          (click)="save()"
        >
          <span *ngIf="!currentMileage?.id">Create</span>
          <span *ngIf="currentMileage?.id">Save</span>
        </button>
        &nbsp;&nbsp;
        <button
          preventDefaultAction
          mat-raised-button
          color="white"
          mat-button
          (click)="cancel()"
        >
          Cancel
        </button>
      </div>
      <button
        preventDefaultAction
        mat-raised-button
        color="accent"
        *ngIf="currentMileage?.id"
        mat-button
        (click)="delete(currentMileage.id)"
      >
        Delete
      </button>
    </div>
  </form>
</div>
