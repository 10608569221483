import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { CompanyV2 } from '../../../../shared/models/companies/company-V2';
import { CallNoteService } from '../../../../shared/services/call-notes/call-note.service';
import { CallNoteV2 } from './../../../../shared/models/call-notes/call-note-V2';
import { CallNoteV2Service } from './../../../../shared/services/call-notes/call-note-V2.service';
import { ActivityPermissionsService } from './../../../../shared/services/permissions/activity-permissions.service';
import { FilteredList } from '../../../../shared/models/filteredList';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import { OpenDrawer } from 'src/app/store/layout/layout.actions';
import { CallNoteFormComponent } from 'src/app/view/shared/call-note-form/call-note-form.component';
import {
  SelectCallNoteId,
  LoadCallNotesSuccess
} from 'src/app/store/call-notes/call-notes.actions';
import { CallNote } from 'src/app/models/call-notes/call-note';
import { selectCurrentCompany } from 'src/app/store/companies/selectors/current-company.selector';

@Component({
  selector: 'company-profile-activity',
  templateUrl: './company-profile-activity.component.html',
  styleUrls: ['../../companies.scss']
})
export class CompanyProfileActivityComponent implements OnInit, OnDestroy {
  @Input() loadingCallNotes: boolean;
  @Input() selectedCallNote: CallNoteV2;
  @Input() callNoteExpanded: boolean;
  @Input() callNotePopup: boolean;
  @Input() editCallNotesFeature: number;
  @Input() companyJPI: CompanyV2;
  @Input() callNotes: CallNoteV2[];
  @Output() selectedCallNoteChange: EventEmitter<CallNoteV2> =
    new EventEmitter<CallNoteV2>();
  @Output() callNoteExpandedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() callNotePopupChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  callNotesFilter: any;
  followUps: any;
  showMoreCallNotesButton: boolean;
  callNoteSubscription: Subscription;
  callNoteDeleteSubscription: Subscription;
  callNoteFromToolbarSubscription: Subscription;
  dataDisplayed: any;

  constructor(
    activityPermissionsService: ActivityPermissionsService,
    private callNoteV2Service: CallNoteV2Service,
    private store: Store<RootState>,
    private callNoteService: CallNoteService
  ) {
    activityPermissionsService
      .getCallNotesPermission()
      .then((result) => (this.editCallNotesFeature = result));
  }

  ngOnDestroy(): void {
    this.callNoteSubscription.unsubscribe();
    this.callNoteDeleteSubscription.unsubscribe();
    this.callNoteFromToolbarSubscription.unsubscribe();
  }

  ngOnInit() {
    this.store.select(selectCurrentCompany).subscribe();
    if (!this.callNotes) {
      this.callNotesFilter = {
        filters: [
          { operand1: 'companyId', operator: 'IS_EQUAL_TO', operand2: this.companyJPI.id }
        ]
      };
      this.callNoteV2Service
        .getCallNotesFiltered(this.callNotesFilter, 0, 18, 'createdDate')
        .then((callNotes: FilteredList) => {
          this.callNotes = <CallNoteV2[]>callNotes.items;
          this.callNotes = this.callNotes.filter((note) => note.createdBy);
          if (this.callNotes.length % 18 == 0 && this.callNotes.length != 0) {
            this.showMoreCallNotesButton = true;
          }
          this.followUps = this.callNotes.filter(
            (callNote: CallNoteV2) => callNote.followUp && callNote.followUp.task
          );
        });
      this.callNoteFromToolbarSubscription =
        this.callNoteService.callNoteAnnounced.subscribe((callNote) => {
          if (callNote) {
            let newCallNote = true;
            this.callNotes = this.callNotes.map((note) => {
              if (note.id === callNote.id) {
                newCallNote = false;
                return callNote;
              } else {
                return note;
              }
            });
            if (newCallNote) {
              this.callNotes.unshift(callNote);
            }
            this.callNoteService.callNoteCreatedFromToolbar(null);
          }
        });
    }

    // this.callNoteFromToolbarSubscription = this._callNoteService.callNoteAnnounced.subscribe(callNote => {
    // 	if (callNote) {
    // 		this.asdfasdf.unshift(callNote);
    // 		this._callNoteService.callNoteCreatedFromToolbar(null);
    // 	}
    // });

    this.callNoteSubscription = this.callNoteService.callNoteAnnounced.subscribe(
      (updatedCallNote) => {
        if (
          updatedCallNote != null &&
          typeof updatedCallNote === 'object' &&
          this.updatingCallNote(updatedCallNote)
        ) {
          this.callNotes = this.callNotes.map((callNote) => {
            return updatedCallNote.id === callNote.id ? updatedCallNote : callNote;
          });
          this.callNoteService.callNoteCreatedFromToolbar(null);
        } else if (updatedCallNote != null && updatedCallNote != 'reload') {
          this.callNotes.unshift(updatedCallNote);
          if (updatedCallNote.followUp && updatedCallNote.followUp.task) {
            this.followUps.unshift(updatedCallNote);
          }
          this.callNoteService.callNoteCreatedFromToolbar(null);
        }
      }
    );

    this.callNoteDeleteSubscription =
      this.callNoteService.callNoteDeletedAnnounced.subscribe((id) => {
        if (id) {
          this.callNotes = this.callNotes.filter((callNote) => callNote.id != id);
          this.callNoteService.callNoteDeletedOnProfile(null);
        }
      });
  }

  updatingCallNote(callNote): boolean {
    const callNoteIds = [];

    this.callNotes.forEach((note) => callNoteIds.push(note.id));
    return callNoteIds.includes(callNote.id);
  }

  calculateCreatedBy(callNote: CallNoteV2): string {
    let user: string = '';
    if (callNote.createdBy && callNote.createdBy.firstName) {
      user = callNote.createdBy.firstName + ' ' + callNote.createdBy.lastName;
    }
    return user;
  }

  calculateContacts(callNote: CallNoteV2): string {
    let contacts: string = '';
    if (callNote.contacts) {
      callNote.contacts.forEach((contact, index) => {
        index === callNote.contacts.length - 1
          ? (contacts += `${contact.firstName} ${contact.lastName}`)
          : (contacts += `${contact.firstName} ${contact.lastName}, `);
      });
    }
    return contacts;
  }

  calculateCallType(callNote: CallNoteV2): string {
    let callType: string = '';
    if (callNote.type && callNote.type.name) {
      callType = callNote.type.name;
    }
    return callType;
  }

  calculateNarrative(callNote: CallNoteV2): string {
    let narrative: string = '';
    if (callNote.fields) {
      for (let i = 0; i < callNote.fields.length; i++) {
        if (
          callNote.fields[i].field &&
          callNote.fields[i].field.type &&
          callNote.fields[i].field.type.legacyId == 22 &&
          callNote.fields[i].value &&
          callNote.fields[i].value != ''
        ) {
          narrative += callNote.fields[i].value;
          break;
        }
      }
    }
    return narrative;
  }

  calculateFollowUp(callNote: CallNoteV2): string {
    let followUpTask: string = '';
    if (callNote.followUp && callNote.followUp.task) {
      followUpTask = callNote.followUp.task;
    }
    return followUpTask;
  }

  calculateAssignedAgent(callNote: CallNoteV2): string {
    let assignedAgent: string = '';
    if (
      callNote.followUp &&
      callNote.followUp.assignedTo &&
      callNote.followUp.assignedTo?.firstName
    ) {
      assignedAgent =
        callNote.followUp.assignedTo?.firstName +
        ' ' +
        callNote.followUp.assignedTo?.lastName;
    }
    return assignedAgent;
  }

  loadMoreCallNotesClick(): void {
    this.callNotesFilter = {
      filters: [
        { operand1: 'companyId', operator: 'IS_EQUAL_TO', operand2: this.companyJPI.id }
      ],
      previousRecord: this.callNotes[this.callNotes.length - 1]
    };
    this.callNoteV2Service
      .getCallNotesFiltered(this.callNotesFilter, 0, 18, 'createdDate')
      .then((callNotes: FilteredList) => {
        this.callNotes = this.callNotes.concat(<CallNoteV2[]>callNotes.items);
        if (this.callNotes.length % 18 == 0 && this.callNotes.length != 0) {
          this.showMoreCallNotesButton = true;
        } else {
          this.showMoreCallNotesButton = false;
        }
        this.followUps = this.callNotes.filter(
          (callNote: CallNoteV2) => callNote.followUp && callNote.followUp.task
        );
      });
  }

  resetCallNotes(callNote: CallNoteV2) {
    for (let i = 0; i < this.callNotes.length; i++) {
      if (this.callNotes[i].id === callNote.id) {
        this.callNotes.splice(i, 1, callNote);
      }
    }
  }

  callNotePopupChanged(callNotePopup): void {
    this.callNotePopup = callNotePopup;
    this.callNotePopupChange.emit(this.callNotePopup);
  }

  editCallNote(event: any, isCallNote: boolean): void {
    if (event.event.target.className != 'dx-checkbox-icon') {
      this.selectedCallNote = Object.assign({}, event.data);
      // this.callNoteExpanded = isCallNote;
      // this.callNotePopup = true;
      this.store.dispatch(
        new OpenDrawer({
          component: CallNoteFormComponent,
          data: { callnoteId: this.selectedCallNote.id }
        })
      );
      this.store.dispatch(
        new LoadCallNotesSuccess({
          [this.selectedCallNote.id]: this.selectedCallNote as unknown as CallNote
        })
      );
      this.store.dispatch(new SelectCallNoteId(this.selectedCallNote.id));
      this.selectedCallNoteChange.emit(this.selectedCallNote);
      this.callNoteExpandedChange.emit(this.callNoteExpanded);
      this.callNotePopupChange.emit(this.callNotePopup);
    }
  }

  completeCheckBoxChange(callNote: CallNoteV2, e: any): void {
    callNote.followUp.complete = e.value;
    if (callNote.followUp.complete == false) {
      callNote.followUp.completedDate = null;
    }
    if (callNote.id) {
      callNote.createdDate = new Date(callNote.createdDate);
      if (callNote.followUp.dueDateStart && callNote.followUp.dueDateEnd) {
        callNote.followUp.dueDateStart = new Date(
          callNote.followUp.dueDateStart.toString()
        );
        callNote.followUp.dueDateEnd = new Date(callNote.followUp.dueDateEnd.toString());
      }
      this.callNoteV2Service.updateCallNote(callNote);
    }
  }
}
