import { ActivityPermissionsService } from './../../services/permissions/activity-permissions.service';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import isEqual from 'lodash.isequal';

import { UserV2 } from '../../models/admin/users/user-V2';
import { CommentV3 } from '../../models/call-notes/comment-V3';
import { CallNoteCommentService } from '../../services/call-notes/call-note-comments.service';
import { CallNoteV2 } from './../../models/call-notes/call-note-V2';
import { AuthenticationService } from './../../services/authentication.service';

@Component({
    selector: 'call-note-comments',
    templateUrl: './call-note-comments.component.html',
    styleUrls: ['./call-note-popup.component.scss']
})
export class CallNoteCommentsComponent implements OnInit {
    @Input() commentsExpanded: boolean;
    @Input() callNote: CallNoteV2;
    @Input() callNotes: CallNoteV2[];
    @Input() editCallNotesFeature: number;
    @Input() companyAgents: UserV2[];
    @Input() newComment: CommentV3;
    @Input() addNewComment: boolean;
    @Output() addNewCommentChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() callNotesChange: EventEmitter<CallNoteV2[]> = new EventEmitter<CallNoteV2[]>();
    agentId: number;
    commentCopy: CommentV3;

    constructor(private _callNoteCommentService: CallNoteCommentService, private _authService: AuthenticationService, private _activityPermissions: ActivityPermissionsService) { }

    ngOnInit() {
        if (this.callNote.comments) {
            this.callNote.comments = this.callNote.comments.filter((comment: CommentV3) => !comment.deleted);
            this.callNote.comments.map((comment: CommentV3) => {
                (<any>comment).disabled = true;
            });
            this.callNote.comments.sort((a: CommentV3, b: CommentV3) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());
        }
        this.agentId = parseInt(this._authService.getCookie(this._authService.cookieNames.AgentId));
        if (!this.editCallNotesFeature) { this._activityPermissions.getCallNotesPermission().then(result => this.editCallNotesFeature = result); }
    }

    expandCommentRow(): void {
        this.commentsExpanded = !this.commentsExpanded;
    }

    editComment(comment: CommentV3) {
        (<any>comment).disabled = false;
        this.commentCopy = JSON.parse(JSON.stringify(comment));
        document.getElementById(comment.id.toString()).focus();
    }

    deleteComment(comment: CommentV3) {
        delete (<any>comment).disabled;
        this._callNoteCommentService.deleteCallNoteComment(this.callNote.id, comment).then(() => {
            for (let i = 0; i < this.callNote.comments.length; i++) {
                if (this.callNote.comments[i].id == comment.id) {
                    this.callNote.comments.splice(i, 1);
                    break;
                }
            }
        });
    }

    cancelComment(comment: CommentV3): void {
        if (!isEqual(comment, this.commentCopy)) {
            this.callNote.comments.map((callNoteComment: CommentV3) => {
                if (callNoteComment.id == comment.id) { callNoteComment.value = JSON.parse(JSON.stringify(this.commentCopy.value)); }
            });
        }
        (<any>comment).disabled = true;
    }

    cancelNewComment(): void {
        this.newComment = Object.assign({});
        this.addNewComment = false;
        this.addNewCommentChange.emit(this.addNewComment);
    }

    setNewCommentFocus(e: any) {
        setTimeout(() => {
            e.component.focus();
        }, 0);
    }

    saveComment(comment: CommentV3): void {
        if (comment.id) {
            comment.createdDate = new Date(comment.createdDate);
            delete (<any>comment).disabled;
            this._callNoteCommentService.updateCallNoteComment(this.callNote.id, comment).then(() => {
                for (let callNoteComment of this.callNote.comments) {
                    if (callNoteComment.id == comment.id) { callNoteComment = Object.assign(comment); }
                }
                this.updateCallNotes();
                (<any>comment).disabled = true;
            });
        } else {
            comment.createdDate = new Date(Date.now());
            (<any>comment).createdBy = {
                id: parseInt(this._authService.getCookie(this._authService.cookieNames.AgentId)),
                mailbox: parseInt(this._authService.getCookie(this._authService.cookieNames.AgentMailbox)),
                agencyId: parseInt(this._authService.getCookie(this._authService.cookieNames.AgencyId)),
                deleted: false
            };
            this._callNoteCommentService.addCallNoteComment(this.callNote.id, comment).then((result) => {
                comment = JSON.parse(JSON.stringify(result));
                (<any>comment).disabled = true;
                if (!this.callNote.comments) { this.callNote.comments = []; }
                this.callNote.comments.unshift(comment);
                this.updateCallNotes();
                comment = Object.assign({});
                this.addNewComment = false;
            });
        }
    }

    updateCallNotes(): void {
        if (this.callNotes) {
            for (let i = 0; i < this.callNotes.length; i++) {
                if (this.callNote.id == this.callNotes[i].id) {
                    this.callNotes[i] = JSON.parse(JSON.stringify(this.callNote));
                }
            }
            this.callNotesChange.emit(this.callNotes);
        }
    }

    getCompanyAgentForComment(comment: CommentV3): void {
        for (const agent of this.companyAgents) {
            if (agent.id == comment.createdBy.id) {
                comment.createdBy.firstName = agent.firstName;
                comment.createdBy.lastName = agent.lastName;
            }
        }
    }
}

