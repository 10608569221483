import { MemoizedSelector, createSelector } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import { Category } from '../../../../models/admin/files/category';

export const selectAllCategories = (state: RootState) => state.fileCategories.data;

export const selectCategoriesAsArray: MemoizedSelector<
  RootState,
  Category[]
> = createSelector(
  selectAllCategories,
  (categoriesMap: { [id: number]: Category }): Category[] => {
    return Object.values(categoriesMap);
  }
);
