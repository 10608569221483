<div class="contact-form">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <mat-form-field style="margin-right: 10px">
      <span class="input-icon" matPrefix> <mat-icon>search</mat-icon>&nbsp; </span>
      <input
        matInput
        (keyup)="applyFilter($event.target.value)"
        autocomplete="off"
        placeholder="Search contacts"
      />
    </mat-form-field>
  </div>

  <mat-card
    *ngFor="let contact of filteredContacts"
    class="project-call-note-card mat-elevation-z4"
    style="margin: 10px"
  >
    <mat-card-header fxLayout="row" fxLayoutAlign="space-between center">
      <div
        class="call-note-title-info"
        fxLayout="row"
        fxLayoutAlign="start start"
        fxLayoutGap="16px"
      >
        <div fxLayout="column" fxLayoutGap="6px">
          <div fxLayout="row">
            <div [style.fontWeight]="500">
              {{ contact?.firstName }} {{ contact?.lastName }}
            </div>
          </div>
          <div [style.color]="'#999999'">{{ contact?.title }}</div>
        </div>
      </div>
      <div class="call-note-actions" fxLayout="row">
        <a
          mat-icon-button
          color="primary"
          *ngIf="contact?.email"
          href="mailto:{{ contact?.email }}"
          matTooltip="Email contact"
          target="_blank"
          ><mat-icon [style.fontSize]="'20px'" class="material-icons">
            email
          </mat-icon></a
        >
        <button
          mat-icon-button
          color="primary"
          (click)="addCallNote(contact)"
          matTooltip="Add call note"
        >
          <mat-icon>note_add</mat-icon>
        </button>
        <button
          mat-icon-button
          color="primary"
          (click)="rowClicked(contact)"
          matTooltip="Edit contact"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          mat-icon-button
          color="warn"
          (click)="openDialog(contact)"
          matTooltip="Delete contact"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div>
        <mat-divider></mat-divider>

        <div fxLayout="row" style="padding-top: 10px">
          <div fxFlex="50" fxLayout="column">
            <div style="margin-bottom: 10px">
              <b>Phone: </b
              ><span [style.color]="'#999999'">{{
                contact?.formattedPhone || '--'
              }}</span>
            </div>
          </div>
          <div fxFlex="50" fxLayout="column">
            <div>
              <b>Ext: </b
              ><span [style.color]="'#999999'">{{ contact?.ext || '--' }}</span>
            </div>
          </div>
        </div>
        <div fxLayout="row">
          <div fxFlex="50" fxLayout="column">
            <div style="margin-bottom: 10px">
              <b>Mobile: </b
              ><span [style.color]="'#999999'">{{
                contact?.formattedMobilePhone || '--'
              }}</span>
            </div>
          </div>
          <div fxFlex="50" fxLayout="column">
            <div>
              <b>Fax: </b
              ><span [style.color]="'#999999'">{{ contact?.formattedFax || '--' }}</span>
            </div>
          </div>
        </div>
        <div fxLayout="row">
          <div fxFlex="50" fxLayout="column">
            <div style="margin-bottom: 10px">
              <b>Title: </b
              ><span [style.color]="'#999999'">{{ contact?.title || '--' }}</span>
            </div>
          </div>
          <div fxFlex="50" fxLayout="column">
            <div>
              <b>Department: </b
              ><span [style.color]="'#999999'">{{ contact?.dept || '--' }}</span>
            </div>
          </div>
        </div>
        <div fxLayout="row">
          <div fxFlex="100" fxLayout="column">
            <div style="margin-bottom: 10px">
              <b>Email: </b
              ><span [style.color]="'#999999'">{{ contact?.email || '--' }}</span>
            </div>
          </div>
        </div>
        <div fxLayout="row">
          <div fxFlex="50" fxLayout="column">
            <div *ngIf="contact.birthday" style="margin-bottom: 10px">
              <b>Birthday: </b
              ><span [style.color]="'#999999'">{{
                contact?.birthday | date : 'longDate'
              }}</span>
            </div>
            <div *ngIf="!contact.birthday" style="margin-bottom: 10px">
              <b>Birthday: </b><span [style.color]="'#999999'">--</span>
            </div>
          </div>
        </div>
        <div *ngIf="contact.address" style="padding-bottom: 10px">
          <b>Address: </b>
          <span [style.color]="'#999999'" *ngIf="contact.address.addressLine1">
            {{ contact?.address?.addressLine1 }},
          </span>
          <span [style.color]="'#999999'" *ngIf="contact.address.addressLine2">
            {{ contact?.address?.addressLine2 }},
          </span>
          <span [style.color]="'#999999'" *ngIf="contact.address.city"
            >{{ contact?.address?.city }},</span
          >
          <span [style.color]="'#999999'" *ngIf="contact.address.state">
            {{ contact?.address?.state }}
          </span>
          <span [style.color]="'#999999'" *ngIf="contact.address.postCode">{{
            contact?.address?.postCode
          }}</span>
        </div>
        <div *ngIf="contact.notes">
          <b>Details: </b
          ><span class="details" [style.color]="'#999999'">{{ contact?.notes }}</span>
        </div>
        <div *ngIf="!contact.notes">
          <b>Details: </b><span [style.color]="'#999999'">--</span>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-progress-bar *ngIf="$pending | async" mode="indeterminate"></mat-progress-bar>
</div>
