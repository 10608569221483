import { CompanyLabelsActions, CompanyLabelsActionTypes } from './company-labels.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { LoadCompanyLabels } from 'src/app/store/system-settings/company-labels/company-labels.actions';
import { GenericMap } from 'src/app/models/generic-map';
import { LabelGroup } from 'src/app/models/label-group';

export interface CompanyLabelsState {
  pending: boolean;
  error: HttpErrorResponse;
  data: GenericMap<LabelGroup>;
  selectedCompanyLabelId: number;
}

export const initialState: CompanyLabelsState = {
  pending: false,
  data: {},
  error: undefined,
  selectedCompanyLabelId: undefined
};

export function companyLabelsReducer(
  state = initialState,
  action: CompanyLabelsActions
): CompanyLabelsState {
  switch (action.type) {
    case CompanyLabelsActionTypes.SelectCompanyLabelId:
      return {
        ...state,
        selectedCompanyLabelId: action.payload
      };

    case CompanyLabelsActionTypes.ClearCompanyLabelId:
      return {
        ...state,
        selectedCompanyLabelId: undefined
      };

    case CompanyLabelsActionTypes.LoadCompanyLabels:
      return {
        ...state,
        pending: true
      };

    case CompanyLabelsActionTypes.LoadCompanyLabelsSuccess:
      return {
        ...state,
        pending: false,
        data: action.payload
      };

    case CompanyLabelsActionTypes.LoadCompanyLabelsFailure:
      return {
        ...state,
        pending: false,
        data: {},
        error: action.payload
      };

    case CompanyLabelsActionTypes.CreateCompanyLabels:
      return {
        ...state,
        pending: true
      };

    case CompanyLabelsActionTypes.CreateCompanyLabelsSuccess:
      return {
        ...state,
        pending: false
      };

    case CompanyLabelsActionTypes.CreateCompanyLabelsFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case CompanyLabelsActionTypes.UpdateCompanyLabelsSuccess:
      return {
        ...state,
        pending: false
      };

    case CompanyLabelsActionTypes.UpdateCompanyLabelsFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case CompanyLabelsActionTypes.DeleteCompanyLabels:
      return {
        ...state,
        pending: true
      };

    case CompanyLabelsActionTypes.DeleteCompanyLabelsSuccess:
      return {
        ...state,
        pending: false,
        selectedCompanyLabelId: undefined
      };

    case CompanyLabelsActionTypes.DeleteCompanyLabelsFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    default:
      return state;
  }
}
