import { RootState } from '../../store.reducer';
import { MemoizedSelector, createSelector } from '@ngrx/store';
import { GenericMap } from '../../../models/generic-map';
import { ItemCustomField } from 'src/app/models/Opportunity/item-custom-field';
import { selectAllFieldTypes } from '../../system-settings/field-types/selectors/field-types.selector';
import { FieldType } from 'src/app/models/call-notes/field-type';

export const selectItemCustomFields = (state: RootState): GenericMap<ItemCustomField> =>
  state.opportunities.customItemFields.data;

export const selectItemCustomFieldsAsArray: MemoizedSelector<
  RootState,
  ItemCustomField[]
> = createSelector(
  selectItemCustomFields,
  selectAllFieldTypes,
  (
    itemCFs: GenericMap<ItemCustomField>,
    types: GenericMap<FieldType>
  ): ItemCustomField[] => {
    return Object.values(types).length
      ? Object.values(itemCFs).map((cf: ItemCustomField) => ({
          ...cf,
          typeName: types[cf.typeId] ? types[cf.typeId].name : 'No type name'
        }))
      : [];
  }
);
