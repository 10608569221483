import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { AuthenticationService } from '../authentication.service';
import { CommentV3 } from './../../models/call-notes/comment-V3';

@Injectable()
export class CallNoteCommentService {
	constructor(private authService: AuthenticationService) { }

	getCallNoteComments(callNoteId: number): Promise<CommentV3[]> {
		return this.authService.makeJpiRequest('/callnotes/' + callNoteId + '/comments', null, null, 'get').then((result) => {
			return JSON.parse(result);
		});
	}

	getCallNoteCommentById(callNoteId: number, comment: CommentV3): Promise<CommentV3> {
		return this.authService.makeJpiRequest('/callnotes/' + callNoteId + '/comments/' + comment.id, null, null, 'get').then((result) => {
			return JSON.parse(result);
		});
	}

	addCallNoteComment(callNoteId: number, comment: CommentV3): Promise<CommentV3> {
		(<any>comment).createdDate = moment(new Date(Date.now())).format('YYYY-MM-DDTHH:mm:ss');
		return this.authService.makeJpiRequest('/callnotes/' + callNoteId + '/comments', null, comment, 'post').then((result) => {
			return JSON.parse(result);
		});
	}

	updateCallNoteComment(callNoteId: number, comment: CommentV3): Promise<CommentV3> {
		return this.authService.makeJpiRequest('/callnotes/' + callNoteId + '/comments/' + comment.id, null, comment, 'put').then((result) => {
			return JSON.parse(result);
		});
	}

	deleteCallNoteComment(callNoteId: number, comment: CommentV3): Promise<CommentV3> {
		return this.authService.makeJpiRequest('/callnotes/' + callNoteId + '/comments/' + comment.id, null, comment, 'delete').then((result) => {
			return JSON.parse(result);
		});
	}
}
