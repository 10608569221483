import { Inject, Component } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RootState } from 'src/app/store/store.reducer';
import { Store } from '@ngrx/store';
import { CallNote } from 'src/app/models/call-notes/call-note';
import { DeleteCallNote } from 'src/app/store/call-notes/call-notes.actions';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'company-call-note-dialog',
  templateUrl: 'company-call-note-dialog.component.html'
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CompanyCallNoteDialog {
  constructor(
    public dialogRef: MatDialogRef<CompanyCallNoteDialog>,
    private store: Store<RootState>,
    @Inject(MAT_DIALOG_DATA) public data: CallNote
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  deleteCallNote() {
    this.store.dispatch(new DeleteCallNote(this.data));
    this.dialogRef.close();
  }
}
