import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { LabelGroup } from 'src/app/models/label-group';

export enum CompanyLabelsActionTypes {
  SelectCompanyLabelId = '[CompanyLabels] Select CompanyLabel ID',
  ClearCompanyLabelId = '[CompanyLabels] Clear Selected CompanyLabel ID',
  LoadCompanyLabels = '[CompanyLabels] Load CompanyLabels',
  LoadCompanyLabelsSuccess = '[CompanyLabels] Load CompanyLabels Success',
  LoadCompanyLabelsFailure = '[CompanyLabels] Load CompanyLabels Failure',
  CreateCompanyLabels = '[CompanyLabels] Create CompanyLabels',
  CreateCompanyLabelsSuccess = '[CompanyLabels] Create CompanyLabels Success',
  CreateCompanyLabelsFailure = '[CompanyLabels] Create CompanyLabels Failure',
  UpdateCompanyLabels = '[CompanyLabels] Update CompanyLabels',
  UpdateCompanyLabelsSuccess = '[CompanyLabels] Update CompanyLabels Success',
  UpdateCompanyLabelsFailure = '[CompanyLabels] Update CompanyLabels Failure',
  DeleteCompanyLabels = '[CompanyLabels] Delete CompanyLabels',
  DeleteCompanyLabelsSuccess = '[CompanyLabels] Delete CompanyLabels Success',
  DeleteCompanyLabelsFailure = '[CompanyLabels] Delete CompanyLabels Failure'
}

export class SelectCompanyLabelId implements Action {
  readonly type = CompanyLabelsActionTypes.SelectCompanyLabelId;
  constructor(public payload: number) {}
}

export class ClearCompanyLabelId implements Action {
  readonly type = CompanyLabelsActionTypes.ClearCompanyLabelId;
}

export class LoadCompanyLabels implements Action {
  readonly type = CompanyLabelsActionTypes.LoadCompanyLabels;
}

export class LoadCompanyLabelsSuccess implements Action {
  readonly type = CompanyLabelsActionTypes.LoadCompanyLabelsSuccess;
  constructor(public payload: LabelGroup[]) {}
}

export class LoadCompanyLabelsFailure implements Action {
  readonly type = CompanyLabelsActionTypes.LoadCompanyLabelsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class CreateCompanyLabels implements Action {
  readonly type = CompanyLabelsActionTypes.CreateCompanyLabels;
  constructor(public payload: LabelGroup) {}
}

export class CreateCompanyLabelsSuccess implements Action {
  readonly type = CompanyLabelsActionTypes.CreateCompanyLabelsSuccess;
  constructor(public payload: LabelGroup) {}
}

export class CreateCompanyLabelsFailure implements Action {
  readonly type = CompanyLabelsActionTypes.CreateCompanyLabelsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class UpdateCompanyLabels implements Action {
  readonly type = CompanyLabelsActionTypes.UpdateCompanyLabels;
  constructor(public payload: LabelGroup) {}
}

export class UpdateCompanyLabelsSuccess implements Action {
  readonly type = CompanyLabelsActionTypes.UpdateCompanyLabelsSuccess;
  constructor(public payload: LabelGroup) {}
}

export class UpdateCompanyLabelsFailure implements Action {
  readonly type = CompanyLabelsActionTypes.UpdateCompanyLabelsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class DeleteCompanyLabels implements Action {
  readonly type = CompanyLabelsActionTypes.DeleteCompanyLabels;
  constructor(public payload: number) {}
}

export class DeleteCompanyLabelsSuccess implements Action {
  readonly type = CompanyLabelsActionTypes.DeleteCompanyLabelsSuccess;
  constructor(public payload: number) {}
}

export class DeleteCompanyLabelsFailure implements Action {
  readonly type = CompanyLabelsActionTypes.DeleteCompanyLabelsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export type CompanyLabelsActions =
  | SelectCompanyLabelId
  | ClearCompanyLabelId
  | LoadCompanyLabels
  | LoadCompanyLabelsSuccess
  | LoadCompanyLabelsFailure
  | CreateCompanyLabels
  | CreateCompanyLabelsSuccess
  | CreateCompanyLabelsFailure
  | UpdateCompanyLabels
  | UpdateCompanyLabelsSuccess
  | UpdateCompanyLabelsFailure
  | DeleteCompanyLabels
  | DeleteCompanyLabelsSuccess
  | DeleteCompanyLabelsFailure;
