<div *ngIf="callNote.id">
    <div class="white-padding" fxLayout="row">
        <div fxFlex="4"></div>
        <div class="client-input edit-company-dropdown input-padding row-click popup-expanded-row" fxLayout="row"
            (click)="expandCommentRow()" fxFlex>
            <div *ngIf="callNote.comments" fxFlex>Comments&nbsp;({{callNote.comments.length}})</div>
            <div *ngIf="!callNote.comments" fxFlex>Comments</div>
            <mat-icon *ngIf="!commentsExpanded" class="icon-large">arrow_drop_down</mat-icon>
            <mat-icon *ngIf="commentsExpanded" class="icon-large">arrow_drop_up</mat-icon>
        </div>
        <div fxFlex="4"></div>
    </div>
    <div *ngIf="commentsExpanded">
        <div *ngFor="let comment of callNote.comments">
            <div class="client-input input-padding" fxLayout="row">
                <div fxFlex="4"></div>
                <div fxLayout="column" fxFlex>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div> {{comment.createdBy.firstName}}&nbsp;{{comment.createdBy.lastName}} </div>
                        <div> {{comment.createdDate | date: 'mediumDate'}}&nbsp; {{comment.createdDate
                            | date: 'shortTime'}}</div>
                        <div fxLayout="row" fxLayoutAlign="end center">
                            <div *ngIf="editCallNotesFeature==1 && (comment.createdBy.id==agentId)" (click)="editComment(comment)"
                                style="cursor:pointer;">Edit&nbsp;&nbsp;</div>
                            <div *ngIf="editCallNotesFeature==1 && (comment.createdBy.id==agentId)" (click)="deleteComment(comment)"
                                style="cursor:pointer;">Delete</div>
                        </div>
                    </div>
                    <dx-text-area id="{{comment.id}}" [(value)]="comment.value" [autoResizeEnabled]="true" [disabled]="comment.disabled"></dx-text-area>
                    <div *ngIf="!comment.disabled" fxLayout="row">
                        <div (click)="cancelComment(comment)" style="cursor:pointer;">Cancel&nbsp;&nbsp;</div>
                        <div (click)="saveComment(comment)" style="cursor:pointer;">Save</div>
                    </div>
                </div>
                <div fxFlex="4"></div>
            </div>
        </div>
        <div *ngIf="addNewComment">
            <div class="client-input input-padding" fxLayout="row">
                <div fxFlex="4"></div>
                <div fxLayout="column" fxFlex>
                    <span>New Comment</span>
                    <dx-text-area [(value)]="newComment.value" [autoResizeEnabled]="true" (onInitialized)="setNewCommentFocus($event)"></dx-text-area>
                    <div fxLayout="row">
                        <span fxFlex></span>
                        <span fxLayoutAlign="end center" (click)="cancelNewComment(newComment)" style="cursor:pointer;">Cancel&nbsp;&nbsp;</span>
                        <span (click)="saveComment(newComment)" style="cursor:pointer;">Save</span>
                    </div>
                </div>
                <div fxFlex="4"></div>
            </div>
        </div>
    </div>
</div>