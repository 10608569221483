import { Pipe, PipeTransform } from '@angular/core';
import { formatAddressIntoOneLine } from 'src/app/utils/formatAddressIntoOneLine';
import { Address } from 'src/app/models/address';

@Pipe({
  name: 'formatAddress'
})
export class FormatAddressPipe implements PipeTransform {
  transform(value: Address): any {
    if (typeof value === 'string') {
      return value;
    } else {
      return formatAddressIntoOneLine(value);
    }
  }
}
