export interface FieldTypeV2 {
    id: number;
    name: string;
    deleted: boolean;
    // deletedDate: Date;
    companyField: boolean;
    contactField: boolean;
    callNoteField: boolean;
}

export enum FieldTypeEnum {
    Text = 1,
    Checkbox = 2,
    Money = 4,
    Dropdown = 5,
    Date = 6,
    Address = 8,
    PhoneNumber = 9,
    Email = 10,
    Website = 11,
    Manufacturers = 12
}
