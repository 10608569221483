import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ShowReportsBy } from 'src/app/models/sales/show-reports-by.enum';
import { SalesSummaryType } from 'src/app/models/sales/sales-summary-type.enum';
import { Observable } from 'rxjs';
import { SalesSummaryRow } from 'src/app/models/sales/sales-summary-row';
import { removeNullPropsImmutable } from 'src/app/utils/removeNullProps';
import { SalesSummaryRequest } from 'src/app/models/sales/sales-summary-req';

@Injectable({
  providedIn: 'root'
})
export class SalesService {
  private jpiUrl = environment.jpiUrl;
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getCompanyAssignments(companyId: number) {
    return this.http.get(
      this.apiUrl + '/api-v1.2.1/company/getCompanyAssignment?companyId=' + companyId
    );
  }

  setCompanyAssignment(
    companyId: number,
    agentMailboxId: number,
    associateSales: boolean
  ) {
    const sQS =
      '/api-v1.2.1/company/setCompanyAssignment?companyId=' +
      companyId +
      '&agentMailboxId=' +
      agentMailboxId +
      '&associateSales=' +
      associateSales;
    return this.http.post(this.apiUrl + sQS, null);
  }

  getSalesReports(req: SalesSummaryRequest): Observable<SalesSummaryRow[]> {
    const queryParams = new URLSearchParams(
      removeNullPropsImmutable({
        type: req.type,
        showBy: req.showBy,
        month: req.month.toString(),
        year: req.year.toString(),
        companyId: !!req.companyId ? req.companyId.toString() : null,
        repId: !!req.repId ? req.repId.toString() : null,
        searchTerm: req.searchTerm
      })
    ).toString();

    return this.http.get(
      `${this.jpiUrl}/salessummaries/v2?${queryParams.toString()}`
    ) as Observable<SalesSummaryRow[]>;
  }
}
