import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { concatMap, map, catchError } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import {
  ProductLinesActionTypes,
  ProductLinesActions,
  LoadProductLinesSuccess,
  LoadProductLinesFailure
} from './product-lines.actions';
import { ProductLinesService } from 'src/app/services/product-lines/product-lines.service';
import { ProductLine } from 'src/app/models/product-lines/product-line';
import { convertToMap } from 'src/app/utils/convertToMap';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ProductLinesEffects {
  constructor(
    private actions$: Actions<ProductLinesActions>,
    private plService: ProductLinesService
  ) {}

  loadProductLines$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductLinesActionTypes.LoadProductLines),
      concatMap(() =>
        this.plService.getProductLines().pipe(
          map(
            (res: ProductLine[]) => new LoadProductLinesSuccess(convertToMap(res, 'id'))
          ),
          catchError((error: HttpErrorResponse) => of(new LoadProductLinesFailure(error)))
        )
      )
    )
  );
}
