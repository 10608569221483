<div class='panel-padding' fxLayout="column">
    <div class="loading-container" *ngIf="loadingSales" fxFlex fxLayout="column" fxLayoutAlign="center center">
        <dx-load-indicator id="medium-indicator" height="40" width="40"></dx-load-indicator>
        <h3>Loading...</h3>
    </div>
    <div fxLayout="row" *ngIf="!loadingSales" style="padding-top: 10px;">
        <div fxFlex="80">
            <dx-chart [dataSource]="sales.Months">
                <dxi-series *ngFor="let year of sales.Years" [valueField]="year.value" [name]="year.name"></dxi-series>
                <dxo-common-series-settings #seriesSettings argumentField="month" [type]="month"></dxo-common-series-settings>
                <dxo-margin [bottom]="20"></dxo-margin>
                <dxo-argument-axis [valueMarginsEnabled]="false" discreteAxisDivisionMode="crossLabels">
                    <dxo-grid [visible]="true"></dxo-grid>
                </dxo-argument-axis>
                <dxo-title text="Total Sales"></dxo-title>
                <dxo-export [enabled]="true"></dxo-export>
                <dxo-tooltip [enabled]="true"></dxo-tooltip>
            </dx-chart>
        </div>
        <div fxLayout="column" fxFlex>
            <div class="sales-card">
                <div class="card-header">
                    Total Sales
                </div>
                <div class="card-body">
                    <div fxLayout="row">
                        <span fxFlex="50">This Month</span>
                        <span *ngIf="sales.SalesThisMonth">{{sales.SalesThisMonth |
                            currency:'USD':'symbol-narrow':'0.2-2'
                            }}
                        </span>
                    </div>
                    <div fxLayout="row">
                        <span fxFlex="50">This Month Last Year</span>
                        <span *ngIf="sales.SalesThisMonthLastYear">{{sales.SalesThisMonthLastYear
                            |
                            currency:'USD':'symbol-narrow':'0.2-2'
                            }}
                        </span>
                    </div>
                    <div fxLayout="row">
                        <span fxFlex="50">Last Year Total</span>
                        <span *ngIf="sales.LastYearTotal">{{sales.LastYearTotal |
                            currency:'USD':'symbol-narrow':'0.2-2'
                            }}
                        </span>
                    </div>
                    <div fxLayout="row">
                        <span fxFlex="50">Last Year to Date</span>
                        <span *ngIf="sales.LastYearToDate">{{sales.LastYearToDate |
                            currency:'USD':'symbol-narrow':'0.2-2'
                            }}
                        </span>
                    </div>
                    <div fxLayout="row">
                        <span fxFlex="50">This Year to Date</span>
                        <span *ngIf="sales.YearToDate">{{sales.YearToDate |
                            currency:'USD':'symbol-narrow':'0.2-2'
                            }}</span>
                    </div>
                    <div fxLayout="row">
                        <span fxFlex="50">YTD Change</span>
                        <span *ngIf="sales.YearToDateChange">{{sales.YearToDateChange |
                            currency:'USD':'symbol-narrow':'0.2-2'
                            }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div fxLayout="row" *ngIf="!loadingSales">
        <div class="sales-card" fxLayout="column" fxFlex="20" *ngFor="let manufacturerSales of sales.Manufacturers">
            <div class="card-header">
                <span [innerText]="manufacturerSales.ManufacturerName"></span>
            </div>
            <div class="card-body">
                <div fxLayout="row">
                    <span fxFlex="50">This Month</span>
                    <span *ngIf="manufacturerSales.SalesThisMonth">{{manufacturerSales.SalesThisMonth
                        |
                        currency:'USD':'symbol-narrow':'0.2-2'
                        }}
                    </span>
                </div>
                <div fxLayout="row">
                    <span fxFlex="50">This Month Last Year</span>
                    <span *ngIf="manufacturerSales.SalesThisMonthLastYear">
                        {{manufacturerSales.SalesThisMonthLastYear |
                        currency:'USD':'symbol-narrow':'0.2-2'}}
                    </span>
                </div>
                <div fxLayout="row">
                    <span fxFlex="50">Last Year Total</span>
                    <span *ngIf="manufacturerSales.LastYearTotal">{{manufacturerSales.LastYearTotal
                        |
                        currency:'USD':'symbol-narrow':'0.2-2'
                        }}
                    </span>
                </div>
                <div fxLayout="row">
                    <span fxFlex="50">Last Year to Date</span>
                    <span *ngIf="manufacturerSales.LastYearToDate">{{manufacturerSales.LastYearToDate
                        |
                        currency:'USD':'symbol-narrow':'0.2-2'
                        }}
                    </span>
                </div>
                <div fxLayout="row">
                    <span fxFlex="50">This Year to Date</span>
                    <span *ngIf="manufacturerSales.YearToDate">{{manufacturerSales.YearToDate
                        |
                        currency:'USD':'symbol-narrow':'0.2-2'
                        }}
                    </span>
                </div>
                <div fxLayout="row">
                    <span fxFlex="50">YTD Change</span>
                    <span *ngIf="manufacturerSales.YearToDateChange">{{manufacturerSales.YearToDateChange
                        |
                        currency:'USD':'symbol-narrow':'0.2-2'
                        }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>