import { Pipe, PipeTransform } from '@angular/core';
import { Company } from 'src/app/models/companies/company';

@Pipe({
  name: 'companiesFilter'
})
export class CompaniesFilterPipe implements PipeTransform {
  transform(companies: Company[], searchText: string): Company[] {
    searchText = searchText ? searchText.toLowerCase() : null;
    return !!companies && companies.length > 0
      ? companies.filter((company) => {
          return !!searchText
            ? company.name.toLowerCase().match(searchText) ||
                Object.values(company.shippingAddress)
                  .join(', ')
                  .toLowerCase()
                  .match(searchText) ||
                Object.values(company.billingAddress)
                  .join(', ')
                  .toLowerCase()
                  .match(searchText) ||
                company.contacts
                  .map((contact) =>
                    !!contact.name
                      ? contact.name
                      : contact.firstName + ' ' + contact.lastName
                  )
                  .join(', ')
                  .toLowerCase()
                  .match(searchText)
            : true;
        })
      : [];
  }
}
