import { MfaDialogComponent } from '../../view/pages/auth/mfa-dialog/mfa-dialog.component';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  tap,
  mergeMap,
  withLatestFrom,
  concatMap,
  switchMap
} from 'rxjs/operators';
import { of, forkJoin } from 'rxjs';
import {
  LoadAuthFailure,
  LoadApiSuccess,
  LoadJpiSuccess,
  AuthActionTypes,
  AuthActions,
  AuthenticationRedirect,
  LoadAuthComplete,
  SetInflightUrl,
  DeleteAuthObjects,
  SignInAsOther,
  SignInAsOtherSuccess,
  SignInAsOtherFailure,
  LoginRedirect,
  LoadMFA,
  ClearState
} from './auth.actions';
import { AuthService } from '../../services/auth/auth.service';
import { Router } from '@angular/router';
import { RootState } from '../store.reducer';
import { Store } from '@ngrx/store';
import { convertToMap } from 'src/app/utils/convertToMap';
import { Feature } from 'src/app/app-v2/shared/models/admin/feature';
import { AuthData } from './auth.reducer';
import { ClearUsers } from '../users/users.actions';
import { PermissionsService } from 'src/app/services/permissions/permissions.service';
import { JpiAuthResponse } from 'src/app/models/auth/jpi-auth-response';
import { AuthenticationResponse } from 'src/app/models/auth/authentication-response';
import { CloseDrawer } from '../layout/layout.actions';
import {
  SetCallFieldIds,
  SetCallTypeIds,
  SetLabelGroupIds,
  SetTerritoryIds,
  SetUserIds
} from '../dashboard/dashboard.actions';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions<AuthActions>,
    private authService: AuthService,
    private router: Router,
    private permService: PermissionsService,
    private store$: Store<RootState>,
    public dialog: MatDialog
  ) {}

  loadAuth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.LoadAuth),
      mergeMap((action) => {
        return this.authService
          .apiLogin(action.payload.mailbox, action.payload.password)
          .pipe(
            mergeMap((res) =>
              forkJoin([
                of(res),
                this.authService.jpiLogin(action.payload.mailbox, action.payload.password)
              ])
            ),
            mergeMap(
              ([apiResult, jpiResult]: [AuthenticationResponse, JpiAuthResponse]) => {
                if (!apiResult.UseNewTelenotes) {
                  this.authService.deleteCookies();
                  this.store$.dispatch(new DeleteAuthObjects());
                  window.location.href =
                    'https://telenotes.net/AccountInfo.aspx?SNID=' + apiResult.Snid;
                }

                const data: AuthData = {
                  api: this.convertRolesToMap(apiResult),
                  jpi: jpiResult
                };
                // clean default route
                if (!!data.api.DefaultUrl && data.api.DefaultUrl.startsWith('/')) {
                  data.api.DefaultUrl = data.api.DefaultUrl.substr(
                    1,
                    data.api.DefaultUrl.length
                  );
                }
                this.authService.deleteCookies();
                this.authService.setCookies(data);
                if (data.jpi.token === 'SUCCESS') {
                  return [
                    new LoadMFA({ apiData: data.api, jpiData: data.jpi }),
                    new LoadApiSuccess(data.api)
                  ];
                } else {
                  return [
                    new LoadApiSuccess(data.api),
                    new LoadJpiSuccess(data.jpi),
                    new ClearUsers(),
                    new LoadAuthComplete()
                  ];
                }
              }
            ),
            catchError((error) => {
              return of(new LoadAuthFailure(error));
            })
          );
      })
    )
  );

  loadMFA$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.LoadMFA),
      concatMap((action: any) => {
        return this.authService.getMFA(action.payload.jpiData.user).pipe(
          mergeMap((res: any) => {
            this.dialog.open(MfaDialogComponent, {
              data: {
                apiData: action.payload.apiData,
                jpiData: action.payload.jpiData
              }
            });
            return [];
          })
        );
      }),
      catchError((error) => {
        return of(new LoadAuthFailure(error));
      })
    )
  );

  sendMFA$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.SendMFA),
      concatMap((action: any) => {
        return this.authService
          .getMFAToken(action.payload.mfa, action.payload.data.jpiData.user)
          .pipe(
            mergeMap((res: any) => {
              return [new LoadJpiSuccess(res), new ClearUsers(), new LoadAuthComplete()];
            })
          );
      }),
      catchError((error) => {
        return of(new LoadAuthFailure(error));
      })
    )
  );

  authComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.LoadAuthComplete),
      map(() => new AuthenticationRedirect())
    )
  );

  loginRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.LoginRedirect),
        tap(() => {
          this.router.navigate(['/auth']);
          return new ClearState();
        })
      ),
    { dispatch: false }
  );

  clearState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.ClearState),
      switchMap(() => {
        return [
          new SetUserIds([]),
          new SetTerritoryIds([]),
          new SetCallTypeIds([]),
          new SetCallFieldIds([]),
          new SetLabelGroupIds([])
        ];
      })
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.Logout),
      switchMap(() => {
        this.authService.logoutFetch();
        window.localStorage.removeItem('jpiReturn');
        this.store$.dispatch(new SetInflightUrl('/'));
        this.authService.deleteCookies();
        return [new LoginRedirect()];
      })
    )
  );

  authenticatedRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.AuthenticationRedirect),
        withLatestFrom(this.store$),
        map((actionAndStore) => ({
          action: actionAndStore[0],
          store: actionAndStore[1]
        })),
        tap(({ store }) => {
          if (store.auth.data.api.UseNewTelenotes) {
            window.location.href =
              window.location.protocol +
              '//' +
              window.location.host +
              store.auth.inflightUrl;
          } else {
            window.location.href =
              'https://telenotes.net/AccountInfo.aspx?SNID=' + store.auth.data.api.Snid;
          }
        })
      ),
    { dispatch: false }
  );

  reauthenticate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.Reauthenticate),
      withLatestFrom(this.store$.select('router')),
      map((actionAndRouterState) => {
        let inflightUrl = actionAndRouterState[1].state.url;
        if (/login/.test(inflightUrl) || /auth/.test(inflightUrl)) {
          inflightUrl = '/';
        }
        return new SetInflightUrl(inflightUrl);
      }),
      tap(() => {
        this.authService.deleteCookies();
        // this.router.navigate(['/auth']);
        window.location.href =
          window.location.protocol + '//' + window.location.host + '/auth/login';
      })
    )
  );

  signInAsOther$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.SignInAsOther),
      concatMap((action: SignInAsOther) => {
        return this.authService.signInAsOther(action.payload).pipe(
          mergeMap((res: AuthData) => {
            this.authService.deleteCookies();
            this.authService.setCookies(res);
            return [
              new CloseDrawer(),
              new SignInAsOtherSuccess({
                api: this.convertRolesToMap(res.api),
                jpi: res.jpi
              })
            ];
          }),
          catchError((error) => of(new SignInAsOtherFailure(error)))
        );
      })
    )
  );

  convertRolesToMap(apiResult: AuthenticationResponse): AuthenticationResponse {
    if (!!apiResult.Role && !!apiResult.Role.Features) {
      apiResult.Role.Features = convertToMap(
        (apiResult.Role.Features as []).map((feature: Feature) => {
          return {
            ...feature,
            Permissions: convertToMap(feature.Permissions as [], 'Id')
          };
        }),
        'Id'
      );
    }
    return apiResult;
  }
}
