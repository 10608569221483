import { Action } from '@ngrx/store';

export enum UserSettingsActionTypes {
  LoadUserSettings = '[UserSettings] Load UserSettings',
  LoadUserSettingsSuccess = '[UserSettings] Load UserSettings Success',
  LoadUserSettingsFailure = '[UserSettings] Load UserSettings Failure'
}

export class LoadUserSettings implements Action {
  readonly type = UserSettingsActionTypes.LoadUserSettings;
}

export class LoadUserSettingsSuccess implements Action {
  readonly type = UserSettingsActionTypes.LoadUserSettingsSuccess;
  constructor(public payload: { data: any }) {}
}

export class LoadUserSettingsFailure implements Action {
  readonly type = UserSettingsActionTypes.LoadUserSettingsFailure;
  constructor(public payload: { error: any }) {}
}

export type UserSettingsActions =
  | LoadUserSettings
  | LoadUserSettingsSuccess
  | LoadUserSettingsFailure;
