import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Lead } from 'src/app/models/leads/lead';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LeadService {
  jpiUrl = environment.jpiUrl;

  constructor(private http: HttpClient) {}

  createLead(lead: Lead) {
    return this.http.post(`${this.jpiUrl}/lead`, lead);
  }
}
