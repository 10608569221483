import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ColumnHeader } from 'src/app/models/column-header';
import { GenericMap } from 'src/app/models/generic-map';
import { Project } from 'src/app/models/projects/project';
import { ProjectBid } from 'src/app/models/projects/project-bid';
import { FileMetaData } from 'src/app/models/file-meta-data';
import { ProjectEstimate } from 'src/app/models/projects/project-estimate';
import { ProjectTableSelect } from 'src/app/models/projects/project-table-select.enum';
import { ApiProjectCustomFieldValue } from 'src/app/models/projects/api-project-custom-field-value';
import { ApiProjectCompanyCustomFieldValue } from 'src/app/models/projects/api-project-company-custom-field-value';
import { ApiProjectManufCustomFieldValue } from 'src/app/models/projects/api-project-manuf-custom-field-value';
import { ApiBidCustomFieldValue } from 'src/app/models/projects/api-bid-custom-field-value';

export enum ProjectsActionTypes {
  AddLabelColumns = '[Projects] Add Label Columns',
  LabelColumnsAdded = '[Projects] Label Columns Added',
  LabelColumnAdded = '[Projects] Label Column Added',
  NoLabelColsAdded = '[Projects] No Label Columns Added',

  SelectProjTableColumns = '[Projects] Select Project Table Columns',
  SelectManufTableColumns = '[Projects] Select Manufacturer Table Columns',
  SelectCompTableColumns = '[Projects] Select Company Table Columns',
  SelectBidTableColumns = '[Projects] Select Bid Table Columns',

  LoadProjects = '[Projects] Load Projects',
  LoadProjectsSuccess = '[Projects] Load Projects Success',
  LoadProjectsFailure = '[Projects] Load Projects Failure',

  LoadProject = '[Projects] Load Project',
  LoadProjectSuccess = '[Projects] Load Project Success',
  LoadProjectFailure = '[Projects] Load Project Failure',

  RedirectToProjects = '[Projects] Redirect To Projects',
  RedirectToProject = '[Projects] Redirect To Project',

  LoadProjectsTableColumns = '[Projects] Load Projects Table Columns',
  LoadProjectsTableColumnsSuccess = '[Projects] Load Projects Table Columns Success',
  LoadProjectsTableColumnsFailure = '[Projects] Load Projects Table Columns Failure',

  SaveProject = '[Projects] Save Project',
  SaveProjectSuccess = '[Projects] Save Project Success',
  SaveProjectFailure = '[Projects] Save Project Failure',

  DeleteProject = '[Projects] Delete Project',
  DeleteProjectSuccess = '[Projects] Delete Project Success',
  DeleteProjectSuccessNoRedirect = '[Projects] Delete Project Success No Redirect',
  DeleteProjectFailure = '[Projects] Delete Project Failure',

  LoadProjectBids = '[Projects] Load Project Bids',
  LoadProjectBidsSuccess = '[Projects] Load Project Bids Success',
  LoadProjectBidsFailure = '[Projects] Load Project Bids Failure',

  LoadProjectBidsSummary = '[Projects] Load Project Bids Summary',
  LoadProjectBidsSummarySuccess = '[Projects] Load Project Bids Summary Success',
  LoadProjectBidsSummaryFailure = '[Projects] Load Project Bids Summary Failure',

  SaveProjectBid = '[Projects] Save Project Bid',
  SaveProjectBidSuccess = '[Projects] Save Project Bid Success',
  SaveProjectBidFailure = '[Projects] Save Project Bid Failure',

  DeleteProjectBid = '[Projects] Delete Project Bid',
  DeleteProjectBidSuccess = '[Projects] Delete Project Bid Success',
  DeleteProjectBidFailure = '[Projects] Delete Project Bid Failure',

  UploadProjectFile = '[Projects] Upload Project File',
  UploadProjectFileSuccess = '[Projects] Upload Project File Success',
  UploadProjectFileFailure = '[Projects] Upload Project File Failure',

  DeleteProjectFile = '[Projects] Delete Project File',
  DeleteProjectFileSuccess = '[Projects] Delete Project File Success',
  DeleteProjectFileFailure = '[Projects] Delete Project File Failure',

  DeleteMultipleProjectBidsByCompany = '[Projects] Delete Multiple Project Bids By Company',
  DeleteMultipleProjectBidsByCompanySuccess = '[Projects] Delete Multiple Project Bids By Company Success',

  SaveProjectEstimate = '[Projects] Save Project Estimate',
  SaveProjectEstimateSuccess = '[Projects] Save Project Estimate Success',
  SaveProjectEstimateFailure = '[Projects] Save Project Estimate Failure',

  DeleteProjectEstimate = '[Projects] Delete Project Estimate',
  DeleteProjectEstimateSuccess = '[Projects] Delete Project Estimate Success',
  DeleteProjectEstimateFailure = '[Projects] Delete Project Estimate Failure',

  SaveProjectCustomFieldValue = '[Projects] Save Project Custom Field Value',
  SaveProjectCustomFieldValueSuccess = '[Projects] Save Project Custom Field Value Success',
  SaveProjectCustomFieldValueFailure = '[Projects] Save Project Custom Field Value Failure',

  DeleteProjectCustomFieldValue = '[Projects] Delete Project Custom Field Value',
  DeleteProjectCustomFieldValueSuccess = '[Projects] Delete Project Custom Field Value Success',
  DeleteProjectCustomFieldValueFailure = '[Projects] Delete Project Custom Field Value Failure',

  SaveProjectCompanyCustomFieldValue = '[Projects] Save Project Company Custom Field Value',
  SaveProjectCompanyCustomFieldValueSuccess = '[Projects] Save Project Company Custom Field Value Success',
  SaveProjectCompanyCustomFieldValueFailure = '[Projects] Save Project Company Custom Field Value Failure',

  DeleteProjectCompanyCustomFieldValue = '[Projects] Delete Project Company Custom Field Value',
  DeleteProjectCompanyCustomFieldValueSuccess = '[Projects] Delete Project Company Custom Field Value Success',
  DeleteProjectCompanyCustomFieldValueFailure = '[Projects] Delete Project Company Custom Field Value Failure',

  SaveProjectManufCustomFieldValue = '[Projects] Save Project Manuf Custom Field Value',
  SaveProjectManufCustomFieldValueSuccess = '[Projects] Save Project Manuf Custom Field Value Success',
  SaveProjectManufCustomFieldValueFailure = '[Projects] Save Project Manuf Custom Field Value Failure',

  DeleteProjectManufCustomFieldValue = '[Projects] Delete Project Manuf Custom Field Value',
  DeleteProjectManufCustomFieldValueSuccess = '[Projects] Delete Project Manuf Custom Field Value Success',
  DeleteProjectManufCustomFieldValueFailure = '[Projects] Delete Project Manuf Custom Field Value Failure',

  SaveBidCustomFieldValue = '[Projects] Save Bid Custom Field Value',
  SaveBidCustomFieldValueSuccess = '[Projects] Save Bid Custom Field Value Success',
  SaveBidCustomFieldValueFailure = '[Projects] Save Bid Custom Field Value Failure',

  DeleteBidCustomFieldValue = '[Projects] Delete Bid Custom Field Value',
  DeleteBidCustomFieldValueSuccess = '[Projects] Delete Bid Custom Field Value Success',
  DeleteBidCustomFieldValueFailure = '[Projects] Delete Bid Custom Field Value Failure',

  SelectProjectTable = '[Projects] Select Project Table',

  SaveCompanyLinkedProject = '[Projects] Save Company-Linked Project',
  SaveCompanyLinkedProjectSuccess = '[Projects] Save Company-Linked Project Success',
  SaveCompanyLinkedProjectFailure = '[Projects] Save Company-Linked Project Failure'
}

export class AddLabelColumns implements Action {
  readonly type = ProjectsActionTypes.AddLabelColumns;
}

export class LabelColumnAdded implements Action {
  readonly type = ProjectsActionTypes.LabelColumnAdded;
  constructor(public payload: ColumnHeader) {}
}

export class LabelColumnsAdded implements Action {
  readonly type = ProjectsActionTypes.LabelColumnsAdded;
  constructor(public payload: ColumnHeader[]) {}
}

export class NoLabelColsAdded implements Action {
  readonly type = ProjectsActionTypes.NoLabelColsAdded;
}

export class SelectProjTableColumns implements Action {
  readonly type = ProjectsActionTypes.SelectProjTableColumns;
  constructor(public payload: ColumnHeader[]) {}
}

export class SelectManufTableColumns implements Action {
  readonly type = ProjectsActionTypes.SelectManufTableColumns;
  constructor(public payload: ColumnHeader[]) {}
}

export class SelectCompTableColumns implements Action {
  readonly type = ProjectsActionTypes.SelectCompTableColumns;
  constructor(public payload: ColumnHeader[]) {}
}

export class SelectBidTableColumns implements Action {
  readonly type = ProjectsActionTypes.SelectBidTableColumns;
  constructor(public payload: ColumnHeader[]) {}
}

export class LoadProjects implements Action {
  readonly type = ProjectsActionTypes.LoadProjects;
}

export class LoadProjectsSuccess implements Action {
  readonly type = ProjectsActionTypes.LoadProjectsSuccess;
  constructor(public payload: GenericMap<Project>) {}
}

export class LoadProjectsFailure implements Action {
  readonly type = ProjectsActionTypes.LoadProjectsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadProject implements Action {
  readonly type = ProjectsActionTypes.LoadProject;
  constructor(public payload: number) {}
}

export class LoadProjectSuccess implements Action {
  readonly type = ProjectsActionTypes.LoadProjectSuccess;
  constructor(public payload: Project) {}
}

export class LoadProjectFailure implements Action {
  readonly type = ProjectsActionTypes.LoadProjectFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class RedirectToProjects implements Action {
  readonly type = ProjectsActionTypes.RedirectToProjects;
}

export class RedirectToProject implements Action {
  readonly type = ProjectsActionTypes.RedirectToProject;
  constructor(public payload: number) {}
}

export class LoadProjectsTableColumns implements Action {
  readonly type = ProjectsActionTypes.LoadProjectsTableColumns;
}

export class LoadProjectsTableColumnsSuccess implements Action {
  readonly type = ProjectsActionTypes.LoadProjectsTableColumnsSuccess;
  constructor(public payload: ColumnHeader[]) {}
}

export class LoadProjectsTableColumnsFailure implements Action {
  readonly type = ProjectsActionTypes.LoadProjectsTableColumnsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class SaveProject implements Action {
  readonly type = ProjectsActionTypes.SaveProject;
  constructor(public payload: Project) {}
}

export class SaveProjectSuccess implements Action {
  readonly type = ProjectsActionTypes.SaveProjectSuccess;
  constructor(public payload: Project) {}
}

export class SaveProjectFailure implements Action {
  readonly type = ProjectsActionTypes.SaveProjectFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class DeleteProject implements Action {
  readonly type = ProjectsActionTypes.DeleteProject;
  constructor(public payload: { id: number; redirect: boolean }) {}
}

export class DeleteProjectSuccess implements Action {
  readonly type = ProjectsActionTypes.DeleteProjectSuccess;
}

export class DeleteProjectSuccessNoRedirect implements Action {
  readonly type = ProjectsActionTypes.DeleteProjectSuccessNoRedirect;
}

export class DeleteProjectFailure implements Action {
  readonly type = ProjectsActionTypes.DeleteProjectFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadProjectBids implements Action {
  readonly type = ProjectsActionTypes.LoadProjectBids;
  constructor(public payload: Project) {}
}

export class LoadProjectBidsSuccess implements Action {
  readonly type = ProjectsActionTypes.LoadProjectBidsSuccess;
  constructor(public payload: { project: Project; bidsMap: GenericMap<ProjectBid> }) {}
}

export class LoadProjectBidsFailure implements Action {
  readonly type = ProjectsActionTypes.LoadProjectBidsFailure;
  constructor(public payload: { project: Project; error: HttpErrorResponse }) {}
}

export class LoadProjectBidsSummary implements Action {
  readonly type = ProjectsActionTypes.LoadProjectBidsSummary;
  constructor(public payload: Project) {}
}

export class LoadProjectBidsSummarySuccess implements Action {
  readonly type = ProjectsActionTypes.LoadProjectBidsSummarySuccess;
  constructor(public payload: any) {}
}

export class LoadProjectBidsSummaryFailure implements Action {
  readonly type = ProjectsActionTypes.LoadProjectBidsSummaryFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class SaveProjectBid implements Action {
  readonly type = ProjectsActionTypes.SaveProjectBid;
  constructor(public payload: { bid: ProjectBid; project: Project }) {}
}

export class SaveProjectBidSuccess implements Action {
  readonly type = ProjectsActionTypes.SaveProjectBidSuccess;
  constructor(public payload: ProjectBid) {}
}

export class SaveProjectBidFailure implements Action {
  readonly type = ProjectsActionTypes.SaveProjectBidFailure;
  constructor(public payload: { bid: ProjectBid; error: HttpErrorResponse }) {}
}

export class DeleteProjectBid implements Action {
  readonly type = ProjectsActionTypes.DeleteProjectBid;
  constructor(public payload: { bid: ProjectBid; project: Project }) {}
}

export class DeleteProjectBidSuccess implements Action {
  readonly type = ProjectsActionTypes.DeleteProjectBidSuccess;
  constructor(public payload: ProjectBid) {}
}

export class DeleteProjectBidFailure implements Action {
  readonly type = ProjectsActionTypes.DeleteProjectBidFailure;
  constructor(public payload: { bid: ProjectBid; error: HttpErrorResponse }) {}
}

export class UploadProjectFile implements Action {
  readonly type = ProjectsActionTypes.UploadProjectFile;
  constructor(public payload: Blob) {}
}

export class UploadProjectFileSuccess implements Action {
  readonly type = ProjectsActionTypes.UploadProjectFileSuccess;
  constructor(public payload: FileMetaData) {}
}

export class UploadProjectFileFailure implements Action {
  readonly type = ProjectsActionTypes.UploadProjectFileFailure;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class DeleteProjectFile implements Action {
  readonly type = ProjectsActionTypes.DeleteProjectFile;
  constructor(public payload: number) {}
}

export class DeleteProjectFileSuccess implements Action {
  readonly type = ProjectsActionTypes.DeleteProjectFileSuccess;
  constructor(public payload: FileMetaData) {}
}

export class DeleteProjectFileFailure implements Action {
  readonly type = ProjectsActionTypes.DeleteProjectFileFailure;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class DeleteMultipleProjectBidsByCompany implements Action {
  readonly type = ProjectsActionTypes.DeleteMultipleProjectBidsByCompany;
  constructor(
    public payload: { project: Project; companyId: number; bids: ProjectBid[] }
  ) {}
}

export class DeleteMultipleProjectBidsByCompanySuccess implements Action {
  readonly type = ProjectsActionTypes.DeleteMultipleProjectBidsByCompanySuccess;
  constructor(public payload: { projectId: number; companyId: number }) {}
}

export class SaveProjectEstimate implements Action {
  readonly type = ProjectsActionTypes.SaveProjectEstimate;
  constructor(public payload: ProjectEstimate) {}
}

export class SaveProjectEstimateSuccess implements Action {
  readonly type = ProjectsActionTypes.SaveProjectEstimateSuccess;
}

export class SaveProjectEstimateFailure implements Action {
  readonly type = ProjectsActionTypes.SaveProjectEstimateFailure;
  constructor(
    public payload: { projectEstimate: ProjectEstimate; error: HttpErrorResponse }
  ) {}
}

export class DeleteProjectEstimate implements Action {
  readonly type = ProjectsActionTypes.DeleteProjectEstimate;
  constructor(public payload: ProjectEstimate) {}
}

export class DeleteProjectEstimateSuccess implements Action {
  readonly type = ProjectsActionTypes.DeleteProjectEstimateSuccess;
}

export class DeleteProjectEstimateFailure implements Action {
  readonly type = ProjectsActionTypes.DeleteProjectEstimateFailure;
  constructor(
    public payload: { projectEstimate: ProjectEstimate; error: HttpErrorResponse }
  ) {}
}

export class SaveProjectCustomFieldValue implements Action {
  readonly type = ProjectsActionTypes.SaveProjectCustomFieldValue;
  constructor(public payload: ApiProjectCustomFieldValue) {}
}

export class SaveProjectCustomFieldValueSuccess implements Action {
  readonly type = ProjectsActionTypes.SaveProjectCustomFieldValueSuccess;
  constructor(public payload: ApiProjectCustomFieldValue) {}
}

export class SaveProjectCustomFieldValueFailure implements Action {
  readonly type = ProjectsActionTypes.SaveProjectCustomFieldValueFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class DeleteProjectCustomFieldValue implements Action {
  readonly type = ProjectsActionTypes.DeleteProjectCustomFieldValue;
  constructor(public payload: ApiProjectCustomFieldValue) {}
}

export class DeleteProjectCustomFieldValueSuccess implements Action {
  readonly type = ProjectsActionTypes.DeleteProjectCustomFieldValueSuccess;
}

export class DeleteProjectCustomFieldValueFailure implements Action {
  readonly type = ProjectsActionTypes.DeleteProjectCustomFieldValueFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class SaveProjectCompanyCustomFieldValue implements Action {
  readonly type = ProjectsActionTypes.SaveProjectCompanyCustomFieldValue;
  constructor(public payload: ApiProjectCompanyCustomFieldValue) {}
}

export class SaveProjectCompanyCustomFieldValueSuccess implements Action {
  readonly type = ProjectsActionTypes.SaveProjectCompanyCustomFieldValueSuccess;
  constructor(public payload: ApiProjectCompanyCustomFieldValue) {}
}

export class SaveProjectCompanyCustomFieldValueFailure implements Action {
  readonly type = ProjectsActionTypes.SaveProjectCompanyCustomFieldValueFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class DeleteProjectCompanyCustomFieldValue implements Action {
  readonly type = ProjectsActionTypes.DeleteProjectCompanyCustomFieldValue;
  constructor(public payload: ApiProjectCompanyCustomFieldValue) {}
}

export class DeleteProjectCompanyCustomFieldValueSuccess implements Action {
  readonly type = ProjectsActionTypes.DeleteProjectCompanyCustomFieldValueSuccess;
}

export class DeleteProjectCompanyCustomFieldValueFailure implements Action {
  readonly type = ProjectsActionTypes.DeleteProjectCompanyCustomFieldValueFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class SaveProjectManufCustomFieldValue implements Action {
  readonly type = ProjectsActionTypes.SaveProjectManufCustomFieldValue;
  constructor(public payload: ApiProjectManufCustomFieldValue) {}
}

export class SaveProjectManufCustomFieldValueSuccess implements Action {
  readonly type = ProjectsActionTypes.SaveProjectManufCustomFieldValueSuccess;
  constructor(public payload: ApiProjectManufCustomFieldValue) {}
}

export class SaveProjectManufCustomFieldValueFailure implements Action {
  readonly type = ProjectsActionTypes.SaveProjectManufCustomFieldValueFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class DeleteProjectManufCustomFieldValue implements Action {
  readonly type = ProjectsActionTypes.DeleteProjectManufCustomFieldValue;
  constructor(public payload: ApiProjectManufCustomFieldValue) {}
}

export class DeleteProjectManufCustomFieldValueSuccess implements Action {
  readonly type = ProjectsActionTypes.DeleteProjectManufCustomFieldValueSuccess;
}

export class DeleteProjectManufCustomFieldValueFailure implements Action {
  readonly type = ProjectsActionTypes.DeleteProjectManufCustomFieldValueFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class SaveBidCustomFieldValue implements Action {
  readonly type = ProjectsActionTypes.SaveBidCustomFieldValue;
  constructor(public payload: ApiBidCustomFieldValue) {}
}

export class SaveBidCustomFieldValueSuccess implements Action {
  readonly type = ProjectsActionTypes.SaveBidCustomFieldValueSuccess;
  constructor(public payload: ApiBidCustomFieldValue) {}
}

export class SaveBidCustomFieldValueFailure implements Action {
  readonly type = ProjectsActionTypes.SaveBidCustomFieldValueFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class DeleteBidCustomFieldValue implements Action {
  readonly type = ProjectsActionTypes.DeleteBidCustomFieldValue;
  constructor(public payload: ApiBidCustomFieldValue) {}
}

export class DeleteBidCustomFieldValueSuccess implements Action {
  readonly type = ProjectsActionTypes.DeleteBidCustomFieldValueSuccess;
}

export class DeleteBidCustomFieldValueFailure implements Action {
  readonly type = ProjectsActionTypes.DeleteBidCustomFieldValueFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class SelectProjectTable implements Action {
  readonly type = ProjectsActionTypes.SelectProjectTable;
  constructor(public payload: ProjectTableSelect) {}
}

export class SaveCompanyLinkedProject implements Action {
  readonly type = ProjectsActionTypes.SaveCompanyLinkedProject;
  constructor(
    public payload: { project: Project; companyID: number; companyName: string }
  ) {}
}

export class SaveCompanyLinkedProjectSuccess implements Action {
  readonly type = ProjectsActionTypes.SaveCompanyLinkedProjectSuccess;
  constructor(public payload: Project) {}
}

export class SaveCompanyLinkedProjectFailure implements Action {
  readonly type = ProjectsActionTypes.SaveCompanyLinkedProjectFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export type ProjectsActions =
  | AddLabelColumns
  | LabelColumnsAdded
  | LabelColumnAdded
  | NoLabelColsAdded
  | SelectProjTableColumns
  | SelectManufTableColumns
  | SelectCompTableColumns
  | SelectBidTableColumns
  | LoadProjects
  | LoadProjectsSuccess
  | LoadProjectsFailure
  | LoadProject
  | LoadProjectSuccess
  | LoadProjectFailure
  | RedirectToProjects
  | RedirectToProject
  | LoadProjectsTableColumns
  | LoadProjectsTableColumnsSuccess
  | LoadProjectsTableColumnsFailure
  | SaveProject
  | SaveProjectSuccess
  | SaveProjectFailure
  | DeleteProject
  | DeleteProjectSuccess
  | DeleteProjectSuccessNoRedirect
  | DeleteProjectFailure
  | LoadProjectBids
  | LoadProjectBidsSuccess
  | LoadProjectBidsFailure
  | LoadProjectBidsSummary
  | LoadProjectBidsSummarySuccess
  | LoadProjectBidsSummaryFailure
  | SaveProjectBid
  | SaveProjectBidSuccess
  | SaveProjectBidFailure
  | DeleteProjectBid
  | DeleteProjectBidSuccess
  | DeleteProjectBidFailure
  | UploadProjectFile
  | UploadProjectFileSuccess
  | UploadProjectFileFailure
  | DeleteProjectFile
  | DeleteProjectFileSuccess
  | DeleteProjectFileFailure
  | DeleteMultipleProjectBidsByCompany
  | DeleteMultipleProjectBidsByCompanySuccess
  | SaveProjectEstimate
  | SaveProjectEstimateSuccess
  | SaveProjectEstimateFailure
  | DeleteProjectEstimate
  | DeleteProjectEstimateSuccess
  | DeleteProjectEstimateFailure
  | SelectProjectTable
  | SaveProjectCustomFieldValue
  | SaveProjectCustomFieldValueSuccess
  | SaveProjectCustomFieldValueFailure
  | DeleteProjectCustomFieldValue
  | DeleteProjectCustomFieldValueSuccess
  | DeleteProjectCompanyCustomFieldValueFailure
  | SaveProjectCompanyCustomFieldValue
  | SaveProjectCompanyCustomFieldValueSuccess
  | SaveProjectCompanyCustomFieldValueFailure
  | DeleteProjectCompanyCustomFieldValue
  | DeleteProjectCompanyCustomFieldValueSuccess
  | DeleteProjectCompanyCustomFieldValueFailure
  | SaveProjectManufCustomFieldValue
  | SaveProjectManufCustomFieldValueSuccess
  | SaveProjectManufCustomFieldValueFailure
  | DeleteProjectManufCustomFieldValue
  | DeleteProjectManufCustomFieldValueSuccess
  | DeleteProjectManufCustomFieldValueFailure
  | SaveBidCustomFieldValue
  | SaveBidCustomFieldValueSuccess
  | SaveBidCustomFieldValueFailure
  | DeleteBidCustomFieldValue
  | DeleteBidCustomFieldValueSuccess
  | DeleteBidCustomFieldValueFailure
  | SaveCompanyLinkedProject
  | SaveCompanyLinkedProjectSuccess
  | SaveCompanyLinkedProjectFailure;
