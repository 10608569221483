import { Injectable } from '@angular/core';
import { ServicesModule } from '../services.module';
import { environment } from 'src/environments/environment';
import { CallNote, CallNoteAudio } from 'src/app/models/call-notes/call-note';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalFilter } from 'src/app/models/global-filter';
import { JpiResponse } from 'src/app/models/http/jpi-response.model';
import { Field } from 'src/app/models/call-notes/field';
import { Comment } from 'src/app/models/call-notes/comment';

@Injectable({
  providedIn: ServicesModule
})
export class CallNotesService {
  private jpiUrl = environment.jpiUrl;
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getCallNotes(
    filter: GlobalFilter,
    params: { limit: string; order?: string; skip?: string }
  ): Observable<JpiResponse<CallNote>> {
    return this.http.post(`${this.jpiUrl}/callnotes/search`, filter, {
      params
    }) as Observable<JpiResponse<CallNote>>;
  }

  getCallNote(callNoteId: number): Observable<CallNote> {
    return this.http.get(
      this.jpiUrl + '/callnotes/' + callNoteId
    ) as Observable<CallNote>;
  }

  create(callNote: CallNote): Observable<CallNote> {
    return this.http.post(this.jpiUrl + '/callnotes', callNote) as Observable<CallNote>;
  }

  update(callNote: CallNote): Observable<CallNote> {
    return this.http.put(
      this.jpiUrl + '/callnotes/' + callNote.id,
      callNote
    ) as Observable<CallNote>;
  }

  delete(callNoteId: number): Observable<any> {
    return this.http.delete(this.jpiUrl + '/callnotes/' + callNoteId) as Observable<any>;
  }

  createAudio(payload: any): Observable<CallNoteAudio> {
    return this.http.post(
      this.apiUrl + '/api-v1.2.0/upload/audioFile?' + payload.params,
      payload.payload as FormData
    ) as Observable<CallNoteAudio>;
  }

  getCallNoteFieldsForCallNote(callNoteId: number) {
    return this.http.get(this.jpiUrl + '/callnotes/' + callNoteId + '/fields');
  }

  getCallNoteFieldByIdForCallNote(callNoteId: number, fieldId: number) {
    return this.http.get(this.jpiUrl + '/callnotes/' + callNoteId + '/fields/' + fieldId);
  }

  addCallNoteFieldForCallNote(callNoteId: number, field: Field) {
    return this.http.post(this.jpiUrl + '/callnotes/' + callNoteId + '/fields/', field);
  }

  updateCallNoteFieldForCallNote(callNoteId: number, field: Field) {
    return this.http.put(
      this.jpiUrl + '/callnotes/' + callNoteId + '/fields/' + field.id,
      field
    );
  }

  deleteCallNoteFieldForCallNote(callNoteId: number, fieldId: number) {
    return this.http.delete(
      this.jpiUrl + '/callnotes/' + callNoteId + '/fields/' + fieldId
    );
  }

  getCommentsForCallNote(callNoteId: number) {
    return this.http.get(this.jpiUrl + '/callnotes/' + callNoteId + '/comments');
  }

  getAudioForCallNote(callNoteId: number) {
    return this.http.get(this.jpiUrl + '/audio_upload/file/' + callNoteId, {
      responseType: 'blob'
    });
  }

  getFileByIdForCallNote(callNoteId: number) {
    return this.http.get(this.jpiUrl + '/files/callnote/' + callNoteId);
  }

  getCommentByIdForCallNote(callNoteId: number, commentId: number) {
    return this.http.get(
      this.jpiUrl + '/callnotes/' + callNoteId + '/comments/' + commentId
    );
  }

  addCommentForCallNote(callNoteId: number, comment: Partial<Comment>) {
    return this.http.post(
      this.jpiUrl + '/callnotes/' + callNoteId + '/comments/',
      comment
    );
  }

  updateCommentForCallNote(callNoteId: number, comment: Partial<Comment>) {
    return this.http.put(
      this.jpiUrl + '/callnotes/' + callNoteId + '/comments/' + comment.id,
      comment
    );
  }

  deleteCommentForCallNote(callNoteId: number, commentId: number) {
    return this.http.delete(
      this.jpiUrl + '/callnotes/' + callNoteId + '/comments/' + commentId
    );
  }
}
