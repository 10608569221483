import { Component, Input, OnInit, OnDestroy } from '@angular/core';

import { UserV2 } from './../../../../shared/models/admin/users/user-V2';
import { CompanyV2 } from './../../../../shared/models/companies/company-V2';
import { File } from './../../../../shared/models/files/file';
import { FileService } from './../../../../shared/services/file.service';
import { ToolbarService } from '../../../../shared/services/toolbar.service';
import { UserService } from '../../../../shared/services/user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'company-profile-files',
  templateUrl: './company-profile-files.component.html',
  styleUrls: ['../../companies.scss']
})
export class CompanyProfileFilesComponent implements OnInit, OnDestroy {
  @Input() companyJPI: CompanyV2;
  users: UserV2[];
  companyFiles: File[];
  file: File;
  fileUploadPopup: boolean;
  fileUploadPopup$: boolean;
  deleteFilePopup: boolean;
  loading: boolean;
  customFileSubscription: Subscription;

  constructor(
    private _fileService: FileService,
    private _toolbarService: ToolbarService,
    private _userService: UserService
  ) {}

  ngOnInit() {
    this._userService.getActive().then(users => {
      this.users = users;
      this._fileService.getFilesForCompany(this.companyJPI.id).then(results => {
        this.companyFiles = results;
        if (!!this.companyFiles.length) {
          return this.companyFiles.sort((a, b) => {
            const textA = a.UploadedDate;
            const textB = b.UploadedDate;
            return textB < textA ? -1 : textB > textA ? 1 : 0;
          });
        } else {
          [];
        }
        for (let i = 0; i < this.companyFiles.length; i++) {
          if (this.companyFiles[i].CategoryId == 0) {
            this.companyFiles[i].CategoryName = 'Miscellaneous';
          }
        }

        for (let i = 0; i < this.companyFiles.length; i++) {
          if (this.users) {
            for (let j = 0; j < this.users.length; j++) {
              if (this.users[j].id == this.companyFiles[i].uploadedBy) {
                this.companyFiles[i].uploadedUser =
                  this.users[j].firstName + ' ' + this.users[j].lastName;
                break;
              }
            }
          }
        }
        this.customFileSubscription = this._toolbarService.customFileUploadAnnounced.subscribe(
          result => {
            this.fileUploadPopup$ = result;
          }
        );
      });
    });
  }

  ngOnDestroy(): void {
    if (this.customFileSubscription) {
      this.customFileSubscription.unsubscribe();
    }
  }

  uploadNewFile(): void {
    this.file = Object.assign({});
    this.fileUploadPopup = true;
  }

  deleteFile(file: File): void {
    this.file = file;
    this.deleteFilePopup = true;
  }

  fileDeleted(): void {
    this.loading = true;
    this._fileService.deleteFile(this.file).then(() => {
      this.companyFiles = this.companyFiles.filter(file => file.Id != this.file.Id);
      this.loading = false;
      this.deleteFilePopup = false;
    });
  }

  downloadFile(file: File) {
    this._fileService.downloadFile(file);
  }
}
