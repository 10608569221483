import { Injectable } from '@angular/core';

import { MapsPermissions } from '../../models/admin/permission-enums';
import { FeaturePermissionsService } from './feature-permissions.service';
import { AuthenticationService } from '../authentication.service';

@Injectable()
export class MapsPermissionsService {

	constructor(private featurePermissionsService: FeaturePermissionsService, private authService: AuthenticationService) { }

	getRoutesPermission(): Promise<boolean> {
		return this.featurePermissionsService.getPermissionValue(this.authService.modules.Maps, MapsPermissions.Routes);
	}

	getLeadsPermission(): Promise<boolean> {
		return this.featurePermissionsService.getPermissionValue(this.authService.modules.Maps, MapsPermissions.Leads);
	}
}
