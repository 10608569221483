import { Injectable } from '@angular/core';

import { AppsPermissions } from '../../models/admin/permission-enums';
import { FeaturePermissionsService } from './feature-permissions.service';
import { AuthenticationService } from '../authentication.service';

@Injectable()
export class AppsPermissionsService {

	constructor(private featurePermissionsService: FeaturePermissionsService, private authService: AuthenticationService) { }

	getCompanyLabelsPermission(): Promise<number> {
		return this.featurePermissionsService.getPermissionValue(this.authService.modules.Apps, AppsPermissions.CompanyLabels).then(result => {
			return result;
		});
	}

	getCallTypesPermission(): Promise<boolean> {
		return this.featurePermissionsService.getPermissionValue(this.authService.modules.Apps, AppsPermissions.CallTypes);
	}

	getCallNoteCheckboxFieldsPermission(): Promise<boolean> {
		return this.featurePermissionsService.getPermissionValue(this.authService.modules.Apps, AppsPermissions.CallNoteCheckboxFields);
	}

	getAllowUploadingBusinessCardsPermission(): Promise<boolean> {
		return this.featurePermissionsService.getPermissionValue(this.authService.modules.Apps, AppsPermissions.AllowUploadingBusinessCards);
	}
}
