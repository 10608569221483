import { selectTerritoriesTree } from 'src/app/store/territory-management/selectors/territories-tree.selector';
import { Subscription } from 'rxjs/internal/Subscription';
import {
  Component,
  Injectable,
  Output,
  EventEmitter,
  Input,
  OnDestroy
} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { BehaviorSubject, Observable } from 'rxjs';
import { IdNameItem } from 'src/app/models/id-name-item';
import { RootState } from 'src/app/store/store.reducer';
import { Store } from '@ngrx/store';
import { GenericMap } from 'src/app/models/generic-map';
import { LoadTerritories } from 'src/app/store/territory-management/territory-management.actions';
import { UserBasic } from 'src/app/app-v2/shared/models/admin/users/user-basic';

/**
 * Node for item
 */
export class ItemNode {
  children: ItemNode[];
  storeno: number;
  label: string;
  id: number;
  isChecked: boolean;
  isPlanType: boolean;
  claimId: number;
  users: UserBasic[];
}

/** Flat item node with expandable and level information */
export class ItemFlatNode {
  label: string;
  storeno: number;
  level: number;
  expandable: boolean;
  id: number;
  isChecked: boolean;
  isPlanType: boolean;
  claimId: number;
  users: number[];
}

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable({ providedIn: 'any' })
export class ChecklistDatabase implements OnDestroy {
  dataChange = new BehaviorSubject<ItemNode[]>([]);
  $allTerritories: Observable<IdNameItem[]>;
  territories: Subscription;

  territoryMap: GenericMap<IdNameItem>;

  get data(): ItemNode[] {
    return this.dataChange.value;
  }

  constructor(private store: Store<RootState>) {
    this.initialize();
  }

  initialize() {
    this.store.dispatch(new LoadTerritories());
    this.$allTerritories = this.store.select(selectTerritoriesTree);
    this.territories = this.$allTerritories.subscribe((territories: IdNameItem[]) => {
      // Build the tree nodes from Json object. The result is a list of `ItemNode` with nested
      //     file node as children.
      const data = this.buildFileTree(territories, 0);

      // Notify the change.
      this.dataChange.next(data);
    });
  }

  ngOnDestroy(): void {
    this.territories.unsubscribe();
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `ItemNode`.
   */

  buildFileTree(obj: { [key: string]: any }, level: number): ItemNode[] {
    return Object.keys(obj).reduce<ItemNode[]>((accumulator, key) => {
      const item = obj[key];
      const node = new ItemNode();
      node.label = obj[key].name;
      node.id = obj[key].id;
      node.storeno = obj[key].storeno;
      node.isChecked = obj[key].isChecked || false;
      node.children = obj[key].subTerritories;
      node.users = obj[key].users;

      if (item != null) {
        if (typeof item === 'object' && item.subTerritories != undefined) {
          node.children = this.buildFileTree(item.subTerritories, level + 1);
        } else {
          node.label = item.name;
        }
      }
      return accumulator.concat(node);
    }, []);
  }
}

@Component({
  selector: 'tn-territories-select',
  templateUrl: './territories-select.component.html',
  styleUrls: ['./territories-select.component.scss']
})
export class TerritoriesSelectComponent {
  @Output() selectedItemsChange = new EventEmitter<IdNameItem[]>();
  @Input() filterLabel: string;

  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<ItemFlatNode, ItemNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<ItemNode, ItemFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: ItemFlatNode | null = null;

  treeControl: FlatTreeControl<ItemFlatNode>;

  treeFlattener: MatTreeFlattener<ItemNode, ItemFlatNode>;

  dataSource: MatTreeFlatDataSource<ItemNode, ItemFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<ItemFlatNode>(true /* multiple */);
  constructor(private _database: ChecklistDatabase) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.treeControl = new FlatTreeControl<ItemFlatNode>(
      this.getLevel,
      this.isExpandable
    );
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    _database.dataChange.subscribe((data) => {
      this.dataSource.data = data;
    });
  }

  getLevel = (node: ItemFlatNode) => node.level;

  isExpandable = (node: ItemFlatNode) => node.expandable;

  getChildren = (node: ItemNode): ItemNode[] => node.children;

  hasChild = (_: number, _nodeData: ItemFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: ItemFlatNode) => _nodeData.label === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: ItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.label === node.label
        ? existingNode
        : new ItemFlatNode();
    flatNode.label = node.label;
    flatNode.storeno = node.storeno;
    flatNode.level = level;
    flatNode.id = node.id;
    flatNode.isChecked = node.isChecked;
    flatNode.expandable = !!node.children;
    flatNode.users = node.users.map((user) => user.id);
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: ItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.checklistSelection.isSelected(child);
      });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: ItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the item selection. Select/deselect all the descendants node */
  itemSelectionToggle(node: ItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach((child) => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
    // @ts-ignore
    this.selectedItemsChange.emit(this.checklistSelection.selected);
  }

  /** Toggle a leaf item selection. Check all the parents to see if they changed */
  leafItemSelectionToggle(node: ItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
    // @ts-ignore
    this.selectedItemsChange.emit(this.checklistSelection.selected);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: ItemFlatNode): void {
    let parent: ItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: ItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.checklistSelection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: ItemFlatNode): ItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  getSelectedItems(): string {
    if (!this.checklistSelection.selected.length && !this.filterLabel)
      return 'Filter by territory assignments';
    if (!this.checklistSelection.selected.length && this.filterLabel)
      return this.filterLabel;
    return this.checklistSelection.selected.map((s) => s.label).join(', ');
  }
}
