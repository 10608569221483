import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { CallNote } from '../../models/call-notes/call-note';
import { CallNoteField } from '../../models/call-notes/call-note-field';
import { Comment } from '../../models/call-notes/comment';
import { CommentV2 } from '../../models/call-notes/comment-V2';
import { CallType } from '../../models/call-type';
import { CompanyList } from '../../models/companies/company-list';
import { Narrative } from '../../models/narrative';
import { PagedResultRequest } from '../../models/paged-result-request';
import { AuthenticationService } from '../authentication.service';

@Injectable()
export class CallNoteService {
  private tempNotes: { [companyId: number]: CallNote };
  private tempNarratives: Narrative[];
  private tempContacts: any;
  private tempComments: Comment[];
  private callNoteAnnouncer = new Subject<any>();
  public callNoteAnnounced = this.callNoteAnnouncer.asObservable();
  private callNoteDeletedAnnouncer = new Subject<any>();
  public callNoteDeletedAnnounced = this.callNoteDeletedAnnouncer.asObservable();
  private followUpsAnnouncer = new Subject<boolean>();
  public followUpsAnnounced = this.followUpsAnnouncer.asObservable();

  constructor(private authService: AuthenticationService) {}

  callNoteCreatedFromToolbar(callNote: any) {
    this.callNoteAnnouncer.next(null);
    this.callNoteAnnouncer.next(callNote);
  }

  callNoteDeletedOnProfile(callNoteId: any) {
    this.callNoteDeletedAnnouncer.next(null);
    this.callNoteDeletedAnnouncer.next(callNoteId);
  }

  clearCallNote() {
    this.callNoteAnnouncer.next(null);
  }

  updateCalendarFollowUps(boolean: boolean) {
    this.followUpsAnnouncer.next(null);
    this.followUpsAnnouncer.next(boolean);
  }

  getCallNotesByTaskDate(
    from: Date,
    to: Date,
    assignedAgentId: number
  ): Promise<CallNote[]> {
    return this.authService
      .makeRequest(
        '/api-v1.2.1/callnote/noteByTaskDate?from=' +
          from.toLocaleDateString() +
          '&to=' +
          to.toLocaleDateString() +
          '&assignedToId=' +
          assignedAgentId,
        null,
        'get'
      )
      .then(result => {
        return JSON.parse(result);
      });
  }

  getCallNotesLists(): Promise<CompanyList[]> {
    return this.authService
      .makeRequest('/api-v1.2.1/callnote/getCallNoteLists', null, 'get')
      .then(result => {
        return JSON.parse(result);
      });
  }

  createCallNoteList(list: CompanyList): Promise<number> {
    return this.authService
      .makeRequest('/api-v1.2.1/callnote/createList', list, 'post')
      .then(result => {
        return parseInt(result);
      });
  }

  updateCallNoteList(list: CompanyList): Promise<boolean> {
    return this.authService
      .makeRequest('/api-v1.2.1/callnote/updateList', list, 'post')
      .then(() => {
        return true;
      });
  }

  deleteCallNoteList(list: CompanyList): Promise<boolean> {
    return this.authService
      .makeRequest('/api-v1.2.1/callnote/deleteList', list, 'post')
      .then(() => {
        return true;
      });
  }

  getFollowUps(startDate: Date, endDate: Date): Promise<any[]> {
    return this.authService
      .makeRequest(
        '/api-v1.2.1/task/getByDateRange?from=' +
          startDate.toLocaleDateString() +
          '&to=' +
          endDate.toLocaleDateString(),
        null,
        'get'
      )
      .then(result => {
        return JSON.parse(result);
      });
  }

  getRecentCallNotesForCompany(companyId: number, limit: number): Promise<any> {
    return this.authService
      .makeRequest(
        '/api-v1.2.1/callnote/notes/getrecentforcompany?companyId=' +
          companyId +
          '&limit=' +
          limit,
        '',
        'get'
      )
      .then(result => {
        return result;
      });
  }

  getCallNoteCount(): Promise<number> {
    return this.authService
      .makeRequest('/api-v1.2.1/callnote/count', null, 'get')
      .then(result => {
        return parseInt(result);
      });
  }

  getCallNotes(request: PagedResultRequest): Promise<any> {
    return this.authService
      .makeRequest('/api-v1.2.1/callnote/notesPaged', request, 'post')
      .then(result => {
        return JSON.parse(result);
      });
  }

  getCallNotesForCompany(companyId: number, from: Date, to: Date): Promise<CallNote[]> {
    const fromString: string =
      from.getFullYear() + '-' + (from.getMonth() + 1) + '-' + from.getDate();
    const toString: string =
      to.getFullYear() + '-' + (to.getMonth() + 1) + '-' + to.getDate();

    return Promise.all([
      this.authService.makeRequest(
        '/api-v1.2.1/callnote/notes/getByCompanyId?id=' +
          companyId +
          '&from=' +
          fromString +
          '&to=' +
          toString,
        '',
        'get'
      ),
      this.authService.makeRequest(
        '/api-v1.2.1/callnote/narratives/getByCompanyId?id=' +
          companyId +
          '&from=' +
          fromString +
          '&to=' +
          toString,
        '',
        'get'
      ),
      this.authService.makeRequest(
        '/api-v1.2.1/callnote/comments/getByCompanyId?id=' +
          companyId +
          '&from=' +
          fromString +
          '&to=' +
          toString,
        '',
        'get'
      ),
      this.authService.makeRequest(
        '/api-v1.2.1/callnote/contacts/getByCompanyId?id=' +
          companyId +
          '&from=' +
          fromString +
          '&to=' +
          toString,
        '',
        'get'
      )
    ]).then((result: any) => {
      this.tempNotes = this.parseNoteCsv(result[0]);
      this.tempNarratives = this.parseNarrativeCsv(result[1]);
      this.tempComments = this.parseCommentCsv(result[2]);
      this.tempContacts = this.parseContactCsv(result[3]);

      let i = 0;

      for (i = 0; i < this.tempNarratives.length; i++) {
        if (
          this.tempNotes[this.tempNarratives[i].CallNoteId] &&
          !this.tempNotes[this.tempNarratives[i].CallNoteId].Narratives
        ) {
          this.tempNotes[this.tempNarratives[i].CallNoteId].Narratives = [];
        }
        if (
          this.tempNotes[this.tempNarratives[i].CallNoteId] &&
          this.tempNotes[this.tempNarratives[i].CallNoteId].Narratives
        ) {
          this.tempNotes[this.tempNarratives[i].CallNoteId].Narratives.push(
            this.tempNarratives[i]
          );
        }
      }

      for (i = 0; i < this.tempComments.length; i++) {
        if (
          this.tempNotes[this.tempComments[i].CallNoteId] &&
          !this.tempNotes[this.tempComments[i].CallNoteId].Comments
        ) {
          this.tempNotes[this.tempComments[i].CallNoteId].Comments = [];
        }
        if (
          this.tempNotes[this.tempComments[i].CallNoteId] &&
          this.tempNotes[this.tempComments[i].CallNoteId].Comments
        ) {
          this.tempNotes[this.tempComments[i].CallNoteId].Comments.push(
            this.tempComments[i]
          );
        }
      }

      for (i = 0; i < this.tempContacts.length; i++) {
        if (
          this.tempNotes[this.tempContacts[i].CallNoteId] &&
          !this.tempNotes[this.tempContacts[i].CallNoteId].ContactIds
        ) {
          this.tempNotes[this.tempContacts[i].CallNoteId].ContactIds = [];
        }
        if (
          this.tempNotes[this.tempContacts[i].CallNoteId] &&
          this.tempNotes[this.tempContacts[i].CallNoteId].ContactIds
        ) {
          this.tempNotes[this.tempContacts[i].CallNoteId].ContactIds.push(
            this.tempContacts[i].ContactId
          );
        }
      }

      const notes = this.convertToArray(this.tempNotes);
      for (const note of notes) {
        note.AssignedAgentId = parseInt(note.AssignedAgentId.toString());
        note.CreatedAgentId = parseInt(note.CreatedAgentId.toString());
        note.CallTypeId = parseInt(note.CallTypeId.toString());
        for (let contact of note.ContactIds) {
          contact = parseInt(contact.toString());
        }
      }

      delete this.tempNotes;
      delete this.tempContacts;
      delete this.tempNarratives;
      delete this.tempComments;

      return notes.sort(
        (note1: CallNote, note2: CallNote) =>
          <any>note2.CreatedDate - <any>note1.CreatedDate
      );
    });
  }

  getNarrativeTypes(): Promise<Narrative[]> {
    return this.authService
      .makeRequest('/api-v1.2.1/callnote/getNarrativeTypes', '', 'get')
      .then((result: any) => {
        return this.parseNarrativeTypeCsv(result);
      });
  }

  getCallnoteFields(): Promise<CallNoteField[]> {
    return this.authService
      .makeRequest('/api-v1.2.1/callnote/getCallNoteFields', '', 'get')
      .then((result: any) => {
        return JSON.parse(result);
      });
  }

  setCallnoteField(cnf): Promise<number> {
    return this.authService
      .makeRequest('/api-v1.2.1/callnote/setCallNoteField', cnf, 'post')
      .then((result: any) => {
        return result;
      });
  }

  deleteCallNoteField(cnf): Promise<boolean> {
    return this.authService
      .makeRequest('/api-v1.2.1/callnote/deleteCallNoteField', cnf, 'post')
      .then(() => {
        return true;
      });
  }

  getCallTypes(): Promise<CallType[]> {
    return this.authService
      .makeRequest('/api-v1.2.1/callnote/getCallTypes', '', 'get')
      .then((result: any) => {
        return this.parseCallTypeCsv(result);
      });
  }

  addNewCallNote(callNote: CallNote): Promise<number> {
    return this.authService
      .makeRequest('/api-v1.2.1/callnote/add', callNote, 'post')
      .then(result => {
        return result;
      });
  }

  updateCallNote(callNote: CallNote): Promise<boolean> {
    return this.authService
      .makeRequest('/api-v1.2.1/callnote/edit', callNote, 'post')
      .then((result: any) => {
        return true;
      });
  }

  deleteCallNote(callNote: CallNote): Promise<boolean> {
    return this.authService
      .makeRequest('/api-v1.2.1/callnote/delete', callNote, 'post')
      .then((result: any) => {
        return true;
      });
  }

  addNewComment(comment: CommentV2): Promise<any> {
    return this.authService
      .makeRequest('/api-v1.2.1/callnote/createComment', comment, 'post')
      .then(result => {
        return result;
      });
  }

  updateComment(comment: CommentV2): Promise<boolean> {
    return this.authService
      .makeRequest('/api-v1.2.1/callnote/updateComment', comment, 'post')
      .then((result: any) => {
        return result;
      });
  }

  deleteComment(comment: CommentV2): Promise<boolean> {
    return this.authService
      .makeRequest('/api-v1.2.1/callnote/deleteComment', comment, 'post')
      .then((result: any) => {
        return result;
      });
  }

  convertToArray(notes: { [companyId: number]: CallNote }): CallNote[] {
    const tempArr: CallNote[] = [];
    const keys: any = Object.keys(notes);

    for (let i = 0; i < keys.length; i++) {
      tempArr.push(notes[keys[i]]);
    }
    return tempArr;
  }

  parseNoteCsv(csvString: string): { [key: number]: CallNote } {
    const temp: any[] = csvString.split('\n');
    const notes: { [key: number]: CallNote } = {};

    let i = 1;
    for (i = 1; i < temp.length; i++) {
      const data: any[] = temp[i].split('\xa7');

      if (data.length > 1) {
        const note: CallNote = <CallNote>{};

        note.CallNoteId = data[0];
        note.AssignedAgentId = data[1];
        note.AssignedAgentName = data[2];
        note.CreatedAgentId = data[3];
        note.CreatedAgentName = data[4];
        note.CallTypeId = data[5];
        note.CallType = data[6];
        note.CompanyId = data[7];
        note.CreatedDate = new Date(data[8]);
        note.CreatedDateString = note.CreatedDate.toLocaleDateString();
        note.LastModified = new Date(data[9]);
        note.FollowUpTask = data[10];
        note.FollowUpDueDate = new Date(data[11]);
        note.FollowUpEndDate = new Date(data[12]);
        note.FollowUpComplete = data[13].toLowerCase() == 'true';
        note.ContactIds = [];

        notes[data[0]] = note;
      }
    }

    return notes;
  }

  parseNarrativeCsv(csvString: string): Narrative[] {
    const temp: any[] = csvString.split('\n');
    const narratives: Narrative[] = [];

    let i = 1;
    for (i = 1; i < temp.length; i++) {
      const data: any[] = temp[i].split('\xa7');

      if (data.length > 1) {
        const narrative: Narrative = <Narrative>{};

        narrative.CallNoteId = data[0];
        narrative.NarrativeId = data[1];
        narrative.Narrative = data[2];
        narrative.NarrativeType = data[3];

        narratives.push(narrative);
      }
    }

    return narratives;
  }

  parseCommentCsv(csvString: string): Comment[] {
    const temp: any[] = csvString.split('\n');
    const comments: Comment[] = [];

    let i = 1;
    for (i = 1; i < temp.length; i++) {
      const data: any[] = temp[i].split('\xa7');

      if (data.length > 1) {
        const comment: Comment = <Comment>{};

        comment.CallNoteId = data[0];
        comment.CommentId = data[1];
        comment.CommentContent = data[2];
        comment.CreatedDate = new Date(data[3]);
        comment.AgentId = data[4];

        comments.push(comment);
      }
    }

    return comments;
  }

  parseContactCsv(csvString: string): any {
    const temp: any[] = csvString.split('\n');
    const contacts: any = [];

    let i = 1;
    for (i = 1; i < temp.length; i++) {
      const data: any[] = temp[i].split('\xa7');

      if (data.length > 1) {
        const con: any = {};

        con.CallNoteId = data[0];
        con.ContactId = data[1];

        contacts.push(con);
      }
    }

    return contacts;
  }

  parseCallTypeCsv(csvString: string): CallType[] {
    const temp: any[] = csvString.split('\n');
    const callTypes: CallType[] = [];

    let i = 1;
    for (i = 1; i < temp.length; i++) {
      const data: any[] = temp[i].split('\xa7');

      if (data.length > 1) {
        const type: CallType = <CallType>{};
        let shouldPush: boolean = true;

        type.CallTypeId = data[0];
        type.CallType = data[1];

        let j = 0;
        for (j = 0; j < callTypes.length; j++) {
          if (
            callTypes[j].CallType.toLowerCase() == type.CallType.toLowerCase() ||
            !type.CallType
          ) {
            shouldPush = false;
            break;
          }
        }

        if (shouldPush) {
          callTypes.push(type);
        }
      }
    }

    return callTypes;
  }

  parseNarrativeTypeCsv(csvString: string): Narrative[] {
    const temp: any[] = csvString.split('\n');
    const narrativeTypes: Narrative[] = [];

    let i = 1;
    for (i = 1; i < temp.length; i++) {
      const data: any[] = temp[i].split('\xa7');

      if (data.length > 1) {
        const type: Narrative = <Narrative>{};
        let shouldPush: boolean = true;

        type.NarrativeTypeId = data[0];
        type.NarrativeType = data[1];

        let j = 0;
        for (j = 0; j < narrativeTypes.length; j++) {
          if (
            narrativeTypes[j].NarrativeType.toLowerCase() ==
              type.NarrativeType.toLowerCase() ||
            !type.NarrativeType
          ) {
            shouldPush = false;
            break;
          }
        }

        if (shouldPush) {
          narrativeTypes.push(type);
        }
      }
    }

    return narrativeTypes;
  }
}
