import { Injectable } from '@angular/core';

import { AuthenticationService } from '../authentication.service';
import { AgentDetails } from '../../models/agent-details';
import { Territory, TerritoryAssignment, TerritoryType, TerritoryWReps } from '../../models/admin/territories/territory';

@Injectable()
export class RegionService {
	constructor(private authService: AuthenticationService) { }

	clearServiceData(): void {
	}

	getAgentDetails(): Promise<AgentDetails[]> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/agentDetails/GetAll?agentDetails=', '', 'get').then(function (result: any) {
			return JSON.parse(result);
		});
	}

	getAgentAllDetails(): Promise<AgentDetails[]> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/agentDetails/GetAgentsAll', '', 'get').then(function (result: any) {
			return JSON.parse(result);
		});
	}

	getRegionsByAgent(): Promise<TerritoryWReps[]> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/region/GetAllbyAgentId', '', 'get').then(function (result: any) {
			return JSON.parse(result);
		});
	}

	getRegions(): Promise<Territory[]> {
		return this.authService.makeRequest('/api-v1.2.1/region/GetAll', '', 'get').then(function (result: any) {
			return JSON.parse(result);
		});
	}

	getClientRegions(clientId: number): Promise<Territory[]> {
		return this.authService.makeRequest('/api-v1.2.1/region/GetAll?agencyId=' + clientId, '', 'get').then(function (result: any) {
			return JSON.parse(result);
		});
	}

	updateItem(list: Territory): Promise<boolean> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/region/update', list, 'post').then(function (result: any) {
			return true;
		});
	}

	addItem(reg: Territory): Promise<number> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/region/add', reg, 'post').then(function (result: any) {
			return result;
		});
	}

	disableRegion(regionID: number): Promise<boolean> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/region/disable?regionID=' + regionID, '', 'post').then(function (result: any) {
			return result;
		});
	}

	archiveItem(ItemID: number): Promise<boolean> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/region/archiveItem?regionID=' + ItemID, '', 'post').then(function (result: any) {
			return true;
		});
	}

	getTerrAsign(): Promise<TerritoryAssignment[]> {
		// return Promise.resolve(null);
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/region/getRepTer', '', 'get').then(function (result: any) {
			return JSON.parse(result);
		});
	}

	getTypes(): Promise<TerritoryType[]> {
		// return Promise.resolve(null);
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/region/getTypes', '', 'get').then(function (result: any) {
			return JSON.parse(result);
		});
	}

	addType(type: TerritoryType): Promise<number> {
		// return Promise.resolve(null);
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/region/addType', type, 'post').then(function (result: any) {
			return result[0];
		});
	}

	updateType(type: TerritoryType): Promise<number> {
		// return Promise.resolve(null);
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/region/updateType', type, 'post').then(function (result: any) {
			return result[0];
		});
	}

	deleteType(typeid: number): Promise<number> {
		// return Promise.resolve(null);
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/region/deleteType?typeId=' + typeid, '', 'post').then(function (result: any) {
			return result;
		});
	}

	addRepTer(agentId: number, regionId: number): Promise<boolean> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/region/setRepTer?regionId=' + regionId + '&agentId=' + agentId, '', 'post').then(function (result: any) {
			return result;
		});
	}

	remRepTer(agentId: number, regionId: number): Promise<boolean> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/region/remRepTer?regionId=' + regionId + '&agentId=' + agentId, '', 'post').then(function (result: any) {
			return result;
		});
	}

	setTerritory(territoryId: number, typeId: number): Promise<boolean> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/region/setTerritoryLink?territoryId=' + territoryId + '&typeId=' + typeId, '', 'post').then(function (result: any) {
			return result;
		});
	}

	remTerritory(territoryId: number, typeId: number): Promise<boolean> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.1/region/remTerritoryLink?territoryId=' + territoryId + '&typeId=' + typeId, '', 'post').then(function (result: any) {
			return result;
		});
	}
}
