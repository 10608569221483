import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { callNoteCustomFieldsReducer } from './call-note-custom-fields.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CallNoteCustomFieldsEffects } from './call-note-custom-fields.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('callNoteCustomFields', callNoteCustomFieldsReducer),
    EffectsModule.forFeature([CallNoteCustomFieldsEffects])
  ]
})
export class CallNoteCustomFieldsStateModule {}
