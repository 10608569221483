<mat-toolbar fxLayout="row" fxFlex>
  <div fxLayout="row" class="search-and-new-container" fxFlex="1 1 auto">
    <tn-searchbar
      fxLayout="row"
      fxLayoutAlign="center center"
      fxFlex="1 1 auto"
    ></tn-searchbar>
  </div>
  <div fxFlex="auto"></div>
  <div fxLayout="row" fxLayoutGap="12px">
    <span *ngIf="$showNewSalesModuleButton | async">
      <button
        mat-flat-button
        *ngIf="$newSalesModuleState | async"
        (click)="toggleSalesModule()"
      >
        Switch to Old Sales Reports
      </button>
      <button
        mat-flat-button
        *ngIf="($newSalesModuleState | async) === false"
        color="primary"
        (click)="toggleSalesModule()"
      >
        Try New Sales Reports
      </button>
    </span>
    <div fxLayout="row" fxLayoutAlign="space-around center" *ngIf="webRecording">
      <button
        tabindex="false"
        (click)="openCallNoteDrawer()"
        mat-icon-button
        matTooltip="Record Call Note"
        style="background-color: red"
      >
        <mat-icon style="color: white">mic</mat-icon>
      </button>
    </div>
    <tn-new-menu></tn-new-menu>
    <tn-user-options></tn-user-options>
  </div>
</mat-toolbar>
