import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RootState } from 'src/app/store/store.reducer';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { OpportunityCustomField } from 'src/app/models/Opportunity/opportunity-custom-field';
import { selectOppCustomFieldsAsArray } from 'src/app/store/opportunities/selectors/opp-custom-fields.selector';
import { FieldType } from 'src/app/models/call-notes/field-type';
import { selectFieldTypesAsArray } from 'src/app/store/system-settings/field-types/selectors/field-types.selector';
import { map } from 'rxjs/operators';
import { selectCurrentOppCustomField } from 'src/app/store/opportunities/selectors/current-opp-custom-field.selector';
import {
  CreateOpportunityCustomField,
  UpdateOpportunityCustomField,
  DeleteOpportunityCustomField
} from 'src/app/store/opportunities/opportunities.actions';
import { CloseDrawer } from 'src/app/store/layout/layout.actions';

@Component({
  selector: 'tn-opp-custom-field-form',
  templateUrl: './opp-custom-field-form.component.html',
  styleUrls: ['./opp-custom-field-form.component.scss']
})
export class OppCustomFieldFormComponent implements OnInit {
  $fields: Observable<OpportunityCustomField[]>;
  fields: OpportunityCustomField[];
  $types: Observable<FieldType[]>;
  $fieldsSub: Subscription;

  currentField: OpportunityCustomField;

  oppCFForm = new FormGroup({
    name: new FormControl(null, Validators.required),
    type: new FormControl(null, Validators.required)
  });
  constructor(private store: Store<RootState>) {}

  ngOnInit() {
    this.$fields = this.store.select(selectOppCustomFieldsAsArray);
    this.$fieldsSub = this.$fields.subscribe((fields) => {
      this.fields = fields;
    });
    this.$types = this.store
      .select(selectFieldTypesAsArray)
      .pipe(map((types) => types.filter((type) => type.opportunityField === true)));

    this.store.select(selectCurrentOppCustomField).subscribe((icf) => {
      if (!!icf) {
        this.currentField = icf;
        this.oppCFForm.setValue({
          name: icf.name,
          type: {
            name: icf.typeInfo.name,
            id: icf.typeInfo.id
          }
        });
      }
    });
  }

  compareObjects(o1: any, o2: any) {
    if (!!o1 && !!o2) {
      return o1.name === o2.name && o1.id && o2.id;
    }
  }

  onSave() {
    if (!this.currentField) {
      this.store.dispatch(
        new CreateOpportunityCustomField({
          name: this.oppCFForm.value.name,
          // typeInfo: this.oppCFForm.value.type,
          type: this.oppCFForm.value.type.id,
          listOrder: this.fields.length
        })
      );
    } else {
      this.store.dispatch(
        new UpdateOpportunityCustomField({
          ...this.currentField,
          name: this.oppCFForm.value.name,
          // typeInfo: this.oppCFForm.value.type,
          type: this.oppCFForm.value.type.id,
          listOrder: this.currentField.listOrder
        })
      );
    }
    this.store.dispatch(new CloseDrawer());
  }

  onDelete() {
    this.store.dispatch(new DeleteOpportunityCustomField(this.currentField.id));
    this.store.dispatch(new CloseDrawer());
  }

  onCancel() {
    this.store.dispatch(new CloseDrawer());
  }
}
