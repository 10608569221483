<div class="opp-form-container">
  <form [formGroup]="oppCFForm" fxLayout="column">
    <mat-form-field fxFlex="100">
      <mat-label>Name</mat-label>
      <input formControlName="name" matInput type="text" required autocomplete="off" />
    </mat-form-field>
    <mat-form-field fxFlex="100">
      <mat-label>Type</mat-label>
      <mat-select formControlName="type" matInput [compareWith]="compareObjects" required>
        <mat-option *ngFor="let type of $types | async" [value]="type">{{
          type.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <div fxLayout="row-reverse" fxLayoutAlign="space-between center">
      <div fxLayout="row-reverse" fxLayoutGap="12px">
        <button
          [disabled]="oppCFForm.pristine || oppCFForm.invalid"
          mat-raised-button
          color="primary"
          mat-button
          class="button-container"
          (click)="onSave()"
        >
          Save
        </button>
        <button
          preventDefaultAction
          class="button-container"
          mat-raised-button
          color="white"
          mat-button
          (click)="onCancel()"
        >
          Cancel
        </button>
      </div>
      <button
        preventDefaultAction
        *ngIf="currentField?.id"
        mat-raised-button
        color="warn"
        mat-button
        (click)="onDelete()"
      >
        Delete
      </button>
    </div>
  </form>
</div>
