import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ProjectLabelsEffects } from './project-labels.effects';
import { projectLabelsReducer } from './project-labels.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('projectLabels', projectLabelsReducer),
    EffectsModule.forFeature([ProjectLabelsEffects])
  ]
})
export class ProjectLabelsStateModule {}
