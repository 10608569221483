import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Category } from 'src/app/models/admin/files/category';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import { selectSelectedCategory } from 'src/app/store/system-settings/file-categories/selectors/selected-category.selector';
import {
  CreateFileCategories,
  ClearFileCategoriesId,
  UpdateFileCategories,
  DeleteFileCategories
} from '../../../../../store/system-settings/file-categories/file-categories.actions';
import { CloseDrawer } from 'src/app/store/layout/layout.actions';
import { DeleteObject } from 'src/app/store/undo-delete/undo-delete.actions';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDeleteComponent } from 'src/app/view/shared/confirm-delete/confirm-delete.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'tn-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.scss']
})
export class AddCategoryComponent implements OnInit, OnDestroy {
  fileCategoryForm: FormGroup;
  categories: Category[];
  currentCategory: Category;
  $currentCategorySub: Subscription;

  constructor(private store: Store<RootState>, private dialog: MatDialog) {}

  ngOnInit() {
    this.fileCategoryForm = new FormGroup({
      name: new FormControl(null, Validators.required),
      id: new FormControl(null)
    });
    this.$currentCategorySub = this.store
      .select(selectSelectedCategory)
      .subscribe((category) => {
        if (!!category) {
          this.currentCategory = category;
          this.fileCategoryForm.setValue({
            name: category.name,
            id: category.id
          });
        }
      });
  }

  ngOnDestroy() {
    this.$currentCategorySub.unsubscribe();
  }

  save() {
    this.store.dispatch(new CreateFileCategories(this.fileCategoryForm.value));
    this.store.dispatch(new CloseDrawer());
    this.store.dispatch(new ClearFileCategoriesId());
  }

  update() {
    this.fileCategoryForm.value.id = this.currentCategory.id;
    this.store.dispatch(new UpdateFileCategories(this.fileCategoryForm.value));
    this.store.dispatch(new CloseDrawer());
    this.store.dispatch(new ClearFileCategoriesId());
  }

  cancel(): void {
    this.store.dispatch(new CloseDrawer());
    this.store.dispatch(new ClearFileCategoriesId());
  }

  delete(): void {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {});

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((willDelete: boolean) => {
        if (willDelete) {
          this.store.dispatch(new DeleteFileCategories(this.currentCategory.id));
          this.store.dispatch(new ClearFileCategoriesId());
          this.store.dispatch(new CloseDrawer());
        }
      });
  }
}
