import { Injectable } from '@angular/core';

import { AuthenticationService } from '../../shared/services/authentication.service';
import { ContactV2 } from '../models/contacts/contact-V2';

@Injectable()
export class ContactJPIService {
	constructor(private authService: AuthenticationService) {
	}

	getContactsForCompany(companyId: number): Promise<any> {
		return this.authService.makeJpiRequest('/companies/' + companyId + '/contacts', null, null, 'get')
			.then(contacts => {
				contacts = JSON.parse(contacts);
				contacts.map((contact: any) => contact.fullName = `${contact.firstName} ${contact.lastName}`);
				return contacts.sort((contact1: ContactV2, contact2: ContactV2) => contact1.firstName.localeCompare(contact2.firstName));
			});
	}

	getContactFromCompany(companyId: number, contactId: number): Promise<any> {
		return this.authService.makeJpiRequest('/companies/' + companyId + '/contacts/' + contactId, null, null, 'get')
			.then(contact => {
				contact = JSON.parse(contact);
				contact.fullName = `${contact.firstName} ${contact.lastName}`;
				return contact;
			});
	}

	createContact(companyId: number, contact: ContactV2): Promise<any> {
		return this.authService.makeJpiRequest('/companies/' + companyId + '/contacts', null, contact, 'post')
			.then(contact => {
				return JSON.parse(contact);
			});
	}

	updateContact(companyId: number, contact: ContactV2): Promise<any> {
		return this.authService.makeJpiRequest('/companies/' + companyId + '/contacts/' + contact.id, null, contact, 'put')
			.then(contact => {
				return JSON.parse(contact);
			});
	}

	deleteContact(companyId: number, contact: ContactV2): Promise<any> {
		return this.authService.makeJpiRequest('/companies/' + companyId + '/contacts/' + contact.id, null, contact, 'delete')
			.then(contact => JSON.parse(contact));
	}
}
