import { RootState } from '../../store.reducer';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { CompanySelectItem } from 'src/app/models/companies/company-select';
import { GenericMap } from 'src/app/models/generic-map';
import { Company } from 'src/app/models/companies/company';
import { selectCompanyParamId, selectAllCompanies } from './current-company.selector';

export const selectCompanyInProfile: MemoizedSelector<
  RootState,
  CompanySelectItem
> = createSelector(
  selectCompanyParamId,
  selectAllCompanies,
  (id: number, companies: GenericMap<Company>): CompanySelectItem => {
    return id
      ? Object.keys(companies).length && companies[id]
        ? {
            id,
            name: companies[id].name,
            address: companies[id].billingAddress
          }
        : null
      : null;
  }
);
