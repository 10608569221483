import { Action } from '@ngrx/store';
import { GenericMap } from 'src/app/models/generic-map';
import { ProductLine } from 'src/app/models/product-lines/product-line';
import { HttpErrorResponse } from '@angular/common/http';

export enum ProductLinesActionTypes {
  LoadProductLines = '[ProductLines] Load Product Lines',
  LoadProductLinesSuccess = '[ProductLines] Load Product Lines Success',
  LoadProductLinesFailure = '[ProductLines] Load Product Lines Failure'
}

export class LoadProductLines implements Action {
  readonly type = ProductLinesActionTypes.LoadProductLines;
}

export class LoadProductLinesSuccess implements Action {
  readonly type = ProductLinesActionTypes.LoadProductLinesSuccess;
  constructor(public payload: GenericMap<ProductLine>) {}
}

export class LoadProductLinesFailure implements Action {
  readonly type = ProductLinesActionTypes.LoadProductLinesFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export type ProductLinesActions =
  | LoadProductLines
  | LoadProductLinesSuccess
  | LoadProductLinesFailure;
