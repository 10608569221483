import { ColumnHeader, ColumnType } from 'src/app/models/column-header';

export const OpportunitiesTableColumnsData: ColumnHeader[] = [
  {
    name: 'name',
    displayName: 'Name',
    type: ColumnType.STRING,
    isCurrency: false,
    isDate: false
  },
  {
    name: 'id',
    displayName: 'Opportunity ID',
    type: ColumnType.STRING,
    isCurrency: false,
    isDate: false
  },
  {
    name: 'companyName',
    displayName: 'Company',
    type: ColumnType.STRING,
    isCurrency: false,
    isDate: false
  },
  {
    name: 'companyId',
    displayName: 'Company ID',
    type: ColumnType.STRING,
    isCurrency: false,
    isDate: false
  },
  {
    name: 'ownedByName',
    displayName: 'Owner',
    type: ColumnType.STRING,
    isCurrency: false,
    isDate: false
  },
  {
    name: 'createdByName',
    displayName: 'Created By',
    type: ColumnType.STRING,
    isCurrency: false,
    isDate: false
  },
  {
    name: 'assignedToNames',
    displayName: 'Assigned To',
    type: ColumnType.STRING,
    isCurrency: false,
    isDate: false
  },
  {
    name: 'itemNames',
    displayName: 'Items',
    type: ColumnType.STRING,
    isCurrency: false,
    isDate: false
  },
  {
    name: 'createdDate',
    displayName: 'Date Created',
    type: ColumnType.NUMERIC,
    isCurrency: false,
    isDate: true
  },
  {
    name: 'estimatedCloseDate',
    displayName: 'Estimated Close date',
    type: ColumnType.NUMERIC,
    isCurrency: false,
    isDate: true
  },
  {
    name: 'total',
    displayName: 'Total',
    type: ColumnType.NUMERIC,
    isCurrency: true,
    isDate: false
  },
  {
    name: 'weightedTotal',
    displayName: 'Weighted Total',
    type: ColumnType.NUMERIC,
    isCurrency: true,
    isDate: false
  },
  {
    name: 'age',
    displayName: 'Age',
    type: ColumnType.NUMERIC,
    isCurrency: false,
    isDate: false
  },
  {
    name: 'accountId',
    displayName: 'Account ID',
    type: ColumnType.STRING,
    isCurrency: false,
    isDate: false
  },
  {
    name: 'description',
    displayName: 'Description',
    type: ColumnType.STRING,
    isCurrency: false,
    isDate: false
  },
  {
    name: 'divisionName',
    displayName: 'Division',
    type: ColumnType.STRING,
    isCurrency: false,
    isDate: false
  },
  {
    name: 'businessUnit',
    displayName: 'Business Unit',
    type: ColumnType.STRING,
    isCurrency: false,
    isDate: false
  }
];
