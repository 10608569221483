import { Injectable } from '@angular/core';

import { AuthenticationService } from './authentication.service';

@Injectable()
export class TutorialService {
	constructor(authService: AuthenticationService) {
		// super('tutorials', authService);
	}
}
