import { Pipe, PipeTransform } from '@angular/core';
import { FileMetaData } from 'src/app/models/file-meta-data';

@Pipe({
  name: 'projectFilesFilter'
})
export class ProjectFilesFilterPipe implements PipeTransform {
  transform(files: FileMetaData[], searchText: string): any {
    return files.filter(file => {
      return !!searchText
        ? file.fileName.toLowerCase().match(searchText) ||
            file.uploadedByItem.name.toLowerCase().match(searchText)
        : true;
    });
  }
}
