import { Action } from '@ngrx/store';
import { Territory } from 'src/app/models/admin/territories/territory';
import { HttpErrorResponse } from '@angular/common/http';
import { TerritoryType } from 'src/app/models/admin/territories/territory-type';
import { TerritoriesMap } from 'src/app/models/admin/territories/territories-map';
import { TerritoryTypesMap } from 'src/app/models/admin/territories/territory-types-map';
import { IdNameItemMap } from 'src/app/models/id-name-item';
import { CompanySelectMap } from 'src/app/models/companies/company-select';

export enum TerritoryManagementActionTypes {
  LoadTerritories = '[Territory Management] Load Territories',
  LoadTerritoriesSuccess = '[Territory Management] Load Territories Success',
  LoadTerritoriesFailure = '[Territory Management] Load Territories Failure',
  RedirectToTerritories = '[Territory Management] Redirect To Territories',

  LoadTerritory = '[Territory Management] Load Territory',
  LoadTerritorySuccess = '[Territory Management] Load Territory Success',
  LoadTerritoryFailure = '[Territory Management] Load Territory Failure',

  SaveTerritory = '[Territory Management] Save Territory',
  SaveTerritorySuccess = '[Territory Management] Save Territory Success',
  SaveTerritoryFailure = '[Territory Management] Save Territory Failure',

  DeleteTerritory = '[Territory Management] Delete Territory',
  DeleteTerritorySuccess = '[Territory Management] Delete Territory Success',
  DeleteTerritoryFailure = '[Territory Management] Delete Territory Failure',

  LoadTerritoryCompanies = '[Territory Management] Load Territory Companies',
  LoadTerritoryCompaniesSuccess = '[Territory Management] Load Territory Companies Success',
  LoadTerritoryCompaniesFailure = '[Territory Management] Load Territory Companies Failure',

  LoadTerritoryTypes = '[Territory Management] Load Territory Types',
  LoadTerritoryTypesSuccess = '[Territory Management] Load Territory Types Success',
  LoadTerritoryTypesFailure = '[Territory Management] Load Territory Types Failure',

  LoadTerritoryType = '[Territory Management] Load Territory Type',
  LoadTerritoryTypeSuccess = '[Territory Management] Load Territory Type Success',
  LoadTerritoryTypeFailure = '[Territory Management] Load Territory Type Failure',

  SaveCompanyAssociation = '[Territory Management] Save Company Association',
  SaveCompanyAssociationSuccess = '[Territory Management] Save Company Association Success',
  SaveCompanyAssociationFailure = '[Territory Management] Save Company Association Failure'
}

export class LoadTerritories implements Action {
  readonly type = TerritoryManagementActionTypes.LoadTerritories;
}

export class LoadTerritoriesSuccess implements Action {
  readonly type = TerritoryManagementActionTypes.LoadTerritoriesSuccess;
  constructor(public payload: TerritoriesMap) {}
}

export class LoadTerritoriesFailure implements Action {
  readonly type = TerritoryManagementActionTypes.LoadTerritoriesFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class RedirectToTerritories implements Action {
  readonly type = TerritoryManagementActionTypes.RedirectToTerritories;
}

export class LoadTerritory implements Action {
  readonly type = TerritoryManagementActionTypes.LoadTerritory;
  constructor(public payload: Territory) {}
}

export class LoadTerritorySuccess implements Action {
  readonly type = TerritoryManagementActionTypes.LoadTerritorySuccess;
  constructor(public payload: Territory) {}
}

export class LoadTerritoryFailure implements Action {
  readonly type = TerritoryManagementActionTypes.LoadTerritoryFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class SaveTerritory implements Action {
  readonly type = TerritoryManagementActionTypes.SaveTerritory;
  constructor(public payload: Territory) {}
}

export class SaveTerritorySuccess implements Action {
  readonly type = TerritoryManagementActionTypes.SaveTerritorySuccess;
  constructor(public payload: Territory) {}
}

export class SaveTerritoryFailure implements Action {
  readonly type = TerritoryManagementActionTypes.SaveTerritoryFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class DeleteTerritory implements Action {
  readonly type = TerritoryManagementActionTypes.DeleteTerritory;
  constructor(public payload: number) {}
}

export class DeleteTerritorySuccess implements Action {
  readonly type = TerritoryManagementActionTypes.DeleteTerritorySuccess;
}

export class DeleteTerritoryFailure implements Action {
  readonly type = TerritoryManagementActionTypes.DeleteTerritoryFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadTerritoryCompanies implements Action {
  readonly type = TerritoryManagementActionTypes.LoadTerritoryCompanies;
  constructor(public payload: number) {}
}

export class LoadTerritoryCompaniesSuccess implements Action {
  readonly type = TerritoryManagementActionTypes.LoadTerritoryCompaniesSuccess;
  constructor(
    public payload: { companyNamesAndIds: CompanySelectMap; territoryId: number }
  ) {}
}

export class LoadTerritoryCompaniesFailure implements Action {
  readonly type = TerritoryManagementActionTypes.LoadTerritoryCompaniesFailure;
  constructor(public payload: { error: HttpErrorResponse; territoryId: number }) {}
}

export class LoadTerritoryTypes implements Action {
  readonly type = TerritoryManagementActionTypes.LoadTerritoryTypes;
}

export class LoadTerritoryTypesSuccess implements Action {
  readonly type = TerritoryManagementActionTypes.LoadTerritoryTypesSuccess;
  constructor(public payload: TerritoryTypesMap) {}
}

export class LoadTerritoryTypesFailure implements Action {
  readonly type = TerritoryManagementActionTypes.LoadTerritoryTypesFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadTerritoryType implements Action {
  readonly type = TerritoryManagementActionTypes.LoadTerritoryType;
}

export class LoadTerritoryTypeSuccess implements Action {
  readonly type = TerritoryManagementActionTypes.LoadTerritoryTypeSuccess;
  constructor(public payload: TerritoryType) {}
}

export class LoadTerritoryTypeFailure implements Action {
  readonly type = TerritoryManagementActionTypes.LoadTerritoryTypeFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class SaveCompanyAssociation implements Action {
  readonly type = TerritoryManagementActionTypes.SaveCompanyAssociation;
  constructor(
    public payload: { companyId: number; territoryId: number; isAssociated: boolean }
  ) {}
}

export class SaveCompanyAssociationSuccess implements Action {
  readonly type = TerritoryManagementActionTypes.SaveCompanyAssociationSuccess;
  constructor(public payload: number) {}
}

export class SaveCompanyAssociationFailure implements Action {
  readonly type = TerritoryManagementActionTypes.SaveCompanyAssociationFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export type TerritoryManagementActions =
  | LoadTerritories
  | LoadTerritoriesSuccess
  | LoadTerritoriesFailure
  | RedirectToTerritories
  | LoadTerritory
  | LoadTerritorySuccess
  | LoadTerritoryFailure
  | SaveTerritory
  | SaveTerritorySuccess
  | SaveTerritoryFailure
  | DeleteTerritory
  | DeleteTerritorySuccess
  | DeleteTerritoryFailure
  | LoadTerritoryCompanies
  | LoadTerritoryCompaniesSuccess
  | LoadTerritoryCompaniesFailure
  | LoadTerritoryTypes
  | LoadTerritoryTypesSuccess
  | LoadTerritoryTypesFailure
  | LoadTerritoryType
  | LoadTerritoryTypeSuccess
  | LoadTerritoryTypeFailure
  | SaveCompanyAssociation
  | SaveCompanyAssociationSuccess
  | SaveCompanyAssociationFailure;
