import { Injectable } from '@angular/core';
import { CallNoteType } from 'src/app/models/call-notes/call-note-type';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ServicesModule } from '../services.module';

@Injectable({
  providedIn: ServicesModule
})
export class CallNoteTypesService {
  private jpiUrl = environment.jpiUrl;

  constructor(private http: HttpClient) {}

  getAll(): Observable<CallNoteType[]> {
    return this.http.get(this.jpiUrl + '/callnotetypes') as Observable<CallNoteType[]>;
  }

  getById(id): Observable<CallNoteType> {
    return this.http.get(this.jpiUrl + '/callnotetypes/' + id) as Observable<
      CallNoteType
    >;
  }

  create(callNoteType: any): Observable<CallNoteType> {
    return this.http.post(this.jpiUrl + '/callnotetypes', callNoteType) as Observable<
      CallNoteType
    >;
  }

  update(callNoteType: CallNoteType): Observable<CallNoteType> {
    return this.http.put(
      this.jpiUrl + '/callnotetypes/' + callNoteType.id,
      callNoteType
    ) as Observable<CallNoteType>;
  }

  delete(callNoteType: CallNoteType) {
    return this.http.delete(this.jpiUrl + '/callnotetypes/' + callNoteType.id);
  }
}
