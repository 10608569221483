import { Component, OnInit } from '@angular/core';
import { RootState } from 'src/app/store/store.reducer';
import { Store } from '@ngrx/store';
import {
  CancelDelete,
  CloseDeleteSnackbar
} from 'src/app/store/undo-delete/undo-delete.actions';

@Component({
  selector: 'tn-undo-delete',
  templateUrl: './undo-delete.component.html',
  styleUrls: ['./undo-delete.component.css']
})
export class UndoDeleteComponent implements OnInit {
  $objectsToDelete: any;
  constructor(private store: Store<RootState>) {}

  ngOnInit() {
    this.$objectsToDelete = this.store.select('undoDelete', 'itemsBeingDeleted');
  }

  undoButtonClicked() {
    this.store.dispatch(new CancelDelete());
  }

  clearButtonClicked() {
    this.store.dispatch(new CloseDeleteSnackbar());
  }
}
