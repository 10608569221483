import { WhatsNewActions, WhatsNewActionTypes } from './whats-new.actions';

export interface WhatsNewState {
  pending: boolean;
}

export const initialState: WhatsNewState = {
  pending: false
};

export function whatsNewReducer(
  state = initialState,
  action: WhatsNewActions
): WhatsNewState {
  switch (action.type) {
    case WhatsNewActionTypes.LoadWhatsNew:
      return {
        ...state,
        pending: true
      };

    default:
      return state;
  }
}
