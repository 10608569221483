import { CompanyFieldsActions, CompanyFieldsActionTypes } from './company-fields.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { CompanyField } from 'src/app/models/companies/company-field';

export interface CompanyFieldsState {
  pending: boolean;
  standardCompanyFields: CompanyField[];
  customCompanyFields: CompanyField[];
  currentCompanyField: CompanyField;
  error: HttpErrorResponse;
  companyFields?: CompanyField[];
}

export const initialState: CompanyFieldsState = {
  pending: false,
  standardCompanyFields: [],
  customCompanyFields: [],
  currentCompanyField: undefined,
  error: undefined
};

export function companyFieldsReducer(
  state = initialState,
  action: CompanyFieldsActions
): CompanyFieldsState {
  switch (action.type) {
    case CompanyFieldsActionTypes.SetCurrentCompanyField:
      return { ...state, currentCompanyField: action.payload };

    case CompanyFieldsActionTypes.ClearCurrentCompanyField:
      return { ...state, currentCompanyField: undefined };

    case CompanyFieldsActionTypes.LoadCompanyFields:
      return { ...state, pending: true };

    case CompanyFieldsActionTypes.LoadCompanyFieldsSuccess:
      return {
        ...state,
        pending: false,
        standardCompanyFields: action.payload.filter(field => field.standardField),
        customCompanyFields: action.payload.filter(field => field.standardField === null)
      };

    case CompanyFieldsActionTypes.LoadCompanyFieldsFailure:
      return {
        ...state,
        pending: false,
        standardCompanyFields: [],
        customCompanyFields: [],
        error: action.payload
      };

    case CompanyFieldsActionTypes.CreateCompanyField:
      return { ...state, pending: true };

    case CompanyFieldsActionTypes.CreateCompanyFieldSuccess:
      return {
        ...state,
        pending: false,
        customCompanyFields: [...state.customCompanyFields, action.payload]
      };

    case CompanyFieldsActionTypes.CreateCompanyFieldFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case CompanyFieldsActionTypes.UpdateCompanyField:
      return { ...state };

    case CompanyFieldsActionTypes.UpdateCompanyFieldSuccess:
      const updatedCustomCompanyFields = state.customCompanyFields.map(field =>
        action.payload.id === field.id ? action.payload : field
      );
      return {
        ...state,
        pending: false,
        customCompanyFields: updatedCustomCompanyFields
      };

    case CompanyFieldsActionTypes.UpdateCompanyFieldFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case CompanyFieldsActionTypes.DeleteCompanyField:
      return { ...state, pending: true };

    case CompanyFieldsActionTypes.DeleteCompanyFieldSuccess:
      return {
        ...state,
        pending: false,
        customCompanyFields: state.customCompanyFields.filter(
          companyField => companyField.id !== action.payload
        ),
        currentCompanyField: undefined
      };

    case CompanyFieldsActionTypes.DeleteCompanyFieldFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    default:
      return state;
  }
}
