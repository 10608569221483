import { Injectable } from '@angular/core';

import { AuthenticationService } from '../../shared/services/authentication.service';
import { File } from '../models/files/file';
import { FileCategory } from '../models/files/file-category';
import { saveAs } from 'file-saver';
import { FileMetaData } from 'src/app/models/file-meta-data';
import { Observable } from 'rxjs';

@Injectable()
export class FileService {
  constructor(private authService: AuthenticationService) {}

  getFiles(): Promise<File[]> {
    return this.authService
      .makeRequest('/api-v1.2.1/files', null, 'get')
      .then(function(result) {
        return JSON.parse(result);
      });
  }

  getFileCategories(): Promise<FileCategory[]> {
    return this.authService
      .makeRequest('/api-v1.2.1/fileCategories', null, 'get')
      .then(function(result) {
        return JSON.parse(result);
      });
  }

  getFilesForCompany(companyId: number): Promise<File[]> {
    return this.authService
      .makeFileRequest('/api-v1.2.1/filesForCompany?companyId=' + companyId, null, 'get')
      .then(function(result) {
        return JSON.parse(result);
      });
  }

  getFilesForCompanyJpi(companyId: number): Observable<unknown[]> {
    return this.authService.makeJPIFileRequestForCompany(
      '/files?companyId=' + companyId
    ) as Observable<unknown[]>;
  }

  getFilesForAgent(agentId: number): Promise<File[]> {
    return this.authService
      .makeRequest('/api-v1.2.1/filesForAgent?agentId=' + agentId, null, 'get')
      .then(function(result) {
        return JSON.parse(result);
      });
  }

  uploadFile(file: any, companyId: number, categoryId: number): Observable<FileMetaData> {
    return this.authService.makeJPIFileUpload(
      '/files',
      file,
      companyId,
      categoryId
    ) as Observable<FileMetaData>;
  }

  deleteFile(file: File): Promise<boolean> {
    return this.authService
      .makeRequest('/api-v1.2.1/deleteFile', file, 'post')
      .then(function(result) {
        return true;
      });
  }

  newFileCategory(category: FileCategory): Promise<number> {
    return this.authService
      .makeRequest('/api-v1.2.1/createFileCategory', category, 'post')
      .then(function(result) {
        return result;
      });
  }

  updateFileCategory(category: FileCategory): Promise<boolean> {
    return this.authService
      .makeRequest('/api-v1.2.1/updateFileCategory', category, 'post')
      .then(function(result) {
        return true;
      });
  }

  deleteFileCategory(category: FileCategory): Promise<boolean> {
    return this.authService
      .makeRequest('/api-v1.2.1/deleteFileCategory', category, 'post')
      .then(function(result) {
        return true;
      });
  }

  downloadFile(file) {
    this.authService.makeJPIFileRequest('/files/' + file.id).then(result => {
      saveAs(result, file.fileName);
    });
  }
}
