import { MemoizedSelector, createSelector } from '@ngrx/store';
import { RootState } from '../../../store.reducer';
import { GenericMap } from 'src/app/models/generic-map';
import { GenericApi } from 'src/app/models/generic-api';
import { DropdownOption } from 'src/app/models/dropdown-option';
import { Field } from 'src/app/models/call-notes/field';

export const selectAllDropdownOptions = (state: RootState) =>
  state.callNoteCustomFields.dropdownOptions;

export const selectedCallNoteField = (state: RootState) =>
  state.callNoteCustomFields.currentCallNoteField;

export const selectCurrentCallNoteFieldDropdownOptions: MemoizedSelector<
  RootState,
  DropdownOption[]
> = createSelector(
  selectAllDropdownOptions,
  selectedCallNoteField,
  (
    dropdownOptions: GenericMap<GenericApi<GenericMap<DropdownOption>>>,
    callNoteField: Field
  ): DropdownOption[] => {
    return !!dropdownOptions && !!callNoteField && !!dropdownOptions[callNoteField.id]
      ? Object.values(
          dropdownOptions[callNoteField.id].data
            ? dropdownOptions[callNoteField.id].data
            : {}
        )
          .sort((a: DropdownOption, b: DropdownOption) => {
            return a.dropdownOrder < b.dropdownOrder
              ? -1
              : a.dropdownOrder > b.dropdownOrder
              ? 1
              : 0;
          })
          .filter(option => !!option && !!option.id)
          .map((option: DropdownOption) => {
            return {
              ...option,
              isEditing: false
            };
          })
      : [];
  }
);
