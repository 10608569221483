import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Client } from 'src/app/models/admin/client/client';
import { RootState } from 'src/app/store/store.reducer';
import { Store } from '@ngrx/store';
import {
  CreateClients,
  ClearCurrentClient,
  UpdateClients,
  DeleteClients,
  SyncToElastic
} from '../../../../store/clients/clients.actions';
import { CloseDrawer } from 'src/app/store/layout/layout.actions';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Plans } from 'src/app/models/admin/plans/plans';
import { LoadPlans } from 'src/app/store/plans/plans.actions';
import { ClientsService } from '../../../../services/clients/clients.service';
import { selectPlansAsArray } from 'src/app/store/plans/selectors/all-plans-as-array.selector';

@Component({
  selector: 'tn-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.css']
})
export class AddClientComponent implements OnInit, OnDestroy {
  currentClient: Client;
  clientForm: FormGroup;
  clients: Client[];
  $clientSub: Subscription;
  $currentClientSub: Subscription;
  $plansSub: Subscription;
  plans;

  constructor(
    private store: Store<RootState>,
    private clientsService: ClientsService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.store.dispatch(new LoadPlans());
    this.$plansSub = this.store
      .select(selectPlansAsArray)
      .subscribe((plans) => (this.plans = plans));
    this.$currentClientSub = this.store
      .select('clients', 'currentClient')
      .subscribe((client) => (this.currentClient = client));
    this.$clientSub = this.store
      .select('clients', 'clients')
      .subscribe((client) => (this.clients = client));
    this.clientForm = new FormGroup({
      id: new FormControl(this.currentClient.id),
      name: new FormControl(this.currentClient.name, [Validators.required]),
      primaryContact: new FormControl(this.currentClient.primaryContact),
      primaryPhone: new FormControl(this.currentClient.primaryPhone),
      address: this.fb.group({
        addressLine1: new FormControl(this.currentClient.address.addressLine1),
        addressLine2: new FormControl(this.currentClient.address.addressLine2),
        city: new FormControl(this.currentClient.address.city),
        state: new FormControl(this.currentClient.address.state),
        postCode: new FormControl(this.currentClient.address.postCode)
      }),
      planId: new FormControl(this.currentClient.planId),
      billingCycle: new FormControl(this.currentClient.billingCycle),
      startDate: new FormControl(this.currentClient.startDate)
    });
  }

  ngOnDestroy() {
    this.$clientSub.unsubscribe();
    this.$currentClientSub.unsubscribe();
  }

  save() {
    this.store.dispatch(new CreateClients(this.clientForm.value));
    this.store.dispatch(new ClearCurrentClient());
    this.store.dispatch(new CloseDrawer());
  }

  update() {
    this.store.dispatch(new UpdateClients(this.clientForm.value));
    this.store.dispatch(new ClearCurrentClient());
    this.store.dispatch(new CloseDrawer());
  }

  syncToElastic() {
    this.store.dispatch(new CloseDrawer());
    this.store.dispatch(new SyncToElastic(this.currentClient.id));
  }

  cancel() {
    this.store.dispatch(new ClearCurrentClient());
    this.store.dispatch(new CloseDrawer());
  }

  delete() {
    this.store.dispatch(new DeleteClients(this.currentClient.id));
    this.store.dispatch(new ClearCurrentClient());
    this.store.dispatch(new CloseDrawer());
  }
}
