import {
  CallNoteCustomFieldsActions,
  CallNoteCustomFieldsActionTypes
} from './call-note-custom-fields.actions';
import { CallNoteField } from 'src/app/models/call-notes/call-note-field';
import { HttpErrorResponse } from '@angular/common/http';
import { Field } from 'src/app/models/call-notes/field';
import { GenericMap } from 'src/app/models/generic-map';
import { GenericApi } from 'src/app/models/generic-api';
import { DropdownOption } from 'src/app/models/dropdown-option';

export interface CallNoteCustomFieldsState {
  pending: boolean;
  callNoteFields: Field[];
  currentCallNoteField: Field;
  error: HttpErrorResponse;
  dropdownOptions: GenericMap<GenericApi<GenericMap<DropdownOption>>>;
}

export const initialState: CallNoteCustomFieldsState = {
  pending: false,
  callNoteFields: [],
  currentCallNoteField: undefined,
  error: undefined,
  dropdownOptions: {}
};

export function callNoteCustomFieldsReducer(
  state = initialState,
  action: CallNoteCustomFieldsActions
): CallNoteCustomFieldsState {
  switch (action.type) {
    case CallNoteCustomFieldsActionTypes.SetCurrentCallNoteField:
      return { ...state, currentCallNoteField: action.payload };

    case CallNoteCustomFieldsActionTypes.ClearCurrentCallNoteField:
      return { ...state, currentCallNoteField: undefined };

    case CallNoteCustomFieldsActionTypes.LoadCallNoteCustomFields:
      return { ...state, pending: true };

    case CallNoteCustomFieldsActionTypes.LoadCallNoteCustomFieldsSuccess:
      return {
        ...state,
        pending: false,
        callNoteFields: action.payload
      };

    case CallNoteCustomFieldsActionTypes.LoadCallNoteCustomFieldsFailure:
      return {
        ...state,
        pending: false,
        callNoteFields: [],
        error: action.payload
      };

    case CallNoteCustomFieldsActionTypes.CreateCallNoteCustomField:
      return { ...state, pending: true };

    case CallNoteCustomFieldsActionTypes.CreateCallNoteCustomFieldSuccess:
      return {
        ...state,
        pending: false,
        callNoteFields: [...state.callNoteFields, action.payload]
      };

    case CallNoteCustomFieldsActionTypes.CreateCallNoteCustomFieldFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case CallNoteCustomFieldsActionTypes.UpdateCallNoteCustomFieldSuccess:
      const updatedCallNoteFields = state.callNoteFields.map(field =>
        action.payload.id === field.id ? action.payload : field
      );
      return {
        ...state,
        pending: false,
        callNoteFields: updatedCallNoteFields
      };

    case CallNoteCustomFieldsActionTypes.UpdateCallNoteCustomFieldFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case CallNoteCustomFieldsActionTypes.DeleteCallNoteCustomField:
      return { ...state, pending: true };

    case CallNoteCustomFieldsActionTypes.DeleteCallNoteCustomFieldSuccess:
      return {
        ...state,
        pending: false,
        callNoteFields: state.callNoteFields.filter(
          callNoteField => callNoteField.id !== action.payload
        ),
        currentCallNoteField: undefined
      };

    case CallNoteCustomFieldsActionTypes.DeleteCallNoteCustomFieldFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case CallNoteCustomFieldsActionTypes.LoadCallNoteDropdownOptions:
      return {
        ...state,
        dropdownOptions: {
          ...state.dropdownOptions,
          [action.payload]: {
            ...state.dropdownOptions[action.payload],
            pending: true
          }
        }
      };

    case CallNoteCustomFieldsActionTypes.LoadCallNoteDropdownOptionsSuccess:
      return {
        ...state,
        dropdownOptions: {
          ...state.dropdownOptions,
          [action.payload.fieldId]: {
            error: undefined,
            pending: false,
            data: action.payload.dropdownOptions
          }
        }
      };

    case CallNoteCustomFieldsActionTypes.LoadCallNoteDropdownOptionsFailure:
      return {
        ...state,
        dropdownOptions: {
          ...state.dropdownOptions,
          [action.payload.fieldId]: {
            ...state.dropdownOptions[action.payload.fieldId],
            pending: false,
            error: action.payload.error
          }
        }
      };

    case CallNoteCustomFieldsActionTypes.SaveCallNoteDropdownOptionSuccess:
      return {
        ...state,
        dropdownOptions: {
          ...state.dropdownOptions,
          [action.payload.fieldId]: {
            error: undefined,
            pending: false,
            data: {
              ...state.dropdownOptions[action.payload.fieldId].data,
              [action.payload.id]: action.payload
            }
          }
        }
      };

    case CallNoteCustomFieldsActionTypes.DeleteCallNoteDropdownOptionSuccess:
      return {
        ...state,
        dropdownOptions: {
          ...state.dropdownOptions,
          [action.payload.fieldId]: {
            error: undefined,
            pending: false,
            data: {
              ...state.dropdownOptions[action.payload.fieldId].data,
              [action.payload.id]: undefined
            }
          }
        }
      };

    case CallNoteCustomFieldsActionTypes.UpdateCallNoteDropdownOptionsOrderSuccess:
      return {
        ...state,
        dropdownOptions: {
          ...state.dropdownOptions,
          [action.payload.fieldId]: {
            error: undefined,
            pending: false,
            data: action.payload.optionsMap
          }
        }
      };

    default:
      return state;
  }
}
