import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, mergeMap, switchMap } from 'rxjs/operators';
import { of, forkJoin } from 'rxjs';
import {
  LoadGlobalSearchFailure,
  LoadGlobalSearchSuccess,
  GlobalSearchActionTypes,
  GlobalSearchActions,
  LoadGlobalSearch
} from './global-search.actions';
import { convertToMap } from 'src/app/utils/convertToMap';
import { CompaniesService } from 'src/app/services/companies/companies.service';
import { CallNotesService } from 'src/app/services/call-notes/call-notes.service';
import { GlobalFilter } from 'src/app/app-v2/shared/models/filters/globalFilter';
import { Company } from 'src/app/models/companies/company';
import { formatAddressIntoOneLine } from 'src/app/utils/formatAddressIntoOneLine';
import { CallNote } from 'src/app/models/call-notes/call-note';
import { OpenLeftDrawer } from '../layout/layout.actions';
import { GlobalSearchResultsComponent } from 'src/app/view/shared/global-search-results/global-search-results.component';
import { LoadCallNotesSuccess } from '../call-notes/call-notes.actions';
import {
  LoadCompanyContactsSuccess,
  LoadCompaniesSuccess
} from '../companies/companies.actions';
import { Contact } from 'src/app/models/contacts/contact';

@Injectable()
export class GlobalSearchEffects {
  constructor(
    private actions$: Actions<GlobalSearchActions>,
    private companiesService: CompaniesService,
    private callNotesService: CallNotesService
  ) {}

  loadGlobalSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalSearchActionTypes.LoadGlobalSearch),
      concatMap((action: LoadGlobalSearch) => {
        const companyFilter: GlobalFilter = {
          filters: [
            {
              operand1: 'searchField',
              operator: 'CONTAINS_ALL_OF',
              operand2: [action.payload]
            }
          ]
        };

        const contactsFilter: GlobalFilter = {
          filters: [
            {
              operand1: 'contacts.searchField',
              operator: 'CONTAINS_ALL_OF',
              operand2: [action.payload]
            }
          ]
        };

        const callNotesFilter: GlobalFilter = {
          filters: [
            {
              operand1: 'fields.value',
              operator: 'CONTAINS_ALL_OF',
              operand2: [action.payload]
            }
          ]
        };
        return forkJoin([
          this.companiesService
            .getCompaniesFiltered(companyFilter, 10, 'id', false)
            .pipe(catchError((error) => of(new LoadGlobalSearchFailure(error)))),
          this.companiesService
            .getCompaniesFiltered(contactsFilter, 10, 'id', false)
            .pipe(catchError((error) => of(new LoadGlobalSearchFailure(error)))),
          this.callNotesService
            .getCallNotes(callNotesFilter, { limit: '10', skip: '0', order: 'callDate' })
            .pipe(catchError((error) => of(new LoadGlobalSearchFailure(error))))
        ]).pipe(
          switchMap((data: any) => {
            return [
              new LoadCompaniesSuccess(
                convertToMap([...data[1].items, ...data[0].items], 'id')
              ),
              // Map through the contacts results and dispatch a
              // LoadCompanyContactsSuccess success for each of them
              ...data[1].items.map((company: Company) => {
                return new LoadCompanyContactsSuccess({
                  companyId: company.id,
                  contacts: convertToMap(
                    company.contacts.map((contact: Contact) => {
                      return {
                        ...contact,
                        company: {
                          id: company.id,
                          name: company.name,
                          address: company.billingAddress
                        }
                      };
                    }),
                    'id'
                  )
                });
              }),
              new LoadCallNotesSuccess(convertToMap(data[2].items, 'id')),
              new LoadGlobalSearchSuccess({
                companies: {
                  totalMatches: data[0].count,
                  data: convertToMap(
                    data[0].items.map((company: Company) => ({
                      id: company?.id,
                      address: formatAddressIntoOneLine(company.billingAddress),
                      name: company?.name
                    })) || {},
                    'id'
                  )
                },
                contacts: {
                  totalMatches: data[1].count,
                  data: convertToMap(
                    data[1].items.map((company: Company) => ({
                      id: company?.contacts[0]?.id,
                      company: {
                        name: company?.name,
                        id: company?.id
                      },
                      account: company?.contacts[0]?.account,
                      name:
                        company?.contacts[0]?.firstName && company?.contacts[0]?.lastName
                          ? company?.contacts[0]?.firstName +
                            ' ' +
                            company?.contacts[0]?.lastName
                          : !company?.contacts[0]?.firstName &&
                            company?.contacts[0]?.lastName
                          ? company?.contacts[0]?.lastName
                          : company?.contacts[0]?.firstName &&
                            !company?.contacts[0]?.lastName
                          ? company?.contacts[0]?.firstName
                          : undefined
                    })) || {},
                    'id'
                  )
                },
                callNotes: {
                  totalMatches: data[2].count,
                  data: convertToMap(
                    data[2].items.map((callNote: CallNote) => ({
                      id: callNote?.id,
                      company: callNote?.company?.name,
                      date: callNote?.callDate,
                      opportunityId: callNote?.opportunityId,
                      projectId: callNote?.projectId,
                      createdBy:
                        callNote?.createdBy?.firstName +
                        ' ' +
                        callNote?.createdBy?.lastName,
                      text: callNote?.fields[0]?.value
                    })) || {},
                    'id'
                  )
                }
              }),
              new OpenLeftDrawer({ component: GlobalSearchResultsComponent })
            ];
          })
        );
      })
    )
  );
}
