import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpportunityLabelsIndividualEffects } from './opportunity-labels-individual.effects';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { opportunityLabelsIndividualReducer } from './opportunity-labels-individual.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      'opportunityLabelsIndividual',
      opportunityLabelsIndividualReducer
    ),
    EffectsModule.forFeature([OpportunityLabelsIndividualEffects])
  ]
})
export class OpportunityLabelsIndividualStateModule {}
