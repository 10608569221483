import { BillingActions, BillingActionTypes } from './billing.actions';

export interface BillingState {
  pending: boolean;
}

export const initialState: BillingState = {
  pending: false
};

export function billingReducer(
  state = initialState,
  action: BillingActions
): BillingState {
  switch (action.type) {
    case BillingActionTypes.LoadBilling:
      return { ...state, pending: true };

    case BillingActionTypes.LoadBillingSuccess:
      return { ...state, pending: false };

    case BillingActionTypes.LoadBillingFailure:
      return { ...state, pending: false };

    default:
      return state;
  }
}
