import { ManageUsersActions, ManageUsersActionTypes } from './manage-users.actions';

export interface ManageUsersState {
  pending: boolean;
}

export const initialState: ManageUsersState = {
  pending: false
};

export function manageUsersReducer(
  state = initialState,
  action: ManageUsersActions
): ManageUsersState {
  switch (action.type) {
    case ManageUsersActionTypes.LoadManageUsers:
      return { ...state, pending: true };

    case ManageUsersActionTypes.LoadManageUsersSuccess:
      return { ...state, pending: false };

    case ManageUsersActionTypes.LoadManageUsersFailure:
      return { ...state, pending: false };

    default:
      return state;
  }
}
