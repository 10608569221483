import { Component, Input, OnInit } from '@angular/core';

import { SalesAnalysis, ChartData, ChartDataName } from '../../../shared/models/dashboard';
import { DashboardService } from '../../../shared/services/dashboard.service';

@Component({
    selector: 'tn-procede-yearly-sales',
    templateUrl: './procede-yearly-sales.component.html',
    styleUrls: ['../dashboard.component.scss']
})
export class ProcedeYearlySalesComponent implements OnInit {
    @Input() companyId: number;
    loading: boolean;
    isLoaded: boolean;
    salesAnalysis: SalesAnalysis[];
    productData: ChartDataName[];

    constructor(private _dashboardService: DashboardService) { }

    ngOnInit(): void {
        this.isLoaded = false;
        this.salesAnalysis = [];
        this.productData = [];
        this.loading = true;
        this.getChartData();
    }

    getChartData(): void {
        this._dashboardService.getCompanyYearlySales(this.companyId).then((result: any) => {
            this.salesAnalysis = result;
            if (this.salesAnalysis) {
                for (const salesdata of this.salesAnalysis) {
                    this.getMonthArray(salesdata);

                    const chtData = { name: salesdata.columnTitle, lydata: this.setChanged(salesdata), data: this.setCurrent(salesdata) };

                    this.productData.push(chtData);
                }

                this.isLoaded = true;
                this.loading = false;
            }
        });
    }
    customizePoint(arg: any) {
        if (arg.value < 0 && arg.seriesName != "average") {
            return { color: 'red' };
        }
    }
    arrayMax(array): number {
        if (array) {
            return array.reduce((a, b) => Math.max(a, b));
        }
    }

    arrayMin(array): number {
        if (array) {
            return array.reduce((a, b) => Math.min(a, b));
        }
    }

    getMovingAverage(data, returnindex): number {
        const pnts = data;

        const point1 = (pnts[0]);
        const point2 = (pnts[0] + pnts[1]) / 2;
        const point3 = (pnts[0] + pnts[1] + pnts[2]) / 3;
        const point4 = (pnts[0] + pnts[1] + pnts[2] + pnts[3]) / 4;
        const point5 = (pnts[0] + pnts[1] + pnts[2] + pnts[3] + pnts[4]) / 5;
        const point6 = (pnts[0] + pnts[1] + pnts[2] + pnts[3] + pnts[4] + pnts[5]) / 6;
        const point7 = (pnts[0] + pnts[1] + pnts[2] + pnts[3] + pnts[4] + pnts[5] + pnts[6]) / 7;
        const point8 = (pnts[0] + pnts[1] + pnts[2] + pnts[3] + pnts[4] + pnts[5] + pnts[6] + pnts[7]) / 8;
        const point9 = (pnts[0] + pnts[1] + pnts[2] + pnts[3] + pnts[4] + pnts[5] + pnts[6] + pnts[7] + pnts[8]) / 9;
        const point10 = (pnts[0] + pnts[1] + pnts[2] + pnts[3] + pnts[4] + pnts[5] + pnts[6] + pnts[7] + pnts[8] + pnts[9]) / 10;
        const point11 = (pnts[0] + pnts[1] + pnts[2] + pnts[3] + pnts[4] + pnts[5] + pnts[6] + pnts[7] + pnts[8] + pnts[9] + pnts[10]) / 11;
        const point12 = (pnts[0] + pnts[1] + pnts[2] + pnts[3] + pnts[4] + pnts[5] + pnts[6] + pnts[7] + pnts[8] + pnts[9] + pnts[10] + pnts[11]) / 12;

        const returnPoints: number[] = [point1, point2, point3, point4, point5, point6, point7, point8, point9, point10, point11, point12];

        return returnPoints[returnindex];
    }
    getMonthArray(el): void {
        if (!el.ary1) {
            el.ary1 = [];
            el.aryly = [];
        }
        el.ary1.push(el.jan);
        el.ary1.push(el.feb);
        el.ary1.push(el.mar);
        el.ary1.push(el.apr);
        el.ary1.push(el.may);
        el.ary1.push(el.jun);
        el.ary1.push(el.jul);
        el.ary1.push(el.aug);
        el.ary1.push(el.sep);
        el.ary1.push(el.oct);
        el.ary1.push(el.nov);
        el.ary1.push(el.dec);

        el.aryly.push(el.janly);
        el.aryly.push(el.febly);
        el.aryly.push(el.marly);
        el.aryly.push(el.aprly);
        el.aryly.push(el.mayly);
        el.aryly.push(el.junly);
        el.aryly.push(el.jully);
        el.aryly.push(el.augly);
        el.aryly.push(el.seply);
        el.aryly.push(el.octly);
        el.aryly.push(el.novly);
        el.aryly.push(el.decly);
    }

    setCurrent(o): ChartData[] {
        const a1 = o.ary1[0];
        const a2 = o.ary1[1];
        const a3 = o.ary1[2];
        const a4 = o.ary1[3];
        const a5 = o.ary1[4];
        const a6 = o.ary1[5];
        const a7 = o.ary1[6];
        const a8 = o.ary1[7];
        const a9 = o.ary1[8];
        const a10 = o.ary1[9];
        const a11 = o.ary1[10];
        const a12 = o.ary1[11];

        const calculatedArray = [{ val: a1, arg: 'Jan', avg: this.getMovingAverage(o.ary1, 0) },
        { val: a2, arg: 'Feb', avg: this.getMovingAverage(o.ary1, 1) },
        { val: a3, arg: 'Mar', avg: this.getMovingAverage(o.ary1, 2) },
        { val: a4, arg: 'Apr', avg: this.getMovingAverage(o.ary1, 3) },
        { val: a5, arg: 'May', avg: this.getMovingAverage(o.ary1, 4) },
        { val: a6, arg: 'Jun', avg: this.getMovingAverage(o.ary1, 5) },
        { val: a7, arg: 'Jul', avg: this.getMovingAverage(o.ary1, 6) },
        { val: a8, arg: 'Aug', avg: this.getMovingAverage(o.ary1, 7) },
        { val: a9, arg: 'Sep', avg: this.getMovingAverage(o.ary1, 8) },
        { val: a10, arg: 'Oct', avg: this.getMovingAverage(o.ary1, 9) },
        { val: a11, arg: 'Nov', avg: this.getMovingAverage(o.ary1, 10) },
        { val: a12, arg: 'Dec', avg: this.getMovingAverage(o.ary1, 11) }];
        return calculatedArray;
    }

    setChanged(o): ChartData[] {
        const a1 = o.ary1[0] - o.aryly[0];
        const a2 = o.ary1[1] - o.aryly[1];
        const a3 = o.ary1[2] - o.aryly[2];
        const a4 = o.ary1[3] - o.aryly[3];
        const a5 = o.ary1[4] - o.aryly[4];
        const a6 = o.ary1[5] - o.aryly[5];
        const a7 = o.ary1[6] - o.aryly[6];
        const a8 = o.ary1[7] - o.aryly[7];
        const a9 = o.ary1[8] - o.aryly[8];
        const a10 = o.ary1[9] - o.aryly[9];
        const a11 = o.ary1[10] - o.aryly[10];
        const a12 = o.ary1[11] - o.aryly[11];
        const monthlyData: number[] = [a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, a12];

        const calculatedArray = [{ val: a1, arg: 'Jan', avg: this.getMovingAverage(monthlyData, 0) },
        { val: a2, arg: 'Feb', avg: this.getMovingAverage(monthlyData, 1) },
        { val: a3, arg: 'Mar', avg: this.getMovingAverage(monthlyData, 2) },
        { val: a4, arg: 'Apr', avg: this.getMovingAverage(monthlyData, 3) },
        { val: a5, arg: 'May', avg: this.getMovingAverage(monthlyData, 4) },
        { val: a6, arg: 'Jun', avg: this.getMovingAverage(monthlyData, 5) },
        { val: a7, arg: 'Jul', avg: this.getMovingAverage(monthlyData, 6) },
        { val: a8, arg: 'Aug', avg: this.getMovingAverage(monthlyData, 7) },
        { val: a9, arg: 'Sep', avg: this.getMovingAverage(monthlyData, 8) },
        { val: a10, arg: 'Oct', avg: this.getMovingAverage(monthlyData, 9) },
        { val: a11, arg: 'Nov', avg: this.getMovingAverage(monthlyData, 10) },
        { val: a12, arg: 'Dec', avg: this.getMovingAverage(monthlyData, 11) }];
        return calculatedArray;
    }

    setTooltip(arg) {
        return { text: arg.argumentText + "    " + arg.valueText };
    }

    getArray(r): number[] {
        return r.array;
    }

    setColor(i): string {
        return "style='background-color:" + i.color + "'";
    }

    getLarger(a, b): number {
        if (a > b) {
            return a;
        } else {
            return b;
        }
    }
}
