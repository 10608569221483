import { RootState } from '../../store.reducer';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { Project } from 'src/app/models/projects/project';
import { GenericMap } from 'src/app/models/generic-map';
import { selectUsers } from '../../users/selectors/multi-select-users.selector';
import { User } from 'src/app/models/admin/users/user';
import { IdNameItem } from 'src/app/models/id-name-item';
import { selectIdNameCurrentUser } from '../../users/selectors/id-name-current-user.selector';
import { FileMetaData } from 'src/app/models/file-meta-data';
import { extractParamId } from 'src/app/utils/extractParamId';

export const selectAllProjects = (state: RootState) => state.projects.data;
export const selectProjectParamId = (state: RootState) =>
  !!state.router
    ? !!state.router.state.root.firstChild
      ? extractParamId(state.router.state.root, 'projects')
      : undefined
    : undefined;

export const selectCurrentProject: MemoizedSelector<RootState, Project> = createSelector(
  selectProjectParamId,
  selectAllProjects,
  selectUsers,
  selectIdNameCurrentUser,
  (
    id: number,
    projects: GenericMap<Project>,
    users: GenericMap<User>,
    currentUser: IdNameItem
  ): Project => {
    let project: Partial<Project>;
    if (
      !!id &&
      Object.keys(projects).length &&
      Object.keys(users).length &&
      !!currentUser &&
      !!projects[id]
    ) {
      const selectedProject = projects[id];
      const createdByUser: User = users[selectedProject.createdBy.toString()];
      const projectOwnerUser: User = users[selectedProject.projectOwner.toString()];
      const address =
        !!projects[id].address.city && !!projects[id].address.state
          ? projects[id].address
          : null;
      project = {
        ...selectedProject,
        links: selectedProject.links.filter(link => link !== ''),
        address,
        assignedToItems: !!(
          !!selectedProject.assignedTo || selectedProject.assignedTo.length
        )
          ? selectedProject.assignedTo
              .filter((userId: number) => {
                return !!users[userId.toString()];
              })
              .map((userId: number) => {
                const user: User = users[userId.toString()];
                return {
                  id: user.id,
                  name: user.firstName + ' ' + user.lastName
                };
              })
          : [],
        createdByItem: {
          id: !!createdByUser ? createdByUser.id : null,
          name: !!createdByUser
            ? createdByUser.firstName + ' ' + createdByUser.lastName
            : 'Inactive user'
        },
        files: projects[id].files.map((file: FileMetaData) => {
          const uploadedBy = users[file.uploadedBy as number];
          return {
            ...file,
            uploadedByItem: !!uploadedBy
              ? {
                  name: uploadedBy.firstName + ' ' + uploadedBy.lastName,
                  id: uploadedBy.id
                }
              : {
                  name: '--',
                  id: null
                }
          };
        }),
        projectOwnerItem: {
          id: !!projectOwnerUser ? projectOwnerUser.id : null,
          name: !!projectOwnerUser
            ? projectOwnerUser.firstName + ' ' + projectOwnerUser.lastName
            : 'Inactive user'
        }
      };
    } else if (!id && !!currentUser) {
      project = {
        name: '',
        address: null,
        bidDate: null,
        bidDateEstimate: null,
        closeDate: null,
        closeDateEstimate: null,
        amount: null,
        amountEstimate: null,
        assignedToItems: [],
        description: '',
        projectOwnerItem: currentUser,
        links: []
      };
    }
    return project as Project;
  }
);
