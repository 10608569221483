<call-note-popup *ngIf="callNotePopup" [callNotePopup]="callNotePopup" (callNotePopupChange)="callNotePopupChanged($event)" [(callNote)]="selectedCallNote"
	[(contacts)]="companyJPI.contacts" [(companyId)]="companyJPI.id" [(callNotes)]="callNotes" (callNoteNotChanged)="resetCallNotes($event)"></call-note-popup>

<div class="loading-container" *ngIf="loadingCallNotes" fxFlex fxLayout="column" fxLayoutAlign="center center">
    <dx-load-indicator id="medium-indicator" height="40" width="40"></dx-load-indicator>
    <h3>Loading...</h3>
</div>
<mat-tab-group>
    <!-- TODO figure out why virtual scrolling isn't working. Partially because max height doesn't work as a style-->
    <mat-tab label="Call Notes">
        <div *ngIf="!loadingCallNotes">
            <div style="max-height: 700px">
                <dx-data-grid *ngIf="callNotes && callNotes.length>0" [dataSource]="callNotes" [showColumnLines]="false"
                    [showRowLines]="true" [showBorders]="true" [rowAlternationEnabled]="true" (onRowClick)="editCallNote($event, true)">
                    <dxo-scrolling mode="infinite"></dxo-scrolling>
                    <dxo-search-panel [visible]="true" [width]="250" placeholder="Search..."></dxo-search-panel>
                    <dxi-column [width]="150" caption="Created By" [calculateCellValue]="calculateCreatedBy"
                        [allowSearch]="true" [allowSorting]="true"></dxi-column>
                    <dxi-column dataField="callDate" [width]="90" caption="Call Date" dataType="date" [allowSearch]="true"></dxi-column>
                    <dxi-column caption="Call Type" [calculateCellValue]="calculateCallType" [allowSearch]="true"
                        [allowSorting]="true"></dxi-column>
                    <dxi-column caption="Contacts" [calculateCellValue]="calculateContacts" [allowSearch]="true"
                        [allowSorting]="true"></dxi-column>
                    <dxi-column caption="All narratives" [calculateCellValue]="calculateNarrative" [allowSearch]="true"
                        [allowSorting]="true"></dxi-column>
                    <dxi-column caption="Follow Up" [calculateCellValue]="calculateFollowUp" [allowSearch]="true"
                        [allowSorting]="true"></dxi-column>
                    <dxi-column caption="Follow Up Start Date" cellTemplate="startDateTemplate"></dxi-column>
                    <div *dxTemplate="let startDate of 'startDateTemplate'">
                        <span *ngIf="startDate.data.followUp && startDate.data.followUp.dueDateStart && startDate.data.followUp.dueDateStart.toString() != 'Invalid Date'">{{startDate.data.followUp.dueDateStart
                            | date:'short'}}</span>
                    </div>
                    <dxi-column *ngIf="editCallNotesFeature==1" caption="Complete" cellTemplate="completeTemplate"
                        alignment="center"></dxi-column>
                    <div *dxTemplate="let complete of 'completeTemplate'">
                        <dx-check-box *ngIf="complete.data.followUp && complete.data.followUp.task" [(value)]="complete.data.followUp.complete"
                            (onOptionChanged)="completeCheckBoxChange(complete.data, $event)"></dx-check-box>
                    </div>
                </dx-data-grid>
                <div fxLayout="row" fxLayoutAlign="end end">
                    <span class="link" style="padding-top: 10px" (click)="loadMoreCallNotesClick()">Show more call
                        notes</span>
                </div>
                <div *ngIf="!callNotes || callNotes.length==0" style="padding-top: 10px">There are no
                    call
                    notes for&nbsp;{{companyJPI.name}}.</div>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="Follow-ups">
        <div style="max-height: 700px">
            <dx-data-grid *ngIf="followUps && followUps.length>0" [dataSource]="followUps" [showColumnLines]="false"
                [showRowLines]="true" [showBorders]="true" [rowAlternationEnabled]="true" (onRowClick)="editCallNote($event, false)">
                <dxo-scrolling mode="virtual"></dxo-scrolling>
                <dxo-search-panel [visible]="true" [width]="250" placeholder="Search..."></dxo-search-panel>
                <dxi-column width="33%" caption="Follow Up" [calculateCellValue]="calculateFollowUp" [allowSearch]="true"
                    [allowSorting]="true"></dxi-column>
                <dxi-column caption="Start Date" cellTemplate="startDateTemplate"></dxi-column>
                <div *dxTemplate="let startDate of 'startDateTemplate'">
                    <span *ngIf="startDate.data.followUp && startDate.data.followUp.dueDateStart && startDate.data.followUp.dueDateStart.toString() != 'Invalid Date'">{{startDate.data.followUp.dueDateStart
                        | date:'short'}}</span>
                </div>
                <dxi-column caption="End Date" cellTemplate="endDateTemplate"></dxi-column>
                <div *dxTemplate="let endDate of 'endDateTemplate'">
                    <span *ngIf="endDate.data.followUp && endDate.data.followUp.dueDateEnd && endDate.data.followUp.dueDateEnd.toString() != 'Invalid Date'">{{endDate.data.followUp.dueDateEnd
                        | date:'short'}}</span>
                </div>
                <dxi-column caption="Assigned Sales Rep" [calculateCellValue]="calculateAssignedAgent" [allowSearch]="true"
                    [allowSorting]="true"></dxi-column>
                <dxi-column caption="Complete" cellTemplate="completeTemplate" alignment="center"></dxi-column>
                <div *dxTemplate="let complete of 'completeTemplate'">
                    <dx-check-box [(value)]="complete.data.followUp.complete" (onOptionChanged)="completeCheckBoxChange(complete.data, $event)"></dx-check-box>
                </div>
            </dx-data-grid>
            <div *ngIf="showMoreCallNotesButton" fxLayout="row" fxLayoutAlign="end end">
                <span class="link" style="padding-top: 10px" (click)="loadMoreCallNotesClick()">Show more call
                    notes</span>
            </div>
            <div *ngIf="!followUps || followUps.length==0" style="padding-top: 10px">There are no
                follow-ups for&nbsp;{{companyJPI.name}}.</div>
        </div>
    </mat-tab>
</mat-tab-group>