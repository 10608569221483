import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  DeletePrivateNoteBatchFailure,
  DeletePrivateNoteBatchSuccess,
  DeletePrivateNoteFailure,
  DeletePrivateNoteSuccess,
  LoadPrivateNotesAssignableUsersSuccess,
  LoadPrivateNotesByCompanyIdFailure,
  LoadPrivateNotesByCompanyIdSuccess,
  LoadPrivateNotesFailure,
  LoadPrivateNotesSuccess,
  PrivateNotesActionTypes,
  PrivateNotesActions,
  SavePrivateNoteFailure,
  SavePrivateNoteSuccess,
  UpdatePrivateNoteFailure,
  UpdatePrivateNoteSuccess
} from './private-notes.actions';
import { PageableData, PrivateNote } from 'src/app/models/private-notes/private-note';
import { PrivateNotesService } from 'src/app/services/private-notes/private-notes.service';
import { RootState } from '../store.reducer';
import { Action, Store } from '@ngrx/store';
import { catchError, forkJoin, map, mergeAll, mergeMap, of, switchMap } from 'rxjs';
import { User } from 'src/app/models/admin/users/user';
import { convertToMap } from 'src/app/utils/convertToMap';

type SuccessfulResult = { success: true; id: number };
type FailedResult = { success: false; id: number; error: any };
type Result = SuccessfulResult | FailedResult;

@Injectable()
export class PrivateNotesEffects {
  constructor(
    private actions$: Actions<PrivateNotesActions>,
    private privateNotesService: PrivateNotesService
  ) {}

  loadPrivateNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PrivateNotesActionTypes.LoadPrivateNotes),
      map((action) => action.payload),
      switchMap(({ limit, offset }) =>
        this.privateNotesService.getPrivateNotes(limit, offset).pipe(
          map((res: PageableData<PrivateNote>) => {
            return new LoadPrivateNotesSuccess(res);
          }),
          catchError((error) => of(new LoadPrivateNotesFailure(error)))
        )
      )
    )
  );

  loadPrivateNotesByCompanyId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PrivateNotesActionTypes.LoadPrivateNotesByCompanyId),
      map((action) => action.payload),
      switchMap(({ companyId, limit, offset }) =>
        this.privateNotesService
          .getPrivateNotesByCompanyId(companyId, limit, offset)
          .pipe(
            map((res: PageableData<PrivateNote>) => {
              return new LoadPrivateNotesByCompanyIdSuccess(res);
            }),
            catchError((error) => of(new LoadPrivateNotesByCompanyIdFailure(error)))
          )
      )
    )
  );

  loadPrivateNotesAssignableUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PrivateNotesActionTypes.LoadPrivateNotesAssignableUsers),
      switchMap(() =>
        this.privateNotesService.getPrivateNotesAssignableUsers().pipe(
          map((response: User[]) => {
            return new LoadPrivateNotesAssignableUsersSuccess(
              convertToMap(response, 'id')
            );
          }),
          catchError((error) => of(new LoadPrivateNotesAssignableUsersSuccess(error)))
        )
      )
    )
  );

  savePrivateNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PrivateNotesActionTypes.SavePrivateNote),
      map((action) => action.payload),
      switchMap((privateNote) =>
        this.privateNotesService.savePrivateNote(privateNote).pipe(
          map((privateNote) => {
            return new SavePrivateNoteSuccess(privateNote);
          }),
          catchError((error) => of(new SavePrivateNoteFailure(error)))
        )
      )
    )
  );

  updatePrivateNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PrivateNotesActionTypes.UpdatePrivateNote),
      map((action) => action.payload),
      switchMap((privateNote: PrivateNote) =>
        this.privateNotesService.updatePrivateNoteById(privateNote).pipe(
          map((privateNote) => {
            return new UpdatePrivateNoteSuccess(privateNote);
          }),
          catchError((error) => of(new UpdatePrivateNoteFailure(error)))
        )
      )
    )
  );

  deletePrivateNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PrivateNotesActionTypes.DeletePrivateNote),
      map((action) => action.payload),
      switchMap((id: number) =>
        this.privateNotesService.deletePrivateNoteById(id).pipe(
          map((note: PrivateNote) => {
            return new DeletePrivateNoteSuccess(note.id);
          }),
          catchError((error) => of(new DeletePrivateNoteFailure(error)))
        )
      )
    )
  );

  deletePrivateNoteBatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PrivateNotesActionTypes.DeletePrivateNoteBatch),
      map((action) => action.payload),
      mergeMap((ids: number[]) =>
        forkJoin(
          ids.map((id) =>
            this.privateNotesService.deletePrivateNoteById(id).pipe(
              map(() => ({ success: true, id } as SuccessfulResult)),
              catchError((error) => of({ success: false, id, error } as FailedResult))
            )
          )
        )
      ),
      map((results: Result[]) => {
        const successfulIds = results
          .filter((result) => result.success)
          .map((result) => result.id);
        const failures = results
          .filter((result): result is FailedResult => !result.success)
          .map(({ id, error }) => ({ id, error }));

        const actions: Action[] = [];
        if (successfulIds.length > 0) {
          actions.push(new DeletePrivateNoteBatchSuccess(successfulIds));
        }
        if (failures.length > 0) {
          actions.push(new DeletePrivateNoteBatchFailure(failures));
        }
        return actions;
      }),
      mergeAll(),
      map((action) => action as Action)
    )
  );
}
