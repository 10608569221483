import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  concatMap,
  withLatestFrom,
  switchMap,
  tap,
  mergeMap
} from 'rxjs/operators';
import { of } from 'rxjs';
import {
  LoadOpportunitiesFailure,
  LoadOpportunitiesSuccess,
  OpportunitiesActionTypes,
  OpportunitiesActions,
  LoadOpportunitiesTableColumnsSuccess,
  LoadOpportunitiesTableColumnsFailure,
  SaveOpportunitySuccess,
  RedirectToOpportunities,
  SaveOpportunityFailure,
  DeleteOpportunitySuccess,
  DeleteOpportunitySuccessNoRedirect,
  DeleteOpportunityFailure,
  LoadOpportunities,
  DeleteOpportunity,
  UploadOpportunityFileFailure,
  DeleteOpportunityFileFailure,
  SaveOpportunity,
  DeleteOpportunityFileSuccess,
  UploadOpportunityFileSuccess,
  RedirectToOpportunity,
  LoadOpportunityItemsTableColumnsSuccess,
  LoadOpportunityItemsTableColumnsFailure,
  LoadOpportunity,
  LoadOpportunitySuccess,
  LoadOpportunityFailure,
  LoadOppItemCustomFieldsSuccess,
  LoadOppItemCustomFieldsFailure,
  DeleteOppItemCustomField,
  UpdateOppItemCustomField,
  CreateOppItemCustomField,
  UpdateOppItemCustomFieldFailure,
  DeleteOppItemCustomFieldFailure,
  CreateOppItemCustomFieldFailure,
  UpdateOppItemCustomFieldSuccess,
  CreateOppItemCustomFieldSuccess,
  DeleteOppItemCustomFieldSuccess,
  LoadOppItemCustomFields,
  AddLabelColumns,
  LabelColumnsAdded,
  NoLabelColsAdded,
  LoadOpportunitiesElasticSuccess,
  LoadOpportunitiesElasticFailure,
  LoadOppItemsElasticSuccess,
  LoadOppItemsElasticFailure,
  LoadOpportunityCustomFieldsSuccess,
  LoadOpportunityCustomFieldsFailure,
  CreateOpportunityCustomField,
  CreateOpportunityCustomFieldSuccess,
  CreateOpportunityCustomFieldFailure,
  UpdateOpportunityCustomField,
  UpdateOpportunityCustomFieldSuccess,
  UpdateOpportunityCustomFieldFailure,
  DeleteOpportunityCustomField,
  DeleteOpportunityCustomFieldSuccess,
  DeleteOpportunityCustomFieldFailure,
  LoadOpportunityCustomFields,
  ReorderOpportunityCustomFields,
  ReorderOpportunityCustomFieldsSuccess,
  SaveOpportunityCustomFieldValue,
  SaveOpportunityCustomFieldValueSuccess,
  SaveOpportunityCustomFieldValueFailure,
  DeleteOpportunityCustomFieldValue,
  DeleteOpportunityCustomFieldValueSuccess,
  DeleteOpportunityCustomFieldValueFailure
} from './opportunities.actions';
import { OpportunitiesTableColumnsData } from './columns.data';
import { ColumnHeader, ColumnType } from 'src/app/models/column-header';
import { Store } from '@ngrx/store';
import { RootState } from '../store.reducer';
import { OpportunitiesService } from '../../services/opportunities/opportunities.service';
import { convertToMap } from 'src/app/utils/convertToMap';
import { HttpErrorResponse } from '@angular/common/http';
import { Opportunity } from 'src/app/models/Opportunity/opportunity';
import { Router } from '@angular/router';
import { selectCurrentOpportunity } from './selectors/current-opportunity.selector';
import {
  OpenErrorSnackbar,
  OpenSnackbar,
  SetLoadingBarVisibility
} from '../layout/layout.actions';
import { FilesService } from 'src/app/services/files/files.service';
import { FileMetaData } from 'src/app/models/file-meta-data';
import { OpportunityItemsTableColumnsData } from './itemColumns.data';
import { ItemCustomField } from 'src/app/models/Opportunity/item-custom-field';
import { LoadContactCustomFields } from '../system-settings/contact-custom-fields/contact-custom-fields.actions';
import { GenericMap } from 'src/app/models/generic-map';
import { LabelGroup } from 'src/app/models/label-group';
import { LoadUsers } from '../users/users.actions';
import { JpiResponse } from 'src/app/models/http/jpi-response.model';
import { ElasticOpportunity } from 'src/app/models/Opportunity/elastic-opportunity';
import { ElasticOppItem } from 'src/app/models/Opportunity/elastic-opp-item';
import { OpportunityCustomField } from 'src/app/models/Opportunity/opportunity-custom-field';
import { OpportunityCustomFieldValue } from 'src/app/models/Opportunity/opportunity-custom-field-value';

@Injectable()
export class OpportunitiesEffects {
  addLabelColumns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunitiesActionTypes.AddLabelColumns),
      withLatestFrom(
        this.store$.select('opportunityLabels', 'data'),
        this.store$.select('opportunities', 'useDefaultColumns')
      ),
      map(
        ([action, labels, useDefaultCols]: [
          AddLabelColumns,
          GenericMap<LabelGroup>,
          boolean
        ]) => {
          if (!!labels && !!Object.values(labels).length && useDefaultCols) {
            const labelCols: ColumnHeader[] = Object.values(labels).map((label) => ({
              name: label.name,
              displayName: label.name,
              type: ColumnType.LABEL
            }));
            return new LabelColumnsAdded(labelCols);
          }
          return new NoLabelColsAdded();
        }
      )
    )
  );

  loadOpportunities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunitiesActionTypes.LoadOpportunities),
      concatMap(() =>
        this.oppService.getOpportunities().pipe(
          switchMap((opportunities: Opportunity[]) => {
            return [
              // new LoadUsers(),
              new LoadOpportunitiesSuccess(convertToMap(opportunities, 'id'))
            ];
          }),
          catchError((error: HttpErrorResponse) =>
            of(new LoadOpportunitiesFailure(error))
          )
        )
      )
    )
  );

  loadOpportunitiesElastic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunitiesActionTypes.LoadOpportunitiesElastic),
      concatMap((action) => {
        return this.oppService
          .getOpportunitiesElastic(
            {
              filters: action.payload.filters,
              should: action.payload.should,
              andShould: action.payload.andShould,
              orReps: action.payload.orReps,
              orLabels: action.payload.orLabels
            },
            {
              ...action.payload.params
            }
          )
          .pipe(
            map((res: JpiResponse<ElasticOpportunity>) => {
              return new LoadOpportunitiesElasticSuccess({
                count: res.count,
                items: res.items,
                fetchingMore: parseInt(action.payload.params.skip, 10) > 0
              });
            }),
            catchError((error: HttpErrorResponse) =>
              of(new LoadOpportunitiesElasticFailure(error))
            )
          );
      })
    )
  );

  loadOppItemsElastic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunitiesActionTypes.LoadOppItemsElastic),
      concatMap((action) => {
        return this.oppService
          .getOppItemsElastic(
            {
              filters: action.payload.filters,
              should: action.payload.should,
              andShould: action.payload.andShould,
              orReps: action.payload.orReps,
              orLabels: action.payload.orLabels
            },
            {
              ...action.payload.params
            }
          )
          .pipe(
            map((res: JpiResponse<ElasticOppItem>) => {
              return new LoadOppItemsElasticSuccess({
                count: res.count,
                items: res.items,
                fetchingMore: parseInt(action.payload.params.skip, 10) > 0
              });
            }),
            catchError((error: HttpErrorResponse) =>
              of(new LoadOppItemsElasticFailure(error))
            )
          );
      })
    )
  );

  loadOpportunity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunitiesActionTypes.LoadOpportunity),
      concatMap((action: LoadOpportunity) =>
        this.oppService.getOpportunity(action.payload).pipe(
          map((opportunity: Opportunity) => {
            return new LoadOpportunitySuccess(opportunity);
          }),
          catchError((error: HttpErrorResponse) => of(new LoadOpportunityFailure(error)))
        )
      )
    )
  );

  oppError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OpportunitiesActionTypes.LoadOpportunityFailure,
        OpportunitiesActionTypes.LoadOpportunitiesFailure,
        OpportunitiesActionTypes.SaveOpportunityFailure,
        OpportunitiesActionTypes.DeleteOpportunityFailure,
        OpportunitiesActionTypes.DeleteOpportunityFileFailure,
        OpportunitiesActionTypes.UploadOpportunityFileFailure
      ),
      map((action: { type: string; payload: HttpErrorResponse }) => action.payload),
      map((error: HttpErrorResponse) => {
        return new OpenErrorSnackbar({
          duration: 10000,
          message: `${error.status}: ${error.message}`,
          opened: true
        });
      })
    )
  );

  saveOpportunity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunitiesActionTypes.SaveOpportunity),
      withLatestFrom(this.store$),
      map((actionAndStore) => ({
        payload: actionAndStore[0].payload,
        store: actionAndStore[1]
      })),
      concatMap(({ payload, store }) => {
        const agencyId = store.auth.data.jpi.user.agencyId;
        const opportunity = {
          ...payload,
          agencyId
        };
        if (!opportunity.id) {
          return this.oppService.createOpportunity(opportunity).pipe(
            switchMap((res: Opportunity) => [
              new SaveOpportunitySuccess(res),
              new RedirectToOpportunity(res.id)
            ]),
            catchError((error) => of(new SaveOpportunityFailure(error)))
          );
        } else {
          return this.oppService.updateOpportunity(opportunity).pipe(
            map((res: Opportunity) => new SaveOpportunitySuccess(res)),
            catchError((error) => of(new SaveOpportunityFailure(error)))
          );
        }
      })
    )
  );

  deleteOpportunity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunitiesActionTypes.DeleteOpportunity),

      concatMap(({ payload }) => {
        if (payload.redirect) {
          return this.oppService.deleteOpportunity(payload.id).pipe(
            map(() => new DeleteOpportunitySuccess()),
            catchError((error) => of(new DeleteOpportunityFailure(error)))
          );
        } else {
          return this.oppService.deleteOpportunity(payload.id).pipe(
            map(() => new DeleteOpportunitySuccessNoRedirect()),
            catchError((error) => of(new DeleteOpportunityFailure(error)))
          );
        }
      })
    )
  );

  saveOpportunitySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunitiesActionTypes.SaveOpportunitySuccess),
      mergeMap((action) => [
        new LoadOpportunity(action.payload.id),
        new OpenSnackbar({
          duration: 5000,
          message: 'Opportunity saved successfully',
          opened: true
        })
      ])
    )
  );

  deleteOpportunitySuccessNoRedirect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunitiesActionTypes.DeleteOpportunitySuccessNoRedirect),
      switchMap(() => [new LoadOpportunities()])
    )
  );

  deleteOpportunitySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunitiesActionTypes.DeleteOpportunitySuccess),
      switchMap(() => [new RedirectToOpportunities(), new LoadOpportunities()])
    )
  );

  redirectToOpportunities$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OpportunitiesActionTypes.RedirectToOpportunities),
        tap(() => {
          this.router.navigate(['/opportunities'], { queryParamsHandling: 'preserve' });
        })
      ),
    { dispatch: false }
  );

  redirectToOpportunity$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OpportunitiesActionTypes.RedirectToOpportunity),
        map((action: RedirectToOpportunity) => action.payload),
        mergeMap((oppId: number) =>
          this.router.navigate([`/opportunities/opportunity/${oppId}/items`], {
            queryParamsHandling: 'preserve'
          })
        )
      ),
    { dispatch: false }
  );

  loadOpportunitiesTableColumns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunitiesActionTypes.LoadOpportunitiesTableColumns),
      concatMap(() => {
        return of(OpportunitiesTableColumnsData).pipe(
          map((res: ColumnHeader[]) => {
            return new LoadOpportunitiesTableColumnsSuccess(res);
          }),
          catchError((error) => of(new LoadOpportunitiesTableColumnsFailure(error)))
        );
      })
    )
  );

  loadOpportunityItemTableColumns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunitiesActionTypes.LoadOpportunityItemsTableColumns),
      concatMap(() => {
        return of(OpportunityItemsTableColumnsData).pipe(
          map((res: ColumnHeader[]) => {
            return new LoadOpportunityItemsTableColumnsSuccess(res);
          }),
          catchError((error) => of(new LoadOpportunityItemsTableColumnsFailure(error)))
        );
      })
    )
  );

  uploadOpportunityFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunitiesActionTypes.UploadOpportunityFile),
      tap(() => this.store$.dispatch(new SetLoadingBarVisibility(true))),
      withLatestFrom(this.store$.select(selectCurrentOpportunity)),
      map((actionAndStore) => ({
        file: actionAndStore[0].payload,
        opportunity: actionAndStore[1]
      })),
      switchMap(({ file, opportunity }) => {
        return this.filesService.uploadFile(file).pipe(
          switchMap((res: FileMetaData) => [
            new SetLoadingBarVisibility(false),
            new UploadOpportunityFileSuccess(res),
            new OpenSnackbar({
              duration: 5000,
              message: 'Uploaded file successfully',
              opened: true
            }),
            new SaveOpportunity({
              ...opportunity,
              files: [...opportunity.files, { id: res.id } as FileMetaData]
            })
          ]),
          catchError((error: HttpErrorResponse) =>
            of(new UploadOpportunityFileFailure(error))
          )
        );
      })
    )
  );

  uploadOpportunityFileFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunitiesActionTypes.UploadOpportunityFileFailure),
      map((action) => action.payload),
      switchMap((error: HttpErrorResponse) => {
        return [
          new SetLoadingBarVisibility(false),
          new OpenErrorSnackbar({
            duration: 10000,
            message: error.status === 413 ? 'Unsupported tile type' : error.message,
            opened: true
          })
        ];
      })
    )
  );

  deleteOpportunityFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunitiesActionTypes.DeleteOpportunityFile),
      withLatestFrom(this.store$.select(selectCurrentOpportunity)),
      map((actionAndStore) => ({
        fileId: actionAndStore[0].payload,
        opportunity: actionAndStore[1]
      })),
      concatMap(({ fileId, opportunity }) => {
        return this.filesService.deleteFile(fileId).pipe(
          switchMap((res: FileMetaData) => [
            new DeleteOpportunityFileSuccess(res),
            new SaveOpportunity({
              ...opportunity,
              files: opportunity.files.filter((file) => file.id !== fileId)
            })
          ]),
          catchError((error) => of(new DeleteOpportunityFileFailure(error)))
        );
      })
    )
  );

  loadOppItemCustomFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunitiesActionTypes.LoadOppItemCustomFields),
      concatMap(() => {
        return this.oppService.getOppItemCustomFields().pipe(
          map((cfs: ItemCustomField[]) => {
            return new LoadOppItemCustomFieldsSuccess(convertToMap(cfs, 'id'));
          }),
          catchError((error: HttpErrorResponse) =>
            of(new LoadOppItemCustomFieldsFailure(error))
          )
        );
      })
    )
  );

  createOppItemCustomField$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunitiesActionTypes.CreateOppItemCustomField),
      map((action: CreateOppItemCustomField) => action.payload),
      mergeMap((field: ItemCustomField) =>
        this.oppService.createOppItemCustomField(field).pipe(
          switchMap((newField: ItemCustomField) => [
            new CreateOppItemCustomFieldSuccess(newField),
            new OpenSnackbar({
              duration: 5000,
              message: 'Custom field created successfully',
              opened: true
            })
          ]),
          catchError((error: HttpErrorResponse) =>
            of(new CreateOppItemCustomFieldFailure(error))
          )
        )
      )
    )
  );

  updateOppItemCustomField$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunitiesActionTypes.UpdateOppItemCustomField),
      map((action: UpdateOppItemCustomField) => action.payload),
      mergeMap((field: ItemCustomField) =>
        this.oppService.updateOppItemCustomField(field).pipe(
          switchMap((updatedField: ItemCustomField) => [
            new UpdateOppItemCustomFieldSuccess(updatedField),
            new SetLoadingBarVisibility(false)
          ]),
          catchError((error: HttpErrorResponse) =>
            of(new UpdateOppItemCustomFieldFailure(error))
          )
        )
      )
    )
  );

  deleteOppItemCustomField$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunitiesActionTypes.DeleteOppItemCustomField),
      map((action: DeleteOppItemCustomField) => action.payload),
      mergeMap((id: number) =>
        this.oppService.deleteOppItemCustomField(id).pipe(
          switchMap(() => [
            new DeleteOppItemCustomFieldSuccess(id),
            new SetLoadingBarVisibility(false),
            new OpenSnackbar({
              duration: 5000,
              message: 'Custom field deleted successfully',
              opened: true
            })
          ]),
          catchError((error: HttpErrorResponse) =>
            of(new DeleteOppItemCustomFieldFailure(error))
          )
        )
      )
    )
  );

  changeItemCustomFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OpportunitiesActionTypes.CreateOppItemCustomField,
        OpportunitiesActionTypes.UpdateOppItemCustomField,
        OpportunitiesActionTypes.DeleteOppItemCustomField
      ),
      map(() => new SetLoadingBarVisibility(true))
    )
  );

  refetchItemCustomFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OpportunitiesActionTypes.CreateOppItemCustomFieldSuccess,
        // OpportunitiesActionTypes.UpdateOppItemCustomFieldSuccess,
        OpportunitiesActionTypes.DeleteOppItemCustomFieldSuccess
      ),
      switchMap(() => [new SetLoadingBarVisibility(false), new LoadOppItemCustomFields()])
    )
  );

  changeOppItemCustomFieldFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OpportunitiesActionTypes.CreateOppItemCustomFieldFailure,
        OpportunitiesActionTypes.UpdateOppItemCustomFieldFailure,
        OpportunitiesActionTypes.DeleteOppItemCustomFieldFailure
      ),
      map((action) => action.payload),
      switchMap((error: HttpErrorResponse) => {
        return [
          new SetLoadingBarVisibility(false),
          new OpenErrorSnackbar({
            duration: 10000,
            message: `${error.status} - ${error.message}`,
            opened: true
          })
        ];
      })
    )
  );

  loadOpportunityCustomFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunitiesActionTypes.LoadOpportunityCustomFields),
      concatMap(() => {
        return this.oppService.getOpportunityCustomFields().pipe(
          map((cfs: OpportunityCustomField[]) => {
            return new LoadOpportunityCustomFieldsSuccess(convertToMap(cfs, 'id'));
          }),
          catchError((error: HttpErrorResponse) =>
            of(new LoadOpportunityCustomFieldsFailure(error))
          )
        );
      })
    )
  );

  createOpportunityCustomField$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunitiesActionTypes.CreateOpportunityCustomField),
      map((action: CreateOpportunityCustomField) => action.payload),
      mergeMap((field: OpportunityCustomField) =>
        this.oppService.createOpportunityCustomField(field).pipe(
          switchMap((newField: OpportunityCustomField) => [
            new CreateOpportunityCustomFieldSuccess(newField),
            new OpenSnackbar({
              duration: 5000,
              message: 'Custom field created successfully',
              opened: true
            })
          ]),
          catchError((error: HttpErrorResponse) =>
            of(new CreateOpportunityCustomFieldFailure(error))
          )
        )
      )
    )
  );

  updateOpportunityCustomField$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunitiesActionTypes.UpdateOpportunityCustomField),
      map((action: UpdateOpportunityCustomField) => action.payload),
      mergeMap((field: OpportunityCustomField) =>
        this.oppService.updateOpportunityCustomField(field).pipe(
          switchMap((updatedField: OpportunityCustomField) => [
            new UpdateOpportunityCustomFieldSuccess(updatedField),
            new SetLoadingBarVisibility(false)
          ]),
          catchError((error: HttpErrorResponse) =>
            of(new UpdateOpportunityCustomFieldFailure(error))
          )
        )
      )
    )
  );

  deleteOpportunityCustomField$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunitiesActionTypes.DeleteOpportunityCustomField),
      map((action: DeleteOpportunityCustomField) => action.payload),
      mergeMap((id: number) =>
        this.oppService.deleteOpportunityCustomField(id).pipe(
          switchMap(() => [
            new DeleteOpportunityCustomFieldSuccess(id),
            new OpenSnackbar({
              duration: 5000,
              message: 'Custom field deleted successfully',
              opened: true
            })
          ]),
          catchError((error: HttpErrorResponse) =>
            of(new DeleteOpportunityCustomFieldFailure(error))
          )
        )
      )
    )
  );

  reorderOpportunityCustomField$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunitiesActionTypes.ReorderOpportunityCustomFields),
      map((action: ReorderOpportunityCustomFields) => action.payload),
      mergeMap((fields: OpportunityCustomField[]) =>
        this.oppService.reorderOpportunityCustomFields(fields).pipe(
          switchMap((reorderedFields: OpportunityCustomField[]) => [
            new ReorderOpportunityCustomFieldsSuccess(
              convertToMap(reorderedFields, 'id')
            ),
            new SetLoadingBarVisibility(false)
          ]),
          catchError((error: HttpErrorResponse) =>
            of(new UpdateOpportunityCustomFieldFailure(error))
          )
        )
      )
    )
  );

  changeOppCustomFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OpportunitiesActionTypes.CreateOpportunityCustomField,
        OpportunitiesActionTypes.UpdateOpportunityCustomField,
        OpportunitiesActionTypes.DeleteOpportunityCustomField
      ),
      map(() => new SetLoadingBarVisibility(true))
    )
  );

  refetchOppCustomFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OpportunitiesActionTypes.CreateOpportunityCustomFieldSuccess,
        // OpportunitiesActionTypes.UpdateOpportunityCustomFieldSuccess,
        OpportunitiesActionTypes.DeleteOpportunityCustomFieldSuccess
      ),
      switchMap(() => [
        new SetLoadingBarVisibility(false),
        new LoadOpportunityCustomFields()
      ])
    )
  );

  changeOppCustomFieldFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OpportunitiesActionTypes.CreateOpportunityCustomFieldFailure,
        OpportunitiesActionTypes.UpdateOpportunityCustomFieldFailure,
        OpportunitiesActionTypes.DeleteOpportunityCustomFieldFailure
      ),
      map((action) => action.payload),
      switchMap((error: HttpErrorResponse) => {
        return [
          new SetLoadingBarVisibility(false),
          new OpenErrorSnackbar({
            duration: 10000,
            message: `${error.status} - ${error.message}`,
            opened: true
          })
        ];
      })
    )
  );

  saveOpportunityCustomFieldValue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunitiesActionTypes.SaveOpportunityCustomFieldValue),
      map((action: SaveOpportunityCustomFieldValue) => action.payload),
      concatMap((payload: OpportunityCustomFieldValue) => {
        if (payload.id) {
          return this.oppService.updateOpportunityCustomFieldValue(payload).pipe(
            switchMap((res) => [
              new SaveOpportunityCustomFieldValueSuccess(res),
              new LoadOpportunity(payload.opportunityId)
            ]),
            catchError((error) => of(new SaveOpportunityCustomFieldValueFailure(error)))
          );
        } else {
          return this.oppService.createOpportunityCustomFieldValue(payload).pipe(
            switchMap((res) => [
              new SaveOpportunityCustomFieldValueSuccess(res),
              new LoadOpportunity(payload.opportunityId)
            ]),
            catchError((error) => of(new SaveOpportunityCustomFieldValueFailure(error)))
          );
        }
      })
    )
  );

  deleteProjectCustomFieldValue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpportunitiesActionTypes.DeleteOpportunityCustomFieldValue),
      map((action: DeleteOpportunityCustomFieldValue) => action.payload),
      concatMap((payload: OpportunityCustomFieldValue) => {
        return this.oppService.deleteOpportunityCustomFieldValue(payload).pipe(
          switchMap(() => [
            new DeleteOpportunityCustomFieldValueSuccess(),
            new LoadOpportunity(payload.opportunityId)
          ]),
          catchError((error) => of(new DeleteOpportunityCustomFieldValueFailure(error)))
        );
      })
    )
  );

  constructor(
    private router: Router,
    private oppService: OpportunitiesService,
    private store$: Store<RootState>,
    private filesService: FilesService,
    private actions$: Actions<OpportunitiesActions>
  ) {}
}
