<dx-popup [position]="{ my: 'right', at: 'right', of: window }" [width]="600" [height]="'100vh'" [showTitle]="false" [dragEnabled]="false"
    [closeOnOutsideClick]="false" [(visible)]="childCompanyPopup" shadingColor="rgba(0,0,0,0.5)">
    <div *dxTemplate="let data of 'content'">
        <div fxLayout="column" style="height: calc(100% - 50px);">
            <dx-scroll-view [height]="'inherit'">
                <div class="popup-title">
                    <span *ngIf="companyJPI">Add child company under {{ companyJPI.name }}</span>
                </div>
                <div fxLayout="column" style="padding-top: 10px">
                    <span>Search for existing company</span>
                    <dx-tag-box *ngIf="companies" [dataSource]="companies" searchEnabled="true" displayExpr="name" valueExpr="id" [(value)]="childCompanyRequest.ChildCompanyIds"
                        [searchEnabled]="true" [showSelectionControls]="true" applyValueMode="instantly" [hideSelectedItems]="true"
                        [multiline]="true" (onKeyUp)="searchCompanies($event)"></dx-tag-box>
                </div>
                <div fxLayout="column" fxFlex="35">
                    <span>Or</span>
                    <span class="button button-white" (click)="createChildCompany()">Create new company</span>
                </div>
            </dx-scroll-view>
        </div>
        <div fxLayout="row" fxLayoutAlign="end end">
            <div fxFlex fxLayoutAlign="end end">
                <span class="button button-white" (click)="childCompanyPopup=false">Cancel</span>
                <span class="button button-blue" (click)="saveChildCompany()">Save</span>
            </div>
        </div>
    </div>
</dx-popup>