import { Component, Input, OnInit } from '@angular/core';

import { TerritoryV2 } from '../../models/admin/territories/territory-V2';
import { UserV2 } from '../../models/admin/users/user-V2';
import { CompanyV2 } from '../../models/companies/company-V2';
import { CompanyService } from './../../services/companies/company.service';
import { CompanyPermissionsService } from './../../services/permissions/company-permissions.service';

@Component({
	selector: 'company-popup-territories-and-reps',
	templateUrl: './company-popup-territories-and-reps.component.html',
	styleUrls: ['./company-popup.component.scss']
})
export class CompanyPopupTerritoriesAndRepsComponent implements OnInit {
	@Input() company: CompanyV2;
	@Input() territories: TerritoryV2[];
	@Input() primaryUserId: number;
	selectedTerritoryIds: number[] = [];
	selectedTerritories: TerritoryV2[] = [];
	primaryUsers: UserV2[] = [];
	canAssignTerritories: boolean;
	primaryUsersIds: number[] = [];
	territoriesLoaded: boolean;

	constructor(private _companyService: CompanyService, private _companyPermissionsService: CompanyPermissionsService) {
	}

	ngOnInit() {
		if (!this.territories) {
			setTimeout(() => {
				this.ngOnInit();
				return;
			}, 50);
		} else {
			this.getAssignmentPermissions();
			this.getCompanysTerritories();
		}
	}

	getAssignmentPermissions() {
		if (this.company.id) {
			this._companyService.canEditCompany(this.company).then(canEditCompany => {
				if (canEditCompany) {
					this._companyPermissionsService.getAssignPermission().then(result => { this.canAssignTerritories = result; });
				}
			});
		} else {
			this._companyPermissionsService.getAssignPermission().then(result => this.canAssignTerritories = result);
		}
	}

	getCompanysTerritories(): void {
		if (this.company.territories) {
			this.company.territories.map((territory: TerritoryV2) => {
				if (territory.users) {
					territory.users.map((user: UserV2) => {
						user.fullName = `${user.firstName} ${user.lastName}`;
						this.primaryUsers.push(user);
					});
				}
				this.selectedTerritoryIds.push(territory.id);
				this.selectedTerritories.push(territory);
			});
		}
	}

	territorySelectionChanged(e: any): void {
		this.updateSelectedTerritories(e);
		this.populatePrimaryUsersData();

		this.primaryUsers.map((user: UserV2) => { user.fullName = `${user.firstName} ${user.lastName}`; });
		this.primaryUsers.sort((a: UserV2, b: UserV2) => a.fullName.localeCompare(b.fullName));
		if (!this.primaryUsersIds.includes(this.primaryUserId)) { this.primaryUserId = null; }
	}

	updateSelectedTerritories(e: any): void {
		if (e.addedItems.length > 0) {
			e.addedItems.map((territory: TerritoryV2) => this.selectedTerritories.push(territory));
		} else if (e.removedItems.length > 0) {
			e.removedItems.map((territory: TerritoryV2) => {
				this.selectedTerritories = this.selectedTerritories.filter((terr: TerritoryV2) => terr.id != territory.id);
			});
		}
	}

	populatePrimaryUsersData(): void {
		this.primaryUsers = [];
		this.primaryUsersIds = [];

		this.selectedTerritories.forEach(territory => {
			if (territory.users) {
				territory.users.forEach(user => {
					if (!this.primaryUsersIds.includes(user.id)) {
						this.primaryUsersIds.push(user.id);
						this.primaryUsers.push(user);
					}
				});
			}
		});
	}
}
