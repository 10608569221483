import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { combineLatest, take } from 'rxjs';
import { RouteDatum } from 'src/app/models/routing/route-datum.model';
import { RootState } from 'src/app/store/store.reducer';
import { selectSidenavLinks } from '../sidenav/sidenav-links.selector';

@Component({
  selector: 'tn-select-landing-page-dialogue',
  templateUrl: './select-landing-page-dialogue.component.html',
  styleUrls: ['./select-landing-page-dialogue.component.scss']
})
export class SelectLandingPageDialogueComponent implements OnInit {
  links: RouteDatum[];
  selectedOptions = ['dashboard'];
  landingPageSelected = false;
  constructor(private store: Store<RootState>) {}

  ngOnInit(): void {
    combineLatest([
      this.store.select('layout', 'defaultLandingPage'),
      this.store.select(selectSidenavLinks)
    ])
      .pipe(take(1))
      .subscribe(([landingPage, links]: [string, RouteDatum[]]) => {
        this.links = links;
        this.landingPageSelected = !!landingPage;
        this.selectedOptions = [landingPage || 'dashboard'];
      });
  }
}
