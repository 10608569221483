<div class="label-form">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <mat-form-field style="margin-right: 10px">
      <span class="input-icon" matPrefix> <mat-icon>search</mat-icon>&nbsp; </span>
      <input
        matInput
        (keyup)="applyFilter($event.target.value)"
        autocomplete="off"
        placeholder="Filter labels by group name"
      />
    </mat-form-field>
  </div>
  <div *ngIf="!filteredGroupsArray?.length">
    <i
      >No labels have been added to this company, please edit the company to add labels</i
    >
  </div>
  <div *ngFor="let group of filteredGroupsArray">
    <h4>{{ group.name }}</h4>
    <mat-chip-list>
      <mat-chip
        *ngFor="let label of group.labels"
        [removable]="true"
        (removed)="deleteLabel(label)"
        >{{ label.name
        }}<mat-icon color="warn" matChipRemove *ngIf="removable"
          >cancel</mat-icon
        ></mat-chip
      >
    </mat-chip-list>
  </div>
  <mat-progress-bar *ngIf="$pending | async" mode="indeterminate"></mat-progress-bar>
</div>
