<div class="child-form">
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="12px">
    <h3 [style.marginTop]="'0'" [style.marginBottom]="'10px'">
      Child Companies
    </h3>
    <div [style.minWidth]="'296px'">
      <form
        [formGroup]="addCompanyForm"
        (submit)="onSubmit($event)"
        class="new-company-form"
        (click)="$event.stopPropagation()"
        (keydown.tab)="$event.stopPropagation()"
        (keydown.shift.tab)="$event.stopPropagation()"
      >
        <div fxLayout="row">
          <tn-company-select
            fxFlex="1 1"
            (companySearchEvent)="onCompanySearch($event)"
            [$companySearchResults]="$companies"
            [$error]="$companiesSearchError"
            [placeholder]="'Add a child company'"
            (companySelected)="onCompanySelect($event)"
            [showCompanyLink]="false"
            [$focusEvent]="$focusEventSubject"
            [$clearInputEvent]="$addCompanyFormEventsSubject"
            [$pending]="$companiesSearchPending"
          >
          </tn-company-select>
          <button
            mat-icon-button
            color="primary"
            type="submit"
            [disabled]="addCompanyForm.invalid"
          >
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </form>
    </div>
  </div>
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    *ngIf="company.directChildCompanies.length"
  >
    <mat-form-field fxFlex="100">
      <span class="input-icon" matPrefix> <mat-icon>search</mat-icon>&nbsp; </span>
      <input
        matInput
        (keyup)="applyFilter($event.target.value)"
        autocomplete="off"
        placeholder="Filter existing child companies"
      />
    </mat-form-field>
  </div>
  <mat-progress-bar *ngIf="$pending | async" mode="indeterminate"></mat-progress-bar>
  <p style="text-align: center" *ngIf="company?.directChildCompanies?.length === 0">
    <i>
      There are currently no child companies associated with this company.
    </i>
  </p>
  <div
    *ngIf="company?.directChildCompanies?.length > 0"
    fxLayout="column"
    fxLayoutGap="16px"
  >
    <mat-card
      class="mat-elevation-z3"
      *ngFor="let child of filteredChildCompanies"
      style="margin: 10px"
      ><mat-card-content fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="column" fxLayoutAlign="start start">
          <h4 [style.marginTop]="0" [style.marginBottom]="'8px'">{{ child?.name }}</h4>
          <div [style.color]="'#999999'">
            <div *ngIf="hasShippingAddress(child.shippingAddress); else billingAddress">
              {{ child.shippingAddress | formatAddress }}
            </div>
            <ng-template #billingAddress>
              <div>{{ child.billingAddress | formatAddress }}</div>
            </ng-template>
          </div>
        </div>
        <div fxLayout="row">
          <button mat-icon-button color="warn" (click)="deleteChild(child)">
            <mat-icon>delete</mat-icon>
          </button>
          <a [routerLink]="['/companies/company/', child.id]" target="_blank">
            <button mat-icon-button color="primary">
              <mat-icon>call_made</mat-icon>
            </button>
          </a>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
