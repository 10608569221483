import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ExpenseType } from '../../../models/activities/expense-type';

export enum ExpenseTypesActionTypes {
  SelectExpenseTypeId = '[ExpenseTypes] Select Expense Type ID',
  ClearExpenseTypeId = '[ExpenseTypes] Clear Selected Expense Type Id',
  LoadExpenseTypes = '[ExpenseTypes] Load ExpenseTypes',
  LoadExpenseTypesSuccess = '[ExpenseTypes] Load ExpenseTypes Success',
  LoadExpenseTypesFailure = '[ExpenseTypes] Load ExpenseTypes Failure',
  CreateExpenseTypes = '[ExpenseTypes] Create Expense Types',
  CreateExpenseTypesSuccess = '[ExpenseTypes] Create Expense Types Success',
  CreateExpenseTypesFailure = '[ExpenseTypes] Create Expense Types Failure',
  UpdateExpenseTypes = '[ExpenseTypes] Update Expense Types',
  UpdateExpenseTypesSuccess = '[ExpenseTypes] Update Expense Types Success',
  UpdateExpenseTypesFailure = '[ExpenseTypes] Update Expense Types Failure',
  DeleteExpenseTypes = '[ExpenseTypes] Delete Expense Types',
  DeleteExpenseTypesSuccess = '[ExpenseTypes] Delete Expense Types Success',
  DeleteExpenseTypesFailure = '[ExpenseTypes] Delete Expense Types Failure'
}

export class SelectExpenseTypeId implements Action {
  readonly type = ExpenseTypesActionTypes.SelectExpenseTypeId;
  constructor(public payload: number) {}
}

export class ClearExpenseTypeId implements Action {
  readonly type = ExpenseTypesActionTypes.ClearExpenseTypeId;
}

export class LoadExpenseTypes implements Action {
  readonly type = ExpenseTypesActionTypes.LoadExpenseTypes;
}

export class LoadExpenseTypesSuccess implements Action {
  readonly type = ExpenseTypesActionTypes.LoadExpenseTypesSuccess;
  constructor(public payload: ExpenseType[]) {}
}

export class LoadExpenseTypesFailure implements Action {
  readonly type = ExpenseTypesActionTypes.LoadExpenseTypesFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class CreateExpenseTypes implements Action {
  readonly type = ExpenseTypesActionTypes.CreateExpenseTypes;
  constructor(public payload: ExpenseType) {}
}

export class CreateExpenseTypesSuccess implements Action {
  readonly type = ExpenseTypesActionTypes.CreateExpenseTypesSuccess;
  constructor(public payload: ExpenseType) {}
}

export class CreateExpenseTypesFailure implements Action {
  readonly type = ExpenseTypesActionTypes.CreateExpenseTypesFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class UpdateExpenseTypes implements Action {
  readonly type = ExpenseTypesActionTypes.UpdateExpenseTypes;
  constructor(public payload: ExpenseType) {}
}

export class UpdateExpenseTypesSuccess implements Action {
  readonly type = ExpenseTypesActionTypes.UpdateExpenseTypesSuccess;
  constructor(public payload: ExpenseType) {}
}

export class UpdateExpenseTypesFailure implements Action {
  readonly type = ExpenseTypesActionTypes.UpdateExpenseTypesFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class DeleteExpenseTypes implements Action {
  readonly type = ExpenseTypesActionTypes.DeleteExpenseTypes;
  constructor(public payload: number) {}
}

export class DeleteExpenseTypesSuccess implements Action {
  readonly type = ExpenseTypesActionTypes.DeleteExpenseTypesSuccess;
  constructor(public payload: number) {}
}

export class DeleteExpenseTypesFailure implements Action {
  readonly type = ExpenseTypesActionTypes.DeleteExpenseTypesFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export type ExpenseTypesActions =
  | SelectExpenseTypeId
  | ClearExpenseTypeId
  | LoadExpenseTypes
  | LoadExpenseTypesSuccess
  | LoadExpenseTypesFailure
  | CreateExpenseTypes
  | CreateExpenseTypesSuccess
  | CreateExpenseTypesFailure
  | UpdateExpenseTypes
  | UpdateExpenseTypesSuccess
  | UpdateExpenseTypesFailure
  | DeleteExpenseTypes
  | DeleteExpenseTypesSuccess
  | DeleteExpenseTypesFailure;
