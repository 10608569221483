import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, mergeMap, switchMap } from 'rxjs/operators';
import { Observable, EMPTY, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { TodoService } from 'src/app/services/todo/todo.service';
import { HttpErrorResponse } from '@angular/common/http';
import {
  TodosActionTypes,
  CreateTodoFailure,
  LoadCompleteTodosFailure,
  LoadCompleteTodosSuccess,
  CreateTodoSuccess,
  CreateTodo,
  LoadIncompleteTodosSuccess,
  LoadIncompleteTodosFailure,
  UpdateTodo,
  UpdateTodoSuccess,
  UpdateTodoFailure,
  LoadIncompleteTodos,
  ReorderTodosSuccess,
  ReorderTodosFailure,
  ReorderTodos,
  LoadCompleteTodos,
  DeleteTodoSuccess,
  DeleteTodoFailure,
  DeleteTodo
} from './todos.actions';
import { Todo } from 'src/app/models/todo/todo';
import { OpenSnackbar, CloseDrawer } from '../layout/layout.actions';

@Injectable()
export class TodosEffects {
  loadCompleteTodos$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TodosActionTypes.LoadCompleteTodos),
      mergeMap(() =>
        this.todoService.getAllCompleteTodos().pipe(
          map((data: Todo[]) => new LoadCompleteTodosSuccess(data)),
          catchError((error: HttpErrorResponse) =>
            of(new LoadCompleteTodosFailure(error))
          )
        )
      )
    )
  );

  loadIncompleteTodos$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TodosActionTypes.LoadIncompleteTodos),
      mergeMap(() =>
        this.todoService.getAllIncompleteTodos().pipe(
          map((data: Todo[]) => new LoadIncompleteTodosSuccess(data)),
          catchError((error: HttpErrorResponse) =>
            of(new LoadIncompleteTodosFailure(error))
          )
        )
      )
    )
  );

  createTodo$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TodosActionTypes.CreateTodo),
      map((action: CreateTodo) => action.payload),
      mergeMap((todo: Todo) =>
        this.todoService.createTodo(todo).pipe(
          map((newTodo: Todo) => new CreateTodoSuccess(newTodo)),
          catchError((error: HttpErrorResponse) => of(new CreateTodoFailure(error)))
        )
      )
    )
  );

  createTodoSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TodosActionTypes.CreateTodoSuccess),
      switchMap(() => [
        new CloseDrawer(),
        new OpenSnackbar({
          duration: 5000,
          message: 'To-do created successfully',
          opened: true
        })
      ])
    )
  );

  updateTodoSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TodosActionTypes.UpdateTodoSuccess),
      switchMap(() => [
        new OpenSnackbar({
          duration: 5000,
          message: 'To-do updated successfully',
          opened: true
        })
      ])
    )
  );

  updateTodo$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TodosActionTypes.UpdateTodo),
      map((action: UpdateTodo) => action.payload),
      mergeMap((todo: Todo) =>
        this.todoService.updateTodo(todo).pipe(
          switchMap((updatedTodo: Todo) => [
            new UpdateTodoSuccess(updatedTodo),
            new LoadIncompleteTodos(),
            new LoadCompleteTodos()
          ]),
          catchError((error: HttpErrorResponse) => of(new UpdateTodoFailure(error)))
        )
      )
    )
  );

  reorderTodos$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TodosActionTypes.ReorderTodos),
      map((action: ReorderTodos) => action.payload),
      mergeMap((todos: Todo[]) =>
        this.todoService.reOrderTodos(todos).pipe(
          map((updatedTodoArray: Todo[]) => new ReorderTodosSuccess(updatedTodoArray)),
          catchError((error: HttpErrorResponse) => of(new ReorderTodosFailure(error)))
        )
      )
    )
  );

  deleteTodo$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TodosActionTypes.DeleteTodo),
      map((action: DeleteTodo) => action.payload),
      mergeMap((todo: Todo) =>
        this.todoService.deleteTodo(todo.id).pipe(
          map(() => new DeleteTodoSuccess(todo)),
          catchError((error: HttpErrorResponse) => of(new DeleteTodoFailure(error)))
        )
      )
    )
  );

  deleteTodoSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TodosActionTypes.DeleteTodoSuccess),
      map((action: DeleteTodoSuccess) => action.payload),
      switchMap((todo: Todo) => [
        new OpenSnackbar({
          duration: 5000,
          message: 'To-do deleted successfully - ' + todo.description,
          opened: true
        }),
        new LoadCompleteTodos(),
        new LoadIncompleteTodos()
      ])
    )
  );

  constructor(private todoService: TodoService, private actions$: Actions) {}
}
