import { Pipe, PipeTransform } from '@angular/core';
import { CallNote } from 'src/app/models/call-notes/call-note';

@Pipe({
  name: 'callNoteFilter'
})
export class CallNoteFilterPipe implements PipeTransform {
  transform(callNotes: CallNote[], searchText: string): CallNote[] {
    searchText = searchText ? searchText.toLowerCase() : null;
    return !!callNotes && callNotes.length > 0
      ? callNotes.filter((cn) => {
          return !!searchText
            ? cn.company.name.toLowerCase().match(searchText) ||
                Object.values(cn.company.shippingAddress)
                  .join(', ')
                  .toLowerCase()
                  .match(searchText) ||
                Object.values(cn.company.billingAddress)
                  .join(', ')
                  .toLowerCase()
                  .match(searchText) ||
                cn.contacts
                  .map((contact) =>
                    !!contact.name
                      ? contact.name
                      : contact.firstName + ' ' + contact.lastName
                  )
                  .join(', ')
                  .toLowerCase()
                  .match(searchText) ||
                cn.fields
                  .map((field) => field.name + ' ' + field.value)
                  .join(', ')
                  .toLowerCase()
                  .match(searchText) ||
                (!!cn.followUp.task &&
                  cn.followUp.task.toLowerCase().match(searchText)) ||
                (!!cn.followUp.assignedTo &&
                  !!cn.followUp.assignedTo?.name &&
                  cn.followUp.assignedTo?.name.toLowerCase().match(searchText)) ||
                (!!cn.followUp.assignedTo &&
                  !!cn.followUp.assignedTo?.firstName &&
                  cn.followUp.assignedTo?.firstName.toLowerCase().match(searchText)) ||
                (!!cn.followUp.assignedTo &&
                  !!cn.followUp.assignedTo?.lastName &&
                  cn.followUp.assignedTo?.lastName.toLowerCase().match(searchText)) ||
                (!!cn.type &&
                  !!cn.type.name &&
                  cn.type.name.toLowerCase().match(searchText)) ||
                (!!cn.createdBy &&
                  !!cn.createdBy.name &&
                  cn.createdBy.name.toLowerCase().match(searchText)) ||
                (!!cn.createdBy &&
                  !!cn.createdBy.firstName &&
                  cn.createdBy.firstName.toLowerCase().match(searchText)) ||
                (!!cn.createdBy &&
                  !!cn.createdBy.lastName &&
                  cn.createdBy.lastName.toLowerCase().match(searchText))
            : true;
        })
      : [];
  }
}
