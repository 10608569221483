<div class="container">
  <h2>Daily Activity</h2>
  <!-- Dates -->
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <mat-form-field style="width: 48%">
      <mat-label>Activity from</mat-label>
      <input
        matInput
        (dateChange)="dateChange($event)"
        [matDatepicker]="picker"
        [(ngModel)]="initialDate"
        [max]="today"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field style="width: 48%" *ngIf="seeAllSalesReps">
      <mat-label>Search Reps</mat-label>
      <input
        autocomplete="off"
        [(ngModel)]="repSearch"
        (keyup)="searchReps()"
        type="text"
        matInput
      />
      <button
        *ngIf="repSearch"
        matSuffix
        mat-icon-button
        aria-label="Clear Search"
        (click)="clearRepSearch()"
      >
        <mat-icon>close</mat-icon>
      </button>
      <mat-icon *ngIf="!repSearch" matSuffix style="vertical-align: -7.5px"
        >search</mat-icon
      >
    </mat-form-field>
  </div>
  <!-- Total Call Notes -->
  <div>
    <div>Total Call Notes</div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxFlex="60">
        <mat-chip-list>
          <span class="total-call-notes">{{ totalCallNotes }}</span>
          <mat-chip
            *ngIf="totalCallNotes > averageCallNotes"
            style="background-color: #63ce7a"
            selected
          >
            <span class="material-icons">arrow_upward </span
            >{{ totalCallNotes - averageCallNotes }}
          </mat-chip>
          <mat-chip
            *ngIf="averageCallNotes > totalCallNotes"
            style="background-color: #eb4e3d"
            selected
          >
            <span class="material-icons">arrow_downward </span
            >{{ averageCallNotes - totalCallNotes }}
          </mat-chip>
          <mat-chip
            *ngIf="averageCallNotes === totalCallNotes"
            style="background-color: #3071e2"
            selected
          >
            <span class="material-icons">arrow_forward </span>0
          </mat-chip>
        </mat-chip-list>
      </div>
      <div class="call-note-data" fxLayout="column" fxLayoutAlign="space-between" fxFlex>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div>30-Day Average</div>
          <div>{{ averageCallNotes || 0 }}</div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="totalCallNotes > 0; else noCallNotes">
    <div *ngIf="!seeMore">
      <!-- Top Sales Reps -->
      <div class="section" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="column-header">Sales reps</div>
        <div
          *ngIf="allRepsMaster.length > 3"
          class="clickable"
          (click)="toggleSeeAllSalesReps()"
        >
          See all sales reps
          <span class="material-icons" style="vertical-align: middle">
            chevron_right
          </span>
        </div>
      </div>
      <div *ngIf="allRepsMaster.length > 0; else noCallNotes">
        <div
          class="data"
          *ngFor="let rep of topReps"
          fxLayout="row"
          fxLayoutAlign="space-between center"
          (click)="toggleSeeRep(rep, 'open')"
        >
          <div>
            {{
              rep?.rep_info?.hits?.hits[0]?._source?.assignedToFirstName || rep?.firstName
            }}
            {{
              rep?.rep_info?.hits?.hits[0]?._source?.assignedToLastName || rep?.lastName
            }}
          </div>
          <div class="chip">
            <mat-chip-list>
              <span>{{ rep.doc_count }}</span>
              <mat-chip
                class="chip-text"
                *ngIf="rep?.direction === 'positive'"
                style="background-color: #63ce7a"
                selected
              >
                <span class="material-icons">arrow_upward </span>{{ rep?.difference }}
              </mat-chip>
              <mat-chip
                class="chip-text"
                *ngIf="rep?.direction === 'negative'"
                style="background-color: #eb4e3d"
                selected
              >
                <span class="material-icons">arrow_downward </span>{{ rep?.difference }}
              </mat-chip>
              <mat-chip
                class="chip-text"
                *ngIf="rep?.direction === 'neutral'"
                style="background-color: #3071e2"
                selected
              >
                <span class="material-icons">arrow_forward </span>{{ rep?.difference }}
              </mat-chip>
            </mat-chip-list>
          </div>
        </div>
      </div>
      <!-- Top Call Types -->
      <div class="section" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="column-header">Call types</div>
        <div
          *ngIf="allCallTypes.length > 3"
          class="clickable"
          (click)="toggleSeeAllCallTypes()"
        >
          See all call types
          <span class="material-icons" style="vertical-align: middle">
            chevron_right
          </span>
        </div>
      </div>
      <div *ngIf="allCallTypes.length > 0; else noCallNotes">
        <div
          class="data"
          *ngFor="let type of topCallTypes"
          fxLayout="row"
          fxLayoutAlign="space-between center"
          (click)="toggleSeeType(type, 'open')"
        >
          <div>{{ type.type_info.hits.hits[0]._source.typeName }}</div>
          <div class="chip">
            <mat-chip-list>
              <span>{{ type.doc_count }}</span>
              <mat-chip
                class="chip-text"
                *ngIf="type.direction === 'positive'"
                style="background-color: #63ce7a"
                selected
              >
                <span class="material-icons">arrow_upward </span>{{ type.difference }}
              </mat-chip>
              <mat-chip
                class="chip-text"
                *ngIf="type.direction === 'negative'"
                style="background-color: #eb4e3d"
                selected
              >
                <span class="material-icons">arrow_downward </span>{{ type.difference }}
              </mat-chip>
              <mat-chip
                class="chip-text"
                *ngIf="type.direction === 'neutral'"
                style="background-color: #3071e2"
                selected
              >
                <span class="material-icons">arrow_forward </span>{{ type.difference }}
              </mat-chip>
            </mat-chip-list>
          </div>
        </div>
      </div>
      <!-- Top Call Note Fields -->
      <div class="section" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="column-header">Call note fields</div>
        <div
          *ngIf="allCallNoteFields.length > 3"
          class="clickable"
          (click)="toggleSeeAllCallNoteFields()"
        >
          See all call note fields
          <span class="material-icons" style="vertical-align: middle">
            chevron_right
          </span>
        </div>
      </div>
      <div *ngIf="allCallNoteFields.length > 0; else noCallNotes">
        <div
          class="data"
          *ngFor="let field of topCallNoteFields"
          fxLayout="row"
          fxLayoutAlign="space-between center"
          (click)="toggleSeeField(field, 'open')"
        >
          <div>{{ field.call_note_field_info.hits.hits[0]._source.fieldName }}</div>
          <div class="chip">
            <mat-chip-list>
              <span>{{ field.doc_count }}</span>
              <mat-chip
                class="chip-text"
                *ngIf="field.direction === 'positive'"
                style="background-color: #63ce7a"
                selected
              >
                <span class="material-icons">arrow_upward </span>{{ field.difference }}
              </mat-chip>
              <mat-chip
                class="chip-text"
                *ngIf="field.direction === 'negative'"
                style="background-color: #eb4e3d"
                selected
              >
                <span class="material-icons">arrow_downward </span>{{ field.difference }}
              </mat-chip>
              <mat-chip
                class="chip-text"
                *ngIf="field.direction === 'neutral'"
                style="background-color: #3071e2"
                selected
              >
                <span class="material-icons">arrow_forward </span>0
              </mat-chip>
            </mat-chip-list>
          </div>
        </div>
      </div>
    </div>
    <!-- All Sales Reps -->
    <div *ngIf="seeAllSalesReps" class="scroll">
      <div class="section" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="column-header">All sales reps</div>
        <div class="clickable" (click)="toggleSeeAllSalesReps()">
          <span class="material-icons" style="vertical-align: middle">
            chevron_left
          </span>
          Back to summary
        </div>
      </div>
      <div
        class="data"
        *ngFor="let rep of allReps"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        (click)="toggleSeeRep(rep, 'open')"
      >
        <div>
          {{
            rep?.rep_info?.hits?.hits[0]?._source?.assignedToFirstName || rep?.firstName
          }}
          {{ rep?.rep_info?.hits?.hits[0]?._source?.assignedToLastName || rep?.lastName }}
        </div>
        <div class="chip">
          <mat-chip-list>
            <span class="all-view">{{ rep?.doc_count }}</span>
            <mat-chip
              class="chip-text"
              *ngIf="rep?.direction === 'positive'"
              style="background-color: #63ce7a"
              selected
            >
              <span class="material-icons">arrow_upward </span>{{ rep?.difference }}
            </mat-chip>
            <mat-chip
              class="chip-text"
              *ngIf="rep?.direction === 'negative'"
              style="background-color: #eb4e3d"
              selected
            >
              <span class="material-icons">arrow_downward </span>{{ rep?.difference }}
            </mat-chip>
            <mat-chip
              class="chip-text"
              *ngIf="rep?.direction === 'neutral'"
              style="background-color: #3071e2"
              selected
            >
              <span class="material-icons">arrow_forward </span>{{ rep?.difference }}
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>
    </div>
    <!-- All call note fields -->
    <div *ngIf="seeAllCallNoteFields">
      <div class="section" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="column-header">All call note fields</div>
        <div class="clickable" (click)="toggleSeeAllCallNoteFields()">
          <span class="material-icons" style="vertical-align: middle">
            chevron_left
          </span>
          Back to summary
        </div>
      </div>
      <div
        class="data"
        *ngFor="let field of allCallNoteFields"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        (click)="toggleSeeField(rep, 'open')"
      >
        <div>{{ field.call_note_field_info.hits.hits[0]._source.fieldName }}</div>
        <div class="chip">
          <mat-chip-list>
            <span class="all-view">{{ field.doc_count }}</span>
            <mat-chip
              class="chip-text"
              *ngIf="field.totalCallNotes > field.lastWeekCallNotes"
              style="background-color: #63ce7a"
              selected
            >
              <span class="material-icons">arrow_upward </span
              >{{ field.totalCallNotes - field.lastWeekCallNotes }}
            </mat-chip>
            <mat-chip
              class="chip-text"
              *ngIf="field.lastWeekCallNotes > field.totalCallNotes"
              style="background-color: #eb4e3d"
              selected
            >
              <span class="material-icons">arrow_downward </span
              >{{ field.lastWeekCallNotes - field.totalCallNotes }}
            </mat-chip>
            <mat-chip
              class="chip-text"
              *ngIf="field.lastWeekCallNotes === field.totalCallNotes"
              style="background-color: #3071e2"
              selected
            >
              <span class="material-icons">arrow_forward </span>0
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>
    </div>
    <!-- All Call Note Types -->
    <div *ngIf="seeAllCallTypes" class="scroll">
      <div class="section" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="column-header">All call types</div>
        <div class="clickable" (click)="toggleSeeAllCallTypes()">
          <span class="material-icons" style="vertical-align: middle">
            chevron_left
          </span>
          Back to summary
        </div>
      </div>
      <div
        class="data"
        *ngFor="let type of allCallTypes"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        (click)="toggleSeeType(rep, 'open')"
      >
        <div>{{ type.type_info.hits.hits[0]._source.typeName }}</div>
        <div class="chip">
          <mat-chip-list>
            <span class="all-view">{{ type.doc_count }}</span>
            <mat-chip
              class="chip-text"
              *ngIf="type.direction === 'positive'"
              style="background-color: #63ce7a"
              selected
            >
              <span class="material-icons">arrow_upward </span>{{ type.difference }}
            </mat-chip>
            <mat-chip
              class="chip-text"
              *ngIf="type.direction === 'negative'"
              style="background-color: #eb4e3d"
              selected
            >
              <span class="material-icons">arrow_downward </span>{{ type.difference }}
            </mat-chip>
            <mat-chip
              class="chip-text"
              *ngIf="type.direction === 'neutral'"
              style="background-color: #3071e2"
              selected
            >
              <span class="material-icons">arrow_forward </span>{{ type.difference }}
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>
    </div>
    <!-- Rep view -->
    <div *ngIf="seeRep">
      <div class="section" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="column-header">Call notes for {{ selectedRep.fullName }}</div>
        <div class="clickable" (click)="toggleSeeRep()">
          <span class="material-icons" style="vertical-align: middle">
            chevron_left
          </span>
          Back to summary
        </div>
      </div>
      <div *ngIf="(repCallNotes | async)?.length > 0; else noCallNotes">
        <tn-call-note
          *ngFor="let note of repCallNotes | async"
          [callNote]="note._source"
          #tnCallNote
        ></tn-call-note>
      </div>
    </div>
    <!-- Call type view -->
    <div *ngIf="seeType">
      <div class="section" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="column-header">
          Call notes for {{ selectedType?.type_info?.hits?.hits[0]?._source?.typeName }}
        </div>
        <div class="clickable" (click)="toggleSeeType()">
          <span class="material-icons" style="vertical-align: middle">
            chevron_left
          </span>
          Back to summary
        </div>
      </div>
      <div *ngIf="(typeCallNotes | async)?.length > 0; else noCallNotes">
        <tn-call-note
          *ngFor="let note of typeCallNotes | async"
          [callNote]="note._source"
        ></tn-call-note>
      </div>
    </div>
    <!-- Call field view -->
    <div *ngIf="seeField">
      <div class="section" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="column-header">
          Call notes for
          {{ selectedField?.call_note_field_info?.hits?.hits[0]?._source?.fieldName }}
        </div>
        <div class="clickable" (click)="toggleSeeField()">
          <span class="material-icons" style="vertical-align: middle">
            chevron_left
          </span>
          Back to summary
        </div>
      </div>
      <div *ngIf="(fieldCallNotes | async)?.length > 0; else noCallNotes">
        <tn-call-note
          *ngFor="let note of fieldCallNotes | async"
          [callNote]="note._source"
        ></tn-call-note>
      </div>
    </div>

    <div class="close-slide-out">
      <div>
        <button mat-mini-fab color="primary" (click)="close()">
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #noCallNotes>
  <p>
    <i>No call notes recorded on {{ initialDate | date }}</i>
  </p>
</ng-template>
