import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  concatMap,
  mergeMap,
  withLatestFrom,
  tap
} from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import {
  LoadSalesReportsFailure,
  LoadSalesReportsSuccess,
  SalesActionTypes,
  SalesActions,
  LoadCompanyAssignments,
  LoadCompanyAssignmentsSuccess,
  LoadCompanyAssignmentsFailure,
  SetCompanyAssignmentSuccess,
  SetCompanyAssignmentFailure
} from './sales.actions';
import { CompanyAssignment } from 'src/app/models/sales/companyAssignment';
import { HttpErrorResponse } from '@angular/common/http';
import { SalesService } from 'src/app/services/sales/sales.service';
import { RootState } from '../store.reducer';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

@Injectable()
export class SalesEffects {
  loadSales$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesActionTypes.LoadSalesReports),
      mergeMap((action) =>
        this.salesService.getSalesReports(action.payload).pipe(
          map((data) => new LoadSalesReportsSuccess(data)),
          catchError((error) => of(new LoadSalesReportsFailure(error)))
        )
      )
    )
  );

  loadCompanyAssignments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesActionTypes.LoadCompanyAssignments),
      mergeMap((action: LoadCompanyAssignments) =>
        this.salesService.getCompanyAssignments(action.payload).pipe(
          map(
            (companyAssignments: CompanyAssignment[]) =>
              new LoadCompanyAssignmentsSuccess(
                companyAssignments.reduce((mapObj, obj) => {
                  if (mapObj[obj.CompanyId]) {
                    mapObj[obj.CompanyId][obj.AgentMailboxId] = {
                      pending: false,
                      error: undefined,
                      data: obj
                    };
                  } else {
                    mapObj[obj.CompanyId] = {};
                    mapObj[obj.CompanyId][obj.AgentMailboxId] = {
                      pending: false,
                      error: undefined,
                      data: obj
                    };
                  }
                  return mapObj;
                }, {})
              )
          ),
          catchError((error: HttpErrorResponse) =>
            of(new LoadCompanyAssignmentsFailure(error))
          )
        )
      )
    )
  );

  setCompanyAssignment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SalesActionTypes.SetCompanyAssignment),
      concatMap(({ payload }) =>
        this.salesService
          .setCompanyAssignment(
            payload.companyId,
            payload.agentMailboxId,
            payload.associateSales
          )
          .pipe(
            map(() => new SetCompanyAssignmentSuccess(payload)),
            catchError((error: HttpErrorResponse) =>
              of(
                new SetCompanyAssignmentFailure({
                  companyId: payload.companyId,
                  agentMailboxId: payload.agentMailboxId,
                  error
                })
              )
            )
          )
      )
    )
  );

  toggleSalesModule$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SalesActionTypes.ToggleSalesModule),
        withLatestFrom(this.store$.select('sales', 'useNewSalesModule')),
        tap(([_, canUse]: [any, boolean]) => {
          if (canUse) {
            this.router.navigate(['/sales']);
          } else {
            this.router.navigate(['/salescommissions']);
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    private salesService: SalesService,
    private store$: Store<RootState>,
    private actions$: Actions<SalesActions>,
    private router: Router
  ) {}
}
