import { Injectable } from '@angular/core';

import { GlobalFilter } from '../../models/global-filter';
import { CompanyJPIService } from './company-JPI.service';
import { CallNoteV2Service } from '../call-notes/call-note-V2.service';
import { CompanyV2 } from '../../models/companies/company-V2';
import * as moment from 'moment';
import { CallNoteV2 } from '../../models/call-notes/call-note-V2';
import { ServicesModule } from 'src/app/services/services.module';

@Injectable({
  providedIn: ServicesModule
})
export class CompanySearchService {
  constructor(
    private companyJPIService: CompanyJPIService,
    private callNoteService: CallNoteV2Service
  ) {}

  toolbarSearch(searchTerm: string): any {
    const companyFilter: GlobalFilter = {
      filters: [
        {
          operand1: 'searchField',
          operator: 'CONTAINS_ALL_OF',
          operand2: <any>[searchTerm]
        }
      ]
    };

    const contactFilter: GlobalFilter = {
      filters: [
        {
          operand1: 'contacts.searchField',
          operator: 'CONTAINS_ALL_OF',
          operand2: <any>[searchTerm]
        }
      ]
    };

    const noteFilter: GlobalFilter = {
      filters: [
        {
          operand1: 'fields.value',
          operator: 'CONTAINS_ALL_OF',
          operand2: <any>[searchTerm]
        }
      ]
    };

    let filterResults: any[] = [];

    return Promise.all([
      this.companyJPIService.getJPICompaniesFiltered(0, 10, 'id', false, companyFilter),
      this.companyJPIService.getJPICompaniesFiltered(0, 10, 'id', false, contactFilter),
      this.callNoteService.getCallNotesFiltered(noteFilter, 0, 10, 'callDate')
    ]).then(result => {
      filterResults.push(result[0]); // push companies into result

      let contactMap: any[] = [];

      for (let i = 0; i < result[1].items.length; i++) {
        if (contactMap.length >= 10) {
          break;
        }

        const company: CompanyV2 = <CompanyV2>result[1].items[i];

        for (let j = 0; j < company.contacts.length; j++) {
          if (contactMap.length >= 10) {
            break;
          }
          (<any>company.contacts[j]).companyName = company.name;
          contactMap.push(company.contacts[j]);
        }
      }

      result[1].items = contactMap;

      const values: string[] = searchTerm.replace(/\W/g, '').split(' ');
      result[2].items.forEach((callNote: CallNoteV2) => {
        (<any>callNote).callDate = moment((<any>callNote).callDate).toDate();

        callNote.fields.forEach(field => {
          let isHit = true;

          for (let i = 0; i < values.length; i++) {
            if (
              !field.value
                .replace(/\W/g, '')
                .toLowerCase()
                .includes(values[i].toLowerCase())
            ) {
              isHit = false;
              break;
            }
          }

          if (isHit) {
            (<any>field).isHit = isHit;
          }
        });
      });

      filterResults.push(result[1]); // push contacts into result
      filterResults.push(result[2]); // push notes into result

      return filterResults;
    });
  }
}
