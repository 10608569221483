import { Injectable } from '@angular/core';

import { CustomerAltId, RepAltId } from '../models/sales/AltIds';
import { CommissionSplit } from '../models/sales/CommissionSplit';
import { CustomField } from '../models/sales/CustomField';
import { FileTemplate } from '../models/sales/FileTemplate';
import { Manufacturer } from '../models/sales/Manufacturer';
import { SalesSettings } from '../models/sales/SalesSettings';
import { Transaction, TransactionRevision } from '../models/sales/Transaction';
import { TransactionSplit } from '../models/sales/TransactionSplit';
import { AuthenticationService } from '../services/authentication.service';
import { CompanyService } from './companies/company.service';
import { AgentService } from './agent.service';

declare var Papa: any;

@Injectable()
export class SalesService {
	public permissions: any = {
		ViewSales: 1, ViewHistory: 7, SalesAdmin: 11, Import: 15, ManageInvoices: 16, ManualEntry: 17, ManageCommissions: 18, ManageCustomFields: 19, ManageManufacturers: 20,
		CloseMonths: 21, EditTeamReports: 22, ViewCommissions: 23, ViewAllRepCommissions: 65, ViewAgencyCommissions: 66
	};
	public salesPermissions: any = { ViewSales: 1 };
	private salesData: any[];
	private agencyReps: any[];
	private agencyCustomers: any[];
	private fileData: any = {};
	private isAdmin: string;
	private file: any;
	settings: SalesSettings;

	constructor(private authService: AuthenticationService) {
	}

	clearServiceData(): void {
		delete this.salesData;
		delete this.agencyReps;
		delete this.agencyCustomers;
		delete this.fileData;
		delete this.isAdmin;
		delete this.file;
	}

	canSeeSales(comp: any): Promise<boolean> {
		const self = this;
		return Promise.all([this.authService.getFeature(this.authService.modules.Sales)]).then(function (result) {
			if (!result[0]) {
				return false;
			}

			let permissionLevel = 0;

			for (let i = 0; i < result[0].Permissions.length; i++) {
				if (result[0].Permissions[i].Id == self.salesPermissions.ViewSales) {
					permissionLevel = parseInt(result[0].Permissions[i].Value);
					break;
				}
			}
			switch (permissionLevel) {
				case 0: // can't see sales
					return false;
				case 1: // can see primary companies sales
					return comp.PrimaryRepId == parseInt(self.authService.getCookie(self.authService.cookieNames.AgentId));
				case 2: // can see companies in users territories sales
					return self.regionIdPresent(self.authService.getAssignedRegions(), comp.territories);
				case 3: // can see companies in users territories and children territories sales
					return self.regionIdPresent(self.authService.getAssignedRegionsAndChildRegions(), comp.territories);
				case 4: // can see all sales
					return true;
				default: // permission not found, can't see sales
					return false;
			}
		});
	}

	regionIdPresent(region: number[], compRegions: any[]): boolean {
		if (compRegions) {
			for (let j = 0; j < compRegions.length; j++) {
				if (region && region.includes(compRegions[j].id)) {
					return true;
				}
			}
		}
		return false;
	}

	getPermissionValue(permission: number): Promise<string> {
		return this.authService.getFeature(this.authService.modules.Sales).then(function (result) {
			if (result) {
				for (let i = 0; i < result.Permissions.length; i++) {
					if (result.Permissions[i].Id == permission) {
						return result.Permissions[i].Value;
					}
				}
			}
			return '';
		});
	}

	hasAdmin(): Promise<boolean> {
		return this.getPermissionValue(this.permissions.SalesAdmin).then(function (result) {
			return parseInt(result) > 0;
		});
	}

	hasImport(): Promise<boolean> {
		return this.getPermissionValue(this.permissions.Import).then(function (result) {
			return result == 'true';
		});
	}

	hasManualEntry(): Promise<boolean> {
		return this.getPermissionValue(this.permissions.ManualEntry).then(function (result) {
			return result == 'true';
		});
	}

	hasCommissionVisibility(): Promise<boolean> {
		return this.getPermissionValue(this.permissions.ViewCommissions).then(function (result) {
			return parseInt(result) > 0;
		});
	}

	hasAgencyCommissionVisibility(): Promise<boolean> {
		return this.getPermissionValue(this.permissions.ViewAgencyCommissions).then(function (result) {
			return result == 'true';
		});
	}

	hasAllRepCommissionVisibility(): Promise<boolean> {
		return this.getPermissionValue(this.permissions.ViewAllRepCommissions).then(function (result) {
			return result == 'true';
		});
	}

	canManageCommissions(): Promise<boolean> {
		return this.getPermissionValue(this.permissions.ManageCommissions).then(function (result) {
			return result == 'true';
		});
	}

	canManageCustomFields(): Promise<boolean> {
		return this.getPermissionValue(this.permissions.ManageCustomFields).then(function (result) {
			return result == 'true';
		});
	}

	canManageManufacturers(): Promise<boolean> {
		return this.getPermissionValue(this.permissions.ManageManufacturers).then(function (result) {
			return result == 'true';
		});
	}

	canManageInvoices(): Promise<boolean> {
		return this.getPermissionValue(this.permissions.ManageInvoices).then(function (result) {
			return result == 'true';
		});
	}

	getImportSummariesByYear(year: number): Promise<any[]> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.2/sales/getImportSummariesByYear?year=' + year, '', 'get').then(function (result: any) {
			return self.parseImportsByYear(result);
		});
	}

	convertToArray(container: any): any[] {
		const keys = Object.keys(container);
		const temp: any[] = [];

		let i = 0;
		for (i = 0; i < keys.length; i++) {
			temp.push(container[keys[i]]);
		}

		return temp;
	}

	parseCustomFieldCsv(csvString: string): any {
		const temp: any[] = csvString.split('\n');
		const fields: any = {};

		let i = 1;
		for (i = 1; i < temp.length; i++) {
			const data: any[] = temp[i].split(',');

			if (data.length > 1) {
				fields[data[1]] = data[0];
			}
		}

		const keys = Object.keys(fields);
		if (keys.length > 0) {
			return fields;
		} else {
			return null;
		}
	}

	parseImportsByYear(csvString: string): any[] {
		const temp: any[] = csvString.split('\n');
		const imports: any[] = [];

		let i = 1;
		for (i = 1; i < temp.length; i++) {
			const data: any[] = temp[i].split(',');

			if (data.length > 1) {
				const importData: any = {};

				importData.Month = parseInt(data[0]);
				importData.Rows = parseInt(data[1]);
				importData.GrossSales = parseFloat(data[2]);
				importData.AgencyCommission = parseFloat(data[3]);
				importData.Companies = parseInt(data[4]);
				importData.Manufacturers = parseInt(data[5]);

				imports.push(importData);
			}
		}

		return imports;
	}

	getSalesByDateRange(from: Date, to: Date): Promise<any[]> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.2/sales/getSalesByDateRange?from=' + from.toLocaleDateString() + '&to=' + to.toLocaleDateString(), null, 'Get').then(function (result: any) {
			return self.parseSales(result);
		});
	}

	getSalesByDateRangeForCompany(from: Date, to: Date, companyId: number): Promise<any[]> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.2/sales/getSalesByDateRange?from=' + from.toLocaleDateString() + '&to=' + to.toLocaleDateString() + '&companyId=' + companyId, null, 'Get').then(function (result: any) {
			return self.parseSales(result);
		});
	}

	getTransactionsForMonth(month: number, year: number): Promise<Transaction[]> {
		const self = this;
		return this.authService.makeRequest('/api-v1.2.2/sales/getInvoicesByMonth?month=' + month + '&year=' + year, null, 'get').then(function (result) {
			const parsedCsv = Papa.parse(result, { delimiter: '\xa7', header: true, skipEmptyLines: true }).data;
			const tempTransactions = {};
			for (let i = 0; i < parsedCsv.length; i++) {
				const newTransaction: Transaction = <Transaction>{};
				newTransaction.TransactionId = parsedCsv[i].Id;
				newTransaction.InvoiceDate = parsedCsv[i].InvoiceDate;
				newTransaction.Sales = parseFloat(parsedCsv[i].Sales);
				newTransaction.Commission = parseFloat(parsedCsv[i].Commission);
				newTransaction.CustomerId = parsedCsv[i].CustomerId;
				newTransaction.ManufacturerId = parsedCsv[i].ManufacturerId;

				if (!tempTransactions[newTransaction.TransactionId]) {
					tempTransactions[newTransaction.TransactionId] = newTransaction;
				}

				if (parsedCsv[i].SplitId != 0) {
					const newSplit: TransactionSplit = <TransactionSplit>{};
					newSplit.SplitId = parsedCsv[i].SplitId;
					newSplit.TransactionId = parsedCsv[i].SplitTransactionId;
					newSplit.RepId = parsedCsv[i].RepId;
					newSplit.SplitAmount = parseFloat(parsedCsv[i].SplitAmount);

					if (!tempTransactions[newSplit.TransactionId].TransactionSplits) {
						tempTransactions[newSplit.TransactionId].TransactionSplits = [];
					}

					tempTransactions[newSplit.TransactionId].TransactionSplits.push(newSplit);
				}
			}

			return self.convertToArray(tempTransactions);
		});
	}

	parseSales(csvString: string): any[] {
		const temp: any[] = csvString.split('\n');
		const sales: any[] = [];

		let i = 1;
		for (i = 1; i < temp.length; i++) {
			const data: any[] = temp[i].split('\xa7');

			if (data.length > 1) {
				const transaction: any = {};

				transaction.CustomerId = data[0];
				transaction.CustomerName = data[1];
				transaction.CustomerAddress = data[2];
				transaction.CustomerCity = data[3];
				transaction.CustomerState = data[4];
				transaction.CustomerZip = data[5];
				transaction.ManufacturerId = data[6];
				transaction.ManufacturerName = data[7];
				transaction.Month = data[8];
				transaction.Year = data[9];
				transaction.Sales = parseFloat(data[10]);
				transaction.Commission = parseFloat(data[11]);
				transaction.AgentId = data[12];
				transaction.AgentName = data[13];
				transaction.AgentCommission = data[14];

				sales.push(transaction);
			}
		}

		return sales;
	}

	getSalesSettings(): Promise<SalesSettings> {
		if (this.settings) {
			return Promise.resolve(this.settings);
		} else {
			const self = this;
			return this.authService.makeRequest('/api-v1.2.2/sales/getSettings', null, 'get').then(function (result: any) {
				self.settings = JSON.parse(result);
				return self.settings;
			});
		}
	}

	updateSalesSettings(settings: SalesSettings): Promise<boolean> {
		this.settings = settings;
		this.authService.updateSalesSettings(settings);
		return this.authService.makeRequest('/api-v1.2.2/sales/updateSettings', settings, 'post').then(function (result: any) {
			return result;
		});
	}

	getCustomFieldsV2(): Promise<CustomField[]> {
		return this.authService.makeRequest('/api-v1.2.2/sales/getCustomFields', null, 'get').then(function (result: any) {
			const temp: any[] = result.split('\n');
			const customFields: CustomField[] = [];

			let i = 1;
			for (i = 1; i < temp.length; i++) {
				const data: any[] = temp[i].split('\xa7');

				if (data.length > 1) {
					const field: CustomField = <CustomField>{};

					field.Id = data[0];
					field.CreatedDate = data[1];
					field.DataType = data[2];
					field.FieldLabel = data[3];
					field.Required = data[4] == 'True' ? true : false;

					customFields.push(field);
				}
			}

			return customFields;
		});
	}

	createCustomField(field: CustomField): Promise<number> {
		return this.authService.makeRequest('/api-v1.2.2/sales/addCustomField', field, 'post').then(function (result: any) {
			return result;
		});
	}

	updateCustomField(field: CustomField): Promise<boolean> {
		return this.authService.makeRequest('/api-v1.2.2/sales/updateCustomField', field, 'post').then(function (result: any) {
			return result;
		});
	}

	deleteCustomField(field: CustomField): Promise<boolean> {
		return this.authService.makeRequest('/api-v1.2.2/sales/deleteCustomField', field, 'post').then(function (result: any) {
			return result;
		});
	}

	getCommissionSplits(): Promise<CommissionSplit[]> {
		return this.authService.makeRequest('/api-v1.2.2/sales/getCommissionSplits', null, 'get').then(function (result) {
			return Papa.parse(result, { delimiter: '\xa7', header: true, skipEmptyLines: true, dynamicTyping: true }).data;
		});
	}

	createCommissionSplit(split: CommissionSplit): Promise<number> {
		return this.authService.makeRequest('/api-v1.2.2/sales/addCommissionSplit', split, 'post').then(function (result) {
			return result;
		});
	}

	createCommissionSplits(splits: CommissionSplit[]): Promise<number> {
		return this.authService.makeRequest('/api-v1.2.2/sales/addCommissionSplits', splits, 'post').then(function (result) {
			return result;
		});
	}

	updateCommissionSplit(split: CommissionSplit): Promise<boolean> {
		return this.authService.makeRequest('/api-v1.2.2/sales/updateCommissionSplit', split, 'post').then(function (result) {
			return true;
		});
	}

	deleteCommissionSplit(split: CommissionSplit): Promise<boolean> {
		return this.authService.makeRequest('/api-v1.2.2/sales/deleteCommissionSplit', split, 'post').then(function (result) {
			return true;
		});
	}

	createManufacturer(manufacturer: Manufacturer): Promise<number> {
		return this.authService.makeRequest('/api-v1.2.2/sales/addManfacturer', manufacturer, 'post').then(function (result) {
			return result;
		});
	}

	updateManufacturer(manufacturer: Manufacturer): Promise<boolean> {
		return this.authService.makeRequest('/api-v1.2.2/sales/updateManfacturer', manufacturer, 'post').then(function (result) {
			return true;
		});
	}

	deleteManufacturer(manufacturer: Manufacturer): Promise<boolean> {
		return this.authService.makeRequest('/api-v1.2.2/sales/deleteManufacturer', manufacturer, 'post').then(function (result) {
			return true;
		});
	}

	getFileTemplates(): Promise<FileTemplate[]> {
		return this.authService.makeRequest('/api-v1.2.2/sales/getFileTemplates', null, 'get').then(function (result) {
			return Papa.parse(result, { delimiter: '\xa7', header: true, skipEmptyLines: true, dynamicTyping: true }).data;
		});
	}

	getCustomerAltIds(): Promise<CustomerAltId[]> {
		return this.authService.makeRequest('/api-v1.2.2/sales/getCustomerAltIds', null, 'get').then(function (result) {
			return Papa.parse(result, { delimiter: '\xa7', header: true, skipEmptyLines: true, dynamicTyping: true }).data;
		});
	}

	getCustomerAltIdsForManufacturer(manufacturerId: number): Promise<CustomerAltId[]> {
		return Promise.resolve(null);
	}

	createCustomerAltId(altId: CustomerAltId): Promise<number> {
		return this.authService.makeRequest('/api-v1.2.2/sales/addCustomerAltId', altId, 'post').then(function (result) {
			return result;
		});
	}

	createCustomerAltIds(altIds: CustomerAltId[]): Promise<boolean> {
		return this.authService.makeRequest('/api-v1.2.2/sales/addCustomerAltIds', altIds, 'post').then(function (result) {
			return true;
		});
	}

	deleteCustomerAltId(altId: CustomerAltId): Promise<boolean> {
		return this.authService.makeRequest('/api-v1.2.2/sales/deleteCustomerAltId', altId, 'post').then(function (result) {
			return true;
		});
	}

	deleteCustomerAltIds(altIds: number[]): Promise<boolean> {
		return this.authService.makeRequest('/api-v1.2.2/sales/deleteCustomerAltIds', altIds, 'post').then(function (result) {
			return true;
		});
	}

	getRepAltIds(): Promise<RepAltId[]> {
		return this.authService.makeRequest('/api-v1.2.2/sales/getRepAltIds', null, 'get').then(function (result) {
			return Papa.parse(result, { delimiter: '\xa7', header: true, skipEmptyLines: true, dynamicTyping: true }).data;
		});
	}

	createRepAltId(altId: RepAltId): Promise<number> {
		return this.authService.makeRequest('/api-v1.2.2/sales/addRepAltId', altId, 'post').then(function (result) {
			return result;
		});
	}

	deleteRepAltId(altId: RepAltId): Promise<boolean> {
		return this.authService.makeRequest('/api-v1.2.2/sales/deleteRepAltId', altId, 'post').then(function (result) {
			return true;
		});
	}

	deleteRepAltIds(altIds: number[]): Promise<boolean> {
		return this.authService.makeRequest('/api-v1.2.2/sales/deleteRepAltIds', altIds, 'post').then(function (result) {
			return true;
		});
	}

	getDrafts(): Promise<any> {
		return this.authService.makeRequest('/api-v1.2.2/sales/getDrafts', null, 'get').then(function (result) {
			const temp: any[] = Papa.parse(result, { delimiter: '\xa7', header: true, skipEmptyLines: true, dynamicTyping: true }).data;
			for (let i = 0; i < temp.length; i++) {
				temp[i].CreatedDate = new Date(temp[i].CreatedDate);
				temp[i].LastUpdated = new Date(temp[i].LastUpdated);
				temp[i].CreatedDate.setTime(temp[i].CreatedDate.getTime() - (temp[i].CreatedDate.getTimezoneOffset() * 60000));
				temp[i].LastUpdated.setTime(temp[i].LastUpdated.getTime() - (temp[i].LastUpdated.getTimezoneOffset() * 60000));
			}
			return temp;
		});
	}

	getDraft(draftId: number): Promise<any> {
		return this.authService.makeRequest('/api-v1.2.2/sales/getDraft?draftId=' + draftId, null, 'get').then(function (result) {
			return JSON.parse(result);
		});
	}

	saveDraft(sales: Transaction[], draftId: number, manufacturerId: number): Promise<number> {
		const request = { DraftId: draftId, ManufacturerId: manufacturerId, Transactions: sales };
		return this.authService.makeRequest('/api-v1.2.2/sales/saveDraft', request, 'post').then(function (result) {
			return result;
		});
	}

	deleteDraft(draftId: number): Promise<boolean> {
		return this.authService.makeRequest('/api-v1.2.2/sales/deleteDraft', draftId, 'post').then(function (result) {
			return true;
		});
	}

	importSales(sales: Transaction[], draftId: number, manufacturerId: number): Promise<boolean> {
		const request = { DraftId: draftId, ManufacturerId: manufacturerId, Transactions: sales };
		return this.authService.makeRequest('/api-v1.2.2/sales/importTransactions', request, 'post').then(function (result) {
			return true;
		});
	}

	createFileTemplate(template: FileTemplate): Promise<boolean> {
		return this.authService.makeRequest('/api-v1.2.2/sales/createFileTemplate', template, 'post').then(function (result) {
			return true;
		});
	}

	updateCustomerLinks(agentMailbox: number, customerIds: number[]): Promise<boolean> {
		const request: any = { AgentId: agentMailbox, Ids: customerIds };

		return this.authService.makeRequest('/api-v1.2.2/sales/updateCustomerLinks', request, 'post').then(function (result) {
			return true;
		});
	}

	getCustomerLinks(): Promise<any[]> {
		return this.authService.makeRequest('/api-v1.2.2/sales/getCustomerLinks', null, 'get').then(function (result) {
			const temp: any[] = Papa.parse(result, { delimiter: '\xa7', header: true, skipEmptyLines: true, dynamicTyping: true }).data;
			return temp;
		});
	}

	updateManufacturerLinks(agentId: number, manufacturerIds: number[]): Promise<boolean> {
		const request: any = { AgentId: agentId, Ids: manufacturerIds };

		return this.authService.makeRequest('/api-v1.2.2/sales/updateManufacturerLinks', request, 'post').then(function (result) {
			return true;
		});
	}

	getManufacturerLinks(): Promise<any[]> {
		return this.authService.makeRequest('/api-v1.2.2/sales/getManufacturerLinks', null, 'get').then(function (result) {
			const temp: any[] = Papa.parse(result, { delimiter: '\xa7', header: true, skipEmptyLines: true, dynamicTyping: true }).data;
			return temp;
		});
	}

	getRevisions(month: number, year: number): Promise<any[]> {
		return this.authService.makeRequest('/api-v1.2.2/sales/getRevisions?month=' + month + '&year=' + year, null, 'get').then(function (result) {
			return Papa.parse(result, { delimiter: '\xa7', header: true, skipEmptyLines: true, dynamicTyping: true }).data;
		});
	}

	getRevision(revisionId: number): Promise<TransactionRevision[]> {
		return this.authService.makeRequest('/api-v1.2.2/sales/getRevision?revisionId=' + revisionId, null, 'get').then(function (result) {
			return Papa.parse(result, { delimiter: '\xa7', header: true, skipEmptyLines: true, dynamicTyping: true }).data;
		});
	}

	createRevision(month: number, year: number, transactions: TransactionRevision[]): Promise<boolean> {
		const request = { RevisedMonth: month, RevisedYear: year, transactionRevisions: transactions };
		return this.authService.makeRequest('/api-v1.2.2/sales/createRevision', request, 'post').then(function (result) {
			return true;
		});
	}
}
