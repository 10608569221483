import { Pipe, PipeTransform } from '@angular/core';
import { Contact } from 'src/app/models/contacts/contact';

// Input: Contact array (or any array of objects containing firstName and lastName)

// Does: Combines first and last name, creates comma-separated list of full names.

// Output: All full contact names, comma separated, as a single string

@Pipe({ name: 'displayContacts' })
export class DisplayContactsPipe implements PipeTransform {
  transform(contacts: Contact[] | { firstName: string; lastName: string }[]) {
    const contactNamesArray: string[] = [];
    contacts.forEach(contact => {
      if (contact.firstName && contact.lastName) {
        contactNamesArray.push(contact.firstName + ' ' + contact.lastName);
      } else if (contact.firstName && !contact.lastName) {
        contactNamesArray.push(contact.firstName);
      } else if (!contact.firstName && contact.lastName) {
        contactNamesArray.push(contact.lastName);
      }
    });
    return contactNamesArray.join(', ');
  }
}
