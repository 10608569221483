import {
  TerritoryManagementActions,
  TerritoryManagementActionTypes
} from './territory-management.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { TerritoriesMap } from 'src/app/models/admin/territories/territories-map';
import { TerritoryTypesMap } from 'src/app/models/admin/territories/territory-types-map';
import { AsyncAssociationState } from 'src/app/models/async-association-state';
import { CompanySelectMap } from 'src/app/models/companies/company-select';

export interface TerritoryManagementState {
  territories: {
    pending: boolean;
    error: HttpErrorResponse;
    data: TerritoriesMap;
    companiesAssociation: AsyncAssociationState<CompanySelectMap>;
  };
  types: {
    pending: boolean;
    error: HttpErrorResponse;
    data: TerritoryTypesMap;
  };
}

export const initialState: TerritoryManagementState = {
  territories: {
    pending: false,
    error: undefined,
    data: {},
    companiesAssociation: {}
  },
  types: {
    pending: false,
    error: undefined,
    data: {}
  }
};

export function territoryManagementReducer(
  state = initialState,
  action: TerritoryManagementActions
): TerritoryManagementState {
  switch (action.type) {
    case TerritoryManagementActionTypes.LoadTerritories:
      return {
        ...state,
        territories: {
          ...state.territories,
          pending: true
        }
      };

    case TerritoryManagementActionTypes.LoadTerritoriesSuccess: {
      return {
        ...state,
        territories: {
          ...state.territories,
          pending: false,
          error: undefined,
          data: action.payload
        }
      };
    }

    case TerritoryManagementActionTypes.LoadTerritoriesFailure:
      return {
        ...state,
        territories: {
          ...state.territories,
          pending: false,
          error: action.payload,
          data: {}
        }
      };

    case TerritoryManagementActionTypes.LoadTerritoryCompanies:
      return {
        ...state,
        territories: {
          ...state.territories,
          companiesAssociation: {
            ...state.territories.companiesAssociation,
            [action.payload]: {
              ...state.territories.companiesAssociation[action.payload],
              pending: true
            }
          }
        }
      };

    case TerritoryManagementActionTypes.LoadTerritoryCompaniesSuccess:
      return {
        ...state,
        territories: {
          ...state.territories,
          companiesAssociation: {
            ...state.territories.companiesAssociation,
            [action.payload.territoryId]: {
              ...state.territories.companiesAssociation[action.payload.territoryId],
              data: action.payload.companyNamesAndIds,
              pending: false
            }
          }
        }
      };

    case TerritoryManagementActionTypes.LoadTerritoryCompaniesFailure:
      return {
        ...state,
        territories: {
          ...state.territories,
          companiesAssociation: {
            ...state.territories.companiesAssociation,
            [action.payload.territoryId]: {
              ...state.territories.companiesAssociation[action.payload.territoryId],
              error: action.payload.error,
              pending: false
            }
          }
        }
      };

    default:
      return state;
  }
}
