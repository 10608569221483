import { Injectable } from '@angular/core';

import { FieldTypeV2 } from '../../../models/admin/custom-fields/field-type';
import { AuthenticationService } from '../../authentication.service';

@Injectable()
export class FieldTypeService {
	constructor(private authService: AuthenticationService) { }

	getAllFieldTypes(): Promise<FieldTypeV2[]> {
		return this.authService.makeJpiRequest('/fieldtypes', null, null, 'get').then((result: any) => {
			return JSON.parse(result);
		});
	}

	getCallNoteFieldTypes(): Promise<FieldTypeV2[]> {
		return this.authService.makeJpiRequest('/fieldtypes', null, null, 'get').then((result: any) => {
			const fields = JSON.parse(result).filter((type: FieldTypeV2) => type.callNoteField);
			return fields;
		});
	}

	getCompanyFieldTypes(): Promise<FieldTypeV2[]> {
		return this.authService.makeJpiRequest('/fieldtypes', null, null, 'get').then((result: any) => {
			const fields = JSON.parse(result).filter((type: FieldTypeV2) => type.companyField);
			return fields;
		});
	}

	getContactFieldTypes(): Promise<FieldTypeV2[]> {
		return this.authService.makeJpiRequest('/fieldtypes', null, null, 'get').then((result: any) => {
			const fields = JSON.parse(result).filter((type: FieldTypeV2) => type.contactField);
			return fields;
		});
	}

	getFieldTypeById(id: number): Promise<FieldTypeV2[]> {
		return this.authService.makeJpiRequest('/fieldtypes/' + id, null, null, 'get').then((result: any) => {
			return JSON.parse(result);
		});
	}

	addFieldType(fieldType: FieldTypeV2): Promise<FieldTypeV2> {
		return this.authService.makeJpiRequest('/fieldtypes', null, fieldType, 'post').then((result: any) => {
			return JSON.parse(result);
		});
	}

	updateFieldType(fieldType: FieldTypeV2): Promise<FieldTypeV2> {
		return this.authService.makeJpiRequest('/fieldtypes/' + fieldType.id, null, fieldType, 'put').then((result: any) => {
			return JSON.parse(result);
		});
	}

	deleteFieldType(fieldType: FieldTypeV2, contactId: number): Promise<FieldTypeV2> {
		return this.authService.makeJpiRequest('/fieldtypes/' + fieldType.id, null, fieldType, 'delete').then((result: any) => {
			return JSON.parse(result);
		});
	}
}

