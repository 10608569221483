import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { SalesEffects } from './sales.effects';
import { salesReducer } from './sales.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('sales', salesReducer),
    EffectsModule.forFeature([SalesEffects])
  ]
})
export class SalesStateModule {}
