<div style="text-align: center">
  <h2>{{ data.header }}</h2>
</div>
<div>
  <p>
    {{ data.message }}
  </p>
</div>
<div style="text-align: center">
  <form action="https://telenotes.com">
    <input class="buttonLink" type="submit" value="Back to Voze" />
  </form>
</div>
