import { RootState } from '../../store.reducer';
import { MemoizedSelector, createSelector } from '@ngrx/store';
import { Mileage } from 'src/app/models/activities/mileage';
import { GenericMap } from 'src/app/models/generic-map';
import { TableMileage } from 'src/app/models/activities/table-mileage';
import { formatDate } from '@angular/common';

export const selectAllMileage = (state: RootState) => state.mileage.data;

export const selectMileageAsArray: MemoizedSelector<RootState, TableMileage[]> =
  createSelector(selectAllMileage, (mileageMap: GenericMap<Mileage>): TableMileage[] => {
    return Object.values(mileageMap).map((mileage: Mileage) => ({
      id: mileage.id,
      repName: !!mileage.assignedToUser
        ? mileage.assignedToUser.firstName + ' ' + mileage.assignedToUser.lastName
        : '--',
      createdDate: mileage.createdDate
        ? formatDate(new Date(mileage.createdDate), 'mediumDate', 'en-US')
        : '--',
      mileageDate: mileage.mileageDate,
      odometerStart: mileage.odometerStart,
      odometerEnd: mileage.odometerEnd,
      miles: mileage.odometerEnd - mileage.odometerStart,
      assignedToUser: mileage.assignedToUser
    }));
  });
