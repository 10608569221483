import { Address } from '../models/address';

export function formatAddressIntoOneLine(address: Address) {
  return !!address
    ? Object.values(address)
        .filter(addressLine => !!addressLine)
        .map(addressLine => addressLine.trim())
        .join(', ')
    : '';
}
