import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { Expense } from 'src/app/models/activities/expense';
import { ExpenseType } from '../../models/activities/expense-type';
import { FileMetaData } from 'src/app/models/file-meta-data';
import { ColumnHeader } from 'src/app/models/column-header';

export enum ExpensesActionTypes {
  SelectExpenseId = '[Expenses] Select Expense ID',
  ClearExpenseId = '[Expenses] Clear Selected Expense ID',
  LoadExpenses = '[Expenses] Load Expenses',
  LoadExpensesSuccess = '[Expenses] Load Expenses Success',
  LoadExpensesFailure = '[Expenses] Load Expenses Failure',
  CreateExpense = '[Expenses] Create Expense',
  CreateExpenseSuccess = '[Expenses] Create Expense Success',
  CreateExpenseFailure = '[Expenses] Create Expense Failure',
  UpdateExpense = '[Expenses] Update Expense',
  UpdateExpenseSuccess = '[Expenses] Update Expense Success',
  UpdateExpenseFailure = '[Expenses] Update Expense Failure',
  DeleteExpense = '[Expenses] Delete Expense',
  DeleteExpenseSuccess = '[Expenses] Delete Expense Success',
  DeleteExpenseFailure = '[Expenses] Delete Expense Failure',
  LoadExpenseTypes = '[Expenses] Load Expense Types',
  LoadExpenseTypesSuccess = '[Expenses] Load Expense Types Success',
  LoadExpenseTypesFailure = '[Expenses] Load Expense Types Failure',
  CancelExpense = '[Expenses] Cancel Expense',

  UploadExpenseFile = '[Expenses] Upload Expense File',
  UploadExpenseFileSuccess = '[Expenses] Upload Expense File Success',
  UploadExpenseFileFailure = '[Expenses] Upload Expense File Failure',

  UploadFileOnNewExpense = '[Expenses] Upload File On New Expense',
  UploadFileOnNewExpenseSuccess = '[Expenses] Upload File On New Expense Success',
  UploadFileOnNewExpenseFailure = '[Expenses] Upload File On New Expense Failure',

  ClearExpenseFile = '[Expenses] Clear Expense File',
  ClearExpenseFileSuccess = '[Expenses] Clear Expense File Success',
  ClearExpenseFileFailure = '[Expenses] Clear Expense File Failure',

  ClearAllExpenseFiles = '[Expenses] Clear All Expense Files',
  ClearAllExpenseFilesSuccess = '[Expenses] Clear All Expense Files Success',

  DeleteExpenseFile = '[Expenses] Delete Expense File',
  DeleteExpenseFileSuccess = '[Expenses] Delete Expense File Success',
  DeleteExpenseFileFailure = '[Expenses] Delete Expense File Failure',

  SetDateRange = '[Expenses] Set Date Range',

  LoadExpensesTableColumns = '[Expenses] Load Expenses Table Columns',
  LoadExpensesTableColumnsSuccess = '[Expenses] Load Expenses Table Columns Success',
  LoadExpensesTableColumnsFailure = '[Expenses] Load Expenses Table Columns Failure',

  LoadSelectedExpensesTableColumns = '[Expenses] Load Selected Expenses Table Columns',
  LoadSelectedExpensesTableColumnsSuccess = '[Expenses] Load Selected Expenses Table Columns Success',
  LoadSelectedExpensesTableColumnsFailure = '[Expenses] Load Selected Expenses Table Columns Failure',

  SelectExpenseTableColumns = '[Expenses] Select Expense Table Columns'
}

export class SelectExpenseId implements Action {
  readonly type = ExpensesActionTypes.SelectExpenseId;
  constructor(public payload: number) {}
}

export class ClearExpenseId implements Action {
  readonly type = ExpensesActionTypes.ClearExpenseId;
}

export class LoadExpenses implements Action {
  readonly type = ExpensesActionTypes.LoadExpenses;
  constructor(
    public payload?: {
      companyId?: number;
      startDate: string;
      endDate: string;
    }
  ) {}
}

export class LoadExpensesSuccess implements Action {
  readonly type = ExpensesActionTypes.LoadExpensesSuccess;
  constructor(public payload: Expense[]) {}
}

export class LoadExpensesFailure implements Action {
  readonly type = ExpensesActionTypes.LoadExpensesFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class CreateExpense implements Action {
  readonly type = ExpensesActionTypes.CreateExpense;
  constructor(public payload: Expense) {}
}

export class CreateExpenseSuccess implements Action {
  readonly type = ExpensesActionTypes.CreateExpenseSuccess;
  constructor(public payload: Expense) {}
}

export class CreateExpenseFailure implements Action {
  readonly type = ExpensesActionTypes.CreateExpenseFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class UpdateExpense implements Action {
  readonly type = ExpensesActionTypes.UpdateExpense;
  constructor(public payload: Expense) {}
}

export class UpdateExpenseSuccess implements Action {
  readonly type = ExpensesActionTypes.UpdateExpenseSuccess;
  constructor(public payload: Expense) {}
}

export class UpdateExpenseFailure implements Action {
  readonly type = ExpensesActionTypes.UpdateExpenseFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class DeleteExpense implements Action {
  readonly type = ExpensesActionTypes.DeleteExpense;
  constructor(public payload: number) {}
}

export class DeleteExpenseSuccess implements Action {
  readonly type = ExpensesActionTypes.DeleteExpenseSuccess;
  constructor(public payload: number) {}
}

export class DeleteExpenseFailure implements Action {
  readonly type = ExpensesActionTypes.DeleteExpenseFailure;
  constructor(public payload: HttpErrorResponse) {}
}

// Expense Types

export class LoadExpenseTypes implements Action {
  readonly type = ExpensesActionTypes.LoadExpenseTypes;
}

export class LoadExpenseTypesSuccess implements Action {
  readonly type = ExpensesActionTypes.LoadExpenseTypesSuccess;
  constructor(public payload: ExpenseType[]) {}
}

export class LoadExpenseTypesFailure implements Action {
  readonly type = ExpensesActionTypes.LoadExpenseTypesFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class CancelExpense implements Action {
  readonly type = ExpensesActionTypes.CancelExpense;
}

// Expense Files

export class UploadExpenseFile implements Action {
  readonly type = ExpensesActionTypes.UploadExpenseFile;
  constructor(public payload: Blob) {}
}

export class UploadExpenseFileSuccess implements Action {
  readonly type = ExpensesActionTypes.UploadExpenseFileSuccess;
  constructor(public payload: { fileMetaData: FileMetaData; expense: Expense }) {}
}

export class UploadExpenseFileFailure implements Action {
  readonly type = ExpensesActionTypes.UploadExpenseFileFailure;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class UploadFileOnNewExpense implements Action {
  readonly type = ExpensesActionTypes.UploadFileOnNewExpense;
  constructor(public payload: Blob) {}
}

export class UploadFileOnNewExpenseSuccess implements Action {
  readonly type = ExpensesActionTypes.UploadFileOnNewExpenseSuccess;
  constructor(public payload: FileMetaData) {}
}

export class UploadFileOnNewExpenseFailure implements Action {
  readonly type = ExpensesActionTypes.UploadFileOnNewExpenseFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class ClearExpenseFile implements Action {
  readonly type = ExpensesActionTypes.ClearExpenseFile;
  constructor(public payload: number) {}
}

export class ClearExpenseFileSuccess implements Action {
  readonly type = ExpensesActionTypes.ClearExpenseFileSuccess;
  constructor(public payload: number) {}
}

export class ClearExpenseFileFailure implements Action {
  readonly type = ExpensesActionTypes.ClearExpenseFileFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class ClearAllExpenseFiles implements Action {
  readonly type = ExpensesActionTypes.ClearAllExpenseFiles;
}

export class ClearAllExpenseFilesSuccess implements Action {
  readonly type = ExpensesActionTypes.ClearAllExpenseFilesSuccess;
}

export class DeleteExpenseFile implements Action {
  readonly type = ExpensesActionTypes.DeleteExpenseFile;
  constructor(public payload: number) {}
}

export class DeleteExpenseFileSuccess implements Action {
  readonly type = ExpensesActionTypes.DeleteExpenseFileSuccess;
  constructor(public payload: { fileId: number; expense: Expense }) {}
}

export class DeleteExpenseFileFailure implements Action {
  readonly type = ExpensesActionTypes.DeleteExpenseFileFailure;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class SetDateRange implements Action {
  readonly type = ExpensesActionTypes.SetDateRange;
  constructor(public payload: { begin: string; end: string }) {}
}

export class LoadExpensesTableColumns implements Action {
  readonly type = ExpensesActionTypes.LoadExpensesTableColumns;
}

export class LoadExpensesTableColumnsSuccess implements Action {
  readonly type = ExpensesActionTypes.LoadExpensesTableColumnsSuccess;
  constructor(public payload: string[]) {}
}

export class LoadExpensesTableColumnsFailure implements Action {
  readonly type = ExpensesActionTypes.LoadExpensesTableColumnsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadSelectedExpensesTableColumns implements Action {
  readonly type = ExpensesActionTypes.LoadSelectedExpensesTableColumns;
  constructor(public payload: string[]) {}
}

export class LoadSelectedExpensesTableColumnsSuccess implements Action {
  readonly type = ExpensesActionTypes.LoadSelectedExpensesTableColumnsSuccess;
  constructor(public payload: string[]) {}
}

export class LoadSelectedExpensesTableColumnsFailure implements Action {
  readonly type = ExpensesActionTypes.LoadSelectedExpensesTableColumnsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class SelectExpenseTableColumns implements Action {
  readonly type = ExpensesActionTypes.SelectExpenseTableColumns;
  constructor(public payload: string[]) {}
}

export type ExpensesActions =
  | SelectExpenseId
  | ClearExpenseId
  | LoadExpenses
  | LoadExpensesSuccess
  | LoadExpensesFailure
  | CreateExpense
  | CreateExpenseSuccess
  | CreateExpenseFailure
  | UpdateExpense
  | UpdateExpenseSuccess
  | UpdateExpenseFailure
  | DeleteExpense
  | DeleteExpenseSuccess
  | DeleteExpenseFailure
  | LoadExpenseTypes
  | LoadExpenseTypesSuccess
  | LoadExpenseTypesFailure
  | CancelExpense
  | UploadExpenseFile
  | UploadExpenseFileSuccess
  | UploadExpenseFileFailure
  | UploadFileOnNewExpense
  | UploadFileOnNewExpenseSuccess
  | UploadFileOnNewExpenseFailure
  | ClearExpenseFile
  | ClearExpenseFileSuccess
  | ClearExpenseFileFailure
  | ClearAllExpenseFiles
  | ClearAllExpenseFilesSuccess
  | DeleteExpenseFile
  | DeleteExpenseFileSuccess
  | DeleteExpenseFileFailure
  | SetDateRange
  | LoadExpensesTableColumns
  | LoadExpensesTableColumnsSuccess
  | LoadExpensesTableColumnsFailure
  | LoadSelectedExpensesTableColumns
  | LoadSelectedExpensesTableColumnsSuccess
  | LoadSelectedExpensesTableColumnsFailure
  | SelectExpenseTableColumns;
