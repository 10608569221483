import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { LayoutActionTypes, LayoutActions } from './layout.actions';
import { SelectCallNoteId } from '../call-notes/call-notes.actions';
import { SelectMileageId } from '../mileage/mileage.actions';
import { SelectExpenseId } from '../expenses/expenses.actions';
import { SelectExpenseTypeId } from '../system-settings/expense-types/expense-types.actions';
import { SelectCompanyLabelId } from '../system-settings/company-labels/company-labels.actions';
import { switchMap, tap } from 'rxjs/operators';
import {
  SetCurrentOppItemCustomFieldId,
  SetCurrentOppItemId,
  SetCurrentOpportunityCustomFieldId
} from '../opportunities/opportunities.actions';
import { ClearCurrentContact } from '../contacts/contacts.actions';
import { SetCurrentProjectCustomFieldId } from '../system-settings/project-custom-fields/project-custom-fields.actions';
import { SetCurrentProjectCompanyCustomFieldId } from '../system-settings/proj-comp-custom-fields/project-company-custom-fields.actions';
import { SetCurrentBidCustomFieldId } from '../system-settings/bid-custom-fields/bid-custom-fields.actions';
import { SetCurrentProjectManufCustomFieldId } from '../system-settings/proj-manuf-custom-fields/project-manuf-custom-fields.actions';

@Injectable()
export class LayoutEffects {
  constructor(private actions$: Actions<LayoutActions>) {}

  drawerClosed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LayoutActionTypes.CloseDrawer),
      switchMap(() => {
        return [
          new SelectCallNoteId(null),
          new SelectMileageId(null),
          new SelectExpenseId(null),
          new SelectExpenseTypeId(null),
          new SelectCompanyLabelId(null),
          new SetCurrentOppItemCustomFieldId(null),
          new SetCurrentOpportunityCustomFieldId(null),
          new SetCurrentOppItemId(null),
          SetCurrentProjectCustomFieldId(null),
          SetCurrentBidCustomFieldId(null),
          SetCurrentProjectCompanyCustomFieldId(null),
          SetCurrentProjectManufCustomFieldId(null),
          new ClearCurrentContact()
        ];
      })
    )
  );
}
