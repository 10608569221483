<dx-popup
  [width]="windowWidth"
  [height]="windowHeight"
  [showTitle]="false"
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [visible]="$companyProfilePopup | async"
  shadingColor="rgba(0,0,0,0.5)"
  (onHiding)="companyProfileClosed()"
>
  <div *dxTemplate="let data of 'content'">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <span class="link" (click)="openCompanyInNewTab()">Open in a new tab</span>
      <mat-icon style="cursor: pointer" (click)="companyProfileClosed()">clear</mat-icon>
    </div>
    <dx-scroll-view [height]="'inherit'" (onScroll)="profileScrolled($event)">
      <company-profile
        *ngIf="$companyProfilePopup | async"
        [isList]="true"
        [isPopup]="true"
        [popupTabsFixed]="popupTabsFixed"
        [companyId]="companyId"
      ></company-profile>
    </dx-scroll-view>
  </div>
</dx-popup>
