import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { DxSelectBoxComponent } from 'devextreme-angular';
import isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';

import { Address } from '../../models/address';
import { CustomFieldV2 } from '../../models/admin/custom-fields/custom-field-V2';
import { Company } from '../../models/companies/company';
import { CompanyV2 } from '../../models/companies/company-V2';
import { Contact } from '../../models/contacts/contact';
import { ContactV2 } from '../../models/contacts/contact-V2';
import { File } from '../../models/files/file';
import { GlobalFilter } from '../../models/global-filter';
import { CustomFieldService } from '../../services/admin/custom-field.service';
import { CompanyJPIService } from '../../services/companies/company-JPI.service';
import { CompanyService } from '../../services/companies/company.service';
import { ContactJPIService } from '../../services/contact-JPI.service';
import { ContactService } from '../../services/contact.service';
import { NotificationService } from '../../services/notification.service';
import { CompanyPermissionsService } from './../../services/permissions/company-permissions.service';
import { FilteredList } from '../../models/filteredList';

@Component({
  selector: 'contact-popup',
  templateUrl: './contact-popup.component.html',
  styleUrls: [
    '../../../shared/styles/buttons.shared.scss',
    './contact-popup.component.scss'
  ]
})
export class ContactPopupComponent implements OnInit, OnDestroy {
  @ViewChild('companySelectBox')
  companySelectBox: DxSelectBoxComponent;
  @Input() contactPopup: boolean;
  @Input() contact: ContactV2;
  @Input() contactCustomFields: CustomFieldV2[];
  @Input() contactDetails: CustomFieldV2[];
  @Input() companyFiles: File[];
  @Input() contacts: ContactV2[];
  @Input() companyId: number;
  @Input() contactCompanies: any;
  @Input() companiesAndContacts: any[];
  @Input() companies: any[];
  @Output() contactChange: EventEmitter<ContactV2> = new EventEmitter<ContactV2>();
  @Output() contactDetailsChange: EventEmitter<CustomFieldV2[]> = new EventEmitter<
    CustomFieldV2[]
  >();
  @Output() contactPopupChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() contactCustomFieldsChange: EventEmitter<CustomFieldV2[]> = new EventEmitter<
    CustomFieldV2[]
  >();
  @Output() companyFilesChange: EventEmitter<File[]> = new EventEmitter<File[]>();
  @Output() contactsChange: EventEmitter<ContactV2[]> = new EventEmitter<ContactV2[]>();
  @Output() companiesAndContactsChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() companiesChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  fileUploadPopup: boolean;
  contactsCopy: ContactV2[];
  deleteContactPopup: boolean;
  editContactsFeature: number;
  window: any = window;
  contactCopy: ContactV2;
  contactDetailsCheck: CustomFieldV2[];
  contactCustomFieldsCheck: CustomFieldV2[];
  addressExpanded: boolean;
  contactDetailsExpanded: boolean;
  customContactFieldsExpanded: boolean;
  cancelConfirmationPopup: boolean;
  websiteRules: any = /([\w\.]+\.(?:com|cc|net|ru)[^,\s]*)/;
  websiteRegex = new RegExp(/([\w\.]+\.(?:com|cc|net|ru)[^,\s]*)/);
  standardFieldsRegexTest: boolean = true;
  customFieldsRegexTest: boolean = true;
  contactCustomFieldsRegexTest: boolean = true;
  contactStandardFieldsRegexTest: boolean = true;
  cancelPopup: boolean;
  file: File;
  searchTerm: string;
  searchValue: Company[];
  companyFilter: Company[];
  companySelectBoxVisible: boolean;
  displayedCompany: Object;
  clickEditCompany: boolean;
  contactNewCompany: CompanyV2;
  isLoading: boolean;
  typingTimer: any;
  inputDelay = 300;

  constructor(
    private _customFieldService: CustomFieldService,
    private _contactService: ContactService,
    private _notificationService: NotificationService,
    private _companyPermissionService: CompanyPermissionsService,
    private _companyService: CompanyService,
    private _companyJPIService: CompanyJPIService,
    private _contactJPIService: ContactJPIService
  ) {
    _companyPermissionService
      .getContactsPermission()
      .then(result => (this.editContactsFeature = result));
  }

  ngOnInit(): void {
    if (this.contacts) {
      this.contactsCopy = JSON.parse(JSON.stringify(this.contacts));
    }
    if (this.companyId) {
      this._companyJPIService.getCompanyByIdJPI(this.companyId).then(company => {
        this.contactNewCompany = company;
      });
    }
    this.companySelectBoxVisible = true;
    if (!this.contact || Object.keys(this.contact).length == 0) {
      this.newContactInit();
    } else {
      if (!this.contact.address) {
        this.contact.address = Object.assign({});
      }
      this.contactCopy = JSON.parse(JSON.stringify(this.contact));
      if (!this.contactDetails && !this.contactCustomFields) {
        this._customFieldService.getContactFields().then(result => {
          this.contactDetails = result.filter(
            (field: CustomFieldV2) => field.IsVisible && field.IsStandard
          );
          this.contactDetailsCheck = JSON.parse(JSON.stringify(this.contactDetails));
          this.contactCustomFields = result.filter(
            (field: CustomFieldV2) => !field.IsDeleted && !field.IsStandard
          );
          this.contactCustomFieldsCheck = JSON.parse(
            JSON.stringify(this.contactCustomFields)
          );
        });
      } else if (!this.contactDetails) {
        this._customFieldService.getContactFields().then(result => {
          this.contactDetails = result.filter(
            (field: CustomFieldV2) => field.IsVisible && field.IsStandard
          );
          this.contactDetailsCheck = JSON.parse(JSON.stringify(this.contactDetails));
        });
      } else if (!this.contactCustomFields) {
        this._customFieldService.getContactFields().then(result => {
          this.contactCustomFields = result.filter(
            (field: CustomFieldV2) => !field.IsDeleted && !field.IsStandard
          );
          this.contactCustomFieldsCheck = JSON.parse(
            JSON.stringify(this.contactCustomFields)
          );
        });
      } else {
        this.contactDetailsCheck = JSON.parse(JSON.stringify(this.contactDetails));
        this.contactCustomFields = this.contactCustomFields.filter(
          (field: CustomFieldV2) => !field.IsDeleted
        );
        this.contactCustomFieldsCheck = JSON.parse(
          JSON.stringify(this.contactCustomFields)
        );
      }
    }
    if (this.companyId) {
      this._companyJPIService.getById(this.companyId).then(
        (result: CompanyV2) => {
          this.getDisplayedCompany(result);
          this.companySelectBoxVisible = false;
          this.clickEditCompany = true;
          const filter: GlobalFilter = { filters: [] };
          this._companyJPIService
            .getJPICompaniesFiltered(0, 25, 'name.keyword', true, filter)
            .then(
              (results: FilteredList) => {
                this.contactCompanies = results.items;
              },
              error => {
                throw error;
              }
            );
        },
        error => {
          throw error;
        }
      );
    }
  }

  ngOnDestroy(): void {
    this.contact = Object.assign({});
  }

  newContactInit(): void {
    this.contact = Object.assign({ address: Object.assign({}) });
    this.contactCopy = JSON.parse(JSON.stringify(this.contact));
    const filter: GlobalFilter = { filters: [] };
    if (!this.companyId) {
      this._companyJPIService
        .getJPICompaniesFiltered(0, 25, 'name.keyword', true, filter)
        .then((results: FilteredList) => {
          this.contactCompanies = results.items;
          this.companySelectBoxVisible = true;
        });
    }
    this._customFieldService.getContactFields().then(result => {
      this.contactDetails = result.filter(function(field) {
        return field.IsStandard && field.IsVisible;
      });
      this.contactDetailsCheck = JSON.parse(JSON.stringify(this.contactDetails));
      this.contactCustomFields = result.filter(function(field) {
        return !field.IsStandard && !field.IsDeleted;
      });
      this.contactCustomFieldsCheck = JSON.parse(
        JSON.stringify(this.contactCustomFields)
      );
    });
  }

  searchTermChanged(e): void {
    this._companyService.searchForCompany(this.searchTerm).then(searchResults => {
      this.companyFilter = searchResults;
    });
  }

  expandAddressRow(): void {
    this.addressExpanded = !this.addressExpanded;
  }

  expandContactDetails(): void {
    this.contactDetailsExpanded = !this.contactDetailsExpanded;
  }

  expandCustomContactFields(): void {
    this.customContactFieldsExpanded = !this.customContactFieldsExpanded;
  }

  cancelContact(): void {
    if (
      isEqual(this.contact, this.contactCopy) &&
      isEqual(this.contactDetails, this.contactDetailsCheck) &&
      isEqual(this.contactCustomFields, this.contactCustomFieldsCheck)
    ) {
      this.contactPopup = false;
      this.contactPopupChange.emit(this.contactPopup);
    } else {
      this.cancelConfirmationPopup = true;
    }
  }

  getDisplayedCompany(company: CompanyV2): any {
    this.displayedCompany = {};
    if (company.billingAddress) {
      this.displayedCompany = {
        name: company.name,
        id: company.id,
        addressLine1: company.billingAddress.addressLine1,
        addressLine2: company.billingAddress.addressLine2,
        city: company.billingAddress.city,
        state: company.billingAddress.state,
        postCode: company.billingAddress.postCode
      };
    } else {
      this.displayedCompany = {
        name: company.name,
        id: company.id
      };
    }
  }

  contactCancelled(): void {
    if (this.contactsCopy) {
      this.contacts = JSON.parse(JSON.stringify(this.contactsCopy));
    }
    this.closeContact();
  }

  standardFieldRegexTest(): void {
    for (let i = 0; i < this.contactDetails.length; i++) {
      if (this.contactDetails[i].FieldType == 11) {
        if (this.contactDetails[i].Value) {
          if (!this.websiteRegex.test(this.contactDetails[i].Value)) {
            this.contactStandardFieldsRegexTest = false;
            this._notificationService.showError(
              `Must have a valid website for ${this.contactDetails[i].Name} Field`
            );
            break;
          } else {
            this.contactStandardFieldsRegexTest = true;
          }
        }
      }
    }
  }

  customFieldRegexTest(): void {
    for (let i = 0; i < this.contactCustomFields.length; i++) {
      if (this.contactCustomFields[i].FieldType == 11) {
        if (this.contactCustomFields[i].Value) {
          if (!this.websiteRegex.test(this.contactCustomFields[i].Value)) {
            this.contactCustomFieldsRegexTest = false;
            this._notificationService.showError(
              `Must have a valid website for ${this.contactCustomFields[i].Name} Field`
            );
            break;
          } else {
            this.contactCustomFieldsRegexTest = true;
          }
        }
      }
    }
  }

  contactUpdated(contact: ContactV2): void {
    this.standardFieldRegexTest();
    this.customFieldRegexTest();
    if (
      this.contactStandardFieldsRegexTest == true &&
      this.contactCustomFieldsRegexTest == true
    ) {
      this.saveContact(contact);
    } else {
      this._notificationService.showError('Please provide valid websites');
    }
  }

  saveStandardFields(contact: ContactV2): void {
    for (const field of this.contactDetails) {
      field.ContactId = contact.id;
      field.CompanyId = this.companyId;
    }
    this._contactService.updateContactFieldValues(this.contactDetails).then(() => {
      this.saveCustomFields(contact);
    });
  }

  saveCustomFields(contact: ContactV2): void {
    for (const field of this.contactCustomFields) {
      field.ContactId = contact.id;
      field.CompanyId = this.companyId;
    }
    this._contactService.updateContactFieldValues(this.contactCustomFields).then(() => {
      if (contact.id) {
        if (this.contacts && this.contacts.length > 0) {
          for (let Contact of this.contacts) {
            if (contact.id == Contact.id) {
              Contact = Object.assign({}, contact);
            }
          }
        }
      } else {
        this.contactsChange.emit(this.contacts);
      }
    });
  }

  saveContact(contact: ContactV2): void {
    if (!contact.title) {
      contact.title = '';
    }
    if (contact.firstName && !contact.lastName) {
      contact.lastName = '';
    }
    if (!contact.firstName && contact.lastName) {
      contact.firstName = '';
    }
    if (contact.fullName) {
      delete contact.fullName;
    }
    if (!contact.email) {
      contact.email = '';
    }
    if (!contact.phone) {
      contact.phone = '';
    }
    if (!contact.fax) {
      contact.fax = '';
    }
    if (!contact.address.addressLine1) {
      contact.address.addressLine1 = '';
    }
    if (!contact.address.city) {
      contact.address.city = '';
    }
    if (!contact.address.state) {
      contact.address.state = '';
    }
    if (!contact.address.postCode) {
      contact.address.postCode = '';
    }
    if (!contact.firstName && !contact.lastName) {
      this._notificationService.showError('Must provide contact with a name');
    } else if (!this.contactNewCompany && !this.companyId) {
      this._notificationService.showError('Must select a company for the contact');
    } else {
      this.isLoading = true;
      if (contact.id) {
        if (!this.contactNewCompany || this.companyId == this.contactNewCompany.id) {
          this._contactJPIService.updateContact(this.companyId, contact).then(
            () => {
              contact.fullName = `${contact.firstName} ${contact.lastName}`;
              this._contactService.contactFromCallNote(contact);
              this.saveStandardFields(contact);
              this.closeContact();
            },
            error => {
              throw error;
            }
          );
        } else {
          this.updateContactToNewCompany(contact);
        }
      } else {
        this._contactJPIService.createContact(this.contactNewCompany.id, contact).then(
          result => {
            contact = result;
            contact.fullName = `${contact.firstName} ${contact.lastName}`;
            this.saveStandardFields(contact);
            if (this.contacts) {
              this.contacts.push(contact);
            }
            if (this.companiesAndContacts) {
              this.pushContactIntoList(contact);
            }
            this._contactService.contactFromCallNote(contact);
            this.closeContact();
          },
          error => {
            throw error;
          }
        );
      }
    }
  }

  updateContactToNewCompany(contact: ContactV2): void {
    this._companyJPIService.getById(this.companyId).then(
      (result: CompanyV2) => {
        const oldCompany: CompanyV2 = result;
        oldCompany.contacts = oldCompany.contacts.filter(
          (cont: ContactV2) => cont.id != contact.id
        );
        this.contacts = this.contacts.filter((cont: ContactV2) => cont.id != contact.id);
        this._companyJPIService.update(oldCompany).then(
          () => {
            if (!this.contactNewCompany.contacts) {
              this.contactNewCompany.contacts = [];
            }
            this.contactNewCompany.contacts.push(contact);
            this._companyJPIService.update(this.contactNewCompany).then(
              () => {
                this.saveStandardFields(contact);
                this.closeContact();
              },
              error => {
                throw error;
              }
            );
          },
          error => {
            throw error;
          }
        );
      },
      error => {
        throw error;
      }
    );
  }

  pushContactIntoList(contact: ContactV2): void {
    // if (this.companies) { this.companies.push(contact); }
    // if (this.companiesAndContacts) { this.companiesAndContacts.push(contact); }
    for (const field of this.contactDetails) {
      contact[field.Name] = field.Value;
    }
    for (const field of this.contactCustomFields) {
      contact[field.Name] = field.Value;
    }
    for (const comp of this.contactCompanies._store._array) {
      if (comp.CompanyId == this.companyId) {
        const temp = Object.assign(comp, contact);
        this.companiesAndContacts.push(temp);
        break;
      }
    }
  }

  closeContact(): void {
    this.isLoading = false;
    this.contact = Object.assign({});
    this.contactPopup = false;
    this.contactPopupChange.emit(this.contactPopup);
    this.contactChange.emit(this.contact);
    this.contactsChange.emit(this.contacts);
    this.companiesChange.emit(this.companies);
    this.companiesAndContactsChange.emit(this.companiesAndContacts);
  }

  uploadNewFile(): void {
    this.fileUploadPopup = true;
    this.file = Object.assign({});
  }

  deleteContact(contact: ContactV2): void {
    delete contact.fullName;
    this._contactJPIService.deleteContact(this.companyId, contact).then(
      () => {
        for (let i = 0; i < this.contacts.length; i++) {
          if (this.contacts[i].id == contact.id) {
            this.contacts.splice(i, 1);
          }
        }
        this.contact = Object.assign({});
        this.contactChange.emit(this.contact);
        this.deleteContactPopup = false;
        this.contactPopup = false;
        this.contactPopupChange.emit(this.contactPopup);
        this.contactsChange.emit(this.contacts);
      },
      error => {
        throw error;
      }
    );
  }

  companySearch(): void {
    if (this.companySelectBox.instance.option().text != '') {
      this.companySelectBox.instance.open();
    }
    setTimeout(() => {
      this.selectBoxCompanySearch(this.companySelectBox.instance.option().text);
    }, 20);
  }

  selectBoxCompanySearch(searchTerm?: string): any {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      const filter: GlobalFilter = { filters: [] };
      if (searchTerm) {
        filter.filters.push({
          operand1: 'name',
          operator: 'CONTAINS_ANY_OF',
          operand2: [searchTerm]
        });
        this._companyJPIService
          .getJPICompaniesFiltered(0, 25, 'name.keyword', true, filter)
          .then((results: FilteredList) => {
            results.items.map((result: any) => {
              this.cleanUpAddresses(result);
              if (isEmpty(result.billingAddress)) {
                delete result.billingAddress;
              }
              if (isEmpty(result.shippingAddress)) {
                delete result.shippingAddress;
              }
            });
            this.contactCompanies = results.items;
            this.companySelectBox.instance.open();
          });
      } else {
        this._companyJPIService
          .getJPICompaniesFiltered(0, 25, 'name.keyword', true, filter)
          .then((results: FilteredList) => {
            results.items.map((result: any) => {
              this.cleanUpAddresses(result);
              if (isEmpty(result.billingAddress)) {
                delete result.billingAddress;
              }
              if (isEmpty(result.shippingAddress)) {
                delete result.shippingAddress;
              }
            });
            this.contactCompanies = results.items;
          });
      }
    }, this.inputDelay);
  }

  cleanUpAddresses(company: CompanyV2): void {
    for (const key in company.billingAddress) {
      if (company.billingAddress.hasOwnProperty(key)) {
        company.billingAddress[key] = company.billingAddress[key].trim();
        if (company.billingAddress[key] == '') {
          delete company.billingAddress[key];
        }
      }
    }
    for (const key in company.shippingAddress) {
      if (company.shippingAddress.hasOwnProperty(key)) {
        company.shippingAddress[key] = company.shippingAddress[key].trim();
        if (company.shippingAddress[key] == '') {
          delete company.shippingAddress[key];
        }
      }
    }
  }

  changeCompany(): void {
    const filter: GlobalFilter = { filters: [] };
    this._companyJPIService
      .getJPICompaniesFiltered(0, 25, 'name.keyword', true, filter)
      .then((results: FilteredList) => {
        this.contactCompanies = results.items;
        this.displayedCompany = {};
        this.companySelectBoxVisible = true;
        this.companySelectBox.instance.reset();
        this.clickEditCompany = false;
      });
  }

  companyChanged(e): void {
    this.contactNewCompany = e.value;
  }

  checkEmptyObject(address: Address): boolean {
    return isEmpty(address);
  }
}
