import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ErrorComponent } from './error/error.component';
import { LoginComponent } from './login/login.component';
import { AuthenticationService } from './shared/services/authentication.service';
import { AppV2Component } from './app-v2.component';
import { DefaultLandingPageGuard } from '../services/default-landing-page.guard';
import { StubComponent } from './shared/stub.component';

const v2Routes: Routes = [
  {
    path: '',
    component: AppV2Component,
    children: [
      // { path: 'forgotPassword', component: ForgotPasswordComponent },
      // { path: 'resetPassword', component: ResetPasswordComponent },
      {
        path: 'signout',
        component: LoginComponent,
        data: { title: 'Login | Voze' }
      },
      {
        path: 'accountinfo',
        redirectTo: 'companies-list',
        pathMatch: 'prefix'
      },
      {
        path: 'company/app',
        redirectTo: 'companies-list',
        pathMatch: 'prefix'
      },
      {
        path: 'companies-list',
        loadChildren: () =>
          import('./modules/companies/companies.module').then((m) => m.CompaniesModule),
        data: { title: 'Companies | Voze' }
      },
      {
        path: 'activity-list',
        loadChildren: () =>
          import('./modules/activities/activities.module').then(
            (m) => m.ActivitiesModule
          ),
        data: { title: 'Call Notes | Voze' }
      },
      // { path: 'dashboard', redirectTo: 'dashboard/overview/0' },
      // {
      //   path: 'dashboard',
      //   loadChildren: () =>
      //     import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
      //   data: { title: 'Dashboard | Voze' }
      // },
      {
        path: 'salescommissions',
        loadChildren: () =>
          import('./modules/sales/sales.module').then((m) => m.SalesModule),
        data: { title: 'Sales | Voze' }
      },
      {
        path: 'maps',
        loadChildren: () =>
          import('./modules/maps/maps.module').then((m) => m.MapsModule),
        data: { title: 'Maps | Voze' }
      },
      {
        path: 'reports-classic',
        loadChildren: () =>
          import('./modules/classic/classic-reports/classic-reports.module').then(
            (m) => m.ClassicReportsModule
          ),
        data: { title: 'Reports | Voze' }
      },
      {
        path: 'callnotes',
        loadChildren: () =>
          import('./modules/classic/classic-call-notes/classic-call-notes.module').then(
            (m) => m.ClassicCallNotesModule
          ),
        data: { title: 'Callnotes | Voze' }
      },
      {
        path: 'opportunities-classic',
        loadChildren: () =>
          import(
            './modules/classic/classic-opportunities/classic-opportunities.module'
          ).then((m) => m.ClassicOpportunitiesModule),
        data: { title: 'Opportunities | Voze' }
      },
      {
        path: 'lunch',
        loadChildren: () =>
          import('./modules/lunch/lunch.module').then((m) => m.LunchModule),
        data: { title: 'Lunch | Voze' }
      },
      {
        path: 'admin',
        loadChildren: () =>
          import('./modules/admin/admin.module').then((m) => m.AdminModule),
        data: { title: 'Admin | Voze' }
      },
      {
        path: 'tutorials',
        loadChildren: () =>
          import('./modules/tutorials/tutorials.module').then((m) => m.TutorialsModule),
        data: { title: 'Tutorials | Voze' }
      },
      { path: 'tnNewDashboard', redirectTo: 'dashboard/classic', pathMatch: 'prefix' },
      { path: 'tnDashBoard', redirectTo: 'dashboard/classic', pathMatch: 'prefix' },
      {
        path: 'projects',
        loadChildren: () =>
          import('./modules/classic/classic-projects/classic-projects.module').then(
            (m) => m.ClassicProjectsModule
          ),
        data: { title: 'Projects | Voze' }
      },
      {
        path: 'tasks',
        redirectTo: 'calendar',
        data: { title: 'Tasks | Voze' },
        pathMatch: 'prefix'
      },
      {
        path: 'error',
        canActivate: [AuthenticationService],
        component: ErrorComponent,
        data: { title: 'Error | Voze' }
      },
      {
        path: 'error/:id',
        canActivate: [AuthenticationService],
        component: ErrorComponent,
        data: { title: 'Error | Voze' }
      },
      { path: '', canActivate: [DefaultLandingPageGuard], component: StubComponent },
      {
        path: '**',
        redirectTo: 'error/404',
        pathMatch: 'prefix'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(v2Routes)],
  exports: [RouterModule]
})
export class AppV2RoutingModule {}
