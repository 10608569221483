import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, concatMap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import {
  LoadExpenseTypesFailure,
  LoadExpenseTypesSuccess,
  ExpenseTypesActionTypes,
  ExpenseTypesActions,
  CreateExpenseTypes,
  CreateExpenseTypesSuccess,
  CreateExpenseTypesFailure,
  UpdateExpenseTypes,
  UpdateExpenseTypesSuccess,
  UpdateExpenseTypesFailure,
  DeleteExpenseTypes,
  DeleteExpenseTypesSuccess,
  DeleteExpenseTypesFailure
} from './expense-types.actions';
import { Action } from '@ngrx/store';
import { ExpenseTypesService } from '../../../services/system-settings/expens-types/expense-types.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ExpenseType } from '../../../models/activities/expense-type';
import { convertToMap } from 'src/app/utils/convertToMap';

@Injectable()
export class ExpenseTypesEffects {
  loadExpenseTypes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ExpenseTypesActionTypes.LoadExpenseTypes),
      mergeMap(() =>
        this.expenseTypesService.getAll().pipe(
          map((expenseTypes: ExpenseType[]) => {
            return new LoadExpenseTypesSuccess(convertToMap(expenseTypes, 'id'));
          }),
          catchError((error: HttpErrorResponse) => of(new LoadExpenseTypesFailure(error)))
        )
      )
    )
  );

  createExpenseTypes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ExpenseTypesActionTypes.CreateExpenseTypes),
      map((action: CreateExpenseTypes) => action.payload),
      mergeMap((expenseType: ExpenseType) => {
        return this.expenseTypesService.create(expenseType).pipe(
          map(
            (newExpenseType: ExpenseType) => new CreateExpenseTypesSuccess(newExpenseType)
          ),
          catchError((error: HttpErrorResponse) =>
            of(new CreateExpenseTypesFailure(error))
          )
        );
      })
    )
  );

  updateExpenseTypes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ExpenseTypesActionTypes.UpdateExpenseTypes),
      map((action: UpdateExpenseTypes) => action.payload),
      mergeMap((expenseType: ExpenseType) => {
        return this.expenseTypesService.update(expenseType).pipe(
          map(
            (updatedExpenseType: ExpenseType) =>
              new UpdateExpenseTypesSuccess(updatedExpenseType)
          ),
          catchError((error: HttpErrorResponse) =>
            of(new UpdateExpenseTypesFailure(error))
          )
        );
      })
    )
  );

  deleteExpenseTypes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ExpenseTypesActionTypes.DeleteExpenseTypes),
      map((action: DeleteExpenseTypes) => action.payload),
      mergeMap((expenseTypeId: number) =>
        this.expenseTypesService.delete(expenseTypeId).pipe(
          map(() => new DeleteExpenseTypesSuccess(expenseTypeId)),
          catchError((error: HttpErrorResponse) =>
            of(new DeleteExpenseTypesFailure(error))
          )
        )
      )
    )
  );

  constructor(
    private expenseTypesService: ExpenseTypesService,
    private actions$: Actions<ExpenseTypesActions>
  ) {}
}
