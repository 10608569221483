import { Injectable } from '@angular/core';
import { ServicesModule } from '../services.module';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { FileMetaData } from 'src/app/models/file-meta-data';
import { Observable } from 'rxjs';
import { saveAs } from 'file-saver';
import { AuthenticationService } from 'src/app/app-v2/shared/services/authentication.service';

@Injectable({
  providedIn: ServicesModule
})
export class FilesService {
  jpiUrl = environment.jpiUrl;

  constructor(private http: HttpClient, private authService: AuthenticationService) {}

  getFiles(agencyId: number): Observable<FileMetaData[]> {
    return this.http.get(`${this.jpiUrl}/files`, {
      params: new HttpParams({
        fromObject: { agencyId: agencyId.toString() }
      })
    }) as Observable<FileMetaData[]>;
  }

  getFilesByCompany(companyId: number): Observable<FileMetaData[]> {
    return this.http.get(`${this.jpiUrl}/files`, {
      params: new HttpParams({
        fromObject: { companyId: companyId.toString() }
      })
    }) as Observable<FileMetaData[]>;
  }

  getFilesForCompany(companyId: number): Promise<File[]> {
    return this.authService
      .makeFileRequest('/api-v1.2.1/filesForCompany?companyId=' + companyId, null, 'get')
      .then(result => {
        return JSON.parse(result);
      });
  }

  uploadFile(file: Blob): Observable<FileMetaData> {
    const formData: FormData = new FormData();
    const headers = new HttpHeaders();
    formData.set('file', file);
    formData.set('fileobject', '{}');
    return this.http.post(`${this.jpiUrl}/files`, formData, {
      headers
    }) as Observable<FileMetaData>;
  }

  uploadFileByCompany(file: Blob, companyId: number): Observable<FileMetaData> {
    const formData: FormData = new FormData();
    const headers = new HttpHeaders();
    const fileObject = {
      companyId: companyId.toString()
    };
    formData.set('file', file);
    formData.set('fileobject', JSON.stringify(fileObject));
    return this.http.post(`${this.jpiUrl}/files`, formData, {
      headers
    }) as Observable<FileMetaData>;
  }

  deleteFile(fileId: number): Observable<null> {
    return this.http.delete(`${this.jpiUrl}/files/${fileId}`) as Observable<null>;
  }

  downloadFile(file: FileMetaData) {
    this.http
      .get(`${this.jpiUrl}/files/${file.id}`, {
        responseType: 'blob'
      })
      .toPromise()
      .then(result => {
        saveAs(result, file.fileName);
      });
  }

  downloadImage(file: FileMetaData) {
    saveAs(file.downloadUrl, file.name, { type: file.fileType });
  }
}
