import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { concatMap, mergeMap, catchError, map } from 'rxjs/operators';
import { EMPTY, Observable, of } from 'rxjs';
import {
  FileCategoriesActionTypes,
  FileCategoriesActions,
  LoadFileCategoriesSuccess,
  LoadFileCategoriesFailure,
  CreateFileCategories,
  CreateFileCategoriesSuccess,
  CreateFileCategoriesFailure,
  UpdateFileCategories,
  UpdateFileCategoriesSuccess,
  UpdateFileCategoriesFailure,
  DeleteFileCategories,
  DeleteFileCategoriesSuccess,
  DeleteFileCategoriesFailure,
  LoadFileCategories
} from './file-categories.actions';
import { Action, Store } from '@ngrx/store';
import { CategoriesService } from 'src/app/services/system-settings/files/categories.service';
import { RootState } from '../../store.reducer';
import { Category } from 'src/app/models/admin/files/category';
import { convertToMap } from 'src/app/utils/convertToMap';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class FileCategoriesEffects {
  loadCategories$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(FileCategoriesActionTypes.LoadFileCategories),
      mergeMap(() =>
        this.categoriesService.getAll().pipe(
          map((Categories: Category[]) => {
            return new LoadFileCategoriesSuccess(convertToMap(Categories, 'id'));
          }),
          catchError((error: HttpErrorResponse) =>
            of(new LoadFileCategoriesFailure(error))
          )
        )
      )
    )
  );

  createCategories$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(FileCategoriesActionTypes.CreateFileCategories),
      map((action: CreateFileCategories) => action.payload),
      mergeMap((category: Category) => {
        return this.categoriesService.createName(category).pipe(
          map((newCategory: Category) => new CreateFileCategoriesSuccess(newCategory)),
          catchError((error: HttpErrorResponse) =>
            of(new CreateFileCategoriesFailure(error))
          )
        );
      })
    )
  );

  updateCategories$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(FileCategoriesActionTypes.UpdateFileCategories),
      map((action: UpdateFileCategories) => action.payload),
      mergeMap((category: Category) => {
        return this.categoriesService.updateCategory(category).pipe(
          map(
            (updatedCategory: Category) =>
              new UpdateFileCategoriesSuccess(updatedCategory)
          ),
          catchError((error: HttpErrorResponse) =>
            of(new UpdateFileCategoriesFailure(error))
          )
        );
      })
    )
  );

  deleteCategories$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(FileCategoriesActionTypes.DeleteFileCategories),
      map((action: DeleteFileCategories) => action.payload),
      mergeMap((categoryId: number) =>
        this.categoriesService.deleteCategory(categoryId).pipe(
          map(() => new DeleteFileCategoriesSuccess(categoryId)),
          catchError((error: HttpErrorResponse) =>
            of(new DeleteFileCategoriesFailure(error))
          )
        )
      )
    )
  );

  reloadCategories$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        FileCategoriesActionTypes.DeleteFileCategoriesSuccess,
        FileCategoriesActionTypes.UpdateFileCategoriesSuccess,
        FileCategoriesActionTypes.CreateFileCategoriesSuccess
      ),
      map(() => new LoadFileCategories())
    )
  );

  constructor(
    private actions$: Actions<FileCategoriesActions>,
    private categoriesService: CategoriesService,
    private store$: Store<RootState>
  ) {}
}
