import { Injectable } from '@angular/core';
import { ServicesModule } from '../services.module';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GlobalFilter } from '../../../app/models/global-filter';
import { Observable, of } from 'rxjs';
import { ApiCompany } from 'src/app/models/companies/api-company';
import { Company } from 'src/app/models/companies/company';
import { CompanyLink } from 'src/app/models/companies/company-link';
import { DmsLinkRequest } from 'src/app/models/companies/dms-link-request';

@Injectable({
  providedIn: ServicesModule
})
export class CompaniesService {
  private jpiUrl = environment.jpiUrl;
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getCompanyById(id: number) {
    return this.http.get(`${this.jpiUrl}/companies/${id}`);
  }

  getCompanies(limit: string = '25', order: string = 'createdDate') {
    return this.http.post(
      `${this.jpiUrl}/companies/search`,
      { filters: [], previousRecord: null },
      {
        params: {
          order,
          limit
        }
      }
    );
  }

  getCompaniesFiltered(
    filter: GlobalFilter,
    limit: number = 30,
    orderField: string = 'name.keyword',
    orderAsc: boolean,
    skip: number = 0
  ) {
    let url = `${this.jpiUrl}/companies/search`;
    if (orderField) {
      url += '?order=' + (orderAsc ? '%2B' : '%2D') + orderField;
    }
    return this.http.post(url, filter, {
      params: {
        limit: limit.toString(),
        skip: skip.toString()
      }
    });
  }

  removeCompanyFromTerritory(
    companyId: number,
    territoryId: number
  ): Observable<boolean> {
    return this.http.post(`${this.jpiUrl}/bulkoperations/removeterritories`, {
      companyIds: [companyId],
      territories: [{ id: territoryId }]
    }) as Observable<boolean>;
  }

  setCompaniesByTerritoryId(companyId: number, territoryId: number): Observable<boolean> {
    return this.http.post(`${this.jpiUrl}/bulkoperations/addterritories`, {
      companyIds: [companyId],
      territories: [{ id: territoryId }]
    }) as Observable<boolean>;
  }

  createCompany(company: Company) {
    return this.http.post(`${this.jpiUrl}/companies`, company);
  }

  getDmsLinked() {
    return this.http.get(`${this.jpiUrl}/dms/integrator/linkedProcedeCompanies`);
  }

  getDmsExactMatches() {
    return this.http.get(`${this.jpiUrl}/dms/integrator/unLinkedDMSCompanies_ExactMatch`);
  }

  getDmsUnlinked() {
    return this.http.get(`${this.jpiUrl}/dms/integrator/unLinkedDMSCompanies`);
  }

  getDmsLinkedCompany(id: string, date: string) {
    return this.http.get(`${this.jpiUrl}/dms/${id}/${date}`);
  }

  linkDmsCompany(dmsLinkRequest: DmsLinkRequest) {
    return this.http.post(
      `${this.jpiUrl}/dms/integrator/DMSCompanies/link`,
      dmsLinkRequest
    );
  }

  unlinkDmsCompany(dmsLinkRequest: DmsLinkRequest) {
    return this.http.post(
      `${this.jpiUrl}/dms/integrator/DMSCompanies/unlink`,
      dmsLinkRequest
    );
  }

  bulkLinkDmsCompany(dmsLinkRequests: DmsLinkRequest[]) {
    return this.http.post(
      `${this.jpiUrl}/dms/integrator/DMSCompanies/bulklink`,
      dmsLinkRequests
    );
  }

  updateCompany(company: Company) {
    return this.http.put(`${this.jpiUrl}/companies/${company.id}`, company);
  }

  deleteCompany(id: number): Observable<Company> {
    return this.http.delete(`${this.jpiUrl}/companies/${id}`) as Observable<Company>;
  }

  saveChildCompanies(
    companyId: number,
    children: CompanyLink[]
  ): Observable<CompanyLink[]> {
    return this.http.put(
      `${this.jpiUrl}/companies/directChildCompanies/${companyId}`,
      children
    ) as Observable<CompanyLink[]>;
  }
}
