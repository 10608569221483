import { RootState } from '../../store.reducer';
import { MemoizedSelector, createSelector } from '@ngrx/store';
import { UsersMap } from 'src/app/models/admin/users/users-map';
import { User } from 'src/app/models/admin/users/user';
import { IdNameItem } from 'src/app/models/id-name-item';
import { selectUsers } from './multi-select-users.selector';

export const selectAllMultiSelectUsers: MemoizedSelector<RootState, IdNameItem[]> =
  createSelector(selectUsers, (usersMap: UsersMap): IdNameItem[] => {
    const users = Object.values(usersMap);
    if (users.length) {
      return users
        .map((user: User) => {
          return {
            id: user.id,
            agencyId: user.agencyId,
            name: `${user.firstName} ${user.lastName}`,
            firstName: user.firstName,
            lastName: user.lastName,
            mailbox: user.mailbox,
            email: user.email,
            phone: user.phone,
            address: user.address,
            createdDate: user.createdDate,
            deleted: user.deleted,
            deletedDate: user.deletedDate,
            useNewTelenotes: user.useNewTelenotes
          };
        })
        .sort((a, b) => {
          const textA = a.name.toLowerCase();
          const textB = b.name.toLowerCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
    } else {
      return [];
    }
  });
