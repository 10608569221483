<form [formGroup]="projectFieldForm" class="project-field-form">
  <div>
    <mat-form-field fxFlex>
      <mat-label>Name</mat-label>
      <input
        [value]="currentProjectField?.name"
        formControlName="name"
        matInput
        type="text"
        (keydown.enter)="$event.stopPropagation()"
      />
    </mat-form-field>
  </div>
  <div>
    <mat-form-field fxFlex>
      <mat-label>Type</mat-label>
      <mat-select
        [value]="currentProjectField?.type"
        formControlName="type"
        matInput
        [compareWith]="compareObjects"
        (keydown.enter)="$event.stopPropagation()"
      >
        <mat-option *ngFor="let type of $fieldTypes | async" [value]="type">{{
          type.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div fxLayout="row-reverse" fxLayoutAlign="space-between center">
    <div fxLayout="row-reverse">
      <button
        *ngIf="!currentProjectField?.id"
        type="button"
        mat-raised-button
        color="primary"
        mat-button
        [disabled]="projectFieldForm.pristine || projectFieldForm.invalid"
        (click)="createButtonClicked()"
        (keydown.enter)="$event.stopPropagation()"
      >
        Create
      </button>
      <button
        *ngIf="currentProjectField?.id"
        type="button"
        mat-raised-button
        color="primary"
        mat-button
        [disabled]="projectFieldForm.pristine || projectFieldForm.invalid"
        (click)="saveButtonClicked()"
        (keydown.enter)="$event.stopPropagation()"
      >
        Save
      </button>
      &nbsp;
      <button
        preventDefaultAction
        mat-raised-button
        color="white"
        mat-button
        (click)="cancelButtonClicked()"
      >
        Cancel
      </button>
    </div>
    <button
      *ngIf="currentProjectField?.id"
      preventDefaultAction
      mat-raised-button
      color="accent"
      mat-button
      (click)="deleteButtonClicked()"
    >
      Delete
    </button>
  </div>
</form>
