<loading-popup
  *ngIf="isLoading"
  [isLoading]="isLoading"
  message="Saving..."
></loading-popup>

<dx-popup
  [position]="{ my: 'right', at: 'right', of: window }"
  [width]="600"
  [height]="'100vh'"
  [showTitle]="false"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [(visible)]="companyPopup"
  shadingColor="rgba(0,0,0,0.5)"
>
  <div *dxTemplate="let data of 'content'">
    <div fxLayout="column" style="height: calc(100% - 50px);">
      <dx-scroll-view [height]="'inherit'">
        <div class="popup-title" fxLayout="row" fxFlex>
          <div fxFlex="4"></div>
          <div *ngIf="!company.id">New Company</div>
          <div *ngIf="company.id">Edit Company</div>
        </div>
        <div fxLayout="row">
          <div fxFlex="4"></div>
          <div class="client-input" fxFlex>
            <span>Company Name</span>
            <dx-text-box [(value)]="company.name"></dx-text-box>
          </div>
          <div fxFlex="4"></div>
        </div>
        <div fxLayout="row">
          <div fxFlex="4"></div>
          <div class="client-input" fxFlex>
            <span>Account Id</span>
            <dx-text-box [(value)]="company.accountId"></dx-text-box>
          </div>
          <div fxFlex="4"></div>
        </div>
        <company-popup-territories-and-reps
          *ngIf="!companyListEditPopup"
          [(company)]="company"
          [(territories)]="territories"
          [(primaryUserId)]="primaryUserId"
        ></company-popup-territories-and-reps>
        <company-popup-labels
          [(labelsExpanded)]="labelsExpanded"
          [(newGroupLabels)]="newGroupLabels"
          [(company)]="company"
        ></company-popup-labels>
        <company-popup-addresses
          [(billingAddressExpanded)]="billingAddressExpanded"
          [(shippingAddressExpanded)]="shippingAddressExpanded"
          [(company)]="company"
        ></company-popup-addresses>
        <company-popup-company-details
          [(companyDetailsExpanded)]="companyDetailsExpanded"
          [(company)]="company"
          [(companyDetails)]="companyDetails"
          [(companyDetailsCheck)]="companyDetailsCheck"
          [(companyCustomFields)]="companyCustomFields"
          [(companies)]="companies"
        ></company-popup-company-details>
      </dx-scroll-view>
    </div>
    <div fxLayout="row" fxLayoutAlign="end end">
      <span *ngIf="company.id" class="button button-red" (click)="deleteCompany()"
        >Delete</span
      >
      <div fxFlex fxLayoutAlign="end end">
        <span class="button button-white" (click)="cancelCompany(company)">Cancel</span>
        <span
          *ngIf="company.id"
          class="button button-blue"
          (click)="updatingCompany(company)"
          >Save</span
        >
        <span
          *ngIf="!company.id"
          class="button button-blue"
          (click)="saveNewCompany(company)"
          >Save</span
        >
      </div>
    </div>
  </div>
</dx-popup>

<dx-popup
  [width]="310"
  [height]="150"
  [showTitle]="false"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [(visible)]="cancelPopup"
  shadingColor="rgba(0,0,0,0.5)"
>
  <div *dxTemplate="let data of 'content'">
    <div fxLayout="column" fxFlex>
      <h3>Are you sure you want to cancel?</h3>
      <div fxLayout="row" fxLayoutAlign="center center">
        <span class="button button-white" (click)="cancelPopup = false">No</span>
        <span class="button button-red" (click)="companyCancelled()">Yes</span>
      </div>
    </div>
  </div>
</dx-popup>

<dx-popup
  [width]="420"
  [height]="150"
  [showTitle]="false"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [(visible)]="deleteCompanyPopup"
  shadingColor="rgba(0,0,0,0.5)"
>
  <div *dxTemplate="let data of 'content'">
    <div fxLayout="column" fxFlex>
      <h3>Are you sure you want to delete this company?</h3>
      <div fxLayout="row" fxLayoutAlign="center center">
        <span class="button button-white" (click)="deleteCompanyPopup = false">No</span>
        <span class="button button-red" (click)="companyDeleted()">Yes</span>
      </div>
    </div>
  </div>
</dx-popup>
