<mat-card fxLayout="row" fxLayoutAlign="space-between start">
  <div fxLayoutAlign="start start" fxLayout="row" fxLayoutGap="32px">
    <div [ngSwitch]="file.fileType">
      <mat-icon class="file-icon" *ngSwitchCase="'application/pdf'">
        picture_as_pdf
      </mat-icon>
      <mat-icon class="file-icon" *ngSwitchCase="'image/jpeg'">
        image
      </mat-icon>
      <mat-icon class="file-icon" *ngSwitchCase="'image/png'">
        image
      </mat-icon>
      <mat-icon class="file-icon" *ngSwitchDefault>
        insert_drive_file
      </mat-icon>
    </div>
    <div fxLayoutAlign="start start" fxLayout="column" fxLayoutGap="8px">
      <div fxLayoutAlign="start end" fxLayout="row" fxLayoutGap="6px">
        <span [ngSwitch]="file.fileType">
          <a
            href="javascript:void(0)"
            *ngSwitchCase="'image/jpeg'"
            (click)="onDownloadImage()"
          >
            {{ file?.fileName }}
          </a>
          <a
            href="javascript:void(0)"
            *ngSwitchCase="'image/png'"
            (click)="onDownloadImage()"
          >
            {{ file?.fileName }}
          </a>
          <a href="javascript:void(0)" *ngSwitchDefault (click)="onDownload()">
            {{ file?.fileName }}
          </a>
        </span>
        <span class="file-size"> - {{ file?.fileSize }}</span>
      </div>
      <div class="file-details">
        Uploaded by <span class="bolder">{{ file?.uploadedByItem.name }}</span> on
        <span class="bolder">{{ file?.uploadedDate | date: 'medium' }}</span>
      </div>
      <div>
        <img *ngIf="!!image" [src]="image" />
      </div>
    </div>
  </div>
  <div class="file-actions" fxLayout="row">
    <button mat-icon-button color="warn" (click)="onDelete()">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</mat-card>
