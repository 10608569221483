import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

import { Feature } from '../../models/admin/feature';
import { TerritoryV2 } from '../../models/admin/territories/territory-V2';
import { User } from '../../models/admin/users/user';
import { BulkEditRequest } from '../../models/companies/bulk-edit-request';
import { ChildCompanyRequest } from '../../models/companies/child-company-request';
import { Company } from '../../models/companies/company';
import { CompanyList } from '../../models/companies/company-list';
import { CompanyType } from '../../models/companies/company-type';
import { CompanyV2 } from '../../models/companies/company-V2';
import { Manufacturer } from '../../models/sales/Manufacturer';
import { AuthenticationService } from '../authentication.service';
import { TerritoryService } from '../territories/territory.service';

@Injectable()
export class CompanyService {
  public permissions: any = { EditCompanies: 37, ViewExcede: 108, MapExcede: 109 };
  private allCompanies: Company[];
  private mappableCompanies: Company[];
  private routedCompanies: Company[];
  private companyAnnouncer = new Subject<CompanyV2>();
  public companyAnnounced = this.companyAnnouncer.asObservable();
  private newCompanyAddedAnnouncer = new Subject<boolean>();
  public newCompanyAddedAnnounced = this.newCompanyAddedAnnouncer.asObservable();

  private visibleMapCompaniesAnnouncer = new Subject<Company[]>();
  public visibleMapCompaniesChanged = this.visibleMapCompaniesAnnouncer.asObservable();
  private companyProfileCompanyPopupAnnouncer = new Subject<boolean>();
  public companyProfileCompanyPopupAnnounced =
    this.companyProfileCompanyPopupAnnouncer.asObservable();
  private companyProfilePopupAnnouncer = new Subject<boolean>();
  public companyProfilePopupAnnounced = this.companyProfilePopupAnnouncer.asObservable();
  territories: TerritoryV2[];
  feature: Feature;
  private jpiUrl = environment.jpiUrl;

  constructor(
    private authService: AuthenticationService,
    private territoryService: TerritoryService,
    private http: HttpClient
  ) {
    this.routedCompanies = [];
    this.allCompanies = [];
    this.mappableCompanies = [];
  }

  companyCreatedFromToolbar(company: any) {
    this.companyAnnouncer.next(null);
    this.companyAnnouncer.next(company);
  }

  clearCompanyFromToolbar() {
    this.companyAnnouncer.next(null);
  }

  newCompanyAdded(bool: boolean): void {
    this.newCompanyAddedAnnouncer.next(null);
    this.newCompanyAddedAnnouncer.next(bool);
  }

  companyProfileCompanyPopupChange(bool: boolean): void {
    this.companyProfileCompanyPopupAnnouncer.next(null);
    this.companyProfileCompanyPopupAnnouncer.next(bool);
  }

  companyProfilePopupChange(bool: boolean): void {
    this.companyProfilePopupAnnouncer.next(null);
    this.companyProfilePopupAnnouncer.next(bool);
  }

  clearServiceData(): void {
    delete this.allCompanies;
    delete this.mappableCompanies;
    delete this.routedCompanies;

    this.allCompanies = [];
    this.routedCompanies = [];
    this.mappableCompanies = [];
  }

  async canEditCompany(comp: any) {
    if (this.territories) {
      if (!this.feature) {
        return false;
      }

      let permissionLevel = 0;
      permissionLevel = this.getPermissionLevel(this.feature);
      return this.returnEditAvailability(permissionLevel, comp, this.territories);
    } else {
      this.territories = await this.territoryService.getAllFlattened();
      this.feature = await this.authService.getFeature(
        this.authService.modules.Companies
      );

      if (!this.feature) {
        return false;
      }

      let permissionLevel = 0;
      permissionLevel = this.getPermissionLevel(this.feature);

      return this.returnEditAvailability(permissionLevel, comp, this.territories);
    }
  }

  getPermissionLevel(feature): number {
    for (let i = 0; i < feature.Permissions.length; i++) {
      if (feature.Permissions[i].Id == this.permissions.EditCompanies) {
        return parseInt(feature.Permissions[i].Value);
      }
    }
  }

  returnEditAvailability(permissionLevel, comp, territories): boolean {
    const territoryIds = territories.map((territory) => territory.id);
    switch (permissionLevel) {
      case 0: // can't edit companies
        return false;
      case 1: // can edit primary companies
        return comp.primaryUser
          ? comp.primaryUser.id ==
              parseInt(this.authService.getCookie(this.authService.cookieNames.AgentId))
          : false;
      case 2: // can edit companies in users territories
        return this.regionIdPresent(territories, territoryIds, false);
      case 3: // can edit companies in users territories and children territories
        return this.regionIdPresent(territories, territoryIds, true);
      case 4: // can edit everything
        return true;
      default:
        // permission not found, can't edit
        return false;
    }
  }

  regionIdPresent(
    territories: TerritoryV2[],
    territoryIds: number[],
    recursive: boolean
  ): boolean {
    if (territoryIds && territoryIds.length > 0) {
      for (let i = 0; i < territories.length; i++) {
        if (territoryIds.includes(territories[i].id)) {
          return true;
        }
        if (
          recursive &&
          territories[i].subTerritories &&
          territories[i].subTerritories.length > 0
        ) {
          if (
            this.regionIdPresent(territories[i].subTerritories, territoryIds, recursive)
          ) {
            return true;
          }
        }
      }
    }
    return false;
  }

  canEditSelectedCompanies(companies: any[]): boolean {
    let temp = true;
    for (let i = 0; i < companies.length; i++) {
      if (!this.canEditCompany(companies[i])) {
        temp = false;
        break;
      } else {
        temp = true;
      }
    }
    if (temp) {
      return true;
    }
  }

  canViewExcedeData(): Promise<boolean> {
    return this.authService.getFeature(this.authService.modules.Excede).then((result) => {
      if (!result) {
        return false;
      }

      for (let i = 0; i < result.Permissions.length; i++) {
        if (result.Permissions[i].Id == this.permissions.ViewExcede) {
          return result.Permissions[i].Value == 'true';
        }
      }
    });
  }

  canMapExcedeData(): Promise<boolean> {
    return this.authService.getFeature(this.authService.modules.Excede).then((result) => {
      if (!result) {
        return false;
      }

      for (let i = 0; i < result.Permissions.length; i++) {
        if (result.Permissions[i].Id == this.permissions.MapExcede) {
          return result.Permissions[i].Value == 'true';
        }
      }
    });
  }

  getEmailList(): Promise<User[]> {
    return this.authService
      .makeRequest('/api-v1.2.1/admin/emailList', null, 'get')
      .then((result: any) => {
        return JSON.parse(result);
      });
  }

  getNewCompanies() {
    return this.authService
      .makeRequest('/api-v1.2.1/company/getNew', null, 'get')
      .then((result: any) => {
        return JSON.parse(result);
      });
  }

  getRecentCompanies() {
    return this.authService
      .makeRequest('/api-v1.2.1/company/getRecent', null, 'get')
      .then((result: any) => {
        return JSON.parse(result);
      });
  }

  getCompanyLists(): Promise<CompanyList[]> {
    return this.authService
      .makeRequest('/api-v1.2.1/company/getCompanyLists', null, 'get')
      .then((result: any) => {
        return JSON.parse(result);
      });
  }

  createList(list: CompanyList): Promise<any> {
    return this.authService
      .makeRequest('/api-v1.2.1/company/createList', list, 'post')
      .then((result: any) => {
        return JSON.parse(result);
      });
  }

  updateList(list: CompanyList): Promise<any> {
    return this.authService
      .makeRequest('/api-v1.2.1/company/updateList', list, 'post')
      .then((result: any) => {
        return result;
      });
  }

  deleteList(list: CompanyList): Promise<any> {
    return this.authService
      .makeRequest('/api-v1.2.1/company/deleteList', list, 'post')
      .then((result: any) => {
        return result;
      });
  }

  getCompanyAssignments(companyId: number) {
    return this.authService
      .makeRequest(
        '/api-v1.2.1/company/getCompanyAssignment?companyId=' + companyId,
        null,
        'get'
      )
      .then((result: any) => {
        return JSON.parse(result);
      });
  }

  getTerritories(companyId: number) {
    return this.authService
      .makeRequest(
        '/api-v1.2.1/company/getTerritories?companyId=' + companyId,
        null,
        'get'
      )
      .then((result: any) => {
        return JSON.parse(result);
      });
  }

  getPrimaryRep(companyId: number) {
    return this.authService
      .makeRequest(
        '/api-v1.2.1/company/getPrimaryRep?companyId=' + companyId,
        null,
        'get'
      )
      .then((result: any) => {
        return JSON.parse(result);
      });
  }

  setCompanyAssignment(
    companyId: number,
    agentMailboxId: number,
    associateSales: boolean
  ) {
    const sQS =
      '/api-v1.2.1/company/setCompanyAssignment?companyId=' +
      companyId +
      '&agentMailboxId=' +
      agentMailboxId +
      '&associateSales=' +
      associateSales;
    return this.authService.makeRequest(sQS, null, 'post').then((result: any) => {
      return JSON.parse(result);
    });
  }
  setPrimaryRep(companyId: number, agentMailboxId: number) {
    return this.authService
      .makeRequest(
        '/api-v1.2.1/company/setPrimaryRep?companyId=' +
          companyId +
          '&agentMailboxId=' +
          agentMailboxId,
        null,
        'post'
      )
      .then((result: any) => {
        return JSON.parse(result);
      });
  }

  favoriteCompanyList(list: any): Promise<boolean> {
    const temp = JSON.parse(JSON.stringify(list));
    temp.ListFilter = JSON.stringify(temp.ListFilter);
    return this.authService
      .makeRequest('/api-v1.2.0/company/favoriteList', temp, 'post')
      .then(
        () => {
          return true;
        },
        () => false
      );
  }

  unfavoriteCompanyList(list: any): Promise<boolean> {
    const temp = JSON.parse(JSON.stringify(list));
    temp.ListFilter = JSON.stringify(temp.ListFilter);
    return this.authService
      .makeRequest('/api-v1.2.0/company/unFavoriteList', temp, 'post')
      .then(
        () => {
          return true;
        },
        () => false
      );
  }

  bulkEdit(edit: BulkEditRequest) {
    return this.authService
      .makeRequest('/api-v1.2.1/company/bulkEdit', edit, 'post')
      .then((result: any) => {
        return result;
      });
  }

  // Takes an array of company ids which will all be deleted
  bulkDelete(deleting: number[]) {
    return this.authService
      .makeRequest('/api-v1.2.1/company/bulkDelete', deleting, 'post')
      .then((result: any) => {
        return result;
      });
  }

  // Takes an array of 2 companies to be merged. The first company in the array is the one that is kept, the second one will be deleted
  mergeCompanies(merge: Company[]): Promise<boolean> {
    return this.authService
      .makeRequest('/api-v1.2.1/company/merge', merge, 'post')
      .then((result: any) => {
        return result;
      });
  }

  getProcedeCustomer(procedeCustomerId: string): Promise<any> {
    return this.authService
      .makeRequest(
        '/api-v1.2.0/procede?procedeCustomerId=' +
          this.urlEncode(procedeCustomerId) +
          '&currentDate=' +
          new Date().toLocaleDateString(),
        null,
        'get'
      )
      .then((result: any) => {
        return result;
      });
  }

  linkProcedeCustomer(
    telenotesCompanyId: number,
    procedeCompanyId: string
  ): Promise<boolean> {
    const request: any = {};
    request.TelenotesCompanyId = telenotesCompanyId;
    request.ProcedeCompanyId = procedeCompanyId;

    return this.authService
      .makeRequest('/api-v1.2.0/integrator/procedeCompanies/link', request, 'post')
      .then((result) => {
        return true;
      });
  }

  bulkLinkProcedeCompanies(procedeLinks: any[]): Promise<any> {
    return this.authService
      .makeRequest(
        '/api-v1.2.0/integrator/procedeCompanies/bulklink',
        procedeLinks,
        'post'
      )
      .then((result: any) => {
        return true;
      });
  }

  unlinkProcedeCustomer(
    telenotesCompanyId: number,
    procedeCompanyId: string
  ): Promise<boolean> {
    const request: any = {};
    request.TelenotesCompanyId = telenotesCompanyId;
    request.ProcedeCompanyId = procedeCompanyId;

    return this.authService
      .makeRequest('/api-v1.2.0/integrator/procedeCompanies/unlink', request, 'post')
      .then((result) => {
        return true;
      });
  }

  getUnlinkedProcedeCustomers(): Promise<any[]> {
    return this.authService
      .makeRequest('/api-v1.2.0/integrator/unLinkedProcedeCompanies', '', 'get')
      .then((result: any) => {
        return this.parseUnlinkedProcedeCompanyCsv(result);
      });
  }

  getUnlinkedExactMatchProcedeCustomers(): Promise<any[]> {
    return this.authService
      .makeRequest('/api-v1.2.0/integrator/unLinkedProcedeCompaniesExactMatch', '', 'get')
      .then((result: any) => {
        return this.parseUnlinkedExactMatchCsv(result);
      });
  }

  getLinkedProcedeCustomers(): Promise<any[]> {
    return this.authService
      .makeRequest('/api-v1.2.0/integrator/linkedProcedeCompanies', '', 'get')
      .then((result: any) => {
        return this.parseLinkedProcedeCompanyCsv(result);
      });
  }

  getCompanies(): Promise<Company[]> {
    return this.authService
      .makeRequest('/api-v1.2.5/company/getcsv', '', 'get')
      .then((result: any) => {
        return (this.allCompanies = this.parseCsv(result));
      });
  }

  getCompanyCustomFieldsWithValues(): Promise<any[]> {
    return this.authService
      .makeRequest('/api-v1.2.1/getCompanyCustomFieldsWithValues', null, 'get')
      .then((result: any) => {
        return this.parseCustomFieldsCsv(result);
      });
  }

  getCompanyById(id: number): Promise<Company> {
    return this.authService
      .makeRequest('/api-v1.2.1/company/getById?id=' + id, '', 'get')
      .then((result: any) => {
        const newcomp: any = JSON.parse(result);
        newcomp.AltIDs = JSON.parse(newcomp.AlternateIdentifiers);
        newcomp.CreatedDate = new Date(newcomp.CreatedDate);
        this.allCompanies.push(newcomp);
        return newcomp;
      });
  }

  setCompaniesByTerritoryId(companyId: number, territoryId: number): Promise<boolean> {
    return this.authService
      .makeRequest(
        '/api-v1.2.1/company/setByTerritoryId?companyId=' +
          companyId +
          '&territoryId=' +
          territoryId,
        '',
        'post'
      )
      .then((result: any) => {
        const newcomp: any = JSON.parse(result);
        return newcomp;
      });
  }

  remCompanyFromTerritory(companyId: number, territoryId: number): Promise<boolean> {
    return this.authService
      .makeRequest(
        '/api-v1.2.1/company/remTerritoryLink?companyId=' +
          companyId +
          '&territoryId=' +
          territoryId,
        '',
        'post'
      )
      .then((result: any) => {
        const newcomp: any = JSON.parse(result);
        return newcomp;
      });
  }

  getTerritoryAssignments(): Promise<any> {
    return this.authService
      .makeRequest('/api-v1.2.1/company/territoryAssignments', '', 'get')
      .then((result: any) => {
        return this.parseTerritories(result);
      });
  }

  getCompaniesByTerritoryId(id: number): Promise<Company[]> {
    return this.authService
      .makeRequest('/api-v1.2.1/company/getByTerritoryId?territoryId=' + id, '', 'get')
      .then((result: any) => {
        return JSON.parse(result);
      });
  }

  getAgencyManufacturers(): Promise<Manufacturer[]> {
    return this.authService
      .makeRequest('/api-v1.2.1/company/manufacturers', '', 'get')
      .then((result: any) => {
        return this.parseManufacturerCsv(result);
      });
  }

  getAgencyCustomers(getDeleted: boolean): Promise<any[]> {
    return this.authService
      .makeRequest('/api-v1.2.1/company/customers', '', 'get')
      .then((result: any) => {
        return this.parseCustomerCsv(result, getDeleted);
      });
  }

  getCompanyTypes(): Promise<CompanyType[]> {
    return this.authService
      .makeRequest('/api-v1.2.1/company/types', null, 'get')
      .then((result: any) => {
        return this.parseTypeCsv(result);
      });
  }

  getCompanyStatus(): Promise<CompanyType[]> {
    return this.authService
      .makeRequest('/api-v1.2.1/company/status', null, 'get')
      .then((result: any) => {
        return this.parseTypeCsv(result);
      });
  }

  searchForCompany(searchTerm: string): Promise<any[]> {
    searchTerm = this.urlEncode(searchTerm);

    return this.authService
      .makeRequest(
        '/api-v1.2.1/company/searchForCompany?searchTerm=' + searchTerm,
        '',
        'get'
      )
      .then((result: any) => {
        return this.parseSearchResultCsv(result);
      });
  }

  fuzzySearchForCompany(searchTerm: string): Promise<any[]> {
    searchTerm = this.urlEncode(searchTerm);

    return this.authService
      .makeRequest(
        '/api-v1.2.1/company/fuzzySearchForCompany?searchTerm=' + searchTerm,
        '',
        'get'
      )
      .then((result: any) => {
        return this.parseSearchResultCsv(result);
      });
  }

  urlEncode(value: string): string {
    value = value.replace(/[!]/g, '%21');
    value = value.replace(/[#]/g, '%23');
    value = value.replace(/[$]/g, '%24');
    value = value.replace(/[&]/g, '%26');
    value = value.replace(/[']/g, '%27');
    value = value.replace(/[(]/g, '%28');
    value = value.replace(/[)]/g, '%29');
    value = value.replace(/[*]/g, '%2a');
    value = value.replace(/[+]/g, '%2b');
    value = value.replace(/[,]/g, '%2c');
    value = value.replace(/[/]/g, '%2f');
    value = value.replace(/[:]/g, '%3a');
    value = value.replace(/[;]/g, '%3b');
    value = value.replace(/[=]/g, '%3d');
    value = value.replace(/[?]/g, '%3f');
    value = value.replace(/[@]/g, '%40');
    value = value.replace(/[[]/g, '%5b');
    value = value.replace(/[\]]/g, '%5d');
    return value;
  }

  addCompany(company: Company): Promise<number> {
    return this.authService
      .makeRequest('/api-v1.2.1/company/add', company, 'post')
      .then((result: any) => {
        return parseInt(result);
      });
  }

  updateCompany(company: Company): Promise<boolean> {
    return this.authService
      .makeRequest('/api-v1.2.1/company/edit', company, 'post')
      .then(() => {
        return true;
      });
  }

  updateAlternateIds(companyId: number, altIds: any[]): Promise<boolean> {
    const idString = JSON.stringify(altIds);
    const request = { CompanyId: companyId, AltIds: idString };
    return this.authService
      .makeRequest('/api-v1.2.1/company/altnernateIds', request, 'post')
      .then(() => {
        return true;
      });
  }

  setChildCompany(child: ChildCompanyRequest): Promise<boolean> {
    return this.authService
      .makeRequest('/api-v1.2.1/company/setChildren', child, 'post')
      .then((result: any) => {
        return result;
      });
  }

  parseCompanyListsCsv(csvString: string): any[] {
    const temp: any[] = csvString.split('\n');
    const companyLists: CompanyType[] = [];

    let i = 1;
    for (i = 1; i < temp.length; i++) {
      const data: any[] = temp[i].split('\xa7');

      if (data.length > 1) {
        const list: any = {};

        list.ListId = data[0];
        list.AgencyId = data[1];
        list.AgentId = data[2];
        list.IsGloballyVisible = data[3];
        list.IsFavorite = data[4] == 'True';
        list.ListName = data[5];

        if (list.ListId == 3) {
          list.ListFilter = [
            'CreatedDate',
            '>=',
            new Date(new Date().setDate(new Date().getDate() - 30))
          ];
        } else {
          list.ListFilter = data[6] ? JSON.parse(data[6]) : null;
        }
        companyLists.push(list);
      }
    }
    return companyLists;
  }

  parseUnlinkedProcedeCompanyCsv(csvString: string): any[] {
    const temp: any[] = csvString.split('\n');
    const procedeCompanies: CompanyType[] = [];

    let i = 1;
    for (i = 1; i < temp.length; i++) {
      const data: any[] = temp[i].split('\xa7');

      if (data.length > 1) {
        const company: any = {};

        company.CustomerId = data[0];
        company.CustomerName = data[1];
        company.Address1 = data[2];
        company.City = data[3];
        company.State = data[4];
        company.Zip = data[5];
        company.WorkPhone = data[6];
        company.WorkEmail = data[7];
        company.CreateDate = new Date(data[8]);
        company.UpdateDate = new Date(data[9]);
        company.PastSales = parseFloat(data[10]);

        procedeCompanies.push(company);
      }
    }

    return procedeCompanies;
  }

  parseUnlinkedExactMatchCsv(csvString: string): any[] {
    const temp: any[] = csvString.split('\n');
    const procedeCompanies: CompanyType[] = [];

    let i = 1;
    for (i = 1; i < temp.length; i++) {
      const data: any[] = temp[i].split('\xa7');

      if (data.length > 1) {
        const company: any = {};

        company.PCustomerId = data[0];
        company.PCustomerName = data[1];
        company.PAddress1 = data[2];
        company.PCity = data[3];
        company.PState = data[4];
        company.PZip = data[5];
        company.TCustomerId = data[6];
        company.TCustomerName = data[7];
        company.TAddress1 = data[8];
        company.TCity = data[9];
        company.TState = data[10];
        company.TZip = data[11];
        company.Link = true;

        procedeCompanies.push(company);
      }
    }

    return procedeCompanies;
  }

  parseLinkedProcedeCompanyCsv(csvString: string): any[] {
    const temp: any[] = csvString.split('\n');
    const procedeCompanies: CompanyType[] = [];

    let i = 1;
    for (i = 1; i < temp.length; i++) {
      const data: any[] = temp[i].split('\xa7');

      if (data.length > 1) {
        const company: any = {};
        company.CustomerId = data[0];
        company.CustomerName = data[1];
        company.TelenotesId = data[2];
        company.TelenotesName = data[3];
        procedeCompanies.push(company);
      }
    }

    return procedeCompanies;
  }

  parseTypeCsv(csvString: string): CompanyType[] {
    const temp: any[] = csvString.split('\n');
    const results: CompanyType[] = [];

    let i = 1;
    for (i = 1; i < temp.length; i++) {
      const data: any[] = temp[i].split('\xa7');

      if (data.length > 1) {
        const result: CompanyType = <CompanyType>{};

        result.Id = parseInt(data[0]);
        result.Value = data[1];

        results.push(result);
      }
    }

    return results;
  }

  parseSearchResultCsv(csvString: string): any {
    const temp: any[] = csvString.split('\n');
    const results: any = [];

    let i = 1;
    for (i = 1; i < temp.length; i++) {
      const data: any[] = temp[i].split('\xa7');

      if (data.length > 1) {
        const result: any = {};

        result.CompanyId = parseInt(data[0]);
        result.CompanyName = data[1];
        result.AccountId = data[2];
        result.StreetAddress = data[3];
        result.City = data[4];
        result.State = data[5];
        result.Zip = data[6];
        result.Phone = data[7];
        result.Email = data[8];

        results.push(result);
      }
    }

    return results;
  }

  parseCustomerCsv(csvString: string, getDeleted: boolean): any {
    const temp: any[] = csvString.split('\n');
    const customers: any = [];

    let i = 1;
    for (i = 1; i < temp.length; i++) {
      const data: any[] = temp[i].split('\xa7');

      if (data.length > 1) {
        if ((data[8] < 0 && getDeleted) || data[8] > 0) {
          const customer: any = {};

          customer.Id = parseInt(data[0]);
          customer.TelenotesId = data[1];
          customer.Name = data[2];
          customer.altIds = JSON.parse(data[3]);
          customer.address = data[4].trim();
          customer.city = data[5].trim();
          customer.state = data[6].trim();
          customer.zip = data[7].trim();

          customers.push(customer);
        }
      }
    }

    return customers;
  }

  parseManufacturerCsv(csvString: string): Manufacturer[] {
    const temp: any[] = csvString.split('\n');
    const mans: Manufacturer[] = [];

    let i = 1;
    for (i = 1; i < temp.length; i++) {
      const data: any[] = temp[i].split('\xa7');

      if (data.length > 1) {
        const man: Manufacturer = <Manufacturer>{};

        man.Id = parseInt(data[0]);
        man.ManufacturerName = data[1];
        man.AgencyCommission = data[2];
        man.Archived = data[3] == 'True' ? true : false;

        mans.push(man);
      }
    }

    return mans;
  }

  parseTerritories(csvString: string): any[] {
    const temp: any[] = csvString.split('\n');
    const territories: any[] = [];

    for (let i = 1; i < temp.length; i++) {
      const data: any[] = temp[i].split('\xa7');

      if (data.length > 1) {
        const territory: any = <any>{};

        territory.CompanyId = data[0];
        territory.TerritoryId = data[1];
        territory.TerritoryName = data[2];

        territories.push(territory);
      }
    }
    return territories;
  }

  parseCsv(csvString: string): Company[] {
    const temp: any[] = csvString.split('\n');
    const comps: Company[] = [];

    let i = 1;
    for (i = 1; i < temp.length; i++) {
      const data: any[] = temp[i].split('\xa7');

      if (data.length > 1) {
        const comp: Company = <Company>{};

        comp.CompanyId = data[0];
        comp.CompanyName = data[2];
        comp.AgencyId = data[3];
        comp.Address = data[4];
        comp.Address2 = data[5];
        comp.City = data[6];
        comp.State = data[7];
        comp.Zip = data[10];
        comp.ShippingAddress = data[11];
        comp.ShippingCity = data[12];
        comp.ShippingState = data[13];
        comp.ShippingZip = data[15];
        comp.Phone = data[16];
        comp.BusinessPhone = data[17];
        comp.MobilePhone = data[18];
        comp.WebSite = data[19];
        comp.CompanyNotes = data[20];
        comp.TypeId = data[21];
        comp.StatusId = data[22];
        comp.CreatedDate = new Date(data[23]);
        comp.TypeName = data[24];
        comp.StatusName = data[25];
        comp.Latitude = parseFloat(data[26]);
        comp.Longitude = parseFloat(data[27]);
        comp.PlaceId = data[28];
        comp.LastCallDate = data[33];
        comp.TotalCalls = data[34];
        comp.PrimaryRepId = data[35];
        comp.PrimaryRepName = data[36];

        comps.push(comp);
      }
    }
    return comps;
  }

  parseJPICsv(csvString: string): CompanyV2[] {
    const temp: any[] = csvString.split('\n');
    const comps: CompanyV2[] = [];

    let i = 1;
    for (i = 1; i < temp.length; i++) {
      const data: any[] = temp[i].split('\xa7');

      if (data.length > 1) {
        const comp: CompanyV2 = <CompanyV2>{};

        comp.id = data[0];
        comp.agencyId = data[2];
        comp.name = data[3];
        comp.website = data[4];
        comp.billingAddress = data[5];
        comp.shippingAddress = data[6];
        comp.phone = data[7];
        comp.fax = data[10];
        comp.notes = data[11];
        comp.createdDate = data[12];
        comp.deleted = data[13];
        comp.deletedDate = data[15];
        comp.contacts = data[16];
        comp.territories = data[17];
        comp.primaryUser = data[18];

        comps.push(comp);
      }
    }
    return comps;
  }

  parseCustomFieldsCsv(csvString: string): any[] {
    const temp: any[] = csvString.split('\n');
    const fields: any[] = [];

    for (let i = 1; i < temp.length; i++) {
      const data: any[] = temp[i].split('\xa7');

      if (data.length > 1) {
        const field: any = <any>{};

        field.CompanyId = data[0];
        field.SectionId = data[1];
        field.LabelId = data[2];
        field.TabId = data[3];
        field.FieldId = data[4];
        field.FieldType = data[5];
        field.ListId = data[6];
        field.IsFieldStandard = data[7];
        field.StandardFieldId = data[8];
        field.Value = data[9];

        fields.push(field);
      }
    }

    return fields;
  }
}
