import {
  CallNoteTypesActions,
  CallNoteTypesActionTypes
} from './call-note-types.actions';
import { GenericApi } from 'src/app/models/generic-api';
import { GenericMap } from 'src/app/models/generic-map';
import { CallNoteType } from 'src/app/models/call-notes/call-note-type';

export interface CallNoteTypesState extends GenericApi<GenericMap<CallNoteType>> {}

export const initialState: CallNoteTypesState = {
  pending: false,
  error: undefined,
  data: {}
};

export function callNoteTypesReducer(
  state = initialState,
  action: CallNoteTypesActions
): CallNoteTypesState {
  switch (action.type) {
    case CallNoteTypesActionTypes.LoadCallNoteTypes: {
      return {
        ...state,
        pending: true,
        error: undefined
      };
    }

    case CallNoteTypesActionTypes.LoadCallNoteTypesSuccess: {
      return {
        pending: false,
        error: undefined,
        data: action.payload
      };
    }

    case CallNoteTypesActionTypes.LoadCallNoteTypesFailure: {
      return {
        pending: false,
        error: action.payload,
        data: {}
      };
    }

    default:
      return state;
  }
}
