import { Injectable } from '@angular/core';
import { ServicesModule } from '../services.module';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: ServicesModule
})
export class UsersService {
  private jpiUrl = environment.jpiUrl;
  constructor(private http: HttpClient) {}

  getAll(agencyId?: number) {
    const paramsObj: any = {};
    if (!!agencyId) {
      paramsObj.agencyId = agencyId.toString();
    }
    return this.http.get(`${this.jpiUrl}/users`, {
      params: new HttpParams({
        fromObject: paramsObj
      })
    });
  }
}
