import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ProjectBid } from 'src/app/models/admin/projects/project-bid';
import { Subscription, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import {
  LoadProjectBids,
  ClearProjectBidId,
  AddProjectBids,
  UpdateProjectBids,
  DeleteProjectBids,
  AddProjectBidsSuccess
} from 'src/app/store/system-settings/project-bids/project-bids.actions';
import { CloseDrawer } from 'src/app/store/layout/layout.actions';
import { DeleteObject } from 'src/app/store/undo-delete/undo-delete.actions';
import { FieldType } from 'src/app/models/call-notes/field-type';
import { LoadFieldTypes } from 'src/app/store/system-settings/field-types/field-types.actions';
import { selectSelectedProjectBid } from 'src/app/store/system-settings/project-bids/selectors/selected-projectBids.selector';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDeleteComponent } from 'src/app/view/shared/confirm-delete/confirm-delete.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'tn-add-project-bids',
  templateUrl: './add-project-bids.component.html',
  styleUrls: ['./add-project-bids.component.css']
})
export class AddProjectBidsComponent implements OnInit, OnDestroy {
  projectBidForm: FormGroup;
  $fieldTypes: Observable<FieldType[]>;
  projectBids;
  currentProjectBid;
  $projectBidSub: Subscription;
  $currentProjectBidSub: Subscription;
  fieldTypes: [];
  createSuccess: boolean;
  constructor(private store: Store<RootState>, private dialog: MatDialog) {}

  ngOnInit() {
    this.store.dispatch(new LoadFieldTypes());
    this.$fieldTypes = this.store.select('fieldTypes', 'fieldTypes');
    this.$currentProjectBidSub = this.store
      .select(selectSelectedProjectBid)
      .subscribe((projectBid) => (this.currentProjectBid = projectBid));
    this.$projectBidSub = this.store
      .select('projectBids', 'data')
      .subscribe((projectBid) => (this.projectBids = projectBid));
    this.projectBidForm = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      type: new FormControl(null, [Validators.required]),
      id: new FormControl(null),
      visible: new FormControl(null),
      fieldOrder: new FormControl(null),
      agencyId: new FormControl(null),
      description: new FormControl(null),
      listId: new FormControl(null),
      deleted: new FormControl(null)
    });
    if (this.currentProjectBid) {
      this.projectBidForm.patchValue({
        type: this.currentProjectBid.type
      });
    }
  }

  ngOnDestroy() {
    this.$projectBidSub.unsubscribe();
    this.$currentProjectBidSub.unsubscribe();
  }

  compareObjects(o1: any, o2: any) {
    if (!!o1 && !!o2) {
      return o1.name === o2.name && o1.id && o2.id;
    }
  }

  save() {
    this.projectBidForm.patchValue({ fieldOrder: this.projectBids.length + 1 });
    this.store.dispatch(new AddProjectBids(this.projectBidForm.value));
    this.store.dispatch(new CloseDrawer());
    this.store.dispatch(new ClearProjectBidId());
    this.store.dispatch(new LoadProjectBids());
  }

  update() {
    this.projectBidForm.patchValue({ id: this.currentProjectBid.id });
    this.store.dispatch(new UpdateProjectBids(this.projectBidForm.value));
    this.store.dispatch(new CloseDrawer());
    this.store.dispatch(new ClearProjectBidId());
    this.store.dispatch(new LoadProjectBids());
  }

  cancel(): void {
    this.store.dispatch(new CloseDrawer());
    this.store.dispatch(new ClearProjectBidId());
  }

  delete(): void {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {});

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((willDelete: boolean) => {
        if (willDelete) {
          this.store.dispatch(new DeleteProjectBids(this.currentProjectBid.id));
          this.store.dispatch(new CloseDrawer());
          this.store.dispatch(new ClearProjectBidId());
          this.store.dispatch(new LoadProjectBids());
        }
      });
  }
}
