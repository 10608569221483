import { JpiOktaAuth } from './../../models/auth/jpi-okta-auth';
import { Injectable } from '@angular/core';
import { AuthModule } from './auth.module';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiLoginInput } from 'src/app/models/auth/api-login-input';
import { JpiLoginInput } from 'src/app/models/auth/jpi-login-input';
import { CookieService } from 'ngx-cookie-service';
import { AuthenticationResponse } from 'src/app/models/auth/authentication-response';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/admin/users/user';
import { AuthData } from 'src/app/store/auth/auth.reducer';
import { JpiAuthResponse } from 'src/app/models/auth/jpi-auth-response';

@Injectable({
  providedIn: AuthModule
})
export class AuthService {
  public cookieNames = {
    NewCustomFields: 'NCF',
    JpiToken: 'JpiToken',
    RoleId: 'RoleId',
    NewTelenotes: 'NewTelenotes',
    AuthToken: 'AuthToken',
    AgencyId: 'AgencyId',
    AgentId: 'AgentId',
    AgentMailbox: 'Mailbox',
    FullName: 'FullName',
    Snid: 'Snid',
    DefaultUrl: 'DefaultUrl',
    Email: 'Email'
  };
  public defaultValidHours = 12;

  private apiUrl: string = environment.apiUrl;
  private jpiUrl: string = environment.jpiUrl;
  private simpleHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private http: HttpClient,
    private cookies: CookieService,
    private handler: HttpBackend
  ) {}

  apiLogin(
    mailbox: string | number,
    password: string
  ): Observable<AuthenticationResponse> {
    const body = this.formatApiLoginInput(mailbox, password);
    return this.http.post(
      `${this.apiUrl}/api/security/AuthenticateWebUser`,
      JSON.stringify(body),
      {
        headers: this.simpleHeaders
      }
    ) as Observable<AuthenticationResponse>;
  }

  jpiLogin(email: string | number, password: string): Observable<JpiAuthResponse> {
    const body = this.formatJpiLoginInput(email, password);
    return this.http.post(`${this.jpiUrl}/users/authenticate`, JSON.stringify(body), {
      headers: this.simpleHeaders
    }) as Observable<JpiAuthResponse>;
  }

  signInAsOther(userId: number): Observable<AuthData> {
    return this.http.get(`${this.jpiUrl}/users/godMode/${userId}`, {
      headers: this.simpleHeaders
    }) as Observable<AuthData>;
  }

  getMFA(userData: any): Observable<any> {
    return this.http.post(
      `${this.jpiUrl}/users/request_auth_code`,
      userData
    ) as Observable<any>;
  }

  getMFAToken(authCode: number, userData: any): Observable<any> {
    return this.http.post(
      `${this.jpiUrl}/users/authenticate/mfa/${authCode}`,
      userData
    ) as Observable<any>;
  }

  oktaJpiLogin(
    accessCode: string,
    uri: string
  ): Observable<{
    token?: string;
    user?: User;
  }> {
    let body = this.formatJpiOktaBody(accessCode, uri);
    return this.http.post(
      `${this.jpiUrl}/oktasecurity/authenticate`,
      JSON.stringify(body),
      {
        headers: this.simpleHeaders
      }
    );
  }

  oktaApiLogin(accessCode: string): Observable<AuthenticationResponse> {
    const body = { accessCode };
    return this.http.post(
      `${this.apiUrl}/security/AuthenticateOktaUser`,
      JSON.stringify(accessCode),
      {
        headers: this.simpleHeaders
      }
    ) as Observable<AuthenticationResponse>;
  }

  logout() {
    window.location.href = 'https://' + environment.classicUrl + '/logout.aspx';
  }

  logoutFetch() {
    fetch('https://clsc.telenotes.com/logout.aspx');
  }

  setCookies(data: AuthData) {
    if (window.location.hostname === 'localhost') {
      document.cookie = `
        ${this.cookieNames.AuthToken}=${data.api.ApiAuthToken};
        max-age=${this.defaultValidHours * 60 * 60};path=/
        `.trim();
      document.cookie = `
        ${this.cookieNames.AgencyId}=${data.api.AgencyId.toString()};
        max-age=${this.defaultValidHours * 60 * 60};path=/
        `.trim();
      document.cookie = `
        ${this.cookieNames.AgentId}=${data.api.AgentId.toString()};
        max-age=${this.defaultValidHours * 60 * 60};path=/
        `.trim();
      document.cookie = `
        ${this.cookieNames.AgentMailbox}=${data.api.Mailbox.toString()};
        max-age=${this.defaultValidHours * 60 * 60};path=/
        `.trim();
      document.cookie = `
        ${this.cookieNames.FullName}=${data.api.FullName};
        max-age=${this.defaultValidHours * 60 * 60};path=/
        `.trim();
      document.cookie = `
        ${this.cookieNames.Snid}=${data.api.Snid};
        max-age=${this.defaultValidHours * 60 * 60};path=/
        `.trim();
      document.cookie = `
        ${this.cookieNames.DefaultUrl}=${data.api.DefaultUrl};
        max-age=${this.defaultValidHours * 60 * 60};path=/
        `.trim();
      document.cookie = `
        ${this.cookieNames.Email}=${data.api.Email};
        max-age=${this.defaultValidHours * 60 * 60};path=/
        `.trim();
      document.cookie = `
        ${this.cookieNames.NewTelenotes}=${data.api.UseNewTelenotes.toString()};
        max-age=${this.defaultValidHours * 60 * 60};path=/
        `.trim();
      document.cookie = `
        ${this.cookieNames.RoleId}=${data.api.RoleId.toString()};
        max-age=${this.defaultValidHours * 60 * 60};path=/
        `.trim();
      document.cookie = `
        ${
          this.cookieNames.NewCustomFields
        }=${data.jpi.user.client.useNewCustomFields.toString()};
        max-age=${this.defaultValidHours * 60 * 60};path=/
        `.trim();
    } else {
      this.cookies.set(
        this.cookieNames.AuthToken,
        data.api.ApiAuthToken,
        this.defaultValidHours,
        '/',
        window.location.hostname,
        true,
        'None'
      );
      this.cookies.set(
        this.cookieNames.AgencyId,
        data.api.AgencyId.toString(),
        this.defaultValidHours,
        '/',
        window.location.hostname,
        true,
        'None'
      );
      this.cookies.set(
        this.cookieNames.AgentId,
        data.api.AgentId.toString(),
        this.defaultValidHours,
        '/',
        window.location.hostname,
        true,
        'None'
      );
      this.cookies.set(
        this.cookieNames.AgentMailbox,
        data.api.Mailbox.toString(),
        this.defaultValidHours,
        '/',
        window.location.hostname,
        true,
        'None'
      );
      this.cookies.set(
        this.cookieNames.FullName,
        data.api.FullName,
        this.defaultValidHours,
        '/',
        window.location.hostname,
        true,
        'None'
      );
      this.cookies.set(
        this.cookieNames.Snid,
        data.api.Snid,
        this.defaultValidHours,
        '/',
        window.location.hostname,
        true,
        'None'
      );
      this.cookies.set(
        this.cookieNames.DefaultUrl,
        data.api.DefaultUrl,
        this.defaultValidHours,
        '/',
        window.location.hostname,
        true,
        'None'
      );
      this.cookies.set(
        this.cookieNames.Email,
        data.api.Email,
        this.defaultValidHours,
        '/',
        window.location.hostname,
        true,
        'None'
      );
      this.cookies.set(
        this.cookieNames.NewTelenotes,
        data.api.UseNewTelenotes.toString(),
        this.defaultValidHours,
        '/',
        window.location.hostname,
        true,
        'None'
      );
      this.cookies.set(
        this.cookieNames.RoleId,
        data.api.RoleId.toString(),
        this.defaultValidHours,
        '/',
        window.location.hostname,
        true,
        'None'
      );
      this.cookies.set(
        this.cookieNames.NewCustomFields,
        data.jpi.user.client.useNewCustomFields.toString(),
        this.defaultValidHours,
        '/',
        window.location.hostname,
        true,
        'None'
      );
    }
  }

  deleteCookies() {
    this.cookies.deleteAll('/');
  }

  private formatApiLoginInput(mailbox: string | number, password: string): ApiLoginInput {
    return {
      mailbox: isNaN(mailbox as any) ? mailbox : parseInt(mailbox.toString(), 10),
      password
    };
  }

  private formatJpiLoginInput(email: string | number, password: string): JpiLoginInput {
    const usernameKey = isNaN(email as any) ? 'email' : 'mailbox';
    return {
      [usernameKey]: isNaN(email as any) ? email : parseInt(email.toString(), 10),
      password
    };
  }
  private formatJpiOktaBody(accessCode: string, uri: string): JpiOktaAuth {
    return {
      accessCode,
      uri
    };
  }
  resetPassword(mailbox: number, agencyId: number, token: string, password: string) {
    const httpInterceptBypass = new HttpClient(this.handler);
    return httpInterceptBypass
      .post(this.apiUrl + '/api-v1.2.1/security/resetPassword', {
        mailbox,
        agencyId,
        token,
        password
      })
      .toPromise();
  }
}
