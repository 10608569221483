import { MergeRequest } from './../models/companies/merge-request';
import { Injectable } from '@angular/core';

import { LabelRequest } from '../models/label-request';
import { TerritoryRequest } from './../models/territory-request';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class BulkOperationsService {

	constructor(private authService: AuthenticationService) {
	}

	addLabels(labelRequest: LabelRequest) {
		return this.authService.makeJpiRequest('/bulkoperations/addlabels', null, labelRequest, 'post').then(response => {
			return response;
		});
	}

	removeLabels(labelRequest: LabelRequest) {
		return this.authService.makeJpiRequest('/bulkoperations/removeLabels', null, labelRequest, 'post').then(response => {
			return response;
		});
	}

	addTerritories(territoryRequest: TerritoryRequest) {
		return this.authService.makeJpiRequest('/bulkoperations/addterritories', null, territoryRequest, 'post').then(response => {
			return response;
		});
	}

	removeTerritories(territoryRequest: TerritoryRequest) {
		return this.authService.makeJpiRequest('/bulkoperations/removeterritories', null, territoryRequest, 'post').then(response => {
			return response;
		});
	}

	bulkEdit(body: MergeRequest) {
		return this.authService.makeJpiRequest('/bulkoperations/edit', null, body, 'put').then(response => {
			return response;
		});
	}
}
