import {Injectable} from '@angular/core';

import {UserV2} from '../models/admin/users/user-V2';
import {AuthenticationService} from './authentication.service';
import {NylasCalendar} from "../../modules/admin/nylas-sync/nylas-calendar";

@Injectable()
export class UserService {

    constructor(private authService: AuthenticationService) {
    }

    // I don't even know if we are using this method
    // authenticate(): Promise<boolean> {
    // 	return this._authService.makeJpiRequest('/users', null, null, 'get').then((result: any) => {
    // 		return JSON.parse(result);
    // 	});
    // }

    getActive(): Promise<UserV2[]> {
        return this.authService.makeJpiRequest('/users', null, null, 'get').then((users) => {
            users = JSON.parse(users);
            users.forEach(user => {
                user.fullName = `${user.firstName} ${user.lastName}`;
            });
            users = users.filter(user => !user.deleted);
            return users.sort((a: UserV2, b: UserV2) => a.firstName.localeCompare(b.firstName));
        });
    }

    getUsers(): Promise<UserV2[]> {
        return this.authService.makeJpiRequest('/users', null, null, 'get').then((users) => {
            users = JSON.parse(users);
            users.forEach(user => {
                user.fullName = `${user.firstName} ${user.lastName}`;
            });
            return JSON.parse(users);
        });
    }

    getById(id: number): Promise<UserV2> {
        return this.authService.makeJpiRequest('/users/' + id, null, null, 'get').then((user) => {
            return JSON.parse(user);
        });
    }

    getUsersForClient(agencyId: number): Promise<UserV2[]> {
        return this.authService.makeJpiRequest('/users?agencyId=' + agencyId, null, null, 'get').then((users) => {
            return JSON.parse(users);
        });
    }

    create(user: any): Promise<UserV2> {
        return this.authService.makeJpiRequest('/users', null, user, 'post').then((user) => {
            return JSON.parse(user);
        });
    }

    update(user: UserV2): Promise<UserV2> {
        return this.authService.makeJpiRequest('/users/' + user.id, null, user, 'put').then((user) => {
            return JSON.parse(user);
        });
    }

    delete(user: UserV2): Promise<UserV2> {
        return this.authService.makeJpiRequest('/users/' + user.id, null, user, 'delete').then((user) => {
            return JSON.parse(user);
        });
    }

    getNylasCalendars(): Promise<NylasCalendar[]> {
        return this.authService.makeJpiRequest('/nylas/calendars', null, null, 'get').then(calendars => {
            return JSON.parse(calendars);
        });
    }

    unsubscribe(user: UserV2): Promise<boolean> {
        return this.authService.makeJpiRequest('/nylas/unsubscribe', null, null, 'put').then(result => {
            return true;
        });
    }
}
