import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ItemName } from 'src/app/models/Opportunity/itemName';

@Injectable({
  providedIn: 'root'
})
export class ItemNameService {
  private jpiUrl = environment.jpiUrl;
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get(`${this.jpiUrl}/opportunityitemnames`);
  }

  getItemName(id: number) {
    return this.http.get(`${this.jpiUrl}/opportunityitemnames/${id}`);
  }

  createName(itemName: ItemName) {
    return this.http.post(`${this.jpiUrl}/opportunityitemnames`, itemName);
  }

  updateItemName(itemName: ItemName) {
    return this.http.put(`${this.jpiUrl}/opportunityitemnames/${itemName.id}`, itemName);
  }

  deleteItemName(itemNameId: number) {
    return this.http.delete(`${this.jpiUrl}/opportunityitemnames/${itemNameId}`);
  }
}
