import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as fromProjectManufCustomFields from './project-manuf-custom-fields.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ProjectManufCustomFieldsEffects } from './project-manuf-custom-fields.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromProjectManufCustomFields.projectManufCustomFieldsFeatureKey,
      fromProjectManufCustomFields.projectManufCustomFieldsReducer
    ),
    EffectsModule.forFeature([ProjectManufCustomFieldsEffects])
  ]
})
export class ProjManufCustomFieldsStateModule {}
