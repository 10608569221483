import { Pipe, PipeTransform } from '@angular/core';

import { CompanyV2 } from '../models/companies/company-V2';

@Pipe({ name: 'companyAddress' })
export class CompanyAddressPipe implements PipeTransform {

    transform(company: CompanyV2): string {
        let address: any;
        if (company.billingAddress) {
            address = company.billingAddress;
        } else if (company.shippingAddress) {
            address = company.shippingAddress;
        } else {
            return "No Address Provided";
        }

        this.fixNullFields(address);
        this.trimAddressFields(address);
        return this.concatenateAddressFields(address);
    }

    fixNullFields(address): void {
        const addressFields: string[] = Object.keys(address);
        addressFields.forEach(addressField => {
            if (!address[addressField]) {
                address[addressField] = '';
            }
        });
    }

    trimAddressFields(address): void {
        const addressFields: string[] = Object.keys(address);
        addressFields.forEach(addressField => address[addressField] = address[addressField] ? address[addressField].trim() : '');
    }

    concatenateAddressFields(address): any {
        const addressFields: string[] = Object.values(address);
        const addressFieldArray: string[] = [];
        addressFields.forEach(addressField => {
            if (addressField !== "") {
                addressField.charAt(addressField.length - 1) === '.' ? addressFieldArray.push(addressField + ' ') : addressFieldArray.push(addressField + ', ');
            }
        });

        return addressFieldArray.length === 0 ? "No Address Provided" : addressFieldArray.join('').slice(0, -2);
    }
}
