import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RootState } from 'src/app/store/store.reducer';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { ItemCustomField } from 'src/app/models/Opportunity/item-custom-field';
import { selectItemCustomFieldsAsArray } from 'src/app/store/opportunities/selectors/item-custom-fields.selector';
import { FieldType } from 'src/app/models/call-notes/field-type';
import { selectFieldTypesAsArray } from 'src/app/store/system-settings/field-types/selectors/field-types.selector';
import { map } from 'rxjs/operators';
import { selectCurrentOppItemCustomField } from 'src/app/store/opportunities/selectors/current-opp-item-custom-field.selector';
import {
  CreateOppItemCustomField,
  UpdateOppItemCustomField,
  DeleteOppItemCustomField
} from 'src/app/store/opportunities/opportunities.actions';
import { CloseDrawer } from 'src/app/store/layout/layout.actions';

@Component({
  selector: 'tn-opp-item-custom-field-form',
  templateUrl: './opp-item-custom-field-form.component.html',
  styleUrls: ['./opp-item-custom-field-form.component.scss']
})
export class OppItemCustomFieldFormComponent implements OnInit {
  $fields: Observable<ItemCustomField[]>;
  fields: ItemCustomField[];
  $types: Observable<FieldType[]>;
  $fieldsSub: Subscription;

  currentField: ItemCustomField;

  oppItemCFForm = new FormGroup({
    name: new FormControl(null, Validators.required),
    type: new FormControl(null, Validators.required)
  });
  constructor(private store: Store<RootState>) {}

  ngOnInit() {
    this.$fields = this.store.select(selectItemCustomFieldsAsArray);
    this.$fieldsSub = this.$fields.subscribe(fields => {
      this.fields = fields;
    });
    this.$types = this.store
      .select(selectFieldTypesAsArray)
      .pipe(map(types => types.filter(type => type.opportunityItemField === true)));

    this.store.select(selectCurrentOppItemCustomField).subscribe(icf => {
      if (!!icf) {
        this.currentField = icf;
        this.oppItemCFForm.setValue({
          name: icf.name,
          type: {
            name: icf.typeName,
            id: icf.typeId
          }
        });
      }
    });
  }

  compareObjects(o1: any, o2: any) {
    if (!!o1 && !!o2) {
      return o1.name === o2.name && o1.id && o2.id;
    }
  }

  onSave() {
    if (!this.currentField) {
      this.store.dispatch(
        new CreateOppItemCustomField({
          name: this.oppItemCFForm.value.name,
          typeId: this.oppItemCFForm.value.type.id,
          listorder: this.fields.length
        })
      );
    } else {
      this.store.dispatch(
        new UpdateOppItemCustomField({
          ...this.currentField,
          name: this.oppItemCFForm.value.name,
          typeId: this.oppItemCFForm.value.type.id,
          listorder: this.currentField.listorder
        })
      );
    }
    this.store.dispatch(new CloseDrawer());
  }

  onDelete() {
    this.store.dispatch(new DeleteOppItemCustomField(this.currentField.id));
    this.store.dispatch(new CloseDrawer());
  }

  onCancel() {
    this.store.dispatch(new CloseDrawer());
  }
}
