import { Action } from '@ngrx/store';
import { GenericMap } from 'src/app/models/generic-map';
import { CallNote } from 'src/app/models/call-notes/call-note';
import { CallNoteAudio } from 'src/app/models/call-notes/call-note';
import { HttpErrorResponse } from '@angular/common/http';
import { GlobalFilter } from 'src/app/models/global-filter';
import { Comment } from 'src/app/models/call-notes/comment';

export enum CallNotesActionTypes {
  SelectCallNoteId = '[CallNotes] Select Call Note Id',

  LoadCallNotes = '[CallNotes] Load Call Notes',
  LoadCallNotesSuccess = '[CallNotes] Load Call Notes Success',
  LoadCallNotesFailure = '[CallNotes] Load Call Notes Failure',

  LoadCallNote = '[CallNotes] Load Call Note',
  LoadCallNoteSuccess = '[CallNotes] Load Call Note Success',
  LoadCallNoteFailure = '[CallNotes] Load Call Note Failure',

  SaveCallNote = '[CallNotes] Save Call Note',
  SaveCallNoteSuccess = '[CallNotes] Save Call Note Success',
  SaveCallNoteFailure = '[CallNotes] Save Call Note Failure',

  SaveCallNoteAudio = '[CallNotes] Save Call Note Audio',
  SaveCallNoteAudioSuccess = '[CallNotes] Save Call Note Audio Success',
  SaveCallNoteAudioFailure = '[CallNotes] Save Call Note Audio Failure',

  DeleteCallNote = '[CallNotes] Delete Call Note',
  DeleteCallNoteSuccess = '[CallNotes] Delete Call Note Success',
  DeleteCallNoteFailure = '[CallNotes] Delete Call Note Failure',

  SaveCallNoteComment = '[CallNotes] Save Call Note Comment',
  SaveCallNoteCommentSuccess = '[CallNotes] Save Call Note Comment Success',
  SaveCallNoteCommentFailure = '[CallNotes] Save Call Note Comment Failure',

  DeleteCallNoteComment = '[CallNotes] Delete Call Note Comment',
  DeleteCallNoteCommentSuccess = '[CallNotes] Delete Call Note Comment Success',
  DeleteCallNoteCommentFailure = '[CallNotes] Delete Call Note Comment Failure',

  LoadCallNoteComments = '[CallNotes] Load Call Note Comments',
  LoadCallNoteCommentsSuccess = '[CallNotes] Load Call Note Comments Success',
  LoadCallNoteCommentsFailure = '[CallNotes] Load Call Note Comments Failure',

  CancelCallNote = '[CallNotes] Cancel Call Note',

  LoadCallNotesByCompanyId = '[CallNotes] Load Call Notes By Company ID',
  LoadCallNotesByCompanyIdSuccess = '[CallNotes] Load Call Notes By Company ID Success',
  LoadCallNotesByCompanyIdFailure = '[CallNotes] Load Call Notes By Company ID Failure',

  LoadCallNotesByCompanyIdWithFollowUps = '[CallNotes] Load Call Notes By Company ID With Follow Ups',
  LoadCallNotesByCompanyIdWithFollowUpsSuccess = '[CallNotes] Load Call Notes By Company ID With Follow Ups Success',
  LoadCallNotesByCompanyIdWithFollowUpsFailure = '[CallNotes] Load Call Notes By Company ID With Follow Ups Failure',

  LoadCallNoteAudioById = '[CallNotes] Load Call Note Audio By ID',
  LoadCallNoteAudioByIdSuccess = '[CallNotes] Load Call Note Audio By ID Success',
  LoadCallNoteAudioByIdFailure = '[CallNotes] Load Call Note Audio By ID Failure',

  ClearCallNotesByCompanyId = '[CallNotes] Clear Call Notes By Company ID',
  ClearCallNotesByCompanyIdWithFollowUps = '[CallNotes] Clear Call Notes By Company ID With Follow Ups'
}

export class SelectCallNoteId implements Action {
  readonly type = CallNotesActionTypes.SelectCallNoteId;
  constructor(public payload: number) {}
}

export class LoadCallNotes implements Action {
  readonly type = CallNotesActionTypes.LoadCallNotes;
  constructor(
    public payload: {
      filter: GlobalFilter;
      params: {
        limit: string;
        order?: string;
        skip: string;
      };
    }
  ) {}
}

export class LoadCallNotesSuccess implements Action {
  readonly type = CallNotesActionTypes.LoadCallNotesSuccess;
  constructor(public payload: GenericMap<CallNote>) {}
}

export class LoadCallNotesFailure implements Action {
  readonly type = CallNotesActionTypes.LoadCallNotesFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadCallNote implements Action {
  readonly type = CallNotesActionTypes.LoadCallNote;
  constructor(public payload: number) {}
}

export class LoadCallNoteSuccess implements Action {
  readonly type = CallNotesActionTypes.LoadCallNoteSuccess;
  constructor(public payload: CallNote) {}
}

export class LoadCallNoteFailure implements Action {
  readonly type = CallNotesActionTypes.LoadCallNoteFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadCallNoteComments implements Action {
  readonly type = CallNotesActionTypes.LoadCallNoteComments;
  constructor(public payload: number) {}
}

export class LoadCallNoteCommentsSuccess implements Action {
  readonly type = CallNotesActionTypes.LoadCallNoteCommentsSuccess;
  constructor(public payload: { callNoteId: number; comments: Comment[] }) {}
}

export class LoadCallNoteCommentsFailure implements Action {
  readonly type = CallNotesActionTypes.LoadCallNoteCommentsFailure;
  constructor(public payload: { callNoteId: number; error: HttpErrorResponse }) {}
}

export class LoadCallNoteAudioById implements Action {
  readonly type = CallNotesActionTypes.LoadCallNoteAudioById;
  constructor(public payload: number) {}
}

export class LoadCallNoteAudioByIdSuccess implements Action {
  readonly type = CallNotesActionTypes.LoadCallNoteAudioByIdSuccess;
  constructor(public payload: { callNoteId: number; audio: string }) {}
}

export class LoadCallNoteAudioByIdFailure implements Action {
  readonly type = CallNotesActionTypes.LoadCallNoteAudioByIdFailure;
  constructor(public payload: { callNoteId: number; error: HttpErrorResponse }) {}
}

export class SaveCallNote implements Action {
  readonly type = CallNotesActionTypes.SaveCallNote;
  constructor(public payload: CallNote) {}
}

export class SaveCallNoteSuccess implements Action {
  readonly type = CallNotesActionTypes.SaveCallNoteSuccess;
  constructor(public payload: CallNote) {}
}

export class SaveCallNoteFailure implements Action {
  readonly type = CallNotesActionTypes.SaveCallNoteFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class SaveCallNoteAudio implements Action {
  readonly type = CallNotesActionTypes.SaveCallNoteAudio;
  constructor(public payload: any) {}
}

export class SaveCallNoteAudioSuccess implements Action {
  readonly type = CallNotesActionTypes.SaveCallNoteAudioSuccess;
  constructor(public payload: CallNoteAudio) {}
}

export class SaveCallNoteAudioFailure implements Action {
  readonly type = CallNotesActionTypes.SaveCallNoteAudioFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class DeleteCallNote implements Action {
  readonly type = CallNotesActionTypes.DeleteCallNote;
  constructor(public payload: CallNote) {}
}

export class DeleteCallNoteSuccess implements Action {
  readonly type = CallNotesActionTypes.DeleteCallNoteSuccess;
  constructor(public payload: { callNoteId: number; companyId: number }) {}
}

export class DeleteCallNoteFailure implements Action {
  readonly type = CallNotesActionTypes.DeleteCallNoteFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class SaveCallNoteComment implements Action {
  readonly type = CallNotesActionTypes.SaveCallNoteComment;
  constructor(public payload: Comment) {}
}

export class SaveCallNoteCommentSuccess implements Action {
  readonly type = CallNotesActionTypes.SaveCallNoteCommentSuccess;
  constructor(public payload: Comment) {}
}

export class SaveCallNoteCommentFailure implements Action {
  readonly type = CallNotesActionTypes.SaveCallNoteCommentFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class DeleteCallNoteComment implements Action {
  readonly type = CallNotesActionTypes.DeleteCallNoteComment;
  constructor(public payload: { comment: Comment; callNoteId: number }) {}
}

export class DeleteCallNoteCommentSuccess implements Action {
  readonly type = CallNotesActionTypes.DeleteCallNoteCommentSuccess;
  constructor(public payload: { comment: Comment; callNoteId: number }) {}
}

export class DeleteCallNoteCommentFailure implements Action {
  readonly type = CallNotesActionTypes.DeleteCallNoteCommentFailure;
  constructor(public payload: { error: HttpErrorResponse; callNoteId: number }) {}
}

export class CancelCallNote implements Action {
  readonly type = CallNotesActionTypes.CancelCallNote;
}

export class LoadCallNotesByCompanyId implements Action {
  readonly type = CallNotesActionTypes.LoadCallNotesByCompanyId;
  constructor(
    public payload: {
      companyId: number;
      filter: GlobalFilter;
      params: {
        limit: string;
        order?: string;
        skip?: string;
      };
    }
  ) {}
}

export class LoadCallNotesByCompanyIdSuccess implements Action {
  readonly type = CallNotesActionTypes.LoadCallNotesByCompanyIdSuccess;
  constructor(
    public payload: { companyId: number; data: GenericMap<CallNote>; count: number }
  ) {}
}

export class LoadCallNotesByCompanyIdFailure implements Action {
  readonly type = CallNotesActionTypes.LoadCallNotesByCompanyIdFailure;
  constructor(public payload: { companyId: number; error: HttpErrorResponse }) {}
}

export class LoadCallNotesByCompanyIdWithFollowUps implements Action {
  readonly type = CallNotesActionTypes.LoadCallNotesByCompanyIdWithFollowUps;
  constructor(
    public payload: {
      companyId: number;
      filter: GlobalFilter;
      params: {
        limit: string;
        order?: string;
        skip?: string;
      };
    }
  ) {}
}

export class LoadCallNotesByCompanyIdWithFollowUpsSuccess implements Action {
  readonly type = CallNotesActionTypes.LoadCallNotesByCompanyIdWithFollowUpsSuccess;
  constructor(
    public payload: { companyId: number; data: GenericMap<CallNote>; count: number }
  ) {}
}

export class LoadCallNotesByCompanyIdWithFollowUpsFailure implements Action {
  readonly type = CallNotesActionTypes.LoadCallNotesByCompanyIdWithFollowUpsFailure;
  constructor(public payload: { companyId: number; error: HttpErrorResponse }) {}
}

export class ClearCallNotesByCompanyId implements Action {
  readonly type = CallNotesActionTypes.ClearCallNotesByCompanyId;
  constructor(public payload: number) {}
}

export class ClearCallNotesByCompanyIdWithFollowUps implements Action {
  readonly type = CallNotesActionTypes.ClearCallNotesByCompanyIdWithFollowUps;
  constructor(public payload: number) {}
}

export type CallNotesActions =
  | SelectCallNoteId
  | LoadCallNotes
  | LoadCallNotesSuccess
  | LoadCallNotesFailure
  | LoadCallNote
  | LoadCallNoteSuccess
  | LoadCallNoteFailure
  | LoadCallNoteComments
  | LoadCallNoteCommentsSuccess
  | LoadCallNoteCommentsFailure
  | SaveCallNote
  | SaveCallNoteSuccess
  | SaveCallNoteFailure
  | SaveCallNoteAudio
  | SaveCallNoteAudioSuccess
  | SaveCallNoteAudioFailure
  | SaveCallNoteComment
  | SaveCallNoteCommentSuccess
  | SaveCallNoteCommentFailure
  | DeleteCallNote
  | DeleteCallNoteSuccess
  | DeleteCallNoteFailure
  | DeleteCallNoteComment
  | DeleteCallNoteCommentSuccess
  | DeleteCallNoteCommentFailure
  | CancelCallNote
  | LoadCallNotesByCompanyId
  | LoadCallNotesByCompanyIdSuccess
  | LoadCallNotesByCompanyIdFailure
  | LoadCallNotesByCompanyIdWithFollowUps
  | LoadCallNotesByCompanyIdWithFollowUpsSuccess
  | LoadCallNotesByCompanyIdWithFollowUpsFailure
  | ClearCallNotesByCompanyId
  | ClearCallNotesByCompanyIdWithFollowUps
  | LoadCallNoteAudioById
  | LoadCallNoteAudioByIdSuccess
  | LoadCallNoteAudioByIdFailure;
