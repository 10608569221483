import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ContactField } from 'src/app/models/contacts/contact-field';

export enum ContactCustomFieldsActionTypes {
  SetCurrentContactField = '[ContactCustomFields] Set Current Contact Custom Field',
  ClearCurrentContactField = '[ContactCustomFields] Clear Current Contact Custom Field',
  LoadContactCustomFields = '[ContactCustomFields] Load ContactCustomFields',
  LoadContactCustomFieldsSuccess = '[ContactCustomFields] Load ContactCustomFields Success',
  LoadContactCustomFieldsFailure = '[ContactCustomFields] Load ContactCustomFields Failure',
  CreateContactCustomField = '[ContactCustomFields] Create ContactCustomField',
  CreateContactCustomFieldSuccess = '[ContactCustomFields] Create ContactCustomField Success',
  CreateContactCustomFieldFailure = '[ContactCustomFields] Create ContactCustomField Failure',
  UpdateContactCustomField = '[ContactCustomFields] Update Load ContactCustomField',
  UpdateContactCustomFieldSuccess = '[ContactCustomFields] Update ContactCustomField Success',
  UpdateContactCustomFieldFailure = '[ContactCustomFields] Update ContactCustomField Failure',
  DeleteContactCustomField = '[ContactCustomFields] Delete ContactCustomField',
  DeleteContactCustomFieldSuccess = '[ContactCustomFields] Delete ContactCustomField Success',
  DeleteContactCustomFieldFailure = '[ContactCustomFields] Delete ContactCustomField Failure'
}

export class SetCurrentContactField implements Action {
  readonly type = ContactCustomFieldsActionTypes.SetCurrentContactField;
  constructor(public payload: ContactField) {}
}

export class ClearCurrentContactField implements Action {
  readonly type = ContactCustomFieldsActionTypes.ClearCurrentContactField;
}

export class LoadContactCustomFields implements Action {
  readonly type = ContactCustomFieldsActionTypes.LoadContactCustomFields;
}

export class LoadContactCustomFieldsSuccess implements Action {
  readonly type = ContactCustomFieldsActionTypes.LoadContactCustomFieldsSuccess;
  constructor(public payload: ContactField[]) {}
}

export class LoadContactCustomFieldsFailure implements Action {
  readonly type = ContactCustomFieldsActionTypes.LoadContactCustomFieldsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class CreateContactCustomField implements Action {
  readonly type = ContactCustomFieldsActionTypes.CreateContactCustomField;
  constructor(public payload: ContactField) {}
}

export class CreateContactCustomFieldSuccess implements Action {
  readonly type = ContactCustomFieldsActionTypes.CreateContactCustomFieldSuccess;
  constructor(public payload: ContactField) {}
}

export class CreateContactCustomFieldFailure implements Action {
  readonly type = ContactCustomFieldsActionTypes.CreateContactCustomFieldFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class UpdateContactCustomField implements Action {
  readonly type = ContactCustomFieldsActionTypes.UpdateContactCustomField;
  constructor(public payload: ContactField) {}
}

export class UpdateContactCustomFieldSuccess implements Action {
  readonly type = ContactCustomFieldsActionTypes.UpdateContactCustomFieldSuccess;
  constructor(public payload: ContactField) {}
}

export class UpdateContactCustomFieldFailure implements Action {
  readonly type = ContactCustomFieldsActionTypes.UpdateContactCustomFieldFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class DeleteContactCustomField implements Action {
  readonly type = ContactCustomFieldsActionTypes.DeleteContactCustomField;
  constructor(public payload: number) {}
}

export class DeleteContactCustomFieldSuccess implements Action {
  readonly type = ContactCustomFieldsActionTypes.DeleteContactCustomFieldSuccess;
  constructor(public payload: number) {}
}

export class DeleteContactCustomFieldFailure implements Action {
  readonly type = ContactCustomFieldsActionTypes.DeleteContactCustomFieldFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export type ContactCustomFieldsActions =
  | SetCurrentContactField
  | ClearCurrentContactField
  | LoadContactCustomFields
  | LoadContactCustomFieldsSuccess
  | LoadContactCustomFieldsFailure
  | CreateContactCustomField
  | CreateContactCustomFieldSuccess
  | CreateContactCustomFieldFailure
  | UpdateContactCustomField
  | UpdateContactCustomFieldSuccess
  | UpdateContactCustomFieldFailure
  | DeleteContactCustomField
  | DeleteContactCustomFieldSuccess
  | DeleteContactCustomFieldFailure;
