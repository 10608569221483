import { RootState } from '../../store.reducer';
import { MemoizedSelector, createSelector } from '@ngrx/store';
import { FileMetaData } from 'src/app/models/file-meta-data';
import { selectCompanyParamId } from './current-company.selector';

export const selectAllFiles = (state: RootState) => state.companies.files;
export const selectAgencyId = (state: RootState) =>
  state.auth.data.jpi ? state.auth.data.jpi.user.agencyId : undefined;

export const selectFilesByCompanyAsArray: MemoizedSelector<RootState, FileMetaData[]> =
  createSelector(
    selectAllFiles,
    selectCompanyParamId,
    selectAgencyId,
    (filesMap, companyId: number, agencyId: number): FileMetaData[] => {
      return Object.values(filesMap[agencyId].data)
        .filter((file) => file.companyId === companyId)
        .map((file: FileMetaData) => ({
          id: file.id,
          companyId: file.companyId,
          downloadUrl: file.downloadUrl,
          fileName: file.fileName,
          fileType: file.fileType,
          fileSize: file.fileSize,
          uploadedBy: file.uploadedBy,
          uploadedDate: file.uploadedDate
        }))
        .sort((a, b) => {
          if (a.uploadedDate > b.uploadedDate) {
            return -1;
          }
          if (a.uploadedDate < b.uploadedDate) {
            return 1;
          }

          return 0;
        });
    }
  );
