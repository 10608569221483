import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ProjectLabelsIndividiualEffects } from './project-labels-individiual.effects';
import { projectLabelsIndividualReducer } from './project-labels-individiual.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('projectLabelsIndividual', projectLabelsIndividualReducer),
    EffectsModule.forFeature([ProjectLabelsIndividiualEffects])
  ]
})
export class ProjectLabelsIndividualStateModule {}
