<form [formGroup]="projectBidForm" class="project-bids-form">
  <div>
    <mat-form-field fxFlex>
      <mat-label>Name</mat-label>
      <input
        [value]="currentProjectBid?.name"
        formControlName="name"
        matInput
        type="text"
        (keydown.enter)="$event.stopPropagation()"
      />
    </mat-form-field>
  </div>
  <div>
    <mat-form-field fxFlex>
      <mat-label>Type</mat-label>
      <mat-select
        formControlName="type"
        matInput
        [compareWith]="compareObjects"
        (keydown.enter)="$event.stopPropagation()"
      >
        <mat-option *ngFor="let type of $fieldTypes | async" [value]="type">{{
          type.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div fxLayout="row-reverse" fxLayoutAlign="space-between center">
    <div fxLayout="row-reverse">
      <button
        *ngIf="!currentProjectBid?.id"
        type="button"
        mat-raised-button
        color="primary"
        mat-button
        [disabled]="projectBidForm.pristine || projectBidForm.invalid"
        (click)="save()"
        (keydown.enter)="$event.stopPropagation()"
      >
        Create
      </button>
      <button
        *ngIf="currentProjectBid?.id"
        type="button"
        mat-raised-button
        color="primary"
        mat-button
        [disabled]="projectBidForm.pristine || projectBidForm.invalid"
        (click)="update()"
        (keydown.enter)="$event.stopPropagation()"
      >
        Save
      </button>
      &nbsp;
      <button
        preventDefaultAction
        mat-raised-button
        color="white"
        mat-button
        (click)="cancel()"
      >
        Cancel
      </button>
    </div>
    <button
      *ngIf="currentProjectBid?.id"
      preventDefaultAction
      mat-raised-button
      color="accent"
      mat-button
      (click)="delete()"
    >
      Delete
    </button>
  </div>
</form>
