import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Category } from 'src/app/models/admin/files/category';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {
  private jpiUrl = environment.jpiUrl;
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get(`${this.jpiUrl}/filecategories`);
  }

  getCategory(id: number) {
    return this.http.get(`${this.jpiUrl}/filecategories/${id}`);
  }

  createName(category: Category) {
    return this.http.post(`${this.jpiUrl}/filecategories`, category);
  }

  updateCategory(category: Category) {
    return this.http.put(`${this.jpiUrl}/filecategories/${category.id}`, category);
  }

  deleteCategory(categoryId: number) {
    return this.http.delete(`${this.jpiUrl}/filecategories/${categoryId}`);
  }
}
