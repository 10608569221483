import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { MileageEffects } from './mileage.effects';
import { mileageReducer } from './mileage.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('mileage', mileageReducer),
    EffectsModule.forFeature([MileageEffects])
  ]
})
export class MileageStateModule {}
