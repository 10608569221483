import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import {
  LoadContactsFailure,
  LoadContactsSuccess,
  ContactsActionTypes,
  ContactsActions,
  LoadContacts,
  SaveContact,
  SaveContactSuccess,
  SaveContactFailure,
  LoadContact,
  LoadContactSuccess,
  LoadContactFailure
} from './contacts.actions';
import { ContactsService } from 'src/app/services/contacts.service';
import { Contact } from 'src/app/models/contacts/contact';
import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';

@Injectable()
export class ContactsEffects {
  constructor(
    private contactsService: ContactsService,
    private actions$: Actions<ContactsActions>
  ) {}

  loadContacts$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactsActionTypes.LoadContacts),
      map((action: LoadContacts) => action.payload),
      mergeMap((companyId: number) =>
        this.contactsService.getContactsForCompany(companyId).pipe(
          map((data: Contact[]) => new LoadContactsSuccess(data)),
          catchError((error: HttpErrorResponse) => of(new LoadContactsFailure(error)))
        )
      )
    )
  );

  loadContact$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactsActionTypes.LoadContact),
      map((action: LoadContact) => action.payload),
      mergeMap((action: { companyId: number; contactId: number }) =>
        this.contactsService.getContact(action.companyId, action.contactId).pipe(
          map((data: Contact) => new LoadContactSuccess(data)),
          catchError((error: HttpErrorResponse) => of(new LoadContactFailure(error)))
        )
      )
    )
  );

  saveContact$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactsActionTypes.SaveContact),
      map((action: SaveContact) => action.payload),
      mergeMap((action: { contact: Contact; companyId: number }) => {
        return this.contactsService.createContact(action.contact, action.companyId).pipe(
          map((newContact: Contact) => new SaveContactSuccess(newContact)),
          catchError((error: HttpErrorResponse) => of(new SaveContactFailure(error)))
        );
      })
    )
  );
}
