import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';

export enum DmsActionTypes {
  LoadMagentoDmsLinkedCompany = '[Dms] Load Magento Dms Linked Company',
  LoadMagentoDmsLinkedCompanySuccess = '[Dms] Load Magento Dms Linked Company Success',
  LoadMagentoDmsLinkedCompanyFailure = '[Dms] Load Magento Dms Linked Company Failure'
}

export class LoadMagentoDmsLinkedCompany implements Action {
  readonly type = DmsActionTypes.LoadMagentoDmsLinkedCompany;
  constructor(public payload: { customerId: string; date: string }) {}
}

export class LoadMagentoDmsLinkedCompanySuccess implements Action {
  readonly type = DmsActionTypes.LoadMagentoDmsLinkedCompanySuccess;
  constructor(public payload: any) {}
}

export class LoadMagentoDmsLinkedCompanyFailure implements Action {
  readonly type = DmsActionTypes.LoadMagentoDmsLinkedCompanyFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export type DmsActions =
  | LoadMagentoDmsLinkedCompany
  | LoadMagentoDmsLinkedCompanySuccess
  | LoadMagentoDmsLinkedCompanyFailure;
