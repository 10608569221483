import { BrowserModule, Title } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { RootStoreModule } from './store/store.module';
import { ViewModule } from './view/view.module';

import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { InquiryComponent } from './view/pages/inquiry/inquiry.component';

import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';

@NgModule({
  declarations: [AppComponent, InquiryComponent],
  imports: [
    BrowserModule,
    RootStoreModule,
    ViewModule,
    ReactiveFormsModule,
    MatDialogModule
  ],
  providers: [
    Title,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
