import { RouteData } from '../models/routing/route-data.model';

export const routeData: RouteData = {
  dashboard: {
    icon: 'insert_chart',
    path: 'dashboard',
    title: 'Dashboard'
  },
  companies: {
    icon: 'business',
    path: 'companies-list',
    title: 'Companies'
  },
  activity: {
    icon: 'check',
    path: 'activity-list/list',
    title: 'Call Notes'
  },
  calendar: {
    icon: 'today',
    path: 'calendar',
    title: 'Calendar'
  },
  maps: {
    icon: 'place',
    path: 'maps',
    title: 'Maps'
  },
  classicOpportunities: {
    icon: 'trending_up',
    path: 'opportunities-classic',
    title: 'Opportunities'
  },
  opportunities: {
    icon: 'trending_up',
    path: 'opportunities',
    title: 'Opportunities'
  },
  privateNotes: {
    icon: 'verified_user',
    path: 'private-notes',
    title: 'Private Notes'
  },
  projects: {
    icon: 'assignment',
    path: 'projects',
    title: 'Projects'
  },
  sales: {
    icon: 'local_atm',
    path: 'salescommissions',
    title: 'Sales'
  },
  reports: {
    icon: 'equalizer',
    path: 'reports-classic',
    title: 'Reports'
  },
  mileage: {
    icon: 'directions_car',
    path: 'mileage',
    title: 'Mileage'
  },
  expenses: {
    icon: 'credit_card',
    path: 'expenses',
    title: 'Expenses'
  }
  // callNotes: {
  //   icon: 'description',
  //   path: 'v2callnotes',
  //   title: 'Call Notes'
  // }
};
