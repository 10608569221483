import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CompanyListColumnsService {
  constructor(private http: HttpClient) {}

  getColumns(option: string) {
    let path;
    switch (option) {
      case 'company':
        path = '../../../../assets/jsondata/company-columns.json';
        break;
      case 'editList':
        path = '../../../../assets/jsondata/companylist-columns.json';
        break;
    }
    return this.http.get(path);
  }
}
