import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import {
  LoadManageUsersFailure,
  LoadManageUsersSuccess,
  ManageUsersActionTypes,
  ManageUsersActions
} from './manage-users.actions';

@Injectable()
export class ManageUsersEffects {
  loadManageUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageUsersActionTypes.LoadManageUsers),
      concatMap(() =>
        /** An EMPTY observable only emits completion. Replace with your own observable API request */
        EMPTY.pipe(
          map(data => new LoadManageUsersSuccess({ data })),
          catchError(error => of(new LoadManageUsersFailure({ error })))
        )
      )
    )
  );

  constructor(private actions$: Actions<ManageUsersActions>) {}
}
