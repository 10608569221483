import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { BidCustomFieldsEffects } from './bid-custom-fields.effects';
import {
  bidCustomFieldsFeatureKey,
  bidCustomFieldsReducer
} from './bid-custom-fields.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(bidCustomFieldsFeatureKey, bidCustomFieldsReducer),
    EffectsModule.forFeature([BidCustomFieldsEffects])
  ]
})
export class BidCustomFieldsStateModule {}
