import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Label } from 'src/app/models/label';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OpportunityLabelsIndividualService {
  private jpiUrl = environment.jpiUrl;

  constructor(private http: HttpClient) {}

  getAll(groupId: number) {
    return this.http.get(`${this.jpiUrl}/opportunitylabelgroups/${groupId}/labels`);
  }

  getById(labelId: number, groupId: number) {
    return this.http.get(
      `${this.jpiUrl}/opportunitylabelgroups/${groupId}/labels/${labelId}`
    );
  }

  create(label: Label, groupId: number) {
    return this.http.post(
      `${this.jpiUrl}/opportunitylabelgroups/${groupId}/labels`,
      label
    );
  }

  update(label: Label, groupId: number): Observable<Label> {
    return this.http.put(
      `${this.jpiUrl}/opportunitylabelgroups/${groupId}/labels/${label.id}`,
      label
    ) as Observable<Label>;
  }

  delete(groupId: number, labelId: number) {
    return this.http.delete(
      `${this.jpiUrl}/opportunitylabelgroups/${groupId}/labels/${labelId}`
    );
  }

  setLabelListOrder(labelGroupId: number, labels: Label[]): Observable<Label[]> {
    return this.http.post(
      `${this.jpiUrl}/opportunitylabelgroups/${labelGroupId}/labels/setListOrder`,
      labels
    ) as Observable<Label[]>;
  }
}
