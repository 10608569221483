<div class="private-note-form" fxLayout="column" fxLayoutAlign="space-between center">
  <mat-accordion [multi]="true" class="private-note-form-panels">
    <mat-expansion-panel [expanded]="true" disabled>
      <mat-expansion-panel-header>
        <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center">
          <div class="mat-card-title" fxFlex="100">
            <div fxLayoutAlign="space-between center">
              <span style="color: black">Private Note</span>
              <div
                class="private-note-created-info"
                *ngIf="!!currentPrivateNote?.id"
                fxLayout="column"
                fxLayoutGap="6px"
              >
                <div>
                  Created By:
                  {{
                    currentPrivateNote.createdBy.firstName +
                      ' ' +
                      currentPrivateNote.createdBy?.lastName
                  }}
                </div>
                <div>On: {{ currentPrivateNote.createdDate | date : 'medium' }}</div>
                <div>ID: {{ currentPrivateNote.id }}</div>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel-header>
      <form [formGroup]="privateNoteForm" (submit)="savePrivateNote()" autocomplete="off">
        <div fxLayout="column">
          <div fxLayout="row" fxLayoutGap="12px">
            <mat-form-field fxFlex="33%">
              <input
                matInput
                [matDatepicker]="callDatePicker"
                formControlName="callDate"
                required
                placeholder="Choose a date"
                (click)="callDatePicker.open()"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="callDatePicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #callDatePicker></mat-datepicker>
            </mat-form-field>
            <tn-company-select
              fxFlex="67%"
              (companySearchEvent)="onCompanySearch($event)"
              [$companySearchResults]="$companies"
              [selectedCompany]="currentPrivateNote?.company"
              [$error]="$companiesSearchError"
              [isRequired]="true"
              [isDisabled]="!isOwner"
              (companySelected)="onCompanySelect($event)"
              [$pending]="$companiesSearchPending"
            ></tn-company-select>
          </div>
          <div fxLayout="row">
            <mat-form-field fxFlex="100%">
              <mat-label>Private Note</mat-label>
              <textarea
                class="text-area"
                type="text"
                matInput
                formControlName="note"
                required
              ></textarea>
            </mat-form-field>
          </div>
          <div fxLayout="row">
            <tn-multi-select
              fxFlex="100%"
              [$allItems]="$assignableUsers"
              [selectedItems]="currentPrivateNote?.assignedTo"
              (selectedItemsChange)="onAssignableUserSelect($event)"
              [placeholder]="'Assign To'"
              [isRemovable]="isOwner"
              [isDisabled]="!isOwner"
            ></tn-multi-select>
          </div>
        </div>
      </form>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="form-actions" fxLayout="row-reverse" fxLayoutAlign="space-between center">
    <div fxLayout="row-reverse" fxLayoutGap="12px">
      <button
        *ngIf="isOwner"
        type="button"
        mat-raised-button
        color="primary"
        mat-button
        (click)="savePrivateNote()"
        [disabled]="privateNoteForm.invalid || privateNoteForm.pristine"
      >
        Save
      </button>
      <button
        preventDefaultAction
        type="button"
        mat-raised-button
        color="white"
        mat-button
        (click)="cancelButtonClicked()"
      >
        Cancel
      </button>
    </div>
    <button
      *ngIf="currentPrivateNote?.id && isOwner"
      preventDefaultAction
      type="button"
      color="warn"
      class="text-white"
      mat-button
      (click)="deletePrivateNote()"
    >
      Delete
    </button>
  </div>
</div>
