import { FieldTypesActions, FieldTypesActionTypes } from './field-types.actions';
import { FieldType } from 'src/app/models/call-notes/field-type';
import { HttpErrorResponse } from '@angular/common/http';
import { GenericMap } from 'src/app/models/generic-map';
import { convertToMap } from 'src/app/utils/convertToMap';

export interface FieldTypesState {
  pending: boolean;
  fieldTypes: FieldType[];
  error: HttpErrorResponse;
  data: GenericMap<FieldType>;
}

export const initialState: FieldTypesState = {
  pending: false,
  fieldTypes: [],
  error: undefined,
  data: {}
};

export function fieldTypesReducer(
  state = initialState,
  action: FieldTypesActions
): FieldTypesState {
  switch (action.type) {
    case FieldTypesActionTypes.LoadFieldTypes:
      return { ...state, pending: true };

    case FieldTypesActionTypes.LoadFieldTypesSuccess:
      return {
        ...state,
        pending: false,
        fieldTypes: action.payload,
        data: convertToMap(action.payload, 'id') as any
      };

    case FieldTypesActionTypes.LoadFieldTypesFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    default:
      return state;
  }
}
