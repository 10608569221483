import { Opportunity } from 'src/app/models/Opportunity/opportunity';
import { IdNameItem } from 'src/app/models/id-name-item';
import { CompanySelectItem } from './../../../models/companies/company-select';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  debounceTime,
  distinctUntilChanged,
  EMPTY,
  filter,
  map,
  Observable,
  Subscription,
  tap
} from 'rxjs';
import { CompanyIdNameItem } from 'src/app/models/companies/company-id-name-item';
import { GlobalFilter } from 'src/app/models/global-filter';
import {
  ClearSearchCompanies,
  SearchCompanies,
  SelectSearchedCompany
} from 'src/app/store/companies/companies.actions';
import { RootState } from 'src/app/store/store.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { CallNoteType } from 'src/app/models/call-notes/call-note-type';
import { SaveCallNoteAudio } from 'src/app/store/call-notes/call-notes.actions';
import moment from 'moment';
import { CloseDrawer, OpenDrawer } from 'src/app/store/layout/layout.actions';
import { CompanySlideOutFormComponent } from '../company-slide-out-form/company-slide-out-form.component';
import { Field } from 'src/app/models/call-notes/field';
import { Company } from 'src/app/models/companies/company';
import { selectCurrentCompany } from 'src/app/store/companies/selectors/current-company.selector';
import { LoadCallNoteTypes } from 'src/app/store/call-note-types/call-note-types.actions';
import { LoadOpportunitiesElastic } from 'src/app/store/opportunities/opportunities.actions';
import { selectActiveOpportunityItems } from 'src/app/store/opportunities/selectors/all-opportunities-as-array.selector';

@Component({
  selector: 'tn-call-note-form-audio',
  templateUrl: './call-note-form-audio.component.html',
  styleUrls: ['./call-note-form-audio.component.scss']
})
export class CallNoteFormAudioComponent implements OnInit {
  @Input() data: {
    company: any;
    selectedContact?: IdNameItem;
    redirect?: boolean;
  };
  @Input() autofocus: boolean;
  $companySearchResults: Observable<CompanySelectItem[]>;
  $pending: Observable<boolean> = EMPTY;
  $error: Observable<HttpErrorResponse> = EMPTY;
  $company: Observable<Company>;

  companySearchInputSub: Subscription;
  companySearchResults: Subscription;
  companySearchCtrl = new FormControl();
  companies: any[] = [];
  companyId: number;
  callTypeId: number;
  fieldsTypeIds: number[];
  contactId: number = 0;
  opportunityId: number = 0;

  pageCount: number = 0;

  callNoteAudio: FormData;
  callNoteParams: string;
  recordingTouched: boolean = false;

  callTypes: CallNoteType[];
  $callTypeSub: Subscription;

  $callNoteFieldsSub: Subscription;
  callNoteFields: Field[] = [];
  selectedFields: number[] = [];

  $opportunities: Observable<Opportunity[]>;
  $opportunitiesSub: Subscription;
  opportunities: Opportunity[] = [];
  opportunitySelect: boolean = false;

  @ViewChild('companySearchInput', { static: true })
  companySearchInput: ElementRef<HTMLInputElement>;

  constructor(private store: Store<RootState>) {}

  ngOnInit(): void {
    this.store.dispatch(
      new LoadOpportunitiesElastic({
        filters: [],
        params: { limit: '10000', skip: '0', order: 'createdDate' }
      })
    );
    this.$opportunities = this.store.select(selectActiveOpportunityItems);
    this.$opportunitiesSub = this.$opportunities.subscribe((opportunities) => {
      this.opportunities = opportunities;
    });
    this.store
      .select('auth', 'data', 'api', 'Role', 'Features')
      .subscribe((features: any) => {
        if (features[20].Permissions[138].Value === '2') {
          this.opportunitySelect = true;
        }
      });

    this.store.dispatch(new LoadCallNoteTypes());
    this.$company = this.store.select(selectCurrentCompany);
    if (this.data.company && !this.data.redirect) {
      this.companySearchCtrl.setValue(this.data.company.name);
      this.onCompanySearch(this.data.company.name);
    }
    if (!!this.data.redirect) {
      this.setCompany(this.data.company.id);
      this.companySearchCtrl.setValue(this.data.company.name);
    }
    this.$company.subscribe((company) => {
      if (company) {
        this.companySearchCtrl.setValue(company.name);
        this.onCompanySearch(company.name);
      }
    });
    // this.store.dispatch(new LoadCallNoteCustomFields());
    this.$companySearchResults = this.store.select('companies', 'search', 'data');
    this.companySearchResults = this.$companySearchResults.subscribe((companies) => {
      this.companies = companies;
    });
    this.$pending = this.store.select('companies', 'search', 'pending');
    this.$error = this.store.select('companies', 'search', 'error');

    this.companySearchInputSub = this.companySearchCtrl.valueChanges
      .pipe(
        filter(
          (companySearchVal: string | CompanyIdNameItem) =>
            typeof companySearchVal === 'string'
        ),
        debounceTime(500),
        distinctUntilChanged(),
        tap((companySearchVal: string) => {
          this.onCompanySearch(companySearchVal);
        })
      )
      .subscribe();

    this.$callTypeSub = this.store
      .select('callNoteTypes', 'data')
      .pipe(
        map((callTypes: CallNoteType[]) =>
          Object.values(callTypes).sort((a, b) => {
            const textA = a.name.toLowerCase();
            const textB = b.name.toLowerCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          })
        )
      )
      .subscribe((callTypes: CallNoteType[]) => {
        this.callTypes = callTypes;
      });
    // TODO: uncomment when custom fields are ready
    // this.$callNoteFieldsSub = this.store
    //   .select('callNoteCustomFields', 'callNoteFields')
    //   .subscribe((customFields) => {
    //     this.callNoteFields = customFields;
    //   });
  }

  onCompanySearch(searchVal: string) {
    if (!!searchVal.length) {
      const reqFilter: GlobalFilter = {
        filters: [
          {
            operand1: 'searchField',
            operator: 'CONTAINS_ALL_OF',
            operand2: [searchVal]
          }
        ]
      };
      this.store.dispatch(new SearchCompanies(reqFilter));
    } else {
      this.store.dispatch(new ClearSearchCompanies());
    }
  }

  async setCompany(companyId: number): Promise<void> {
    this.companyId = companyId;
    this.store.dispatch(new SelectSearchedCompany(companyId));

    // Subscribe to opportunities observable
    await new Promise((resolve) => {
      this.$opportunitiesSub = this.$opportunities.subscribe((opportunities) => {
        this.opportunities = opportunities;
        resolve(undefined);
      });
    });

    if (!!this.opportunities.length && this.opportunitySelect) {
      this.pageCount++;
    } else {
      this.pageCount = 2;
    }
  }

  setOpportunity(opportunityId: number) {
    this.opportunityId = opportunityId;
    this.pageCount++;
  }

  setCallType(callTypeId: number) {
    this.callTypeId = callTypeId;
    this.pageCount++;
  }
  //  TODO: uncomment when custom fields are ready
  // addRemoveField(fieldId: number) {
  //   if (this.selectedFields.includes(fieldId)) {
  //     this.selectedFields = this.selectedFields.filter((id) => id !== fieldId);
  //   } else {
  //     this.selectedFields.push(fieldId);
  //   }
  // }

  // checked(fieldId: number): boolean {
  //   return this.selectedFields.includes(fieldId);
  // }

  // setFields() {
  //   this.pageCount++;
  // }

  // skip() {
  //   this.pageCount++;
  // }

  back() {
    if (!this.opportunities.length && this.pageCount === 2) {
      this.pageCount = 0;
    } else {
      this.pageCount--;
    }
  }

  skip() {
    this.pageCount++;
  }

  handleAudioEmit(payload) {
    this.recordingTouched = true;
    this.callNoteAudio = payload.formData;

    const params = {
      audioFileName: payload.filename,
      createdDate: moment().format('YYYY-MM-DD') + 'T' + moment().format('HH:mm:ss.SSS'),
      companyId: this.companyId.toString(),
      contactId: this.contactId.toString(),
      callTypeId: this.callTypeId.toString(),
      latitude: '0',
      longitude: '0'
    };
    if (this.opportunityId != 0) {
      params['opportunityId'] = this.opportunityId.toString();
    }
    this.callNoteParams = this.createQueryString(params);
  }

  createQueryString(params) {
    const pairs = Object.keys(params).map((key) => `${key}=${params[key]}`);
    const queryString = pairs.join('&');

    return queryString;
  }

  clearAudio() {
    this.callNoteAudio = null;
    this.callNoteParams = null;
    this.recordingTouched = false;
  }

  saveCallNote() {
    this.store.dispatch(
      new SaveCallNoteAudio({ payload: this.callNoteAudio, params: this.callNoteParams })
    );
  }

  openCompanyDrawer(): void {
    this.store.dispatch(
      new OpenDrawer({
        component: CompanySlideOutFormComponent,
        data: {
          redirect: true
        }
      })
    );
  }

  closeDrawer() {
    this.store.dispatch(new CloseDrawer());
  }
}
