import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { concatMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { HelpActionTypes, HelpActions } from './help.actions';

@Injectable()
export class HelpEffects {
  // loadHelps$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(HelpActionTypes.LoadHelp),
  //     /** An EMPTY observable only emits completion. Replace with your own observable API request */
  //     concatMap(() => EMPTY)
  //   )
  // );

  constructor(private actions$: Actions<HelpActions>) {}
}
