import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { LabelGroup } from 'src/app/models/label-group';

export enum ProjectLabelsActionTypes {
  SelectProjectLabelId = '[ProjectLabels] Select ProjectLabel Id',
  ClearProjectLabelId = '[ProjectLabels] Clear Project Label Id',
  LoadProjectLabels = '[ProjectLabels] Load ProjectLabels',
  LoadProjectLabelsSuccess = '[ProjectLabels] Load ProjectLabels Success',
  LoadProjectLabelsFailure = '[ProjectLabels] Load ProjectLabels Failure',
  AddProjectLabels = '[ProjectLabels] Add ProjectLabels',
  AddProjectLabelsSuccess = '[ProjectLabels] Add ProjectLabels Success',
  AddProjectLabelsFailure = '[ProjectLabels] Add ProjectLabels Failure',
  UpdateProjectLabels = '[ProjectLabels] Update ProjectLabels',
  UpdateProjectLabelsSuccess = '[ProjectLabels] Update ProjectLabels Success',
  UpdateProjectLabelsFailure = '[ProjectLabels] Update ProjectLabels Failure',
  DeleteProjectLabels = '[ProjectLabels] Delete ProjectLabels',
  DeleteProjectLabelsSuccess = '[ProjectLabels] Delete ProjectLabels Success',
  DeleteProjectLabelsFailure = '[ProjectLabels] Delete ProjectLabels Failure'
}

export class SelectProjectLabelId implements Action {
  readonly type = ProjectLabelsActionTypes.SelectProjectLabelId;
  constructor(public payload: number) {}
}

export class ClearProjectLabelId implements Action {
  readonly type = ProjectLabelsActionTypes.ClearProjectLabelId;
}

export class LoadProjectLabels implements Action {
  readonly type = ProjectLabelsActionTypes.LoadProjectLabels;
}

export class LoadProjectLabelsSuccess implements Action {
  readonly type = ProjectLabelsActionTypes.LoadProjectLabelsSuccess;
  constructor(public payload: LabelGroup[]) {}
}

export class LoadProjectLabelsFailure implements Action {
  readonly type = ProjectLabelsActionTypes.LoadProjectLabelsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class AddProjectLabels implements Action {
  readonly type = ProjectLabelsActionTypes.AddProjectLabels;
  constructor(public payload: LabelGroup) {}
}

export class AddProjectLabelsSuccess implements Action {
  readonly type = ProjectLabelsActionTypes.AddProjectLabelsSuccess;
  constructor(public payload: LabelGroup) {}
}

export class AddProjectLabelsFailure implements Action {
  readonly type = ProjectLabelsActionTypes.AddProjectLabelsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class UpdateProjectLabels implements Action {
  readonly type = ProjectLabelsActionTypes.UpdateProjectLabels;
  constructor(public payload: LabelGroup) {}
}

export class UpdateProjectLabelsSuccess implements Action {
  readonly type = ProjectLabelsActionTypes.UpdateProjectLabelsSuccess;
  constructor(public payload: LabelGroup) {}
}

export class UpdateProjectLabelsFailure implements Action {
  readonly type = ProjectLabelsActionTypes.UpdateProjectLabelsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class DeleteProjectLabels implements Action {
  readonly type = ProjectLabelsActionTypes.DeleteProjectLabels;
  constructor(public payload: number) {}
}

export class DeleteProjectLabelsSuccess implements Action {
  readonly type = ProjectLabelsActionTypes.DeleteProjectLabelsSuccess;
  constructor(public payload: number) {}
}

export class DeleteProjectLabelsFailure implements Action {
  readonly type = ProjectLabelsActionTypes.DeleteProjectLabelsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export type ProjectLabelsActions =
  | SelectProjectLabelId
  | ClearProjectLabelId
  | LoadProjectLabels
  | LoadProjectLabelsSuccess
  | LoadProjectLabelsFailure
  | AddProjectLabels
  | AddProjectLabelsSuccess
  | AddProjectLabelsFailure
  | UpdateProjectLabels
  | UpdateProjectLabelsSuccess
  | UpdateProjectLabelsFailure
  | DeleteProjectLabels
  | DeleteProjectLabelsSuccess
  | DeleteProjectLabelsFailure;
