import { Injectable } from '@angular/core';
import { ServicesModule } from '../../services.module';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { LabelGroup } from 'src/app/models/label-group';

@Injectable({
  providedIn: ServicesModule
})
export class ProjectLabelsService {
  private jpiUrl = environment.jpiUrl;
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get(`${this.jpiUrl}/projectlabelgroups`);
  }

  addProjectLabel(projectLabel: LabelGroup) {
    return this.http.post(`${this.jpiUrl}/projectlabelgroups`, projectLabel);
  }

  updateProjectLabel(projectLabel: LabelGroup) {
    return this.http.put(
      `${this.jpiUrl}/projectlabelgroups/${projectLabel.id}`,
      projectLabel
    );
  }

  deleteProjectLabel(projectLabelId: number) {
    return this.http.delete(`${this.jpiUrl}/projectlabelgroups/${projectLabelId}`);
  }
}
