import { Injectable } from '@angular/core';
import { AuthenticationService } from "./authentication.service";
import { Summary } from '../models/sales/Summary';

@Injectable()
export class SalesJPIService {
	constructor(private authService: AuthenticationService) {
	}

	getSalesSummaries(startDate: Date, endDate: Date, currentMonth: number, currentYear: number, group1: number, group2: number, group3: number): Promise<Summary[]> {
		const params: any = {};

		params['startDate'] = startDate.toLocaleDateString();
		params['endDate'] = endDate.toLocaleDateString();
		params['currentMonth'] = currentMonth;
		params['currentYear'] = currentYear;
		params['group1'] = group1;
		params['group2'] = group2;
		params['group3'] = group3;

		return this.authService.makeJpiRequest('/salessummaries', params, null, 'get').then(function (result) {
			return JSON.parse(result);
		});
	}
}
