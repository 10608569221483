import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ExpandService {
    private expandedAnnouncer = new Subject<boolean>();
    expandedAnnounced = this.expandedAnnouncer.asObservable();

    constructor() {
    }

    expandMenu(expand: boolean) {
        this.expandedAnnouncer.next(expand);
    }
}
