import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { tutorialsReducer } from './tutorials.reducer';
import { EffectsModule } from '@ngrx/effects';
import { TutorialsEffects } from './tutorials.effects';

@NgModule({
  imports: [
    StoreModule.forFeature('tutorials', tutorialsReducer),
    EffectsModule.forFeature([TutorialsEffects])
  ]
})
export class TutorialsStateModule {}
