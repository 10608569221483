<company-popup
  *ngIf="companyPopup$"
  [(companyPopup)]="companyPopup$"
  [(companies)]="companies"
  [(companiesAndContacts)]="companiesAndContacts"
></company-popup>
<contact-popup
  *ngIf="contactPopup$"
  [(contactPopup)]="contactPopup$"
  [(companies)]="companies"
  [(companiesAndContacts)]="companiesAndContacts"
></contact-popup>
<!-- <call-note-popup *ngIf="callNotePopup$" [(callNotePopup)]="callNotePopup$" [callNoteExpanded]="true"></call-note-popup>
<call-note-popup *ngIf="followUpPopup$" [(callNotePopup)]="followUpPopup$" [callNoteExpanded]="false"></call-note-popup> -->

<!-- Loading Spinner -->
<div
  class="loading-container-center"
  *ngIf="isBusy && !showSearchResults"
  fxFlex
  fxLayout="column"
  fxLayoutAlign="center center"
  style="height: 200px; width: 200px"
>
  <mat-spinner></mat-spinner>
  <span style="font-size: 1.2em; padding-top: 10px">
    <strong>Loading...</strong>
  </span>
  <span>This may take a minute.</span>
</div>

<dx-load-panel
  shadingColor="rgba(0,0,0,0.4)"
  [position]="{ my: 'center', at: 'center' }"
  [(visible)]="isLoading"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [closeOnOutsideClick]="false"
>
</dx-load-panel>

<!-- Merging notification Spinner -->
<div
  class="loading-container-center"
  *ngIf="isMerging"
  fxFlex
  fxLayout="column"
  fxLayoutAlign="center center"
  style="height: 200px; width: 200px"
>
  <mat-spinner></mat-spinner>
  <span style="font-size: 1.2em; padding-top: 10px">
    <strong>Merging...</strong>
  </span>
</div>

<div *ngIf="!showSearchResults && !isBusy" fxLayout="column" class="list-container">
  <!-- <h2 class="text-muted">Search for, or create a company using the toolbar above to begin!</h2> -->

  <!-- Company list header -->
  <div id="header-options" fxLayout="row" class="list-header-main">
    <!-- fxFlex -->
    <!-- selected List header and company count -->
    <div *ngIf="selectedList" fxLayoutAlign="start center" fxFlex>
      <h2 fxLayoutAlign="start center">{{ selectedList.name }}</h2>
      <mat-icon
        *ngIf="!expandCustomerMenuPopup"
        (click)="expandCustomerMenu()"
        class="button-dropdown-small button-white"
        >arrow_drop_down</mat-icon
      >
      <mat-icon
        *ngIf="expandCustomerMenuPopup"
        (click)="expandCustomerMenu()"
        class="button-dropdown-small button-black"
        >arrow_drop_up</mat-icon
      >
    </div>

    <!-- Search company bar, actions button, save button -->
    <div *ngIf="selectedList" fxLayoutAlign="end center" fxFlex>
      <span
        *ngIf="
          selectedList.name != 'All Companies' && selectedList.name != 'All Contacts'
        "
        fxLayoutAlign="end center"
        class="button button-blue"
        (click)="saveList(selectedList, false)"
        >Save</span
      >

      <span
        *ngIf="
          selectedList.name == 'All Companies' || selectedList.name == 'All Contacts'
        "
        fxLayoutAlign="end center"
        class="button button-blue"
        (click)="saveAs(selectedList)"
        >Save As...</span
      >

      <mat-icon
        *ngIf="!expandSaveMenuPopup"
        class="button-dropdown-small button-white"
        (click)="expandSaveMenu()"
      >
        arrow_drop_down</mat-icon
      >
      <mat-icon
        *ngIf="expandSaveMenuPopup"
        class="button-dropdown-small button-black"
        (click)="expandSaveMenu()"
      >
        arrow_drop_up</mat-icon
      >
    </div>
  </div>

  <div id="contentContainer"></div>
  <!-- Buttton options for list view-->
  <div *ngIf="!detailedView" class="sub-menu-buttons">
    <div fxLayout="row">
      <div fxLayoutAlign="start center" fxFlex>
        <span
          *ngIf="
            (totalCompaniesSelected >= 2 ||
              totalCompaniesSelected == 2 ||
              totalCompaniesSelected == 3) &&
            bulkEditFeature &&
            canBulkEdit
          "
          class="button button-white"
          (click)="editList()"
          >Bulk Edit</span
        >
        <span
          *ngIf="
            (totalCompaniesSelected == 0 || onlyContactColumnsVisible) &&
            bulkDeleteFeature
          "
          class="button button-white button-disabled"
          >Delete</span
        >
        <span
          *ngIf="
            totalCompaniesSelected >= 1 &&
            !onlyContactColumnsVisible &&
            bulkDeleteFeature &&
            canBulkDelete
          "
          class="button button-white"
          (click)="deleteList()"
          >Delete</span
        >
        <span
          *ngIf="
            (totalCompaniesSelected == 0 || totalCompaniesSelected == 1) && mergeFeature
          "
          class="button button-white button-disabled"
          >Merge</span
        >
        <span
          *ngIf="
            totalCompaniesSelected == 2 && canMerge && companiesDisplayed && mergeFeature
          "
          class="button button-white"
          (click)="mergeList()"
          >Merge</span
        >
        <span
          *ngIf="totalCompaniesSelected >= 2 && !canMerge && mergeFeature"
          class="button button-white button-disabled"
          >Merge</span
        >
        <span
          *ngIf="
            totalCompaniesSelected == 2 && canMerge && contactsDisplayed && mergeFeature
          "
          class="button button-white"
          (click)="mergeList()"
          >Merge</span
        >
        <span>
          <small *ngIf="countRelation == 'GREATER_THAN_OR_EQUAL_TO'">More than </small>
          <small
            [innerText]="count + ' companies matching filter.'"
            style="cursor: default"
          ></small>
        </span>
      </div>
      <div fxLayout="row" fxLayoutAlign="end end" fxFlex>
        <span
          *ngIf="!detailedView"
          class="button button-white"
          (click)="openEditColumnChooser()"
          >Columns</span
        >
        <span
          *ngIf="!detailedView"
          class="button button-white"
          (click)="detailedViewClicked()"
          >Detail View</span
        >
        <dx-select-box
          [dataSource]="sortingColumns"
          displayExpr="value"
          [(value)]="selectedSortOption"
          (onValueChanged)="sortChanged()"
          [width]="300"
        ></dx-select-box>
      </div>
    </div>
    <div fxLayout="row">
      <div fxFlex fxLayout="row">
        <div
          *ngFor="let filter of selectedList.filters"
          fxLayout="row"
          class="filterItem"
        >
          <div
            fxLayout="row"
            fxFlex
            (click)="selectFilterField(filter, true)"
            fxLayoutAlign="center center"
          >
            <strong [innerText]="filter.value"></strong>
            <span
              *ngIf="filter.intermediateText"
              [innerText]="'&nbsp;' + filter.intermediateText"
            ></span
            >&nbsp;
            <div *ngIf="filter.filterValue && filter.filterValue.length > 1">
              <span *ngFor="let value of filter.filterValue; let i = index">
                <strong *ngIf="filter.type == 'date'">{{
                  value | date : 'shortDate'
                }}</strong>
                <strong *ngIf="filter.type != 'date'" [innerText]="value"></strong>
                <strong *ngIf="i != filter.filterValue.length - 1">,&nbsp;</strong>
              </span>
            </div>
            <div *ngIf="filter.filterValue && filter.filterValue.length == 1">
              <strong *ngIf="filter.type == 'date'">{{
                filter.filterValue[0] | date : 'shortDate'
              }}</strong>
              <strong
                *ngIf="filter.type != 'date'"
                [innerText]="filter.filterValue[0]"
              ></strong>
            </div>
          </div>
          <div fxLayoutAlign="center center">
            <mat-icon (click)="removeFilter(filter)" class="mat-icon-special"
              >close</mat-icon
            >
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center end">
          <span class="button button-blue" (click)="openFilterMenu()">
            + add filter
          </span>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="center end">
        <div fxLayout="row" fxLayoutAlign="end end">
          <span>Export</span>
          <mat-icon
            *ngIf="!expandExportMenuPopup"
            class="button-dropdown-small button-white"
            (click)="expandExportMenu()"
            >arrow_drop_down</mat-icon
          >
          <mat-icon
            *ngIf="expandExportMenuPopup"
            class="button-dropdown-small button-black"
            (click)="expandExportMenu()"
            >arrow_drop_up</mat-icon
          >
        </div>
        <div id="exportContainer"></div>
      </div>
    </div>
  </div>

  <!-- Buttton options for detail view -->
  <div *ngIf="detailedView" class="sub-menu-buttons">
    <div fxLayout="row">
      <div fxLayoutAlign="start center" fxFlex>
        <span *ngIf="numberOfRowsSelected == 0"
          >{{ TotalRowCount | number }} Companies</span
        >
      </div>
      <div fxLayoutAlign="end center">
        <!-- <span *ngIf="!detailedView" class="button button-white" (click)="openEditColumnChooser()" >Edit Columns</span> -->
        <span
          *ngIf="detailedView"
          class="button button-white"
          (click)="detailedViewClicked()"
          >List View</span
        >
        <dx-select-box
          fxFlex
          [dataSource]="sortingColumns"
          displayExpr="value"
          [(value)]="selectedSortOption"
          (onValueChanged)="sortChanged()"
          [width]="300"
        ></dx-select-box>
      </div>
    </div>
  </div>

  <!-- grid list -->
  <dx-data-grid
    [dataSource]="dataDisplayed"
    [columns]="filteredColumnOptions"
    [height]="'63vh'"
    [allowColumnReordering]="false"
    [allowColumnResizing]="false"
    [columnAutoWidth]="false"
    [showColumnLines]="false"
    [showRowLines]="false"
    [showBorders]="true"
    [rowAlternationEnabled]="true"
    [filterValue]="filterValue"
    (onSelectionChanged)="checkSelection($event)"
    (onRowClick)="showCompanyProfile($event)"
    [visible]="!detailedView"
    [height]="window.innerHeight - 200"
  >
    <!-- [remoteOperations]="true" -->
    <!-- <dxo-paging [pageSize]="pageSize"></dxo-paging> -->
    <!-- (onContentReady)="checkVisibleColumns()" -->
    <dxo-scrolling mode="infinite"></dxo-scrolling>
    <dxo-remote-operations [sorting]="true" [paging]="true"> </dxo-remote-operations>
    <dxo-selection
      mode="multiple"
      [allowSelectAll]="true"
      [deferred]="true"
    ></dxo-selection>
    <div *dxTemplate="let territories of 'territoriesTemplate'">
      <span *ngFor="let item of territories.value">
        {{ item.name }}<span *ngIf="territories.value.length > 1">, </span></span
      >
    </div>
    <div *dxTemplate="let child of 'childCompaniesTemplate'">
      <span *ngFor="let item of child.value"
        >{{ item.name }}<span *ngIf="child.value.length > 1">, </span></span
      >
    </div>
    <div *dxTemplate="let contact of 'contactTemplate'">
      <span *ngFor="let item of contact.value"
        >{{ item.lastName }}, {{ item.firstName
        }}<span *ngIf="contact.value.length > 1">; </span></span
      >
    </div>
    <div *dxTemplate="let data of 'salesRepTemplate'">
      <span *ngFor="let rep of getReps(data.data); let last = last">
        <span *ngIf="!last">{{ rep.firstName }} {{ rep.lastName }}, </span>
        <span *ngIf="last">{{ rep.firstName }} {{ rep.lastName }}</span>
      </span>
    </div>
    <div *dxTemplate="let label of 'labelTemplate'">
      <span>{{ displayCompanyLabels(label) }}</span>
    </div>
  </dx-data-grid>
  <!-- <dxo-column-chooser enabled="true" title="Company Details" mode="select" [width]="600" [height]="600"></dxo-column-chooser> -->

  <!-- detailed view -->
  <div *ngIf="detailedView" fxLayout="row">
    <div fxFlex="25">
      <dx-list
        [dataSource]="dataDisplayed"
        [scrollingEnabled]="true"
        showScrollbar="always"
        [height]="'75vh'"
        selectionMode="single"
        [(selectedItems)]="selectedItems"
      >
        <div *dxTemplate="let company of 'item'" (click)="detailListClicked(company)">
          <div fxLayout="column">
            <span>{{ company.name }}</span>
          </div>
          <div fxLayout="row" class="text-small text-muted">
            <span
              *ngIf="
                company.Address &&
                (company.City || company.State || (company.City && company.State))
              "
              >{{ company.billingAddress.addressLine1 }},&nbsp;</span
            >
            <span
              *ngIf="
                company.Address &&
                !(company.City || company.State || (company.City && company.State))
              "
              >{{ company.billingAddress.addressLine1 }}</span
            >
            <span
              *ngIf="
                company.Address2 &&
                (company.City || company.State || (company.City && company.State))
              "
              >{{ company.billingAddress.addressLine2 }},&nbsp;</span
            >
            <span
              *ngIf="
                company.Address2 &&
                !(company.City || company.State || (company.City && company.State))
              "
              >{{ company.billingAddress.addressLine2 }}</span
            >
            <span *ngIf="company.City && company.State"
              >{{ company.billingAddress.city }},&nbsp;</span
            >
            <span *ngIf="company.City && !company.State">{{
              company.billingAddress.city
            }}</span>
            <span *ngIf="company.State">{{ company.billingAddress.state }}</span>
          </div>
        </div>
      </dx-list>
    </div>
    <div fxFlex="75" style="height: 75vh; overflow-y: auto">
      <company-profile
        *ngIf="listCompanyId"
        [companyId]="listCompanyId"
        [isList]="true"
        style="max-height: 10vh; overflow: hidden"
      ></company-profile>
    </div>
  </div>
</div>
<div></div>
<tn-searchResults
  *ngIf="showSearchResults"
  [searchResults]="searchResults"
  style="z-index: 99999"
></tn-searchResults>

<!-- <dx-popup *ngIf="showProfile" [width]="'80vw'" [height]="'85vh'" [(visible)]="showProfile" [showTitle]="false"
	[closeOnOutsideClick]="true">
	<div *dxTemplate="let data of 'content'">
		<dx-scroll-view [height]="'inherit'">
			<company-profile *ngIf="showProfile" [companyId]="profileId" [isList]="true" [isPopup]="true"></company-profile>
		</dx-scroll-view>
	</div>
</dx-popup> -->

<company-profile-popup
  *ngIf="showProfile"
  [(companyProfilePopup)]="showProfile"
  [companyId]="profileId"
>
</company-profile-popup>

<!-- Customer Menu Pop-up -->
<dx-popup
  [width]="'80vw'"
  [height]="600"
  [showTitle]="false"
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [(visible)]="expandCustomerMenuPopup"
  shadingColor="rgba(0,0,0,0.5)"
  [position]="{ my: 'top left', at: 'left', of: '#contentContainer', offset: '-20 -20' }"
>
  <div *dxTemplate="let data of 'content'">
    <div fxLayout="row" fxFlex>
      <div
        *ngIf="
          selectedList.name != 'All Companies' && selectedList.name != 'All Contacts'
        "
        fxLayout="column"
        fxFlex="33"
        class="menu-options-section menu-containers"
      >
        <span class="menu-h2">Options</span>
        <span class="text-muted">Current List:</span>
        <span *ngIf="!selectedList" class="menu-h3">All Companies</span>
        <span *ngIf="selectedList" class="menu-h3">{{ selectedList.name }}</span>
        <span
          *ngIf="canManipulateList(selectedList)"
          class="menu-list-items"
          (click)="renameList(selectedList)"
          >Rename</span
        >
        <span
          *ngIf="canManipulateList(selectedList)"
          class="menu-list-items"
          (click)="openDeleteListPopup(selectedList)"
          >Delete</span
        >
        <span class="menu-list-items" (click)="duplicateList(selectedList)"
          >Duplicate</span
        >
        <!-- <span *ngIf="!selectedList.default" class="menu-list-items" (click)="setListAsDefault(selectedList)">Set
					as default</span> -->
        <span
          *ngIf="!selectedList.teamList && canManipulateList(selectedList)"
          class="menu-list-items"
          (click)="addListToTeam(selectedList)"
          >Add to team</span
        >
        <span
          *ngIf="selectedList.teamList && canManipulateList(selectedList)"
          class="menu-list-items"
          (click)="removeListFromTeam(selectedList)"
          >Move to My Lists</span
        >
      </div>

      <div
        *ngIf="
          selectedList.name == 'All Companies' || selectedList.name == 'All Contacts'
        "
        fxLayout="column"
        fxFlex="33"
        class="menu-options-section menu-containers"
      >
        <span class="menu-h2">Options</span>
        <span class="text-muted">Current List:</span>
        <span *ngIf="selectedList" class="menu-h3">{{ selectedList.name }}</span>
        <span class="menu-list-items" (click)="duplicateList(selectedList)"
          >Duplicate</span
        >
        <!-- <span
          *ngIf="!selectedList.default"
          class="menu-list-items"
          (click)="setListAsDefault(selectedList)"
          >Set as default</span
        > -->
      </div>

      <div fxLayout="column" fxFlex="33" class="menu-options-section menu-containers">
        <span class="menu-h2 menu-containers">Saved Lists</span>
        <div *ngFor="let list of teamLists" fxLayout="column">
          <div
            class="company-list-options"
            [ngClass]="{ 'list-options-opened': list.expanded }"
            fxFlex
          >
            <div *ngIf="list.default" fxLayoutAlign="start center">
              <mat-icon>star_rate</mat-icon>
            </div>

            <div
              *ngIf="!list.default"
              style="width: 24px"
              fxLayoutAlign="start center"
            ></div>

            <div
              style="font-weight: 500"
              (click)="selectList(list, true)"
              fxLayoutAlign="start center"
              fxFlex
            >
              {{ list.name }}
            </div>

            <div
              class="options-hidden"
              [ngClass]="{ 'options-appear': list.expanded }"
              (click)="openOptions(list)"
              fxLayoutAlign="end center"
              fxLayout="row"
            >
              <div class="link" fxLayoutAlign="end center" fxFlex>Options</div>
              <mat-icon *ngIf="!list.expanded">keyboard_arrow_down</mat-icon>
              <mat-icon *ngIf="list.expanded">keyboard_arrow_up</mat-icon>
            </div>
          </div>

          <div
            *ngIf="
              list.expanded &&
              !(list.name == 'All Companies' || list.name == 'All Contacts')
            "
            fxLayout="column"
          >
            <span
              *ngIf="canManipulateList(list)"
              class="menu-list-items-teams"
              (click)="renameList(list)"
              >Rename</span
            >
            <span
              *ngIf="canManipulateList(list)"
              class="menu-list-items-teams"
              (click)="openDeleteListPopup(list)"
              >Delete</span
            >
            <span class="menu-list-items-teams" (click)="duplicateList(list)"
              >Duplicate</span
            >
            <!-- <span *ngIf="!list.default" class="menu-list-items-teams" (click)="setListAsDefault(list)">Set
							as default</span> -->
            <span
              *ngIf="canManipulateList(list)"
              class="menu-list-items-teams"
              (click)="removeListFromTeam(list)"
              >Move to My Lists</span
            >
          </div>

          <div
            *ngIf="
              list.expanded &&
              (list.name == 'All Companies' || list.name == 'All Contacts')
            "
            fxLayout="column"
          >
            <span class="menu-list-items-teams" (click)="duplicateList(list)"
              >Duplicate</span
            >
            <!-- <span *ngIf="!list.default" class="menu-list-items-teams" (click)="setListAsDefault(list)">Set
							as default</span> -->
          </div>
        </div>
      </div>

      <div fxLayout="column" fxFlex="33" class="menu-containers">
        <span class="menu-h2 menu-containers">My Lists</span>
        <dx-scroll-view>
          <div *ngFor="let list of myLists" fxLayout="column">
            <div
              class="company-list-options"
              [ngClass]="{ 'list-options-opened': list.expanded }"
              fxLayout="row"
              fxFlex
            >
              <div *ngIf="list.default" fxLayoutAlign="start center">
                <mat-icon>star_rate</mat-icon>
              </div>
              <div
                *ngIf="!list.default"
                style="width: 24px"
                fxLayoutAlign="start center"
              ></div>
              <div
                style="font-weight: 500"
                (click)="selectList(list, true)"
                fxLayoutAlign="start center"
                fxFlex
              >
                {{ list.name }}
              </div>
              <div
                class="options-hidden"
                [ngClass]="{ 'options-appear': list.expanded }"
                (click)="openOptions(list)"
                fxLayoutAlign="end center"
                fxLayout="row"
              >
                <div class="link" fxLayoutAlign="end center" fxFlex>Options</div>
                <mat-icon *ngIf="!list.expanded">keyboard_arrow_down</mat-icon>
                <mat-icon *ngIf="list.expanded">keyboard_arrow_up</mat-icon>
              </div>
            </div>
            <div
              *ngIf="
                list.expanded &&
                list.name != 'All Companies' &&
                list.name != 'All Contacts'
              "
              fxLayout="column"
            >
              <span class="menu-list-items-teams" (click)="renameList(list)">Rename</span>
              <span class="menu-list-items-teams" (click)="openDeleteListPopup(list)"
                >Delete</span
              >
              <span class="menu-list-items-teams" (click)="duplicateList(list)"
                >Duplicate</span
              >
              <!-- <span
                *ngIf="!list.default"
                class="menu-list-items-teams"
                (click)="setListAsDefault(list)"
                >Set as default</span
              > -->
              <span class="menu-list-items-teams" (click)="addListToTeam(list)"
                >Add to team</span
              >
            </div>
            <div
              *ngIf="
                list.expanded &&
                (list.name == 'All Companies' || list.name == 'All Contacts')
              "
              fxLayout="column"
            >
              <span class="menu-list-items-teams" (click)="duplicateList(list)"
                >Duplicate</span
              >
              <!-- <span
                *ngIf="!list.default"
                class="menu-list-items-teams"
                (click)="setListAsDefault(list)"
                >Set as default</span
              > -->
              <span class="menu-list-items-teams" (click)="duplicateList(list)"
                >Duplicate</span
              >
              <!-- <span
                *ngIf="!list.default"
                class="menu-list-items-teams"
                (click)="setListAsDefault(list)"
                >Set as default</span
              > -->
            </div>
          </div>
        </dx-scroll-view>
      </div>
    </div>
  </div>
</dx-popup>

<!-- Save button options Pop-up -->
<dx-popup
  [width]="'15vw'"
  [height]="80"
  [showTitle]="false"
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [(visible)]="expandSaveMenuPopup"
  shadingColor="rgba(0,0,0,0.5)"
  [position]="{ my: 'right', at: 'top right', of: '#contentContainer', offset: '0 20' }"
>
  <div *dxTemplate="let data of 'content'">
    <div fxLayout="column" fxFlex>
      <span style="cursor: pointer" (click)="saveAs(selectedList)">Save as</span>
      <span style="cursor: pointer" (click)="discardChanges(selectedList)"
        >Discard changes</span
      >
    </div>
  </div>
</dx-popup>

<!-- Export button options Pop-up -->
<dx-popup
  [width]="'15vw'"
  [height]="80"
  [showTitle]="false"
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [(visible)]="expandExportMenuPopup"
  shadingColor="rgba(0,0,0,0.5)"
  [position]="{ my: 'right', at: 'top right', of: '#exportContainer', offset: '0 45' }"
>
  <div *dxTemplate="let data of 'content'">
    <div fxLayout="column" fxFlex>
      <span style="cursor: pointer" (click)="exportData()">Export All Rows</span>
    </div>
  </div>
</dx-popup>

<!-- Edit button options Pop-up -->
<dx-popup
  *ngIf="bulkEditPopup"
  [position]="{ my: 'right', at: 'right', of: window }"
  [width]="600"
  [height]="'100vh'"
  [showTitle]="false"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [(visible)]="bulkEditPopup"
  shadingColor="rgba(0,0,0,0.5)"
>
  <div *dxTemplate="let data of 'content'">
    <div fxLayout="column" style="height: calc(100% - 50px)">
      <dx-scroll-view [height]="'inherit'">
        <div class="list-header" fxLayout="row">
          <h2 fxLayoutAlign="start center" fxFlex>Edit Companies</h2>
          <span *ngIf="numberOfRowsSelected > 1" fxLayoutAlign="end center"
            >{{ numberOfRowsSelected }} companies selected</span
          >
          <span *ngIf="numberOfRowsSelected == 1" fxLayoutAlign="end center"
            >{{ numberOfRowsSelected }} company selected</span
          >
        </div>
        <div *ngIf="BulkEditPage == 1">
          <div fxLayout="row" class="client-input input-padding">
            <dx-radio-group
              [items]="editOptions"
              [(value)]="editOption"
              displayExpr="text"
              valueExpr="value"
              [disabled]="false"
            >
            </dx-radio-group>
          </div>
          <div *ngFor="let section of customSections">
            <div fxLayout="row" class="client-input input-padding">
              <dx-check-box [(value)]="section.ShouldEdit"></dx-check-box>
              <span class="check-box-text-padding">Edit {{ section.Name }} fields</span>
            </div>
          </div>
        </div>
        <div *ngIf="BulkEditPage == 2">
          <dx-radio-group
            [items]="editTerritoryOptions"
            [(value)]="editTerritoryOption"
            displayExpr="text"
            valueExpr="value"
            [disabled]="false"
          >
          </dx-radio-group>
        </div>
        <div *ngIf="BulkEditPage == 5" fxLayout="column">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div>Add territories</div>
            <dx-tag-box
              width="300"
              [dataSource]="v2Territories"
              displayExpr="name"
              [hideSelectedItems]="true"
              applyValueMode="instantly"
              [hideSelectedItems]="true"
              [(value)]="bulkEditSelectedTerritories"
            >
            </dx-tag-box>
          </div>
          <current-territory-assignments
            [currentTerritoryAssignments]="currentTerritoryAssignments"
            [selectedRows]="selectedRows"
          ></current-territory-assignments>
        </div>
        <div *ngIf="BulkEditPage == 6" fxLayout="column">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div>Remove territories</div>
            <dx-tag-box
              width="300"
              [dataSource]="selectedCompanyTerritories"
              displayExpr="name"
              [hideSelectedItems]="true"
              [(value)]="bulkEditSelectedTerritories"
            ></dx-tag-box>
          </div>
          <current-territory-assignments
            [currentTerritoryAssignments]="currentTerritoryAssignments"
            [selectedRows]="selectedRows"
          ></current-territory-assignments>
        </div>
        <div *ngIf="BulkEditPage == 7">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div>Move Territories</div>
            <div fxLayout="column">
              <div>From</div>
              <dx-tag-box
                [dataSource]="selectedCompanyTerritories"
                [(value)]="moveFromTerritories"
                width="200"
                displayExpr="name"
              >
              </dx-tag-box>
            </div>
            <div>
              <div>To</div>
              <dx-tag-box
                [dataSource]="v2Territories"
                [(value)]="moveToTerritories"
                displayExpr="name"
                width="200"
              >
              </dx-tag-box>
            </div>
          </div>
          <current-territory-assignments
            [currentTerritoryAssignments]="currentTerritoryAssignments"
            [selectedRows]="selectedRows"
          ></current-territory-assignments>
        </div>
        <div *ngIf="BulkEditPage == 8">
          <dx-radio-group
            [items]="editLabelOptions"
            [(value)]="editLabelOption"
            displayExpr="text"
            valueExpr="value"
            [disabled]="false"
          >
          </dx-radio-group>
        </div>
        <div *ngIf="BulkEditPage == 9" fxLayout="column">
          <div fxLayout="column">
            <h3 fxFlex="4">Add labels</h3>
            <div fxLayout="row wrap" fxFlex>
              <ng-template ngFor let-group [ngForOf]="labelGroups">
                <div class="client-input input-padding" fxFlex="50" fxLayout="row">
                  <div fxFlex></div>
                  <div
                    *ngIf="group.labels"
                    class="client-input"
                    fxLayout="column"
                    fxFlex="84"
                  >
                    <span>{{ group.name }}</span>
                    <dx-tag-box
                      *ngIf="
                        (group.labels.length == 0 || group.labels.length > 1) &&
                        group.allowMultiple == true
                      "
                      [dataSource]="group.labels"
                      displayExpr="name"
                      [(value)]="group.addedLabels"
                      [showSelectionControls]="true"
                      applyValueMode="instantly"
                      [hideSelectedItems]="true"
                      [multiline]="true"
                      searchEnabled="true"
                    >
                    </dx-tag-box>
                    <dx-select-box
                      *ngIf="group.labels.length == 1 || group.allowMultiple == false"
                      [dataSource]="group.labels"
                      displayExpr="name"
                      [(value)]="group.addedLabels"
                      searchEnabled="true"
                    >
                    </dx-select-box>
                  </div>
                  <div fxFlex></div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
        <div *ngIf="BulkEditPage == 10" fxLayout="column">
          <div fxLayout="column">
            <h3 fxFlex="4">Remove labels</h3>
            <div>
              Please select the labels that you would like removed from the selected
              companies
            </div>
            <div fxLayout="row wrap" fxFlex>
              <ng-template ngFor let-group [ngForOf]="removeLabelGroups">
                <div class="client-input input-padding" fxFlex="50" fxLayout="row">
                  <div fxFlex></div>
                  <div
                    *ngIf="group.labels"
                    class="client-input"
                    fxLayout="column"
                    fxFlex="84"
                  >
                    <span>{{ group.name }}</span>
                    <dx-tag-box
                      [dataSource]="group.labels"
                      displayExpr="name"
                      [(value)]="group.removedLabels"
                      [showSelectionControls]="true"
                      applyValueMode="instantly"
                      [hideSelectedItems]="true"
                      [multiline]="true"
                      searchEnabled="true"
                    ></dx-tag-box>
                  </div>
                  <div fxFlex></div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
        <div *ngIf="BulkEditPage == 11">
          <div fxLayout="column">
            <div>Which label group do want to move labels?</div>
            <dx-radio-group
              [items]="labelGroups"
              [(value)]="moveLabelOption"
              displayExpr="name"
              [disabled]="false"
            >
            </dx-radio-group>
          </div>
        </div>
        <div *ngIf="BulkEditPage == 12">
          <div fxLayout="column">
            <h3 fxFlex="4">Move</h3>
            <div>From</div>
            <div fxLayout="row wrap">
              <div class="client-input input-padding" fxFlex="50" fxLayout="row">
                <div fxFlex></div>
                <div
                  *ngIf="moveLabelOptionRemove.labels"
                  class="client-input"
                  fxLayout="column"
                  fxFlex="84"
                >
                  <span>{{ moveLabelOptionRemove.name }}</span>
                  <dx-tag-box
                    [dataSource]="moveLabelOptionRemove.labels"
                    displayExpr="name"
                    [(value)]="moveLabelOptionRemove.removedLabels"
                    [showSelectionControls]="true"
                    applyValueMode="instantly"
                    [hideSelectedItems]="true"
                    [multiline]="true"
                    searchEnabled="true"
                  >
                  </dx-tag-box>
                </div>
                <div fxFlex></div>
              </div>
            </div>
            <div>To</div>
            <div fxLayout="row wrap">
              <div class="client-input input-padding" fxFlex="50" fxLayout="row">
                <div fxFlex></div>
                <div
                  *ngIf="moveLabelOptionAdd.labels"
                  class="client-input"
                  fxLayout="column"
                  fxFlex="84"
                >
                  <span>{{ moveLabelOptionAdd.name }}</span>
                  <dx-tag-box
                    *ngIf="
                      (moveLabelOptionAdd.labels.length == 0 ||
                        moveLabelOptionAdd.labels.length > 1) &&
                      moveLabelOptionAdd.allowMultiple == true
                    "
                    [dataSource]="moveLabelOptionAdd.labels"
                    displayExpr="name"
                    [(value)]="moveLabelOptionAdd.addedLabels"
                    [showSelectionControls]="true"
                    applyValueMode="instantly"
                    [hideSelectedItems]="true"
                    [multiline]="true"
                    searchEnabled="true"
                  >
                  </dx-tag-box>
                  <dx-select-box
                    *ngIf="
                      moveLabelOptionAdd.labels.length == 1 ||
                      moveLabelOptionAdd.allowMultiple == false
                    "
                    [dataSource]="moveLabelOptionAdd.labels"
                    displayExpr="name"
                    [(value)]="moveLabelOptionAdd.addedLabels"
                    searchEnabled="true"
                  ></dx-select-box>
                </div>
                <div fxFlex></div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="BulkEditPage == 3">
          <current-territory-assignments
            [currentTerritoryAssignments]="currentTerritoryAssignments"
            [selectedRows]="selectedRows"
            [selectPrimaryUser]="true"
            [(newPrimaryUser)]="newPrimaryUser"
            [(primaryUserTerritory)]="primaryUserTerritory"
          ></current-territory-assignments>
          <div>
            <dx-check-box
              style="padding-top: 20px; padding-left: 15px"
              [(value)]="addTerritoriesToCompanies"
              text="Assign all companies to the Primary User's territory"
            ></dx-check-box>
          </div>
        </div>
        <div *ngIf="BulkEditPage == 4">
          <div fxLayout="row">
            <div fxFlex="4"></div>
            <div class="client-input" fxFlex>
              <span>Company Name</span>
              <dx-text-box [(value)]="editFieldsCompany.name"></dx-text-box>
            </div>
            <div fxFlex="4"></div>
          </div>
          <company-popup-addresses
            [(company)]="editFieldsCompany"
          ></company-popup-addresses>
          <company-popup-company-details
            [(company)]="editFieldsCompany"
            [(companyDetails)]="companyDetails"
            [(companyDetailsCheck)]="companyDetailsCheck"
            [(companyCustomFields)]="companyCustomFields"
            [(companies)]="companies"
          >
          </company-popup-company-details>
        </div>
      </dx-scroll-view>
    </div>
    <div fxLayout="row" fxLayoutAlign="end end">
      <span class="button button-white" (click)="clearBulkEdit()">Cancel</span>
      <div fxFlex fxLayoutAlign="end end">
        <span
          *ngIf="BulkEditPage > 1 && BulkEditPage != 5 && BulkEditPage != 6"
          class="button button-white"
          (click)="BulkEditPage = 1"
          >Back</span
        >
        <span
          *ngIf="BulkEditPage == 5 || BulkEditPage == 6"
          class="button button-white"
          (click)="editTerritoryBack()"
          >Back</span
        >
        <span
          *ngIf="
            editOption == null ||
            (BulkEditPage == 2 && editTerritoryOption == null) ||
            (BulkEditPage == 8 && !editLabelOption) ||
            (BulkEditPage == 11 && !moveLabelOption)
          "
          class="button button-blue button-disabled"
          >Next</span
        >
        <span
          *ngIf="editOption != null && BulkEditPage == 1"
          class="button button-blue"
          (click)="bulkEditRouter(editOption)"
          >Next</span
        >
        <span
          *ngIf="BulkEditPage == 2 && editTerritoryOption != null"
          class="button button-blue"
          (click)="bulkEditTerritoryRouter(editTerritoryOption)"
          >Next</span
        >
        <span
          *ngIf="BulkEditPage == 8 && editLabelOption != null"
          class="button button-blue"
          (click)="bulkEditLabelsRouter(editLabelOption)"
          >Next</span
        >
        <span
          *ngIf="BulkEditPage == 11 && moveLabelOption != null"
          class="button button-blue"
          (click)="labelsOptionRouter()"
          >Next</span
        >
        <!-- <span *ngIf="editOption != null && BulkEditPage==3 && newPrimaryUser!=null" class="button button-blue"
							(click)="bulkEditRouter(editOption)">Next</span> -->
        <span
          *ngIf="
            (BulkEditPage == 3 && newPrimaryUser == undefined) ||
            ((BulkEditPage == 5 || BulkEditPage == 6) &&
              !bulkEditSelectedTerritories.length) ||
            (BulkEditPage == 7 &&
              (!moveFromTerritories.length || !moveToTerritories.length))
          "
          class="button button-blue button-disabled"
          >Save</span
        >
        <span
          *ngIf="
            (BulkEditPage == 3 && newPrimaryUser !== undefined) ||
            BulkEditPage == 4 ||
            ((BulkEditPage == 5 || BulkEditPage == 6) &&
              bulkEditSelectedTerritories.length) ||
            (BulkEditPage == 7 &&
              moveFromTerritories.length &&
              moveToTerritories.length) ||
            BulkEditPage == 9 ||
            BulkEditPage == 10 ||
            BulkEditPage == 12 ||
            (BulkEditPage == 11 && moveFromLabels.length && moveToLabels.length)
          "
          class="button button-blue"
          (click)="saveEdit()"
          >Save</span
        >
      </div>
    </div>
  </div>
</dx-popup>

<dx-popup
  *ngIf="bulkEditPrimarySalesPopup"
  [position]="{ my: 'right', at: 'right', of: window }"
  [width]="600"
  [height]="'100vh'"
  [showTitle]="false"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [(visible)]="bulkEditPrimarySalesPopup"
  shadingColor="rgba(0,0,0,0.5)"
>
  <div *dxTemplate="let data of 'content'">
    <div fxLayout="column" style="height: calc(100% - 50px)">
      <dx-scroll-view [height]="'inherit'">
        <div class="list-header" fxLayout="row">
          <h2 fxLayoutAlign="start center" fxFlex>Edit Companies</h2>
          <span *ngIf="numberOfRowsSelected > 1" fxLayoutAlign="end center"
            >{{ numberOfRowsSelected }} companies selected</span
          >
          <span *ngIf="numberOfRowsSelected == 1" fxLayoutAlign="end center"
            >{{ numberOfRowsSelected }} company selected</span
          >
        </div>
        <div class="client-input input-padding list-header">
          <span class="text-small">
            <strong>Step {{ stepNumber }} of {{ numberOfSteps }}</strong> Edit primary
            sales rep</span
          >
        </div>
        <div class="small-text">Select primary sales rep for the selected companies</div>
        <div *ngFor="let region of regionAgents">
          <div fxLayout="row" class="popup-expansion-row" fxFlex>
            <span class="text-bold" fxFlex fxLayoutAlign="start center">{{
              region.regionName
            }}</span>
            <span class="text-small text-muted" fxFlex fxLayoutAlign="start center"
              >Assigned to 3/{{ numberOfRowsSelected | number }} selected companies</span
            >
            <mat-icon fxLayoutAlign="end center" class="icon-large"
              >arrow_drop_down</mat-icon
            >
            <mat-icon *ngIf="labelsExpanded" class="icon-large">arrow_drop_up</mat-icon>
          </div>
          <div *ngFor="let agent of region.Reps">
            <div fxLayout="row" class="agent-padding">
              <dx-check-box
                [(value)]="agent.primary"
                (onValueChanged)="setPrimary($event, agent)"
              >
              </dx-check-box>
              <span fxFlex>{{ agent.firstname }} {{ agent.lastname }}</span>
              <span class="text-muted" fxFlex>{{ agent.role }}</span>
            </div>
          </div>
        </div>
        <div fxLayout="row">
          <dx-check-box></dx-check-box>
          <span class="text-small"
            >Assign selected companies to the primary sales rep's territory if not already
            assigned</span
          >
        </div>
      </dx-scroll-view>
    </div>
    <div fxLayout="row" fxLayoutAlign="end end">
      <span class="button button-white" (click)="clearBulkEdit()">Cancel</span>
      <div fxFlex fxLayoutAlign="end end">
        <span class="button button-white" (click)="stepBackwards()">Back</span>
        <span class="button button-blue" disabled="true" (click)="checkNextPopup()"
          >Next</span
        >
      </div>
    </div>
  </div>
</dx-popup>

<dx-popup
  *ngIf="bulkEditCompanyDetailsPopup"
  [position]="{ my: 'right', at: 'right', of: window }"
  [width]="600"
  [height]="'100vh'"
  [showTitle]="false"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [(visible)]="bulkEditCompanyDetailsPopup"
  shadingColor="rgba(0,0,0,0.5)"
>
  <div *dxTemplate="let data of 'content'">
    <div fxLayout="column" style="height: calc(100% - 50px)">
      <dx-scroll-view [height]="'inherit'">
        <div class="list-header" fxLayout="row">
          <h2 fxLayoutAlign="start center" fxFlex>Edit Companies</h2>
          <span *ngIf="numberOfRowsSelected > 1" fxLayoutAlign="end center"
            >{{ numberOfRowsSelected }} companies selected</span
          >
          <span *ngIf="numberOfRowsSelected == 1" fxLayoutAlign="end center"
            >{{ numberOfRowsSelected }} company selected</span
          >
        </div>
        <div class="client-input input-padding list-header">
          <span class="text-small">
            <strong>Step {{ stepNumber }} of {{ numberOfSteps }}</strong> Edit Company
            Details</span
          >
        </div>
        <div fxLayout="row">
          <div fxFlex="7"></div>
          <div class="client-input" fxFlex>
            <span>Company Name</span>
            <dx-text-box [(value)]="bulkEditCompany.CompanyName"></dx-text-box>
          </div>
          <div fxFlex="7"></div>
        </div>
        <div class="white-padding" fxLayout="row">
          <div fxFlex="7"></div>
          <div
            class="client-input edit-company-dropdown input-padding popup-expansion-row"
            fxLayout="row"
            (click)="expandLabelsRow()"
            fxFlex
          >
            <div fxFlex>Labels</div>
            <mat-icon *ngIf="!labelsExpanded" class="icon-large"
              >arrow_drop_down</mat-icon
            >
            <mat-icon *ngIf="labelsExpanded" class="icon-large">arrow_drop_up</mat-icon>
          </div>
          <div fxFlex="7"></div>
        </div>
        <div *ngIf="labelsExpanded">
          <div fxLayout="row wrap" fxFlex>
            <ng-template ngFor let-group [ngForOf]="groupLabels">
              <div class="client-input input-padding" fxFlex="50" fxLayout="row">
                <div fxFlex></div>
                <div class="client-input" fxLayout="column" fxFlex="72">
                  <span>{{ group.name }}</span>
                  <dx-tag-box
                    [dataSource]="group.labels"
                    displayExpr="name"
                    valueExpr="labelId"
                    [(value)]="group.labelId"
                    [showSelectionControls]="true"
                    applyValueMode="instantly"
                    [hideSelectedItems]="true"
                    [multiline]="false"
                  >
                  </dx-tag-box>
                </div>
                <div fxFlex></div>
              </div>
            </ng-template>
          </div>
        </div>
        <div class="white-padding" fxLayout="row">
          <div fxFlex="7"></div>
          <div
            class="client-input edit-company-dropdown input-padding popup-expansion-row"
            fxLayout="row"
            (click)="expandBillingRow(billingAddress1Expanded)"
            fxFlex
          >
            <div fxFlex>Billing Address</div>
            <mat-icon *ngIf="!billingAddress1Expanded" class="icon-large"
              >arrow_drop_down</mat-icon
            >
            <mat-icon *ngIf="billingAddress1Expanded" class="icon-large"
              >arrow_drop_up</mat-icon
            >
          </div>
          <div fxFlex="7"></div>
        </div>
        <div *ngIf="billingAddress1Expanded">
          <div class="client-input input-padding" fxLayout="row">
            <div fxFlex="7"></div>
            <div class="client-input" fxLayout="column" fxFlex>
              <span>Address Line 1</span>
              <dx-text-box [(value)]="bulkEditCompany.Address"></dx-text-box>
            </div>
            <div fxFlex="7"></div>
          </div>
          <div class="client-input input-padding" fxLayout="row">
            <div fxFlex="7"></div>
            <div class="client-input" fxLayout="column" fxFlex>
              <span>Address Line 2</span>
              <dx-text-box [(value)]="bulkEditCompany.Address2"></dx-text-box>
            </div>
            <div fxFlex="7"></div>
          </div>
          <div class="client-input input-padding" fxLayout="row">
            <div fxFlex="7"></div>
            <div fxFlex="60" class="client-input" fxLayout="column">
              <span>City</span>
              <dx-text-box [(value)]="bulkEditCompany.City"></dx-text-box>
            </div>
            <div fxFlex="6"></div>
            <div fxFlex="34" class="client-input" fxLayout="column">
              <span>ZIP / Postal Code</span>
              <dx-text-box [(value)]="bulkEditCompany.Zip"></dx-text-box>
            </div>
            <div fxFlex="7"></div>
          </div>
          <div class="client-input input-padding" fxLayout="row">
            <div fxFlex="7"></div>
            <div fxFlex="60" class="client-input" fxLayout="column">
              <span>State / Province / Region</span>
              <dx-text-box [(value)]="bulkEditCompany.State"></dx-text-box>
            </div>
            <div fxFlex></div>
            <!-- <div fxFlex="34" class="client-input" fxLayout="column">
                            <span>Country</span>
                            <dx-text-box></dx-text-box>
                        </div>
                        <div fxFlex="7"></div> -->
          </div>
          <!-- <div class="client-input input-padding" fxLayout="row">
                        <div fxFlex="7"></div>
                        <div fxFlex="46" class="client-input" fxLayout="column">
                            <span>County</span>
                            <dx-select-box placeholder="Select County"></dx-select-box>
                        </div>
                    </div> -->
        </div>
        <div class="white-padding" fxLayout="row">
          <div fxFlex="7"></div>
          <div
            class="client-input edit-company-dropdown input-padding popup-expansion-row"
            fxLayout="row"
            (click)="expandBillingRow(shippingAddress1Expanded)"
            fxFlex
          >
            <div fxFlex>Shipping Address</div>
            <mat-icon *ngIf="!shippingAddress1Expanded" class="icon-large"
              >arrow_drop_down</mat-icon
            >
            <mat-icon *ngIf="shippingAddress1Expanded" class="icon-large"
              >arrow_drop_up</mat-icon
            >
          </div>
          <div fxFlex="7"></div>
        </div>
        <div *ngIf="shippingAddress1Expanded">
          <div class="client-input input-padding" fxLayout="row">
            <div fxFlex="7"></div>
            <div fxFlex class="client-input" fxLayout="column">
              <span>Address Line 1</span>
              <dx-text-box [(value)]="bulkEditCompany.ShippingAddress"></dx-text-box>
            </div>
            <div fxFlex="7"></div>
          </div>
          <div class="client-input input-padding" fxLayout="row">
            <div fxFlex="7"></div>
            <div fxFlex="60" class="client-input" fxLayout="column">
              <span>City</span>
              <dx-text-box [(value)]="bulkEditCompany.ShippingCity"></dx-text-box>
            </div>
            <div fxFlex="6"></div>
            <div fxFlex="34" class="client-input" fxLayout="column">
              <span>ZIP / Postal Code</span>
              <dx-text-box [(value)]="bulkEditCompany.ShippingZip"></dx-text-box>
            </div>
            <div fxFlex="7"></div>
          </div>
          <div class="client-input input-padding" fxLayout="row">
            <div fxFlex="7"></div>
            <div fxFlex="60" class="client-input" fxLayout="column">
              <span>State / Province / Region</span>
              <dx-text-box [(value)]="bulkEditCompany.ShippingState"></dx-text-box>
            </div>
            <div fxFlex></div>
            <!-- <div fxFlex="34" class="client-input" fxLayout="column">
                            <span>Country</span>
                            <dx-text-box></dx-text-box>
                        </div>
                        <div fxFlex="7"></div> -->
          </div>
          <!-- <div class="client-input input-padding" fxLayout="row">
                        <div fxFlex="7"></div>
                        <div fxFlex="46" class="client-input" fxLayout="column">
                            <span>County</span>
                            <dx-select-box placeholder="Select County"></dx-select-box>
                        </div>
                    </div> -->
        </div>
        <div class="client-input input-padding" fxLayout="row" fxFlex>
          <div fxFlex="7"></div>
          <div fxFlex class="client-input" fxLayout="column">
            <span>Main Office</span>
            <dx-number-box [(value)]="bulkEditCompany.BusinessPhone"></dx-number-box>
          </div>
          <div fxFlex="14"></div>
          <div fxFlex class="client-input" fxLayout="column">
            <span>Fax</span>
            <dx-number-box [(value)]="bulkEditCompany.PrimaryFax"></dx-number-box>
          </div>
          <div fxFlex="7"></div>
        </div>
        <div class="client-input input-padding" fxLayout="row" fxFlex>
          <div fxFlex="7"></div>
          <div fxFlex="36" class="client-input" fxLayout="column">
            <span>Website</span>
            <dx-text-box [(value)]="bulkEditCompany.WebSite"></dx-text-box>
          </div>
          <div fxFlex></div>
        </div>
        <div fxLayout="row wrap" fxFlex>
          <ng-template ngFor let-field [ngForOf]="companyDetails">
            <div
              *ngIf="field.FieldType == 1 && field.Name == 'Notes'"
              class="client-input input-padding"
              fxFlex="100"
              fxLayout="row"
            >
              <div fxFlex></div>
              <div fxFlex="86" class="client-input" fxLayout="column">
                <span [innerText]="field.Name"></span>
                <dx-text-area [(value)]="field.Value"></dx-text-area>
              </div>
              <div fxFlex></div>
            </div>
            <div
              *ngIf="
                (field.FieldType == 1 ||
                  field.FieldType == 8 ||
                  field.FieldType == 10 ||
                  field.FieldType == 11) &&
                field.Name != 'Notes'
              "
              class="client-input input-padding"
              fxFlex="50"
              fxLayout="row"
            >
              <div fxFlex></div>
              <div fxFlex="72" class="client-input" fxLayout="column">
                <span [innerText]="field.Name"></span>
                <dx-text-box [(value)]="field.Value"></dx-text-box>
              </div>
              <div fxFlex></div>
            </div>
            <div
              *ngIf="field.FieldType == 2"
              class="client-input input-padding"
              fxFlex="50"
              fxLayout="row"
            >
              <div fxFlex="14"></div>
              <div fxFlex="72" class="client-input" fxLayout="column">
                <span [innerText]="field.Name"></span>
                <dx-check-box [(value)]="field.Value"></dx-check-box>
              </div>
              <div fxFlex="14"></div>
            </div>
            <div
              *ngIf="field.FieldType == 3"
              class="client-input input-padding"
              fxFlex="50"
              fxLayout="row"
            >
              <div fxFlex="14"></div>
              <div fxFlex="72" class="client-input" fxLayout="column">
                <span [innerText]="field.Name"></span>
                <dx-number-box [(value)]="field.Value"></dx-number-box>
              </div>
              <div fxFlex="14"></div>
            </div>
            <div
              *ngIf="field.FieldType == 4"
              class="client-input input-padding"
              fxFlex="50"
              fxLayout="row"
            >
              <div fxFlex="14"></div>
              <div fxFlex="72" class="client-input" fxLayout="column">
                <span [innerText]="field.Name"></span>
                <dx-number-box
                  format="$ #,##0.##"
                  [(value)]="field.Value"
                ></dx-number-box>
              </div>
              <div fxFlex="14"></div>
            </div>
            <div
              *ngIf="field.FieldType == 5"
              class="client-input input-padding"
              fxFlex="50"
              fxLayout="row"
            >
              <div fxFlex="14"></div>
              <div fxFlex="72" class="client-input" fxLayout="column">
                <span [innerText]="field.Name"></span>
                <dx-select-box [(value)]="field.Value"></dx-select-box>
              </div>
              <div fxFlex="14"></div>
            </div>
            <div
              *ngIf="field.FieldType == 6"
              class="client-input input-padding"
              fxFlex="50"
              fxLayout="row"
            >
              <div fxFlex="14"></div>
              <div fxFlex="72" class="client-input" fxLayout="column">
                <span [innerText]="field.Name"></span>
                <dx-date-box [(value)]="field.Value" type="date"></dx-date-box>
              </div>
              <div fxFlex="14"></div>
            </div>
            <div
              *ngIf="field.FieldType == 9"
              class="client-input input-padding"
              fxFlex="50"
              fxLayout="row"
            >
              <div fxFlex="14"></div>
              <div fxFlex="72" class="client-input" fxLayout="column">
                <span [innerText]="field.Name"></span>
                <dx-text-box
                  [(value)]="field.Value"
                  mask="(000) 000-0000"
                  [maskRules]="rules"
                >
                </dx-text-box>
              </div>
              <div fxFlex="14"></div>
            </div>
            <div
              *ngIf="field.FieldType == 12"
              class="client-input input-padding"
              fxFlex="50"
              fxLayout="row"
            >
              <div fxFlex="14"></div>
              <div fxFlex="72" class="client-input" fxLayout="column">
                <span [innerText]="field.Name"></span>
                <dx-text-box [(value)]="field.Value"></dx-text-box>
              </div>
              <div fxFlex="14"></div>
            </div>
          </ng-template>
        </div>
      </dx-scroll-view>
    </div>
    <div fxLayout="row" fxLayoutAlign="end end">
      <span class="button button-white" (click)="clearBulkEdit()">Cancel</span>
      <div fxFlex fxLayoutAlign="end end">
        <span class="button button-white" (click)="stepBackwards()">Back</span>
        <span
          class="button button-blue"
          disabled="true"
          (click)="storeCompanyDetails(); checkNextPopup()"
          >Next</span
        >
      </div>
    </div>
  </div>
</dx-popup>

<dx-popup
  *ngIf="editCustomFieldPopup"
  [position]="{ my: 'right', at: 'right', of: window }"
  [width]="600"
  [height]="'100vh'"
  [showTitle]="false"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [(visible)]="editCustomFieldPopup"
  shadingColor="rgba(0,0,0,0.5)"
>
  <div *dxTemplate="let data of 'content'">
    <div fxLayout="column" style="height: calc(100% - 50px)">
      <dx-scroll-view [height]="'inherit'">
        <div class="list-header" fxLayout="row">
          <h2 fxLayoutAlign="start center" fxFlex>Edit Companies</h2>
          <span *ngIf="numberOfRowsSelected > 1" fxLayoutAlign="end center"
            >{{ numberOfRowsSelected }} companies selected</span
          >
          <span *ngIf="numberOfRowsSelected == 1" fxLayoutAlign="end center"
            >{{ numberOfRowsSelected }} company selected</span
          >
        </div>
        <div class="client-input input-padding list-header">
          <span class="text-small">
            <strong>Step {{ stepNumber }} of {{ numberOfSteps }}</strong> Edit
            {{ customSection.Name }} fields</span
          >
        </div>
        <ng-template ngFor let-tab [ngForOf]="customSection.Tabs">
          <div fxLayout="row">
            <span fxLayoutAlign="center center" fxFlex>{{ tab.Name }}</span>
          </div>
          <div class="header-width"></div>
          <div fxLayout="row wrap" fxFlex>
            <ng-template ngFor let-field [ngForOf]="tab.Fields">
              <div
                *ngIf="field.FieldType == 1 && field.Name == 'Notes'"
                class="client-input input-padding"
                fxFlex="100"
                fxLayout="row"
              >
                <div fxFlex></div>
                <div fxFlex="86" class="client-input" fxLayout="column">
                  <span [innerText]="field.Name"></span>
                  <dx-text-area [(value)]="field.Value"></dx-text-area>
                </div>
                <div fxFlex></div>
              </div>
              <div
                *ngIf="
                  (field.FieldType == 1 ||
                    field.FieldType == 8 ||
                    field.FieldType == 10 ||
                    field.FieldType == 11) &&
                  field.Name != 'Notes'
                "
                class="client-input input-padding"
                fxFlex="50"
                fxLayout="row"
              >
                <div fxFlex></div>
                <div fxFlex="72" class="client-input" fxLayout="column">
                  <span [innerText]="field.Name"></span>
                  <dx-text-box [(value)]="field.Value"></dx-text-box>
                </div>
                <div fxFlex></div>
              </div>
              <div
                *ngIf="field.FieldType == 2"
                class="client-input input-padding"
                fxFlex="50"
                fxLayout="row"
              >
                <div fxFlex="14"></div>
                <div fxFlex="72" class="client-input" fxLayout="column">
                  <span [innerText]="field.Name"></span>
                  <dx-check-box [(value)]="field.Value"></dx-check-box>
                </div>
                <div fxFlex="14"></div>
              </div>
              <div
                *ngIf="field.FieldType == 3"
                class="client-input input-padding"
                fxFlex="50"
                fxLayout="row"
              >
                <div fxFlex="14"></div>
                <div fxFlex="72" class="client-input" fxLayout="column">
                  <span [innerText]="field.Name"></span>
                  <dx-number-box [(value)]="field.Value"></dx-number-box>
                </div>
                <div fxFlex="14"></div>
              </div>
              <div
                *ngIf="field.FieldType == 4"
                class="client-input input-padding"
                fxFlex="50"
                fxLayout="row"
              >
                <div fxFlex="14"></div>
                <div fxFlex="72" class="client-input" fxLayout="column">
                  <span [innerText]="field.Name"></span>
                  <dx-number-box
                    format="$ #,##0.##"
                    [(value)]="field.Value"
                  ></dx-number-box>
                </div>
                <div fxFlex="14"></div>
              </div>
              <div
                *ngIf="field.FieldType == 5"
                class="client-input input-padding"
                fxFlex="50"
                fxLayout="row"
              >
                <div fxFlex="14"></div>
                <div fxFlex="72" class="client-input" fxLayout="column">
                  <span [innerText]="field.Name"></span>
                  <dx-select-box [(value)]="field.Value"></dx-select-box>
                </div>
                <div fxFlex="14"></div>
              </div>
              <div
                *ngIf="field.FieldType == 6"
                class="client-input input-padding"
                fxFlex="50"
                fxLayout="row"
              >
                <div fxFlex="14"></div>
                <div fxFlex="72" class="client-input" fxLayout="column">
                  <span [innerText]="field.Name"></span>
                  <dx-date-box [(value)]="field.Value" type="date"></dx-date-box>
                </div>
                <div fxFlex="14"></div>
              </div>
              <div
                *ngIf="field.FieldType == 9"
                class="client-input input-padding"
                fxFlex="50"
                fxLayout="row"
              >
                <div fxFlex="14"></div>
                <div fxFlex="72" class="client-input" fxLayout="column">
                  <span [innerText]="field.Name"></span>
                  <dx-text-box
                    [(value)]="field.Value"
                    mask="(000) 000-0000"
                    [maskRules]="rules"
                  >
                  </dx-text-box>
                </div>
                <div fxFlex="14"></div>
              </div>
              <div
                *ngIf="field.FieldType == 12"
                class="client-input input-padding"
                fxFlex="50"
                fxLayout="row"
              >
                <div fxFlex="14"></div>
                <div fxFlex="72" class="client-input" fxLayout="column">
                  <span [innerText]="field.Name"></span>
                  <dx-text-box [(value)]="field.Value"></dx-text-box>
                </div>
                <div fxFlex="14"></div>
              </div>
            </ng-template>
          </div>
        </ng-template>
      </dx-scroll-view>
    </div>
    <div fxLayout="row" fxLayoutAlign="end end">
      <span class="button button-white" (click)="clearBulkEdit()">Cancel</span>
      <div fxFlex fxLayoutAlign="end end">
        <span class="button button-white" (click)="stepBackwards()">Back</span>
        <span
          class="button button-blue"
          disabled="true"
          (click)="storeCustomFieldsChanged(customSection); checkNextPopup()"
          >Next</span
        >
      </div>
    </div>
  </div>
</dx-popup>

<dx-popup
  *ngIf="confirmationPopup"
  [position]="{ my: 'right', at: 'right', of: window }"
  [width]="600"
  [height]="'100vh'"
  [showTitle]="false"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [(visible)]="confirmationPopup"
  shadingColor="rgba(0,0,0,0.5)"
>
  <div *dxTemplate="let data of 'content'">
    <div fxLayout="column" style="height: calc(100% - 50px)">
      <dx-scroll-view [height]="'inherit'">
        <div class="list-header" fxLayout="row">
          <h2 fxLayoutAlign="start center" fxFlex>Edit Companies</h2>
          <span *ngIf="numberOfRowsSelected > 1" fxLayoutAlign="end center"
            >{{ numberOfRowsSelected }} companies selected</span
          >
          <span *ngIf="numberOfRowsSelected == 1" fxLayoutAlign="end center"
            >{{ numberOfRowsSelected }} company selected</span
          >
        </div>
        <div class="client-input input-padding list-header">
          <span class="text-small">
            <strong>Step {{ stepNumber }} of {{ numberOfSteps }}</strong> Confirm
            edits</span
          >
        </div>
        <div
          *ngIf="
            (territoriesChanged.length == 0 || territoriesChanged == undefined) &&
            (primarySalesAgentChanged.length == 0 ||
              primarySalesAgentChanged == undefined) &&
            (companyDetailsChanged.length == 0 || companyDetailsChanged == undefined) &&
            (customSectionsChanged.length == 0 || customSectionsChanged == undefined)
          "
        >
          <span>You didn't make any changes to the companies</span>
        </div>
        <div
          *ngIf="
            territoriesChanged.length != 0 ||
            primarySalesAgentChanged.length != 0 ||
            companyDetailsChanged.length != 0 ||
            customSectionsChanged.length != 0
          "
        >
          <ul>
            <div *ngIf="territoriesChanged.length != 0">
              <li>
                Added
                <span *ngFor="let field of territoriesChanged; let last = last">
                  <strong *ngIf="!last">{{ field }}, </strong>
                  <strong *ngIf="last">{{ field }}</strong>
                </span>
                to the territories
              </li>
            </div>
            <div *ngIf="primarySalesAgentChanged.length != 0">
              <li>
                Make <strong>{{ primarySalesAgentChanged[0] }}</strong> the primary sales
                rep for all selected companies
              </li>
            </div>
            <div *ngFor="let field of companyDetailsChanged">
              <div *ngIf="field">
                <li>
                  Update <strong>{{ field.Name }}</strong> to
                  <strong>{{ field.Value }}</strong> for all selected companies
                </li>
              </div>
            </div>
            <div *ngFor="let field of customSectionsChanged">
              <div *ngIf="field">
                <li>
                  Update <strong>{{ field.Section }}</strong
                  >, <strong>{{ field.FieldName }}</strong> to
                  <strong>{{ field.Value }}</strong> for all selected companies
                </li>
              </div>
            </div>
          </ul>
        </div>
      </dx-scroll-view>
    </div>
    <div fxLayout="row" fxLayoutAlign="end end">
      <span class="button button-white" (click)="clearBulkEdit()">Cancel</span>
      <div fxFlex fxLayoutAlign="end end">
        <span class="button button-white" (click)="stepBackwards()">Back</span>
        <span class="button button-blue" (click)="saveEditedCompanies(selectedRows)"
          >Save</span
        >
      </div>
    </div>
  </div>
</dx-popup>

<dx-popup
  [width]="500"
  [height]="300"
  [showTitle]="false"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [(visible)]="bulkDeletePopup"
  shadingColor="rgba(0,0,0,0.5)"
>
  <div *dxTemplate="let data of 'content'">
    <div fxLayout="column" fxFlex>
      <div class="list-header" fxLayout="row">
        <h2 fxLayoutAlign="start center" fxFlex>Delete Companies</h2>
        <span>{{ numberOfRowsSelected }} companies selected</span>
      </div>
      <div class="cell-padding">
        Are you sure you want to delete the selected companies?
      </div>
      <div class="text-small cell-padding background-yellow">
        This will also delete all contacts, call notes, follow ups, files and sales data
        associated with the selected companies. This delete cannot be undone.
      </div>
      <div class="cell-padding">
        <div>Type DELETE to confirm</div>
        <dx-text-box [(value)]="deleteConfirmation"></dx-text-box>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center">
        <span class="button button-white" (click)="bulkDeletePopup = false">Cancel</span>
        <span
          *ngIf="deleteConfirmation != 'DELETE'"
          class="button button-red button-disabled"
          >Delete</span
        >
        <span
          *ngIf="deleteConfirmation == 'DELETE'"
          class="button button-red"
          (click)="bulkDelete(companiesDeleted)"
          >Delete</span
        >
      </div>
    </div>
  </div>
</dx-popup>

<dx-popup
  [position]="{ my: 'right', at: 'right', of: window }"
  [width]="600"
  [height]="'100vh'"
  [showTitle]="false"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [(visible)]="renameListPopup"
  shadingColor="rgba(0,0,0,0.5)"
>
  <div *dxTemplate="let data of 'content'">
    <div fxLayout="column" style="height: calc(100% - 50px)">
      <dx-scroll-view [height]="'inherit'">
        <div class="list-header" fxLayout="row">
          <h2 fxFlex>Rename List</h2>
        </div>
        <div>
          <div fxLayout="column" fxFlex>
            <span>List Name</span>
            <dx-text-box [(value)]="selectedList.name"></dx-text-box>
          </div>
        </div>
      </dx-scroll-view>
    </div>
    <div fxLayout="row" fxLayoutAlign="end end">
      <span class="button button-white" (click)="cancelRenameList()">Cancel</span>
      <div fxFlex fxLayoutAlign="end end">
        <span
          class="button button-blue"
          disabled="true"
          (click)="saveList(selectedList, false)"
          >Save</span
        >
      </div>
    </div>
  </div>
</dx-popup>

<dx-popup
  [position]="{ my: 'right', at: 'right', of: window }"
  [width]="600"
  [height]="'100vh'"
  [showTitle]="false"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [(visible)]="duplicateListPopup"
  shadingColor="rgba(0,0,0,0.5)"
>
  <div *dxTemplate="let data of 'content'">
    <div fxLayout="column" style="height: calc(100% - 50px)">
      <dx-scroll-view [height]="'inherit'">
        <div class="list-header" fxLayout="row">
          <h2 fxFlex>Duplicate List</h2>
        </div>
        <div>
          <div fxLayout="column" fxFlex>
            <span>List Name</span>
            <dx-text-box [(value)]="newList.name"></dx-text-box>
          </div>
        </div>
      </dx-scroll-view>
    </div>
    <div fxLayout="row" fxLayoutAlign="end end">
      <span class="button button-white" (click)="duplicateListPopup = false">Cancel</span>
      <div fxFlex fxLayoutAlign="end end">
        <span class="button button-blue" disabled="true" (click)="saveList(newList, true)"
          >Save</span
        >
      </div>
    </div>
  </div>
</dx-popup>

<dx-popup
  [position]="{ my: 'right', at: 'right', of: window }"
  [width]="600"
  [height]="'100vh'"
  [showTitle]="false"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [(visible)]="scratchListPopup"
  shadingColor="rgba(0,0,0,0.5)"
>
  <div *dxTemplate="let data of 'content'">
    <div fxLayout="column" style="height: calc(100% - 50px)">
      <dx-scroll-view [height]="'inherit'">
        <div class="list-header" fxLayout="row">
          <h2 fxFlex>New List from scratch</h2>
        </div>
        <div>
          <div fxLayout="column" fxFlex>
            <span>List Name</span>
            <dx-text-box [(value)]="selectedList.name"></dx-text-box>
          </div>
        </div>
      </dx-scroll-view>
    </div>
    <div fxLayout="row" fxLayoutAlign="end end">
      <span class="button button-white" (click)="scratchListPopup = false">Cancel</span>
      <div fxFlex fxLayoutAlign="end end">
        <span
          class="button button-blue"
          disabled="true"
          (click)="saveList(selectedList, false)"
          >Save</span
        >
      </div>
    </div>
  </div>
</dx-popup>

<dx-popup
  *ngIf="existingDuplicatePopup"
  [position]="{ my: 'right', at: 'right', of: window }"
  [width]="600"
  [height]="'100vh'"
  [showTitle]="false"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [(visible)]="existingDuplicatePopup"
  shadingColor="rgba(0,0,0,0.5)"
>
  <div *dxTemplate="let data of 'content'">
    <div fxLayout="column" style="height: calc(100% - 50px)">
      <dx-scroll-view [height]="'inherit'">
        <div class="list-header" fxLayout="row">
          <h2 fxFlex>Duplicate from existing list</h2>
        </div>
        <div>
          <div fxLayout="column" fxFlex>
            <h3>Select a list to duplicate</h3>
            <div *ngFor="let list of lists" fxLayout="row" fxFlex>
              <div class="list-options" (click)="selectDuplicateList(list)" fxFlex>
                {{ list.name }}
              </div>
            </div>
          </div>
        </div>
      </dx-scroll-view>
    </div>
    <div fxLayout="row" fxLayoutAlign="end end">
      <span class="button button-white" (click)="existingDuplicatePopup = false"
        >Cancel</span
      >
      <div fxFlex fxLayoutAlign="end end"></div>
    </div>
  </div>
</dx-popup>

<!-- Save as Pop-up -->
<dx-popup
  [width]="300"
  [height]="'150'"
  [showTitle]="false"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [(visible)]="saveAsListPopup"
  shadingColor="rgba(0,0,0,0.5)"
>
  <div *dxTemplate="let data of 'content'">
    <div fxLayout="column">
      <div>
        <div fxLayout="column" fxFlex>
          <div>List Name</div>
          <dx-text-box [(value)]="newList.name"></dx-text-box>
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="end end">
      <span class="button button-white" (click)="saveAsListPopup = false">Cancel</span>
      <div fxFlex fxLayoutAlign="end end">
        <span
          class="button button-blue"
          disabled="true"
          (click)="saveList(newList, false)"
          >Save</span
        >
      </div>
    </div>
  </div>
</dx-popup>

<dx-popup
  [width]="310"
  [height]="150"
  [showTitle]="false"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [(visible)]="deleteListPopup"
  shadingColor="rgba(0,0,0,0.5)"
>
  <div *dxTemplate="let data of 'content'">
    <div fxLayout="column" fxFlex>
      <h3>Are you sure you want to delete?</h3>
      <div fxLayout="row" fxLayoutAlign="center center">
        <span class="button button-white" (click)="deleteListPopup = false">No</span>
        <span class="button button-red" (click)="confirmDeleteSingleList(listDelete)"
          >Yes</span
        >
      </div>
    </div>
  </div>
</dx-popup>

<!-- Merge button options Pop-up -->
<dx-popup
  [position]="{ my: 'right', at: 'right', of: window }"
  [width]="700"
  [height]="'100vh'"
  [showTitle]="false"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [(visible)]="mergePopup"
  shadingColor="rgba(0,0,0,0.5)"
>
  <div *dxTemplate="let data of 'content'">
    <div class="list-header-main">
      <h2 fxLayoutAlign="start center">Merge Companies</h2>
      <span fxLayoutAlign="end center" fxFlex class="text-small text-blue"
        >What will be saved in the merge?</span
      >
    </div>
    <dx-scroll-view [height]="'80vh'">
      <table id="mergeOptions">
        <tbody style="height: 100%">
          <tr>
            <td style="width: 237px"></td>
            <td style="width: 207px">
              <div>
                {{ company1SelectedField }} Fields Selected <br />
                <!-- <dx-check-box [(value)]="selectAllCompany1.selected" (onValueChanged)="selectBoxMerge(selectAllCompany1,1)"></dx-check-box> -->
              </div>
            </td>
            <td>
              <div>
                {{ company2SelectedField }} Fields Selected <br />
                <!-- <dx-check-box [(value)]="selectAllCompany2.selected" (onValueChanged)="selectBoxMerge(selectAllCompany2,2)"></dx-check-box> -->
              </div>
            </td>
          </tr>
          <tr>
            <td>Telenotes ID</td>
            <td>
              <dx-check-box
                *ngIf="company1.id && company1.id.value !== ''"
                [(value)]="company1.id.selected"
                (onValueChanged)="selectBoxMerge(company1.id, 'id', 1)"
              >
              </dx-check-box>
              &nbsp;
              {{ company1.id.value }}
            </td>
            <td>
              <dx-check-box
                *ngIf="company2.id && company2.id.value !== ''"
                [(value)]="company2.id.selected"
                (onValueChanged)="selectBoxMerge(company2.id, 'id', 2)"
              >
              </dx-check-box>
              &nbsp;
              {{ company2.id.value }}
            </td>
          </tr>
          <tr>
            <td>Company Name</td>
            <td>
              <dx-check-box
                *ngIf="company1.name && company1.name.value !== ''"
                [(value)]="company1.name.selected"
                (onValueChanged)="selectBoxMerge(company1.name, 'name', 1)"
              ></dx-check-box>
              &nbsp;
              {{ company1.name.value }}
            </td>
            <td>
              <dx-check-box
                *ngIf="company1.name && company1.name.value !== ''"
                [(value)]="company2.name.selected"
                (onValueChanged)="selectBoxMerge(company2.name, 'name', 2)"
              ></dx-check-box>
              &nbsp;
              {{ company2.name.value }}
            </td>
          </tr>
          <tr>
            <td>Website</td>
            <td>
              <dx-check-box
                *ngIf="company1.website && company1.website.value !== ''"
                [(value)]="company1.website.selected"
                (onValueChanged)="selectBoxMerge(company1.website, 'website', 1)"
              >
              </dx-check-box>
              &nbsp;
              {{ company1.website?.value }}
            </td>
            <td>
              <dx-check-box
                *ngIf="company2.website && company2.website.value !== ''"
                [(value)]="company2.website.selected"
                (onValueChanged)="selectBoxMerge(company2.website, 'website', 2)"
              >
              </dx-check-box>
              &nbsp;
              {{ company2.website?.value }}
            </td>
          </tr>
          <tr>
            <td>Account ID</td>
            <td>
              <dx-check-box
                *ngIf="company1.accountId && company1.accountId.value !== ''"
                [(value)]="company1.accountId.selected"
                (onValueChanged)="selectBoxMerge(company1.accountId, 'accountId', 1)"
              >
              </dx-check-box>
              &nbsp;
              {{ company1.accountId?.value }}
            </td>
            <td>
              <dx-check-box
                *ngIf="company2.accountId && company2.accountId.value !== ''"
                [(value)]="company2.accountId.selected"
                (onValueChanged)="selectBoxMerge(company2.accountId, 'accountId', 2)"
              >
              </dx-check-box>
              &nbsp;
              {{ company2.accountId?.value }}
            </td>
          </tr>
          <tr>
            <td>Phone</td>
            <td>
              <dx-check-box
                *ngIf="company1.phone && company1.phone.value !== ''"
                [(value)]="company1.phone.selected"
                (onValueChanged)="selectBoxMerge(company1.phone, 'phone', 1)"
              ></dx-check-box>
              &nbsp;
              {{ company1.phone?.value }}
            </td>
            <td>
              <dx-check-box
                *ngIf="company2.phone && company2.phone.value !== ''"
                [(value)]="company2.phone.selected"
                (onValueChanged)="selectBoxMerge(company2.phone, 'phone', 2)"
              ></dx-check-box>
              &nbsp;
              {{ company2.phone?.value }}
            </td>
          </tr>
          <tr>
            <td>Fax</td>
            <td>
              <dx-check-box
                *ngIf="company1.fax && company1.fax.value !== ''"
                [(value)]="company1.fax.selected"
                (onValueChanged)="selectBoxMerge(company1.fax, 'fax', 1)"
              ></dx-check-box>
              &nbsp;
              {{ company1.fax?.value }}
            </td>
            <td>
              <dx-check-box
                *ngIf="company2.fax && company2.fax.value !== ''"
                [(value)]="company2.fax.selected"
                (onValueChanged)="selectBoxMerge(company2.fax, 'fax', 2)"
              ></dx-check-box>
              &nbsp;
              {{ company2.fax?.value }}
            </td>
          </tr>
          <tr>
            <td>Notes</td>
            <td>
              <dx-check-box
                *ngIf="company1.notes && company1.notes.value !== ''"
                [(value)]="company1.notes.selected"
                (onValueChanged)="selectBoxMerge(company1.notes, 'notes', 1)"
              ></dx-check-box>
              &nbsp;
              {{ company1.notes?.value }}
            </td>
            <td>
              <dx-check-box
                *ngIf="company2.notes && company2.notes.value !== ''"
                [(value)]="company2.notes.selected"
                (onValueChanged)="selectBoxMerge(company2.notes, 'notes', 2)"
              ></dx-check-box>
              &nbsp;
              {{ company2.notes?.value }}
            </td>
          </tr>
          <tr>
            <td>Territories and Primary Sales Rep</td>
            <td>
              <div *ngFor="let territory of company1.territories">
                <div fxLayout="column">
                  <div fxLayout="row">
                    <dx-check-box
                      [(value)]="territory.selected"
                      (onValueChanged)="territoryChanged($event)"
                    >
                    </dx-check-box>
                    &nbsp;
                    <span>{{ territory.name }}</span>
                  </div>
                  <div fxLayout="column">
                    <div
                      *ngFor="let user of territory.users"
                      fxLayout="row"
                      style="padding-left: 25px"
                    >
                      <input
                        type="radio"
                        name="primaryUser"
                        [disabled]="!territory.selected"
                        [value]="user.id"
                      />
                      <span
                        style="padding-left: 12px"
                        [ngClass]="{ 'text-muted': !territory.selected }"
                        >{{ user.firstName }} {{ user.lastName }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <span *ngFor="let territory of company2.territories">
                <div fxLayout="column">
                  <div fxLayout="row">
                    <dx-check-box
                      [(value)]="territory.selected"
                      (onValueChanged)="territoryChanged($event)"
                    >
                    </dx-check-box>
                    &nbsp;
                    <span>{{ territory.name }}</span>
                  </div>
                  <div fxLayout="column">
                    <div
                      *ngFor="let user of territory.users"
                      fxLayout="row"
                      style="padding-left: 25px"
                    >
                      <input
                        type="radio"
                        name="primaryUser"
                        [disabled]="!territory.selected"
                        [value]="user.id"
                      />
                      <span
                        style="padding-left: 12px"
                        [ngClass]="{ 'text-muted': !territory.selected }"
                        >{{ user.firstName }} {{ user.lastName }}</span
                      >
                    </div>
                  </div>
                </div>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <dx-data-grid
        [dataSource]="mergeData"
        [allowColumnReordering]="false"
        [allowColumnResizing]="false"
        [wordWrapEnabled]="true"
        [columnAutoWidth]="false"
        [showRowLines]="true"
        [showBorders]="true"
        [rowAlternationEnabled]="false"
        [showBorders]="true"
        [showColumnHeaders]="false"
      >
        <dxi-column dataField="fieldName"></dxi-column>
        <dxi-column
          dataField="field"
          [groupIndex]="0"
          groupCellTemplate="groupTemplate"
        ></dxi-column>
        <div *dxTemplate="let group of 'groupTemplate'">
          <span>{{ group.data.key }}</span>
        </div>
        <dxi-column dataField="comp1" cellTemplate="cellTemplate1"></dxi-column>
        <dxi-column dataField="comp2" cellTemplate="cellTemplate2"></dxi-column>
        <div *dxTemplate="let data of 'cellTemplate1'">
          <div *ngIf="data.data.comp1 && data.data.comp1.length">
            <div>
              <div *ngFor="let label of data.data.comp1" fxLayout="row wrap">
                <dx-check-box
                  [(value)]="label.selected"
                  [text]="label.value.trim()"
                ></dx-check-box>
                &nbsp;&nbsp;
              </div>
            </div>
          </div>
          <div *ngIf="data.data.comp1.value">
            <dx-check-box
              *ngIf="data.value.value !== ''"
              [(value)]="data.value.selected"
              (onValueChanged)="selectBoxMerge(data, data.data.field, 1, $event)"
            ></dx-check-box>
            &nbsp; {{ data.value.value }}
          </div>
        </div>
        <div *dxTemplate="let data of 'cellTemplate2'">
          <div *ngIf="data.data.comp2 && data.data.comp2.length">
            <div>
              <div *ngFor="let label of data.data.comp2" fxLayout="row wrap">
                <dx-check-box
                  [(value)]="label.selected"
                  [text]="label.value.trim()"
                ></dx-check-box>
                &nbsp;&nbsp;
              </div>
            </div>
          </div>
          <div *ngIf="data.data.comp2.value">
            <dx-check-box
              *ngIf="data.value.value !== ''"
              [(value)]="data.value.selected"
              (onValueChanged)="selectBoxMerge(data, data.data.field, 2, $event)"
            ></dx-check-box>
            &nbsp; {{ data.value.value }}
          </div>
        </div>
        <dxo-group-panel [visible]="false"></dxo-group-panel>
        <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>
      </dx-data-grid>
    </dx-scroll-view>

    <div fxLayoutAlign="end end">
      <div fxLayoutAlign="end center">
        <span class="button button-white" (click)="cancelMerge()">Cancel</span>
      </div>
      <!-- <span class="button button-white button-disabled" *ngIf="!selectAllCompany1.selected && !selectAllCompany2.selected">Merge</span> -->
      <span class="button button-blue" (click)="mergeCompanies()">Merge</span>
    </div>
  </div>
</dx-popup>

<!-- edit column pop-up -->
<dx-popup
  [width]="'40vw'"
  [height]="'60vh'"
  [showTitle]="false"
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [(visible)]="editColumnPopup"
  shadingColor="rgba(0,0,0,0.5)"
>
  <div *dxTemplate="let data of 'content'" fxLayout="column">
    <div fxLayout="column" style="height: calc(100% - 21px)">
      <dx-scroll-view [height]="'inherit'">
        <div fxLayout="column">
          <!-- Title -->
          <div class="list-header-main">
            <h3>Edit Columns</h3>
          </div>
        </div>

        <div fxLayout="row" fxFlex>
          <!-- Column fields -->
          <div fxLayout="column" fxFlex>
            <!-- available columns -->
            <dx-scroll-view [height]="'100%'" direction="vertical">
              <dx-list
                [dataSource]="column_options"
                [searchEnabled]="true"
                [grouped]="true"
                [collapsibleGroups]="false"
              >
                <div *dxTemplate="let data of 'item'">
                  <dx-check-box
                    [(value)]="data.enabled"
                    text="{{ data.caption }}"
                    (onValueChanged)="changeRowPosition(data.caption, data.enabled)"
                  ></dx-check-box>
                </div>
              </dx-list>
            </dx-scroll-view>
          </div>
          <div fxLayout="column" class="divider"></div>
          <!-- divider -->

          <div
            id="selected"
            fxLayout="column"
            style="width: 50%"
            class="selectedColumnList"
            fxFlex
          >
            <div fxLayout="row" class="colum-header" fxLayoutAlign="center start">
              Selected Columns ({{ selectedColumnCount }})
            </div>

            <dx-scroll-view [height]="'100%'" direction="vertical">
              <dx-list
                [dataSource]="selected_columns"
                height="100%"
                (onItemReordered)="moveFields($event)"
                [allowItemReordering]="true"
              >
                <dxo-item-dragging [allowReordering]="true">
                  <div
                    class="selectedColumnBlock columnText"
                    *dxTemplate="let data of 'item'"
                  >
                    {{ data.caption }}
                  </div>
                </dxo-item-dragging>
              </dx-list>
            </dx-scroll-view>
          </div>
        </div>
      </dx-scroll-view>
    </div>
    <div fxLayout="row" style="padding-top: 10px" fxLayoutAlign="end start">
      <!-- Buttons -->

      <div class="removeAll" (click)="removeAllColumns()">Remove all columns</div>
      <button class="button button-white" (click)="closeEditColumns()">Cancel</button>
      <button
        [disabled]="selectedColumnCount == 0"
        [ngClass]="{
          'button-white': selectedColumnCount > 0,
          button: selectedColumnCount >= 0,
          'button-disabled': selectedColumnCount == 0
        }"
        (click)="applyColumns()"
      >
        Apply
      </button>
    </div>
  </div>
</dx-popup>

<!-- Filter pop-up -->
<dx-popup
  *ngIf="showFilterMenu"
  [position]="{ my: 'right', at: 'right', of: window }"
  [width]="400"
  [height]="'100vh'"
  [showTitle]="true"
  title="Filters"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [(visible)]="showFilterMenu"
  shadingColor="rgba(0,0,0,0.5)"
>
  <div *dxTemplate="let data of 'content'">
    <div style="height: calc(100%)">
      <dx-scroll-view [height]="'inherit'">
        <div *ngIf="filterMenuPage == 1" fxLayout="column">
          <dx-list
            [dataSource]="filterOptions"
            [searchEnabled]="true"
            searchMode="contains"
            searchExpr="value"
          >
            <div
              *dxTemplate="let data of 'item'"
              (click)="selectFilterField(data, false)"
            >
              <div>{{ data.value }}</div>
            </div>
          </dx-list>
        </div>
        <div *ngIf="filterMenuPage == 2" fxLayout="column">
          <span class="link" (click)="filterMenuPage = 1"> < back</span>
          <h4 [innerText]="selectedFilterField.value"></h4>
          <div [ngSwitch]="selectedFilterField.type" fxLayout="column">
            <div *ngSwitchCase="'list'" fxLayout="column">
              <dx-select-box
                class="filterField"
                [dataSource]="listFilterOptions"
                displayExpr="value"
                [(value)]="selectedFilterField.selectedFilterOption"
              >
              </dx-select-box>
              <div class="filterField">
                <dx-tag-box
                  fxFlex
                  *ngIf="selectedFilterField.selectedFilterOption.id !== 3"
                  [items]="selectedFilterField.listData"
                  [(value)]="selectedFilterField.filterValue"
                  displayExpr="name"
                  valueExpr="name"
                  [acceptCustomValue]="false"
                  [searchEnabled]="true"
                >
                </dx-tag-box>
                <button
                  *ngIf="selectedFilterField.selectedFilterOption.id === 3"
                  class="button button-blue"
                  [style.border]="'none'"
                  [style.width]="'100%'"
                  (click)="searchCompaniesWithoutTerritories()"
                >
                  Search companies without territories
                </button>
              </div>
            </div>
            <div *ngSwitchCase="'date'">
              <dx-select-box
                class="filterField"
                [dataSource]="dateFilterOptions"
                displayExpr="value"
                [(value)]="selectedFilterField.selectedFilterOption"
              >
              </dx-select-box>
              <div
                class="filterField"
                [ngSwitch]="selectedFilterField.selectedFilterOption.id"
              >
                <div *ngSwitchDefault fxFlex>
                  <dx-date-box
                    fxFlex
                    [(value)]="selectedFilterField.filterValue[0]"
                    type="date"
                  >
                  </dx-date-box>
                </div>
                <div *ngSwitchCase="4" fxFlex fxLayout="column">
                  <dx-date-box
                    fxFlex
                    [(value)]="selectedFilterField.filterValue[0]"
                    type="date"
                  >
                  </dx-date-box>
                  <span>and</span>
                  <dx-date-box
                    fxFlex
                    [(value)]="selectedFilterField.filterValue[1]"
                    type="date"
                  >
                  </dx-date-box>
                </div>
              </div>
            </div>
            <div *ngSwitchCase="'string'" fxLayout="column">
              <dx-select-box
                class="filterField"
                [dataSource]="stringFilterOptions"
                displayExpr="value"
                [(value)]="selectedFilterField.selectedFilterOption"
                (onValueChanged)="stringFilterOptionChanged($event)"
              ></dx-select-box>
              <div
                class="filterField"
                [ngSwitch]="selectedFilterField.selectedFilterOption.id"
              >
                <div *ngSwitchDefault fxFlex>
                  <dx-tag-box
                    fxFlex
                    [(value)]="selectedFilterField.filterValue"
                    [acceptCustomValue]="true"
                    placeholder="Type keywords then hit enter"
                  >
                  </dx-tag-box>
                </div>
                <div *ngSwitchCase="4"></div>
                <div *ngSwitchCase="5"></div>
              </div>
            </div>
            <div *ngSwitchCase="'bool'" fxLayout="column">
              <mat-radio-group fxLayout="column" (change)="boolValueChanged($event)">
                <mat-radio-button value="true">is true</mat-radio-button>
                <mat-radio-button value="false">is false</mat-radio-button>
              </mat-radio-group>
            </div>
            <div *ngSwitchCase="'number'" fxLayout="column">
              <dx-select-box
                class="filterField"
                [dataSource]="numberFilterOptions"
                displayExpr="value"
                [(value)]="selectedFilterField.selectedFilterOption"
              >
              </dx-select-box>
              <div
                class="filterField"
                [ngSwitch]="selectedFilterField.selectedFilterOption.id"
              >
                <div *ngSwitchDefault fxFlex>
                  <dx-tag-box
                    fxFlex
                    [(value)]="selectedFilterField.filterValue"
                    [acceptCustomValue]="true"
                    placeholder="Type keywords then hit enter"
                  >
                  </dx-tag-box>
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="
              selectedFilterField.filterValue && selectedFilterField.filterValue.length
            "
          >
            <span
              class="button button-white"
              style="margin-top: 50px"
              fxFlex
              fxLayoutAlign="center center"
              (click)="applyFilter()"
              >Apply Filter</span
            >
          </div>
        </div>
      </dx-scroll-view>
    </div>
  </div>
</dx-popup>
