import { createSelector, MemoizedSelector } from '@ngrx/store';
import { Feature } from 'src/app/models/features/feature';
import { GenericMap } from 'src/app/models/generic-map';
import { selectFeatures } from 'src/app/view/shared/sidenav/sidenav-links.selector';
import { RootState } from '../../store.reducer';

export const selectHasExpensesAdmin: MemoizedSelector<RootState, boolean> =
  createSelector(selectFeatures, (featuresMap: GenericMap<Feature>): boolean => {
    if (
      featuresMap[4] &&
      featuresMap[4].Permissions[137] &&
      featuresMap[4].Permissions[137].Value.toString() === 'true'
    ) {
      return true;
    }
    return false;
  });
