import { RootState } from '../../../store/store.reducer';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { GenericMap } from 'src/app/models/generic-map';
import { Feature } from 'src/app/models/features/feature';
import { selectFeatures } from '../sidenav/sidenav-links.selector';
import { userOptionsRouteData } from '../../user-options-route-data';
import { FeaturePermission } from 'src/app/models/features/feature-permission';
import { RouteDatum } from 'src/app/models/routing/route-datum.model';

export const selectRoleName = (state: RootState) =>
  state.auth.data.api ? state.auth.data.api.Role.RoleName.toLowerCase() : undefined;

export const selectOptionsLinks: MemoizedSelector<RootState, RouteDatum[][]> =
  createSelector(
    selectFeatures,
    selectRoleName,
    (features: GenericMap<Feature>, roleName: string): RouteDatum[][] => {
      let availFeatureNames: string[] = [];
      if ((features != null || undefined) && Object.keys(features).length) {
        availFeatureNames = Object.values(features).map((feature: Feature) =>
          feature.Name.toLowerCase()
        );
      }

      if (availFeatureNames.includes('admin')) {
        const adminPermissions = Object.values(features[5].Permissions)
          .filter((perm: FeaturePermission) => {
            return perm.Value === 'true' || perm.Value !== '0';
          })
          .map((perm: FeaturePermission) => perm.Name.toLowerCase());

        if (roleName !== 'telenotes admin') {
          delete userOptionsRouteData.admin.manageUsers;
        }
        if (!adminPermissions.includes('territories')) {
          delete userOptionsRouteData.admin.territoryManagement;
        }
        if (!adminPermissions.includes('customization')) {
          delete userOptionsRouteData.admin.systemSettings;
        }
        // if (!adminPermissions.includes('sign in as other users')) {
        //   delete userOptionsRouteData.admin.signInAsUser;
        // }
      } else {
        delete userOptionsRouteData.admin;
      }

      if (roleName !== 'telenotes admin') {
        delete userOptionsRouteData.owner;
      }
      return Object.values(userOptionsRouteData).map((category) =>
        Object.values(category)
      );
    }
  );
