import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import {
  LoadProjectBidsFailure,
  LoadProjectBidsSuccess,
  ProjectBidsActionTypes,
  ProjectBidsActions,
  AddProjectBids,
  AddProjectBidsSuccess,
  AddProjectBidsFailure,
  UpdateProjectBids,
  UpdateProjectBidsSuccess,
  UpdateProjectBidsFailure,
  DeleteProjectBids,
  DeleteProjectBidsSuccess,
  DeleteProjectBidsFailure
} from './project-bids.actions';
import { Action } from '@ngrx/store';
import { ProjectBidsService } from 'src/app/services/system-settings/projects/project-bids.service';
import { ProjectBid } from 'src/app/models/admin/projects/project-bid';
import { HttpErrorResponse } from '@angular/common/http';
import { convertToMap } from 'src/app/utils/convertToMap';

@Injectable()
export class ProjectBidsEffects {
  loadProjectBids$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectBidsActionTypes.LoadProjectBids),
      mergeMap(() =>
        this.projectBidsService.getAll().pipe(
          map((data: ProjectBid[]) => {
            return new LoadProjectBidsSuccess(convertToMap(data, 'id'));
          }),
          catchError((error: HttpErrorResponse) => of(new LoadProjectBidsFailure(error)))
        )
      )
    )
  );

  addProjectBids$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectBidsActionTypes.AddProjectBids),
      map((action: AddProjectBids) => action.payload),
      mergeMap((projectBid: ProjectBid) =>
        this.projectBidsService.addProjectBid(projectBid).pipe(
          map((newProjectBid: ProjectBid) => new AddProjectBidsSuccess(newProjectBid)),
          catchError((error: HttpErrorResponse) => of(new AddProjectBidsFailure(error)))
        )
      )
    )
  );

  updateProjectBids$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectBidsActionTypes.UpdateProjectBids),
      map((action: UpdateProjectBids) => action.payload),
      mergeMap((projectBid: ProjectBid) =>
        this.projectBidsService.updateProjectBid(projectBid).pipe(
          map(
            (updatedProjectBid: ProjectBid) =>
              new UpdateProjectBidsSuccess(updatedProjectBid)
          ),
          catchError((error: HttpErrorResponse) =>
            of(new UpdateProjectBidsFailure(error))
          )
        )
      )
    )
  );

  deleteProjectBids$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectBidsActionTypes.DeleteProjectBids),
      map((action: DeleteProjectBids) => action.payload),
      mergeMap((projectBidId: number) =>
        this.projectBidsService.deleteProjectBid(projectBidId).pipe(
          map(() => new DeleteProjectBidsSuccess(projectBidId)),
          catchError((error: HttpErrorResponse) =>
            of(new DeleteProjectBidsFailure(error))
          )
        )
      )
    )
  );

  // @Effect()
  // reloadProjectBids$: Observable<Action> = this.actions$.pipe(
  //   ofType(
  //     ProjectBidsActionTypes.DeleteProjectBidsSuccess,
  //     ProjectBidsActionTypes.UpdateProjectBidsSuccess,
  //     ProjectBidsActionTypes.AddProjectBidsSuccess
  //   ),
  //   map(() => new LoadProjectBids())
  // );

  constructor(
    private projectBidsService: ProjectBidsService,
    private actions$: Actions<ProjectBidsActions>
  ) {}
}
