import { MemoizedSelector, createSelector } from '@ngrx/store';
import { RootState } from '../../../store.reducer';
import { Label } from '../../../../models/label';

export const selectAllProjectLabelsIndividual = (state: RootState) =>
  state.projectLabelsIndividual.data;

export const selectProjectLabelsIndividualAsArray: MemoizedSelector<
  RootState,
  Label[]
> = createSelector(
  selectAllProjectLabelsIndividual,
  (projectLabelsIndividualMap: { [id: number]: Label }): Label[] => {
    return Object.values(projectLabelsIndividualMap);
  }
);
