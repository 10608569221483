<div class="splash-screen"></div>
<div
  fxLayout="column"
  fxLayoutAlign="center center"
  class="login-container"
  *ngIf="!loading"
>
  <form (ngSubmit)="login()" #loginForm="ngForm">
    <div>
      <a href="https://telenotes.com"
        ><img
          class="logo_img"
          src="voze_logo.png"
          [ngStyle]="{ width: '450px', 'margin-bottom': '20px' }"
      /></a>
    </div>
    <div class="error" *ngIf="passwordError" fxLayout="row" fxLayoutAlign="center center">
      Incorrect username/password!
    </div>
    <div class="error" *ngIf="usernameError" fxLayout="row" fxLayoutAlign="center center">
      Must provide username and password!
    </div>
    <div class="error" *ngIf="serverError" fxLayout="row" fxLayoutAlign="center center">
      Sign in failed due to an error. Telenotes is fixing the issue right now.
    </div>
    <div>
      <mat-form-field class="user-input">
        <input
          matInput
          placeholder="Email or Pin"
          type="text"
          required
          [(ngModel)]="mailbox"
          name="mailbox"
        />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="user-input">
        <input
          matInput
          placeholder="Password"
          type="password"
          required
          [(ngModel)]="password"
          name="password"
        />
      </mat-form-field>
    </div>
    <div fxLayout="row">
      <div fxFlex="50" fxLayoutAlign="start center">
        <small class="link" [routerLink]="['/forgotPassword']"
          >Forgot your PIN or password?</small
        >
      </div>
      <div fxFlex="50" fxLayoutAlign="end center">
        <button class="md-btn md-btn-raised">Sign In</button>
      </div>
    </div>
    <input type="submit" class="enter_enabler" />
  </form>
  <br /><br />
  <span style="font-size: 18px"
    >Voze Beta users&nbsp;<a href="https://beta.telenotes.com" style="color: #ee4823"
      >sign in here</a
    ></span
  >
</div>
<div
  fxLayout="column"
  fxLayoutAlign="center center"
  class="login-container"
  *ngIf="loading"
>
  <dx-load-indicator id="medium-indicator" height="40" width="40"></dx-load-indicator>
</div>

<dx-popup
  class="popup"
  [width]="700"
  [height]="400"
  [showTitle]="false"
  [dragEnabled]="false"
  [closeOnOutsideClick]="true"
  [(visible)]="showTooltip"
>
  <div *dxTemplate="let data of 'content'">
    <span
      >All of your CRM data is available in both the original and new Telenotes by
      Voze.</span
    >
    <br /><br /><span
      >You may still see some of the original modules or features in the new Telenotes by
      Voze. This is normal and we will be updating them soon. The new Voze receives
      updates frequently, so if you have any questions or run into any issues, feel free
      to contact support@telenotes.com and we will get you squared away.</span
    >
    <br /><br /><span
      ><strong>*Quick Tip:*</strong> You can now sign in with either your email address or
      PIN.</span
    >
  </div>
</dx-popup>
