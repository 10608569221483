import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ExpensesEffects } from './expenses.effects';
import { expensesReducer } from './expenses.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('expenses', expensesReducer),
    EffectsModule.forFeature([ExpensesEffects])
  ]
})
export class ExpensesStateModule {}
