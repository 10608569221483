import { Action } from '@ngrx/store';
import { ProjectField } from 'src/app/models/admin/projects/project-field';
import { HttpErrorResponse } from '@angular/common/http';
import { ProjectSetting } from '../../../models/projects/project-settings';

export enum ProjectFieldsActionTypes {
  SetCurrentProjectField = '[projectFields] Set Current ProjectField',
  SelectProjectFieldId = '[ProjectFields] Select ProjectField ID',
  ClearProjectFieldId = '[ProjectFields] Clear Selected ProjectField ID',
  LoadProjectFields = '[ProjectFields] Load ProjectFields',
  LoadProjectFieldsSuccess = '[ProjectFields] Load ProjectFields Success',
  LoadProjectFieldsFailure = '[ProjectFields] Load ProjectFields Failure',
  LoadProjectFieldsSetting = '[ProjectSettings] Load ProjectSettings',
  LoadProjectFieldsSettingSuccess = '[ProjectSettings] Load ProjectSettings Success',
  LoadProjectFieldsSettingFailure = '[ProjectSettings] Load ProjectSettings Failure',
  CreateProjectFields = '[ProjectFields] Create ProjectFields',
  CreateProjectFieldsSuccess = '[ProjectFields] Create ProjectFields Success',
  CreateProjectFieldsFailure = '[ProjectFields] Create ProjectFields Failure',
  UpdateProjectFields = '[ProjectFields] Update ProjectFields',
  UpdateProjectFieldsSuccess = '[ProjectFields] Update ProjectFields Success',
  UpdateProjectFieldsFailure = '[ProjectFields] Update ProjectFields Failure',
  UpdateProjectFieldsSetting = '[ProjectSettings] Update ProjectSettings',
  UpdateProjectFieldsSettingSuccess = '[ProjectSettings] Update ProjectSettings Success',
  UpdateProjectFieldsSettingFailure = '[ProjectSettings] Update ProjectSettings Failure',
  DeleteProjectFields = '[ProjectFields] Delete ProjectFields',
  DeleteProjectFieldsSuccess = '[ProjectFields] Delete ProjectFields Success',
  DeleteProjectFieldsFailure = '[ProjectFields] Delete ProjectFields Failure'
}

export class SetCurrentProjectField implements Action {
  readonly type = ProjectFieldsActionTypes.SetCurrentProjectField;
  constructor(public payload: ProjectField) {}
}

export class SelectProjectFieldId implements Action {
  readonly type = ProjectFieldsActionTypes.SelectProjectFieldId;
  constructor(public payload: number) {}
}

export class ClearProjectFieldId implements Action {
  readonly type = ProjectFieldsActionTypes.ClearProjectFieldId;
}

export class LoadProjectFields implements Action {
  readonly type = ProjectFieldsActionTypes.LoadProjectFields;
}

export class LoadProjectFieldsSuccess implements Action {
  readonly type = ProjectFieldsActionTypes.LoadProjectFieldsSuccess;
  constructor(public payload: ProjectField[]) {}
}

export class LoadProjectFieldsFailure implements Action {
  readonly type = ProjectFieldsActionTypes.LoadProjectFieldsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadProjectFieldsSetting implements Action {
  readonly type = ProjectFieldsActionTypes.LoadProjectFieldsSetting;
}

export class LoadProjectFieldsSettingSuccess implements Action {
  readonly type = ProjectFieldsActionTypes.LoadProjectFieldsSettingSuccess;
  constructor(public payload: ProjectSetting) {}
}

export class LoadProjectFieldsSettingFailure implements Action {
  readonly type = ProjectFieldsActionTypes.LoadProjectFieldsSettingFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class CreateProjectFields implements Action {
  readonly type = ProjectFieldsActionTypes.CreateProjectFields;
  constructor(public payload: ProjectField) {}
}

export class CreateProjectFieldsSuccess implements Action {
  readonly type = ProjectFieldsActionTypes.CreateProjectFieldsSuccess;
  constructor(public payload: ProjectField) {}
}

export class CreateProjectFieldsFailure implements Action {
  readonly type = ProjectFieldsActionTypes.CreateProjectFieldsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class UpdateProjectFields implements Action {
  readonly type = ProjectFieldsActionTypes.UpdateProjectFields;
  constructor(public payload: ProjectField) {}
}

export class UpdateProjectFieldsSuccess implements Action {
  readonly type = ProjectFieldsActionTypes.UpdateProjectFieldsSuccess;
  constructor(public payload: ProjectField) {}
}

export class UpdateProjectFieldsFailure implements Action {
  readonly type = ProjectFieldsActionTypes.UpdateProjectFieldsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class UpdateProjectFieldsSetting implements Action {
  readonly type = ProjectFieldsActionTypes.UpdateProjectFieldsSetting;
  constructor(public payload: ProjectSetting) {}
}

export class UpdateProjectFieldsSettingSuccess implements Action {
  readonly type = ProjectFieldsActionTypes.UpdateProjectFieldsSettingSuccess;
  constructor(public payload: ProjectSetting) {}
}

export class UpdateProjectFieldsSettingFailure implements Action {
  readonly type = ProjectFieldsActionTypes.UpdateProjectFieldsSettingFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class DeleteProjectFields implements Action {
  readonly type = ProjectFieldsActionTypes.DeleteProjectFields;
  constructor(public payload: number) {}
}

export class DeleteProjectFieldsSuccess implements Action {
  readonly type = ProjectFieldsActionTypes.DeleteProjectFieldsSuccess;
  constructor(public payload: number) {}
}

export class DeleteProjectFieldsFailure implements Action {
  readonly type = ProjectFieldsActionTypes.DeleteProjectFieldsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export type ProjectFieldsActions =
  | SetCurrentProjectField
  | LoadProjectFields
  | LoadProjectFieldsSuccess
  | LoadProjectFieldsFailure
  | LoadProjectFieldsSetting
  | LoadProjectFieldsSettingSuccess
  | LoadProjectFieldsSettingFailure
  | CreateProjectFields
  | CreateProjectFieldsSuccess
  | CreateProjectFieldsFailure
  | UpdateProjectFields
  | UpdateProjectFieldsSuccess
  | UpdateProjectFieldsFailure
  | UpdateProjectFieldsSetting
  | UpdateProjectFieldsSettingSuccess
  | UpdateProjectFieldsSettingFailure
  | DeleteProjectFields
  | DeleteProjectFieldsSuccess
  | DeleteProjectFieldsFailure
  | SelectProjectFieldId
  | ClearProjectFieldId;
