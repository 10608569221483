<mat-table [style.width]="'300px'" [dataSource]="$columns | async">
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
    <mat-cell *matCellDef="let column">{{ column.displayName }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="selected">
    <mat-header-cell *matHeaderCellDef fxLayoutAlign="end center"
      >Add / Remove</mat-header-cell
    >
    <mat-cell *matCellDef="let column" fxLayoutAlign="end center">
      <mat-checkbox
        [checked]="column.selected"
        (change)="onCheckboxChanged(column, $event)"
      >
      </mat-checkbox>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let column; columns: displayedColumns"></mat-row>
</mat-table>
