import { CallNotesActions, CallNotesActionTypes } from './call-notes.actions';
import { GenericApi } from 'src/app/models/generic-api';
import { CallNote } from 'src/app/models/call-notes/call-note';
import { GenericMap } from 'src/app/models/generic-map';
import { Comment } from 'src/app/models/call-notes/comment';

export interface CallNotesState extends GenericApi<GenericMap<CallNote>> {
  selectedCallNoteId: number;
  pendingIndividual: boolean;
  byCompanyId: GenericMap<GenericApi<GenericMap<CallNote>>>;
  byCompanyIdWithFollowUps: GenericMap<GenericApi<GenericMap<CallNote>>>;
  commentsByCallNoteId: GenericMap<GenericApi<Comment[]>>;
  audioByCallNoteId: GenericMap<GenericApi<any>>;
}

export const initialState: CallNotesState = {
  pending: false,
  error: undefined,
  selectedCallNoteId: null,
  data: {},
  pendingIndividual: false,
  byCompanyId: {},
  byCompanyIdWithFollowUps: {},
  commentsByCallNoteId: {},
  audioByCallNoteId: {}
};

export function callNotesReducer(
  state = initialState,
  action: CallNotesActions
): CallNotesState {
  switch (action.type) {
    case CallNotesActionTypes.SelectCallNoteId: {
      return {
        ...state,
        selectedCallNoteId: action.payload
      };
    }

    case CallNotesActionTypes.LoadCallNotes:
      return {
        ...state,
        pending: true,
        error: undefined
      };

    case CallNotesActionTypes.LoadCallNotesSuccess:
      return {
        ...state,
        pending: false,
        error: undefined,
        data: {
          ...state.data,
          ...action.payload
        }
      };

    case CallNotesActionTypes.LoadCallNoteComments:
      return {
        ...state,
        commentsByCallNoteId: {
          ...state.commentsByCallNoteId,
          [action.payload.toString()]: {
            ...state.commentsByCallNoteId[action.payload.toString()],
            pending: true,
            error: null
          }
        }
      };

    case CallNotesActionTypes.LoadCallNoteCommentsSuccess:
      return {
        ...state,
        commentsByCallNoteId: {
          ...state.commentsByCallNoteId,
          [action.payload.callNoteId.toString()]: {
            pending: false,
            error: null,
            data: action.payload.comments
          }
        }
      };
    case CallNotesActionTypes.LoadCallNoteCommentsFailure:
      return {
        ...state,
        commentsByCallNoteId: {
          ...state.commentsByCallNoteId,
          [action.payload.callNoteId.toString()]: {
            pending: false,
            error: action.payload.error,
            data: []
          }
        }
      };

    case CallNotesActionTypes.LoadCallNoteAudioById:
      return {
        ...state,
        audioByCallNoteId: {
          ...state.audioByCallNoteId,
          [action.payload.toString()]: {
            ...state.audioByCallNoteId[action.payload.toString()],
            pending: true,
            error: null
          }
        }
      };

    case CallNotesActionTypes.LoadCallNoteAudioByIdSuccess:
      return {
        ...state,
        audioByCallNoteId: {
          ...state.audioByCallNoteId,
          [action.payload.callNoteId.toString()]: {
            pending: false,
            error: null,
            data: action.payload.audio
          }
        }
      };
    case CallNotesActionTypes.LoadCallNoteAudioByIdFailure:
      return {
        ...state,
        audioByCallNoteId: {
          ...state.audioByCallNoteId,
          [action.payload.callNoteId.toString()]: {
            pending: false,
            error: action.payload.error,
            data: ''
          }
        }
      };

    case CallNotesActionTypes.LoadCallNoteSuccess:
      return {
        ...state,
        pendingIndividual: false,
        data: {
          ...state.data,
          [action.payload.id]: action.payload
        },
        byCompanyId: {
          ...state.byCompanyId,
          [action.payload.company.id]: {
            ...state.byCompanyId[action.payload.company.id],
            data: {
              ...(!!state.byCompanyId[action.payload.company.id]
                ? state.byCompanyId[action.payload.company.id].data
                : {}),
              [action.payload.id]: action.payload
            }
          }
        },
        byCompanyIdWithFollowUps: {
          ...state.byCompanyIdWithFollowUps,
          [action.payload.company.id]: {
            ...state.byCompanyIdWithFollowUps[action.payload.company.id],
            data: {
              ...(!!state.byCompanyIdWithFollowUps[action.payload.company.id]
                ? state.byCompanyIdWithFollowUps[action.payload.company.id].data
                : {}),
              [action.payload.id]: action.payload
            }
          }
        }
      };

    case CallNotesActionTypes.LoadCallNotesFailure:
      return { ...state, pending: false, error: action.payload };

    case CallNotesActionTypes.DeleteCallNoteSuccess: {
      return {
        ...state,
        pendingIndividual: false,
        data: {
          ...state.data,
          [action.payload.callNoteId]: undefined
        },
        byCompanyId: {
          ...state.byCompanyId,
          [action.payload.companyId]: {
            ...state.byCompanyId[action.payload.companyId],
            data: {
              ...(state.byCompanyId[action.payload.companyId]
                ? state.byCompanyId[action.payload.companyId].data
                : {}),
              [action.payload.callNoteId]: undefined
            }
          }
        },
        byCompanyIdWithFollowUps: {
          ...state.byCompanyIdWithFollowUps,
          [action.payload.companyId]: {
            ...state.byCompanyIdWithFollowUps[action.payload.companyId],
            data: {
              ...(state.byCompanyIdWithFollowUps[action.payload.companyId]
                ? state.byCompanyIdWithFollowUps[action.payload.companyId].data
                : {}),
              [action.payload.callNoteId]: undefined
            }
          }
        }
      };
    }

    case CallNotesActionTypes.DeleteCallNote:
    case CallNotesActionTypes.SaveCallNote: {
      return {
        ...state,
        pendingIndividual: true
      };
    }

    case CallNotesActionTypes.SaveCallNoteFailure:
    case CallNotesActionTypes.DeleteCallNoteFailure:
      return {
        ...state,
        pendingIndividual: false
      };

    case CallNotesActionTypes.SaveCallNoteSuccess:
      return {
        ...state,
        pendingIndividual: false,
        data: {
          ...state.data,
          [action.payload.id]: action.payload
        },
        byCompanyId: {
          ...state.byCompanyId,
          [action.payload.company.id]: {
            ...state.byCompanyId[action.payload.company.id],
            data: {
              ...(!!state.byCompanyId[action.payload.company.id]
                ? state.byCompanyId[action.payload.company.id].data
                : {}),
              [action.payload.id]: action.payload
            }
          }
        },
        byCompanyIdWithFollowUps: {
          ...state.byCompanyIdWithFollowUps,
          [action.payload.company.id]: {
            ...state.byCompanyIdWithFollowUps[action.payload.company.id],
            data: {
              ...(!!state.byCompanyIdWithFollowUps[action.payload.company.id]
                ? state.byCompanyIdWithFollowUps[action.payload.company.id].data
                : {}),
              [action.payload.id]: action.payload
            }
          }
        }
      };

    case CallNotesActionTypes.LoadCallNotesByCompanyId:
      return {
        ...state,
        byCompanyId: {
          ...state.byCompanyId,
          [action.payload.companyId]: {
            pending: true,
            error: undefined,
            count: undefined,
            data: {
              ...(state.byCompanyId[action.payload.companyId]
                ? state.byCompanyId[action.payload.companyId].data
                : {})
            }
          }
        }
      };

    case CallNotesActionTypes.LoadCallNotesByCompanyIdSuccess:
      return {
        ...state,
        byCompanyId: {
          ...state.byCompanyId,
          [action.payload.companyId]: {
            pending: false,
            error: undefined,
            count: action.payload.count,
            data: {
              ...(state.byCompanyId[action.payload.companyId]
                ? state.byCompanyId[action.payload.companyId].data
                : {}),
              ...action.payload.data
            }
          }
        }
      };

    case CallNotesActionTypes.LoadCallNotesByCompanyIdFailure:
      return {
        ...state,
        byCompanyId: {
          ...state.byCompanyId,
          [action.payload.companyId]: {
            pending: false,
            error: action.payload.error,
            count: undefined,
            data: {}
          }
        }
      };

    case CallNotesActionTypes.LoadCallNotesByCompanyIdWithFollowUps:
      return {
        ...state,
        byCompanyIdWithFollowUps: {
          ...state.byCompanyIdWithFollowUps,
          [action.payload.companyId]: {
            pending: true,
            error: undefined,
            count: undefined,
            data: {
              ...(state.byCompanyIdWithFollowUps[action.payload.companyId]
                ? state.byCompanyIdWithFollowUps[action.payload.companyId].data
                : {})
            }
          }
        }
      };

    case CallNotesActionTypes.LoadCallNotesByCompanyIdWithFollowUpsSuccess:
      return {
        ...state,
        byCompanyIdWithFollowUps: {
          ...state.byCompanyIdWithFollowUps,
          [action.payload.companyId]: {
            pending: false,
            error: undefined,
            count: action.payload.count,
            data: {
              ...(state.byCompanyIdWithFollowUps[action.payload.companyId]
                ? state.byCompanyIdWithFollowUps[action.payload.companyId].data
                : {}),
              ...action.payload.data
            }
          }
        }
      };

    case CallNotesActionTypes.LoadCallNotesByCompanyIdWithFollowUpsFailure:
      return {
        ...state,
        byCompanyIdWithFollowUps: {
          ...state.byCompanyIdWithFollowUps,
          [action.payload.companyId]: {
            pending: false,
            error: action.payload.error,
            count: undefined,
            data: {}
          }
        }
      };

    case CallNotesActionTypes.ClearCallNotesByCompanyId:
      return {
        ...state,
        byCompanyId: {
          ...state.byCompanyId,
          [action.payload]: {
            pending: true,
            error: undefined,
            count: undefined,
            data: {}
          }
        }
      };

    case CallNotesActionTypes.ClearCallNotesByCompanyIdWithFollowUps:
      return {
        ...state,
        byCompanyIdWithFollowUps: {
          ...state.byCompanyIdWithFollowUps,
          [action.payload]: {
            pending: true,
            error: undefined,
            count: undefined,
            data: {}
          }
        }
      };

    default:
      return state;
  }
}
