<div class="container" fxLayout="column">
  <div fxFlex="5" fxLayoutAlign="end start">
    <button mat-icon-button class="mr" (click)="closeDrawer()">
      Close <mat-icon>close</mat-icon>
    </button>
  </div>
  <section>
    <section
      class="call-note-record"
      #companySearch
      fxLayout="column"
      fxLayoutAlign="start center"
      *ngIf="pageCount === 0"
    >
      <div fxLayout="column">
        <h1>Select a Company</h1>

        <mat-form-field class="company-select-form-field search">
          <mat-icon class="search-icon" matPrefix>search</mat-icon>
          <input
            type="text"
            placeholder="Search Your Companies"
            matInput
            autocomplete="off"
            autofocus="true"
            #companySearchInput
            [formControl]="companySearchCtrl"
          />
          <span class="loading-icon" matSuffix>
            <mat-spinner *ngIf="$pending | async" diameter="16"></mat-spinner>
            <mat-icon *ngIf="$error | async" color="warn">error</mat-icon>
          </span>
        </mat-form-field>
        <mat-list *ngIf="companies.length" class="search list">
          <div
            *ngFor="let company of companies"
            fxLayout="column"
            (click)="setCompany(company.id)"
          >
            <mat-divider></mat-divider>
            <div class="list-item">
              <p>{{ company.name }}</p>
              <p class="address">
                {{ company.address.addressLine1 }}, {{ company.address.city }},
                {{ company.address.state }}, {{ company.address.postCode }}
              </p>
            </div>
          </div>
        </mat-list>
      </div>
      <div class="fixed">
        <button mat-raised-button class="add-company" (click)="openCompanyDrawer()">
          Add New Company
        </button>
      </div>
    </section>
    <section
      #opportunitySelect
      *ngIf="pageCount === 1 && opportunitySelect"
      fxLayout="column"
      fxLayoutAlign="start center"
    >
      <h1>Select an Opportunity</h1>
      <mat-list class="search list">
        <mat-list-item
          *ngFor="let opportunity of opportunities"
          class="list-item"
          (click)="setOpportunity(opportunity.id)"
        >
          <mat-divider></mat-divider>
          {{ opportunity.name }}</mat-list-item
        >
      </mat-list>
    </section>
    <section
      #callTypeSelect
      *ngIf="pageCount === 2"
      fxLayout="column"
      fxLayoutAlign="start center"
    >
      <h1>Select a Call Type</h1>
      <mat-list class="search list">
        <mat-list-item
          *ngFor="let callType of callTypes"
          class="list-item"
          (click)="setCallType(callType.id)"
        >
          <mat-divider></mat-divider>
          {{ callType.name }}</mat-list-item
        >
      </mat-list>
    </section>
    <!-- TODO Fields Select -->
    <!-- <section #fieldsSelect *ngIf="pageCount === 2" fxLayout="column"
      fxLayoutAlign="start center">
        <mat-list class="search">
            <mat-list-item
                *ngFor="let field of callNoteFields"
                class="list-item"
            >
              <mat-divider></mat-divider>
              <div (click)="addRemoveField(field.id)" fxLayout="row" fxFlex fxLayoutAlign="space-between center">
                <p>{{ field.name }}</p>
                <input type="checkbox" [checked]="checked(field.id)">
              </div></mat-list-item>
        </mat-list>
      </section> -->
    <section
      #callNoteRecord
      class="call-note-record"
      *ngIf="pageCount === 3"
      fxLayout="column"
      fxLayout="center space-between"
    >
      <h1>Start Recording</h1>
      <tn-record-call-note
        [companyId]="companyId"
        (audioOutput)="handleAudioEmit($event)"
        (clearAudio)="clearAudio()"
      ></tn-record-call-note>
      <div class="list" [ngClass]="recordingTouched === true ? 'visible' : 'hidden'">
        <button
          mat-raised-button
          color="primary"
          (click)="saveCallNote()"
          [hidden]="recordingTouched"
        >
          Save
        </button>
      </div>
    </section>
  </section>
  <section
    #controls
    class="fixed"
    *ngIf="pageCount !== 0"
    fxFlex="15"
    fxLayoutAlign="space-between end"
  >
    <button class="controls" mat-icon-button (click)="back()">
      <mat-icon>arrow_back</mat-icon>Back
    </button>
    <button class="controls" mat-icon-button (click)="skip()" *ngIf="pageCount === 1">
      Skip<mat-icon>arrow_forward</mat-icon>
    </button>
    <!-- TODO: Fields controls -->
    <!-- <button class="controls bg-orange" mat-raised-button (click)="setFields()" *ngIf="pageCount === 2 && selectedFields.length">Submit Fields</button> -->
  </section>
</div>
