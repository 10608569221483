<!--<file-upload-popup *ngIf="fileUploadPopup" [(fileUploadPopup)]="fileUploadPopup" [(companyFiles)]="companyFiles" [(callNoteId)]="callNote.CallNoteId"
    [(file)]="file" [(companyId)]="callNote.CompanyId"></file-upload-popup>-->
<loading-popup *ngIf="isSaving" [isLoading]="isSaving" [message]="savingMessage"></loading-popup>
<loading-popup *ngIf="isDeleting" [isLoading]="isDeleting" [message]="deletingMessage"></loading-popup>

<dx-popup [position]="{ my: 'right', at: 'right', of: window }" [width]="600" [height]="'100vh'" [showTitle]="false"
	[dragEnabled]="false" [closeOnOutsideClick]="false" [(visible)]="callNotePopup" shadingColor="rgba(0,0,0,0.5)">
	<div *dxTemplate="let data of 'content'">
		<div fxLayout="column" style="height: calc(100% - 50px);">
			<dx-scroll-view [height]="'inherit'">
				<div fxLayout="row" fxFlex>
					<div fxFlex="4"></div>
					<div class="popup-title" fxLayoutAlign="start center" fxFlex>
						<div *ngIf="callNote && !callNote.id">New Call Note</div>
						<div *ngIf="callNote && callNote.id">Edit Call Note</div>
					</div>
					<div *ngIf="callNote && callNote.id" fxLayoutAlign="end center" class="text-small text-muted">
						<span fxLayoutAlign="end center">
							<mat-icon>account_circle</mat-icon>
							{{callNote.createdBy.firstName}}&nbsp;{{callNote.createdBy.lastName}}
						</span>
						<span fxLayoutAlign="end center" class="check-box-text-padding">
							<mat-icon>schedule</mat-icon>{{callNote.createdDate | date:'medium'}}
						</span>
						<span fxLayoutAlign="end center" class="check-box-text-padding">
							<strong>ID</strong>: {{callNote.id}}</span>
					</div>
				</div>
				<!-- <div fxLayout="row">
					<div fxFlex></div>
					<div *ngIf="editCallNotesFeature==1" class="button button-white" style="padding-bottom: 7px;" fxLayoutAlign="end center"
					 (click)="uploadFile()">Upload File</div>
					<div fxFlex="4"></div>
				</div> -->
				<call-note-details [(editCallNotesFeature)]="editCallNotesFeature" [(contactsFeature)]="contactsFeature"
					[(companyId)]="companyId" [(callNote)]="callNote" [(contacts)]="contacts" [(companies)]="companies"
					[(contactsInList)]="contactsInList" [(contactsList)]="contactsList"
					[(contactTagBoxDisplayed)]="contactTagBoxDisplayed" [(clickEditCompany)]="clickEditCompany"
					[(companySelectBoxDisplayed)]="companySelectBoxDisplayed" [(displayedCompany)]="displayedCompany">
				</call-note-details>
				<call-note-follow-up [(editCallNotesFeature)]="editCallNotesFeature" [(callNote)]="callNote"
					[(assignTasksFeature)]="assignTasksFeature" [companyAgents]="companyAgents"></call-note-follow-up>
				<call-note-comments [(callNote)]="callNote" [(callNotes)]="callNotes"
					[(editCallNotesFeature)]="editCallNotesFeature" [companyAgents]="companyAgents"
					[(newComment)]="newComment" [(commentsExpanded)]="commentsExpanded"
					[(addNewComment)]="addNewComment"></call-note-comments>
			</dx-scroll-view>
		</div>
		<div fxLayout="row" fxLayoutAlign="end end">
			<span *ngIf="callNote && callNote.id && editCallNotesFeature==1" class="button button-red"
				(click)="deleteCallNotePopup=true">Delete</span>
			<span *ngIf="editCallNotesFeature==0" class="button button-white" (click)="closeCallNote()">Close</span>
			<div fxFlex fxLayoutAlign="end end">
				<span *ngIf="editCallNotesFeature==1 && callNote && callNote.id" class="button button-white"
					(click)="addComment()">Comment</span>
				<span *ngIf="editCallNotesFeature==1" class="button button-white"
					(click)="shareNoteDialog=true">Share</span>
				<span *ngIf="editCallNotesFeature==1" class="button button-white"
					(click)="cancelCallNote(callNote)">Cancel</span>
				<span *ngIf="editCallNotesFeature==1" class="button button-blue" (click)="saveCallNote()">Save</span>
			</div>
		</div>
	</div>
</dx-popup>

<dx-popup [width]="310" [height]="150" [showTitle]="false" [dragEnabled]="false" [closeOnOutsideClick]="false"
	[(visible)]="cancelCallNotePopup" shadingColor="rgba(0,0,0,0.5)">
	<div *dxTemplate="let data of 'content'">
		<div fxLayout="column" fxFlex>
			<h3>Are you sure you want to cancel?</h3>
			<div fxLayout="row" fxLayoutAlign="center center">
				<span class="button button-white" (click)="cancelCallNotePopup=false">No</span>
				<span class="button button-red" (click)="callNoteCancelled()">Yes</span>
			</div>
		</div>
	</div>
</dx-popup>

<dx-popup [width]="310" [height]="150" [showTitle]="false" [dragEnabled]="false" [closeOnOutsideClick]="false"
	[(visible)]="deleteCallNotePopup" shadingColor="rgba(0,0,0,0.5)">
	<div *dxTemplate="let data of 'content'">
		<div fxLayout="column" fxFlex>
			<h3>Are you sure you want to delete?</h3>
			<div fxLayout="row" fxLayoutAlign="center center">
				<span class="button button-white" (click)="deleteCallNotePopup=false">No</span>
				<span class="button button-red" (click)="callNoteDeleted(callNote)">Yes</span>
			</div>
		</div>
	</div>
</dx-popup>

<dx-popup [position]="{ my: 'right', at: 'right', of: window }" [width]="600" [height]="'100vh'" [showTitle]="false"
	[dragEnabled]="false" [closeOnOutsideClick]="false" [(visible)]="shareNoteDialog" shadingColor="rgba(0,0,0,0.5)">
	<div *dxTemplate="let data of 'content'">
		<div fxLayout="column" style="height: calc(100% - 50px);">
			<h3>Share Call Note</h3>
			<div fxLayout="row" fxLayoutAlign="center center">
				<dx-tag-box #shareCallNoteTagBox [width]="'100%'" [items]="emailList" displayExpr="EmailDisplay"
					valueExpr="Email" [(value)]="callNote.shareWithEmails" [acceptCustomValue]="true"
					[searchEnabled]="true" (onKeyDown)="shareCallNoteKeyDown($event)" [hideSelectedItems]="true"
					placeholder="example@example.com">
				</dx-tag-box>
			</div>
		</div>
		<div fxLayout="row" fxLayoutAlign="end center">
			<span class="button button-blue" (click)="shareNoteDialog=false">Done</span>
		</div>
	</div>
</dx-popup>

<dx-popup [width]="375" [height]="150" [showTitle]="false" [dragEnabled]="false" [closeOnOutsideClick]="true"
	[visible]="anotherNotePopup" shadingColor="rgba(0,0,0,0.5)">
	<div *dxTemplate="let data of 'content'">
		<div>
			<h3>Would you like to make another call note?</h3>
		</div>
		<div fxLayout="row" fxLayoutAlign="center center">
			<span fxLayoutAlign="end center" class="button button-white" (click)="notNewNote()">No</span>
			<span fxLayoutAlign="end center" class="button button-blue" (click)="addAnotherNote()">Yes</span>
		</div>
	</div>
</dx-popup>