import { RootState } from '../../store.reducer';
import { MemoizedSelector, createSelector } from '@ngrx/store';
import { GenericMap } from 'src/app/models/generic-map';
import { selectAllPlans } from './all-plans-as-array.selector';
import { Feature } from 'src/app/app-v2/shared/models/admin/feature';
import { Plans } from 'src/app/models/admin/plans/plans';

export const selectedPlanId = (state: RootState) => state.plans.selectedPlanId;

export const selectSelectedPlan: MemoizedSelector<RootState, Plans> = createSelector(
  selectAllPlans,
  selectedPlanId,
  (planMap: GenericMap<Plans>, planId: number): Plans => {
    let Plan: Plans;
    if (!!planId) {
      const selectedPlan = planMap[planId];
      const id = planId.toString();
      Plan = {
        id: selectedPlan.id,
        name: selectedPlan.name,
        description: selectedPlan.description,
        features: selectedPlan.features,
        dashboard: selectedPlan.features.some((dash: Feature) => dash.featureId === 6),
        excede: selectedPlan.features.some((dash: Feature) => dash.featureId === 7),
        sales: selectedPlan.features.some((dash: Feature) => dash.featureId === 2),
        maps: selectedPlan.features.some((dash: Feature) => dash.featureId === 3),
        maddenCo: selectedPlan.features.some((dash: Feature) => dash.featureId === 12),
        projects: selectedPlan.features.some((dash: Feature) => dash.featureId === 13),
        rigDig: selectedPlan.features.some((dash: Feature) => dash.featureId === 22),
        iSQuote: selectedPlan.features.some((dash: Feature) => dash.featureId === 25),
        opportunities: selectedPlan.features.some(
          (dash: Feature) => dash.featureId === 20
        )
      };
    }
    return Plan;
  }
);
