<div
  class="comment-container"
  fxLayout="row"
  fxLayoutGap="8px"
  (mouseenter)="setDeleteVisibility(true)"
  (mouseleave)="setDeleteVisibility(false)"
>
  <div fxLayout="column" fxFlex="100">
    <div class="comment-name">
      {{
        !!comment.createdBy
          ? comment.createdBy.firstName + ' ' + comment.createdBy.lastName
          : ''
      }}
    </div>
    <div class="comment-body" [innerHTML]="comment.value"></div>
    <div class="comment-date">{{ comment.createdDate | date: 'medium' }}</div>
  </div>
  <div *ngIf="showDelete">
    <button mat-icon-button color="warn" (click)="deleteHandler()">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</div>
