import { MemoizedSelector, createSelector } from '@ngrx/store';
import { RootState } from '../../../store.reducer';
import { ProjectLabel } from '../../../../models/admin/projects/project-label';
import { selectAllProjectLabels } from './all-projectLabels-as-array.selector';
import { LabelGroup } from 'src/app/models/label-group';

export const selectedProjectLabelId = (state: RootState) =>
  state.projectLabels.selectedProjectLabelId;

export const selectSelectedProjectLabel: MemoizedSelector<
  RootState,
  LabelGroup
> = createSelector(
  selectAllProjectLabels,
  selectedProjectLabelId,
  (projectLabelMap: { [id: number]: LabelGroup }, projectLabelId: number): LabelGroup => {
    let projectLabel: LabelGroup;
    if (!!projectLabelId) {
      const id = projectLabelId.toString();
      projectLabel = {
        ...projectLabelMap[id]
      };
    }
    return projectLabel;
  }
);
