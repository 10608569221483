import { CallTypesActions, CallTypesActionTypes } from './call-types.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { CallType } from 'src/app/models/admin/call-type/call-type';

export interface CallTypesState {
  pending: boolean;
  error: HttpErrorResponse;
  callTypes: CallType[];
  currentCallType: CallType;
}

export const initialState: CallTypesState = {
  pending: false,
  callTypes: [],
  error: undefined,
  currentCallType: undefined
};

export function callTypesReducer(
  state = initialState,
  action: CallTypesActions
): CallTypesState {
  switch (action.type) {
    case CallTypesActionTypes.SetCurrentCallType:
      return {
        ...state,
        currentCallType: action.payload
      };

    case CallTypesActionTypes.ClearCurrentCallType:
      return {
        ...state,
        currentCallType: undefined
      };

    case CallTypesActionTypes.LoadCallTypes:
      return {
        ...state,
        pending: true
      };

    case CallTypesActionTypes.LoadCallTypesSuccess:
      return {
        ...state,
        pending: false,
        callTypes: action.payload
      };

    case CallTypesActionTypes.LoadCallTypesFailure:
      return {
        ...state,
        pending: false,
        callTypes: [],
        error: action.payload
      };

    case CallTypesActionTypes.CreateCallTypes:
      return {
        ...state,
        pending: true
      };

    case CallTypesActionTypes.CreateCallTypesSuccess:
      return {
        ...state,
        pending: false,
        callTypes: [...state.callTypes, action.payload]
      };

    case CallTypesActionTypes.CreateCallTypesFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case CallTypesActionTypes.UpdateCallTypes:
      return {
        ...state,
        pending: true
      };

    case CallTypesActionTypes.UpdateCallTypesSuccess:
      const updatedCallTypes = state.callTypes.map(callType =>
        action.payload.id === callType.id ? action.payload : callType
      );
      return {
        ...state,
        pending: false,
        callTypes: updatedCallTypes
      };

    case CallTypesActionTypes.UpdateCallTypesFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case CallTypesActionTypes.DeleteCallTypes:
      return {
        ...state,
        pending: true
      };

    case CallTypesActionTypes.DeleteCallTypesSuccess:
      return {
        ...state,
        pending: false,
        callTypes: state.callTypes.filter(callType => callType.id !== action.payload),
        currentCallType: undefined
      };

    case CallTypesActionTypes.DeleteCallTypesFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    default:
      return state;
  }
}
