// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// cspell:disable
export const environment = {
  production: false,
  staging: false,
  hmr: false,
  apiUrl: 'https://intapi.telenotes.com',
  jpiUrl: 'https://balancerdevjpi.telenotes.com',
  elasticSyncUrl: 'https://develasticsync.telenotes.com',
  /* cspell: disable-next-line */
  nylasClientId: '9xjwoxedpzrwi00hsgt1gnwwu',
  nylasCalendarClientId: '27o98tt44mpdz56spigtkdog4',
  mapsApiKey: 'AIzaSyBvYdM8BBpF5Ma4E7cFTwxAnex2FDxPPRk',
  classicUrl: 'beta.telenotes.net',
  oktaClientId: '0oaol3m1iINi8jKGj4x6',
  sentry_dsn:
    'https://f7808464478f489185ed87ad6dc8329b@o4504126276763648.ingest.sentry.io/4504126325260288'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
