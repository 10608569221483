import { Action } from '@ngrx/store';
import { Label } from 'src/app/models/label';
import { HttpErrorResponse } from '@angular/common/http';

export enum OpportunityLabelsIndividualActionTypes {
  SelectOpportunityLabelIndividualId = '[OpportunityLabelsIndividual] Select OpportunityLabelIndividual Id',
  ClearOpportunityLabelIndividualId = '[OpportunityLabelsIndividual] Clear OpportunityLabelIndividual Id',
  LoadOpportunityLabelsIndividuals = '[OpportunityLabelsIndividual] Load OpportunityLabelsIndividuals',
  LoadOpportunityLabelsIndividualsSuccess = '[OpportunityLabelsIndividual] Load OpportunityLabelsIndividuals Success',
  LoadOpportunityLabelsIndividualsFailure = '[OpportunityLabelsIndividual] Load OpportunityLabelsIndividuals Failure',
  CreateOpportunityLabelsIndividual = '[OpportunityLabelsIndividual] Create OpportunityLabelsIndividual',
  CreateOpportunityLabelsIndividualSuccess = '[OpportunityLabelsIndividual] Create OpportunityLabelsIndividual Success',
  CreateOpportunityLabelsIndividualFailure = '[OpportunityLabelsIndividual] Create OpportunityLabelsIndividual Failure',
  UpdateOpportunityLabelsIndividual = '[OpportunityLabelsIndividual] Update OpportunityLabelsIndividual',
  UpdateOpportunityLabelsIndividualSuccess = '[OpportunityLabelsIndividual] Update OpportunityLabelsIndividual Success',
  UpdateOpportunityLabelsIndividualFailure = '[OpportunityLabelsIndividual] Update OpportunityLabelsIndividual Failure',
  DeleteOpportunityLabelsIndividual = '[OpportunityLabelsIndividual] Delete OpportunityLabelsIndividual',
  DeleteOpportunityLabelsIndividualSuccess = '[OpportunityLabelsIndividual] Delete OpportunityLabelsIndividual Success',
  DeleteOpportunityLabelsIndividualFailure = '[OpportunityLabelsIndividual] Delete OpportunityLabelsIndividual Failure',
  BulkUpdateOpportunityLabels = '[OpportunityLabels] Bulk Update Opportunity Labels',
  BulkUpdateOpportunityLabelsSuccess = '[OpportunityLabels] Bulk Update Opportunity Labels Success',
  BulkUpdateOpportunityLabelsFailure = '[OpportunityLabels] Bulk Update Opportunity Labels Failure',
  SetOppLabelListOrder = '[OpportunityLabels] Set Opp Label List Order',
  SetOppLabelListOrderSuccess = '[OpportunityLabels] Set Opp Label List Order Success',
  SetOppLabelListOrderFailure = '[OpportunityLabels] Set Opp Label List Order Failure'
}

export class SelectOpportunityLabelIndividualId implements Action {
  readonly type =
    OpportunityLabelsIndividualActionTypes.SelectOpportunityLabelIndividualId;
  constructor(public payload: number) {}
}

export class ClearOpportunityLabelIndividualId implements Action {
  readonly type =
    OpportunityLabelsIndividualActionTypes.ClearOpportunityLabelIndividualId;
}

export class LoadOpportunityLabelsIndividuals implements Action {
  readonly type = OpportunityLabelsIndividualActionTypes.LoadOpportunityLabelsIndividuals;
  constructor(public payload: number) {}
}

export class LoadOpportunityLabelsIndividualsSuccess implements Action {
  readonly type =
    OpportunityLabelsIndividualActionTypes.LoadOpportunityLabelsIndividualsSuccess;
  constructor(public payload: Label[]) {}
}

export class LoadOpportunityLabelsIndividualsFailure implements Action {
  readonly type =
    OpportunityLabelsIndividualActionTypes.LoadOpportunityLabelsIndividualsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class CreateOpportunityLabelsIndividual implements Action {
  readonly type =
    OpportunityLabelsIndividualActionTypes.CreateOpportunityLabelsIndividual;
  constructor(public payload: { label: Label; groupId: number }) {}
}

export class CreateOpportunityLabelsIndividualSuccess implements Action {
  readonly type =
    OpportunityLabelsIndividualActionTypes.CreateOpportunityLabelsIndividualSuccess;
  constructor(public payload: Label) {}
}

export class CreateOpportunityLabelsIndividualFailure implements Action {
  readonly type =
    OpportunityLabelsIndividualActionTypes.CreateOpportunityLabelsIndividualFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class UpdateOpportunityLabelsIndividual implements Action {
  readonly type =
    OpportunityLabelsIndividualActionTypes.UpdateOpportunityLabelsIndividual;
  constructor(public payload: { label: Label; groupId: number }) {}
}

export class UpdateOpportunityLabelsIndividualSuccess implements Action {
  readonly type =
    OpportunityLabelsIndividualActionTypes.UpdateOpportunityLabelsIndividualSuccess;
  constructor(public payload: Label) {}
}

export class UpdateOpportunityLabelsIndividualFailure implements Action {
  readonly type =
    OpportunityLabelsIndividualActionTypes.UpdateOpportunityLabelsIndividualFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class DeleteOpportunityLabelsIndividual implements Action {
  readonly type =
    OpportunityLabelsIndividualActionTypes.DeleteOpportunityLabelsIndividual;
  constructor(public payload: { groupId: number; id: number }) {}
}

export class DeleteOpportunityLabelsIndividualSuccess implements Action {
  readonly type =
    OpportunityLabelsIndividualActionTypes.DeleteOpportunityLabelsIndividualSuccess;
  constructor(public payload: { groupId: number; id: number }) {}
}

export class DeleteOpportunityLabelsIndividualFailure implements Action {
  readonly type =
    OpportunityLabelsIndividualActionTypes.DeleteOpportunityLabelsIndividualFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class BulkUpdateOpportunityLabels implements Action {
  readonly type = OpportunityLabelsIndividualActionTypes.BulkUpdateOpportunityLabels;
  constructor(public payload: Label[]) {}
}

export class BulkUpdateOpportunityLabelsSuccess implements Action {
  readonly type =
    OpportunityLabelsIndividualActionTypes.BulkUpdateOpportunityLabelsSuccess;
  constructor(public payload: Label[]) {}
}

export class BulkUpdateOpportunityLabelsFailure implements Action {
  readonly type =
    OpportunityLabelsIndividualActionTypes.BulkUpdateOpportunityLabelsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class SetOppLabelListOrder implements Action {
  readonly type = OpportunityLabelsIndividualActionTypes.SetOppLabelListOrder;
  constructor(public payload: { groupId: number; labels: Label[] }) {}
}

export class SetOppLabelListOrderSuccess implements Action {
  readonly type = OpportunityLabelsIndividualActionTypes.SetOppLabelListOrderSuccess;
  constructor(public payload: { groupId: number; labels: Label[] }) {}
}

export class SetOppLabelListOrderFailure implements Action {
  readonly type = OpportunityLabelsIndividualActionTypes.SetOppLabelListOrderFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export type OpportunityLabelsIndividualActions =
  | SelectOpportunityLabelIndividualId
  | ClearOpportunityLabelIndividualId
  | LoadOpportunityLabelsIndividuals
  | LoadOpportunityLabelsIndividualsSuccess
  | LoadOpportunityLabelsIndividualsFailure
  | CreateOpportunityLabelsIndividual
  | CreateOpportunityLabelsIndividualSuccess
  | CreateOpportunityLabelsIndividualFailure
  | UpdateOpportunityLabelsIndividual
  | UpdateOpportunityLabelsIndividualSuccess
  | UpdateOpportunityLabelsIndividualFailure
  | DeleteOpportunityLabelsIndividual
  | DeleteOpportunityLabelsIndividualSuccess
  | DeleteOpportunityLabelsIndividualFailure
  | BulkUpdateOpportunityLabels
  | BulkUpdateOpportunityLabelsSuccess
  | BulkUpdateOpportunityLabelsFailure
  | SetOppLabelListOrder
  | SetOppLabelListOrderSuccess
  | SetOppLabelListOrderFailure;
