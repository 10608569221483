export function queryToObject<T>(queryString: string): T {
  const retObj = {};
  let pair = null;
  // get the query string, omitting the "?"
  const sPageURL = queryString.substring(1);
  // use the ampersand as a separator
  const qArr = sPageURL.split('&');

  // each element in qArr is not a key/val pair
  // so we need to turn each one of these pairs
  // into a two-element array
  qArr.forEach(elem => {
    // use the "=" as a separator
    pair = elem.split('=');
    // pair is now a two-element array
    // so the "key" is the first element of that array
    // and the "val" is the second element
    // so now we just add this "pair" to our return object
    retObj[pair[0]] = pair[1];
  });

  // return the new object
  return retObj as T;
}
