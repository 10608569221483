import { Action } from '@ngrx/store';
import { CompanyAssignment } from 'src/app/models/sales/companyAssignment';
import { HttpErrorResponse } from '@angular/common/http';
import { GenericMap } from 'src/app/models/generic-map';
import { GenericApi } from 'src/app/models/generic-api';
import { SalesSummaryType } from 'src/app/models/sales/sales-summary-type.enum';
import { ShowReportsBy } from 'src/app/models/sales/show-reports-by.enum';
import { SalesSummaryRow } from 'src/app/models/sales/sales-summary-row';
import { SalesSummaryRequest } from 'src/app/models/sales/sales-summary-req';

export enum SalesActionTypes {
  LoadSalesReports = '[Sales] Load Sales Reports',
  LoadSalesReportsSuccess = '[Sales] Load Sales Reports Success',
  LoadSalesReportsFailure = '[Sales] Load Sales Reports Failure',
  LoadCompanyAssignments = '[Sales] Load Company Assignments',
  LoadCompanyAssignmentsSuccess = '[Sales] Load Company Assignments Success',
  LoadCompanyAssignmentsFailure = '[Sales] Load Company Assignments Failure',
  SetCompanyAssignment = '[Sales] Set Company Assignment',
  SetCompanyAssignmentSuccess = '[Sales] Set Company Assignment Success',
  SetCompanyAssignmentFailure = '[Sales] Set Company Assignment Failure',
  ToggleSalesModule = '[Sales] Toggle Sales Module'
}

export class LoadSalesReports implements Action {
  readonly type = SalesActionTypes.LoadSalesReports;
  constructor(public payload: SalesSummaryRequest) {}
}

export class LoadSalesReportsSuccess implements Action {
  readonly type = SalesActionTypes.LoadSalesReportsSuccess;
  constructor(public payload: SalesSummaryRow[]) {}
}

export class LoadSalesReportsFailure implements Action {
  readonly type = SalesActionTypes.LoadSalesReportsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoadCompanyAssignments implements Action {
  readonly type = SalesActionTypes.LoadCompanyAssignments;
  constructor(public payload: number) {}
}

export class LoadCompanyAssignmentsSuccess implements Action {
  readonly type = SalesActionTypes.LoadCompanyAssignmentsSuccess;
  constructor(public payload: GenericMap<GenericMap<GenericApi<CompanyAssignment>>>) {}
}

export class LoadCompanyAssignmentsFailure implements Action {
  readonly type = SalesActionTypes.LoadCompanyAssignmentsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class SetCompanyAssignment implements Action {
  readonly type = SalesActionTypes.SetCompanyAssignment;
  constructor(
    public payload: { companyId: number; agentMailboxId: number; associateSales: boolean }
  ) {}
}

export class SetCompanyAssignmentSuccess implements Action {
  readonly type = SalesActionTypes.SetCompanyAssignmentSuccess;
  constructor(
    public payload: { companyId: number; agentMailboxId: number; associateSales: boolean }
  ) {}
}

export class SetCompanyAssignmentFailure implements Action {
  readonly type = SalesActionTypes.SetCompanyAssignmentFailure;
  constructor(
    public payload: {
      companyId: number;
      agentMailboxId: number;
      error: HttpErrorResponse;
    }
  ) {}
}

export class ToggleSalesModule implements Action {
  readonly type = SalesActionTypes.ToggleSalesModule;
}

export type SalesActions =
  | LoadSalesReports
  | LoadSalesReportsSuccess
  | LoadSalesReportsFailure
  | LoadCompanyAssignments
  | LoadCompanyAssignmentsSuccess
  | LoadCompanyAssignmentsFailure
  | SetCompanyAssignment
  | SetCompanyAssignmentSuccess
  | SetCompanyAssignmentFailure
  | ToggleSalesModule;
