import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Field } from 'src/app/models/call-notes/field';
import { DropdownOption } from 'src/app/models/dropdown-option';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CallNoteCustomFieldsService {
  private jpiUrl = environment.jpiUrl;
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get(`${this.jpiUrl}/callnotefields`);
  }

  getById(callNoteFieldId: number) {
    return this.http.get(`${this.jpiUrl}/callnotefields/${callNoteFieldId}`);
  }

  create(callNoteField: Field) {
    return this.http.post(`${this.jpiUrl}/callnotefields`, callNoteField);
  }

  update(callNoteField: Field) {
    return this.http.put(
      `${this.jpiUrl}/callnotefields/${callNoteField.id}`,
      callNoteField
    );
  }

  delete(callNoteFieldId: number) {
    return this.http.delete(`${this.jpiUrl}/callnotefields/${callNoteFieldId}`);
  }

  getDropdownOptionsByFieldId(callNoteFieldId: number): Observable<DropdownOption[]> {
    return this.http.get(
      `${this.jpiUrl}/callnotefields/${callNoteFieldId}/dropdownfields`
    ) as Observable<DropdownOption[]>;
  }
  createDropdownOption(dropDownOption: DropdownOption): Observable<DropdownOption> {
    return this.http.post(
      `${this.jpiUrl}/callnotefields/${dropDownOption.fieldId}/dropdownfields`,
      dropDownOption
    ) as Observable<DropdownOption>;
  }
  updateDropdownOption(dropDownOption: DropdownOption): Observable<DropdownOption> {
    return this.http.put(
      `${this.jpiUrl}/callnotefields/${dropDownOption.fieldId}/dropdownfields/${dropDownOption.id}`,
      dropDownOption
    ) as Observable<DropdownOption>;
  }
  deleteDropdownOption(dropDownOption: DropdownOption) {
    return this.http.delete(
      `${this.jpiUrl}/callnotefields/${dropDownOption.fieldId}/dropdownfields/${dropDownOption.id}`
    );
  }
  updateDropdownOptionsOrder(
    fieldId: number,
    dropDownOptions: DropdownOption[]
  ): Observable<DropdownOption[]> {
    return this.http.put(
      `${this.jpiUrl}/callnotefields/${fieldId}/dropdownfields/updateListOrder`,
      dropDownOptions
    ) as Observable<DropdownOption[]>;
  }

  getDropdownValues(callNoteId: number) {}
  createDropdownValue(callNoteId: number) {}
  updateDropdownValue(callNoteId: number, callNoteFieldValue) {}
  deleteDropdownValue() {}
}
