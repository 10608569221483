import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  AddMileage,
  UpdateMileage,
  DeleteMileage,
  ClearMileageId
} from 'src/app/store/mileage/mileage.actions';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/store.reducer';
import { CloseDrawer } from 'src/app/store/layout/layout.actions';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { Mileage } from 'src/app/models/activities/mileage';
import { Company } from 'src/app/models/companies/company';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { selectSelectedMileage } from 'src/app/store/mileage/selectors/selected-mileage.selector';
import { IdNameItem } from 'src/app/models/id-name-item';
import { selectIdNameCurrentUser } from 'src/app/store/users/selectors/id-name-current-user.selector';
import { User } from 'src/app/models/admin/users/user';
import { withLatestFrom, map } from 'rxjs/operators';
import { selectMultiSelectUsers } from 'src/app/store/users/selectors/multi-select-users.selector';
import { selectHasMileageAdmin } from 'src/app/store/expenses/selectors/has-mileage-admin.selector';

@Component({
  selector: 'tn-add-mileage',
  templateUrl: './add-mileage.component.html',
  styleUrls: ['./add-mileage.component.css']
})
export class AddMileageComponent implements OnInit, OnDestroy {
  mileageForm: FormGroup;
  $currentMileage: Observable<Mileage>;
  $searchResults: Observable<Company[]>;
  $currentMileageSub: Subscription;
  $searching: Observable<boolean>;
  $currentUser: Observable<User>;
  $allUsers: Observable<IdNameItem[]>;
  $odometerValsSub: Subscription;
  hasOdometerError = true;
  currentMileage: Mileage;
  $hasMileageAdmin: Observable<boolean>;

  constructor(private store: Store<RootState>) {}

  ngOnInit() {
    this.$currentUser = this.store.select(selectIdNameCurrentUser);
    this.$currentMileage = this.store.select(selectSelectedMileage);
    this.$hasMileageAdmin = this.store.select(selectHasMileageAdmin);
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const createdDate = new Date(year, month, day);
    this.mileageForm = new FormGroup({
      agencyId: new FormControl(null),
      odometerStart: new FormControl(null, Validators.required),
      odometerEnd: new FormControl(null, Validators.required),
      mileageDate: new FormControl(createdDate, Validators.required),
      createdById: new FormControl(null),
      assignedToUser: new FormControl(null, Validators.required),
      assignedToId: new FormControl(null),
      createdDate: new FormControl(createdDate)
    });

    this.$allUsers = this.store.select(selectMultiSelectUsers).pipe(
      map((users) => {
        if (!!users.length) {
          return users;
        } else {
          return [];
        }
      })
    );

    this.$odometerValsSub = combineLatest([
      this.mileageForm.controls.odometerStart.valueChanges,
      this.mileageForm.controls.odometerEnd.valueChanges
    ])
      .pipe(
        map((startAndEnd) => ({ start: startAndEnd[0], end: startAndEnd[1] })),
        map(({ start, end }) => {
          if (end === 0) {
            this.hasOdometerError = false;
          } else if (start >= end) {
            this.hasOdometerError = true;
          } else {
            this.hasOdometerError = false;
          }
        })
      )
      .subscribe();

    this.$currentMileageSub = this.$currentMileage
      .pipe(
        withLatestFrom(this.$currentUser),
        map((mileageAndUser) => ({
          mileage: mileageAndUser[0],
          assignedToUser: mileageAndUser[1]
        }))
      )
      .subscribe(({ mileage, assignedToUser }) => {
        if (!!mileage && !!mileage.assignedToUser) {
          this.currentMileage = {
            ...mileage,
            assignedToUser
          };
          this.mileageForm.patchValue({
            mileageDate: mileage.mileageDate,
            odometerStart: mileage.odometerStart,
            odometerEnd: mileage.odometerEnd,
            createdById: mileage.createdById,
            createdDate: mileage.createdDate,
            assignedToUser: mileage.assignedToUser
          });
        } else {
          this.currentMileage = {
            odometerStart: 0,
            odometerEnd: 0,
            assignedToUser,
            assignedToId: assignedToUser.id,
            createdById: assignedToUser.id
          };
          this.mileageForm.patchValue({
            odometerStart: this.currentMileage.odometerStart,
            odometerEnd: this.currentMileage.odometerEnd,
            assignedToUser: this.currentMileage.assignedToUser,
            createdById: this.currentMileage.createdById
          });
        }
      });
    this.mileageForm.markAllAsTouched();
  }

  ngOnDestroy() {
    this.$currentMileageSub.unsubscribe();
    this.$odometerValsSub.unsubscribe();
  }

  save() {
    this.mileageForm.patchValue({
      assignedToId: this.mileageForm.value.assignedToUser.id,
      agencyId: this.mileageForm.value.assignedToUser.agencyId
    });
    if (this.currentMileage.id) {
      this.store.dispatch(
        new UpdateMileage({
          ...this.mileageForm.value,
          id: this.currentMileage.id,
          createdDate: this.currentMileage.createdDate
        })
      );
    } else {
      this.store.dispatch(new AddMileage({ ...this.mileageForm.value }));
    }
    this.store.dispatch(new ClearMileageId());
    this.store.dispatch(new CloseDrawer());
  }

  cancel(): void {
    this.store.dispatch(new ClearMileageId());
    this.store.dispatch(new CloseDrawer());
  }

  delete(id: number): void {
    this.store.dispatch(new CloseDrawer());
    this.store.dispatch(new DeleteMileage(id));
  }

  onUserSelected(itemSelected: IdNameItem) {
    this.mileageForm.markAsDirty();
    this.mileageForm.controls.assignedToUser.setValue(itemSelected);
  }

  userValidation(isValid: boolean) {
    if (!isValid) {
      this.mileageForm.setErrors({ error: 'User must be selected' });
    }
  }

  compareObjects(o1: any, o2: any) {
    return o1.name === o2.name && o1.id && o2.id;
  }
}
