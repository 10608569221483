import { ExpenseTypeV2 } from './../../../models/expense-typeV2';
import { Injectable } from '@angular/core';

import { AuthenticationService } from '../../authentication.service';
import { Observable } from 'rxjs';

@Injectable()
export class ExpenseTypesService {
    constructor(private authService: AuthenticationService) { }

    getAll(): Observable<ExpenseTypeV2[]> {
        return this.authService.makeNewJpiRequest("/expensetypes", null, null, "get").map((expenseTypes) => {
            return JSON.parse(expenseTypes);
        });
    }

    getById(id: number): Observable<ExpenseTypeV2> {
        return this.authService.makeNewJpiRequest(`/expensetypes/${id}`, null, null, "get").map((expenseTypes) => {
            return JSON.parse(expenseTypes);
        });
    }

    create(expenseType: ExpenseTypeV2): Observable<ExpenseTypeV2> {
        return this.authService.makeNewJpiRequest("/expensetypes", null, expenseType, "post").map((expenseTypes) => {
            return JSON.parse(expenseTypes);
        });
    }

    update(id: number, expenseType: ExpenseTypeV2): Observable<ExpenseTypeV2> {
        return this.authService.makeNewJpiRequest(`/expensetypes/${id}`, null, expenseType, "put").map((expenseTypes) => {
            return JSON.parse(expenseTypes);
        });
    }

    delete(expenseType: ExpenseTypeV2): void {
        this.authService.makeJpiRequest(`/expensetypes/${expenseType.id}`, null, expenseType, "delete");
    }
}
