import { LayoutActions, LayoutActionTypes } from './layout.actions';
import { Type } from '@angular/core';

export interface SnackbarState {
  opened: boolean;
  message: string;
  duration: number;
}
export interface DialogItem {
  component: Type<any>;
  data?: any;
}

export interface LayoutState {
  defaultLandingPage: string;
  sidenavOpened: boolean;
  sidenavExpanded: boolean;
  drawerOpened: boolean;
  drawerContents: {
    component: Type<any>;
    data?: any;
  };
  leftDrawerOpened: boolean;
  leftDrawerContents: {
    component: Type<any>;
    data?: any;
  };
  loadingBarVisible: boolean;
  snackbar: SnackbarState;
  errorSnackbar: SnackbarState;
}

export const initialState: LayoutState = {
  defaultLandingPage: null,
  sidenavOpened: true,
  sidenavExpanded: true,
  drawerOpened: false,
  drawerContents: {
    component: undefined,
    data: undefined
  },
  leftDrawerOpened: false,
  leftDrawerContents: {
    component: undefined,
    data: undefined
  },
  loadingBarVisible: false,
  snackbar: {
    opened: false,
    message: undefined,
    duration: undefined
  },
  errorSnackbar: {
    opened: false,
    message: undefined,
    duration: undefined
  }
};

export function layoutReducer(state = initialState, action: LayoutActions): LayoutState {
  switch (action.type) {
    case LayoutActionTypes.OpenSidenav:
      return {
        ...state,
        sidenavOpened: true
      };

    case LayoutActionTypes.CloseSidenav:
      return {
        ...state,
        sidenavOpened: false
      };

    case LayoutActionTypes.ToggleOpenSidenav:
      return {
        ...state,
        sidenavOpened: !state.sidenavOpened
      };

    case LayoutActionTypes.ExpandSidenav:
      return {
        ...state,
        sidenavExpanded: true
      };

    case LayoutActionTypes.CollapseSidenav:
      return {
        ...state,
        sidenavExpanded: false
      };

    case LayoutActionTypes.ToggleExpandSidenav:
      return {
        ...state,
        sidenavExpanded: !state.sidenavExpanded
      };

    case LayoutActionTypes.OpenDrawer:
      return {
        ...state,
        drawerOpened: true,
        drawerContents: action.payload
      };

    case LayoutActionTypes.CloseDrawer:
      return {
        ...state,
        drawerOpened: false,
        drawerContents: {
          component: undefined,
          data: undefined
        }
      };

    case LayoutActionTypes.OpenLeftDrawer:
      return {
        ...state,
        leftDrawerOpened: true,
        leftDrawerContents: action.payload
      };

    case LayoutActionTypes.CloseLeftDrawer:
      return {
        ...state,
        leftDrawerOpened: false,
        leftDrawerContents: {
          component: undefined,
          data: undefined
        }
      };

    case LayoutActionTypes.SetLoadingBarVisibility:
      return {
        ...state,
        loadingBarVisible: action.payload
      };

    case LayoutActionTypes.OpenSnackbar:
      return {
        ...state,
        snackbar: {
          ...action.payload
        }
      };

    case LayoutActionTypes.CloseSnackbar:
      return {
        ...state,
        snackbar: {
          opened: false,
          message: undefined,
          duration: undefined
        }
      };

    case LayoutActionTypes.OpenErrorSnackbar:
      return {
        ...state,
        errorSnackbar: {
          ...action.payload
        }
      };

    case LayoutActionTypes.CloseErrorSnackbar:
      return {
        ...state,
        errorSnackbar: {
          opened: false,
          message: undefined,
          duration: undefined
        }
      };

    case LayoutActionTypes.SetDefaultLandingPage: {
      return {
        ...state,
        defaultLandingPage: action.payload
      };
    }

    default:
      return state;
  }
}
