import { RootState } from '../../store.reducer';
import { MemoizedSelector, createSelector } from '@ngrx/store';
import { GenericMap } from '../../../models/generic-map';
import { selectAllFieldTypes } from '../../system-settings/field-types/selectors/field-types.selector';
import { FieldType } from 'src/app/models/call-notes/field-type';
import { OpportunityCustomField } from 'src/app/models/Opportunity/opportunity-custom-field';
import { selectOppCustomFields } from './opp-custom-fields.selector';

export const selectCurrentOppCustomFieldId = (state: RootState): number =>
  state.opportunities.selectedCustomOppFieldId;

export const selectCurrentOppCustomField: MemoizedSelector<
  RootState,
  OpportunityCustomField
> = createSelector(
  selectOppCustomFields,
  selectAllFieldTypes,
  selectCurrentOppCustomFieldId,
  (
    oppCFs: GenericMap<OpportunityCustomField>,
    types: GenericMap<FieldType>,
    fieldId: number
  ): OpportunityCustomField => {
    return !!oppCFs[fieldId] && !!types[oppCFs[fieldId].typeInfo.id]
      ? oppCFs[fieldId]
      : null;
  }
);
