import { ExpenseTypesActions, ExpenseTypesActionTypes } from './expense-types.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { ExpenseType } from '../../../models/activities/expense-type';

export interface ExpenseTypesState {
  pending: boolean;
  error: HttpErrorResponse;
  data: { [id: number]: ExpenseType };
  selectedExpenseTypeId: number;
}

export const initialState: ExpenseTypesState = {
  pending: false,
  data: {},
  error: undefined,
  selectedExpenseTypeId: undefined
};

export function expenseTypesReducer(
  state = initialState,
  action: ExpenseTypesActions
): ExpenseTypesState {
  switch (action.type) {
    case ExpenseTypesActionTypes.SelectExpenseTypeId:
      return {
        ...state,
        selectedExpenseTypeId: action.payload
      };

    case ExpenseTypesActionTypes.ClearExpenseTypeId:
      return {
        ...state,
        selectedExpenseTypeId: undefined
      };

    case ExpenseTypesActionTypes.LoadExpenseTypes:
      return {
        ...state,
        pending: true
      };

    case ExpenseTypesActionTypes.LoadExpenseTypesSuccess:
      return {
        ...state,
        pending: false,
        data: action.payload
      };

    case ExpenseTypesActionTypes.LoadExpenseTypesFailure:
      return {
        ...state,
        pending: false,
        data: {},
        error: action.payload
      };

    case ExpenseTypesActionTypes.CreateExpenseTypes:
      return {
        ...state,
        pending: true
      };

    case ExpenseTypesActionTypes.CreateExpenseTypesSuccess:
      return {
        ...state,
        pending: false
      };

    case ExpenseTypesActionTypes.CreateExpenseTypesFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case ExpenseTypesActionTypes.UpdateExpenseTypesSuccess:
      return {
        ...state,
        pending: false
      };

    case ExpenseTypesActionTypes.UpdateExpenseTypesFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case ExpenseTypesActionTypes.DeleteExpenseTypes:
      return {
        ...state,
        pending: true
      };

    case ExpenseTypesActionTypes.DeleteExpenseTypesSuccess:
      return {
        ...state,
        pending: false,
        selectedExpenseTypeId: undefined
      };

    case ExpenseTypesActionTypes.DeleteExpenseTypesFailure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    default:
      return state;
  }
}
