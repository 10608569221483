import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiLoginInput } from 'src/app/models/auth/api-login-input';
import { AuthData } from './auth.reducer';

export enum AuthActionTypes {
  LoadAuth = '[Auth] Load Auth',
  LoadMFA = '[Auth] Load MFA',
  SendMFA = '[Auth] Send MFA',
  LoadApiSuccess = '[Auth] Load API Success',
  LoadJpiSuccess = '[Auth] Load JPI Success',
  LoadAuthComplete = '[Auth] Load Auth Complete',
  LoadAuthFailure = '[Auth] Load Auth Failure',
  LoginRedirect = '[Auth] Login Redirect',
  AuthenticationRedirect = '[Auth] Authentication Redirect',
  Logout = '[Auth] Logout',
  ClearState = '[Auth] Clear State',
  Reauthenticate = '[Auth] Reauthenticate',
  SetInflightUrl = '[Auth] Set Inflight Url',
  DeleteAuthObjects = '[Auth] Delete Auth Objects',
  SetOktaState = '[Auth] Set Okta State',
  OktaLogin = '[Auth] Okta Login',
  OktaLoginSuccess = '[Auth] Okta Login Success',
  OktaLoginFailure = '[Auth] Okta Login Failure',
  ExpireAuth = '[Auth] Expire Auth',
  SignInAsOther = '[Auth] Sign In As Other',
  SignInAsOtherSuccess = '[Auth] Sign In As Other Success',
  SignInAsOtherFailure = '[Auth] Sign In As Other Failure'
}

export class LoadAuth implements Action {
  readonly type = AuthActionTypes.LoadAuth;
  constructor(public payload: ApiLoginInput) {}
}

export class LoadMFA implements Action {
  readonly type = AuthActionTypes.LoadMFA;
  constructor(public payload: any) {}
}

export class SendMFA implements Action {
  readonly type = AuthActionTypes.SendMFA;
  constructor(public payload: { mfa: number | string; data: {} }) {}
}

export class LoadApiSuccess implements Action {
  readonly type = AuthActionTypes.LoadApiSuccess;
  constructor(public payload: any) {}
}

export class LoadJpiSuccess implements Action {
  readonly type = AuthActionTypes.LoadJpiSuccess;
  constructor(public payload: any) {}
}

export class LoadAuthComplete implements Action {
  readonly type = AuthActionTypes.LoadAuthComplete;
}

export class LoadAuthFailure implements Action {
  readonly type = AuthActionTypes.LoadAuthFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class LoginRedirect {
  readonly type = AuthActionTypes.LoginRedirect;
}

export class ClearState {
  readonly type = AuthActionTypes.ClearState;
}

export class AuthenticationRedirect {
  readonly type = AuthActionTypes.AuthenticationRedirect;
}

export class Logout {
  readonly type = AuthActionTypes.Logout;
}

export class Reauthenticate {
  readonly type = AuthActionTypes.Reauthenticate;
}

export class SetInflightUrl {
  readonly type = AuthActionTypes.SetInflightUrl;
  constructor(public payload: string) {}
}

export class DeleteAuthObjects {
  readonly type = AuthActionTypes.DeleteAuthObjects;
}

export class SetOktaState {
  readonly type = AuthActionTypes.SetOktaState;
  constructor(public payload: string) {}
}

export class OktaLogin implements Action {
  readonly type = AuthActionTypes.OktaLogin;
  constructor(public payload: string) {}
}

export class OktaLoginSuccess implements Action {
  readonly type = AuthActionTypes.OktaLoginSuccess;
  constructor(public payload: AuthData) {}
}

export class OktaLoginFailure implements Action {
  readonly type = AuthActionTypes.OktaLoginFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class ExpireAuth implements Action {
  readonly type = AuthActionTypes.ExpireAuth;
}

export class SignInAsOther implements Action {
  readonly type = AuthActionTypes.SignInAsOther;
  constructor(public payload: number) {}
}

export class SignInAsOtherSuccess implements Action {
  readonly type = AuthActionTypes.SignInAsOtherSuccess;
  constructor(public payload: AuthData) {}
}

export class SignInAsOtherFailure implements Action {
  readonly type = AuthActionTypes.SignInAsOtherFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export type AuthActions =
  | LoadAuth
  | LoadMFA
  | LoadApiSuccess
  | LoadJpiSuccess
  | LoadAuthComplete
  | LoadAuthFailure
  | LoginRedirect
  | AuthenticationRedirect
  | Logout
  | Reauthenticate
  | SetInflightUrl
  | DeleteAuthObjects
  | SetOktaState
  | OktaLogin
  | OktaLoginSuccess
  | OktaLoginFailure
  | ExpireAuth
  | SignInAsOther
  | SignInAsOtherSuccess
  | SignInAsOtherFailure;
