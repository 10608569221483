import { UndoDeleteActions, UndoDeleteActionTypes } from './undo-delete.actions';

export interface DeletedState {
  itemsBeingDeleted: any[];
  currentDeleteAction: any;
}

export const initialState: DeletedState = {
  itemsBeingDeleted: [],
  currentDeleteAction: undefined
};

export function undoDeleteReducer(
  state = initialState,
  action: UndoDeleteActions
): DeletedState {
  switch (action.type) {
    case UndoDeleteActionTypes.AddObjectsToDeletion:
      return {
        ...state,
        itemsBeingDeleted: [...action.payload, ...state.itemsBeingDeleted]
      };

    case UndoDeleteActionTypes.SetDeleteAction:
      return {
        ...state,
        currentDeleteAction: action.payload
      };

    case UndoDeleteActionTypes.ClearDeletedState:
      return {
        ...state,
        itemsBeingDeleted: [],
        currentDeleteAction: undefined
      };

    default:
      return state;
  }
}
