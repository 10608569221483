import { HttpErrorResponse } from '@angular/common/http';
import { DmsActionTypes, DmsActions } from './dms.actions';

export interface DmsState {
  magentoDmsLinkedCompany: {
    pending: boolean;
    error: HttpErrorResponse;
    magentolinkedCompany: any;
  };
}

export const initialState: DmsState = {
  magentoDmsLinkedCompany: {
    pending: false,
    error: null,
    magentolinkedCompany: null
  }
};

export function dmsReducer(state: DmsState = initialState, action: DmsActions): DmsState {
  switch (action.type) {
    case DmsActionTypes.LoadMagentoDmsLinkedCompany:
      return {
        ...state,
        magentoDmsLinkedCompany: {
          ...state.magentoDmsLinkedCompany,
          pending: true,
          error: null
        }
      };
    case DmsActionTypes.LoadMagentoDmsLinkedCompanySuccess:
      return {
        ...state,
        magentoDmsLinkedCompany: {
          ...state.magentoDmsLinkedCompany,
          pending: false,
          magentolinkedCompany: action.payload
        }
      };
    case DmsActionTypes.LoadMagentoDmsLinkedCompanyFailure:
      return {
        ...state,
        magentoDmsLinkedCompany: {
          ...state.magentoDmsLinkedCompany,
          pending: false,
          error: action.payload
        }
      };
    default:
      return state;
  }
}
