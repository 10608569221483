import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileMetaData } from 'src/app/models/file-meta-data';
import { FilesService } from 'src/app/services/files/files.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'tn-project-file',
  templateUrl: './project-file.component.html',
  styleUrls: ['./project-file.component.scss']
})
export class ProjectFileComponent implements OnInit {
  @Input() file: FileMetaData;
  @Output() deleteEvent: EventEmitter<number> = new EventEmitter<number>();
  sanitizedUrl: SafeUrl;
  isImage: boolean;
  image: any;
  jpiFileUrl = `${environment.jpiUrl}/files/`;

  constructor(private sanitizer: DomSanitizer, private fs: FilesService) {}

  ngOnInit() {
    if (/image/.test(this.file.fileType)) {
      this.isImage = true;
    }
    this.sanitizedUrl = this.sanitizer.bypassSecurityTrustUrl(
      `data:${this.file.fileType},${this.file.downloadUrl}`
    );
  }

  onDownload() {
    this.fs.downloadFile(this.file);
  }

  onDownloadImage() {
    this.fs.downloadImage(this.file);
  }

  onDelete() {
    this.deleteEvent.emit(this.file.id);
  }
}
