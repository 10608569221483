import { MemoizedSelector, createSelector } from '@ngrx/store';
import { RootState } from '../../../store.reducer';
import { GenericMap } from 'src/app/models/generic-map';
import { ProjectCustomField } from 'src/app/models/projects/project-custom-field';

export const selectAllProjectCustomFields = (state: RootState) =>
  state.projectCustomFields.data;

export const selectedProjectCustomFieldId = (state: RootState) =>
  state.projectCustomFields.selectedPcfId;

export const selectCurrentProjectCustomField: MemoizedSelector<
  RootState,
  ProjectCustomField
> = createSelector(
  selectAllProjectCustomFields,
  selectedProjectCustomFieldId,
  (
    projectCFMap: GenericMap<ProjectCustomField>,
    projectCFId: number
  ): ProjectCustomField => {
    let projectCF: ProjectCustomField;
    if (!!projectCFId && projectCFMap[projectCFId]) {
      const id = projectCFId.toString();
      projectCF = {
        ...projectCFMap[id]
      };
    }
    return projectCF;
  }
);
