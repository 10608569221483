import { User } from 'src/app/models/admin/users/user';
import { PageableData, PrivateNote } from './../../models/private-notes/private-note';
import { PrivateNotesActionTypes, PrivateNotesActions } from './private-notes.actions';
import { GenericMap } from 'src/app/models/generic-map';

export interface PrivateNotesState {
  data: PageableData<PrivateNote>;
  assignableUsers: GenericMap<User>;
  loading: boolean;
  error: any;
}

export const intialState: PrivateNotesState = {
  data: {
    content: []
  } as PageableData<PrivateNote>,
  assignableUsers: {} as GenericMap<User>,
  loading: false,
  error: null
};

export function privateNotesReducer(
  state = intialState,
  action: PrivateNotesActions
): PrivateNotesState {
  switch (action.type) {
    case PrivateNotesActionTypes.LoadPrivateNotes:
      return {
        ...state,
        loading: true,
        error: undefined
      };

    case PrivateNotesActionTypes.LoadPrivateNotesSuccess:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: undefined
      };

    case PrivateNotesActionTypes.LoadPrivateNotesFailure:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case PrivateNotesActionTypes.LoadPrivateNotesByCompanyId:
      return {
        ...state,
        loading: true,
        error: undefined
      };

    case PrivateNotesActionTypes.LoadPrivateNotesByCompanyIdSuccess:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: undefined
      };

    case PrivateNotesActionTypes.LoadPrivateNotesByCompanyIdFailure:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case PrivateNotesActionTypes.LoadPrivateNotesAssignableUsers:
      return {
        ...state,
        loading: true,
        error: undefined
      };

    case PrivateNotesActionTypes.LoadPrivateNotesAssignableUsersSuccess:
      return {
        ...state,
        assignableUsers: action.payload,
        loading: false,
        error: undefined
      };

    case PrivateNotesActionTypes.LoadPrivateNotesAssignableUsersFailure:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case PrivateNotesActionTypes.SavePrivateNote:
      return {
        ...state,
        loading: true,
        error: undefined
      };

    case PrivateNotesActionTypes.SavePrivateNoteSuccess:
      return {
        ...state,
        data: {
          ...state.data,
          content: [action.payload, ...state.data?.content]
        },
        loading: false,
        error: undefined
      };

    case PrivateNotesActionTypes.SavePrivateNoteFailure:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case PrivateNotesActionTypes.UpdatePrivateNote:
      return {
        ...state,
        loading: true,
        error: undefined
      };

    case PrivateNotesActionTypes.UpdatePrivateNoteSuccess:
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data?.content.map((note) => {
            if (note.id === action.payload.id) {
              return action.payload;
            }
            return note;
          })
        },
        loading: false,
        error: undefined
      };

    case PrivateNotesActionTypes.UpdatePrivateNoteFailure:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case PrivateNotesActionTypes.DeletePrivateNote:
      return {
        ...state,
        loading: true,
        error: undefined
      };

    case PrivateNotesActionTypes.DeletePrivateNoteSuccess:
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data?.content.filter((note) => note.id !== action.payload)
        },
        loading: false,
        error: undefined
      };

    case PrivateNotesActionTypes.DeletePrivateNoteFailure:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case PrivateNotesActionTypes.DeletePrivateNoteBatch:
      return {
        ...state,
        loading: true,
        error: undefined
      };

    case PrivateNotesActionTypes.DeletePrivateNoteBatchSuccess:
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data?.content.filter((note) => !action.payload.includes(note.id))
        },
        loading: false,
        error: undefined
      };

    case PrivateNotesActionTypes.DeletePrivateNoteBatchFailure:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
}
