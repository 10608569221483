import { RootState } from '../../store.reducer';
import { createSelector, MemoizedSelector } from '@ngrx/store';

export const selectUserFirstName = (state: RootState) =>
  state.auth.data.jpi ? state.auth.data.jpi.user.firstName : undefined;
export const selectUserLastName = (state: RootState) =>
  state.auth.data.jpi ? state.auth.data.jpi.user.lastName : undefined;

export const selectUserFullName: MemoizedSelector<RootState, string> = createSelector(
  selectUserFirstName,
  selectUserLastName,
  (firstName: string, lastName: string): string => {
    if (firstName && lastName) {
      return firstName + ' ' + lastName;
    } else {
      return 'No Username Available';
    }
  }
);
