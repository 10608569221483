import { Action } from '@ngrx/store';

export enum BillingActionTypes {
  LoadBilling = '[Billing] Load Billings',
  LoadBillingSuccess = '[Billing] Load Billings Success',
  LoadBillingFailure = '[Billing] Load Billings Failure'
}

export class LoadBilling implements Action {
  readonly type = BillingActionTypes.LoadBilling;
}

export class LoadBillingSuccess implements Action {
  readonly type = BillingActionTypes.LoadBillingSuccess;
  constructor(public payload: { data: any }) {}
}

export class LoadBillingFailure implements Action {
  readonly type = BillingActionTypes.LoadBillingFailure;
  constructor(public payload: { error: any }) {}
}

export type BillingActions = LoadBilling | LoadBillingSuccess | LoadBillingFailure;
