import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthenticationService } from '../shared/services/authentication.service';

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
    loading: boolean = false;
    mailbox: string;
    password: string;
    passwordError: boolean = false;
    serverError: boolean = false;
    usernameError: boolean = false;
    showTooltip: boolean;

    constructor(private _authenticationService: AuthenticationService,
        private _router: Router,
        private _route: ActivatedRoute) {
    }

    ngOnInit(): void {
        const path = this._route.snapshot.url[0].path;
        if (path.toLowerCase() == 'signout') {
            this.signout();
        } else {
            if (this._authenticationService.isSignedIn()) {
                this._router.navigate(['activity/calendar']);
                return;
            }
            if (!(this._authenticationService.getCookie(this._authenticationService.cookieNames.NewTelenotes) && this._authenticationService.getCookie(this._authenticationService.cookieNames.NewTelenotes) == 'true') && this._authenticationService.getCookie(this._authenticationService.cookieNames.Snid)) {
                window.location.href = 'https://telenotes.net/AccountInfo.aspx?newLogin=1&SNID=' + this._authenticationService.getCookie(this._authenticationService.cookieNames.Snid);
            }
            if (this._authenticationService.isSignedIn()) {
                if (this._authenticationService.getCookie(this._authenticationService.cookieNames.NewTelenotes) == 'true') {
                    this._router.navigate([this._authenticationService.getDefaultRoute()]);
                } else {
                    window.location.href = 'https://telenotes.net/AccountInfo.aspx?newLogin=1&SNID=' + this._authenticationService.getCookie(this._authenticationService.cookieNames.Snid);
                }
            }
        }
    }

    ngOnDestroy(): void {
        delete this.password;
    }

    login(): void {
        this.loading = true;
        if (this.mailbox && this.password) {
            this.usernameError = false;
            this._authenticationService.login(this.mailbox, this.password).then((result) => {
                if (result) {
                    if (this._authenticationService.getCookie(this._authenticationService.cookieNames.NewTelenotes) == 'true') {
                        this._router.navigate([this._authenticationService.getDefaultRoute()]);
                    } else {
                        window.location.href = 'https://telenotes.net/AccountInfo.aspx?newLogin=1&SNID=' + this._authenticationService.getCookie(this._authenticationService.cookieNames.Snid);
                    }
                } else {
                    if (this._authenticationService.signInError == 401) {
                        this.passwordError = true;
                    } else {
                        this.serverError = true;
                    }
                }
                this.loading = false;
            });
        } else {
            this.loading = false;
            this.usernameError = true;
        }
    }

    signout(): void {
        this._authenticationService.logout();
    }
}
