<div class="searchbar-container">
  <div class="search-bar" fxLayout="row" fxLayoutAlign="start center">
    <mat-icon>search</mat-icon>
    &nbsp;
    <input
      [placeholder]="searchBarPlaceHolder"
      [formControl]="searchInput"
      [autofocus]="true"
    />
    <span class="spinner">
      <mat-spinner *ngIf="searchLoading" diameter="16"></mat-spinner>
    </span>
  </div>
</div>
