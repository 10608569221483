import { Action } from '@ngrx/store';
import { CompanyField } from 'src/app/models/companies/company-field';
import { HttpErrorResponse } from '@angular/common/http';

export enum CompanyFieldsActionTypes {
  SetCurrentCompanyField = '[CompanyFields] Set Current Company Custom Field',
  ClearCurrentCompanyField = '[CompanyFields] Clear Current Company Custom Field',
  LoadCompanyFields = '[CompanyFields] Load CompanyFields',
  LoadCompanyFieldsSuccess = '[CompanyFields] Load CompanyFields Success',
  LoadCompanyFieldsFailure = '[CompanyFields] Load CompanyFields Failure',
  CreateCompanyField = '[CompanyFields] Create CompanyField',
  CreateCompanyFieldSuccess = '[CompanyFields] Create CompanyField Success',
  CreateCompanyFieldFailure = '[CompanyFields] Create CompanyField Failure',
  UpdateCompanyField = '[CompanyFields] Update Load CompanyField',
  UpdateCompanyFieldSuccess = '[CompanyFields] Update CompanyField Success',
  UpdateCompanyFieldFailure = '[CompanyFields] Update CompanyField Failure',
  DeleteCompanyField = '[CompanyFields] Delete CompanyField',
  DeleteCompanyFieldSuccess = '[CompanyFields] Delete CompanyField Success',
  DeleteCompanyFieldFailure = '[CompanyFields] Delete CompanyField Failure'
}

export class SetCurrentCompanyField implements Action {
  readonly type = CompanyFieldsActionTypes.SetCurrentCompanyField;
  constructor(public payload: CompanyField) {}
}

export class ClearCurrentCompanyField implements Action {
  readonly type = CompanyFieldsActionTypes.ClearCurrentCompanyField;
}

export class LoadCompanyFields implements Action {
  readonly type = CompanyFieldsActionTypes.LoadCompanyFields;
}

export class LoadCompanyFieldsSuccess implements Action {
  readonly type = CompanyFieldsActionTypes.LoadCompanyFieldsSuccess;
  constructor(public payload: CompanyField[]) {}
}

export class LoadCompanyFieldsFailure implements Action {
  readonly type = CompanyFieldsActionTypes.LoadCompanyFieldsFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class CreateCompanyField implements Action {
  readonly type = CompanyFieldsActionTypes.CreateCompanyField;
  constructor(public payload: CompanyField) {}
}

export class CreateCompanyFieldSuccess implements Action {
  readonly type = CompanyFieldsActionTypes.CreateCompanyFieldSuccess;
  constructor(public payload: CompanyField) {}
}

export class CreateCompanyFieldFailure implements Action {
  readonly type = CompanyFieldsActionTypes.CreateCompanyFieldFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class UpdateCompanyField implements Action {
  readonly type = CompanyFieldsActionTypes.UpdateCompanyField;
  constructor(public payload: CompanyField) {}
}

export class UpdateCompanyFieldSuccess implements Action {
  readonly type = CompanyFieldsActionTypes.UpdateCompanyFieldSuccess;
  constructor(public payload: CompanyField) {}
}

export class UpdateCompanyFieldFailure implements Action {
  readonly type = CompanyFieldsActionTypes.UpdateCompanyFieldFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class DeleteCompanyField implements Action {
  readonly type = CompanyFieldsActionTypes.DeleteCompanyField;
  constructor(public payload: number) {}
}

export class DeleteCompanyFieldSuccess implements Action {
  readonly type = CompanyFieldsActionTypes.DeleteCompanyFieldSuccess;
  constructor(public payload: number) {}
}

export class DeleteCompanyFieldFailure implements Action {
  readonly type = CompanyFieldsActionTypes.DeleteCompanyFieldFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export type CompanyFieldsActions =
  | SetCurrentCompanyField
  | ClearCurrentCompanyField
  | LoadCompanyFields
  | LoadCompanyFieldsSuccess
  | LoadCompanyFieldsFailure
  | CreateCompanyField
  | CreateCompanyFieldSuccess
  | CreateCompanyFieldFailure
  | UpdateCompanyField
  | UpdateCompanyFieldSuccess
  | UpdateCompanyFieldFailure
  | DeleteCompanyField
  | DeleteCompanyFieldSuccess
  | DeleteCompanyFieldFailure;
