import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ServicesModule } from '../../services.module';

@Injectable({
  providedIn: ServicesModule
})
export class FieldTypesService {
  private jpiUrl = environment.jpiUrl;
  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get(`${this.jpiUrl}/fieldtypes`);
  }
}
